export class bengaliLabel {
    public product: string = 'পণ্য';
    public support: string = 'সমর্থন';
    public raise_a_query: string = 'প্রশ্ন উত্তোলন';
    public raise_a_claim: string = 'দাবি উত্তোলন';
    public contact_us: string = 'আমাদের সাথে যোগাযোগ করুন';
    public career: string = 'ক্যারিয়ার';
    public gallery: string = 'গ্যালারি';
    public login: string = 'লগইন করুন';
    public two_wheeler: string = 'দুই চাকার গাড়ি';
    public four_wheeler: string = 'চার চাকার গাড়ি';
    public health: string = 'স্বাস্থ্য';
    public commercial_vehicle: string = 'বাণিজ্যিক যানবাহন';
    public bike_ins_title: string = 'আপনার টু-হুইলার বীমায় একচেটিয়াভাবে কম দাম পান';
    public bike_ins_start: string = 'টু-হুইলার বীমা ₹555 থেকে শুরু*';
    public enter_bike_number: string = 'টু-হুইলার নম্বর লিখুন। EX: AB12CD3456';
    public verify: string = 'যাচাই করুন';
    public dont_have_policy: string = "আমার বৈধ পলিসি নেই";
    public buy_new_bike: string = 'একটি নতুন টু-হুইলার কিনেছেন?';
    public what_is_ins1: string = 'বীমা';
    public what_is_ins2: string = 'কি?';
    public what_is_ins_title: string = 'বীমা সহ মনের শান্তি আবিষ্কার করুন';
    public what_is_ins_text: string = "আমাদের Click4bima পোর্টালে স্বাগতম, আপনার ভবিষ্যত সুরক্ষিত করার গেটওয়ে! বিস্তৃত বীমা বিকল্পগুলি অন্বেষণ করার জন্য আমাদের পোর্টাল হল আপনার ওয়ান-স্টপ গন্তব্য। স্বাস্থ্য এবং অটো থেকে শুরু করে বাড়ি এবং ভ্রমণ পর্যন্ত, আমরা আপনার প্রয়োজন অনুসারে সেরা কভারেজ প্ল্যানগুলি আনতে স্বনামধন্য বীমা সংস্থাগুলির সাথে অংশীদারিত্ব করেছি। বিভিন্ন নীতির তুলনা করে, আপনি সচেতন সিদ্ধান্ত নিতে পারেন এবং আপনার জীবনধারা এবং বাজেটের সাথে মানানসই একটি নির্বাচন করতে পারেন।";
    public why_insurance_imp1: string = 'কেন বীমা';
    public why_insurance_imp2: string = 'গুরুত্বপূর্ণ?';
    public why_insurance_imp_title: string = 'আগামীকাল নিরাপদ করা: বীমার প্রাণশক্তি উন্মোচন করা';
    public why_insurance_imp_text: string = "বীমা হল জীবনের অনিশ্চয়তার বিরুদ্ধে আপনার ঢাল, আপনার যখন সবচেয়ে বেশি প্রয়োজন তখন আপনাকে মানসিক শান্তি প্রদান করে। এটি আপনার এবং আপনার নির্বাচিত বীমা প্রদানকারীর মধ্যে একটি চুক্তি যা অপ্রত্যাশিত ঘটনার সময়ে আর্থিক সহায়তার নিশ্চয়তা দেয়। এটি একটি আকস্মিক চিকিৎসা ব্যয়, একটি অটোমোবাইল দুর্ঘটনা, আপনার সম্পত্তির ক্ষতি, বা কোন অপ্রত্যাশিত পরিস্থিতি, বীমা বোঝা সহজ করার জন্য পদক্ষেপ.";
    public claim_assistance1: string = 'সহায়তা';
    public claim_assistance2: string = 'দাবি করুন';
    public claim_assistance_title: string = 'আপনার মনের শান্তি পুনরুদ্ধার করা হয়েছে';
    public claim_assistance_text: string = "Click4bima থেকে 'ক্লেম অ্যাসিসট্যান্স'-এর মাধ্যমে, আপনি নিশ্চিন্ত থাকতে পারেন যে আপনার একজন নির্ভরযোগ্য অংশীদার আছে যিনি একটি মসৃণ দাবি প্রক্রিয়ার গুরুত্ব বোঝেন। আপনার মনের শান্তি পুনরুদ্ধার করুন এই জেনে যে আপনি শুধুমাত্র একটি নীতির চেয়েও বেশি কিছু দ্বারা সুরক্ষিত - আপনাকে এমন একটি দল দ্বারা সমর্থিত যারা যত্নশীল। আমাদের 'ক্লেইম অ্যাসিসটেন্স' হল আপনার বিশ্বস্ত সঙ্গী যখন আপনার সবচেয়ে বেশি প্রয়োজন হয়।";
    public best_place_ins_title: string = 'কি আমাদের ভারতে বীমা কেনার সেরা জায়গা করে তোলে?';
    public best_place_ins_text: string = 'আমাদের Click4bima পোর্টালে স্বাগতম, ভারতে সেরা বীমা কভারেজ খোঁজার জন্য আপনার এক-স্টপ গন্তব্য। ভাবছেন কেন আমরা বাকিদের থেকে আলাদা? সঠিক বীমা কভারেজের সুরক্ষার সাথে একটি উদ্বেগমুক্ত ভবিষ্যতকে আলিঙ্গন করুন। আজই আমাদের সাথে আপনার বীমা যাত্রা শুরু করুন।';
    public everytime_support_title: string = '24*7 সমর্থন ';
    public everytime_support_text: string = "আমাদের 24/7 বীমা সহায়তার মাধ্যমে যেকোনো সময়, যে কোনো জায়গায় মানসিক শান্তি পান। আমাদের ডেডিকেটেড টিম আপনাকে বিশেষজ্ঞ সহায়তা প্রদান করতে, আপনার প্রশ্নের উত্তর দিতে এবং আপনি সর্বদা আচ্ছাদিত আছেন তা নিশ্চিত করতে এখানে রয়েছে।"


    public best_price_ins_title: string = 'সেরা মূল্য বীমা';
    public best_price_ins_text: string = "আমাদের লক্ষ্য সহজ: গুণমানের সাথে আপস না করেই আপনাকে সেরা বীমা চুক্তি প্রদান করা। কাস্টমাইজযোগ্য নীতির একটি পরিসীমা সহ, আমরা আপনার বাজেট অক্ষুণ্ণ রেখে আপনার ভবিষ্যৎ সুরক্ষিত করতে এখানে আছি।";
    public reliable_title: string = 'নির্ভরযোগ্য';
    public reliable_text: string = 'কোন ব্যতিক্রম ছাড়াই আপনার কাছে সবচেয়ে গুরুত্বপূর্ণ বিষয়গুলি রক্ষা করার জন্য আপনি আমাদের বিশ্বাস করতে পারেন৷ আমাদের প্রতিশ্রুতি প্রদানের একটি প্রমাণিত ট্র্যাক রেকর্ড সহ, আমরা প্রতিটি পদক্ষেপে আপনার মানসিক শান্তি নিশ্চিত করি। আপনার ভবিষ্যত রক্ষায় অটুট নির্ভরযোগ্যতার জন্য আমাদের বেছে নিন।';
    public easy_claim_title: string = 'দাবি সহজ করা';
    public easy_claim_text: string = "আমাদের স্ট্রিমলাইনড পদ্ধতি এবং ডেডিকেটেড সাপোর্ট টিম নিশ্চিত করে যে আপনার যখন সবচেয়ে বেশি প্রয়োজন তখন আপনি আপনার প্রয়োজনীয় সহায়তা পান। আমরা প্রক্রিয়াটিকে সহজ করার জন্য প্রতিশ্রুতিবদ্ধ যাতে আপনি দ্রুত আপনার জীবনের সবচেয়ে গুরুত্বপূর্ণ বিষয়গুলিতে ফিরে যেতে পারেন৷";
    public mobile_app_title: string = 'অর্থপ্রদান করুন, বিনিয়োগ করুন, একটি একক অ্যাপের মাধ্যমে আপনার অর্থ সঞ্চয় করুন';
    public mobile_app_content: string = 'যে কোনো সময়, যেকোনো জায়গায় আপনার সমস্ত বীমা চাহিদার নিয়ন্ত্রণ পান';

    public mobile_app_compare: string = 'বিভিন্ন বীমা পলিসির তুলনা করুন';
    public mobile_app_buy:string='অনলাইনে আপনার সমস্ত নীতি কিনুন, সঞ্চয় করুন এবং শেয়ার করুন'
    public mobile_app_track: string = 'যেতে যেতে আপনার পলিসি স্ট্যাটাস ট্র্যাক করুন';
    public mobile_app_download: string = 'একটি ট্যাপ দিয়ে আপনার নীতি ডাউনলোড করুন';
    public mobile_app_coming: string = 'আশ্চর্যজনক কিছুর জন্য প্রস্তুত হন - আমাদের অ্যাপ শীঘ্রই চালু হবে!';
    public testimonial_title: string = 'আমরা আমাদের গ্রাহক অভিজ্ঞতার বিষয়েও যত্নশীল|';
    public our_partner_title: string = 'আমাদের অংশীদারদের |';
    public our_partner_text: string = 'আমরা আপনাকে সর্বোত্তম পরিষেবা প্রদানের জন্য একসাথে কাজ করি।';
    public need_help_title: string = 'কিছু সাহায্য প্রয়োজন?';
    public need_help_text: string = 'আমরা আপনাকে সর্বোত্তম পরিষেবা প্রদানের জন্য একসাথে কাজ করি। Click4bima পোর্টালে, আমরা আপনার বীমা যাত্রার প্রতিটি ধাপে ব্যতিক্রমী গ্রাহক সেবা এবং সহায়তা প্রদানের জন্য প্রতিশ্রুতিবদ্ধ। বীমা জগতে নেভিগেট করার জন্য আমাদের আপনার বিশ্বস্ত অংশীদার হতে দিন। আজই আমাদের সাপোর্ট টিমের সাথে যোগাযোগ করুন এবং সরাসরি পার্থক্যটি অনুভব করুন। আপনার মনের শান্তি আমাদের অগ্রাধিকার!';
    public mail_us:string='আমাদের মেইল করুন';
    public mobile_number:string='মোবাইল নম্বর';
    public whatsapp:string='হোয়াটসঅ্যাপ';
    public about_us:string='আমাদের সম্পর্কে';
    public sitemap:string='সাইটম্যাপ';    
    public faqs:string='প্রায়শই জিজ্ঞাসিত প্রশ্ন';
    public motor_insurance:string='মোটর বীমা';
    public car_insurance :string='গাড়ী বীমা';   
    public two_wheeler_insurance :string='টু হুইলার বীমা';
    public taxi_insurance :string='ট্যাক্সি বীমা';
    public commercial_veh_insurance:string='বাণিজ্যিক যানবাহন বীমা';
    public third_party_car_ins:string='তৃতীয় পক্ষের গাড়ী বীমা'; 
    public third_party_bike_ins:string='থার্ড পার্টি 2W ইন্স্যুরেন্স';
    public health_insurance:string='স্বাস্থ্য বীমা';
    public family_health_ins:string='পারিবারিক স্বাস্থ্য পলিসি';
    public individual_health_ins:string='ব্যক্তিগত স্বাস্থ্য পলিসি';
    public group_health_ins:string='গোষ্ঠী স্বাস্থ্য পলিসি';
    public senior_citizen_health_ins:string='সিনিয়র সিটিজেন স্বাস্থ্য পলিসি'; 
    public critical_illness_ins:string='জটিল অসুস্থতা পলিসি';
    public arogya_san_ins:string='আরোগ্য সঞ্জীবনী পলিসি';
    public life_insurance:string='জীবনবীমা';
    public term_insurance:string='টার্ম  বীমা';
    public single_pay_term_ins:string='একক বেতন টার্ম  বীমা'; 
    public group_term_ins:string='গ্রুপ টার্ম ইন্স্যুরেন্স';  
    public saving_plans:string='সেভিং প্ল্যান';  
    public tax_saving_ins:string='ট্যাক্স সেভিং পলিসি';  
    public money_back_ins:string='মানি ব্যাক পলিসি';  
    public guaranteed_return_ins:string='গ্যারান্টিযুক্ত রিটার্ন পলিসি';   
    public ulip:string='ইউ এল আই পি';
    public pension_plan:string='পেনশন পরিকল্পনা';  
    public child_plan:string='শিশু পরিকল্পনা';    
    public others:string='অন্যান্য |';    
    public privacy_policy:string='গোপনীয়তা পলিসি';  
    public cancellation_refund:string='বাতিলকরণ এবং ফেরত';  
    public term_condition:string='নিয়ম ও শর্তাবলী';
    public disclaimer: string = 'দাবিত্যাগ';
    public grivance_redressal: string = 'প্রতিকার';
    public srg_add_title: string = 'এসআরজি ইন্স্যুরেন্স ব্রোকারস প্রাইভেট লিমিটেড';
    public srg_add_detail: string = '2 & 5, বিনায়ক কমপ্লেক্স, দুর্গা নার্সারি রোড, উদয়পুর, রাজস্থান, 313001';
    
    public direct_broker_title: string = 'সরাসরি ব্রোকার, লাইসেন্স নম্বর: DB-290 ';
    public direct_broker_detail: string = 'মেয়াদ: 02.02.2026, CIN: U74140RJ2002PTC017656';
    public  payment_method:string='মুল্য পরিশোধ পদ্ধতি';
    public  copyright:string='© কপিরাইট 2023 Click4bima. সমস্ত অধিকার সংরক্ষিত';   
    public continue:string='চালিয়ে যান';
    public bought_new_bike:string=' একটি নতুন টু-হুইলার কিনেছেন';
    public bought_new_car:string='একটা নতুন গাড়ি কিনেছে';
    public car_ins_title:string='আপনার গাড়ী বীমা একটি একচেটিয়াভাবে কম দাম পান';
    public car_ins_start:string='গাড়ী বীমা ₹555 থেকে শুরু*';
    public enter_car_number:string='গাড়ির নম্বর লিখুন EX: AB12CD3456৷'; 
    public vehicle_detail_form:string='ତୁମର ଯାନର ବିବରଣୀ |';
    public city:string='শহর'; 
    public rto:string="আরটিও";
    public manufacturer:string="প্রস্তুতকারক"; 
    public model:string="মডেল"; 
    public fuel_type:string="জ্বালানীর ধরণ";
    public variant:string="বৈকল্পিক"; 
    public reg_year:string="নিবন্ধন বছর"; 
    public get_quotes:string="উদ্বৃতি পেতে";  
    public search_city:string="শহর অনুসন্ধান করুন"; 
    public select_city:string="অথবা শহর নির্বাচন করুন"; 
    public select_rto:string='সিটি আরটিও নির্বাচন করুন';
    public search_manufacturer:string='অনুসন্ধান প্রস্তুতকারক';
    public select_manufacturer:string='অথবা প্রস্তুতকারক নির্বাচন করুন';  
    public search_model:string='অনুসন্ধান মডেল';
    public select_model:string='অথবা মডেল নির্বাচন করুন';  
    public search_variant:string='বৈকল্পিক অনুসন্ধান করুন';   
    public select_variant:string='অথবা বৈকল্পিক নির্বাচন করুন';   
    public select_year:string='বছর নির্বাচন করুন';   
    public select_fuel_type:string='জ্বালানীর ধরন নির্বাচন করুন'; 
    public contact_title:string='আমাদের সাথে যোগাযোগ করুন';   
    public contact_text:string='আমরা যত তাড়াতাড়ি সম্ভব আপনার কাছে ফিরে যাব।';  
    public full_name:string='পুরো নাম';     
    public mobile:string='মোবাইল নম্বর';            
    public interested_in:string='আগ্রহী'  ;  
    public email:string='ইমেইল অ্যাকাউন্ট';
    public description:string='বিবরণ';            
    public call_me:string='আমাকে কল ব্যাক';
    public continue_fb:string='ফেসবুকের সাথে চালিয়ে যান';
    public continue_google:string='গুগলের সাথে চালিয়ে যান';  
    public sign_in:string='সাইন ইন করুন';  
    public password:string='পাসওয়ার্ড';    
    public remember:string='আমাকে মনে রেখো';   
    public dont_have_acc:string="কোনো অ্যাকাউন্ট নেই"; 
    public signup_here:string="এখানে নিবন্ধন করুন";     
    public click_here:string="এখানে ক্লিক করুন";       
    public send_otp:string="ওটিপি পাঠান৷";         
    public back_login:string="প্রবেশ করতে পেছান";       
    public already_hv_acc:string="ইতিমধ্যে একটি সদস্যপদ আছে";     
    public signin_here:string="এখানে সাইন ইন করুন";     
    public verification:string="প্রতিপাদন";   
    public verify_text:string="আপনার নম্বর +918543871911 এ পাঠানো 6 সংখ্যার কোড লিখুন। আপনার মোবাইল নম্বর পরিবর্তন করতে এখানে ক্লিক করুন.";    
    public enter_otp:string="ওটিপি দিন";   
    public dont_rec_otp:string="যদি আপনি একটি কোড গ্রহণ না! আবার পাঠান";    
    public submit:string="জমা দিন"; 
    public accept_terms:string="আপনার অ্যাকাউন্ট তৈরি করে, আপনি আমাদের নিয়ম ও শর্তাবলীতে সম্মত হন";    
    public basic_details:string ="মৌলিক বিবরণ";      
    public first_name:string ="প্রথম নাম";     
    public last_name:string ="শেষ নাম";     
    public dob:string ="জন্ম তারিখ";                        
    public referal_code:string ="রেফারেল কোড";              
    public gender:string ="লিঙ্গ";                
    public new_password:string  ="নতুন পাসওয়ার্ড লিখুন";   
    public confirm_old_pass:string  ="নিশ্চিত কর নতুন গোপননম্বর";      
    public dont_have_policy_popup:string="আপনার টু-হুইলার নম্বর মনে না থাকলে, আপনার টু-হুইলারের প্রাথমিক বিবরণ অ্যাক্সেস করতে আপনার RC (রেজিস্ট্রেশন সার্টিফিকেট) আপনার কাছে প্রস্তুত রাখুন।";       

}