<div class="needs-validation" >
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" id="offcanvasScrollingLabel">{{!isEdit ? 'Add' : 'Update'}} Document</h4>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #closebtn></button>
  </div>
  <div class="offcanvas-body">
    <form id="kt_modal_add_user_form" class="form" [formGroup]="documentForm">
      <div class="row flex-column" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
        <div class="form-group mb-5">
          <label class="form-label required">Document Type</label>
          <select name="documentype" [ngClass]="isEdit ? 'disabled' : ''" aria-label="Select a document type" data-control="select2" formControlName="documentTypeId" data-placeholder="Select a document type" class="form-select">
            <option *ngFor="let document of documentList" [value]="document.documentTypeId">{{document.documentTypeName}}</option>
          </select>
        </div>

        <div class="form-group mb-5">
          <label class="form-label required">Document Number</label>
          <input type="text" name="document" placeholder="Enter Document Number" class="form-control" formControlName="documentNumber" oninput="this.value = this.value.toUpperCase()">
        </div>
        <div class="row mb-7 position-relative">
          <label class="d-block fw-semibold fs-6 mb-0 required">Document</label>
          <span class="form-text mt-0 mb-3">(Allowed file types: png, jpg, jpeg, pdf with max size 500 kb)</span>
          <div class="image-input h-100px position-initial" data-kt-image-input="true" style="background-image: url(./assets/images/blank-image.svg);">
            <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow top-n35"
                   data-kt-image-input-action="change"
                   data-bs-toggle="tooltip"
                   data-bs-dismiss="click"
                   title="Change document">
              <i class="fa fa-pencil"></i>
              <input type="file" name="avatar" (change)="onSelectFile($event)" accept=".png, .jpg, .jpeg, .pdf" />
              <input type="hidden" name="avatar_remove" />
            </label>
            <!-- <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                  data-kt-image-input-action="remove"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Remove document" (click)="onDeleteFile()">
              <i class="fa fa-times"></i>
            </span> -->
            <img [src]="format == 'pdf' ? '../../assets/svgIcon/pdf_alt.svg' : baseUpload64FileData" class="img-fluid h-100px">
            <!-- <div *ngIf="format == 'image'" class="image-input-wrapper w-125px h-125px" style="background-image: url({{baseUpload64FileData}})"></div>
            <video *ngIf="format == 'video'" [src]="baseUpload64FileData" style="width: 100%; height: 400px;" controls></video>
            <pdf-viewer *ngIf="format == 'pdf'" [src]="baseUpload64FileData" [render-text]="true" [autoresize]="true" [original-size]='false' [fit-to-page]='false' [show-borders]="true" style="width: 100%; height: 400px;"></pdf-viewer> -->
          </div>
        </div>
        <div class="position-absolute bottom-0">
          <button class="btn btn-primary w-100" (click)="submit()" [disabled]="documentForm.invalid">{{ !isEdit ? 'Add' : 'Update'}} Document</button>
        </div>
      </div>
    </form>
  </div>
</div>
