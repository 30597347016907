import { Component } from '@angular/core';
import { PremiumDetails, VehicleDetails } from '../../Models/vehicle-component.model';

@Component({
  selector: 'app-vehicle-premium-info',
  templateUrl: './vehicle-premium-info.component.html',
  styleUrls: ['./vehicle-premium-info.component.css']
})
export class VehiclePremiumInfoComponent {
  vehicleData: VehicleDetails = {};
  premiumData: PremiumDetails = {};

  async QuoteDetails(data: any) {
    this.vehicleData = data.vehicleData;
    this.premiumData = data.premiumData;

  }

}
