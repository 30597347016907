import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentService } from '../Services/document.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddDocumentComponent } from '../add-document/add-document.component';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  documentForm: FormGroup;
  fileToUpload: File | undefined;
  baseUpload64FileData: string = "";
  documentList: any = [];
  userDocumentList: any = [];
  format: string = '';
  isedit: boolean = false;
  title: string = 'Add Document';
  modalRef: BsModalRef;


  constructor(private fb: FormBuilder, private documentservice: DocumentService, 
    private spinner: NgxSpinnerService,private modalService: BsModalService,private generalService : GeneralService) {
    this.documentForm = this.fb.group({
      documentUniqueId: [''],
      documentStatus: 1,
      documentTypeId: [Validators.required],
      documentBase64: ['', Validators.required],
      documentNumber: ['', Validators.required]
    });
  }
  async ngOnInit() {
    await this.GetDocumentTypes();
    await this.GetUserDocuments();
  }

  onDeleteFile() {
    this.baseUpload64FileData = '';
    this.format = '';
    this.documentForm.get('DocumentBase64')?.setValue('');
  }

  async GetDocumentTypes() {
    const response = await this.documentservice.GetDocumentTypes();
    if (response?.isSuccess) {
      this.documentList = response.result;
      this.documentForm.get('documentTypeId')?.setValue(this.documentList[0].documentTypeId);
    }
  }


  async GetUserDocuments() {
    this.spinner.show();
    let request = {userId : this.generalService.getUniqueuserid()}
    const response = await this.documentservice.GetUserDocuments(request);
    if (response?.isSuccess) {
      this.userDocumentList = response.result.documents;
    }
    this.spinner.hide();
  }

  async onSelectFile(file: any) {
    this.fileToUpload = file.target.files.item(0);
    if (this.fileToUpload?.type.includes('image') && this.fileToUpload!.size > 500000) {
      this.baseUpload64FileData = '';
      this.documentForm.get('documentBase64')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 500kb',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else if (this.fileToUpload?.type.includes('pdf') && this.fileToUpload!.size > 5000000) {
      this.baseUpload64FileData = '';
      this.documentForm.get('documentBase64')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 5MB',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else if (this.fileToUpload?.type.includes('video') && this.fileToUpload!.size > 50000000) {
      this.baseUpload64FileData = '';
      this.documentForm.get('documentBase64')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 50MB',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else {
      let reader = new FileReader();
      reader.onload = async (event: any) => {
        this.baseUpload64FileData = reader.result!.toString();
        this.documentForm.get('documentBase64')?.setValue(this.baseUpload64FileData);
        await this.SetFileType();
      }
      reader.readAsDataURL(this.fileToUpload!);
    }
  }

  async submit() {
    let documentName = this.documentList.filter((x: { documentTypeId: any; }) => x.documentTypeId == this.documentForm.get('documentTypeId')?.value)[0].documentTypeName;
    this.documentForm.get('documentUniqueId')?.setValue(`${documentName.replace(' ', '')}_${uuidv4().replace(/-/g, '')}_${(moment(new Date()).format('MMDDYYYY'))}`)
    this.documentForm.value.documentStatus = 1;
    const response = await this.documentservice.SaveUserDocument(this.documentForm.value);
    if (response?.isSuccess) {
      this.display = "none";
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Document saved successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      this.ngOnInit();
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Document not saved',
        showConfirmButton: false,
        timer: 3000,
      });
    }

  }

  async viewDocument(documentTypeId: number) {
    this.isedit = true;
    debugger;
    let document = this.userDocumentList.filter((x: { documentTypeId: any; }) => x.documentTypeId == documentTypeId)[0];
    if (document.documentTypeId > 0) {
      if(document?.documentBase64?.length > 200){
        this.baseUpload64FileData = document.documentBase64;
      }
      else
      {
        const response = await this.documentservice.DownloadDocument(document.documentUniqueId,"","");
        if (response?.isSuccess) {
          this.baseUpload64FileData = response.result;          
        }
      }
      this.title = 'View document';
      this.documentForm.patchValue(document);
      await this.SetFileType();
      this.display = "block";
    }
  }

  async SetFileType() {
    if (this.baseUpload64FileData.includes(`application/pdf`)) {
      this.format = 'pdf';
    }
    else if (this.baseUpload64FileData.includes(`image`)) {
      this.format = 'image';
    }
    else if (this.baseUpload64FileData.includes(`video`)) {
      this.format = 'video';
    }
  }

  async addDocument() {
    this.title = 'Add Document';
    this.isedit = false;
    this.documentForm.reset();
    this.documentForm.get('documentTypeId')?.setValue(this.documentList[0].documentTypeId);
    this.baseUpload64FileData = '';
    this.format = '';
    this.openModal();
  }
  display = "none";
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }

  addNewDocument() {
    const initialState: any = {list: [{"Page": "AddDocument"}]
    };
    this.modalRef = this.modalService.show(AddDocumentComponent, {
      backdrop: 'static',keyboard: false,animated: true,ignoreBackdropClick: true,class: 'modal-dialog-centered',initialState
    });
  }
}


