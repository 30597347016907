import { Component,Input ,OnInit} from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { InsuranceService } from '../../../Services/insurance.service';


@Component({
  selector: 'app-royal-sundaram-policy',
  templateUrl: './royal-sundaram-policy.component.html',
  styleUrls: ['./royal-sundaram-policy.component.css']
})
export class RoyalSundaramPolicyComponent implements OnInit{
  quoteId: string;
  constructor(private route: ActivatedRoute, private insuranceservice: InsuranceService){
  
  }
  ngOnInit() {
     ;
    this.route.queryParamMap
    .subscribe((params) => {
      this.quoteId = params.get('request') as string;
      this.getPolicyDetails();
    }
  );   
 
  
  }
responseData:any;
async getPolicyDetails() {
  try {
    const response = await this.insuranceservice.GetTransactionHistory(this.quoteId);
    if (response?.isSuccess) {
      this.responseData = response.result.data.policyNumber;
      console.log(response, "Policy Details");
    } else {
      console.error('Error in GetTransactionHistory:', response?.message);
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

}
