export class englishLabel {
    public product: string = 'Products';
    public support: string = 'Support';
    public raise_a_query: string = 'Raise a Query';
    public raise_a_claim: string = 'Raise a Claim';
    public contact_us: string = 'Contact Us';
    public career: string = 'Career';
    public gallery: string = 'Gallery';
    public login: string = 'Login';
    public two_wheeler: string = 'Two-Wheeler';
    public four_wheeler: string = 'Four-Wheeler';
    public health: string = 'Health';
    public commercial_vehicle: string = 'Commercial Vehicle';
    public bike_ins_title: string = 'Get an exclusively low price on your Two-Wheeler Insurance';
    public bike_ins_start: string = 'Two-Wheeler insurance starting at ₹635*';
    public enter_bike_number: string = 'Enter Two-Wheeler Number EX: AB12CD3456';
    public verify: string = 'Verify';
    public dont_have_policy: string = "Don't Remember Vehicle Number?";
    public buy_new_bike: string = 'Bought a new Two-Wheeler?';
    public what_is_ins1: string = 'What is';
    public what_is_ins2: string = 'Insurance?';
    public what_is_ins_title: string = 'Discover Peace of Mind with Insurance';
    public what_is_ins_text: string = "Insurance is like a safety plan for your money. When you get insurance, you pay a certain amount regularly, called a premium. In return, if something bad happens, like your things getting damaged or someone getting sick or passing away, the insurance company helps by giving you money. There are different types of insurance, like auto, health, life, or things you own. It's like having a financial backup when things don't go as planned. ";
    public why_insurance_imp_title: string = 'Securing Tomorrow: Unveiling the Vitality of Insurance';
    public why_insurance_imp_text: string = "It is important for financial protection in uncertain times, aiding individuals and businesses in recovering from accidents, property damage etc.   Health insurance eases the financial burden by covering medical expenses, including hospitalization, surgeries, medications, and preventive care. This assurance of financial protection brings peace of mind, making insurance a crucial component for navigating life's uncertainties. ";
    public claim_assistance1: string = 'Claim';
    public claim_assistance2: string = 'Assistance'
    public claim_assistance_title: string = 'Your Peace of Mind Restored';
    public claim_assistance_text: string = "Click4bima ensures a seamless claims experience with step-by-step guidance, aiding policyholders in navigating the process smoothly. We provide expert support in preparing and submitting required documents, guaranteeing accurate information presentation. With dedicated customer support, Click4bima addresses queries and concerns during the claims process, enhancing the overall experience for our policyholders. ";
    public best_place_ins_title: string = 'Why should you consider us the top choice for purchasing insurance in India?';
    public best_place_ins_text: string = 'Choose Click4bima for insurance – where reliability meets innovation, making us the premier destination for your insurance needs in India. ';
    public why_insurance_imp1: string = 'Why Insurance is';
    public why_insurance_imp2: string = 'Important?'

    public everytime_support_title: string = '24*7 Support';
    public everytime_support_text: string = "At Click4bima, our commitment to 24*7 support ensures that you have constant assistance, providing peace of mind around the clock for all your insurance needs. "


    public best_price_ins_title: string = 'Best Prices Insurance '; 
    public best_price_ins_text: string = "Click4bima offers the best prices on insurance, providing cost-effective solutions without compromising on coverage, and making protection affordable for everyone. ";
    public reliable_title: string = 'Reliable';
    public reliable_text: string = 'At Click4bima, reliability is our cornerstone—ensuring steadfast coverage and dependable service, we stand by you for a secure and trustworthy insurance experience. ';
    public easy_claim_title: string = 'Claim Made Easy';
    public easy_claim_text: string = "Experience hassle-free claims with Click4bima - our 'Claim Made Easy' approach simplifies the process, ensuring quick and efficient settlements for your peace of mind. ";
    public mobile_app_title: string = 'Pay, invest, save your money with a single app';
    public mobile_app_content: string = 'Get control of all your insurance needs anywhere, anytime';

    public mobile_app_compare: string = 'Compare different insurance policies';
    public mobile_app_track: string = 'Track your policy status on the go';
    public mobile_app_download: string = 'Download your policy with a single tap';
    public mobile_app_coming: string = 'Get Ready for Something Amazing - Our Apps Launches Soon!';
    public testimonial_title: string = 'We care about our customer experience too';
    public our_partner_title: string = 'Our Partners';
    public our_partner_text: string = 'We work together to provide you the best services.';
    public need_help_title: string = 'Need Some Help?';
    public need_help_text: string = 'We work together to provide you the best services. At Click4bima portal, we are committed to providing exceptional customer service and assistance at every step of your insurance journey. Let us be your trusted partner in navigating the world of insurance. Contact our support team today and experience the difference firsthand. Your peace of mind is our priority!';
    public mail_us:string='Mail Us';
    public mobile_number:string='Mobile ';
    public whatsapp:string='Whatsapp';
    public about_us:string='About Us';
    public sitemap:string='Sitemap';    
    public faqs:string='FAQs';
    public motor_insurance:string='Motor Insurance';
    public car_insurance :string='Car Insurance';   
    public two_wheeler_insurance :string='Two Wheeler Isurance';
    public taxi_insurance :string='Taxi Insurance';
    public commercial_veh_insurance:string='Commercial Vehicle Insurance';
    public third_party_car_ins:string='Third Party Car Insurance'; 
    public third_party_bike_ins:string='Third Party 2 W Insurance';
    public health_insurance:string='Health Insurance';
    public family_health_ins:string='Family Health Policy';
    public individual_health_ins:string='Individual Health Policy';
    public group_health_ins:string='Group Health Policy';
    public senior_citizen_health_ins:string='Senior Citizen Health Policy'; 
    public critical_illness_ins:string='Critical Illness Policy';
    public arogya_san_ins:string='Arogya Sanjeevni Policy';
    public life_insurance:string='Life Insurance';
    public term_insurance:string='Term Insurance';
    public single_pay_term_ins:string='Single Pay Term Insurance '; 
    public group_term_ins:string='Group Term Insurance';  
    public saving_plans:string='Saving Plans';  
    public tax_saving_ins:string='Tax Saving Policy';  
    public money_back_ins:string='Money Back Policy';  
    public guaranteed_return_ins:string='Guaranteed Return Policy';   
    public ulip:string='ULIP';
    public pension_plan:string='Pension Plan';  
    public child_plan:string='Child Plan';    
    public others:string='Others';    
    public privacy_policy:string='Privacy Policy';  
    public cancellation_refund:string='Cancellation & Refund';  
    public term_condition:string='Terms & Conditions';
    public disclaimer: string = 'Disclaimer';
    public grivance_redressal: string = 'Redressal';
    public srg_add_title: string = 'SRG INSURANCE BROKERS PRIVATE LIMITED';
    public srg_add_detail: string = '2 & 5, Vinayak Complex, Durga Nursery Road, Udaipur, Rajasthan, 313001';
    
    public direct_broker_title: string = 'Direct Broker, License No: DB-290';
    public direct_broker_detail: string = 'Validity Till: 02.02.2026,CIN: U74140RJ2002PTC017656';
    public  payment_method:string='Payment Method';
    public  copyright:string='© Copyright '+new Date().getFullYear()+' Click4bima. All Rights Reserved';   
    public continue:string='Continue';
    public bought_new_bike:string=' Bought a new Two-Wheeler';
    public bought_new_car:string='Bought a new Four-Wheeler?';
    public car_ins_title:string='Get an exclusively low price on your Four-Wheeler Insurance';
    public car_ins_start:string='Four-Wheeler insurance starting at ₹2471*';
    public enter_car_number:string='Enter Four-Wheeler Number EX: AB12CD3456'; 
    public vehicle_detail_form:string='Your vehicle details';
    public city:string='City'; 
    public rto:string="RTO";
    public manufacturer:string="Manufacturer"; 
    public model:string="Model"; 
    public fuel_type:string="Fuel Type";
    public variant:string="Variant"; 
    public reg_year:string="Registration Year"; 
    public get_quotes:string="Get Quotes";  
    public search_city:string="Search City"; 
    public select_city:string="Or Select City"; 
    public select_rto:string='Select City RTO';
    public search_manufacturer:string='Search Manufacturer';
    public select_manufacturer:string='Or Select Manufacturer';  
    public search_model:string='Search Model';
    public select_model:string='Or Select Model';  
    public search_variant:string='Search Variant';   
    public select_variant:string='Or Select Variant';   
    public select_year:string='Select Year';   
    public select_fuel_type:string='Select Fuel Type'; 
    public contact_title:string='Get In Touch With Us';   
    public contact_text:string='We will get back to you as soon as possible.';  
    public full_name:string='Full Name';     
    public mobile:string='Mobile Number';            
    public interested_in:string='Interested In'  ;  
    public email:string='Email Address';
    public description:string='Description';            
    public call_me:string='Call Me Back';
    public continue_fb:string='Continue with Facebook';
    public continue_google:string='Continue with Google';  
    public sign_in:string='Sign In';  
    public password:string='Password';    
    public remember:string='Remember me';   
    public dont_have_acc:string="Don't have an account"; 
    public signup_here:string="Signup Here";     
    public click_here:string="Click Here";       
    public send_otp:string="Send OTP";         
    public back_login:string="Back to Login";       
    public already_hv_acc:string="Already have an account";     
    public signin_here:string="Sign In Here";     
    public verification:string="Verification";   
    public verify_text:string="Enter 6 digit code that sent to your number +918543871911. Click here to change your mobile number.";    
    public enter_otp:string="Enter OTP";   
    public dont_rec_otp:string="If you didn't receive a code! Resend";    
    public submit:string="Submit"; 
    public accept_terms:string="By creating your account, you agree to our Terms & Conditions";    
    public basic_details:string ="Basic Details";      
    public first_name:string ="First Name";     
    public last_name:string ="Last Name";     
    public dob:string ="Date Of Birth";                        
    public referal_code:string ="Referral Code";              
    public gender:string ="Gender";                
    public new_password:string  ="Enter New Password";   
    public confirm_old_pass:string  ="Confirm New Password";      
    public dont_have_policy_popup:string="If you don't remember your two wheeler number, keep your RC (Registration Certificate) ready with you to access the basic details of your two-wheeler.";       
    public mobile_app_buy:string='Buy, store and share all your policies online';
    public about_us_title:string ="We do things differently...";                
    public about_us_text:string ="Situated in the heart of Udaipur city in Rajasthan, SRG Insurance Brokers (P) Ltd. is one of India's best Licensed Insurance Brokers. Our professionals have proven track record in devising and delivering cost effective Insurance and Reinsurance solutions and thus, over the years, we could establish a huge customer base.";                
    public insurance_count:string ="400+";                
    public ins_complete:string ="Insurance Completed";                
    public ins_comp_text:string ="We focus on the details of everything we do. All to help businesses around the world";                
    public found_title:string ="Our Founding Story";                
    public found_text:string ="We focus on the details of everything we do. All to help businesses around the world focus on what's most important to them. We take pride in this. We focus on the details of everything we do. All to help businesses around the world focus on what's most important to them. We take pride in this. We focus on the details of everything we do. All to help businesses around the world focus on what's most important to them. We take pride in this.";                
    public mission_vision_title:string ="Mission, Vision & Value";                
    public mission_vision_text:string ="To be the leader and most trusted insurance service provider. To provide all insurance products at best price, total risk free solutions to delight customers and to dominate indian insurance industry. Service with honesty, respect, professional ethics, commitment & execution.";                
    public mgmt_title:string ="Management";                
    public mgmt_text:string ="Management consists of outstanding professionals in insurance, banking, commerce, industry, finance, taxation and information technology. They have proven track records in various disciplines together with planning, development and execution. We have a vigorous team work supported by modern technology, well-knit IT& communication system. However, in matters of expert advice, we work together with other specialists and professionals and sort out problems.";   
   public year:string='Year';
   public your_details:string='Enter Your Details';
   public name_title:string='Title';
   public comm_ins_title: string = 'Get an exclusively low price on your Commercial Vehicle Insurance';
   public comm_ins_start: string = 'Commercial Vehicle Insurance starting at ₹499*';
   public comm_vcl_number: string = 'Enter your Vehicle Number';
   public viewQuotes: string = 'View Quotes';
   public i_dont_have_policy: string = "I Don't Have Valid Policy";
   public bought_new_vcl: string = 'Bought a new Vehicle?';
}