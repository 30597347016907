<app-navbar></app-navbar>
<div class="container term_use_div">
  <h1>Terms and Conditions Click4bima</h1>

  <div>
    Welcome to the terms and conditions page for Click4bima, an online insurance providing website in India. By using our website, you agree to the terms and conditions outlined below. If you do not agree to these terms, you should not use our website.
  </div>

  <h2>
    Use of Our Website
  </h2>
  <div>
    You may use our website only for lawful purposes and in accordance with these terms and conditions. You agree not to use our website in any way that violates any applicable federal, state, local, or international law or regulation.
  </div>

  <h2>
    Intellectual Property
  </h2>
  <div>
    The content on our website, including text, graphics, images, and logos, is the property of Click4bima and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written consent.

  </div>
  <h2>
    Insurance Policies
  </h2>
  <div>
    The insurance policies offered on our website are subject to the terms and conditions of the insurance provider. Click4bima is not responsible for any claims or disputes arising from the insurance policies purchased through our website.

  </div>
  <h2>
    User Accounts
  </h2>
  <div>
    To purchase insurance policies on our website, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
  </div>

  <h2>
    Payment
  </h2>
  <div>
    All payments for insurance policies purchased through our website must be made using the payment methods accepted on our website. Click4bima is not responsible for any issues that may arise with payment processing.

  </div>
  <h2>
    Limitation of Liability
  </h2>
  <div>
    Click4bima is not responsible for any damages, including direct, indirect, incidental, consequential, or punitive damages, arising from your use of our website or the insurance policies purchased through our website.

  </div>
  <h2>
    Disclaimers
  </h2>
  <div>
    Click4bima makes no warranties or representations about the accuracy, completeness, or reliability of the information on our website or the insurance policies offered through our website. We reserve the right to make changes to our website and the insurance policies offered on our website at any time without notice.

  </div>
  <h2>
    Governing Law
  </h2>
  <div>
    These terms and conditions are governed by the laws of India. Any dispute arising from these terms and conditions or your use of our website shall be resolved in accordance with the laws of India.

  </div>
  <h2>
    Changes to These Terms and Conditions
  </h2>
  <div>
    We reserve the right to modify these terms and conditions at any time without notice. By continuing to use our website after any such modifications, you agree to be bound by the modified terms and conditions.
  </div>

  <h2>
    Contact Us
  </h2>
  <div>
    If you have any questions or concerns about these terms and conditions, please contact us at [insert contact information].

    Thank you for choosing Click4bima as your online insurance provider in India.
  </div>
</div>
<app-footer></app-footer>
