import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/Services/general.service';
import { UserService } from 'src/app/Services/user.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent {
    // Menu Items initialization
    isProducts:boolean = true;
    isloggedIn:boolean = true;
    isloggedOut:boolean = false;
  isUserLogged: boolean = false;
  fullname: any;
  isBike: boolean = true;
  isCar: boolean = false;
  isHealth: boolean = false;
  isHome: boolean = false;
  isLife: boolean = false;
  isTerm: boolean = false;
  isViewQuotes: boolean = false;
  agent: boolean = false;
  sideValue: any;
  @Input()  showMenu:boolean = false;
  goTo(value: string) {
    if (value == 'Bike') {
      this.isBike = true;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
    }
    else if (value == 'Car') {
      this.isBike = false;
      this.isCar = true;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
    }
    else if (value == 'Health') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = true;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
    }
    else if (value == 'Home') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = true;
      this.isLife = false;
      this.isTerm = false;
    }
    else if (value == 'Life') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = true;
      this.isTerm = false;
    }
    else if (value == 'Term') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = true;
    }
  }
constructor(private router: Router, private userservice: UserService, private generalService : GeneralService){

}

ngOnInit() {
  if(this.generalService.getUniqueuserid() != ""){
    this.isUserLogged = true;
    this.getUserDetails();
  }
  
  this.sideValue = localStorage.getItem('agentValue');
  if(this.sideValue == "agent"){
    this.agent = true;
  }
  
}
viewQuotes() {
  this.isViewQuotes = !this.isViewQuotes;
}
async getUserDetails() {
  let request = { uniqueUserId: this.generalService.getUniqueuserid()}
  const response = await this.userservice.AcquireUserInfo(request);
  if (response?.isSuccess) {
    this.fullname = `${response.result.firstName} ${response.result.lastName}`;

  }
}
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  menuItems(value:any) {
    if(value == 'products') {
      this.isProducts = !this.isProducts;
      // this.router.navigate(['/']);
    }
  }
  isHomePage(): boolean {
    return this.router.url === '/';
  }

  logout() { 
    if(!this.isAgent()){
      this.generalService.removeAllSession();
      this.isUserLogged = false;
      this.router.navigate(['/']);
      if(this.router.url =='/' ){
        window.location.href = window.location.href;
      }
    }
    else
    {
      this.generalService.removeAllSession();
      this.isUserLogged = false;
      this.router.navigate(['/agent-homepage']);
      if(this.router.url =='/agent-homepage' ){
        window.location.href = window.location.href;
      }
    }  
  }

  dashboard(){
    if(!this.isAgent()){
      this.router.navigate(['/dashboard']);
    }
    else
    {
      this.router.navigate(['/agent-dashboard']);
    }

  }

  profile(){
    if(!this.isAgent()){
      this.router.navigate(['/profile']);
    }
    else
    {
      this.router.navigate(['/agent-profile']);
    }
  }

  // Sticky Header
  isSticky = false;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    // Add logic here to determine when to make the header sticky
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= 100) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  isAgent():boolean{
    return this.generalService.getIsAgent() == 'true';
  }
}
