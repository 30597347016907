<div class="user-dashboard">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
      type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
    <div class="card-user user-route">
      <div class="row">
        <div class="col">
            <h1>Import Policy</h1>
        </div>
        <div class="col-auto text-right">
            <button class="btn btn-primary-outline btn-outline" (click)="backTolist()">Back to List</button>
        </div>
      </div>
      <div class="card card-body">
<!-- <div class="needs-validation" >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="offcanvasScrollingLabel">Add Policy</h4>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #closebtn></button>
    </div>
    <div class="offcanvas-body min-height-70"> -->
    <form class="form" [formGroup]="insuranceForm">
        <div class="row mb-2"> 
            <div class="col-md-2 mt-3">
                <label class="name">Segment : </label>
                <div class="">                    
                    <span class="d-flex align-items-center justify-content-between">
                      <span class="d-flex align-items-center">
                        <input id="yes" type="radio" class="scale-1_3 ml-1 mr-2 mt-0"  [value]="'Yes'" formControlName="isMotor" (change)="segmentChange()"><span>Motor</span>
                        <input id="no" type="radio" class="scale-1_3 mx-2 mt-0" [value]="'No'" formControlName="isMotor" (change)="segmentChange()"><span>Non-Motor</span>
                      </span>                      
                    </span>
                  </div>
            </div>           
            <div class="col-md-3 mt-3">
                <div class="row">
                    <div class="col-8">
                        <label class="name">Category : </label>
                        <select name="category" id="category" class="form-select form-select-custom"
                            formControlName="vehicleType" (change)="categoryChange()"
                            [ngClass]="{'border border-danger': ((insuranceFormControl['vehicleType'].touched) && insuranceFormControl['vehicleType'].errors?.['required'])}">
                            <option value="" selected>Select Category</option>
                            <option *ngFor="let cat of policyCategory" [value]="cat.id">
                                {{cat.name}}
                            </option>
                        </select>
                        <Span class="text-danger"
                            *ngIf="((insuranceFormControl['vehicleType'].touched) && !insuranceFormControl['vehicleType'].valid)">
                            Category is required.
                        </Span>
                    </div>
                    <div class="col-md-4 text-right">
                        <img src="../../assets/svgIcon/policyvehicle/{{insuranceFormControl['vehicleType'].value}}.svg" class="img-fluid h-100" [ngClass]="{'d-none' : insuranceFormControl['vehicleType'].value == ''}"/>
                    </div>
                </div>                
            </div>
            <div class="col-md-3 mt-3">
                <label for="planName" class=" required">{{insuranceFormControl['isMotor'].value == 'Yes' ? 'Policy Type' : 'Plan Name'}}
                </label>
                <select name="planName" formControlName="planName" *ngIf="insuranceFormControl['isMotor'].value == 'Yes'"
                    placeholder="Policy Type" (change)="typeofCoverChange($event)"
                    class="form-select form-select-custom"
                    [ngClass]="{'border border-danger': ((insuranceFormControl['planName'].touched ) && insuranceFormControl['planName'].errors?.['required'])}">
                    <option *ngFor="let polType of newPolicyTypeList" [value]="polType.value">
                        {{polType.text}}
                    </option>
                </select>
                <input type="text" class="form-control" name="planName" formControlName="planName"
                    placeholder="Plan Name" *ngIf="insuranceFormControl['isMotor'].value == 'No'"
                    [ngClass]="{'border border-danger': ((insuranceFormControl['planName'].touched ) && insuranceFormControl['planName'].errors?.['required'])}">
                <Span class="text-danger" *ngIf="((insuranceFormControl['planName'].touched ) && insuranceFormControl['planName'].errors?.['required'])">
                    {{insuranceFormControl['isMotor'].value ? 'Policy type' : 'Plan name'}} is required.
                </Span>
            </div>
            <!-- <div class="col-md-6 mt-3">
                <label class="name">Quote Id : </label>
                <input type="text" class="form-control" name="quoteId"
                formControlName="quoteId" placeholder="Quote Id"
                [ngClass]="{'border border-danger': ((insuranceFormControl['quoteId'].touched) && insuranceFormControl['quoteId'].errors?.['required'])}">
                <Span class="text-danger"
                    *ngIf="((insuranceFormControl['vehicleType'].touched) && !insuranceFormControl['vehicleType'].valid)">
                    Quote id is required.
                </Span>
            </div> -->
            <div class="col-md-2 mt-3">
                <label for="agentId" class=" required">Agent </label>
                <app-autocomplete class="w-100" (itemSelected)="onAgentSelection($event)"
                [items]="agentList" placeholder="Search and select agent"
                [userInput]="agentName"
                [showError]="((insuranceFormControl['agentId'].touched) && insuranceFormControl['agentId'].errors?.['required'])"></app-autocomplete>
                <span class="text-danger" *ngIf="((insuranceFormControl['agentId'].touched) && insuranceFormControl['agentId'].errors?.['required'])">
                    Agent is required.</span>
            </div>
            <div class="col-md-2 mt-3">
                <label for="channel" class=" required">Channel </label>
                <input type="text" class="form-control" name="channel" formControlName="channel"
                    placeholder="Channel"
                    [ngClass]="{'border border-danger': ((insuranceFormControl['channel'].touched ) && insuranceFormControl['channel'].errors?.['required'])}">
                <span class="text-danger" *ngIf="((insuranceFormControl['channel'].touched) && insuranceFormControl['channel'].errors?.['required'])">
                    Channel is required.</span>
            </div>
            <!-- <div class="col-md-4 mt-3">
                <label for="agentId" class=" required">Agent </label>
                <app-autocomplete class="w-100" (itemSelected)="onAgentSelection($event)"
                [items]="agentList" placeholder="Search and select agent"
                [userInput]="agentName"
                [showError]="((insuranceFormControl['agentId'].touched) && insuranceFormControl['agentId'].errors?.['required'])"></app-autocomplete>
                <span class="text-danger" *ngIf="((insuranceFormControl['agentId'].touched) && insuranceFormControl['agentId'].errors?.['required'])">
                    Agent is required.</span>
            </div> -->
        </div>
    </form>
    <ng-container >
        <!-- *ngIf="!insuranceFormControl['vehicleType'].errors?.['required'] && !insuranceFormControl['quoteId'].errors?.['required'] && !insuranceFormControl['agentId'].errors?.['required']">                    -->
        <!-- <div class="row mt-3">
            <div class="col-12">
            <ul class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center">
                <li class="list-item col" [ngClass]="{'active': detail == 'owner'}" (click)="changeTab('owner')">
                    Owner Details 
                </li>
                <li class="list-item  col" [ngClass]="{'active': detail == 'address'}" (click)="changeTab('address')">
                    Address Details
                </li>
                <li class="list-item  col" [ngClass]="{'active': detail == 'nominee'}" (click)="changeTab('nominee')">
                    Nominee Details
                </li>                
                <li class="list-item  col" [ngClass]="{'active': detail == 'policy'}" (click)="changeTab('policy')">
                    Policy Details
                </li>
                <li class="list-item  col" [ngClass]="{'active': detail == 'vehicle'}" (click)="changeTab('vehicle')" *ngIf="insuranceFormControl['isMotor'].value">
                    Vehicle Details
                </li>
            </ul>
            </div>
        </div> -->
        <div class="clearfix mb-5">
            <!-- tab-content  -->
            <div id="ownerdetails" class="tab-pane  col-md-12 c-pl c-pr">
                <!-- [ngClass]="{'active': detail == 'owner'}"> -->
            <ng-container >
                <div class="card pb-5 px-0 row br-top-0 my-1">
                        <div class="details_card">
                            <form id="kt_account_profile_details_form" class="form" [formGroup]="userForm"
                                novalidate="novalidate">
                                <div>
                                    <div id="ownerdetails1">
                                        <div class="row">
                                            <div class="h5 border-bottom py-3 border-top-radius bg-color-1 mb-0"> Proposer Details</div>
                                            <div class="col-md-6">
                                                <div class="justify-content-between row">
                                                    <!-- <div class="mt-3 col-md-6">
                                                        <label for="name" class="required">PAN Card</label>                                                            
                                                        <div class="d-flex">
                                                            <input type="text" class="form-control rounded-right1-0" name="pancard"
                                                            formControlName="panNumber" placeholder="Pan card" minlength="10"
                                                            maxlength="10" (keypress)="alphaNumericOnly($event)" oninput="this.value = this.value.toUpperCase()"
                                                            [ngClass]="{'border border-danger': ((userFormControl['panNumber'].touched  || submitted) && !userFormControl['panNumber'].valid)}">
                                                            <button  disabled="" class="btn btn-primary py-0 px-2 rounded-left1-0" [disabled]="!userFormControl['panNumber'].valid">
                                                                <img  src="../../assets/svgIcon/fetch.svg" alt="Fetch" class="icon-white" title="Fetch Details">
                                                            </button>
                                                        </div>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['panNumber'].touched  || submitted) && userFormControl['panNumber'].errors?.['required'])">
                                                            PAN number is required.
                                                        </Span>
                                                        <Span class="text-danger"
                                                            *ngIf="((userFormControl['panNumber'].touched  || submitted) && userFormControl['panNumber'].errors?.['pattern'])">
                                                            Provide valid PAN number.
                                                        </Span>
                                                    </div>

                                                <div class="mt-3 col-md-6">
                                                    <label class="required" for="dob">Date Of Birth</label>
                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                        formControlName="dateOfBirth" autocomplete="off" 
                                                        class="new-user form-control bg-transparent"
                                                        [ngClass]="{'border border-danger': ((userFormControl['dateOfBirth'].touched  || submitted) && !userFormControl['dateOfBirth'].valid)}" />
                                                    <Span class="text-danger"
                                                        *ngIf="((userFormControl['dateOfBirth'].touched  || submitted) && !userFormControl['dateOfBirth'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div> -->
                                                <div class="col-md-3 mt-3">
                                                    <label for="title" class="required">Title</label>
                                                    <select name="title" id="title" class="form-select form-select-custom"
                                                        formControlName="title">
                                                        <option *ngFor="let title of titleList" [value]="title">
                                                            {{title}}
                                                        </option>
                                                    </select>                
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="row"> 
                                                        <div class="mt-3" [ngClass]="{'col-md-6':this.userFormControl['title'].value != 'M/s','col-md-12':this.userFormControl['title'].value == 'M/s'}">
                                                            <label for="name" class="required">{{this.userFormControl['title'].value == 'M/s' ? 'Company Name' :'First Name'}}</label>
                                                            <input type="text" class="form-control" name="firstName"
                                                                (keypress)="alphabetOnly($event)" formControlName="firstName"
                                                                placeholder="{{this.userFormControl['title'].value == 'M/s' ? 'Company Name' :'First Name'}}"
                                                                [ngClass]="{'border border-danger': ((userFormControl['firstName'].touched  || submitted) && !userFormControl['firstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((userFormControl['firstName'].touched  || submitted) && !userFormControl['firstName'].valid)">
                                                                {{this.userFormControl['title'].value == 'M/s' ? 'Company name' :'First name'}} is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3" [ngClass]="{'col-md-6':this.userFormControl['title'].value != 'M/s','d-none':this.userFormControl['title'].value == 'M/s'}">
                                                            <label for="name" class="">Last Name</label>
                                                            <input type="text" class="form-control" name="lastName"
                                                                (keypress)="alphabetOnly($event)" formControlName="lastName"
                                                                placeholder="Last name"
                                                                [ngClass]="{'border border-danger': ((userFormControl['lastName'].touched  || submitted) && userFormControl['lastName'].errors?.['required'])}">
                                                            <Span class="text-danger"
                                                                *ngIf="((userFormControl['lastName'].touched  || submitted) && !userFormControl['lastName'].valid)">
                                                                Last name is required.
                                                            </Span>
                                                        </div>                                                                               
                                                        <!-- <div class="mt-3 col-md-6">
                                                            <label for="name" class="required">First Name</label>
                                                            <input type="text" class="form-control" name="firstName"
                                                                (keypress)="alphabetOnly($event)" formControlName="firstName"
                                                                placeholder="First Name"
                                                                [ngClass]="{'border border-danger': ((userFormControl['firstName'].touched  || submitted) && !userFormControl['firstName'].valid)}">
                                                            <Span class="text-danger"
                                                                *ngIf="((userFormControl['firstName'].touched  || submitted) && !userFormControl['firstName'].valid)">
                                                                First Name is required.
                                                            </Span>
                                                        </div>
                                                        <div class="mt-3 col-md-6">
                                                            <label for="name" class="required">Last Name</label>
                                                            <input type="text" class="form-control" name="lastName"
                                                                (keypress)="alphabetOnly($event)" formControlName="lastName"
                                                                placeholder="Last name"
                                                                [ngClass]="{'border border-danger': ((userFormControl['lastName'].touched  || submitted) && userFormControl['lastName'].errors?.['required'])}">
                                                            <Span class="text-danger"
                                                                *ngIf="((userFormControl['lastName'].touched  || submitted) && !userFormControl['lastName'].valid)">
                                                                Last name is required.
                                                            </Span>
                                                        </div> -->
                                                    </div>                
                                                </div>                                                  
                                                </div> 
                                            </div>    
                                            <div class="mt-3" [ngClass]="{'d-none':this.userFormControl['title'].value == 'M/s','col-md-2':this.userFormControl['title'].value != 'M/s'}">
                                                <label for="name" class="required">Gender</label>
                                                <select name="gender" id="gender" class="form-select form-select-custom"
                                                formControlName="gender">
                                                    <option *ngFor="let gender of genderList" [value]="gender">
                                                        {{gender}}
                                                    </option>
                                                </select>    
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['gender'].touched  || submitted) && userFormControl['gender'].errors?.['required'])">
                                                    Gender is required.
                                                </Span>
                                            </div> 
                                            <div class="mt-3 col-md-2 " [ngClass]="{'col-md-3':this.userFormControl['title'].value == 'M/s','col-md-2':this.userFormControl['title'].value != 'M/s'}">
                                                <label for="name" class="required">Email Address</label>
                                                <input type="text" class="form-control" name="email" formControlName="email"
                                                    placeholder="User email address"
                                                    [ngClass]="{'border border-danger': ((userFormControl['email'].touched  || submitted) && !userFormControl['email'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['email'].touched  || submitted) && userFormControl['email'].errors?.['required'])">
                                                    Email address is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['email'].touched  || submitted) && userFormControl['email'].errors?.['pattern'])">
                                                    Please provide valid email address.
                                                </Span>
                                            </div>
                                            <div class="mt-3" [ngClass]="{'col-md-3':this.userFormControl['title'].value == 'M/s','col-md-2':this.userFormControl['title'].value != 'M/s'}">
                                                <label for="name" class="required">Mobile Number</label>
                                                <input type="text" class="form-control" name="mobile"
                                                    formControlName="phoneNumber" placeholder="User mobile number"
                                                    minlength="10" maxlength="10" (keypress)="numberOnly($event)"
                                                    [ngClass]="{'border border-danger': ((userFormControl['phoneNumber'].touched  || submitted) && !userFormControl['phoneNumber'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['phoneNumber'].touched  || submitted) && userFormControl['phoneNumber'].errors?.['required'])">
                                                    Mobile number is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['phoneNumber'].touched  || submitted) && userFormControl['phoneNumber'].errors?.['pattern'])">
                                                    Provide valid Mobile number.
                                                </Span>
                                            </div>                                                            
                                        </div>
                                    </div>                                                        
                                </div>
                            </form>
                        </div>                                   
            </div>
            <!-- <div class="row">
                <div class="col-md-12 mt-5  text-end">
                    <button class="btn btn-primary" (click)="Details('address')">Next</button>
                </div>
            </div> -->
            </ng-container>
            </div>
            <div id="address" class="tab-pane col-md-12 c-pl c-pr">
                 <!-- [ngClass]="{'active': detail == 'address'}"> -->
            <ng-container >
                <div class="card pb-5 row br-top-0 my-1">
                        <div class="details_card">
                            <form id="kt_account_profile_details_form" class="form" [formGroup]="userForm"
                                novalidate="novalidate">
                                <div class="justify-content-between row">
                                    <div class="h5 border-bottom py-3 border-top-radius bg-color-2 mb-0">Address Details</div>
                                    <div class="mt-3 col-md-2">
                                        <label class="required" for="flatNo">Street Number</label>
                                        <input type="text" class="form-control" name="flatNo" formControlName="flatNo"
                                            placeholder="Flat Number/Street Number"
                                            [ngClass]="{'border border-danger': ((userFormControl['flatNo'].touched  || submittedAddress) && userFormControl['flatNo'].errors?.['required'])}">
                                        <Span class="text-danger"
                                            *ngIf="((userFormControl['flatNo'].touched  || submittedAddress) && userFormControl['flatNo'].errors?.['required'])">
                                            Flat number/Street number is required.
                                        </Span>
                                    </div>
                                    <div class="mt-3 col-md-4">
                                        <label class="required" for="streetAddress">Street Address</label>
                                        <input type="text" class="form-control" name="streetAddress"
                                            formControlName="streetAddress" placeholder="Street Address"
                                            [ngClass]="{'border border-danger': ((userFormControl['streetAddress'].touched  || submittedAddress) && !userFormControl['streetAddress'].valid)}">
                                        <Span class="text-danger"
                                            *ngIf="((userFormControl['streetAddress'].touched  || submittedAddress) && userFormControl['streetAddress'].errors?.['required'])">
                                            Street address is required.
                                        </Span>
                                    </div>
                                    <div class="mt-3 col-md-2">
                                        <label for="name">District/City</label>
                                        <app-autocomplete class="w-100" [items]="districtList" placeholder="District/City"
                                            (itemSelected)="onDistrictSelection($event)"
                                            (onBlurEvent)="onDistrictBlur($event)" oninput="this.value = this.value.toUpperCase()"
                                            [userInput]="userFormControl['city'].value" (onKeyUpEvent)="onDistrictChange($event);" 
                                            [showError]="((userFormControl['city'].touched  || submittedAddress) && !userFormControl['city'].valid)"></app-autocomplete>
                                        <Span class="text-danger"
                                            *ngIf="((userFormControl['city'].touched  || submittedAddress) && userFormControl['city'].errors?.['required'])">
                                            District/City is required.
                                        </Span>
                                    </div>
                                    <div class="mt-3 col-md-2">
                                        <label class="required" for="name">Pincode</label>
                                        <app-autocomplete class="w-100" (itemSelected)="onPincodeSelection($event)"
                                            [items]="pincodeList" placeholder="Pincode"
                                            [userInput]="userFormControl['pincode'].value" (onBlurEvent)="onPincodeBlur($event)"
                                            [showError]="((userFormControl['pincode'].touched  || submittedAddress) && !userFormControl['pincode'].valid)"
                                             ></app-autocomplete>
                                        <Span class="text-danger"
                                            *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['required'])">
                                            Pincode is required.
                                        </Span>
                                        <Span class="text-danger"
                                            *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['pattern'])">
                                            Provide valid pincode.
                                        </Span>
                                    </div>
                                    <div class="mt-3 col-md-2">
                                        <label class="required" for="State">State</label>
                                        <app-autocomplete class="w-100" [items]="stateList" placeholder="State"
                                            (itemSelected)="onStateSelection($event)"
                                            (onBlurEvent)="onStateBlur($event)"
                                            [userInput]="userFormControl['state'].value"
                                            [showError]="((userFormControl['state'].touched  || submittedAddress) && !userFormControl['state'].valid)"></app-autocomplete>
                                        <Span class="text-danger"
                                            *ngIf="((userFormControl['state'].touched  || submittedAddress) && userFormControl['state'].errors?.['required'])">
                                            State is required.
                                        </Span>
                                    </div>
                                    
                                    <!-- <div class="mt-3 col-md-6">
                                        <label class="required" for="name">City/Talluka/Village</label>
                                        <app-autocomplete class="w-100" (itemSelected)="onCitySelection($event)"
                                            [items]="cityList" placeholder="City/Talluka/Village"
                                            [supportingText]="true"
                                            [userInput]="userFormControl['city'].value"
                                            [showError]="((userFormControl['city'].touched  || submittedAddress) && !userFormControl['city'].valid)"
                                            (onBlurEvent)="onCityBlur($event)"></app-autocomplete>
                                        <Span class="text-danger"
                                            *ngIf="((userFormControl['city'].touched  || submittedAddress) && userFormControl['city'].errors?.['required'])">
                                            City/Talluka/Village is required.
                                        </Span>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                        
                    <!-- </div> -->
            </div>
            <!-- <div class="row">
                <div class="col-md-6 mt-5  text-start">
                    <button class="btn btn-primary-outline btn-outline" (click)="Details('owner')">Back</button>
                </div>
                <div class="col-md-6 mt-5  text-end">
                    <button class="btn btn-primary" (click)="Details('nominee')" >Next</button> -->
                    <!-- <button class="btn btn-primary" (click)="Details('vehicle')" *ngIf="showNomineeForm == false">Next</button> -->
                <!-- </div>
            </div> -->
            </ng-container>
        </div>  
        <div id="vehicle" class="tab-pane col-md-12 c-pl c-pr" *ngIf="insuranceFormControl['isMotor'].value == 'Yes'">
            <!-- [ngClass]="{'active': detail == 'vehicle'}" -->
            <ng-container >
            <div class="card pb-5 row br-top-0 my-1">
                <form id="kt_vehicle_details_form" class="form" [formGroup]="vehicleForm"
                novalidate="novalidate">
                <div class="justify-content-between row">
                    <div class="col-12">
                        <div class="row">
                            <div class="h5 border-bottom py-3 border-top-radius bg-color-3 mb-0"> Vehicle Details</div>
                            <div class="col-md-3 mt-3">
                                <label for="registrationNumber" class=" required">Registration Number </label>
                                    <input type="text" name="registrationNumber"
                                    formControlName="registrationNumber" placeholder="Registration number"
                                    maxlength="10" minlength="7" class="form-control" oninput="this.value = this.value.toUpperCase()"
                                    [ngClass]="{'border border-danger': ((vehicleFormControl['registrationNumber'].touched  || submittedVehicle) && !vehicleFormControl['registrationNumber'].valid)}">                                            
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['registrationNumber'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber'].errors?.['required'])">
                                    Registration number is required.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['registrationNumber'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber'].errors?.['pattern'])">
                                    Please provide valid Registration number.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['registrationNumber'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber'].errors?.['minlength'])">
                                    Registration number minimum length is 7 char.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['registrationNumber'].touched  || submittedVehicle) && vehicleFormControl['registrationNumber'].errors?.['maxlength'])">
                                    Registration number maximum length is 10 char.
                                </Span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label for="make" class=" required">Manufacturer </label>
                                <app-autocomplete class="w-100" (itemSelected)="onMakeSelection($event)" [items]="vehicleMakeList"
                                placeholder="Make" (onBlurEvent)="onMakeBlur($event)"
                                [userInput]="vehicleFormControl['make'].value"
                                [showError]="vehicleFormControl['make'].invalid && submittedVehicle"></app-autocomplete>
                                <span class="text-danger"
                                *ngIf="vehicleFormControl['make'].invalid && submittedVehicle">Manufacturer is required.</span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label for="model" class=" required">Model </label>
                                <app-autocomplete class="w-100" (itemSelected)="onModelSelection($event)"
                                [items]="vehicleModelList" placeholder="Model" (onBlurEvent)="onModelBlur($event)"
                                [userInput]="vehicleFormControl['model'].value"
                                [showError]="vehicleFormControl['model'].invalid && submittedVehicle"></app-autocomplete>
                                <span class="text-danger" *ngIf="vehicleFormControl['model'].invalid && submittedVehicle">Model is
                                required.</span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label for="variant" class=" required">Variant</label>
                                <app-autocomplete class="w-100" (itemSelected)="onVariantSelection($event)"
                                [userInput]="vehicleFormControl['variant'].value"
                                [items]="vehicleVariantList" placeholder="Variant" (onBlurEvent)="onVariantBlur($event)"
                                [showError]="vehicleFormControl['variant'].invalid && submittedVehicle"></app-autocomplete>
                                <span class="text-danger" *ngIf="vehicleFormControl['variant'].invalid && submittedVehicle">Variant
                                is required.</span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label for="fuelType" class=" required">Fuel Type</label>
                                <select class="form-select form-select-custom" formControlName="fuelType">
                                <option *ngFor="let item of fuelType; let i = index;" value="{{item}}">{{item}}</option>
                                </select>
                            </div>  
                            <div class="mt-3 col-md-3">
                                <label for="name" class="required">Cubic Capacity</label>
                                <input type="text" class="form-control" name="engineCapacityAmount"
                                    formControlName="engineCapacityAmount" placeholder="Cubic Capacity"
                                    minlength="2" (keypress)="numberOnly($event)"
                                    [ngClass]="{'border border-danger': ((vehicleFormControl['engineCapacityAmount'].touched  || submitted) && !vehicleFormControl['engineCapacityAmount'].valid)}">
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['engineCapacityAmount'].touched  || submitted) && vehicleFormControl['engineCapacityAmount'].errors?.['required'])">
                                    Cubic capacity is required.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['engineCapacityAmount'].touched  || submitted) && vehicleFormControl['engineCapacityAmount'].errors?.['pattern'])">
                                    Provide valid cubic capacity.
                                </Span>
                            </div>  
                            <div class="col-md-3 mt-3" *ngIf="this.insuranceFormControl['vehicleType'].value == 3">
                                <label for="category" class=" required">Vehicle Category</label>
                                <select class="form-select form-select-custom" formControlName="category">
                                <option *ngFor="let item of vehicleCategory; let i = index;" value="{{item}}">{{item}}</option>
                                </select>
                            </div>   
                            <div class="col-md-3 mt-3">
                                <label for="engineNumber" class=" required">Engine Number </label>
                                <input type="text" name="engineNumber" formControlName="engineNumber" minlength="6"
                                    placeholder="Engine number " class="form-control" oninput="this.value = this.value.toUpperCase()"
                                    [ngClass]="{'border border-danger': ((vehicleFormControl['engineNumber'].touched  || submittedVehicle) && !vehicleFormControl['engineNumber'].valid)}" (keypress)="alphaNumericOnly($event)">
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['engineNumber'].touched  || submittedVehicle) && vehicleFormControl['engineNumber'].errors?.['required'])">
                                    Engine number is required.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['engineNumber'].touched  || submittedVehicle) && vehicleFormControl['engineNumber'].errors?.['minlength'])">
                                    Engine number minimum length is 6 characters.
                                </Span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label for="ChassisNumber" class=" required">Chassis Number </label>
                                <input type="text" name="ChassisNumber" formControlName="chasisNumber" minlength="6"
                                    placeholder="Chassis number " class="form-control" oninput="this.value = this.value.toUpperCase()"
                                    [ngClass]="{'border border-danger': ((vehicleFormControl['chasisNumber'].touched  || submittedVehicle) && !vehicleFormControl['chasisNumber'].valid)}"  onkeypress="if(this.value.length=='17') return false"  (keypress)="alphaNumericOnly($event)">
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['chasisNumber'].touched  || submittedVehicle) && vehicleFormControl['chasisNumber'].errors?.['required'])">
                                    Chassis number is required.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['chasisNumber'].touched  || submittedVehicle) && vehicleFormControl['chasisNumber'].errors?.['minlength'])">
                                    Chassis number minimum length is 6 characters.
                                </Span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label for="VehicleRegistrationDate" class="required">Vehicle Registration
                                    Date </label>
                                <input type="date" name="VehicleRegistrationDate" onkeydown="return false" 
                                    formControlName="registrationDate"
                                    placeholder="Vehicle Registration Date " class="form-control"
                                    [ngClass]="{'border border-danger': ((vehicleFormControl['registrationDate'].touched  || submittedVehicle) && !vehicleFormControl['registrationDate'].valid)}">
                                <Span class="text-danger"
                                    *ngIf="((vehicleFormControl['registrationDate'].touched  || submittedVehicle) && vehicleFormControl['registrationDate'].errors?.['required'])">
                                    Vehicle Registration Date is required
                                </Span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </div>
            <!-- <div class="row">
                <div class="col-md-6 mt-5  text-start">
                    <button class="btn btn-primary-outline btn-outline" (click)="Details('policy')">Back</button>
                </div>
                <div class="col-md-6 mt-5  text-end" *ngIf="insuranceFormControl['isMotor'].value">
                    <button class="btn btn-primary" (click)="continue()">Continue</button>
                </div>
            </div> -->
            </ng-container>
        </div>    
        <div id="policy" class="tab-pane col-lg-12 c-pl c-pr">
            <!-- [ngClass]="{'active': detail == 'policy'}" -->
            <ng-container >
            <div class="card pb-5 row br-top-0 my-1">         
                <form id="kt_vehicle_details_form" class="form pb-4" [formGroup]="priceBreakupForm"
                    novalidate="novalidate">
                    <div class="justify-content-between row">
                        <div class="col-12">
                            <div class="row">
                                <div class="h5 border-bottom py-3 border-top-radius bg-color-4 mb-0"> Policy Details</div>
                                <div class="col-md-3 mt-3">
                                    <label for="insuranceCompany" class=" required">Policy Company
                                    </label>
                                    <app-autocomplete class="w-100" [items]="insurancePartnersList" placeholder="Type and search policy company"
                                        (itemSelected)="onInsCompSelection($event)"
                                        [userInput]="insuranceCompanyStr" 
                                        [showError]="((priceBreakupFormControl['insuranceCompany'].touched  || submittedPolicy) && priceBreakupFormControl['insuranceCompany'].errors?.['required'])"></app-autocomplete>
                                    <Span class="text-danger"
                                    *ngIf="((priceBreakupFormControl['insuranceCompany'].touched  || submittedPolicy) && priceBreakupFormControl['insuranceCompany'].errors?.['required'])">
                                        policy company is required.
                                    </Span>
                                </div>                                
                                <div class="col-md-3 mt-3">
                                    <label for="policyStartDate" class="required">Policy Start Date </label>
                                    <input type="date" name="policyStartDate"
                                        formControlName="policyStartDate"
                                        placeholder="Policy Start Date" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['policyStartDate'].touched  || submittedPolicy) && !priceBreakupFormControl['policyStartDate'].valid)}"
                                         onkeydown="return false">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['policyStartDate'].touched  || submittedPolicy) && priceBreakupFormControl['policyStartDate'].errors?.['required'])">
                                        Policy start date is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="policyEndDate" class="required">Policy End Date </label>
                                    <input type="date" name="policyEndDate"
                                        formControlName="policyEndDate"
                                        placeholder="Policy End Date" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['policyEndDate'].touched  || submittedPolicy) && !priceBreakupFormControl['policyEndDate'].valid)}"
                                         onkeydown="return false">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['policyEndDate'].touched  || submittedPolicy) && priceBreakupFormControl['policyEndDate'].errors?.['required'])">
                                        Policy end date is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="policyNumber" class=" required">Policy Number</label>
                                    <input type="text" name="policyNumber"
                                        formControlName="policyNumber"
                                        placeholder="Policy Number" class="form-control" oninput="this.value = this.value.toUpperCase()"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['policyNumber'].touched  || submittedPolicy) && !priceBreakupFormControl['policyNumber'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['policyNumber'].touched  || submittedPolicy) && priceBreakupFormControl['policyNumber'].errors?.['required'])">
                                        policy number is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3" *ngIf="insuranceFormControl['isMotor'].value == 'Yes' && this.vehicleForm?.get('typeOfCover')?.value != 'Thirdparty'">
                                    <label for="addOnValue" class="">Add On Amount</label>
                                    <input type="text" name="addOnValue" (keypress)="numberOnly($event)"
                                        formControlName="addOnValue"
                                        placeholder="Add On Amount" class="form-control">
                                </div>
                                <!-- <div class="col-md-3 mt-3" *ngIf="insuranceFormControl['isMotor'].value">
                                    <label for="basicOwnDamagePremium" class=" required">Basic OD</label>
                                    <input type="text" name="basicOwnDamagePremium" (keypress)="numberOnly($event)"
                                        formControlName="basicOwnDamagePremium"
                                        placeholder="Basic OD" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['basicOwnDamagePremium'].touched  || submittedPolicy) && !priceBreakupFormControl['basicOwnDamagePremium'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['basicOwnDamagePremium'].touched  || submittedPolicy) && priceBreakupFormControl['basicOwnDamagePremium'].errors?.['required'])">
                                        Basic OD is required.
                                    </Span>
                                </div> 
                                <div class="col-md-3 mt-3" *ngIf="insuranceFormControl['isMotor'].value">
                                    <label for="thirdPartyCoverPremium" class=" required">Basic TP</label>
                                    <input type="text" name="thirdPartyCoverPremium" (keypress)="numberOnly($event)"
                                        formControlName="thirdPartyCoverPremium"
                                        placeholder="Basic TP" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['thirdPartyCoverPremium'].touched  || submittedPolicy) && !priceBreakupFormControl['thirdPartyCoverPremium'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['thirdPartyCoverPremium'].touched  || submittedPolicy) && priceBreakupFormControl['thirdPartyCoverPremium'].errors?.['required'])">
                                        Basic TP is required.
                                    </Span>
                                </div> -->
                                <div class="col-md-3 mt-3" *ngIf="this.vehicleForm?.get('typeOfCover')?.value != 'Standalone' && this.insuranceFormControl['isMotor']?.value == 'Yes'">
                                    <label class="name">Is PA Cover Opted : </label>
                                    <div class="">                    
                                        <span class="d-flex align-items-center justify-content-between">
                                          <span class="d-flex align-items-center">
                                            <input id="yes" type="radio" class="scale-1_3 ml-1 mr-2 mt-0"  [value]="'Yes'" formControlName="isPACover" (change)="paCoverChange()"><span>Yes</span>
                                            <input id="no" type="radio" class="scale-1_3 mx-2 mt-0" [value]="'No'" formControlName="isPACover" (change)="paCoverChange()"><span>No</span>
                                          </span>                      
                                        </span>
                                      </div>
                                </div> 
                                <div class="col-md-3 mt-3" *ngIf="priceBreakupFormControl['isPACover'].value == 'Yes'">
                                    <label for="paCover" class=" required">PA Cover</label>
                                    <input type="text" name="paCover" (keypress)="numberOnly($event)"
                                        formControlName="paCover" 
                                        placeholder="PA Cover" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['paCover'].touched  || submittedPolicy) && !priceBreakupFormControl['paCover'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['paCover'].touched  || submittedPolicy) && priceBreakupFormControl['paCover'].errors?.['required'])">
                                        PA Cover is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3" *ngIf="insuranceFormControl['isMotor'].value == 'Yes' && this.vehicleForm?.get('typeOfCover')?.value != 'Thirdparty'">
                                    <label for="net_OD" class=" required">Net OD</label>
                                    <input type="text" name="net_OD" (keypress)="numberOnly($event)"
                                        formControlName="net_OD"  (keyup)="keyUp('net_od')"
                                        placeholder="Net OD" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['net_OD'].touched  || submittedPolicy) && !priceBreakupFormControl['net_OD'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['net_OD'].touched  || submittedPolicy) && priceBreakupFormControl['net_OD'].errors?.['required'])">
                                        Net OD is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3" *ngIf="insuranceFormControl['isMotor'].value == 'Yes' && this.vehicleForm?.get('typeOfCover')?.value != 'Standalone'">
                                    <label for="net_TP" class=" required">Net TP</label>
                                    <input type="text" name="net_TP" (keypress)="numberOnly($event)" (keyup)="keyUp('net_tp')"
                                        formControlName="net_TP"
                                        placeholder="Net TP" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['net_TP'].touched  || submittedPolicy) && !priceBreakupFormControl['net_TP'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['net_TP'].touched  || submittedPolicy) && priceBreakupFormControl['net_TP'].errors?.['required'])">
                                        Net TP is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="netPremium" class=" required">Net Premium</label>
                                    <input type="text" name="netPremium" (keypress)="numberOnly($event)" (keyup)="keyUp('net_premium')"
                                        formControlName="netPremium" 
                                        placeholder="Net Premium" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['netPremium'].touched  || submittedPolicy) && !priceBreakupFormControl['netPremium'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['netPremium'].touched  || submittedPolicy) && priceBreakupFormControl['netPremium'].errors?.['required'])">
                                        Net Premium is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="serviceTaxValue" class=" required">GST</label>
                                    <input type="text" name="serviceTaxValue" (keypress)="numberOnly($event)"
                                        formControlName="serviceTaxValue" (keyup)="keyUp('gst')"
                                        placeholder="GST" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['serviceTaxValue'].touched  || submittedPolicy) && !priceBreakupFormControl['serviceTaxValue'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['serviceTaxValue'].touched  || submittedPolicy) && priceBreakupFormControl['serviceTaxValue'].errors?.['required'])">
                                        GST is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="finalPremium" class=" required">Final Premium</label>
                                    <input type="text" name="finalPremium" (keypress)="numberOnly($event)"
                                        formControlName="finalPremium" 
                                        placeholder="Final Premium" class="form-control"
                                        [ngClass]="{'border border-danger': ((priceBreakupFormControl['finalPremium'].touched  || submittedPolicy) && !priceBreakupFormControl['finalPremium'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((priceBreakupFormControl['finalPremium'].touched  || submittedPolicy) && priceBreakupFormControl['finalPremium'].errors?.['required'])">
                                        Final Premium is required.
                                    </Span>
                                </div>
                                <div class="mt-3 col-md-3">
                                    <label for="policy-document-upload" class=" required">Policy Document</label> 
                                    <a *ngIf="priceBreakupFormControl['policyBase64'].value != null && priceBreakupFormControl['policyBase64'].value != undefined && priceBreakupFormControl['policyBase64'].value != ''" class="float-right text-main cursor-pointer fw-bolder" target="_blank" [href]="priceBreakupFormControl['policyBase64'].value" download="{{insuranceFormControl['quoteId'].value != null && insuranceFormControl['quoteId'].value != undefined && insuranceFormControl['quoteId'].value != '' ? (insuranceFormControl['quoteId'].value + '.pdf') : 'policy.pdf' }}">Download</a>
                                    <!-- <label for="policy-document-upload" class="custom-file-upload text-center" > -->
                                        <!-- <img src="{{!policyImage ? '../../../assets/svgIcon/uplod.svg' : policyImage}}" alt=""
                                            width="160px" height="100px" [ngClass]="{'success':policyImage,'required_' : ((priceBreakupFormControl['policyBase64'].touched  || submitted) && priceBreakupFormControl['policyBase64'].errors?.['required'])}"> -->
                                        <!-- <p *ngIf="!policyImage" class="my-1 px-0 text-grey4">Upload Policy Document</p> -->
                                        <!-- <input id="policy-document-upload" type="file" name="uploadPAN" 
                                        (change)="onPolicySelected($event)" accept="image/*"> -->
                                        <!-- <p *ngIf="policyImage" class="my-1 px-0 text-success">Policy document uploaded</p>                                         -->
                                    <!-- </label>  -->
                                    <input id="policy-document-upload" type="file" name="uploadPAN" (change)="onPolicySelected($event)" accept="pdf"> 
                                    <!-- <i class="fa fa-download text-primary" aria-hidden="true"></i> -->
                                        <span class="text-danger" *ngIf="((priceBreakupFormControl['policyBase64'].touched  || submitted) && priceBreakupFormControl['policyBase64'].errors?.['required'])">
                                        Policy document is required.
                                        </span>
                                </div>
                                <div class="mt-3 col-md-1">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            <ng-container *ngIf="this.vehicleForm?.get('typeOfCover')?.value != 'Bundled' && insuranceFormControl['isMotor'].value == 'Yes'">
                <hr class="premium_breakup my-0 px-0">
                <div class="col-md-12 d-flex justify-content-between align-items-center my-3">
                    <h4  class="mb-0">Existing Policy Details</h4>
                </div>
                <hr class="premium_breakup my-0 px-0">
                    <form id="kt_vehicle_details_form" class="form pb-4" [formGroup]="vehicleForm"
                    novalidate="novalidate">
                    <div class="justify-content-between row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-3 mt-3">
                                    <label for="previousPolicyCompany" [ngClass]="{'required':!isExistingPolicyValidationRemoved}">Existing Policy Company
                                    </label>
                                    <app-autocomplete class="w-100" [items]="insurancePartnersList" placeholder="Type and search Existing policy company"
                                        (itemSelected)="onPrevCompSelection($event)"
                                        (onBlurEvent)="onPrevCompBlur($event)"
                                        [userInput]="vehicleFormControl['previousPolicyCompany'].value" 
                                        [showError]="((vehicleFormControl['previousPolicyCompany'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyCompany'].errors?.['required'])"></app-autocomplete>
                                    <Span class="text-danger"
                                        *ngIf="((vehicleFormControl['previousPolicyCompany'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyCompany'].errors?.['required'])">
                                        Existing policy company is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="previousPolicyType" [ngClass]="{'required':!isExistingPolicyValidationRemoved}">Existing Policy Type
                                    </label>
                                    <select name="previousPolicyType" formControlName="previousPolicyType"
                                        placeholder="Existing Policy Type"
                                        class="form-select form-select-custom"
                                        [ngClass]="{'border border-danger': ((vehicleFormControl['previousPolicyType'].touched  || submittedPolicy) && !vehicleFormControl['previousPolicyType'].valid)}">
                                        <option *ngFor="let polType of policyTypeList" [value]="polType.value">
                                            {{polType.text}}
                                        </option>
                                    </select>
                                    <Span class="text-danger"
                                        *ngIf="((vehicleFormControl['previousPolicyType'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyType'].errors?.['required'])">
                                        Existing policy type is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="previousPolicyNumber" [ngClass]="{'required':!isExistingPolicyValidationRemoved}">Existing Policy Number
                                    </label>
                                    <input type="text" name="previousPolicyNumber"
                                        formControlName="previousPolicyNumber"
                                        placeholder="Existing Policy Number" class="form-control" oninput="this.value = this.value.toUpperCase()"
                                        [ngClass]="{'border border-danger': ((vehicleFormControl['previousPolicyNumber'].touched  || submittedPolicy) && !vehicleFormControl['previousPolicyNumber'].valid)}">
                                    <Span class="text-danger"
                                        *ngIf="((vehicleFormControl['previousPolicyNumber'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyNumber'].errors?.['required'])">
                                        Existing policy number is required.
                                    </Span>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <label for="previousPolicyExpiryDate" [ngClass]="{'required':!isExistingPolicyValidationRemoved}">Existing Policy Exp.Date </label>
                                    <input type="date" name="previousPolicyExpiryDate"
                                        formControlName="previousPolicyExpiryDate"
                                        placeholder="Existing Policy Exp. Date" class="form-control"
                                        [ngClass]="{'border border-danger': ((vehicleFormControl['previousPolicyExpiryDate'].touched  || submittedPolicy) && !vehicleFormControl['previousPolicyExpiryDate'].valid)}"
                                         onkeydown="return false">
                                    <Span class="text-danger"
                                        *ngIf="((vehicleFormControl['previousPolicyExpiryDate'].touched  || submittedPolicy) && vehicleFormControl['previousPolicyExpiryDate'].errors?.['required'])">
                                        Existing policy exp. date is required.
                                    </Span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
            <!-- <ng-container>
                <hr class="premium_breakup my-0 px-0">
                <div _ngcontent-ybu-c94="" class="col-md-12 d-flex justify-content-between align-items-center my-3">
                    <h4  class="mb-0">Existing Third Party Policy Details</h4>
                </div>
                <hr class="premium_breakup my-0 px-0">
                <form id="kt_vehicle_details_form pt-2" class="form" [formGroup]="existingTPPolicyDetailsForm"
                novalidate="novalidate">
                <div class="justify-content-between row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-6 mt-3">
                                <label for="tpInsurer" class=" required">Existing Third Party Company
                                </label>
                                <app-autocomplete class="w-100" [items]="insurancePartnersList" placeholder="Type and search Existing policy company"
                                    (itemSelected)="onPrevTPCompSelection($event)"
                                    (onBlurEvent)="onPrevTPCompBlur($event)"
                                    [userInput]="existingTPPolicyDetailsFormControl['tpInsurer'].value" 
                                    [showError]="((existingTPPolicyDetailsFormControl['tpInsurer'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInsurer'].errors?.['required'])"></app-autocomplete>
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpInsurer'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInsurer'].errors?.['required'])">
                                    Existing third party company is required.
                                </Span>
                            </div>
                            <div class="col-md-6 mt-3">
                                <label for="tpPolicyNumber" class=" required">Existing Third Party Policy Number
                                </label>
                                <input type="text" name="tpPolicyNumber"
                                    formControlName="tpPolicyNumber"
                                    placeholder="Existing Third Party Policy Nuber" class="form-control"
                                    [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpPolicyNumber'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpPolicyNumber'].valid)}">
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpPolicyNumber'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpPolicyNumber'].errors?.['required'])">
                                    Existing third party policy number is required.
                                </Span>
                            </div>
                            <div class="col-md-6 mt-3">
                                <label for="previousPolicyNumber" class=" required">Existing Third Party Policy Start Date </label>
                                <input type="date" name="tpInceptionDate"
                                    formControlName="tpInceptionDate"
                                    placeholder="Existing Third Party Policy Start Date" class="form-control"
                                    [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpInceptionDate'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpInceptionDate'].valid)}"
                                     onkeydown="return false">
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpInceptionDate'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInceptionDate'].errors?.['required'])">
                                    Existing third party policy start date is required.
                                </Span>
                            </div>
                            <div class="col-md-6 mt-3">
                                <label for="tpExpiryDate" class=" required">Existing Third Party Policy Exp. Date</label>
                                <input type="date" name="tpExpiryDate"
                                    formControlName="tpExpiryDate"
                                    placeholder="Existing Third Party Policy Exp. Date" class="form-control"
                                    [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpExpiryDate'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpExpiryDate'].valid)}"
                                    onkeydown="return false">
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpExpiryDate'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpExpiryDate'].errors?.['required'])">
                                    Existing third party policy exp. date is required.
                                </Span>
                            </div>
                            <div class="col-md-6 mt-3">
                                <label for="tpInsurerAddress" class=" required">Ensuring Branch Address
                                </label>
                                <input type="text" name="tpInsurerAddress"
                                    formControlName="tpInsurerAddress"
                                    placeholder="Ensuring Branch Address" class="form-control"
                                    [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpInsurerAddress'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpInsurerAddress'].valid)}">
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpInsurerAddress'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpInsurerAddress'].errors?.['required'])">
                                    Ensuring branch address is required.
                                </Span>
                            </div>                                        
                            <div class="col-md-6 mt-3">
                                <label for="tpAddress2" class=" required">Ensuring Branch State
                                </label>
                                <app-autocomplete class="w-100" [items]="ensuringBranchStateList" placeholder="Ensuring Branch State"
                                    (itemSelected)="onEnsuringBranchStateSelection($event)"
                                    (onBlurEvent)="onEnsuringBranchStateBlur($event)"
                                    [userInput]="existingTPPolicyDetailsFormControl['tpAddress2'].value"
                                    [showError]="((existingTPPolicyDetailsFormControl['tpAddress2'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpAddress2'].valid)"></app-autocomplete>
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpAddress2'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpAddress2'].errors?.['required'])">
                                    Ensuring branch state is required.
                                </Span>
                            </div>
                            <div class="col-md-6 mt-3">
                                <label for="tpAddress1" class=" required">Ensuring Branch City
                                </label>
                                <app-autocomplete class="w-100" [items]="ensuringBranchDistrictList" placeholder="Ensuring Branch City"
                                    (itemSelected)="onEnsuringBranchDistrictSelection($event)"
                                    (onBlurEvent)="onEnsuringBranchDistrictBlur($event)"
                                    [userInput]="existingTPPolicyDetailsFormControl['tpAddress1'].value"
                                    [showError]="((existingTPPolicyDetailsFormControl['tpAddress1'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpAddress1'].valid)"></app-autocomplete>
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpAddress1'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpAddress1'].errors?.['required'])">
                                    Ensuring branch city is required.
                                </Span>
                            </div>
                            <div class="col-md-6 mt-3">
                                <label for="tpPolicyTerm" class=" required">Existing Third Party Policy Tenure
                                </label>
                                <select name="tpPolicyTerm" formControlName="tpPolicyTerm"
                                    placeholder="Existing Third Party Policy Tenure"
                                    class="form-select form-select-custom"
                                    [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpPolicyTerm'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpPolicyTerm'].valid)}">
                                    <option *ngFor="let polType of policyTermList" [value]="polType.value">
                                        {{polType.text}}
                                    </option>
                                </select>  
                            </div>
                            <div class="col-md-6 mt-3">
                                <label for="tpSumInsured" class=" required">Existing PA Cover Sum Insured
                                </label>
                                <input type="text" name="tpSumInsured"
                                    formControlName="tpSumInsured" (keypress)="numberOnly($event)"
                                    placeholder="Ensuring Branch Address" class="form-control"
                                    [ngClass]="{'border border-danger': ((existingTPPolicyDetailsFormControl['tpSumInsured'].touched  || submittedPolicy) && !existingTPPolicyDetailsFormControl['tpSumInsured'].valid)}">
                                <Span class="text-danger"
                                    *ngIf="((existingTPPolicyDetailsFormControl['tpSumInsured'].touched  || submittedPolicy) && existingTPPolicyDetailsFormControl['tpSumInsured'].errors?.['required'])">
                                    PA cover sum insured is required.
                                </Span> 
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </ng-container>                            -->

            </div>
            <!-- <div class="row">
                <div class="col-md-6 mt-5  text-start">
                    <button class="btn btn-primary-outline btn-outline" (click)="Details('nominee')">Back</button>
                </div>
                <div class="col-md-6 mt-5  text-end" *ngIf="insuranceFormControl['isMotor'].value">
                    <button class="btn btn-primary" (click)="Details('vehicle')">Next</button>
                </div>
                <div class="col-md-6 mt-5  text-end" *ngIf="!insuranceFormControl['isMotor'].value">
                    <button class="btn btn-primary" (click)="continue()">Continue</button>
                </div>
            </div> -->
            </ng-container>
        </div>
        <div id="nominee" class="tab-pane col-md-12 c-pl c-pr">
            <!-- [ngClass]="{'active': detail == 'nominee'}" -->
            <ng-container >
            <div class="card pb-5 row br-top-0 my-1">
                <div class="details_card">
                    <form class="form needs-validation" [formGroup]="nomineeForm" novalidate="">
                        <div class="justify-content-between row">
                            <div class="h5 border-bottom py-3 border-top-radius bg-color-5 mb-0 "> Nominee Details</div>
                            <div class="col-md-3 mt-3">
                                <label for="name">Nominee Name</label>
                                <input type="text" class="form-control" name="nominee"
                                    formControlName="nomineeName" placeholder="User nominee name"
                                    (keypress)="alphabetOnly($event)"
                                    [ngClass]="{'border border-danger': ((nomineeFormControl['nomineeName'].touched  || submittedNominee) && nomineeFormControl['nomineeName'].errors?.['required'])}" />
                                <Span class="text-danger"
                                    *ngIf="((nomineeFormControl['nomineeName'].touched  || submittedNominee) && !nomineeFormControl['nomineeName'].valid)">
                                    Nominee name is required.
                                </Span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label class="name">Relation : </label>
                                <select name="income" id="Relationship" class="form-select form-select-custom"
                                    formControlName="relationshipWithNominee"
                                    [ngClass]="{'border border-danger': ((nomineeFormControl['relationshipWithNominee'].touched  || submittedNominee) && nomineeFormControl['relationshipWithNominee'].errors?.['required'])}">
                                    <option value="" selected>Select Relationship</option>
                                    <option *ngFor="let relation of RelationShipList" [value]="relation.name">
                                        {{relation.name}}
                                    </option>
                                </select>
                                <Span class="text-danger"
                                    *ngIf="((nomineeFormControl['relationshipWithNominee'].touched  || submittedNominee) && !nomineeFormControl['relationshipWithNominee'].valid)">
                                    Nominee relation is required.
                                </Span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label class="name">Age : </label>
                                <select name="Age" placeholder="Select nominee age" formControlName="nomineeAge"
                                    class="form-select form-select-custom" 
                                    [ngClass]="{'border border-danger': ((nomineeFormControl['nomineeAge'].touched  || submittedNominee) && !nomineeFormControl['nomineeAge'].valid)}">
                                    <option *ngFor="let age of ageList" [value]="age.value">
                                        {{age.text}}
                                    </option>
                                </select>
                                <Span class="text-danger"
                                    *ngIf="((nomineeFormControl['nomineeAge'].touched  || submittedNominee) && nomineeFormControl['nomineeAge'].errors?.['required'])">
                                    Nominee age is required.
                                </Span>
                                <Span class="text-danger"
                                    *ngIf="((nomineeFormControl['nomineeAge'].touched  || submittedNominee) && nomineeFormControl['nomineeAge'].errors?.['pattern'])">
                                    Provide valid Nominee age.
                                </Span>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label for="gender">Gender</label>
                                <select name="gender" id="gender" class="form-select form-select-custom"
                                        formControlName="gender">
                                            <option *ngFor="let gender of genderList" [value]="gender">
                                                {{gender}}
                                            </option>
                                        </select>  
                                <Span class="text-danger"
                                *ngIf="((nomineeFormControl['gender'].touched  || submittedNominee) && nomineeFormControl['gender'].invalid)">
                                Select your Gender
                            </Span>
                            </div>
                        </div>                        
                    </form>
                </div>                
            </div>
            <!-- <div class="row">
                <div class="col-md-6 mt-5  text-start">
                    <button class="btn btn-primary-outline btn-outline" (click)="Details('address')">Back</button>
                </div>
                <div class="col-md-6 mt-5  text-end">
                    <button class="btn btn-primary" (click)="Details('policy')">Next</button>
                </div>
            </div> -->
            <div class="row">
                <div class="col-12 mt-3 text-end">
                    <button class="btn btn-primary" (click)="continue()">{{this.id != '0' ? 'Update ' : 'Save '}}Policy</button>
                </div>
            </div>
            </ng-container>
        </div>  
        </div>
    </ng-container>
</div>