
<div class="overlay">
    <div class="popup2 col-md-3 col-11">
      <div class="modal-header tooltip_">
        <h3>Is Ownership Transferred?</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <span>
              Is the ownership transferred?
            </span>
            <span class="d-flex align-items-center">
              <input id="osty" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="isOwnerShipTransferred??'isOwnerShipTransferred'" [(ngModel)]="this.isOwnerShipTransferred"><span class="mr-2">Yes</span>
              <input id="ostn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="isOwnerShipTransferred??'isOwnerShipTransferred'" [(ngModel)]="this.isOwnerShipTransferred"><span>No</span>
            </span>  
          </div>
          <div class="col-12 py-2">
            <span class="text-grey4" *ngIf="data_isOwnerShipTransferred != this.isOwnerShipTransferred">
              If you proceed with this selection, quotes will recalculate.
            </span>
            <button type="button" class="pull-right btn btn-primary btn-md py-2 mt-5" aria-label="Close" (click)="closeOwnershipTransferredModal()">
              {{data_isOwnerShipTransferred != this.isOwnerShipTransferred ? 'Recalculate Quote' : 'Proceed'}}              
          </button>
          </div>
          
        </div>
        
      </div>
    </div>
  </div>