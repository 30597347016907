<div class="user-dashboard">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                 size="medium"
                 color="#009ef7"
                 type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
    <div class="card-user user-route">
      <div class="card-user">
        <div class="row">
            <div class="col-6">
                <div class="row card mx-1 flex-row py-2 align-items-center">
                    <div class="col-auto">
                        <img src="../../../assets/svgIcon/agent/total_points_icon.svg">
                    </div>
                    <div class="col-auto">
                        <span class="d-block h1 mb-1">
                            {{totalPointsEarned}}
                        </span>
                        <span class="text-grey4">
                            Total Earned Points
                        </span>                        
                    </div>                    
                </div>
            </div>
            <div class="col-6">
                <div class="row card mx-1 flex-row py-2 align-items-center">
                    <div class="col-auto">
                        <img src="../../../assets/svgIcon/agent/redeeed_points_icon.svg">
                    </div>
                    <div class="col-auto">
                        <span class="d-block h1 mb-1">
                            {{totalPointsRedeemed}}
                        </span>
                        <span class="text-grey4">
                            Total Redeemed Points
                        </span> 
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="row card mx-1 flex-row">
                    <div class="col-12 border-bottom">
                        <div class="row py-2 ">
                            <div class="col-6 h1 mb-0 align-content-center">
                                Commission
                            </div>
                            <div class="col-6 text-right">
                                <button class="btn btn-primary btn-sm" (click)="exportToExcel()">Download Statement</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 border-bottom">
                        <div class="row py-2">
                            <div class="col-auto py-2 cursor-pointer" *ngFor="let my of monthYearArray" (click)="getAgentCommissionData(my.year,my.month)">
                                <span class="p-2 border-2 border rounded" [ngClass]="{'selected-ym' : my.year == this.selectedYear && my.month == this.selectedMonth}">
                                    {{my.text}}
                                </span>
                            </div>                            
                        </div>
                    </div>
                    <div class="col-md-6 border-right my-3">
                        <div class="row card mx-1 flex-row py-3 bg-grey1">
                            <div class="col-8 h4">
                                Cycle 1
                            </div>
                            <div class="col-4 text-right h4">
                                {{totalPointsCycle1}} <span class="font-12-10 ">Points</span>
                            </div>
                            <div class="col-8 text-grey4">
                                {{dateRangeCycle1}}
                            </div>
                            <div class="col-4 text-right">
                                
                            </div>
                        </div>  
                        <ng-container  *ngIf="dataCycle1?.length> 0">
                            <div class="row mx-1 flex-row pt-3 pb-1 border-bottom font-17-13  text-grey5 fw-bolder">
                                <div class="col-6 mb-0 align-content-center">
                                    Particular
                                </div>
                                <div class="col-3 text-right">
                                    Credit <span class="font-12-10"> (Points)</span>
                                </div>
                                <div class="col-3 text-right">
                                    Debit <span class="font-12-10"> (Points)</span>
                                </div>
                            </div>
                            <div class="ledger-container">
                                <div class="row mx-1 flex-row py-2 border-bottom" *ngFor="let item of dataCycle1">
                                    <div class="col-6 mb-0 align-content-center">
                                        <span class="font-17-13 text-grey5">Policy No: {{item.policyNumber}}</span>
                                        <span class="text-grey4 font-12-10 d-block">Issued On {{item.whenCreated}}</span>
                                    </div>
                                    <div class="col-3 text-right">
                                        <span class="font-17-13 text-grey5">{{item.commissionPointsCr}}</span>
                                    </div>
                                    <div class="col-3 text-right">
                                        <span class="font-17-13 text-grey5">{{item.commissionPointsDr}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>     
                        <ng-container *ngIf="dataCycle1?.length == 0">
                            <div class="row mx-1 flex-row pt-3 pb-1 font-12-10 text-grey4 fw-bolder">
                                <div class="col-12 text-grey4">
                                    No entry found for this cycle
                                </div>
                            </div>
                        </ng-container> 
                    </div>
                    <div class="col-md-6 my-3">
                        <div class="row card mx-1 flex-row py-3 bg-grey1">
                            <div class="col-8 h4">
                                Cycle 2
                            </div>
                            <div class="col-4 text-right h4">
                                {{totalPointsCycle2}} <span class="font-12-10">Points</span>
                            </div>
                            <div class="col-8 text-grey4">
                                {{dateRangeCycle2}}
                            </div>
                            <div class="col-4 text-right">
                                
                            </div>                            
                        </div>  
                        <ng-container *ngIf="dataCycle2?.length> 0">
                            <div class="row mx-1 flex-row pt-3 pb-1 border-bottom font-17-13  text-grey5 fw-bolder">
                                <div class="col-6 mb-0 align-content-center">
                                    Particular
                                </div>
                                <div class="col-3 text-right">
                                    Credit <span class="font-12-10"> (Points)</span>
                                </div>
                                <div class="col-3 text-right">
                                    Debit <span class="font-12-10"> (Points)</span>
                                </div>
                            </div>
                            <div class="ledger-container">
                                <div class="row mx-1 flex-row py-2 border-bottom" *ngFor="let item of dataCycle2">
                                    <div class="col-6 mb-0 align-content-center">
                                        <span class="font-17-13 text-grey5">Policy No: {{item.policyNumber}}</span>
                                        <span class="text-grey4 font-12-10 d-block">{{item.whenCreated}}</span>
                                    </div>
                                    <div class="col-3 text-right">
                                        <span class="font-17-13 text-grey5">{{item.commissionPointsCr}}</span>                                    
                                    </div>
                                    <div class="col-3 text-right">
                                        <span class="font-17-13 text-grey5">{{item.commissionPointsDr}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="dataCycle2?.length == 0">
                            <div class="row mx-1 flex-row pt-3 pb-1 font-12-10 text-grey4 fw-bolder">
                                <div class="col-12 text-grey4">
                                    No entry found for this cycle
                                </div>
                            </div>
                        </ng-container>        
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    </div>