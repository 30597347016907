import { Component,Input,ElementRef ,OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http'; // Import HttpCl

@Component({
  selector: 'app-future-general-payment',
  templateUrl: './future-general-payment.component.html',
  styleUrls: ['./future-general-payment.component.css']
})
export class FutureGeneralPaymentComponent implements OnInit,AfterViewInit {
  @Input() futureDetails :any;
  @ViewChild('file', { read: ElementRef }) myDiv: ElementRef<HTMLElement>;
  // @ViewChild('myDiv') myDiv: ElementRef;
  constructor(private route: ActivatedRoute,private http: HttpClient){

  } 
  ngAfterViewInit() {
    console.log(this.futureDetails,"Future Genarali Test Payment");
    this.submitForm();
}

  submitForm() {
    const form = document.getElementById('PostForm') as HTMLFormElement;
    console.log(form);
     form.submit();
  }
  
    
  ngOnInit() {
   
  }
}
