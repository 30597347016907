<div class="container">
    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="card p-1">
                <div class="row">
                    <div class="col-md-8">
                        <p>Pages / Agent / Profile</p>
                        <h4>Agent Profile </h4>

                    </div>
                    <div class="col-md-1 p-3">
                          <img class="mt-2 ml-5" src="../../../assets/svgIcon/BellSimple.svg" alt="">
                      
                    </div>
                    <div class="col-md-1 p-2">
                        <img  src="../../../assets/svgIcon/Ellipse 1228.svg" alt="" class="dropbtn">

                  </div>
                    <div class="col-md-2 ">
                        <!-- <button class="btn btn-md btn-primary mr-3"> + Sell Policies</button> -->
                        <!-- <img class="mb-6 mr-2" src="../../../assets/svgIcon/BellSimple.svg" alt="">
                        <img class="mb-6 " src="../../../assets/svgIcon/Ellipse 1228.svg" alt="" class="dropbtn"> -->
                        <div class="dropdown p-3">
                            <h4 >Jignesh Mistry</h4>
                            <span>Agent</span>
                           <div class="dropdown-content">
                              <a href="/agent-profile"> <p><img src="../../../assets/svgIcon/UserCircle.svg" alt="">&nbsp; Profile</p></a>
                              <a href="#"><p> <img src="../../../assets/svgIcon/UserCircle.svg" alt="">&nbsp;Help</p></a>
                              <a href="#"><p> <img src="../../../assets/svgIcon/UserCircle.svg" alt=""> &nbsp; Setting</p></a>
                              <a href="#"><p> <img src="../../../assets/svgIcon/UserCircle.svg" alt=""> &nbsp;Logout</p></a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>