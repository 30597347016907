import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfileService } from '../Services/profile.service';
import swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'manageproperty',
  templateUrl: './manageproperty.component.html',
  styleUrls: ['./manageproperty.component.css']
})
export class ManagepropertyComponent implements OnInit {
  propertyInfoForm: FormGroup;
  title: string = 'Add new property';
  proprtyAge: { id: number; name: string; }[];
  structureValueList: { id: number; name: string; }[];
  IsProfile: boolean = false;
  propertyType: number = 1;
  @ViewChild('closebtn', { static: true }) closeButtonRef: ElementRef;

  constructor(private fb: FormBuilder, private profileService: ProfileService,
    private activatedRoute: ActivatedRoute, private generalService : GeneralService) {
    this.propertyInfoForm = this.fb.group({
      "UniqueUserId": this.generalService.getUniqueuserid(),
      "propertyType": [1],
      "propertyCity": [''],
      "propertyAge": [''],
      "propertyValue": [''],
      "isInsured": ['false'],
      "policyExpiryDate": ['']
    });

    this.activatedRoute.url.subscribe(urlSegments => {
      this.IsProfile = urlSegments[0].path.toLowerCase() === 'profile' ? true : false;
    });
  }

  async ngOnInit() {
    await this.getPropertyAge();
    await this.getStructureValue();
  }

  async getPropertyAge() {
    this.proprtyAge = [{ id: 5, name: '<5 Years' }, { id: 10, name: '5-10 Years' }, { id: 15, name: '10-15 Years' }, { id: 20, name: '>15 Years' }
    ]
  }

  async getStructureValue() {
    this.structureValueList = [{ id: 5, name: '1000000' }, { id: 10, name: '2000000' }, { id: 15, name: '3000000' }, { id: 20, name: '4000000' }
    ]
  }

  async insertPropertynfo() {
    this.propertyInfoForm.patchValue(this.propertyInfoForm.value)
    this.propertyInfoForm.value.propertyType = this.propertyInfoForm.get('propertyType')?.value;//this.propertyType;//this.propertyInfoForm.value.propertyType == true ? 1 : 2;
    const res = await this.profileService.ManagePropertyDetails(this.propertyInfoForm.value);
    if (res?.isSuccess) {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Property added successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      this.closeButtonRef.nativeElement.click();
      this.propertyInfoForm.reset();
    }
  }

  async setPropertyType(type:number){
    this.propertyType = type;
    this.propertyInfoForm.get('propertyType')?.setValue(type);
  }

}
