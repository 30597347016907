import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-medical-profile',
  templateUrl: './medical-profile.component.html',
  styleUrls: ['./medical-profile.component.css']
})
export class MedicalProfileComponent {
  constructor(private route: Router) {

  }
  verification() {
    this.route.navigate(["/medical-verification"]);
  }
  bank() {
    this.route.navigate(["/medical-bank"]);
  }

  editPopup: boolean = false;
  eidt() {
    this.editPopup = true;
  }
  closePop() {
    this.editPopup = false;
  }
  reload() {
    location.reload();
  }
  tabs: string = '1';
  changeTab(pg: string) {
    this.tabs = pg;
  }
}
