<div class="content-box bg-grey1" [ngClass]="!isUserLogged ? '' : 'user-dashboard'">
    <div class="container-fluid" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}"
        [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="container" style="background-color: #F3F3F4">
            <div class="container-fluid mt-10">
                <div class="mt-10" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'col-4 ml-4 mb-4': !isSmallScreen, 'col-12': isSmallScreen}">
                        <div class="vehicle_details" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <h4><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"> Your vehicle details
                                </h4>
                            </div>
                            <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/suzuki.svg" alt="">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Vehicle</p>
                                    <h5>{{vehicleData.make}}</h5>
                                </div>
                            </div>
                            <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                        <div [ngClass]="{'col-3': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <img class="svg_img" src="../../assets/svgIcon/bike_icon.svg" alt="Bike"
                                                width="20">
                                        </div>
                                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <p class="font-body">Model</p>
                                            <h5>{{vehicleData.model}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                        <div [ngClass]="{'col-3': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <img class="svg_img" src="../../assets/svgIcon/city.svg" alt="city"
                                                width="27">
                                        </div>
                                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <p class="font-body">RTO</p>
                                            <h5>{{vehicleData.registrationNumber}} - {{vehicleData.city}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                        <div [ngClass]="{'col-3': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <img src="../../assets/svgIcon/CalendarBlank.svg" alt="Calendar">
                                        </div>
                                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <p class="font-body">Year</p>
                                            <h5>{{vehicleData.registrationYear}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                        <div [ngClass]="{'col-3': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <img src="../../assets/svgIcon/Coins.svg" alt="">
                                        </div>
                                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <p class="font-body">Last Year Claim</p>
                                            <h5>{{vehicleData.claimsMadeInPreviousPolicy}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <div class="row">
                                        <div [ngClass]="{'col-3': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <img src="../../assets/svgIcon/Percent.svg" alt="">
                                        </div>
                                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                            <p class="font-body">NCB Last Year</p>
                                            <h5>{{vehicleData.ncbprevious}}%</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="mt-5 extra_coverage" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                      <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <h4>Add Extra Coverage</h4>
                      </div>
                      <div class="mt-3" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                              <input type="checkbox">
                          </div>
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <h5>Personal Accident Cover of Rs. 10 Lakh</h5>
                          </div>
                          <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                              <img src="../../assets/svgIcon/Info.svg" alt="">
                          </div>
                      </div>
                      <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <span style="color: #DF3A4D;">PA cover is mandatory</span>
                          </div>
                      </div>
                      <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <p><span class="amount">₹180/</span>
                                  <span class="year text-center">1 Year</span>
                              </p>
                          </div>
                      </div>
                      <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                              <input type="checkbox">
                          </div>
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <h5>Consumable Cover</h5>
                          </div>
                          <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                              <img src="../../assets/svgIcon/Info.svg" alt="">
                          </div>
                      </div>
                      <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <p><span class="amount">₹320</span>
                              </p>
                          </div>
                      </div>
                  </div> -->
                        <div class="mt-5 premium_breakup" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <h4>Premium Breakup</h4>
                            </div>
                            <div class="mt-2 border-btm" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Basic Own Damage</p>
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h6 class="text-end">₹0</h6>
                                </div>
                            </div>
                            <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Net Premium</p>
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h6 class="text-end">₹{{premiumData.premium}}</h6>
                                </div>
                            </div>
                            <div class="mt-2 border-btm" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Add GST(18%)</p>
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h6 class="text-end">₹{{premiumData.gst}}</h6>
                                </div>
                            </div>
                            <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Final Premium</p>
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h6 class="text-end">₹{{premiumData.netPremium}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>&nbsp;&nbsp;&nbsp;
                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                            <h2 class=text-start>Policy Details</h2>
                        </div>
                        <form id="kt_vehicle_details_form" class="form" [formGroup]="vehicleForm"
                            novalidate="novalidate">
                            <div class="details_card mt-2">
                                <div class="justify-content-between"
                                    [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                        <label for="name">Registration Number </label>
                                        <input type="text" name="RegistrationNumber"
                                            formControlName="registrationNumber" placeholder="Registration Number "
                                            class="form-control">
                                        <!-- <label for="name">Registration Number</label>
                              <input type="text" class="form-control" placeholder="GJ11 CG6814"> -->
                                    </div>
                                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                        <label for="name">Engine Number </label>
                                        <input type="text" name="EngineNumver" formControlName="engineNumber"
                                            placeholder="Engine Numver " class="form-control">
                                    </div>
                                </div>
                                <div class="justify-content-between mt-3"
                                    [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                        <label for="name">Chasis Number </label>
                                        <input type="text" name="ChasisNumber" formControlName="chasisNumber"
                                            placeholder="Chasis Number " class="form-control">
                                    </div>
                                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                        <label for="name">Vehicle Registration Date </label>
                                        <input type="date" name="VehicleRegistrationDate"
                                            formControlName="registrationDate" placeholder="Vehicle Registration Date "
                                            class="form-control">
                                    </div>
                                </div>
                                <!-- <div class="justify-content-between mt-3" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <label for="name">Vehicle registration date</label>
                              <input type="text" class="form-control" placeholder="1 Feb 2021">
                          </div>
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                          </div>
                      </div> -->

                                <!-- <div class="mt-10" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <h3 class=text-start>Is your vehicle financed/ on loan? </h3>
                      </div>
                      <div class="justify-content-between mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <label for="name">Company which has provided the loan?</label>
                              <input type="text" class="form-control" placeholder="Company">
                          </div>
                          <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                              <label for="name">City where the loan was taken</label>
                              <input type="text" class="form-control" placeholder="City">
                          </div>
                      </div> -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottm_bar">
            <div class=" container">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}">
                        <p class="font-body mb-0">Net premium</p>
                        <h1 class="text-start mb-0">₹{{premiumData.netPremium}}</h1>
                    </div>
                    <div [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}">
                        <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                            <div class="justify-content-end d-flex align-items-center"
                                [ngClass]="{'col-4': !isSmallScreen, 'col-12': isSmallScreen}">
                                <a href="/faqs" class="btn">Help?</a>
                            </div>
                            <div class="btn-continue" [ngClass]="{'col-4': !isSmallScreen, 'col-12': isSmallScreen}">
                                <button class="btn btn-primary" (click)="CreatePolicy()">Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="insuranceChild bottomFooter">
    <div class="row childBottomFooter">
      <div class="col-md-6">
        <div style=" padding-left: 21px;">Net Premium</div>
        <div class="coverageAmount">₹{{premium}}</div>
      </div>
      <div class="col-md-4">
        <button class="floatRight btn btn-primary continueBtn" (click)="CreatePolicy()" >Continue</button>
        <div class="floatRight help">Help?</div>

      </div>
    </div>
  </div>
</div> -->
        <!-- <div class="content-box bg-grey1">
  <div class="container-fluid">
    <div class="row mt-20">
      <app-vehicle-premium-info></app-vehicle-premium-info>

      <div class="col-md-8" style="padding: 0px 20px;">
        <p class="section-part">Policy Details</p>
        <form id="kt_vehicle_details_form" class="form" [formGroup]="vehicleForm"
              novalidate="novalidate">
          <div class="row insuranceCard">
            <div class="row m-0">
              <div class="col-6">
                <div class="form-group">
                  <label class="float-start">Registration Number  </label>
                  <input type="text" name="RegistrationNumber" formControlName="registrationNumber"
                         placeholder="Registration Number " class="form-control">
                </div>
                <div class="form-group">
                  <label class="float-start">Engine Number  </label>
                  <input type="text" name="EngineNumver" formControlName="engineNumber"
                         placeholder="Engine Numver " class="form-control">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="float-start">Chasis Number  </label>
                  <input type="text" name="ChasisNumber" formControlName="chasisNumber"
                         placeholder="Chasis Number " class="form-control">
                </div>
                <div class="form-group">
                  <label class="float-start">Vehicle Registration Date  </label>
                  <input type="date" name="VehicleRegistrationDate" formControlName="registrationDate"
                         placeholder="Vehicle Registration Date " class="form-control">
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="insuranceChild bottomFooter">
    <div class="row childBottomFooter">
      <div class="col-md-8">
        <div style=" padding-left: 21px;">Net Premium</div>
        <div class="coverageAmount">₹{{premium}}</div>
      </div>
      <div class="col-md-4">
        <button class="floatRight btn btn-primary continueBtn" (click)="CreatePolicy()" >Continue</button>
        <div class="floatRight help">Help?</div>

      </div>
    </div>

  </div> -->