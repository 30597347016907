import { Component,Input,ElementRef ,OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http'; // Import HttpCl
@Component({
  selector: 'app-hdfcergo-payment',
  templateUrl: './hdfcergo-payment.component.html',
  styleUrls: ['./hdfcergo-payment.component.css']
})

export class HDFCERGOPaymentComponent implements OnInit,AfterViewInit {

  @Input()  HDFCDetails :any;
  @ViewChild('file', { read: ElementRef }) myDiv: ElementRef<HTMLElement>;
  // @ViewChild('myDiv') myDiv: ElementRef;
  constructor(private route: ActivatedRoute,private http: HttpClient){
  
  } 
  ngAfterViewInit() {
    this.submitForm();
}
  submitForm() {
    const form = document.getElementById('PostForm') as HTMLFormElement;
    console.log(form);
     form.submit();
  }
  
  ngOnInit() {
  
  }
}
