<app-navbar (languageChanged) = "languageChanged()"></app-navbar>
<div class="content-box">
    <div class="first_box">
        <div class="container">
            <div class="row text-center mt-5">
<h1>About Click4Bima</h1>
            </div>
            <div class="row mt-10 text-center">
                <h2 class="">{{langList.about_us_title}}</h2>
                <h3 class="mt-2" style="color: #848493;">{{langList.about_us_text}}</h3>
            </div>
            <div class="row backgroud_banner   align-items-center justify-content-between">
                <div class="col-md-4 banner_content text-center">
                    <div class=" bannerDiv">
                        <span class="client_number">{{langList.insurance_count}}</span>
                        <h4 class="text-white">{{langList.ins_complete}}</h4>
                        <p class="font-body text-white">
                            {{langList.ins_comp_text}}
                        </p>
                    </div>

                </div>
                <div class="col-md-4 banner_content text-center">
                    <div class=" bannerDiv">
                        <span class="client_number">{{langList.insurance_count}}</span>
                        <h4 class="text-white">{{langList.ins_complete}}</h4>
                        <p class="font-body text-white">
                            {{langList.ins_comp_text}}
                        </p>
                    </div>

                </div>
                <div class="col-md-4 banner_content text-center">
                    <div class=" bannerDiv">
                        <span class="client_number">{{langList.insurance_count}}</span>
                        <h4 class="text-white">{{langList.ins_complete}}</h4>
                        <p class="font-body text-white">
                            {{langList.ins_comp_text}}
                        </p>
                    </div>

                </div>
            </div>
            <!-- <div class="row backgroud_banner align-items-center justify-content-between">
                <div class="col-4 banner_content text-center">
                    <div class=" bannerDiv">
                        <span class="client_number">400+</span>
                        <h4 class="text-white">Insurance Completed</h4>
                        <p class="font-body text-white">
                            We focus on the details of everything we do. All to help businesses around the world
                        </p>
                    </div>
                </div>
                <div class="col-4 banner_content text-center">
                    <div class="bannerDiv">
                        <span class="client_number">400+</span>
                        <h4 class="text-white">Insurance Completed</h4>
                        <p class="font-body text-white">
                            We focus on the details of everything we do. All to help businesses around the world
                        </p>
                    </div>
                </div>
                <div class="col-4 banner_content text-center">
                    <div class="bannerDiv">
                        <span class="client_number">400+</span>
                        <h4 class="text-white">Insurance Completed</h4>
                        <p class="font-body text-white">
                            We focus on the details of everything we do. All to help businesses around the world
                        </p>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
    <div class="second_box">
        <div class="container">
            <div class="row card1">
                <div class="col">
                    <img class="poster" src="../../assets/images/aboutus_story.png" alt="">
                </div>
                <div class="col">
                    <div class="row about_us">
                        <h1 class="text-start">{{langList.found_title}}</h1>
                        <h3 class="text-start story">{{langList.found_text}}</h3>
                    </div>
                </div>
            </div>
            <div class="row card1">
                <div class="col">
                    <div class="row about_us">
                        <h1 class="text-start">{{langList.mission_vision_title}}</h1>
                        <h3 class="text-start story">{{langList.mission_vision_text}}</h3>
                    </div>
                </div>
                <div class="col">
                    <img class="poster" src="../../assets/images/aboutus_misson.png" alt="">
                </div>
            </div>
            <div class="row card1 about_us">
                <h1 class="text-start">{{langList.mgmt_title}}</h1>
                <h3 class="text-start story">{{langList.mgmt_text}}</h3>
            </div>
        </div>
    </div>
    <div class="mt-5 mb-5">
        <!-- <div class="container">
            <div class="card-container d-flex card bg-dark" style="flex-direction: row; border-radius:32px">
                <div class="row">
                    <div class="col-sm-5">
                        <div style="margin-left: 2rem;">
                            <img class="phone1 rounded-5" src="../../assets/images/android_img.png"
                                style="height: 400px;width:400px;">
                        </div>
                    </div>
                    <div class="col-sm-7">
                    <div style="margin: 10%;">
                        <h1 style="color: #FFFFFF;">
                            Pay, invest, save your<br> money with a <span style="color: var(--main);">
                                single
                                app
                            </span>
                        </h1>
                        <span class="font-body" style="color:#C2C2C9!important;">Get control of all your insurance needs
                            anywhere,
                            anytime</span>
                        <div class="d-flex mt-5">
                            <i class="fa fa-compress" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                            <p>Compare different insurance policies</p>&nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="fa fa-shopping-cart" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                            <p>Buy, store and share all your policies online</p>
                        </div>
                        <div class="d-flex">
                            <i class="fa fa-truck" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                            <p>Track your policy status on the go</p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="fa fa-download" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                            <p>Download your policy with a single tap</p>
                        </div>
                        <p style="color: #E7E7E9 !important;">Download our app from</p>
                        <div class="d-flex">
                            <a href="https://play.google.com/store/apps/details?id=com.bimag.app"><img class="rounded-3"
                                    src="../../assets/images/Google Play.svg" width="180"></a>&nbsp;&nbsp;
                            <a href=""><img class="rounded-3" src="../../assets/images/App Store.svg" width="180"></a>
                        </div>
                    </div>
                </div>

                </div>
            </div>
        </div> -->
        <!-- <app-android-banner></app-android-banner> -->
    </div>
</div>

<app-footer></app-footer>