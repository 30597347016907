
 <div class="container-fluid" style="background-color: rgb(16 160 193 / 5%)">
    <div class="container footer_links">
        <div class="card-container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="about-section d-flex justify-content-between">
                        <div class="">
                            <a href="/aboutus">{{langList.about_us}}</a>
                        </div>
                        <div class="">
                            <a class="" href="/career">{{langList.career}}</a>
                        </div>
                        <div class="">
                            <a class="" href="/sitemap">{{langList.sitemap}}</a>
                        </div>
                        <div class="">
                            <a class="" href="/contactus">{{langList.contact_us}}</a>
                        </div>
                        <div class="">
                            <a class="" href="/faqs">{{langList.faqs}}</a>
                        </div>
                        <div class=""></div>
                    </div>
                    <!-- <ul class="d-flex mt-5">
                        <a class="" href="/aboutus">ABOUT US</a>
                        <a class="" href="/career">CAREERS</a>
                        <a class="" href="/contactus">CONTACT US</a>
                        <a class="" style="text-align:center;" href="/faqs">FAQS </a>
                        <a class="" href="/sitemap">SITEMAP</a>
                    </ul> -->
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 text-right">
                    <div class="row" style="float: right;">   
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 dimension">
                            <a href="https://www.instagram.com/Click4bima/"><img class="mt-2"
                                    src="../../assets/svgIcon/Insta.svg" width="20"></a>
                        </div>&nbsp;
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 dimension">
                            <a href="https://www.facebook.com/click4bima"><img class="mt-2"
                                    src="../../assets/svgIcon/fb2.svg" width="11"></a>
                        </div>&nbsp;
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 dimension">
                            <a href="https://in.linkedin.com/in/online-policy-Click4bima-157816222"><img class="mt-2"
                                    src="../../assets/svgIcon/Linkedin.svg" width="20"></a>
                        </div>&nbsp;
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 dimension">
                            <a href="https://www.youtube.com/@click4bima">
                                <img class="mt-3" src="../../assets/svgIcon/Youtube2.svg" width="20">
                            </a>
                        </div>&nbsp;
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 dimension">
                            <a href="https://twitter.com/Click4bima"><img class="mt-2"
                                    src="../../assets/svgIcon/Twitter.svg" width="20"></a>
                        </div>
                        <!-- <div class="col-lg-2 col-md-2 col-sm-2 col-2 dimension">
                            <a href="mailto: Click4bima@gmail.com"><img class="mt-2" src="../../assets/svgIcon/mail.svg"
                                    width="20"></a>

                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="bg-dark">
    <div class="container">
        <div class="footer card-container footer_quick_links">
            <div class="container d-flex row">

                <div class="row">

                    <div class="col-lg-2 col-md-2 col-sm-2 col-12 body-length">
                        <ul>
                            <li class="mr-2">
                                <h6 class="menu text">{{langList.motor_insurance}}</h6>
                            </li><br>
                            <li><a href="/car-insurance">
                                    <p>{{langList.car_insurance}}</p>
                                </a></li>
                            <li><a href="/two-wheeler-insurance">
                                    <p>{{langList.two_wheeler_insurance}}</p>
                                </a></li>
                            <li><a href="/taxi-insurance">
                                    <p>{{langList.taxi_insurance}}</p>
                                </a></li>
                            <li><a href="/commercial-vehicle-insurance">
                                    <p>{{langList.commercial_veh_insurance}}</p>
                                </a></li>
                            <li><a href="/third-party-car-insurance">
                                    <p>{{langList.third_party_car_ins}}</p>
                                </a></li>
                            <li><a href="/third-party-two-wheeler-insurance">
                                    <p>{{langList.third_party_bike_ins}}</p>
                                </a></li>
                        </ul>

                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-12 body-length">
                        <ul>
                            <li>
                                <h6 class="menu text">{{langList.health_insurance}}</h6>
                            </li><br>
                            <li><a href="/family-health-insurance">
                                    <p>{{langList.family_health_ins}}</p>
                                </a></li>
                            <li><a href="/individual-health-insurance">
                                    <p>{{langList.individual_health_ins}}</p>
                                </a></li>
                            <li><a href="/group-health-insurance">
                                    <p>{{langList.group_health_ins}}</p>
                                </a></li>
                            <li><a href="/senior-citizen-health-insurance">
                                    <p>{{langList.senior_citizen_health_ins}}</p>
                                </a></li>
                            <li><a href="/critical-illness-insurance">
                                    <p>{{langList.critical_illness_ins}}</p>
                                </a></li>
                            <li><a href="/arogya-sanjivni-insurance">
                                    <p>{{langList.arogya_san_ins}}</p>
                                </a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-12 body-length">

                        <ul>
                            <li>
                                <h6 class="menu text">{{langList.life_insurance}}</h6>
                            </li><br>
                            <li><a href="/term-insurance">
                                    <p>{{langList.term_insurance}}</p>
                                </a></li>
                            <li><a href="/single-pay-insurance">
                                    <p>{{langList.single_pay_term_ins}}</p>
                                </a></li>
                            <li><a href="/group-term-insurance">
                                    <p>{{langList.group_term_ins}}</p>
                                </a></li>
                        </ul>

                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-12 body-length">
                        <ul>
                            <li>
                                <h6 class="menu text">{{langList.saving_plans}}</h6>
                            </li><br>
                            <li><a href="/tax-saving-insurance">
                                    <p>{{langList.tax_saving_ins}}</p>
                                </a></li>
                            <li><a href="/money-back-insurance">
                                    <p>{{langList.money_back_ins}}</p>
                                </a></li>
                            <li><a href="/guaranteed-return-insurance">
                                    <p>{{langList.guaranteed_return_ins}}</p>
                                </a></li>
                            <li><a href="/ulip-insurance">
                                    <p>{{langList.ulip}}</p>
                                </a></li>
                            <li><a href="/pension-insurance">
                                    <p>{{langList.pension_plan}}</p>
                                </a></li>
                            <li><a href="/child-insurance">
                                    <p>{{langList.child_plan}}</p>
                                </a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-12 body-length">
                        <ul>
                            <li>
                                <h6 class="menu text">{{langList.others}}</h6>
                            </li><br>
                            <li><a href="/privacy-policy">
                                    <p>{{langList.privacy_policy}}</p>
                                </a></li>
                            <li><a href="/cancellation-refund">
                                    <p>{{langList.cancellation_refund}}</p>
                                </a></li>
                            <li><a href="/term-condition">
                                    <p>{{langList.term_condition}}</p>
                                </a></li>
                            <li><a href="/disclaimer">
                                    <p>{{langList.disclaimer}}</p>
                                </a></li>
                            <li><a href="/grivance-redressal">
                                    <p>{{langList.grivance_redressal}}</p>
                                </a></li>
                        </ul>

                    </div>
                </div>
            </div>
            <div class="row">
                <div style="text-align: end;">
                    <p style="display: -webkit-inline-box; margin-right: 10px;">Handcrafted & Proudly Made In
                    </p>
                    <img src="../../assets/images/india-flag.svg">
                </div>
            </div>

            <div [ngClass]="{'row d-flex': !isSmallScreen, '': isSmallScreen}" class="footer-intro">
                <div [ngClass]="{'col-md-4': !isSmallScreen, 'col-12': isSmallScreen}" >
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}" class="mt-2">
                        <div [ngClass]="{'col-3': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/images/srg.png" alt="" width="100" height="50">
                        </div>
                        <div [ngClass]="{'col-8': !isSmallScreen, 'col-12': isSmallScreen}" class="footer_para">
                            <b>{{langList.srg_add_title}}</b><br>
                            <span>{{langList.srg_add_detail}}</span>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'col-md-4': !isSmallScreen, 'col-12': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}" class=" mt-2">
                        <div [ngClass]="{'col-3': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/images/irdai.jpg" alt="" width="100" height="50">
                        </div>
                        <div [ngClass]="{'col-8': !isSmallScreen, 'col-12': isSmallScreen}">
                            <b>{{langList.direct_broker_title}}</b><br>
                            <span>{{langList.direct_broker_detail}}</span><br>
                            <!-- <span>CIN: U74140RJ2002PTC017656</span> -->
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'col-md-4': !isSmallScreen, 'col-12 ml-3 mb-3': isSmallScreen}">
                    <b>{{langList.payment_method}}</b>
                    <div>
                        <img src="../../assets/images/visa.svg" width="50">&nbsp;
                        <img src="../../assets/images/american-express.svg" width="50">&nbsp;
                        <img src="../../assets/images/mastercard.svg" width="50">&nbsp;
                        <img src="../../assets/images/google-pay.svg" width="50">&nbsp;
                        <img src="../../assets/images/rupay.svg" width="50">&nbsp;
                        <img src="../../assets/images/paytm.svg" width="50">
                    </div>
                    <!-- <div class="row">
                        <div class="col-3" style="margin: 10px;">
                            
                        </div>&nbsp;&nbsp;
                        <div class="col-8">
                            <b>SRG INSURANCE BROKERS PRIVATE LIMITED</b><br>
                            <span>Corporate Office : 2, Ground floor, Vinayak Complex-B, Durga Nursery Road, Udaipur-313001, Rajasthan</span>
                        </div> -->
                    <!-- </div> -->
                </div>

            </div>
            <hr>
            <div>
                <p class="text-center" style="color:#FFFFFF">{{langList.copyright}}</p>
            </div>
        </div>
    </div>
</div>