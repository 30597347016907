<div class="login-container d-flex flex-root" id="kt_app_root">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
    type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="section d-flex justify-content-center">
    <div class="login">
      <div class="card-body d-flex flex-column">
        <!-- <div class="logo">
          <a href="/" class="link-primary" routerLink="/">
          <img src="../../assets/images/logo.svg" alt="" class="logo">
        </a>
        </div> -->
        <div class="mt-5 text-center">
          <button type="button" (click)="signInWithFB()" class="btn btn-primary btn-fb">
            <img class="mr-4" src="../../assets/images/fbLogo.svg" alt="">Continue with Facebook
          </button>
        </div>
        <div class="mt-5 text-center"> 
          <button type="button" class="btn btn-primary btn-google" (click)="signWithGoogle()">
            <img class="mr-4" src="../../assets/images/googleLogo.svg" alt="">Continue with Google 
          </button>
        </div>

        <div class="divider mt-5">or</div>

        <div *ngIf="isLoggedin === true">
          
            <button type="button" (click)="signOut()" class="btn btn-primary">Sign Out</button>
          </div>
        <div class="login-form flex-center flex-column-fluid">
          <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm">
            <div class="text-center mb-11">
              <h1>Sign In</h1>
            </div>
            <div class="fv-row mb-8">
              <label class="input-label required" for="mobile">Mobile Number</label>
              <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true"[searchCountryFlag]="false" [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode" customPlaceholder="Enter your mobile number"
                name="phone" formControlName="PhoneNumber" [inputId]="'phone_number'"
                (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

              </ngx-intl-tel-input>
              <!-- <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                [searchCountryFlag]="false" [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode"
                name="phone" formControlName="PhoneNumber" [inputId]="'phone_number'"
                (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

              </ngx-intl-tel-input> -->
            </div>
            <div class="fv-row mb-7">
              <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold">
                <div><label class="input-label required" for="password">Password</label></div>
                <a href="/forgotpassword" class="link-primary" routerLink="/forgotPassword">Forgot Password ?</a>
              </div>
              <input [type]="this.passwordType" placeholder="Enter Password" name="password" autocomplete="off"
                class="form-control bg-transparent" formControlName="Password" />
                <i [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                  (click)="ShowPassword()"></i>
            </div>
            <div class="d-flex">
              <input type="checkbox">&nbsp;
              <span class="remember mt-1">Remember me</span>
            </div>
            <div class="d-grid mb-5 mt-5">
              <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" [disabled]="loginForm.invalid || ServicesDown"
                (click)="submit()">
                <span class="indicator-label">Sign In</span>
              </button>
            </div>
            <div class="text-center">
              Don't have an account?
              <a routerLink="/signup" class="link-primary">Signup Here</a>
            </div>
          </form>
        </div>
        <div class="footer_copy_right Copyright">
          <span>© Copyright {{currentYear}} Click4bima. All Rights Reserved</span>
        </div>
      </div>
    </div>
  </div>
  <div class="service card shadow rounded-5 bg-light">
    <app-carousel></app-carousel>
  </div>
</div>
