import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { NavigationStart, Router } from '@angular/router';
import { GeneralService } from './general.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient,private router: Router, private generalService:GeneralService) { }

  async GetUserMenu(data: any) {
    const url = environment.APIUrl + 'menu/GetUserMenu';
    return this.http.post<ApiResponse>(url, data)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetDistributorSettings() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}menu/GetDistributorSettings` + '?url=' + window.location.href.split('?')[0], {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  showMenu(){
    let ShowMenu = false;

        // if (this.router.url.includes("/login") || this.router.url == "/" || this.router.url.includes("/signup") || this.router.url.includes("/forgotpassword") || this.router.url.includes("/resetpassword")) {
        //   ShowMenu = false;
        // }
        // else
        if (this.generalService.getAccessToken() != '') {
          ShowMenu = true;
        }
        else {
          ShowMenu = false;
        }
    return ShowMenu;
  }

  showMenu1(){
    let ShowMenu = false;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes("/login") || event.url == "/" || event.url.includes("/signup") || event.url.includes("/forgotpassword") || event.url.includes("/resetpassword")) {
          ShowMenu = false;
        }
        else
        if (this.generalService.getAccessToken() != '') {
          ShowMenu = true;
        }
        else {
          ShowMenu = false;
        }
      }
    });
    return ShowMenu;
  }
}
