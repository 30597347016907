import { Component } from '@angular/core';

@Component({
  selector: 'app-vehicle-popover',
  styleUrls: ['./vehicle-popover.component.css'],
  template: `<div>
  <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_24_18" title="Edit Vehicle" (click)="onEditClicked()"
  data-bs-toggle="offcanvas" data-bs-target="#addvehicle" aria-controls="offcanvasScrolling">
</div>`
})
export class VehiclePopoverComponent {
  ngOnInit() {
  }
  params: any;
  agInit(params: any): void {
    this.params = params;
  }
  constructor() { }
  async onEditClicked() {
    this.params.context.componentMember.editVehicle(this.params.data);
  }
}
