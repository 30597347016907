import { Component } from '@angular/core';

@Component({
  selector: 'app-child-plan',
  templateUrl: './child-plan.component.html',
  styleUrls: ['./child-plan.component.css']
})
export class ChildPlanComponent {

}
