import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) {

  }

  async GetPolicyDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}policy/PolicyDetails`,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  
  async SavePaymentDetail(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}policy/SavePaymentDetail`, data, {
     headers: new HttpHeaders() 
       .set('Content-Type', 'application/json')
       .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async UpdatePolicyReceipt(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}policy/UpdatePolicyReceipt`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  async GetPolicyReceipt(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}policy/GetPolicyReceipt`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  
 
  async GetPolicyById(policyNo?:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}policy/PolicyDetailsById?policyNo=${policyNo}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetPolicyById1(policyId?:string,applicationId:string = "", quoteId:string ="",policyIdEnc:string = "") {
    return this.http.get<ApiResponse>(`${environment.APIUrl}policy/PolicyDetailsById1?policyId=${policyId}&applicationId=${applicationId}&quoteId=${quoteId}&policyIdEnc=${policyIdEnc??""}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GeneratePolicyPDF(policyNo?:string,insuranceCompany?:number,policyIdEnc?:string) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}policy/GeneratePolicyPDF?policyNo=${policyNo}&insuranceCompany=${insuranceCompany}&policyIdEnc=${policyIdEnc}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async ContinuePolicyJourney(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}policy/ContinuePolicyJourney`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async saveOldPolicy(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}policy/SaveOldPolicy`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  //int? insuranceCompany, string flag, string quoteId = "", string policyId = "", string policyIdEnc = ""
  async policyDetailsByFlag(insuranceCompany?:number,flag?:string, quoteId:string ="",policyId:string ="" ,policyIdEnc:string = "") {
    return this.http.get<ApiResponse>(`${environment.APIUrl}policy/PolicyDetailsByFlag?insuranceCompany=${insuranceCompany}&flag=${flag}&applicationId=${quoteId}&policyId=${policyId}&quoteId=${quoteId}&policyIdEnc=${policyIdEnc??""}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }
  
  async DownloadPolicy(policyId:any) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}policy/DownloadPolicy?p=${policyId}`,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
}
