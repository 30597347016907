<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1>Car Insurance</h1>
            </div>
            <!-- <div class="top-line"></div> -->
            <div class="row mt-5">
                <p class="font-body mt-3">Driving on roads may be a pleasant experience, however, one has to consider
                    the safety factor equally well. Even a skilled driver cannot avoid the risk factor while driving. A
                    little bump or minor accident in such cases can result in huge loss. Thus, the only best way to
                    remain prepared against such circumstances is to buy a car insurance. also known as motor or vehicle
                    insurance is insurance for cars, trucks, cabs, and other four wheeler plus vehicles. It’s the
                    smartest way to financially secure you and your car.</p>
                <p class="font-body">A car insurance policy is not a choice, it’s a necessity and mandatory by the Motor
                    Vehicles Act in India. The primary purpose of car insurance is to provide financial protection
                    against physical damage arising from road accidents and against liability resulting from incidents
                    involving third party vehicles/property/person. A car insurance policy gives confidence to a person
                    to drive their car confidently on the road and is a boon at the time of emergencies.</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why should you buy a car insurance policy?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        A Car Insurance Policy is mandatory under the Motor Vehicle Act. Every car owner must have an
                        insurance which covers third party injury, death or property damage. Followings are the key
                        factors why you should buy a car insurance:-
                    </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Having a car insurance provides you a protection for loss
                        or damages caused to your car</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Indemnity for third party property damages upto a certain
                        limit</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It also provides personal accident cover to you, including
                        your paid driver as well as the occupants in the car</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It offers unlimited liability cover for third party injury
                        claims/third party death.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It pays hospitalization expencess in case of accident&nbsp;
                    </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Provides riders benefits such as riders, road side
                        assistance, zero depreciation and expenses are further reduced.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Cover Damage to someone else&apos;s car or property</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Car against theft, accidents, disasters such as floods,
                        earthquake, fire, etc.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why should you buy insurance online at <a href="https://Click4bima.com/">www.Click4bima.com</a>
                        ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        It is important for any policyholder to compare car insurance plans before going for 4-wheeler
                        insurance renewal or buying a new policy. Compare various car insurance plans online on
                        Click4bima and buy the one that fulfils your expectations. Following are the basic advantages of
                        taking insurance policy on Click4bima :-
                    </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Get the best car insurance policy from top Car insurers</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Instant and easy online car insurance renewal process</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Comprehensive coverage for four-wheeler vehicle</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Quick easy and most of all –reliable </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Awide range of Add-on covers for improved protection</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Easy renewal </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of Car Insurance Policy in India</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In india Car Insurance policy is available in three types</p>
                    <h3>1.Comprehensive Car Insurance Plan</h3>
                    <p class="font-body">A comprehensive car insurance is a type of car insurance policy that protect
                        your vehicle from all the unforeseen losses such as amid an accident, fire, theft ornatural
                        calamity. This type of policy covers the person financially in case of any loss is incurred as a
                        result of an unavoidable instance to the vehicle. It covers the person against theft, financial
                        loss and accidents. The cover can range from, coverage to an insured party vehicle and
                        third-party liability which means that it covers – damage to insured vehicle or theft of the car
                        also coverage of legal liability (liability to others for body or property injury by insured
                        vehicle). It is best for those wanting to cover for losses for accidents and incidents such as
                        theft and natural disasters. A car insurance policy gives confidence to a person to drive their
                        car confidently on the road and is a boon at the time of emergencies.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What is covered under comprehensive Car insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A comprehensive insurance policy can be customized to increase the level of
                        protection for your car from a variety of adversities. Following list of covers available under
                        comprehensive car insurance</p>
                    <p class="font-body">Accidental Damages – Unfortunately if your car suffers from any loss or damages
                        following an accident, comprehensive car insurance plan will cover it for you.</p>
                    <p class="font-body">Fire Damages - In case your car catches fire, this insurance plan will cover
                        any loss or damages resulting out of it.</p>
                    <p class="font-body">Car Thefts – in case of theft, your motor insurer will pay amount to your IDV.
                    </p>
                    <p class="font-body">Financial protection from Natural Calamities — In case a natural calamity
                        strikes your neighbourhood damaging your car partially or completely, then this insurance will
                        cover the damages or losses incurred. Natural calamities include cyclones, floods, earthquakes,
                        landslides, etc.</p>
                    <p class="font-body">Man-made disasters:- comprehensive car insurance will covers man made disasters
                        including riots,strike,fire etc.</p>
                    <p class="font-body">Third Party Loss - This car insurance will cover any third party damages or
                        losses caused by your car, including property damages, bodily injuries or death of a third party
                        person.</p>
                    <p class="font-body">Vandalism - If your car gets damaged or suffers from total loss due to
                        vandalism, this policy will pay for the repairs or losses incurred.</p>
                    <p class="font-body">In-transit Car Damages - In case your car gets damaged while being transported
                        via land, water or air, your motor insurer will pay for its repairs.</p>
                    <p class="font-body">Personal Accident Cover- In case of death or permanent disability in an
                        accident, the insurer compensates you.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What is Add-On Cover in Car Insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Getting involved in an accident means facing an unplanned financial crunch. A
                        basic car insurance policy type—the third-party liability insurance—does not provide enough
                        coverage. In fact, even a comprehensive car insurance plan may also miss out on a few crucial
                        liabilities. Add on covers in motor insurance come into the picture when you need extended
                        coverage. Like the name suggests, add-on covers for car insurance offer additional protection to
                        your insured vehicle, which can help you enhance your basic car insurance policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Add-on Covers in Car Insurance </h4>
                    <h4>Zero Depreciation cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Depreciation means wear and tear of your vehicle over a period of time and it
                        depreciates the value of the vehicle At the time of partial loss,</p>
                    <p class="font-body">In majority of the cases, it is the car owner who pays for the depreciation and
                        replacement of new spare parts, after an accident. However, if one wants this expenditure to be
                        covered by the insurance company, one needs to buy the Nil or Zero Depreciation add-on to avail
                        the benefits of this cover along with the existing insurance plan. The Nil or Zero depreciation
                        plan covers repair and replacement costs of rubber, plastic and fibre components of the vehicle.
                    </p>
                    <p class="font-body">A zero depreciation cover helps you mitigate this burden of bearing the
                        depreciation cost of your vehicle thereby increasing your claim amount. Most of the insurers
                        allow a maximum of 2 zero depreciation claims.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Consumable cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">At the time of claims, usually consumable elements such as grease, air
                        conditioner’s gas, lubricants clip, bearings, fuel filter, engine oil, oil filter, break oil,
                        nut and bolt, screw, washers, etc, are not covered under insurance. With this add on, you can
                        save on all those consumables that will be covered however small they might be! </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Return to Invoice </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">RTI Cover is an add-on cover offered in a comprehensive car insurance plan,
                        Generally, in case of loss or theft of vehicle you get the Insured Declared Value only. However,
                        there’s a significant difference between the IDV and ex-showroom value. In this cover consumer
                        receive full compensation, i.e. the last complete invoice value of their car, in case it has
                        been stolen or damaged beyond repair. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Who can opt for RTI cover in car insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A Return to Invoice cover is an exclusive cover offered to only new cars, or
                        ones that are below five years of age.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Engine Protection cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In a standard car insurance policy, only damages that occurred during the
                        accident are covered. However, sometimes due to heavy rains and floods your car engine could
                        have consequential damages too, after the accident. This add-on helps you protect for damages
                        caused due to that and provide Repair and part replacement costs for engine including Repairs &
                        replacement of child parts Labour costs Engine cylinder re-boring, compression tests & other
                        machining charges Repairs & replacement of internal parts.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>No Claim Bonus Protection</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">NCB proctector add-on acts like a reward to the owner of the vechile for no
                        insurance claim(s) in the previous policy tenutures. On every claim free renewal you get
                        discount in the form of No Claim bonus range starts from 20 to 50% based on your no claims
                        history. ; however, accidents are unpredictable and we understand that. An add-on cover No Claim
                        bonus protection in which on the event of accident, we shall keep your No Claim bonus discount
                        intact for the next renewal of your policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Emergency Assistance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">This add on cover provides assistance services at the time of any mechanical or
                        technical breakdown or road accident. It aims at providing round the clock road assistance for a
                        pleasurable driving experience. Further it has a bouquet of services which includes tyre
                        replacement cover, towing of vehicle to the nearest garage, emergency fuel delivery,
                        accommodation arrangement in case of break down, alternate travel arrangement, minor repairs on
                        the spot etc.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>GAP Value Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">This add-on helps you get complete invoice price of the vehicle in the event of
                        theft, total loss or constructive total loss. It also covers the Road Tax and first time
                        registration charges against an additional premium.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Tyre Protection cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Normally, damage to tyres and tubes are covered only if the vehicle has met
                        with an accident resulting in damage to the tyres and/or tubes. Damage to the tyres and tubes
                        without the vehicle meeting with an accident, is not covered under the Private Car Package
                        Policy issued to cover your car. more so considering the road conditions in India. So, getting
                        tyre protection cover makes complete sense. After taking this cover your insurance company will
                        pay for cost of repair/ replacement of tyre/ tube and/or part(s) as applicable.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Voluntary deductible cover </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In case of any claim, if you voluntarily agree to bear the stipulated amount of
                        claim, company will pay the claim amount only for damages in excess of this limit of Voluntary
                        Deductible. This way you can save money on your Motor Insurance premium, as there will be a
                        discount on your premium ranging from 15% to 35% on the Own Damage Premium. You can opt for
                        voluntary deductible amount ranging from Rs 1,500 to 15,000. By opting for this cover, you will
                        not only be paying a lower premium for your car insurance, but also be protecting the value of
                        the Car.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Key Loss Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">This cover the cost incurred in repair/ replacement of keys without deducting
                        any amount towards depreciation, including labour costs in the event your car Key(s) are lost/
                        misplaced or damaged and makes the insurance company liable to compensate the policyholder for
                        the loss of key(s).</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Tyre Protect Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The tyres of your car will be protected from any loss or damages under all
                        non-accidental situations and not just during an accident.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Return to Invoice</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">This cover allows you to get an insured declared value equal to the purchase
                        price of the car, instead of the car's current market value, in case of total loss claims. This
                        cover is ideal for people who've just bought a new car.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Breakdown Assistance Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Under this cover, you will be able to access roadside or breakdown assistance
                        from your motor insurer, in case you get stuck in the middle of the road due to flat tyre, empty
                        fuel tank, loss of keys, etc.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Consumable Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">This cover ensures that all the consumables used in your car, such as washers,
                        nuts, coolant, bolts, engine oil, etc are covered under your comprehensive car insurance policy.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Engine & Gearbox Protection</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">With this cover, the cost of repairing or replacing your engine and gearbox of
                        your car will be covered in all situations and not just under a car accident.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Conveyance Benefit</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">This cover makes you eligible to receive a daily conveyance allowance from your
                        insurer to commute by other means of transport while your car is getting repaired at a garage.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What Comprehensive Car Insurance policy does not cover ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Generally a comprehensive insurance policy provides coverage to your car at
                        maximum possible extent However, not everything is covered by comprehensive car insurance.
                        Further take a look at the list of exclusions under comprehensive car insurance policy:- </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Influence of Alcohol</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It does not cover any damages or losses incurred if you are caught driving the
                        car under the influence of alcohol.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Illegal activities</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It does not cover any loss or Accidental Damages caused while you were using
                        your car for illegal activities </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Depreciation</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">If the value of your car gets depreciated, this insurance will not compensate
                        for it.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Contributory Negligence</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It does not cover any loss or damages to your car due to contributory
                        negligence on your part. For example, taking your car near the low-rise areas despite a cyclone
                        warning.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Electrical & Mechanical Breakdown</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body"></p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Conveyance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">If your car suffers from an electrical or mechanical breakdown, then you will
                        have to pay for the resultant expenses on your own.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Regular mechanical expencess</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body"> In case you suffer any loss or damages due to the regular wear and tear of
                        your car, then you will have to pay for them on your own.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Consequential Damages</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It does not cover any consequential loss or damages suffered by your car.For
                        example, damage to the tyres while the car is being towed.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Non-Accidental Damages to Tyre/ Tube</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It does not cover any loss or damages to the tyre or tube of your car which is
                        not a direct result of a car accident.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Driving Without Valid License Holder</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">If you have a learner's license and are found driving the car without a valid
                        driving license holder, then you will have to pay for the resultant damages or losses on your
                        own.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Loss and damages that are caused due to war and nuclear risk</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It does not cover any loss or damages caused to your car as a result of war,
                        war-like operations, nuclear weapon or other related perils.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits of Comprehensive Car Insurance Policy ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A comprehensive car insurance policy protects your car from any unforeseen
                        event except collision. It also covers for losses incurred during an accident. Let’s take a look
                        at the various other benefits of this “other than collision” cover:</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It is the highest level of protection you can give your
                        car.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;If you are found to be at fault in event of an accident,
                        with comprehensive car insurance, you can claim for losses incurred in such situations.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It covers lawsuits, including legal fees brought against
                        you as a result of an accident.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Peace of mind and financial protection.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Offers financial aid in case your car is written off.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Unlike third-party insurance, it covers from damages caused
                        to your own car during an accident.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why you should buy a comprehensive car insurance policy ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Buying a car is a dream come true for many. You work hard for years and save a
                        substantial amount of your salary so that you can have the keys to that four wheeler you always
                        fancied. Once you have made the purchase, a prudent task is to safeguard your car from any harm.
                        The best way to do is to buy a car insurance. When you go and buy car insurance, you will have
                        two policy options.</p>
                    <p class="font-body">Third Party Motor Insurance Policy: Mandatory by law, it covers any damage or
                        injury caused by the insured to a third party individual or property.</p>
                    <p class="font-body">Comprehensive Motor Insurance Policy: As the name suggests, this policy covers
                        any accidental loss or damage to your car. Plus any damage or injury caused by the insured to a
                        third party individual or property.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>While you can only buy third party motor insurance policy, it’s prudent for you to buy the
                        comprehensive motor insurance policy as well. Here are a few reasons why.</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">1. You're covered against weather calamities : With unpredictable weather
                        becoming a common occurrence these days, it helps to have a comprehensive car insurance policy.
                        Take the Mumbai monsoons for instance, a few hours of rainfall and the whole city’s flooded. The
                        floods will surely cause damage to your car. It’s at this time when the comprehensive insurance
                        will come in handy, it covers your car against floods and storms and even other weather
                        calamities such as earthquakes and landslides.</p>
                    <p class="font-body">2. it covers against man-made acts as well : Apart from unpredictable weather,
                        a comprehensive car policy also covers your car against man-made harmful activities such as acts
                        of burglary or theft, malicious acts, and terrorist activities. For such acts, the insurance
                        covers your vehicle and you won't need to suffer huge financial burden for the above mentioned
                        events.</p>
                    <p class="font-body">3. it covers third party insurance : A comprehensive car insurance by default
                        covers the damage done by the insured to third party.</p>
                    <p class="font-body">4. Excellent Add-Ons and Additional Covers One of the best benefits for
                        comprehensive car insurance is the many add-ons and covers you can add. It may increase your
                        premium amount by a bit but these enhance the cover your vehicle has.</p>
                    <p class="font-body">With such benefits, it’s prudent that you purchase a comprehensive insurance
                        policy. If the cost is hindering you, a recent report states that third party motor insurance
                        will cost up to 50% more.</p>
                    <p class="font-body">Now that you will have to shell out more money for third party insurance, why
                        not put a bit more money and buy the comprehensive insurance as well?</p>
                    <p class="font-body">You make sure you buy a policy which is sound and comes with a lot of benefits.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Who Should Buy Comprehensive Car Insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">If you are confusing whether a comprehensive car insurance is suitable for you
                        or not, following is a list of people that must buy a comprehensive insurance policy for their
                        car:</p>
                    <p class="font-body">New Car Owners- Buying a car involves a major financial investment, which makes
                        it important to save your newly-bought possession from all kinds of dangers. Thus, new car
                        owners should buy a comprehensive policy for their car.</p>
                    <p class="font-body">Travelers- when you are on vacation or trip by driving your own car then a
                        comprehensive policy protect you in case any emergencies arises and also provides road side
                        assistance.</p>
                    <p class="font-body">Residents of High-Risk Areas - Some places are more dangers as compared to
                        others. For example, landslides are common in mountainous areas. Thus, people staying in such
                        areas should have a comprehensive policy to keep their cars safe.</p>
                    <p class="font-body">Fancy Car Owners If your fancy car gets stolen or is damaged in an accident,
                        you will suffer a bigger loss than people with normal cars. Owning a fancy car, such as a BMW
                        etc. , not only boosts your self-esteem but also makes you an easy target for theft. Thus, you
                        must keep your prized possession protected under a comprehensive car insurance policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Final Words</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Comprehensive car insurance is a blessing for all car owners that saves them
                        from any monetary losses in case their cars get damaged in a mishap. It is the perfect solution
                        to all your car safety and allows you to enjoy taking your car around. Don't wait up and upgrade
                        your car insurance to a comprehensive policy today.</p>
                </div>
            </div>
            <div class="row">
                <div class="mt-3">
                    <h3>2.Third Party Car Insurance</h3>
                    <p class="font-body">Third-party insurance also referred to as liability insurance essentially
                        provides financial coverage to an insured person against any liability incurred in case of any
                        loss/damage caused to third-party property or the person. This type of policy compensates the
                        damages to a third party and their property. Under motor laws of India, third party insurance is
                        compulsory. Third-party insurance covers accidental damage to the third party property or Death
                        or bodily injury to a third party by the insured vehicle So, this means that the insurance will
                        also cover the third person who may have underwent an accident or have incurred some loss due to
                        the accident. The policy is different in a way that it does not cover any loss or damages to the
                        insured car due to theft, accident, etc.</p>
                    <p class="font-body">The law mandates that for every vehicle purchase it is compulsory to have
                        third-party insurance. As per the rules of the Motor Vehicles Act 1988, the Insurance Regulatory
                        and Development Authority (IRDA) of India computes the third party damages.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How Does Third-Party Car Insurance Work?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        In order to better understand the concept of third-party car insurance, let us look at some
                        terminologies. For example, in the event of a car accident, the parties involved are as follows:
                    </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;First party: The insured person or policy holder.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Second party: The insurance company.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Third party: The person who claims for the damages caused
                        by the first party.</p>
                    <p class="font-body">In an event where an insured person with a third party insurance policy is held
                        legally liable for injuries or damage done to a third party, then his/her insurance company
                        indemnifies the insured person. Any losses arising due to damages or injury caused by the
                        insured to a third party or third party’s property, are covered under the third-party insurance
                        policy. In the event of a car accident, an insured person with a third party insurance policy is
                        required to immediately inform the insurance company of the incident. If you were responsible
                        for the accident (or the other driver believes you were responsible), it's almost certain that a
                        claim will be made against you, which your insurance company will be expected to pay. So, for
                        the speedy resolution of the claim it is of utmost importance that the insurance company is
                        intimated about the accidental claim at the earliest.</p>
                    <p class="font-body">Further Insurance companies cover two kinds of motor Third Party Insurance
                        claims — bodily injury liability and property damage liability.</p>
                    <p class="font-body">Third party bodily injury liability claim arises out of the insured causing
                        physical injuries to another person with his vehicle. Such claims provide coverage for
                        hospitalization expenses, pain & suffering, loss of income as well as death or permanent
                        disability as a result of the accident.</p>
                    <p class="font-body">Third party property damage liability claim covers the property damage or
                        complete loss of property caused by the insured vehicle. It includes claims related to damaged
                        landscapes, such as a ruined fence, front lawn, etc. damaged property such as mailboxes, the
                        fence gates, etc. as well as replacement of structures , such as house etc. This is how a
                        third-party motor insurance works.</p>

                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What Does a Third-Party Car Insurance Policy Cover? </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A third-party insurance of car covers for the expenses incurred due to the
                        following reasons: </p>
                    <p class="font-body">Third Party Liabilities : Third party liabilities arise out of any loss or
                        damages caused by the insured car to a third party during an accident. Since the accident is
                        caused by the insured car, paying for the damages is the responsibility of the insured car's
                        owner. Third party liability insurance covers legal liabilities arising out of causing</p>
                    <p class="font-body">Damage to third-party property : In case you, unfortunately, ram your car in a
                        third party person’s property, such as the home,boundary wall or a shop, your third party
                        insurance policy will pay for the loss or damages sustained by that person.</p>
                    <p class="font-body">Damage to a Third-Party Vehicle : because of the insured vehicle If you
                        accidentally hit a third party person‘s car with your own while driving, your insurance policy
                        will come to your rescue and pay for the damages caused to that person's car on your behalf.</p>
                    <p class="font-body">Death or bodily injury to a third party : In case you accidentally hit a third
                        party person with your car, your third party insurer will pay for the treatment of his bodily
                        injuries.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Personal Accident Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A few motor insurance companies also offer personal accident cover to the
                        owner-driver of the insured car. As part of this cover, the owner-driver of the car is granted
                        with compensation in case he ends up suffering from a disability or dies as a result of a car
                        accident. In case of death of the policyholder, the compensation is paid to the nominee
                        appointed by the car owner.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What Does a Third-Party Car Insurance Policy Not Cover?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">When opting for a third party only car insurance policy, you must be aware of
                        what benefits you’re missing out on. Let’s take a look at the following most common exclusions
                        that third party car insurance does not cover: </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Cost of damages to a vehicle or the belongings in the
                        vehicle in case of an accident.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Accidental damage/liability/loss outside any particular
                        geographical boundary.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Any Claims arising out of contractual liability.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Cover for losses incurred if your car or belongings are
                        stolen or destroyed.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;In case vehicle was driving by any individual, other than
                        the owner or the designated driver.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;If the third party sustains accidental loss or damage,
                        which has accrued from any substantial loss.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Liability, caused by a nuclear weapon or radioactive
                        contamination, directly or indirectly.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Claim arising when the policy is inactive .</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Claim arising when the driver is not carrying a valid
                        driving license at the time of the accident</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Advantages of a Third-Party Car Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">There are several advantages of third-party car insurance. Let’s look at the
                        advantages that you will be eligible for in case you buy third-party car insurance: </p>
                    <p class="font-body">Fullfil Mandate Requirements : Under the Motor Vehicles Act it is necessary to
                        insure your car if you want to drive on Indian roads. Getting basic third-party car insurance
                        will protect you from hefty fines.</p>
                    <p class="font-body">It Will Save You Time :Third-party car insurance is very easy to purchase
                        online with us in just a few clicks. This will save you time and money as you can buy this plan
                        from the comfort of your home. </p>
                    <p class="font-body">Personal Damages coverages : In case of an unfortunate event like an accident
                        where you hurt someone physically or in severe cases, cause the death of the third person. Your
                        third-party car insurance will provide coverage for the losses up to unlimited liability**.</p>
                    <p class="font-body">Provides Third-Party Property or Vehicle Damages coverage: In case of an
                        accident where it causes damage to one’s vehicle or property, your third-party car insurance
                        will provide coverage.</p>
                    <p class="font-body">Protects you in case of any physical injuries : In case you don’t have a
                        personal accident cover, you can opt for it with your third-party car insurance. This way you
                        can protect yourself and your family financially in the case of permanent disability or death
                        due to an accident.</p>
                    <p class="font-body">Protectection from Unexpected Losses: Indian roads are congested, and you can
                        see several cars on the roads. So, an unfortunate event like an accident that is bound to
                        happen. If you opt for a third-party car insurance policy, you don’t have to worry about the
                        expenses that might incur in case of an unexpected accident loss. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Disadvantages of Third-Party Car Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Nothing is perfect, not even your third-party car insurance as it comes with
                        several disadvantages as well. Here is the look at the disadvantages that you might face if you
                        buy third-party car insurance:</p>
                    <p class="font-body">Doesn't cover your own damages </p>
                    <p class="font-body">Your third-party car insurance will not provide coverage against the damages
                        and losses to your own car. To protect your car financially, you need to opt for a separate
                        comprehensive car insurance policy from reputable insurers. </p>
                    <p class="font-body">Doesn't cover damages due to natural calamities </p>
                    <p class="font-body">No one can predict the future. Natural calamities such as floods, earthquakes,
                        tsunami, etc. are some of the unfortunate events that no one has control on. In case your car is
                        damaged due to a natural calamity, your third-party car insurance will not cover it. However, if
                        you are opting comprehensive car insurance will surely protect you financially against the
                        damage caused to your car due to both natural and man-made calamities. </p>
                    <p class="font-body">No Protection from Theft/Fire</p>
                    <p class="font-body">Besides accidents, a car is constantly under the risk of getting stolen or
                        catching fire. In case your car catches fire or gets stolen, your third party insurance policy
                        will be of no use to you as it will not pay for the loss of your car.</p>
                    <p class="font-body">You can’t opt for add-ons </p>
                    <p class="font-body">There are several add-ons that enhance the coverage of your plan, but you can
                        only opt for them in case you buy a comprehensive car insurance plan and not with third-party
                        car insurance. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How to File a Claim for Third Party Car Insurance? </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Given below are the step wise points that you need to consider in case of a
                        third-party insurance claim:</p>
                    <p class="font-body">1.Application: In an unfortunate event like an accident, the third-party must
                        file an FIR immediately after the accident takes place. You must retain a copy of the said FIR
                        and the Charge sheet filed by the Police. On happening of an accident causing injuries to the
                        third party, you need to inform the police and your insurance provider, without any delay and
                        share the valid documents of the vehicle including the driving licence of the driver.</p>
                    <p class="font-body">2.file an FIR : In case of an accident, where you are not at fault or you have
                        been impleaded in an accident, though not involved, file an FIR with the police against the
                        person/s who have caused the accident or have impleaded you as a party to the case and defend
                        the case in the court along with your insurer. </p>
                    <p class="font-body">3.Register the case with Motor accidents Claims Tribunal: In India, Motor
                        Accident Claims Tribunal decides the claim for compensation amount to a third party. In case of
                        personal injuries to a third-party, there is no upper limit, whereas, there is limited liability
                        of insurance up to Rs 7.5 lakhs in the event of losses and damages caused to third-party
                        property or vehicle depending on the involvement of the vehicle insured. A claims petition
                        seeking compensation is filed by the third party before the concerned Motor Accident Claims
                        Tribunal and the judicial proceedings start wherein the vehicle owner, driver and insurer is a
                        party to the case.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;If the damages and losses caused due to an accident are
                        minor, then it is advisable to settle it outside the court as dealing with the court proceedings
                        is a time-consuming process. </p>
                    <p class="font-body">4.Get the Compensation amount: When the matter is decided by the Tribunal and
                        there no violation of the insurance policy and in case everything is in order, the compensation
                        amount awarded by the Tribunal will be paid by your insurance provider to the third party.</p>
                    <p class="font-body">In case of a claim related to personal accidents, all you need to do is call
                        customer care number and your insurer will take care of the rest. </p>
                    <p class="font-body">Disclaimer: The above information is for illustrative purpose only. For more
                        details, please refer to detailed policy wordings.</p>
                    <p class="font-body"></p>
                </div>
            </div>
            <div class="row">
                <div class="mt-3">
                    <h3>3. Pay As You Drive Insurasnce</h3>
                    <p class="font-body">A "Pay As You Drive" product is a comprehensive own damage plus third party
                        policy. It uses a telematics device to monitor how often the car is used depending on the total
                        number of kilometres covered. Under this type of car insurance policy premium is charged based
                        on the usage of the car.</p>
                    <p class="font-body">It is a newly-launched usage-based car insurance plan for private cars under
                        the sandbox project of The Insurance Regulatory and Development Authority of India. The project
                        is currently available on a pilot basis for one year.</p>
                    <p class="font-body">The pay as you go car insurance is considered more affordable as compared to
                        regular car insurance plans. This is because the premium under this plan is charged based on the
                        kilometres driven.</p>
                    <p class="font-body">Features of Pay As You Drive Insurance Policy</p>
                    <p class="font-body">Following are some of the most interesting features of pay as you go car
                        insurance:</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;More affordable as compared to regular car insurance.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Part of the pilot sandbox project of IRDAI.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Tenure of one year.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;IRDAI decides the third party premium.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Own damage premium depends on car usage slab as per the
                        total kilometres covered.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How Does Pay As You Drive Insurance Work?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The pay as you go car insurance functions slightly different than a regular car
                        insurance policy. Take a look at how pay as you drive insurance works:</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;First, you need to declare the car usage for the policy
                        period of one year based on the total number of kilometres that your car will cover. You can
                        choose one of the car usage slab options provided by your insurance company. </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Your insurer will install a telematics device in your car
                        without charging any money for it.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The premium will be charged based on the kilometre slab you
                        pick and the add-on covers you choose.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The telematics device will track the kilometres covered by
                        the car and show the remaining kilometer balance. It will also monitor how the car is being used
                        and the driving habits of the driver.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Key Benefits of Pay As You Go Car Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The pay as you drive insurance comes with several benefits that will make you
                        fall in love with it. Take a look at the benefits of pay as you go car insurance below:</p>
                    <p class="font-body">Lower Premiums</p>
                    <p class="font-body">The premium amount under the pay as you drive insurance is calculated based on
                        the total number of kilometres covered by your car. Besides, the usage slab is pretty low.
                        Therefore, the premium turns out to be much lower than that of regular car insurance.</p>
                    <p class="font-body">Free Telematics Device Installed</p>
                    <p class="font-body">Under this type of car insurance plan, a telematics device is installed in your
                        car free of cost. This device constantly monitors the condition of your car and the driving
                        habits of the insured.</p>
                    <p class="font-body">Custom Coverage</p>
                    <p class="font-body">This insurance allows you to customize your car insurance cover as per your
                        preference by opting for add-on covers. Moreover, it also allows car owners to shift to a higher
                        usage slab or convert into a regular own damage cover if they have exhausted their car usage
                        limit, provided they pay the additional premium.</p>
                    <p class="font-body">Discounts on Premium</p>
                    <p class="font-body">Under the pay as you drive insurance, premium discounts are offered by
                        insurance companies on own damage insurance premium. Car owners can get a minimum of 5% discount
                        to up to 25% discount depending insurance company.</p>
                    <p class="font-body">Usage-based car insurance</p>
                    <p class="font-body">The cost of your insurance is based on a usage-based car insurance policy. So,
                        if you don’t use your car much during a year, you can pay a lower premium.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Who Should Buy Pay As You Drive Insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Car usage varies for different kinds of people. Some may use them daily, while
                        others may not use it as much, Taking cognizance of varying car usage behaviour of car owners,
                        the pay as you drive insurance has been designed for the following kinds of people</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Ideal for those customers who have multiple vehicles and
                        may not use each vehicle as much.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Useful for those who commute daily via public transport or
                        frequently travel beyond city limits and rarely use their personal vehicle.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It is perfect for people who own multiple cars and do not
                        use all the cars in equal measures.</p>
                    <p class="font-body">How does pay as you drive work?</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp; You pick a plan ,which will be valid for a year, depending
                        on the kilometers you cover upto 3000,5000 & 7500km.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Your insurer will provide you with the telematics device
                        and it’ll take care of the installation.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;You can customize your plan with your choice of add-on
                        covers.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;You can even track your usage , remaining balance and more
                        on the telematics device app.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;You can also renew your plan within the policy period , if
                        you exaust your balance.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What, if the Declared Car Usage Limit is Exhausted?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In case your car usage limit gets exhausted, you can recharge it in between the
                        policy tenure or at the time of policy expiry. Pay as you drive insurance also gives you the
                        choice of moving to a higherkilometre usage slab or shifting to a regular own damage car
                        insurance, provided no claims have been made. However, you will be required to pay the
                        difference in the premium amount.</p>
                    <p class="font-body">Moreover, the third party cover under the pay as you drive insurance will
                        continue to remain active even though the own damage cover will cease to exist until the plan is
                        renewed. This means you cannot make any own damage claims if you have driven your car more than
                        the declared kilometre limit.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why Should You Buy The ‘Pay as you drive’ Insurance Policy?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Build better and safe driving habits: The ‘Pay as you drive’ insurance policy
                        instils safe driving habits as the driver is made aware that his/her driving habits are analyzed
                        by the device and considered by the insurance company for determining the premium amount. Also,
                        the insurance policy offers bonus kilometers at the renewal of the policy for good driving
                        behaviour.</p>
                    <p class="font-body">Advantages of fitting a telematics device: The telematics device that comes
                        free of cost with the policy offers a lot of benefits in terms ofdetecting theft, offering
                        roadside assistance, and tracking the vehicular movement. The device also contains motor sensor
                        support and generates fuel-saving reports, and guards against dangerous driving and fuel
                        slippage in the vehicle.</p>
                    <p class="font-body">Save money on premiums: Unlike traditional motor insurance policies, where
                        premiums are based on the car model and the year of manufacturing, this policy is based on the
                        usage of the vehicle and the overall driving habits. In short, if a person drives carefully,
                        (s)he will end up paying a lesser premium than (s)he would have paid in his/her standard motor
                        insurance policy.</p>
                    <p class="font-body">Ideal for people who own multiple cars: Many people who own multiple cars end
                        up burning their pockets for paying premiums even when their cars are not in regular use. This
                        policy is beneficial for such individuals as they can save premium on their vehicle's insurance
                        policy.</p>
                    <p class="font-body">Discounts: Some insurance companies are offering a discount based on the slabs
                        chosen. For e.g., Bharti AXA is offering a discount of 25% to those who pick up the 2,500 km
                        slab, 15% for 5,000 km slab, and 10% for 7,500 km.</p>
                    <p class="font-body">Documents need be submitted for availing of this insurance policy</p>
                    <p class="font-body">To buy a ‘pay as you drive’ insurance policy, you need to submit the following
                        details to the insurance company:</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Odometer reading.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Customer consumer consent form.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;KYC details.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Featurs of car insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It is most important to know all the details about any insurance policy.
                        Following are the features of a car insurance policy:</p>
                    <p class="font-body">Third-party insurance covers accidental damage to the third party property or
                        Death or bodily injury to a third party by the insured vehicle. So, this means that the
                        insurance will also cover the third person who may have underwent an accident or have incurred
                        some loss due to the accident. The policy is different in a way that it does not cover any
                        damage which could have done to your vehicle. The law mandates that for every vehicle purchase
                        it is compulsory to have third-party insurance.</p>
                    <p class="font-body">Comprehensive Car Insurance Policy or Package Policy</p>
                    <p class="font-body">A Comprehensive package policy as the word signifies complete, offers extensive
                        protection due to accidental damages to both parties involved . It provides coverage to vehicle
                        against ,damage to the vehicle , theft , natural disaster along with coverage against third
                        party liability for body or property injury by insured vehicle. Though this is not a mandatory
                        policy ,however it is advised to have a comprehensive coverage for once vehicle to safe guard
                        self from financial burden arising in case of an accidental damage to your car or to third
                        party.</p>
                    <p class="font-body">Insured Declared Value</p>
                    <p class="font-body">All the vehicles are insured for a value called Insured Declared value which is
                        based on the price of the vehicle subtracted by the depreciation faced due to vehicles age. It
                        is the maximum amount your insurer will approve if your vehicle is stolen or is damaged beyond
                        repair. For example, if the market value of your car is Rs. 5 lakh when you buy the motor
                        policy, the insurer will approve a claim of a maximum amount of Rs. 5 lakh.</p>
                    <p class="font-body">Premium</p>
                    <p class="font-body">Premium depends on the vehicle model, capacity, age, fuel type, the city you
                        stay in, your age, profession, a modification made or accessories added. Premium saving options
                        are also available by showing that you haven’t made any claim in the past year</p>
                    <p class="font-body">Choose the best car insurance that suitable for you</p>
                    <p class="font-body">Now that you understand the nuances of all types of Car Insurance Policies you
                        must be contemplating about which one to choose. Here are some valuable recommendatory points to
                        help you with your decision-</p>
                    <p class="font-body">Coverage - Checkout the inclusions and exclusions of both third party insurance
                        and comprehensive vehicle insurance policy. Only buy third party insurance if you can bear your
                        own damage expenses.</p>
                    <p class="font-body">Compare Car Insurance - Compare car insurance online and select the one that
                        meets most of your financial expectations. You can easily get multiple four wheeler insurance
                        quotes online from the best car insurance companies in India.</p>
                    <p class="font-body">Claim Ratio - Higher CR, indicate satisfied customers and more the chances of
                        your claim being settled.</p>
                    <p class="font-body">Add-on Covers - It is always recommended to buy a comprehensive car insurance
                        policy with additional benefits like roadside assistance, zero depreciation, flat tyre
                        assistance, etc.</p>
                    <p class="font-body">The Third Party Liability Car Insurance policy is generally chosen by
                        individuals who want to buy car insurance as it is mandatory by law.If you seek overall
                        protection from the damages caused due to unforeseen accidents, opting for a comprehensive car
                        insurance policy is the best option. Additionally, you can pick add-on covers for car insurance
                        as per the risks which are more relevant to you. And also, compare car insurance policies before
                        buying to purchase the one which suits you the best.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Important car insurance terms you should know</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Information is the key to make the right decisions. To ensure that you make an
                        informed purchase, we have explained the essential car insurance concepts right here-</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>IDV</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The IDV (Insured Declared Value) is the current market value of the car which
                        is calculated after deducting the depreciation amount. It is a crucial component of the car
                        insurance policy as it determines the amount of compensation. In case of theft or total
                        destruction of the car, the IDV is offered as “Sum Insured”.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>No Claim Bonus</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">When you don't file a claim during your policy period, you will be eligible for
                        a discount on your next premium which is referred to as a No Claim Bonus. This discount will
                        keep increasing progressively for each claim-free year until thecar insurance premium is reduced
                        up to 50%.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Nil Depreciation Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">You may already know that the monetary value of your car reduces overtime. This
                        eventuality is a result of asset depreciation. you can combat the losses of depreciation by
                        taking up a nil depreciation add-on cover along with your car insurance. With nil depreciation
                        cover also known as zero depreciation cover, you can be assured that the entire claim amount
                        will be paid to you without deducting the depreciation sum.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How is a car insurance premium calculated?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Factors upon which car insurance premium is based:-</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Car Manufacturer and Model</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Cubic capacity (cc)</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Age of the Car</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Insured Declared Value (IDV)</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;No Claim Bonus (NCB)</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Tax</p>
                    <p class="font-body">Other factors like add-on covers, discounts, purpose of the vehicle and safety
                        features such as airbags and anti-theft devices will also be taken into consideration while
                        calculating your car insurance premium.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits of Buying and Renewing Car Insurance Online</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A Motor Insurance Policy generally has a fixed validity after which they need
                        renewal to get the insurance going. Renewal of car insurance is possible both online and
                        offline, however the recommended method for the renewal these days is online as there are
                        several benefits of doing so. Apart from renewing, you can also buy car insurance online. The
                        advantages of buying and renewing car insurance online include:</p>
                    <p class="font-body">Time-Saving : With the help of the internet, one could easily get his/her car
                        insurance renewed online which would save a lot of time and struggle in going to the office and
                        filling out various forms and details to renew the insurance. These details can be registered
                        online and thus are used to renew the insurance. Also, the payment can be made online and a new
                        renewed insurance paper will be generated, a copy of which would serve for the onginal purpose.
                        Offline renewal requires one visit to the insurer's office or to contact the agents which could
                        often be tiring and a long process. The online insurance process is paperless and thus is much
                        more efficient.</p>
                    <p class="font-body">Safe process : Renewal of the policy online is a secure process as you have all
                        the details in front of you which you can read yourself at your speed. In offline insurance
                        people generally listen to the agents and sign the paper which can cause a dispute at a later
                        stage. Also, payment through secure payment gateways online means that the person will have
                        protection against any kind of fraud or risks.</p>
                    <p class="font-body">Zero Paperwork : Another benefit of buying four wheeler insurance policy online
                        is zero paperwork. Contrary to the offline mode which requires you to fill multiple forms, the
                        online mode allows you to fill up all the forms online. You can also upload the required
                        documents online making the application process digital and free of any paperwork.</p>
                    <p class="font-body">Customisation : This is an added benefit of having online coverage. One could
                        easily customize their coverage by taking some add- ons at any point in time. The policy premium
                        depends on the type of coverage that you have opted for while taking the insurance. The more
                        secure way you opt for, the more is the premium thus it is important to find a good balance
                        between the premium you can afford which is also secure.</p>
                    <p class="font-body">Company Switch : At the time of renewal, the features, as well as the insurance
                        company, could be changed to a completely new one. If you feel that any specific company is
                        providing better coverage of the policy at an affordable premium which is better than your
                        current plan, then you are more than welcome to switch the insurance company at the time of
                        renewal. So, at the time of renewal, it is important to do proper research to find out the best
                        insurance which you can get.</p>
                    <p class="font-body">Payment Reminders : Missing policy payments or renewals can cost you a great
                        deal. Not only will you lose out on the renewal discounts but also have a break in policy. But
                        if you purchase your car insurance policy online, you will receive timely reminders ahead of
                        your due date ensuring that you do not miss out on the payments.</p>
                    <p class="font-body">Thus, there is no better means than to buy or renew car insurance online with
                        which you can also enjoy a lot of other benefits.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How to Minimize Car Insurance Policy Premium?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Know Your Car's IDV : The value of your car depreciates overtime. Hence it is
                        important to know your IDV. A lower IDV attracts a lower premium.</p>
                    <p class="font-body">Claim Your No Claim Bonus: The No Claim Bonus is your rightful reward for being
                        a responsible driver. Earn discounts of up to 50% on your premium year-on-year.</p>
                    <p class="font-body">Install Security Devices The safer your car, the lower will be the premium.
                        Installing anti-theft devices can fetch you up to 2.5% discount on your car insurance premium.
                    </p>
                    <p class="font-body">Become AAI Member: Become a member of the Automobile Association of India and
                        you can secure a concession on personal-damage premium.</p>
                    <p class="font-body">Increase the Voluntary Deductible: A voluntary deductible is the amount you
                        agree to pay out of your pocket. The higher your voluntary deductible, the lesser will be your
                        premium. Know what are deductibles for car insurance before purchasing.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What does the Car Insurance policy not Cover?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A Car insurance policy does not cover the following :</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;General wear-and-tear of the car</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Cars being used other than in accordance with the
                        limitations as to use. For example, if you use a private car for remuneration purpose.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Mechanical and electrical breakdown</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Damage to/by person driving without a valid driving license
                    </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Loss or damage caused while driving under the influence of
                        alcohol or any other intoxicating substance</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Loss or damage due to depreciation of the car's value</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Loss or damage due to war or nuclear risks</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Consequential loss - if the original damage causes
                        subsequent damage loss, only the original damage will be covered under our motor car insurance
                    </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Compulsory deductibles - a fixed amount that gets deducted
                        at the time of the claim</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Depreciation Cover shall not be applicable to theft & total
                        loss claims</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>ZERO DEPRICIATION POLICY</h4>
                    <h4>What is Zero Depreciation?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A zero depreciation insurance is a comprehensive insurance policy, with the
                        zero depreciation add-on cover included. This implies that if your vcehicle gets damaged
                        following a collision, no depreciation is subtracted from the coverage of wearing out of any
                        body parts of vehicle excluding tyres and batteries. Zero depreciation insurance policy offers
                        100% coverage for all fibre, rubber and metal parts without deduction of depreciation. It does
                        not cover engine damage due to water ingression or oil leakage. Any mechanical breakdown, oil
                        change or consumables are also not covered in this policy. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Who Should You Buy Zero Depreciation Cover?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Zero depreciation insurance proves to be beneficial to: </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;People living in accident-prone areas</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;People having luxury cars / New Cars</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;If you worry about small bumps and dents</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;If you have a car with expensive spare parts</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;If you want to reduce your pocket expences</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Regular car users</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How is Depreciation Calculated? </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Depreciation is the decrease in cost of your car, due to its natural wear and
                        tear due to its age. The older your car is, higher the depreciation will be.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Who Should You Buy Zero Depreciation Cover?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">According to the Insurance Regulatory & Development Authority Of India (IRDAI)
                        following are the depreciation rates, on the basis of which the total depreciation of your car
                        is calculated: </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Rubber, Nylon, and Plastic Parts, and Batteries: 50%</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Fiber Glass Components: 30%</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Wooden Parts: 5% in the first year, 10% in the second year,
                        and so on.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits of Zero Depreciation Insurance Add-on</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Save Money</p>
                    <p class="font-body">Having a Zero Depreciation Addon ensures that you do not have to shell out
                        money from your pocket in case there is a claim, as without a Zero Dep addon the cost of
                        depreciation of the parts has to be borne by you. But with a Zero Dep Addon, that is taken care
                        by your insurance company.</p>
                    <p class="font-body">Get a Higher Claim Amount</p>
                    <p class="font-body">Securing your vehicle with a zero depreciation add-on means the depreciation on
                        your vehicle’s parts won’t be calculated and hence you’ll receive a higher amount during claims.
                    </p>
                    <p class="font-body">Peace of Mind</p>
                    <p class="font-body">You can be assured of a complete peace of mind. Also, you can save yourself a
                        lot of hassle by purchasing a nil-depreciation cover by paying a little extra premium. A zero
                        depreciation addon ensures you don’t unnecessarily spend from your pocket during claims and
                        honestly just being assured that someone will have your back during your unfortunate times can
                        be so satisfying and really gives you some peace of mind.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>The Add-on Comes at an Affordable Cost</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">While the Zero Dep Insurance is offered with an additional premium, the cost is
                        low and affordable. You can compare the Nil Depreciation premium online and then choose the plan
                        with the lowest premium. Additionally, by including the Zero Depreciation Add-on to the
                        Comprehensive Insurance, your money is well-protected at an affordable cost.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Zero Depreciation Cover Vs Comprehensive Insurance Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Let’s quickly look at how a zero depreciation cover varies from a Comprehensive
                        cover:</p>
                    <p class="font-body">Zero Depreciation</p>
                    <p class="font-body">ZComprehensive Insurance Cover</p>
                    <p class="font-body">A zero depreciation cover is an optional addon you can opt for in your car
                        insurance policy. Having this addon in your plan ensures your insurer won’t charge for your
                        car’s depreciation during claims and hence, you won’t be liable to pay for the cost of
                        depreciation of your car’s parts during claims. A comprehensive car insurance policy is a type
                        of car insurance policy that covers for your car’s own damages and third-party damages. This
                        type of policy can further be customized for extensive coverage.</p>
                    <p class="font-body">A zero depreciation cover is meant for new cars</p>
                    <p class="font-body">Whereas a normal car insurance cover can be taken for cars older than 3 years.
                    </p>
                    <p class="font-body">Having a zero depreciation cover means you don’t need to pay for the cost of
                        depreciation during your car insurance claims. In a comprehensive car insurance policy, you will
                        be required to pay for the cost of depreciation of your car’s parts during car insurance claims.
                    </p>
                    <p class="font-body">The premiums to be paid for a zero depreciation cover are higher</p>
                    <p class="font-body">The premiums to be paid for a comprehensive cover are less than Zero Dep</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What is not covered in a Zero Depreciation Addon?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Driving without a Driver’s License</p>
                    <p class="font-body">You won’t be eligible to receive the benefits of a zero depreciation addon if
                        you don’t have a valid car license.</p>
                    <p class="font-body">Cars More Than 5 Years Old</p>
                    <p class="font-body">Unfortunately, a zero depreciation addon cannot be opted for if your car is
                        more than five years old. </p>
                    <p class="font-body">Driving Under the Influence of Alcohol</p>
                    <p class="font-body">Those driving under the influence of drugs or alcohol are excluded from
                        benefiting from a zero-depreciation cover during claims. </p>
                    <p class="font-body">Doesn’t Cover Mechanical Breakdowns</p>
                    <p class="font-body">As a standard rule, a zero depreciation cover won’t cover for mechanical
                        breakdowns or normal wear and tear of your car.</p>
                    <p class="font-body">Doesn’t Cover Compulsory Deductibles</p>
                    <p class="font-body">A zero depreciation cover doesn’t cover for your compulsory deductibles (if
                        any) included in your car insurance plan.</p>
                    <p class="font-body">Cost of Engine Oil</p>
                    <p class="font-body">This addon doesn’t cover for costs such as engine oil, clutch oil, coolant,
                        etc.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Factors To Consider Before Opting For a Zero Depreciation Cover:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The following are important factors to consider before choosing to buy the Zero
                        Depreciation Add-on Cover:</p>
                    <p class="font-body">Age of The Car</p>
                    <p class="font-body">The Zero Depreciation Car Policy is applicable for cars under 5 years old only.
                        However, some insurers offer the cover for cars above 5 years old. Ensure you check with the
                        insurance company before opting for the Add-on.</p>
                    <p class="font-body">Premium Of the Policy</p>
                    <p class="font-body">With Zero Depreciation offered as an Add-on to the Comprehensive Car Insurance
                        Policy, the premium is bound to increase Consider the increase in the premium before choosing
                        the Add-on. However, if you have a luxury car or live in a high-risk area, you should consider
                        adding Zero Depreciation cover to your car insurance policy. This is because the premium for the
                        Add-on is based on the age and model of the car, and the location of the vehicle.</p>
                    <p class="font-body">Compulsory Deductibles</p>
                    <p class="font-body">While settling claims, the Add-on covers only the cost of the car part’s
                        depreciation and does not cover compulsory deductibles.</p>
                    <p class="font-body">Limit of Claims</p>
                    <p class="font-body">You can only raise a limited number of claims during the policy period. Hence,
                        check with the insurance company about the limit of claims before choosing the Add-on.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How to Renew Zero Dep Car Insurance Online at Click4bima ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Online Car insurance renewal is a simple and hassle-free process. Let’s see how
                        it is done :</p>
                    <p class="font-body">Keep a track of the renewal date : It is recommended for the policyholder to
                        keep a record of the same so that the policy is renewed before the due date.</p>
                    <p class="font-body">Enter the required policy details : While renewing zero depreciation car
                        insurance online, there is
                        no
                        need of any paperwork, cheque submission etc. It can be done online on <a
                            href="//www.Click4bima.com">www.Click4bima.com</a> by clicking on the policy renewal option
                        easily.</p>
                    <p class="font-body">Follow the instructions : Submit all the required details, fill the form step
                        by step and click on the payment option</p>
                    <p class="font-body">Make the payment online : Make payment through online using a debit or credit
                        card.</p>
                    <p class="font-body">Renewal : The insurer will send an acknowledgement of the zero depreciation car
                        insurance renewal on your registered email id and phone number.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Insured Declared Value(IDV)</h4>
                    <h4>What Is Insured declared value ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">IDV or Insured Declared Value is the maximum Sum Assured fixed by the insurer
                        that is provided on theft or total loss of vehicle in the event of an accident. It is the
                        existing market value of t the vehicle after deducting the depreciation amount and vehicle
                        registration cost which helps in determining the right claim settlement amount. In case your
                        vehicle suffers a total loss in an accident, or is stolen, IDV is the compensation that the
                        insurer will provide to the policyholder.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>IDV in Car Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In a Car insurance policy, the Insured Declared Value or IDV is the value of
                        the Car. The IDV is a determining factor when it comes to the amount that the Car owner can
                        claim in the event of loss or damage owing to an unfortunate incident. An IDV (Insured Declared
                        Value) in Car insurance is nothing complicated but, refers to the market value of your Car. In
                        other words, it is the amount your Car could receive in today’s market. </p>
                    <p class="font-body">This IDV in Car insurance helps your insurer to determine your claim amounts
                        correctly during claim payments. Additionally, it also helps insurance company to determine the
                        right premium price for your Car insurance. </p>
                    <p class="font-body">Let’s put it across with a simple example-say you buy a brand-new Car for Rs 10
                        lakh (excluding the cost of registration, road tax, insurance, accessories et al.). Your IDV at
                        the time of purchase will be 10 lakh since your Car is brand new. But as your Car gets older,
                        its value starts to depreciate, and so does the IDV. So, say your Car is worth Rs 6,50,000 after
                        two years. Your IDV will also be Rs 6,50,000.</p>
                    <p class="font-body">Now, coming to the main point where most people get confused. IDV calculates
                        the depreciation of your vehicle according to the ‘manufacturer’s specifications’ or ‘the amount
                        the manufacturer values your Car at’ and NOT the value you can personally sell the Car at i.e.
                        someone may offer to buy your Car for even Rs 8,50,000, but the IDV will still stand at
                        6,50,000. </p>
                    <p class="font-body">Age of the vehicle % of Depreciation for adjusting Insured Declared Value (IDV)
                        of the Car</p>
                    <p class="font-body">New Car – between o to six months 5%</p>
                    <p class="font-body">Between six months to one year 15%</p>
                    <p class="font-body">Between one year to two years 20%</p>
                    <p class="font-body">Between two years to three years 30%</p>
                    <p class="font-body">Between three years to four years 40%</p>
                    <p class="font-body">Between four years to five years 50%</p>
                    <p class="font-body">6th year onwards IDV is mutually agreed between insured and insurance company
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What are the factors that help determine your Car's IDV?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Age of the Car: Since the IDV represents the market value of your Car, The age
                        of your Car is one of the biggest factors that help determine the IDV of your Car. The older
                        your Car is, the lesser will be its IDV and vice versa. </p>
                    <p class="font-body">Manufacturer Make and Model of the Vehicle: The make and model of your Car is
                        directly influenced to your IDV. It helps to determine how high-end is the Car and how high
                        would be its repair cost. For example; a Car like a Audi Q7 will have a higher IDV than that of
                        an Hyundai i 20 One due to its difference in make and model.</p>
                    <p class="font-body">City of Registration Details: Your Car registration details are available on
                        your registration certificate. Also, the city where your Car is registered has an impact on its
                        insured declared value. The IDV of your Car in a metro city may be less than its IDV in a
                        tier-II city.</p>
                    <p class="font-body">Standard Depreciation (As per Indian Motor Tariff): Your Car’s value
                        depreciates from the moment you drive it out of the showroom– and the percentage of its
                        depreciation increases with each year. This too ultimately affects your IDV. Have a look at
                        table to help you understand the respective depreciation rates with context to your Car’s age.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Advantages of decreasing/increasing IDV?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">High IDV: High IDV means High premium but you get a higher compensation at the
                        time of loss or theft of your insured Car.</p>
                    <p class="font-body">Low IDV: Low IDV means low premium but this little saving on the premium can be
                        a big loss for you at the time of loss or theft of your insured Car.</p>
                    <p class="font-body">Significance of IDV in Car Insurance</p>
                    <p class="font-body">Your IDV is the market value of your Car, and hence directly affects your
                        premium for your Car insurance.</p>
                    <p class="font-body">During claims, compensations are paid out based on the value of your Car. After
                        all, repair or replacement expenses would be based on the same. Therefore, this is one of the
                        most crucial factors in your Car insurance so that in times of need, you receive the right
                        amount of compensation for loss and claim made.</p>
                    <p class="font-body">If your Car is stolen or damaged beyond repair, the compensation you receive
                        for your loss will exactly be the amount of what your IDV is. Therefore, always make sure your
                        IDV is right, as per your Car’s true value.</p>
                    <p class="font-body">Your Car’s IDV also determines the level of its risk. The higher your Car’s
                        IDV, the higher is its risk and consequently, this would demand for a higher premium.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Points to remember while calculating IDV</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Following are the important points to be considered while assessing insured
                        declared value (IDV) of your Car:</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Do your homework. Check with the manufacturer before you
                        give a nod to IDV fixed by your Car insurance provider.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;IDV is the most important component while calculating the
                        renewal premium for Car insurance. Hence ensure the cost of the premium is determined based on
                        your Car’s IDV.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Greater the IDV higher is the premium. Lesser the IDV
                        lesser will be your compensation amount. Hence, declare the right amount of IDV to get the
                        rightful claims.</p>
                    <p class="font-body">To sum up, it’s important to know that your Car insurance policy runs around
                        IDV. Hence, to choose the best and right insurance for your Car, ensure to check IDV before
                        purchase, declare the right IDV.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How does IDV affect your Car Insurance Premium?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The Insured Declared Value and your Car insurance premium go hand in hand. This means, the higher your IDV is, the higher your Car insurance premium – and as your vehicle ages and IDV depreciates, your premium also decreases.</p>
                    <p class="font-body">Also, when you decide to sell your Car, a higher IDV means you’ll get a higher price for it. Price may also be affected by other factors like usage, past Car insurance claims experience etc.</p>
                    <p class="font-body">So, when you’re choosing the right Car insurance policy for your Car, remember to make note of the IDV being offered, and not just the premium.</p>
                    <p class="font-body">A company offering a low premium may be tempting, but this could be because the IDV on offer is low. In case of a total loss of your Car, a higher IDV leads to higher compensations.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits of Higher IDV in Car Insurance:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Indian roads are tricky sometimes and an accident can put a dent in your Car and your finances. Insuring your Car with the appropriate IDV will save you money. However, there are advantages if you opt for a higher sum insured amount in Car insurance. Here are some of them:</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Lower Risk: While a lower IDV will lead to a lower premium amount, it would also mean that when you raise a claim, you may not get the market value of the vehicle when your Car is stolen or is totally damaged due to fire or natural calamities.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Peace of Mind: Rest assured when you raise a claim for damages sustained by your Car, an increase in IDV will offer you enough funding to repair or replace any parts of your Car.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>IDV And Claims:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In most cases of a Car insurance claim, the IDV is not required for the calculation of the claim amount. However, while buying a comprehensive Car insurance policy, it is important that you declare the IDV every year. The value of your Car begins depreciating from the minute of its purchase. If the IDV is low, then the Car premium will also be low. But a lower IDV that is less than the reasonable market price may pose a problem too. This is because you will receive a lower claim amount. IDV comes into the picture for claim settlement only in the following situations:</p>
                    <p class="font-body">Constructive Total Loss: When a vehicle gets damaged due to a contingency, the insurer will assess the extent of damage. If there is a damage of 75% or more, it will require extreme repairs. In this situation, the repair cost of your Car will be more than its IDV. Here it is advised that a total loss claim should be raised.</p>
                    <p class="font-body">Theft : You can raise a theft claim after the police declares your vehicle as “non-traceable”. In this situation, your insurer will settle a claim amount that is approximately equal to the IDV of your Car.</p>
                    <p class="font-body">Total Loss: In an unfortunate situation where the Car gets damaged beyond repair, the owner can raise a Total Loss claim. Here the IDV component of the Car will be taken into account for settling the claim.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>IDV During a Car Insurance Renewal</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">At the time of the renewal of Car insurance policy, the IDV of the Car is calculated taking into consideration the accumulated depreciation for the year. However, the holder of the policy also has the liberty to alter the IDV of the Car. It is recommended that the policyholder keeps the IDV as accurate as possible. While a higher IDV is not an impediment to claim settlement, it is best to keep the IDV well within the limits. There are two cases where the insured pays the entire IDV as a claim. First, if the Car is stolen. Second, the damage to the Car equals 75% of the IDV. The IDV value fixed differs from one insurance service provider to another. Therefore, one must settle with an insurance company that offers maximum IDV at a reasonable premium cost.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>IDV in Two wheeler Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In a Two wheeler insurance policy, the Insured Declared Value or IDV is the value of the Two wheeler. The IDV is a determining factor when it comes to the amount that the Two wheeler owner can claim in the event of loss or damage owing to an unfortunate incident. An IDV (Insured Declared Value) in Two wheeler insurance is nothing complicated but, refers to the market value of your Two wheeler. In other words, it is the amount your Two wheeler could receive in today’s market. </p>
                    <p class="font-body">This IDV in Two wheeler insurance helps your insurer to determine your claim amounts correctly during claim payments. Additionally, it also helps insurance company to determine the right premium price for your Two wheeler insurance.   </p>
                    <p class="font-body">Let’s put it across with a simple example-say you buy a brand-new bikefor Rs 1 lakh (excluding the cost of registration, road tax, insurance, accessories et al.). Your IDV at the time of purchase will be 1 lakh since your bike is brand new. But as your bike gets older, its value starts to depreciate, and so does the IDV. So, say your bike is worth Rs 65,000 after two years. Your IDV will also be Rs 65,000.</p>
                    <p class="font-body">Now, coming to the main point where most people get confused. IDV calculates the depreciation of your vehicle according to the ‘manufacturer’s specifications’ or ‘the amount the manufacturer values your bike at’ and NOT the value you can personally sell the bike at i.e. someone may offer to buy your bike for even Rs 85,000, but the IDV will still stand at 65,000.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>IDV Calculator for Two wheeler</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The calculation of your IDV is pretty simple: It is the ex-showroom price/current market value of the vehicle minus the depreciation on its parts. The registration cost, road tax and insurance cost are not included in the IDV. Also, if there are accessories that have been fit later, IDV of those parts will be calculated separately.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What are the factors that help determine your Car's IDV?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Age of the Car: Since the IDV represents the market value of your Two wheeler , The age of your Two wheeler is one of the biggest factors that help determine the IDV. The older your vehicle is, the lesser will be its IDV and vice versa. </p>
                    <p class="font-body">Manufacturer Make and Model of the Vehicle: The make and model of your Two wheeler is directly influenced to your IDV. It helps to determine how high-end is the Two wheeler and how high would be its repair cost.  For example; a bike like a BMW G310 R will have a higher IDV than that of an Honda activa One due to its difference in make and model.</p>
                    <p class="font-body">City of Registration Details: Your vehicle registration details are available on your registration certificate. Also, the city where your Two wheeler is registered has an impact on its insured declared value. The IDV of your Two wheeler in a metro city may be less than its IDV in a tier-II city.</p>
                    <p class="font-body">Standard Depreciation (As per Indian Motor Tariff): Your vehicle’s value depreciates from the moment you drive it out of the showroom– and the percentage of its depreciation increases with each year. This too ultimately affects your IDV. Have a look at table to help you understand the respective depreciation rates with context to your vehicle’s age. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Age of the Bike</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Depreciation            %</p>
                    <p class="font-body">6 months and below     5%</p>
                    <p class="font-body">6 months to 1 year    15%</p>
                    <p class="font-body">1-2 years             20%</p>
                    <p class="font-body">2-3 years             30%</p>
                    <p class="font-body">3-4 years             40%</p>
                    <p class="font-body">4-5 years             50%</p>
                    <p class="font-body">5+ years    IDV mutually decided by the insurance provider and policyholder</p>
                    <p class="font-body">Depreciation rates for your Bike</p>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>