<div class="container">
    <div class="row mt-10">
        <div class="col-md-12 mt-15">
            <div class="card ">
                <div class="row">
                    <div class="col-md-1 mt-2 col1">
                        <img src="../../../assets/svgIcon/agent/agentAvatar.svg" alt="" class="user-img">
                    </div>
                    <div class="col-md-3 mt-2 p-3">
                        <h4>Doctor Name  &nbsp;<span class="act p-1"> <img src="../../../assets/svgIcon/dot.svg"
                                    alt=""> Doctor</span> </h4>
                        <span>Business ID : 1236456 </span>
                    </div>
                    <div class="col-md-4 mt-2">
                        <div class="row l-br">
                            <div class="col-md-6 c6 mt-2">
                                <p><img src="../../../assets/images/call.svg" alt="">&nbsp;&nbsp;Contact Number :</p>
                                <p><img src="../../../assets/images/email.svg" alt="">&nbsp;&nbsp;Email ID :</p>
                            </div>
                            <div class="col-md-6 c6 mt-2">
                                <p>9687337536</p>
                                <p>xyz123@gmail.com</p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 mt-2">
                        <div class="row l-br">
                            <div class="col-md-6 c6 mt-2">
                                <p><img src="../../../assets/images/call.svg" alt="">&nbsp;&nbsp;Business Contact :</p>
                                <p><img src="../../../assets/images/email.svg" alt="">&nbsp;&nbsp;Business Email ID :
                                </p>
                            </div>
                            <div class="col-md-6 c6 mt-2">
                                <p>9687337536</p>
                                <p>xyz123@gmail.com</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4 mt-4">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-12">
                        <p>Start earning with Click4bima
                            <br> Complete the simple steps to start earning.
                        </p >
                    </div>
                    <div class="col-md-1 c1 mt-2">
                        <img src="../../../assets/images/Check box.svg" alt="">
                    </div>
                    <div class="col-md-11 c11  mt-2"><p> &nbsp;&nbsp;Step.1 : &nbsp;&nbsp;Enter Personal Details</p></div>
                    
                    <div class="col-md-1 c1 mt-4">
                        <img src="../../../assets/images/Check box2.svg" alt="">
                    </div>
                    <div class="col-md-11 c11  mt-4"><p> &nbsp;&nbsp;Step.2 : &nbsp;&nbsp;Clinic Details </p></div>
                    
                    <div class="col-md-1 c1 mt-4">
                        <img src="../../../assets/images/Check box2.svg" alt="">
                    </div>
                    <div class="col-md-11 c11  mt-4"><p> &nbsp;&nbsp;Step.3 : &nbsp;&nbsp;Enter Bank Details</p></div>
                    
                    <div class="col-md-1 c1 mt-4">
                        <img src="../../../assets/images/Check box2.svg" alt="">
                    </div>
                    <div class="col-md-11 c11 mt-4"><p> &nbsp;&nbsp;Step.4 : &nbsp;&nbsp;Start Earning</p></div>
                </div>
            </div>
        </div>
        <div class="col-md-8 mt-4">
            <div class="card p-4">
                <div class="row">
                    <div class="col-md-12 l-bm mt-3">
                        <div class="row">
                            <div class="col-md-1">
                                <img src="../../../assets/svgIcon/userH.svg" alt="">
                            </div>
                            <div class="col-md-7  c7">
                                <p class="p-tx">Business Verification <br> <span>Business Documentation</span> </p>
                            </div>
                            <div class="col-md-2   c2 p-2 text-end">
                                <span class="act p-1 mt-2"> <img src="../../../assets/svgIcon/dot.svg"
                                    alt=""> Update</span> 
                            </div>
                            <div class="col-md-2 c2 text-end" style="cursor: pointer;" (click)="verification()">
                                <img src="../../../assets/svgIcon/chevron_right.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 l-bm mt-3">
                        <div class="row">
                            <div class="col-md-1">
                                <img src="../../../assets/svgIcon/bankH.svg" alt="">
                            </div>
                            <div class="col-md-7 c7">
                                <p class="p-tx">Bank Details<br> <span>Banking Details and Documentation</span> </p>
                            </div>
                            <div class="col-md-2 p-2 c2  text-end">
                                <span class="act p-1 mt-2"> <img src="../../../assets/svgIcon/dot.svg"
                                    alt=""> Update</span> 
                            </div>
                            <div class="col-md-2 c2 text-end" style="cursor: pointer;" (click)="bank()">
                                <img src="../../../assets/svgIcon/chevron_right.svg" alt="">
                            </div>
                        </div>
                    </div>
                      <div class="col-md-12 l-bm mt-3">
                        <div class="row">
                            <div class="col-md-1">
                                <img src="../../../assets/svgIcon/panH.svg" alt="">
                            </div>
                            <div class="col-md-9 c11">
                                <p class="p-tx">Edit Personal Profile <br> <span>Change your Personal Details and Password</span> </p>
                            </div>
                            
                            <div class="col-md-2 text-end c1" style="cursor: pointer;" (click)="eidt()">
                                <img src="../../../assets/svgIcon/chevron_right.svg" alt="" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div><div class="overlay " *ngIf="editPopup">
    <div class="popup1  col-md-3 col-11" style=" overflow-x: unset;">
      <div class="modal-header tooltip_ mt-3">
          <h6 class="modal-title">
              <h6 class="" *ngIf="tabs=='1'" style="font-weight: 600;
              font-size: 14px;">Edit Doctor Info
          
              </h6>
  
              <h6 class="" *ngIf="tabs=='2'" style="font-weight: 600;
              font-size: 14px;">Edit Password
          
              </h6>
  
  
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)=" closePop()" style="    margin-bottom: -2%;">
              <img src="../../../assets/svgIcon/Crosss.svg "  alt="">
          </button>
      </div>
      <div class="modal-body  ">
          <div *ngIf="tabs=='1'">
                  <div class=" row text-center">
                 
                      <div class="col-md-12 mt-5">
                          <input type="text" class="form-control" name="userName" placeholder="First Name"
                              formControlName="userName" >
                      </div>
                      <div class="col-md-12 mt-5">
                          <input type="text" class="form-control" name="LastName" placeholder="Last Name"
                              formControlName="userName">
                      </div>
                      <div class="col-md-12 mt-5">
                          <input type="email" class="form-control" name="userEmail"
                              placeholder="User Email" formControlName="userEmail" >
                      </div>
                      <div class="col-md-12 mt-5">
                          <input type="email" class="form-control" name="language"
                              placeholder="Spoken Language Ex : Hindi, English" formControlName="language"
                            >
                      </div>
                      <!-- <div class="col-md-6 mt-5">
                  <button class="btn btn-outline-warning btm-sm" id="btn">
                      Cancel
                  </button>
              </div> -->
                      <div class="col-md-6  text-center mt-5">
                          <button class="  bt-s btn-sm " style="margin-bottom: 5%;" type="submit" id="btn"
                              (click)="reload()">
                              Cancle
                          </button>
                      </div>
                      <div class="col-md-6  text-center mt-5">
                          <button class=" btn btn-primary  btn-sm" style="margin-bottom: 5%;"
                              type="submit" id="btn" 
                              >
                              Update
                          </button>
                      </div>
  
                      <div class="col-md-12 ">
                         
                          <button class="btn-sm bt-c" (click)="changeTab('2')">
                              Change Password
                          </button>
                      </div>
                  </div>
          </div>
          <div *ngIf="tabs=='2'">
              <div class="row">
                  <div class="col-md-12 mt-3">
                      <input type="password" name="" placeholder="Current Password" class="form-control" id="">
                  </div>
                  <div class="col-md-12 mt-3">
                      <input type="password" name="" placeholder="Enter Password" class="form-control" id="">
                  </div>
                  <div class="col-md-12 mt-3">
                      <input type="password" name="" placeholder="Enter Password" class="form-control" id="">
                  </div>
                  <div class="col-md-6 mt-5 text-center">
                      <button class="  bt-s btn-sm " style="margin-bottom: 5%;" type="submit" id="btn"
                          (click)="changeTab('1')">
                          Cancle
                      </button>
                  </div>
                  <div class="col-md-6  text-center mt-5">
                      <button class=" btn btn-primary  btn-sm" style="margin-bottom: 5%;"
                          type="submit" id="btn" >
                          Update
                      </button>
                  </div>
              </div>
          </div>
      </div>
    
    </div>
  </div>