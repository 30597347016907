<div class="">
    <app-insurance (clickOnButton)="clicked($event)" style="background-color: #ffffff;"></app-insurance>
  </div>
  
  
  <script>
    function changeTab(event, tabNumber) {
      var tabs = document.getElementsByClassName("nav-item");
      for (var i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove("active");
      }
      event.target.classList.add("active");
  
    }
    function changeMenu(event, tabNumber) {
      var tabs = document.getElementsByClassName("selected-menu");
      for (var i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove("active");
      }
      event.target.classList.add("active");
    }
    function changeCard(event, tabNumber) {
      var tabs = document.getElementsByClassName("selected-card");
      for (var i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove("active");
      }
      event.target.classList.add("active");
    }
  </script>