<app-navbar></app-navbar>
<div class="content-box" [ngClass]="{'mt-77':isUserLogged}">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <img src="../../assets/images/3rd pose-01.png" alt="faq" width="250">
        <h2>For any other query, please click on the Button.<a href="/contactus" style="position: relative;bottom: 5px;"> &nbsp;&nbsp; <button class="btn btn-sm btn-primary">Contact Us </button></a></h2>
        <!-- <div>
          <h1  >Frequently Asked  Questions   </h1>
        </div>
        <div class="top-line"></div>
        <div class="mt-2">
          <p class="font-body">Everything you need to know so you can get into Click4bima easily.</p>
        </div>
        <div>
          <input type="text" class="form-control questionBox w-50" placeholder="Do you have any Query?">
          <button class="btn btn-primary questionBtn shadow">Ask</button>
        </div> -->
      </div>
      <!-- <div class="col-2">
        <img src="../../assets/images/3rd pose-01.png" alt="faq" width="250">
      </div> -->
    </div>

    <div class="row">
      <div id="accordion" class="accordion mt-2">
        <!-- <div class="mb-0"> -->
        <div class="card-data">
          <div class="card-header" (click)="faqs('Investment')">
            <div class="card" >
              <a class="card-title">
                <h1 style="color: antiquewhite;"> Investment Related Questions</h1>
                <i class="fa fa-plus" aria-hidden="true" style="color:antiquewhite" *ngIf="isinvestmentnotcollapsed"></i>
                <i class="fa fa-minus" aria-hidden="true" style="color:antiquewhite" *ngIf="isinvestmentcollapsed"></i>
              </a>
            </div>
          </div>
          <div class="faq" *ngIf="isInsurance">
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(0)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }" aria-hidden="true"></i>
                1. What is money back plan?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> A Money Back Plan provides guaranteed payout at regular intervals during the premium payment term to help you achieve short term goals. They also provide life insurance cover. Some Money Back plans offer lump sum at maturity as well.</div>
            </div>
        
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(1)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"  aria-hidden="true"></i>
                2. Is money back policy transferable?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">No, money back policy is not transferable, however,you can assign it to another person’s name.</div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(2)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 2, 'fa-minus': openItemIndex === 2 }"  aria-hidden="true"></i>
                3. Can I revive my money back policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }">Yes, you can revive your money back policy within a period of two years from the date of lapse, subject to you fulfilling the revival conditions set by the insurance company.</div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(3)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"  aria-hidden="true"></i>
                4. What is Unit Linked Insurance Plan (ULIP)?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }">ULIP is Unit Linked Insurance Plan. ULIP is a life insurance cum investment product which provides life risk care as well as growth into your investment. The amounts are invested in funds based on allocation rate as per policy holder choice.</div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(4)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"  aria-hidden="true"></i>
                5. What are the main benefits of ULIP?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                <ul>
                  <li> Death Benefit: Sum assured or value of the fund would be paid in case of death of insured.
                  </li>
                  <li> Maturity benefit: In case of survival, value of the fund would be paid to ULIP holder.</li>
                  <li>
                    Tax benefit: Insurance premium can be claimed as tax benefit under Income Tax Act.
                  </li>
                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(5)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"  aria-hidden="true"></i>
                6. Can we surrender ULIP at any time?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">
                If you have taken the new ULIP policy, there is free look in period of 15 days from the date of receipt of policy. If you are not happy with the ULIP, then you can surrender them. Insurance company would deduct necessary stamp duty, medical expenses and any other expenses incurred towards issuing such policy and repay you the balance premiums.<br><br>
                If you want to surrender existing ULIP policy, they have a lock-in period of 5 years. You can surrender your ULIP after this period. However due to high allocation charges at initial period, your fund value would be less and you may not see much gains in the amount you invested through premiums. You can see some benefits when you continue your plan till the end of the ULIP tenure.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(6)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"  aria-hidden="true"></i>
                7. Where can I invest to save tax?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }">
                You can invest in Unit Linked Insurance Plan (ULIP) to save tax. The ULIP premium amount can be deducted from your taxable income, thus lowering your taxes.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(7)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"  aria-hidden="true"></i>
                8. How pension plan works?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">
                Pension plansalso known as retirement plans for your future financial stability during your old age With ever increasing costliving it has become important that you make arrangements for your retired life When you continually invest"this plan it grows"the compounding effect <br><br>
                <b>What are the types pf Endowment plans?</b>
                <ul>
                  <li>Full Endowment Plan</li>
                  <li>Low-Cost Endowment Plan</li>
                  <li>Unit-Linked Endowment Plan</li>
                  <li>Unitized with Profit Endowment Plan</li>
                  <li>Non-Profit Endowment Plan</li>
                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(8)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"  aria-hidden="true"></i>
                9. Why Should You Invest In Tax Saving Plans?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }">
              
                  All individuals work hard to earn their money - whether they are salaried professionals, freelancers, self-employed businessmen etc. On the income so earned, they are required to pay taxes if this income exceeds a prescribed threshold limit. Tax planning can help reduce the burden of taxes that falls on an individual and maximize their savings. There are many financial instruments that serve the purpose of saving taxes. The mark of a good tax saving investment is that it provides not just tax saving, but also safety, returns and liquidity. An ideal financial instrument will help you save taxes at the same time as it reaps benefits in the form of decent returns and the flexibility to withdraw funds. By investing in tax saving plans, individuals also inculcate a habit of saving over time.

              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(9)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"  aria-hidden="true"></i>
                10. How do I save my Tax through availing pension plan scheme?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">
           
                  As the name explains, pension plans cater to the pensionary needs of the senior citizens after retirement. Such plans act as a financial safeguard for individuals post-retirement. Several pension plans are available in the market, each designed to cater to varied retirement goals that one may have.
                <br><br>
                A consistent thread across most pension plans is that
                  the investment is
                  eligible for a tax deduction, such that an individual can plan for retirement without having to pay
                  taxes on the amount. Section 80C and 80CCC give specific details about the tax benefits of various
                  kinds of pension plans.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(10)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"  aria-hidden="true"></i>
                11. What is Guaranteed Retirement Account(GRA) plan?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">
                
                  The GRA is a retirement plan for all employees who are not participating in an equal or better
                    retirement plan through their employer. Self-employed workers would also participate.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(11)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 11, 'fa-minus': openItemIndex === 11 }"  aria-hidden="true"></i>
                12. What would happen when I retire?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 11 }">
                
                  At age 60 or—whenever you choose to retire—you would be eligible to receive a guaranteed monthly
                  check for the rest of your life. The amount of your monthly check would be determined based on the
                  amount you have in your GRA at retirement, and it would gradually increase over time to keep up with
                  inflation.GRA benefits would be similar to a lifetime pension.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(12)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 12, 'fa-minus': openItemIndex === 12 }"  aria-hidden="true"></i>
                13. How much will I spend in retirement? What is money back plan?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 12 }">
               
                  Most people’s spending remains at about the same level
                    as pre-retirement. The  adjustments to pre-retirement spending typically involve one of these:
              
                <ul>
                  <li>Changes in the amount of travel spending</li>
                  <li>Changes in healthcare costs.</li>
                  <li>Changes in your housing costs based on downsizing or paying off your mortgage.</li>
                  <li>Expensive new activities or hobbies.</li>
                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(13)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 13, 'fa-minus': openItemIndex === 13 }"  aria-hidden="true"></i>
                14. What are Child Education Insurance Plans?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 13 }">
                
                  Child Education Insurance Plans are insurance plans that take care of your Protection and Savings
                    needs for securing the future of your children. As a parent, one of your most important goals would
                    be to make sure that your children have a bright future and lead their lives comfortably. These
                    plans can help you achieve this by saving for your children’s higher education at a prestigious
                    university.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(14)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 14, 'fa-minus': openItemIndex === 14 }"  aria-hidden="true"></i>
                15. Why should I buy child insurance plan?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 14 }">
                
                  This plan is designed to give you assurance, flexibility and safety, all in one plan, to cope up
                  with the dynamic dreams of your little ones. The plan will provide regular guaranteed payouts to
                  coincide with the milestones that you have planned for your child.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(15)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 15, 'fa-minus': openItemIndex === 15 }"  aria-hidden="true"></i>
                16. What if I am not there tomorrow to take care of my child’s needs?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 15 }">
                
                  You now have us to be there for your child, even when you may not be. We provide for your planned
                  pay outs even if something untoward were to happen to you and no future premiums need to be paid.
                
              </div>
            </div>
            <!-- Add more FAQ items as needed -->
          </div>
        </div>
        <div class="card-data">
          <div class="card-header" (click)="faqs('life')">
            <div class="card" >
              <a class="card-title">
                <h1 style="color: antiquewhite;">Life Insurance Related Questions</h1>
                <i class="fa fa-plus" aria-hidden="true" style="color:antiquewhite" *ngIf="islifenotcollapsed"></i>
                <i class="fa fa-minus" aria-hidden="true" style="color:antiquewhite" *ngIf="islifecollapsed"></i>
              </a>
            </div>
          </div>
          <div class="faq" *ngIf="islife">
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(0)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }" aria-hidden="true"></i>
                1. What is Life Insurance?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Many people do fully understand what life insurance is and how it works. Life Insurance is a
                contract between a person and a life insurance company it offers many opportunities for creating
                security for you and for your family</div>
            </div>
        
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(1)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"  aria-hidden="true"></i>
                2. Why life insurance is most important in this morden world and what does it covers?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">In Life Insurance policy, it provides your family with financial security under circumstances where
                you cannot earn or in the case of your premature demise. It is desined to provide cover loss of
                income It helps to keep your family in a position to enjoy financial security even after your
                demise. Life insurance policy also provide financial stability. It is important for different
                individuals for different reasons. For some people, it is important to cover end-of-life costs while
                others want to create a large financial legacy for their dependents and heirs. Some people want a
                life insurance policy that will help their business continue to flourish after their passing. Others
                want to create an endowment that will benefit an important cause or institution.</div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(2)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 2, 'fa-minus': openItemIndex === 2 }"  aria-hidden="true"></i>
                3. What is group life insurance policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }">A group life insurance policy is an insurance that covers a group of people, usually member of any
                society, organisation, labour union or common employees of any organisation. One of the best benefit
                under group life insurance is that Premium per person is much less than if they had to buy
                individually.</div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(3)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"  aria-hidden="true"></i>
                4. What are the consequences if I do not pay the premium on time?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }">Usually you get a grace period for upto 30 days to pay your premium once it falls due. But if you
                don’t pay your premium after such grace period of 30 days, your policy stands defunct and you cannot
                claim any policy benefits. You need to pay revival premium when you want to restart the coverage.</div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(4)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"  aria-hidden="true"></i>
                5. Are different options available to pay the premium?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                Yes, there are several options available to payment of premium. You may choose monthly, quarterly,
                  semi-annually, or annually. Regular premium payment is the most recommended mode to pay your premium
                  and it is the most recommended mode. It is advisable to pay regular premium considering the
                  affordability factor. However some policies are available with one time premium.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(5)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"  aria-hidden="true"></i>
                6. What are the documents to be submitted in case of Maturity Claim?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">
                Usually the insurance company will send intimation attaching the discharge voucher holder at least 2
                  to 3 monts in advance of the date of maturity of the policy intimating the claim amount payble. The
                  policy bond and the discharge voucher duly signed and witnessed are to be returned to the insurance
                  company immediately so that the insurance company will be able to make
                  payment. If the policy is assigned in favour of any other person the claim amount will be paid only
                  to the assignee who will give the discharge.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(6)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"  aria-hidden="true"></i>
                7. What Are the Various Types of Life Insurance Plans?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }">
                There are several different types of life insurance plans. In order to get the best one to fulfill
                  the needs that you have established, you should research what plans insurance companies offer and
                  the costs and benefits associated. Types of life insurance include:<br>
                  <ul>
                    <li>Whole Life Coverage</li>
                    <li>Universal Life Coverage</li>
                    <li>Limited-pay</li>
                    <li>Endowments</li>
                    <li>Accidental Death</li>
                  </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(7)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"  aria-hidden="true"></i>
                8. How Much Life Insurance Should I Purchase?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">
                Gather all of your financial information and begin
                  sorting through what
                  expenses may remain once you are gone.
                  For those who do not have family or dependents to care for, an insurance policy that covers the
                  funeral expenses is adequate. For those with family and dependents, it is important to calculate all
                  of the expenses that you will be leaving behind and the expenses of your dependents.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(8)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"  aria-hidden="true"></i>
                9. Can I Buy Policies for More Than One Person?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }">
                
                  Joint or group policies are available for those who want to have one plan that will cover more than
                    one person. As with standard single policies, working with an insurance agent is the best way to
                    establish what needs to be done upon the cashing of the policy.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(9)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"  aria-hidden="true"></i>
                10. Can the premiums change after a period of time?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">
              
                  This depends on several factors like addition of riders or declaration of habits like smoking,
                    drinking etc. or the declaration pertaining to a hazardous employment nature etc.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(10)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"  aria-hidden="true"></i>
                11. Can multiple claims be entertained?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">
                
                  More than one claim, from different insurers can be entertained, provided that these claims and
                    the detailed nature have been declared at the time of purchasing the policy.

                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(11)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 11, 'fa-minus': openItemIndex === 11 }"  aria-hidden="true"></i>
                12. Are NRIs eligible for purchasing term insurance in India?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 11 }">
                
                  Yes. NRI’s who hold dual citizenship and qualify as citizens of India are eligible for purchasing
                    term insurance in India.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(12)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 12, 'fa-minus': openItemIndex === 12 }"  aria-hidden="true"></i>
                13. Should my spouse have life insurance too?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 12 }">
                
                  Most families have coverage on both husband and wife. There are many financial pressures on a family
                    after the loss of either parent. Beyond the obvious final expenses, the financial strain on a family
                    can be significant, even if the deceased spouse wasn’t working.
                <br><br>
                The surviving spouse or domestic partner may need to take time off work, or change jobs in order to
                  spend more time with the children. Therefore, your financial plan should include life insurance for
                  both spouses/parents.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(13)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 13, 'fa-minus': openItemIndex === 13 }"  aria-hidden="true"></i>
                14. What are the benefits of shopping online for life insurance?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 13 }">
                
                  There are four great reasons to shop online for life insurance. <br>
                    1. Speed – You can get a quote in less than two minutes! After briefly talking to a licensed life
                    insurance, you’ll get personalized advice and the application process can begin, if you choose to
                    move forward. <br>
                    2. Convenience – Shopping for life insurance has never been more convenient. You get the information
                    and advice you need from the comfort of your own home at any time throughout the day.
                    <br>3. Savings – Online life insurance quoting firms allow you to compare dozens of quotes
                    side-by-side, finding the best values in life insurance.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(14)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 14, 'fa-minus': openItemIndex === 14 }"  aria-hidden="true"></i>
                15. I want to invest in life insurance policy what are the advantages available to me in addition to financial protection ?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 14 }">
                
                  In addition to give you and your family financial
                    protection, life insurance
                    policy offers you many other benefits
                <br>
                <ol>
                  <li>Through the life insurance policy, you can claim tax benefit under section 80 C of Income Tax
                    Act</li>
                  <li>Encourages the habit of saving</li>
                  <li>The maturity benefits from a life insurance policy are tax-free under section 10(10D) of the
                    Income Tax Act.</li>
                  <li>You can invest in a policy that offers you a loan against your amount invested if you need
                    financial assistance in the case of an emergency. You can also take a loan from a bank or
                    financial institution and put your policy up as collateral for the loan.</li>
                  <li> You can invest in a policy that allows you to withdraw a part of your investment at the time
                    of a financial emergency.</li>
                  <li>You can add a critical illness rider to your policy, which offers you medical aid in case you
                    are inflicted by a serious illness or injury. Under this rider, you can also
                    claim a tax benefit up to Rs. 15,000 as specified in section 80D of the Income Tax Act.
                  </li>
                  <li> You can also invest in policies in the name of your spouse and children and claim tax
                    benefit, under section 80C, on those policies as well.

                  </li>
                </ol>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(15)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 15, 'fa-minus': openItemIndex === 15 }"  aria-hidden="true"></i>
                16. What are the benefits of shopping online for life insurance?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 15 }">
                
                  Correct. If you stop premium payments of your policy, it amounts to discontinuation of the policy
                    and you cannot claim any tax benefits. However, if you discontinue paying your premiums after 2
                    years from the commencement of your policy, the tax will not be deducted on the premium paid in the
                    year when your policy ends. The amount of tax deducted on the premium paid in the preceding year is
                    taxable in the year when the policy terminates.
                    Question: What about with a Unit Linked Insurance Plan (ULIP). Can I claim tax benefits if I
                    discontinue my ULIP policy?
                <br><br>
                In the case of a Unit Linked Insurance Plan you are not entitled to receive any tax benefits if you
                  stop paying premiums earlier than 5 years from the commencement of your policy.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(16)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 16, 'fa-minus': openItemIndex === 16 }"  aria-hidden="true"></i>
                17. What about tax benefits on medical insurance premiums?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 16 }">
                
                  In calculating your (individual or HUF) total income, any sum paid by you, other than in cash, out
                    of your income that is chargeable to tax to effect or to keep in force insurance for your health or
                    the health of your spouse or children and to effect or to keep in force an insurance on the health
                    of your parent or parents up to Rs. 15,000 for each person mentioned in (i) and (ii) in the previous
                    year and in case the person is a senior citizen up to Rs. 20,000 for each person mentioned in (i)
                    and (ii) in the previous year shall be allowed a deduction.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(17)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 17, 'fa-minus': openItemIndex === 17 }"  aria-hidden="true"></i>
                18. What are the Tax Benefits in case I opt for a Pension Plan?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 17 }">
               
                  You can claim tax benefits for a Pension Plan under
                    Section 80CCC if you have
                    paid premiums. You will receive a pension from a fund referred to in Section 10(23AAB). You will be
                    able to get a deduction of up to Rs. 100,000 on your total annual income.
               
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(18)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 18, 'fa-minus': openItemIndex === 18 }"  aria-hidden="true"></i>
                19. What happens when my life insurance policy matures?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 18 }">
               
                  When your policy matures you will receive the accumulated amount. This amount will include
                    the total of all your premiums paid, plus any bonuses you have received on the part of your premium
                    that has been invested by the insurance company on your behalf (for eg, ULIPs). The amount you
                    receive will be quite substantial because the premiums you pay will accumulate and get compounded
                    every year until the maturity of your policy.
               
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(19)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 19, 'fa-minus': openItemIndex === 19 }"  aria-hidden="true"></i>
                20. Will I have to pay tax on my maturity benefit?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 19 }">
              
                  No, you will have to pay no tax on the maturity proceeds of a life insurance policy. In
                      fact, under a pension plan you can even withdraw up to one-third of the total maturity amount in
                      cash and that too will be tax-free. All this is provided that you have paid all your premiums and
                      you have not let your policy lapse.
              
              </div>
            </div>
            <!-- Add more FAQ items as needed -->
          </div>
        </div>
        <div class="card-data">
          <div class="card-header" (click)="faqs('motor')">
            <div class="card" >
              <a class="card-title">
                <h1 style="color: antiquewhite;">Motor Insurance Related Questions</h1>
                <i class="fa fa-plus" aria-hidden="true" style="color:antiquewhite" *ngIf="ismotornotcollapsed"></i>
                <i class="fa fa-minus" aria-hidden="true" style="color:antiquewhite" *ngIf="ismotorcollapsed"></i>
              </a>
            </div>
          </div>
          <div class="faq" *ngIf="ismotor">
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(0)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }" aria-hidden="true"></i>
                1. Is motor vehicle insurance mandatory under law?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }">
                Yes , it is mandatory under Motor vehicle Act ,1988.
              </div>
            </div>
        
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(1)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"  aria-hidden="true"></i>
                2. What are the factors to be considerd while choosing the plan?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">
                The IDV (Insured Declared Value) of the car
                    <br>
                    The age of the car (If your car is a used one)
                    <br>Zone/City of Registration
                    <br>Cubic capacity of the car engine
                    <br>Previous year Claim Status & NCB
                    Consider regular coverage or Zero Depreciation coverage.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(2)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 2, 'fa-minus': openItemIndex === 2 }"  aria-hidden="true"></i>
                3. What if a company offers higher premium compared to other companies,is it a better cover?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }">
                No,all covers are standard and covers you for any damage or theft to your car and any liability you
                    or your car can cause (known as Third Party cover)<br><br>
                “Zero Depreciation” covers, which cover you for full value of repair without deducting any
                  depreciation. However,these covers are more expensive.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(3)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"  aria-hidden="true"></i>
                4. What are Zero Depreciation Plans?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }">
                In regular covers, when you claim insurance for any
                  damage or repair, the insurance company would deduct some amount and make you pay for it.That’s
                  called “Depreciation charges”
                  <br><br>Eg.Suppose you had a claim of 80,000 rupees but the
                    insurance company paid only 70000 and you had to pay the balance amount of 10,000. The balance that
                    you had to pay is called Depreciation charges.in case If you had taken a Zero Depreciation cover,
                    the insurance company would have paid the entire amount of 80,000 rupees.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(4)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"  aria-hidden="true"></i>
                5. should I take regular coverage or zero depreciation plan?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                Look, If your car is less than 3 years old – take Zero
                  Depreciation<br><br>
                  Repair costs of luxury/New car models are very
                    expensive, since you go to the authorised dealers usually. Go for Zero Depreciation covers<br><br>
                    So, consider these factors and go for Plan
                    accordingly. Zero Depreciation covers are expensive, sometimes by even 40-50% ,
                  
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(5)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"  aria-hidden="true"></i>
                6. What is "No Claim Bonus"?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">
                No Claim Bonus (NCB) is your bonus for driving/riding
                  carefully and not making any claim. You’ll get a bonus In form of discount on your premium.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(6)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"  aria-hidden="true"></i>
                7. What about my No Claim Bonus if I change my insurancr company ?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }">
                
                  Your No-Claim Bonus is freely transferable so when you change your insurance company, your No Claim
                    Bonus will be get merged with another company.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(7)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"  aria-hidden="true"></i>
                8. What is deductible?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">
                
                  Deductible or “excess” is the amount over and above,
                    the claim payable. This ranges ranging from Rs 50 for two-wheelers to Rs 500 for Private Cars and
                    Commercial Vehicles. Increase in the cubic capacity/carrying capacity of the vehicle increase this
                    cost. In some cases the insurer may impose additional excess depending upon the age of the vehicle
                    or if there is a high frequency of claims.
              
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(8)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"  aria-hidden="true"></i>
                9. What if I want to change in my policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }">
                
                  Changes like address or modifications to the vehicle
                    or its use will be done by an Endorsement by the insurance company. What you need to do is submit a
                    letter to the insurer with relevant document proof of the changes.
                    <br><br>
                However, Some endorsements may require you to pay an
                  additional premium
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(9)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"  aria-hidden="true"></i>
                10. Does the City of registration of my vehicle affect premium?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">
                
                  If your vehicle is registered in Udaipur, the rate applicable for Zone C is charged. Even when you
                    shift to a different city/town, the same rate will continue to be applied. Similarly, if a vehicle
                    is registered in a town, it attracts Zone C premium rate. Subsequently, if the owner shifts to a
                    metro, he will continue to be charged the Zone C rate.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(10)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"  aria-hidden="true"></i>
                11. What is a Certificate of Insurance under Motor Vehicle Act?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">
                
                  As per Rule 141 of Central Motor Vehicle Rules 1989, a certificate of Insurance is to be issued. It
                    is only in Motor Vehicle Insurance, apart from the policy, that a separate certificate of insurance
                    is required to be issued by insurers. This document should always be carried in the vehicle. The
                    policy should be preserved separately at home/office.

              
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(11)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 11, 'fa-minus': openItemIndex === 11 }"  aria-hidden="true"></i>
                12. is vehicle insurance transferable ?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 11 }">
                
                  No, you will have to pay no tax on the maturity proceeds of a life insurance policy. In
                    fact, under a pension plan you can even withdraw up to one-third of the total maturity amount in
                    cash and that too will be tax-free. All this is provided that you have paid all your premiums and
                    you have not let your policy lapse.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(12)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 12, 'fa-minus': openItemIndex === 12 }"  aria-hidden="true"></i>
                13. Can the ownership of vehicle and insurance be in different names?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 12 }">
                
                  Registration and insurance of the vehicle should
                    always be in the same name with the same address.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(13)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 13, 'fa-minus': openItemIndex === 13 }"  aria-hidden="true"></i>
                14. What are the documents required to be submitted for a Motor Insurance claim?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 13 }">
                
                  This requirement can very from company to company, followings illustrative list for your convenience
                    <br>
                    Filled claim form, copy of RC of the vehicle, Driving licence, FIR, if required, for claims related
                    to theft, an Original estimate of the loss, Original repair invoice and payment receipt(in case of
                    cash payment) Pollution Certificate Copy
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(14)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 14, 'fa-minus': openItemIndex === 14 }"  aria-hidden="true"></i>
                15. What are the deductibles?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 14 }">
              
                  Deductibles are the part of claim amount which is borne by the policy holder at the time of claim.
                    The amount that insured has to pay from his own pocket in case of claim settlement this is known as
                    deductibles. The rest of amount is paid by insurance company.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(15)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 15, 'fa-minus': openItemIndex === 15 }"  aria-hidden="true"></i>
                16. What is the difference between Compulsory deductible and Voluntary Compulsory Deductible
                (VCD)?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 15 }">
                
                  The compulsory deductible is an amount that is made mandatory by Insurance Regulatory Development
                    Authority of India (IRDAI). Depending upon the cubic capacity* of car engine, IRDAI has fixed
                    certain amount to be borne by user in case of claim.
                    <br><br>
                voluntary deductible is optional for the insured to opt for. It is the minimum amount which a policy
                  holder decides to pay when a claim is lodged. It is inversely proportional to the premium amount.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(16)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 16, 'fa-minus': openItemIndex === 16 }"  aria-hidden="true"></i>
                17. Can my insurance claim be reject by my insurance company?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 16 }">
                
                  Following are some factors on which Insurance company can reject your claim-
                    <br>If any modifications done to the car and not intimated to Insurance Company and RTO at the same
                    time
                    <br>If the insurance company is mis leaded, not informed about purpose of using car
                    <br>If the car is repaired before making a claim
                
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(17)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 17, 'fa-minus': openItemIndex === 17 }"  aria-hidden="true"></i>
                18. In case I make any changes in car does it mandatory to inform RTO about such changes?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 17 }">
                
                  Yes, it is mandatory to inform RTO and insurance company about any installation done in the car. And
                    in case of theft, the owner has to inform RTO about the incident in order to avoid transfer of
                    registration on other name, resulting in any fraudulent activity.
                
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(18)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 18, 'fa-minus': openItemIndex === 18 }"  aria-hidden="true"></i>
                19. Under car insurance ,are natural calamities also coverd?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 18 }">
                
                  Yes , any tangible damage caused to vehicle due to natural calamity like- flood, cyclone, earthquake
                    etc. are covered under comprehensive insurance policy.

                
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(19)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 19, 'fa-minus': openItemIndex === 19 }"  aria-hidden="true"></i>
                20. What is the period of Motor Insurance Policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 19 }">
                
                  A motor insurance policy usually valid for one yearand it needs to be renewed before expiry. Vehicle
                    is required to be inspected In case of any delay in paying premium.
                
              </div>
            </div>
            <!-- Add more FAQ items as needed -->
          </div>
        </div>
        <div class="card-data">
          <div class="card-header" (click)="faqs('other')">
            <div class="card" >
              <a class="card-title">
                <h1 style="color: antiquewhite;"> Other Insurance Related Questions</h1>
                <i class="fa fa-plus" aria-hidden="true" style="color:antiquewhite" *ngIf="isothernotcollapsed"></i>
                <i class="fa fa-minus" aria-hidden="true" style="color:antiquewhite" *ngIf="isothertcollapsed"></i>
              </a>
            </div>
          </div>
          <div class="faq" *ngIf="isother">
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(0)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }" aria-hidden="true"></i>
                1. Why should you buy travel insurance?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }">
                It is prudent to obtain a travel insurance policy when you are travelling on business or holiday or
                    for education, research etc. as medical treatment costs in many countries are much higher than what
                    they are in India. Moreover it is mandatory for some countires to obtain Visa. 
              </div>
            </div>
        
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(1)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"  aria-hidden="true"></i>
                2. Can I get refund under my policy if I cut short my travel?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">
                In case your travel does not take off and you show
                    proof of the same ,companies would generally provide refund of premium after deducting their
                    administrative costs. Where travel is cut short, polices may or may not refund subject to conditions
                    as per policy. It is advisable that you must read your document and understand whether there is such
                    provison or not and if so, how it operates. 
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(2)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 2, 'fa-minus': openItemIndex === 2 }"  aria-hidden="true"></i>
                3. What are the requirements to purchase a Home Insurance policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }">
                The proposer of the policy first and foremost have an interest in the house being proposed for
                  insurance, i.e. he/she should stand to loss financially in the event of loss or damage to such
                  assets. Secondly, the proposer should submit the proposal form. The proposal form should disclose
                  all details, which are true and best of insured’s knowledge.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(3)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"  aria-hidden="true"></i>
                4. What are the benefits available to insured under personal Insurance policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }">
                The policy provides the benefits to you and your family, for Accidental Death, Permanent Disability,
                  Broken Bones, Burns due to an accident. It also provides benefit of Ambulance cost and hospital
                  cash. So by taking personal insurance policy you can protect your entire family against accidental
                  injuries.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(4)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"  aria-hidden="true"></i>
                5. I am 29 year old and i want to obtain personal Insurance policy for myself, am I eligible to obtain this?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                Personal Accident Insurance is open to everyone from the age of 18 years to 65 years.
                  
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(5)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"  aria-hidden="true"></i>
                6. I am a small shop owner, can I avail Shopkeeper Insurance Policy for my shop?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">
                 Any individual who owns a medium or small shop can avail shopkeeper insurance policy. There is no
                  such rigid eligibility criterion to purchase the insurance plan
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(6)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"  aria-hidden="true"></i>
                7. In shopkeeper Insurance policy generally how much time taken to settle claims?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }">
              
                  Under Shopkeeper Insurance policy, claims are usually settled within 30 days from the date of the
                    receipt of last document submitted. No claim will be settled if any document is missing or any
                    fraudulent activity is involved.
              
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(7)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"  aria-hidden="true"></i>
                8. Whether restaurants are covered under shopkeeper Insurance policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">
                
                  No, restaurants are not covered under this insurance.
               
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(8)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"  aria-hidden="true"></i>
                9. What value should I place on my boat insurance policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }">
             
                  In our professional opinion, you should insure your
                    boat for what it is worth at the time when you insure your vessel. This generally should include any
                    improvements that you have made on the vessel and can prove. Most policies also insure electronics
                    on the basis of adding their value into the hull, but this can vary by insurance company.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(9)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"  aria-hidden="true"></i>
                10. What if someone borrows my boat and meet with an accident?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">
             
                  Marine insurance policies typically cover your vessel while it is being used by someone with your
                    permission. The operator of the boat is typically not covered for injury to himself, but liability
                    toward others is typically covered, as is the risk of damage to the boat.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(10)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"  aria-hidden="true"></i>
                11. On which factor the cost of fire insurance policy depends?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">
               
                  The fire insurance policy pemium depends upon following factors:
                
                <ul>
                  <li>Perils to be covered </li>
                    <li>Valur of items covered</li>
                    <li>Uses of proposed premises</li>
                    <li>location details of the premises proposed for insurance etc.</li>
                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(11)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 11, 'fa-minus': openItemIndex === 11 }"  aria-hidden="true"></i>
                12. Does general fire insurance policy covers jewellery and art work?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 11 }">
                
                  General insurance policy does not cover items like jewellery ,art work,scripts, docuemnrey
                    information ,computer system, share and cashunless prior consent has been given by the insurer.
                    These can be covered only after special request and subject to valuation where necessary.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(12)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 12, 'fa-minus': openItemIndex === 12 }"  aria-hidden="true"></i>
                13. What does Burglary covers?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 12 }">
                
                  It covers burglary/theft of your household contents.
                    Signs of forcible entry and/or assault need to be present, to trigger the coverage. Additional
                    coverage is available for valuables such as jewelry, works of art, and valuable items which can be
                    insured, but they have to be scheduled on the policy.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(13)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 13, 'fa-minus': openItemIndex === 13 }"  aria-hidden="true"></i>
                14. What does contractors all risk cover?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 13 }">
                
                  A Contractors All Risk Policy will cover a contractor
                    for any occurrence which may occur whilst he is busy carrying out work on site in terms of a
                    contract. This will also include theft of materials, storm damage, malicious damage, accidental
                    damage or any other peril which may be included or endorsed in the Policy Schedule. A Contractors
                    All Risk Policy is a requirement by any institution which would be funding the contractor or
                    contract.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(14)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 14, 'fa-minus': openItemIndex === 14 }"  aria-hidden="true"></i>
                15. What is Professional indemnity insurance? What does it covers?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 14 }">
                
                  Professional indemnity (PI) insurance provides cover
                    for the legal costs and expenses in defending a claim, as well as compensation payable to your
                    client if you are found to be responsible for providing inadequate advice, services or designs that
                    cause your client to lose money.
                    <br><br>
                It provides following coverages:<br><br>
                The policy pays other parties for damages which you are legally liable to pay
                  as a result of negligent acts, errors or omissions in the performance of your professional services
                  including defense cost, court attendance fee etc. The insurance company has an obligation to defend
                  you against such claims, even if the allegations ultimately are determined to be false or groundless
                  Pays for: -
                  <ul>
                    <li>Expenses incurred in the investigation, adjustment, appeal, settlement or defense of any
                      claim,</li>
                    <li>Costs taxed against the Insured in respect of a claim, pre-judgement interest, and interest on
                      the full amount of any Judgment etc. </li>
                  </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(15)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 15, 'fa-minus': openItemIndex === 15 }"  aria-hidden="true"></i>
                16. How can I claim under the policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 15 }">
                Members can claim through cashless or reimbursement.<br><br>
                  Cashless treatment - TPA shall provide cashless facility at the approved
                    network hospital provided to members along with Medical cards / available on portal.
                    <br><br>Member may obtain reimbursement against hospitalization expenses from Insurance
                  Company through TPA subject to the hospital should be defined as per policy terms and condition.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(16)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 16, 'fa-minus': openItemIndex === 16 }"  aria-hidden="true"></i>
                17. What should I do when an employee is injured ar work?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 16 }">
             
                  When an employee is injured at work your first responsibility is ensuring the safety of your
                    workers. Your immediate response should be to assist the injured, get workers to a safe area, and
                    contact emergency medical services if anything more than basic first-aid is needed. When the
                    situation is under control, talk to witnesses and take notes to document what happened. 
              
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(17)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 17, 'fa-minus': openItemIndex === 17 }"  aria-hidden="true"></i>
                18. Can you cancel a workcover claim?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 17 }">
          
                  You can cancel your policy if you sell your business. The new owner will need to take out a new
                    workers compensation insurance policy to cover employees. If you cease to employ but there's a claim
                    in the current policy period or your business is a pvt Ltd company, you can cancel your policy at
                    the next policy expiry. 
              
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(18)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 18, 'fa-minus': openItemIndex === 18 }"  aria-hidden="true"></i>
                19. What is office package policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 18 }">
             
                  An office package in an insurance package designed to covers organizational infrastructure including
                    IT sector and Govt. offices, etc. 
              
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(19)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 19, 'fa-minus': openItemIndex === 19 }"  aria-hidden="true"></i>
                20. Who needs office package policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 19 }">
            
                  Irrepective whether you are a big firm owner or small scale business owner ,if you are operating out
                    of an office space, you are going to need a office package policy which helps you in case any
                    unfortunate event takes place in your office for eg. fire burglary. 
             
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(20)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 20, 'fa-minus': openItemIndex === 20 }"  aria-hidden="true"></i>
                21. Is motor vehicle insurance mandatory under law?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 20 }">
             
                  Following illustrative list which generally covers under Office Package Insurance Policy
              
                <ul>
                  <li> Commercial offices </li>
                  <li> Government offices </li>
                  <li> ITES and BPO call centers </li>
                  <li> Softwere development office </li>
                  <li> Office premises, including wherehouse and manufacturing units etc.</li>
                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(21)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 21, 'fa-minus': openItemIndex === 21 }"  aria-hidden="true"></i>
                22. Who normally needs Constructuion All Risk?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 21 }">
              
                  Generally all parties involved in any way in the construction may be insured
                  <br> For Eg.
                
                <ul>
                  <li> Contractors</li>

                  <li> Firms commissioned to carry out the work, including subcontractors</li>

                  <li> The purchaser or owner</li>

                  <li> Parties financing the project</li>

                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(22)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 22, 'fa-minus': openItemIndex === 22 }"  aria-hidden="true"></i>
                23. What are the add on coverages under a Construction All Risk policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 22 }">
                
                <ul>
                  <li>	Air Fright </li>
                  <li>	Earthquake</li>
                  <li>	Terrorism</li>
                  <li>	Owners surrounding property</li>
                  <li> 	Third party liability </li>
                  <li>	Construction, plant & Machinery </li>
                  <li>	Cross Liablity</li>
                  <li>	Breakage of glasses etc.</li>
                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(23)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 23, 'fa-minus': openItemIndex === 23 }"  aria-hidden="true"></i>
                24. What are th add on covers under Erection All Risk policy?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 23 }">
                
                <ul>
                  <li>	Owners surrounding property</li>
                  <li>	Air Fright</li>
                  <li> 	Additional custom duty</li>
                  <li> 	Overtime charges</li>
                  <li>	Contractors plant & machinery </li>
                  <li>  Holiday and Overtime rates and Wages</li>
                  <li>	Escalation</li>
                </ul>
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(24)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 24, 'fa-minus': openItemIndex === 24 }"  aria-hidden="true"></i>
                25. Under Contract plant& machinery What is the policy Period means? 
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 24 }">
                The cover attaches with the commencement of work or after the items entered in the schedule of the policy have been unloaded at the site, whichever is earlier and shall expire on the date specified in the policy. However, the company's liability expires for parts of the insured contract works taken over or put into service by the Principal prior to the expiry date specified in the policy, whichever shall be earlier.
              </div>
            </div>
            <!-- Add more FAQ items as needed -->
          </div>
        </div>
        <div class="card-data">
          <div class="card-header" (click)="faqs('renew')">
            <div class="card" >
              <a class="card-title">
                <h1 style="color: antiquewhite;">Renewal Insurance Related Questions</h1>
                <i class="fa fa-plus" aria-hidden="true" style="color:antiquewhite" *ngIf="isrenewnotcollapsed"></i>
                <i class="fa fa-minus" aria-hidden="true" style="color:antiquewhite" *ngIf="isrenewcollapsed"></i>
              </a>
            </div>
          </div>
          <div class="faq" *ngIf="isrenew">
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(0)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }" aria-hidden="true"></i>
                1. What does renewal mean in insurance?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }">
                Renewal, in the context of insurance, refers the continuation of coverage. The policyholder extends their contract with the insurance company to continue their current coverage for a furture specified period.
              </div>
            </div>
        
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(1)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"  aria-hidden="true"></i>
                2. What if my policy is not renewed in time before expiry date, will it be denied for renewal? 
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">
                The policy will be renewable provided you pay the premium within 30 days (called as Grace Period) of expiry date. The policy will lapse if the premium is not paid within the grace period. However, coverage would not be available for the period for which no premium is received by the insurance company.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(2)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 2, 'fa-minus': openItemIndex === 2 }"  aria-hidden="true"></i>
                3. In case the policy renewal premium is paid after the grace period, will there be any extra charge that will have to be paid?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }">
                In case if the policy is lapsed, then the customer will have to pay the interest at the rate of 8% on overdue premium amount.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(3)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"  aria-hidden="true"></i>
                4. What’s the Renewal Process?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }">
                Whether you are renewing or buying car insurance online, visiting the www.Click4bima.com renewing your car insurance policy is easy. If there is no inspection required, you can renew your car insurance policy within minutes. Once you complete the online payment, you will receive the policy in your inbox.<br><br>
                The car inspection step in the renewal process is dependent on your insurance provider and the time lapsed after the expiry date. Some insurers do not need an inspection if the policy is about to be renewed within a week after its expiry. For some, that period can be a month as well. However, if the period exceeds 90 days, then it becomes mandatory to inspect the car before insuring it again.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(4)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"  aria-hidden="true"></i>
                5. Can A Policy Be Renewed From A Different Insurance Provider? 
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                Yes, just as you have the choice to buy insurance from any insurance provider, you can choose to renew it from a different insurance provider as well. When the policy expires or is about to expire, it is a good time to review your insurance provider. It is completely legal to buy insurance from one provider and renew it from another<br><br>
   You can change your insurance provider if you have had a bad experience dealing with that company. If you have heard good things about a particular insurance provider and you want to switch, you certainly can while renewing your insurance. Your NCB will also stay intact if you choose to go to a different car insurance provider.
                  
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(5)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"  aria-hidden="true"></i>
                6. Why Renew Your Policy with us? 
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">
                 We are here to provide you total convenience in buying insurance online as well as renewing it. The products are simple to understand and easy to purchase. Claiming insurance is hassle-free and the entire insurance experience is quick, transparent, and supportive.
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(6)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"  aria-hidden="true"></i>
                7. Is car insurance renewal mandatory every year?  
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }">
              
                  Yes, car insurance renewal is mandatory every year. In other words, car insurance policies are annual policies which have to be renewed every year.
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(7)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"  aria-hidden="true"></i>
                8. Can I opt for online car policy renewal? 
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">
              
                  Yes, you can do your car insurance renewal online through our website.
              
                
              </div>
            </div>
            <div class="faq-item">
              <div class="faq-question" (click)="toggleAnswer(8)">
                <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"  aria-hidden="true"></i>
                9. What are the documents required for renewal of insurance?
              </div>
              <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }">

                  Very simple, let say For example online car insurance policy renewal, you will require your current insurance policy number, vehicle’s registration number, manufacturer’s name and model detail. 
               
              </div>
            </div>
            <!-- Add more FAQ items as needed -->
          </div>
        </div>
      </div>
    </div>   
 </div>
</div>
<app-footer></app-footer>