<div class="user-dashboard">
    <div class="row user-route">
        <form id="PostForm" name="PostForm" autocomplete="off" [action]="RelianceDetails.sourceUrl" method="get">
            <input name="ProposalNo" [value]="RelianceDetails.proposalNo">
            <input type="hidden" name="userID"  [value]="RelianceDetails.userID">
            <input type="hidden" name="ProposalAmount" [value]="RelianceDetails.proposalAmount">
            <input type="hidden" name="PaymentType" [value]="RelianceDetails.paymentType">
            <input type="hidden" name="Responseurl" [value]="RelianceDetails.responseurl">
            <input type="hidden" name="CKYC" [value]="RelianceDetails.ckyc">
            <input type="hidden" name="IsDocumentUploaded" [value]="RelianceDetails.isDocumentUploaded">
            <input type="hidden" name="PanNo" [value]="RelianceDetails.panNo">
            <input type="hidden" name="IsForm60" [value]="RelianceDetails.isForm60">
             <input type="hidden" name="subscription-key" [value]="RelianceDetails.subscription_key">
             <input type="submit" value="Submit" (click)="submitForm()"> 
            </form>
    </div>
</div>
