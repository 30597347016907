<div class="container testim">
    <div class="testimonial_div">
        <section class="pt-5 pb-5">
            <div class="testim-card">
                <!-- <h1 style="text-align: center;">
                    We <span style="color: var(--main2)">care</span> about our <span style="color: var(--main)">customer</span>
                    experience too
                    </h1> -->
                <h1 style="text-align: center;">
                    {{langList.testimonial_title}}
                    </h1>
                <div class="row">
                    <!-- <div [ngClass]="{'col-md-3': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h1>
                        We <span style="color: var(--main2)">care</span> about our <span style="color: var(--main)">customer</span>
                        experience too
                        </h1>
                        <span class="font-body">
                        At Click4bima, we believe that providing exceptional customer experience is at the heart of everything we do. We care deeply about our customers and strive to create a positive and seamless journey for everyone who interacts with us. Experience the difference of a customer-centric approach with us today. Your satisfaction is our top priority, and we are always here to serve you better. 
                        </span>
                    </div> -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="100000">
                            <div class="w-100 carousel-inner mb-5" role="listbox">
                                <div class="carousel-item active">
                                    <div class="row mt-5">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“It's been really great experience on all my insurance needs .it really made the experience stress free. I would have been a wreck had I gone anywhere else but Click4bima for all the help.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“I just wish to express my complete satisfaction in bringing insurance policies to the Click4bima .I now know that all of my assets are covered and that if I should ever have any questions, Click4bima will always be there. Thank you, Click4bima , for making my insurance buying experience a stress-free one!”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“Your timely, professional and precisely informative help has been great. It is truly a pleasure to associates with you and I will gladly refer you to other friends”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="row mt-5">
                                        
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“I appreciate the time, energy and expertise of the entire organization. In particular, how your team is going above and beyond relative to the 'Un-Affordable Care Act'. Thanks again for all you do.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“Click4bima has provided Business insurances, vehicle, home and contents and even trave linsurance. They have always been completely professional, efficient, worked hard to provide the best cover and always a pleasure to deal with. Making a claim is seamless with experienced and prompt assistance and guidance.Well done Ciclk4bima for providing such a great service.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“I have found Click4bima to be a reliable and responsive company. The staff is always helpful, friendly and responds to all my requests with a sense of urgency.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="row mt-5">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“Customer friendly and prompt service. I am totally happy. Continue the good work.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“We were in need of private health insurance. Click4bima was fast in its results and answered all of my many questions. We were all set up and ready to go in a matter of a day or two. Thanks Click4bima.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“I really appreciate your care & compassion shown to me. Your wonderful service is greatly appreciated.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="row mt-5">
                                        
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“Very happy with Click4bima Explained insurance and prescription plans very well.Comparisons were done and I am confident in the plan we have chosen. I highly recommend Click4bima to anyone who has any questions about insurance .”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“It's been really great experience on all my insurance needs .it really made the experience stress free. I would have been a wreck had I gone anywhere else but Click4bima for all the help.”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div class="carousel-caption card">
                                                <p class="font-body box-text">“I just wish to express my complete satisfaction in bringing insurance policies to the Click4bima .I now know that all of my assets are covered and that if I should ever have any questions, Click4bima will always be there. Thank you, Click4bima , for making my insurance buying experience a stress-free one!”</p>
                                                <!-- <p class="font-body box-text">.....</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left"></i></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right"></i></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
        </section>
        <div class="feedback_para text-center" *ngIf="isLoggedIn()">
            <p style="color:#000000;">Share Your Valuable Experience with Us</p>
            <a href="/add-testimonial"><button class="btn btn-primary" type="button">Upload Your Testimonial</button></a>
        </div>
    </div>
  </div>

  <!-- [ngClass]="{'uplodTestimoial':!isUserLogged}" -->