<app-navbar></app-navbar>
<div class="container">
    <div class="row mt-5">
        <div class="col-md-1">
            <button class="btn btn-bd btn-sm" (click)="back()">Back</button>
        </div>
        <div class="col-md-10 text-start">
            <h4 class="mt-2">Summary</h4>
        </div>
        <div class="col-md-1" style="    position: relative;
        right: 16px;">
            <button class="btn btn-primary btn-sm"><img src="../../../assets/svgIcon/ShareNetwork.svg"
                    alt="">&nbsp;Share</button>
        </div>

    </div>
    <div class="row " >
        <div class="col-md-4 mt-5">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-6">
                        <img src="../../../assets/images/united.png" width="80%" alt="">
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4"><span>
                                    Plan:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">Comprehensive</label>
                            </div>
                            <div class="col-md-4"><span>
                                    IDV:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">₹15,186</label>
                            </div>
                            <div class="col-md-5"><span>
                                Tenure:
                            </span></div>
                        <div class="col-md-7">
                            <label for="">1 Year</label>
                        </div>

                        </div>

                    </div>
                  
                    <div class="col-md-12">
                        <hr>
                        <h5 class="h">Premium Breakup</h5>
                        <hr>
                    </div>
                    <div class="col-md-12">

                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <span>Basic Own Damage Premium</span>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <span>₹10,252</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-9">
                                <span style="color:black"><b>Net Premium</b></span>
                            </div>
                            <div class="col-md-3">
                                <span style="color:black"><b>₹12,250</b></span>
                            </div>
                            <div class="col-md-9 mt-2 hf-cl">
                                <span>GST (18%)</span>
                            </div>
                            <div class="col-md-3 mt-2 hf-cl">
                                <span>+ ₹699</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <h5 class="h" style="color:black ">Total Premium</h5>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <h5 class="h" style="color:black">₹12,250</h5>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-1">
                        <button class="btn btn-primary" id="btn">
                            Pay Securely
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 mt-5">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-12 mt-2 p-2    back">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Members Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="down2" (click)="ChildDown()">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="Up2" (click)="ChildUp()">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>


                        </div>
                    </div>



                
                <div class="row" *ngIf="child">
                    <div class="col-md-12 mt-2 p-2    back1">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Member 1 (Self - 61 Years)  &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="chDw" (click)="next('down')" >
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="chUp" (click)="next('up')" >

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>


                        </div>
                    </div>
                    <div class="col-md-12 mt-1" *ngIf="chUp">   
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <span>Name</span>
                                <p>MR JIGNESH MISTRY</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Date of Birth</span>
                                <p>23-12-1998</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>PAN Card Number</span>
                                <p>AAAAA1111A</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Passport Number</span>
                                <p>AAAAA1111A</p>
                            </div>  
                            <div class="col-md-6 mt-2">
                                <span>Mobile Number</span>
                                <p>+919687337536</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Email ID</span>
                                <p>mistryjignesh56@gmail.com</p>
                            </div>  
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-2    back1">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Member 2 (Self - 61 Years)  &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="chDw1" (click)="next('down1')" >
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="chUp1" (click)="next('up1')" >

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>


                        </div>
                    </div>
                    <div class="col-md-12 mt-1" *ngIf="chUp1">   
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <span>Name</span>
                                <p>MR JIGNESH MISTRY</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Date of Birth</span>
                                <p>23-12-1998</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>PAN Card Number</span>
                                <p>AAAAA1111A</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Passport Number</span>
                                <p>AAAAA1111A</p>
                            </div>  
                            <div class="col-md-6 mt-2">
                                <span>Mobile Number</span>
                                <p>+919687337536</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Email ID</span>
                                <p>mistryjignesh56@gmail.com</p>
                            </div>  
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-2    back1">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Member 2 (Self - 61 Years)  &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="chDw2" (click)="next('down2')" >
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="chUp2" (click)="next('up2')" >

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>


                        </div>
                    </div>
                    <div class="col-md-12 mt-1" *ngIf="chUp2">   
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <span>Name</span>
                                <p>MR JIGNESH MISTRY</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Date of Birth</span>
                                <p>23-12-1998</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>PAN Card Number</span>
                                <p>AAAAA1111A</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Passport Number</span>
                                <p>AAAAA1111A</p>
                            </div>  
                            <div class="col-md-6 mt-2">
                                <span>Mobile Number</span>
                                <p>+919687337536</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Email ID</span>
                                <p>mistryjignesh56@gmail.com</p>
                            </div>  
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-2    back1">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Member 2 (Self - 61 Years)  &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="chDw3" (click)="next('down3')" >
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="chUp3" (click)="next('up3')" >

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>


                        </div>
                    </div>
                    <div class="col-md-12 mt-1" *ngIf="chUp3">   
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <span>Name</span>
                                <p>MR JIGNESH MISTRY</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Date of Birth</span>
                                <p>23-12-1998</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>PAN Card Number</span>
                                <p>AAAAA1111A</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Passport Number</span>
                                <p>AAAAA1111A</p>
                            </div>  
                            <div class="col-md-6 mt-2">
                                <span>Mobile Number</span>
                                <p>+919687337536</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <span>Email ID</span>
                                <p>mistryjignesh56@gmail.com</p>
                            </div>  
                        </div>
                    </div>
                </div>
                    <div class="col-md-12 mt-2 p-2    back">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Address Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="down1" (click)="dropDown('down1')">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="Up1" (click)="dropDown('up1')">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="Up1">
                        <div class="row">
                            <div class="col-md-6 mt-1">
                                <span>Flat Number</span>
                                <p>A-306</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>Street Address</span>
                                <p>Jawahar Nagar</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>State </span>
                                <p>Rajasthan</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>District</span>
                                <p>Chittorgarh</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>Village</span>
                                <p>Chittorgarh</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>PIN Code</span>
                                <p>312001</p>
                            </div>
                        </div>
                    </div>
                   
                <div class="col-md-12 mt-2 p-2    back">
                    <div class="row">
                        <div class="col-md-11 col11">
                            <h5 class="h" style="color:black">Nominees Details   &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                    bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                    Edit</span>
                            </h5>
                        </div>

                        <div class="col-md-1  col1" *ngIf="down3" (click)="dropDown('down3')">
                            <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                        </div>
                        <div class="col-md-1  col1" *ngIf="Up3" (click)="dropDown('up3')">

                            <img src="../../../assets/svgIcon/Vector.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-2" *ngIf="Up3">
                    <div class="row">
                        <div class="col-md-6 mt-1">
                            <span>Nominees Name</span>
                            <p>Mistry Jignesh</p>
                        </div>
                        <div class="col-md-6 mt-1">
                            <span>Relation</span>
                            <p>Brother</p>
                        </div>
                        <div class="col-md-6 mt-1">
                            <span>Age </span>
                            <p>24</p>
                        </div>
                        <div class="col-md-6 mt-1">
                            <span>Gender</span>
                            <p>Male</p>
                        </div>
                       
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>  