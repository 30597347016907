<div class="user-dashboard">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#009ef7"
               type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="card-user user-route">
    <div class="card-user">
      <h1>User Documents List</h1>

      <div class="row p-3">
        <div class="col-md-6 ps-0" *ngFor="let userDocument of userDocumentList">
          <div class="row mt-2 m-0 p-3 card">
            <div class="row m-0 p-0 text-start">
              <div class="col-6 d-flex">
                <div>
                  <p class="font-body m-0">Name</p>
                  <h4>{{userDocument.documentTypeName}}</h4>
                </div>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <div>
                  <span *ngIf="userDocument.documentStatus == 1" class="badge badge-light-success">Active</span>
                  <span *ngIf="userDocument.documentStatus == 0" class="badge badge-light-danger">Inactive</span>
                </div>
              </div>
              <div class="col-6 d-flex">
                <div>
                  <p class="font-body m-0">Timestamp</p>
                  <h4>{{ userDocument.whenCreated | date:'medium' }}</h4>
                </div>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <div>
                  <p class="font-body m-0">Actions</p>
                  <a (click)="viewDocument(userDocument.documentTypeId)" data-bs-toggle="modal" data-bs-target="#kt_modal_add_user" class="btn btn-light btn-active-light-primary btn-sm" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    View
                    <span class="svg-icon svg-icon-5 m-0">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <button type="button" class="btn btn-primary float-end me-5" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
          <span class="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor" />
              <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
            </svg>
          </span>
          Add Document
        </button>

        <div class="offcanvas offcanvas-end overflow-scroll" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
             id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
          <add-edit-document></add-edit-document>
        </div>
      </div>
    </div>
  </div>
</div>
