<!-- <app-navbar></app-navbar> -->
<!-- <div class="container"> -->
      <div class="overlay" *ngIf="cutomerDetail">
        <div class="popup2 col-md-3 col-11">
          <div class="modal-header tooltip_">
            <h4 class="mb-0">{{langList.your_details}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true"></span>
           </button>
          </div>
          <div class="modal-body">
            <div class="">
              
              <!-- <form action="#"> -->
            <form [formGroup]="customerInfoForm" class="needs-validation" novalidate="novalidate" autocomplete="off">
              <div class="text-left">                
                <select name="title" id="title" class="form-select form-select-custom input_" formControlName="title">
                    <option *ngFor="let title of titleList" [value]="title">
                        {{title}}
                    </option>
                </select>  
                <label class="required label_">{{langList.name_title}}</label>            
              </div>  
              <div class="text-left">
                  <input class="form-control input_" type="text" id="firstname" name="firstName" placeholder=" " formControlName="firstName"  (keypress)="alphabetOnly($event)"
                  [ngClass]="{'border border-danger': ((customerInfoFormControl['firstName'].touched  || submitted) && !customerInfoFormControl['firstName'].valid)}">
                  <label class="required label_">{{langList.first_name}}</label>
                  <span class="text-danger" *ngIf="((customerInfoFormControl['firstName'].touched  || submitted) && customerInfoFormControl['firstName'].errors?.['required'])">{{langList.first_name_req}}</span>
                </div>
                <div class="text-left">
                  <input class="form-control input_" type="text" id="lastName" name="lastName" placeholder=" " formControlName="lastName"  (keypress)="alphabetOnly($event)"
                  [ngClass]="{'border border-danger': ((customerInfoFormControl['lastName'].touched  || submitted) && !customerInfoFormControl['lastName'].valid)}">
                  <label class="required label_">{{langList.last_name}}</label>
                  <span class="text-danger" *ngIf="((customerInfoFormControl['lastName'].touched  || submitted) && customerInfoFormControl['lastName'].errors?.['required'])">{{langList.last_name_req}}</span>
                </div>
                <div class="text-left">                  
                  <input class="form-control input_" type="email" name="email" placeholder=" " formControlName="email"
                  [ngClass]="{'border border-danger': ((customerInfoFormControl['email'].touched  || submitted) && !customerInfoFormControl['email'].valid)}">
                  <label class="required label_">{{langList.email}}</label>
                  <Span class="text-danger" *ngIf="((customerInfoFormControl['email'].touched  || submitted) && customerInfoFormControl['email'].errors?.['required'])">
                    {{langList.email_req}}
                  </Span>
                  <Span class="text-danger" *ngIf="((customerInfoFormControl['email'].touched  || submitted) && customerInfoFormControl['email'].errors?.['pattern'])">
                    {{langList.pro_valid_email}}
                  </Span>
                </div>
                <div class="text-left">                  
                  <input class="form-control input_" type="number" name="mobileNumber" placeholder=" " formControlName="mobileNumber"  minlength="10" maxlength="10"  (keypress)="numberOnly($event)" onKeyPress="if(this.value.length==10) return false;"
                  [ngClass]="{'border border-danger': ((customerInfoFormControl['mobileNumber'].touched  || submitted) && !customerInfoFormControl['mobileNumber'].valid)}">
                  <label class="required label_">{{langList.mobile_number}}</label>
                  <Span class="text-danger" *ngIf="((customerInfoFormControl['mobileNumber'].touched  || submitted) && customerInfoFormControl['mobileNumber'].errors?.['required'])">
                    {{langList.mobile_req}}
                  </Span>
                  <Span class="text-danger" *ngIf="((customerInfoFormControl['mobileNumber'].touched  || submitted) && customerInfoFormControl['mobileNumber'].errors?.['pattern'])">
                    {{langList.pro_valid_mobile}}
                  </Span>
                  
                </div>
                <div class="text-end">
                  <button type="button" class="btn btn-primary" [disabled]="!customerInfoFormControl['lastName'].valid || !customerInfoFormControl['firstName'].valid || !customerInfoFormControl['email'].valid || !customerInfoFormControl['mobileNumber'].valid" (click)="GetQuoteInfo()">{{langList.continue}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    <div class="row">
        <div class="col-md-8 mt-5">
            <div class="card p-5">
                <ul class=" row justify-content-between px-8 mb-0">
                  <li class="col selected-box {{tabNumber == 1? 'onemore' : ( this.mftList || tabNumber >= 2 ? 'active': '' )}}" (click)="changeTabs($event, 1)">
                    <a style="text-decoration: none;" href="#1a" data-toggle="tab">
                      <h3>{{langList.city}}/{{langList.rto}}</h3>
                    </a>
                  </li>&nbsp;&nbsp;
                  <li class="col selected-box {{tabNumber == 2? 'onemore' : (tabNumber >= 3 ? 'active': '' )}}" (click)="changeTabs($event, 2)">
                    <a style="text-decoration: none;" href="#2a" data-toggle="tab">
                      <h3>{{langList.manufacturer}}</h3>
                    </a>
                  </li>&nbsp;&nbsp;
                  <li class="col selected-box {{tabNumber == 3? 'onemore' : ( tabNumber >= 4 ? 'active': '' )}}" (click)="changeTabs($event, 3)">
                    <a style="text-decoration: none;" href="#3a" data-toggle="tab">
                      <h3>{{langList.model}}</h3>
                    </a>
                  </li>&nbsp;&nbsp;
                  <li class="col selected-box {{tabNumber == 4? 'onemore' : ( tabNumber >= 5 ? 'active': '' )}}" (click)="changeTabs($event, 4)">
                    <a style="text-decoration: none;" href="#4a" data-toggle="tab">
                      <h3>{{langList.variant}}</h3>
                    </a>
                  </li>&nbsp;&nbsp;           
                  <!-- <ng-container *ngIf="showFuelTab"> -->
                    <li class="col selected-box {{tabNumber == 5? 'onemore' : ( tabNumber >= 6 ? 'active': '' )}}" (click)="changeTabs($event, 5)">
                      <a style="text-decoration: none;" href="#5a" data-toggle="tab">
                        <h3>{{langList.fuel_type}}</h3>
                      </a>
                    </li>&nbsp;&nbsp;
                  <!-- </ng-container>  -->
                  <li class="col selected-box {{tabNumber == 6? 'onemore' : tabNumber >= 7 ? 'active': ''}}" (click)="changeTabs($event, 6)">
                    <a style="text-decoration: none;" href="#6a" data-toggle="tab">
                      <h3>{{langList.year}}</h3>
                    </a>
                  </li>
                </ul>
                <div class="tab-content clearfix">
                  <div id="1a" class="tab-pane active px-8" >
                    <div *ngIf="selCity" class="mt-5">
                      <div>
                        <input type="text" class="form-control input-control cityIcon w-100" #input (input)="onBlurCity(input.value)"
                               placeholder="{{langList.search_city}}">
                      </div>
                      <div>
                        <h3 class="text-start mt-5">{{langList.select_city}}</h3>
                      </div>
                      <div class="mx-2">
                        <div class="row">
                          <div class="col-md-2 col-6 px-1 mb-2 card-selection" *ngFor="let city of cityList; let i = index;" (click)="cityChange(city.city,city.rto)">
                            <div class="card">
                              <h4 class="m-auto">{{city.cityRTO}}</h4>
                            </div>
                          </div>
                        </div>
                    </div>
                      <!-- <div>
                        <h6>Can't find your city? <a href="#">Click Here</a></h6>
                      </div> -->
                    </div><br>
                    <!-- <div *ngIf="rtoList">
                      <div>
                        <h3 class="text-start">{{langList.select_rto}}</h3>
                      </div>
                      <div class="x-2">
                        <div class="row">
                          <div class="col-md-2 col-6 px-1 mb-2" *ngFor="let rto of rtoNamesList; let j = index;" (click)="handleRtoClick(rto)">
                            <div class="card">
                              <h4 class="m-auto">{{rto.regionCode}}</h4>
                            </div>
                          </div>
                        </div>
                      </div>                
                    </div> -->
                    <div *ngIf="mftList" id="2a" class="tab-pane">
                      <div>
                        <input type="text" class="form-control input-control manufacturerIcon w-100" placeholder="{{langList.search_manufacturer}}"
                              #manufacture (input)="getVehicleCompanyDetails(manufacture.value)">
                      </div>
                      <div class="mt-5">
                        <div>
                          <h3 class="text-start">{{langList.select_manufacturer}}</h3>
                        </div>
                        <div class="x-2">
                          <div class="row">
                            <div class="col-md-2 col-6 px-1 mb-2 card-selection" *ngFor="let company of vehicleCompanyList; let k = index;" (click)="getVehicleModelDetails(company,company)">
                              <div class="card">
                                <h4 class="m-auto" >{{company}}</h4>
                              </div>  
                            </div>
                          </div>
                      </div>
                      </div><br>
                    </div>
                    <div *ngIf="modelList" id="3a" class="tab-pane">
                      <div>
                        <input type="text" class="form-control input-control bikeIcon" placeholder="{{langList.search_model}}"
                              style="width: 100% !important;" #model (input)="searchVehicleModelDetails(model.value)">
                      </div>
                      <div class="mt-5">
                        <div>
                          <h3 class="text-start">{{langList.select_model}}</h3>
                        </div>
                        <div class="x-2">
                          <div class="row">
                            <div class="col-md-2 col-6 px-1 mb-2 card-selection" *ngFor="let vehicle of vehicleModelList;" (click)="getVehicleVariantDetails(vehicle,vehicle)">
                              <div class="card">
                                <h4 class="m-auto" >{{vehicle}}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br>
                    </div>
                    <div *ngIf="variList" id="4a" class="tab-pane ">
                      <div>
                        <input type="text" class="form-control input-control bikeIcon" placeholder="{{langList.search_variant}}"
                              style="width: 100% !important;"  #variant (input)="searchVehicleVariantDetails(variant.value)">
                      </div>
                      <div class="mt-5">
                        <div>
                          <h3 class="text-start">{{langList.select_variant}}</h3>
                        </div>
                        <div class="x-2">
                          <div class="row">
                            <div class="col-md-2 col-6 px-1 mb-2 card-selection" style="margin: 5px 5px;" *ngFor="let variant of vehicleVariantList;" (click)="getVehicleReg(variant)">
                              <div class="card">
                                <h4 class="m-auto" >{{variant}}</h4>
                              </div>
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>
                    <div *ngIf="fuelType" id="5a" class="tab-pane">
                      <div class="mt-5">
                        <div>
                          <h3 class="text-start">{{langList.select_fuel_type}}</h3>
                        </div>
                        <div class="x-2">
                          <div class="row">
                            <div class="col-md-2 col-6 px-1 mb-2 card-selection" *ngFor="let fuelType of fuelTypeLists;" (click)="getFuelType(fuelType.name)">
                              <div class="card">
                                <h4 class="m-auto" >{{fuelType.name}}</h4>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                      <!-- <div>
                        <h5>Can't find your two wheeler variant? <a href="#">Click Here</a></h5>
                      </div> -->
                    </div>
                    <div *ngIf="yearList" id="6a" class="tab-pane">
                      <div class="mt-5">
                        <div class="x-2">
                          <div class="row">
                            <div class="col-9">
                              <div>
                                <h3 class="text-start">{{langList.select_year}}</h3>
                              </div>
                              <div class="row"  *ngIf="!isnew">
                                <div class="col-md-2 col-6 px-1 mb-2 card-selection" *ngFor="let yr of registrationYear;" (click)="changeYears(yr)">
                                  <div class="card" [ngClass]="{'selected_card' : yr.toString() == this.formData.get('registrationYear')?.value }">
                                    <h4 class="m-auto" >{{yr}}</h4>
                                  </div>
                                </div>
                              </div>
                              <div class="row"  *ngIf="isnew">
                                <div class="col-md-2 col-6 px-1 mb-2 card-selection" *ngFor="let yr of years;"  (click)="changeYears(yr)">
                                  <div class="card" [ngClass]="{'selected_card' : yr.toString() == this.formData.get('registrationYear')?.value }">
                                    <h4 class="m-auto" >{{yr}}</h4>
                                  </div>
                                </div>
                              </div> 
                            </div>
                            <div class="col-3 border-left ">
                              <div>
                                <h3 class="text-start">Month</h3>
                              </div>
                              <div class="row">
                                <div class="col-lg-4 col-md-6 px-1 mb-2 card-selection" *ngFor="let mnth of registrationMonth;"  (click)="changemonth(mnth)">
                                  <div class="card" [ngClass]="{'selected_card' : mnth == this.formData.get('registrationMonth')?.value }">
                                    <h4 class="m-auto" >{{mnth}}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-5">
            <div class="card">
              <form [formGroup]="formData" class="needs-validation frm" novalidate="" autocomplete="off">
                <div class="p-5 mb-9">
                  <h4 class="text-start mb-3">
                    {{langList.vehicle_detail_form}}
                </h4>
                <div class="form-group">              
                  <input type="text" class="form-control input_" name="rto" placeholder=" " formControlName="city" readonly>
                  <label class="float-start label_">{{langList.city}}</label>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control input_" name="rto" placeholder=" " formControlName="rto"readonly>
                  <label class="float-start label_">{{langList.rto}}</label>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control input_" placeholder=" " formControlName="make"readonly>
                  <label class="float-start label_" for="make">{{langList.manufacturer}}</label>              
                </div>
                <div class="form-group">
                  <input type="text" class="form-control input_" placeholder=" " formControlName="model"readonly>
                  <label class="float-start label_" for="Modal">{{langList.model}}</label>              
                </div>
                <div class="form-group">
                  <input type="text" class="form-control input_" placeholder=" " formControlName="variant"readonly>
                  <label class="float-start label_" for="Variant">{{langList.variant}}</label>              
                </div>
                <!-- <div class="form-group" *ngIf="formData?.get('category')?.value == 'GCV' || formData?.get('category')?.value == 'MISC'">
                  <input type="text" class="form-control input_" placeholder=" " formControlName="gVW" >
                  <label class="float-start label_" for="gVW">Gross Vehicle Weight</label>              
                </div> -->
                <div class="form-group" *ngIf="formData?.get('category')?.value == 'GCV' || formData?.get('category')?.value == 'MISC'">
                  <select class="form-select form-select-custom input_" aria-label="Default select example" formControlName="gVW">
                    <option *ngFor="let gvw of CommVehicleWeightList" value="{{gvw.value}}">{{gvw.text}}</option>    
                  </select>
                  <label class="float-start label_" for="gVW">Gross Vehicle Weight</label>  
                </div> 
                <div class="form-group">
                <!-- <span *ngIf="showFuelTab"> -->
                  <input type="text" class="form-control input_" placeholder=" " formControlName="fuelType"readonly>
                  <label class="float-start label_" for="fuelType">{{langList.fuel_type}}</label>            
                <!-- </span> -->
                </div>
                <div class="form-group">
                  <input type="text" class="form-control input_" placeholder=" " formControlName="registrationYear"readonly>
                  <label class="float-start label_" for="registration">{{langList.reg_year}}</label>              
                </div>
                <div class="form-group">
                  <input type="text" class="form-control input_" placeholder=" " formControlName="registrationMonth"readonly >
                  <label class="float-start label_" for="registrationMonth">Month</label>              
                </div>
                <div class="form-group mb-8">
                  <app-autocomplete class="w-100 input_" (itemSelected)="onPincodeSelection($event)"
                  [items]="pincodeList" [supportingText]="true" (onBlurEvent)="onPincodeBlur($event)"	
                  [showError]="false" (onKeyUpEvent)="onPincodeChange($event)"  (input)="onInputChange($event)"></app-autocomplete>
                  <label class="float-start label_" for="pincode">Pincode</label>   
                  <span class="text-main position-absolute ml-1_25">Note: Please provide pincode for more accurate quotations</span>         
                </div>
              </div>
                <div class="text-center">
                  <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" [disabled]="formData.invalid" (click)="customerInfo()">
                    {{langList.get_quotes}}
                  </button>
                </div>
              </form>
            </div>
          </div>
    </div>
<!-- </div> -->