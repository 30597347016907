export class BusinessInfoModel{

    public businessName!: string;
    public  businessPhoneNumber! :number;
    public  businessEmail! :number;
    public  businessDescription! :string;
    public  businessAddress1! :number;
    public  businessAddress2! :number;
    public businessPinCode!:number;
    public businessCity! :any;
    public countryName! :any;
    public  stateName! :any;
   

}

export class LookUpViewModel{
    public  id! :number;
    public  name! :string;
}

