<div class="user-dashboard">
    <div class="row user-route">

<form id="PostForm" name="PostForm" method="post" [action]="futureDetails.paymentURL" >
    <input type="hidden" name='TransactionID' [value]="futureDetails.transactionID"/>
    <input type="hidden" name='PaymentOption' [value]="futureDetails.paymentOption"/>
    <input type="hidden" name='ResponseURL' [value]="futureDetails.returnUrl"/>
    <input type="hidden" name='ProposalNumber' [value]="futureDetails.quotE_ID"/>
    <input type="hidden" name='PremiumAmount' [value]="futureDetails.premium"/>
    <input type="hidden" name='UserIdentifier' [value]="futureDetails.userIdentifier"/>
    <input type="hidden" name='UserId' [value]="futureDetails.userId"/>
    <input type="hidden" name='FirstName' [value]="futureDetails.firstName"/>
    <input type="hidden" name='LastName' [value]="futureDetails.lastName"/>
    <input type="hidden" name='Mobile' [value]="futureDetails.phoneNumber"/>
    <input type="hidden" name='Email' [value]="futureDetails.email"/>
    <input type="hidden" name="CheckSum" [value]="futureDetails.checkSum"/>
    <input type="hidden" name="Vendor" value="0"/>
    <input type="submit" value='Submit' />   
        </form>
    </div>
    </div>