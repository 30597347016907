import { Component, HostListener } from '@angular/core';
import { LanguageLableService } from '../Services/language-lable.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  isSmallScreen: boolean = false;selectedValue: string | null;
  langList: any;
 constructor(private languageLableService: LanguageLableService) { this.checkScreenSize(); } 
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); } 
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }

  ngOnInit() {
    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
  }

  languageChanged(){
    location.reload();
   }
}
