<div class="background-image-container">
  <div class="container">
    <!-- Your content goes here -->
    <div class="p-5">
      <a href="/" class="d-sm-block px"><img class="bg-image app-icon  " src="../../assets/images/logo.svg" alt="logo"
          width="150" /></a>
    </div>
    <div class="row col8 tabV">
      <div class="col-md-6 t-c-6 mt-3">
        <p class="title ">Empower Your Career: Take Control of Your Professional Journey</p>
        <p class="font-body main-text p-txt">
          Join our platform and take control of your professional journey, gaining access to a vast network of insurance
          providers and an array of tools and resources designed to supercharge your career.
        </p>
      </div>
      <div class="row rw-mg">
        <div class="col-md-2 mt-2 col2">
          <button type="button" class="btn btn-agentlog btt bt" (click)="Login()">Log In</button>
        </div>
        <div class="col-md-2 mt-2 col2 mg">
          <button type="button" class="btn btn-agentsign btt " (click)="Popup('signup')">Sign Up</button>
        </div>
      </div>
    </div>

    <div class="row image-container mrg">
      <div class="col-md-2 mt-4 col2 show-card ">
        <div class="business-box card-col2 card-back " (mouseenter)="showDiv1 = true" (mouseleave)="showDiv1 = false">
          <img class="img-icon pb-4" src="../../assets/svgIcon/agent/agentBusiness.svg" clialt="logo" width="50">
          <h4>Agent</h4>
          <span *ngIf="showDiv1">
            Empower Your Earnings By Amplifying Your Insurance Business with Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 hide-card">
        <div class="business-box card-col2 card-back h-card">
          <img class="img-icon pb-4" src="../../assets/svgIcon/agent/agentBusiness.svg" clialt="logo" width="50">
          <h4>Agent</h4>
          <span>
            Empower Your Earnings By Amplifying Your Insurance Business with Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 show-card">
        <div class="business-box card-col2 card-back" (mouseenter)="showDiv2 = true" (mouseleave)="showDiv2 = false">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/hospitalBusiness.svg" clialt="logo" width="50">
          <h4>Hospital <br> (Coming Soon)</h4>
          <span *ngIf="showDiv2">
            Join Our Hospital Network and Secure Your Practice's Future with Click4bima!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 hide-card">
        <div class="business-box card-col2 card-back h-card">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/hospitalBusiness.svg" clialt="logo" width="50">
          <h4>Hospital <br> (Coming Soon)</h4>
          <span>
            Join Our Hospital Network and Secure Your Practice's Future with Click4bima!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 show-card">
        <div class="business-box card-col2 card-back" (mouseenter)="showDiv3 = true" (mouseleave)="showDiv3 = false">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/medicalBusiness.svg" clialt="logo" width="50">
          <h4>Medical Store <br> (Coming Soon)</h4>
          <span *ngIf="showDiv3">
            Expand Your Business And Boost Your Earnings On Our Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 hide-card">
        <div class="business-box card-col2 card-back h-card">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/medicalBusiness.svg" clialt="logo" width="50">
          <h4>Medical Store <br> (Coming Soon)</h4>
          <span>
            Expand Your Business And Boost Your Earnings On Our Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 show-card">
        <div class="business-box card-col2 card-back" (mouseenter)="showDiv4 = true" (mouseleave)="showDiv4 = false">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/doctorBusiness.svg" clialt="logo" width="50">
          <h4>Doctor <br> (Coming Soon)</h4>
          <span *ngIf="showDiv4">
            Unlock Earnings, Register Your Practice – Boost Your Income With Our Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 hide-card">
        <div class="business-box card-col2 card-back h-card">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/doctorBusiness.svg" clialt="logo" width="50">
          <h4>Doctor <br> (Coming Soon)</h4>
          <span>
            Unlock Earnings, Register Your Practice – Boost Your Income With Our Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 show-card">
        <div class="business-box card-col2 card-back" (mouseenter)="showDiv5 = true" (mouseleave)="showDiv5 = false">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/garageBusiness.svg" clialt="logo" width="50">
          <h4>Garage <br> (Coming Soon)</h4>
          <span *ngIf="showDiv5">
            Drive Extra Income And Secure Your Garage Business Confidently On Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 hide-card">
        <div class="business-box card-col2 card-back h-card">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/garageBusiness.svg" clialt="logo" width="50">
          <h4>Garage <br> (Coming Soon)</h4>
          <span>
            Drive Extra Income And Secure Your Garage Business Confidently On Click4bima Portal!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 show-card">
        <div class="business-box card-col2 card-back" (mouseenter)="showDiv6 = true" (mouseleave)="showDiv6 = false">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/influencerBusiness.svg" clialt="logo" width="50">
          <h4>Influencer <br> (Coming Soon)</h4>
          <span *ngIf="showDiv6">
            Monetize Your Influence, Insure Your Success!
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-4 col2 hide-card">
        <div class="business-box card-col2 card-back h-card">
          <img class="img-icon pb-5" src="../../assets/svgIcon/agent/influencerBusiness.svg" clialt="logo" width="50">
          <h4>Influencer <br> (Coming Soon)</h4>
          <span>
            Monetize Your Influence, Insure Your Success!
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="business-info text-center">
    <span class="posp-cont">
      POSP
    </span>
    <span class="ben-cont">
      Benefits
    </span>

    <div class="row business-container">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div *ngIf="pospimg1">
              <img src="../../assets/svgIcon/agent/pospimg1.svg" class="w-img" alt="">
              <ul class="text-left">
                <li>
                  Flexibility to achieve a better work-balance.
                </li>
                <li>
                  Better reach-customers in different regions without physical office.
                </li>
                <li>
                  Increase productivity.
                </li>
              </ul>
            </div>
            <div *ngIf="pospimg2">
              <img src="../../assets/svgIcon/agent/pospimg2.svg" class="w-img" alt="">
              <ul class="text-left">
                <li>
                  Insurance sales career without any financial investment.
                </li>
                <li>
                  Business with zero financial risk.
                </li>
                <li>
                  Generate income through commissions without having to invest.
                </li>
              </ul>
            </div>
            <div *ngIf="pospimg3">
              <img src="../../assets/svgIcon/agent/pospimg3.svg" class="w-img" alt="">
              <ul class="text-left">
                <li>
                  Additional source of income by dedicating just a few hours per day.
                </li>
                <li>
                  Flexibility to choose the hours that best suit your schedule.
                </li>
                <li>
                  An excellent opportunity to earn additional income as a part-time.
                </li>
              </ul>
            </div>
            <div *ngIf="pospimg4">
              <img class="m-5" src="../../assets/svgIcon/agent/pospimg4.svg" class="w-img" alt="">
              <ul class="text-left">
                <li>
                  Expedite the policy issuance process with just one tap.
                </li>
                <li>
                  Streamline the onboarding process without extensive paperwork.
                </li>
                <li>
                  Digital documentation enhances the security and accessibility.
                </li>
              </ul>
            </div>
            <div *ngIf="pospimg5">
              <img src="../../assets/svgIcon/agent/pospimg5.svg" class="w-img" alt="">
              <ul class="text-left">
                <li>
                  Use the portal across a range of devices.
                </li>
                <li>
                  Conduct business and assist customers anytime, anywhere, and any device.
                </li>
                <li>
                  Continuous learning and skill development from any device.
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row img-mg">
              <div class="col-md-12">
                <div class="posp-image    mt-5 " (mouseover)="handleMouseOver1()" (mouseleave)="handleMouseLeave1()">
                  <img src="../../assets/svgIcon/agent/pospBen1.svg" class="w-img" alt="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="posp-image    mt-5" (mouseover)="handleMouseOver2()" (mouseleave)="handleMouseLeave2()">
                  <img src="../../assets/svgIcon/agent/pospBen2.svg" class="w-img" alt="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="posp-image    mt-5" (mouseover)="handleMouseOver3()" (mouseleave)="handleMouseLeave3()">
                  <img src="../../assets/svgIcon/agent/pospBen3.svg" class="w-img" alt="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="posp-image    mt-5" (mouseover)="handleMouseOver4()" (mouseleave)="handleMouseLeave4()">
                  <img src="../../assets/svgIcon/agent/pospBen4.svg" class="w-img" alt="">
                </div>
              </div>
              <div class="col-md-12">
                <div class="posp-image    mt-5" (mouseover)="handleMouseOver5()" (mouseleave)="handleMouseLeave5()">
                  <img src="../../assets/svgIcon/agent/pospBen5.svg" class="w-img" alt="">
                </div>
              </div>






            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="business-jrny text-center">

  <div class="container">
    <div class="p-5">
      <span class="posp-cont">
        POSP
      </span>

      <span class="ben-cont">
        Road Map
      </span>
    </div>
    <div class="row mt-5 business-container">
      <img src="../../assets/svgIcon/agent/agentjrny.svg" alt="">
    </div>
  </div>
</div>
<div>
  <div class="container">
    <div class="text-center mt-10">
      <span class="posp-cont">
        Frequently Asked
      </span>
      <span class="ben-cont">
        Questions
      </span>
      <p class="font-body">
        Have questions? We're here to help.
      </p>
    </div>
    <div class="faq-container">
      <!-- <div class="">
      <input type="text" class="form-control" placeholder="search">
    </div> -->
      <div class="row ">
        <div class="col-md-11 col10  mt-3 " (click)="opencloseAnswer(0)">
          <p class="ques-font">1. What does POSP mean?</p>
          <p class="font-body pl-2 faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }">
            POSP stands for "Point Of Sales Person". They act as intermediaries between the insurance provider and the
            customer, helping individuals choose the right insurance policies based on their needs.
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(0)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row ">
        <div class="col-md-11 col10  mt-3" (click)="opencloseAnswer(1)">
          <p class="ques-font">2. Why to become an Insurance Point Of Sale (POS)?</p>
          <p class="font-body pl-2 faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">
            <li>Work from Home</li>
            <li>Zero Investment Business</li>
            <li>Another source of Income, just few hrs to spend</li>
            <li>Paperless & easy to start, just 1 Tap to do policy</li>
            <li>Portal available on Mobile /Tablet / Laptop/ Desktop</li>
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(1)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(2)">
          <p class="ques-font">3. How much one can earn as an Insurance Point Of Sale (POS) Advisor?</p>
          <p class="font-body faq-answer pl-2 " [ngClass]="{ 'open': openItemIndex === 2 }">
            <li>Reward and Recognition</li>
            <li>You can earn up to 30% from New Premium Collection*</li>
            <li>Extra Referral Income</li>
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(2)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 2, 'fa-minus': openItemIndex === 2 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(3)">
          <p class="ques-font">4. What are the benefits that make Click4bima different from others?</p>
          <p class="font-body faq-answer pl-2" [ngClass]="{ 'open': openItemIndex === 3 }">
            <li>Local Assistance and Support on Premium Calculation</li>
            <li>Strong Relationship with Insurer</li>
            <li>Timely Payout</li>
            <li>In-house Lead Management</li>
            <li>In-house Grievances Resolution Desk</li>
            <li>In-house Claim & Research department</li>
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(3)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(4)">
          <p class="ques-font">5. What is the Minimum Qualification & what happens if you fail the Point Of Sale (POS)
            Advisor's exam with Click4bima?</p>
          <p class="font-body faq-answer pl-2" [ngClass]="{ 'open': openItemIndex === 4 }">
            To become an insurance Point of Sale (POS) or Partner, the minimum educational qualification is 10th pass.
            There is no upper age criteria for Point of Sale (POS), and anyone above the age of 18 can join and start
            earning.
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(4)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(5)">
          <p class="ques-font">6. How Can I Join Click4bima as a POS ?</p>
          <p class="font-body faq-answer pl-2" [ngClass]="{ 'open': openItemIndex === 5 }">
            <b>You just have to go for three steps</b>
            <li>Register yourself with your personal basic Information</li>
            <li>Scan & upload the Documents Require - PAN, Aadhar, Passport Size Photo <br>&nbsp;&nbsp;&nbsp;&nbsp; 10th
              Pass Certificate or Mark sheet, & Cancel Cheque for NEFT.</li>
            <li>Exam & Training for Just 15Hrs on mobile / Desktop/ Laptop</li>
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(5)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(6)">
          <p class="ques-font">7. When can I start Selling ?</p>
          <p class="font-body faq-answer pl-2" [ngClass]="{ 'open': openItemIndex === 6 }">
            To become an insurance Point of Sale (POS) or Partner, the minimum educational qualification is 10th pass.
            There is no upper age criteria for Point of Sale (POS), and anyone above the age of 18 can join and start
            earning.
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(6)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(7)">
          <p class="ques-font">8. What is the training duration and syllabus for becoming Click4bima POSP?</p>
          <p class="font-body faq-answer pl-2" [ngClass]="{ 'open': openItemIndex === 7 }">
            For becoming a POSP with Click4bima, one must undergo an online training of 15 hours. Training consists of 5
            Modules, and on each module, one must spend minimum hours to read & move to the next module. The training
            syllabus covers all the basic and advanced information on Insurance.
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(7)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(8)">
          <p class="ques-font">9. Which Insurance Companies' policy can Click4bima POSP sell?</p>
          <p class="font-body faq-answer pl-2" [ngClass]="{ 'open': openItemIndex === 8 }">
            <li>Availability of all products & Insurers under one roof</li>
            <li>Life insurance/ Health Insurance/ Car & Two Wheeler Insurance/ Commercial/ Business Insurance, Travel.
              Fire & Other Insurance</li>
            <li>Exclusive policies for Covid</li>
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(8)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"
            aria-hidden="true"></i>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-11 col10 mt-3" (click)="opencloseAnswer(9)">
          <p class="ques-font">10. What life and general insurance plans are available with Click4bima, and what can I
            expect in terms of earnings?</p>
          <p class="font-body faq-answer pl-2" [ngClass]="{ 'open': openItemIndex === 9 }">
            We have Life Guaranteed Saving Plans, Term Plans and wealth Plans, apart from this we also have General
            Insurance Health, Covid, Personal Accidental, Car Insurance, Two Wheeler Insurance, Taxi & Heavy Vehicle
            Insurance, Fire, Marine, Cattle, Home, Shop & Office Insurance
          </p>
        </div>
        <div class="col-md-1 cl2 mt-3 plus" (click)="opencloseAnswer(9)">
          <i class="fa" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"
            aria-hidden="true"></i>
        </div>
      </div>

    </div>
  </div>
  <div class="overlay" *ngIf="LoginPopUp">
    <div class="popup2">
      <div class="modal-header bg-transparent tooltip_">
        <!-- <h4 class="modal-title">Sign In</h4> -->
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        </button>
      </div>
      <div class="model-body">
        <div class="">

          <div class="row " *ngIf="component=='1'">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-center">
                  <img src="../../assets/images/logo.svg" alt="" class="logo">

                </div>
              </div>
              <div class="row" *ngIf="popupNo=='login'">
                <div class="col-md-12  logo">
                  <p class=" font-body">
                    Login to your Account
                  </p>

                </div>
                <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm">
                  <div class="container con">
                    <div class="row">
                      <div class="col-md-12  text-start set">
                        <label for="">Mobile Number</label>
                        <!-- <input type="number" id="" class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                      onkeypress="if(this.value.length=='10') return false"> -->
                        <ngx-intl-tel-input class="bg-transparent  " [enableAutoCountrySelect]="true"
                          [searchCountryFlag]="false" [onlyCountries]="[CountryISO.India]"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [phoneValidation]="true"
                          [separateDialCode]="separateDialCode" customPlaceholder="Mobile Number" name="phone"
                          formControlName="PhoneNumber" [inputId]="'phone_number'"
                          (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber
                          (keydown)="validateNumberInput($event)" (input)="onInput($event)" [minlength]="10"
                          [maxLength]="10" [(ngModel)]="M_Number">
                        </ngx-intl-tel-input>
                        <Span class="text-danger"
                          *ngIf="((loginFormControl['PhoneNumber'].touched || submittedLogin) && loginFormControl['PhoneNumber'].errors?.['required'])">
                          Mobile number is required.
                        </Span>
                      </div>
                      <div class="col-md-12 mt-5 text-start set group">
                        <label for="">Password</label>
                        <input type="password" name="Password" id="" class="form-control txt" formControlName="Password"
                          [type]="this.passwordType" autocomplete="off" class="form-control" (keypress)="passKeyPress($event)"
                          onfocus="this.placeholder=''" [(ngModel)]="userPass" />
                        <i [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                          (click)="ShowPassword()"></i>  
                        <Span class="text-danger"
                          *ngIf="((loginFormControl['Password'].touched || submittedLogin) && !loginFormControl['Password'].valid)">
                          Password is required.</Span>
                      </div>

                      <div class="col-md-7 mt-4 text-start rm ">
                        <!-- <div class="form-check mt-1 col6">
                      &nbsp;&nbsp;&nbsp;<input type="checkbox" value="" id="flexCheckDefault">
                      <span style="font-size: 13  px;">
                        Remember me? </span>
                    </div> -->
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                            (change)="Autologin()">
                          <label class="form-check-label" for="flexCheckDefault" style="margin-top: 7px; ">
                            Remember Me ?
                          </label>
                        </div>
                      </div>
                      <div class="col-md-5  mt-5 fr">
                        <span class="main point" style="font-size: 13px;
" (click)="Popup('forgate')">Forgot Password?</span>
                      </div>
                      <div class="col-12 mt-5">
                        <re-captcha required formControlName="recaptchaReactive"></re-captcha>
                      </div>
                      <div class="col-md-12 mt-2">
                        <button class="btn btn-primary btn-custom " id="btn" (click)="submit()"
                          [disabled]="!loginForm.valid">Sign In</button>
                      </div>
                      <div class="col-md-10 set mt-3">
                        <span class="text-center" style="font-size: 13px; ">
                          &nbsp;&nbsp;&nbsp;&nbsp;Don’t have an
                          Account?&nbsp;<span class="point main" (click)="Popup('signup')">Signup Here</span>
                        </span>

                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="row" *ngIf="popupNo=='ForgotPasswordOTP'">
                <div class="col-md-12 mt-5 text-center logo">
                  <h2 class="text-center">
                    OTP <span style="color: rgba(255, 173, 42, 1);">Verification</span>
                  </h2>

                </div>
                <div class="container con">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <p class="font-body p-txt">
                        Enter 6 digit code that sent to your mobile number. <br> +91{{MobileNumber}} <span
                          class="point main" (click)="Popup('forgate')">Click Here</span> to change your mobile
                        number.
                      </p>
                    </div>
                    <div class="col-md-12  text-start set">
                      <label for="">Enter OTP</label>
                      <input type="number" name="otp" id="" class="form-control" placeholder="OTP" #otp
                        onkeypress="if(this.value.length==6) return false" (keydown)="validateNumberInput($event)"
                        (input)="onInput($event)">
                    </div>

                    <div class="col-md-8 text-start mt-3 rm">
                      <!-- <span  style="position: relative;
                  right: 30%;" >Resend
                    OTP in&nbsp;<span style="color:green ">00:30</span></span> -->
                      <ng-container *ngIf="timerValue > 0"> Resend OTP in 00: {{ timerValue }} Secs</ng-container>
                    </div>
                    <div class="col-md-4 mt-3 fr">
                      <span class="point main" style="font-size: 13px;" *ngIf="timerValue < 1"
                        (click)="Popup('ForgotPasswordOTP',forgotMobile)">Resend OTP </span>
                    </div>
                    <div class="col-md-12 mt-6 ">
                      <button class="btn btn-primary btn-sm" id="btn" (click)="Popup('resetPassword',MobileNumber)"
                        [disabled]="otp.value == '' || otp.value.length != 6">Verify</button>
                    </div>
                    <div class="col-md-12  mt-3 text-center">
                      <span class="text-center spn">Back
                        to Login?&nbsp;&nbsp;<span class="main point spn" (click)="Popup('login')">Click
                          Here</span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="popupNo=='forgate'">
                <div class="col-md-12 mt-5 text-center logo">
                  <h2 class="text-center">
                    Forgot <span style="color: rgba(255, 173, 42, 1);">Password?</span>
                  </h2>

                </div>
                <div class="container con mt-5">
                  <div class="row">

                    <div class="col-md-12 text-start">
                      <label for="">Mobile Number</label>
                      <input type="number" name="mobile" id="" class="form-control" placeholder="Mobile number"
                        onkeypress="if(this.value.length==10) return false" #forgotMobile
                        (keydown)="validateNumberInput($event)" (input)="onInput($event)" [(ngModel)]="MobileNumber">
                    </div>
                    <div class="col-md-12 mt-8 ">
                      <button class="btn btn-primary btn-sm" id="btn"
                        (click)="Popup('ForgotPasswordOTP',forgotMobile.value)"
                        [disabled]="forgotMobile.value == '' || forgotMobile.value.length != 10">Send OTP</button>
                    </div>
                    <div class="col-md-12  mt-3 text-center">
                      <span class="text-center spn">Back
                        to Login?&nbsp;&nbsp;<span class="main point spn" (click)="Popup('login')">Click
                          Here</span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="popupNo=='resetPassword'">
                <div class="col-md-12 mt-5 logo">
                  <h2 class="text-center" style="     ">
                    Reset <span style="color: rgba(255, 173, 42, 1);">New Password </span>
                  </h2>

                </div>
                <div class="container con">
                  <div class="row">
                    <div class="col-md-12 text-start group">
                      <label for="">Password</label>
                      <input [type]="this.passwordType1" name="password" id="" class="form-control" #password [(ngModel)] = "password1"
                        onkeypress="if(this.value.length=='16') return false" (keypress)="passKeyPress($event)" (keyup)="passKeyUp($event)">
                      <i [class]="passwordShow1 === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                        (click)="ShowPassword1()"></i>
                        <div class="d-flex">
                          <div class="power-container my-2"> 
                            <div id="power-point" [style.width.%]="passBarWidth" [style.background-color]="passBarBGColor"></div> 
                          </div>
                          <span class="ml-2" [style.color]="passBarBGColor">{{passBarText}}</span>
                        </div>
                        <div class="text-grey4">Password must have : At least 8 characters, Uppercase and lowercase character, special character, number</div>
                    </div>
                    <div class="col-md-12 mt-5 text-start set">
                      <label for="">Confirm Password</label>
                      <input [type]="this.passwordType2" name="Password" id="" class="form-control" #confirmPassword [(ngModel)] = "confirmPassword1"
                        onkeypress="if(this.value.length=='16') return false" (keypress)="passKeyPress($event)">
                      <i [class]="passwordShow2 === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                        (click)="ShowPassword2()"></i>                        
                      <span class="text-danger" *ngIf="confirmPassword.value != '' && password.value != confirmPassword.value">
                        Password and confirm password must be same
                      </span>
                    </div>
                    <div class="col-md-12  mt-5">
                      <button class="btn btn-primary btn-custom" id="btn" (click)="Popup('succes')" [disabled]="password.value != confirmPassword.value">Reset
                        Password</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="popupNo=='succes'">
                <div class="col-md-12 mt-5 text-center">
                  <h2 class="text-center">
                    Reset <span style="color: rgba(255, 173, 42, 1);">Password</span>
                  </h2>

                </div>
                <div class="col-md-12 mt-4 text-center ">
                  <img src="../../assets/images/done.png" class="text-center" walt="" width="30%" height="65%">
                </div>
                <div class="col-md-12  text-center">
                  <h4 class="text-center">
                    Your Password Has Been Changed Successfully
                  </h4>
                </div>
                <div class="col-md-12 mt-3 ">
                  <button class="btn btn-primary btn-sm set" id="btn" (click)="Popup('login')">Login Again</button>
                </div>
              </div>
              <div class="row" *ngIf="popupNo=='signup'">
                <form [formGroup]="signUp">
                <div class="col-md-12  text-center">
                  <h2 class="text-center">
                    Sign<span style="color: rgba(255, 173, 42, 1);"> Up </span>
                  </h2>

                </div>
                <div class="container con">
                  <div class="row mt-6">
                    <div class="col-md-12 text-start">
                      <label for="">Mobile Number </label>
                      <input type="number" name="mobile" id="" class="form-control" placeholder="Mobile number" #mobile
                        onkeypress="if(this.value.length==10) return false" (keydown)="validateNumberInput($event)"
                        (input)="onInput($event)" formControlName="signup" maxlength=10>
                    </div>
                    <Span class="text-danger text-left" *ngIf="(SignUpControl['signup'].touched || sign )&& !SignUpControl['signup'].valid">
                      Mobile number is required.
                    </Span>
                    <Span class="text-danger text-left" *ngIf="(SignUpControl['signup'].errors?.['maxlength'])">    Please provide valid mobile number.
                    </Span>
                    <div class="col-md-12 mt-6  logo">
                      <button class="btn btn-primary btn-sm" id="btn"
                        (click)="signInOtpClicked = true; Popup('OTP',mobile.value)"
                        [disabled]="!(mobile.value != '' && mobile.value.length == 10)">Send
                        OTP</button>
                    </div>
                    <div class="col-md-12  text-center   " style="position: relative;
                    bottom: 14px;">
                      <span class="text-center spn">Already have an Account?&nbsp;<span class="main point spn"
                          (click)="Popup('login')">SignIn Here</span></span>
                    </div>
                  </div>
                </div>
              </form> </div>
              <div class="row" *ngIf="popupNo=='OTP'">
                <div class="col-md-12 mt-5 text-center logo">
                  <h2 class="text-center">
                    OTP <span style="color: rgba(255, 173, 42, 1);">Verification</span>
                  </h2>

                </div>
                <div class="container con" style="margin-top: -20px;">
                  <div class="row">
                    <div class="col-md-12   text-center">
                      <p class=" font-body p-txt">
                        Enter 6 digit code that sent to your mobile number. <br> +91{{mobile}} <span class="point main"
                          (click)="Popup('signup')">Click Here</span> to change your mobile
                        number.
                      </p>
                    </div>
                    <div class="col-md-12 text-start">
                      <label for="">Enter OTP</label>
                      <input type="number" name="otp" id="" class="form-control" placeholder="OTP" #otp
                        onkeypress="if(this.value.length==6) return false" (keydown)="validateNumberInput($event)"
                        (input)="onInput($event)">
                    </div>

                    <div class="col-md-8 text-start mt-3 rm">
                      <!-- <span style="position: relative;
                  right: 30%;">Resend
                    OTP in&nbsp;<span style="color:green ">00:30</span></span> -->
                      <ng-container *ngIf="timerValue > 0"> Resend OTP in 00: {{ timerValue }}</ng-container>
                    </div>
                    <div class="col-md-4 mt-3 fr">
                      <span class="point main" style="font-size: 13px;" *ngIf="timerValue ==0"
                        (click)="Popup('OTP',mobile)">Resend OTP </span>
                    </div>
                    <div class="col-md-12 mt-6 ">
                      <button class="btn btn-primary btn-sm" id="btn"
                        [disabled]="otp.value == '' || otp.value.length != 6"
                        (click)="Popup('select',otp.value)">Submit</button>
                    </div>
                    <div class="col-md-12  mt-3 text-center">
                      <span class="text-center spn">Back
                        to Login?&nbsp;&nbsp;<span class="main point spn" (click)="Popup('login')">Click
                          Here</span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="popupNo=='select'">
                <div class="col-md-12 mt-5 text-center">
                  <h2 class="text-center">
                    Select <span style="color: rgba(255, 173, 42, 1);">Business Type </span>
                  </h2>
                  <span>Select your business type as per your need</span>

                </div>
                <div class="container con ">
                  <div class="row">
                    <div class="  col-md-4 mt-2">
                      <div class="card p-q cd " [ngClass]="{'crd': active=='agent'}">
                        <div class="row" (click)="activeimg('agent')">
                          <div class="col-md-12 text-right"
                            data-title="Become an agent to help customer find the best coverage and earn commissions while assisting customers with their insurance needs.">
                            <img src="../../../assets/svgIcon/Info1.svg" class="point" alt="">
                          </div>
                          <div class="col-md-12 text-center">
                            <img src="../../../assets/svgIcon/ag.svg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="  col-md-4 mt-2">
                      <div class="card p-q cd " [ngClass]="{'crd': active=='hospital'}">
                        <div class="row" (click)="activeimg('hospital')">
                          <div class="col-md-12 text-right"
                            data-title="Register your hospital business to offer insurance benefits to patients, ensuring comprehensive coverage and seamless access to healthcare services.">
                            <img src="../../../assets/svgIcon/Info1.svg" class="point" alt="">
                          </div>
                          <div class="col-md-12 text-center">
                            <img src="../../../assets/svgIcon/hp.svg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="  col-md-4 mt-2">
                      <div class="card p-q cd " [ngClass]="{'crd': active=='doctor'}">
                        <div class="row" (click)="activeimg('doctor')">
                          <div class="col-md-12 text-right"
                            data-title="Register your doctor's business to offer patients valuable insurance benefits, ensuring their healthcare needs are covered conveniently.">
                            <img src="../../../assets/svgIcon/Info1.svg" class="point" alt="">
                          </div>
                          <div class="col-md-12 text-center">
                            <img src="../../../assets/svgIcon/dt.svg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="  col-md-4 mt-2">
                      <div class="card p-q cd " [ngClass]="{'crd': active=='medical'}">
                        <div class="row" (click)="activeimg('medical')">
                          <div class="col-md-12 text-right"
                            data-title="Register your medical store business to offer valuable insurance benefits to customers and attract more customers by providing essential coverage options">
                            <img src="../../../assets/svgIcon/Info1.svg" class="point" alt="">
                          </div>
                          <div class="col-md-12 text-center">
                            <img src="../../../assets/svgIcon/md.svg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="  col-md-4 mt-2">
                      <div class="card p-q cd " [ngClass]="{'crd': active=='Garage'}">
                        <div class="row" (click)="activeimg('Garage')">
                          <div class="col-md-12 text-right"
                            data-title="Get peace of mind for your vehicle with our garage services, offering comprehensive insurance benefits to customers through our partnership with top insurance providers.">
                            <img src="../../../assets/svgIcon/Info1.svg" class="point" alt="">
                          </div>
                          <div class="col-md-12 text-center">
                            <img src="../../../assets/svgIcon/gg.svg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="  col-md-4 mt-2">
                      <div class="card p-q cd " [ngClass]="{'crd': active=='Influencer'}">
                        <div class="row" (click)="activeimg('Influencer')">
                          <div class="col-md-12 text-right"
                            data-title="Register your influencer business to offer insurance benefits to your followers. Earn commissions by connecting them with the best insurance plans.">
                            <img src="../../../assets/svgIcon/Info1.svg" class="point" alt="">
                          </div>
                          <div class="col-md-12 text-center">
                            <img src="../../../assets/svgIcon/in.svg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="  col-md-12 mt-10 ">
                      <button class="btn btn-primary btn-md bsn" id="btn" *ngIf="active=='agent'"
                        (click)="next('agent')">Continue</button>
                      <button class="btn btn-primary btn-md bsn" id="btn" *ngIf="active=='hospital'"
                        (click)="next('hospital')">Continue</button>
                      <button class="btn btn-primary btn-md bsn" id="btn" *ngIf="active=='medical'"
                        (click)="next('medical')">Continue</button>
                      <button class="btn btn-primary btn-md bsn" id="btn" *ngIf="active=='Garage'"
                        (click)="next('Garage')">Continue</button>
                      <button class="btn btn-primary btn-md bsn" id="btn" *ngIf="active=='doctor'"
                        (click)="next('doctor')">Continue</button>
                      <button class="btn btn-primary btn-md bsn" id="btn" *ngIf="active=='Influencer'"
                        (click)="next('Influencer')">Continue</button>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-6 mt-10">
              <div class=" rounded-5 ">
                <app-carousel></app-carousel>
              </div>
            </div> -->
              </div>
            </div>
          </div>
          <div class="row p-2" *ngIf="component=='agent'">

            <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="signupForm">
              <div class="col-md-12 text-center">
                <img src="../../assets/images/logo.svg" alt="" class="logo">
              </div>
              <div class="col-md-12  text-center">
                <h2 class="text-center">
                  Basic <span style="color: rgba(255, 173, 42, 1);">Details </span>
                </h2>

              </div>
              <div class="col-md-12 mt-2 text-start">
                <div class="row">
                  <div class="col-md-6">
                    <label for="">First name</label>
                    <input type="text" class="form-control" name="name" placeholder="First Name"
                      formControlName="firstName" (keypress)="alphabetOnly($event)" (copy)="$event.preventDefault()"
                      (paste)="$event.preventDefault()">
                    <Span class="text-danger"
                      *ngIf="((signupFormControl['firstName'].touched || submittedSignUp) && signupFormControl['firstName'].errors?.['required'])">
                      First name is required.
                    </Span>
                  </div>
                  <div class="col-md-6">
                    <label for="">Last name</label>
                    <input type="text" class="form-control" name="name" placeholder="Last Name"
                      formControlName="lastName" (keypress)="alphabetOnly($event)" (copy)="$event.preventDefault()"
                      (paste)="$event.preventDefault()">
                    <Span class="text-danger"
                      *ngIf="((signupFormControl['lastName'].touched || submittedSignUp) && signupFormControl['lastName'].errors?.['required'])">
                      Last name is required.
                    </Span>
                  </div>
                  <div class="col-md-8 mt-4">
                    <div class="row">
                      <div class="col-md-12"><label for="">Gender</label></div>
                      <div class="col-md-4">
                        <input class="" type="radio" value="Male" name="flexRadioDefault" id="flexRadioDefault1"
                          formControlName="gender">
                        <span class="lb-sty sp">
                          &nbsp;Male
                        </span>
                      </div>
                      <div class="col-md-4" style="position: relative;right: 5px;">
                        <input class="" type="radio" value="Female" name="flexRadioDefault" id="flexRadioDefault2"
                          formControlName="gender">
                        <span class="lb-sty sp">
                          &nbsp;Female
                        </span>
                      </div>
                      <div class="col-md-4">
                        <input class="" type="radio" value="Other" name="flexRadioDefault" id="flexRadioDefault3"
                          formControlName="gender">
                        <span class="lb-sty sp">
                          &nbsp;Other
                        </span>
                      </div>
                    </div>
                    <Span class="text-danger"
                      *ngIf="((signupFormControl['gender'].touched || submittedSignUp) && signupFormControl['gender'].errors?.['required'])">
                      Gender is required.
                    </Span>
                  </div>
                  <div class="col-md-12 mt-3">
                    <label for="">Email Address</label>
                    <input type="email" class="form-control" name="userEmail" placeholder="abc@gmail.com"
                      autocomplete="off" formControlName="userEmail"  (keypress)="validateEmail($event)" >
                    <Span class="text-danger"
                      *ngIf="((signupFormControl['userEmail'].touched || submittedSignUp) && signupFormControl['userEmail'].errors?.['required'])">
                      Email is required.
                    </Span>
                    <Span class="text-danger"
                      *ngIf="((signupFormControl['userEmail'].touched || submittedSignUp) && signupFormControl['userEmail'].errors?.['pattern'])">
                      Please provide valid email address.
                    </Span>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">Date Of Birth</label>
                    <input type="date" class="form-control" name="dob" placeholder="Date Of Birth"
                      formControlName="dob"[max]="maxDOB" onkeydown="return false">
                    <Span class="text-danger"
                      *ngIf="((signupFormControl['dob'].touched || submittedSignUp) && signupFormControl['dob'].errors?.['required'])">
                      Date of birth is required.
                    </Span>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">Spoken Language</label>
                    <select class="stl" aria-label="Default select example" formControlName="language">
                      <option [value]="''"> Select Language</option>
                      <option value="1">English</option>
                      <option value="2">Hindi</option>
                      <option value="3">Telugu</option>
                      <option value="4">Gujarati</option>
                      <option value="5">Marathi</option>
                      <option value="6">Kannada</option>
                      <option value="7">Malyalam</option>
                      <option value="8">Tamil</option>
                      <option value="9">Bengali</option>
                      <option value="10">Punjabi</option>
                      <option value="11">Odiya</option>
                    </select>
                    <Span class="text-danger"
                      *ngIf="((signupFormControl['language'].touched || submittedSignUp) && signupFormControl['language'].errors?.['required'])">
                      Spoken language is required.
                    </Span>
                  </div>
                  <div class="col-md-6 mt-2  text-start group">
                    <label for=""> Password</label>
                    <input type="password" class="form-control" name="password" placeholder="Password "
                      formControlName="passcode" onkeypress="if(this.value.length==16) return false" (keyup)="passKeyUp($event)" (keypress)="passKeyPress($event)"
                      [type]="this.passwordType" autocomplete="off" onfocus="this.placeholder=''">
                    <i [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                      (click)="ShowPassword()"></i>
                    <div class="d-flex">
                      <div class="power-container my-2"> 
                        <div id="power-point" [style.width.%]="passBarWidth" [style.background-color]="passBarBGColor"></div> 
                      </div>
                      <span class="ml-2" [style.color]="passBarBGColor">{{passBarText}}</span>
                    </div>
                    <div class="text-grey4">Password must have : At least 8 characters, Uppercase and lowercase character, special character, number</div>
                  </div>
                  <div class="col-md-6 mt-2  text-start">
                    <label for="">Confirm Password</label>
                    <input type="password" class="form-control" name="Confirm password" placeholder="Confirm password "
                      formControlName="ConfirmPassword" (keypress)="passKeyPress($event)">
                      <span class="text-danger" *ngIf="signupFormControl['ConfirmPassword'].touched && this.signupForm.get('passcode')?.value != this.signupForm.get('ConfirmPassword')?.value">
                        Password and confirm password must be same
                      </span>
                  </div>                  
                  <div class="col-12 mt-5">
                    <re-captcha required formControlName="recaptchaReactive"></re-captcha>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-2 text-center">
                <button class="btn btn-primary btn-sm" style="width: 70%;" [disabled]="!signupForm.valid || passBarWidth != '100' || this.signupForm.get('passcode')?.value != this.signupForm.get('ConfirmPassword')?.value"
                  (click)="signUpUser()">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="row p-2 text-start" *ngIf="component=='hospital'">
            <form [formGroup]="hospitalSave" class="row">
              <div class="col-md-12 text-center">
                <img src="../../assets/images/logo.svg" alt="" class="logo">
              </div>
              <div class="col-md-12  text-center">
                <h2 class="text-center">
                  Hospital <span style="color: rgba(255, 173, 42, 1);">Details </span>
                </h2>

              </div>
              <div class="col-md-6 mt-5">
                <label for="">Hospital Name</label>
                <input type="text" name="" id="" class="form-control" placeholder="Hospital"
                  formControlName="HospitalName" (keypress)="alphaNumericOnly($event)">
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-6 mt-5">
                <label for="">Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Contact Number"
                  formControlName="HContact">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Business Contact Number "
                  formControlName="HBusiness_Contact">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Email ID  "
                  formControlName="HEmail_ID">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Business Email ID  "
                  formControlName="HBusinessEmail">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Password" (keypress)="passKeyPress($event)"
                  formControlName="HPassword">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Confirm Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Confirm Password" (keypress)="passKeyPress($event)"
                  formControlName="HConfirmPass">
              </div>
              <div class="col-12 mt-5">
                <re-captcha required formControlName="recaptchaReactive"></re-captcha>
              </div>
              <div class="col-md-12 mt-2 text-center">
                <button class="btn btn-primary btn-sm" style="width: 70%;" [disabled]="hospitalSave.invalid">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="row p-2 text-start" *ngIf="component=='medical'">
            <form [formGroup]="medicalSave" class="row">
              <div class="col-md-12 text-center">
                <img src="../../assets/images/logo.svg" alt="" class="logo">
              </div>
              <div class="col-md-12  text-center">
                <h2 class="text-center">
                  Medical <span style="color: rgba(255, 173, 42, 1);">Details </span>
                </h2>

              </div>
              <div class="col-md-6 mt-5">
                <label for="">Medical Name</label>
                <input type="text" name="" id="" class="form-control" placeholder="Medical"
                  formControlName="MedicalName" (keypress)="alphaNumericOnly($event)">
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-6 mt-5">
                <label for="">Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Contact Number"
                  formControlName="MContact">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Business Contact Number "
                  formControlName="MBusiness_Contact">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Email ID  "
                  formControlName="MEmail">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Business Email ID  "
                  formControlName="MBusiness_Email">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Password" (keypress)="passKeyPress($event)"
                  formControlName="MPassword">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Confirm Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Confirm Password" (keypress)="passKeyPress($event)"
                  formControlName="MConfirmPass">
              </div>
              <div class="col-12 mt-5">
                <re-captcha required formControlName="recaptchaReactive"></re-captcha>
              </div>
              <div class="col-md-12 mt-2 text-center">
                <button class="btn btn-primary btn-sm" style="width: 70%;" [disabled]="medicalSave.invalid">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="row p-2 text-start" *ngIf="component=='Garage'">
            <form [formGroup]="GarageSave" class="row">
              <div class="col-md-12 text-center">
                <img src="../../assets/images/logo.svg" alt="" class="logo">
              </div>
              <div class="col-md-12  text-center">
                <h2 class="text-center">
                  Garage <span style="color: rgba(255, 173, 42, 1);">Details </span>
                </h2>

              </div>
              <div class="col-md-6 mt-5">
                <label for="">Garage Name</label>
                <input type="text" name="" id="" class="form-control" placeholder="Garage" formControlName="GarageName"
                  (keypress)="alphaNumericOnly($event)">
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-6 mt-5">
                <label for="">Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Contact Number"
                  formControlName="GContact">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Business Contact Number "
                  formControlName="GBusiness_Contact">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Email ID  "
                  formControlName="GEmail">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Business Email ID  "
                  formControlName="GBusinessEmail">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Password" (keypress)="passKeyPress($event)"
                  formControlName="GPassword">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Confirm Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Confirm Password" (keypress)="passKeyPress($event)"
                  formControlName="GConfirmPass">
              </div>
              <div class="col-12 mt-5">
                <re-captcha required formControlName="recaptchaReactive"></re-captcha>
              </div>
              <div class="col-md-12 mt-2 text-center">
                <button class="btn btn-primary btn-sm" style="width: 70%;" [disabled]="GarageSave.invalid">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="row p-2 text-start" *ngIf="component=='doctor'">
            <form [formGroup]="DoctorSave" class="row">
              <div class="col-md-12 text-center">
                <img src="../../assets/images/logo.svg" alt="" class="logo">
              </div>
              <div class="col-md-12  text-center">
                <h2 class="text-center">
                  Doctor <span style="color: rgba(255, 173, 42, 1);">Details </span>
                </h2>

              </div>
              <div class="col-md-6 mt-5">
                <label for="">Doctor Name</label>
                <input type="text" name="" id="" class="form-control" placeholder="Doctor Name"
                  formControlName="Doctor_Name" (keypress)="alphabetOnly($event)">
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-6 mt-5">
                <label for="">Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Contact Number"
                  formControlName="DContact">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Business Contact Number "
                  formControlName="DBusinessContact">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Email ID  "
                  formControlName="DEmail">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Business Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Business Email ID  "
                  formControlName="DBusinessEmail">
              </div>

              <div class="col-md-6 mt-5">
                <label for="">Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Password"(keypress)="passKeyPress($event)"
                  formControlName="DPassword">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Confirm Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Confirm Password" (keypress)="passKeyPress($event)"
                  formControlName="DConfirmPass">
              </div>
              <div class="col-12 mt-5">
                <re-captcha required formControlName="recaptchaReactive"></re-captcha>
              </div>
              <div class="col-md-12 mt-2 text-center">
                <button class="btn btn-primary btn-sm" style="width: 70%;" [disabled]="DoctorSave.invalid">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div class="row p-2 text-start" *ngIf="component=='Influencer'">
            <form [formGroup]="InfluencerSave" class="row">
              <div class="col-md-12 text-center">
                <img src="../../assets/images/logo.svg" alt="" class="logo">
              </div>
              <div class="col-md-12  text-center">
                <h2 class="text-center">
                  Influencer <span style="color: rgba(255, 173, 42, 1);">Details </span>
                </h2>

              </div>
              <div class="col-md-6 mt-5">
                <label for="">Influencer Name</label>
                <input type="text" name="" id="" class="form-control" placeholder="Influencer"
                  formControlName="InfluencerName" (keypress)="alphabetOnly($event)">
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-6 mt-5">
                <label for="">Contact Number </label>
                <input type="number" name="" id="" class="form-control" placeholder="Contact Number"
                  formControlName="InfluencerContact">
              </div>


              <div class="col-md-6 mt-5">
                <label for="">Email ID </label>
                <input type="email" name="" id="" class="form-control" placeholder="Email ID  "
                  formControlName="InfluencerEmail">

              </div>
              <div class="col-md-6 mt-5">
                <label for="">Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Password" (keypress)="passKeyPress($event)"
                  formControlName="InfluencerPassword">
              </div>
              <div class="col-md-6 mt-5">
                <label for="">Confirm Password </label>
                <input type="password" name="" id="" class="form-control" placeholder="Confirm Password" (keypress)="passKeyPress($event)"
                  formControlName="InfluencerConfirmPass">
              </div>
              <div class="col-12 mt-5">
                <re-captcha required formControlName="recaptchaReactive"></re-captcha>
              </div>
              <div class="col-md-12 mt-2 text-center">
                <button class="btn btn-primary btn-sm" style="width: 70%;" [disabled]="InfluencerSave.invalid">
                  Submit
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="overlay" *ngIf="success">
    <div class="success col-md-3 col-11">
      <div class="modal-header bg-transparent tooltip_">
        <!-- <h4 class="modal-title">Sign In</h4> -->
        <button type="button" class="close pull-right" aria-label="Close">

        </button>
      </div>
      <div class="model-body">
        <div class="row ">
          <div class="col-md-12 mt-2 text-center">
            <img src="../../../assets/svgIcon/Layer 2.svg" alt="">
          </div>
          <div class="col-md-12 mt-4 text-center">
            <h2>Registration Completed <br> Successfully</h2>
            <span>Your registration on agent portal is completed successfully. You can now log into your account.</span>
          </div>
          <div class="col-md-12 mt-10 text-center">
            <button class="btn btn-sm  bt-1" (click)="reload()">
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-5">
  <!-- <img src="../../assets/svgIcon/agent/footerimg.svg" alt=""> -->
  <div class="agent-footer text-center">
    <div class="footer-content">
      <h6>
        © Copyright {{currentYear}} Click4bima. All Rights Reserved
      </h6>
    </div>
  </div>
</div>