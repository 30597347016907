<div class="bg-white box p-5 mb-2 f-ps">
    <div class="row">
        <div class="col-7 d-flex align-items-center">
            <p class="font-body mb-0">© Copyright {{currentYear}} Click4bima. All Rights Reserved</p>
        </div>
        <div class="col-5 text-end d-flex align-items-center justify-content-end">
            <p class="font-body mb-0 mr-2">Handcrafted & Proudly Made In
            </p>
            <img src="../../assets/images/india-flag.svg">
        </div>
    </div>
</div>
