import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async BusinessInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}business/BusinessInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async UserAccountInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}business/UserAccountInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }


  async GetBusinessDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}business/BusinessDetails`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async AcquireBusinessUsers() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}business/AcquireBusinessUsers`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }


}
