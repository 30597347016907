import { Component } from '@angular/core';

@Component({
  selector: 'app-group-health-insurance',
  templateUrl: './group-health-insurance.component.html',
  styleUrls: ['./group-health-insurance.component.css']
})
export class GroupHealthInsuranceComponent {

}
