import { PincodeMaster } from '../Models/signUp.component.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { UserService } from '../Services/user.service';
import { AppConfig } from '../Shared/AppConfig';
import swal from 'sweetalert2';
import { LoginService } from './../Services/login.service';
import { Router } from '@angular/router';
import { ProfileService } from '../Services/profile.service';
import { FamilyMembers, PropertyDetails, RelationShips, Vehicles } from '../Models/Profile.model';
import { LookUpService } from './../Services/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from '../Services/general.service';
import * as moment from 'moment';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']
})
export class PersonalDetailsComponent {
  pincodeMaster = new PincodeMaster();
  userForm: FormGroup;
  mobile: string = '';
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  isAgent: boolean = this.generalService.getIsAgent() === 'true' ? true : false;
today=new Date();
max=moment(this.today).format('YYYY-MM-DD');
min = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
  mobileNumberIsDisable:boolean=true;
  constructor(private fb: FormBuilder, private userservice: UserService, private lookUpService: LookUpService,
    private loginService: LoginService, private route: Router, private profileService: ProfileService,
    private spinner: NgxSpinnerService, private generalService : GeneralService) {

    this.getUserDetails();
  }
  ngOnInit() {
    this.loadUserForm();
  }

  loadUserForm() {
    this.userForm = this.fb.group({
      "uniqueUserId": [''],
      "userName": [''],
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],
      "phoneNumber": ['', [Validators.required]],
      "countryCode": [''],
      "userEmail": ['', [Validators.pattern(/\S+@\S+\.\S+/)]],
      "userHash": [''],
      "dob": ['', [Validators.required]],
      "gender": ['', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "pincode": ['', [Validators.required]],
      "pincodeId": [''],
      "referralCode": [{ value: '', disabled: true }],
      "maritalStatus": [''],
      "annualIncome": [''],
    });
  }

  onCountryChangeNew(e: any) {
    this.userForm.get('phoneNumber')?.setValue("");
  }

  async save() {

    if (this.userForm.get('phoneNumber')?.value != null) {
      let phone = this.userForm.get('phoneNumber')?.value;
      if (phone.dialCode) {
        this.userForm.get('countryCode')?.setValue(phone.dialCode);
        this.userForm.get('phoneNumber')?.setValue(phone.number.replace(phone.dialCode, '').replace(/\s/g, ""));
      }

    }
    this.userForm.get('pincodeId')?.setValue(this.pincodeMaster?.id?.toString());
    this.userForm.get('annualIncome')?.setValue(this.userForm.get('annualIncome')?.value?.toString());
    console.log(this.userForm.value);
    debugger;
    const response = await this.userservice.ManipulateUserInfo(this.userForm.value);
    if (response?.isSuccess) {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Details updated',
        showConfirmButton: false,
        timer: 3000,
      });
    }

  }

  async getUserDetails() {
    this.spinner.show();
    let request = { uniqueUserId: this.generalService.getUniqueuserid()}
    const response = await this.userservice.AcquireUserInfo(request);
    if (response?.isSuccess) {
      this.userForm.patchValue(response.result);
      this.mobile = response.result.phoneNumber;
      this.userForm.get('dob')?.setValue(response.result.dob);
      this.userForm.get("phoneNumber")?.setValue(this.mobile);
      setTimeout(() => {
        const mobileInput = document.getElementById('phoneNumber') as HTMLInputElement | null;
        if (mobileInput) {
          mobileInput.value = this.mobile.replace(response?.result?.countryCode ?? '', '');
        }
      }, 1000);

    }
    this.spinner.hide();
  }

  async pincodeUserChangeEvent(pincode: any, isLoad?: boolean) {
    this.pincodeMaster = new PincodeMaster();
    var pin = "";
    if (isLoad)
      pin = pincode;
    else
      pin = pincode.value;
    const res = await this.lookUpService.pincodeMasterDetails(pin);
    if (res != null && res.result) {
      this.pincodeMaster = res.result[0];
      this.userForm.get('pincodeId')?.setValue(this.pincodeMaster?.id?.toString());
    }
  }
  public isInputDisabled(): boolean {
    
    return true;   }
}
