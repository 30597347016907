<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Privacy Policy</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">We, as brokers along with insurance industry strongly respect your right to privacy and safeguard your personal information. As our policy is very transparent, we further give assurance as to why and how we collect & use your personal information which will be handled appropriately.</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Personal information</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Depending on the purpose(s) we may ask for the following personal information: Your name, address, telephone and fax numbers, e-mail address, birth date and marital status. Claims history, Credit and past payment records, details about your property such as the value of your home and its contents, Medical information such as any condition that may affect the operation of a vehicle.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Employment information</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        We may obtain this information from you, Insurance companies, insurance brokers or agents, Credit organizations, Motor vehicle and driver licensing authorities, financial institutions & Medical professionals
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why do we collect personal information?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        When you first become a client or whenever you acquire or renew a product from us, we need to collect some of your personal information -To enable us to acquire or renew an insurance policy for you; Assist you and assess your ongoing needs for insurance; assess your need for other products, such as financial products, and to offer them to you; your information is accurate andUp-to-date; and protect us both against error or fraud.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Who do we disclose your personal information?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        For many of the purposes identified above, we will need to share your personal information with others in order to serve your needs. These other parties commonly include:Insurance companies, other insurance brokers or agents, Credit organizations Professionals working with us such as adjusters or lawyersfinancial institutions. For example, the organization that carries your mortgage.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How we protect your personal information?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        In order to protect your personal information and right to privacy, we will:Not collect, use or disclose your personal information for any purpose other than those that we identify to you.Keep your personal information only, as long as we need it, to fulfill the stated purpose or as required by law.Your personal information is as accurate, complete and up-to-date as possible.Safeguard your personal information to the best of our ability.Obtain the appropriate consent from you for the collection, use or disclosure of your personal information.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Consent</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        For using this site, you signify your consent to our privacy policy. If you don’t agree with, please do not use the site. We reserve the right, at our discretion, to update, change, modify, add, or remove this policy from time to time, as appropriate. Should we make changes, we will publish these on the site timely. Please do not hesitate to contact our Privacy Officer should you have any questions.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Our Privacy Officer may be contacted as follows:</h4>
                </div>
                <div class="mt-3">
                    <p  class="font-body">SRG Insurance Brokers (P) Ltd.</p>
                <p  class="font-body">2 & 5, Vinayak Complex’B’, Durga Nursery Road, UDAIPUR,Rajasthan</p>
                <p  class="font-body">Ph :0294--6535633</p>
                <p  class="font-body">Email.info@srggroup.net</p>
                <p  class="font-body">Upon request, an individual will be informed of the existence, use, and disclosure of his or her personal information which is under our control, and may be given access to, and challenge the accuracy and completeness of that information.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
