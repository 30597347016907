<nav class="navbar navbar-expand-sm" [class.sticky]="isSticky">
  <div class="container justify-content-center1 p-0">
    <div class="row w-100 align-items-md-center">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
        <div class="logo">
          <a class="navbar-brand pl-md-3 pl-0" href=""><img src="../../assets/images/logo.svg" class="logo" alt=""></a>
        </div>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-6">
        <!-- data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" -->
        <!-- <app-header-menu></app-header-menu> -->
        <div class="header_menu mt-4 mt-md-0">
          <li class="lang-dropdown dropdown mob-lang ">
            <a class="nav-link show-nav-mob" href="#" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"><img src="../../assets/svgIcon/language.svg" alt=""></a>
            <ul class="dropdown-menu cursor-point lang_dropdown drop-mrg" (click)="languageDropdown($event)">
              <li value="hindi">हिंदी</li>
              <li value="english">English</li>
              <li value="gujrati">ગુજરાતી</li>
              <li value="punjabi">ਪੰਜਾਬੀ</li>
              <li value="kannada">ಕನ್ನಡ</li>
              <li value="marathi">मराठी</li>
              <li value="telugu">తెలుగు</li>
              <li value="malayalam">മലയാളം</li>
              <li value="tamil">தமிழ்</li>
              <li value="bengali">বাংলা</li>
              <li value="odiya">ଓଡ଼ିଆ</li>
            </ul>
          </li>
          <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse p-0" id="collapsibleNavbar" [ngClass]="{ 'show': navbarOpen }">
            <div class="navbar_menu_div show-nav-desk">
              <ul class="navbar-nav align-items-md-center text-start pl-5 pl-md-0">
                
                <!-- <li class="nav-item dropdown">
                  <a class="nav-link " href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false" (click)="menuItems('products')">{{ langList.product }}</a>
                  <ul class="dropdown-menu mobile_view">                       
        
                    <li><a class="dropdown-item" href="#bike">{{langList.two_wheeler}}</a></li>
          
                    <li><a class="dropdown-item" href="#car">{{langList.four_wheeler}}</a></li>
          
                    <li><a class="dropdown-item" href="#health">{{langList.health}}</a></li>
          
                    <li><a class="dropdown-item" href="#home">{{langList.commercial_vehicle}}</a></li>
          
                    <li><a class="dropdown-item" href="#">Life Insurance</a></li>
          
                    <li><a class="dropdown-item" href="#">Term Insurance</a></li>
          
                    <li><a class="dropdown-item" href="#">Investment Plans</a></li>
          
                  </ul>
                </li> -->
                <!-- <li class="nav-item dropdown">
                  <a class="nav-link p-md-0 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">Renew policy</a>
                  <ul class="dropdown-menu">
        
                    <li class="sub-dropdown">
                      <a href="#">Motor Insurance</a>
                      <ul class="sub-dropdown-menu">
                          <li><a href="/car-insurance">Car Insurance</a></li>
                          <li><a href="/two-wheeler-insurance">Two Wheeler Insurance</a></li>
                          <li><a href="/taxi-insurance">Taxi Insurance</a></li>
                          <li><a href="/third-party-car-insurance">Commercial Vehicle Insurance</a></li>
                          <li><a href="/third-party-car-insurance">Third Party Car Insurance</a></li>
                          <li><a href="/third-party-two-wheeler-insurance">Third Party 2 W Insurance</a></li>
                      </ul>
                    </li>
                    <li class="sub-dropdown">
                      <a href="#">Health Insurance</a>
                      <ul class="sub-dropdown-menu">
                          <li><a href="/family-health-insurance">Family Health Policy</a></li>
                          <li><a href="/individual-health-insurance">Individual Health Policy</a></li>
                          <li><a href="/group-health-insurance">Group Health Policy</a></li>
                          <li><a href="/senior-citizen-health-insurance">Senior Citizen Health Policy</a></li>
                          <li><a href="/critical-illness-insurance">Critical Illness Policy</a></li>
                          <li><a href="/arogya-sanjivni-insurance">Arogya Sanjivani Policy</a></li>
                      </ul>
                    </li>
                    <li class="sub-dropdown">
                      <a href="#">Life Insurance</a>
                      <ul class="sub-dropdown-menu">
                          <li><a href="/term-insurance">Term Insurance</a></li>
                          <li><a href="/single-pay-insurance">Single Pay Term Insurance</a></li>
                          <li><a href="/group-term-insurance">Group Term Insurance</a></li>
                      </ul>
                    </li>
                    <li class="sub-dropdown">
                      <a href="#">Saving Plans</a>
                      <ul class="sub-dropdown-menu">
                          <li><a href="/tax-saving-insurance">Tax Saving Policy</a></li>
                          <li><a href="/money-back-insurance">Money Back Policy</a></li>
                          <li><a href="/guaranteed-return-insurance">Guaranteed Return Policy</a></li>
                          <li><a href="/ulip-insurance">ULIP</a></li>
                          <li><a href="/pension-insurance">Pension Plan</a></li>
                          <li><a href="/child-insurance">Child Plan</a></li>
                      </ul>
                    </li>
                    <li class="sub-dropdown">
                      <a href="#">Others</a>
                      <ul class="sub-dropdown-menu">
                          <li><a href="/privacy-policy">Privacy Policy</a></li>
                          <li><a href="/cancellation-refund">Cancellation & Refund</a></li>
                          <li><a href="/term-condition">Terms & Conditions</a></li>
                          <li><a href="/disclaimer">Disclaimer</a></li>
                          <li><a href="/grivance-redressal">Grivance Redressal</a></li>
                      </ul>
                    </li>
                  </ul>
                </li> -->
                <!-- <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">Claim</a>
                  <ul class="dropdown-menu">
      
                    <li><a class="dropdown-item" href="#">File a New Claim</a></li>
          
                    <li><a class="dropdown-item" href="#">Filed Claim with Insurer</a></li>
          
                    <li><a class="dropdown-item" href="#">Claim Type </a></li>
          
                    <li><a class="dropdown-item" href="#">Claim Assistance</a></li>
          
          
          
                  </ul>
                </li> -->
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">{{langList.support}}</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">{{langList.raise_a_query}}</a></li>
                    <li><a class="dropdown-item" href="#">Raise a Claim</a></li>

                    <li><a class="dropdown-item" href="/contactus">{{langList.contact_us}}</a></li>
                    <li><a class="dropdown-item" href="/Vacancy">{{langList.career}}</a></li>
                  </ul>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="http://clickfourbima.com/gallery/">{{langList.gallery}}</a>
                </li> -->
                <li class="nav-item">
                  <!-- (click)="agentValue('agent')" -->
                  <button type="button" class="btn btn-agentlog" (click)="agentValue('agent')"><a id="agent_btn"
                      routerLink="/agent-homepage" target="_blank">Earn With Us</a></button>
                </li>
                <!-- <li class="nav-item" *ngIf="!checkUserIdLoggedIn()">
                  <a class="nav-link" routerLink="/login">{{langList.login}}</a>
                </li>         -->
                <li class="nav-item" *ngIf="!checkUserIdLoggedIn()">
                  <a class="nav-link login_btn11" (click)="Login()">Login</a>
                </li>
                <li class="lang-dropdown dropdown">
                  <a class="nav-link lang_btn " href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false"><img src="../../assets/svgIcon/language.svg" alt=""></a>
                  <ul class="dropdown-menu cursor-point lang_dropdown" (click)="languageDropdown($event)">
                    <li value="hindi">हिंदी</li>
                    <li value="english">English</li>
                    <li value="gujrati">ગુજરાતી</li>
                    <li value="punjabi">ਪੰਜਾਬੀ</li>
                    <li value="kannada">ಕನ್ನಡ</li>
                    <li value="marathi">मराठी</li>
                    <li value="telugu">తెలుగు</li>
                    <li value="malayalam">മലയാളം</li>
                    <li value="tamil">தமிழ்</li>
                    <li value="bengali">বাংলা</li>
                    <li value="odiya">ଓଡ଼ିଆ</li>
                  </ul>
                </li>
                <li class="nav-item dropdown" *ngIf="checkUserIdLoggedIn()">
                  <a class="nav-link dropdown-toggle profile-img logout_img pr-0 mt-2" href="#" role="button"
                    data-bs-toggle="dropdown" aria-expanded="true">
                    <img class="image_one ml-2 mt-n2" src="../../assets/images/avatar_one.png" alt=""> <span
                      style="font-family: Roboto;font-size: 13px;font-weight: 400;line-height: 18px;letter-spacing: 0px;text-align: center; color: rgba(132, 132, 147, 1)">Welcome
                      back!</span> <br> <span
                      style="font-family: Roboto;font-size: 17px;font-weight: 500;line-height: 24px;letter-spacing: 0px;text-align: center; color: rgba(71, 71, 92, 1);">{{fullname}}</span>
                  </a>
                  <ul class="dropdown-menu dashboard_icons">                
                    <li><a class="dropdown-item" (click)="dashboard()"><img class="mr-3 mt-n2"
                      src="../../assets/nav_icons/Browsers.png" alt=""> Dashboard</a></li>
                    <li><a class="dropdown-item" (click)="profile()"><img class="mr-3 mt-n2"
                      src="../../assets/nav_icons/UserCircle.png" alt=""> Profile</a></li>
                    <li><a class="dropdown-item" (click)="logout()"><img class="mr-3 mt-n2"
                          src="../../assets/nav_icons/SignOut.png" alt=""> Logout</a></li>
                  </ul>
                </li>

              </ul>
            </div>
            <div class="card show-nav-mob ">
              <div class="row p-6 text-start">
                <!-- <div class="col-md-12"><h2>  <a class="nav-link" href="http://clickfourbima.com/gallery/">{{langList.gallery}}</a></h2></div> -->
                <div class="col-md-12 mt-2">
                  <h2 (click)="support()">{{langList.support}}&nbsp; &nbsp; <img src="../../assets/svgIcon/arrowDown.svg" alt="" >
                 </h2>
                 <ul *ngIf="isSupport">
                  <li class="lis mt-2"><a class="dropdown-item" href="#">{{langList.raise_a_query}}</a></li>
                  <li class="lis mt-2"><a class="dropdown-item" href="#">Raise a Claim</a></li>
                  <li class="lis mt-2"><a class="dropdown-item" href="/contactus">{{langList.contact_us}}</a></li>
                  <li class="lis mt-2"><a class="dropdown-item" href="/Vacancy">{{langList.career}}</a></li>
                </ul>
                </div>
                <div class="col-md-12 mt-2">
                  <button type="button" class="btn btn-agentlog" (click)="agentValue('agent')" id="btn"> <a id="agent_btn"
                    routerLink="/agent-homepage" target="_blank" >Earn With Us</a></button>
                </div>
                <div class="col-md-12 mt-2" *ngIf="!checkUserIdLoggedIn()">
                  <button type="button" class="btn " id="btn" style="border: 1px solid var(--main);" (click)="Login()"> Login</button>
                </div>
                <div class="col-md-12 mt-2" *ngIf="checkUserIdLoggedIn()">
                  <a class="nav-link dropdown-toggle profile-img logout_img pr-0 mt-2" href="#" role="button"
                  data-bs-toggle="dropdown" aria-expanded="true">
                  <img class="image_one ml-2 mt-n2" src="../../assets/images/avatar_one.png" alt=""> <span
                    style="font-family: Roboto;font-size: 13px;font-weight: 400;line-height: 18px;letter-spacing: 0px;text-align: center; color: rgba(132, 132, 147, 1)">Welcome
                    back!</span> <br> <span
                    style="font-family: Roboto;font-size: 17px;font-weight: 500;line-height: 24px;letter-spacing: 0px;text-align: center; color: rgba(71, 71, 92, 1);">{{fullname}}</span>
                </a>
                <ul class="dropdown-menu dashboard_icons">
                  <li><a class="dropdown-item" (click)="dashboard()"><img class="ml-2 mt-n2"
                        src="../../assets/nav_icons/Browsers.png" alt=""> Dashboard</a></li>
                  <li><a class="dropdown-item" (click)="profile()"><img class="ml-2 mt-n2"
                        src="../../assets/nav_icons/UserCircle.png" alt=""> Profile</a></li>
                  <li><a class="dropdown-item" (click)="logout()"><img class="ml-2 mt-n2"
                        src="../../assets/nav_icons/SignOut.png" alt=""> Logout</a></li>
                </ul>
                </div>
              </div>  
             
              </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</nav>
<section class="products_section">
  <div class="container">
    <div class="menu_tabs" *ngIf="isProducts">
      <div class="user-menu card" *ngIf="!isViewQuotes">
        <ul class="nav nav-tabs row text-center" role="tablist">
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isBike}" (click)="goTo('Bike')">
            <a href="#bike" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/bike.svg" alt="">&nbsp;&nbsp;<span>{{langList.two_wheeler}}</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isCar}" (click)="goTo('Car')">
            <a href="#car" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/car.svg" alt="">&nbsp;&nbsp;<span style="line-height: 0px;">{{langList.four_wheeler}}</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isHealth}" (click)="goTo('Health')">
            <a href="#health" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/health.svg" alt="">&nbsp;&nbsp;<span>{{langList.health}}</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isHome}" (click)="goTo('Home')">
            <a href="#home" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/truckkk.svg" alt="">&nbsp;&nbsp;<span>Commercial</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isProperty}" (click)="goTo('Property')">
            <a href="#property" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/homeeee.svg" alt="">&nbsp;&nbsp;<span>Property</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isTravel}" (click)="goTo('Travel')">
            <a href="#travel" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/travellll.svg" alt="">&nbsp;&nbsp;<span>Travel</span>
            </a>
          </li>
          
          <!-- <li class="nav-item menu-item col" [ngClass]="{'nav-item': isLife}" (click)="goTo('Life')">
            <a href="#5a" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/life.svg" alt="">&nbsp;&nbsp;<span>Life</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isTerm}" (click)="goTo('Term')">
            <a href="#6a" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/term.svg" alt="">&nbsp;&nbsp;<span>Term</span>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- <nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a href="">    <img src="../../assets/images/logo.svg" class="logo" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
      
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="pollicy" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Renew Policy
          </a>
          <ul class="dropdown-menu" aria-labelledby="pollicy">
            <li><a class="dropdown-item" href="#">Two Wheeler Renewal</a></li>
            <li><a class="dropdown-item" href="#">Four wheeler Renewal</a></li>
            <li><a class="dropdown-item" href="#">Health Renewal</a></li>
            <li><a class="dropdown-item" href="#">Home Renewal</a></li>
            <li><a class="dropdown-item" href="#">Life Renewal</a></li>
  
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="Claim" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Claim
          </a>
          <ul class="dropdown-menu" aria-labelledby="Claim">
            <li><a class="dropdown-item" href="#">File a New Claim</a></li>
            <li><a class="dropdown-item" href="#">Filed Claim with Insurer</a></li>
            <li><a class="dropdown-item" href="#">Claim Type </a></li>
            <li><a class="dropdown-item" href="#">Claim Assistance</a></li>
  
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="Support" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Support
          </a>
          <ul class="dropdown-menu" aria-labelledby="Support">
            <li><a class="dropdown-item" href="#">Download Policy</a></li>
            <li><a class="dropdown-item" href="#">Get Help</a></li>
            <li><a class="dropdown-item" href="#">Raise a Query</a></li>
            <li><a class="dropdown-item" href="#">Request a Callback</a></li>
            <li><a class="dropdown-item" href="#">Track Policy Status</a></li>
            <li><a class="dropdown-item" href="#">View Policy Details</a></li>
            <li><a class="dropdown-item" href="#">Share Feedback</a></li>
            <li><a class="dropdown-item" href="#">Contact Us</a></li>
  
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" style="background-color: var(--main);border-radius:10px; " id="Support" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="../../assets/svgIcon/Translate.svg" class="language" alt="">
          </a>
          <ul class="dropdown-menu" aria-labelledby="Support">
            <li><a class="dropdown-item" href="#">English</a></li>
            <li><a class="dropdown-item" href="#">Hindi</a></li>
            <li><a class="dropdown-item" href="#">Gujarati</a></li>
            <li><a class="dropdown-item" href="#">Telugu</a></li>
            <li><a class="dropdown-item" href="#">Rajasthani</a></li>
            <li><a class="dropdown-item" href="#">Tamil</a></li>
            <li><a class="dropdown-item" href="#">Marathi</a></li>
            <li><a class="dropdown-item" href="#">Punjabi</a></li>
            <li><a class="dropdown-item" href="#">Odiya</a></li>
            <li><a class="dropdown-item" href="#">Bengali</a></li>
            <li><a class="dropdown-item" href="#">Kannada</a></li>
            <li><a class="dropdown-item" href="#">Malayalam</a></li>
  
          </ul>
        </li> 
      </ul>
      &nbsp;&nbsp;&nbsp;&nbsp;
    <form class="form-inline my-2 my-lg-0">
      <button class="btn btn-primary" style="background-color: rgba(64,64,255,0.05) !important;"><a class=""><span style="color:var(--main)" routerLink="/login">Login</span></a></button>
      </form>
    </div>
  </div>
</nav> -->
<div class="overlay" *ngIf="LoginPopUp">
  <div class="popup2 col-md-4 col-sm-8 col-xs-12" [ngClass]="{'col-md-5':isNewUser}">
    <div class="modal-header tooltip_ pb-0">
      <!-- <h4 class="modal-title">Sign In</h4> -->
      <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
        <!-- <span aria-hidden="true">×</span> -->
      </button>
    </div>
    <div class="modal-body pt-0">
      <div class="row">
        <div class="col-md-12 text-center mb-4">
          <img src="../../assets/images/logo.svg" alt="" class="logomod">
        </div>
      </div>
      <div class="row" *ngIf="component=='1'">
        <div class="col-md-12">
          <!-- <div class="row">
              <div class="col-md-12">
                <img src="../../assets/images/logo.svg" alt="" class="logo">

              </div>
            </div> -->
          <div class="row" *ngIf="loginFormDiv">
            <div class="col-md-12">
              <p class=" font-body text-center">
                Login to your Account
              </p>

              <div class="login_div mt-5">
                <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm">
                  <div class="form-group">
                    <label class="input-label required px-0 lbl" for="mobile">Mobile Number</label>
                    <!-- <input type="number" class="form-control" name="phone" formControlName="PhoneNumber" placeholder="Enter your number" minlength="10" onKeyPress="if(this.value.length==10) return false;" onfocus="this.placeholder=''" autocomplete="off" /> -->

                    <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true" [searchCountryFlag]="false" [onlyCountries]="[CountryISO.India]"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [phoneValidation]="true"
                      customPlaceholder="Enter your mobile number" name="phone" formControlName="PhoneNumber"
                      [inputId]="'phone_number'" (countryChange)="onCountryChangeNew($event)" aria-hidden="true"
                      #phoneNumber (keydown)="validateNumberInput($event)"  (input)="onInput($event)" [minlength]="10"
                      [maxLength]="10" [(ngModel)]="M_Number">
                    </ngx-intl-tel-input>
                    <Span class="text-danger" *ngIf="((loginFormControl['PhoneNumber'].touched || submittedLogin) && loginFormControl['PhoneNumber'].errors?.['required'])">
                      Mobile number is required.
                    </Span>
                  </div>
                  <div class="form-group mb-2">
                    <label class="input-label required px-0 lbl" for="password">Password</label>
                    <input [type]="this.passwordType" placeholder="Enter Password" name="password" autocomplete="off"  (keypress)="passKeyPress($event)"
                      class="form-control bg-transparent" formControlName="password" onfocus="this.placeholder=''" [(ngModel)]="userPass"/>
                    <i [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                      (click)="ShowPassword()" ></i>
                    <Span class="text-danger"
                      *ngIf="((loginFormControl['password'].touched || submittedLogin) && !loginFormControl['password'].valid)">
                      Password is required.
                    </Span>
                  </div>
                  <div class="passwords_div d-flex justify-content-between align-items-center mt-5">
                    <span class="d-flex align-items-center">
                      <input type="checkbox" class="remember_checkbox mt-0 scale-1_3 mx-1"  (change)="Autologin()">&nbsp;<label
                        class="remember lbl" style="font-weight: 550    ; position: relative;
                        top: 3px;;">Remember Me?</label>
                    </span>
                    <a class="link-primary forgot_password_link" (click)="forgotPassword()">Forgot Password?</a>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12">
                      <re-captcha required  formControlName="recaptchaReactive"></re-captcha>
                    </div>
                  </div>
                  <div class="d-grid">
                    <!-- [disabled]="loginForm.invalid" -->
                    <button class="btn btn-primary login_btn" type="submit" id="kt_sign_in_submit" [disabled]="!loginForm.valid" (click)="submit()">
                      <span class="indicator-label">Sign In</span>
                    </button>
                    <p class="mt-5 mb-0 text-center">Don't have an Account? <a class="link-primary link-color"
                        (click)="registerDiv()">Sign up here </a></p>
                  </div>
                  <!-- <div class="passwords_div">
                      <p>Don't have Account <a  class="link-primary sign_up_link" (click)="" >Forgot Password ?</a></p>
                    </div> -->

                </form>
              </div>
            </div>
            <!-- <div class="col-md-10 mt-10 set">
                <label for="">Mobile Number</label>
                <input type="number" name="number" id="" class="form-control"
                  onkeypress="if(this.value.length=='10') return false">
              </div>
              <div class="col-md-10 mt-5  set">
                <label for="">Password</label>
                <input type="password" name="Password" id="" class="form-control"
                  onkeypress="if(this.value.length=='16') return false">
              </div>
              <div class="col-md-6 mt-5 ">  
                <div class="form-check ml-5">
                 <input    type="checkbox" value="" id="flexCheckDefault">
                  <span style="font-size: 13  px;"  >
                    Remember me? </span>    
                </div>  
              </div>
              <div class="col-md-6 mt-5 text-end">
                <span class="main point" style="font-size: 13px;" (click)="Popup('forgate')">Forgot Password?</span>
              </div>
              <div class="col-md-12 set mt-5">
                <button class="btn btn-primary btn-custom" id="btn" >Sign In</button>
              </div>
              <div class="col-md-10 set mt-3">
                <span class="text-center" style="font-size: 13px; ">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Don’t have an Account?&nbsp;<span
                     class="point main" (click)="Popup('signup')">Signup Here</span>
                </span>

              </div> -->
          </div>
          <div class="row" *ngIf="forgotPasswordDiv">
            <!-- <div class="col-md-12 mt-5 text-center">
                <h2 class="text-center">
                  OTP <span style="color: rgba(255, 173, 42, 1);">Verification</span>
                </h2>

              </div>
              <div class="col-md-12  text-center ">
                <p class="text-center">
                  Enter 6 digit code that sent to your mobile Number. 9687337536 Click here to change your Mobile
                  Number.
                </p>
              </div>
              <div class="col-md-10 mt-4 set">
                <label for="">Enter OTP</label>
                <input type="number" name="otp" id="" class="form-control" placeholder="OTP" onkeypress="if(this.value.length==6) return false">
              </div>

              <div class="col-md-8 mt-3">
           <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resend OTP in&nbsp;<span style="color:green ">00:30</span></span>
              </div>
              <div class="col-md-4 mt-3">
                <span class="point main" style="font-size: 13px;">Resend OTP </span>
              </div>
              <div class="col-md-12 mt-6 set">
                <button class="btn btn-primary btn-sm" id="btn" (click)="Popup('resetPassword')">Verify</button>
              </div>
              <div class="col-md-10  mt-3 text-center"  >
                <span class="text-center spn">&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back to login&nbsp;&nbsp;<span class="main point spn" (click)="Popup('login')">Click Here</span></span>
              </div> -->
            <div class="col-md-12">
              <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="resetPassForm">
                <!-- <div class="back_div">
                    <button class="btn-primary back_btn" (click)="forgotBackF()"><img src="../../assets/svgIcon/ArrowLeft.svg" class="svg_white" /></button>
                  </div> -->
                <ng-container *ngIf="isForgot">
                  <div class="text-center ">
                    <h2 class=" text-center">Forgot <span class="h"> Password? </span></h2>
                    <!-- <p class="text-center">Reset your current password</p> -->
                  </div>
                  <div class="form-group">
                    <label class="input-label required lbl" for="mobile">Mobile Number</label>
                    <!-- <input type="number" class="form-control" name="phone" formControlName="PhoneNumber" placeholder="Enter your number" minlength="10" onKeyPress="if(this.value.length==10) return false;" onfocus="this.placeholder=''"
                      [ngClass]="{'border border-danger': ((resetPassFormControl['PhoneNumber'].touched || submitted) && !resetPassFormControl['PhoneNumber'].valid)}"> -->
                    <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true"
                      placeholder="Enter Mobile Number/Email" [searchCountryFlag]="false"
                      [onlyCountries]="[CountryISO.India]"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [phoneValidation]="true"
                      [enablePlaceholder]="true" customPlaceholder="Enter your mobile number" name="phone"
                      [maxLength]="10" formControlName="PhoneNumber" [inputId]="'phone_number'"
                      (countryChange)="onCountryChangeNew($event)" aria-hidden="true" (keydown)="validateNumberInput($event)"  (input)="onInput($event)" >
                    </ngx-intl-tel-input>
                    <Span class="text-danger"
                      *ngIf="((resetPassFormControl['PhoneNumber'].touched || submitted) && resetPassFormControl['PhoneNumber'].errors?.['required'])">
                      Mobile number is required.
                    </Span>
                    <Span class="text-danger"
                      *ngIf="((resetPassFormControl['PhoneNumber'].touched || submitted) && resetPassFormControl['PhoneNumber'].errors?.['pattern'])">
                      Provide valid Mobile number.
                    </Span>
                  </div>
                  <div class="d-grid">
                    <button class="btn btn-primary login_btn" type="submit" id="kt_sign_in_submit" (click)="sendOTP()"
                      [disabled]="!resetPassFormControl['PhoneNumber'].valid">
                      <span class="indicator-label">Send OTP</span>
                    </button>
                    <p class="login_para text-center mt-5 mb-0">Back to Login? <a class="link-color"
                        (click)="clickBack()">Click Here</a></p>
                  </div>
                </ng-container>

                <ng-container *ngIf="isOtp">
                  <div class="text-center mb-3">
                    <h2 class=" text-center">OTP <span class="h"> Verification</span></h2>
                    <p class="text-center">Enter 6 digit code that sent to your mobile number 
                      +91{{resetPassFormControl['PhoneNumber'].value.number }}.<br> <a
                        class="text-primary cursor-pointer link-color" (click)="changeNumber()">Click here</a> to change
                      your mobile number.</p>
                  </div>
                  <div class="form-group mb-2">
                    <label class="input-label required lbl" for="mobile">Enter OTP</label>
                    <input type="number" class="form-control" name="phone" formControlName="otpNumber"
                      placeholder="Enter OTP" minlength="6" maxlength="6"
                      onfocus="this.placeholder=''"
                      [ngClass]="{'border border-danger': ((resetPassFormControl['otpNumber'].touched || submittedOtp) && !resetPassFormControl['otpNumber'].valid)}" (keydown)="validateNumberInput($event)"  (input)="onInput($event)">
                    <Span class="text-danger"
                      *ngIf="((resetPassFormControl['otpNumber'].touched || submittedOtp) && resetPassFormControl['otpNumber'].errors?.['required'])">
                      OTP is required.
                    </Span>
                    <Span class="text-danger"
                      *ngIf="((resetPassFormControl['otpNumber'].touched || submittedOtp) && resetPassFormControl['otpNumber'].errors?.['pattern'])">
                      Provide valid OTP.
                    </Span>
                  </div>
                  <div class="passwords_div min-height50">
                    
                      <div class="row"><div class="col-md-6">
                        <span>
                          <ng-container *ngIf="timerValue > 0">Resend OTP in 00: {{ timerValue }}
                            Secs</ng-container></span>
                      </div>
                    <div class="col-md-6 text-end">
                      <button type="button" *ngIf="timerValue == 0" [disabled]="timerValue > 0"
                        class="link-primary link-primary forgot_password_link cursor-pointer btn-none px-0"
                        (click)="resendOTP()"> Resend OTP </button>
                    </div></div>
                      <!-- <ng-container *ngIf="timerValue > 0">Resend OTP in {{ timerValue }}</ng-container>  -->
                      
                  
                  </div>
                  <div class="d-grid">
                    <button class="btn btn-primary login_btn" type="button" (click)="validateOtp()"
                      [disabled]="!resetPassFormControl['otpNumber'].valid">
                      <span class="indicator-label">Verify</span>
                    </button>
                    <p class="login_para text-center mb-0 mt-5">Back to Login? <a class="link-color"
                        (click)="clickBack()">Click Here</a></p>
                  </div>
                </ng-container>
                <!-- <div class="d-grid mb-5"></div>      
                  <div class="d-grid mb-5"></div>  -->
              </form>
              <form class="form" novalidate="novalidate" [formGroup]="loginForm" *ngIf="isReset">
                <div class="text-center mb-3">
                  <h2>Reset <span class="h"> Password </span></h2>
                  <!-- <p>Login to your Account</p> -->
                </div>
                <div class="form-group">
                  <label class="input-label required lbl" for="mobile">New Password</label>  <span class="text-danger mb-3 error" *ngIf="strongPassword()"><b> ( Should be 8+ chars, with 1 uppercase, 1 lowercase, 1 number)</b></span>
                
                  <input [type]="this.resetPasswordType" class="form-control" name="phone" formControlName="password"
                    placeholder="Enter New Password" onfocus="this.placeholder=''"
                    [ngClass]="{'border border-danger': ((forgotPasswordFormControl['password'].touched || submittedPass) && !forgotPasswordFormControl['password'].valid)}"
                    (keyup)="passwordChange()" minlength="8" />
                  <!-- <i [class]="passwordShow === false ? 'fa fa-eye-slash field_icon field-icon fs-4' : 'fa fa-eye field-icon fs-4'" (click)="Showpassword(false)"></i> -->
                  <Span class="text-danger"
                    *ngIf="((forgotPasswordFormControl['password'].touched || submittedPass) && !forgotPasswordFormControl['password'].valid)">
                    Password is required.
                  </Span>
                
                </div>
                  <Span class="text-danger"
                  *ngIf="((forgotPasswordFormControl['password'].touched || submittedPass) && forgotPasswordFormControl['password'].errors?.['minlength'])">
                  Size must be 8 characters or digits.
                </Span>
              
                <div class="form-group">
                  <label class="input-label required lbl" for="confirm_password">Confirm  Password</label>
                  <input [type]="this.resetPasswordTypeConfirm" placeholder="Enter Confirm Password"
                    name="confirm_password" autocomplete="off" class="form-control bg-transparent"
                    formControlName="confirm_password" onfocus="this.placeholder=''"
                    [ngClass]="{'border border-danger': ((forgotPasswordFormControl['confirm_password'].touched || submittedPass) && !forgotPasswordFormControl['confirm_password'].valid)}"
                    (keyup)="passwordChange()" />
                  <i [class]="passwordShowConfirm === false ? 'fa fa-eye-slash field_icon field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                    (click)="resetShowpasswordConfirm()"></i>
                  <Span class="text-danger"
                    *ngIf="((forgotPasswordFormControl['confirm_password'].touched || submittedPass) && !forgotPasswordFormControl['confirm_password'].valid)">
                    Confirm password is required.
                  </Span>
                  <Span class="text-danger" *ngIf="!isPassSame">
                    Password and Confirm password must be same.
                  </Span>
                </div>
                <div class="fv-row mb-8">
                </div>
                <!-- <div class="passwords_div">
                    <input type="checkbox">&nbsp;<span class="remember mt-1">Remember me</span>
                    <a href="/forgot-password" class="link-primary forgot_password_link" >Forgot Password ?</a>
                  </div> -->
                <div class="d-grid mb-5">
                  <button class="btn btn-primary login_btn" type="submit" id="kt_sign_in_submit"
                    (click)="resetPassword()" [disabled]="!isValidLoginForm">
                    <span class="indicator-label">Reset Password</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="row" *ngIf="signupDiv">
            <div class="col-md-12">
              <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="signupForm"
                autocomplete="off">
                <!-- <div class="back_div">
                    <button class="btn-primary back_btn" (click)="signupBackF()"><img src="../../assets/svgIcon/ArrowLeft.svg" class="svg_white" /></button>
                  </div> -->
                <ng-container *ngIf="isSignup">
                  <div class="text-center mb-3">
                    <h2 class="text-center ">Sign<span class="h">&nbsp;up </span></h2>
                  </div>
                  <div class="fv-row mb-7">
                    <label class="input-label required lbl" for="mobile">Mobile Number</label>
                    <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true" [searchCountryFlag]="false" [onlyCountries]="[CountryISO.India]"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false" [maxLength]="10" [minlength]="10"
                      [selectedCountryISO]="CountryISO.India" [phoneValidation]="true"
                      customPlaceholder="Enter your mobile number" name="phone" formControlName="phoneNumber"
                      [inputId]="'phone_number'" (countryChange)="onCountryChangeNew($event)" aria-hidden="true"
                      #phoneNumber (keydown)="validateNumberInput($event)"  (input)="onInput($event)">

                    </ngx-intl-tel-input>
                    <!-- [separateDialCode]="separateDialCode" -->
                  </div>
                  <div class="d-grid mb-5 mt-5">
                    <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="PhoneExists()"
                      [disabled]="signupForm.invalid || ServicesDown">
                      <span class="indicator-label">Continue</span>
                    </button>
                  </div>
                  <div class="mt-5 text-center">
                    <span> Already have an account?
                      <a class="link-primary link-color" (click)="haveAccount()">Sign In Here</a></span>
                  </div>
                </ng-container>
                <ng-container *ngIf="isOtp">
                  <div class="mb-6">
                    <h2 class="text-center ">OTP <span class="h">Verification</span></h2>
                    <p class=" text-center">Enter 6 digit code that sent to your number {{phoneNumber}}.<br> <a
                        class="link-color float-none" (click)="changemobile()">Click here</a> to change your mobile
                      number.</p>
                  </div>
                  <div class="fv-row mb-5">
                    <label class="input-label required lbl" for="mobile">Enter OTP</label>
                    <div class="fields_group">
                      <input placeholder="Enter OTP" name="number" autocomplete="off"
                        class="form-control bg-transparent otp_filed" formControlName="otp" style="width: 29rem;"
                        maxlength="6" minlength="6" pattern="[0-9]{6}"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"  (keydown)="validateNumberInput($event)"  (input)="onInput($event)"/>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <ng-container *ngIf="timerValue > 0"> Resend OTP in 00: {{ timerValue }} Secs</ng-container>
                    </div>
                    <!--   <span style="color: #337ab7 !important; font-size: 13px;" class="timerCounter" *ngIf="timerValue > 0">{{ timerValue }} Secs</span> -->
                    <div class="col-md-6 text-end" > <button type="button" *ngIf="timerValue == 0" [disabled]="timerValue > 0"
                        class="link-primary btn-none px-0 forgot_password_link" (click)="resendOTPSignUp()"> Resend OTP
                        </button>
                    </div>
                  </div>
                  <div class="d-grid mb-5 mt-5">
                    <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="validateSignUpOtp()"
                      [disabled]="signupForm.invalid">
                      <span class="indicator-label">Submit</span>
                    </button>
                  </div>
                  <div class="text-center">
                    <span>By creating your account, you agree to our <a routerLink="/terms-of-use" target="_blank"
                        class="link-color">Terms & Conditions</a></span>
                  </div>
                </ng-container>
                <ng-container *ngIf="isNewUser">
                  <div class="text-center mb-5">
                    <h2 class=" text-center" style="text-align: center; ">Basic <span class="h">Details</span></h2>
                  </div>
                  <div class="d-flex fv-row mb-3 row">
                    <div class="col-md-6">
                      <label class="input-label required lbl" for="firstName">First Name</label>
                      <input type="text" placeholder="First name" name="firstName" autocomplete="off"
                        (keypress)="alphabetOnly($event)" class="new-user form-control bg-transparent"
                        formControlName="firstName" />
                        <Span class="text-danger" *ngIf="((signupFormControl['firstName'].touched || submittedSignUp) && signupFormControl['firstName'].errors?.['required'])">
                          First name is required.
                        </Span>
                    </div>
                    <div class="col-md-6">
                      <label class="input-label required lbl" for="lastName">Last Name</label>
                      <input type="email" placeholder="Last name " name="lastName" autocomplete="off"
                        (keypress)="alphabetOnly($event)" class="new-user form-control bg-transparent"
                        formControlName="lastName" />
                        <Span class="text-danger" *ngIf="((signupFormControl['lastName'].touched || submittedSignUp) && signupFormControl['lastName'].errors?.['required'])">
                          Last name is required.
                        </Span>
                    </div>
                  </div>
                  <div class="d-flex fv-row mb-3 row">
                    <!-- <div class="col">
                        <label class="input-label required" for="name">Your Name</label>
                        <input type="text" placeholder="User Name" name="name" autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="userName" />
                      </div> -->
                    <div class="col-md-6">
                      <label class="input-label required lbl" for="email">Email Address</label>
                      <input type="email" placeholder="Email address" name="email" autocomplete="off"
                        class="form-control bg-transparent" formControlName="userEmail" />
                        <Span class="text-danger" *ngIf="((signupFormControl['userEmail'].touched || submittedSignUp) && signupFormControl['userEmail'].errors?.['required'])">
                          Email is required.
                        </Span>
                        <Span class="text-danger" *ngIf="((signupFormControl['userEmail'].touched || submittedSignUp) && signupFormControl['userEmail'].errors?.['pattern'])">
                          Please provide valid email address.
                        </Span>
                    </div>

                    <div class="col-md-6">
                      <label class="input-label required lbl" for="dob">Date Of Birth</label>
                      <input type="date" placeholder="DD-MM-YYYY" name="dob" autocomplete="off"
                        class="form-control bg-transparent"
                         formControlName="dob"
                          [(ngModel)]="dob"  onkeydown="return false" [max]="maxDate" />
                        <Span class="text-danger" *ngIf="((signupFormControl['dob'].touched || submittedSignUp) && signupFormControl['dob'].errors?.['required'])"  >
                          Date of birth is required.
                        </Span>
                    </div>
                  </div>
                  <!-- <div class="d-flex fv-row mb-1">
                      <div class="mt-3 mx-2" [hidden]="isReff">
                        <label class="input-label" for="address">Refferal Code</label>
                        <input placeholder="Enter Refferal Code"  autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="referralCode" />
                      </div>
                    </div> -->
                  <div class="d-flex fv-row row mb-3">
                    <div class="col-md-12" style="padding: 2%;margin-left: 1%;">
                      <label class="input-label required" for="gender">Gender</label> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                      <input class="my-3" type="radio" id="male" name="gender" value="Male" formControlName="gender">
                     &nbsp;&nbsp;<span >Male</span>
                      &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                      <input class="my-3" type="radio" id="female" name="gender" value="Female"
                        formControlName="gender">
                        &nbsp;&nbsp;  <span > Female </span> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                      <input class="my-3" type="radio" id="other" name="gender" value="Other" formControlName="gender">
                      &nbsp;&nbsp; <span > Other </span>                         
                      <Span class="text-danger w-100" *ngIf="((signupFormControl['gender'].touched || submittedSignUp) && signupFormControl['gender'].errors?.['required'])">
                        Gender is required.
                      </Span>
                    </div>
                  </div>
                  <!-- <div class="d-flex fv-row mb-1">
                      <div>
                        <label class="input-label required" for="address">Street Address</label>
                        <input placeholder="Enter Address" name="address" autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="streetAddress" />
                      </div>&nbsp;&nbsp;
                      <div>
                        <label class="input-label required" for="pincode">Your Pincode</label>
                        <input type="number" placeholder="Enter Pincode" name="pincode" autocomplete="off" #pinCode
                               (blur)="pincodeUserChangeEvent(pinCode)" class="new-user form-control bg-transparent"
                               formControlName="pincodeId" />
                        <label> {{pincodeMaster.district}} {{pincodeMaster.state}} {{pincodeMaster.country}}</label>
                      </div>
                    </div> -->
                  <div class="d-flex fv-row mb-3 row">
                    <div class="col-md-6">
                      <label class="input-label required lbl" for="passcode">Password</label>
                      <input [type]="this.signUpFormPasswordType" placeholder="password" name="passcode"
                        autocomplete="off" class="new-user form-control bg-transparent" formControlName="passcode" [minlength]="8" />
                        <Span class="text-danger" *ngIf="((signupFormControl['passcode'].touched || submittedSignUp) && signupFormControl['passcode'].errors?.['required'] && signupFormControl['passcode'].errors?.['minlength']) " >
                          Password is required.
                        </Span>
                        <span class="text-danger mb-3 error" *ngIf="strongPasswordForSignUp()"><b> ( Should be 8+ chars, with 1 uppercase, 1 lowercase, 1 number, 1 special char)</b></span>
                
                    </div>
                    <div class="col-md-6">
                      <label class="input-label required lbl" for="confirmPassword">Confirm Password</label>
                      <input [type]="this.signUpFormPasswordTypeConfirm" placeholder="Confirm password"
                        name="confirmPassword" autocomplete="off" class="new-user form-control bg-transparent"
                        formControlName="confirmPassword" />
                      <i [class]="signUpFormPasswordShowConfirm === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'"
                        (click)="signUpFormShowPassword()"></i>
                        <Span class="text-danger" *ngIf="((signupFormControl['confirmPassword'].touched || submittedSignUp) && signupFormControl['confirmPassword'].errors?.['required'])">
                          Confirm Password is required.
                        </Span>
                        <Span class="text-danger" *ngIf="(((signupFormControl['passcode'].touched && signupFormControl['confirmPassword'].touched) || submittedSignUp) && signupFormControl['confirmPassword'].value != signupFormControl['passcode'].value)">
                          Password and confirm password must be same. 
                        </Span>
                    </div>
                  </div>
                  <div class="row mt-5 mb-2">
                    <div class="col-12">
                      <re-captcha required  formControlName="recaptchaReactive"></re-captcha>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                    <div class="d-grid justify-content-center col-12">
                      <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="submitOTPSignup()"
                        [disabled]="signupForm.invalid || signupFormControl['confirmPassword'].value!=signupFormControl['passcode'].value">
                        <span class="indicator-label">Submit</span>
                      </button>
                    </div></div>
                  </div>

                </ng-container>
              </form>
            </div>
          </div>
          <!-- <div class="row" *ngIf="popupNo=='forgate'">
              <div class="col-md-12 mt-5 text-center">
                <h2 class="text-center">
                  Forgot <span style="color: rgba(255, 173, 42, 1);">Password?</span>
                </h2>

              </div>
              <div class="col-md-12  text-center ">
                <p class="text-center">
                  Change your current password
                </p>
              </div>
              <div class="col-md-10 mt-4 set">
                <label for="">Mobile Number</label>
                <input type="number" name="mobile" id="" class="form-control" placeholder="Mobile Number" onkeypress="if(this.value.length==10) return false">
              </div>

           
              <div class="col-md-12 mt-8 set">
                <button class="btn btn-primary btn-sm" id="btn" (click)="Popup('ForgatePasswordOTP')">Send OTP</button>
              </div>
              <div class="col-md-10  mt-3 text-center">
                <span class="text-center spn">&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back to login&nbsp;&nbsp;Back to login&nbsp;&nbsp;<span class="main point spn" (click)="Popup('login')">Click Here</span></span>
              </div>

            </div>
            <div class="row" *ngIf="popupNo=='resetPassword'">
              <div class="col-md-12 mt-5 text-center">
                <h2 class="text-center">
                  Reset <span style="color: rgba(255, 173, 42, 1);">Password?</span>
                </h2>

              </div>
              <div class="col-md-10 mt-10 set">
                <label for="">Password</label>
                <input type="password" name="password" id="" class="form-control"
                  onkeypress="if(this.value.length=='16') return false">
              </div>
              <div class="col-md-10 mt-5  set">
                <label for="">Confirm Password</label>
                <input type="password" name="Password" id="" class="form-control"
                  onkeypress="if(this.value.length=='16') return false">
              </div>
              
              
              <div class="col-md-12 set mt-5">
                <button class="btn btn-primary btn-custom" id="btn" (click)="Popup('succes')">Confirm Password</button>
              </div>
             
            </div>
            <div class="row" *ngIf="popupNo=='succes'">
              <div class="col-md-12 mt-5 text-center">
                <h2 class="text-center">
                  Reset <span style="color: rgba(255, 173, 42, 1);">Password</span>
                </h2>

              </div>
              <div class="col-md-12 mt-4 text-center " >
                <img src="../../assets/images/done.png" class="text-center" walt="" width="30%" height="65%">
              </div>
              <div class="col-md-12  text-center">
                <h4 class="text-center">
                  Your Password Has Been Changed Successfully
                </h4>
              </div>
              <div class="col-md-12 mt-3">
                <button class="btn btn-primary btn-sm set" id="btn" (click)="Popup('login')">Login Again</button>
              </div>
            </div>
            <div class="row" *ngIf="popupNo=='signup'">
              <div class="col-md-12 mt-5 text-center">
                <h2 class="text-center">
                  Sign <span style="color: rgba(255, 173, 42, 1);">up </span>
                </h2>

              </div>
             
              <div class="col-md-10 mt-4 set">
                <label for="">Mobile Number </label>
                <input type="number" name="mobile" id="" class="form-control" placeholder="Mobile Number" onkeypress="if(this.value.length==10) return false">
              </div>

            
              <div class="col-md-12 mt-6 set">
                <button class="btn btn-primary btn-sm" id="btn"(click)="Popup('OTP')">Send OTP</button>
              </div>
              <div class="col-md-10  mt-3 text-center">
                <span class="text-center spn">&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Already have an Account?&nbsp;&nbsp;<span class="main point spn" (click)="Popup('login')">Sign In Here</span></span>
              </div>

            </div>
            <div class="row" *ngIf="popupNo=='OTP'">
              <div class="col-md-12 mt-5 text-center">
                <h2 class="text-center">
                  OTP <span style="color: rgba(255, 173, 42, 1);">Verification</span>
                </h2>

              </div>
              <div class="col-md-12  text-center ">
                <p class="text-center">
                  Enter 6 digit code that sent to your mobile Number. 9687337536 Click here to change your Mobile
                  Number.
                </p>
              </div>
              <div class="col-md-10 mt-4 set">
                <label for="">Enter OTP</label>
                <input type="number" name="otp" id="" class="form-control" placeholder="OTP" onkeypress="if(this.value.length==6) return false">
              </div>

              <div class="col-md-8 mt-3">
           <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resend OTP in&nbsp;<span style="color:green ">00:30</span></span>
              </div>
              <div class="col-md-4 mt-3">
                <span class="point main" style="font-size: 13px;">Resend OTP </span>
              </div>
              <div class="col-md-12 mt-6 set">
                <button class="btn btn-primary btn-sm" id="btn" (click)="next('2')">Submit</button>
              </div>
              <div class="col-md-10  mt-3 text-center"  >
                <span class="text-center spn">&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back to login&nbsp;&nbsp;<span class="main point spn" (click)="Popup('login')">Click Here</span></span>
              </div>

            </div> -->
        </div>
        <div class="col-md-6">
          <div class=" rounded-5 ">
            <!-- <app-carousel></app-carousel> -->
          </div>
        </div>
      </div>
      <div class="row" *ngIf="component=='2'">
        <div class="col-md-12 mt-2 p-3 text-center">
          <img src="../../assets/images/logo.svg" alt="" class="logo">
        </div>
        <div class="col-md-6 mt-3">
          <input type="text" class="form-control" name="name" placeholder="Your Name">
        </div>
        <div class="col-md-6 mt-3">
          <input type="email" class="form-control" name="email" placeholder="abc@gmail.com">
        </div>
        <div class="col-md-6 mt-5">
          <input type="date" class="form-control" name="DOB" placeholder="Date Of Birth ">
        </div>
        <div class="col-md-6 mt-3">
          <label class="input-label " for="Date">Gender</label><br>
          <input class="my-3 radio-button" type="radio" id="male" name="gender" value="Male" formControlName="gender">
          Male
          &nbsp;&nbsp;
          <input class="my-3 radio-button" type="radio" id="female" name="gender" value="Female"
            formControlName="gender">
          Female &nbsp;&nbsp;
          <input class="my-3 radio-button" type="radio" id="other" name="gender" value="Other" formControlName="gender">
          Other
        </div>
        <div class="col-md-6 mt-3">
          <input type="text" class="form-control" name="address" placeholder="Street Address ">
        </div>
        <div class="col-md-6 mt-3">
          <input type="number" class="form-control" name="pincode" placeholder="Pin Code "
            onkeypress="if(this.value.length==6) return false">
        </div>
        <div class="col-md-6 mt-3">
          <input type="password" class="form-control" name="password" placeholder="Password "
            onkeypress="if(this.value.length==16) return false">
        </div>
        <div class="col-md-6 mt-3">
          <input type="password" class="form-control" name="ConfirmPassword" placeholder="Confirm Password "
            onkeypress="if(this.value.length==16) return false">
        </div>
        <div class="col-md-12 mt-5 text-center">
          <button class="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>