import { Component } from '@angular/core';
import { updateLocale } from 'moment';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.css']
})
export class SummaryPageComponent {
  Up:boolean=true;
  Up1:boolean=false;
  Up2:boolean=false;
  Up3:boolean=false;
  down:boolean=false;
  down1:boolean=true;
  down2:boolean=true;
  down3:boolean=true;
value:string='';
  dropDown(val:string)
  {
    this.value=val;
    if(this.value=='down'){
      this.down=false;
      this.Up=true;
      this.Up1=false;
      this.down1=true;
      this.Up2=false;
      this.down2=true;
      this.Up3=false;
      this.down3=true;

     
    }
    if(this.value=='up'){
      this.down=true;
      this.Up=false;
    }
    if(this.value=='down1'){
      this.down1=false;
      this.Up1=true;
      this.Up=false;
      this.down=true;
      this.Up2=false;
      this.down2=true;
      this.Up3=false;
      this.down3=true;
     
    }
    if(this.value=='up1'){
      this.down1=true;
      this.Up1=false;
    }
    if(this.value=='down2'){
      this.down2=false;
      this.Up2=true;
      this.Up=false;
      this.down=true;
      this.Up1=false;
      this.down1=true;
      this.Up3=false;
      this.down3=true
      
    }
    if(this.value=='up2'){
      this.down2=true;
      this.Up2=false;
    }
    if(this.value=='down3'){
      this.down3=false;
      this.Up3=true;
      this.Up=false;
      this.down=true;
      this.Up1=false;
      this.down1=true;
      this.Up2=false;
      this.down2=true;
    
    }
    if(this.value=='up3'){
      this.down3=true;
      this.Up3=false;
    }
  }
  
}
