import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { UserService } from '../Services/user.service';
import { AppConfig } from '../Shared/AppConfig';
import swal from 'sweetalert2';
import { LoginService } from './../Services/login.service';
import { Router } from '@angular/router';
import { ProfileService } from '../Services/profile.service';
import{FamilyMembers,PropertyDetails,RelationShips,Vehicles} from '../Models/Profile.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-family-details',
  templateUrl: './family-details.component.html',
  styleUrls: ['./family-details.component.css']
})
export class FamilyDetailsComponent implements OnInit{
  familyDetails:FamilyMembers[]=[];
  memberForm:FormGroup;
  RelationShipList:RelationShips[]=[];
   isEdit:boolean = false;

  constructor(private fb: FormBuilder, private userservice: UserService,
    private loginService: LoginService, private route: Router, private profileService: ProfileService,
    private spinner: NgxSpinnerService) {
    this.memberForm = this.fb.group({
      "relationShipType": ['', [Validators.required]],
      "fullName": ['', [Validators.required]],
      "dob": ['', [Validators.required]],
      "gender": ['', [Validators.required]],
      "memberId": [''],
    });
    }
  
  ngOnInit(){
     this.title ="Save";
   this.loadRelationships();
   this.getFamilyDetails();
  }

  loadRelationships(){
    this.RelationShipList = [{id:1,name:"Spouse"},{id:2,name:"Parent"},{id:3,name:"Child"},{id:4,name:"Sibling"},
   {id:5,name:"Grandparent"},{id:6,name:"Grandchild"},{id:7,name:"Cousin"},{id:8,name:"AuntUncle"},
   {id:9,name:"NieceNephew"},{id:10,name:"Friend"},{id:11,name:"Colleague"},{id:12,name:"Acquaintance"}]
   }
  

  
  async getFamilyDetails() {
    this.spinner.show();
   this.familyDetails =[];
     const response = await this.profileService.GetFamilyMemberDetails();
     if (response?.isSuccess){
       this.familyDetails = response.result?.familyMembers;
       this.familyDetails.forEach((item,index)=>{
         if(item.relationShipType!=null){
           item.relationShipType = this.RelationShipList.find(x=>x.id==item.relationShipType)?.id;
           item.relationShipTypeName = this.RelationShipList.find(x=>x.id==item.relationShipType)?.name;
         }
       })
    }
    this.spinner.hide();
   }

   async addMemebrInfo(){
    this.memberForm.value.memberId = this.memberForm.value.memberId ==""? 0 :this.memberForm.value.memberId;
    this.memberForm.value.relationShipType = parseInt(this.memberForm.value.relationShipType);
    const response = await this.userservice.ManageFamilyMemberDetails(this.memberForm.value);
    if (response?.isSuccess) {
      await this.getFamilyDetails();
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Details Saved',
        showConfirmButton: false,
        timer: 3000,
      });

    }
  }
title:string="";
  editRecords(data:any){
    this.title ="Update";
    this.isEdit = true;
    this.memberForm.patchValue(data);
  }

  cancel(){
    
    this.isEdit = false;
  }
}
