import { Component, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
// import { BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.css']
})


export class AgentDashboardComponent {
  ShowMenu: boolean = false;
  selectedTab: string = 'tab1';
  toggle: any = true;
  toggle1: any = true;
  toggle2: any = true;
  toggle3: any = true;
  training: boolean = false;

  selectTab(tab: string) {
    this.selectedTab = tab;
    alert(this.selectedTab);
  }

  dashboard: boolean = true;
  customer: boolean = false;
  profile: boolean = false;

  showComponent(component: string) {
    this.dashboard = component === 'dashboard';
    this.customer = component === 'customer';
    this.profile = component === 'profile';
    this.training = component === 'training';
  }

  tabClick(index: any | number){
    if(index==0){
      this.toggle= false;
      this.toggle1= true;
      this.toggle2= true;
      this.toggle3= true;
    }else if(index==1){
      this.toggle= true;
      this.toggle1= false;
      this.toggle2= true;
      this.toggle3= true;
    }else if(index==2){
      this.toggle= true;
      this.toggle1= true;
      this.toggle2= false;
      this.toggle3= true;
    }else if(index==3){
      this.toggle= true;
      this.toggle1= true;
      this.toggle2= true;
      this.toggle3= false;
    }
  }

  ngOnInit() {
    // this.headerWidth =this.topnav1.nativeElement.width;
          this.ShowMenu = true;
  }


  public doughnutChartLabels: string[] = [
    'Download Sales',
  ];
  public doughnutChartData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      { data: [50, 50, 10] ,
      }
    ],
  };
  public doughnutChartType: ChartType = 'doughnut';

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40],
        label: 'Series A',
        backgroundColor: 'rgba(148,159,177,0.2)',
        // borderColor: 'rgba(148,159,177,1)',

    pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
    ],
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      y: {
        position: 'left',
      },
      y1: {
        position: 'right',
        grid: {
          color: 'rgba(255,0,0,0.3)',
        },
        ticks: {
          color: 'red',
        },
      },
    },
  };

  public lineChartType: ChartType = 'line';


  // @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  // };
  // public barChartLabels: string[] = ['Label 1', 'Label 2', 'Label 3', 'Label 4'];
  // public barChartType: ChartType = 'line'; // Define chartType as a string
  // public barChartLegend = true;
  // public barChartData: ChartDataset[] = [
  //   { data: [65, 59, 80, 81], label: 'Series A' },
  // ];
}
