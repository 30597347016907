import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { PasswordType } from '../Models/login.component.model';
import {SignupService} from '../Services/signup.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  mobileNo: string = '';
  @ViewChild('phoneNumber') phoneNumbers: any;
  @ViewChild('otp') otp: any;
  currentYear : number = new Date().getFullYear();
  forgotForm: FormGroup;
  isForgot: boolean = true;
  isOtp: boolean = false;
  isReset: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  resetPassForm: FormGroup;
  passwordShow1: boolean = false;
  passwordShow2: boolean = false;
  public passwordType: string = PasswordType.pwd;
  enterOTP = new FormControl("", [Validators.required]);

  // Timer Initialization
  isResendEnabled: boolean = true;
  timerSpanValue: boolean = false;
  timerValue: number = 60; // Start at 30 seconds
  timer: any; // To store the interval reference
  randOTP:string ="";

  constructor(private fb: FormBuilder,private loginService: LoginService, private router: Router,
    private signupService : SignupService) {
    this.forgotForm = this.fb.group({
      "PhoneNumber": ['', [Validators.required]],
      "otpNumber": ['', [Validators.required]],
    });
    this.resetPassForm =  this.fb.group({
      "PhoneNumber": ['', [Validators.required]],
      "password": [''],
      "password1": [''],
      "confirm_password": [''],
      "otpNumber":['']
    });
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
    this.startTimer();
  }

  //Generate RandomNo
 GenerateRandomNo()
{
    this.randOTP = "";
    let range = {min: 100000, max: 999999}
    let delta = range.max - range.min
    this.randOTP = Math.round(range.min + Math.random() * delta).toString();
}

  startTimer() {
    this.timerSpanValue = true;
    this.timer = setInterval(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
      } else {
        this.stopTimer();
        
      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  }

  stopTimer() {
    clearInterval(this.timer);
  }
  onCountryChangeNew(e: any) {
    this.forgotForm?.get('PhoneNumber')?.setValue("");
  }

  ShowPassword1() {
    if (!this.passwordShow1) {
      this.passwordType = PasswordType.txt;
      this.passwordShow1 = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow1 = false;
    }
  }

  ShowPassword2() {
    if (!this.passwordShow2) {
      this.passwordType = PasswordType.txt;
      this.passwordShow2 = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow2 = false;
    }
  }

 async sendOtp() {
  this.mobileNo = this.phoneNumbers.phoneNumber;
  this.GenerateRandomNo();
  let request = { phoneNumber: this.mobileNo ,OTP :this.randOTP  }
    const response = await this.signupService.SendOtp(request);
    if (response?.code == 1) {
      this.isOtp = !this.isOtp
      this.isForgot = !this.isForgot;
    }
    else{
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: response?.message??'Phone number not exists',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
         confirmButton: "error_btn",
        }
      });
    }


    

  }
  async resetPassword() {
    let request = { OTP:  this.otp.nativeElement.value ,GeneratedOTP:this.randOTP,PhoneNumber:this.mobileNo}
    const response = await this.signupService.ValidateOTP(request);
    if (response?.code == 1) {
      this.isReset = !this.isReset
      this.isOtp = !this.isOtp
    }
    else{
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Invalid OTP',
        showConfirmButton: false,
        timer: 3000,
      });
    }

  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
         return false;
      }
      return true;
  
  }

  async passwordReset() {
 if(this.resetPassForm.value.password1 && this.resetPassForm.value.confirm_password){
  if(this.resetPassForm.value.password1 !==this.resetPassForm.value.confirm_password ){
    swal.fire({
      position: 'center',
      icon: 'error',
      title: 'New password and confrm password does not match',
      showConfirmButton: false,
      timer: 3000,
    });
   // return false;
  }
 else{
  if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
    let phone = this.resetPassForm.get('PhoneNumber')?.value;
    this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
  }
   this.resetPassForm.get('password')?.setValue(window.btoa(this.resetPassForm.get('password1')?.value)); 
  const response = await this.loginService.ForgotPasword(this.resetPassForm.value);
  if (response?.isSuccess && response?.result && response?.result.code == 1) {
    swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Password Reset Successful!!',
      showConfirmButton: false,
      timer: 3000,
    });
    this.router.navigate(['/login']);
  }
  else
        {
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            title: response?.result?.message??"Something went wrong!",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            }
          });
        }
 }
}
  }
}
