import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InsuranceService } from 'src/app/Services/insurance.service';

@Component({
  selector: 'app-property-policy',
  templateUrl: './property-policy.component.html',
  styleUrls: ['./property-policy.component.css']
})
export class PropertyPolicyComponent {
  ageList:any[];
  isCoverage:any;
  isBuilding:boolean=false;
  isContant:boolean=false;
  isStock:boolean=false;
  building:any;
  content:any;
  stock:any;
  requestData:any;
  quoteList:any;
  structureSumMin:number = 0;
  structureSumMax:number = 0;
  structureSumCur:number = 0;
  contentSumMin:number = 0;
  contentSumMax:number = 0;
  contentSumCur:number = 0;
  stockSumMin:number = 0;
  stockSumMax:number = 0;
  stockSumCur:number = 0;
  constructor(private router:Router, private insuranceSerrvice : InsuranceService){
  }
  isQuote:boolean=true;
  isDetail:boolean=false;
  checked:boolean=false;
  isDetailsPopup:boolean=false;
check()
{
 if(!this.checked)
 {
  this.checked=true;
 }
 else{
  this.checked=false;
 }
 
}
details(){

  this.isDetail=true;
  this.isQuote=false;
}
close()
{
  
this.isDetailsPopup=false;
}
tabs:string='';
nextTab(val:string)
{
  this.tabs=val;
}
getSummary()
{
  this.router.navigate(['/property-summary'])
}
async getAge()
{
  this.ageList = [];
  for (var i = 18; i <= 100; i++) {
    this.ageList.push(i)
  }
}
PopUPCoverage:boolean=false;
CoverPopuo(){
  
this.PopUPCoverage=true;
}
closeModal()
{
  this.PopUPCoverage=false;
  
}
coversVal:string='inbuilt';
Covertabs(val:string){
this.coversVal=val;
}

async getQuotes(data:any){
  if(data?.cover?.includes('structure'))
  {
    this.isBuilding=true;
  }  
  if(data?.cover?.includes('content'))
  {
    this.isContant=true;
  }
  if(data?.cover?.includes('stock')){
    this.isStock=true;
  }

  this.requestData = data;
  debugger;
  let response = await this.insuranceSerrvice.GetPropertyQuote(data);
  if (response?.isSuccess) {
      this.quoteList = response.result;
    }
  // else
  // {    
  //   this.quoteList =[
  //     {
  //         "quoteId": "",
  //         "insuranceCompany": 14,
  //         "logo": "https://uatapi.bimag.in/portalapi/api/lookUpInfo/GetInsuranceLogoById?InsuranceId=14",
  //         "planName": "Asia Travel",
  //         "totalPremiumAmount": "309"
  //     },
  //     {
  //       "quoteId": "",
  //       "insuranceCompany": 2,
  //       "logo": "https://uatapi.bimag.in/portalapi/api/lookUpInfo/GetInsuranceLogoById?InsuranceId=14",
  //       "planName": "Asia Travel",
  //       "totalPremiumAmount": "1500"
  //   }
  // ];
  // }
}

showLocation():string{
  let location="test 123456";
  if(this.requestData?.insuranceFor == "home"){
    if(this.requestData?.propertyType == "owned"){
      location = this.requestData?.homeLocation?.ownerCity + ' ' + this.requestData?.homeLocation?.HomeCode;
    }
    else
    {
      location = this.requestData?.homeLocationrent?.rentCity + ' ' + this.requestData?.homeLocationrent?.rentCode;
    }
  }
  else if(this.requestData?.insuranceFor == "shop"){
    location = this.requestData?.location?.Citys + ' ' + this.requestData?.location?.pinCode;
  }
  else if(this.requestData?.insuranceFor == "office"){
    location = this.requestData?.officeLocation?.officeCity + ' ' + this.requestData?.officeLocation?.officeCode;
  }
  return location;
}

showTotalValue():string{
  let totalValue = 0;

  if(this.requestData?.insuranceFor == "home"){
    if(this.requestData?.HomeDetails?.Value_of_Building != null && this.requestData?.HomeDetails?.Value_of_Building != undefined && this.requestData?.HomeDetails?.Value_of_Building != ""){
      this.structureSumCur =  parseInt(this.requestData?.HomeDetails?.Value_of_Building);
      this.structureSumMax = this.structureSumCur;
      totalValue = totalValue + this.structureSumCur;
      
    }
    if(this.requestData?.HomeDetails?.contentValue != null && this.requestData?.HomeDetails?.contentValue != undefined && this.requestData?.HomeDetails?.contentValue != ""){
      this.contentSumCur =  parseInt(this.requestData?.HomeDetails?.contentValue);
      this.contentSumMax = this.contentSumCur;
      totalValue = totalValue + this.contentSumCur;
    }
  }
  else if(this.requestData?.insuranceFor == "shop"){
    if(this.requestData?.shopDetail?.buildingValue != null && this.requestData?.shopDetail?.buildingValue != undefined && this.requestData?.shopDetail?.buildingValue != ""){
      this.structureSumCur =  parseInt(this.requestData?.shopDetail?.buildingValue);
      this.structureSumMax = this.structureSumCur;
      totalValue = totalValue + this.structureSumCur;
    }
    if(this.requestData?.shopDetail?.contentVal != null && this.requestData?.shopDetail?.contentVal != undefined && this.requestData?.shopDetail?.contentVal != ""){
      this.contentSumCur =  parseInt(this.requestData?.shopDetail?.contentVal);
      this.contentSumMax = this.contentSumCur;
      totalValue = totalValue + this.contentSumCur;
    }
    if(this.requestData?.shopDetail?.stokVal != null && this.requestData?.shopDetail?.stokVal != undefined && this.requestData?.shopDetail?.stokVal != ""){
      this.stockSumCur =  parseInt(this.requestData?.shopDetail?.stokVal);
      this.stockSumMax = this.stockSumCur;
      totalValue = totalValue + this.stockSumCur;
    }
  }
  else if(this.requestData?.insuranceFor == "office"){
    if(this.requestData?.officeDetail?.buildValue != null && this.requestData?.officeDetail?.buildValue != undefined && this.requestData?.officeDetail?.buildValue != ""){
      this.structureSumCur =  parseInt(this.requestData?.officeDetail?.buildValue);
      this.structureSumMax = this.structureSumCur;
      totalValue = totalValue + this.structureSumCur;
    }
    if(this.requestData?.officeDetail?.ContentVal != null && this.requestData?.officeDetail?.ContentVal != undefined && this.requestData?.officeDetail?.ContentVal != ""){
      this.contentSumCur =  parseInt(this.requestData?.officeDetail?.ContentVal);
      this.contentSumMax = this.contentSumCur;
      totalValue = totalValue + this.contentSumCur;
    }
    if(this.requestData?.officeDetail?.stockVal != null && this.requestData?.officeDetail?.stockVal != undefined && this.requestData?.officeDetail?.stockVal != ""){
      this.stockSumCur =  parseInt(this.requestData?.officeDetail?.stockVal);
      this.stockSumMax = this.stockSumCur;
      totalValue = totalValue + this.stockSumCur;
    }
  }
  return totalValue.toString();
}

getDetails(){

}
}

