//import { Component } from '@angular/core';
import { Component,Input,ElementRef ,OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http'; // Import HttpCl
@Component({
  selector: 'app-reliance-general-payment',
  templateUrl: './reliance-general-payment.component.html',
  styleUrls: ['./reliance-general-payment.component.css']
})
export class RelianceGeneralPaymentComponent implements OnInit,AfterViewInit {

  @Input() RelianceDetails :any;
  @ViewChild('file', { read: ElementRef }) myDiv: ElementRef<HTMLElement>;
  // @ViewChild('myDiv') myDiv: ElementRef;
  constructor(private route: ActivatedRoute,private http: HttpClient){
  
  } 
  ngAfterViewInit() {
    this.submitForm();
}

  submitForm() {
    const form = document.getElementById('PostForm') as HTMLFormElement;
    console.log(form);
     form.submit();
  }
  
  ngOnInit() {
  
  }
}