import { Component } from '@angular/core';

@Component({
  selector: 'app-pension-plan',
  templateUrl: './pension-plan.component.html',
  styleUrls: ['./pension-plan.component.css']
})
export class PensionPlanComponent {

}
