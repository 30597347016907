            <app-navbar></app-navbar>
<div class="content-box">
    <div class="container">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Commercial Vehicle Insurance </h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">A Commercial Vehicle Insurance is a customized motor insurance policy to cover for damages and losses caused to or by a commercial vehicle and the respective owner-driver. This could include damages and losses in situations such as accidents, collisions, natural calamities, fires, etc. Commercial vehicles are an important mode of transportation and are heavy-duty vehicles, which are used on an everyday basis to Carry out tasks. These heavy-duty commercial vehicles have an important contribution to bulk transportation for many businesses. However, such heavy-duty commercial vehicles are always prone to road accidents, unpredicted damages while driving and natural calamities. Commercial Vehicle Insurance covers Goods Carrying Vehicles, Passenger Carrying Vehicles, Taxies, Miscellaneous class of vehicles such as Tractors, Cranes and Trailers, Trade Road Risk Policy, Trade Road Transit Policy and Trade Internal Risk Policy</p>
                <p class="font-body">Therefore, commercial vehicle insurance is mandatory for all businesses to buy a commercial vehicle insurance for their vehicles, such as for auto-rickshaws, cabs, school buses, tractors, commercial vans and trucks, amongst others.</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why do you need a Commercial Vehicle Insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If your business owns one or many vehicles, it’s essential to get a commercial vehicle insurance that will financially protect and cover your business from any losses and damages caused to and by your vehicle (s) and the people using it.
                    </p>
                    <p class="font-body">It is beneficial for the smooth functioning of your running business against any adverse situations that a may cause a huge financial drain.</p>
                    <p class="font-body">Buying commercial vehicle insurance online is a quick, easy and hassle-free process. Therefore, before you zero down the policy, compare commercial vehicie insurance quates and purchase a policy that fit your budget.</p>
                    <p class="font-body">As a mandate by law, it is compulsory to at least have a Liability Only policy in place, that protects third-parties against any damages and losses that could be caused by your commercial vehicle.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Key Features of Commercial Vehicle Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Commercial Vehicle Insurance provides coverage for a variety of situations
                    </p>
                    <p class="font-body">Claims can be made quickly and simply.</p>
                    <p class="font-body">Various classes of commercial vehicles can be insured including trailers, goods carrying vehicles (both private and public carrier), passenger carrying vehicles and special types of vehicles (excavators, shovels, mobile rigs, grabs, tractors etc.)</p>
                    <p class="font-body">Apersonal accident cover for the driver and the owner.</p>
                    <p class="font-body">The commercial vehicle insurance plan provides cover for the liabilities in case of any injury or an accident.</p>
                    <p class="font-body">Having a commercial vehicle insurance plan will provide monetary compensation.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of Commercial Vehicle Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Based on the requirement of your commercial vehicle, there are two types of commercial vehicle insurance policies as mentioned below: </p>
                    <p class="font-body">Comprehensive Commercial Vehicle Insurance:</p>
                    <p class="font-body">Comprehensive insurance provides complete protection to your commercial vehicle under any adverse circumstances, which may lead to an unwanted financial burden. Secure your commercial vehicle from adverse circumstances such as damage, fire, theft, robbery, flood, earthquake etc. Besides, comprehensive vehicle insurance policy provides cover to third-party property or person in case of loss/damage.</p>
                    <p class="font-body">Third-party Liability Commercial Vehicle Insurance</p>
                    <p class="font-body">Commercial vehicle insurance third-party liability provides protection to any third-party property, any third person or the vehicle, which may lead to the demise, bodily injury or any other damage. It is purchased for protection against the claims of another. Third-party offers coverage against claims of damages and losses incurred by a driver who is not the insured, the principal, and is therefore not covered under the insurance policy. The driver who caused damages is the third party.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What's covered in a Commercial Vehicle Insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Commercial vehicle insurance policy compensates for personal, medical, and financial losses that are incurred due to damage resulting from theft, accidents, and natural disasters.</p>
                    <p class="font-body">Here is a list of the benefits that commercial vehicle insurance policy offers:</p>
                    <p class="font-body">Accidents: Damages caused to the commercial vehicle in case of an accident.</p>
                    <p class="font-body">Theft : Loss or damage to the commercial vehicle due to theft.</p>
                    <p class="font-body">Fire, self-ignition, explosion or lightning : Damages caused to the commercial vehicle due to a fire self-ignition, explosion or lightning</p>
                    <p class="font-body">Natural Disasters: Damages caused to the commercial vehicle due to any natural calamity.</p>
                    <p class="font-body">Personal Accident :If there is an accident of the commercial vehicle, leading to an injury or death of the driver. Also  includes permanent total disability of the paid driver and the owner,</p>
                    <p class="font-body">Third Party Losses: Commercial vehicle third-party insurance provides cover in case of an accident involving your insured commercial vehicle and a third-party vehicle. It will cover the loss or damage caused ta the third-party property, vehicle, badily injuries and death.</p>
                    <p class="font-body">Towing Disabled Vehicles:  Any damages caused to by the commercial vehicle to the vehicle being towed.</p>
                    <p class="font-body">Theft, housebreaking, and burglary: Losses arrises due to Theft burglary of your commercial vehicle.</p>
                    <p class="font-body">Flood, hurricane, tempest, storm etc:  Any losses arrises due to Flood, hurricane, tempest, storm etc</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Add-ons covers available with Commercial Vehicle Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">You can also enhance your scope of commercial vehicle insurance coverage by opting an add-on covers on the payment of an additional premium. While buying commercial vehicle insurance comparison the add-on covers will help you to make an informed decision.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>The following are some add-on covers that you may consider while buying commercial vehicle insurance online:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Consumable Cover: A consumable cover will give your commercial vehicle protection beyond just the usual. It covers the cost for your vehicle's bits and pieces, like the nuts and bolts, screws, engine oil, and grease in case of an accident.</p>
                    <p class="font-body">Parts Depreciation Protect: Your vehicle and its parts can depreciate in value over time because of its wear and tear and this amount is usually deducted from any claim. This add-on ensures that this depreciation will be covered on any replaced vehicle parts (like rubber or fiberglass parts) in the case of an accident.</p>
                    <p class="font-body">Legal liability: covers to the paid driver/conductor/non-fare paying passengers.</p>
                    <p class="font-body">Engine and Gear Box Protect: Your vehicle's engine or gearbox get damaged in an accident, due to things like water regression or leakage of lubricating oil. This add-on will help cover any consequential damages caused after the accident (but because of it), for example engine damage due to hydrostatic loss, which is not covered in a standard policy.</p>
                    <p class="font-body">Personal Accident cover: Personal accident benefit to the owner/driver and any other named person apart from an employee and vehicle cleaner/ conductor/ paid driver.</p>
                    <p class="font-body">Tawing Cover: We all need a bit of help sometimes! Whenever you face a breakdown of your vehicle on the road, due to an accident, flat tires, malfunctioning battery, or more, with our breakdown assistance add-on, you will have the benefit of getting assistance 24x7.</p>
                    <p class="font-body">Loss of Revenue:  For many, vehicles are essential for work. This add-on helps to compensate you for any loss of income that could occur while your commercial vehicle is not available when it’s being repaired due to damages.</p>
                    <p class="font-body">EMI Protection Cover  If your vehicle is taken on a loan and is damaged by an accident, and it’s sitting in a garage for repair that might mean a loss for your business. Through this add-on, you will be paid regular EMIs to payable to the financier of the vehicle recorded in our books.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What is not covered in Commercial Vehicle Insurance ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It is equally important to know what’s not covered in your commercial vehicle insurance policy so that there are no surprises when you make a claim. Here are some cases where policy does not cover any claim arise: </p>
                    <p class="font-body">Own Damages for Third-Party Policy holder : In the case of a Third-Party Liability Only Policy, damages to own vehicle won’t be covered.</p>
                    <p class="font-body">Drunk Riding or without a Licence: The occurrence of an accident while driving with an invalid driving license or under the consumption of alcohol.</p>
                    <p class="font-body">Contributory negligence :  Any damage caused due to contributory negligence of the driver (such as driving when there’s an existing flood)  </p>
                    <p class="font-body">Consequential Damages :  Any damage that is not a direct result of the accident/natural calamity i.e., consequential losses like loss of earnings, loss of market, etc.</p>
                    <p class="font-body">Wear and tear of the commercial vehicle: Any loss/ damages of wear and tear of commercial vehicle does not cover in this policy.</p>
                    <p class="font-body">Contractual liabilities are also not covered: Contractual liabilities are also not covered under this policy. </p>
                    <p class="font-body">Inactive policy: In case, the policy is not active at the time of accident ar loss.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of Commercial Vehicles Covered</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Passenger Carrying Vehicle Insurance</p>
                    <p class="font-body">A passenger carrying vehicle insurance is a type of commercial vehicle insurance  policy, designed to protect any commercial vehicles used for the purpose of transporting passengers from one place to another.</p>
                    <p class="font-body">This passenger carrying vehicle insurance protects the vehicle from damages and losses that could arise in case of an accident, natural calamity or fire, amongst other unforeseen events. The most common types of passenger carrying vehicle insurance policies include a bus insurance, taxi/cab insurance etc.</p>
                    <p class="font-body mt-2">Types of Passenger Carrying Vehicles Covered:</p>
                    <p class="font-body">Buses: Buses such as school buses, private tour buses and others used for the purpose of transporting passengers from one place to another are covered under a passenger carrying vehicle insurance policy.</p>
                    <p class="font-body">Auto Rickshaws: All auto rickshaws used for commercial purposes; transporting people from one place to another too can be covered under this policy.</p>
                    <p class="font-body">Taxis, Cabs and Commercial Cars: Cabs and commercial cars such as your daily uber, ola and other private cars used for commercial, public transportation can be covered under a passenger carrying vehicle insurance.</p>
                    <p class="font-body">Vans: Vans such as school vans and private tour minibuses used for commercial purposes too are covered under this policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why you should buy a Passenger Carrying Vehicle Insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Protection from Unforeseen Losses: Whether it is due to a natural calamity, accident, collision or even a fire; a passenger carrying vehicle insurance helps compensate for these uncertain losses, and thereby protects your business from being liable to them.</p>
                    <p class="font-body">Passenger Protection: While buying a passenger vehicle, one can also opt to cover for their passengers; thus, being a responsible business that cares for not only oneself but for all its stakeholders involved. </p>
                    <p class="font-body">Covers for Owner-Driver: Insuring your commercial vehicle with a passenger carrying vehicle insurance does not only protect the vehicle but, also covers for the owner-driver. </p>
                    <p class="font-body">Comply with the Law: As per the Motor Vehicle Act it is compulsory for all vehicles including ones used for commercial purposes to be insured with at least a basic, third-party motor insurance to help protect one from losses and damages caused to any third-party; such as a person, property or vehicle.</p>
                    <p class="font-body">Passenger Carrying Vehicle Insurance Features</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>key features of passenger carrying vehicle insurance:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The policy premium is decided based on the type of vehicle, cubic capacity, the zone of registration, Licensed Carrying Capacity of the vehicle, Gross Vehicle Weight etc.</p>
                    <p class="font-body">This insurance can be bought for various passenger-carrying vehicles such as bus, taxi, passenger-carrying vans etc.</p>
                    <p class="font-body">The policy covers the insured vehicle for the damages due to man-made and natural calamities.</p>
                    <p class="font-body">No Claim Bonus to be earned for every claim-free year.</p>
                    <p class="font-body">Some prospective policy holders will get discount offers on premium deepening on the mode of buying.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What's covered in a Passenger Carrying Vehicle Insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Passenger Carrying Vehicle Insurance covers following:</p>
                    <p class="font-body">Accidental Damages : The policy covers the vehicle in case of an accident leading to damages or injuries.</p>
                    <p class="font-body">Natural Disasters : Natural calamities may take a toll over the vehicle. Do not worry, it is covered as well..</p>
                    <p class="font-body">Fire :Total or partial loss of the vehicle due to fire or explosion is covered.</p>
                    <p class="font-body">Third Party Losses : Covers for losses and damages caused to a third-party vehicle, property or person due to the passenger carrying vehicle, in the case of an accident or collision.</p>
                    <p class="font-body">Personal Accident : Covers for any personal injuries or death of the owner-driver of the the passenger carrying vehicle.</p>
                    <p class="font-body">Theft : Any damages and losses caused to the passenger carrying vehicle due to theft.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What Passenger Carrying Vehicle Insurance does not cover?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Passenger carrying vehicle insurance does not cover the following:</p>
                    <p class="font-body">Own Damages for a Third-Party Policy Holder: If you've purchased only a Third-Party Passenger Vehicle Insurance Policy, then own damages and losses won’t be covered.</p>
                    <p class="font-body">Illegal Driving: If you drive your vehicle under the influence of alcohol or drugs or you do not possess an authorized driving license then your insurance plan cannot not help you. </p>
                    <p class="font-body">Electrical Breakdown: Any electrical breakdown is not covered under your passenger-carrying vehicle insurance policy.</p>
                    <p class="font-body">Contributory negligence :  Any damage caused due to contributory negligence of the driver (such as driving when there’s an existing flood) </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Goods Carrying Vehicle Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A goods carrying vehicle insurance is a type of commercial vehicle insurance , designed to protect commercial vehicles used for the purpose of transporting goods from one place to another. A Goods carrying vehicle insurance covers the vehicles against loss or damages due to man-made and natural calamities such as theft, fire, explosion, flood, earthquake, landslide, accidental damages, persanal accident and third-party liability.The goods carrying vehicle insurance can be bought for vehicles such as trucks, trailers, tractor-trailer, etc.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of Goods Carrying Vehicles</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">There are various types of goods carrying vehicles, basis the nature of different businesses in India. Some of the common examples of goods carrying vehicles includes Three-Wheel Vehicles, Trucks, Tempos etc.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Features of Goods Carrying Vehicle Insurance:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Listed below are some of the key features of goods carrying vehicle insurance:</p>
                    <p class="font-body">The policy offers both third-party liability as well as own-damage cover.</p>
                    <p class="font-body">Cashless claim facility to be availed across the country at registered garages</p>
                    <p class="font-body">By opting far various add-on covers you can enhance your benefits. </p>
                    <p class="font-body">No Claim Bonus to be earned for every claim-free year up to a defined percentage based on the policy terms.</p>
                    <p class="font-body">It covers natural calamities causing a threat to the insured vehicles such as flood, storm, earthquake, landslide etc.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What a Goods Carrying Vehicle Insurance covers ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Accidents : Damages and losses caused to the goods carrying vehicle due to an accident.</p>
                    <p class="font-body">Theft : Any damages and losses caused to the goods carrying vehicle in the case of a theft.</p>
                    <p class="font-body">Fire : Any damages or losses caused to the goods carrying vehicle in an unfortunate case of fire.</p>
                    <p class="font-body">Natural Disasters : Damage, loss, or due to natural calamities are covered. For example, damages and losses caused to your goods carrying vehicle due to floods, earthquakes and other natural calamities.</p>
                    <p class="font-body">Personal Accident : Covers for any personal injuries or death of the owner-driver of the insured goods carrying vehicle.</p>
                    <p class="font-body">Third Party Losses : The policy covers the insured vehicle for any liability arising out of accidental injury, bodily damage or death of a third party.</p>
                    <p class="font-body">Towing Disabled Vehicles : Covers for any damages caused to your goods carrying vehicle in cases where it's being towed.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What’s not covered under Goods Carrying Insurance Policy ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Goods Carrying Vehicle Insurance does not cover the following:</p>
                    <p class="font-body">Own Damages for a Third-Party Policy Holder: If you've purchased only a Third-Party Passenger Vehicle Insurance Policy, then own damages and losses won’t be covered.</p>
                    <p class="font-body">Illegal Driving: If you drive your vehicle under the influence of alcohol or drugs or you do not possess an authorized driving license then your insurance plan cannot not help you. </p>
                    <p class="font-body">Electrical Breakdown: Any electrical breakdown is not covered under your Goods Carrying Vehicle Insurance policy.</p>
                    <p class="font-body">Contributory negligence :  Any damage caused due to contributory negligence of the driver (such as driving when there’s an existing flood)  </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Miscellaneous & Special Vehicles Insurance </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Apart from cabs, taxis, trucks and buses, there are many other vehicles often used for and by business. Some of these may include special vehicles used for farming, mining and construction, among others.</p>
                    <p class="font-body">A commercial vehicle insurance for the same will protect the insured vehicle from any damages and losses caused to and by the vehicle and its respective owner-driver.</p>
                    <p class="font-body">Given the both the investment and size of these vehicles, it’s always a safer choice to insure the same with a commercial vehicle insurance. This way, the business or sole proprietor will lessen its risk and protect itself from any financial losses that could occur due to unfortunate mishaps.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What Are The Factors To Be Considered While Buying/Renewing Commercial Vehicle Insurance ? </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">While renewing or buying a commercial vehicle insurance policy, you should consider the following aspects:</p>
                    <p class="font-body">Coverage: It is primary factor to consider that policy have adequate coverage when buying a commercial vehicle insurance policy. A comprehensive commercial vehicle insurance policy is recommended for those who wish to safeguard their business and want to enjoy and have peace of mind.</p>
                    <p class="font-body">No Claim Bonus:  At the time of renewal, the policyholder should check with the insurer for any No Claim Bonus available on the motor insurance premium.</p>
                    <p class="font-body">Insured Declared Value: Higher the IDV ,higher the Premium payble .One of the most important things that one needs to consider at the time of renewal/buying is choosing the correct IDV in your motor insurance policy.In case total damage or theft your insurer will compensate amount equal to the IDV of vehicle.</p>
                    <p class="font-body">Voluntary Deductibles: Opt for deductibles only when it is possible to pay a portion of the claim from your pocket. Always check for the deductibles and clauses at the time of renewal. Opt-out for deductibles if it is not working well for you.</p>
                    <p class="font-body">Add-on Benefits: Select add-on cavers to enhance the overall policy cammercial vehicle insurance coverage.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
