import { Component } from '@angular/core';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent {

  isEditPolicy: boolean = false;

  editPolicy(){
    this.isEditPolicy =! this.isEditPolicy;
  }
  
}
