<div class="container" style="background-color: #f3f3f4;">
    <div class="row">
        <div class="col-md-12 mt-4 ">
            <div class="card crd p-3">
                <div class="row ">
                    <div class="col-md-1 ">
                        <p><img src="../../../assets/svgIcon/arrow.svg" alt="">&nbsp;&nbsp;&nbsp;</p>
                    </div>
                    <div class="col-md-3">
                        <p class="p-tg">Vehicle Category</p>
                        <span>PCV</span>
                    </div>
                    <div class="col-md-4 ">
                        <p class="p-tg">Manufacturer/Model</p>
                        <span>TATA/Altroz</span>
                    </div>
                    <div class="col-md-3">
                        <p class="p-tg">Year</p>
                        <span>2014</span>
                    </div>
                    <div class="col-md-1  ">
                        <p><img class="mrg" src="../../../assets/svgIcon/pencile.svg" alt="">&nbsp;&nbsp;&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-md-4">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-6">
                        <img src="../../../assets/images/united.png" width="100%" alt="">
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4"><span>
                                    Plan:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">Comprehensive</label>
                            </div>
                            <div class="col-md-4"><span>
                                    IDV:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">₹15,186</label>
                            </div>
                            <div class="col-md-5"><span>
                                    Tenure:
                                </span></div>
                            <div class="col-md-7">
                                <label for="">1 Year</label>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-12">
                        <hr>
                        <h5 class="h">Premium Breakup</h5>
                        <hr>
                    </div>
                    <div class="col-md-12">

                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <span>Basic Own Damage Premium</span>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <span>₹10,252</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-9">
                                <span style="color:black"><b>Net Premium</b></span>
                            </div>
                            <div class="col-md-3">
                                <span style="color:black"><b>₹12,250</b></span>
                            </div>
                            <div class="col-md-9 mt-2 hf-cl">
                                <span>GST (18%)</span>
                            </div>
                            <div class="col-md-3 mt-2 hf-cl">
                                <span>+ ₹699</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <h5 class="h" style="color:black ">Total Premium</h5>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <h5 class="h" style="color:black">₹12,250</h5>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card p-5">
                <div class="row">
                    <div class="col-md-2 tab col2">
                        <div class="card crd  " [ngClass]="{'selected':tabs==''}">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs==''}">Proposer Details</p>
                        </div>
                    </div>
                    <div class="col-md-2 tab col2">
                        <div class="card crd  " [ngClass]="{'selected':tabs=='Address'}">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs=='Address'}">Address Details</p>
                        </div>
                    </div>
                    <div class="col-md-2 tab col2">
                        <div class="card crd  " [ngClass]="{'selected':tabs=='Nominee'}">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs=='Nominee'}">Nominee Details</p>
                        </div>
                    </div>
                    <div class="col-md-2 tab col2">
                        <div class="card crd  " [ngClass]="{'selected':tabs=='Vehicle'}">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs=='Vehicle'}">Vehicle Details</p>
                        </div>
                    </div>
                    <div class="col-md-2 tab col2">
                        <div class="card  " [ngClass]="{'selected':tabs=='Policy'}">
                            <p class="main mt-2" [ngClass]="{'p-cl':tabs=='Policy'}">Existing Policy Details</p>
                        </div>
                    </div>

                </div>
                <hr style="    position: relative;
                bottom: 11px;">
                <div class="row mt-5">
                    <div class="col-md-12">
                        <div class="content">
                            <div *ngIf="tabs==''">
                                <div class="row">


                                    <div class="col-md-12 mt-2">
                                        <form class="form" [formGroup]="proposerPublicSave">
                                            <div class="row">

                                                <div class="col-md-6 mt-2">
                                                    <label for="">PAN Card Number</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        placeholder="User PAN Number" formControlName="pan_card">
                                                </div>
                                                <div class="col-md-6 mt-2">
                                                    <label for="">Date of Birth</label>
                                                    <input type="date" name="" id="" class="form-control"
                                                        placeholder="DD-MM-YYYY" formControlName="DOB">
                                                </div>
                                                <div class="col-md-6 mt-2">
                                                    <label for="">Organization Name</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        placeholder="Enter Company Name" formControlName="organization">
                                                </div>

                                                <div class="col-md-6 mt-2">
                                                    <label for="">Email ID</label>
                                                    <input type="email" name="" id="" class="form-control"
                                                        placeholder="User Email Address" formControlName="email">
                                                </div>

                                                <div class="col-md-6 mt-2">
                                                    <label for="">Mobile Number</label>
                                                    <input type="number" name="" id="" class="form-control"
                                                        placeholder="User Mobile Number" formControlName="mobile">
                                                </div>


                                                <div class="col-md-6 mt-2">
                                                    <label for="">GSTIN</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        placeholder="User GSTIN" formControlName="GSTIN">
                                                </div>
                                            </div>
                                            <button class="btn btn-primary mt-3 bt"
                                                (click)="nextTab('Address')">Next</button>
                                        </form>
                                    </div>
                                    <!-- <div class="col-md-12 mt-2" *ngIf="private">
                                        <form  class="form" [formGroup]="proposerPrivateSave">
                                            <div class="row">

                                                <div class="col-md-6 mt-2">
                                                    <label for="">Owners Name</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        placeholder="Enter Owners Name" formControlName="ownerName">
                                                </div>

                                                <div class="col-md-6 mt-2">
                                                    <label for="">Email ID</label>
                                                    <input type="email" name="" id="" class="form-control"
                                                        placeholder="User Email Address" formControlName="email1">
                                                </div>

                                                <div class="col-md-6 mt-2">
                                                    <label for="">Mobile Number</label>
                                                    <input type="number" name="" id="" class="form-control"
                                                        placeholder="User Mobile Number" formControlName="mobile1"
                                                        onkeypress="if(this.value.length=='10') return false">
                                                </div>
                                                <div class="col-md-6 mt-2">
                                                    <label for="">Date of Birth</label>
                                                    <input type="date" name="" id="" class="form-control"
                                                        placeholder="DD-MM-YYYY" formControlName="DOB1">
                                                </div>
                                                <div class="col-md-6 mt-2">
                                                    <label for="">PAN Card Number</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        placeholder="User PAN Number" formControlName="pan_card1">
                                                </div>
                                                <div class="col-md-6 mt-2">
                                                    <label for="">GSTIN</label>
                                                    <input type="text" name="" id="" class="form-control"
                                                        placeholder="User GSTIN" formControlName="GSTIN1">
                                                </div>
                                            </div>
                                            <button class="btn btn-primary bt mt-3" 
                                                (click)="nextTab('Address')" [disabled]="proposerPrivateSave.invalid">Next</button>
                                        </form>
                                    </div> -->

                                </div>
                            </div>

                            <div *ngIf="tabs=='Address'">
                                <form [formGroup]="addressSave">
                                    <div class="row">

                                        <div class="col-md-6 mt-2">
                                            <label for="">Flat Number/Street Address</label>
                                            <input type="text" name="" class="form-control" formControlName="address"
                                                placeholder="Flat Number/Street Address">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">PIN Code</label>
                                            <input type="number" name="" class="form-control" formControlName="pinCode"
                                                placeholder="Pin Code">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">State</label>
                                            <input type="text" name="" class="form-control" formControlName="state"
                                                placeholder="state">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">City</label>
                                            <input type="text" name="" class="form-control" formControlName="city"
                                                placeholder="City">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-9">
                                            <button class="btn  mt-3 btn-agentlog" (click)="nextTab('')">Back</button>
                                        </div>
                                        <div class="col-md-3">
                                            <button class="btn btn-primary mt-3" id="btn"
                                                (click)="nextTab('Nominee')">Next</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                            <div *ngIf="tabs=='Nominee'">
                                <form [formGroup]="nomineeSave">
                                    <div class="row">
                                        <div class="col-md-6 mt-2">
                                            <label for="">Nominee Full Name</label>
                                            <input type="text" name="" class="form-control" id=""
                                                formControlName="nomineeName">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">Relation </label>
                                            <select class="form-select" aria-label="Default select example"
                                                formControlName="relation">
                                                <option selected>Select Relationship</option>
                                                <option *ngFor="let relation of ralationship" value="{{relation}}">
                                                    {{relation}}
                                                </option>

                                            </select>
                                        </div>

                                        <div class="col-md-6 mt-2">
                                            <label for="">Age </label>
                                            <select class="form-select" formControlName="age">
                                                <option selected>Select Age</option>
                                                <option *ngFor="let age of ageList" value="{{age}}">{{age}}</option>

                                            </select>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <label for="">Gender</label>
                                            <div class="row">
                                                <div class="col-md-4 mt-2">

                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="gender"
                                                            value="male" formControlName="gender">
                                                        <span class="gn">Male </span>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mt-2">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="gender"
                                                            value="female" formControlName="gender">
                                                        <span class="gn">Female </span>
                                                    </div>

                                                </div>
                                                <div class="col-md-4 mt-2">
                                                  
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"  name="gender" value="other"
                                                        formControlName="gender">
                                                        <span class="gn">Other </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <ul class="mt-3">
                                                <li style="    position: relative;
                                            right: 15%;">
                                                    
                                                
                                                  
                                                </li>
                                            </ul> -->
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-9">
                                            <button class="btn  mt-3 btn-agentlog"
                                                (click)="nextTab('Address')">Back</button>
                                        </div>
                                        <div class="col-md-3">
                                            <button class="btn btn-primary mt-3" id="btn"
                                                (click)="nextTab('Vehicle')">Next</button>
                                        </div>
                                    </div>

                                </form>
                            </div>

                            <div *ngIf="tabs=='Vehicle'">
                                <form [formGroup]="vechileSave">
                                    <div class="row">
                                        <div class="col-md-6 mt-2">
                                            <label for="">Registration Number</label>
                                            <input type="text" name="" id="" class="form-control"
                                                formControlName="registration">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">Engine Number</label>
                                            <input type="text" name="" id="" class="form-control"
                                                formControlName="engine">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">Chassis Number</label>
                                            <input type="text" name="" id="" class="form-control"
                                                formControlName="chassis">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">Vehicle Registration Date</label>
                                            <input type="date" name="" id="" class="form-control"
                                                formControlName="registration_date">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-9">
                                            <button class="btn  mt-3 btn-agentlog"
                                                (click)="nextTab('Nominee') ">Back</button>
                                        </div>
                                        <div class="col-md-3">
                                            <button class="btn btn-primary mt-3" id="btn"
                                                (click)="nextTab('Policy')">Next</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div *ngIf="tabs=='Policy'">
                                <form [formGroup]="policySave">
                                    <div class="row">
                                        <div class="col-md-6 mt-2">
                                            <label for="">Existing Policy Company</label>
                                            <input type="text" name="" id="" class="form-control"
                                                formControlName="ex_policy_com">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">Existing Policy Type</label>
                                            <select class="form-select" aria-label="Default select example"
                                                formControlName="ex_policy_type">
                                                <option selected>Existing Policy</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">Existing Policy Number</label>
                                            <input type="number" name="" id="" class="form-control"
                                                formControlName="ex_policy_num">
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label for="">Existing Policy Exp. Date</label>
                                            <input type="date" name="" id="" class="form-control"
                                                formControlName="ex_policy_date">
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-md-9">
                                            <button class="btn  mt-3 btn-agentlog"
                                                (click)="nextTab('Vehicle')">Back</button>
                                        </div>
                                        <div class="col-md-3">
                                            <button class="btn btn-primary mt-3" id="btn"
                                                (click)="goTOsummary()">Next</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>