<div class="container">
    <div class="row mt-2" [ngClass]="{'d-none' : !isPropertyPage}">
        <div class="col-md-6">
            <img src="../../../assets/svgIcon/property-1.png" width="100%" height="100%" alt="">
        </div>
        <div class="col-md-6" style="text-align:center">
            <img src="../../../assets/images/newicons/prot.svg" width="70%" height="100%" alt="">
        </div>

        <div class="col-md-6" style="display: none;">
            <div class="row">
                <div class="col-md 12">
                    <h2 class="heading text-left">Get best Property insurance for you and your family.</h2>
                    <h5 class="gray"> Select Property Type
                    </h5>
                </div>
                <div class="col-md-12 mtg-top">
                    <div class="row">
                        <div class="col-md-4 c4" (click)="homePopup()">
                            <div class="card">
                                <img src="../../../assets/svgIcon/p-home.svg" class="h-img" width="100%" height="100%"
                                    alt="">
                            </div>
                        </div>
                        <div class="col-md-4 c4" (click)="shopPopup()">
                            <div class="card">
                                <img src="../../../assets/svgIcon/shop.svg" class="h-img" width="100%" height="100%"
                                    alt="">
                            </div>
                        </div>
                        <div class="col-md-4 c4 " (click)="officePopup()">
                            <div class="card">
                                <img src="../../../assets/svgIcon/office.svg" class="h-img" width="100%" height="100%"
                                    alt="">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="isHomePopup">
        <div class="popup2 col-md-7 col-lg-3 col-11" style=" overflow-x: unset;">
            <div class="modal-header tooltip_">
                <h4 class="modal-title">
                    <h4 class="">Home Insurance &nbsp;&nbsp;&nbsp;<img src="../../../assets/svgIcon/1-5.svg" alt=""
                            *ngIf="first">
                        <img src="../../../assets/svgIcon/2-5.svg" *ngIf="second" alt="">
                        <img src="../../../assets/svgIcon/3-5.svg" *ngIf="third" alt="">
                        <img src="../../../assets/svgIcon/4-5.svg" *ngIf="four" alt="">
                        <img src="../../../assets/svgIcon/5-5.svg" *ngIf="five" alt="">
                    </h4>
                </h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
                    <span></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row " *ngIf="first">
                    <div class="row">
                        <div class="col-md-12 mt-5">
                            <p class="p-wh"> Select your home type</p>
                        </div>
                        <div class="col-md-12 ">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card p-2 text-center crd" [ngClass]="{'selected':homeType=='owned'}"
                                        (click)="home('owned')">
                                        <p class="p-size p-crd">Owned</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card p-2 text-center crd" [ngClass]="{'selected':homeType=='rented'}"
                                        (click)="home('rented')">
                                        <p class="p-size p-crd">Rented</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="expeiry">
                        <div class="col-md-12 mt-3">
                            <p class="p-wh">Do you want to renew or buy a fresh policy?</p>
                        </div>
                        <div class="col-md-12 ">
                            <form [formGroup]="homeExpiry">
                                <div class="row">
                                    <div class="col-md-12 mt-2">
                                        <div class="card p-2 text-center crd"
                                            [ngClass]="{'selected':selectedPolicy=='existing'}"
                                            (click)="policy('existing')">
                                            <p class="p-size  p-crd">Renew Existing Policy</p>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="card p-2 text-center crd "
                                            [ngClass]="{'selected':selectedPolicy=='new'}" (click)="policy('new')">
                                            <p class="p-size p-crd ">Buy New Policy</p>
                                        </div>
                                    </div>

                                </div>
                                <div class="row" *ngIf="selectedPolicy=='existing'">
                                    <div class="col-md-12 mt-4">
                                        <label for="" class="required">Policy's Expiry Status</label>
                                        <select class="form-select in-h" aria-label="Default select example" #val
                                            (change)="expiry(val.value)" formControlName="expiryPolicy">
                                            <option selected>Select Existing Policy Status</option>
                                            <option value="Not expired">Not expired</option>
                                            <option value="Expired within 90 days">Expired within 90 days</option>
                                            <option value="Expired more than 90 days">Expired more than 90 days</option>
                                            <option value="Don't remember">Don't remember</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mt-4"
                                        *ngIf="expiryValue=='Not expired'|| expiryValue=='Expired within 90 days'">
                                        <label for="" class="required">Existing Policy Company</label>
                                        <app-autocomplete class="w-100" [items]="insurancePartnersList"
                                            placeholder="Type and search Current policy company"
                                            (itemSelected)="onPrevCompSelectionHome($event)"
                                            (onBlurEvent)="onPrevCompBlurHome($event)"
                                            [userInput]="previousPolicyCompany" [className]="'py-6_5 shadow_'"
                                            [showError]="showPreviousPolicyCompanyError"></app-autocomplete>
                                        <span class="text-danger" *ngIf="showPreviousPolicyCompanyError">Existing policy
                                            company is required.</span>
                                    </div>
                                    <div class="col-md-12 mt-4"
                                        *ngIf="expiryValue=='Not expired'|| expiryValue=='Expired within 90 days'">
                                        <label for="" class="mt-3">Existing Policy Expiry Date</label>
                                        <input type="date" name="" class="form-control in-h" id=""
                                            formControlName="expiryDate">
                                    </div>

                                    <div class="col-md-12 mt-4">
                                        <label for="" class="required">Did you make a claim in your existing
                                            policy?</label>
                                        <select class="form-select in-h" aria-label="Default select example" #cl
                                            (click)="claim(cl.value)" formControlName="claimPolcy">
                                            <option selected> Select Existing Policy Claim</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mt-4" *ngIf="claimPolicy=='No'">
                                        <label for="" class="required">Existing NCB (No Claim Bonus)</label>
                                        <select class="form-select in-h" aria-label="Default select example"
                                            formControlName="bonus">
                                            <option selected>Select Existing NCB</option>
                                            <option value="0%">0%</option>
                                            <option value="20%" selected>20%</option>
                                            <option value="25%">25%</option>
                                            <option value="35%" selected>35%</option>
                                            <option value="45%">45%</option>
                                            <option value="50%" selected>50%</option>
                                        </select>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="second">
                    <form [formGroup]="homeLocation" class="row">
                        <!-- <div class="col-md-12 mt-2">
                            <p class="p-wh"><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
                                    (click)="Page('1')">&nbsp;&nbsp;Select the location of your Home</p>
                        </div> -->
                        <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                            <button type="button" class="btn btn-primary px-1 py-1">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="Page('1')">
                            </button>
                            <p class="heading_"> Select the location of your Home </p>
                        </div>
                        <div class="col-md-12 text-left">
                            <label for="">Your PIN Code</label>
                            <input type="number" name="" class="form-control in-h" id="" placeholder="e.g :- 535006 "
                                formControlName="HomeCode" #ownerCode (blur)="pincodeUserChangeEvent(ownerCode)">
                        </div>
                        <div class="col-md-12 mt-2">
                            <label for="">State</label>
                            <input type="text" name="" class="form-control" placeholder="State"
                                formControlName="ownerState" [ngModel]="ownerState">
                        </div>
                        <div class="col-md-12 text-left mt-2">
                            <label for="">Select City</label>
                            <select class="form-select in-h" aria-placeholder="Selcet City" aria-label="Selcet City"
                                formControlName="ownerCity" #ownerCity (change)='changeCity(ownerCity.value)'>
                                <option *ngFor="let city of cityList" [value]="city">
                                    {{city}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="row " *ngIf="third">
                    <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                        <button type="button" class="btn btn-primary px-1 py-1">
                            <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                class="cursor-pointer svg_white edit-icon" (click)="Page('2')">
                        </button>
                        <p class="heading_"> I want to cover </p>
                    </div>
                    <div class="col-md-12  mt-2" (click)="cover('structure')">
                        <div class="card p-2 crd" [ngClass]="{'selected':coverType=='structure'}">
                            <p class="mt-2"> Structure</p>
                        </div>
                    </div>

                    <div class="col-md-12  mt-2" (click)="cover('content')">
                        <div class="card p-2 crd" [ngClass]="{'selected':coverType=='content'}">
                            <p class="mt-2"> Content</p>
                        </div>
                    </div>

                    <div class="col-md-12  mt-2" (click)="cover('both')">
                        <div class="card p-2 crd" [ngClass]="{'selected':coverType=='both'}">
                            <p class="mt-2"> Structure and Content</p>
                        </div>
                    </div>
                </div>
                <div class="row " *ngIf="four">
                    <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                        <button type="button" class="btn btn-primary px-1 py-1">
                            <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                class="cursor-pointer svg_white edit-icon" (click)="Page('3')">
                        </button>
                        <p class="heading_"> Select Type of Dwelling </p>
                    </div>
                    <div class="col-md-12   mt-2" (click)="dwelling('Independent')">
                        <div class="card p-2 crd" [ngClass]="{'selected':dwellingType=='Independent'}">
                            <p class="mt-2"> Independent Home</p>
                        </div>
                    </div>

                    <div class="col-md-12   mt-2" (click)="dwelling('Flat')">
                        <div class="card p-2 crd" [ngClass]="{'selected':dwellingType=='Flat'}">
                            <p class="mt-2"> Flat/ Building/ Complex</p>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="five">
                    <form [formGroup]="HomeDetails" class="row">
                        <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                            <button type="button" class="btn btn-primary px-1 py-1">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="Page('4')">
                            </button>
                            <p class="heading_"> Enter your Home Details </p>
                        </div>
                        <div class="col-md-12 mt-2">
                            <p>Select Construction Type &nbsp;&nbsp;&nbsp;<img src="../../../assets/svgIcon/Info1.svg"
                                    alt="">
                            </p>
                        </div>
                        <div class="col-md-6 mt-2" (click)="construct('Concrete')">
                            <div class="card p-2 crd" [ngClass]="{'selected':construcType=='Concrete'}">
                                <p class="mt-2"> Concrete Construction</p>
                            </div>
                        </div>
                        <div class="col-md-6 mt-2" (click)="construct('Kutcha')">
                            <div class="card p-2 crd" [ngClass]="{'selected':construcType=='Kutcha'}">
                                <p class="mt-2"> Temporary Construction</p>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <label for="">Carpet Area</label>
                            <div class="inputBox">
                                <input type="number" placeholder="Eg: 750" formControlName="CarpetArea"><span>Sq.
                                    Ft.</span>
                            </div>
                        </div>

                        <div class="col-md-12 mt-2">
                            <label for="">Rate Per Sq. Ft.</label>
                            <div class="inputBox">

                                <input type="number" placeholder="₹2000" formControlName="Rate"><span>₹</span>

                            </div>

                        </div>

                        <div class="col-md-12 mt-2">
                            <label for="">Value of Building</label>
                            <div class="inputBox">

                                <input type="number" placeholder="₹2000" formControlName="Value_of_Building"><span>₹.5L
                                    to
                                    5cr.</span>

                            </div>

                        </div>

                        <div class="col-md-12 mt-2">
                            <label for="">Number of Floors</label>
                            <div class="inputBox">
                                <select class="form-select " aria-label="Default select example"
                                    formControlName="Floors">
                                    <option selected> Select Floors</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    <option value="4">Four</option>
                                    <option value="5">Five</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-12 mt-2">
                            <label for="">Number of Rooms</label>
                            <div class="inputBox">

                                <input type="number" placeholder="Eg:1" formControlName="Rooms"><span> </span>

                            </div>
                        </div>

                        <div class="col-md-12 mt-2">
                            <label for="">Age of Building</label>
                            <div class="inputBox">
                                <select class="form-select" aria-label="Default select example" formControlName="Age">
                                    <option selected> Select Age </option>
                                    <option *ngFor="let building of buildingAge" value="{{building}}">{{building}}
                                    </option>

                                </select>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <label for="">Value of Content</label>
                            <div class="inputBox">

                                <input type="number" placeholder="Eg: ₹ 50,000" formControlName="contentValue"><span>
                                    50K to
                                    10L</span>

                            </div>
                        </div>
                    </form>
                </div>
                <div class="row" *ngIf="six">
                    <form class="row" [formGroup]="homeLocationrent">
                        <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                            <button type="button" class="btn btn-primary px-1 py-1">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="Page('5')">
                            </button>
                            <p class="heading_"> Select the location of your Home </p>
                        </div>
                        <div class="col-md-12 text-left">
                            <label for="">Your PIN Code</label>
                            <input type="number" name="" class="form-control in-h" id="" placeholder="Pin code"
                                formControlName="rentCode" #rnCode (blur)="pincodeUserChangeEvent(rnCode)">
                        </div>
                        <div class="col-md-12 mt-2">
                            <label for="">State</label>
                            <input type="text" name="" class="form-control" id="" formControlName="rentState"
                                [ngModel]="rentState">
                        </div>
                        <div class="col-md-12 text-left mt-2    ">
                            <label for="">Select Location</label>
                            <select class="form-select in-h" aria-label="Default select example"
                                formControlName="rentCity" #RnCity (change)='changeCity(RnCity.value)'>
                                <option selected>Select location</option>
                                <option *ngFor="let rentcode of pincodeMaster" [value]="rentcode.id">
                                    {{rentcode.cityVillageArea}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="row" *ngIf="seven">
                    <form [formGroup]="homeRentedDetails">
                        <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                            <button type="button" class="btn btn-primary px-1 py-1">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="Page('6')">
                            </button>
                            <p class="heading_"> Enter your Content Details </p>
                        </div>
                        <div class="col-md-12 mt-4">
                            <label for="">Value of content</label>
                            <div class="inputBox">
                                <input type="text" placeholder="Eg: ₹ 50,000"
                                    formControlName="Value_of_content"><span>50K
                                    to 10L</span>
                            </div>
                        </div>
                    </form>
                </div>



            </div>
            <div class="row btn-mrg">
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0"
                    [disabled]="isButton && homeExpiry.invalid " (click)="Page('2')"
                    *ngIf="firstbutton">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="Page('3')"
                    *ngIf="second" [disabled]="homeLocation.invalid">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" [disabled]="thirdButton"
                    (click)="Page('4')" *ngIf="third">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="Page('5')"
                    *ngIf="four" [disabled]="fourthButton">Continue</button>

                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="Page('6')"
                    *ngIf="rentedButton" [disabled]="isButton && homeExpiry.invalid">Continue</button>

                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="Page('7')"
                    *ngIf="six" [disabled]="homeLocationrent.invalid">Continue</button>


                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="verify"
                    (click)="getQuotes()" [disabled]="HomeDetails.invalid && homeRentedDetails.invalid">Verify Quotes
                </button>
            </div>
        </div>
    </div>
    <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="isShopPopup">
        <div class="popup2 col-md-7 col-lg-3 col-11" style=" overflow-x: unset;">
            <div class="modal-header tooltip_">
                <h4 class="modal-title">
                    <h4 class="">Shop Insurance &nbsp;&nbsp;&nbsp;<img src="../../../assets/svgIcon/1-5.svg" alt=""
                            *ngIf="Shopfirst">
                        <img src="../../../assets/svgIcon/2-5.svg" *ngIf="Shopsecond" alt="">
                        <img src="../../../assets/svgIcon/3-5.svg" *ngIf="Shopthird" alt="">
                        <img src="../../../assets/svgIcon/4-5.svg" *ngIf="Shopfour" alt="">
                        <img src="../../../assets/svgIcon/5-5.svg" *ngIf="Shopfive  " alt="">
                    </h4>



                </h4>
                <button type="button" class="close pull-right btn" aria-label="Close" (click)=" closeModal()">
                    <span></span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="shopPage=='1'">
                    <div class="row p-3">

                        <div class="col-md-12 mt-3">
                            <p class="p-wh"><b>Select your shop type</b></p>
                        </div>
                        <div class="row col-md-12 mt-2" (click)="Type('owned')">
                            <div class="card text-center p-2 crd" [ngClass]="{'sl-img':type=='owned'}">
                                <p class="p-size  p-crd">Owned</p>
                            </div>
                        </div>
                        <div class="row col-md-12 mt-2" (click)="Type('rented')">
                            <div class="card text-center p-2 crd" [ngClass]="{'sl-img':type=='rented'}">
                                <p class="p-size  p-crd"> Rented</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row  " *ngIf="shopPage=='2'">
                    <form [formGroup]="shopeExpiry" class="row">
                        <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                            <button type="button" class="btn btn-primary px-1 py-1">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="ShopPage('1')">
                            </button>
                            <p class="heading_"> About your shop </p>
                        </div>
                        <div class=" col-md-6 mt-2 ">
                            <div class="card text-center  crd" (click)="policy('existingShop')"
                                [ngClass]="{'selected':selectedPolicy=='existingShop'}">
                                <p class="p-size  p-crd">Renew Existing Policy</p>
                            </div>
                        </div>
                        <div class=" col-md-6 mt-2">
                            <div class="card text-center p-2 crd" (click)="policy('newShop')"
                                [ngClass]="{'selected':selectedPolicy=='newShop'}">
                                <p class="p-size  p-crd">Buy New Policy</p>
                            </div>
                        </div>
                        <div class="row" *ngIf="selectedPolicy=='existingShop'">
                            <div class="col-md-12 mt-4">
                                <label for="" class="required">Policy's Expiry Status</label>
                                <select class="form-select in-h" aria-label="Default select example" #val
                                    (change)="Shopexpiry(val.value)" formControlName="policyStatus">
                                    <option selected>Select Existing Policy Status</option>
                                    <option value="Not expired">Not expired</option>
                                    <option value="Expired within 90 days">Expired within 90 days</option>
                                    <option value="Expired more than 90 days">Expired more than 90 days</option>
                                    <option value="Don't remember">Don't remember</option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-4"
                                *ngIf="expiryValue=='Not expired'|| expiryValue=='Expired within 90 days'">
                                <label for="" class="required">Existing Policy Company</label>
                                <app-autocomplete class="w-100" [items]="insurancePartnersList"
                                    placeholder="Type and search Current policy company"
                                    (itemSelected)="onPrevCompSelectionShop($event)"
                                    (onBlurEvent)="onPrevCompBlurShop($event)" [userInput]="previousPolicyCompany"
                                    [className]="'py-6_5 shadow_'"
                                    [showError]="showPreviousPolicyCompanyError"></app-autocomplete>
                                <span class="text-danger" *ngIf="showPreviousPolicyCompanyError">Existing policy company
                                    is required.</span>
                            </div>
                            <div class="col-md-12 mt-4"
                                *ngIf="expiryValue=='Not expired'|| expiryValue=='Expired within 90 days'">
                                <label for="" class="required">Existing Policy Expiry Date</label>
                                <input type="date" name="" class="form-control in-h" id="" formControlName="PolicyDate">
                            </div>
                            <div class="col-md-12 mt-4">
                                <label for="" class="required">Did you make a claim in your existing policy?</label>
                                <select class="form-select in-h" aria-label="Default select example" #cl
                                    (change)="Shopclaim(cl.value)" formControlName="exsitingclaim">
                                    <option selected> Select Existing Policy Claim</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-4" *ngIf="claimPolicy=='No'">
                                <label for="" class="required">Existing NCB (No Claim Bonus)</label>
                                <select class="form-select in-h" aria-label="Default select example"
                                    formControlName="NCB">
                                    <option selected>Select Existing NCB</option>
                                    <option value="0%">0%</option>
                                    <option value="20%" selected>20%</option>
                                    <option value="25%">25%</option>
                                    <option value="35%" selected>35%</option>
                                    <option value="45%">45%</option>
                                    <option value="50%" selected>50%</option>
                                </select>
                            </div>

                        </div>
                        <div class="row" *ngIf="shopType">
                            <div class="col-md-12 mt-5">
                                <label for="">Shop Name</label>
                                <input type="text" name="" class="form-control in-h" placeholder="Shop name" id=""
                                    formControlName="Shopname">
                            </div>
                            <div class="col-md-12 mt-5">
                                <label for="">Business Type</label>
                                <select class="form-select in-h" aria-label="Default select example"
                                    formControlName="BusinessType" #bsType (change)="BussinessType(bsType.value)">
                                    <option selected>Select Your business type </option>
                                    <option value="Mobile and Other Electronics">Mobile and Other Electronics </option>
                                    <option value="Grocery and General Stores">Grocery and General Stores</option>
                                    <option value="Offices and Educational Spaces">Offices and Educational Spaces
                                    </option>
                                    <option value="Manufacturing and Processing">Manufacturing and Processing </option>
                                    <option value="Personal Lifestyle and Fitness">Personal Lifestyle and Fitness
                                    </option>
                                    <option value="Food and Eatables">Food and Eatables </option>
                                    <option value="Healthcare">Healthcare </option>
                                    <option value="Home Repair Services">Home Repair Services </option>
                                    <option value="Others">Others </option>

                                </select>



                            </div>
                            <div class="col-md-12 mt-2" *ngIf="isOther">
                                <label for="">Other Business Type </label>
                                <input type="text" name="" class="form-control" placeholder="Other Business" id="">
                            </div>
                            <div class="col-ms-12 mt-3">
                                <label for="">Total Sum Insured</label>
                            </div>
                            <div class=" col-md-6 mt-2">
                                <div class="card text-center p-2 crd" (click)="Insured('less')"
                                    [ngClass]="{'selected':sum=='less'}">
                                    <p class="p-size  p-crd">Less than 1.5 cr</p>
                                </div>
                            </div>
                            <div class=" col-md-6 mt-2 ">
                                <div class="card text-center  crd" (click)="Insured('more')"
                                    [ngClass]="{'selected':sum=='more'}">
                                    <p class="p-size  p-crd">More than 1.5 cr</p>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                <div *ngIf="shopPage=='3'">
                    <form [formGroup]="location" class="row">
                        <!-- <div class="col-md-12 mt-2">
                            <p class="p-wh"><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
                                    (click)="ShopPage('2')">&nbsp;&nbsp;
                                <b> Select the location of your Shop </b>
                            </p>
                        </div> -->

                        <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                            <button type="button" class="btn btn-primary px-1 py-1">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="ShopPage('2')">
                            </button>
                            <p class="heading_"> Select the location of your Shop </p>
                        </div>


                        <div class="col-md-12 mt-3">
                            <label for="">Your PIN Code</label>
                            <input type="number" name="" class="form-control in-h" placeholder="Ex: 321001" id=""
                                #pinCode (blur)="pincodeUserChangeEvent(pinCode)" value="" formControlName="pinCode">
                        </div>
                        <div class="col-md-12 mt-5">
                            <label for="">Select State</label>
                            <!-- <select class="form-select in-h" aria-label="Default select example" #myState
                                (change)='changeState(myState.value)' formControlName="Citys">
                                <option selected>Select location</option>
                                <option *ngFor="let state of pincodeMaster" [value]="state.id">
                                    {{state.state}}
                                </option>
    
                            </select> -->
                            <input type="text" placeholder="State" [ngModel]="myState" class="form-control"
                                formControlName="State">
                        </div>
                        <div class="col-md-12 mt-5">
                            <label for="">Select City</label>
                            <select class="form-select in-h" aria-label="Default select example" #mycity
                                (change)='changeCity(mycity.value)' formControlName="Citys">
                                <option *ngFor="let city of cityList" [value]="city">
                                    {{city}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
                <div *ngIf="shopPage=='4'">
                    <div class="row">
                        <!-- <div class="col-md-12 mt-2">
                            <p class="p-wh"><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
                                    (click)="ShopPage('3')">&nbsp;&nbsp;
                                <b> I want to cover </b>
                            </p>
                        </div> -->
                        <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                            <button type="button" class="btn btn-primary px-1 py-1">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer svg_white edit-icon" (click)="ShopPage('3')">
                            </button>
                            <p class="heading_"> I want to cover </p>
                        </div>
                        <div class=" col-md-12 mt-2 ">
                            <div class="card  p-4 crd" *ngIf="isShopOwner" (click)="ShopCover('structure')"
                                [ngClass]="{'selected':isStructure}">
                                <div class="row">
                                    <div class="col-md-10 c-10">
                                        <div class="form-check">
                                            <p>Building</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class=" col-md-12 mt-2 ">
                            <div class="card  p-4 crd" (click)="ShopCover('content')"
                                [ngClass]="{'selected':isContent}">
                                <div class="row">
                                    <div class="col-md-10 c-10">
                                        <div class="form-check">
                                            <p>Content</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class=" col-md-12 mt-2 ">
                            <div class="card  p-4 crd" (click)="ShopCover('stock')" [ngClass]="{'selected':isStock}">
                                <div class="row">
                                    <div class="col-md-10 c-10">

                                        <div class="form-check">
                                            <p>Stock</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="shopPage=='5'" class="row p-2">
                    <div class="col-md-12 d-flex align-items-center gap-8 mb-3">
                        <button type="button" class="btn btn-primary px-1 py-1">
                            <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                class="cursor-pointer svg_white edit-icon" (click)="ShopPage('4')">
                        </button>
                        <p class="heading_"> Enter your shop details </p>
                    </div>
                    <form [formGroup]="shopDetail">
                        <div class="row" *ngIf="isShopOwner && isStructure">
                            <div class="col-md-12 mt-2">
                                <p><b>Building Value</b></p>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Carpet Area (sq.mtr.)</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: 750" formControlName="Area"><span>Sq.
                                        mtr..</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Cost of Construction Per Sq. mtr..</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 2000"
                                        formControlName="RateFt"><span>₹.</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Value of Building</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: 750" formControlName="buildingValue"
                                        onkeypress="if(this.value.length=='7') return false"><span>Upto 50L</span>

                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isContent">
                            <div class="col-md-12 mt-4">
                                <p><b>Content Value</b></p>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Value of content</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="contentVal"><span>₹.</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Furniture/Fixtures/Fittings Value</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="Furniture"><span>₹.</span>

                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isStock">
                            <div class="col-md-12 mt-5">
                                <p><b>Stock Value</b></p>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Stock Value</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="stokVal"><span>₹.</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Goods held in trust value</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="goodHeld"><span>₹.</span>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <div class="row btn-mrg">
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="ShopPage('2')"
                    *ngIf="Shopfirst" [disabled]="shop1">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="ShopPage('3')"
                    *ngIf="Shopsecond" [disabled]="shopeExpiry.invalid || shop2">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="ShopPage('4')"
                    *ngIf="Shopthird" [disabled]="location.invalid">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="ShopPage('5')"
                    *ngIf="Shopfour" [disabled]="shop4"> Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="Shopfive"
                    (click)="getQuotes()">View Quotes </button>
            </div>
        </div>
    </div>
    <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="isOfficePopup">
        <div class="popup2 col-md-7 col-lg-3 col-11" style=" overflow-x: unset;">
            <div class="modal-header tooltip_">
                <h6 class="modal-title">
                    <h6 class="" style="font-weight: 600;
                    font-size: 14px;">Office Package Insurance &nbsp;&nbsp;&nbsp;<img
                            src="../../../assets/svgIcon/1-5.svg" alt="" *ngIf="Officefirst">
                        <img src="../../../assets/svgIcon/2-5.svg" *ngIf="Officesecond" alt="">
                        <img src="../../../assets/svgIcon/3-5.svg" *ngIf="Officethird" alt="">
                        <img src="../../../assets/svgIcon/4-5.svg" *ngIf="Officefour" alt="">
                        <img src="../../../assets/svgIcon/5-5.svg" *ngIf="Officefive" alt="">
                    </h6>



                </h6>
                <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
                    <span></span>
                </button>
            </div>
            <div class="modal-body">

                <div *ngIf="officePage=='1'">
                    <div class="row p-3">

                        <div class="col-md-12 mt-3">
                            <p><b>Select your office type</b></p>
                        </div>
                        <div class="row col-md-12 mt-2" (click)="Type('Officeowner')">
                            <div class="card text-center p-2 crd" [ngClass]="{'sl-img':type=='Officeowner'}">
                                <p class="p-size p-crd">Owned</p>
                            </div>
                        </div>
                        <div class="row col-md-12 mt-2" (click)="Type('Officerented')">
                            <div class="card text-center p-2 crd" [ngClass]="{'sl-img':type=='Officerented'}">
                                <p class="p-crd p-size p-crd"> Rented</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="officePage=='2'">
                    <form [formGroup]="officeExpiry" class="row">
                        <div class="col-md-12 mt-2">
                            <p><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
                                    (click)="OfficePage('1')">&nbsp;&nbsp;
                                <b> About your office </b>
                            </p>
                        </div>
                        <div class=" col-md-12 mt-2">
                            <div class="card text-center p-2 crd"
                                [ngClass]="{'selected':selectedPolicy=='OfficeExisting'}"
                                (click)="policy('OfficeExisting')">
                                <p class="p-crd p-size p-crd"> Renew Existing Policy</p>
                            </div>
                        </div>
                        <div class=" col-md-12 mt-2 ">
                            <div class="card text-center  crd" [ngClass]="{'selected':selectedPolicy=='OfficeNew'}"
                                (click)="policy('OfficeNew')">
                                <p class="p-crd p-size p-crd"> Buy New Policy</p>
                            </div>
                        </div>
                        <div class="row" *ngIf="selectedPolicy=='OfficeExisting'">

                            <div class="col-md-12 mt-4">
                                <label for="" class="required">Policy's Expiry Status</label>
                                <select class="form-select in-h" aria-label="Default select example" #val
                                    (change)="expiry(val.value)" formControlName="ExpiryStatus">
                                    <option selected>Select Existing Policy Status</option>
                                    <option value="Not expired">Not expired</option>
                                    <option value="Expired within 90 days">Expired within 90 days</option>
                                    <option value="Expired more than 90 days">Expired more than 90 days</option>
                                    <option value="Don't remember">Don't remember</option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-4"
                                *ngIf="expiryValue=='Not expired'|| expiryValue=='Expired within 90 days'">
                                <label for="" class="required">Existing Policy Company</label>
                                <app-autocomplete class="w-100" [items]="insurancePartnersList"
                                    placeholder="Type and search Current policy company"
                                    (itemSelected)="onPrevCompSelectionBusiness($event)"
                                    (onBlurEvent)="onPrevCompBlurBusiness($event)" [userInput]="previousPolicyCompany"
                                    [className]="'py-6_5 shadow_'"
                                    [showError]="showPreviousPolicyCompanyError"></app-autocomplete>
                                <span class="text-danger" *ngIf="showPreviousPolicyCompanyError">Existing policy company
                                    is required.</span>
                            </div>
                            <div class="col-md-12 mt-4"
                                *ngIf="expiryValue=='Not expired'|| expiryValue=='Expired within 90 days'">
                                <label for="" class="mt-3">Existing Policy Expiry Date</label>
                                <input type="date" name="" class="form-control in-h" id=""
                                    formControlName="PolicyDateExisting">
                            </div>
                            <div class="col-md-12 mt-4">
                                <label for="" class="required">Did you make a claim in your existing policy?</label>
                                <select class="form-select in-h" aria-label="Default select example" #cl
                                    (click)="claim(cl.value)" formControlName="NCBclaim">
                                    <option selected> Select Existing Policy Claim</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-4" *ngIf="claimPolicy=='No'">
                                <label for="" class="required">Existing NCB (No Claim Bonus)</label>
                                <select class="form-select in-h" aria-label="Default select example"
                                    formControlName="NCBbonus">
                                    <option selected>Select Existing NCB</option>
                                    <option value="0%">0%</option>
                                    <option value="20%" selected>20%</option>
                                    <option value="25%">25%</option>
                                    <option value="35%" selected>35%</option>
                                    <option value="45%">45%</option>
                                    <option value="50%" selected>50%</option>
                                </select>
                            </div>

                        </div>
                        <div class="row" *ngIf="officeType">
                            <div class="col-md-12 mt-5">
                                <label for="">Company Name</label>
                                <input type="text" name="" class="form-control in-h" placeholder="Company name" id=""
                                    formControlName="NameCompany">
                            </div>

                            <div class="col-ms-12 mt-10">
                                <label for="">Total Sum Insured</label>
                            </div>
                            <div class=" col-md-6 mt-2">
                                <div class="card text-center p-2 crd" (click)="Insured('less-3')"
                                    [ngClass]="{'selected':sum=='less-3'}">
                                    <p class="p-crd p-size p-crd">Less than 3 cr</p>
                                </div>
                            </div>
                            <div class=" col-md-6 mt-2 ">
                                <div class="card text-center  crd" (click)="Insured('more-3')"
                                    [ngClass]="{'selected':sum=='more-3'}">
                                    <p class="p-crd p-size p-crd">More than 3 cr</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="officePage=='3'">
                    <form [formGroup]="officeLocation" class="row">
                        <div class="col-md-12 mt-2">
                            <p><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
                                    (click)="OfficePage('2')">&nbsp;&nbsp;
                                <b> Select the location of your office </b>
                            </p>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label for="">Your PIN Code</label>
                            <input type="number" name="" class="form-control in-h" placeholder="Ex: 321001" id=""
                                #officePinCode (blur)="pincodeUserChangeEvent(officePinCode)"
                                formControlName="officeCode">
                        </div>
                        <div class="col-md-12 mt-3">
                            <label for="">State</label>
                            <input type="text" placeholder="State" class="form-control" formControlName="officeState"
                                [ngModel]="OfficeState">
                        </div>
                        <div class="col-md-12 mt-5">
                            <label for="">Select City</label>
                            <select class="form-select in-h" aria-placeholder="Selcet City" aria-label="Selcet City"
                                #myOfficecity (change)='changeCity(myOfficecity.value)' formControlName="officeCity">
                                <option *ngFor="let city of cityList" [value]="city">
                                    {{city}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
                <div *ngIf="officePage=='4'">
                    <div class="row">
                        <div class="col-md-12 mt-2">
                            <p><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
                                    (click)="OfficePage('3')">&nbsp;&nbsp;
                                <b> I want to cover </b>
                            </p>
                        </div>
                        <div class=" col-md-12 mt-2 ">
                            <div class="card  p-4 crd" *ngIf="isOfficeOwner" (click)="OfficeCover('Officestructure')"
                                [ngClass]="{'selected':isOfficeStructure}">
                                <div class="row">
                                    <div class="col-md-10 c-10">
                                        <div class="form-check">
                                            <p>Building</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class=" col-md-12 mt-2 ">
                            <div class="card  p-4 crd" (click)="OfficeCover('OffilceContent')"
                                [ngClass]="{'selected':isOfficeContent}">
                                <div class="row">
                                    <div class="col-md-10 c-10">
                                        <div class="form-check">
                                            <p>Content</p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class=" col-md-12 mt-2 ">
                            <div class="card  p-4 crd" (click)="OfficeCover('Offilcestock')"
                                [ngClass]="{'selected':isOfficeStock}">
                                <div class="row">
                                    <div class="col-md-10 c-10">

                                        <div class="form-check">
                                            <p>Stock</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row  p-2" *ngIf="officePage=='5'">
                    <div class="col-md-12 mt-2">
                        <p><img src="../../../assets/svgIcon/arrow_back_ios.svg" alt=""
                                (click)="OfficePage('4')">&nbsp;&nbsp;
                            <b> Enter your Office details </b>
                        </p>
                    </div>
                    <form [formGroup]="officeDetail">
                        <div class="row" *ngIf="isOfficeStructure && isOfficeStructure">
                            <div class="col-md-12 mt-2">
                                <p><b>Building Value</b></p>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Carpet Area (sq.mtr.)</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: 750" formControlName="offilceArea"><span>Sq.
                                        mtr..</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Cost of Construction Per Sq. mtr..</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 2000"
                                        formControlName="officeRateFt"><span>₹.</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Value of Building</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: 750" formControlName="officebuildingValue"
                                        onkeypress="if(this.value.length=='7') return false"><span>Upto 50L</span>

                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isOfficeContent">
                            <div class="col-md-12 mt-2">
                                <p><b>Content Value</b></p>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Value of content</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="officeContentVal"><span>₹.</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Furniture/Fixtures/Fittings Value</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="officeFurniture"><span>₹.</span>

                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isOfficeStock">
                            <div class="col-md-12 mt-2">
                                <p><b>Stock Value</b></p>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Stock Value</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="officeStokVal"><span>₹.</span>

                                </div>
                            </div>
                            <div class="col-md-12 mt-1">
                                <label for="">Goods held in trust value</label>
                                <div class="inputBox">

                                    <input type="number" placeholder="Eg: ₹ 50,000"
                                        formControlName="officeGoodHeld"><span>₹.</span>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

            <div class="row btn-mrg">
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="OfficePage('2')"
                    *ngIf="Officefirst" [disabled]=" Office1">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="OfficePage('3')"
                    *ngIf="Officesecond" [disabled]=" officeExpiry.invalid || Office2">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="OfficePage('4')"
                    *ngIf="Officethird" [disabled]="officeLocation.invalid">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" (click)="OfficePage('5')"
                    *ngIf="Officefour" [disabled]="Office4">Continue</button>
                <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-0 r-0" *ngIf="Officefive"
                    (click)="getQuotes()">View Quotes </button>
            </div>
        </div>
    </div>

    <div [ngClass]="{'d-none': isPropertyPage}">
        <app-property-policy></app-property-policy>
    </div>
</div>


<!-- 
<div class="banner">
  <div class="container mt-5 mb-5 ">
    <div style="display: flex; justify-content: space-between;  width: 87%;margin-left: 6%;">
      <div
        style="display: flex; padding: 17px 30px 17px 30px; background-color: #FCF1D6; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/insurance-claim.svg"></div>
        <div class="bannerText">Claim 2 wheeler<br> Insurance Now</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #F6D1E5; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/garage.svg"></div>
        <div class="bannerText">Search nearby<br> cashless garages</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #D1F1DF; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/repeat-message.svg"></div>
        <div class="bannerText">Earn with Us, Be a<br> Reseller with Us</div>
      </div>
      <div style="display: flex; padding: 22px;  background-color: #D2DAF8; border-radius: 12px;column-gap: 21px;">
        <div><img src="./assets/images/newicons/cash-money-rupee-coin.svg"></div>
        <div class="bannerText">Renewal your 2 <br>wheeler insurance</div>
      </div>
    </div>
  </div>

</div>


<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; letter-spacing: 1px;">What is Bike Insurance?
        </h2>
        <span>
          <p style="padding-top: 15px;" class="insText">A bike insurance policy offers comprehensive protection and
            coverage for your bike. It covers repair costs for accidental damages and provides financial security
            against theft, natural disasters, vandalism, and other unforeseen incidents. . With optional add-ons for
            enhanced coverage, such as engine protection and roadside assistance, you can tailor your policy to your
            specific needs. Secure your ride with a bike insurance policy and enjoy peace of mind on every journey.</p>

        </span> 
      </div>
      <div class="col-md-5" style="text-align: right;">
        <img src="./assets/images/newicons/bike.svg" style="width:65%">
      </div>
    </div>
  </div>
</div>

<div class="whyins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5" style="text-align: left; padding-top: 40px;  ">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; text-align: right; letter-spacing: 1px;">
          Everything about Bike Insurance</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Bike insurance provides coverage for accidental damages, theft, natural disasters, and vandalism,
            including repair costs. <br>
            2. It also offers protection against legal and financial responsibilities for injuries or property damage
            caused to others.<br>
            3. Covers personal accidents, offering compensation for injuries or death resulting from bike accidents.<br>
            4. Allows for policy customization with various plans and add-ons to match individual needs and preferences.
          </p>
        </span> 

      </div>

    </div>
  </div>
</div>


<div class="click4bima">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12">
        <div class="">
          <h1 style="font-size: 40px;; color: #fff; font-weight: 200;">Types of Two-Wheeler Insurance</h1>
        </div>
        <div style="margin-top: 35px;">
          <table>
            <thead>
              <tr>
                <th class="key-features">Key Features</th>
                <th>Comprehensive</th>
                <th>Third-Party(TP)</th>
                <th>Own Damage(OD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="leftHead">Description</td>
                <td>A comprehensive bike insurance policy covers your own motorcycle or scooter and third-party
                  liabilities. It ensures wide-ranging protection for both your vehicle and legal responsibilities.</td>
                <td>A third-party bike insurance policy covers the cost of damages or injuries you cause to someone
                  else's property or person while riding your bike. It doesn't cover any damages to your own bike.</td>
                <td>An own damage bike insurance policy covers repair or replacement costs if your bike gets damaged. It
                  ensures financial protection for your vehicle against unforeseen damages.</td>
              </tr>
              <tr>
                <td class="leftHead">Coverage</td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Third-Party Liabilities</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Third-Party Liabilities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Add-ons Availability</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
                <td>Not Available</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Premium</td>
                <td>High</td>
                <td>Low</td>
                <td>Moderate</td>
              </tr>
              <tr>
                <td class="leftHead">Pros</td>
                <td>
                  <ul>
                    <li>Wide Coverage</li>
                    <li>Additional Benefits with Add-ons</li>
                    <li>Financial Security for Own and Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Financial Security for Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>Lower Premium</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Covers Accidental Damage</li>
                    <li>Easy Claim Settlement</li>
                    <li>Financial Security for Own Vehicle Damage</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Cons</td>
                <td>
                  <ul>
                    <li>Higher Premiums</li>
                    <li>Depreciation Deduction</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Limited Coverage</li>
                    <li>No Financial Protection for Own Damage</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Depreciation Deduction</li>
                    <li>No PA Cover</li>
                    <li>No Financial Protection for Third-Party Damage</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div style="text-align: center;margin-top: 50px;">
    <h2 style="font-size: 40px; font-weight: 600;">Add-On We Provide for Two-Wheelers</h2>
  </div>
  <div
    style="text-align: center;margin-top: 50px; display: flex;width: 80%; margin-left: 10%; justify-content: space-around;">
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 1 }" (click)="getValue(1)">
      <div>
        <img src="./assets/images/newicons/b1.svg">
      </div>
      <div>
        Zero/Nil<br>
        Depreciation
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 2 }" (click)="getValue(2)">
      <div>
        <img src="./assets/images/newicons/b2.svg">
      </div>
      <div>
        Consumable<br>
        Cover
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 3 }" (click)="getValue(3)">
      <div>
        <img src="./assets/images/newicons/b3.svg">
      </div>
      <div>
        Return to<br>
        Invoice
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 4 }" (click)="getValue(4)">
      <div>
        <img src="./assets/images/newicons/b4.svg">
      </div>
      <div>
        Road Side<br>
        Assistance
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 5 }" (click)="getValue(5)">
      <div>
        <img src="./assets/images/newicons/b5.svg">
      </div>
      <div>
        Engine & Gear<br>
        Box Protect
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 6 }" (click)="getValue(6)">
      <div>
        <img src="./assets/images/newicons/b6.svg">
      </div>
      <div>
        Daily<br>
        Allowance
      </div>
    </div>

  </div>
  <div
    style="text-align: center;margin-top: 50px;width: 80%; margin-left: 10%; background-color: var(--grey1); padding:15px 50px 15px 50px; border-radius: 12px;     font-size: large;">
    {{text}}
  </div>
</div>

<div class="become">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12 ">
        <div class="">
          <h1 style="font-size: 40px;;">How to choose best two-wheeler Insurance?</h1>
        </div>
        <div style="margin-top: 35px;">
          <img src="./assets/images/newicons/group.svg">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; letter-spacing: 1px;">What is covered ?</h2>
        <span>
          <p style="padding-top: 15px;" class="insText">
            1. Coverage for damage to your two-wheeler due to accidents, including collisions, overturning, and other
            mishaps.<br>
            2. Compensation in case of theft or total loss of the vehicle.<br>
            3. Covers damages or injuries caused to third parties, including property damage and bodily injury.<br>
            4. Provides financial protection for the owner-rider in case of accidental death or disability.<br>
            5. Discounts on premium for claim-free years, which can be accumulated over time.

          </p>

        </span> 
      </div>
      <div class="col-md-5" style="text-align: right; padding-top: 40px;">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
    </div>
  </div>
</div>

<div class="whyins1">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5" style="text-align: left; padding-top: 40px;">
        <img src="./assets/images/newicons/video.svg" class="videoClass">
      </div>
      <div class="col-md-7">
        <h2 style="font-size: 35px; margin-top: 30px; font-weight: 600; text-align: right;margin-right: 17%;
    letter-spacing: 1px;">
          What is not Covered?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Damage due to regular use, aging, and wear and tear is not covered.<br>
            2. Issues arising from mechanical or electrical failures that are not related to accidents.<br>
            3. Damage caused intentionally or due to reckless behavior is not covered.<br>
            4. Accidents occurring while the rider is under the influence of alcohol or drugs are generally
            excluded.<br>
            5. Damage to or loss of a vehicle that is not properly registered.
          </p>
        </span> 

      </div>

    </div>
  </div>
</div>

<div class="faq">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <h2>FAQ</h2>
    </div>
    <div class="row" style="padding-top: 15px;">
      <div class="col-md-6">

        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(0)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }"
                aria-hidden="true"></i>
              1. What does bike insurance cover??
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Bike insurance covers damages to your
              bike from accidents, theft, fire, natural and man-made calamities, and third-party liabilities. It may
              also include personal accident cover and various add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(1)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"
                aria-hidden="true"></i>
              2. What is the difference between third-party and comprehensive bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">Third-party insurance covers damages and
              liabilities to third parties caused by your bike. Comprehensive insurance covers both third-party
              liabilities and damages to your own bike..</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(3)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"
                aria-hidden="true"></i>
              3. What are add-ons in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> Add-ons are additional coverages you
              can purchase to enhance your policy, such as zero depreciation cover, engine protection, roadside
              assistance, and more.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(4)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"
                aria-hidden="true"></i>
              4. How can I file a claim?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">To file a claim, notify your insurer
              immediately after the incident, provide necessary documents (e.g., FIR, repair bills), and follow the
              insurer’s claim procedure, which may include an inspection of the damage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(5)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"
                aria-hidden="true"></i>
              5. Can I renew my bike insurance online?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">Yes, you can renew your policy by
              visiting
              our website, paying the premium, and updating all necessary information.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(6)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"
                aria-hidden="true"></i>
              6. How is the premium for bike insurance calculated?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }"> Premiums are calculated based on
              factors such as the bike's make and model, age, RTO, coverage type, and add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(7)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"
                aria-hidden="true"></i>
              7. What is a No Claim Bonus (NCB)?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">NCB is a discount on your premium for
              each claim-free year. It rewards safe riding and reduces your insurance cost upon renewal.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(8)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"
                aria-hidden="true"></i>
              8. What documents are required for buying bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> Common documents include the bike's
              registration certificate (RC), driving license, and identity proof. Some insurers may also require a
              recent photograph of the bike.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(9)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"
                aria-hidden="true"></i>
              9. How can I renew my bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">Bike insurance can be renewed online
              through the insurer’s website, mobile app, or by visiting an insurance office. Ensure to renew it before
              the expiry date to avoid lapses in coverage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(10)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"
                aria-hidden="true"></i>
              10. What are the exclusions in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">Common exclusions include normal wear
              and tear, mechanical breakdowns, damage caused under the influence of alcohol or drugs, and damage due to
              illegal activities.</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid background">
  <div class="container partners_section">
    <div class="card-container ">
      <div class="justify-content">
        <div class="text " style="margin-top: 3%;"> 
          <h1 class="heading">Our 2 Wheeler Insurance Partners</h1>
          <span class="font-body">We work together to provide you the best services.</span>
        </div>

        <div class="container ">
          <div style="text-align: center;">
            <div class="image-row">
              <img *ngFor="let img of images" [src]="img" alt="">
            </div>
            <div class="image-row">
              <img *ngFor="let img of images1" [src]="img" alt="">
            </div>
            <div style="width: 100%; text-align: center;margin-bottom: 20px;">
              <a *ngIf="!showAllImages" class="butn" (click)="showAllImages = true">Show More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->