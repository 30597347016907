// data.service.ts
import { Injectable } from '@angular/core';
import { CommercialVehicleDeatils } from '../Models/vehicle-component.model';

@Injectable({
  providedIn: 'root',
})
export class CommercialVehicleDetailsService {
  private formData: CommercialVehicleDeatils | null = null;
  private formDatas: FormData | null = null;

  setFormData(data: any) {
    this.formData = data;
  }

  setFormDatas(data: FormData) {
    this.formDatas = data;
  }

  getFormDatas(): FormData | null {
    return this.formDatas;
  }

  
  getFormData(): any | null {
    return this.formData;
  }
}
