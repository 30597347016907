<div class="agent-dashboard " style="    position: relative;
left: 2px;">
    <div class="row">
        <div class="col-12 dash-box">
            <div style="background-color: #f3f3f4; padding-left: 18px; padding-right: 18px;" *ngIf="isProfile">

                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="row align-items-center ">
                                <div class="col-md-1 col1">
                                    <img src="../../../assets/svgIcon/agent/agentAvatar.svg" alt="" class="user-img">
                                </div>
                                <div class="col-md-10 cl3  p-2 mt-2 text-left ">
                                    <h4> &nbsp;{{fullname}} </h4>
                                    <span class="act p-1 ml-2" *ngIf="agentStatus == 1"><img
                                            src="../../../assets/svgIcon/dot.svg" alt=""> Active</span>

                                    <span class="act-i p-1 ml-2 text-danger" *ngIf="agentStatus != 1">Inactive</span>
                                    <p *ngIf="agentStatus == 1">&nbsp;&nbsp;Agent ID : {{pospCode}} </p>
                                </div>
                                <div class="col-md-7 vl ">
                                    <div class="row mt-5">
                                        <div class="col-md-3">
                                            <!-- <p><img src="../../../assets/images/call.svg" alt="">&nbsp;Contact Number :</p> -->
                                            <p><img src="../../../assets/images/email.svg" alt="">&nbsp;Email ID :</p>
                                            <p><img src="../../../assets/images/lang.svg" alt="">&nbsp;Spoken Language :
                                            </p>
                                        </div>
                                        <div class="col-md-9">
                                            <!-- <p class="pt">{{mobile}}</p> -->
                                            <p class="pt">{{email}}</p>
                                            <p class="pt">{{languageText}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="dropdown card-type selected-card rounded1">
                                        <button class="btn btn-primary-outline dropdown-toggle py-2 text-main"
                                            type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="../../assets/svgIcon/PhoneCall.svg"
                                                class="width-20 mr-3 icon-main" alt=""> Need Help?
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a class="dropdown-item text-main">
                                                    <img src="../../assets/svgIcon/PhoneCall.svg"
                                                        class="width-20 mr-3 icon-main" alt="">
                                                    +91 9829080258
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item text-main">
                                                    <img src="../../assets/images/mail.png"
                                                        class="width-20 mr-3 icon-main" alt="">
                                                    Info@srggroup.net
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item text-main">
                                                    <img src="../../assets/svgIcon/WhatsappLogo.svg"
                                                        class="width-20 mr-3 icon-main" alt="">
                                                    +91 9829080258
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-4">
                        <div class="card p-5">
                            <div class="row">
                                <div class="col-md-12 mt-1 p-1"><span>
                                        Start earning with Click4bima <br>Complete the simple steps to start earning.
                                    </span>
                                </div>
                                <div class="col-md-1 mt-5  mb-2">
                                    <div class="card">
                                        <p><img class="hw-22" src="../../../assets/svgIcon/workflow/approved.svg"
                                                alt="Verified" title="Verified"> </p>
                                    </div>
                                </div>
                                <div class="col-md-11 mt-4  mb-2">
                                    <p>Step.1 :Enter Basic Details</p>
                                </div>
                                <div class="col-md-1 mt-4  mb-2">
                                    <div class="card">
                                        <p><img class="hw-22"
                                                src="../../../assets/svgIcon/workflow/{{kycStatus == 1 ? 'approved' : (kycStatus == 2 ? 'underVerify' : (kycStatus == 3 ? 'rejected' : 'initial' ))}}.svg"
                                                alt="{{kycStatus == 1 ? 'Verified' : (kycStatus == 2 ? 'Under Verification' : (kycStatus == 3 ? 'Rejected' : 'Update' ))}}"
                                                title="{{kycStatus == 1 ? 'Verified' : (kycStatus == 2 ? 'Under Verification' : (kycStatus == 3 ? 'Rejected' : 'Update' ))}}">
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-11 mt-4  mb-2 ">
                                    <p>Step.2 :Complete KYC</p>
                                </div>
                                <div class="col-md-1 mt-4  mb-2">
                                    <div class="card">
                                        <p><img class="hw-22"
                                                src="../../../assets/svgIcon/workflow/{{bankStatus == 1 ? 'approved' : (bankStatus == 2 ? 'underVerify' : (bankStatus == 3 ? 'rejected' : 'initial' ))}}.svg"
                                                alt="{{bankStatus == 1 ? 'Verified' : (bankStatus == 2 ? 'Under Verification' : (bankStatus == 3 ? 'Rejected' : 'Update' ))}}"
                                                title="{{bankStatus == 1 ? 'Verified' : (bankStatus == 2 ? 'Under Verification' : (bankStatus == 3 ? 'Rejected' : 'Update' ))}}">
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-11  mt-4  mb-2">
                                    <p>Step.3 :Enter Bank Details</p>
                                </div>
                                <div class="col-md-1 mt-4  mb-2">
                                    <div class="card">
                                        <p><img class="hw-22"
                                                src="../../../assets/svgIcon/workflow/{{trainingStatus == 1 ? 'approved' : (trainingStatus == 2 ? 'underVerify' : (trainingStatus == 3 ? 'rejected' : 'initial' ))}}.svg"
                                                alt="{{trainingStatus == 1 ? 'Verified' : (trainingStatus == 2 ? 'Under Verification' : (trainingStatus == 3 ? 'Rejected' : 'Update' ))}}"
                                                title="{{trainingStatus == 1 ? 'Verified' : (trainingStatus == 2 ? 'Under Verification' : (trainingStatus == 3 ? 'Rejected' : 'Update' ))}}">
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-11  mt-4  mb-2">
                                    <p>Step.4 :Complete Training</p>
                                </div>
                                <div class="col-md-1 mt-4  mb-2">
                                    <div class="card">
                                        <p><img class="hw-22"
                                                src="../../../assets/svgIcon/workflow/{{examStatus == 1 ? 'approved' : (examStatus == 2 ? 'underVerify' : (examStatus == 3 ? 'rejected' : 'initial' ))}}.svg"
                                                alt="{{examStatus == 1 ? 'Verified' : (examStatus == 2 ? 'Under Verification' : (examStatus == 3 ? 'Rejected' : 'Update' ))}}"
                                                title="{{examStatus == 1 ? 'Verified' : (examStatus == 2 ? 'Under Verification' : (examStatus == 3 ? 'Rejected' : 'Update' ))}}">
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-11  mt-4  mb-2">
                                    <p>Step.5 :Complete Examination</p>
                                </div>
                                <div class="col-md-1 mt-4  mb-2">
                                    <div class="card">
                                        <p><img class="hw-22"
                                                src="../../../assets/svgIcon/workflow/{{this.kycStatus == 1 && this.bankStatus == 1 && this.trainingStatus == 1 && this.examStatus == 1 ? 'approved' : 'initial'}}.svg">
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-11  mt-4  mb-2">
                                    <p>Step.6 :Start Earning</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card p-5">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row cursor-pointer" (click)="goto('Aadhar-kyc')">
                                        <div class="col-md-1">
                                            <div class="card">
                                                <img src="../../../assets/svgIcon/agent/kycverify.svg" class="" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-7 col7">
                                            <h6>KYC Verification</h6>
                                            <span>KYC Documentation</span>
                                        </div>
                                        <div class="col-md-4 text-end align-self-center">
                                            <span class="dot-status-container"
                                                class="{{kycStatus == 1 ? 'bg-green-light text-green' : (kycStatus == 2 ? 'bg-orange-light text-orange' : (kycStatus == 3 ? 'bg-red-light text-red' : 'bg-grey3 text-grey5' ))}}">
                                                <span
                                                    class="dot-status {{kycStatus == 1 ? 'bg-green' : (kycStatus == 2 ? 'bg-orange' : (kycStatus == 3 ? 'bg-red' : 'bg-grey5' ))}}"></span>
                                                {{kycStatus == 1 ? 'Verified' : (kycStatus == 2 ? 'Under Verification' :
                                                (kycStatus == 3 ? 'Rejected' : 'Update' ))}}
                                            </span>
                                            <img src="../../../assets/svgIcon/chevron_right.svg">
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-12">
                                    <div class="row cursor-pointer" (click)="goto('Back-detail')">
                                        <div class="col-md-1">
                                            <div class="card ">
                                                <img src="../../../assets/svgIcon/agent/bankverify.svg" class="" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-7 col7">
                                            <h6>Bank Details</h6>
                                            <span>Banking Details and Documentation</span>
                                        </div>
                                        <div class="col-md-4 text-end align-self-center">
                                            <span class="dot-status-container"
                                                class="{{bankStatus == 1 ? 'bg-green-light text-green' : (bankStatus == 2 ? 'bg-orange-light text-orange' : (bankStatus == 3 ? 'bg-red-light text-red' : 'bg-grey3 text-grey5' ))}}">
                                                <span
                                                    class="dot-status {{bankStatus == 1 ? 'bg-green' : (bankStatus == 2 ? 'bg-orange' : (bankStatus == 3 ? 'bg-red' : 'bg-grey5' ))}}"></span>
                                                {{bankStatus == 1 ? 'Verified' : (bankStatus == 2 ? 'Under Verification'
                                                : (bankStatus == 3 ? 'Rejected' : 'Update' ))}}
                                            </span>
                                            <img src="../../../assets/svgIcon/chevron_right.svg">
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-12">
                                    <div class="row cursor-pointer"
                                        [ngClass]="this.kycStatus == 1 && this.bankStatus == 1 ? 'cursor-pointer' : 'cursor-not-allowed'"
                                        (click)="goto('training')">
                                        <div class="col-md-1">
                                            <div class="card ">
                                                <img src="../../../assets/svgIcon/agent/trainingimg.svg" class=""
                                                    alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <h6>Training</h6>
                                            <span>Get Free Certification Here</span>
                                        </div>
                                        <div class="col-md-4 text-end align-self-center">
                                            <ng-container *ngIf="this.kycStatus == 1 && this.bankStatus == 1">
                                                <span class="dot-status-container"
                                                    class="{{trainingStatus == 1 ? 'bg-green-light text-green' : (trainingStatus == 2 ? 'bg-orange-light text-orange' : (trainingStatus == 3 ? 'bg-red-light text-red' : 'bg-grey3 text-grey5' ))}}">
                                                    <span
                                                        class="dot-status {{trainingStatus == 1 ? 'bg-green' : (trainingStatus == 2 ? 'bg-orange' : (trainingStatus == 3 ? 'bg-red' : 'bg-grey5' ))}}"></span>
                                                    {{trainingStatus == 1 ? 'Completed' : (trainingStatus == 2 ? 'In
                                                    Progress' : (trainingStatus == 3 ? 'Fail' : 'Start' ))}}
                                                </span>
                                                <img src="../../../assets/svgIcon/chevron_right.svg">
                                            </ng-container>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-12">
                                    <div class="row"
                                        [ngClass]="this.kycStatus == 1 && this.bankStatus == 1 && this.trainingStatus == 1  && remainingTime == 0? 'cursor-pointer' : 'cursor-not-allowed'"
                                        (click)="goto('Examination')">
                                        <div class="col-md-1">
                                            <div class="card ">
                                                <img src="../../../assets/svgIcon/exam.svg" class="" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <h6>Examination</h6>
                                            <span>Complete your POSP Examination and Get Free Certification Here</span>
                                        </div>
                                        <div class="col-md-4 text-end align-self-center">
                                            <ng-container
                                                *ngIf="this.kycStatus == 1 && this.bankStatus == 1 && this.trainingStatus == 1">
                                                <span *ngIf="remainingTime > 0 && this.examStatus == 0"
                                                    class="text-main">Exam will start in : {{countdownText}}</span>
                                                <span *ngIf="remainingTime > 0  && this.examStatus == 2"
                                                    class="text-main">Result will declared in : {{countdownText}}</span>
                                                <app-post-certificate [examHeldOn]="examEndAtStr"
                                                    [titleWithName]="(gender =='Male' ? 'Mr. ' : 'Ms.' ) + fullname"
                                                    [certDownloadDate]="todayDate" [pospNumber]="pospCode"
                                                    *ngIf="examStatus == 1"></app-post-certificate>
                                                <span *ngIf="remainingTime == 0" class="dot-status-container"
                                                    class="{{examStatus == 1 ? 'bg-green-light text-green' : (examStatus == 2 ? 'bg-orange-light text-orange' : (examStatus == 3 ? 'bg-red-light text-red' : 'bg-grey3 text-grey5' ))}}">
                                                    <span
                                                        class="dot-status {{examStatus == 1 ? 'bg-green' : (examStatus == 2 ? 'bg-orange' : (examStatus == 3 ? 'bg-red' : 'bg-grey5' ))}}"></span>
                                                    {{examStatus == 1 ? 'Download Certificate' : (examStatus == 2 ?
                                                    'Under Review' : (examStatus == 3 ? 'Fail' : 'Start' ))}}
                                                </span>
                                                <img src="../../../assets/svgIcon/chevron_right.svg">
                                            </ng-container>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="col-md-12">
                                    <div class="row cursor-pointer" (click)="goto('edit')">
                                        <div class="col-md-1">
                                            <div class="card">
                                                <img src="../../../assets/svgIcon/agent/agentedit.svg" class="" alt="">
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <h6>Edit Personal Profile</h6>
                                            <span>Change your Personal Details</span>
                                        </div>
                                        <div class="col-md-4 text-end align-self-center">
                                            <img src="../../../assets/svgIcon/chevron_right.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isKYC">
                <app-agent-kyc-verification></app-agent-kyc-verification>
            </div>
            <div *ngIf="isBank">
                <app-agent-bank-details></app-agent-bank-details>
            </div>
            <div class="overlay" *ngIf="edit">
                <div class="popup2 col-md-3 col-11">
                    <div class="modal-header tooltip_">
                        <!-- <h4 class="modal-title">Sign In</h4> -->
                        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)=" close()">
                            <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                        </button> -->
                        <h4 class="modal-title">{{tabs == '1' ? 'Edit Agent Info' : 'Reset Password'}}</h4>
                        <button type="button" class="close pull-right close1" aria-label="Close" (click)="close()">
                            <span aria-hidden="true"></span>
                        </button>
                        <!-- <button type="button" class="close btn pull-right mt-1" aria-label="Close" (click)=" close()">
                            <img src="../../../assets/svgIcon/crs.svg" alt="">      </button> -->
                    </div>
                    <div class="model-body p-3">
                        <div *ngIf="tabs=='1'">
                            <form class="form" novalidate="novalidate" [formGroup]="saveProfileForm">
                                <div class=" row text-center">
                                    <!-- <div class="col-md-12 mt-3 text-start">
                                        <h4>Edit Agent Info</h4>
                                        <hr>
                                    </div> -->
                                    <div class="col-md-12 mt-5">
                                        <input type="text" class="form-control input_" name="firstName"
                                            (keypress)="alphabetWithSpaces($event)" placeholder=" "
                                            formControlName="firstName" value="{{fullname}}">
                                        <label class="float-start required label_">First Name</label>
                                        <Span class="text-danger error_span"
                                            *ngIf="((saveProfileFormControl['firstName'].touched) && saveProfileFormControl['firstName'].errors?.['required'])">
                                            First name is required.
                                        </Span>
                                        <Span class="text-danger error_span"
                                            *ngIf="((saveProfileFormControl['firstName'].touched) && saveProfileFormControl['firstName'].errors?.['pattern'])">
                                            Alphabets & space allowed only.
                                        </Span>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <input type="text" class="form-control input_" name="lastName"
                                            (keypress)="alphabetWithSpaces($event)" placeholder=" "
                                            formControlName="lastName">
                                        <label class="float-start required label_">Last Name</label>
                                        <Span class="text-danger error_span"
                                            *ngIf="((saveProfileFormControl['lastName'].touched) && saveProfileFormControl['lastName'].errors?.['required'])">
                                            Last name is required.
                                        </Span>
                                        <Span class="text-danger error_span"
                                            *ngIf="((saveProfileFormControl['lastName'].touched) && saveProfileFormControl['lastName'].errors?.['pattern'])">
                                            Alphabets & space allowed only.
                                        </Span>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <input type="email" class="form-control input_" name="userEmail" placeholder=" "
                                            placeholder=" " formControlName="userEmail" value="{{email}}">
                                        <label class="float-start required label_">Email</label>
                                        <Span class="text-danger error_span"
                                            *ngIf="((saveProfileFormControl['userEmail'].touched) && saveProfileFormControl['userEmail'].errors?.['required'])">
                                            Email is required.
                                        </Span>
                                        <Span class="text-danger error_span"
                                            *ngIf="((saveProfileFormControl['userEmail'].touched) && saveProfileFormControl['userEmail'].errors?.['pattern'])">
                                            Please provide valid email address.
                                        </Span>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <input type="text" class="form-control input_" name="phoneNumber"
                                            placeholder=" " formControlName="phoneNumber" [readOnly]="true">
                                        <label class="float-start label_">Phone Number</label>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <!-- <input type="email" class="form-control" name="language"
                                            placeholder="Spoken Language Ex : Hindi, English" formControlName="language"
                                            value="{{language}}"> -->
                                        <!-- <select class="form-select form-select-custom input_" aria-label="Default select example"  formControlName="language">
                                                <option value=""> Select Language</option>
                                                <option *ngFor="let items of languageList" [value]="items.value">{{items.text}}</option>
                                            </select> -->
                                        <app-multi-dropdown class="w-100 multiselect_custom input_"
                                            [items]="languageList" [showSearch]="true" [showAll]="false"
                                            [showStatus]="false" [showError]="false"
                                            (itemChange)="onMultiSelectChange($event)" placeholder=" " id="self">
                                        </app-multi-dropdown>
                                        <label class="float-start required label_">Language</label>
                                    </div>
                                    <!-- <div class="col-md-6 mt-5">
                                <button class="btn btn-outline-warning btm-sm" id="btn">
                                    Cancel
                                </button>
                            </div> -->
                                    <div class="col-md-6  text-center mt-5">
                                        <button class="  bt-s btn-sm " style="margin-bottom: 5%;" type="submit" id="btn"
                                            (click)="reload()">
                                            Cancel
                                        </button>
                                    </div>
                                    <div class="col-md-6  text-center mt-5">
                                        <button class=" btn btn-primary  btn-sm" style="margin-bottom: 5%;"
                                            type="submit" id="btn" (click)="updateAgentInfo()"
                                            [disabled]="saveProfileForm.invalid">
                                            Update
                                        </button>
                                    </div>

                                    <div class="col-md-12 ">

                                        <button class="btn-sm bt-c" (click)="changeTab('2')">
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="tabs=='2'">
                            <form class="form" novalidate="novalidate" [formGroup]="resetPasswordForm">
                                <div class="row text-start">
                                    <div class="col-md-12 mt-3"
                                    [ngClass]="{'mb-5': ((resetPasswordFormControl['oldPassword'].touched  || resetFormSubmitted) && !resetPasswordFormControl['oldPassword'].valid)}">
                                        <input type="password" name="" placeholder=" " class="form-control input_" formControlName="oldPassword"
                                        [ngClass]="{'border border-danger': ((resetPasswordFormControl['oldPassword'].touched  || resetFormSubmitted) && !resetPasswordFormControl['oldPassword'].valid)}">
                                        <label class="float-start required label_">Current Password</label>
                                        <Span class="text-danger error_span"
                                            *ngIf="((resetPasswordFormControl['oldPassword'].touched  || resetFormSubmitted) && resetPasswordFormControl['oldPassword'].errors?.['required'])">
                                            Current Password is required
                                        </Span>
                                    </div>
                                    <div class="col-md-12 group mt-3"
                                    [ngClass]="{'mb-2': ((resetPasswordFormControl['newPassword'].touched  || resetFormSubmitted) && !resetPasswordFormControl['newPassword'].valid)}">                                        
                                        <input [type]="'password'" class="form-control input_" formControlName="newPassword" placeholder=" "
                                            onkeypress="if(this.value.length=='16') return false" (keypress)="passKeyPress($event)" (keyup)="passKeyUp($event)"
                                            [ngClass]="{'border border-danger': ((resetPasswordFormControl['newPassword'].touched  || resetFormSubmitted) && !resetPasswordFormControl['newPassword'].valid)}">
                                        <label class="float-start required label_">New Password</label>
                                        <Span class="text-danger error_span"
                                            *ngIf="((resetPasswordFormControl['newPassword'].touched  || resetFormSubmitted) && resetPasswordFormControl['newPassword'].errors?.['required'])">
                                            New Password is required
                                        </Span>
                                        <div class="d-flex w-100">
                                            <div class="power-container my-2"> 
                                                <div id="power-point" [style.width.%]="passBarWidth" [style.background-color]="passBarBGColor"></div> 
                                            </div>
                                            <span class="ml-2" [style.color]="passBarBGColor">{{passBarText}}</span>
                                        </div>
                                        <div class="text-grey4">Password must have : At least 8 characters, Uppercase and lowercase character, special character, number</div>
                                    </div>
                                    <div class="col-md-12 mt-5"
                                        [ngClass]="{'mb-2': ((resetPasswordFormControl['confirmNewPassword'].touched  || resetFormSubmitted) && !resetPasswordFormControl['confirmNewPassword'].valid)}">
                                        <input type="password" placeholder=" " class="form-control input_" formControlName="confirmNewPassword"
                                        [ngClass]="{'border border-danger': ((resetPasswordFormControl['confirmNewPassword'].touched  || resetFormSubmitted) && !resetPasswordFormControl['confirmNewPassword'].valid)}">
                                        <label class="float-start required label_">Confirm New Password</label>
                                        <Span class="text-danger error_span"
                                            *ngIf="((resetPasswordFormControl['confirmNewPassword'].touched  || resetFormSubmitted) && resetPasswordFormControl['confirmNewPassword'].errors?.['required'])">
                                            Confirm Password is required
                                        </Span>
                                        <Span class="text-danger error_span"
                                            *ngIf="resetPasswordFormControl['confirmNewPassword'].valid && resetPasswordFormControl['newPassword'].valid && resetPasswordFormControl['confirmNewPassword'].value != resetPasswordFormControl['newPassword'].value">
                                            New password and confirm password must be same
                                        </Span>
                                    </div>
                                    <div class="col-md-6 mt-5 text-center">
                                        <button class="  bt-s btn-sm " type="submit" id="btn" (click)="changeTab('1')"> Cancle</button>
                                    </div>
                                    <div class="col-md-6  text-center mt-5">
                                        <button class=" btn btn-primary  btn-sm" type="submit" [disabled]="!resetPasswordForm?.valid || passBarWidth != '100' || resetPasswordFormControl['confirmNewPassword'].value != resetPasswordFormControl['newPassword'].value" (click)="resetPassword()">
                                            Reset Password
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <app-agentfooter></app-agentfooter>
            </div>
        </div>
    </div>
</div>

<div class="overlay" *ngIf="BankPopup">
    <div class="popup1 col-md-3 col-12">
        <div class="modal-header tooltip_">

            <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
            </button>
        </div>
        <div class="modal-body ">
            <div class="row">
                <div class="col-md-12 text-center">
                    <img src="../../../assets/images/wrong.png" width="40%" alt="">
                </div>
                <div class="col-md-12 text-center p-4 ">
                    <h4>
                        Complete your KYC Details before move on Back Details
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="examPopup">
    <div class="popup1 col-md-3 col-12">
        <div class="modal-header tooltip_">

            <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
            </button>
        </div>
        <div class="modal-body ">
            <div class="row">
                <div class="col-md-12 text-center">
                    <img src="../../../assets/images/wrong.png" width="40%" alt="">
                </div>
                <div class="col-md-12 text-center p-4 ">
                    <h4>
                        Complete your Bank Details before move on Exam Training
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>