import { Component } from '@angular/core';
import { FormBuilder,FormGroup,Validator, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent {
  constructor(private fb:FormBuilder,private router:Router){
this.proposerPublicSave=this.fb.group({
"organization":['',[Validators.required]],
"email":['',[Validators.required]],
"mobile":['',[Validators.required]],
"DOB":['',[Validators.required]],
"pan_card":['',[Validators.required]],
"GSTIN":['',[Validators.required]]
});

this.proposerPrivateSave=this.fb.group({
  "ownerName":['',[Validators.required]],
  "email1":['',[Validators.required]],
  "mobile1":['',[Validators.required]],
  "DOB1":['',[Validators.required]],
  "pan_card1":['',[Validators.required]],
  "GSTIN1":['',[Validators.required]]
  });

  this.addressSave=this.fb.group({
    "flatNumber":['',[Validators.required]],
    "address":['',[Validators.required]],
    "pinCode":['',[Validators.required]],
    "state":[''],
    "city":['']
  });

  this.nomineeSave=this.fb.group({
    "nomineeName":['',[Validators.required]],
    "relation":['',[Validators.required]],
    "age":['',[Validators.required]],
    "gender":['',[Validators.required]],

  });
  this.vechileSave=this.fb.group({
    "registration":['',[Validators.required]],
    "engine":['',[Validators.required]],
    "chassis":['',[Validators.required]],
    "registration_date":['',[Validators.required]],
  });
  this.policySave=this.fb.group({
    "ex_policy_com":['',[Validators.required]],
    "ex_policy_type":['',[Validators.required]],
    "ex_policy_num":['',[Validators.required]],
    "ex_policy_date":['',[Validators.required]],
  });
  this. getAge();
  }
  tabs:string='';
  first:boolean=true;
  private:boolean=false;
  public:boolean=true;
  type:string='public';
  proposerPublicSave:FormGroup;
  proposerPrivateSave:FormGroup;
  addressSave:FormGroup;
  nomineeSave:FormGroup;
  vechileSave:FormGroup;
  policySave:FormGroup;
  ageList:any[];
  ralationship:string[]=['Father','Mother','Brother','Sister','Son','Daughter','Uncle','Aunt','Spouse','Grand Son','Grand Daughter'];
  nextTab(val:string)
  {
    this.tabs=val;
  }
  ownerType(ty:string){
    this.type=ty;
    if(this.type=='public')
    {
      this.public=true;
      this.private=false;
    }
    if(this.type=='private')
    {
      this.public=false;
      this.private=true;
    }
    }
    goTOsummary(){
      this.router.navigate(['/summary-details']);
    }
    async getAge(){
      this.ageList = [];
    for(var i=1;i<=100;i++)
    {
      this.ageList.push(i);
    }
    }
}
