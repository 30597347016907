<app-navbar></app-navbar>
    <div class="container-fluid mt-15">
        <div class="container">
            <div class="card-container container-fluid d-flex" id="crd">
                <div class="card card-item">
                <h2>Disclaimer</h2>
                <p class="mt-5">The information on this site is intended to be useful and informative for the general public. SRG will use reasonable care to ensure that information is accurate at the time it is added on the site. This information is subject to change and updated at any time without prior notice. We will not be held responsible for any detrimental reliance you place on this Site or its content. SRG Insurance Brokers, its officers, directors or employees, or any other person associated with this Site, shall not be liable or responsible for any harm, loss or damage that may arise in connection with use of this Site.</p>
                <p>Every Insurance Policy has limits and exclusions. It is the responsibility of each individual and company to be knowledgeable and aware of the wordings and coverage (s) for their Insurance Policy. The display and use of trade marks in this website does not imply that a license of any kind has been granted. No one has permission to copy, distribute disseminate, commercially exploit, republish, or otherwise communicate any part of such contents in any manner whatsoever without the prior written consent of our office.</p>
                <p>The information and data are generic in nature. Our efforts are to offer unbiased, accurate and responsible data to the best of our ability. We are however, in no manner or form responsible for any discrepancies in the data published on our website.</p>
                <p>There is no intention or purpose of violating any intellectual property or ancillary rights. All information provided on the portal Click4bima is subject to its discretion and is likely to change without any notice. Though, any changes in public utility will be communicated on our portal.</p>
                <p>Although, we have tried to maintain high standards in quality, clarity and accuracy of the material posted on the portal. Click4bima is not legally responsible for the same in any manner what so ever.</p>
                <p>Employees, partners and associated staff of Click4bima are not accountable for any loss, harm or damage that occurs or may occur or arise from the usage of information from the portal. Customers are advised to use their own discretion in such matters.</p>
                <p>It is important to understand that insurance is a subject matter of solicitation and market risks. It is the responsibility of the customer to understand the limitations of insurance policies and the risks involved, and under no circumstance, form or manner, do we take any liability in such cases. We also recommend you to please read the subject and offer documents carefully. The information provided on the portal is for financial and insurance purposes.</p>
                <p>It is a mutual understanding between us and the customers that their association with the portal will be at the customer's sole discretion and risk.</p>
            </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>
