export class gujratiLabel {
    public product: string = 'ઉત્પાદનો';
    public support: string = 'આધાર';
    public raise_a_query: string = 'પ્રશ્ન ઉઠાવો';
    public raise_a_claim: string = 'દાવો માટે પૂછો';
    public contact_us: string = 'અમારો સંપર્ક કરો';
    public career: string = 'કારકિર્દી';
    public gallery: string = 'ગેલેરી';
    public login: string = 'લૉગિન';
    public two_wheeler:string='ટુ-વ્હીલર';
    public four_wheeler:string='ફોર-વ્હીલર';
    public health:string='આરોગ્ય';
    public commercial_vehicle:string='કોમર્શિયલ વ્હીકલ';
    public bike_ins_title:string='તમારા ટુ-વ્હીલર ઈન્સ્યોરન્સ પર ખાસ નીચી કિંમત મેળવો';
    public bike_ins_start:string='ટુ-વ્હીલર વીમો ₹635* થી શરૂ';
    public enter_bike_number:string='ટુ-વ્હીલર નંબર દાખલ કરો ઉદાહરણ: AB12CD3456 દાખલ કરો';
    public verify:string='ચકાસો';
    public dont_have_policy:string='નોંધણી નંબર યાદ નથી?';
    public buy_new_bike:string='નવું ટુ-વ્હીલર ખરીદ્યું છે?';
    public what_is_ins1: string = 'વીમો શું';
    public what_is_ins2: string = 'છે?'
    public what_is_ins_title:string='વીમા સાથે મનની શાંતિ શોધો';
    public what_is_ins_text:string='અમારા Click4bima પોર્ટલ પર આપનું સ્વાગત છે, ભવિષ્યની સુરક્ષા માટે તમારું પ્રવેશદ્વાર! અમારું પોર્ટલ વીમા વિકલ્પોની વિશાળ શ્રેણીની શોધ કરવા માટે તમારું એક માત્ર સ્થાન છે. સ્વાસ્થ્ય અને ઓટોથી લઈને ઘર અને મુસાફરી સુધી, અમે પ્રતિષ્ઠિત વીમા કંપનીઓ સાથે ભાગીદારી કરી છે જેથી તમે તમારી જરૂરિયાતોને અનુરૂપ શ્રેષ્ઠ કવરેજ પ્લાન મેળવી શકો. વિવિધ નીતિઓની સરખામણી કરીને, તમે જાણકાર નિર્ણયો લઈ શકો છો અને તમારી જીવનશૈલી અને બજેટને અનુરૂપ હોય તે પસંદ કરી શકો છો. ';
    public why_insurance_imp1: string = 'વીમો શા માટે ';
    public why_insurance_imp2: string = 'મહત્વપૂર્ણ છે?';
    public why_insurance_imp_title:string='આવતીકાલની સુરક્ષા: વીમાની જીવનશક્તિનું અનાવરણ'; 
    public why_insurance_imp_text:string='વીમો એ જીવનની અનિશ્ચિતતાઓ સામે તમારું કવચ છે, જ્યારે તમને  સૌથી વધુ જરૂર હોય ત્યારે તમને માનસિક શાંતિ પ્રદાન કરે છે. તે તમારા અને તમારા પસંદ કરેલા વીમા પ્રદાતા વચ્ચેનો કરાર છે જે અનપેક્ષિત ઘટનાઓના સમયે નાણાકીય સહાયની ખાતરી આપે છે. પછી ભલે તે અચાનક તબીબી ખર્ચ હોય, ઓટોમોબાઈલ દુર્ઘટના, તમારી મિલકતને નુકસાન, અથવા કોઈપણ અણધારી પરિસ્થિતિ જેવાં બોજને હળવો કરવા માટે વીમા પગલાં લે છે.';
    public claim_assistance1: string = 'સહાયનો';
    public claim_assistance2: string = 'દાવો કરો';
    public claim_assistance_title:string='તમારી મનની શાંતિ પુનઃસ્થાપિત';  
    public claim_assistance_text:string="Click4bima તરફથી 'ક્લેમ આસિસ્ટન્સ' સાથે, તમે નિશ્ચિંત રહી શકો છો કે તમારી પાસે વિશ્વાસપાત્ર ભાગીદાર છે જે એક સરળ દાવાની પ્રક્રિયાના મહત્વને સમજે છે. તમારી માનસિક શાંતિ પાછી મેળવો એ જાણીને કે તમે માત્ર એક નીતિથી વધુ સુરક્ષિત છો – તમને કાળજી લેતી ટીમ દ્વારા સમર્થિત છે. જ્યારે તમને તેની સૌથી વધુ જરૂર હોય ત્યારે અમારો 'દાવો સહાય' તમારો વિશ્વાસપાત્ર સાથી છે.";   
    public best_place_ins_title:string='ભારતમાં વીમો ખરીદવા માટે અમને શ્રેષ્ઠ સ્થાન શું બનાવે છે?';
    public best_place_ins_text:string='અમારા Click4bima પોર્ટલ પર આપનું સ્વાગત છે, ભારતમાં શ્રેષ્ઠ વીમા કવરેજ શોધવા માટેનું તમારું વન-સ્ટોપ ડેસ્ટિનેશન. આશ્ચર્ય થાય છે કે શા માટે આપણે બાકીના લોકોથી અલગ છીએ? યોગ્ય વીમા કવરેજના રક્ષણ સાથે ચિંતામુક્ત ભવિષ્યને અપનાવો. આજે જ અમારી સાથે તમારી વીમા યાત્રા શરૂ કરો.';  



    public everytime_support_title: string = '૨૪*૭ સપોર્ટ';
    public everytime_support_text: string = "અમારી 24/7 વીમા સહાયથી ગમે ત્યારે, ગમે ત્યાંથી માનસિક શાંતિ મેળવો. અમારી સમર્પિત ટીમ તમને નિષ્ણાત સહાય પ્રદાન કરવા, તમારા પ્રશ્નોના જવાબ આપવા અને તમે હંમેશા આવરી લેવામાં આવ્યા છો તેની ખાતરી કરવા ચોવીસ કલાક અહીં હાજર છે."  



    public best_price_ins_title:string='શ્રેષ્ઠ કિંમત વીમો'; 
    public best_price_ins_text:string='અમારું મિશન સરળ છે: ગુણવત્તા સાથે સમાધાન કર્યા વિના તમને શ્રેષ્ઠ વીમા સોદા પ્રદાન કરવા. વૈવિધ્યપૂર્ણ નીતિઓની શ્રેણી સાથે, અમે તમારા બજેટને અકબંધ રાખીને તમારા ભવિષ્યની સુરક્ષા માટે અહીં છીએ.';
    public reliable_title:string='ભરોસાપાત્ર';
    public reliable_text:string='કોઈ અપવાદ વિના, તમારા માટે સૌથી વધુ મહત્ત્વની બાબતને સુરક્ષિત કરવા માટે તમે અમારા પર વિશ્વાસ કરી શકો છો. અમારા વચનો પૂરા કરવાના સાબિત ટ્રેક રેકોર્ડ સાથે, અમે તમારી માનસિક શાંતિ, દરેક પગલા પર ખાતરી કરીએ છીએ. તમારા ભવિષ્યને સુરક્ષિત રાખવા માટે અવિશ્વસનીયતા માટે અમને પસંદ કરો.';
    public easy_claim_title:string='દાવો સરળ બનાવ્યો';
    public easy_claim_text:string='અમારી સુવ્યવસ્થિત પ્રક્રિયાઓ અને સમર્પિત સપોર્ટ ટીમ ખાતરી કરે છે કે જ્યારે તમને સૌથી વધુ જરૂર હોય ત્યારે તમને જરૂરી સહાય મળે. અમે પ્રક્રિયાને સરળ બનાવવા માટે પ્રતિબદ્ધ છીએ જેથી કરીને તમે તમારા જીવનમાં જે સૌથી મહત્વપૂર્ણ છે તેના પર ઝડપથી પાછા ફરી શકો.';
    public  mobile_app_title:string='એક જ એપ વડે તમારા પૈસા ચૂકવો, રોકાણ કરો, બચાવો'; 
    public  mobile_app_content:string='ગમે ત્યાં, ગમે ત્યારે તમારી તમામ વીમા જરૂરિયાતોનું નિયંત્રણ મેળવો';  
    public mobile_app_compare:string='વિવિધ વીમા પૉલિસીની સરખામણી કરો'; 
    public mobile_app_track:string='સફરમાં તમારી પોલિસી સ્થિતિને ટ્રૅક કરો'
    public mobile_app_download:string='તમારી પોલિસી એક જ ટેપથી ડાઉનલોડ કરો' ;   
    public mobile_app_coming:string='કંઈક અદ્ભુત માટે તૈયાર રહો - અમારી એપ્સ ટૂંક સમયમાં લોન્ચ થશે!' ;
    public testimonial_title: string = 'અમે અમારા ગ્રાહક અનુભવની પણ કાળજી રાખીએ છીએ';
    public our_partner_title: string = 'અમારા પાર્ટનર્સ';
    public our_partner_text: string = 'અમે તમને શ્રેષ્ઠ સેવાઓ પ્રદાન કરવા માટે સાથે મળીને કામ કરીએ છીએ.';
    public need_help_title: string = 'મદદ જોઈતી ?';
    public need_help_text: string = 'અમે તમને શ્રેષ્ઠ સેવાઓ પ્રદાન કરવા માટે સાથે મળીને કામ કરીએ છીએ. Click4bima પોર્ટલ પર, અમે તમારી વીમા યાત્રાના દરેક પગલા પર અસાધારણ ગ્રાહક સેવા અને સહાય પૂરી પાડવા માટે પ્રતિબદ્ધ છીએ. વીમાની દુનિયામાં નેવિગેટ કરવામાં અમને તમારા વિશ્વાસુ ભાગીદાર બનવા દો. આજે જ અમારી સપોર્ટ ટીમનો સંપર્ક કરો અને તફાવતનો અનુભવ કરો. તમારી મનની શાંતિ અમારી પ્રાથમિકતા છે!';
    public mail_us:string='અમને મેઇલ કરો ';
    public mobile_number:string='મોબાઇલ નંબર';
    public whatsapp:string='વોટ્સેપ';
    public about_us:string='અમારા વિશે';
    public sitemap:string='સાઇટમેપ';
    public faqs:string='વારંવાર પૂછાતા પ્રશ્નો';  
    public motor_insurance:string='મોટર વીમો';
    public car_insurance :string='કાર વીમો';
    public two_wheeler_insurance :string='ટુ વ્હીલર વીમો ';
    public taxi_insurance :string='ટેક્સી વીમો';
    public commercial_veh_insurance:string='કોમર્શિયલ વ્હીકલ ઈન્સ્યોરન્સ ';
    public third_party_car_ins:string='થર્ડ પાર્ટી કાર વીમો'; 
    public third_party_bike_ins:string='થર્ડ પાર્ટી 2W વીમો';
    public health_insurance:string='આરોગ્ય વીમો';
    public family_health_ins:string='કૌટુંબિક આરોગ્ય પોલિસી';
    public individual_health_ins:string='વ્યક્તિગત આરોગ્ય પોલિસી';
    public group_health_ins:string='ગ્રુપ આરોગ્ય પોલિસી';
    public senior_citizen_health_ins:string='વરિષ્ઠ નાગરિક આરોગ્ય પોલિસી'; 
    public critical_illness_ins:string='ગંભીર બીમારી પોલિસી';
    public arogya_san_ins:string='આરોગ્ય સંજીવની પોલિસી';
    public life_insurance:string='જીવન વીમો ';
    public term_insurance:string='ટર્મ વીમો';
    public single_pay_term_ins:string='સિંગલ પે ટર્મ વીમો'; 
    public group_term_ins:string='ગ્રુપ ટર્મ વીમો';  
    public saving_plans:string='બચત યોજનાઓ';  
    public tax_saving_ins:string='ટેક્સ સેવિંગ પોલિસી';
    public money_back_ins:string='મની બેક પોલિસી';  
    public guaranteed_return_ins:string='ગેરંટીડ રીટર્ન પોલિસી';   
    public ulip:string='યુ એલ આઈ પી';
       
    public pension_plan:string='પેન્શન યોજના';   
    public child_plan:string='બાળ યોજના';     
    public others:string='અન્ય'; 
    public privacy_policy:string='પ્રાઇવસી પોલીસી';  
    public cancellation_refund:string='રદ અને રિફંડ';   
    public term_condition:string='શરતો અને નિયમો'; 
    public disclaimer: string = 'અસ્વીકરણ';
    public grivance_redressal: string = 'નિવારણ';
    public srg_add_title: string = 'એસઆરજી ઇન્સ્યોરન્સ બ્રોકર્સ પ્રાઇવેટ લિમિટેડ';
    public srg_add_detail: string = '2 & 5, વિનાયક કોમ્પ્લેક્સ, દુર્ગા નર્સરી રોડ, ઉદયપુર, રાજસ્થાન, 313001';
    public direct_broker_title: string = 'ડાયરેક્ટ બ્રોકર, લાઇસન્સ નંબર: DB-290';  
    public direct_broker_detail: string = 'માન્યતા: 02.02.2026, CIN: U74140RJ2002PTC017656';
    public  payment_method:string='ચુકવણી પદ્ધતિઓ';  
    public  copyright:string='© કૉપિરાઇટ 2023 Click4bima. બધા હકો અમારી પાસે રાખેલા છે';   
    public continue:string='ચાલુ રાખો';     
    
    public bought_new_bike:string=' નવું ટુ-વ્હીલર લીધું';
    public bought_new_car:string='નવી કાર લીધી';
    public car_ins_title:string='તમારા કાર વીમા પર વિશિષ્ટ રીતે ઓછી કિંમત મેળવો';
    public car_ins_start:string='કાર વીમો ₹2471* થી શરૂ'; 
    public enter_car_number:string='કાર નંબર દાખલ કરો ઉદાહરણ: AB12CD3456 ';  
    public vehicle_detail_form:string='તમારા વાહનની વિગતો';
    public city:string='શહેર'; 
    public rto:string="આરટીઓ";
    public manufacturer:string="ઉત્પાદક"; 
    public model:string="મોડલ"; 
    public fuel_type:string="ઇંધણ પ્રકાર"; 
    public variant:string="વિવિધ"; 
    public reg_year:string="રજીસ્ટ્રેશન વર્ષ"; 
    public get_quotes:string="ક્વોટ્સ મેળવો"; 
    public search_city:string="શહેર શોધો"; 
    public select_city:string="અથવા શહેર પસંદ કરો "; 
    public select_rto:string='શહેર આરટીઓ પસંદ કરો';
    public search_manufacturer:string='શોધ ઉત્પાદક';
    public select_manufacturer:string='અથવા ઉત્પાદક પસંદ કરો'; 
    public search_model:string='મોડેલ શોધો'; 
    public select_model:string='અથવા મોડલ પસંદ કરો'; 
    public search_variant:string='વેરિઅન્ટ શોધો';   
    public select_variant:string='અથવા વેરિઅન્ટ પસંદ કરો';
    public select_year:string='વર્ષ પસંદ કરો';      
    public select_fuel_type:string='ઇંધણનો પ્રકાર પસંદ કરો';      
    public contact_title:string='અમારી સાથે સંપર્કમાં રહો';    
    public contact_text:string='અમે શક્ય તેટલી વહેલી તકે તમારો સંપર્ક કરીશું.'; 
    public full_name:string='પૂરું નામ';    
    public mobile:string='મોબાઇલ નંબર';         
    public interested_in:string='રસ ધરાવે છે'  ;      
    public email:string='ઈમેલ એડ્રેસ';
    public description:string='વર્ણન';               
    public call_me:string='મને પાછા કૉલ કરો';
    public continue_fb:string='ફેસબુક સાથે ચાલુ રાખો';
    public continue_google:string='ગૂગલ સાથે ચાલુ રાખો';  
    public sign_in:string='સાઇન ઇન કરો';
    public password:string='પાસવર્ડ';        
    public remember:string='મને યાદ રાખો';    
    public dont_have_acc:string="કોઈપણ ખાતું નથી";
    public signup_here:string="અહીં સાઇન અપ કરો";        
    public click_here:string="અહીં ક્લિક કરો";  
    public send_otp:string="ઓટીપી મોકલો";   
    public back_login:string="લોગિન પર પાછા જાઓ";   
    public already_hv_acc:string="પહેલેથી જ એક એકાઉન્ટ છે";         
    public signin_here:string="અહીં સાઇન ઇન કરો"; 
    public verification:string="વેરિફિકેશન";
    public verify_text:string="તમારા નંબર +918543871911 પર મોકલેલ 6 અંકનો કોડ દાખલ કરો. તમારો મોબાઈલ નંબર બદલવા માટે અહીં ક્લિક કરો.";  
    public enter_otp:string="ઓટીપી દાખલ કરો";
    public dont_rec_otp:string="જો તમને કોડ મળ્યો નથી! ફરી મોકલો";                             
    public submit:string="સબમિટ કરો";
    public accept_terms:string="તમારું એકાઉન્ટ બનાવીને, તમે અમારા નિયમો અને શરતો સાથે સંમત થાઓ છો";  
    public basic_details:string ="મૂળભૂત વિગતો";    
    public first_name:string ="પ્રથમ નામ";   
    public last_name:string ="છેલ્લું નામ";      
    public dob:string ="જન્મ તારીખ";           
    public referal_code:string ="રેફરલ કોડ";                
    public gender:string ="જેન્ડર";           
    public new_password:string  ="નવો પાસવર્ડ દાખલ કરો";  
    public confirm_old_pass:string  ="નવાપાસવર્ડની પુષ્ટી કરો";          
    public dont_have_policy_popup:string='જો તમને તમારો ટુ-વ્હીલર નંબર યાદ ન હોય, તો તમારા ટુ-વ્હીલરની મૂળભૂત વિગતો મેળવવા માટે તમારી આરસી (નોંધણી પ્રમાણપત્ર) તમારી પાસે તૈયાર રાખો.';                              
    public mobile_app_buy:string='તમારી બધી નીતિઓ ઑનલાઇન ખરીદો, સ્ટોર કરો અને શેર કરો';
    public year:string='વર્ષ';
    
   public your_details:string='તમારી વિગતો દાખલ કરો';
   public name_title:string='શીર્ષક';
  } 