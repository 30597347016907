import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuoteService } from '../Services/quote.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.css']
})
export class QuoteDetailsComponent {
  quoteDetailsList:any =[];

  constructor(private fb: FormBuilder, private quoteService: QuoteService, private spinner: NgxSpinnerService) {
   
  }
  async ngOnInit() {
   await this.GetTempQuoteDetails();
  }

  async GetTempQuoteDetails() {
    this.spinner.show();
    let request = {}
    const response = await this.quoteService.GetTempQuoteDetails();
    if (response?.isSuccess) {
      this.quoteDetailsList = response.result;
    }
    this.spinner.hide();
  }

  async viewDocument(documentTypeId: number) {
    // this.isedit = true;
    // let document = this.userDocumentList.filter((x: { documentTypeId: any; }) => x.documentTypeId == documentTypeId)[0];
    // if (document.documentTypeId > 0) {
    //   this.title = 'View document';
    //   this.documentForm.patchValue(document);
    //   this.baseUpload64FileData = document.documentBase64;
    //   await this.SetFileType();
    // }
  }

}
