<div class="needs-validation">
  <div class="offcanvas-header">
    <h4 class="offcanvas-title" id="offcanvasScrollingLabel">Add Customer</h4>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #closebtn></button>
  </div>
  <div class="offcanvas-body">
    <form id="kt_modal_add_user_form" class="form" [formGroup]="customerForm">
      <div class="flex-column row" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
        <div class="col form-group"
        [ngClass]="{'mb-6': ((customerFormControl['firstName'].touched || submittedCustomerForm) && !customerFormControl['firstName'].valid)}">
          <label class="input-label required" for="name">First Name</label>
          <input type="text" placeholder="First Name" class="form-control" formControlName="firstName"
          [ngClass]="{'border border-danger': ((customerFormControl['firstName'].touched  || submittedCustomerForm) && !customerFormControl['firstName'].valid)}">
          <Span class="text-danger error_span" *ngIf="((customerFormControl['firstName'].touched || submittedCustomerForm) && customerFormControl['firstName'].errors?.['required'])">
              First name is required.
          </Span>
        </div>
        <div class="col form-group"
        [ngClass]="{'mb-6': ((customerFormControl['lastName'].touched || submittedCustomerForm) && !customerFormControl['lastName'].valid)}">
          <label class="input-label required" for="name">Last Name</label>
          <input type="text" placeholder="Last Name" class="form-control" formControlName="lastName"
          [ngClass]="{'border border-danger': ((customerFormControl['lastName'] .touched || submittedCustomerForm ) && !customerFormControl['lastName'].valid)}">
          <Span class="text-danger error_span" *ngIf="((customerFormControl['lastName'] .touched || submittedCustomerForm) && customerFormControl['lastName'].errors?.['required'])">
              Last name is required.
          </Span>
        </div>
        <div class="col form-group"
        [ngClass]="{'mb-6': ((customerFormControl['phoneNumber'] .touched || submittedCustomerForm ) && !customerFormControl['phoneNumber'].valid)}">
          <label class="input-label required" for="mobile">Phone Number</label>
          <div></div>
          <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                              [searchCountryFlag]="false" [onlyCountries]="[CountryISO.India]"
                              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                              [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode"
                              name="phone" formControlName="phoneNumber" [inputId]="'phone_number'"
                              (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber
                              (keydown)="validateNumberInput($event)"  (input)="onInput($event)" [minlength]="10" [maxLength]="10" 
                              [ngClass]="{'border border-danger': ((customerFormControl['phoneNumber'] .touched || submittedCustomerForm ) && !customerFormControl['phoneNumber'].valid)}">

          </ngx-intl-tel-input>
          <Span class="text-danger error_span" *ngIf="((customerFormControl['phoneNumber'] .touched || submittedCustomerForm) && customerFormControl['phoneNumber'].errors?.['required'])">
              Phone number is required.
          </Span>
        </div>
        <div class="col form-group"
        [ngClass]="{'mb-6': ((customerFormControl['userEmail'] .touched || submittedCustomerForm ) && !customerFormControl['userEmail'].valid)}">
          <label class="input-label required" for="Email">Your Email Address</label>
          <input type="text" placeholder="useremailadd@domain.com" class="form-control" formControlName="userEmail"
          [ngClass]="{'border border-danger': ((customerFormControl['userEmail'] .touched || submittedCustomerForm ) && !customerFormControl['userEmail'].valid)}">
          <Span class="text-danger error_span" *ngIf="((customerFormControl['userEmail'] .touched || submittedCustomerForm) && customerFormControl['userEmail'].errors?.['required'])">
              Email is required.
          </Span>
          <Span class="text-danger error_span"
              *ngIf="((customerFormControl['userEmail'] .touched || submittedCustomerForm) && customerFormControl['userEmail'].errors?.['pattern'])">
              Please provide valid email address.
          </Span>
        </div>
        <div class="col form-group"
          [ngClass]="{'mb-6': ((customerFormControl['gender'] .touched || submittedCustomerForm) && customerFormControl['gender'].errors?.['required'])}">
          <label class="input-label required" for="Date">Gender</label><br>
          <input class="my-3 radio-button" type="radio" id="male" name="gender" value="Male" formControlName="gender">
          Male
          &nbsp;&nbsp;
          <input class="my-3 radio-button" type="radio" id="female" name="gender" value="Female" formControlName="gender">
          Female &nbsp;&nbsp;
          <input class="my-3 radio-button" type="radio" id="other" name="gender" value="Other" formControlName="gender">
          Other
          <Span class="text-danger error_span" *ngIf="((customerFormControl['gender'] .touched || submittedCustomerForm) && customerFormControl['gender'].errors?.['required'])">
            Gender is required.
          </Span>  
        </div>
        <div class="col form-group"
        [ngClass]="{'mb-6': ((customerFormControl['dob'] .touched || submittedCustomerForm ) && !customerFormControl['dob'].valid)}">
          <label class="input-label required" for="dob">Date of birth</label>
          <input type="date" placeholder="DD-MM-YYYY" name="dob" autocomplete="off" class="new-user form-control bg-transparent" formControlName="dob" 
          [ngClass]="{'border border-danger': ((customerFormControl['dob'] .touched || submittedCustomerForm ) && !customerFormControl['dob'].valid)}">
          <Span class="text-danger error_span" *ngIf="((customerFormControl['dob'] .touched || submittedCustomerForm) && customerFormControl['dob'].errors?.['required'])">
              Date of birth is required.
          </Span>
        </div>
        <div class="col form-group"
        [ngClass]="{'mb-6': ((customerFormControl['streetAddress'] .touched || submittedCustomerForm ) && !customerFormControl['streetAddress'].valid)}">
          <label class="input-label required" for="Street">Street Address</label>
          <input type="text" placeholder="Street Address" class="form-control" formControlName="streetAddress"
          [ngClass]="{'border border-danger': ((customerFormControl['streetAddress'] .touched || submittedCustomerForm ) && !customerFormControl['streetAddress'].valid)}">
          <Span class="text-danger error_span" *ngIf="((customerFormControl['streetAddress'] .touched || submittedCustomerForm) && customerFormControl['streetAddress'].errors?.['required'])">
              Street address is required.
          </Span>
        </div>
        <div class="col form-group"
        [ngClass]="{'mb-6': ((customerFormControl['pincode'] .touched || submittedCustomerForm ) && !customerFormControl['pincode'].valid)}">
          <label class="input-label required" for="Pincode">Pincode</label>
          <input type="text" placeholder="Pincode" class="form-control" formControlName="pincode" #pinCode  (keypress)="passKeyPress($event)"
                 (blur)="pincodeUserChangeEvent(pinCode)" (input)="onInputChange($event)"
          [ngClass]="{'border border-danger': ((customerFormControl['pincode'] .touched || submittedCustomerForm ) && !customerFormControl['pincode'].valid)}">
          <Span class="text-danger error_span" *ngIf="((customerFormControl['pincode'] .touched || submittedCustomerForm) && customerFormControl['pincode'].errors?.['required'])">
              Pincode is required.
          </Span>  
          <Span class="text-danger error_span"
            *ngIf="((customerFormControl['pincode'].touched  || submittedCustomerForm) && customerFormControl['pincode'].errors?.['pattern'])">
            Provide valid pincode.
          </Span>
        </div>
        <div class="col position-absolute bottom-0" >
          <button class="btn btn-primary w-100" (click)="submit()" [disabled]="customerForm.invalid">Save</button>
        </div>
      </div>

    </form>
  </div>
</div>
