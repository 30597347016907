import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-policy-list-popover',
  styleUrls: ['./customer-policy-list-popover.component.css'],
  template: `<div>
    <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_24_18" title="Edit Policy" (click)="onEditClicked()" *ngIf="this.params.data.isExternalPolicy">
    <img src="../../assets/svgIcon/file-download.svg" alt="Downlaod" class="icon_width_height_24_18" title="Download Document" (click)="onDownloadClicked()">
  </div>`
})

export class CustomerPolicyListPopoverComponent {
ngOnInit() {

}
params: any;
agInit(params: any): void {
  this.params = params;
}
constructor() { }
async onEditClicked() {
  this.params.context.componentMember.edit(this.params.data.policyIdEnc);
}

async onDownloadClicked() {
  this.params.context.componentMember.downloadPolicy(this.params.data.policyIdEnc);
}
}