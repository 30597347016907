import { Component } from '@angular/core';
import { Route,Router ,ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-healthinsurance-plan',
  templateUrl: './healthinsurance-plan.component.html',
  styleUrls: ['./healthinsurance-plan.component.css']
})
export class HealthinsurancePlanComponent {

  constructor(private router:Router,private route:ActivatedRoute){}



 async continue(){
  this.router.navigate(['HealthinsuranceDetails']);
 }
}
