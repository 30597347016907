import { Component } from '@angular/core';

@Component({
  selector: 'app-car-insurance-quote',
  templateUrl: './car-insurance-quote.component.html',
  styleUrls: ['./car-insurance-quote.component.css']
})
export class CarInsuranceQuoteComponent {

}
