import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { UserService } from '../Services/user.service';
import { AppConfig } from '../Shared/AppConfig';
import swal from 'sweetalert2';
import { LoginService } from './../Services/login.service';
import { Router } from '@angular/router';
import { ProfileService } from '../Services/profile.service';
import{FamilyMembers,PropertyDetails,RelationShips,Vehicles,AddressDetails} from '../Models/Profile.model';
@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})
export class AddressesComponent implements OnInit{

  AddressDetailsList:AddressDetails[]=[];
  

  constructor(private fb: FormBuilder, private userservice: UserService,
    private loginService: LoginService,private route: Router,private profileService:ProfileService) {

    }

    ngOnInit() {
      this.GetAddressDetails();
    }
    async GetAddressDetails(){
      this.AddressDetailsList=[];
      const response = await this.profileService.GetAddressDetails();
      if (response?.isSuccess){
        this.AddressDetailsList = response.result?.userAddresses;
      }
    }
}
