<app-navbar></app-navbar>
<div class="container mt-3">
    <div class="row">
        <div class="col-md-12">
            <div class="card px-3 py-1" >
                <div class="row align-items-center">
                    <div class="col-auto align ">
                        <button class="btn-primary btn btn-sm  px-3"><img class="text-white"
                                src="../../../assets/svgIcon/whiteArrow.svg" alt="" (click)="back()"></button>
                    </div>

                    <div class="col align">
                        <label for="" class="p-h">Destination</label>
                        <p class="p-align mb-0">{{requesrData?.destination??'Arab'}}
                        </p>
                    </div>
                    <div class="col align">
                        <label for="" class="p-h">Date</label>
                        <p class="p-align mb-0"> {{ moment(requesrData?.travelStartDate).format("DD MMM, YYYY") ?? moment('2024-03-29T18:23').format("DD MMM, YYYY")}} To {{ moment(requesrData?.travelEndDate).format("DD MMM, YYYY")?? moment('2024-03-29T18:23').format("DD MMM, YYYY")}}
                        </p>
                    </div>
                    <div class="col  align">
                        <label for="" class="p-h">Members</label>
                        <p class="p-align mb-0">{{requesrData?.members?.length??5}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 mb-12">
        <div class="col-md-4">
            <div class="card p-2 ">
                <div class="row scroll  ">
                    <div class="col-md-12 mt-2">
                        <label for="">Policy Term</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div class="col-md-12 mt-2">
                        <label for="">Building Age</label>
                        <select class="form-select" aria-label="Default select example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <h4>Inbuilt Coverage</h4>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">Rent for alternate accomodation</p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">Loss of Rent</p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-4">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">I voluntarily opt-out from the Loss of Rent and Rent for Alternate
                                Accommodation inbuilt coverage</p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <h4>Add-On Covers</h4>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <p class="p-tg b-mr">Terrorism</p>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <div class="col-md-4 mt-2">
                                <label class="mt-2" >Medical Expanses</label>
                            </div>
                            <div class="col-md-6 mt-2">
                                <input type="text" name="" class="form-control" id="">
                            </div>
                            <div class="col-md-4 mt-4">
                                <label class="mt-2">Baggage Loss</label>
                            </div>
                            
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                        Yes
                                    </p>
                                  </div>
                            </div>
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                   No                                    </p>
                                  </div>
                            </div>
                            <div class="col-md-4 mt-4">
                                <label class="mt-2">Loss of Passport</label>
                            </div>
                            
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                        Yes
                                    </p>
                                  </div>
                            </div>
                            <div class="col-md-4 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                    <p class="p-mg">
                                   No                                    </p>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <hr>
                        <h4>Shop on Finance</h4>

                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                (click)="check()">
                            <p class="p-tg b-mr">Is Home currently on finance?</p>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-3" *ngIf="checked">
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <label for="">Financier Name</label>
                                <input type="text" name="" class="form-control" id="" placeholder="Financier Name">
                            </div>
                            <div class="col-md-12 mt-3 ">
                                <label for="">Financed Amount</label>
                                <input type="text" name="" class="form-control" placeholder="₹ Amount" id="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-1 r-0" (click)="this.getQuotes(this.requesrData)">Apply</button>
                </div>
            </div>
        </div>
        <div class="col-md-8 mg">
            <div class="row">
                <div class="col-md-9 mt-5">
                    <h2>Insurance plans for you</h2>
                    <span class="spn">Showing {{quoteList?.length??0}} plans for you</span>
                </div>
                <div class="col-md-3 mt-5">
                    <div class="card p-2 cd">
                        <div class="row">
                            <div class="col-md-3 mt-2 c2">
                                <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending" width="22">
                            </div>
                            <div class="col-md-9 mt-2 c6 ">
                                <select name="" id="" class="sort">
                                    <option selected>Sort by</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let quote of quoteList">
                    <div class="col-md-12 mt-2">
                        <div class="card px-5">                            
                                <div class="row align-items-center">
                                    <div class="col-auto">
                                        <img class="image-stl" src="/assets/images/insurance_companies/{{quote.insuranceCompany}}.png" alt="">
                                    </div>    
                                    <div class="col mt-2">
                                        <span class="spn">Plan Name</span>
                                        <p class="p-h">{{quote.planName}}</p>
                                    </div>    
                                    <div class="col mt-2">
                                        <span class="spn">Medical Expenses</span>
                                        <p class="p-h">{{quote.medicalExpenses}}</p>
                                    </div>
                                    <div class="col mt-2">
                                        <span class="spn">Loss Of Passport</span>
                                        <p class="p-h">{{quote.lossOfPassport}}</p>
                                    </div> 
                                    <div class="col mt-2 text-center">
                                        <span class="text-main font-17-13">₹{{quote.totalPremiumAmount}}</span>  
                                        <span class="gst-span">(GST Included)</span>
                                        <span class="span-link" (click)="showPremiumBreakupPopup(quote)">View Breakup</span>
                                    </div>
                                    <div class="col-auto mt-2 text-end">
                                        <button class="btn btn-primary btn-sm" (click)="getDetails(quote)">
                                            Buy Now
                                        </button>                                        
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>


</div>
<div class="overlay" *ngIf="premiumBreakupPopup">
    <div class="popup2 col-lg-4 col-md-6 col-11">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">Premium Breakup</h4>  
        <button type="button" class="close pull-right " aria-label="Close" (click)="hidePremiumBreakupPopup()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="row">
          <div class="col-auto border-right ">
            <img class="image-stl" src="/assets/images/insurance_companies/{{priceBreakup.insuranceCompany}}.png" alt="">
          </div>
          <div class="col d-flex flex-column align-self-center">
            <span class="d-block mb-1"><span class="spn">Plan Name : </span> {{priceBreakup.planName}}</span>
            <span class="d-block"><span class="spn">Product Name : </span> {{priceBreakup.productName}}</span>
          </div>
        </div>            
        <hr class="mt-0 mb-1">
        <div class="row">
          <div class="col-8 mt-1">
            <h5>Net Premium {{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? '(A + B)' : ''}}</h5>
          </div>
          <div class="col-4 mt-1 text-right">
            <h5>₹ {{priceBreakup.netPremium??'0' | thousandSeparator}}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-8 mt-1">
            <span>GST(18%)</span>
          </div>
          <div class="col-4 mt-1 text-right" >
            <h5 class="text-danger">+ ₹ {{priceBreakup.totalTax??'0' | thousandSeparator}}</h5>
          </div>
        </div>
        <hr class="my-1">
        <div class="row">
          <div class="col-8 mt-1">
            <h5>Final Premium</h5>
          </div>
          <div class="col-4 mt-1 text-right">
            <h5>₹ {{priceBreakup.grossPremium ??'0' | thousandSeparator}}</h5>
          </div>
        </div>
      </div>
  </div>
</div>