<app-navbar></app-navbar>
<div class="container">

    <div class="container-fluid">
        <div class="row content-body">
        
            <div class="row">
                <h1 class="mt-5">Group Health Insurance/ Corporate Health Insurance:</h1>
            </div>
            <div class="top-line"></div>
            <div class="row">
                <div class="mt-5">
                    <p class="font-body">
                        As the name indicates, this is a type of plan that provides coverage to a group of members,
                        usually
                        comprising employees of a company or members of an organization. This insurance plan is also
                        referred to
                        as corporate health insurance.&nbsp;</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">A prime benefit under group health insurance provided by the employers is that
                        the coverage support can
                        be extended to the family members of the employees in some cases.</p>
                    <p></p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits: Group health insurance proves to be beneficial for both, employers as well as
                        employees.&nbsp;</h4>
                </div>
                <div class="mt-5">
                    <p>While the employers gain better benefits at a low cost, tax benefits, employee retention, and
                        motivated
                        employees and the employee gains Flexible health insurance customized as per your medical needs,
                        Immediate cover without any waiting period, Cover for the best medical facilities, Wide cover
                        range with
                        no limit on diseases, Advantage of maternity benefit along with cover for newborn baby expenses.
                    </p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Under group health insurance, one can cover their dependents such as spouse,
                        parents, or children
                        (depending on the plan).</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Good and satisfactory service is importance in health insurance. When choosing
                        a group health insurance
                        plan, it is important to know the services your insurer will provide to your employees.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Under the group health insurance policy coverage for pre-existing conditions.</h4>
                </div>
                <div class="mt-5">
                    <p class="font-body">Documents Required to Buy Health Insurance Online</p>
                    </div>
                    <div class="mt-5">
                        <p class="font-body">There are a few documents that a person should have to buy a health insurance
                    policy online. These are-
                </p></div>
                <div class="mt-5">
                    <p class="font-body">Age proof: Any of the following , Birth Certificate, 10th or 12th mark sheet,
                    Driving License, Passport,
                    Voter&rsquo;s ID, etc.</p></div>
                    <div class="mt-5">
                        <p class="font-body">Identity proof: Either of-Passport, Driving License, Voter ID, PAN Card, Aadhar
                    Card, which proves
                    one&rsquo;s citizenship.</p></div>
                    <div class="mt-5">
                        <p class="font-body">Address proof: Either of-Electricity bill, Ration Card, Telephone Bill, Passport,
                    Driving License with
                    your permanent address clearly mentioned on it.</p></div>
                    <div class="mt-5">
                        <p class="font-body">Medical check-up: Some plans require health check-up to ensure that the individual
                    is not suffering from
                    any chronic illness.</p></div>
                    <div class="mt-5">
                        <p class="font-body">Health Insurance Renewal Online Process - Through the Insurer&rsquo;s Website</p></div>
                        <div class="mt-5">
                            <p class="font-body">Health insurance renewal is an easy process, especially when done online through
                    the insurer&rsquo;s
                    website by following the steps given below:</p></div>
                    <div class="mt-5">
                        <p class="font-body">Step 1: Visit the official website of the insurance company.&nbsp;</p></div>
                        <div class="mt-5">
                            <p class="font-body">Step 2: Go to the health insurance renewal section available on the website.</p></div>
                            <div class="mt-5">
                                <p class="font-body">Step 3: Enter your previous health insurance policy number, registered mobile
                    number and such other
                    details as required at the insurance company Website.</p></div>
                    <div class="mt-5">
                        <p class="font-body">Step 4: Check the policy details and the policy renewal premium and conditions such
                    as tenure, payment
                    options etc.</p></div>
                    <div class="mt-5">
                        <p class="font-body">Step 5: Make the payment using your credit/debit card or Net banking.</p></div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>