import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationModel } from 'src/app/Models/vehicle-component.model';
import { CommercialVehicleDetailsService } from 'src/app/Services/commercial-vehicle-details.service';
import { GeneralService } from 'src/app/Services/general.service';
import { InsuranceService } from 'src/app/Services/insurance.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-commercial-plan-list',
  templateUrl: './commercial-plan-list.component.html',
  styleUrls: ['./commercial-plan-list.component.css']
})
export class CommercialPlanListComponent {


  constructor(private router: Router, private dataService: CommercialVehicleDetailsService, private fb: FormBuilder, private insuranceservice: InsuranceService, private generalService:GeneralService) {
    const jsonData = '{"quoteId":"","isMockUp":false,"VehicleType":3,"proposerDetails":{"firstName":"sumeeet","lastName":"f","email":"s@g.com","mobileNumber":7976341425,"title":"Mr"},"nomineeDetails":null,"vehicleDetails":{"rto":"MH01","make":"A1 SUREJA INDUSTRIES","model":"XPLOSIVE","variant":"60V 42AH","registrationYear":"2020","city":"Ahmedabad","ncbprevious":"0","fuelType":"Electric","isNew":false,"vehiclecategory":"2WN","engineCapacityAmount":"100","ncbCurrent":"20","policyTerm":"1","typeOfCover":"Standalone","paPolicyTerm":"1"},"isAgent":false,"Filter":{}}';
    const data = JSON.parse(jsonData);

    // this.insuranceForm = this.fb.group({
    //   RegistrationNumber:[''],
    //   registrationDate: [''],
    //   isMockUp: [''],
    //   VehicleType: [0],
    //   proposerDetails: this.fb.group({
    //     firstName: [''],
    //     lastName:[''],
    //     email: [''],
    //     mobileNumber: [''],
    //     title: ['']
    //   }),
    //   nomineeDetails: this.fb.group({}),  // You might want to provide default values if needed
    //   vehicleDetails: this.fb.group({
    //     registrationNumber: [''],
    //     chasisNumber: [''],
    //     engineNumber: [''],
    //     make: [''],
    //     rTO: [''],
    //     model: [''],
    //     variant: [''],
    //     registrationYear: [''],
    //     city: [''],
    //     ncbprevious: [''],
    //     fuelType: [''],
    //     isNew: [''],
    //     vehiclecategory: [''],
    //     engineCapacityAmount: [''],
    //     ncbCurrent: [''],
    //     policyTerm: [''],
    //     typeOfCover: [''],
    //     paPolicyTerm: ['']
    //   }),
    //   isAgent: [false],
    //   Filter: null  // You might want to provide default values if needed
    // });

    this.insuranceForm = this.fb.group({
      RegistrationNumber:['MH-01-SS-6543'],
      registrationDate: ['2023-12-01'],
      isMockUp: [data.isMockUp],
      VehicleType: [3],
      proposerDetails: this.fb.group({
        firstName: [data.proposerDetails.firstName],
        lastName: [data.proposerDetails.lastName],
        email: [data.proposerDetails.email],
        mobileNumber: [data.proposerDetails.mobileNumber],
        title: [data.proposerDetails.title]
      }),
      nomineeDetails: this.fb.group({}),  // You might want to provide default values if needed
      vehicleDetails: this.fb.group({
        registrationNumber: ['MH-01-SS-6543'],
        chasisNumber: ['MAT448100B567'],
        engineNumber: ['B591803111C63456'],
        make: ['ASHOK LEYLAND'],
        rto: [data.vehicleDetails.rto],
        model: [data.vehicleDetails.model],
        variant: [data.vehicleDetails.variant],
        registrationYear: [data.vehicleDetails.registrationYear],
        city: [data.vehicleDetails.city],
        ncbprevious: [data.vehicleDetails.ncbprevious],
        fuelType: [data.vehicleDetails.fuelType],
        isNew: [data.vehicleDetails.isNew],
        vehiclecategory: [data.vehicleDetails.vehiclecategory],
        engineCapacityAmount: [data.vehicleDetails.engineCapacityAmount],
        ncbCurrent: [data.vehicleDetails.ncbCurrent],
        policyTerm: [data.vehicleDetails.policyTerm],
        typeOfCover: [data.vehicleDetails.typeOfCover],
        paPolicyTerm: [data.vehicleDetails.paPolicyTerm]
      }),
      isAgent: [data.isAgent],
      Filter: null  // You might want to provide default values if needed
    });

    const savedFormData = this.dataService.getFormDatas();

  if (savedFormData) {
    ;
    this.insuranceForm.patchValue(savedFormData);
  }



  }


  addOn: string[] = ['Consumable Cover', 'Legal liability', 'Tawing Cover', 'Loss of Revenue', 'EMI Protection Cover', 'Parts Depreciation Protect', 'Engine and Gear Box Protect'];
  ExpiryPolicy: string[] = ['Not Expired', 'Expired Within 90 Days'];
  PolicyType: string[] = ['Standard/Comprehensive Coverage', 'Third-Party Liability Coverage', "I don't remember"];
  item = [0, 20, 25, 35, 45, 50];
  selectedExpiryStatus: string | null = null;
  vehicleDetails: any;
  proposerDetails: any;
  quotesFormData: any;

  checked: boolean = false;
  isDetailsPopup: boolean = false;
  insuranceForm: FormGroup;
  planList: any = [];
  apiData:any=[];
  registrationData = new RegistrationModel();

  ngOnInit(): void {
    ;
    //this.quotesFormData = this.dataService.getFormDatas();
    //this.vehicleDetails = this.quotesFormData.vehicleDetails;
    //this.proposerDetails = this.quotesFormData.proposerDetails;
    this.AcquireInsurancePlans();
  }


  async AcquireInsurancePlans() {
    try {
      ;
      this.planList = [];
      let response;

      this.registrationData.rc_number;
      if (this.generalService.getAccessToken() != "") {
        response = await this.insuranceservice.AcquireInsurancePlans(this.insuranceForm.value);
      }
      else {
        response = await this.insuranceservice.GetOpenQuotes(this.insuranceForm.value);
      }

      if (response?.isSuccess) {
        ;
        this.planList = response.result;
      }


      if (this.planList.length == 0) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No Quotes available.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }


  check() {
    if (!this.checked) {
      this.checked = true;
    }
    else {
      this.checked = false;
    }

  }
  details() {
    this.router.navigate(['/commercial-details']);
  }
  close() {

    this.isDetailsPopup = false;
  }
}
