import { Component } from '@angular/core';

@Component({
  selector: 'app-term-insurance',
  templateUrl: './term-insurance.component.html',
  styleUrls: ['./term-insurance.component.css']
})
export class TermInsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
