<div class="header_menu mt-4 mt-md-0" style="display: flex;flex-direction: row-reverse;">
    <button class="navbar-toggler" type="button"  (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse p-0" id="collapsibleNavbar" [ngClass]="{ 'show': navbarOpen }" *ngIf="!isAgent()">
      <div class="navbar_menu_div" [ngClass]="showMenu ? 'navbar_menu_div1' : ''">        
        <ul class="navbar-nav align-items-md-center text-start pl-5 pl-md-0"> 
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
            aria-expanded="false" (click)="menuItems('products')">Products</a>
            <ul class="dropdown-menu mobile_view">
  
              <li><a class="dropdown-item" href="#">Bike Insurance</a></li>
    
              <li><a class="dropdown-item" href="#">Car Insurance</a></li>
    
              <li><a class="dropdown-item" href="#">Health Insurance</a></li>
    
              <li><a class="dropdown-item" href="#">Home Insurance</a></li>
    
              <li><a class="dropdown-item" href="#">Life Insurance</a></li>
    
              <li><a class="dropdown-item" href="#">Term Insurance</a></li>
    
              <li><a class="dropdown-item" href="#">Investment Plans</a></li>
    
            </ul>
          </li> -->
          <!-- <li class="nav-item dropdown">
            <a class="nav-link p-md-0 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">Renew policy</a>
            <ul class="dropdown-menu">
  
              <li class="sub-dropdown">
                <a href="#">Motor Insurance</a>
                <ul class="sub-dropdown-menu">
                    <li><a href="/car-insurance">Car Insurance</a></li>
                    <li><a href="/two-wheeler-insurance">Two Wheeler Insurance</a></li>
                    <li><a href="/taxi-insurance">Taxi Insurance</a></li>
                    <li><a href="/third-party-car-insurance">Commercial Vehicle Insurance</a></li>
                    <li><a href="/third-party-car-insurance">Third Party Car Insurance</a></li>
                    <li><a href="/third-party-two-wheeler-insurance">Third Party 2 W Insurance</a></li>
                </ul>
              </li>
              <li class="sub-dropdown">
                <a href="#">Health Insurance</a>
                <ul class="sub-dropdown-menu">
                    <li><a href="/family-health-insurance">Family Health Policy</a></li>
                    <li><a href="/individual-health-insurance">Individual Health Policy</a></li>
                    <li><a href="/group-health-insurance">Group Health Policy</a></li>
                    <li><a href="/senior-citizen-health-insurance">Senior Citizen Health Policy</a></li>
                    <li><a href="/critical-illness-insurance">Critical Illness Policy</a></li>
                    <li><a href="/arogya-sanjivni-insurance">Arogya Sanjivani Policy</a></li>
                </ul>
              </li>
              <li class="sub-dropdown">
                <a href="#">Life Insurance</a>
                <ul class="sub-dropdown-menu">
                    <li><a href="/term-insurance">Term Insurance</a></li>
                    <li><a href="/single-pay-insurance">Single Pay Term Insurance</a></li>
                    <li><a href="/group-term-insurance">Group Term Insurance</a></li>
                </ul>
              </li>
              <li class="sub-dropdown">
                <a href="#">Saving Plans</a>
                <ul class="sub-dropdown-menu">
                    <li><a href="/tax-saving-insurance">Tax Saving Policy</a></li>
                    <li><a href="/money-back-insurance">Money Back Policy</a></li>
                    <li><a href="/guaranteed-return-insurance">Guaranteed Return Policy</a></li>
                    <li><a href="/ulip-insurance">ULIP</a></li>
                    <li><a href="/pension-insurance">Pension Plan</a></li>
                    <li><a href="/child-insurance">Child Plan</a></li>
                </ul>
              </li>
              <li class="sub-dropdown">
                <a href="#">Others</a>
                <ul class="sub-dropdown-menu">
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/cancellation-refund">Cancellation & Refund</a></li>
                    <li><a href="/term-condition">Terms & Conditions</a></li>
                    <li><a href="/disclaimer">Disclaimer</a></li>
                    <li><a href="/grivance-redressal">Grivance Redressal</a></li>
                </ul>
              </li>
            </ul>
          </li> -->
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">Claim</a>
            <ul class="dropdown-menu">

              <li><a class="dropdown-item" href="#">File a New Claim</a></li>
    
              <li><a class="dropdown-item" href="#">Filed Claim with Insurer</a></li>
    
              <li><a class="dropdown-item" href="#">Claim Type </a></li>
    
              <li><a class="dropdown-item" href="#">Claim Assistance</a></li>
    
    
    
            </ul>
          </li> -->
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">Support</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Raise a Query</a></li>
              <li><a class="dropdown-item" href="#">Raise a Claim</a></li>
              <li><a class="dropdown-item" href="/contactus">Contact Us</a></li>
              <li><a class="dropdown-item" href="/Vacancy">Career</a></li>
            </ul>
          </li>                
          <li class="nav-item">
            <a class="nav-link" href="http://clickfourbima.com/gallery/">Gallery</a>
          </li> -->
          <li class="nav-item" *ngIf="!isUserLogged">
            <a class="nav-link" routerLink="/login">Login</a>
          </li>          
          <li class="nav-item dropdown" *ngIf="isUserLogged">
              <a class="nav-link dropdown-toggle fw-bolder profile-img pr-0 mt-2" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="true"> 
                  <img class="image ml-2 mt-n2" src="../../assets/images/User-avatar.png" alt=""> {{fullname}}
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item px-0" (click)="dashboard()"><img class="mr-3 mt-n2"
                  src="../../assets/nav_icons/Browsers.png" alt=""> Dashboard</a></li>
                <li><a class="dropdown-item px-0" (click)="profile()"><img class="mr-3 mt-n2"
                  src="../../assets/nav_icons/UserCircle.png" alt=""> Profile</a></li>
                <li><a class="dropdown-item px-0" (click)="logout()"><img class="mr-3 mt-n2"
                      src="../../assets/nav_icons/SignOut.png" alt=""> Logout</a></li>
              </ul>
          </li>
                
        </ul>
      </div>
    </div>
    <div *ngIf="isAgent()">
      <a class="nav-link dropdown-toggle fw-bolder profile-img pr-0 mt-2" href="#" role="button" data-bs-toggle="dropdown"
          aria-expanded="true"> 
          <img class="image ml-2 mt-n2" src="../../assets/svgIcon/agent/agentAvatar.svg" alt=""> {{fullname}}
      </a>
      <ul class="dropdown-menu agent-drop">
        <li><a class="dropdown-item px-0" (click)="dashboard()"><img class="mr-3 mt-n2"
          src="../../assets/nav_icons/Browsers.png" alt=""> Dashboard</a></li>
        <li><a class="dropdown-item px-0" (click)="profile()"><img class="mr-3 mt-n2"
          src="../../assets/nav_icons/UserCircle.png" alt=""> Profile</a></li>
        <li><a class="dropdown-item px-0" (click)="logout()"><img class="mr-3 mt-n2"
              src="../../assets/nav_icons/SignOut.png" alt=""> Logout</a></li>
      </ul>
    </div>
    
  </div>

