import { Component } from '@angular/core';

@Component({
  selector: 'app-family-health-policy',
  templateUrl: './family-health-policy.component.html',
  styleUrls: ['./family-health-policy.component.css']
})
export class FamilyHealthPolicyComponent {

}
