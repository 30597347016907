<app-navbar></app-navbar>
<div class="container" style="background-color: rgba(64, 64, 255, 0.05)">
    <div class="container-fluid mt-10">
        <div class="mt-10" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
            <div [ngClass]="{'col-4': !isSmallScreen, 'col-12': isSmallScreen}">
                <div class="vehicle_details" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h4><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"> Your vehicle details</h4>
                    </div>
                    <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/svgIcon/suzuki.svg" alt="">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Vehicle</p>
                            <h5>Suzuki Motorcycle</h5>
                        </div>
                    </div>
                    <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img class="svg_img" src="../../assets/svgIcon/bike_icon.svg" alt="Bike" width="20">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Model</p>
                                    <h5>STD BSVI 125</h5>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img class="svg_img" src="../../assets/svgIcon/city.svg" alt="city" width="27">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">RTO</p>
                                    <h5>RJ14 - Jaipur</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/CalendarBlank.svg" alt="Calendar">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Year</p>
                                    <h5>2014</h5>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Coins.svg" alt="">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Last Year Claim</p>
                                    <h5>NO</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/svgIcon/Percent.svg" alt="">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">NCB Last Year</p>
                            <h5>0%</h5>
                        </div>
                    </div>
                </div>
                <div class="mt-5 extra_coverage" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h4>Add Extra Coverage</h4>
                    </div>
                    <div class="mt-3" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <input type="checkbox">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h5>Personal Accident Cover of Rs. 10 Lakh</h5>
                        </div>
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/svgIcon/Info.svg" alt="">
                        </div>
                    </div>
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <span style="color: #DF3A4D;">PA cover is mandatory</span>
                        </div>
                    </div>
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p><span class="amount">₹180/</span>
                                <span class="year text-center">1 Year</span>
                            </p>
                        </div>
                    </div>
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <input type="checkbox">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h5>Consumable Cover</h5>
                        </div>
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/svgIcon/Info.svg" alt="">
                        </div>
                    </div>
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p><span class="amount">₹320</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mt-5 premium_breakup" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h4>Premium Breakup</h4>
                    </div>
                    <div class="mt-2 border-btm" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Basic Own Damage</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹1,000</h6>
                        </div>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Net Premium</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹1,000</h6>
                        </div>
                    </div>
                    <div class="mt-2 border-btm" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Add GST(18%)</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹180</h6>
                        </div>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Final Premium</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹1,180</h6>
                        </div>
                    </div>
                </div>
            </div>&nbsp;&nbsp;&nbsp;
            <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <h2 class=text-start>Policy Details</h2>
                </div>
                <div class="details_card mt-2">
                    <div class="justify-content-between" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <label for="name">Registration Number</label>
                            <input type="text" class="form-control" placeholder="GJ11 CG6814">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <label for="name">Previous Insurer</label>
                            <select name="" id="" class="form-select">
                                <option value="select">Select</option>
                                <option value="1">1</option>
                                <option value="1">1</option>
                                <option value="1">1</option>
                            </select>
                        </div>
                    </div>
                    <div class="justify-content-between mt-3" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <label for="name">Previous Policy Number</label>
                            <input type="text" class="form-control" placeholder="User Mobile Number">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <label for="name">Third party policy expiry date</label>
                            <input type="text" class="form-control" placeholder="User PanCard">
                        </div>
                    </div>
                    <div class="justify-content-between mt-3" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <label for="name">Vehicle registration date</label>
                            <input type="text" class="form-control" placeholder="1 Feb 2021">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        </div>
                    </div>

                    <div class="mt-10" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h3 class=text-start>Is your vehicle financed/ on loan? </h3>
                    </div>
                    <div class="justify-content-between mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <label for="name">Company which has provided the loan?</label>
                            <input type="text" class="form-control" placeholder="Company">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <label for="name">City where the loan was taken</label>
                            <input type="text" class="form-control" placeholder="City">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="bottm_bar"> 
        <div class="container-fluid">
            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                    <p class="font-body">Net premium</p>
                    <h1 class="text-start">₹1,180</h1>
                </div>
                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div class="justify-content-end d-flex" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <button class="btn">Help?</button>
                        </div>
                        <div class="btn-continue" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <button class="btn btn-primary">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
