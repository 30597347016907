import { Component,OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LookUpService } from '../../Services/lookup.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.css']
})
export class VacancyComponent implements OnInit{
  careerForm: FormGroup;
  openItemIndex = -1;
  fileToUpload: File | undefined;
  baseUpload64FileData: string = "";
  format: string = '';
  fileName:string ="Upload Your Resume";



  constructor(private fb: FormBuilder, private lookUpService:LookUpService) {
    this.careerForm = this.fb.group({
      "fullName": ['', [Validators.required]],
      "mobileNumber": ['', [Validators.required]],
      "location": ['', [Validators.required]],
      "totalExperience": ['', [Validators.required]],
      "uploadResume": ['',[Validators.required] ],
    });



  // userName = new UntypedFormControl("", [Validators.required]);
  // phoneNumber = new UntypedFormControl("", [Validators.required]);
  // location = new UntypedFormControl("", [Validators.required]);
  // experience = new UntypedFormControl("", [Validators.required]);
  // uploadResume = new UntypedFormControl("", [Validators.required]);
  // constructor(private router: Router){

  }
  ngOnInit() {
    
  }
  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  async submitCareerDetails() {
    this.careerForm.value.uploadResume =this.baseUpload64FileData;
    const res = await this.lookUpService.SaveCareerDetail(this.careerForm.value);
    if (res !=null  && res.result)  {
      this.careerForm.reset();
      this.careerForm.value.uploadResume ="";
    Swal.fire("Thank you", "your Profile has been successfully updated", "success");
    }

  }

  async onSelectFile(file: any) {
    this.fileToUpload = file.target.files.item(0);
    this.fileName = file.target.files.item(0).name;
    if (this.fileToUpload?.type.includes('image') && this.fileToUpload!.size > 500000) {
      this.baseUpload64FileData = '';
      this.careerForm.get('uploadResume')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 500kb',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else if (this.fileToUpload?.type.includes('pdf') && this.fileToUpload!.size > 5000000) {
      this.baseUpload64FileData = '';
      this.careerForm.get('uploadResume')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 5MB',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else if (this.fileToUpload?.type.includes('video') && this.fileToUpload!.size > 50000000) {
      this.baseUpload64FileData = '';
      this.careerForm.get('uploadResume')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 50MB',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else {
      let reader = new FileReader();
      reader.onload = async (event: any) => {
        this.baseUpload64FileData = reader.result!.toString();
        this.careerForm.get('uploadResume')?.setValue(this.baseUpload64FileData);
        await this.SetFileType();
      }
      reader.readAsDataURL(this.fileToUpload!);
    }
  }

  async SetFileType() {
    if (this.baseUpload64FileData.includes(`application/pdf`)) {
      this.format = 'pdf';
    }
    else if (this.baseUpload64FileData.includes(`image`)) {
      this.format = 'image';
    }
    else if (this.baseUpload64FileData.includes(`video`)) {
      this.format = 'video';
    }
  }
  crsButton:boolean=false;
  showCross(){
    if(this.careerForm.get('uploadResume')?.value!='')
      {
        this.crsButton=true
      }
      else
      this.crsButton=false;
  }
  hideCross(event:any){
    this.crsButton=false;
    this.fileName='Upload Your Resume';
    this.careerForm.get('uploadResume')?.setValue('');
  }

}
