<div class="container">
    <div class="row mt-10">
        <div class="col-md-8">
            <div class="row mt-10">
<div class="col-md-12 mt-4">
    <div class="row">
        <div class="card p-3">
            <div class="row l-bm">
                <div class="col-md-8 mt-4">
                    <h4>Link Bank Account </h4>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="row">
                        <div class="col-md-5 mt-2">
                            <button class="btn btn-sm btn-back" (click)="back()" >Back</button>
                        </div>
                        <div class="col-md-7 mt-2">
                            <button class="btn btn-sm btn-primary"[disabled]="BankDetails.invalid" (click)="success()">Submit</button>
                        </div>
                    </div>
                </div>

            </div>
   
            <form [formGroup]="BankDetails">
                <div class="row mt-3">
                    <div class="col-md-6 mt-3">
                        <label for="">Account Number</label>
                        <input type="password" name="" class="form-control" id="" placeholder="Account number" formControlName="AccountNumber">
                    </div>
                    <div class="col-md-6 mt-3">
                        <label for="">Confirm Account Number</label>
                        <input type="number" name="" class="form-control" id=""  placeholder="Confirm Account number" formControlName="ConfirmAccount">
                    </div>
                    <div class="col-md-6 mt-3">
                        <label for="">Account Holder Name</label>
                        <input type="text" name="" class="form-control" id=""  placeholder="Account Holder name"  formControlName="HolderName"> 
                    </div>
                    <div class="col-md-6 mt-3">
                        <label for="">IFSC Code</label>
                        <input type="text" name="" class="form-control" id=""  placeholder="IFSC code" formControlName="IFSC"> 
                    </div>
                    <div class="col-md-6 mt-3">
                        <label for="">Bank Name</label>
                        <input type="text" name="" class="form-control" id=""  placeholder="Bank name" formControlName="BankName">
                    </div>
                    <div class="col-md-6 mt-3">
                        <label for="">Branch Name</label>
                        <input type="text" name="" class="form-control" id=""  placeholder="Branch name" formControlName="BranchName">
                    </div>
                    <div class="col-md-12 mt-3">
                        <div class="row">
                        <label for="">Cancelled Cheque / Passbook / Bank Statement</label>
                        <div class="col-md-6 mt-5 text-start">
                            <label for="front-file-upload" class="custom-file-upload text-center">
                                <img src="../../../assets/svgIcon/uplod.svg" alt="" width="100%">
                                <p class="mt-3 p-2">Upload </p>
            
                            </label>
                            <input id="front-file-upload" type="file" accept="image/*" formControlName="statement">
                        </div>
                        <label for=""><span style="color:red">Note :</span> Uploaded documents should clearly show account holder name, <br> account number, and IFCS code.</label>
                    </div>
                    </div>
                </div>
                </form>
</div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<div class="overlay" *ngIf="Success">
    <div class="popup2">
        <div class="modal-header  bg-transparent">
            <!-- <h4 class="modal-title">Sign In</h4> -->
            <button type="button" class=" close pull-right" aria-label="Close" (click)=" close()">

            </button>
        </div>
        <div class="model-body">
            <div class=" row text-center">
                <div class="col-md-12 mt-3 p-3 text-center">
                    <img src="../../../assets/images/green_double_circle_check_mark 1.png" alt="">
                </div>
                <div class="col-md-12 mt-3">
                    <h6>""Bank Verification Successfully Done""</h6>
                </div>
                <div class="col-md-12 mt-3">
                    <span>Congratulations! You've successfully Completed the Bank Verification</span>
                </div>
                <div class="col-md-12 mt-2">
                    <button class="btn btn-sm btn-primary" (click)="back()">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>