import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ownership-transfer',
  templateUrl: './ownership-transfer.component.html',
  styleUrls: ['./ownership-transfer.component.css']
})
export class OwnershipTransferComponent {
  isOwnerShipTransferred?: string = "No";
 @Input() data_isOwnerShipTransferred:string;
 @Input() quote:any;
 @Input() IsMockup:boolean;
 @Input() isTPOnly:boolean;
 @Input() isTPPD:boolean;

 @Output() action = new EventEmitter<any>();

 closeOwnershipTransferredModal(){
  this.action.emit({isOwnerShipTransferred:this.isOwnerShipTransferred,quote:this.quote,IsMockup:this.IsMockup,isTPOnly:this.isTPOnly,isTPPD:this.isTPPD});
 }
}
