<div class="user-dashboard">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
      type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
    <div class="card-user user-route">
      <div class="row">
        <div class="col">
            <h1>Import Policy</h1>
        </div>
        <div class="col text-right">
          <button class="btn btn-sm btn-primary" (click)="addPolicy()">Add New Offline Policy</button>          
          <button class="btn btn-sm btn-primary ml-3" (click)="purchasePolicy()">Purchase New Policy</button>
        </div>
      </div>
      <div class="card card-body">
        <form id="kt_account_profile_details_form" class="form" [formGroup]="filterForm" novalidate="novalidate">
          <div class="row mb-5">
            <div class="col-md-3 mt-3">
              <label for="agentId" class=" required">Agent </label>
              <app-autocomplete class="w-100" (itemSelected)="onAgentSelection($event)"
              [items]="agentList" placeholder="Search and select agent"
              [userInput]="agentName"
              [showError]="((filterFormControl['agentId'].touched) && filterFormControl['agentId'].errors?.['required'])"></app-autocomplete>
              <span class="text-danger" *ngIf="((filterFormControl['agentId'].touched) && filterFormControl['agentId'].errors?.['required'])">
                  Agent is required.</span>
            </div>
            <div class="col-md-2 mt-3">
              <label for="year" class="required">Year</label>
              <select name="year" id="year" class="form-select form-select-custom"
                  formControlName="year" (change)="loadYearMonthList()">
                  <option *ngFor="let year of yearList" [value]="year">
                      {{year}}
                  </option>
              </select>    
              <span class="text-danger" *ngIf="((filterFormControl['year'].touched) && filterFormControl['year'].errors?.['required'])">
                Year is required.</span>
            </div>
            <div class="col-md-2 mt-3">
              <label for="month" class="month">Month</label>
              <select name="month" id="month" class="form-select form-select-custom"
                  formControlName="month">
                  <option *ngFor="let month of monthList" [value]="month.value">
                      {{month.text}}
                  </option>
              </select> 
              <span class="text-danger" *ngIf="((filterFormControl['month'].touched) && filterFormControl['month'].errors?.['required'])">
                Month is required.</span>
            </div>
            <div class="col-md-2 mt-3">
              <label for="showPolicy" class="month">Show Policy</label>
              <select name="showPolicy" id="showPolicy" class="form-select form-select-custom"
                  formControlName="showPolicy">
                  <option *ngFor="let show of showPolicyList" [value]="show.value">
                      {{show.text}}
                  </option>
              </select> 
            </div>
            <div class="col-md-3 mt-3">
              <label for="month" class="text-white d-none d-md-block">.</label>
              <button class="btn btn-primary btn-sm" (click)="loadCustomerList()">Filter</button>
            </div>
          </div>
        </form>
        <div class="border-top pt-2">
          <button class="btn btn-primary btn-sm mb-2" (click)="onBtExport()" *ngIf="CustomerPolicyList?.length > 0">Export to Excel</button>
          <ag-grid-angular style="height:63vh;" class="ag-theme-alpine w-100" [columnDefs]="columnDefsPolicy"
          [rowData]="CustomerPolicyList" [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize"
          [paginationAutoPageSize]="false" [pagination]="true" [gridOptions]="gridOptions"
          (gridReady)="onGridReadyCustomerList($event)" aria-hidden="true" id="cust_grid">
        </ag-grid-angular>
        </div>
        <!-- <div class="table-responsive">
          <table class="table table-bordered">
            <thead class="customerHeadewr" >
              <tr>
                <th scope="col" [height]="'35px'" class="pr-2">Actions</th>
                <th scope="col" class="pl-2">Insurance Company</th>
                <th scope="col">Category</th>
                <th scope="col">Policy</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Plan Name</th>
                <th scope="col">Registration Number</th>
                <th scope="col">Quote Id</th>
                <th scope="col">Policy No.</th>
                <th scope="col">Phone Number</th>
                <th scope="col">City</th>
                <th scope="col">Pincode</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Premium</th>
                <th scope="col">Channel</th> 
                <th scope="col">Commission Points </th>   
              </tr>
            </thead>
            <tbody class="tbody1">
              <ng-container *ngIf="CustomerPolicyList?.length == 0">
              <tr>
                <td colspan="15" class="text-center h5">Not data found</td>
              </tr>
              </ng-container>
              <tr *ngFor="let custPol of CustomerPolicyList">
                <td class="text-center">
                  <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="img-fluid h-24" (click)="edit(custPol.policyIdEnc)" title="Edit" *ngIf="custPol.isExternalPolicy == true">
                  <img src="../../assets/svgIcon/file-download.svg" alt="Download Policy" class="img-fluid h-24" (click)="downloadPolicy(custPol.policyIdEnc)" title="Download Policy">
                </td>
                <td class="text-center"><img src="/assets/images/insurance_companies/{{custPol.insuranceCompany}}.png" class="img-fluid h-48" /></td>                
                <td class="text-center"><img img src="../../assets/svgIcon/policyvehicle/{{custPol.policyCategory}}.svg" class="img-fluid"></td>
                <td class="text-center">{{custPol.isExternalPolicy == true ? 'Offline' : 'Online'}}</td>
                <td>{{custPol.firstName}}</td>
                <td>{{custPol.lastName}}</td>
                <td>{{custPol.planName}}</td>
                <td>{{custPol.registrationNumber}}</td>
                <td>{{custPol.quoteId}}</td>
                <td>{{custPol.policyNumber}}</td>
                <td>{{custPol.phoneNumber}}</td>
                <td>{{custPol.city}}</td>
                <td>{{custPol.pincode}}</td>
                <td>{{custPol.startDate | date: 'dd MMM, yyyy'}}</td>
                <td>{{custPol.endDate | date: 'dd MMM, yyyy'}}</td>
                <td>₹ {{custPol.premium??"0" | thousandSeparator}}</td>
                <td>{{custPol.channel}}</td> 
                <td>{{custPol.agentCommissionPoints??0 | thousandSeparator}}</td> 
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
</div>