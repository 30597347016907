<form id="kt_account_profile_details_form" class="form" [formGroup]="userForm" novalidate="novalidate">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
               type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="card p-5">
    <div class="row">
      <div class="col-md-6">
        <label class="input-label required" for="name">First Name</label>
        <input type="text" pattern="[a-zA-Z- ]*" oninvalid="setCustomValidity('Please enter on alphabets only. ')" placeholder="First Name" class="form-control" formControlName="firstName">
      </div>
      <div class="col-md-6">
        <label class="input-label required" for="name">Last Name</label>
        <input type="text" pattern="[a-zA-Z- ]*" oninvalid="setCustomValidity('Please enter on alphabets only. ')" placeholder="Last Name" class="form-control" formControlName="lastName">
      </div>
      <!-- <div class="col">
        <label class="input-label required" for="name">Last Name</label>
        <input type="text" placeholder="Last Name" class="form-control" formControlName="lastName">
      </div> -->
      <div class="col-md-6 mt-4">
        <label class="input-label" for="mobile">Your Mobile</label>
        <!-- <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                            [searchCountryFlag]="false" [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode"
                            name="phone" formControlName="phoneNumber" [inputId]="'phone_number'" 
                            (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber  [disabled]="true" >

        </ngx-intl-tel-input> -->
        <input type="number"  class="form-control" formControlName="phoneNumber " readonly  [value]="mobile">
      </div>
      <div class="col-md-6 mt-4">
        <label class="input-label required" for="Email">Your Email Address</label>
        <input type="email" placeholder="useremailadd@domain.com" class="form-control" formControlName="userEmail" >
      </div>
    </div>
    <div class="row">
      
      <div class="col-md-6 mt-4">
        <!-- <label class="input-label" for="Date">Date of birth</label> -->
        <!-- <input type="text" placeholder="31/01/1996" class="form-control" formControlName="dob"> -->
        <!-- <div> -->
        <label class="input-label required" for="dob">Date of Birth</label>
        <input type="date" placeholder="DD-MM-YYYY"  name="dob"
               autocomplete="off" class="new-user form-control bg-transparent" formControlName="dob"  [max]="max"/>
        <!-- </div>&nbsp;&nbsp; -->

      </div>
      <div class="col-md-6 mt-4">
        <label class="input-label required" for="income">Annual Income</label><br>
        <!-- <select name="income" id="income" class="form-select form-select-custom">
          <option value="">--Select--</option>
        </select> -->
        <input type="number" placeholder="100000" class="form-control" name="Annual" id="Annual" formControlName="annualIncome">
      </div>
    </div>
    <div class="row">
      
      <div class="col-md-6 mt-4">
        <label class="input-label required" for="Date">Gender</label><br>
        <input class="my-3 radio-button" type="radio" id="male" name="gender" value="Male" formControlName="gender">
        Male
        &nbsp;&nbsp;
        <input class="my-3 radio-button" type="radio" id="female" name="gender" value="Female" formControlName="gender">
        Female &nbsp;&nbsp;
        <input class="my-3 radio-button" type="radio" id="other" name="gender" value="Other" formControlName="gender">
        Other
      </div>
      <div class="col-md-6 mt-4">
        <label class="input-label required" for="maritalStatus">Marital Status</label>
        <!-- <input type="text" placeholder="Married" class="form-control" formControlName=""> -->
        <select name="maritalStatus" id="maritalStatus" class="form-select form-select-custom" formControlName="maritalStatus"> 
          <option value="">--Select--</option>
          <option value="Yes">Married</option>
          <option value="No">Unmarried</option>
        </select>
      </div>
    </div>
    <div class="row">
      

      <div class="col-md-6 mt-4">
        <label class="input-label required" for="Street">Street Address</label>
        <input type="text" placeholder="Street Address" class="form-control" formControlName="streetAddress">
      </div>
      <div class="col-md-6 mt-4">
        <label class="input-label required" for="Pincode">Your Pincode</label>
        <input type="text" placeholder="Pincode" class="form-control" [minlength]="6" [maxlength]="6" pattern="^[0-9]*$" formControlName="pincode" #pinCode
               (blur)="pincodeUserChangeEvent(pinCode)">
        <!-- <label class="mt-2 mb-0"> {{pincodeMaster.district}} {{pincodeMaster.state}} {{pincodeMaster.country}}</label> -->
      </div>
      <div class="col-md-6 mt-4" *ngIf="isAgent" >
        <label class="input-label required" for="ReferralCode">ReferralCode</label>
        <input type="text" placeholder="referralCode" class="form-control" formControlName="referralCode">
      </div>
    </div>
  </div>
  <div class=" row mt-3">
    <div class="col-12 text-right">
      <button class="btn btn-primary btn-custom" [disabled]="userForm.invalid" (click)="save()">Save</button>
    </div>    
  </div>
</form>
