<app-navbar></app-navbar>
<div class="content-box">
    <div class="first_box">
        <div class="container">
            <div class="row content-body text-center">
                <h1 class="mt-2">We do things differently...</h1>
                <h3 class="mt-2" style="color: #848493;">We can expertly manage your total insurance portfolio and
                    provide you with a complete Risk free Management advice to protect you with expertise in liability,
                    financial, crime, property and employee benefit products. As a specialist broker, it is always our
                    focus to bring value to our customers. We are a group of insurers / underwriters and re-insurers to
                    manage various insurance risks under one umbrella. We have long standing relationships with insurers
                    and re-insurers nationwide.</h3>
            </div>
            <div class="row backgroud_banner align-items-center justify-content-between">
                <div class="col-4 banner_content text-center mt-3">
                    <div class="row bannerDiv">
                        <h2 class="text-white">Risk Assessment</h2>
                        <p class="font-body text-white">Identification and assessment of risks Review of all policies to
                            eliminate gaps in coverage. Review of prior claims and losses to identify trends & issues of
                            potential concern. Summary in a written report.</p>
                    </div>
                </div>
                <div class="col-4 banner_content text-center mt-3">
                    <div class="bannerDiv">
                        <h2 class="text-white">Monitor & Review</h2>
                        <p class="font-body text-white">Risk Monitoring. Ongoing administration of client’s insurance
                            programs. Ongoing advice on market issues that may influence program design</p>
                    </div>
                </div>
                <div class="col-4 banner_content text-center mt-3">
                    <div class="bannerDiv">
                        <h2 class="text-white">Insurance Placement</h2>
                        <p class="font-body text-white">Quote Sourcing – Negotiation with Insurers. Evaluate quotes with
                            Recommendations ensuring win-win. Focus on coverage and cost optimization. Placement of
                            insurance as per clients choice.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="second_box">
        <div class="container">
            <div class="row card1">
                <div class="col-md-4">
                    <img class="poster " src="../../assets/images/sitemap_img1.png" alt="">
                </div>
                <div class="col-md-8">
                    <div class="row about_us">
                        <h1 class="text-start">Our Strengths - Relationship Based Organization</h1>
                        <h3 class="text-start story">Unlike conventional corporate insurers, SRGIB team meets every
                            customer, enquire their requirement, assess their financial capacity,make evaluation
                            documentation and credit history to borrower background, profile and hope. Insure them
                            suitable product & portfolio with hassle-free documentation through a speedy and transparent
                            process.</h3>
                    </div>
                </div>
            </div>
            <div class="row card1">
                <div class="col-md-8">
                    <div class="row about_us">
                        <h1 class="text-start">Centralized and Independent Verification</h1>
                        <h3 class="text-start story">We are highly focused on the insurance quality portfolio. Each case
                            goes through filtration, verification approval process. We assess viability between the
                            product, customer profile and economic management and policy operation.</h3>
                    </div>
                </div>
                <div class="col-md-4">
                    <img class="poster" src="../../assets/images/sitemap_img3.png" alt="">
                </div>
            </div>
            <div class="row card1">
                <div class="col-md-4">
                    <img class="poster" src="../../assets/images/sitemap_img2.png" alt="">
                </div>
                <div class="col-md-8">
                    <div class="row about_us">
                        <h1 class="text-start">Robust Collection Process</h1>
                        <h3 class="text-start story">SRG Insurance Brokers framed an in-house collection team that uses
                            a friendly approach of premium payment management with flexible and sympathetic way. We make
                            tele-calling, personal visits and letter communication. The Company also has a unique way of
                            approaching customers from collecting premiums and disbursement of payment and our
                            collection efficiency and the end payment is excellent which has gained customer confidence.
                        </h3>
                    </div>
                </div>
            </div>
            <div class="row card1 about_us">
                <h1 class="text-start">Read More</h1>
                <h3 class="text-start story">Our people are proven insurance experts & so, we are able to create outstanding results from risk identification, product design and initial negotiation until claim settlement – an issue that many clients overlook when choosing a consultant and advisor.</h3>
            </div>
        </div>
    </div>
    <div class="container">
        <!-- <div class="top-line"></div> -->
        <!-- <div class="row mt-5">
            <p class="font-body mt-3">We can expertly manage your total insurance portfolio and provide you with a
                complete Risk free Management advice to protect you with expertise in liability, financial, crime,
                property and employee benefit products</p>
        </div>
        <div class="row mt-5">
            <p class="font-body mt-3">As a specialist broker, it is always our focus to bring value to our customers. We
                are a group of insurers / underwriters and re-insurers to manage various insurance risks under one
                umbrella. We have long standing relationships with insurers and re-insurers nationwide.</p>
        </div> -->
        <!-- <div class="table-data row mt-5">
                <table cellspacing="0" cellpadding="0" style="border:0.75pt solid #000000; border-collapse:collapse;">
                    <tbody>
                        <tr>
                            <td>
                                <h4>Life &amp; Health Insurance</h4>
                                <ul>
                                    <li>Individual Health insurance</li>
                                    <li>Family Health Insurance</li>
                                    <li>Covid19 Insurance</li>
                                    <li>Group Health Insurance</li>
                                    <li>Critical Insurance</li>
                                    <li>Senior Citizen</li>
                                    <li>Term Life insurance</li>
                                    <li>Single Premium Term Plans</li>
                                    <li>Group Term Plans</li>
                                    <li>Guaranteed Saving Plans</li>
                                    <li>Pension Plans</li>
                                    <li>Child Education &amp; Marriage Plans</li>
                                    <li>Money Back Insurance Plans</li>
                                    <li>ULIP Plans</li>
                                </ul>
                            </td>
                            <td>
                                <h4>Other General Insurance Products</h4>
                                <ul>
                                    <li>Car Insurance</li>
                                    <li>Two Wheeler Insurance</li>
                                    <li>IIIrd Party&nbsp;</li>
                                    <li>Accident insurance</li>
                                    <li>All Risk And Property Insurance</li>
                                    <li>Machinery Breakdown Insurance</li>
                                    <li>Loss Of Profits Insurance</li>
                                    <li>Terrorism Insurance</li>
                                    <li>Fire Insurance</li>
                                    <li>Home / Shop / Office Insurance</li>
                                    <li>Marine Insurance</li>
                                    <li>Fidelity, Burglary Insurance</li>
                                    <li>Package Policies</li>
                                    <li>Project insurance - On shore and offshore energy, Power projects - Hydro &amp;
                                        Thermal</li>
                                </ul>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:10pt;">&nbsp;</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>Business Insurance Plans</h4>
                                <ul>
                                    <li>Key man insurance</li>
                                    <li>Gratuity Plans</li>
                                    <li>Superannuation plans</li>
                                    <li>MWPA</li>
                                </ul>
                            </td>
                            <td>
                                <h4>Dedicated Support Management Team</h4>
                                <ul>
                                    <li>Advisory Services</li>
                                    <li>Blogs for Knowledge &amp; Financial Update</li>
                                    <li>Support for Claims documentation</li>
                                    <li>Separate Relationship Manager&nbsp; to Guide on Product Selection as per needs,
                                        Issuance of Policy &amp; After Sale Services</li>
                                    <li>In-house surveyors for Claims Assistance</li>
                                    <li>Special Risks Claim Services</li>
                                    <li>Property &amp; Marine Advisory Services</li>
                                    <li>Placement &amp; Earning Opportunity as POSP</li>
                                </ul>
                                <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:10pt;">&nbsp;</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        <!-- <div class="row">
            <div class="title-line">
                <h4>Read More</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">
                    Our people are proven insurance experts &amp; so, we are able to create
                    outstanding results from risk identification, product design and initial negotiation until claim
                    settlement &ndash; an issue that many clients overlook when choosing a consultant and advisor.
                </p>
            </div>
        </div> -->
        <div class="row">
            <div class="title-line">
                <h4>Special Risk</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">&bull; When your needs aren&rsquo;t Standard, you may need more than a Standard
                    cover
                </p>
                <p class="font-body">&bull; Team is equipped to understand your specific needs and customize a Cover
                    that
                    suits you.</p>
                <p class="font-body">&bull; Customized special contingency policy to cover weather based precipitation
                    risk
                    for a hydro-power project</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>SRG Insurance &amp; how you benefit from us?</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">We have years&rsquo; experience in insurance broking and having a NationwideNetwork
                </p>
                <p class="font-body">Finding the optimal balance between coverage and price:Our approach helps balance
                    your
                    risk transfer to suit your requirement.</p>
                <p class="font-body">Building trust through transparency: Our core value and fundamental virtue is to
                    ensure
                    transparency in all our activities.</p>
                <p class="font-body">Adding value through expertise: With our expertise ranging from handling large
                    power
                    projects to some of the largest liability deals, we provide a comprehensive array of property,
                    health,
                    employee benefit, liability, reinsurance and risk management services. In addition, we have
                    developed
                    product-specific competencies that allow us to respond to unique demands and opportunities in
                    specific
                    vertical markets.</p>
                <p class="font-body">Adding value through relationship: With the volumes being placed, and having been
                    one
                    of the longest standing brokers in the Indian market, our relationship with all the insurers help
                    you
                    derive the best, both while underwriting and claim management.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Our knowledge based approach</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">What distinguishes us is our continuous focus to bring in the best knowledge from
                    across the globe. We</p>
                <p class="font-body">have a senior management team comprising of insurance professionals, chartered
                    accountants, engineers and HR professionals. We have with us today one of the most experienced and
                    diverse technical teams in the broking industry. The team together brings years of experience in the
                    insurance industry from handling underwriting to claims settlement. We have a dedicated team of
                    experts,
                    highly respected in the market with many years of knowledge and experience in dealing with claims.
                    The
                    team understands that quick service and efficient claims handling is essential to the clients
                    confidence
                    and peace of mind while they can focus on their business.</p>
                <p class="font-body"
                    style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:14pt;">
                    We
                    will be there when it matters</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Our knowledge based approach</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">We believe in committing to a long lasting relationship with each of our customers
                    and
                    our team has been trained to honour the commitment to you. We are driven by a single imperative
                    &ndash;
                    to make a difference by helping you navigate the complexities of risk through our value-added
                    services.
                </p>
                <p class="font-body">SRGIBPL, a broker built on unique idea and innovative thinking. We are your
                    associate,
                    advisor, consultant and supporter. We measure our success with the success you achieve.Our approach
                    to
                    maximize the benefits of risk management</p>
                <p class="font-body"
                    style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal; font-size:14pt;">
                    We
                    will be there when it matters</p>
            </div>
        </div>
        <!-- <div class="row">
            <div class="title-line">
                <h4>Risk Assessment</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">&bull; Identification and assessment of risks</p>
                <p class="font-body">&bull; Review of all policies to eliminate gaps in coverage</p>
                <p class="font-body">&bull; Review of prior claims and losses to identify trends &amp; issues of
                    potential
                    concern</p>
                <p class="font-body">&bull; Summary in a written report</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Monitor &amp; Review</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">&bull; Risk Monitoring</p>
                <p class="font-body">&bull; On going administration of client&rsquo;s insurance programs</p>
                <p class="font-body">&bull; Ongoing advice on market issues that may influence program design</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Insurance Placement</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">&bull; Quote Sourcing &ndash; Negotiation with Insurers</p>
                <p class="font-body">&bull; Evaluate quotes with Recommendations ensuring win-win</p>
                <p class="font-body">&bull; Focus on coverage and cost optimization</p>
                <p class="font-body">&bull; Placement of insurance as per clients choice</p>
            </div>
        </div> -->
        <div class="row">
            <div class="title-line">
                <h4>Insurance Program Design</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">&bull; Insurance strategy development</p>
                <p class="font-body">&bull; Determine the scope, amount of coverage &amp; deductibles in line with
                    established benchmarks</p>
                <p class="font-body">&bull; Insurer Selection Claims Management</p>
                <p class="font-body">&bull; Handholding in case of claim</p>
                <p class="font-body">&bull; Handle complete documentation for claims</p>
                <p class="font-body">&bull; Insurer follows up for claims recovery</p>
                <p class="font-body">Because we believe that winning and sustaining customer TRUST is the only way of
                    doing
                    broking, we are</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Dealing&nbsp;with the Unexpected</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Geographical Presence</p>
                <p class="font-body">The Company has a wide network across the country spread in urban as well as rural
                    segments encompassing
                    a large customer base.</p>
                <p class="font-body">Salient features of our offices</p>
            </div>
        </div>
        <div>
            <ul>
                <li>Expanded with wide network&nbsp;</li>
                <li>Equipped with latest Technology&nbsp;</li>
                <li>Provide fast, effective, and quality services&nbsp;</li>
                <li>Manned by skilled personnel&nbsp;</li>
                <li>Helpful and responsive executives who visit customers at their residences</li>
                <li>Our offices/field staff help us identify, connect, accentuate with our customers and enable us to
                    provide effective customized services</li>
            </ul>
        </div>
        <!-- <div class="row">
            <div class="title-line">
                <h4>Our Strengths - Relationship Based Organization</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Unlike conventional corporate insurers, SRGIB team meets every customer, enquire
                    their
                    requirement, assess their financial capacity,make evaluation documentation and credit history to
                    borrower background, profile and hope. Insure them suitable product &amp; portfolio with hassle-free
                    documentation through a speedy and transparent process.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Centralized and Independent Verification</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">We are highly focused on the insurance quality portfolio. Each case goes through
                    filtration, verification approval process. We assess viability between the product, customer profile
                    and
                    economic management and policy operation.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Robust Collection Process</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">SRG Insurance Brokers&nbsp; framed an in-house collection team that uses a friendly
                    approach of premium payment management with flexible and sympathetic way. We make tele-calling,
                    personal
                    visits and letter communication. The Company also has a unique way of approaching customers from
                    collecting premiums and disbursement of payment and our collection efficiency and the end payment is
                    excellent which has gained customer confidence.</p>
            </div>
        </div> -->
    </div>
</div>
<app-footer></app-footer>