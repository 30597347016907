import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/Services/general.service';
import * as moment from 'moment';
import { InsuranceService } from 'src/app/Services/insurance.service';
import { MembersForm } from 'src/app/Models/MembersForm';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { LookUpService } from 'src/app/Services/lookup.service';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';
@Component({
  selector: 'app-travel-details',
  templateUrl: './travel-details.component.html',
  styleUrls: ['./travel-details.component.css']
})
export class TravelDetailsComponent implements OnInit {
  tabs:string='Members';
  allData:any;
  moment: any = moment;
  travelForm:FormGroup;
  userForm:FormGroup;
  submittedProposer:boolean = false;
  submittedAddress:boolean = false;
  required_input_fields:MembersForm[] = [];
  genderList: string[] = ["Male","Female","Other"];
  titleList: string[] = ["Mr","Mrs","Ms"];
  priceBreakup:any;
  today = new Date();
  maxDOB = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
  pincodeList: AutoCompleteItem[] = [];
  stateList :AutoCompleteItem[] =[];
  cityList :AutoCompleteItem[] =[];
  showKYCPopup:boolean=false;
  showhttpKYCPopup:boolean=false;
  kycUrl:any='';
  royalDetails :any;
  isRoyalSundaram :boolean = false;

  futureDetails :any;
  isFutureGeneral :boolean = false;

  ShriramDetails :any;
  isShriramGeneral :boolean = false;

  RelianceDetails :any;
  isRelianceGeneral :boolean = false;
  constructor(private route:Router,private fb: FormBuilder, private generalService:GeneralService, private insuranceSerrvice : InsuranceService,private spinner: NgxSpinnerService,
    private agentprofileService : AgentprofileService, private _lookupService : LookUpService, private sanitizer: DomSanitizer
  ){
    this.travelForm = this.fb.group({
      "planFor" : [''],
      "quoteId" : [''],
      "userId": [0],
      "isAgent": [false],
      "destination": [''],      
      "travelPurpose": [''],
      "travelStartDate": [''],
      "travelEndDate": [''],
      "travelMultipleTimesInAYear": [false],
      "memberHasMedicalCondition": ['No'],
      "tripFrequencyInDays": [30],
      "url": [''],
      "filter": [''],
      "royalCalculatePremiumRequest": [''],      
      "futureGeneraliRequest": [''],
      "members": [],
      "membersWithCondition": [],
      "dynamicForm":[],
      "proposer":[],
      "insuranceCompany":[],
      "applicationId":[''],
      "policyNumber":[''],
      "kycLink":['']
    });

    this.userForm = this.fb.group({
      "title":['', [Validators.required]],
      "firstname":['', [Validators.required]],
      "lastname":['', [Validators.required]],
      "dob":['', [Validators.required]],
      "email":['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') ]],
      "pancard": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "passport":[''],
      "city":['', [Validators.required]],
      "state": ['', [Validators.required]],
      "mobileNumber":['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "aadharNumber":[''],
      "addressLine1":['', [Validators.required]],
      "addressLine2":['', [Validators.required]],
      "addressLine3":[''],
      "addressLine4":[''],
      "cKYCNumber":[''],
      "eKYCVerified":[''],
      "kycReferenceNumber":[''],
      "annualIncome":[''],
      "eiaNumber":[''],
      "gender":['', [Validators.required]],
      "gstin":[''],
      "irName":[''],
      "maritalStatus":[''],
      "occupation":[''],
      "pincode":['',[Validators.required,Validators.pattern('[0-9]{6}')]],
  });
  }
  nextTab(val:string)
  {
    this.tabs=val;
  
  }
  ageList:any[];
  async getAge()
{
  this.ageList = [];
  for (var i = 18; i <= 100; i++) {
    this.ageList.push(i)
  }
}
getSummary(){
  //this.route.navigate(['/travel-summary'])
  //this.getQuotes();
  this.CreatePolicy();
}
back(){
  this.route.navigate(['/travel-policy'])
}

ngOnInit(){
  let data = this.generalService.getDataToLocalStorage('quoteDataTravel');
  if(data!= ""){
    //,JSON.stringify ({memberDetails:this.requesrData,quoteList:this.quoteList,quote:quote})
    this.allData = JSON.parse(data);
    debugger;
    this.travelForm.patchValue(this.allData.memberDetails);
    this.travelForm.get("quoteId")?.setValue(this.allData.quote.quoteId);
    this.travelForm.get("insuranceCompany")?.setValue(this.allData.quote.insuranceCompany);
    this.userForm.patchValue(this.travelForm.value.proposer);
    this.showHideMembersDetails(1);
    this.priceBreakup = this.allData.quote.priceBreakUp;
  }
}

async createQuote(){
  this.formatFinalObject();
  let data = this.travelForm.value;
  let response = await this.insuranceSerrvice.CreateTravelQuotes(data);
  if (response?.isSuccess) {
      debugger;
  }
}

async CreatePolicy() {
  this.spinner.show();
  this.formatFinalObject();
  let data = this.travelForm.value;
  let response = await this.insuranceSerrvice.CreateTravelQuotes(data);
  debugger;
  if (response?.isSuccess && response?.result?.code == 1) {
    if(response.result.isKycVerified == false && response.result.kycLink!=null &&  response.result.kycLink!=undefined && response.result.kycLink!="")
    {      
      this.kycUrl= this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink);  
      if(response.result.kycLink.toLowerCase().indexOf('https')==-1 || this.travelForm.value.insuranceCompany==14 || this.travelForm.value.insuranceCompany==9 || this.travelForm.value.insuranceCompany==7 || this.travelForm.value.insuranceCompany==13)
      {
          this.showhttpKYCPopup=true;
      }
      else
      {
        this.showKYCPopup=true;           
      }          
    }
    else if (response?.result?.royalSundaramResponse !=null){
      this.royalDetails = response?.result?.royalSundaramResponse;
      this.isRoyalSundaram = true;
    }
    else if (response?.result?.futureGeneralQuoteResponse !=null){
      this.futureDetails = response?.result?.futureGeneralQuoteResponse;
      this.isFutureGeneral = true;
    }
    else if (response?.result?.shriram_GeneralResponse !=null){
      this.ShriramDetails = response?.result?.shriram_GeneralResponse;
      this.isShriramGeneral = true;
    }

    else if (response?.result?.reliance_GeneralResponse !=null){
      this.RelianceDetails = response?.result?.reliance_GeneralResponse;
      this.isRelianceGeneral = true;
    }
    else if(response?.result?.createUniversalSompoResponse !=null){
      const downloadLink = document.createElement('a');
      downloadLink.href = response?.result?.createUniversalSompoResponse.url;
      downloadLink.click();
    }
  
    else {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: `Policy Created Successfully!`,
        showConfirmButton: false,
        timer: 5000,
      });
      this.route.navigate(['policyDetails']);
    }
  }
  else {
    if(response?.result?.isKycVerified == false && response?.result?.kycLink!=null &&  response?.result?.kycLink!=undefined && response?.result?.kycLink!="")
    {        
      this.kycUrl= this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink); 
      if(response.result.kycLink.toLowerCase().indexOf('https')==-1|| this.travelForm.value.insuranceCompany==14 || this.travelForm.value.insuranceCompany==9 || this.travelForm.value.insuranceCompany==7 || this.travelForm.value.insuranceCompany==13)
      {
          this.showhttpKYCPopup=true;
      }
      else
      {
        this.showKYCPopup=true;           
      }
      this.travelForm.get("applicationId")?.setValue(response.result.applicationId);
      this.travelForm.get("policyNumber")?.setValue(response.result.policyNumber);
      this.travelForm.get("kycLink")?.setValue(response.result.kycLink);
    }
    else
    {
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        // icon: 'error',
        title: '',
        text: response?.result?.message ?? response?.message,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        },
        // timer: 9000,
      });
    }
    
  }
  this.spinner.hide();
}

showHideMembersDetails(tab:number){
  let memebrsList:MembersForm[] =  JSON.parse(JSON.stringify(this.travelForm.get("dynamicForm")?.value)); 
  for(let i =0;i<memebrsList.length;i++){
    let frm = memebrsList[i];
    if(frm != undefined && frm.memberFormFields != undefined && frm.memberFormFields.length > 0){
      for(let j =0;j<frm.memberFormFields.length;j++){
        if(frm.memberFormFields != undefined){
          //frm.memberFormFields[j].isVisible = true;
          if(frm.memberFormFields[j].id?.startsWith('Age') ||frm.memberFormFields[j].id?.startsWith('Age') || frm.memberFormFields[j].id?.startsWith('Age') || frm.memberFormFields[j].id?.startsWith('Age'))
          {
            frm.memberFormFields[j].isVisible = false;
          }
          // else
          // {
          //   frm.memberFormFields[j].isVisible = false;
          // }
        }
      }
    }    
  };
  this.required_input_fields = memebrsList;
}

getSavedData(data:any,cat:string){  
  if(data){
    for(let i=0;i<this.required_input_fields.length;i++){
      if(data.for == this.required_input_fields[i].for){
        this.required_input_fields[i] = data.data;
      }
    }
    this.travelForm.get("dynamicForm")?.setValue(this.required_input_fields);
  }
}

get travelFormControl() {
  return this.travelForm.controls;
}

get userFormControl() {
  return this.userForm.controls;
}

formatFinalObject(){
  let memebrsList:MembersForm[] =  this.travelForm.get("dynamicForm")?.value;
  let objArray=[];
  for(let i =0;i<memebrsList.length;i++){
    let frm = memebrsList[i];
    if(frm != undefined && frm.memberFormFields != undefined && frm.memberFormFields.length > 0){
      let obj ={
        "id": 1,
        "title":"",
        "firstname": "",
        "lastname": "",
        "email": "",
        "age": 0,
        "dob":"",
        "gender": "",
        "hasIllnessInjuryHealthCondition": false,
        "disease": "",
        "relation":"",
        "pancard":"",
        "passport":"",
        "isSelfProposer":"",
        "nomineeFirstname":"",
        "nomineeLastname":"",
        "nomineeRelation":"",
        "nomineeDOB":"",
        "guardianFirstname":"",
        "guardianLastname":"",
        "guardianRelation":"",
        "guardianDOB":""
      }
      for(let j =0;j<frm.memberFormFields.length;j++){
        if(frm.memberFormFields != undefined){
          if(frm.memberFormFields[j].id?.startsWith('Title'))
          {
            obj.title = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Firstname'))
          {
            obj.firstname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Lastname'))
          {
            obj.lastname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('dob'))
          {
            obj.dob = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Relation'))
          {
            obj.relation = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Gender'))
          {
            obj.gender = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Age'))
          {
            obj.age = parseInt(frm.memberFormFields[j].selectedValue == undefined || (frm.memberFormFields[j].selectedValue??"") == "" ? "0" : frm.memberFormFields[j].selectedValue??"0" );
          }
          else if(frm.memberFormFields[j].id?.startsWith('Disease'))
          {
            obj.disease = frm.memberFormFields[j].selectedValue ??"";
            obj.hasIllnessInjuryHealthCondition = ((frm.memberFormFields[j].selectedValue??"") == "" || (frm.memberFormFields[j].selectedValue ??"") =="Select Disease") ? false : true;
          }
          else if(frm.memberFormFields[j].id?.startsWith('Pancard'))
          {
            obj.pancard = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Passport'))
          {
            obj.passport = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('IsSelfProposer'))
          {
            obj.isSelfProposer = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('NomineeFirstname'))
          {
            obj.nomineeFirstname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('NomineeLastname'))
          {
            obj.nomineeLastname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('NomineeRelation'))
          {
            obj.nomineeRelation = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Nomineedob'))
          {
            obj.nomineeDOB = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('GuardianFirstname'))
          {
            obj.guardianFirstname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('GuardianLastname'))
          {
            obj.guardianLastname = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('GuardianRelation'))
          {
            obj.guardianRelation = frm.memberFormFields[j].selectedValue ??"";
          }
          else if(frm.memberFormFields[j].id?.startsWith('Guardiandob'))
          {
            obj.guardianDOB = frm.memberFormFields[j].selectedValue ??"";
          }
        }
      }
      objArray.push(obj);
    }    
  }
  this.travelForm.get("membersWithCondition")?.setValue(objArray);
  this.travelForm.get("members")?.setValue(objArray);
  this.travelForm.get("proposer")?.setValue(this.userForm.value);
}

numberOnly(event:any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode >= 48 && charCode <= 57) {
    return true;
  }
  return false;
}

alphabetOnly(event:any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
    return true;
  }
  return false;
}

alphaNumericOnly(event:any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
    return true;
  }
  return false;
}

async getPanCardDetails(){
  this.spinner.show();
  let obj = {
    docNum:1,
    source:'',
    id_number:this.userForm.get('pancard')?.value
  }
  const response = await this.agentprofileService.GetAgentKYCDetails(obj);
  if (response?.isSuccess) {
    this.spinner.hide();
    let panDetails = response.result;      ;
    this.userForm.get('dob')?.setValue(panDetails?.dob??'');
    if(panDetails?.full_name != null && panDetails?.full_name != undefined && panDetails?.full_name.length > 5 )
    {
     
      {
        let nameArray = panDetails?.full_name.split(' ');
        switch(nameArray.length)
        {
          case 2:
            this.userForm.get('firstname')?.setValue(nameArray[0]);
            this.userForm.get('lastname')?.setValue(nameArray[1]);
            break;
          case 3:
            this.userForm.get('firstname')?.setValue(nameArray[0] + ' ' + nameArray[1]);
            this.userForm.get('lastname')?.setValue(nameArray[2]);
            break;
          case 4:
            this.userForm.get('firstname')?.setValue(nameArray[0] + ' ' + nameArray[1] + ' ' + nameArray[2]);
            this.userForm.get('lastname')?.setValue(nameArray[3]);
            break;  
        }
      }
    }  
  }
  else
  {
    this.spinner.hide();
  }
}

async getStatesByCountry(country:string)
  {
    let response = await this._lookupService.getStatesListByCountry(country); 
    if (response?.isSuccess) {
      this.stateList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
    }
  }

  async getPincodesByDistrict(district:string)
  {
    let response = await this._lookupService.getPincodeByDistrict(district); 
    if (response?.isSuccess) {
      //this.pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      this.cityList = response.result.map((item:any)=> {return {text:item.district,value:item.district, text1:item.pincode, id:item.id}});
    }
  }

  onDistrictSelection(data:AutoCompleteItem){
    this.userForm.get('city')?.setValue(data.text);
    this.getPincodesByDistrict(data.text);
    console.log(this.userForm.value); 
  }

   onPincodeSelection(data:AutoCompleteItem)
   {
    this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase()??"");
    this.userForm.get('pincode')?.setValue(data.pincodeMaster?.pincode);
    this.userForm.get('city')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    this.userForm.get('state')?.setValue(data.pincodeMaster?.state?.toUpperCase());
  }

   onCitySelection(data:AutoCompleteItem)
   {
     this.userForm.get('city')?.setValue(data.text);
   }

   onStateBlur(data:any)
   {
    this.userForm.get('state')?.setValue(data);
   }

   async onPincodeChange(data:any) {
    if(data.length >= 3){
      const res = await this._lookupService.pincodeMasterDetails(data);
      if (res != null && res.result) {
        this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      }
    }    
  }

   onCityBlur(data:any)
   {
       this.userForm.get('city')?.setValue(data);
   }

   onDistrictBlur(data:any)
   {
      this.userForm.get('city')?.setValue(data);
      this.userForm.get('district')?.setValue(data);
   }

   onInputChange(event: any): void {
    const initialValue = event.target.value;
   const input = event.target as HTMLInputElement;

    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (input.value.length > 6) {
        input.value = input.value.slice(0, 6);
        event.stopPropagation();
  
      }
    if (initialValue !== event.target.value) {
      event.stopPropagation();
      
    }
  }

  isDynamicFormIsValid():boolean{
    let count = 0; 
     let memebrsList:MembersForm[] =  JSON.parse(JSON.stringify(this.travelForm.get("dynamicForm")?.value)); 
     for(let i =0;i<memebrsList?.length??0;i++){
       let frm = memebrsList[i];
       if(frm != undefined && frm.memberFormFields != undefined && frm.memberFormFields.length > 0){
         for(let j =0;j<frm.memberFormFields.length;j++){
           if(frm.memberFormFields != undefined){
             console.log('visible :' + frm.memberFormFields[j].isVisible);
             console.log('isRequired :' + frm.memberFormFields[j].isRequired);
             console.log('selectedValue :' + frm.memberFormFields[j].selectedValue);
             if(frm.memberFormFields[j].isVisible == true && frm.memberFormFields[j].isRequired == true && frm.memberFormFields[j].selectedValue == ""){
               count ++;
             }            
           }
         }
       }    
     }
     count = memebrsList == null ? 1 : count;
     return count == 0;
   }

   isProposerValid():boolean{
    let isValid:boolean = false;
    if(this.userFormControl['gender'].valid && this.userFormControl['mobileNumber'].valid && this.userFormControl['pancard'].valid && this.userFormControl['email'].valid
      && this.userFormControl['dob'].valid && this.userFormControl['lastname'].valid && this.userFormControl['firstname'].valid && this.userFormControl['title'].valid
    ){
      isValid = true;
    }
    return isValid
  }

   isAddressValid():boolean{
    let isValid:boolean = false;
    if(this.userFormControl['city'].valid && this.userFormControl['pincode'].valid && this.userFormControl['addressLine1'].valid && this.userFormControl['addressLine2'].valid){
      isValid = true;
    }
    return isValid
  }

  checkValueNotZero(data:string)
  {
    if(data != null && data != undefined && data != "")
    {
      return parseFloat(data) > 0 ? true : false;
    }
    else
    {
      return false;
    }
  }
}	
