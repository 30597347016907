import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-bike-insurance-detail',
  templateUrl: './bike-insurance-detail.component.html',
  styleUrls: ['./bike-insurance-detail.component.css']
})
export class BikeInsuranceDetailComponent {
  isSmallScreen: boolean = false; constructor() { this.checkScreenSize(); } 
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); } 
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }

  isInsBox1: boolean = true; 
  isInsBox2: boolean = false;
  isInsBox3: boolean = false;
  selectInsBox(value: any){
    if(value == 'box1'){
      this.isInsBox1 = true;
      this.isInsBox2 = false;
      this.isInsBox3 = false;
    }
    else if(value == 'box2'){
      this.isInsBox1 = false;
      this.isInsBox2 = true;
      this.isInsBox3 = false;
    }
    else if(value == 'box3'){
      this.isInsBox1 = false;
      this.isInsBox2 = false;
      this.isInsBox3 = true;
    }
  }

  isPolicyBox1: boolean = false;
  isPolicyBox2: boolean = true;
  isPolicyBox3: boolean = false;
  selectPolicy(value : any){
    if(value == 'box1'){
      this.isPolicyBox1 = true;
      this.isPolicyBox2 = false;
      this.isPolicyBox3 = false;
    }
    else if(value == 'box2'){
      this.isPolicyBox1 = false;
      this.isPolicyBox2 = true;
      this.isPolicyBox3 = false;
    }
    else if(value == 'box3'){
      this.isPolicyBox1 = false;
      this.isPolicyBox2 = false;
      this.isPolicyBox3 = true;
    }
  }
}
