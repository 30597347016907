import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NavbarComponent } from '../navbar/navbar.component';
import { UserService } from '../Services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../Services/login.service';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'app-partner-home',
  templateUrl: './partner-home.component.html',
  styleUrls: ['./partner-home.component.css']
})
export class PartnerHomeComponent {

  isSmallScreen: boolean = false;  
  // @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); }
  // checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }
  isMobile: boolean = true;
  isOtp: boolean = false;
  healthForm1: boolean = true;
  healthForm2: boolean = true;
  isToggle: boolean = true;
  selectedTab : number = 1;
  showMenu:boolean = false;
  DisplayInsurance:boolean = false;
  langChange:boolean = false;
  currentTab:string="";
  isUserLogged:boolean = false;
  fullname:string=""
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;
  modalRef: BsModalRef;
  @ViewChild(NavbarComponent) navbarComponent: NavbarComponent;
  constructor(private _formBuilder: FormBuilder, private modalService: BsModalService,private userservice: UserService,private router: Router, private loginService:LoginService, private route: ActivatedRoute, private generalService : GeneralService) { 
    //this.checkScreenSize(); 
    ;
    let a = this.route.snapshot.queryParams['a'];
    let u = this.route.snapshot.queryParams['b'];
    if(a != null && a != undefined && a != ''){
      this.authenticateUser(a,u??"");
    }
  }

  async authenticateUser(a:any,u:any){
    let res = await this.loginService.GetPartnerAuthentication(a,u);
    if (res?.isSuccess) {
      if (res?.isSuccess) {
        //this.spinner.hide();
        let data = res.result;
        this.generalService.setAccessToken(data.accessToken);
        this.generalService.setUniqueuserid(data.uniqueUserId);
        this.generalService.setIsAgent(data.agentId>0?'true':'false');
        this.generalService.setUserLabel(data.userLabel);
        //this.router.navigate(['/']);
        //this.LoginPopUp = false;
      } else {
        // this.spinner.hide();
        // swal.fire({
        //   position: 'center',
        //   // icon: 'error',
        //   // title: res?.message,
        //   imageUrl: "../../assets/alerts/warning.png",
        //   imageHeight: 89,
        //   title: 'Invalid User Name or Password',
        //   text: 'Please check and re-enter your valid mobile number or password and try again.',
        //   showCancelButton: true,
        //     showConfirmButton: false,
        //     cancelButtonText: 'Try Again',
        //     cancelButtonColor: "#DF3A4D",
        //     customClass: {
        //       confirmButton: "error_btn",
        //     },
        // });
      }
    }
  }

  ngOnInit(): void {
  }

  languageChanged(){
   location.reload();
  }
  healthToggle() {
    this.healthForm1 = !this.healthForm1;
    this.healthForm2 = !this.healthForm2;
  }
  
  clicked(data:any){
    this.currentTab =data;
    //this.navbarComponent.goTo(data,true);
    this.DisplayInsurance = true;

  }
  openLoginModal() {
    //this.modalRef = this.modalService.show(LoginComponent);
  }

  display = "none";
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }
}
