<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Term Insurance</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">A Term Insurance plan is a life insurance plan which promises to pay a lump sum benefit in case the life insured dies during the term of the plan. It is the best way to Secure Your Family's Financial Future at the Lowest Cost.The policy holder is free to choose the duration of plan and level of coverage. The sum assured, the term of the policy and the nominee who will get the benefit after the insured person’s death depends on what he or she chooses at the time of buying the term insurance plan.</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why you should buy term insurance & why it is necessary?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Well nobody knows that how long are you going to live, and everyone desires to live their family in comfort in their absence. A term insurance plan helps your prepare against the risk of untimely death. It is the most affordable insurance you can buy with a large cover. Being cheap on pocket has made this form of insurance immensely popular among the people. Term insurance works on a straightforward principle if you die, your family receives the life cover amount. Thereby, they can live a comfortable life even when you are not around. If you survive, nothing gets paid back. That's not all, Have you taken any loan like Home Loan, Car Loan, Personal Loan, Outstanding Credit Card dues? You obviously wouldn't want your family members burdened with your outstanding loans and debts. So, if you want your family to live a comfortable life, buy a term insurance plan. Ensure that you have a large cover amount, which would take care of your family as you would have done until you retire.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>In addition, there are various reason to buy a term plan, however here are the key and basic reason that you must buy tern insurance:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The dependency of your family;</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It is a pure insurance plan with no investment component</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;It covers the risks related to life style</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The insurance company will pay to the nominee only in case where the life assured dies during the policy period</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Protection of the assets</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;For a cover of 20 times of your annual income, you just pay annually a sum of 2-3% of your annual income</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Term insurance offers flexible plan option to suit the need of every individual. You can choose;</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The amount of preferred sum assured.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Term life insurance premium payment which can be one time ,limited, or regular pay.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Term of the policy </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Add on policy coverage</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits of Term insurance Plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A term insurance policy is a must for every person and one cannot articulate its importance completely. Term insurance plan are the only life insurance policy. The term insurance plans  are the only insurance products that are specially designed to solve a sole purpose of protection. Here are some other core  benefits of term life insurance plan in India;</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;High Sum Assured</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Easy Buying Process</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Income Tax Benefits </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Death benefits</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Support in case of disability </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Survival benefits </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Maturity benefits  </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Features of the term plan </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Large Cover at Pocket-Friendly Price: buying a term insurance plan is at cost effective and does not create a hole in the pocket of the individual. A tern plan provides instant insurance solutions, which can be easily available by making premium payments.
                    </p>
                    <p class="font-body">
                        Complete flexibility ; Buying a term insurance policy is no more a tedious task rather one can easily buy an online term plan on the premise of their requirements. Before one zero down a term insurance policy, it is recommended that conduct thorough research in regards to the same and only then make an informed decision of buying an online term insurance policy.
                    </p>
                    <p class="font-body">All-round Protection: Choosing the right term life insurance plan for a long-term perspective will protect your family from any financial liabilities. One has the option to choose a term life cover for up to 50 years of age.</p>
                    <p class="font-body">The premiums can be paid annually, one-time, monthly or half yearly: One can easily make the payment towards term plan premium on monthly, quarterly or yearly basis on the premise of their convenience.</p>
                    <p class="font-body">Tax benefit: The term plan has excellent tax benefits. You can avail tax benefits under relevant section such as 80 C, 80D of income tax act, 1961.   </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Riders in Term Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Riders are the additional optional features that enhance the base policy coverage. There are many riders that you can attach to your base term plan. Most companies also offer a few riders as an in-built feature of a term plan. Before buying a term insurance plan, one must see that rider is available as an in-built feature in the same or as an add-on to the policy.
                    </p>
                    <p class="font-body">
                        On paying a small amount of additional premium, you can opt for such riders. These riders are highly beneficial as they come with their own standalone coverage amount.
                    </p>
                    <p class="font-body">For instance, if the life assured dies due to a road accident, under a standard term plan only the death benefit will be paid to the nominee. But, if there’s an Accidental Death Benefit Rider attached to the base term policy, the insurance company will pay the death benefit plus the rider benefit attached to the accidental death benefit rider.</p>
                    <p class="font-body">NOTE:  Tax benefits are subject to change in relevant laws, kindly consult with your tax advisor for details.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of term insurance plans in India </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        There are a number of term insurance Plan available in the market from various insurance companies in India. All of these companies offer both types of online and offline term life insurance with each term policy having its own set of specific features that make it the best term insurance plan in the market. following are the different types of term insurance plan: </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Level term plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Level-term insurance plan is a type of term life insurance. With this type of coverage, premiums are guaranteed to remain the unchanged throughout the contract, while the amount of coverage provided increases. As a result buying this type of term insurance plan the premiums will be that pocket-friendly. There are different types of common terms such as 10, 15, 20, and 30 years, based on the needs of the policyholder.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Standard Term Insurance Plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A standard term insurance plan is one where the insured person gets a cover against various risks against payment of a certain term life insurance premium amount. It is the most common term plan and generally also considered the best term insurance policy is the one that charges a yearly premium for an annual cover.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Term Return Of Premium Plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Term plan return of premium offers insurance coverage as death benefit along with the benefit of the return of premium as survival benefit. In case the insured survives the entire premium paid for such cover shall be refunds. It is becoming the most popular plan now days as the insurers gets his/her money back in case of survival. In pure term insurance plan, the sum assured amount offered to the policyholder is 10 times the annual premium paid.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Group insurance plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It is an insurance policy covering employer-employee groups or non-employer-employee groups offering standardised coverage. These policies provide the same set of benefits that an individual term plan offers but the overall coverage is generally more in terms of illnesses or other factors that are generally excluded in the individual policie. A group insurance policy offers advantages of standardised coverage at very competitive premium rates as risk is spread over a larger number of people</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Increasing term insurance plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In an increasing term insurance plan, the sum assured increases every year by a predefined amount to adjust against inflation or other financial goals. Unlike a regular term insurance plan, an increasing term plan allows the policyholder to increase the sum assured during the policy period and it will continuously increases until the overall value of the cover is 1.5 times the original cover under the term policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Decreasing term insurance plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Decreasing term insurance is renewable term life insurance where the sum assured of the policy decreases every year over the life of the policy at a predetermined rate. Premiums are usually constant throughout the contract  but the premium rate of decreasing term Plan is less as compared to the normal term Plan. Reductions in coverage typically occur monthly or annually.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Convertible term insurance plan </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A convertible insurance policy is a term usually related to life insurance and generally offered by some insurance companies in India ... This type of policy provides the benefit of obtaining less expensive term life insurance now while maintaining the option to convert to a permanent policy at a later date as insurance needs and financial resources change.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Single life and joint life term insurance plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Joint term life insurance plan provides joint protection to couples where both are the policyholders as well as beneficiaries In case of the single death payout option, if one partner is no more, the surviving partner is paid the sum assured, and the policy terminates thereafter, leaving the surviving partner without any life cover. In case of double payout option, payouts are made twice, once at the death of each the insured partners</p>
                    <p class="font-body">The choice between joint term life insurance policy or two separate term policies depends on your specific needs. payment.</p>
                    <p class="font-body">A joint term insurance plan works out to be cheaper than buying two individual term insurance Plan. Moreover, the features and benefits remain the same, ensuring both the members get the  same advantages of the plan.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Offline and online term insurance plan </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">You can get yourself insured through the help of an agent or branch, which is considered offline mode, under this method plans are sold through traditional method or by yourself on your computer, that is the online way. Under this term insurance Plan that are sold over the internet ... Term insurance is the type of policy that is taken for a fixed term and the premium payment will be paid for that term only.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How to Choose the Best Term Insurance Plan; Compare & buy the best term insurance plan</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In the era of various insurance product available in Indian market it makes a challenge for an individual to make a decision on the policy that will be the best suited for him/her. Moreover, each life insurance company offers a number of variants of term plans. And analyzing the right plan for oneself could be a task.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Let's have a look at the factors you should watch out for while comparing term insurance plans:</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Premiums & Life Cover: The best term insurance is one that offers the highest sum assured at the lowest possible monthly premium. This means that it is the best to start early because the younger you are, the lower will be the premium that you can avail for a high sum assured. </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Settlement Ratio: Claim settlement ratio of the company replicates the proportion of the total claims settled against the total claims raised. Higher claim settlement ration shows that chances of your claim settlement by your company is higher. It is very important factor that affect your you at the time of claim so it is advisable to keep a keen watch on the claim settlement ratio so that you can get assured that to ensure that your claim gets settled when the need arises.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Features of plan : Advisable that always have look into the features and benefits offered by the insurance companies while you compare term insurance online. Give attention to those factors like the policy you wish to opt for offers flexibility to choose the tenure of your choice, the sum assured amount, inbuilt features like terminal/critical illnesses, accidental death benefit and the premium payment modes, additional services provided by your company. </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp; Rider Benefits: Riders are the additional optional features that enhance the base policy coverage. There are many riders that you can attach to your base term plan. Most companies also offer a few riders as an in-built feature of a term plan. Before buying a term insurance plan, one must see that rider is available as an in-built feature in the same or as an add-on to the policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Factors Affecting Term Insurance Premium</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Insurers determine the premium rate based on the risk involved when you apply for a term insurance plan:</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Age: Age is the first and most important factor which determines your premium amount. The older you are when you apply for a term insurance plan, the higher your premium will be and vice versa. As the cost of premiums tends to increase with age, getting a term insurance plan as soon as possible is always suggested.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Sum Assured – a higher level of sum assured means that the company is taking a higher risk. That is why premiums are high for high levels of sum assured.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Gender: Studies have shown that women, on average, live more than longer than men. male lives are deemed to have a higher mortality risk than female lives. For this reason, premiums charged for men are comparatively more than women.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Family History –the premium payble on policy also bearing upon family history. If the family members like Father, Mother, Brother, Sisters has a medical history the insured is likely to have a high mortality risk. As such, premiums are higher for such individuals.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Lifestyle Habits: If the insured engages in consumption of alcohol, smoking etc then probably he’ll faces health risks. it is proven fact that people who consume tobacco products live a lessor life than those who do not. Hence insurers charge higher premium. </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Medical History: Your past medical history also impacts morality risk. If, in the past, you have suffered from any severe illnesses or are currently suffering with, the premium would be high. </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Occupation: The Occupation insured is involved in also plays an role in determining your premium amount. Insurers consider some professions as high risk than others. Hence the premiums for people working in safer environments pay lower premiums than others.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Body Structure – the height and weight of the insured determines the Body Mass Index which in turn tells the company whether the insured is underweight or overweight. If the insured is underweight or overweight, you’re more likely to suffer from medical risk such as weight-related medical conditions, such as breathing problems, heart disease, or diabetes, also affects the premium charged to you, and premiums are high for such individuals.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Place of Stay: The Geographical Locatoin also plays a vital role in determining how much you would pay for your term plan. For eg. If you live in areas facing natural calamities like earthquakes, tsunamis, etc. you would be paying a higher premium as the likelihood of you getting affected by it is more than people living in other locations.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Tenure and Frequency – premiums can be paid for the entire plan duration or for a limited duration. Similarly, premiums can be paid annually, half-yearly, quarterly or monthly. The premium rate depends on the paying tenure and frequency. For regular premiums, the premium rate is lower compared to limited premiums. Similarly, if premiums are paid annually or half-yearly, premiums are lower compared to quarterly or monthly premiums.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Reasons to buy term plan online is Smart Choice</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Apart from the hassle-free and simple process of purchase, an online term plan offers many other benefits.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Economic: Purchasing an online term insurance plan is almost 40 % more economic & affordable as compared to purchasing the plan offline. This is because there are no agents involved in between which saves all intermediary .The policy buyers can directly purchase the online term plan by visiting the website of the insurance company. By purchasing the plan online, the paperwork and processing fees automatically decrease as everything done online and these benefits are offered to the customers as discounts. </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Reliability: The online process of policy purchase is more reliable. Any broker is licensed with the Insurance Governing Authority (IRDAI). They offer you the finest approach for all your needs related to insurance. Essential and top-notch services make them more reliable.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Transparency: Everything about insurance product is open when you buy insurance through online such as features , tenure, benefits  available etc. Moreover when all necessary details are filled and form is submitted , the person will get regular update through emails ,texts regarding  his/her application status.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Easy Access: After buying a term life insurance policy the conventional way, people are usually unable to access their policy details as and when required. However, in case of a policy bought online, one can log in to their account to check all policy details at any time.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Flexibility: This applies prior to submission of form during the purchase or after policy has been brough, changes/correction such as address, mobile no etc.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Comparison: It is one of the best advantage which online mechanism provides to customer. Under this customer can take the advantages related comparison of term insurance policies online and then choose a particular plan as per one’s own requirement and suitability. By comparing term insurance Plan online, the insurance buyers can zero in on the most beneficial plan at the most affordable premium rates.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Reminders: By taking policy online through <a href="https://Click4bima.com/">www.Click4bima.com</a> ,you&rsquo;ll get regular reminders regarding renewals and other offers available to you from time to time.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Who should buy a term insurance policy ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">While purchasing an online insurance policy an individual should consider financial dependency. People who want to provide life protection to their family by paying nominal affordable premium.   </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Taxpayers: Along with the benefit of life coverage, one of the major advantages of purchasing a term insurance policy is that it offers the benefit to save on taxes. The premiums paid towards the term policy are tax exempted under section 80C of the Income Tax Act. Thus, if you want to save on taxes along with the benefit of life cover then you should certainly consider buying a term policy.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Parents: Parents are generally the only breadwinner of the family and the only financial support for their children. Thus the best way to secure the financial future of the children is to have a term insurance policy. As in term plan, a death benefit is paid to the beneficiary of the policy in case of the unfortunate demise of the insured person.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Youngsters : With increasing the age of a person , premium rate would be increase. Purchasing a term insurance policy at a young age is more beneficial as compared to buying a policy at an old age. </p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Newly Married: A term insurance policy can work as a financial safety net for your spouse even in your absence. In case of any eventuality, the term insurance plan not only provides financial security to the beneficiary but also takes care of the liabilities.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What term insurance excludes ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Before purchasing any term plan, it is very important for policy buyers to go through the terms and conditions of the policy and check all the exclusions mentioned in the policy documents. However, term insurance Plan come with only one exclusion i.e.</p>
                    <p class="font-body">Suicide Exclusion:  In case, the life insured (sane or insane) commits suicide within 12 months (1 years) from the date of policy inception or from date of revival of policy, the policy terminates immediately. In such cases, the beneficiary of the term insurance policy receives only the total term life insurance premium amount paid by the insured from the insurance company (inclusive of extra premium, if any, but exclusive of applicable charges and taxes imposed by the government).</p>
                    <p class="font-body">Apart from the above, there are other exclusions as well, which come under critical illness riders. If any of these exclusions are found at the underwriting stage of the term insurance policy, then no benefit will be offered. Let’s take a look at the exclusions, which come under critical illness riders.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Any health condition or ailment that has been suffered by the life insured/ were diagnosed/or receiving treatment within 48 months before the policy initiation date, the insurance provider will not provide insurance protection under critical illness rider of term plan.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The external congenital anomaly, which is in the accessible and visible part of the body, is not covered under the critical illness rider offered by the term insurance plan.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The critical illness rider offered by term plan does not provide coverage in case of intentional self- inflicted injuries, self-abuse, attempted suicide, psychological disorder, etc.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;No critical illness rider benefit is offered by the term plan in case of invasion, war, act of foreign enemy, civil war, revolution, rebellion, strike, mutiny, etc.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The life insured will not be entitled to avail the benefit of a critical illness rider offered by term plan, in case the insured is an alcoholic or addicted to drugs and narcotics.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Any man-made disasters such as wars or rights which result in the loss of life are not covered under the critical illness rider of an online term plan. Any suffering or damage resulting due to the carelessness of the people is not counted by the insurance company.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Thus, no coverage is offered to the insurance holder in such cases.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Term Plan does not offer any critical illness rider coverage in case of involvement of the life insured in extreme and risky sports activity such as scuba diving, bungee jumping, trekking, rock climbing, paragliding, skydiving, water sports activity, etc.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;Term Plans do not offer any critical illness rider in case of sexually transmitted diseases such as AIDS/HIV, the claim made by the beneficiary of the policy is rejected by the insurance company.</p>
                    <p class="font-body">⦁&nbsp; &nbsp;&nbsp;The term insurance company does not provide any critical illness rider in case of any casualty resulting from the involvement of law violation or unlawful activities. The beneficiary of the policy will be eligible for the claim only in case it is a sudden and unintentional act.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What kind of deaths are not covered under term life insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">1. Murder of the policyholder</p>
                    <p class="font-body">Case 1: If the nominee is a criminal</p>
                    <p class="font-body">The insurer will not settle the claim if the policyholder is murdered and investigations reveal the nominee was involved in the crime. "The payout would be made only if murder charges are dropped or on an acquittal.</p>
                    <p class="font-body">Case 2: If death of policyholder was due to involvement in criminal activity</p>
                    <p class="font-body">The insurer will not settle the claim if the policyholder is murdered due to his involvement in a criminal activity. No claim will be paid to the nominee if it is found that the policyholder was involved in some criminal activity. "Death due to the involvement in any type of criminal activity as defined by the law will not be covered under the policy.</p>
                    <p class="font-body">However, if policyholder has a criminal background i.e. he/she has a criminal record but dies due to any natural uncertainty, for instance, he/she dies because of some disease like swine flu, dengue, or lightning strike him/her, then in that case, the nominee will get the claim," she added.</p>
                    <p class="font-body">2. Death happens under the influence of alcohol</p>
                    <p class="font-body">If policyholder's death is due to driving under the influence of alcohol or narcotic substances, the insurer will reject the claim.</p>
                    <p class="font-body">Insurers rarely issue life insurance policies to those who drink heavily or consume narcotic substances. If the policyholder had not disclosed these habits when availing the term insurance policy, the insurer will withhold the death benefit. "Therefore, if you drink too much, most likely you won't get term insurance. </p>
                    <p class="font-body">3. Not disclosing the habit of smoking</p>
                    <p class="font-body">If you are a smoker, disclose the habit before availing the term insurance plan. Smokers may have a higher level of health risk and insurers add an additional amount to the premium.</p>
                    <p class="font-body">4. Death by participating in hazardous activities</p>
                    <p class="font-body">Death by participating in an adventure or hazardous activity is not covered by term insurance. These activities pose a threat to the life of the policyholder and may result in fatal accidents.</p>
                    <p class="font-body">5. Death due to pre-existing health conditions</p>
                    <p class="font-body">Death due to any condition that existed while availing the term insurance policy will not be settled by the insurer. There are a number of other death cases which are not covered under a regular term insurance policy. "Death due to self-inflicted injuries or hazardous activities, sexually transmitted diseases like HIV or AIDs, drug overdose, unless covered by a rider, are not settled by the insurer,</p>
                    <p class="font-body">6. Death due to childbirth</p>
                    <p class="font-body">If the death of the policyholder takes place due to pregnancy complications or childbirth, the insurer would not pay the sum assured to the nominee. At the time pregnancy, death occurring during the childbirth will not be covered in a term insurance policy.</p>
                    <p class="font-body">7. Suicidal death</p>
                    <p class="font-body">If the policyholder commits suicide within the first year of the policy term, then the nominee will not get the death benefit. However, most insurers provide suicide coverage from the second year onwards from the date of purchase of the policy, subject to terms and conditions.</p>
                    <p class="font-body">8. Death due to natural disaster</p>
                    <p class="font-body">If a policyholder with a term insurance plan dies due to a natural disaster such as an earthquake, or hurricane, then the nominee will not get the claim from the insurer. Death due to natural calamities like earthquake, tsunami etc. are also not covered under the term insurance policy. </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container mt-15">
        <div class="content-box">
            <div class="card-container container-fluid d-flex">
                <div class="card card-item shadow">
                    <p>TERM INSURANCE</p>
                    <p>A Term Insurance plan is a life insurance plan which promises to pay a lump sum benefit in case the life insured dies during the term of the plan. It is the best way to Secure Your Family&apos;s Financial Future at the Lowest Cost.The policy holder is free to choose the duration of plan and level of coverage. The sum assured, the term of the policy and the nominee who will get the benefit after the insured person&rsquo;s death depends on what he or she chooses at the time of buying the term insurance plan.</p>
                    <p>Why you should buy term insurance &amp; why it is necessary?</p>
                    <p>Well nobody knows that how long are you going to live, and everyone desires to live their family in comfort in their absence. A term insurance plan helps your prepare against the risk of untimely death. It is the most affordable insurance you can buy with a large cover. Being cheap on pocket has made this form of insurance immensely popular among the people. Term insurance works on a straightforward principle if you die, your family receives the life cover amount. Thereby, they can live a comfortable life even when you are not around. If you survive, nothing gets paid back. That&apos;s not all, Have you taken any loan like Home Loan, Car Loan, Personal Loan, Outstanding Credit Card dues? You obviously wouldn&apos;t want your family members burdened with your outstanding loans and debts. So, if you want your family to live a comfortable life, buy a term insurance plan. Ensure that you have a large cover amount, which would take care of your family as you would have done until you retire.</p>
                    <p>In addition, there are various reason to buy a term plan, however here are the key and basic reason that you must buy tern insurance:</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;The dependency of your family;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;It is a pure insurance plan with no investment component</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;It covers the risks related to life style</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;The insurance company will pay to the nominee only in case where the life assured dies during the policy period</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Protection of the assets</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;For a cover of 20 times of your annual income, you just pay annually a sum of 2-3% of your annual income</p>
                    <p><br></p>
                    <p>Term insurance offers flexible plan option to suit the need of every individual. You can choose;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;The amount of preferred sum assured.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Term life insurance premium payment which can be one time ,limited, or regular pay.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Term of the policy&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Add on policy coverage</p>
                    <p><br></p>
                    <p>Benefits of Term insurance Plan</p>
                    <p>A term insurance policy is a must for every person and one cannot articulate its importance completely. Term insurance plan are the only life insurance policy. The term insurance plans &nbsp;are the only insurance products that are specially designed to solve a sole purpose of protection. Here are some other core &nbsp;benefits of term life insurance plan in India;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;High Sum Assured</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Easy Buying Process</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Income Tax Benefits</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Death benefits&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Support in case of disability&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Survival benefits&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Maturity benefits &nbsp;</p>
                    <p><br></p>
                    <p>Features of the term plan&nbsp;</p>
                    <p>Large Cover at Pocket-Friendly Price: buying a term insurance plan is at cost effective and does not create a hole in the pocket of the individual. A tern plan provides instant insurance solutions, which can be easily available by making premium payments.</p>
                    <p>Complete flexibility ; Buying a term insurance policy is no more a tedious task rather one can easily buy an online term plan on the premise of their requirements. Before one zero down a term insurance policy, it is recommended that conduct thorough research in regards to the same and only then make an informed decision of buying an online term insurance policy.</p>
                    <p>All-round Protection: Choosing the right term life insurance plan for a long-term perspective will protect your family from any financial liabilities. One has the option to choose a term life cover for up to 50 years of age.</p>
                    <p>The premiums can be paid annually, one-time, monthly or half yearly: One can easily make the payment towards term plan premium on monthly, quarterly or yearly basis on the premise of their convenience.</p>
                    <p>Tax benefit: The term plan has excellent tax benefits. You can avail tax benefits under relevant section such as 80 C, 80D of income tax act, 1961.&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                    <p>Riders in Term Insurance</p>
                    <p>Riders are the additional optional features that enhance the base policy coverage. There are many riders that you can attach to your base term plan. Most companies also offer a few riders as an in-built feature of a term plan. Before buying a term insurance plan, one must see that rider is available as an in-built feature in the same or as an add-on to the policy.</p>
                    <p>On paying a small amount of additional premium, you can opt for such riders. These riders are highly beneficial as they come with their own standalone coverage amount.</p>
                    <p>For instance, if the life assured dies due to a road accident, under a standard term plan only the death benefit will be paid to the nominee. But, if there&rsquo;s an Accidental Death Benefit Rider attached to the base term policy, the insurance company will pay the death benefit plus the rider benefit attached to the accidental death benefit rider.</p>
                    <p>NOTE: &nbsp;Tax benefits are subject to change in relevant laws, kindly consult with your tax advisor for details.</p>
                    <p><br></p>
                    <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Types of term insurance plans in India&nbsp;</p>
                    <p>There are a number of term insurance Plan available in the market from various insurance companies in India. All of these companies offer both types of online and offline term life insurance with each term policy having its own set of specific features that make it the best term insurance plan in the market. following are the different types of term insurance plan:&nbsp;</p>
                    <p>Level term plan</p>
                    <p>Level-term insurance plan is a type of term life insurance. With this type of coverage, premiums are guaranteed to remain the unchanged throughout the contract, while the amount of coverage provided increases. As a result buying this type of term insurance plan the premiums will be that pocket-friendly. There are different types of common terms such as 10, 15, 20, and 30 years, based on the needs of the policyholder.</p>
                    <p>Standard Term Insurance Plan</p>
                    <p>A standard term insurance plan is one where the insured person gets a cover against various risks against payment of a certain term life insurance premium amount. It is the most common term plan and generally also considered the best term insurance policy is the one that charges a yearly premium for an annual cover.</p>
                    <p>Term Return Of Premium Plan</p>
                    <p>Term plan return of premium offers insurance coverage as death benefit along with the benefit of the return of premium as survival benefit. In case the insured survives the entire premium paid for such cover shall be refunds. It is becoming the most popular plan now days as the insurers gets his/her money back in case of survival. In pure term insurance plan, the sum assured amount offered to the policyholder is 10 times the annual premium paid.</p>
                    <p>Group insurance plan&nbsp;</p>
                    <p>It is an insurance policy covering employer-employee groups or non-employer-employee groups offering standardised coverage. These policies provide the same set of benefits that an individual term plan offers but the overall coverage is generally more in terms of illnesses or other factors that are generally excluded in the individual policie. A group insurance policy offers advantages of standardised coverage at very competitive premium rates as risk is spread over a larger number of people</p>
                    <p>&nbsp;&nbsp;</p>
                    <p>Increasing term insurance plan</p>
                    <p>In an increasing term insurance plan, the sum assured increases every year by a predefined amount to adjust against inflation or other financial goals. Unlike a regular term insurance plan, an increasing term plan allows the policyholder to increase the sum assured during the policy period and it will continuously increases until the overall value of the cover is 1.5 times the original cover under the term policy.</p>
                    <p>Decreasing term insurance plan&nbsp;</p>
                    <p>Decreasing term insurance is renewable term life insurance where the sum assured of the policy decreases every year over the life of the policy at a predetermined rate. Premiums are usually constant throughout the contract &nbsp;but the premium rate of decreasing term Plan is less as compared to the normal term Plan. Reductions in coverage typically occur monthly or annually.</p>
                    <p>Convertible term insurance plan&nbsp;</p>
                    <p>A convertible insurance policy is a term usually related to life insurance and generally offered by some insurance companies in India ... This type of policy provides the benefit of obtaining less expensive term life insurance now while maintaining the option to convert to a permanent policy at a later date as insurance needs and financial resources change.</p>
                    <p>Single life and joint life term insurance plan</p>
                    <p>Joint term life insurance plan provides joint protection to couples where both are the policyholders as well as beneficiaries In case of the single death payout option, if one partner is no more, the surviving partner is paid the sum assured, and the policy terminates thereafter, leaving the surviving partner without any life cover. In case of double payout option, payouts are made twice, once at the death of each the insured partners</p>
                    <p>The choice between joint term life insurance policy or two separate term policies depends on your specific needs. payment.</p>
                    <p>A joint term insurance plan works out to be cheaper than buying two individual term insurance Plan. Moreover, the features and benefits remain the same, ensuring both the members get the &nbsp;same advantages of the plan.</p>
                    <p>Offline and online term insurance plan&nbsp;</p>
                    <p>You can get yourself insured through the help of an agent or branch, which is considered offline mode, under this method plans are sold through traditional method or by yourself on your computer, that is the online way. Under this term insurance Plan that are sold over the internet ... Term insurance is the type of policy that is taken for a fixed term and the premium payment will be paid for that term only.</p>
                    <p>How to Choose the Best Term Insurance Plan; Compare &amp; buy the best term insurance plan</p>
                    <p>In the era of various insurance product available in Indian market it makes a challenge for an individual to make a decision on the policy that will be the best suited for him/her. Moreover, each life insurance company offers a number of variants of term plans. And analyzing the right plan for oneself could be a task.</p>
                    <p>Let&apos;s have a look at the factors you should watch out for while comparing term insurance plans:</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Premiums &amp; Life Cover: The best term insurance is one that offers the highest sum assured at the lowest possible monthly premium. This means that it is the best to start early because the younger you are, the lower will be the premium that you can avail for a high sum assured.&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Settlement Ratio: Claim settlement ratio of the company replicates the proportion of the total claims settled against the total claims raised. Higher claim settlement ration shows that chances of your claim settlement by your company is higher. It is very important factor that affect your you at the time of claim so it is advisable to keep a keen watch on the claim settlement ratio so that you can get assured that to ensure that your claim gets settled when the need arises.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Features of plan : Advisable that always have look into the features and benefits offered by the insurance companies while you compare term insurance online. Give attention to those factors like the policy you wish to opt for offers flexibility to choose the tenure of your choice, the sum assured amount, inbuilt features like terminal/critical illnesses, accidental death benefit and the premium payment modes, additional services provided by your company.&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Rider Benefits: Riders are the additional optional features that enhance the base policy coverage. There are many riders that you can attach to your base term plan. Most companies also offer a few riders as an in-built feature of a term plan. Before buying a term insurance plan, one must see that rider is available as an in-built feature in the same or as an add-on to the policy.</p>
                    <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Factors Affecting Term Insurance Premium</p>
                    <p>Insurers determine the premium rate based on the risk involved when you apply for a term insurance plan:</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Age: Age is the first and most important factor which determines your premium amount. The older you are when you apply for a term insurance plan, the higher your premium will be and vice versa. As the cost of premiums tends to increase with age, getting a term insurance plan as soon as possible is always suggested.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Sum Assured &ndash; a higher level of sum assured means that the company is taking a higher risk. That is why premiums are high for high levels of sum assured.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Gender: Studies have shown that women, on average, live more than longer than men. male lives are deemed to have a higher mortality risk than female lives. For this reason, premiums charged for men are comparatively more than women.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Family History &ndash;the premium payble on policy also bearing upon family history. If the family members like Father, Mother, Brother, Sisters has a medical history the insured is likely to have a high mortality risk. As such, premiums are higher for such individuals.</p>
                    <p><br></p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Lifestyle Habits: If the insured engages in consumption of alcohol, smoking etc then probably he&rsquo;ll faces health risks. it is proven fact that people who consume tobacco products live a lessor life than those who do not. Hence insurers charge higher premium.&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Medical History: Your past medical history also impacts morality risk. If, in the past, you have suffered from any severe illnesses or are currently suffering with, the premium would be high.&nbsp;</p>
                    <p><br></p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Occupation: The Occupation insured is involved in also plays an role in determining your premium amount. Insurers consider some professions as high risk than others. Hence the premiums for people working in safer environments pay lower premiums than others.</p>
                    <p><br></p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Body Structure &ndash; the height and weight of the insured determines the Body Mass Index which in turn tells the company whether the insured is underweight or overweight. If the insured is underweight or overweight, you&rsquo;re more likely to suffer from medical risk such as weight-related medical conditions, such as breathing problems, heart disease, or diabetes, also affects the premium charged to you, and premiums are high for such individuals.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Place of Stay: The Geographical Locatoin also plays a vital role in determining how much you would pay for your term plan. For eg. If you live in areas facing natural calamities like earthquakes, tsunamis, etc. you would be paying a higher premium as the likelihood of you getting affected by it is more than people living in other locations.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Tenure and Frequency &ndash; premiums can be paid for the entire plan duration or for a limited duration. Similarly, premiums can be paid annually, half-yearly, quarterly or monthly. The premium rate depends on the paying tenure and frequency. For regular premiums, the premium rate is lower compared to limited premiums. Similarly, if premiums are paid annually or half-yearly, premiums are lower compared to quarterly or monthly premiums.&nbsp;</p>
                    <p><br></p>
                    <p>Reasons to buy term plan online is Smart Choice</p>
                    <p>Apart from the hassle-free and simple process of purchase, an online term plan offers many other benefits.</p>
                    <p>Economic: Purchasing an online term insurance plan is almost 40 % more economic &amp; affordable as compared to purchasing the plan offline. This is because there are no agents involved in between which saves all intermediary .The policy buyers can directly purchase the online term plan by visiting the website of the insurance company. By purchasing the plan online, the paperwork and processing fees automatically decrease as everything done online and these benefits are offered to the customers as discounts.&nbsp;</p>
                    <p>Reliability: The online process of policy purchase is more reliable. Any broker is licensed with the Insurance Governing Authority (IRDAI). They offer you the finest approach for all your needs related to insurance. Essential and top-notch services make them more reliable.</p>
                    <p>Transparency: Everything about insurance product is open when you buy insurance through online such as features , tenure, benefits &nbsp;available etc. Moreover when all necessary details are filled and form is submitted , the person will get regular update through emails ,texts regarding &nbsp;his/her application status. &nbsp; &nbsp;&nbsp;</p>
                    <p>Easy Access: After buying a term life insurance policy the conventional way, people are usually unable to access their policy details as and when required. However, in case of a policy bought online, one can log in to their account to check all policy details at any time.</p>
                    <p><br></p>
                    <p>Flexibility: This applies prior to submission of form during the purchase or after policy has been brough, changes/correction such as address, mobile no etc.</p>
                    <p>Comparison: It is one of the best advantage which online mechanism provides to customer. Under this customer can take the advantages related comparison of term insurance policies online and then choose a particular plan as per one&rsquo;s own requirement and suitability. By comparing term insurance Plan online, the insurance buyers can zero in on the most beneficial plan at the most affordable premium rates.</p>
                    <p><br></p>
                    <p>Reminders: By taking policy online through <a href="https://Click4bima.com/">www.Click4bima.com</a> ,you&rsquo;ll get regular reminders regarding renewals and other offers available to you from time to time.</p>
                    <p>Who should buy a term insurance policy ?</p>
                    <p>While purchasing an online insurance policy an individual should consider financial dependency. People who want to provide life protection to their family by paying nominal affordable premium. &nbsp;&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Taxpayers: Along with the benefit of life coverage, one of the major advantages of purchasing a term insurance policy is that it offers the benefit to save on taxes. The premiums paid towards the term policy are tax exempted under section 80C of the Income Tax Act. Thus, if you want to save on taxes along with the benefit of life cover then you should certainly consider buying a term policy.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Parents: Parents are generally the only breadwinner of the family and the only financial support for their children. Thus the best way to secure the financial future of the children is to have a term insurance policy. As in term plan, a death benefit is paid to the beneficiary of the policy in case of the unfortunate demise of the insured person.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Youngsters : With increasing the age of a person , premium rate would be increase. Purchasing a term insurance policy at a young age is more beneficial as compared to buying a policy at an old age.&nbsp;</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Newly Married: A term insurance policy can work as a financial safety net for your spouse even in your absence. In case of any eventuality, the term insurance plan not only provides financial security to the beneficiary but also takes care of the liabilities.</p>
                    <p>What term insurance excludes ?</p>
                    <p>Before purchasing any term plan, it is very important for policy buyers to go through the terms and conditions of the policy and check all the exclusions mentioned in the policy documents. However, term insurance Plan come with only one exclusion i.e.</p>
                    <p>Suicide Exclusion: &nbsp;In case, the life insured (sane or insane) commits suicide within 12 months (1 years) from the date of policy inception or from date of revival of policy, the policy terminates immediately. In such cases, the beneficiary of the term insurance policy receives only the total term life insurance premium amount paid by the insured from the insurance company (inclusive of extra premium, if any, but exclusive of applicable charges and taxes imposed by the government).</p>
                    <p>Apart from the above, there are other exclusions as well, which come under critical illness riders. If any of these exclusions are found at the underwriting stage of the term insurance policy, then no benefit will be offered. Let&rsquo;s take a look at the exclusions, which come under critical illness riders.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Any health condition or ailment that has been suffered by the life insured/ were diagnosed/or receiving treatment within 48 months before the policy initiation date, the insurance provider will not provide insurance protection under critical illness rider of term plan.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;The external congenital anomaly, which is in the accessible and visible part of the body, is not covered under the critical illness rider offered by the term insurance plan.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;The critical illness rider offered by term plan does not provide coverage in case of intentional self- inflicted injuries, self-abuse, attempted suicide, psychological disorder, etc.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;No critical illness rider benefit is offered by the term plan in case of invasion, war, act of foreign enemy, civil war, revolution, rebellion, strike, mutiny, etc.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;The life insured will not be entitled to avail the benefit of a critical illness rider offered by term plan, in case the insured is an alcoholic or addicted to drugs and narcotics.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Any man-made disasters such as wars or rights which result in the loss of life are not covered under the critical illness rider of an online term plan. Any suffering or damage resulting due to the carelessness of the people is not counted by the insurance company.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Thus, no coverage is offered to the insurance holder in such cases.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Term Plan does not offer any critical illness rider coverage in case of involvement of the life insured in extreme and risky sports activity such as scuba diving, bungee jumping, trekking, rock climbing, paragliding, skydiving, water sports activity, etc.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;Term Plans do not offer any critical illness rider in case of sexually transmitted diseases such as AIDS/HIV, the claim made by the beneficiary of the policy is rejected by the insurance company.</p>
                    <p>⦁&nbsp; &nbsp;&nbsp;The term insurance company does not provide any critical illness rider in case of any casualty resulting from the involvement of law violation or unlawful activities. The beneficiary of the policy will be eligible for the claim only in case it is a sudden and unintentional act.</p>
                    <p>What kind of deaths are not covered under term life insurance?</p>
                    <p>1. Murder of the policyholder</p>
                    <p>Case 1: If the nominee is a criminal</p>
                    <p>The insurer will not settle the claim if the policyholder is murdered and investigations reveal the nominee was involved in the crime. &quot;The payout would be made only if murder charges are dropped or on an acquittal.</p>
                    <p><br></p>
                    <p>Case 2: If death of policyholder was due to involvement in criminal activity</p>
                    <p>The insurer will not settle the claim if the policyholder is murdered due to his involvement in a criminal activity. No claim will be paid to the nominee if it is found that the policyholder was involved in some criminal activity. &quot;Death due to the involvement in any type of criminal activity as defined by the law will not be covered under the policy.</p>
                    <p>However, if policyholder has a criminal background i.e. he/she has a criminal record but dies due to any natural uncertainty, for instance, he/she dies because of some disease like swine flu, dengue, or lightning strike him/her, then in that case, the nominee will get the claim,&quot; she added.</p>
                    <p>2. Death happens under the influence of alcohol</p>
                    <p>If policyholder&apos;s death is due to driving under the influence of alcohol or narcotic substances, the insurer will reject the claim.</p>
                    <p>Insurers rarely issue life insurance policies to those who drink heavily or consume narcotic substances. If the policyholder had not disclosed these habits when availing the term insurance policy, the insurer will withhold the death benefit. &quot;Therefore, if you drink too much, most likely you won&apos;t get term insurance.&nbsp;</p>
                    <p>3. Not disclosing the habit of smoking</p>
                    <p>If you are a smoker, disclose the habit before availing the term insurance plan. Smokers may have a higher level of health risk and insurers add an additional amount to the premium.</p>
                    <p>4. Death by participating in hazardous activities</p>
                    <p>Death by participating in an adventure or hazardous activity is not covered by term insurance. These activities pose a threat to the life of the policyholder and may result in fatal accidents.</p>
                    <p>5. Death due to pre-existing health conditions</p>
                    <p>Death due to any condition that existed while availing the term insurance policy will not be settled by the insurer. There are a number of other death cases which are not covered under a regular term insurance policy. &quot;Death due to self-inflicted injuries or hazardous activities, sexually transmitted diseases like HIV or AIDs, drug overdose, unless covered by a rider, are not settled by the insurer,</p>
                    <p><br></p>
                    <p>6. Death due to childbirth</p>
                    <p>If the death of the policyholder takes place due to pregnancy complications or childbirth, the insurer would not pay the sum assured to the nominee. At the time pregnancy, death occurring during the childbirth will not be covered in a term insurance policy.</p>
                    <p><br></p>
                    <p>7. Suicidal death</p>
                    <p>If the policyholder commits suicide within the first year of the policy term, then the nominee will not get the death benefit. However, most insurers provide suicide coverage from the second year onwards from the date of purchase of the policy, subject to terms and conditions.</p>
                    <p><br></p>
                    <p>8. Death due to natural disaster</p>
                    <p>If a policyholder with a term insurance plan dies due to a natural disaster such as an earthquake, or hurricane, then the nominee will not get the claim from the insurer. Death due to natural calamities like earthquake, tsunami etc. are also not covered under the term insurance policy.&nbsp;</p>
                    <p><br></p>
                    <p><br></p>

                </div>
            </div>
        </div>
    </div> -->
<app-footer></app-footer>
