export class punjabiLabel {
    public product: string = 'ਉਤਪਾਦ';
    public support: string = 'ਸਮਰਥਨ';
    public raise_a_query: string = 'ਸਵਾਲ ਪੁੱਛੋ';
    public raise_a_claim: string = 'ਦਾਅਵੇ ਲਈ ਪੁੱਛੋ';
    public contact_us: string = 'ਸਾਨੂੰ ਸੰਪਰਕ ਕਰੋ';
    public career: string = 'ਰੋਜ਼ਗਾਰ';
    public gallery: string = 'ਗੈਲਰੀ';
    public login: string = 'ਲਾਗਇਨ';
    public two_wheeler: string = 'ਦੋਪਹੀਆ ਵਾਹਨ';
    public four_wheeler: string = 'ਚਾਰ ਪਹੀਆ ਵਾਹਨ';
    public health: string = 'ਸਿਹਤ';
    public commercial_vehicle: string = 'ਵਪਾਰਕ ਵਾਹਨ';
    public bike_ins_title: string = 'ਆਪਣੇ ਦੋਪਹੀਆ ਵਾਹਨ ਬੀਮਾ ਤੇ ਵਿਸ਼ੇਸ਼ ਤੌਰ ਤੇ ਘੱਟ ਕੀਮਤ ਪ੍ਰਾਪਤ ਕਰੋ';
    public bike_ins_start: string = 'ਦੋਪਹੀਆ ਵਾਹਨ ਬੀਮਾ ₹635* ਤੋਂ ਸ਼ੁਰੂ';
    public enter_bike_number: string = 'ਦੋ-ਪਹੀਆ ਵਾਹਨ ਨੰਬਰ ਦਰਜ ਕਰੋ ਉਦਾਹਰਨ: AB12CD3456 ';
    public verify: string = 'ਪੁਸ਼ਟੀ ਕਰੋ';
    public dont_have_policy: string = "ਰਜਿਸਟਰੇਸ਼ਨ ਨੰਬਰ ਯਾਦ ਨਹੀਂ ਹੈ";
    public buy_new_bike: string = 'ਨਵਾਂ ਦੋਪਹੀਆ ਵਾਹਨ ਖਰੀਦਿਆ ਹੈ?  ';
    public what_is_ins1: string = 'ਬੀਮਾ ਕੀ';
    public what_is_ins2: string = 'ਹੈ?';
    public what_is_ins_title: string = 'ਬੀਮੇ ਨਾਲ ਮਨ ਦੀ ਸ਼ਾਂਤੀ ਲੱਭੋ';
    public what_is_ins_text: string = "ਸਾਡੇ Click4bima ਪੋਰਟਲ ਵਿੱਚ ਤੁਹਾਡਾ ਸੁਆਗਤ ਹੈ, ਭਵਿੱਖ ਦੀ ਸੁਰੱਖਿਆ ਲਈ ਤੁਹਾਡਾ ਗੇਟਵੇ! ਸਾਡਾ ਪੋਰਟਲ ਬੀਮਾ ਵਿਕਲਪਾਂ ਦੀ ਇੱਕ ਵਿਸ਼ਾਲ ਸ਼੍ਰੇਣੀ ਦੀ ਪੜਚੋਲ ਕਰਨ ਲਈ ਤੁਹਾਡੀ ਇੱਕ-ਸਟਾਪ ਮੰਜ਼ਿਲ ਹੈ। ਸਿਹਤ ਅਤੇ ਆਟੋ ਤੋਂ ਲੈ ਕੇ ਘਰ ਅਤੇ ਯਾਤਰਾ ਤੱਕ, ਅਸੀਂ ਤੁਹਾਡੀਆਂ ਜ਼ਰੂਰਤਾਂ ਦੇ ਅਨੁਸਾਰ ਤੁਹਾਡੇ ਲਈ ਸਭ ਤੋਂ ਵਧੀਆ ਕਵਰੇਜ ਯੋਜਨਾਵਾਂ ਲਿਆਉਣ ਲਈ ਨਾਮਵਰ ਬੀਮਾ ਕੰਪਨੀਆਂ ਨਾਲ ਭਾਈਵਾਲੀ ਕੀਤੀ ਹੈ। ਵੱਖ-ਵੱਖ ਨੀਤੀਆਂ ਦੀ ਤੁਲਨਾ ਕਰਕੇ, ਤੁਸੀਂ ਸੂਝਵਾਨ ਫੈਸਲੇ ਲੈ ਸਕਦੇ ਹੋ ਅਤੇ ਆਪਣੀ ਜੀਵਨਸ਼ੈਲੀ ਅਤੇ ਬਜਟ ਦੇ ਅਨੁਕੂਲ ਇੱਕ ਚੁਣ ਸਕਦੇ ਹੋ।";
    public why_insurance_imp1: string = 'ਬੀਮਾ ਕਿਉਂ';
    public why_insurance_imp2: string = 'ਜ਼ਰੂਰੀ ਹੈ?';
    public why_insurance_imp_title: string = 'ਕੱਲ੍ਹ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨਾ: ਬੀਮੇ ਦੀ ਜੀਵਨਸ਼ਕਤੀ ਦਾ ਪਰਦਾਫਾਸ਼ ਕਰਨਾ';
    public why_insurance_imp_text: string = "ਬੀਮਾ ਜੀਵਨ ਦੀਆਂ ਅਨਿਸ਼ਚਿਤਤਾਵਾਂ ਦੇ ਵਿਰੁੱਧ ਤੁਹਾਡੀ ਢਾਲ ਹੈ, ਤੁਹਾਨੂੰ ਮਨ ਦੀ ਸ਼ਾਂਤੀ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ ਜਦੋਂ ਤੁਹਾਨੂੰ ਇਸਦੀ ਸਭ ਤੋਂ ਵੱਧ ਲੋੜ ਹੁੰਦੀ ਹੈ। ਇਹ ਤੁਹਾਡੇ ਅਤੇ ਤੁਹਾਡੇ ਚੁਣੇ ਹੋਏ ਬੀਮਾ ਪ੍ਰਦਾਤਾ ਵਿਚਕਾਰ ਇਕਰਾਰਨਾਮਾ ਹੈ ਜੋ ਅਚਾਨਕ ਘਟਨਾਵਾਂ ਦੇ ਸਮੇਂ ਵਿੱਤੀ ਸਹਾਇਤਾ ਦੀ ਗਰੰਟੀ ਦਿੰਦਾ ਹੈ। ਭਾਵੇਂ ਇਹ ਅਚਾਨਕ ਡਾਕਟਰੀ ਖਰਚਾ ਹੋਵੇ, ਇੱਕ ਆਟੋਮੋਬਾਈਲ ਦੁਰਘਟਨਾ ਹੋਵੇ, ਤੁਹਾਡੀ ਜਾਇਦਾਦ ਨੂੰ ਨੁਕਸਾਨ ਹੋਵੇ, ਜਾਂ ਕੋਈ ਅਣਕਿਆਸੀ ਸਥਿਤੀ ਹੋਵੇ, ਬੋਝ ਨੂੰ ਘੱਟ ਕਰਨ ਲਈ ਬੀਮਾ ਕਦਮ ਚੁੱਕਦਾ ਹੈ।";
    public claim_assistance1: string = 'ਸਹਾਇਤਾ ਦਾ';
    public claim_assistance2: string = 'ਦਾਅਵਾ ਕਰੋ';
    public claim_assistance_title: string = 'ਤੁਹਾਡੀ ਮਨ ਦੀ ਸ਼ਾਂਤੀ ਬਹਾਲ ਹੋ ਗਈ ਹੈ';
    public claim_assistance_text: string = "Click4bima ਤੋਂ 'ਕਲੇਮ ਅਸਿਸਟੈਂਸ' ਦੇ ਨਾਲ, ਤੁਸੀਂ ਭਰੋਸਾ ਰੱਖ ਸਕਦੇ ਹੋ ਕਿ ਤੁਹਾਡੇ ਕੋਲ ਇੱਕ ਭਰੋਸੇਮੰਦ ਸਾਥੀ ਹੈ ਜੋ ਇੱਕ ਸੁਚਾਰੂ ਦਾਅਵਾ ਪ੍ਰਕਿਰਿਆ ਦੀ ਮਹੱਤਤਾ ਨੂੰ ਸਮਝਦਾ ਹੈ। ਇਹ ਜਾਣ ਕੇ ਆਪਣੀ ਮਨ ਦੀ ਸ਼ਾਂਤੀ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰੋ ਕਿ ਤੁਸੀਂ ਸਿਰਫ਼ ਇੱਕ ਨੀਤੀ ਤੋਂ ਵੱਧ ਸੁਰੱਖਿਅਤ ਹੋ - ਤੁਹਾਨੂੰ ਇੱਕ ਟੀਮ ਦੁਆਰਾ ਸਮਰਥਨ ਪ੍ਰਾਪਤ ਹੈ ਜੋ ਪਰਵਾਹ ਕਰਦੀ ਹੈ। ਸਾਡੀ 'ਕਲੇਮ ਅਸਿਸਟੈਂਸ' ਤੁਹਾਡਾ ਭਰੋਸੇਮੰਦ ਸਾਥੀ ਹੈ ਜਦੋਂ ਤੁਹਾਨੂੰ ਇਸਦੀ ਸਭ ਤੋਂ ਵੱਧ ਲੋੜ ਹੁੰਦੀ ਹੈ।";
    public best_place_ins_title: string = 'ਭਾਰਤ ਵਿੱਚ ਬੀਮਾ ਖਰੀਦਣ ਲਈ ਸਾਨੂੰ ਸਭ ਤੋਂ ਵਧੀਆ ਥਾਂ ਕੀ ਬਣਾਉਂਦੀ ਹੈ?';
    public best_place_ins_text: string = 'ਸਾਡੇ Click4bima ਪੋਰਟਲ ਵਿੱਚ ਤੁਹਾਡਾ ਸੁਆਗਤ ਹੈ, ਭਾਰਤ ਵਿੱਚ ਸਭ ਤੋਂ ਵਧੀਆ ਬੀਮਾ ਕਵਰੇਜ ਲੱਭਣ ਲਈ ਤੁਹਾਡੀ ਇੱਕ-ਸਟਾਪ ਮੰਜ਼ਿਲ। ਹੈਰਾਨ ਹੋ ਰਹੇ ਹਾਂ ਕਿ ਅਸੀਂ ਬਾਕੀਆਂ ਤੋਂ ਵੱਖ ਕਿਉਂ ਹਾਂ? ਸਹੀ ਬੀਮਾ ਕਵਰੇਜ ਦੀ ਸੁਰੱਖਿਆ ਦੇ ਨਾਲ ਚਿੰਤਾ-ਮੁਕਤ ਭਵਿੱਖ ਨੂੰ ਅਪਣਾਓ। ਅੱਜ ਹੀ ਸਾਡੇ ਨਾਲ ਆਪਣੀ ਬੀਮਾ ਯਾਤਰਾ ਸ਼ੁਰੂ ਕਰੋ।';

    public everytime_support_title: string = '੨੪*੭ ਸਹਿਯੋਗ';
    public everytime_support_text: string = "ਸਾਡੀ 24/7 ਬੀਮਾ ਸਹਾਇਤਾ ਨਾਲ ਕਿਸੇ ਵੀ ਸਮੇਂ, ਕਿਤੇ ਵੀ ਮਨ ਦੀ ਸ਼ਾਂਤੀ ਪ੍ਰਾਪਤ ਕਰੋ। ਸਾਡੀ ਸਮਰਪਿਤ ਟੀਮ ਤੁਹਾਨੂੰ ਮਾਹਰ ਸਹਾਇਤਾ ਪ੍ਰਦਾਨ ਕਰਨ, ਤੁਹਾਡੇ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦੇਣ, ਅਤੇ ਇਹ ਯਕੀਨੀ ਬਣਾਉਣ ਲਈ ਕਿ ਤੁਸੀਂ ਹਮੇਸ਼ਾ ਕਵਰ ਹੋ।"


    public best_price_ins_title: string = 'ਵਧੀਆ ਕੀਮਤ ਬੀਮਾ';
    public best_price_ins_text: string = "ਸਾਡਾ ਮਿਸ਼ਨ ਸਧਾਰਨ ਹੈ: ਗੁਣਵੱਤਾ ਨਾਲ ਸਮਝੌਤਾ ਕੀਤੇ ਬਿਨਾਂ ਤੁਹਾਨੂੰ ਸਭ ਤੋਂ ਵਧੀਆ ਬੀਮਾ ਸੌਦੇ ਪ੍ਰਦਾਨ ਕਰਨਾ। ਅਨੁਕੂਲਿਤ ਨੀਤੀਆਂ ਦੀ ਇੱਕ ਸੀਮਾ ਦੇ ਨਾਲ, ਅਸੀਂ ਤੁਹਾਡੇ ਬਜਟ ਨੂੰ ਬਰਕਰਾਰ ਰੱਖਦੇ ਹੋਏ ਤੁਹਾਡੇ ਭਵਿੱਖ ਦੀ ਸੁਰੱਖਿਆ ਲਈ ਇੱਥੇ ਹਾਂ।";
    public reliable_title: string = 'ਭਰੋਸੇਯੋਗ';
    public reliable_text: string = "ਤੁਸੀਂ ਬਿਨਾਂ ਕਿਸੇ ਅਪਵਾਦ ਦੇ, ਤੁਹਾਡੇ ਲਈ ਸਭ ਤੋਂ ਮਹੱਤਵਪੂਰਨ ਚੀਜ਼ਾਂ ਦੀ ਰੱਖਿਆ ਕਰਨ ਲਈ ਸਾਡੇ 'ਤੇ ਭਰੋਸਾ ਕਰ ਸਕਦੇ ਹੋ। ਸਾਡੇ ਵਾਅਦਿਆਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਦੇ ਸਾਬਤ ਹੋਏ ਟਰੈਕ ਰਿਕਾਰਡ ਦੇ ਨਾਲ, ਅਸੀਂ ਤੁਹਾਡੇ ਮਨ ਦੀ ਸ਼ਾਂਤੀ, ਹਰ ਕਦਮ 'ਤੇ ਯਕੀਨੀ ਬਣਾਉਂਦੇ ਹਾਂ। ਆਪਣੇ ਭਵਿੱਖ ਦੀ ਰਾਖੀ ਲਈ ਅਟੁੱਟ ਭਰੋਸੇਯੋਗਤਾ ਲਈ ਸਾਨੂੰ ਚੁਣੋ।";
    public easy_claim_title: string = 'ਦਾਅਵਾ ਆਸਾਨ ਕਰ ਦਿੱਤਾ';
    public easy_claim_text: string = "ਸਾਡੀਆਂ ਸੁਚਾਰੂ ਪ੍ਰਕਿਰਿਆਵਾਂ ਅਤੇ ਸਮਰਪਿਤ ਸਹਾਇਤਾ ਟੀਮ ਇਹ ਯਕੀਨੀ ਬਣਾਉਂਦੀ ਹੈ ਕਿ ਜਦੋਂ ਤੁਹਾਨੂੰ ਸਭ ਤੋਂ ਵੱਧ ਲੋੜ ਹੋਵੇ ਤਾਂ ਤੁਹਾਨੂੰ ਲੋੜੀਂਦੀ ਸਹਾਇਤਾ ਮਿਲਦੀ ਹੈ। ਅਸੀਂ ਪ੍ਰਕਿਰਿਆ ਨੂੰ ਸਰਲ ਬਣਾਉਣ ਲਈ ਵਚਨਬੱਧ ਹਾਂ ਤਾਂ ਜੋ ਤੁਸੀਂ ਛੇਤੀ ਹੀ ਵਾਪਸ ਪ੍ਰਾਪਤ ਕਰ ਸਕੋ ਜੋ ਤੁਹਾਡੀ ਜ਼ਿੰਦਗੀ ਵਿੱਚ ਸਭ ਤੋਂ ਮਹੱਤਵਪੂਰਨ ਹੈ।";
    public mobile_app_title: string = 'ਇੱਕ ਐਪ ਨਾਲ ਭੁਗਤਾਨ ਕਰੋ, ਨਿਵੇਸ਼ ਕਰੋ, ਆਪਣੇ ਪੈਸੇ ਬਚਾਓ';
    public mobile_app_content: string = 'ਕਿਤੇ ਵੀ, ਕਿਸੇ ਵੀ ਸਮੇਂ ਆਪਣੀਆਂ ਸਾਰੀਆਂ ਬੀਮਾ ਲੋੜਾਂ ਦਾ ਨਿਯੰਤਰਣ ਪ੍ਰਾਪਤ ਕਰੋ';

    public mobile_app_compare: string = 'ਵੱਖ-ਵੱਖ ਬੀਮਾ ਪਾਲਿਸੀਆਂ ਦੀ ਤੁਲਨਾ ਕਰੋ';
    public mobile_app_buy:string="ਆਪਣੀਆਂ ਸਾਰੀਆਂ ਪਾਲਿਸੀਆਂ ਆਨਲਾਈਨ ਖਰੀਦੋ, ਸਟੋਰ ਕਰੋ ਅਤੇ ਸਾਂਝੀਆਂ ਕਰੋ";
    public mobile_app_track: string = 'ਚਲਦੇ-ਫਿਰਦੇ ਆਪਣੀ ਪਾਲਿਸੀ ਸਥਿਤੀ ਨੂੰ ਟਰੈਕ ਕਰੋ';
    public mobile_app_download: string = 'ਇੱਕ ਟੈਪ ਨਾਲ ਆਪਣੀ ਪਾਲਿਸੀ ਨੂੰ ਡਾਊਨਲੋਡ ਕਰੋ';
    public mobile_app_coming: string = 'ਕੁਝ ਹੈਰਾਨੀਜਨਕ ਲਈ ਤਿਆਰ ਰਹੋ - ਸਾਡੀਆਂ ਐਪਾਂ ਜਲਦੀ ਹੀ ਲਾਂਚ ਹੋਣਗੀਆਂ!';
    public testimonial_title: string = 'ਅਸੀਂ ਆਪਣੇ ਗਾਹਕ ਅਨੁਭਵ ਦੀ ਵੀ ਪਰਵਾਹ ਕਰਦੇ ਹਾਂ';
    public our_partner_title: string = 'ਸਾਡੇ ਸਾਥੀ';
    public our_partner_text: string = 'ਅਸੀਂ ਤੁਹਾਨੂੰ ਵਧੀਆ ਸੇਵਾਵਾਂ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ ਮਿਲ ਕੇ ਕੰਮ ਕਰਦੇ ਹਾਂ।';
    public need_help_title: string = 'ਕੁਝ ਮਦਦ ਦੀ ਲੋੜ ਹੈ?';
    public need_help_text: string = "ਅਸੀਂ ਤੁਹਾਨੂੰ ਸਭ ਤੋਂ ਵਧੀਆ ਸੇਵਾਵਾਂ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ ਮਿਲ ਕੇ ਕੰਮ ਕਰਦੇ ਹਾਂ। Click4bima ਪੋਰਟਲ 'ਤੇ, ਅਸੀਂ ਤੁਹਾਡੀ ਬੀਮਾ ਯਾਤਰਾ ਦੇ ਹਰ ਪੜਾਅ 'ਤੇ ਬੇਮਿਸਾਲ ਗਾਹਕ ਸੇਵਾ ਅਤੇ ਸਹਾਇਤਾ ਪ੍ਰਦਾਨ ਕਰਨ ਲਈ ਵਚਨਬੱਧ ਹਾਂ। ਬੀਮਾ ਦੀ ਦੁਨੀਆ ਵਿੱਚ ਨੈਵੀਗੇਟ ਕਰਨ ਵਿੱਚ ਸਾਨੂੰ ਤੁਹਾਡੇ ਭਰੋਸੇਮੰਦ ਸਾਥੀ ਬਣੋ। ਅੱਜ ਹੀ ਸਾਡੀ ਸਹਾਇਤਾ ਟੀਮ ਨਾਲ ਸੰਪਰਕ ਕਰੋ ਅਤੇ ਅੰਤਰ ਦਾ ਅਨੁਭਵ ਕਰੋ। ਤੁਹਾਡੀ ਮਨ ਦੀ ਸ਼ਾਂਤੀ ਸਾਡੀ ਤਰਜੀਹ ਹੈ!";
    public mail_us:string='ਸਾਨੂੰ ਮੇਲ ਕਰੋ';
    public mobile_number:string='ਮੋਬਾਇਲ ਨੰਬਰ';
    public whatsapp:string='ਵਟਸਐਪ';
    public about_us:string='ਸਾਡੇ ਬਾਰੇ';
    public sitemap:string='ਸਾਈਟਮੈਪ';    
    public faqs:string='ਅਕਸਰ ਪੁੱਛੇ ਜਾਂਦੇ ਸਵਾਲ';
    public motor_insurance:string='ਮੋਟਰ ਬੀਮਾ';
    public car_insurance :string='ਕਾਰ ਬੀਮਾ';   
    public two_wheeler_insurance :string='ਦੋ ਪਹੀਆ ਵਾਹਨ ਬੀਮਾ';
    public taxi_insurance :string='ਟੈਕਸੀ ਬੀਮਾ';
    public commercial_veh_insurance:string='ਵਪਾਰਕ ਵਾਹਨ ਬੀਮਾ';
    public third_party_car_ins:string='ਥਰਡ ਪਾਰਟੀ ਕਾਰ ਬੀਮਾ'; 
    public third_party_bike_ins:string='ਥਰਡ ਪਾਰਟੀ 2W ਬੀਮਾ';
    public health_insurance:string='ਸਿਹਤ ਬੀਮਾ';
    public family_health_ins:string='ਪਰਿਵਾਰਕ ਸਿਹਤ ਪਾਲਿਸੀ';
    public individual_health_ins:string='ਵਿਅਕਤੀਗਤ ਸਿਹਤ ਪਾਲਿਸੀ';
    public group_health_ins:string='ਗਰੁੱਪ ਸਿਹਤ ਪਾਲਿਸੀ';
    public senior_citizen_health_ins:string='ਸੀਨੀਅਰ ਸਿਟੀਜ਼ਨ ਸਿਹਤ ਪਾਲਿਸੀ'; 
    public critical_illness_ins:string='ਗੰਭੀਰ ਬੀਮਾਰੀ ਪਾਲਿਸੀ';
    public arogya_san_ins:string='ਅਰੋਗਿਆ ਸੰਜੀਵਨੀ ਪਾਲਿਸੀ';
    public life_insurance:string='ਜੀਵਨ ਬੀਮਾ ';
    public term_insurance:string='ਟਰਮ ਬੀਮਾ';
    public single_pay_term_ins:string='ਸਿੰਗਲ ਪੇ ਟਰਮ ਬੀਮਾ'; 
    public group_term_ins:string='ਗਰੁੱਪ ਟਰਮ ਬੀਮਾ';  
    public saving_plans:string='ਬੱਚਤ ਯੋਜਨਾਵਾਂ';  
    public tax_saving_ins:string='ਟੈਕਸ ਬਚਤ ਪਾਲਿਸੀ';  
    public money_back_ins:string='ਮਨੀ ਬੈਕ ਪਾਲਿਸੀ';  
    public guaranteed_return_ins:string='ਗਾਰੰਟੀਸ਼ੁਦਾ ਵਾਪਸੀ ਪਾਲਿਸੀ';   
    public ulip:string='ਯੂ ਐਲ ਆਈ ਪੀ';
    public pension_plan:string='ਪੈਨਸ਼ਨ ਯੋਜਨਾ';  
    public child_plan:string='ਬਾਲ ਯੋਜਨਾ';    
    public others:string='ਹੋਰਾਂ';    
    public privacy_policy:string='ਪਰਾਈਵੇਟ ਪਾਲਿਸੀ';  
    public cancellation_refund:string='ਰੱਦ ਕਰਨਾ ਅਤੇ ਰਿਫੰਡ';  
    public term_condition:string='ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ';
    public disclaimer: string = 'ਬੇਦਾਅਵਾ';
    public grivance_redressal: string = 'ਨਿਵਾਰਣ';
    public srg_add_title: string = 'ਐਸਆਰਜੀ ਇੰਸ਼ੋਰੈਂਸ ਬ੍ਰੋਕਰਜ਼ ਪ੍ਰਾਈਵੇਟ ਲਿਮਿਟੇਡ';
    public srg_add_detail: string = '2 & 5, ਵਿਨਾਇਕ ਕੰਪਲੈਕਸ, ਦੁਰਗਾ ਨਰਸਰੀ ਰੋਡ, ਉਦੈਪੁਰ, ਰਾਜਸਥਾਨ, 313001';
    
    public direct_broker_title: string = 'ਡਾਇਰੈਕਟ ਬ੍ਰੋਕਰ, ਲਾਇਸੈਂਸ ਨੰਬਰ: DB-290';
    public direct_broker_detail: string = 'ਵੈਧਤਾ: 02.02.2026, CIN: U74140RJ2002PTC017656';
    public  payment_method:string='ਭੁਗਤਾਨ ਦੇ ਤਰੀਕੇ';
    public  copyright:string='© ਕਾਪੀਰਾਈਟ 2023 Click4bima. ਸਾਰੇ ਹੱਕ ਰਾਖਵੇਂ ਹਨ';   
    public continue:string='ਜਾਰੀ ਰੱਖੋ';
    public bought_new_bike:string='ਨਵਾਂ ਦੋਪਹੀਆ ਵਾਹਨ ਖਰੀਦਿਆ'
    public bought_new_car:string='ਨਵੀਂ ਕਾਰ ਖਰੀਦੀ ਹੈ';
    public car_ins_title:string="ਆਪਣੀ ਕਾਰ ਬੀਮੇ 'ਤੇ ਵਿਸ਼ੇਸ਼ ਤੌਰ 'ਤੇ ਘੱਟ ਕੀਮਤ ਪ੍ਰਾਪਤ ਕਰੋ";
    public car_ins_start:string='ਕਾਰ ਬੀਮਾ ₹2471* ਤੋਂ ਸ਼ੁਰੂ';
    public enter_car_number:string='ਕਾਰ ਨੰਬਰ ਦਰਜ ਕਰੋ ਉਦਾਹਰਨ: AB12CD3456'; 
    public vehicle_detail_form:string='ਤੁਹਾਡੇ ਵਾਹਨ ਦੇ ਵੇਰਵੇ';
    public city:string='ਸ਼ਹਿਰ'; 
    public rto:string="ਆਰ.ਟੀ.ਓ";
    public manufacturer:string="ਉਤਪਾਦਕ"; 
    public model:string="ਮਾਡਲ"; 
    public fuel_type:string="ਬਾਲਣ ਦੀ ਕਿਸਮ";
    public variant:string="ਵੇਰੀਐਂਟ"; 
    public reg_year:string="ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਸਾਲ"; 
    public get_quotes:string="ਕੋਟਸ ਪ੍ਰਾਪਤ ਕਰੋ";  
    public search_city:string="ਸ਼ਹਿਰ ਦੀ ਖੋਜ ਕਰੋ"; 
    public select_city:string="ਜਾਂ ਸ਼ਹਿਰ ਦੀ ਚੋਣ ਕਰੋ"; 
    public select_rto:string='Sਸਿਟੀ ਆਰ ਟੀ ਓ ਦੀ ਚੋਣ ਕਰੋ';
    public search_manufacturer:string='ਨਿਰਮਾਤਾ ਖੋਜੋ';
    public select_manufacturer:string='ਜਾਂ ਉਤਪਾਦਕ ਚੁਣੋ';  
    public search_model:string='ਮਾਡਲ ਖੋਜੋ';
    public select_model:string='ਜਾਂ ਮਾਡਲ ਚੁਣੋ';  
    public search_variant:string='ਵੇਰੀਐਂਟ ਖੋਜੋ';   
    public select_variant:string='ਜਾਂ ਵੇਰੀਐਂਟ ਚੁਣੋ';   
    public select_year:string='ਸਾਲ ਚੁਣੋ';   
    public select_fuel_type:string='ਬਾਲਣ ਦੀ ਕਿਸਮ ਚੁਣੋ'; 
    public contact_title:string='ਸਾਡੇ ਨਾਲ ਸੰਪਰਕ ਕਰੋ';   
    public contact_text:string='ਅਸੀਂ ਜਿੰਨੀ ਜਲਦੀ ਹੋ ਸਕੇ ਤੁਹਾਡੇ ਕੋਲ ਵਾਪਸ ਆਵਾਂਗੇ।';  
    public full_name:string='ਪੂਰਾ ਨਾਂਮ';     
    public mobile:string='ਮੋਬਾਇਲ ਨੰਬਰ';            
    public interested_in:string='ਵਿੱਚ ਦਿਲਚਸਪੀ ਹੈ'  ;  
    public email:string='ਈਮੇਲ ਪਤਾ';
    public description:string='ਵਰਣਨ';            
    public call_me:string='ਮੈਨੂੰ ਵਾਪਸ ਕਾਲ ਕਰੋ';
    public continue_fb:string='ਫੇਸਬੁੱਕ ਦੇ ਨਾਲ ਜਾਰੀ ਰੱਖੋ';
    public continue_google:string='ਗੂਗਲ ਦੇ ਨਾਲ ਜਾਰੀ ਰੱਖੋ';  
    public sign_in:string='ਸਾਈਨ ਇਨ ਕਰੋ';  
    public password:string='ਪਾਸਵਰਡ';    
    public remember:string='ਮੈਨੂੰ ਯਾਦ ਰੱਖੋ';   
    public dont_have_acc:string="ਕੋਈ ਖਾਤਾ ਨਹੀਂ ਹੈ"; 
    public signup_here:string="ਇੱਥੇ ਸਾਈਨ ਅੱਪ ਕਰੋ";     
    public click_here:string="ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ";       
    public send_otp:string="ਓਟੀਪੀ ਭੇਜੋ";         
    public back_login:string="ਵਾਪਸ ਲਾਗਇਨ ਕਰਨ ਲਈ";       
    public already_hv_acc:string="ਕੀ ਪਹਿਲਾਂ ਹੀ ਖਾਤਾ ਹੈ?";     
    public signin_here:string="ਇੱਥੇ ਸਾਈਨ ਇਨ ਕਰੋ";     
    public verification:string="ਪੁਸ਼ਟੀਕਰਨ";   
    public verify_text:string="6-ਅੰਕ ਦਾ ਕੋਡ ਦਾਖਲ ਕਰੋ ਜੋ ਤੁਹਾਡੇ ਨੰਬਰ +918543871911 'ਤੇ ਭੇਜਿਆ ਗਿਆ ਸੀ। ਆਪਣਾ ਮੋਬਾਈਲ ਨੰਬਰ ਬਦਲਣ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ।";    
    public enter_otp:string="ਓਟੀਪੀ  ਦਾਖਲ ਕਰੋ";   
    public dont_rec_otp:string="ਜੇਕਰ ਤੁਹਾਨੂੰ ਕੋਡ ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਇਆ ਹੈ! ਦੁਬਾਰਾ ਭੇਜੋ";    
    public submit:string="ਜਮ੍ਹਾਂ ਕਰੋ"; 
    public accept_terms:string="ਆਪਣਾ ਖਾਤਾ ਬਣਾ ਕੇ, ਤੁਸੀਂ ਸਾਡੇ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਨਾਲ ਸਹਿਮਤ ਹੁੰਦੇ ਹੋ";    
    public basic_details:string ="ਮੂਲ ਵੇਰਵੇ";      
    public first_name:string ="ਪਹਿਲਾ ਨਾਂ";     
    public last_name:string ="ਆਖਰੀ ਨਾਮ";     
    public dob:string ="ਜਨਮ ਤਾਰੀਖ";                        
    public referal_code:string ="ਰੈਫਰਲ ਕੋਡ";              
    public gender:string ="ਲਿੰਗ";                
    public new_password:string  ="ਨਵਾਂ ਪਾਸਵਰਡ ਦਰਜ ਕਰੋ";   
    public confirm_old_pass:string  ="ਨਵੇਂ ਪਾਸਵਰਡ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ";        
    public dont_have_policy_popup:string='ਜੇਕਰ ਤੁਹਾਨੂੰ ਆਪਣਾ ਦੋਪਹੀਆ ਵਾਹਨ ਨੰਬਰ ਯਾਦ ਨਹੀਂ ਹੈ, ਤਾਂ ਆਪਣੇ ਦੋਪਹੀਆ ਵਾਹਨ ਦੇ ਮੁੱਢਲੇ ਵੇਰਵਿਆਂ ਤੱਕ ਪਹੁੰਚ ਕਰਨ ਲਈ ਆਪਣਾ ਆਰਸੀ (ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਸਰਟੀਫਿਕੇਟ) ਆਪਣੇ ਕੋਲ ਤਿਆਰ ਰੱਖੋ। '; 
    public year:string='ਸਾਲ';
    
    public your_details:string='ਆਪਣਾ ਵੇਰਵਾ ਦਰਜ ਕਰੋ'; 
    
    public name_title:string='ਸਿਰਲੇਖ';
}