<app-navbar></app-navbar>
    <div class="container mt-15">
        <div class="container">
            <div class="card-container container-fluid d-flex " id="crd">
                <div class="card card-item">
                <h2>Grievance Redress</h2>
                <p class="mt-5">The company aims to provide best customer service and is consistently striving on creating a robust and efficient customer service platform. If the customers have any issues or wish to register a complaint, they can reach us at any of the following:</p>
                <ul>
                    <li>We will provide our customers with all the information regarding dues and will give sufficient notice for payment of dues. All customers will be contacted ordinarily at the place of their choice, in the absence of a specified place at the residence of the customer and if the customer is unavailable at the residence, at the customer's place of business/occupation.</li>
                    <li>We will respect privacy of the customer and all our interactions will be in a civil manner. All assistance will be provided to our customers to resolve differences or disputes in a mutually acceptable manner, if any, regarding the dues.</li>
                    <li>The 'Vernacular Declaration' shall be taken from all the customers stating “In case you are unable to understand the contents in English Language, we request you to contact us at any of the branches or on the details as available on the website; www.hridayfin.com</li>
                    <li>At our branches, we display the name and contact details (Telephone / Mobile nos. / Email Address) of the Grievance Redress Officer and Grievance Redress Mechanism followed by us.</li>
                </ul>
                <p>Rural Insurance services - <b>Enhancing & Enriching lives</b></p>
                <p>The availability of quality insurance services in rural areas is extremely important for the security of life of people and growth of the economy as this will enable the large number of rural households to promote confidence of their lives. Therefore greater financial inclusion through life insurance in these segments is imperative. Lack of financial help stunts the growth & stability of life and life expectancy of many rural people. Timely and affordable financial support through insurance of recurring deposits helps people sustain and grow their lives.</p>
            </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>


