export class malayalamLabel {
    public product: string = 'ഉൽപ്പന്നം';
    public support: string = 'പിന്തുണ';
    public raise_a_query: string = 'ചോദ്യം ഉയർത്തുക';
    public raise_a_claim: string = 'അവകാശവാദം ചോദിക്കുക';
    public contact_us: string = 'ഞങ്ങളെ ബന്ധപ്പെടുക';
    public career: string = 'കരിയർ';
    public gallery: string = 'ഗാലറി';
    public login: string = 'ലോഗിൻ ചെയ്യുക';
    public two_wheeler: string = 'ഇരുചക്രവാഹനം';
    public four_wheeler: string = 'നാലുചക്രവാഹനം';
    public health: string = 'ആരോഗ്യം';
    public commercial_vehicle: string = 'വ്യാപാര വാഹനം';
    public bike_ins_title: string = 'നിങ്ങളുടെ ഇരുചക്ര വാഹന ഇൻഷുറൻസിൽ കുറഞ്ഞ വിലയ്ക്ക് മാത്രം നേടൂ';
    public bike_ins_start: string = 'ഇരുചക്ര വാഹന ഇൻഷുറൻസ് ₹635 മുതൽ*';
    public enter_bike_number: string = 'ഇരുചക്രവാഹന നമ്പർ നൽകുക. ഉദാ: AB12CD3456';
    public verify: string = 'പരിശോധിച്ചുറപ്പിക്കുക';
    public dont_have_policy: string = "രജിസ്ട്രേഷൻ നമ്പർ ഓർമ്മയില്ലേ";
    public buy_new_bike: string = 'പുതിയ ഇരുചക്ര വാഹനം വാങ്ങിയോ?';
    public what_is_ins1: string = 'ഇൻഷുറൻസ്';
    public what_is_ins2: string = 'എന്താണ്?';
    public what_is_ins_title: string = 'ഇൻഷുറൻസ് ഉപയോഗിച്ച് മനസ്സമാധാനം കണ്ടെത്തൂ';
    public what_is_ins_text: string = "ഞങ്ങളുടെ Click4bima പോർട്ടലിലേക്ക് സ്വാഗതം, ഭാവിയെ സംരക്ഷിക്കുന്നതിനുള്ള നിങ്ങളുടെ ഗേറ്റ്‌വേ! ഇൻഷുറൻസ് ഓപ്‌ഷനുകളുടെ വിശാലമായ ശ്രേണി പര്യവേക്ഷണം ചെയ്യുന്നതിനുള്ള നിങ്ങളുടെ ഏകജാലക ലക്ഷ്യസ്ഥാനമാണ് ഞങ്ങളുടെ പോർട്ടൽ. ആരോഗ്യം, വാഹനം മുതൽ വീട്ടിലേക്കും യാത്രയിലേക്കും വരെ, നിങ്ങളുടെ ആവശ്യങ്ങൾക്കനുസൃതമായി മികച്ച കവറേജ് പ്ലാനുകൾ നിങ്ങൾക്ക് കൊണ്ടുവരാൻ ഞങ്ങൾ പ്രശസ്ത ഇൻഷുറൻസ് കമ്പനികളുമായി സഹകരിച്ചിട്ടുണ്ട്. വ്യത്യസ്‌ത പോളിസികൾ താരതമ്യം ചെയ്യുന്നതിലൂടെ, നിങ്ങൾക്ക് വിവരമുള്ള തീരുമാനങ്ങൾ എടുക്കാനും നിങ്ങളുടെ ജീവിതശൈലിക്കും ബജറ്റിനും അനുയോജ്യമായ ഒന്ന് തിരഞ്ഞെടുക്കാനും കഴിയും.";
    public why_insurance_imp1: string = 'എന്തുകൊണ്ട് ഇൻഷുറൻസ്';
    public why_insurance_imp2: string = 'പ്രധാനമാണ്?';
    public why_insurance_imp_title: string = 'നാളെ സുരക്ഷിതമാക്കുന്നു: ഇൻഷുറൻസിന്റെ വൈറ്റാലിറ്റി അനാവരണം ചെയ്യുന്നു';
    public why_insurance_imp_text: string = "ജീവിതത്തിലെ അനിശ്ചിതത്വങ്ങൾക്കെതിരായ നിങ്ങളുടെ കവചമാണ് ഇൻഷുറൻസ്, നിങ്ങൾക്ക് ഏറ്റവും ആവശ്യമുള്ളപ്പോൾ മനസ്സമാധാനം പ്രദാനം ചെയ്യുന്നു. അപ്രതീക്ഷിത സംഭവങ്ങളുടെ സമയങ്ങളിൽ സാമ്പത്തിക സഹായം ഉറപ്പുനൽകുന്ന നിങ്ങളും നിങ്ങൾ തിരഞ്ഞെടുത്ത ഇൻഷുറൻസ് ദാതാവും തമ്മിലുള്ള ഒരു കരാറാണിത്. പെട്ടെന്നുള്ള ചികിത്സാ ചെലവ്, വാഹനാപകടം, നിങ്ങളുടെ വസ്തുവകകൾക്ക് കേടുപാടുകൾ, അല്ലെങ്കിൽ മുൻകൂട്ടിക്കാണാൻ കഴിയാത്ത ഏതെങ്കിലും സാഹചര്യം എന്നിവയാണെങ്കിലും, ഇൻഷുറൻസ് ഭാരം ലഘൂകരിക്കാൻ നടപടിയെടുക്കുന്നു.";
    public claim_assistance1: string = 'സഹായം ക്ലെയിം';
    public claim_assistance2: string = 'ചെയ്യുക';
    public claim_assistance_title: string = 'നിങ്ങളുടെ മനസ്സമാധാനം പുനഃസ്ഥാപിച്ചു';
    public claim_assistance_text: string = "Click4bima-ൽ നിന്നുള്ള 'ക്ലെയിം അസിസ്റ്റൻസ്' ഉപയോഗിച്ച്, സുഗമമായ ക്ലെയിം പ്രക്രിയയുടെ പ്രാധാന്യം മനസ്സിലാക്കുന്ന ഒരു വിശ്വസനീയ പങ്കാളി നിങ്ങൾക്കുണ്ടെന്ന് നിങ്ങൾക്ക് ഉറപ്പിക്കാം. ഒരു നയം എന്നതിലുപരിയായി നിങ്ങളെ സംരക്ഷിക്കുന്നുവെന്ന് അറിഞ്ഞുകൊണ്ട് നിങ്ങളുടെ മനസ്സമാധാനം വീണ്ടെടുക്കുക - നിങ്ങളെ പരിപാലിക്കുന്ന ഒരു ടീമാണ് നിങ്ങളെ പിന്തുണയ്ക്കുന്നത്. നിങ്ങൾക്ക് ഏറ്റവും ആവശ്യമുള്ളപ്പോൾ ഞങ്ങളുടെ 'ക്ലെയിം അസിസ്റ്റൻസ്' നിങ്ങളുടെ വിശ്വസ്ത കൂട്ടാളിയാണ്..";
    public best_place_ins_title: string = 'ഇന്ത്യയിൽ ഇൻഷുറൻസ് വാങ്ങുന്നതിനുള്ള ഏറ്റവും മികച്ച സ്ഥലമായി ഞങ്ങളെ മാറ്റുന്നത് എന്താണ്?';
    public best_place_ins_text: string = 'ഞങ്ങളുടെ Click4bima പോർട്ടലിലേക്ക് സ്വാഗതം, ഇന്ത്യയിലെ ഏറ്റവും മികച്ച ഇൻഷുറൻസ് പരിരക്ഷ കണ്ടെത്തുന്നതിനുള്ള നിങ്ങളുടെ ഏകജാലക ലക്ഷ്യസ്ഥാനം. എന്തുകൊണ്ടാണ് നമ്മൾ മറ്റുള്ളവരിൽ നിന്ന് വേറിട്ട് നിൽക്കുന്നതെന്ന് ആശ്ചര്യപ്പെടുന്നുണ്ടോ? ശരിയായ ഇൻഷുറൻസ് പരിരക്ഷയുടെ പരിരക്ഷയോടെ ആശങ്കകളില്ലാത്ത ഭാവി സ്വീകരിക്കുക. ഇന്നുതന്നെ ഞങ്ങളോടൊപ്പം നിങ്ങളുടെ ഇൻഷുറൻസ് യാത്ര ആരംഭിക്കൂ.';
   
    public everytime_support_title: string = '24*7 പിന്തുണ';
    public everytime_support_text: string = "ഞങ്ങളുടെ 24/7 ഇൻഷുറൻസ് സഹായം ഉപയോഗിച്ച് എപ്പോൾ വേണമെങ്കിലും എവിടെയും മനസ്സമാധാനം നേടൂ. നിങ്ങൾക്ക് വിദഗ്ധ പിന്തുണ നൽകാനും നിങ്ങളുടെ ചോദ്യങ്ങൾക്ക് ഉത്തരം നൽകാനും നിങ്ങൾ എല്ലായ്പ്പോഴും പരിരക്ഷിതരാണെന്ന് ഉറപ്പാക്കാനും ഞങ്ങളുടെ സമർപ്പിത ടീം ഇവിടെയുണ്ട്."


    public best_price_ins_title: string = 'മികച്ച വില ഇൻഷുറൻസ്';
    public best_price_ins_text: string = "ഞങ്ങളുടെ ദൗത്യം ലളിതമാണ്: ഗുണനിലവാരത്തിൽ വിട്ടുവീഴ്ച ചെയ്യാതെ നിങ്ങൾക്ക് മികച്ച ഇൻഷുറൻസ് ഡീലുകൾ നൽകുക. ഇഷ്‌ടാനുസൃതമാക്കാവുന്ന നയങ്ങളുടെ ഒരു ശ്രേണി ഉപയോഗിച്ച്, നിങ്ങളുടെ ബജറ്റ് കേടുകൂടാതെ നിലനിർത്തിക്കൊണ്ട് നിങ്ങളുടെ ഭാവി സംരക്ഷിക്കാൻ ഞങ്ങൾ ഇവിടെയുണ്ട്.";
    public reliable_title: string = 'വിശ്വസനീയം';
    public reliable_text: string = 'ഒഴിവാക്കലുകളില്ലാതെ നിങ്ങൾക്ക് ഏറ്റവും പ്രധാനപ്പെട്ടത് പരിരക്ഷിക്കുന്നതിന് ഞങ്ങളെ വിശ്വസിക്കാം. ഞങ്ങളുടെ വാഗ്ദാനങ്ങൾ നിറവേറ്റുന്നതിന്റെ തെളിയിക്കപ്പെട്ട ട്രാക്ക് റെക്കോർഡിനൊപ്പം, നിങ്ങളുടെ മനസ്സമാധാനവും വഴിയുടെ ഓരോ ചുവടും ഞങ്ങൾ ഉറപ്പാക്കുന്നു. നിങ്ങളുടെ ഭാവി സംരക്ഷിക്കുന്നതിൽ അചഞ്ചലമായ വിശ്വാസ്യതയ്ക്കായി ഞങ്ങളെ തിരഞ്ഞെടുക്കുക.';
    public easy_claim_title: string = 'ಹಕ್ಕು ಸುಲಭವಾಯಿತು';
    public easy_claim_text: string = "ನಮ್ಮ ಸುವ್ಯವಸ್ಥಿತ ಕಾರ್ಯವಿಧಾನಗಳು ಮತ್ತು ಮೀಸಲಾದ ಬೆಂಬಲ ತಂಡವು ನಿಮಗೆ ಹೆಚ್ಚು ಅಗತ್ಯವಿರುವಾಗ ನಿಮಗೆ ಅಗತ್ಯವಿರುವ ಸಹಾಯವನ್ನು ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸರಳಗೊಳಿಸಲು ನಾವು ಬದ್ಧರಾಗಿದ್ದೇವೆ ಆದ್ದರಿಂದ ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ ಹೆಚ್ಚು ಮುಖ್ಯವಾದುದನ್ನು ನೀವು ತ್ವರಿತವಾಗಿ ಮರಳಿ ಪಡೆಯಬಹುದು.";
    public mobile_app_title: string = 'ಒಂದೇ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಪಾವತಿಸಿ, ಹೂಡಿಕೆ ಮಾಡಿ, ನಿಮ್ಮ ಹಣವನ್ನು ಉಳಿಸಿ';
    public mobile_app_content: string = 'ಎಲ್ಲಿಯಾದರೂ, ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ಎಲ್ಲಾ ವಿಮೆ ಅಗತ್ಯಗಳ ನಿಯಂತ್ರಣವನ್ನು ಪಡೆಯಿರಿ';

    public mobile_app_compare: string = 'ವಿಭಿನ್ನ ವಿಮಾ ಪಾಲಿಸಿಗಳನ್ನು ಹೋಲಿಕೆ ಮಾಡಿ';
    public mobile_app_track: string = 'എവിടെയായിരുന്നാലും നിങ്ങളുടെ നയ നില ട്രാക്ക് ചെയ്യുക';
    public mobile_app_download: string = 'ഒരൊറ്റ ടാപ്പിലൂടെ നിങ്ങളുടെ നയം ഡൗൺലോഡ് ചെയ്യുക';
    public mobile_app_coming: string = 'അതിശയകരമായ എന്തെങ്കിലും ചെയ്യാൻ തയ്യാറാകൂ - ഞങ്ങളുടെ ആപ്പുകൾ ഉടൻ സമാരംഭിക്കും!';
    public testimonial_title: string = 'ഞങ്ങളുടെ ഉപഭോക്തൃ അനുഭവവും ഞങ്ങൾ ശ്രദ്ധിക്കുന്നു';
    public our_partner_title: string = 'ഞങ്ങളുടെ പങ്കാളികൾ';
    public our_partner_text: string = 'നിങ്ങൾക്ക് മികച്ച സേവനങ്ങൾ നൽകുന്നതിന് ഞങ്ങൾ ഒരുമിച്ച് പ്രവർത്തിക്കുന്നു.';
    public need_help_title: string = 'എന്തെങ്കിലും സഹായം ആവശ്യമുണ്ടോ?';
    public need_help_text: string = 'നിങ്ങൾക്ക് മികച്ച സേവനങ്ങൾ നൽകുന്നതിന് ഞങ്ങൾ ഒരുമിച്ച് പ്രവർത്തിക്കുന്നു. Click4bima പോർട്ടലിൽ, നിങ്ങളുടെ ഇൻഷുറൻസ് യാത്രയുടെ ഓരോ ഘട്ടത്തിലും അസാധാരണമായ ഉപഭോക്തൃ സേവനവും സഹായവും നൽകാൻ ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്. ഇൻഷുറൻസ് ലോകത്ത് നാവിഗേറ്റ് ചെയ്യുന്നതിൽ ഞങ്ങൾക്ക് നിങ്ങളുടെ വിശ്വസ്ത പങ്കാളിയാകാം. ഇന്ന് ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുകയും വ്യത്യാസം നേരിട്ട് അനുഭവിക്കുകയും ചെയ്യുക. നിങ്ങളുടെ മനസ്സമാധാനമാണ് ഞങ്ങളുടെ മുൻഗണന!';
    public mail_us:string='ഞങ്ങൾക്ക് മെയിൽ ചെയ്യുക';
    public mobile_number:string='മൊബൈൽ നമ്പർ';
    public whatsapp:string='വാട്ട്‌സ്ആപ്പ്';
    public about_us:string='ഞങ്ങളേക്കുറിച്ച്';
    public sitemap:string='സൈറ്റ്മാപ്പ്';    
    public faqs:string='പതിവായി ചോദിക്കുന്ന ചോദ്യങ്ങൾ';
    public motor_insurance:string='മോട്ടോർ ഇൻഷുറൻസ്';
    public car_insurance :string='കാർ ഇൻഷുറൻസ്';   
    public two_wheeler_insurance :string='ഇരുചക്ര വാഹന ഇൻഷുറൻസ്';
    public taxi_insurance :string='ടാക്സി ഇൻഷുറൻസ്';
    public commercial_veh_insurance:string='വാണിജ്യ വാഹന ഇൻഷുറൻസ്';
    public third_party_car_ins:string='തേർഡ് പാർട്ടി കാർ ഇൻഷുറൻസ്'; 
    public third_party_bike_ins:string='മൂന്നാം കക്ഷി 2W ഇൻഷുറൻസ്';
    public health_insurance:string='ആരോഗ്യ ഇൻഷുറൻസ്';
    public family_health_ins:string='കുടുംബാരോഗ്യ പോളിസി';
    public individual_health_ins:string='വ്യക്തിഗത ആരോഗ്യ പോളിസി';
    public group_health_ins:string='ഗ്രൂപ്പ് ആരോഗ്യ പോളിസി';
    public senior_citizen_health_ins:string='മുതിർന്ന പൗരന്മാരുടെ ആരോഗ്യ പോളിസി'; 
    public critical_illness_ins:string='ഗുരുതര രോഗ പോളിസി ';
    public arogya_san_ins:string='ആരോഗ്യ സഞ്ജീവനി പോളിസി';
    public life_insurance:string='ലൈഫ് ഇൻഷുറൻസ്';
    public term_insurance:string='ടേം ഇൻഷുറൻസ്';
    public single_pay_term_ins:string='സിംഗിൾ പേ ടേം ഇൻഷുറൻസ്'; 
    public group_term_ins:string='ഗ്രൂപ്പ് ടേം ഇൻഷുറൻസ്';  
    public saving_plans:string='സേവിംഗ് പ്ലാനുകൾ';  
    public tax_saving_ins:string='നികുതി ലാഭിക്കൽ പോളിസി';  
    public money_back_ins:string='മണി ബാക്ക് പോളിസി';  
    public guaranteed_return_ins:string='ഗ്യാരണ്ടീഡ് റിട്ടേൺ പോളിസി';   
    public ulip:string='യു എൽ ഐ പി';
    public pension_plan:string='പെൻഷൻ പ്ലാൻ';  
    public child_plan:string='കുട്ടികളുടെ പദ്ധതി';    
    public others:string='മറ്റുള്ളവ';    
    public privacy_policy:string='സ്വകാര്യതാ പോളിസി';  
    public cancellation_refund:string='റദ്ദാക്കലും റീഫണ്ടും';  
    public term_condition:string='നിബന്ധനകളും വ്യവസ്ഥകളും';
    public disclaimer: string = 'നിരാകരണം';
    public grivance_redressal: string = 'പരിഹാരം';
    public srg_add_title: string = 'എസ് ആർ ജി ഇൻഷുറൻസ് ബ്രോക്കേഴ്സ് പ്രൈവറ്റ് ലിമിറ്റഡ്';
    public srg_add_detail: string = '22 & 5, വിനായക് കോംപ്ലക്സ്, ദുർഗ നഴ്സറി റോഡ്, ഉദയ്പൂർ, രാജസ്ഥാൻ, 313001';
    
    public direct_broker_title: string = 'നേരിട്ടുള്ള ബ്രോക്കർ, ലൈസൻസ് നമ്പർ: DB-290';
    public direct_broker_detail: string = 'സാധുത വരെ: 02.02.2026,CIN: U74140RJ2002PTC017656';
    public  payment_method:string='പേയ്മെന്റ് രീതികൾ';
    public  copyright:string='© പകർപ്പവകാശം 2023 Click4bima. എല്ലാ അവകാശങ്ങളും നിക്ഷിപ്തം';   
    public continue:string='തുടരുക';
    public bought_new_bike:string=' പുതിയ ഇരുചക്ര വാഹനം വാങ്ങി';
    public bought_new_car:string='ഒരു പുതിയ കാർ വാങ്ങി';
    public car_ins_title:string='നിങ്ങളുടെ കാർ ഇൻഷുറൻസിൽ കുറഞ്ഞ വിലയ്ക്ക് മാത്രം നേടൂ';
    public car_ins_start:string='₹2471 മുതൽ ആരംഭിക്കുന്ന കാർ ഇൻഷുറൻസ്*';
    public enter_car_number:string='കാർ നമ്പർ നൽകുക. ഉദാ: AB12CD3456'; 
    public vehicle_detail_form:string='നിങ്ങളുടെ വാഹന വിശദാംശങ്ങൾ';
    public city:string='നഗരം '; 
    public rto:string="ആർ.ടി.ഒ";
    public manufacturer:string="നിർമ്മാതാവ്"; 
    public model:string="മോഡൽ"; 
    public fuel_type:string="ഇന്ധന തരം";
    public variant:string="വേരിയന്റ്"; 
    public reg_year:string="രജിസ്ട്രേഷൻ വർഷം"; 
    public get_quotes:string="ഉദ്ധരണികൾ നേടുക";  
    public search_city:string="നഗരം തിരയുക"; 
    public select_city:string="അല്ലെങ്കിൽ നഗരം തിരഞ്ഞെടുക്കുക"; 
    public select_rto:string='സിറ്റി RTO തിരഞ്ഞെടുക്കുക';
    public search_manufacturer:string='തിരയൽ നിർമ്മാതാവ്';
    public select_manufacturer:string='അല്ലെങ്കിൽ നിർമ്മാതാവിനെ തിരഞ്ഞെടുക്കുക';  
    public search_model:string='തിരയൽ മോഡൽ';
    public select_model:string='അല്ലെങ്കിൽ മോഡൽ തിരഞ്ഞെടുക്കുക';  
    public search_variant:string='വേരിയന്റ് തിരയുക    ';   
    public select_variant:string='അല്ലെങ്കിൽ വേരിയന്റ് തിരഞ്ഞെടുക്കുക';   
    public select_year:string='വർഷം തിരഞ്ഞെടുക്കുക';   
    public select_fuel_type:string='ഇന്ധന തരം തിരഞ്ഞെടുക്കുക'; 
    public contact_title:string='ഞങ്ങളുമായി ബന്ധപ്പെടുക';   
    public contact_text:string='ഞങ്ങൾ എത്രയും വേഗം നിങ്ങളെ ബന്ധപ്പെടും.';  
    public full_name:string='പൂർണ്ണമായ പേര്';     
    public mobile:string='മൊബൈൽ നമ്പർ';            
    public interested_in:string='എനിക്ക് താല്പര്യമുണ്ട്'  ;  
    public email:string='ഇമെയിൽ കണക്ക്';
    public description:string='വിവരണം';            
    public call_me:string='എന്നെ തിരിച്ചു വിളിക്കുക';
    public continue_fb:string='ഫേസ്ബുക്കിൽ തുടരുക';
    public continue_google:string='ഗൂഗിൾ ഉപയോഗിച്ച് തുടരുക';  
    public sign_in:string='സൈൻ ഇൻ ചെയ്യുക';  
    public password:string='പാസ്വേഡ്';    
    public remember:string='എന്നെ ഓർമ്മിക്കുക';   
    public dont_have_acc:string="ഒരു അക്കൗണ്ടും ഇല്ല"; 
    public signup_here:string="ഇവിടെ സൈൻ അപ്പ് ചെയ്യുക";     
    public click_here:string="ഇവിടെ ക്ലിക്ക് ചെയ്യുക";       
    public send_otp:string="ഒടിപി അയക്കുക";         
    public back_login:string="ലോഗിൻ എന്നതിലേക്ക് മടങ്ങുക";       
    public already_hv_acc:string="ഇതിനകം ഒരു അക്കൗണ്ട് ഉണ്ടോ";     
    public signin_here:string="ഇവിടെ സൈൻ ഇൻ ചെയ്യുക";     
    public verification:string="സ്ഥിരീകരണം";   
    public verify_text:string="നിങ്ങളുടെ +918543871911 എന്ന നമ്പറിലേക്ക് അയച്ച 6 അക്ക കോഡ് നൽകുക. നിങ്ങളുടെ മൊബൈൽ നമ്പർ മാറ്റാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക.";    
    public enter_otp:string="ഒടിപി നൽകുക";   
    public dont_rec_otp:string="നിങ്ങൾക്ക് ഒരു കോഡ് ലഭിച്ചില്ലെങ്കിൽ! വീണ്ടും അയയ്ക്കുക  ";    
    public submit:string="സമർപ്പിക്കുക"; 
    public accept_terms:string="നിങ്ങളുടെ അക്കൗണ്ട് സൃഷ്‌ടിക്കുന്നതിലൂടെ, ഞങ്ങളുടെ നിബന്ധനകളും വ്യവസ്ഥകളും നിങ്ങൾ അംഗീകരിക്കുന്നു";    
    public basic_details:string ="അടിസ്ഥാന വിശദാംശങ്ങൾ";      
    public first_name:string ="പേരിന്റെ ആദ്യഭാഗം";     
    public last_name:string ="പേരിന്റെ അവസാന ഭാഗം";     
    public dob:string ="ജനനത്തീയതി";                        
    public referal_code:string ="റഫറൽ കോഡ്";              
    public gender:string ="ലിംഗഭേദം";                
    public new_password:string  ="പുതിയ പാസ്‌വേഡ് നൽകുക";   
    public confirm_old_pass:string  ="പുതിയ പാസ്‌വേഡ് സ്ഥിരീകരിക്കുക";      
    public dont_have_policy_popup:string="നിങ്ങളുടെ ടൂ വീലർ നമ്പർ നിങ്ങൾക്ക് ഓർമ്മയില്ലെങ്കിൽ, നിങ്ങളുടെ ഇരുചക്രവാഹനത്തിന്റെ അടിസ്ഥാന വിശദാംശങ്ങൾ ആക്‌സസ് ചെയ്യുന്നതിന് നിങ്ങളുടെ ആർസി (രജിസ്‌ട്രേഷൻ സർട്ടിഫിക്കറ്റ്) തയ്യാറാക്കി വയ്ക്കുക.";    
    public mobile_app_buy:string='നിങ്ങളുടെ എല്ലാ പോളിസികളും ഓൺലൈനായി വാങ്ങുക, സംഭരിക്കുക, പങ്കിടുക';
    public year:string='വർഷം';
    
   public your_details:string='നിങ്ങളുടെ വിശദാംശങ്ങൾ നൽകുക';
   public name_title:string='ശീർഷകം';

}