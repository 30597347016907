<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Critical Illness Insurance Cover?</h1>
            </div>
            <div class="top-line"></div>
            <div class="row">
                <div class="mt-5">
                    <p class="font-body">Critical Illness Insurance Plans provides coverage against specific life
                        threatening
                        diseases. What this means is that in addition to the hospitalization cost, there will be other
                        costs
                        like doctor visits, medical expenses, etc. A Critical Illness plan pays a lump sum amount that
                        can be
                        used to cover these high expenses .The good thing is that this lump sum payout is in addition to
                        any
                        mediclaim or health insurance policy that you may already have.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Health problems, in most cases, strike unexpectedly, resulting in a sudden
                        financial
                        burden for the family. In such cases, family is not only worried about meeting the cost of
                        treatment,
                        but also struggles with the loss of income they go through due to you being unwell. This most of
                        the
                        times, leads the family, to dip into their savings kitty built for child&rsquo;s education or
                        one&rsquo;s own retirement. Your loved ones let go of their dreams to overcome financial
                        exigencies
                        health problems create.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">&nbsp;Why should one buy a Critical Illness Cover, (even if you already have a
                        mediclaim plan)?</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Treating a critical illness takes time and money. If you are an earning member
                        and are
                        diagnosed with a major illness, it is quite likely that you will have to skip work to get
                        treatment for
                        it. This leads to a double trouble:</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">&bull;&nbsp; &nbsp;&nbsp;Losing your monthly income</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">&bull;&nbsp; &nbsp;&nbsp;Increase in expenses due to frequent visits to the
                        hospital,
                        high cost of medical tests and medicines that will be required for complete recovery.</p>
                </div>

                <div class="mt-5">
                    <p class="font-body">In this case, a mediclaim plan will only reimburse your hospitalisation costs.
                        A
                        critical illness cover goes beyond just hospitalisation costs and helps you cover other expenses
                        like
                        doctor consultation fees, cost of medicines etc. In addition to that, if you have any
                        outstanding loan
                        like a home loan or a car loan, the critical illness pay-out can help with dealing with the
                        outstanding
                        EMIs.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">If you do not have a critical illness cover, you may have to dip into all the
                        hard
                        earned money that you have saved. The high cost of treatment can erode years of savings in just
                        a few
                        months.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits:</h4>
                </div>
                <div class="mt-5">
                    <p class="font-body">PROTECTION FOR YOU:</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">CRITICAL ILLNESS BENEFIT</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">TAX BENEFIT</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">ACCIDENTAL DEATH BENEFIT</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">PROTECTION AGAINST DISABILITY</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">100% BENEFIT PAYOUT</p>
                </div>

            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Health Insurance Renewal Online Process - Through the Insurer&rsquo;s Website</h4>
                </div>
                <div class="mt-5">
                    <p class="font-body">Health insurance renewal is an easy process, especially when done online
                        through the
                        insurer&rsquo;s website by following the steps given below:</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Step 1: Visit the official website of the insurance company.&nbsp;</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Step 2: Go to the health insurance renewal section available on the website.
                    </p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Step 3: Enter your previous health insurance policy number, registered mobile
                        number
                        and such other details as required at the insurance company Website.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Step 4: Check the policy details and the policy renewal premium and conditions
                        such as
                        tenure, payment options etc.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Step 5: Make the payment using your credit/debit card or Net banking.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>