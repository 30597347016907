<div class="container" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-4">
                <div class="card mt-5 p-4">
                    <div class="row flex">
                        <div class="col-md-8 ">
                            <div>
                                <h5 class="text-start">
                                    <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                        class="cursor-pointer">&nbsp;&nbsp; Your
                                    vehicle details
                                </h5>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="d-flex ">
                                <img class="edit-icon" src="../../assets/svgIcon/PencilLine.svg" alt="Edit" width="20%"
                                    height="20%">&nbsp;&nbsp;
                                <!-- <span class="fa fa-pencil-square"></span>&nbsp; -->
                                <h6 class="text-edit">Edit</h6>
                            </div>

                        </div>
                        <div class="col-md-2">

                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJJKgExd3KX8QoCOdIGMpAt6XWrVTZJEiRZQ&usqp=CAU"
                                width="15" height="20" alt="Vehicle">&nbsp;&nbsp;

                        </div>
                        <div class="col-md-10 ">
                            <div>
                                <p class="text-start font">Vehicle
                                    &nbsp;&nbsp;<span>Suzuki Motorcycle</span></p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <img src="../../assets/svgIcon/city.svg" alt="city">&nbsp;&nbsp;
                                <div>
                                    <p class="font">Modal
                                        <br>
                                        <span>ST BSVI 125</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <img src="../../assets/svgIcon/city.svg" alt="city">&nbsp;&nbsp;
                                <div>
                                    <p class="font">RTO
                                        <br>
                                        <span>mp43- abc</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex">
                                <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;
                                <div>
                                    <p class="font">Year</p>
                                    <h5>2023</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card mt-5 p-4">
                    <div class="text-start">
                        <h4>ADD Extra Coverage</h4>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input checkbox" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Personal Accident Cover of Rs. 10 Lack
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-info-circle"
                                    aria-hidden="true"></i>
                            </span>
                            <p style="color:red"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PA Cover is mandatory</p>
                        </label>
                    </div>
                    <div class="text-start">
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="spn"> ₹180/</span>1
                            Year</p>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input checkbox" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Consumable Cover
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i
                                    class="fa fa-info-circle" aria-hidden="true"></i></span>
                            <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>₹320 </span></p>
                        </label>
                    </div>
                </div>
                <div class="card mt-5 p-5">
                    <h4>
                        Premium Breakup
                    </h4>
                    <div class="row">
                        <div class="col-md-8">
                            <p>Basic Own Damage</p>
                        </div>
                        <div class="col-md-4">
                            <span class="spn">₹1,000</span>
                        </div>
                        <hr>
                        <div class="col-md-8">
                            <p>Net Premium</p>
                        </div>
                        <div class="col-md-4">
                            <span class="spn">₹1,000</span>
                        </div>
                        <div class="col-md-8">
                            <p>Add GST(18%)</p>
                        </div>
                        <div class="col-md-4">
                            <span class="spn">₹180</span>
                        </div>
                        <hr>
                        <div class="col-md-8">
                            <p>Final Premium</p>
                        </div>
                        <div class="col-md-4">
                            <span class="spn">₹1,180</span>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-8">
                <div class="text-start mt-5">
                    <h4>
                        Owner Details
                    </h4>
                </div>
                <div class="card mt-5 mb-5 p-4">
                    <form action="">
                        <div class="row ">
                            <div class="col-md-6 mt-5 ">
                                <label>Your Name</label>
                                <input type="text" name="userName" id="" placeholder="User Name" class="form-control">
                            </div><br>
                            <div class="col-md-6 mt-5">
                                <label>Email Address</label>
                                <input type="text" name="email" id="" placeholder="User Email Address"
                                    class="form-control">
                            </div>

                            <div class="col-md-6 mt-5">
                                <label>Mobile Number</label>
                                <input type="text" name="userMobile" id="" placeholder="User Mobile Number"
                                    class="form-control">
                            </div><br>
                            <div class="col-md-6 mt-5">
                                <label>Pan Card</label>
                                <input type="text" name="Pan" id="" placeholder="User Pan Card" class="form-control">
                            </div>

                            <div class="col-md-6 mt-5">
                                <label>Pin Code</label>
                                <input type="text" name="Pin" id="" placeholder="Pin Code" class="form-control">
                            </div><br>
                            <div class="col-md-6 mt-5 mb-5">
                                <label>Street Address</label>
                                <input type="text" name="address" id="" placeholder=" Your  Address"
                                    class="form-control">
                            </div>
                            <div class="col-md-12">
                                <h4 class="text-start">
                                    Nominess Details
                                </h4>
                            </div>

                            <div class="col-md-6 mt-5">
                                <label>Nominess Name</label>
                                <input type="text" name="nominess" id="" placeholder="User Nominess Name"
                                    class="form-control">
                            </div><br>
                            <div class="col-md-6 mt-5 mb-5">
                                <label>Relation</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Relation</option>
                                    <option value="#">One</option>
                                    <option value="#">Two</option>
                                    <option value="#">Three</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="card mt-10 p-5">
            <div class="row ">
                <div class="col-md-7">
                    <p>Net Premium</p>
                    <span class="spn"> ₹1,180</span>
                </div>
                <div class="col-md-5">
                    <div>
                        <p>
                            &nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;      Help?
                            &nbsp;&nbsp;&nbsp;<button class="btn btn-primary" >Continue</button>
                   
                        </p>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>