<app-navbar></app-navbar>
<div class="container" style="background-color: #f3f3f4;">
    <div class="row">
        <div class="col-md-12 mt-4 ">
            <div class="card crd p-3">
                <div class="row ">
                    <div class="col-md-1 ">
                        <p><img src="../../../assets/svgIcon/arrow.svg" alt="">&nbsp;&nbsp;&nbsp;</p>
                    </div>
                    <div class="col-md-3">
                        <p class="p-tg">Vehicle Category</p>
                        <span>001</span>
                    </div>
                    <div class="col-md-4 ">
                        <p class="p-tg">Manufacturer/Model</p>
                        <span>TATA</span>
                    </div>
                    <div class="col-md-3">
                        <p class="p-tg">Year</p>
                        <span>2017</span>
                    </div>
                    <div class="col-md-1  ">
                        <p><img class="mrg" src="../../../assets/svgIcon/pencile.svg" alt="">&nbsp;&nbsp;&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-4 px-0 px-md-2">
            <div>
                <div class="card scroll p-5 filter-section rounded-bottom-0 mx-0 mb-0">
                    <form #rangeForm="ngForm">
                        <ng-container>
                            <div class="justify-content-between d-flex align-items-center mb-3">
                                <h4 class="text-start mb-0">Claim Excess Amount <img src="/assets/images/Vector4.png"
                                        alt="" class="cursor-pointer ml-1 h-18px"></h4>
                                <span></span>
                            </div>
                            <hr class="" />
                        </ng-container>

                        <div class="justify-content-between d-flex">
                            <div>
                                <h4 class="text-start">Insured Declared Value (IDV) <img
                                        src="/assets/images/Vector4.png" alt="" class="cursor-pointer ml-1 h-18px"></h4>
                            </div>
                        </div>
                        <div class="row justify-content-between mt-1">
                            <div class="col-3">
                                <h6 class="text-main fw-medium" style="font-size: 14px;">₹ 21,000</h6>
                                or enter value
                            </div>
                            <div class="col-6 range-wrap">
                                <div class="range-value" id="rangeV">
                                    <!-- <span>{{currentIdv | thousandSeparator}}</span> -->
                                </div>
                                <input type="range" class="form-range text-main" name="customRange" id="customRange1">
                                <input type="number" class="form-control h-26px mt-2" style="height: 26px;">
                            </div>
                            <div class="col-3 text-end fw-bolder">
                                <h6>₹29,000 </h6>
                            </div>
                        </div>
                    </form>
                    <hr class="" />
                    <div class="justify-content-between d-flex">
                        <div>
                            <h4 class="text-start">Add-ons</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 d-flex align-items-center text-wrap mb-1" *ngFor="let adon of addOn">
                            <input type="checkbox" class="scale-1_3 mt-0 mr-2" value="{{adon}}">{{adon}}
                        </div>

                    </div>
                    <ng-container>
                        <hr class="" />

                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <h5 style="color:var(--grey5);font-weight: 550;">Previous Policy Details </h5>
                            </div>

                            <div class="col-md-4 mt-2">
                                <label for="">Policy's Expiry Status</label>
                            </div>
                            <div class="col-md-8 mt-2"> <select class="form-select form-select-custom1">
                                    <option selected>Select Policy's Expiry Status</option>
                                    <option *ngFor="let type of ExpiryPolicy" value="{{type}}">{{type}}</option>

                                </select>
                            </div>
                            <div class="col-md-4 mt-2">
                                <label for="">Policy Type</label>
                            </div>
                            <div class="col-md-8 mt-2"> <select class="form-select form-select-custom1">
                                    <option selected>Select Policy's Expiry Type</option>
                                    <option *ngFor="let type of PolicyType" value="{{type}}">{{type}}</option>

                                </select>
                            </div>
                            <div class="col-md-12">
                                <hr class="" />
                            </div>

                        </div>
                        <div class="justify-content-between d-flex mt-4">
                            <div>
                                <h4 class="text-start">No Claim Bonus <img src="/assets/images/Vector4.png" alt=""
                                        class="cursor-pointer ml-1 h-18px"></h4>
                            </div>
                        </div>
                        <div class="mt-1 row">
                            <div class="col-6">
                                <p class="text-start text-grey4 mb-3">Current applicable NCB:</p>
                            </div>
                            <div class="col">
                                <select class="form-select form-select-custom1">
                                    <option *ngFor="let currentNcb of item " value="{{currentNcb}}">{{currentNcb}}%
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <p class="text-start text-grey4 mb-3">Previous year NCB:</p>
                            </div>
                            <div class="col">
                                <select class="form-select form-select-custom1">
                                    <option *ngFor="let PreviousNcb of item " value="{{PreviousNcb}}">{{PreviousNcb}}%
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <p class="text-start text-grey4 mb-3">Claim in last policy:</p>
                            </div>
                            <div class="col">
                                <select class="form-select form-select-custom1">
                                    <option *ngFor="let ClaimNcb of item " value="{{ClaimNcb}}">{{ClaimNcb}}%</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12 mt-4">
                            <hr>
                            <h5 style="color:var(--grey5);font-weight: 550;">Vehicle on Finance </h5>

                        </div>
                        <div class="col-md-12 mt-4 ">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    (click)="check()">
                                <p class="ch">&nbsp;Is Home currently on finance?</p>
                            </div>
                        </div>

                        <div class="col-md-12 mt-5" *ngIf="checked">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <label for="">Financier Name</label>
                                    <input type="text" name="" id="" class="form-control" placeholder="Financier Name">
                                </div>
                                <div class="col-md-12 mt-5">
                                    <label for="">Financed Amount</label>
                                    <input type="text" name="" id="" class="form-control" placeholder="₹ Amount">
                                </div>
                            </div>
                        </div>



                    </ng-container>
                </div>

                <button class="btn btn-primary w-100 rounded-0 rounded-bottom">Apply</button>
            </div>

            <!-- <div class="card p-5 mt-3">
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start">Choose Addons</h4>
                </div>
                <div class="d-flex" *ngIf="!isEditPolicyAddons">
                  <img class="edit-icon" src="../../assets/svgIcon/PencilLine.svg" alt="Edit">&nbsp;&nbsp;
                  <h6 class="text-edit" (click)="editPolicyAddons()">Edit</h6>
                </div>
                <div class="d-flex" *ngIf="isEditPolicyAddons">
                  <img class="edit-icon" src="../../assets/svgIcon/FloppyDisk.svg" alt="Apply">&nbsp;&nbsp;
                  <h6 class="text-edit" (click)="updatePolicyAddons()">Apply</h6>
                </div>
              </div>
              <div class="mt-5" *ngIf="isEditPolicyAddons">
                <app-multi-dropdown  class="w-100 multiselect_custom"
                        [items]="addOnnsItems" 
                        [showSearch]="showSearch" 
                        [showAll]="showAll" 
                        [showStatus]="showStatus"
                        [showError]="showError" 
                        placeholder="Select Foods" 
                        (itemChange)="onItemChange($event)">
                    </app-multi-dropdown>
              </div>
              <div class="mt-5" *ngIf="!isEditPolicyAddons">
                <h5 class="float-start text-main fw-medium">{{policyAddonsLabel}}</h5>
              </div>
            </div> -->

        </div>
        <!-- <div class="col-md-4">
            <div class="card p-2 ">
                <div class="row scroll  ">
                    <div class="col-md-12">
                        <h4>Insured Declared value (IDV)</h4>
                    </div>
                    <div class="col-md-12 mt-3">
                        <input type="range" id="customRange1">
                    </div>
                    <div class="col-md-9"><span class="spn">₹21,000</span></div>
                    <div class="col-md-3"><span class="spn">₹29,000</span></div>

                    <div class="col-md-4 mt-5"><span style="color:var(--grey5)">Or enter Value</span></div>

                    <div class="col-md-8 mt-3   ">
                        <input type="text" name="" id="" placeholder="₹ IDV Amount" class="form-control">
                    </div>

                    <div class="col-md-12">
                        <hr>
                        <h5 style="color:var(--grey5);font-weight: 550;">Add Ons</h5>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-5">
                        <div class="row">
                            <div class="col-md-6 mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <p class="ch">&nbsp;Zero Nil Dep</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <p class="ch">&nbsp;Zero Nil Dep</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <p class="ch">&nbsp;Zero Nil Dep</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <p class="ch">&nbsp;Zero Nil Dep</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <p class="ch">&nbsp;Zero Nil Dep</p>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <p class="ch">&nbsp;Zero Nil Dep</p>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3">
                                <hr>
                                <h5 style="color:var(--grey5);font-weight: 550;">Previous Policy Details </h5>
                                <hr>
                            </div>
                            <div class="col-md-12 mt-4">
                                <label for="">Policy's Expiry Status</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Select Policy's Expiry Status</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>

                            <div class="col-md-12 mt-4">
                                <label for="">Policy Type</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Select Policy's Expiry Type</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>

                            <div class="col-md-12 mt-4">
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label for="">Current applicable NCB:</label>
                                    </div>
                                    <div class="col-md-6">
                                        <select class="form-select" aria-label="Default select example">
                                            <option selected>25%</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-4">
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label for="">Previous year NCB:</label>
                                    </div>
                                    <div class="col-md-6">
                                        <select class="form-select" aria-label="Default select example">
                                            <option selected>25%</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-4">
                                <div class="row">
                                    <div class="col-md-6 mt-3">
                                        <label for="">Claim in last policy:</label>
                                    </div>
                                    <div class="col-md-6">
                                        <select class="form-select" aria-label="Default select example">
                                            <option selected>25%</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-4">
                                <hr>
                                <h5 style="color:var(--grey5);font-weight: 550;">Vehicle on Finance </h5>
                                <hr>
                            </div>
                            <div class="col-md-12 mt-4">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                        (click)="check()">
                                    <p class="ch">&nbsp;Is Home currently on finance?</p>
                                </div>
                            </div>

                            <div class="col-md-12 mt-5" *ngIf="checked">
                                <div class="row">
                                    <div class="col-md-12 mt-3">
                                        <label for="">Financier Name</label>
                                        <input type="text" name="" id="" class="form-control"
                                            placeholder="Financier Name">
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <label for="">Financed Amount</label>
                                        <input type="text" name="" id="" class="form-control" placeholder="₹ Amount">
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>
                <div class="row mt-4">
                    <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-1 r-0">Apply</button>
                </div>
            </div>
        </div> -->
        <div class="col-md-8 mg">
            <div class="row">
                <div class="col-md-12 mt-5">
                    <h4>Insurance plans for you</h4>
                    <span class="spn">Showing {{planList.length }} plans for you</span>
                </div>
                <div class="col-md-12 mt-4" *ngFor="let item of planList">
                    <div class="card p-5">
                        <div class="row">
                            <div class="col-md-3">
                                <img [src]="item.logo" width="100%" height="100%" alt="">
                            </div>
                            <div class="col-md-3">
                                <span class="spn">IDV</span>
                                <p class="p-mrg">{{ item.idv }}</p>
                            </div>

                            <div class="col-md-3">
                                <span class="spn">Claim Settled</span>
                                <p class="p-mrg">99.97%</p>
                            </div>

                            <div class="col-md-3">
                                <button class="btn btn-primary btn-sm" id="btn" (click)="details()">₹
                                    {{ item.grossPremium }}/Year</button>
                                <a href=""><span class="spn" style="color:var(--main)">View Breakup</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>