<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1>Unit linked Insurance Plan</h1>
            </div>
            <div class="top-line"></div>
            <div class="row">
                <div class="mt-3">
                    <p class="font-body">Unit Linked Insurance Plan (ULIP) is a mix of insurance along with investment.
                        From
                        a ULIP, the goal is to provide wealth creation along with life cover where the insurance company
                        puts a portion of your investment towards life insurance and rest into a fund that is based on
                        equity or debt or both and matches with your long-term goals. These goals could be retirement
                        planning, children&rsquo;s education or another important event you may wish to save for.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How does ULIP work?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">When you make an investment in ULIP, the insurance company invests part of the
                        premium in shares/bonds etc., and the balance amount is utilized in providing an insurance
                        cover.
                        There are fund managers in the insurance companies who manage the investments and therefore the
                        investor is spared the hassle of tracking the investments.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">ULIPS allow you to switch your portfolio between debt and equity based on your
                        risk
                        appetite as well as your knowledge of the market&rsquo;s performance. Benefits like these which
                        offer investors the flexibility of switching is a huge factor contributing to the popularity of
                        these investment instruments.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Lock-in-period of ULIP</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">One of the changes brought about by the Insurance Regulatory and Development
                        Authority of India (IRDAI) in the year 2010 as regards ULIPs, was to increase the lock in a
                        period
                        from 3 years to 5 years. However, insurance being a long-term product, as an investor you may
                        not
                        really reap the benefits of the policy unless you hold it for the entire term of the policy
                        which
                        can range from 10 to 15 years.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why to invest in ULIPs?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Life cover: First and foremost, with ULIPs you get a life cover coupled with
                        investment. It offers security that a taxpayer&rsquo;s family can fall back on in case of
                        emergencies like the untimely death of the taxpayer, etc.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Income tax benefits: Not many are aware that the premium paid towards a ULIP is
                        eligible for a tax deduction under Section 80C. Additionally, the returns out of the policy on
                        maturity are exempt from income tax under Section 10(10D) of the Income-tax Act. This is a dual
                        benefit that you can claim with this policy.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Finance Long Term Goals: If you have long-term goals like buying a house, a new
                        car, marriage, etc., then ULIP is a good investment option because the money gets compounded. As
                        a
                        result, the net returns are generally more. This stands true even if you want to exit after the
                        5
                        year lock-in period in comparison to not having invested the amount at all and retaining it in a
                        savings account or in the form of an FD. But, under ULIP, the mantra is to always keep the
                        policy
                        going for a longer time horizon to reap the best out of it.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">The flexibility of a portfolio switch: As already mentioned, ULIPS are usually
                        designed in a way that they allow you to switch your portfolio between debt and equity based on
                        your
                        risk appetite as well as your knowledge of how the market is performing. Insurance companies, on
                        the
                        other hand, allow a very few numbers of switches free of cost.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Which Investor Class Are They Most Suited For?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Those who wish to closely track their investments</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Unit linked insurance plans allow policy takers to closely monitor their
                        portfolios. They also offer the flexibility to switch your capital between funds with varying
                        risk-return profiles.</p>
                </div>
                
                <div class="mt-3">
                    <p class="font-body">Individuals with a medium to long term investment horizon</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">ULIPs (Unit Insurance Linked Plans) are ideal for individuals who are ready to
                        stay
                        invested for relatively long periods of time.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Those with varying risk profiles</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Across the seven funds offered, the equity component varies from zero to a
                        maximum
                        of 100 per cent. Thus there is a choice of funds available to all types of investors - from
                        risk-averse investor to those investors who have strong risk appetite.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Investors across all life stages</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">This plan category offers a variety of plans which can be opted for depending
                        upon
                        the life stage you are in and your needs and financial liabilities at that point in time.</p>
                </div>
                
            </div>
            <div class="row">
                <div class="title-line">
                    <h4></h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Following are some important factors you should weigh in before investing in
                        ULIPs:
                    </p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Personal financial goals: If your financial goal is about wealth creation and
                        you
                        want to save money for retirement, ULIP is one of the best options available.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Compare ULIP offerings: Once you have determined your financial goal and the
                        type
                        of ULIP that will help you achieve it, the next step would be to compare the ULIP offerings in
                        the
                        market. Look for a comparison in the form of background expenses, premium payments, ULIP
                        performance, etc. Also, investigate the nature of funds that the ULIP invests in to ascertain
                        the
                        returns from investments in the particular ULIP.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Risk factor: Since ULIP investment is not as diversified as compared to ELSS,
                        the
                        risk in ULIP is probably a bit high compared to schemes like ELSS.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Investment horizon: ULIPs have a lock-in period of 5 years. If a ULIP is
                        surrendered in the first three years, the insurance cover would cease immediately. However, the
                        surrender value can be paid only after three years.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Key features of ULIPs</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">ULIP plans come packed with a variety of features to aid the investors:</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Flexibility</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Unit Linked Insurance Plan gives a lot of flexibility to the policyholder. You
                        have
                        the option to switch between different funds to match one&rsquo;s changing needs. There is also
                        a
                        facility to partially withdraw from the fund but this is subject to special charges and
                        conditions.
                        You can even invest additional sums of money as top-up over regular premiums.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Funds for Crucial Milestones in Life</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">A significant amount of funds are required at different stages of life. They
                        may be
                        required for one&rsquo;s business, building a house, a child&rsquo;s marriage, etc. The facility
                        to
                        partially withdraw money gives access to much-needed funds at critical stages to address
                        important
                        needs.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Protecting the Child&rsquo;s Future</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">The unit-linked plan gives the ability to invest in market-linked funds to earn
                        better market-type returns. The funds can be used towards a child&rsquo;s education, his or her
                        marriage, etc. Parents can easily keep a check on the NAV to make sure the returns offered are
                        in
                        keeping with future requirements.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Financial Security Post Retirement</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Equities tend to do well over the long term. Therefore, ULIPs are a good choice
                        to
                        add value to one&rsquo;s retirement portfolio.</p>
                </div>
                
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of ULIPs</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body"> ULIPs are categorized based on the following broad parameters:</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">A. Funds that ULIPs invest in</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Equity Funds: Where the premium paid is invested in the equity market and
                        thereby
                        is subject to higher risk.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Balanced funds: Where the premium paid is balanced between the debt and the
                        equity
                        market to minimize the risk for investors.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Debt Funds: Where the premium is invested in debt instruments which carry a
                        lower
                        risk but in turn also offer a lower return.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">B. End use of Funds</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Retirement Planning: For those of you who plan to invest for the retirement
                        days
                        while you are still employed.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Child Education:You can iYou can invest with a long-term goal of saving to fund
                        your child&rsquo;s education or save for some unforeseen circumstances.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Wealth Creation:You can make investments to build a heavy corpus that you can
                        utilize for a future financial goal</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">C. Death benefit to Policy Holders</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Type I ULIP: This pays higher of the assured sum value or the fund value to the
                        nominee in case of death of the policyholder.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Type II ULIP: This pays the assured sum value, plus the fund value to the
                        nominee
                        in case of the death of the policyholder.</p>
                </div>
                <div class="title-line">
                    <h4>Types of fees and charges under ULIP</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In every investment, there are various charges that need to be paid. In the
                        case of
                        ULIP, the charges can be broadly classified as:</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Premium Allocation Charge</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Premium Allocation Charge is deducted as a fixed percentage from the premium
                        paid
                        in the initial years of the policy. This is charged at a higher rate. The charges include the
                        initial and renewal expenses and intermediary commission expenses. It is a front load charge as
                        it
                        is deducted from your premium paid.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Mortality Charges</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">This charge is to provide for the insurance coverage under the plan. Mortality
                        charges depend on a number of factors like age, sum assured, etc., and is deducted on a monthly
                        basis.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Fund Management Charges</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Fund Management Charge is the fee imposed by the insurance company for the
                        management of the various funds in the ULIP. It is levied for the management of the funds and is
                        deducted before arriving at the NAV figure. The maximum charge allowed is 1.35 percent per annum
                        of
                        the fund value and is charged daily. Generally, insurers levy the maximum amount allowed in
                        equity
                        funds, while the charge on non-equity funds is much lower.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Partial Withdrawal Charge</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">ULIPs have the option of partial withdrawals of funds. Some plans offer
                        unlimited
                        withdrawals, but some restrict it to 2-4 withdrawals. These withdrawals can be free for up to a
                        certain limit or you can be charged based on your transactions.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Switching your funds</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">The moving of funds or investments between options is called switching. There
                        are
                        options to switch your funds for free up to a certain limit per year. Any further changes might
                        incur a charge of Rs. 100 -Rs.250 per switch.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">&nbsp;Policy administration charges</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">This charge is levied for the administration of the policy and it is deducted
                        on a
                        monthly basis by the cancellation of units from all funds chosen. This charge can be levied at a
                        fixed rate or as a percentage of your premium.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">ULIP as a mode of investment is a good choice given it offers the benefits of
                        insurance with investment. With part of the investment spread across stock markets, you stand to
                        gain higher returns. This also means that your investment is subjected to market risks. If your
                        risk
                        profile meets the tradeoff, this could be worth exploring.&nbsp;</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>