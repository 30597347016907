<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row"><h1>Money Back Policy</h1></div>
            <div class="top-line"></div>
            <div class="row">
            <div class="mt-3"><p class="font-body"> A money back policy is a traditional, savings-oriented life insurance plan which also allows liquidity. A financial crisis might strike anytime and we need funds to tackle it. Money back plans pay regular pay-outs during the term of the policy. Thus, these plans provide periodic payments and differ from other life insurance plans.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>What is Money Back Policy ?</h4></div>
            <div class="mt-3"><p class="font-body">A money back insurance plan pays out the same maturity benefits in the form of several guaranteed &ldquo;survival benefits&quot; which are staggered evenly throughout the course of the policy. So, a money back insurance policy is an endowment plan with the benefit of regular liquidity. This money-back is paid during the plan tenure and is a percentage of the Sum Assured. Money-back pay-outs are called Survival Benefits. These benefits are paid during the plan tenure and on maturity, the remaining Sum Assured is paid along with vested bonuses. However, if the insured dies during the plan tenure, the full Sum Assured is paid irrespective of the Survival Benefits already paid. This is what makes the plan unique. Some of the salient features of Money Back Policy are:</p></div>
            <div class="mt-3"><p class="font-body">Riders are available under many money-back plans. Rider benefits are paid as a lump sum only when the contingency covered by the rider occurs during the plan tenure.</p></div>
            <div class="mt-3"><p class="font-body">If the plan matures, the remaining portion of the Sum Assured (actual Sum Assured less the Survival Benefits already paid) is paid as maturity benefit. However, in case of death, the entire Sum Assured is paid irrespective of the money-back benefits already paid.</p></div>
            <div class="mt-3"><p class="font-body">Money-back plans usually come as participating plans where bonuses are added. The accrued bonus is then paid on maturity or on death.</p></div>
            <div class="mt-3"><p class="font-body">Survival Benefits are paid at regular intervals during the plan tenure. There is a fixed interval when the benefits would be paid. Every plan has a different payout structure. Similarly, the percentage of Sum Assured paid as Survival Benefits is also not fixed and varies between different plans.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>How does a Money Back Policy work?</h4></div>
            <div class="mt-3"><p class="font-body">A Money back policy is a type of that offers policyholders Survival Benefits as well as investment opportunities in addition to Maturity Benefits.</p></div>
            <div class="mt-3"><p class="font-body">An average money back policy with a 20 year tenure would thus pay the policyholder what is known as a &lsquo;Survival Benefit&rsquo; a few years after the start of the policy. Around 20% of the Sum Assured would be paid out periodically, while the balance would be paid out at the time of policy maturity with a bonus, if any.</p></div>
            <div class="mt-3"><p class="font-body">In the event the insured individual does not survive till the policy maturation, the nominee would receive the Death Benefit (the entire Sum Assured) and the policy would be terminated.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>Features of Money Back policy</h4></div>
            <div class="mt-3"><p class="font-body">A money back plan has some salient features and provides various benefits too. Here is a list of the plan&rsquo;s features and benefits:</p></div></div>
            <div class="row">
            <div class="title-line"><h4>Survival benefits</h4></div>
            <div class="mt-3"><p class="font-body">The periodic benefits which are paid under money back plans are called survival benefits. These benefits are paid only if the insured survives till the pay-out period.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>Nature of pay-out</h4></div>
            <div class="mt-3"><p class="font-body">
                Survival benefits, under most money back plans are paid in one lump sum. However, in some plans, there might be a provision of monthly incomes too.</p></div></div>
                <div class="row">
                <div class="title-line"><h4>Bonus additions</h4></div>
            <div class="mt-3"><p class="font-body">Money back plans are usually offered as participating plans which offer bonus. Simple or compound reversionary bonus is declared and paid under money back plans. The accumulated bonus is paid either on maturity or death thereby enhancing the plan benefits.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>Riders</h4></div>
            <div class="mt-3"><p class="font-body">Additional coverage features, called riders, are also available with money-back plans. These riders come at an additional premium and increase the scope of coverage.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>Tax benefits</h4></div>
            <div class="mt-3"><p class="font-body">
                Premiums paid for money-back plans, like other life insurance plans, qualify for tax deduction under Section 80C. Even the money back benefits received and the maturity or death benefit is a tax-free income in the hands of the policyholder.</p></div></div>
                <div class="row">
                <div class="title-line"><h4>Premiums</h4></div>
            <div class="mt-3"><p class="font-body">Premiums for the plan can be paid regularly, for a limited period or at once. Different money back plans have different premium paying options.</p></div></div>
            <div class="row"><div class="title-line"><h4>Who should buy a money back plan?</h4></div>
            <div class="mt-3"><p class="font-body">A money back policy is suitable for individuals who are looking for regular incomes at specified intervals. People who want to get assured returns within a short period of time can buy these plans. Individuals can get tax saving benefits as well as assured returns by investing in money back insurance plans.</p></div></div>
            <div class="row"><div class="title-line"><h4>How to choose the best money back plan</h4></div>
            <div class="mt-3"><p class="font-body">There are a lot of money back plans available in the market. To choose the best plan, each plan should be compared against the following parameters.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>The premium rate</h4></div>
            <div class="mt-3"><p class="font-body">The premium rate is another important factor which should be taken into consideration when comparing money back plans. However, the premium rate should be compared together with the coverage offered by the plan.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>The sum assured allowed</h4></div>
            <div class="mt-3"><p class="font-body">Usually, there is no upper limit on the sum assured available under money back plans. However, you should check the maximum sum assured available to ensure that the coverage you are looking for is available under the plan.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>The riders available</h4></div>
            <div class="mt-3"><p class="font-body">The list of available riders should also be checked when comparing money back insurance plans. The best plan should allow all popular riders to be included in the cover.</p></div></div>
           <div class="row"> <div class="title-line"><h4>The coverage features</h4></div>
            <div class="mt-3"><p class="font-body">The coverage features of the plan should be checked to find out if there are any additional inbuilt covers which enhance the scope of the plan&rsquo;s coverage.</p></div>
            <div class="mt-3"><p class="font-body">Eligibility Criteria for Money Back Policy:</p></div>
            <div class="mt-3"><p class="font-body">Prospective policyholders should meet certain criteria to qualify for a money back policy. The eligibility criteria are broadly listed below:</p></div>
            <div class="mt-3"><p class="font-body">Policyholder should be above the minimum entry age and below the maximum entry age (varies from policy to policy).</p></div>
            <div class="mt-3"><p class="font-body">Policyholder should be able to pay the Sum Assured as per the policy guidelines.</p></div>
            <div class="mt-3"><p class="font-body">Documents Required for Money Back Policy:</p></div>
            <div class="mt-3"><p class="font-body">The documents required to apply for a money back policy are listed below:</p></div>
            <div class="mt-3"><p class="font-body">Proof of age document.</p></div>
            <div class="mt-3"><p class="font-body">Proof of address document.</p></div>
            <div class="mt-3"><p class="font-body">Application form duly filled in.</p></div>
            <div class="mt-3"><p class="font-body">Medical reports (if applicable).</p></div>
        </div>
        <div class="row">
            <div class="title-line"><h4>How to Choose a Money Back Policy:</h4></div>
            <div class="mt-3"><p class="font-body">Choosing the right money back policy is key to ensuring individuals receive the maximum benefits from a particular policy.</p></div>
            <div class="mt-3"><p class="font-body">When choosing a money back plan, individuals should look at the policy tenure. The average tenure for a money back policy is around 20 years.</p></div>
            <div class="mt-3"><p class="font-body">As money back policies pay policyholders a Survival Benefit, prospective policyholders should ascertain the percentage of the Sum Assured that will be paid out in instalments. The amount should be enough to cover any expenses the policyholder might have.</p></div>
            <div class="mt-3"><p class="font-body">The type of investments available through the investment component of the policy should be looked over. Policyholders should also verify the duration of the pay-outs being made over the course of the policy term as Survival Be   nefits. Some plans pay policyholders every 5 years, others have a different timeline depending on the policy tenure.</p></div>
            <div class="mt-3"><p class="font-body">Policyholders should also check to see if the money back policy offers tax benefits. Some plans do not offer a tax benefit if 20% of the Sum Assured is being provided as Survival Benefit.</p></div>
        </div>
        <div class="row">
            <div class="title-line"><h4>Money Back Policy Riders:</h4></div>
            <div class="mt-3"><p class="font-body">Money back policies provide policyholders with the option to add cover that is not included in the original policy document in the form of riders. These riders cover additional possibilities such as accidental death, hospitalization expenses, permanent disability and critical illness to name a few.</p></div>
            <div class="mt-3"><p class="font-body">The riders provided along with a money back policy differ from insurer to insurer and also depend on other variables such as the policy tenure.</p></div>
            <div class="mt-3"><p class="font-body">A general list of riders that can be purchased along with a money back policy are given below:</p></div>
            <div class="mt-3"><p class="font-body">Accidental Death Rider: this rider provides coverage in case the policyholder meets with an accidental death as outlines in the rider guidelines. In such a scenario, the policyholder&rsquo;s beneficiaries/nominee will receive a lump sum as additional benefit.</p></div>
            <div class="mt-3"><p class="font-body">Term Rider: this rider provides the policyholder with a waiver from paying the premium amount under certain circumstances but still provides coverage to the policyholder.</p></div>
            <div class="mt-3"><p class="font-body">Critical Illness Rider: this rider provides the policyholder with financial assistance in the event he/she contracts a critical illness as defined by the rider.</p></div>
            <div class="mt-3"><p class="font-body">Hospitalization Rider: this rider provides the policyholder with assistance in paying hospital bills in the event the policyholder is hospitalized. A daily allowance is issued to the policyholder to cover expenses related to treatment.</p></div>
        </div>
    </div>
</div></div>
<app-footer></app-footer>
