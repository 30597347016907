<div class="row mt-5">
    <div class="col">
        <div class="row">
            <div class="col">
                <h3>Customers</h3>
            </div>
            <div class="col text-end">
                <button type="button" class="btn btn-primary">Add Insurer</button>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
        <div class="bg-white box p-3 mb-2 mt-3">
            <div class="row p-5">
                <div class="col-1">
                    <img class="" src="../../assets/svgIcon/agent/cusImage.svg" clialt="logo" width="55">
                </div>
                <div class="col-8 pl-5">
                    <h6>Naresh Purohit</h6>
                    <span>naresh@gmail.com</span>
                </div>
                <div class="col-3">
                    <span>31/05/2023</span>
                </div>
            </div>
            <div class="row box list-box m-1" [align]="'left'">
                <div class="col">
                    <p class="font-body">Total Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Pending Policy</p>
                    <span>2</span>
                </div>
                <div class="col">
                    <p class="font-body">Location</p>
                    <span>Ahmedabad</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <h3>Agent Dashboard</h3>
    </div>
</div>
