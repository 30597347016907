import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentService } from '../Services/document.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'add-edit-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.css']
})
export class AddDocumentComponent implements OnInit {
  documentForm: FormGroup;
  fileToUpload: File | undefined;
  baseUpload64FileData: string = "";
  documentList: any = [];
  userDocumentList: any = [];
  format: string = '';
  isEdit: boolean = false;
  title: string = 'Add Document';
  @ViewChild('closebtn', { static: true }) closeButtonRef: ElementRef;
  @Output() dataEvent = new EventEmitter();
  constructor(private fb: FormBuilder, private documentservice: DocumentService, private spinner: NgxSpinnerService, private generalService : GeneralService) {
    this.documentForm = this.fb.group({
      uniqueUserId: [''],
      documentUniqueId: [''],
      documentStatus: 1,
      documentTypeId: [Validators.required],
      documentBase64: ['', Validators.required],
      documentNumber: ['', Validators.required]
    });
  }


  async ngOnInit() {
    await this.GetDocumentTypes();
    // await this.GetUserDocuments();
  }

  onDeleteFile() {
    this.baseUpload64FileData = '';
    this.format = '';
    this.documentForm.get('DocumentBase64')?.setValue('');
  }
  async GetDocumentTypes() {
    const response = await this.documentservice.GetDocumentTypes();
    if (response?.isSuccess) {
      this.documentList = response.result;
      this.documentForm.get('documentTypeId')?.setValue(this.documentList[0].documentTypeId);
    }
  }
  async onSelectFile(file: any) {
    this.fileToUpload = file.target.files.item(0);
    debugger;
    if(this.fileToUpload?.type?.toLocaleLowerCase()?.includes('png') || this.fileToUpload?.type?.toLocaleLowerCase()?.includes('jpeg') || this.fileToUpload?.type?.toLocaleLowerCase()?.includes('jpg') || this.fileToUpload?.type?.toLocaleLowerCase()?.includes('pdf'))
      {
        if (this.fileToUpload?.type.includes('image') && this.fileToUpload!.size > 512000) {
          this.baseUpload64FileData = '';
          this.documentForm.get('documentBase64')?.setValue('');
          swal.fire({
            position: 'center',
            icon: 'error',
            title: 'File size should not be greather than 500kb',
            showConfirmButton: false,
            timer: 3000,
          });
        }
        else if (this.fileToUpload?.type.includes('pdf') && this.fileToUpload!.size > 512000) {
          this.baseUpload64FileData = '';
          this.documentForm.get('documentBase64')?.setValue('');
          swal.fire({
            position: 'center',
            icon: 'error',
            title: 'File size should not be greather than 5MB',
            showConfirmButton: false,
            timer: 3000,
          });
        }
        // else if (this.fileToUpload?.type.includes('video') && this.fileToUpload!.size > 50000000) {
        //   this.baseUpload64FileData = '';
        //   this.documentForm.get('documentBase64')?.setValue('');
        //   swal.fire({
        //     position: 'center',
        //     icon: 'error',
        //     title: 'File size should not be greather than 50MB',
        //     showConfirmButton: false,
        //     timer: 3000,
        //   });
        // }
        else {
          let reader = new FileReader();
          reader.onload = async (event: any) => {
            this.baseUpload64FileData = reader.result!.toString();
            this.documentForm.get('documentBase64')?.setValue(this.baseUpload64FileData);
            await this.SetFileType();
          }
          reader.readAsDataURL(this.fileToUpload!);
        }    
      }
      else
      {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Allowed file types: png, jpg, jpeg and pdf only!',
          showConfirmButton: false,
          timer: 3000,
        });
      }
  }

  async SetFileType() {
    this.format ="";
    if (this.baseUpload64FileData.includes(`application/pdf`)) {
      this.format = 'pdf';
    }
    else if (this.baseUpload64FileData.includes(`image`)) {
      this.format = 'image';
    }
    else if (this.baseUpload64FileData.includes(`video`)) {
      this.format = 'video';
    }
  }

  async submit() {
    this.spinner.show();
    //let documentName = this.documentList.filter((x: { documentTypeId: any; }) => x.documentTypeId == this.documentForm.get('documentTypeId')?.value)[0].documentTypeName;
    //this.documentForm.get('documentUniqueId')?.setValue(`${documentName.replace(' ', '')}_${uuidv4().replace(/-/g, '')}_${(moment(new Date()).format('MMDDYYYY'))}`)
    this.documentForm.value.documentStatus = 1;
    const response = await this.documentservice.SaveUserDocument(this.documentForm.value);
    if (response?.isSuccess) {
      this.spinner.hide();
      // this.display = "none";
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Document '+ (this.isEdit ? 'updated' : 'saved') +' successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      this.dataEvent.emit();
      this.closeButtonRef.nativeElement.click();
      this.ngOnInit();
    }
    else {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Document not saved',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  UpdateUniqueUserId(uniqueUserId:string) {
    this.isEdit = false;
    this.documentForm.get('uniqueUserId')?.setValue('');
    this.documentForm.get('documentUniqueId')?.setValue('');
    this.documentForm.get('documentStatus')?.setValue(1);
    this.documentForm.get('documentTypeId')?.setValue('');
    this.documentForm.get('documentBase64')?.setValue('');
    this.documentForm.get('documentNumber')?.setValue('');
    this.baseUpload64FileData = "";
    this.documentForm.get('uniqueUserId')?.setValue(uniqueUserId);
  }

  async updateDocumentData(document:any){
    debugger;
    this.isEdit = true;
    let base64 = "";
    this.documentForm.patchValue(document);
    if(document?.documentBase64?.length > 100){
      base64 = document.documentBase64;
    }
    else
    {
      const response = await this.documentservice.DownloadDocument(document.documentUniqueId,"","");
      if (response?.isSuccess && response?.result?.code == 1 ) {
        base64 = response.result?.data;         
      }
    }
    this.baseUpload64FileData = base64;
    this.documentForm.get('documentBase64')?.setValue(base64);    
    await this.SetFileType();
  }

}


