<div class="container-fluid " [ngClass]="!isUserLogged ? '' : 'user-dashboard'" style="background-color: #F3F3F4;">
    <div class="container" style="margin-top:130px;" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}"
        [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="row">
            <div class="">
                <h3>
                    Verify Your Identity
                </h3>
                <span>
                    Select a mobile number/email address to set communication preferences
                </span>
            </div>
            <div class="card-box">
                <div class="row">
                    <div class="col-md-4 tab" [ngClass]="{'activeTab': TabFirst==''}"  (click)="profile('')">
                        <h4><b>
                                Personal Info
                            </b></h4>
                    </div>
                    <div class="col-md-4 tab" [ngClass]="{'activeTab': TabSecond=='Aadhar-otp' || TabSecond=='Identity' || TabSecond=='adhar-doc' || TabSecond=='adhar-doc-success' || TabSecond=='AadharSuccess'} " (click)="Aadhar('Aadhar-otp')">
                        <h4><b>
                                Aadhaar Card </b></h4>
                    </div>
                    <div class="col-md-4 tab" [ngClass]="{'activeTab': TabThird}" (click)="PanCard('Pan-card')">
                        <h4><b>
                                Pan Card
                            </b></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="tabContant">
                        <div *ngIf="TabName==''">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 mt-2">
                                        <label class="required">Full Name</label>
                                        <input type="text" name="name" class="form-control" id=""
                                            placeholder="Your Name">
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <label class="required">Date Of birth </label>
                                        <input type="Date" name="Dob" class="form-control" id="" placeholder="DOB">
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label class="input-label required" for="maritalStatus">Marital Status</label>
                                        <!-- <input type="text" placeholder="Married" class="form-control" formControlName=""> -->
                                        <select name="maritalStatus" id="maritalStatus"
                                            class="form-select form-select-custom">
                                            <option value="">--Select--</option>
                                            <option value="Yes">Married</option>
                                            <option value="No">Unmarried</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label for="Date" class="required">Gender </label><br>
                                        <input class="my-3 radio-button" type="radio" id="male" name="gender"
                                            value="Male" formControlName="gender">
                                        Male
                                        &nbsp;&nbsp;
                                        <input class="my-3 radio-button" type="radio" id="female" name="gender"
                                            value="Female" formControlName="gender">
                                        Female &nbsp;&nbsp;
                                        <input class="my-3 radio-button" type="radio" id="other" name="gender"
                                            value="Other" formControlName="gender">
                                        Other
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Yout Mobile </label>
                                        <input type="number" name="mobile" class="form-control" id=""
                                            placeholder="+91 4565467895" 
                                            onkeypress="if(this.value.length=='10') return false">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Your Email Address </label>
                                        <input type="email" name="email" class="form-control" id=""
                                            placeholder="useremailadd@domain.com">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Street Address </label>
                                        <input type="text" name="Address" class="form-control" id=""
                                            placeholder="B273, Manglam Balaji City, Usercity">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Your Pincode </label>
                                        <input type="number" name="Pincode" class="form-control" id=""
                                            placeholder="Your Pincode"
                                            onkeypress="if(this.value.length=='6') return false">
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-12 text-end">
                                        <button class="btn btn-primary " (click)="Aadhar('Aadhar-otp')">Save &
                                            Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='Aadhar-otp'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="text-center">
                                            <img src="../../../assets/images/adhar-card.png" class="ad-img" alt="">

                                            <div class="mt-5">
                                                <h4>Verify Your Aadhaar Card</h4>
                                                <span>Select a mobile number/email address to set communication
                                                    preferences</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-10">
                                            <div>
                                                <label>Enter Aadhaar Number</label>
                                                <input type="number" name="adhar" id="" class="form-control"
                                                    placeholder="Aadhaar Number"
                                                    onkeypress="if(this.value.length=='12') return false">
                                                <button class="btn btn-primary mt-10" id="btn"
                                                    (click)="IdentityAadhar('Identity')"> Send
                                                    OTP</button>
                                            </div>
                                            <div class="text-center mt-10"> <span>
                                                    Not linked with Mobile Number?
                                                </span></div>
                                            <div class="text-center">
                                                <span (click)="adharDoc('adhar-doc')" class="point"><b>Verify aadhaar with
                                                        other
                                                        methods
                                                    </b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='Identity'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="text-center">
                                            <img src="../../../assets/images/adhar-card.png" class="ad-img" alt="">

                                            <h4>Verify Your Aadhaar Card</h4>
                                            <span>Select a mobile number/email address to set communication
                                                preferences</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-10">

                                            <div class="mt-8">
                                                <label>Enter OTP</label>
                                                <input type="number" name="OTP" id="" class="form-control"
                                                    placeholder="OTP"
                                                    onkeypress="if(this.value.length=='6') return false">
                                            </div>
                                            <button class="btn btn-primary mt-10" id="btn"
                                                (click)="AadharSuccess('AadharSuccess')">
                                                Verify Now</button>
                                            <div class="text-center mt-10"> <span>
                                                    Not linked with Mobile Number?
                                                </span>
                                                <span (click)="adharDoc('adhar-doc')" class="point"><b>Verify aadhaar with
                                                        other
                                                        methods
                                                    </b></span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="TabName=='AadharSuccess'">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <div>
                                            <img src="../../../assets/images/success.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h1>**** - **** - 2342</h1>
                                        <span>Your aadhaar card verified successfully</span>

                                    </div>
                                </div>
                                <div class=" mt-10 row">
                                    <div class="col-md-12 text-end">
                                        <button class="btn btn-primary" (click)="PanCard('Pan-card')">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName== 'Pan-card'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <div class="text-center">
                                            <img src="../../../assets/images/pan.png" class="ad-img" alt="">

                                            <h4>Verify Your PAN Card</h4>
                                            <span>Select a mobile number/email address to set communication
                                                preferences</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-10">
                                            <div>
                                                <label>Enter Pan Number</label>
                                                <input type="number" name="Pan" id="" class="form-control"
                                                    placeholder="Pan  Number">
                                            </div>
                                            <div class="mt-8 text-center">
                                                <label for="file-upload" class="file-upload-label"><i
                                                        class="fa fa-id-card" aria-hidden="true"></i>&nbsp;&nbsp;Uplode
                                                    Your Pan card</label>
                                                <input type="file" id="file-upload" class="file-upload-input">
                                            </div>
                                            <button class="btn btn-primary mt-10" id="btn" (click)="panSuccess('panSuccess')">
                                                Verify Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='panSuccess'">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <div>
                                            <img src="../../../assets/images/success.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h1>Verify Identity Success</h1>
                                        <span>Select a mobile number/email address to set communication
                                            preferences</span>
                                        <div class=" center">
                                            <span style="color: #E98315;"> Verify Identity process takes up-to 2 to 3
                                                days to
                                                verify</span>
                                        </div>
                                    </div>
                                </div>
                                <div class=" mt-10 row">
                                    <div class="col-md-12 text-end">
                                        <button class="btn btn-primary">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='adhar-doc'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="text-center">
                                            <img src="../../../assets/images/adhar-card.png" class="AP-img" alt="">
                                            <div class="mt-5">
                                                <h4>Verify Your Aadhaar Card</h4>
                                                <span>Select a mobile number/email address to set communication
                                                    preferences</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="text-center"><b>&nbsp;&nbsp;&nbsp;Upload Aadhar Front</b></span>
                                        <img src="../../../assets/images/adhar-font.png" class="AP-img" alt="">
                                        <div class="mt-10 ml-3">
                                            <label for="file-upload" class="file-upload-label"><i class="fa fa-id-card"
                                                    aria-hidden="true"></i>&nbsp;&nbsp;Aadhar Front </label>
                                            <input type="file" id="file-upload" class="file-upload-input">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="text-center"><b>&nbsp;&nbsp;&nbsp;Upload Aadhar Back</b></span>
                                        <img src="../../../assets/images/adhar-font.png" class="AP-img" alt="">
                                        <div class="mt-10 ml-3">
                                            <label for="file-upload" class="file-upload-label"><i class="fa fa-id-card"
                                                    aria-hidden="true"></i>&nbsp;&nbsp;Aadhar Back </label>
                                            <input type="file" id="file-upload" class="file-upload-input">
                                        </div>

                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp;
                                        &nbsp; &nbsp; <button class="btn btn-primary"
                                            (click)="adharDocSuccess('adhar-doc-success')">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='adhar-doc-success'">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <div>
                                            <img src="../../../assets/images/success.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h1>Your aadhaar card uploaded successfully</h1>

                                        <div class=" center">
                                            <span style="color: #E98315;"> Verify Identity process takes up-to 2 to 3
                                                days to
                                                verify</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- <div *ngIf="TabName=='Aadhaar&Pan'">
                            <div class="container  center">
                                <div class="row ">
                                    <div class="col-md-12 ">
                                        <h3>Please select option to complete your kyc</h3>
                                    </div>
                                    <div class="col-md-6 mt-10">
                                        <button class="btn btn-primary  text-start" (click)="Tab('Aadhar-otp')">Aadhaar Card</button>
                                    </div>
                                    <div class="col-md-6 mt-10 text-end">
                                        <button class="btn btn-primary  text-start" (click)="Tab('Pan-card')">Pan Card</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>