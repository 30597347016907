<div class="content-box bg-grey9" [ngClass]="!isUserLogged ? '' : 'user-dashboard'">
    <div class="container-fluid" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}" [ngClass]="!isUserLogged ? '' : 'user-route'">
        <!-- <div class="container"> -->
            <div class="row" >
              <div class="col-md-12">
                <div class="insuranceChild fontWeigth500">
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>
                  Select insurance plan and duration
                  <span class="compareText floatRight">
                    <i class="fa fa-balance-scale" aria-hidden="true"></i>
                    Compare</span>
                </div>
          
              </div>
            </div>
            <!--<div class="insuranceHeader">
              Insurance Plan
            </div>
          
            <div class="row">
              <div class="col-md-4 padding0">
                <div class="insuranceChild">
                  <div class="insChildHeader">Owner Damage Only
                   
                  </div>
                  <div class="insChildText">Covers damages to your vehicle only and not third party</div>
                  <div class="insuranceAmount">
                    <div>₹699/ 1 year</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 padding0">
                <div class="insuranceChild">
                  <div class="insChildHeader">Owner Damage Only
                   
                  </div>
                  <div class="insChildText">Covers damages to your vehicle only and not third party</div>
                  <div class="insuranceAmount">
                    <div>₹699/ 1 year</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 padding0">
                <div class="insuranceChild">
                  <div class="insChildHeader">Owner Damage Only
                   
                  </div>
                  <div class="insChildText">Covers damages to your vehicle only and not third party</div>
                  <div class="insuranceAmount">
                    <div>₹699/ 1 year</div>
                  </div>
                </div>
              </div>
            </div>-->
             
            <div class="insuranceHeader">
              Insurance Plan
            </div>
            <!--<div class="row">
              <div class="col-md-4 padding0">
                <div class="insuranceChild">
                  <div class="insChildHeader displayInline">1 Year</div>
                  <div class="displayInline policyAmount">₹699</div>
                  <div class="insChildText displayInline floatRight">premium </div>
                </div>
              </div>
              <div class="col-md-4 padding0">
                <div class="insuranceChild">
                  <div class="insChildHeader displayInline">1 Year</div>
                  <div class="displayInline policyAmount">₹699</div>
                  <div class="insChildText displayInline floatRight">premium </div>
                </div>
              </div>
              <div class="col-md-4 padding0">
                <div class="insuranceChild">
                  <div class="insChildHeader displayInline">1 Year</div>
                  <div class="displayInline policyAmount">₹699</div>
                  <div class="insChildText displayInline floatRight">premium </div>
                </div>
              </div>
            </div>-->
            <div class="row">
              <!--<div class="col-md-8 padding0">
                <div class="insuranceChild">
                  <div class="insChildHeader row m-0 mb-4">Insured Value(IDV)</div>
                  <div class="row m-0 mb-4">
                    <div class="col-1 m-auto">
                        <h5>₹30,500</h5>
                    </div>
                    <div class="col-10">
                    </div>
                    <div class="col-1 m-auto">
                        <h5>₹42,670</h5>
                    </div>
                  </div>
                  
                </div>
          
                <div class="insuranceChild">
                  <div class="fontWeigth500">Add Extra Coverage</div>
                  <div class="row">
                    <div class="col-md-6 marginTop16">
                      <input type="checkbox">
                      <label class="checkBoxLabel">Personal Accident Coverage of Rs. 10Lakh</label>
                   
                      <div class="coverageAmount">
                        ₹180/ <span class="coverageYear"> 1 year</span>
                      </div>
                    </div>
                    <div class="col-md-6 marginTop16">
                      <input type="checkbox">
                      <label class="checkBoxLabel">Personal Accident Coverage of Rs. 10Lakh</label>
                      <div class="coverageAmount">
                        ₹180/ <span class="coverageYear"> 1 year</span>
                      </div>
                    </div>
                    <div class="col-md-6 marginTop16">
                      <input type="checkbox">
                      <label class="checkBoxLabel">Personal Accident Coverage of Rs. 10Lakh</label>
                      <div class="coverageAmount">
                        ₹180/ <span class="coverageYear"> 1 year</span>
                      </div>
                    </div>
                    <div class="col-md-6 marginTop16">
                      <input type="checkbox">
                      <label class="checkBoxLabel">Personal Accident Coverage of Rs. 10Lakh</label>
                      <div class="coverageAmount">
                        ₹180/ <span class="coverageYear"> 1 year</span>
                      </div>
                    </div>
                    <div class="col-md-6 marginTop16">
                      <input type="checkbox">
                      <label class="checkBoxLabel">Personal Accident Coverage of Rs. 10Lakh</label>
                      <div class="coverageAmount">
                        ₹180/ <span class="coverageYear"> 1 year</span>
                      </div>
                    </div>
                    <div class="col-md-6 marginTop16">
                      <input type="checkbox">
                      <label class="checkBoxLabel">Personal Accident Coverage of Rs. 10Lakh</label>
                      <div class="coverageAmount">
                        ₹180/ <span class="coverageYear"> 1 year</span>
                      </div>
                    </div>
                  </div>
          
                </div>
              </div>-->
              <app-vehicle-premium-info></app-vehicle-premium-info>
              </div>
          <!-- </div> -->
    </div>
</div>


  <div class="insuranceChild bottomFooter">
    <div class="row childBottomFooter">
      <div class="col-md-8">
        <div style=" padding-left: 21px;">Net Premium</div>
        <div class="coverageAmount">₹{{premium}}</div>
      </div>
      <div class="col-md-4">
        <button class="floatRight btn btn-primary continueBtn" (click)="continue()">Continue</button>
        <div class="floatRight help"><a href="/faqs" class="btn">Help?</a></div>
     
      </div>
    </div>
   
  </div>


