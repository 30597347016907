<div class="row">
  <div class="col-2 col-md-3 col-lg-3 col-xl-2" *ngIf="ShowMenu">
      <app-nav-asidebar id="sideNav" class="sideNav" (currentpageEvent)="setCurrentPage($event)"></app-nav-asidebar>
  </div>
  <div  [ngClass]="ShowMenu ? 'col-10 col-md-9 col-lg-9 col-xl-10' :'col-12'">
    <div class="topnav1 card" #topnav1 id="topnav1" *ngIf="ShowMenu">
      <div class="row align-items-center">
        <div class="col">
          <span class="text-grey4">Pages / {{currentpage}}</span>
          <h4 class="mb-0">{{currentpage}}</h4>
        </div>
        <div class="col">
          <nav class="navbar navbar-expand-sm mb-0 p-0" [ngClass]="ShowMenu ? 'navbar2' : ''">
            <div class="container p-0" [ngStyle]="{'justify-content: flex-end !important;': !isSmallScreen}">
              <div class="row w-100">
                <div class="p-0">
                  <!-- data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" -->
                  <app-header-menu [showMenu]="ShowMenu"></app-header-menu>
                </div>
              </div>
            </div>
          </nav>
          <!-- <span class="button-label">Click to register as</span>
          <a class="button-link ml-3 mr-3" href="#">Business Profile</a>
          <img src="../../assets/svgIcon/BellSimple.svg" alt="Bell" class="pl-2 pr-2"> -->
        </div>
      </div>
    </div>
        <router-outlet></router-outlet>
  </div>
</div>