<div class="container" style="background-color: #f3f3f4;">
    <div class="row">
        <div class="col-md-12 ">
            <div class="card crd mt-5 p-1">
                <div class="row">
                    <div class="col-md-10">
                        <p>
                            <img src="../../../assets/svgIcon/ArrowLeft.svg" alt="">&nbsp;&nbsp;Select Health Insurance
                            Plan and Policy Duration
                        </p>
                    </div>
                    <div class="col-md-2 ">
                        <p>
                            <a href="" style="color: #10A0C1;"><img src="../../../assets/images/Scales.png" alt="">
                                Compare</a>
                        </p>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12 mt-10">
                    <h4>
                        Insurance Plan
                    </h4>
                </div>
                <div class="col-md-4 mt-5 ">
                    <div class="card div-crd p-4">
                        <div class="row">
                            <div class="col-md-10">
                                <p>My Health Suraksha</p>
                            </div>
                            <div class="col-md-2">
                                <img src="../../../assets/images/Vector4.png" alt="">
                            </div>
                            <div class="col-md-12 mt-2">
                                <span>Covers damages to your vehicle only and not third-party</span>
                            </div>
                            <div class="col-md-12 mt-4">
                                <h3 class="text-center">
                                    ₹699/ 1 Year
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-5 ">
                    <div class="card div-crd p-4">
                        <div class="row">
                            <div class="col-md-10">
                                <p>My Health Suraksha</p>
                            </div>
                            <div class="col-md-2">
                                <img src="../../../assets/images/Vector4.png" alt="">
                            </div>
                            <div class="col-md-12 mt-2">
                                <span>Covers damages to your vehicle only and not third-party</span>
                            </div>
                            <div class="col-md-12 mt-4">
                                <h3 class="text-center">
                                    ₹699/ 1 Year
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-5 ">
                    <div class="card div-crd p-4">
                        <div class="row">
                            <div class="col-md-10">
                                <p>My Health Suraksha</p>
                            </div>
                            <div class="col-md-2">
                                <img src="../../../assets/images/Vector4.png" alt="">
                            </div>
                            <div class="col-md-12 mt-2">
                                <span>Covers damages to your vehicle only and not third-party</span>
                            </div>
                            <div class="col-md-12 mt-4">
                                <h3 class="text-center">
                                    ₹699/ 1 Year
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-10">
                    <h4>
                        Policy Duration &nbsp;&nbsp;<img src="../../../assets/images/Vector4.png" alt="">
                    </h4>
                </div>
                <div class="col-md-4 mt-4">
                    <div class="card p-4 div-crd">
                        <div class="row">
                            <div class="col-md-8">
                                <span>
                                    1 Year
                                </span>
                            </div>
                            <div class="col-md-4">
                                <p><span>Premium</span> &nbsp; ₹699</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-4">
                    <div class="card p-4 div-crd">
                        <div class="row">
                            <div class="col-md-8">
                                <span>
                                    1 Year
                                </span>
                            </div>
                            <div class="col-md-4">
                                <p><span>Premium</span> &nbsp; ₹699</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-4">
                    <div class="card p-4 div-crd">
                        <div class="row">
                            <div class="col-md-8">
                                <span>
                                    1 Year
                                </span>
                            </div>
                            <div class="col-md-4">
                                <p><span>Premium</span> &nbsp; ₹699</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 mt-15">
                    <div class="card p-5 mt-5">
                        <div class="row">
                            <div class="col-md-12 mt-2">
                                <p>
                                    Insurance Cover
                                </p>
                            </div>
                            <div class="col-md-2    mt-2">
                                <p class="text-center">₹5 lakh</p>
                            </div>
                            <div class="col-md-8  text-center mt-2">
                                <input type="range" class="form-range text-center" id="customRange1">
                            </div>
                            <div class="col-md-2   text-center mt-3">
                                <p class="text-center">₹20 lakh</p>
                            </div>
                            <div class="col-md-12 mt-4">
                                <h3 class="text-center" style="color: #10A0C1;">
                                    ₹14 Lakh
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-15">
                    <div class="card  p-4">
                        <div class="row">
                            <div class="col-md-12">
                                <p> &nbsp; Insured members</p>
                            </div>

                            <div class="col-md-6 mt-3">
                                <span>
                                    Self
                                </span>
                                <p>
                                    32 Year
                                </p>

                            </div>
                            <div class="col-md-6 mt-3">
                                <span>
                                    Spouse’s
                                </span>
                                <p>
                                    28 Year
                                </p>

                            </div>
                            <div class="col-md-6 mt-3">
                                <span>
                                    1st Daughter
                                </span>
                                <p>
                                    07 Year
                                </p>

                            </div>
                            <div class="col-md-6 mt-3">
                                <span>
                                    2nd Daughter
                                </span>
                                <p>
                                    03 Year
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 mt-2">
                    <div class="card mt-3 p-5">
                        <div class="row">
                        <div class="col-md-12">
                            <p>Riders</p>
                            <span>You should get these additional benefits to enhance your current plan</span>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <p class="form-check-label" for="flexCheckDefault">
                                   &nbsp;&nbsp;&nbsp; Personal Accident Cover of Rs. 10 Lakh
                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: red;">PA cover is mandatory </span>
                                </p>
                            <h3 class="text-center mt-3">₹180/ 1 Year</h3>

                            </div>

                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <p class="form-check-label" for="flexCheckDefault">
                                   &nbsp;&nbsp;&nbsp; Engine and Gear-Box Protect Cover&nbsp;&nbsp;&nbsp;&nbsp;
                                </p>
                            <h3 class="text-center mt-3">₹100</h3>

                            </div>

                        </div>
                    </div>
                        
                    </div>
                </div>
                <div class="col-md-4 mt-2">
                    <div class="card p-5">
                        <div class="row">
                            <div class="col-md-12 mt-2">
                                <h4>
                                    Premium Breakup
                                </h4>
                            </div>
                            <div class="mt-3 col-md-8">
                                <span>InIDVidual Health Insurance</span>
                            </div>
                            <div class="mt-3 col-md-4">
                                <p>₹1,000</p>
                            </div>
                            <hr>
                            <div class="mt-1 col-md-8">
                                <span>Net Premium</span>
                            </div>
                            <div class="mt-1 col-md-4">
                                <p>₹1,000</p>
                            </div>
                            <div class="mt-1 col-md-8">
                                <span>Add GST(18%)</span>
                            </div>
                            <div class="mt-1 col-md-4">
                                <p>₹180</p>
                            </div>
                            <hr>
                            <div class="mt-1 col-md-8">
                                <span>Final Premium</span>
                            </div>
                            <div class="mt-1 col-md-4">
                                <p>₹1,180</p>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="card p-2" style="background-color: rgba(58, 130, 71, 0.1);">
<h4 style="color: rgba(58, 130, 71, 1);"><img src="../../../assets/images/percent.png" alt="">&nbsp;Your are eligible for 5% Discount</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card mt-5 p-5">
            <div class="row">
                <div class="col-md-8">
                    <span>Net premium</span>
                    <h3>₹1,180</h3>
                </div>
                <div class="col md-4">
                    <span>Help?</span>&nbsp;&nbsp;
                    <button class="btn btn-primary" id="btn" (click)="continue()" >Continue</button>
                </div>

            </div>
        </div>
    </div>
</div>