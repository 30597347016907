import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FamilyMembers, PropertyDetails, RelationShips, Vehicles, AddressDetails } from '../Models/Profile.model';
import { PolicyService } from '../Services/policy.service';
import { ProfileService } from '../Services/profile.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  vehiclesDetails: Vehicles[] = [];
  AddressDetailsList: AddressDetails[] = [];
  policyDetailsList: any = [];


  constructor(private profileService: ProfileService, private router: Router, private spinner: NgxSpinnerService,
    private policyService: PolicyService) {

  }
  ngOnInit(): void {
    this.GetVehiclesDetails();
    this.GetAddressDetails();
    this.GetPolicyDetails();
  }
  async GetVehiclesDetails() {
    this.vehiclesDetails = [];
    const response = await this.profileService.GetVehiclesDetails();
    if (response?.isSuccess) {
      this.vehiclesDetails = response.result?.vehicles;
    }
  }


  async GetPolicyDetails() {
    this.spinner.show();
    let request = {}
    const response = await this.policyService.GetPolicyDetails();
    if (response?.isSuccess) {
      this.policyDetailsList = response.result;
      if(this.policyDetailsList != null && this.policyDetailsList != undefined && this.policyDetailsList.length > 2) {
        this.policyDetailsList = this.policyDetailsList.slice(0, 2);
      } 
    }
    this.spinner.hide();
  }

  async GetAddressDetails() {
    this.AddressDetailsList = [];
    const response = await this.profileService.GetAddressDetails();
    if (response?.isSuccess) {
      this.AddressDetailsList = response.result?.userAddresses;
    }
  }

  RedirectPolicy() {
    this.router.navigate(['policyDetails']);
  }

  insurance(type: string) {
    if(type == 'bike')
    this.router.navigate(['bike']);
    else if (type == 'car')
    this.router.navigate(['car']);
  }
   imageObject: Array<object> = [{
    image: '../../assets/images/banner/01 Motor Insurance.jpg',
    thumbImage: '../../assets/images/banner/01 Motor Insurance.jpg',
    order: 1,
  }, {
    image: '../../assets/images/banner/02 Health Insurance.jpg',
    thumbImage: '../../assets/images/banner/02 Health Insurance.jpg',
    order:2,
  },{
    image: '../../assets/images/banner/03 Life Insurance.jpg',
    thumbImage: '../../assets/images/banner/03 Life Insurance.jpg',
    order: 3,
  },{
    image: '../../assets/images/banner/04 Term Insurance.jpg',
    thumbImage: '../../assets/images/banner/04 Term Insurance.jpg',
    order: 4,
  },{
    image: '../../assets/images/banner/05 Saving Plans.jpg',
    thumbImage: '../../assets/images/banner/05 Saving Plans.jpg',
    order: 5,
  },{
    image: '../../assets/images/banner/06 Family Protection.jpg',
    thumbImage: '../../assets/images/banner/06 Family Protection.jpg',
    order: 6,
  },{
    image: '../../assets/images/banner/07 Insurance Support.jpg',
    thumbImage: '../../assets/images/banner/07 Insurance Support.jpg',
    order: 7,
  },{
    image: '../../assets/images/banner/08 Best Price Insurance.jpg',
    thumbImage: '../../assets/images/banner/08 Best Price Insurance.jpg',
    order: 8,
  }
  ];

}
