<ng-container *ngIf="required_input_fields && required_input_fields.memberFormFields && required_input_fields.memberFormFields.length > 0">
    <div class="row">
        <ng-container *ngFor="let field of required_input_fields.memberFormFields">
            <div class="w-100 border-bottom" *ngIf="field.id?.indexOf('NomineeFirstname') != -1 || field.id?.indexOf('GuardianFirstname') != -1"></div>
            <div class="my-2 dynamic-field-container {{field.className != null && field.className != undefined && field.className != '' ? field.className : containerClass}}"  [ngClass]="{'d-none':!field.isVisible}">
                <label class="mb-0" [ngClass]="{'d-none':field.type == 'checkbox', 'required':field.isRequired}">{{ field.title }}</label>
                <ng-container [ngSwitch]="field.type">
                    <ng-container *ngSwitchCase="'radio'">
                        <div class="d-flex align-items-center gap-5">
                            <ng-container *ngFor="let option of field.options">
                                <span class="d-flex align-items-center">
                                    <input type="radio" class="mt-0 scale-1_3" [id]="field.id + option" [name]="field.name"  [value]="option" [checked]="field.selectedValue ==option ? 'checked' :''" (change)="onChange(field.id??'',$event)"/><label [for]="field.id + option" class="ml-2 mb-0"> {{ option }}</label>
                                </span>
                            </ng-container>
                        </div>                
                    </ng-container>
                    <ng-container *ngSwitchCase="'checkbox'">
                        <span class="d-flex align-items-center">
                            <input type="checkbox" class="mt-0 scale-1_3" value="" [checked]="field.selectedValue == 'Yes'" (click)="onChange(field.id??'',$event, field.type, field.id)"><label [for]="field.id" class="ml-2 mb-0"  [ngClass]="{'required':field.isRequired}"> {{ field.title }}</label>
                        </span>
                    </ng-container>
                    <select *ngSwitchCase="'select'" class="form-select py-2" (change)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isRequired && field.selectedValue ==''}">
                        <option *ngFor="let option of field.options" [value]="option" [selected]="field.selectedValue ==option ? 'selected' :''" >{{ option }}</option>
                    </select>
                    <input *ngSwitchCase="'text'" type="text" class="form-control" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isRequired && field.selectedValue ==''}"/>
                    <input *ngSwitchCase="'number'" type="number"  class="form-control" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isRequired && field.selectedValue ==''}"/>        
                    <textarea *ngSwitchCase="'textarea'" class="form-control" rows="3" [placeholder]="field.placeholder" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isRequired && field.selectedValue ==''}"></textarea>
                    <input *ngSwitchCase="'date'" type="date" class="form-control" [value]="field.selectedValue" (blur)="onChange(field.id??'',$event)" [ngClass]="{'border border-danger':field.isRequired && field.selectedValue ==''}"/>
                </ng-container>
            </div>
        </ng-container >
    </div>    
</ng-container>
