import { Component } from '@angular/core';

@Component({
  selector: 'app-agenttraining',
  templateUrl: './agenttraining.component.html',
  styleUrls: ['./agenttraining.component.css']
})
export class AgenttrainingComponent {

}
