import { Component } from '@angular/core';

@Component({
  selector: 'app-arogya-sanjivni-policy',
  templateUrl: './arogya-sanjivni-policy.component.html',
  styleUrls: ['./arogya-sanjivni-policy.component.css']
})
export class ArogyaSanjivniPolicyComponent {

}
