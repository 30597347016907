<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Single Pay Insurance</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <div class="title-line">
                    <h4>What is single premium term insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Term insurance is one of the simplest types of insurance products. The insurance company assures a pre-decided amount to be paid to the nominee  in the event of the insured’s death on the condition that the policyholder pays the premiums without fail. Term insurance plans do not have any investment component and terminate after the policy term gets over. The payment of premiums is an important aspect of a term plan. Regular term insurance plan have a monthly, quarterly or annual payments plan, but you can also opt for single premium term insurance plans. With single premium term insurance plans, you have the option to pay the premium for the entire policy term at once as a lump-sum amount.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>When should you opt for single premium term insurance?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If you are someone like Ramesh with a fluctuating income, a single premium term insurance could be a suitable option for you. Regular term insurance policies have premium payment tenures stretching over several years, which is a long-term commitment. Since you have to pay a lump-sum amount for a single premium term insurance it is slightly more expensive than regular term insurance. The ideal scenario to buy a single premium term insurance is if you have considerable funds which have to be utilised or have received a hefty cash gift or earned windfall gains in business.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="">
                    <h4>Benefits of single premium term insurance </h4>
                </div>
                <div class="title-line">
                    <h4>Buy and forget: </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">With single premium term insurance, you do not have to worry about monitoring the policy and ensuring regular premium payments. One just has to pay once and policy remains active till the end of the policy term. In the case of regular term insurance plans, if you miss a premium, the insurance company generally gives a grace period for payment. However, if you do not pay or miss the payment even after the grace period, the policy lapses. A lapsed term insurance policy is of no use as the claim will be rejected when your family needs it in your absence.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Stress-free life: </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A term insurance plan ensures that your family remains financially secure even in your absence. With the premium paid as a lump-sum amount, your family is insulated from complexities of the policy. They just have to file a claim in the event of an unfortunate incident. Single-premium term insurance can also be used as collateral in case you avail a loan as you create an asset when you pay the premium for the policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Tax benefits: </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Single premium term insurance policy provides a host of tax benefits. The premium paid for the policy is eligible for deduction up to Rs 1.5 lakh under Section 80C of the Income Tax Act,1961. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>List of top Five Single Premium Insurance Policies</h4>
                </div>
                <div class="mt-3">
                    <h4>1. LIC Single Premium Endowment Plan</h4>
                    <p class="font-body">LIC is one of the most trusted names in the insurance industry, catering to the insurance needs of the customers for more than six decades.</p>
                    <p class="font-body">LIC’s single premium insurance plan serves as a life cover as well as a considerable investment option for those who want to invest a lump sum at once. The plan comes with the following benefits:</p>
                    <p class="font-body">The plan comes with maturity benefit, wherein the maturity benefit is paid after the completion of the policy tenure. In addition to the sum assured, a reversionary bonus (as a percentage of sum assured) and final bonus (paid at once at the end of the policy term) is paid as a maturity benefit.</p>
                    <p class="font-body">The plan also includes a death benefit in the event of the unfortunate demise of the insured. The beneficiary would be paid only the sum assured in case the insured passes away before the commencement of the risk. If the insured passes away after the commencement of the risk, the beneficiary will be eligible to receive accrued bonuses as well.</p>
                    <p class="font-body">The plan also guarantees a specific surrender value for the policyholders which is equal to 75% of the premium paid if the policy is surrendered within the first year. 90% of the value of the premium will be paid as surrender value in case the policy is surrendered after the second year.</p>
                </div>
                <div class="mt-3">
                    <h4>2. ICICI Pru Easy Retirement SP</h4>
                    <p class="font-body">The ICICI Pru Easy Retirement SP helps you build a corpus for retirement at your own risk appetite. The plan comes with a minimum assured</p>
                    <p class="font-body">benefit and accumulated bonus on top of that. The plan also allows the investment of more money to the plan in the form of top-ups. The</p>
                    <p class="font-body">policyholder will also be allowed to choose between two fund options in equity and related securities, money market and debt..</p>
                </div>
                <div class="mt-3">
                    <h4>3. Kotak Single Invest Advantage</h4>
                    <p class="font-body">The Kotak Single Invest Advantage is a single premium insurance plan that offers much flexibility to the policyholder to choose from among various funds for investment, and have access to the money after the completion of 18 years of age.</p>
                    <p class="font-body">Some of the key features of the plan are listed below:</p>
                    <p class="font-body">The insured has the option to choose from five different fund options.</p>
                    <p class="font-body">The accidental death benefit is offered to the beneficiary in the event of an unfortunate demise of the policyholder. The beneficiary will be eligible to receive higher of:</p>
                    <p class="font-body">The fund value</p>
                    <p class="font-body">Top-up sum assured</p>
                    <p class="font-body">105% of the premiums</p>
                    <p class="font-body">Partial and systematic partial withdrawals, loyalty addition and 15-day free look period are some other features of the fund.</p>
                </div>
                <div class="mt-3">
                    <h4>4. HDFC Life Click 2 Invest ULIP</h4>
                    <p class="font-body">The HDFC Click 2 Invest ULIP combines the benefits of life insurance cover with investments. The plan is designed to maximize your investments while providing you benefits at maturity and payout to the nominee at death. The plan also offers as many as 8 fund options to the insured to choose from as per his/her risk appetite.</p>
                    <p class="font-body">The key features of this plan are discussed below:</p>
                    <p class="font-body">This plan provides maturity benefits to the insured on completion of the policy term which can be redeemed over a period of 5 years via the Settlement Option.</p>
                    <p class="font-body">The plan may be surrendered by the policyholder after the completion of 5 years of the policy wherein the entire value of the fund will be paid to the policyholder. If the policy is surrendered before the span of 5 years, the fund value minus discontinuation charge will be credited to the Discontinued Policy Fund which shall earn an interest of 4% p.a.</p>
                    <p class="font-body">The plan also comes with the option of partial withdrawal and up to 4 free-switches between funds.</p>
                </div>
                <div class="mt-3">
                    <h4>5. SBI Life Smart Wealth Assure</h4>
                    <p class="font-body">The SBI Life Smart Wealth Assure is one of the best plans if you are looking forward to investing in a lump sum and maximizing your savings.</p>
                    <p class="font-body">The plan offers two funds to the insured for investment- equity fund and bond fund.</p>
                    <p class="font-body">Let's take a look at the key features of the plan:</p>
                    <p class="font-body">The policyholder will be paid the complete fund value as maturity benefit.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
