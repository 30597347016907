import { Component } from '@angular/core';
@Component({
  selector: 'customer-popover',
  template: `<div>
						    <!-- <button (click)="onEditClicked()" class="btn btn-sm btn-primary">Edit</button> -->
                <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_24_18" title="Edit Customer" (click)="onEditClicked()">
						</div>`
})

export class CustomerpopoverComponent {

  ngOnInit() {

  }
  params: any;
  agInit(params: any): void {
    this.params = params;
  }
  constructor() { }
  async onEditClicked() {
    this.params.context.componentMember.EditCustomer(this.params.data);
  }

}
