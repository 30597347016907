<div class="container mt-5">
    <div class="row bg-grey9">
        <div class="col-md-6 p-10 mt-20">
            <h2>
                How can we help you today?
            </h2>
            <span>Manage your insurance policies & renewals</span>
            <div class="card dis mt-10">
                <div class="row">
                    <div class="col-12">
                      <ul class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center">
                        <li class="list-item col-4" [ngClass]="{'activeList': isAll}" (click)="changeTab('all')">
                          <a class="font-body active font-15-12" data-toggle="tab" href="#all">All</a>
                        </li>
                        <li class="list-item  col-4" [ngClass]="{'activeList': isActive}"   (click)="changeTab('active')">
                          <a class="font-body font-15-12" data-toggle="tab" href="#active">Active</a>
                        </li>
                        <li class="list-item  col-4" [ngClass]="{'activeList': isExpired}" (click)="changeTab('expired')">
                          <a class="font-body font-15-12" data-toggle="tab" href="#expired">Expired</a>
                        </li>
                      </ul>
                    </div>
                  </div>
            </div>
            <div class="card mt-10 p-5">
                <div class="row">
                    <div class="col-md-10">
                        <span> Plan</span>
                        <br>
                        <p>
                            Personal Accident Insurance
                        </p>
                    </div>
                    <div class="col-md-2">
                        <img src="../../assets/images/Vector@2x.png" class="icon" alt="">
                    </div>
                    <div class="col-md-4">
                        <img src="../../assets/images/united.png" id="img" alt="">
                    </div>
                    <div class="col-md-4">
                        <p>
                            United india insurance
                        </p>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="card div-back">
                        <div class="row">
                            <div class="col-md-4">
                                <span>
                                    Cover
                                </span>
                                <p>
                                    ₹ 15,00,000
                                </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Status
                                </span>
                                <p>
                                    Policy Issued </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Cover
                                </span>
                                <p>
                                    ₹ 389 (Yearly)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-10 p-5">
                <div class="row">
                    <div class="col-md-10">
                        <span> Plan</span>
                        <br>
                        <p>
                            Personal Accident Insurance
                        </p>
                    </div>
                    <div class="col-md-2">
                        <img src="../../assets/images/Vector@2x.png" class="icon" alt="">
                    </div>
                    <div class="col-md-4">
                        <img src="../../assets/images/united.png" id="img" alt="">
                    </div>
                    <div class="col-md-4">
                        <p>
                            United india insurance
                        </p>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="card div-back">
                        <div class="row">
                            <div class="col-md-4">
                                <span>
                                    Cover
                                </span>
                                <p>
                                    ₹ 15,00,000
                                </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Status
                                </span>
                                <p>
                                    Policy Issued </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Cover
                                </span>
                                <p>
                                    ₹ 389 (Yearly)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-10 p-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card text-center"
                            style="background-color: rgba(233, 131, 21, 0.2);color: rgba(233, 131, 21, 1);">
                            2 Open Tickets
                        </div>
                    </div>
                    <div class="col-md-10">

                        <span> Plan</span>
                        <br>
                        <p>
                            Personal Accident Insurance
                        </p>
                    </div>
                    <div class="col-md-2" (click)="next('next1')">
                        <img src="../../assets/images/Vector@2x.png" class="icon" alt="">
                    </div>
                    <div class="col-md-4">
                        <img src="../../assets/images/united.png" id="img" alt="">
                    </div>
                    <div class="col-md-4">
                        <p>
                            United india insurance
                        </p>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="card div-back">
                        <div class="row">
                            <div class="col-md-4">
                                <span>
                                    Cover
                                </span>
                                <p>
                                    ₹ 15,00,000
                                </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Status
                                </span>
                                <p>
                                    Policy Issued </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Cover
                                </span>
                                <p>
                                    ₹ 389 (Yearly)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 " *ngIf="nextDiv===''">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="p-80 margin">
                        <img src="../../assets/images/Personal files-bro 1.png" alt="">
                    </div>
                    <div class="col-md-12 text-center"><span>Select a Policy to get help</span>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-6 " *ngIf="nextDiv==='next1'">
            <div class="card margin p-5">
                <div class="row">
                    <div class="col-md-12">
                        <span>
                            Plan
                        </span><br>
                        <p>Compulsory Personal Accident (Owner-Driver) </p>
                    </div>
                    <div class="col-md-4">
                        <img src="../../assets/images/united.png" id="img" alt="">
                    </div>
                    <div class="col-md-8">
                        <p>
                            United india insurance
                        </p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span> Plan Term</span>
                        <p> 1 Year</p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span>Sum Assured</span>
                        <p>₹ 15,00,000</p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span>Booking Date</span>
                        <p>23-Dec-2022</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span> Your Premium</span>
                        <p>₹389 Yearly</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span>Status</span>
                        <p>Policy Issued</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span>Booking ID</span>
                        <p>515620069</p>
                    </div>
                    <div class="col-md-6">
                        <span>Proposer</span>
                        <p>Pushpendra Singh Rathor</p>
                    </div>
                    <div class="col-md-6">
                        <span>Policy No.</span>
                        <p>PBC/000000595296</p>
                    </div>

                </div>
            </div>
            <div class="card mt-5 p-5">
                <div class="row">
                    <div class="col-md-4">
                        <button class="btn btn-primary" id="btn">
                            Download PDF &nbsp;&nbsp;&nbsp;<i class="fa fa-file-o" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-outline-primary" id="btn">
                            Get Help &nbsp;&nbsp;&nbsp;<i class="fa fa-life-ring" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-outline-primary" id="btn">
                            Share &nbsp;&nbsp;&nbsp;<i class="fa fa-share-alt" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <span>
                    Your Have 2 open Tickets
                </span>
            </div>
            <div class="card mt-10 p-5">
                <div class="row">
                    <div class="col-md-10">
                        <span> Concern</span>
                        <br>
                        <p>
                            Policy is not reflecting at parivahan site
                        </p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="card div-back">
                        <div class="row">
                            <div class="col-md-4">
                                <span>
                                    Raised
                                </span>
                                <p>
                                    May 11, 2023
                                </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Ticket ID
                                </span>
                                <p>
                                    BG34000023234 </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Status
                                </span>
                                <p>
                                    In Work
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-5 p-5">
                <div class="row">
                    <div class="col-md-10">
                        <span> Concern</span>
                        <br>
                        <p>
                            Policy is not reflecting at parivahan site
                        </p>
                    </div>
                    <div class="col-md-2">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="card div-back">
                        <div class="row">
                            <div class="col-md-4">
                                <span>
                                    Raised
                                </span>
                                <p>
                                    May 11, 2023
                                </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Ticket ID
                                </span>
                                <p>
                                    BG34000023234 </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Status
                                </span>
                                <p>
                                    In QUEUE
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-primary" id="btn" (click)="next('next2')">
                        Have any other concern?
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="nextDiv==='next2'">
            <div class="card p-5 margin">
                <div class="row">
                    <div class="col-md-2">
                        <img src="../../assets/images/Vector.png" alt="">
                    </div>
                    <div class="col-md-10">
                        <samp>Concern</samp><br>
                        <p>Policy is not reflecting at parivahan site</p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="card div-back">
                        <div class="row">
                            <div class="col-md-4">
                                <span>
                                    Raised
                                </span>
                                <p>
                                    May 11, 2023
                                </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Ticket ID
                                </span>
                                <p>
                                    BG34000023234 </p>
                            </div>
                            <div class="col-md-4">
                                <span>
                                    Status
                                </span>
                                <p>
                                    In QUEUE
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container mt-5 con">
                <div class="body">
                    <p class="msg mt-5">hello</p>
                    <p class="msg user mt-5">hii</p>
                </div>
                <div class="footer">
                    <form action="#">
                        <input type="text" name="" id="" class="form-control">
                        <button class="btn btn-primary btn-sm send" (click)="next('next3')"> send</button>
                    </form>
                </div>
            </div>

        </div>
        <div class="col-md-6" *ngIf="nextDiv==='next3'">
            <div class="card margin p-5">
                <div class="row">
                    <div class="col-md-12">
                        <span>
                            Plan
                        </span><br>
                        <p>Compulsory Personal Accident (Owner-Driver) </p>
                    </div>
                    <div class="col-md-4">
                        <img src="../../assets/images/united.png" id="img" alt="">
                    </div>
                    <div class="col-md-8">
                        <p>
                            United india insurance
                        </p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span> Plan Term</span>
                        <p> 1 Year</p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span>Sum Assured</span>
                        <p>₹ 15,00,000</p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span>Booking Date</span>
                        <p>23-Dec-2022</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span> Your Premium</span>
                        <p>₹389 Yearly</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span>Status</span>
                        <p>Policy Issued</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span>Booking ID</span>
                        <p>515620069</p>
                    </div>
                    <div class="col-md-6">
                        <span>Proposer</span>
                        <p>Pushpendra Singh Rathor</p>
                    </div>
                    <div class="col-md-6">
                        <span>Policy No.</span>
                        <p>PBC/000000595296</p>
                    </div>

                </div>
            </div>
            <div class="card mt-5 p-5">
                <div class="row">
                    <div class="col-md-4">
                        <button class="btn btn-primary" id="btn">
                            Download PDF &nbsp;&nbsp;&nbsp;<i class="fa fa-file-o" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-outline-primary" id="btn">
                            Get Help &nbsp;&nbsp;&nbsp;<i class="fa fa-life-ring" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-outline-primary" id="btn">
                            Share &nbsp;&nbsp;&nbsp;<i class="fa fa-share-alt" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-5 p-2">
                <h4>
                    How can we help?
                </h4>
                <div class="card mt-5 p-5">
                    <div class="card mt-2 p-3">
                        <div class="container ">
                            <details>
                                <summary> I need help in cancellation of the policy</summary>
                                <div></div>
                            </details>
                        </div>
                    </div>
                    <div class="card mt-2 p-3">
                        <div class="container ">
                            <details>
                                <summary> I have a payment related Issue</summary>
                                <div class="mt-2 p-2">
                                    <div class="card p-2 mt-2" style="background-color: #F3F3F4;">
                                        <p>
                                            I have Financial Issues
                                        </p>
                                    </div>
                                    <div class="card p-2 mt-2" style="background-color: #F3F3F4;">
                                        <p>
                                            I have Financial Issues
                                        </p>
                                    </div>

                                </div>
                            </details>
                        </div>
                    </div>
                    <div class="card mt-2 p-3">
                        <div class="container ">
                            <details>
                                <summary> I want to make changes in my policy copy</summary>
                                <div></div>
                            </details>
                        </div>
                    </div>
                    <div class="card mt-2 p-3">
                        <div class="container ">
                            <details>
                                <summary>I have a claims related query</summary>
                                <div></div>
                            </details>
                        </div>
                    </div>
                    <div class="card mt-2 p-3">
                        <div class="container ">
                            <details>
                                <summary>I want to give a feedback</summary>
                                <div></div>
                            </details>
                        </div>
                    </div>
                    <div class="card mt-2 p-3">
                        <div class="container ">
                            <details>
                                <summary>I have refund related issues</summary>
                                <div></div>
                            </details>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2 p-1">
                <div class="col-md-12">
                    <button class="btn btn-primary btn-sm" id="btn" (click)="next('next4')"> Have any other
                        concern?</button>
                </div>
            </div>
        </div>

        <div class="col-md-6" *ngIf="nextDiv==='next4'">
            <div class="card margin p-5">
                <div class="row">
                    <div class="col-md-12">
                        <span>
                            Plan
                        </span><br>
                        <p>Compulsory Personal Accident (Owner-Driver) </p>
                    </div>
                    <div class="col-md-4">
                        <img src="../../assets/images/united.png" id="img" alt="">
                    </div>
                    <div class="col-md-8">
                        <p>
                            United india insurance
                        </p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span> Plan Term</span>
                        <p> 1 Year</p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span>Sum Assured</span>
                        <p>₹ 15,00,000</p>
                    </div>
                    <div class="col-md-4 mt-2">
                        <span>Booking Date</span>
                        <p>23-Dec-2022</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span> Your Premium</span>
                        <p>₹389 Yearly</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span>Status</span>
                        <p>Policy Issued</p>
                    </div>
                    <div class="col-md-4 mt-3">
                        <span>Booking ID</span>
                        <p>515620069</p>
                    </div>
                    <div class="col-md-6">
                        <span>Proposer</span>
                        <p>Pushpendra Singh Rathor</p>
                    </div>
                    <div class="col-md-6">
                        <span>Policy No.</span>
                        <p>PBC/000000595296</p>
                    </div>

                </div>
            </div>
            <div class="card mt-5 p-5">
                <div class="row">
                    <div class="col-md-4">
                        <button class="btn btn-primary" id="btn">
                            Download PDF &nbsp;&nbsp;&nbsp;<i class="fa fa-file-o" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-outline-primary" id="btn">
                            Get Help &nbsp;&nbsp;&nbsp;<i class="fa fa-life-ring" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-outline-primary" id="btn">
                            Share &nbsp;&nbsp;&nbsp;<i class="fa fa-share-alt" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card mt-3 p-3">
                <span>I have a payment related Issue</span>
                <br>
                <p>
                    <img src="../../assets/images/Vector (1).png" alt="">&nbsp;&nbsp;&nbsp; <b>I have Financial
                        Issues</b>
                </p>

            </div>
            <div class="card mt-4 p-5">
                <p><b>
                        Tell us how we can help you
                    </b></p>
                <span>
                    Enter the details for your service request
                </span>
                <div class="card mt-6 p-5 brd">
                    Nice doctors...Nice staff..It is located on the main road Named Sirsi Road so easily
                </div>
            </div>

            <div class="mt-8 text-center crd-brd " id="btn">
                <label for="file-upload" class="file-upload-label" id="btn"><img src="../../assets/images/Upload.png"
                        alt="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add
                    attachment</label>
                <input type="file" id="file-upload" class="file-upload-input">
            </div>
            <div class="card mt-5 p-2">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="../../assets/images/v4-460px-Write-a-Bill-for-Payment-Step-1-Version-4.jpg 1.png"
                                alt="">
                        </div>
                        <div class="col-md-4">
                            <img src="../../assets/images/v4-460px-Write-a-Bill-for-Payment-Step-1-Version-4.jpg 1.png"
                                alt="">
                        </div>
                        <div class="col-md-4">
                            <img src="../../assets/images/v4-460px-Write-a-Bill-for-Payment-Step-1-Version-4.jpg 1.png"
                                alt="">
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-primary" id="btn">
Submit
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- <app-footer></app-footer> -->