import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceService } from '../Services/insurance.service';
import { PolicyService } from '../Services/policy.service';
import swal from 'sweetalert2';
import { GeneralService } from '../Services/general.service';
@Component({
  selector: 'app-policypayment',
  templateUrl: './policypayment.component.html'
})
export class PolicyPaymentComponent implements OnInit {

  type: number = 0;
  token: string = '';
  transactionId: string = '';

  constructor(private router: Router, private route: ActivatedRoute, private policyService: PolicyService, private generalService : GeneralService)
  {
    ;
    this.type = this.route.snapshot.queryParams['type'];
    this.token = this.route.snapshot.queryParams['token'];
    this.transactionId = this.route.snapshot.queryParams['transactionNumber'] ?? '123443';
  }

  ngOnInit() {
    this.SavePaymentDetail();
  }

  async SavePaymentDetail() {
    let data = { uniqueUerId: this.generalService.getUniqueuserid(),value1: this.token, value2: this.transactionId }
    console.log(data,"for params");
    const response = await this.policyService.SavePaymentDetail(data);
    console.log(response,"TEst Payment")
    if (response?.isSuccess) {
      let request = { ApplicationId: this.token, InsuranceCompany: this.type }
      console.log(request,"UpdateRequest");
      ;
      const responseReceipt = await this.policyService.UpdatePolicyReceipt(request);
      console.log(responseReceipt,"TEst Payment12");
      if(responseReceipt && responseReceipt.isSuccess)
      {
        this.router.navigate(['/policyDetails']);
      }
      else
      {
        swal.fire({
          position: 'center',
          // icon: 'error',
          // title: res?.message,
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: responseReceipt?.message??"Unable to buy policy",
          //text: 'Please check and re-enter your valid mobile number or password and try again.',
          showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            },
        });
        this.router.navigate(['/policyDetails']);
      }
      
      // const linkSource =responseReceipt;
      // const downloadLink = document.createElement('a');
      // const fileName = 'policy.pdf';
  
      // downloadLink.href = 'data:application/pdf;base64,' +linkSource;
      // downloadLink.download = fileName;
      // downloadLink.click();
       
    }
  }
}
