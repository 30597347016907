import { Component } from '@angular/core';

@Component({
  selector: 'app-individual-health-policy',
  templateUrl: './individual-health-policy.component.html',
  styleUrls: ['./individual-health-policy.component.css']
})
export class IndividualHealthPolicyComponent {

}
