<div class="user-dashboard">
  <div class="card user-route">
    <div class="row p-3">
      <div class="col-12 col-lg-6 align-self-center">
        <div class="row">
          <div class="col-4 col-md-2 align-self-center d-flex justify-content-center">
            <img class="rounded-circle" src="https://api.bimag.in/portalapi/api/user/GetProfileImage?uniqueUserId={{uniqueuserid}}">
          </div>
          <div class="col d-flex justify-content-center flex-column">
            <p class="fw-bolder font-17-13 text-main-secondary mb-2">{{fullname}}</p>
            <p class="text-grey4 font-15-2 mb-0">{{email}}</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 align-self-center text-right">
        <ul class="d-flex mb-0 align-items-center pl-0 text-nowrap overflow-x-auto">
          <li class="list-item mr-2" [ngClass]="{'activeList': isPersonalDetails}" (click)="changeTab('personalDetails')">
            <a class="font-body" data-toggle="tab" href="#Personal">Personal Details</a>
          </li>
          <li class="list-item ml-2 mr-2" [ngClass]="{'activeList': isPassword}" (click)="changeTab('changePaasword')">
            <a class="font-body" data-toggle="tab" href="#changePaasword">Change Password</a>
          </li>
          <!-- <li class="list-item ml-2 mr-2" [ngClass]="{'activeList': isFamilyDetails}" (click)="changeTab('familyDetails')">
            <a class="font-body" data-toggle="tab" href="#Family">Family Details</a>
          </li>
          <li class="list-item  ml-2 mr-2" [ngClass]="{'activeList': isAssets}" (click)="changeTab('Assets')">
            <a class="font-body" data-toggle="tab" href="#Assets">Assets</a>
          </li>
          <li class="list-item ml-2 mr-2" [ngClass]="{'activeList': isAddresses}" (click)="changeTab('Addresses')">
            <a class="font-body" data-toggle="tab" href="#Addresses">Addresses</a>
          </li> -->
          
        </ul>
      </div>
    </div>
  </div>
  <div class="tab-content clearfix mt-5">
    <div id="Personal" class="tab-pane active">
     <app-personal-details></app-personal-details>
    </div>
    <div id="Family" class="tab-pane">
    <app-family-details></app-family-details>
    </div>
    <div id="Assets" class="tab-pane">
     <app-assets></app-assets>
    </div>
    <div id="Addresses" class="tab-pane ">
   <app-addresses></app-addresses>
  </div>
  <div id="changePaasword" class="tab-pane ">
    <app-change-password></app-change-password>
  </div>
</div>
</div>
<!-- <app-footer></app-footer> -->
