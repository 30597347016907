<div class="user-dashboard mt-10">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
    type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="row user-route">
    <!-- <div class="col-md-4 mb-1">
      <img src="../../assets/images/banner/01 Motor Insurance.jpg" class="w-100">
    </div>
    <div class="col-md-4 mb-1">
      <img src="../../assets/images/banner/02 Health Insurance.jpg" class="w-100">
    </div>
    <div class="col-md-4 mb-1">
      <img src="../../assets/images/banner/03 Life Insurance.jpg" class="w-100">
    </div> -->
    <ng-image-slider imageSize="width: 20%, height: 20%, space: 15" [images]="imageObject" #nav  [infinite]="true"
    [autoSlide]="1"
    [imageSize]="{width: '30%', height: '25%', space:13}"
    slideImage="1"></ng-image-slider>
  </div>
  <!-- <div class="row mt-10">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h3 class="text-grey5 mb-0">Incomplete Policies</h3>
      <p class="m-0"><span class="businessTxt">View All</span></p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mt-5" Para-plan>
      <div class="row m-0 insurancePlanChildDiv1">
        <div class="col-12">
          <div class="row">
            <div class="col-11">
              <p class="text-grey10 mb-0 font-body font-15-12">Plan</p>
              <span class="mb-1 fw-bolder font-17-13">
                Compulsory Personal Accident (Owner-Driver)                
              </span>
            </div>
            <div class="col-1 d-flex justify-content-center align-items-center"><i class="bi bi-chevron-right rightArrIcon"></i></div>
          </div>
        </div>
        <div class="progress progressBar mb-0 progressBar1" role="progressbar" aria-label="progress" aria-valuenow="100"
          aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar" style="width: 100%; background-color: var(--main);"></div>
        </div>
        <div class="col-12">
          <p class="warnTxt font-body">Kindly renew your policy before it expires and get 80% off at checkout.</p>
        </div>
        
      </div>
    </div>

    <div class="col-md-6  mt-5" Para-plan>
      <div class="row m-0 insurancePlanChildDiv1">
        <div class="col-12">
          <div class="row">
            <div class="col-11">
              <p class="text-grey10 m-0 font-body font-15-12">Plan</p>
              <span class="mb-1 fw-bolder font-17-13">
                Compulsory Personal Accident (Owner-Driver)
              </span>
            </div>        
            <div class="col-1 d-flex justify-content-center align-items-center"><i class="bi bi-chevron-right rightArrIcon"></i></div>
          </div>
        </div>        
        <div class="progress progressBar mb-0 progressBar1" role="progressbar" aria-label="Basic example" aria-valuenow="100"
          aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar" style="width: 25%; background-color: var(--main);"></div>
        </div>
        <div class="col-12">
          <p class="successTxt font-body">Complete your policies today and save more 80%</p>
        </div>
        
      </div>
    </div>
  </div> -->
  <div class="row mt-10">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h3 class="text-grey5 mb-0">Inactive Policies</h3>
      <p class="m-0"><span class="businessTxt" >View All</span></p>
    </div>
  </div>
<div class="row  mt-3">
  <div class="col-md-6">
    <div class="card p-3">
      <div class="row">
        <div class="col-md-11">
          <p class="text-grey10 m-0 font-body font-15-12">Plan</p>
          <h4>Compulsory Personal Accident (Owner-Driver)</h4>
        </div>
        <div class="col-md-1 p-4">
          <img src="../../assets/svgIcon/right-arrow.svg" alt="">
        </div>
        <div class="col-md-12 mt-3">
          <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="height: 25%;">
            <div class="progress-bar rng-back" style="width: 100%"></div>
          </div>
        </div>
        <div class="col-md-12 mt-1">
          <div class="card d-back">
            <p class="p-size " style="color: var(--main2);">Kindly renew your policy before it expires and get 80% off at checkout.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card p-3">
      <div class="row">
        <div class="col-md-11">
          <p class="text-grey10 m-0 font-body font-15-12">Plan</p>
          <h4>Compulsory Personal Accident (Owner-Driver)</h4>
        </div>
        <div class="col-md-1 p-4">
          <img src="../../assets/svgIcon/right-arrow.svg" alt="">
        </div>
        <div class="col-md-12 mt-3">
          <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="height: 25%;">
            <div class="progress-bar rng-back" style="width: 70%"></div>
          </div>
        </div>
        <div class="col-md-12 mt-1">
          <div class="card d1-back">
            <p class="p-size " style="color:green;">Complete your policies today and save more 80%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="row mt-10">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h3 class="text-grey5 mb-0">Your Policies</h3>
      <p class="m-0"><span class="businessTxt" (click)="RedirectPolicy()">View All</span></p>
    </div>
  </div>
    <div class="row">
      <!-- <h1>No Incomplete Policies</h1> -->
      <div class="col-md-6" Para-plan *ngFor="let policyDetails of policyDetailsList">
        <div class="card-item card mt-4 ">
          <div class="row mt-5 m-0">
            <div class="col-12">
              <div class="row mb-2">
                <div class="col-1">
                  <div class="img_div">
                    <img src="../../assets/images/banner/bike.png" />
                  </div>
                </div>
                <div class="col-11">
                  <p class="text-grey10 m-0 font-body font-15-12">Plan Type</p>
                  <span class="mb-1">
                    Compulsory Personal Accident (Owner-Driver)
                  </span>
                </div>
              </div>
              <div class="row mt-3 policy_div">
                <div class="col-4">
                  <p class="text-grey10 m-0 font-body font-15-12">Insurance Company</p>
                  <img class="partImg" src="/assets/images/insurance_companies/{{policyDetails.insuranceCompany}}.png">
                </div>     
                <div class="col">
                  <p class="text-grey4 m-0 font-15-12">Status</p>
                  <span class="text-dark mb-0 status text-main-secondary font-15-12" *ngIf="policyDetails.policyValue.policyStatus === 1">Active</span>
                  <span class="text-dark mb-0 status text-main-secondary font-15-12" *ngIf="policyDetails.policyValue.policyStatus != 1">Inactive</span>
                </div>    
                <div class="col">
                  <p class="text-grey4 m-0 font-15-12">Sum Insured</p>
                  <p class="fw-bolder text-dark mb-0 status text-main-secondary font-15-12">0</p>
                </div>
                <div class="col">
                  <p class="text-grey4 m-0 font-15-12">Valid Till</p>
                  <p class="text-dark mb-0 status text-main-secondary font-15-12">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
                </div> 
                <!-- <div class="col m-auto">
                  <span class="fw-bolder font-17-13">{{policyDetails.policyValue.insuranceCompany}}</span>
                </div>  -->
                <!-- <div class="col-1 d-flex justify-content-center align-items-center"><i class="bi bi-chevron-right rightArrIcon"></i></div> -->
              </div>
            </div>
          </div>
          <!-- <div class="col-12">
            <div class="row mb-4 mt-1">
              
              
            </div>
          </div>
          <div class="sub-card">
            <div class="col-12">
              <div class="row"> 
                
                
              </div>
            </div>
          </div> -->
        </div>
    </div>
    
  </div>
<div class="row mt-10">
  <div class="col-md-12">
   <h3 class="text-grey5 mb-0">Buy New Insurance</h3>
  </div>
  <div class="col-md-2 mt-2 ins-logo">
    <img src="../../assets/svgIcon/bike1.svg" class="img-brd" alt="">
  </div>
  <div class="col-md-2 mt-2 ins-logo">
    <img src="../../assets/svgIcon/carrr1.svg" class="img-brd" alt="">
  </div>
  <div class="col-md-2 mt-2 ins-logo">
    <img src="../../assets/svgIcon/health1.svg" class="img-brd" alt="">
  </div>
 
  <div class="col-md-2 mt-2 ins-logo">
    <img src="../../assets/svgIcon/policyBtn.svg" class="img-brd" alt="">
  </div>
  <div class="col-md-2 mt-2 ins-logo">
    <img src="../../assets/svgIcon/policyBtn (1).svg" class="img-brd" alt="">
  </div>
  <div class="col-md-2 mt-2 ins-logo">
    <img src="../../assets/svgIcon/policyBtn (2).svg" class="img-brd" alt="">
  </div>
</div>
  <!-- <div class="row mt-10">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h3 class="text-grey5 mb-0">Buy New Insurance</h3>
    </div>
  </div>
  <div class="row mt-5 ">
    <div class="col-12">
      <div class="row m-0 border justify-content-around insurance-buy-new">
    <div class="col-4 col-lg-1 col-md-2 " (click)="insurance('bike')">
      <div class="row new-inc-card-container">
        <div class="col-12 newInsCard bg-grey8">
          <img class="insIcon" src="../../assets/svgIcon/bike-new.svg">
          <p class="insTxt justify-content-around">Bike</p>
        </div>
      </div>
    </div>
    <div class="col-4 col-lg-1 col-md-2 " (click)="insurance('car')">
      <div class="row new-inc-card-container">
        <div class="col-12 newInsCard bg-grey8">
          <img class="insIcon" src="../../assets/svgIcon/car-new.svg">
          <p class="insTxt">Car</p>
        </div>
      </div>
    </div>
    <div class="col-4 col-lg-1 col-md-2 " (click)="insurance('health')">
      <div class="row new-inc-card-container">
        <div class="col-12 newInsCard bg-grey8">
          <img class="insIcon" src="../../assets/svgIcon/health-new.svg">
          <p class="insTxt">Health</p>
        </div>
      </div>
    </div>
    <div class="col-4 col-lg-1 col-md-2 " (click)="insurance('home')">
      <div class="row new-inc-card-container">
        <div class="col-12 newInsCard bg-grey8">
          <img class="insIcon" src="../../assets/svgIcon/home-new.svg">
          <p class="insTxt">Home</p>
        </div>
      </div>
    </div>
    <div class="col-4 col-lg-1 col-md-2 " (click)="insurance('life')">
      <div class="row new-inc-card-container">
        <div class="col-12 newInsCard bg-grey8">
          <img class="insIcon" src="../../assets/svgIcon/life-new.svg">
          <p class="insTxt">Life</p>
        </div>
      </div>
    </div>
    <div class="col-4 col-lg-1 col-md-2 " (click)="insurance('term')">
      <div class="row new-inc-card-container">
        <div class="col-12 newInsCard bg-grey8">
          <img class="insIcon" src="../../assets/svgIcon/term-new.svg">
          <p class="insTxt">Term</p>
        </div>
      </div>
    </div>
  </div>
</div>  
</div>
 -->
  <!-- <div class="row mt-10">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h3 class="text-grey5 mb-0">Your Assets</h3>
      <p class="m-0"><span class="businessTxt">View All</span></p>
    </div>
  </div>
    <div class="row">
      <div class="col-md-6 mt-5" Para-plan *ngFor="let item of vehiclesDetails">
        <div class="row m-0 insurancePlanChildDiv1">
          <div class="col-12">
          <div class="row">
            <div class="col d-flex align-items-center gap_20_8">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" class="icon_width_height_50_40" viewBox="0 0 50 50" fill="none">
                <circle opacity="0.1" cx="25" cy="25" r="25" fill="var(--main)" />
                <symbol id="icon" viewBox="0 0 30 30">
                  <g clip-path="url(#clip0_1956_71080)">
                    <path
                      d="M4.6875 22.5C6.75857 22.5 8.4375 20.8211 8.4375 18.75C8.4375 16.6789 6.75857 15 4.6875 15C2.61643 15 0.9375 16.6789 0.9375 18.75C0.9375 20.8211 2.61643 22.5 4.6875 22.5Z"
                      stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M25.3125 22.5C27.3836 22.5 29.0625 20.8211 29.0625 18.75C29.0625 16.6789 27.3836 15 25.3125 15C23.2414 15 21.5625 16.6789 21.5625 18.75C21.5625 20.8211 23.2414 22.5 25.3125 22.5Z"
                      stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.875 6.5625H20.625L25.3125 18.75" stroke="var(--main)" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M1.875 9.375C1.875 9.375 7.41914 11.5242 10.3781 12.8684C10.8554 13.0878 11.3845 13.1691 11.9056 13.1033C12.4267 13.0374 12.9189 12.8269 13.3266 12.4957C14.2547 11.7293 15.75 11.25 17.8125 11.25H25.3125C23.6754 11.2495 22.0831 11.7847 20.7786 12.7739C19.4741 13.7631 18.5291 15.152 18.0879 16.7285C17.92 17.313 17.5659 17.8266 17.0794 18.1915C16.5929 18.5564 16.0007 18.7525 15.3926 18.75H4.6875"
                      stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1956_71080">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </symbol>

                <use xlink:href="#icon" x="10" y="10" width="30" height="30" />
              </svg>
              <div class="row">
                <div class="col-12"><p class="font-body mb-0 font-15-12">{{item.make}} {{item.model}}</p></div>
                <span class="font-17-13">{{item.registrationNumber}}</span>
              </div>                            
            </div>
            <div class="col-3 col-md-3 col-lg-2 justify-content-between d-flex">
              <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_30_24">
              <img src="../../assets/svgIcon/Trash.svg" alt="Remove" class="icon_width_height_30_24">
            </div>
          </div>
        </div>
          <div class="row m-0 p-0 mt-3">
            <div class="col-4">
              <p class="font-body m-0 font-15-12">Reg. Year</p>
              <span class="font-15-12 fw-bolder m-0">{{item.registrationYear }}</span>
            </div>
            <div class="col-4">
              <p class="font-body m-0 font-15-12">Fuel Type</p>
              <span class="font-15-12 fw-bolder m-0">Petrol</span>
            </div>
            <div class="col-4">
              <p class="font-body m-0 font-15-12">Policy Expiry Date</p>
              <span class="font-15-12 fw-bolder m-0">{{item.policyExpiryDate | date: 'dd/MM/yyyy'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  

  <!-- <div class="row mt-10">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <h3 class="text-grey5 mb-0">Your Addresses</h3>
      <p class="m-0"><span class="businessTxt">View All</span></p>
    </div>
  </div> -->
    <!-- <div class="row">
      <div class="col-md-6 mt-5" *ngFor="let item of AddressDetailsList">
        <div class="row m-0 insurancePlanChildDiv1">
          <div class="col-12">
          <div class="row">
            <div class="col d-flex align-items-center gap_20_8">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" class="icon_width_height_50_40" viewBox="0 0 50 50" fill="none">
                <circle opacity="0.1" cx="25" cy="25" r="25" fill="var(--main)" />
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none" x="15"
                  y="15">
                  <path
                    d="M17.8125 24.3754V18.7504C17.8125 18.5018 17.7137 18.2633 17.5379 18.0875C17.3621 17.9117 17.1236 17.8129 16.875 17.8129H13.125C12.8764 17.8129 12.6379 17.9117 12.4621 18.0875C12.2863 18.2633 12.1875 18.5018 12.1875 18.7504V24.3754C12.1875 24.6241 12.0887 24.8625 11.9129 25.0384C11.7371 25.2142 11.4986 25.3129 11.25 25.3129H5.625C5.37636 25.3129 5.1379 25.2142 4.96209 25.0384C4.78627 24.8625 4.6875 24.6241 4.6875 24.3754V13.5403C4.68751 13.4096 4.71483 13.2804 4.76771 13.161C4.82058 13.0415 4.89784 12.9344 4.99453 12.8465L14.3695 3.99419C14.5421 3.83707 14.7672 3.75 15.0006 3.75C15.234 3.75 15.459 3.83707 15.6316 3.99419L25.0066 12.8465C25.1033 12.9344 25.1806 13.0415 25.2335 13.161C25.2863 13.2804 25.3137 13.4096 25.3137 13.5403V24.3754C25.3137 24.6241 25.2149 24.8625 25.0391 25.0384C24.8633 25.2142 24.6248 25.3129 24.3762 25.3129H18.75C18.5014 25.3129 18.2629 25.2142 18.0871 25.0384C17.9113 24.8625 17.8125 24.6241 17.8125 24.3754Z"
                    stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </svg>
              <div class="row">
                <div class="col-12">
                  <p class="font-body m-0 font-15-12">Home</p>
                </div>
                <span class="font-17-13 fw-bolder m-0">{{item.streetAddress}}</span>
              </div>
            </div>
            <div class="col-3 col-md-3 col-lg-2 justify-content-between d-flex ">
              <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="" class="icon_width_height_30_24">
              <img src="../../assets/svgIcon/Trash.svg" alt="" class="icon_width_height_30_24">
            </div>
          </div>
        </div>
          <div class="row m-0 p-0 mt-3">
            <div class="col-12 col-md-10">
              <p class="font-body m-0 font-15-12">Flat, House no.</p>
              <span class="font-17-13 fw-bolder m-0">{{item.streetAddress}} {{item.city}} {{item.district}}
                {{item.state}} {{item.pincode}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
 

  <!-- <div class="row m-0 p-0 mt-20">
    <div class="row m-0 p-0" style="border-bottom: 1px solid #ababcb;">
      <div class="col-8 p-0">
        <h6 class="text-grey5 fw-medium">
          Home | About US | Careers | Legal & Admin Policies | Insurance Companies |
          Security | Contact Us
        </h6>
      </div>
      <div class="col-4" style="text-align: end;">
        <p class="text-grey5" style="display: -webkit-inline-box; margin-right: 10px;">
          Handcrafted & Proudly Made In
        </p>
        <img src="../../assets/images/india-flag.svg">
      </div>
    </div>
    <div class="row m-0 p-0 mt-6" style="text-align: center;">
      <p class="text-grey5">© Copyright 2023 Click4bima. ALL RIGHTS RESERVED</p>
    </div>
  </div> -->
</div>
<!-- <app-footer></app-footer> -->