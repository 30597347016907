import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NavbarComponent } from '../navbar/navbar.component';
import { UserService } from '../Services/user.service';
import { SitemapComponent } from '../sitemap/sitemap.component';
import { GeneralService } from '../Services/general.service';
//import { LoginComponent } from '../Login/login.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isSmallScreen: boolean = false;  
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); }
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }
  isMobile: boolean = true;
  isOtp: boolean = false;
  healthForm1: boolean = true;
  healthForm2: boolean = true;
  isToggle: boolean = true;
  selectedTab : number = 1;
  showMenu:boolean = false;
  DisplayInsurance:boolean = false;
  langChange:boolean = false;
  currentTab:string="";
  isUserLogged:boolean = false;
  fullname:string=""
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  isLinear = false;
  modalRef: BsModalRef;
  @ViewChild(NavbarComponent) navbarComponent: NavbarComponent;
  constructor(private _formBuilder: FormBuilder, private modalService: BsModalService,private userservice: UserService, private generalService : GeneralService) { this.checkScreenSize(); }

  ngOnInit(): void {
  }

  async getUserDetails() {
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
      let request = { uniqueUserId: this.generalService.getUniqueuserid()}
      const response = await this.userservice.AcquireUserInfo(request);
      if (response?.isSuccess) {
        this.fullname = `${response.result.firstName} ${response.result.lastName}`;
        this.generalService.setUserDisplayName(this.fullname);    
      }
    }
  }

  languageChanged(){
   location.reload();
  }
  healthToggle() {
    this.healthForm1 = !this.healthForm1;
    this.healthForm2 = !this.healthForm2;
  }
  
  clicked(data:any){
    this.currentTab =data;
    this.navbarComponent.goTo(data,true);
    this.DisplayInsurance = true;

  }
  openLoginModal() {
    //this.modalRef = this.modalService.show(LoginComponent);
  }

  display = "none";
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }

  
}
