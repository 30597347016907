import { Component } from '@angular/core';

@Component({
  selector: 'app-two-wheeler-insurance',
  templateUrl: './two-wheeler-insurance.component.html',
  styleUrls: ['./two-wheeler-insurance.component.css']
})
export class TwoWheelerInsuranceComponent {

}
