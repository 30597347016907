<div id="kt_signin_password_edit" class="flex-row-fluid" >
    <form id="kt_signin_change_password" class="form" [formGroup]="passwordForm"
      novalidate="novalidate">
      <div class="row m-0">
        <div class="col-12 card pt-4 pb-4">
          <div class="row">
            <div class="col-lg-4">
              <div class="fv-row mb-0">
                <label for="currentpassword" class="form-label required">Current
                  Password</label>
                <div class="position-relative mb-3">
                  <input [type]="CurrentpasswordType" formControlName="CurrentPassword"
                    class="form-control" name="currentpassword"
                    id="currentpassword" maxlength="40"  required="required"/>
                  <!-- <span
                    class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility">
                    <i [class]="CurrentpasswordShow === true ? 'fa fa-eye fs-4' : 'fa fa-eye-slash fs-4'"
                      (click)="ShowCurrentPassword()"></i>
                  </span> -->
                  <span class="text-danger " *ngIf="(changePassword['CurrentPassword'].touched || isSubmit) && changePassword['CurrentPassword'].errors?.['required'] ">
                    Current Password is required
                  </span>
                </div>
              </div>
            </div>
    
            <div class="col-lg-4">
              <div class="fv-row mb-0">
                <label for="newpassword" class="form-label required">New Password </label><span class="text-danger mb-3 error" *ngIf="strongPassword()"><b> ( Should be 8+ chars, with 1 uppercase, 1 lowercase, 1 number)</b></span>
                <div class="position-relative mb-3">
                  <input [type]="NewpasswordType" formControlName="NewPassword"
                    class="form-control" name="newpassword"
                    id="newpassword"  minlength="8"  />
                  <span
                    class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility">
                    <i [class]="NewpasswordShow === true ? 'fa fa-eye fs-4' : 'fa fa-eye-slash fs-4'"
                      (click)="ShowNewPassword()"></i>
                  </span>
                  <span class="text-danger " *ngIf="(changePassword['NewPassword'].touched || isSubmit) && changePassword['NewPassword'].errors?.['required'] &&  !changePassword['NewPassword'].errors?.['minlength']">
                    NewPassword is required<br>
                  </span>
                  <span class="text-danger " *ngIf="(changePassword['NewPassword'].touched || isSubmit) &&  changePassword['NewPassword'].errors?.['minlength'] &&changePassword['NewPassword'].value?.length <8">
                  Minimun 8 digits or characters are required
                  </span>
                  <!-- <span class="text-danger " *ngIf="(changePassword['NewPassword'].touched || isSubmit) &&  changePassword['NewPassword'].errors?.['pattern'] &&  !changePassword['NewPassword'].errors?.['minlength']">
                   Password Should be one Special Character and one UpperCase
                    </span> -->
<!-- 
                  <span class="text-danger " >
                    Password Should be have one Special Character and one UpperCase Character
               
                    </span> -->
                </div>
              </div>
            </div>
    
            <div class="col-lg-4">
              <div class="fv-row mb-0">
                <label for="confirmpassword" class="form-label required">Confirm New
                  Password</label>
                <div class="position-relative mb-3">
                  <input [type]="ConfirmNewpasswordType" formControlName="confirm_password"
                    class="form-control" name="confirmpassword"
                    id="confirmpassword" maxlength="40" />
                  <span
                    class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility">
                    <i [class]="ConfirmNewpasswordShow === true ? 'fa fa-eye fs-4' : 'fa fa-eye-slash fs-4'"
                      (click)="ShowConfirmNewPassword()"></i>
                  </span>
                  <span class="text-danger " *ngIf="(changePassword['confirm_password'].touched || isSubmit) && changePassword['confirm_password'].errors?.['required'] ">
                    Confirm Password is required
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-6 "></div>
            <div class="col-lg-6 text-right">
                <button class="btn btn-primary btn-custom"  [disabled]=" paswordValidation()"
                (click)="changePassWord()">Change Password</button>
              </div>
          </div>
        </div>
      </div> 
    </form>
  </div>

