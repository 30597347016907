import { Component } from '@angular/core';

@Component({
  selector: 'app-agentfooter',
  templateUrl: './agentfooter.component.html',
  styleUrls: ['./agentfooter.component.css']
})
export class AgentfooterComponent {
  currentYear :any = (new Date()).getFullYear();
}
