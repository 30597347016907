import { Component } from '@angular/core';

@Component({
  selector: 'app-group-term-insurance',
  templateUrl: './group-term-insurance.component.html',
  styleUrls: ['./group-term-insurance.component.css']
})
export class GroupTermInsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
