import { Component } from '@angular/core';
import { LanguageLableService } from 'src/app/Services/language-lable.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent {
  selectedValue: string | null;
  langList: any;
  constructor(private languageLableService: LanguageLableService) {

  }
  ngOnInit() {
    if (localStorage.getItem("selectedValue") != '') {
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else {
      this.selectedValue = "english";
    }
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
  }
  images: any[] = [
    './assets/partners/new/aditya.png',
    './assets/partners/new/digit.png',
    './assets/partners/new/tata.png',
    './assets/partners/new/future.png',
    './assets/partners/new/kotak.png',
    './assets/partners/new/united.png',
    './assets/partners/new/star.png',
    './assets/partners/new/sompo.png',
  ]
  images2: any[] = [
    './assets/partners/new/shriram.png', './assets/partners/new/sbi.png',
    './assets/partners/new/royal.png', './assets/partners/new/raheja.png',
    './assets/partners/new/oriental.png', './assets/partners/new/national.png',
    './assets/partners/new/magma.png', './assets/partners/new/reliance.png',

    './assets/partners/new/liberty.png', './assets/partners/new/icici.png',
    './assets/partners/new/india.png', './assets/partners/new/iffco.png',

    './assets/partners/new/hdfc.png', './assets/partners/new/bajaj.png',
    './assets/partners/new/chola.png', './assets/partners/new/care.png',
  ];

  showAllImages = false;

}
