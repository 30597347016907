import { Component, HostListener, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { GeneralService } from 'src/app/Services/general.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-agent-bank-details',
  templateUrl: './agent-bank-details.component.html',
  styleUrls: ['./agent-bank-details.component.css']
})
export class AgentBankDetailsComponent {
  AccSubmit:boolean=false;
  Popup: boolean = false;
  value: [''];
  ifscDetails: any = {};
  saveBankDetailForm: FormGroup;
  button: boolean = false;
  isBankUnderReview: boolean = false;
  constructor(private router: Router, private fb: FormBuilder, private spinner: NgxSpinnerService, private agentprofileService: AgentprofileService, private generalService: GeneralService) {

    this.saveBankDetailForm = this.fb.group({
      "accountNumber": ['', [Validators.required]],
      "confirmAccountNumber": ['', [Validators.required]],
      "accountHolderName": ['', [Validators.required]],
      "ifscCode": ['', [Validators.required]],
      "uploadPassbook": ['',[Validators.required]],
      "bankName": ['',[Validators.required]],
      "branchName": ['',[Validators.required]],
      "userDocument":[],
      "status":[2],
      "userId":[0]
    });
    this.check();

  }
  valid: boolean = false;
  check() {
    if (!this.saveBankDetailForm.get('accountNumber') == null && !this.saveBankDetailForm.get('confirmAccountNumber') == null && !this.saveBankDetailForm.get('accountHolderName') == null && !this.saveBankDetailForm.get('ifscCode') == null && !this.saveBankDetailForm.get('uploadPassbook') == null) {
      if (this.match) {
        this.valid = true;
      }
    }
    else {

      this.valid = true;
    }

  }

  match: boolean = false;
  matchAccount() {
    if (this?.saveBankDetailForm?.get('accountNumber')?.value != "" && this?.saveBankDetailForm?.get('confirmAccountNumber')?.value != "" && this?.saveBankDetailForm?.get('accountNumber')?.value != this?.saveBankDetailForm?.get('confirmAccountNumber')?.value) {
      // this.isPassSame = false;
      this.match = false;
    }
    else {
      // this.isPassSame = true;
      this.match = true;
    }
  }
  imageUrl: string | ArrayBuffer | null = null;

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.imageUrl = null;      
      this.saveBankDetailForm.get("uploadPassbook")?.setValue("");
      if (file!.size > 5242880) {        
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File size should not be greater than 5MB',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else if(!(file?.type?.toLocaleLowerCase().includes('jpeg') || file?.type?.toLocaleLowerCase().includes('jpg') || file?.type?.toLocaleLowerCase().includes('png'))){
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File format accepted only JPEG & PNG',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else{
        this.previewImage(file);
      }
    }
  }

  previewImage(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
      this.saveBankDetailForm.get("uploadPassbook")?.setValue(this.imageUrl);
    };

    reader.readAsDataURL(file);
  }
  GoBack() {
    this.router.navigate(['/agent-profile']);
  }

  async submitBankDetails() {
    if(this.isBankUnderReview == false){
      this.spinner.show();
      this.saveBankDetailForm.get("confirmAccountNumber")?.setValue(this.saveBankDetailForm.get("confirmAccountNumber")?.value?.toString());
      const response = await this.agentprofileService.SaveBankDetails(this.saveBankDetailForm.value);
      if(response?.isSuccess){
        this.Popup = true;
      }
      this.spinner.hide();
    }
    else
    {this.Popup = true;}

  }

  msg:string;
  async getIfsc() {
    this.spinner.show();
    const response = await this.agentprofileService.GetIFSCInfo(this.saveBankDetailForm.get('ifscCode')?.value);
    if (response?.isSuccess) {
      this.ifscDetails = response.result;

      this.saveBankDetailForm.get('bankName')?.setValue(this.ifscDetails.bankName)
      this.saveBankDetailForm.get('branchName')?.setValue(this.ifscDetails.branchName)
      if(this.ifscDetails.bankName!=null || this.ifscDetails.branchName !=null )
        {
         this.button=true;
         this.msg="IFSC code is verified";
        }
        else{
          this.msg="Please enter valid IFSC code";
        }
    }
    this.spinner.hide();
  }



  close() {
    this.Popup = false;
  }
  exit() {
    location.reload();
  }
  back(value: string) {
    location.reload();
    localStorage.setItem("bank", value);
  }
  get BankDetails()
  {
    return this.saveBankDetailForm.controls;
  }
  validBankDetails():boolean{
    return (this.BankDetails['accountNumber'].value!=this.BankDetails['confirmAccountNumber'].value || this.saveBankDetailForm.invalid || this.saveBankDetailForm.get('ifscCode')?.value?.length < 11  );
  }

  onInput(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
         // Allow: Ctrl/cmd+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+C
        (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+X
        (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
         // let it happen, don't do anything
         return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
    }
  }  

  checkIFSC(){
    if(this.saveBankDetailForm.get('ifscCode')?.value?.length<11)
      {
        this.button=false;
      }
  }

  alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
  }
  ngOnInit(): void {
    this.getBankDetails();
  }

  async getBankDetails(){
    const response = await this.agentprofileService.GetBankDetailsByUserId(this.generalService.getUniqueuserid());
    if (response?.isSuccess && response?.result) {
      this.saveBankDetailForm.patchValue(response.result);
      this.isBankUnderReview = this.saveBankDetailForm.value.status == 2 || this.saveBankDetailForm.value.status == 1;
      this.button=true;
      this.imageUrl = this.saveBankDetailForm.value.uploadPassbook;
    }
  }

}

