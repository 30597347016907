import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/Services/general.service';

@Component({
  selector: 'app-kyc-response',
  templateUrl: './kyc-response.component.html',
  styleUrls: ['./kyc-response.component.css']
})
export class KycResponseComponent {
  isUserLogged: boolean = true;
  kycStatus: string = "false";

  constructor(private route: ActivatedRoute,private generalService : GeneralService){

  }

  ngOnInit() {
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
    }
    this.route.queryParamMap.subscribe((params:any) => {
      debugger;
      this.kycStatus = params?.params['kycStatus'];
    });
  }
}


