<div class="agent-dashboard">
    <div class="row">
        <div class="col-2 bg-white">
            <app-sidebarmenu></app-sidebarmenu>
        </div>
        <div class="col-10 dash-box">
            <app-agentnavbar></app-agentnavbar>
            <div class="container mb-5">
                <div class="row bg-image" *ngIf="!showImage">
                    
                    <a href="agent-training"><button type="button" class="btn btn-back">Back</button></a>

               </div>
               <div class="row yt-video" *ngIf="showImage">
                   <iframe src="https://www.youtube.com/embed/J3xnjaOStAk" height="450" width="300" title="Iframe Example"></iframe>
              </div>
                <div class="row">
                    <div class="col-9">
                        <p class="font-body">
                            Unlock your potential in the world of insurance with our comprehensive POSP LI course. Learn the ins and outs of insurance products, sales techniques, and regulatory guidelines, all in one convenient program. Empower yourself to confidently assist clients in securing the coverage they need, and start your journey to becoming a successful insurance professional today.
                        </p>
                        <h4>Table Of content</h4>
                        <table>
                            <tr>
                              <th>1. Title Name</th>
                            </tr>
                            <tr>
                              <td>
                                <div class="row">
                                    <div class="col-1 text-center">
                                        <img class="" src="../../assets/svgIcon/agent/actionCheck.svg" clialt="logo" width="25">
                                    </div>
                                    <div class="col-8">
                                        Topic Description here
                                    </div>
                                    <div class="col-3 text-end">
                                        MM:SS
                                    </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionPlay.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionPlay.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionLock.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionLock.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table class="mt-5">
                            <tr>
                              <th>2. Title Name</th>
                            </tr>
                            <tr>
                              <td>
                                <div class="row">
                                    <div class="col-1 text-center">
                                        <img class="" src="../../assets/svgIcon/agent/actionCheck.svg" clialt="logo" width="25">
                                    </div>
                                    <div class="col-8">
                                        Topic Description here
                                    </div>
                                    <div class="col-3 text-end">
                                        MM:SS
                                    </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionPlay.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionPlay.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionLock.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="row">
                                        <div class="col-1 text-center">
                                            <img class="" src="../../assets/svgIcon/agent/actionLock.svg" clialt="logo" width="25">
                                        </div>
                                        <div class="col-8">
                                            Topic Description here
                                        </div>
                                        <div class="col-3 text-end">
                                            MM:SS
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-3 box bg-white">
                        <img src="../../../assets/svgIcon/agent/trainingsession.svg" class="icon" alt="" height="155">
                        <div class="mt-5">
                            <h5>
                                Course Progress
                            </h5>
                            <hr>
                            <h5>Details</h5>
                            <hr>
                            <p class="font-body">
                                Insurance :
                            </p>
                            <p class="font-body">
                                Time HH/MM :
                            </p>
                            <p class="font-body">
                                No. of lessons :
                            </p>
                            <button type="button" class="btn btn-primary" (click)="startTraining()">Start Training</button>
                            <!-- <button type="button" class="btn btn-agentsign" (click)="startTraining()">Share Your Feedback</button> -->
                            <!-- <a href="agent-training"><button type="button" class="btn btn-agentlog">Go Back</button></a> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <app-agentfooter></app-agentfooter>  
            </div>
        </div>
    </div>
</div>