import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VehicleService } from '../Services/vehicle.service';
import swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'managevehicle',
  templateUrl: './managevehicle.component.html',
  styleUrls: ['./managevehicle.component.css']
})
export class ManagevehicleComponent implements OnInit {

  vehicleInfoForm: FormGroup;
  vehicleCompanyList: any = [];
  title: string = 'Add new vehicle';
  vehicleVariantList: any = [];
  vehicleModelList: any = [];
  registrationYear: any = [];
  year = new Date().getFullYear();
  IsProfile: boolean = false;
  isEdit:boolean = false;
  @ViewChild('closebtn', { static: true }) closeButtonRef: ElementRef;
  @Output() dataEvent = new EventEmitter();
  constructor(private fb: FormBuilder, private vehicleService: VehicleService,
    private activatedRoute: ActivatedRoute,private generalService : GeneralService) {
    this.vehicleInfoForm = this.fb.group({
      "UniqueUserId": this.generalService.getUniqueuserid(),
      "registrationNumber": [''],
      "vehicleType": [2],
      "model": ['', [Validators.required]],
      "make": ['', [Validators.required]],
      "registrationYear": [''],
      "variant": ['', [Validators.required]],
      "fuelType": [1],
      "policyExpiryDate": ['', [Validators.required]],
      "vehicleId": []
    });

    this.getVehicleCompanyDetails(2);

    this.activatedRoute.url.subscribe(urlSegments => {
      this.IsProfile = urlSegments[0].path.toLowerCase() === 'profile' ? true : false;
    });
  }

  ngOnInit() {
    this.getFinancialYears();
  }

  async getVehicleCompanyDetails(type: number) {

    this.vehicleCompanyList = [];
    let request = { Type: type };
    const res = await this.vehicleService.GetVehicleCompanyDetails(request);
    if (res != null && res.result) {
      this.vehicleCompanyList = res.result;
    }
  }

  async getVehicleModelDetails(vehicleCompanyId: any) {
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    let request = { CompanyName: vehicleCompanyId.toString().trim() };
    const res = await this.vehicleService.GetVehicleModelDetails(request);
    if (res != null && res.result) {
      this.vehicleModelList = res.result;
    }
  }


  async getVehicleVariantDetails(vehicleModelId: any) {
    this.vehicleVariantList = [];
    let request = { ModelName: vehicleModelId.toString().trim() };
    const res = await this.vehicleService.GetVehicleVariantDetails(request);
    if (res != null && res.result) {
      this.vehicleVariantList = res.result;
    }
  }

  async getFinancialYears() {
    this.registrationYear = [];
    for (var i = (this.year - 25); i < (this.year + 1); i++) {
      this.registrationYear.push(i).orderb
    }
    if (this.registrationYear)
      this.registrationYear.sort(function (a: any, b: any) { return b - a })
  }

  async insertVehicleInfo() {
    try {
      const res = await this.vehicleService.InsertVehicleDetails(this.vehicleInfoForm.value);
      if (res.isSuccess) {
        this.dataEvent.emit();
        swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Vehicle '+ this.isEdit ? 'updated' : 'added'+' successfully',
          showConfirmButton: false,
          timer: 3000,
        });
        this.closeButtonRef.nativeElement.click();
        this.vehicleInfoForm.reset();
      }
    } catch (e) {
      console.log(e);
    }
  }

  EditVehicle(item: any) {
    this.isEdit = true;
    this.title = "Update Vehicle";
    this.getVehicleModelDetails(item.make);
    this.getVehicleVariantDetails(item.model);
    this.getVehicleCompanyDetails(item.vehicleType);
    this.vehicleInfoForm.patchValue(item);
    this.vehicleInfoForm.get('vehicleType')?.setValue(item.vehicleType);
    this.vehicleInfoForm.get('policyExpiryDate')?.setValue(item.policyExpiryDate.split("T")[0]);
  }

  UpdateUniqueUserId(uniqueUserId: string) {
    this.isEdit = false;
    this.vehicleInfoForm.get('registrationNumber')?.setValue("");
    this.vehicleInfoForm.get('vehicleType')?.setValue(2);
    this.vehicleInfoForm.get('model')?.setValue("");
    this.vehicleInfoForm.get('make')?.setValue("");
    this.vehicleInfoForm.get('registrationYear')?.setValue("");
    this.vehicleInfoForm.get('variant')?.setValue("");
    this.vehicleInfoForm.get('fuelType')?.setValue("");
    this.vehicleInfoForm.get('policyExpiryDate')?.setValue("");
    this.vehicleInfoForm.get('vehicleId')?.setValue("");
    this.vehicleInfoForm.get('UniqueUserId')?.setValue(uniqueUserId);
  }
}
