import { Component } from '@angular/core';

@Component({
  selector: 'app-bike-insurance-quote',
  templateUrl: './bike-insurance-quote.component.html',
  styleUrls: ['./bike-insurance-quote.component.css']
})
export class BikeInsuranceQuoteComponent {

}
