<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Terms & Condition</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <div class="title-line">
                    <h4>ACCEPTANCE OF THE TERMS</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        By accessing the Web Site, I acknowledge and accept these Terms and Conditions of use without limitation or qualification. I acknowledge and agree that I have read and understood the following terms of use. I agree, understand and accept that these Terms and Conditions may be revised at any time by updating this posting. I undertake that I will be bound by any such revisions and therefore undertake to periodically visit this page to review the then current Terms and Conditions to which I am bound.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>LIMITATION OF LIABILITY</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        While SRG Insurance Brokers Private Limited makes no warranties or representations as to the accuracy of the contents of this website, under no circumstances, including but not limited to negligence shall SRG Insurance Brokers Private Ltd. (“SRGIBPL”), its employees and directors, it's suppliers and or any party involved in creating, producing, or delivering the website be liable to you for any direct, incidental, consequential, indirect, or punitive damages that result from the use of or the inability to use the materials on this website, in no event shall SRG Insurance Brokers Private Limited's total liability for all damages, losses, and causes of action will not exceed the amount paid by you, if any for using/accessing this website. SRG Insurance Brokers Private Limited also assumes no responsibility and shall not be liable for, any damages to or viruses that may infect, your computer/equipment/handset/any other mode of accessing this website or other property as a result of your access to, use of, or browsing in the website or your downloading of any materials, data, text, images, video, or audio from this website. SRG Insurance Brokers Private Limited makes no guarantees to you or third parties that content contained on the Website would be virus free.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>RESTRICTIONS ON USE OF MATERIALS</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        This website is operated by SRG Insurance Brokers Private Limited except as otherwise expressly permitted. You may download materials displayed on this website for your use only. You may not distribute, modify, transmit, reuse, repost, or use the content of this website for public or commercial purposes, including the text, images, audio, and video without SRG Insurance Brokers Private Limited written permission except for your personal use. SRG Insurance Brokers Private Limited neither warrants nor represents that your use of materials displayed on this website will not infringe rights of third parties not owned by or affiliated with SRG Insurance Brokers Private Limited.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>FOREIGN JURISDICTION</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        SRG Insurance Brokers Private Limited accepts no liability whatsoever, direct or indirect for non-compliance with laws of any country other than that of India. The mere fact that the Website can be accessed by a visitor or a customer in a country other than India would not mean that the laws of the said country would govern these Terms.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>LINKS AND REFERENCE WEBSITES</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        <b>SRG Insurance Brokers Private Limited </b>for your convenience provides certain hyperlinks or reference websites which will forward/redirect you to third parties' websites.
                        Links to third-party websites do not imply any approval or endorsement by the Company of the linked sites, contents therein or authenticity thereof even if they may contain the Company's logo, as such sites are beyond the Company's control.
                        You acknowledge that framing the Company's website or any similar process is prohibited. We do not warrant the adequacy, accuracy, reliability or completeness of any information on such websites and expressly disclaim any liability for any and all of their contents.
                        By accepting the terms of use and accessing SRG Insurance Brokers Private Limited website, you also agree to abide by the proprietary guidelines set forth at any website accessed or hyperlinked to through the SRG Insurance Brokers Private Limited.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>JURISDICTION, SEVERABILITY</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Any action arising out of these terms or this website shall be litigated in, and only in, courts located in Mumbai, India, and you agree to submit to the exclusive jurisdiction of those courts and further agree that they are a convenient forum for you.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>DISPUTE RESOLUTION</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        In case of any dispute or difference arising between the parties out of or in relation to these terms and conditions, both parties hereby agree to settle the said dispute or difference amicably among themselves in the first instance. If the said dispute or difference is not settled amicably between the parties within 30 days from the date on which the said dispute or difference first arose, the same shall be referred to Arbitration, comprising of sole arbitrator appointed by SRG Insurance Brokers Private Limited. The Arbitration Tribunal shall be governed by the provisions of the Arbitration and Conciliation Act, 1996 and the award passed by such Tribunal, including an interim award, shall be final and binding on the parties. The venue for the arbitration shall be at Udaipur and the proceedings shall be conducted in the English language. The award shall be final and binding and the Parties hereby waive all means of recourse to the courts of any country. In the event that any provision of these terms is held unenforceable, the validity or enforceability of the remaining provisions will not be affected, and the unenforceable provision will be replaced with an enforceable provision that comes close to the intention underlying the unenforceable provision.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Description of Services</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        We provides the access to information primarily about financial & Insurance products services including but not restricted to General Insurance, Life Insurance products or related services to fulfill the requirement. We does not claim ownership of any materials you make available through the Website with respect to such materials you submit or make available for inclusion on the Website, you grant. If you do not agree to these terms and conditions, please do not provide us with any submitted content. You are prohibited from posting or transmitting to or from this Website:
                    </p>
                    <ul>
                        <li>any unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material or content that would violate rights of publicity and/or privacy or that would violate any law.</li>
                        <li>Any commercial material or content (including, but not limited to, solicitation of funds, advertising, or marketing of any good or services).</li>
                        <li>Any material or content that violates any copyright, trademark, patent right or other proprietary right of any third party. You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from your posting of content to this Website.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Refund & Cancellation</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        As per the IRDAI rules and regulations, you have the right to cancel your insurance policy within 15(fifteen) days from the date of (online) receipt of insurance policy (referred to as “Free look period”) and refund of your premium amount shall be processed as per the applicable process and procedures followed by the insurer. This Free look facility can be availed only for Life and Health insurance policies, subject to certain other terms and conditions specified by IRDAI. We encourage all our customers to read the insurance policy document carefully and avail the free look facility in case the terms and conditions of the insurance policy document don't match your insurance requirements. Further, you understand that once you place a cancellation request within the Free look period, the policy gets cancelled and entire premium is refunded to you subject to deduction of
                    </p>
                    <ul>
                        <li>charges pertaining to medical tests conducted (ii) administrative and service cost like stamp duty etc.</li>
                        <li>Charges for mortality for the period the policy was in force. Please note such deduction is at the sole discretion of the insurer.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Due Diligence requirements</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        You agree and acknowledge that for undertaking any financial transaction through the website, Our Company may undertake client/customer due diligence measurers and seek mandatory information required for KYC purpose which as a customer you are obliged to give, while facilitating your request of insurance requirements with the insurance company, in accordance with applicable PMLA laws and rules. The Service is not available to minors under the age of 18 or to any users suspended or removed from the system by We for any reason.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Disclaimer</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Nothing on any We shall be construed as conferring any license under any of us or any third party's intellectual property rights, whether by estoppels, implication, or otherwise.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Electronic Communication</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        When you use the Website or send emails to We, you are communicating with electronically. You consent to receive communications from us electronically. The Website may provide users with the ability to send email messages to other users and non-users and to post messages on the Website. We is under no obligation to review any messages, information or content ("Postings") posted on the Website by users and assumes no responsibility or liability relating to any such Postings.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Website Links</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        The Website or third parties may provide links to other World Wide Web Websites or resources, since we has no control over such websites and resources. Further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such Website or resource. Our website allows payments through debit cards, credit cards and net banking. The payment you make is directly credited to the insurance company's account. All transactions made on our website are secure and use the best payment gateway. For any more details related to the payment and payment protection, you may contact us on our phone number or email.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>ERRORS AND QUESTIONS</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        In case of errors or questions about your transactions via this website, you should notify us as soon as possible via one of the following: Write us: Corporate Office
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>SRG Insurance Brokers (P) Ltd.</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        2 & 5, Vinayak Complex'B', Durga Nursery Road, Udaipur, UDAIPUR, Rajasthan - 313001 Email.info@srggroup.net
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- <div class="container mt-15">
        <div class="content-box">
            <div class="card-container container-fluid d-flex">
                <div class="card card-item shadow">
                <h2 style="text-decoration: underline;">Terms & Condition</h2>
                <h3>ACCEPTANCE OF THE TERMS</h3>
                <p>By accessing the Web Site, I acknowledge and accept these Terms and Conditions of use without limitation or qualification. I acknowledge and agree that I have read and understood the following terms of use. I agree, understand and accept that these Terms and Conditions may be revised at any time by updating this posting. I undertake that I will be bound by any such revisions and therefore undertake to periodically visit this page to review the then current Terms and Conditions to which I am bound.</p>
                <h3>LIMITATION OF LIABILITY</h3>
                <p>While SRG Insurance Brokers Private Limited makes no warranties or representations as to the accuracy of the contents of this website, under no circumstances, including but not limited to negligence shall SRG Insurance Brokers Private Ltd. (“SRGIBPL”), its employees and directors, it's suppliers and or any party involved in creating, producing, or delivering the website be liable to you for any direct, incidental, consequential, indirect, or punitive damages that result from the use of or the inability to use the materials on this website, in no event shall SRG Insurance Brokers Private Limited's total liability for all damages, losses, and causes of action will not exceed the amount paid by you, if any for using/accessing this website.
                SRG Insurance Brokers Private Limited also assumes no responsibility and shall not be liable for, any damages to or viruses that may infect, your computer/equipment/handset/any other mode of accessing this website or other property as a result of your access to, use of, or browsing in the website or your downloading of any materials, data, text, images, video, or audio from this website. SRG Insurance Brokers Private Limited makes no guarantees to you or third parties that content contained on the Website would be virus free.</p>
                <h3>RESTRICTIONS ON USE OF MATERIALS</h3>
                <p>This website is operated by SRG Insurance Brokers Private Limited except as otherwise expressly permitted. You may download materials displayed on this website for your use only. You may not distribute, modify, transmit, reuse, repost, or use the content of this website for public or commercial purposes, including the text, images, audio, and video without SRG Insurance Brokers Private Limited written permission except for your personal use.
                SRG Insurance Brokers Private Limited neither warrants nor represents that your use of materials displayed on this website will not infringe rights of third parties not owned by or affiliated with SRG Insurance Brokers Private Limited.</p>
                <h3>FOREIGN JURISDICTION</h3>
                <p>SRG Insurance Brokers Private Limited accepts no liability whatsoever, direct or indirect for non-compliance with laws of any country other than that of India. The mere fact that the Website can be accessed by a visitor or a customer in a country other than India would not mean that the laws of the said country would govern these Terms.</p>
                <h3>LINKS AND REFERENCE WEBSITES</h3>
                <p><b>SRG Insurance Brokers Private Limited </b>for your convenience provides certain hyperlinks or reference websites which will forward/redirect you to third parties' websites.
                Links to third-party websites do not imply any approval or endorsement by the Company of the linked sites, contents therein or authenticity thereof even if they may contain the Company's logo, as such sites are beyond the Company's control.
                You acknowledge that framing the Company's website or any similar process is prohibited. We do not warrant the adequacy, accuracy, reliability or completeness of any information on such websites and expressly disclaim any liability for any and all of their contents.
                By accepting the terms of use and accessing SRG Insurance Brokers Private Limited website, you also agree to abide by the proprietary guidelines set forth at any website accessed or hyperlinked to through the SRG Insurance Brokers Private Limited.</p>
                <h3>JURISDICTION, SEVERABILITY</h3>
                <p>Any action arising out of these terms or this website shall be litigated in, and only in, courts located in Mumbai, India, and you agree to submit to the exclusive jurisdiction of those courts and further agree that they are a convenient forum for you.</p>
                <h3>DISPUTE RESOLUTION</h3>
                <p>In case of any dispute or difference arising between the parties out of or in relation to these terms and conditions, both parties hereby agree to settle the said dispute or difference amicably among themselves in the first instance. If the said dispute or difference is not settled amicably between the parties within 30 days from the date on which the said dispute or difference first arose, the same shall be referred to Arbitration, comprising of sole arbitrator appointed by SRG Insurance Brokers Private Limited. The Arbitration Tribunal shall be governed by the provisions of the Arbitration and Conciliation Act, 1996 and the award passed by such Tribunal, including an interim award, shall be final and binding on the parties. The venue for the arbitration shall be at Udaipur and the proceedings shall be conducted in the English language. The award shall be final and binding and the Parties hereby waive all means of recourse to the courts of any country.
                In the event that any provision of these terms is held unenforceable, the validity or enforceability of the remaining provisions will not be affected, and the unenforceable provision will be replaced with an enforceable provision that comes close to the intention underlying the unenforceable provision.</p>
                <h3>Description of Services</h3>
                <p>We provides the access to information primarily about financial & Insurance products services including but not restricted to General Insurance, Life Insurance products or related services to fulfill the requirement.
                We does not claim ownership of any materials you make available through the Website with respect to such materials you submit or make available for inclusion on the Website, you grant. If you do not agree to these terms and conditions, please do not provide us with any submitted content. You are prohibited from posting or transmitting to or from this Website:</p>
                <ul>
                    <li>any unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material or content that would violate rights of publicity and/or privacy or that would violate any law.</li>
                    <li>Any commercial material or content (including, but not limited to, solicitation of funds, advertising, or marketing of any good or services).</li>
                    <li>Any material or content that violates any copyright, trademark, patent right or other proprietary right of any third party. You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from your posting of content to this Website.</li>
                </ul>
                <h3>Refund & Cancellation</h3>
                <p>As per the IRDAI rules and regulations, you have the right to cancel your insurance policy within 15(fifteen) days from the date of (online) receipt of insurance policy (referred to as “Free look period”) and refund of your premium amount shall be processed as per the applicable process and procedures followed by the insurer. This Free look facility can be availed only for Life and Health insurance policies, subject to certain other terms and conditions specified by IRDAI. We encourage all our customers to read the insurance policy document carefully and avail the free look facility in case the terms and conditions of the insurance policy document don't match your insurance requirements. Further, you understand that once you place a cancellation request within the Free look period, the policy gets cancelled and entire premium is refunded to you subject to deduction of</p>
                <ul>
                    <li>charges pertaining to medical tests conducted (ii) administrative and service cost like stamp duty etc.</li>
                    <li>Charges for mortality for the period the policy was in force. Please note such deduction is at the sole discretion of the insurer.</li>
                </ul>
                <h3>Due Diligence requirements</h3>
                <p>You agree and acknowledge that for undertaking any financial transaction through the website, Our Company may undertake client/customer due diligence measurers and seek mandatory information required for KYC purpose which as a customer you are obliged to give, while facilitating your request of insurance requirements with the insurance company, in accordance with applicable PMLA laws and rules. The Service is not available to minors under the age of 18 or to any users suspended or removed from the system by We for any reason.</p>
                <h3>Disclaimer</h3>
                <p>Nothing on any We shall be construed as conferring any license under any of us or any third party's intellectual property rights, whether by estoppels, implication, or otherwise.</p>
                <h3>Electronic Communication</h3>
                <p>When you use the Website or send emails to We, you are communicating with electronically. You consent to receive communications from us electronically. The Website may provide users with the ability to send email messages to other users and non-users and to post messages on the Website. We is under no obligation to review any messages, information or content ("Postings") posted on the Website by users and assumes no responsibility or liability relating to any such Postings.</p>
                <h3>Website Links</h3>
                <p>The Website or third parties may provide links to other World Wide Web Websites or resources, since we has no control over such websites and resources. Further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such Website or resource.
                Our website allows payments through debit cards, credit cards and net banking. The payment you make is directly credited to the insurance company's account. All transactions made on our website are secure and use the best payment gateway. For any more details related to the payment and payment protection, you may contact us on our phone number or email.</p>
                <h3>ERRORS AND QUESTIONS</h3>
                <p>In case of errors or questions about your transactions via this website, you should notify us as soon as possible via one of the following:
                Write us: Corporate Office :</p>
                <h3>SRG Insurance Brokers (P) Ltd.</h3>
                <p>2 & 5, Vinayak Complex'B', Durga Nursery Road, Udaipur, UDAIPUR, Rajasthan - 313001 Email.info@srggroup.net</p>
            </div>
            </div>
        </div>
    </div> -->
<app-footer></app-footer>
