import { Component } from '@angular/core';

@Component({
  selector: 'app-cancellationrefund',
  templateUrl: './cancellationrefund.component.html',
  styleUrls: ['./cancellationrefund.component.css']
})
export class CancellationrefundComponent {

}
