import { HttpInterceptor, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../Services/general.service';
@Injectable()

export default class AuthInterceptor implements HttpInterceptor {
  iPAddress?: string;
  latitude?: string = "";
  longitude?: string = "";
  constructor(private _router: Router, private generalService:GeneralService) {
  }
  intercept(req: any, next: HttpHandler): any {
   
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     position => {
    //       this.latitude = position.coords.latitude.toString();
    //       this.longitude = position.coords.longitude.toString();
    //     }       
    //   );
    // }
    // else {
    //   console.log("Geolocation is not supported by this browser.");
    // }

    if (req.headers.get('NoAuthorization') == "True") {
      return next.handle(req.clone());
    }
    if ((sessionStorage.getItem('access_token') == null || sessionStorage.getItem('access_token') == undefined) && (localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != undefined && localStorage.getItem('access_token') != "")){
      this.generalService.setAccessToken(localStorage.getItem('access_token') as string);
      this.generalService.setUniqueuserid(localStorage.getItem('uniqueuserid') as string);
      this.generalService.setIsAgent(localStorage.getItem('isAgent') as string);
      this.generalService.setUserLabel(localStorage.getItem('userLabel') as string);
    }

    if (this.generalService.getAccessToken() != "") {
      const headers = req.headers
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.generalService.getAccessToken()}`)
        .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
        .set('Pragma', 'no-cache')
        .set('latitude', this.latitude)
        .set('longitude', this.longitude)
        .set('IpAddress', "")
      const clonereq = req.clone({ headers });
      return next.handle(clonereq).pipe(catchError(errs => {
        if (errs instanceof HttpErrorResponse) {
          if (errs.status === 401) {
            this.generalService.removeAccessToken();
            this._router.navigate(['/']);
          }
        }
        return throwError(errs);
      }));
    }
    else {
      this.generalService.removeAccessToken();
      this._router.navigate(['/']);
    }
  }
}
