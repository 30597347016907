import { Component } from '@angular/core';

@Component({
  selector: 'app-car-quote-detail',
  templateUrl: './car-quote-detail.component.html',
  styleUrls: ['./car-quote-detail.component.css']
})
export class CarQuoteDetailComponent {
  city:string
  ngOnInit(): void {
  }
  Delhi(){
  this.city="Delhi";
  }
  Mumbai(){
    this.city="Mumbai";
  }
  Channai()
  {
    this.city="Channai";
  }
  Kolkata()
  {
    this.city="Kolkata";
  }
  Bengaluru(){
    this.city="Bengaluru";
  }
  Noida(){
    this.city="Noida,Gaziyabad";
  }
  Gurgoan(){
    this.city="Gurgoan";
  }
  Pune(){
    this.city="Pune";
  }
  Hydrabad(){
    this.city="Hydrabad";
  }
  Ahemdabad(){
    this.city="Ahemdabad";
  }
  Jaipur(){
    this.city="Jaipur";
  }
  Udaipur(){
    this.city="Udaipur";
  }
}
