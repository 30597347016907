<div class="content-box" style="margin-top: 50px;">
  <div class="container-fluid text-center">
    <div class="card shadow">
      <div class="card-user">
          <h3>
            <b class="float-start text-primary mt-4 ms-4">Enter User Details</b>
          </h3><br>
      <div class="card-body">
        <form [formGroup]="userForm" class="needs-validation" novalidate="" autocomplete="off">
          <div class="row">
            <div class="col-4 pr-1">
              <div class="form-group">
                <label class="float-start">Business : </label>
                <select class="form-select" name="business" formControlName="businessId" aria-label="Default select example">
                  <option value="" selected>Select Business</option>
                  <option *ngFor="let business of businessList" [value]="business.businessId">
                    {{business.businessName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">First Name : </label>
                <input type="text" name="Registration" placeholder="FirstName " class="form-control" formControlName="firstName">
              </div>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Last Name : </label>
                <input type="text" name="Registration" placeholder="LastName " class="form-control" formControlName="lastName">
              </div>
            </div>
  
  
          </div><br>
          <div class="row">
            <div class="col-md-4 pr-1">
              <label class="float-start">Mobile : </label>
              <ngx-intl-tel-input class="bg-transparent"
                                  [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true"
                                  [searchCountryFlag]="false"
                                  [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [selectFirstCountry]="false"
                                  [selectedCountryISO]="CountryISO.India"
                                  [phoneValidation]="true"
                                  [separateDialCode]="separateDialCode"
                                  name="phone"
                                  formControlName="phoneNumber"
                                  [inputId]="'phone_number'"
                                  (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>
  
              </ngx-intl-tel-input>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Email : </label><br />
                <input type="text" name="Registration" placeholder="Email" class="form-control" formControlName="userEmail">
              </div>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Pan Card : </label>
                <input type="text" name="pancard" placeholder="Pancard " class="form-control" formControlName="pancard">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Address : </label>
                <input type="text" name="Registration" placeholder="Address " class="form-control" formControlName="streetAddress">
              </div>
            </div>
            <div class="col-md-4 pr-1">
              <div class="form-group">
                <label class="float-start">Pincode : </label>
                <input type="text" name="pincode" #pinCode (blur)="pincodeUserChangeEvent(pinCode)" placeholder="Pincode " class="form-control" formControlName="pincodeId">
              </div>
            </div>
          </div>
        
          <br />
          <div class="float-end text-center">
            <button class="btn bnt-light" (click)="clearData()">Clear</button>&nbsp;
            <button class="btn btn-primary" [disabled]="userForm.invalid" (click)="save()">Save</button> &nbsp;
          </div>
        </form>
  
      </div>
      </div>
      </div>
  </div>
  
  <br />
  <!-- Table -->
  <div class="container-fluid text-center">
    <div class="card card-user shadow">
     <div class="card-user">
      <h3>
        <b class="text-primary float-start mt-4 ms-4">Users List</b>
      </h3><br><br>
      <div class="card-body  table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-hover table table-bordered table-striped mb-2">
          <thead>
            <tr>
              <th>Business Name</th>
              <th>FirstName</th>
              <th>LastName </th>
              <th>Email</th>
              <th> PhoneNumber</th>
              <th> Address</th>
              <th> Pincode</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of userList; let i = index">
              <td>
                {{user.businessName}}
              </td>
              <td>
                {{user.firstName}}
              </td>
              <td>
                {{user.lastName}}
              </td>
              <td>
                {{user.userEmail}}
              </td>
              <td>
                {{user.phoneNumber}}
              </td>
              <td>
                {{user.streetAddress}}
              </td>
              <td>
                {{user.pincode}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     </div>
  </div>
  </div>
  
</div>
