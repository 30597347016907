export class teluguLabel {
    public product: string = 'ఉత్పత్తులు';
    public support: string = 'మద్దతు';
    public raise_a_query: string = 'మీ ప్రశ్నను అడగండి';
    public raise_a_claim: string = 'క్లెయిమ్ నూ  అడగండి';
    public contact_us: string = 'మమ్మల్ని సంప్రదించండి';
    public career: string = 'కెరీర్';
    public gallery: string = 'గ్యాలరీ';
    public login: string = 'లాగిన్';
    public two_wheeler: string = 'ద్విచక్ర వాహనం';
    public four_wheeler: string = 'నాలుగు చక్రాల వాహనం';
    public health: string = 'ఆరోగ్యం';
    public commercial_vehicle: string = 'కమర్షియల్ వాహనం';
    public bike_ins_title: string = 'మీ టూ-వీలర్ ఇన్సూరెన్స్‌లో ప్రత్యేకంగా తక్కువ ధరను పొందండి';
    public bike_ins_start: string = 'ద్విచక్ర వాహన బీమా ₹635* నుండి ప్రారంభమవుతుంది ';
    public enter_bike_number: string = 'టూ-వీలర్ నంబర్ ని నమోదు చేయండి ఉదాహరణ: AB12CD3456';
    public verify: string = 'ధృవీకరించండి';
    public dont_have_policy: string = "రిజిస్ట్రేషన్ నంబర్ గుర్తు లేదా";
    public buy_new_bike: string = 'కొత్త ద్విచక్ర వాహనం కొన్నారా?';
    public what_is_ins1: string = 'బీమా అంటే';
    public what_is_ins2: string = 'ఏమిటి?';
    public what_is_ins_title: string = 'బీమాతో మనశ్శాంతిని పొందండి';
    public what_is_ins_text: string = "మా Click4bima పోర్టల్‌కు స్వాగతం, భవిష్యత్తును రక్షించడానికి ఇది మీ గేట్‌వే! అనేక రకాల బీమా ఎంపికలను అన్వేషించడానికి మా పోర్టల్ మీకు వన్-స్టాప్ గమ్యం. ఆరోగ్యం మరియు ఆటో నుండి ఇల్లు మరియు ప్రయాణం వరకు, మీ అవసరాలకు అనుగుణంగా ఉత్తమమైన కవరేజ్ ప్లాన్‌లను మీకు అందించడానికి మేము ప్రసిద్ధ బీమా కంపెనీలతో భాగస్వామ్యం చేసుకున్నాము. విభిన్న పాలసీ లను పోల్చడం ద్వారా, మీరు సమాచారంతో కూడిన నిర్ణయాలు తీసుకోవచ్చు మరియు మీ జీవనశైలి మరియు బడ్జెట్‌కు సరిపోయేదాన్ని ఎంచుకోవచ్చు.";
    public why_insurance_imp1: string = 'బీమా ఎందుకు';
    public why_insurance_imp2: string = 'ముఖ్యం?';
    public why_insurance_imp_title: string = 'రేపు సురక్షితం: బీమా యొక్క ప్రాణశక్తిని ఆవిష్కరించుదాం';
    public why_insurance_imp_text: string = "భీమా అనేది జీవిత అనిశ్చితి నుండి మీకు రక్షణ కవచం, మీకు చాలా అవసరమైనప్పుడు మనశ్శాంతిని అందిస్తుంది. ఇది మీకు మరియు మీరు ఎంచుకున్న బీమా ప్రొవైడర్‌కు మధ్య జరిగిన ఒప్పందం, ఇది ఊహించని సంఘటనల సమయాల్లో ఆర్థిక సహాయానికి హామీ ఇస్తుంది. అది ఆకస్మిక వైద్య ఖర్చు అయినా, ఆటోమొబైల్ ప్రమాదం అయినా, మీ ఆస్తికి నష్టం అయినా లేదా ఏదైనా ఊహించని పరిస్థితి అయినా, భారాన్ని తగ్గించుకోవడానికి బీమా చర్యలు తీసుకుంటుంది.   ";
    public claim_assistance1: string = 'సహాయాన్ని';
    public claim_assistance2: string = 'క్లెయిమ్ చేయండి';
    public claim_assistance_title: string = 'మీ మనశ్శాంతిని తిరిగి పొందండి ';
    public claim_assistance_text: string = "Click4bima నుండి 'క్లెయిమ్ అసిస్టెన్స్'తో, మీరు సున్నితమైన క్లెయిమ్ ప్రాసెస్ యొక్క ప్రాముఖ్యతను అర్థం చేసుకునే విశ్వసనీయ భాగస్వామిని కలిగి ఉన్నారని మీరు నిశ్చయించుకోవచ్చు. మీరు కేవలం ఒక పాలసీ ద్వారా మాత్రమే రక్షించబడ్డారని తెలుసుకోవడం ద్వారా మీ మనశ్శాంతిని తిరిగి పొందండి – మీకు శ్రద్ధ వహించే బృందం మద్దతునిస్తుంది. మీకు అత్యంత అవసరమైనప్పుడు మా 'క్లెయిమ్ అసిస్టెన్స్' మీ విశ్వసనీయ సహచరుడు.";
    public best_place_ins_title: string = 'భారతదేశంలో ఇన్సూరెన్స్‌ను కొనుగోలు చేయడానికి మమ్మల్ని ఉత్తమమైన ప్రదేశంగా మార్చేది ఏమిటి?';
    public best_place_ins_text: string = 'మా Click4bima పోర్టల్‌కు స్వాగతం, భారతదేశంలో అత్యుత్తమ బీమా కవరేజీని కనుగొనడానికి మీ వన్-స్టాప్ గమ్యం. మేము మిగతావాటి కంటె ఎందుకు ప్రత్యేకంగా నిలబడతాము అని ఆలోచిస్తున్నారా? సరైన బీమా కవరేజ్ రక్షణతో ఆందోళన లేని భవిష్యత్తును స్వీకరించండి. ఈరోజే మాతో మీ బీమా ప్రయాణాన్ని ప్రారంభించండి.';
    
    public everytime_support_title: string = '24*7 సపోర్ట్';
    public everytime_support_text: string = "మా 24/7 బీమా సహాయంతో ఎప్పుడైనా, ఎక్కడైనా మనశ్శాంతిని పొందండి. మీకు నిపుణుల మద్దతును అందించడానికి, మీ ప్రశ్నలకు సమాధానమివ్వడానికి మరియు మీరు ఎల్లప్పుడూ కవర్ చేయబడతారని నిర్ధారించుకోవడానికి మా ప్రత్యేక బృందం ఇక్కడ ఉంది.         "


    public best_price_ins_title: string = 'ఉత్తమ ధర గల బీమా';
    public best_price_ins_text: string = "మా లక్ష్యం చాలా స్పష్టం: నాణ్యత విషయంలో రాజీ పడకుండా మీకు అత్యుత్తమ బీమా డీల్‌లను అందించడం. మీకు అనుకూలంగా ఉన్న పాలసీల శ్రేణితో, మీ బడ్జెట్‌ను చెక్కుచెదరకుండా ఉంచుతూ మీ భవిష్యత్తును కాపాడుకోవడానికి మేము ఇక్కడ ఉన్నాము.";
    public reliable_title: string = 'విశ్వసనీయమైనది';
    public reliable_text: string = 'మినహాయింపులు లేకుండా, మీకు అత్యంత ముఖ్యమైన వాటిని రక్షించడానికి మీరు మమ్మల్ని విశ్వసించవచ్చు. మా వాగ్దానాలను అందించడంలో నిరూపితమైన ట్రాక్ రికార్డ్‌తో, మేము మీ మనశ్శాంతిని, అడుగడుగునా భరోసా ఇస్తున్నాము. మీ భవిష్యత్తును కాపాడుకోవడంలో తిరుగులేని విశ్వసనీయత కోసం మమ్మల్ని ఎంచుకోండి.';
    public easy_claim_title: string = 'దావా సులభం చేసింది';
    public easy_claim_text: string = "మా స్ట్రీమ్‌లైన్డ్ ప్రొసీజర్‌లు మరియు అంకితమైన సపోర్ట్ టీమ్ మీకు అవసరమైనప్పుడు మీకు అవసరమైన సహాయం అందేలా చూస్తుంది. మేము ప్రక్రియను సరళీకృతం చేయడానికి కట్టుబడి ఉన్నాము, తద్వారా మీరు మీ జీవితంలో అత్యంత ముఖ్యమైన వాటిని త్వరగా తిరిగి పొందవచ్చు.";
    public mobile_app_title: string = 'ఒకే యాప్‌తో చెల్లించండి, పెట్టుబడి పెట్టండి, మీ డబ్బును ఆదా చేసుకోండి';
    public mobile_app_content: string = 'ఎక్కడైనా, ఎప్పుడైనా మీ బీమా అవసరాలన్నింటినీ నియంత్రించండి';

    public mobile_app_compare: string = 'వివిధ బీమా పాలసీలను సరిపోల్చండి+';
    public mobile_app_buy:string='మీ అన్ని పాలసీలను ఆన్‌లైన్‌లో కొనుగోలు చేయండి, నిల్వ చేయండి మరియు భాగస్వామ్యం చేయండి';
    public mobile_app_track: string = 'ఏసమయంలోనైనా మీ పాలసీ స్థితిని ట్రాక్ చేయండి';
    public mobile_app_download: string = 'ఒక్క ట్యాప్‌తో మీ పాలసీని డౌన్‌లోడ్ చేసుకోండి';
    public mobile_app_coming: string = 'అద్భుతమైన వాటి కోసం సిద్ధంగా ఉండండి - మా యాప్‌లు త్వరలో ప్రారంభించబడతాయి!';
    public testimonial_title: string = 'మేము మా కస్టమర్ అనుభవం గురించి కూడా శ్రద్ధ వహిస్తాము';
    public our_partner_title: string = 'మా భాగస్వాములు';
    public our_partner_text: string = 'మీకు అత్యుత్తమ సేవలను అందించడానికి మేము కలిసి పని చేస్తాము.';
    public need_help_title: string = 'సహాయం కావాలా?';
    public need_help_text: string = 'మీకు అత్యుత్తమ సేవలను అందించడానికి మేము కలిసి పని చేస్తాము. Click4bima పోర్టల్‌లో, మీ బీమా ప్రయాణంలో అడుగడుగునా అసాధారణమైన కస్టమర్ సేవ మరియు సహాయాన్ని అందించడానికి మేము కట్టుబడి ఉన్నాము. బీమా ప్రపంచాన్ని నావిగేట్ చేయడంలో మీ విశ్వసనీయ భాగస్వామిగా ఉండనివ్వండి. ఈరోజే మా మద్దతు బృందాన్ని సంప్రదించండి మరియు తేడాను ప్రత్యక్షంగా తెలుసుకోండి. మీ మనశ్శాంతి మా ప్రాధాన్యత!';
    public mail_us:string='మాకు మెయిల్ చేయండి';
    public mobile_number:string='మొబైల్ నంబర్';
    public whatsapp:string='వాట్సాప్';
    public about_us:string='మా గురించి';
    public sitemap:string='సైట్‌మ్యాప్';    
    public faqs:string='తరచుగా అడిగే ప్రశ్నలు';
    public motor_insurance:string='మోటార్ బీమా';
    public car_insurance :string='కారు భీమా';   
    public two_wheeler_insurance :string='ద్విచక్ర వాహన బీమా';
    public taxi_insurance :string='టాక్సీ బీమా';
    public commercial_veh_insurance:string='కమర్షియల్ వాహన బీమా';
    public third_party_car_ins:string='థర్డ్ పార్టీ కార్ బీమా'; 
    public third_party_bike_ins:string='థర్డ్ పార్టీ 2W బీమా';
    public health_insurance:string='ఆరోగ్య భీమా';
    public family_health_ins:string='కుటుంబ ఆరోగ్య పాలసీ ';
    public individual_health_ins:string='వ్యక్తిగత ఆరోగ్య పాలసీ';
    public group_health_ins:string='గ్రూప్ ఆరోగ్య పాలసీ';
    public senior_citizen_health_ins:string='సీనియర్ సిటిజన్ ఆరోగ్య పాలసీ '; 
    public critical_illness_ins:string='క్రిటికల్ ఇల్‌నెస్ పాలసీ';
    public arogya_san_ins:string='ఆరోగ్య సంజీవని పాలసీ';
    public life_insurance:string='జీవిత భీమా';
    public term_insurance:string='టర్మ్ బీమా';
    public single_pay_term_ins:string='సింగిల్ పే టర్మ్ బీమా'; 
    public group_term_ins:string='గ్రూప్ టర్మ్ బీమా';  
    public saving_plans:string='పొదుపు ప్రణాళికలు';  
    public tax_saving_ins:string='పన్ను ఆదా పాలసీ';  
    public money_back_ins:string='మనీ బ్యాక్ పాలసీ';  
    public guaranteed_return_ins:string='గ్యారెంటీడ్ రిటర్న్ పాలసీ';   
    public ulip:string='యు ఎల్ ఐ పి';
    public pension_plan:string='పెన్షన్ ప్లాన్';  
    public child_plan:string='చైల్డ్ ప్లాన్';    
    public others:string='ఇతరులు';    
    public privacy_policy:string='ప్రైవసీ పాలసీ';  
    public cancellation_refund:string='రద్దు & వాపసు';  
    public term_condition:string='నిబంధనలు & షరతులు';
    public disclaimer: string = 'నిరాకరణ';
    public grivance_redressal: string = 'పరిహారం';
    public srg_add_title: string = 'ఎస్ ఆర్ జి ఇన్సూరెన్స్ బ్రోకర్స్ ప్రైవేట్ లిమిటెడ్';
    public srg_add_detail: string = '2 & 5, వినాయక్ కాంప్లెక్స్, దుర్గా నర్సరీ రోడ్, ఉదయపూర్, రాజస్థాన్, 313001';
    
    public direct_broker_title: string = 'డైరెక్ట్ బ్రోకర్, లైసెన్స్ నం: DB-290';
    public direct_broker_detail: string = 'చెల్లుబాటు: 02.02.2026 వరకు,CIN: U74140RJ2002PTC017656';
    public  payment_method:string='చెల్లింపు పద్ధతులు';
    public  copyright:string='© కాపీరైట్ 2023 Click4bima. సర్వ హక్కులు ప్రత్యేకించబడినవి';   
    public continue:string='కొనసాగించు';
    public bought_new_bike:string='కొత్త ద్విచక్ర వాహనం కొన్నాను';
    public bought_new_car:string='కొత్త కారు కొన్నారు';
    public car_ins_title:string='మీ కార్ ఇన్సూరెన్స్‌పై ప్రత్యేకంగా తక్కువ ధరను పొందండి';
    public car_ins_start:string='కారు బీమా ₹2471 నుండి ప్రారంభమవుతుంది*';
    public enter_car_number:string='కారు నంబర్ ని నమోదు చేయండి ఉదాహరణ: AB12CD3456'; 
    public vehicle_detail_form:string='మీ వాహనం వివరాలు';
    public city:string='నగరం'; 
    public rto:string="ఆర్.టి.ఓ";
    public manufacturer:string="తయారీదారు"; 
    public model:string="మోడల్"; 
    public fuel_type:string="ఇంధన రకం ";
    public variant:string="వేరియంట్"; 
    public reg_year:string="నమోదు సంవత్సరం   "; 
    public get_quotes:string=" పాలసీ ధరలు చుడండి";  
    public search_city:string="నగరాన్ని శోధించండి"; 
    public select_city:string="లేదా నగరాన్ని ఎంచుకోండి"; 
    public select_rto:string='సిటీ ఆర్ టి ఓ ని ఎంచుకోండి';
    public search_manufacturer:string='తయారీదారుని శోధించండి';
    public select_manufacturer:string='లేదా తయారీదారుని ఎంచుకోండి';  
    public search_model:string='మోడల్ ను శోధించండి';
    public select_model:string='లేదా మోడల్‌ని ఎంచుకోండి';  
    public search_variant:string='వేరియంట్ ను శోధించండి';   
    public select_variant:string='లేదా వేరియంట్‌ని ఎంచుకోండి ';   
    public select_year:string='సంవత్సరాన్ని ఎంచుకోండి';   
    public select_fuel_type:string='ఇంధన రకాన్ని ఎంచుకోండి'; 
    public contact_title:string='మాతో అందుబాటులో ఉండండి';   
    public contact_text:string='మేము వీలైనంత త్వరగా మీకు తిరిగి సంప్రదిస్తాము.';  
    public full_name:string='పూర్తి పేరు';     
    public mobile:string='మొబైల్ నంబర్';            
    public interested_in:string='ఆసక్తి ఉంది'  ;  
    public email:string='ఈమెయిల్ అడ్రస్';
    public description:string='వివరణ';            
    public call_me:string='తిరిగి కాల్ చేయు';
    public continue_fb:string='ఫేస్‌బుక్‌తో కొనసాగించండి';
    public continue_google:string='గూగుల్ తో   కొనసాగించండి';  
    public sign_in:string='సైన్ ఇన్ చేయండి';  
    public password:string='పాస్వర్డ్';    
    public remember:string='నన్ను గుర్తుపెట్టుకోండి';   
    public dont_have_acc:string="ఏ ఖాతా లేదు"; 
    public signup_here:string="ఇక్కడ సైన్ అప్ చేయండి";     
    public click_here:string="ఇక్కడ క్లిక్ చేయండి";       
    public send_otp:string="ఓటిపి పంపండి";         
    public back_login:string="తిరిగి లాగిన్‌కి";       
    public already_hv_acc:string="ఇప్పటికే ఖాతా ఉందా?";     
    public signin_here:string="ఇక్కడ సైన్ ఇన్ చేయండి  ";     
    public verification:string="వెరిఫికేషన్";   
    public verify_text:string="మీ నంబర్ +918543871911కి పంపబడిన 6-అంకెల కోడ్‌ను నమోదు చేయండి. మీ మొబైల్ నంబర్ మార్చడానికి ఇక్కడ క్లిక్ చేయండి.";    
    public enter_otp:string="ఓటిపి ని నమోదు చేయండి";   
    public dont_rec_otp:string="మీరు కోడ్‌ని అందుకోకపోతే! మళ్లీ పంపండి";    
    public submit:string="సమర్పించండి"; 
    public accept_terms:string="మీ ఖాతాను సృష్టించడం ద్వారా, మీరు మా నిబంధనలు & షరతులకు అంగీకరిస్తున్నారు";    
    public basic_details:string ="ప్రాథమిక వివరాలు";      
    public first_name:string ="మొదటి పేరు";     
    public last_name:string ="ఇంటిపేరు ";     
    public dob:string ="పుట్టిన తేది";                        
    public referal_code:string ="రెఫరల్ కోడ్";              
    public gender:string ="లింగం";                
    public new_password:string  ="కొత్త పాస్‌వర్డ్‌ని నమోదు చేయండి";   
    public confirm_old_pass:string="కొత్త పాస్‌వర్డ్‌ని నిర్ధారించండి";         
    public dont_have_policy_popup:string='మీకు మీ టూ వీలర్ నంబర్ గుర్తులేకపోతే, మీ ద్విచక్ర వాహనం యొక్క ప్రాథమిక వివరాలను యాక్సెస్ చేయడానికి మీ RC (రిజిస్ట్రేషన్ సర్టిఫికేట్)ని సిద్ధంగా ఉంచుకోండి.';

    public year:string='సంవత్సరం';
    
    public your_details:string='మీ వివరాలను నమోదు చేయండి'; 
    
    public name_title:string='శీర్షిక'; 
}