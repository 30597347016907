import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { GeneralService } from 'src/app/Services/general.service';

@Component({
  selector: 'app-li-posptraining',
  templateUrl: './li-posptraining.component.html',
  styleUrls: ['./li-posptraining.component.css']
})

export class LiPosptrainingComponent {
  cource: string = '0%';
  courceTotal: string = '0%';
  secondM: boolean = false;
  thiredM: boolean = false;
  fourM: boolean = false;
  fiveM: boolean = false;
  showImage: boolean = false;
  training: boolean = true;
  lesson: boolean = false;
  feedback: boolean = false;
  exam: boolean = false;
  content: boolean = true;
  // modual_1: boolean = false;
  // modual_2: boolean = false;
  // modual_3: boolean = false;
  // modual_4: boolean = false;
  // modual_5: boolean = false;
  modualNo = 0;
  md_1: boolean = false;
  md_2: boolean = false;
  md_3: boolean = false;
  md_4: boolean = false;
  md_5: boolean = false;
  show_md_1_content: boolean = false;
  show_md_2_content: boolean = false;
  show_md_3_content: boolean = false;
  show_md_4_content: boolean = false;
  show_md_5_content: boolean = false;
  continue: boolean = false;
  md_name: string = ''
  sideCourse: boolean = false;
  FifteenHr:number = 54000000;
  ThreeHrs:number = 10800000;
  timerDate:Date = new Date("Sep 25, 2025 00:00:00");
//Counter
  private countDownDate: number = this.timerDate.getTime() + this.FifteenHr;//new Date("Sep 25, 2025 15:00:00").getTime();
  private intervalId: any;
  public countdownText: string = '';
  public isPaused: boolean = false;
  public remainingTime: number = this.countDownDate - this.timerDate.getTime() + this.ThreeHrs;//new Date("Sep 25, 2025 00:00:00").getTime();
  trainingDetailForm: FormGroup;
  reainingTotalTime:number = 0;
  reainingTotalTimeText : string = '';
  //Counder End
  constructor(private router: Router, private fb: FormBuilder, private spinner: NgxSpinnerService, private agentprofileService: AgentprofileService, private generalService: GeneralService){

    this.trainingDetailForm = this.fb.group(
    {
      "status":[2],
      "userId":[0],
      "sessionNo": 1,
      "firstSessionDuration": [10800000 ],
      "secondSessionDuration": [10800000],
      "thirdSessionDuration": [10800000],
      "forthSessionDuration": [10800000],
      "fifthSessionDuration": [10800000],
      "perSessionDuration": [10800000]
  });
  }

  async start(name: string, canProceed : boolean = true) {
    debugger;
    this.sideCourse = true;
    this.md_name = name;
    if (this.md_name == '') {
      this.content = true;
      this.modualNo = 0;
      // this.modual_1 = false;
      // this.modual_2 = false;
      // this.modual_3 = false;
      // this.modual_4 = false;
      // this.modual_5 = false;
    }
    if (this.md_name == 'module_1' && canProceed == true) {
      this.content = false;
      this.md_1 = true;
      this.modualNo = 1;
      this.showHideModuleContent();
      //this.cource = "0%";
      // this.modual_1 = true;
      // this.modual_2 = false;
      // this.modual_3 = false;
      // this.modual_4 = false;
      // this.modual_5 = false;


      if(this.trainingDetailForm?.value.firstSessionDuration == 0){
        this.remainingTime = 0;
      }
      else
      {
        await this.getTrainingSessionDetails();
      }
    }
    if (this.md_name == 'module_2' && canProceed == true) {
      this.content = false;
      this.md_2 = true;
      this.modualNo = 2;
      this.showHideModuleContent();
      if(this.trainingDetailForm?.value.secondSessionDuration == 0){
        this.remainingTime = 0;
      }      
      else
      {
        await this.getTrainingSessionDetails();
      }
      //this.cource = "20%";
      // this.modual_1 = false;
      // this.modual_2 = true;
      // this.modual_3 = false;
      // this.modual_4 = false;
      // this.modual_5 = false;
    }
    if (this.md_name == 'module_3' && canProceed == true) {
      this.content = false;
      this.md_3 = true;
      this.modualNo = 3;
      this.showHideModuleContent();
      if(this.trainingDetailForm?.value.thirdSessionDuration == 0){
        this.remainingTime = 0;
      }     
      else
      {
        await this.getTrainingSessionDetails();
      }
      //this.cource = "40%";
      // this.modual_1 = false;
      // this.modual_2 = false;
      // this.modual_3 = true;
      // this.modual_4 = false;
      // this.modual_5 = false;
    }
    if (this.md_name == 'module_4' && canProceed == true) {
      this.content = false;
      this.md_4 = true;
      this.modualNo = 4;
      this.showHideModuleContent();
      if(this.trainingDetailForm?.value.forthSessionDuration == 0){
        this.remainingTime = 0;
      }     
      else
      {
        await this.getTrainingSessionDetails();
      }
      //this.cource = "60%";
      // this.modual_1 = false;
      // this.modual_2 = false;
      // this.modual_3 = false;
      // this.modual_4 = true;
      // this.modual_5 = false;

    }
    if (this.md_name == 'module_5' && canProceed == true) {
      this.content = false;
      this.md_5 = true;
      this.modualNo = 5;
      this.showHideModuleContent();
      if(this.trainingDetailForm?.value.fifthSessionDuration == 0){
        this.remainingTime = 0;
      }
      else
      {
        await this.getTrainingSessionDetails();
      }
      //this.cource = "80%";
      // this.modual_1 = false;
      // this.modual_2 = false;
      // this.modual_3 = false;
      // this.modual_4 = false;
      // this.modual_5 = true;      
    }
    //this.countDownDate = this.timerDate.getTime() + this.ThreeHrs;
    //this.remainingTime = this.countDownDate - (this.timerDate.getTime() + 1000 * 60 * 179);
    this.startCountdown();
  }
  startTraining() {
    this.training = false;
    this.lesson = true;
    this.feedback = false;
    this.exam = false;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.showImage = true;
  }
  numb: string = '1';
  modual() {
    this.content = true;
    this.sideCourse = false;
    this.continue = false;
    let num = this.modualNo.toString();
    // this.modual_1 = false;
    // this.modual_2 = false;
    // this.modual_3 = false;
    // this.modual_4 = false;
    // this.modual_5 = false;
    if (num == "1") {
      if(this.trainingDetailForm?.value.firstSessionDuration != 0){
        this.saveTrainingSession();
      }
      else
      {
        this.getTrainingSessionDetails();
      }
      this.secondM = true;
      this.md_1 = true;
    }
    if (num == "2") {
      if(this.trainingDetailForm?.value.secondSessionDuration != 0){
        this.saveTrainingSession();
      }
      else
      {
        this.getTrainingSessionDetails();
      }
      this.thiredM = true;
      this.md_2 = true;
    }
    if (num == "3") {
      if(this.trainingDetailForm?.value.thirdSessionDuration != 0){
        this.saveTrainingSession();
      }
      else
      {
        this.getTrainingSessionDetails();
      }
      this.fourM = true;
      this.md_3 = true;
    }
    if (num == "4") {
      if(this.trainingDetailForm?.value.forthSessionDuration != 0){
        this.saveTrainingSession();
      }
      else
      {
        this.getTrainingSessionDetails();
      }
      this.fiveM = true;
      this.md_4 = true;
    }

  }
  AllModual:boolean=false;
  complete() {
    this.AllModual=true;
    this.content = true;
    this.sideCourse = false;
    this.continue = true;
    this.saveTrainingSession();
    this.modualNo = 6;

    let statusObj = this.generalService.getDataToLocalStorage("agentStatus");
    if(statusObj != "")
    {
      let obj = JSON.parse(statusObj);
      if(obj?.TRN_Status != 1){
        this.pop = true;
      }
    }
    else
    {
      this.pop = true;
    }
    
    // this.modual_1 = false;
    // this.modual_2 = false;
    // this.modual_3 = false;
    // this.modual_4 = false;
    // this.modual_5 = false;
  }
  mdl: string = '';
  goTo(n: string) {
    this.mdl = n;

    if (this.mdl == '2') {
      // this.modual_1 = true;
      // this.modual_2 = false;
      // this.modual_3 = false;
      // this.modual_4 = false;
      // this.modual_5 = false;
    }
    if (this.mdl == '3') {
      // this.modual_1 = false;
      // this.modual_2 = true;
      // this.modual_3 = false;
      // this.modual_4 = false;
      // this.modual_5 = false;
    }
    if (this.mdl == '4') {
      // this.modual_1 = false;
      // this.modual_2 = false;
      // this.modual_3 = true;
      // this.modual_4 = false;
      // this.modual_5 = false;
    }
    if (this.mdl == '5') {
      // this.modual_1 = false;
      // this.modual_2 = false;
      // this.modual_3 = false;
      // this.modual_4 = true;
      // this.modual_5 = false;
    }
  }
  pop: boolean = false;
  popup() {
    this.pop = true;
  }
  close() {
    this.pop = false;
  }
  agentActive(){
    //localStorage.setItem("active","true");
  }

  //Counter
  private startCountdown(): void {    
    this.intervalId = setInterval(() => {
      if (!this.isPaused) {
        // const now = new Date().getTime();
        // this.remainingTime = this.countDownDate - now;

        //const now = new Date().getTime();    
        this.remainingTime = this.remainingTime - 1000;    
        
        if (this.remainingTime < 0) {
          clearInterval(this.intervalId);
          this.remainingTime = 0;
          this.countdownText = 'Lesson Completed';
        } else {
          this.countdownText = this.updateCountdownText(this.remainingTime);
        }
        this.cource = (((this.ThreeHrs-this.remainingTime)/this.ThreeHrs)*100).toFixed(2).toString()+"%";
      }
    }, 1000);
  }

  private updateCountdownText(distance: number): string {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${hours < 9 ? '0' + hours : hours}h ${minutes < 9 ? '0' + minutes : minutes}m ${seconds < 9 ? '0'+ seconds : seconds}s`;
  }

  public pauseCountdown(): void {
    this.isPaused = true;
    this.saveTrainingSession();
    clearInterval(this.intervalId);
  }

  public resumeCountdown(): void {
    this.isPaused = false;
    //this.countDownDate = new Date().getTime() + this.remainingTime;
    this.startCountdown();
  }
  //Counter End

  ngOnInit(): void {
    this.getTrainingSessionDetails();
  }

  async getTrainingSessionDetails(){
    const response = await this.agentprofileService.GetTrainingSessionByUserId(this.generalService.getUniqueuserid());
    if (response?.isSuccess && response?.result) {
      let ddd = response.result;
      this.trainingDetailForm.patchValue(response.result);
      let ddddd = this.trainingDetailForm?.value;
      this.modualNo = this.trainingDetailForm?.value.sessionNo;
      this.md_1 = this.modualNo > 1;
      this.md_2 = this.modualNo > 2;
      this.md_3 = this.modualNo > 3;
      this.md_4 = this.modualNo > 4;
      this.md_5 = this.modualNo > 5;
      this.secondM  = this.modualNo >= 2;
      this.thiredM = this.modualNo >= 3;
      this.fourM  = this.modualNo >= 4;
      this.fiveM  = this.modualNo >= 5;

      switch(this.modualNo){
        case 1 : this.remainingTime = this.trainingDetailForm?.value.firstSessionDuration;
        break;
        case 2 : this.remainingTime = this.trainingDetailForm?.value.secondSessionDuration;
        break;
        case 3 : this.remainingTime = this.trainingDetailForm?.value.thirdSessionDuration;
        break;
        case 4 : this.remainingTime = this.trainingDetailForm?.value.forthSessionDuration;
        break;
        case 5 : this.remainingTime = this.trainingDetailForm?.value.fifthSessionDuration;
        break;
      }
      this.reainingTotalTime = this.trainingDetailForm?.value.firstSessionDuration + this.trainingDetailForm?.value.secondSessionDuration
      + this.trainingDetailForm?.value.thirdSessionDuration + this.trainingDetailForm?.value.forthSessionDuration + this.trainingDetailForm?.value.fifthSessionDuration;

      this.reainingTotalTimeText = this.updateCountdownText(this.reainingTotalTime);
      this.courceTotal = (((this.FifteenHr-this.reainingTotalTime)/this.FifteenHr)*100).toFixed(2).toString()+"%";
    }
  }

  async saveTrainingSession(){
    this.trainingDetailForm.get('sessionNo')?.setValue(this.modualNo);
    switch(this.modualNo){
      case 1 : this.trainingDetailForm.get('firstSessionDuration')?.setValue(this.remainingTime <= 0 ? 0 : this.remainingTime);
      break;
      case 2 : this.trainingDetailForm.get('secondSessionDuration')?.setValue(this.remainingTime <= 0 ? 0 : this.remainingTime);
      break;
      case 3 : this.trainingDetailForm.get('thirdSessionDuration')?.setValue(this.remainingTime <= 0 ? 0 : this.remainingTime);
      break;
      case 4 : this.trainingDetailForm.get('forthSessionDuration')?.setValue(this.remainingTime <= 0 ? 0 : this.remainingTime);
      break;
      case 5 : this.trainingDetailForm.get('fifthSessionDuration')?.setValue(this.remainingTime <= 0 ? 0 : this.remainingTime);
      break;
    }
    const response = await this.agentprofileService.AgentTrainingSessionInfo(this.trainingDetailForm?.value);
    if (response?.isSuccess && response?.result) {
      this.getTrainingSessionDetails();
    }
  }

  showHideModuleContent(){
    this.show_md_1_content = this.modualNo == 1;
    this.show_md_2_content = this.modualNo == 2;
    this.show_md_3_content = this.modualNo == 3;
    this.show_md_4_content = this.modualNo == 4;
    this.show_md_5_content = this.modualNo == 5;
  }
}
