import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpService } from '../Services/lookup.service';
import swal from 'sweetalert2';
import { LanguageLableService } from '../Services/language-lable.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  contactUsForm: FormGroup;
  selectedValue: string | null;
  langList: any;

  constructor(private languageLableService: LanguageLableService,private fb: FormBuilder, private lookUpService:LookUpService) {
    this.contactUsForm = this.fb.group({
      "name": ['', [Validators.required]],
      "mobileNumber": ['', [Validators.required]],
      "interestedIn": ['', [Validators.required]],
      "email": ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      "description": ['', [Validators.required]],
    });
  }

  ngOnInit() {
    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
  }

  languageChanged(){
    location.reload();
   }
  async contactus() {
    const res = await this.lookUpService.SaveLeadsDetail(this.contactUsForm.value);
    if (res !=null  && res.result)  {
      this.contactUsForm.reset();
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your Query submitted Successfully!',
        showConfirmButton: false,
        timer: 3000,
      });
     
    }
  }
}
