<div class="container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card   p-5">
                    <div class="row">
                        <div class="col-md-6">
                            <h4>
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft">&nbsp;&nbsp;Your Cart
                            </h4>

                        </div>
                        <div class="col-md-6">
                            <p>
                                Order No. PB398457
                            </p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <img src="../../assets/svgIcon/ArrowLeft.svg" alt="" width="50%" height="50%">
                        </div>
                        <div class="col-md-6 mt-4">
                            <h5>
                                United india Insurance
                            </h5>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <p>
                                    Policy Type
                                </p>
                                <h5>
                                    STD BSVI 125
                                </h5>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <p>
                                    Proposal No.
                                </p>
                                <h5>
                                    PBFHG38947347399
                                </h5>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div>
                                <p>
                                    Amount
                                </p>
                                <h5>
                                    ₹1,180
                                </h5>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="card mt-5  p-5">
                    <div class="row">
                        <div class="col-md-12">
                            <div>
                                <h4>
                                    Proposer's Details
                                </h4>
                            </div>
                            <div>
                                <p>
                                    Email
                                </p>
                                <h5>
                                    abc@gmail.com
                                </h5>
                                <p>
                                    Mobile
                                </p>
                                <h5>
                                    +91564654654
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-7 mt-5">
                        <div>
                            <h3>
                                Choose Payment Mode
                            </h3>
                        </div>
                    </div>
                    <div class="col-md-5 mt-5">
                        <div>
                            <p>
                                Session will expire in <span>--min --s</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card mt-2 mr-5 p-5">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="row">
                                    <div class="card">
                                        <div class="tab">
                                            <div class="card ">
                                                <div class="tablinks text-start mt-5 p-5 tabcard "
                                                    (click)="Payment('')" >
                                                    <p>
                                                        <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <span>
                                                            Credit/Debit Card
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="card ">
                                                <div class="tablinks text-start mt-5 p-5 tabcard"
                                                    (click)="Payment('netBanking')">
                                                    <p>
                                                        <i class="fa fa-university" aria-hidden="true"></i>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <span>
                                                            Net Banking
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="card ">
                                                <div class="tablinks text-start mt-5 p-5 tabcard "
                                                    (click)="Payment('UPI')">
                                                    <p>
                                                        <i class="fa fa-cc-visa" aria-hidden="true"></i>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <span>
                                                            UPI/VPA/QR
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="card ">
                                                <div class="tablinks text-start mt-5 p-5  tabcard"
                                                    (click)="Payment('wallet')">
                                                    <p>
                                                        <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <span>
                                                            wallet
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="card mt-5 p-5" >

                                <div class="tabcontent" *ngIf="paymentType === ''" >
                                    <div class="row">

                                        <div class="col-md-6">
                                            <label> Enter card number</label>
                                            <input type="number" class="form-control" name="card">
                                        </div>
                                        <div class="col-md-6">
                                            <label> Name on the card</label>
                                            <input type="text" class="form-control" name="name">
                                        </div>
                                        <div class="col-md-3 mt-3">
                                            <label> Month</label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value="1" selected>Month</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                        <div class="col-md-3 mt-3 ">
                                            <label> Day</label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value="1" selected>Dayh</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option valu e="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>

                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <label for="">CVV</label>
                                            <input type="password" name="cvv" id="" class="form-control">
                                        </div>
                                        <div class="col-md-12 mt-5">
                                            <p>
                                                By clicking on pay now i agree to the Terms & Conditions and confirm
                                                that the vehicle is a registered private two wheeler know more
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <p>
                                                As per IRDAI guidelines, you will be required to complete your KYC after
                                                payment for successful policy issuance
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <button class="btn btn-lg btn-primary" id="btn">
                                                Pay Now ₹1,180
                                            </button>
                                        </div>
                                    </div>

                                </div>



                                <div class="tabcontent" *ngIf="paymentType === 'netBanking'">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <h4>
                                                Popular Banks
                                            </h4>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/Axis.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                AXIS</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/icici.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                ICICI</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/IDBI.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                IDBI</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/kotak.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                Kotak Mahindra</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/standard.jpg" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                Standard Chartered </b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/SBI.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                SBI</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-2">
                                            <label>Select Other Bank</label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Select Your Bank</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12 mt-5">
                                            <p>
                                                By clicking on pay now i agree to the Terms & Conditions and confirm
                                                that the vehicle is a registered private two wheeler know more
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <p>
                                                As per IRDAI guidelines, you will be required to complete your KYC after
                                                payment for successful policy issuance
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <button class="btn btn-lg btn-primary" id="btn">
                                                Pay Now ₹1,180
                                            </button>
                                        </div>



                                    </div>
                                </div>



                                <div class="tabcontent" *ngIf="paymentType === 'UPI'">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img src="../../../../assets/images/qr.svg" alt="">
                                        </div>
                                        <div class="col-md-8 p-5 text-start">
                                            <h3>
                                                Pay using QR code
                                            </h3>
                                            <p>
                                                Click to generate QR code and scan the QR with your preferred UPI app
                                            </p>
                                        </div>
                                        <div class="col-md-12 mt-4">
                                            <h4>
                                                Or Pay Using UPI ID
                                            </h4>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/Axis.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                AXIS</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/Axis.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                AXIS</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/Axis.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                AXIS</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/Axis.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                AXIS</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-5">
                                            <label>Enter UPI ID</label>
                                            <input type="text" name="UPIid" id="" class="form-control"
                                                placeholder="abc@ybl">
                                        </div>
                                        <div class="col-md-12 mt-5">
                                            <p>
                                                By clicking on pay now i agree to the Terms & Conditions and confirm
                                                that the vehicle is a registered private two wheeler know more
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <p>
                                                As per IRDAI guidelines, you will be required to complete your KYC after
                                                payment for successful policy issuance
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <button class="btn btn-lg btn-primary" id="btn">
                                                Pay Now ₹1,180
                                            </button>
                                        </div>


                                    </div>
                                </div>

                                <div class="tabcontent" *ngIf="paymentType === 'wallet'">

                                    <div class="row">
                                        <h4>
                                            wallet
                                        </h4>
                                        <div class="col-md-6 mt-2">
                                            
                                            <div class="card p-3 crd-shadow">
                                                
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/Axis.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                AXIS</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/icici.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                ICICI</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/IDBI.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                IDBI</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/kotak.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                Kotak Mahindra</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/standard.jpg" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                Standard Chartered </b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2 ">
                                            <div class="card p-3 crd-shadow">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img src="../../../../assets/images/SBI.png" class="icon"
                                                            alt="">
                                                    </div>
                                                    <div class="col-md-5 p-1 text-start">
                                                        <span><b>
                                                                SBI</b>
                                                        </span>
                                                    </div>
                                                    <div class="form-check col-md-3">
                                                        <input class="form-check-input chbox" type="radio" value=""
                                                            id="flexCheckIndeterminate" name="bank">

                                                    </div>
                                                </div>
                                            </div>
                                        </div><div class="col-md-12 mt-5">
                                            <p>
                                                By clicking on pay now i agree to the Terms & Conditions and confirm
                                                that the vehicle is a registered private two wheeler know more
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <p>
                                                As per IRDAI guidelines, you will be required to complete your KYC after
                                                payment for successful policy issuance
                                            </p>
                                        </div>
                                        <div class="col-md-12">
                                            <button class="btn btn-lg btn-primary" id="btn">
                                                Pay Now ₹1,180
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>