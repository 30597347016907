<form id="PostForm" name="PostForm" autocomplete="off" action="https://heapp21.hdfcergo.com/UAT/OnlineProducts/CCPGISUBSCRIBER/MakePayment.aspx" method="post" >
<input type="hidden" name="Trnsno" [value]="HDFCDetails.trnsno"/>
<input type="hidden" name="Amt" [value]="HDFCDetails.amt"/>
<input type="hidden" name="Appid" [value]="HDFCDetails.appid"/>
<input type="hidden" name="Subid" [value]="HDFCDetails.subid"/>
<input type="hidden" name="Surl" [value]="HDFCDetails.surl"/>
<input type="hidden" name="Furl" [value]="HDFCDetails.furl"/>
<input type="hidden" name="Src" [value]="HDFCDetails.src"/>
<input type="hidden" name="Chksum" [value]="HDFCDetails.chksum"/>
<input type="submit" value="Submit">
</form>
