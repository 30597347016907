import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse, ApiResponseNew } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) {
  }

  async SignUpUser(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}Registration/SignUpUserInternal`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  async CheckPhoneExists(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/CheckPhoneExistsInternal`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }

  async CheckUserForSignup(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/CheckUserForSignup`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }  

  async SendOtp(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/SendOtp`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }

  async ValidateOTP(data: any) {
    return this.http.post<ApiResponseNew>(`${environment.APIUrl}login/ValidateOTPInternal`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponseNew) => {
      let res = new ApiResponseNew();
      res.code = 0;
      res.message = errorRes.message;
      return res;
    });
  }
  

  async SignUpUserotp(data: any) {
    const url = environment.APIUrl + 'Registration/SignUpMobileOtp';
    return this.http.post<ApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async AgentSignUpUser(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}agent/AgentSignUpUser`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('NoAuthorization', 'True')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  async UpdateAgentInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}agent/UpdateAgentInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }



}
