import { Component } from '@angular/core';

@Component({
  selector: 'app-senior-health-policy',
  templateUrl: './senior-health-policy.component.html',
  styleUrls: ['./senior-health-policy.component.css']
})
export class SeniorHealthPolicyComponent {

}
