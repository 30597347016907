import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { UserService } from '../Services/user.service';
import { AppConfig } from '../Shared/AppConfig';
import { LoginService } from './../Services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../Services/profile.service';
import { FamilyMembers, PropertyDetails, RelationShips, Vehicles } from '../Models/Profile.model';
import { LookUpViewModel } from './../Models/vehicle-component.model';
import { VehicleService } from '../Services/vehicle.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {
  vehicleInfoForm: FormGroup;
  propertyInfoForm: FormGroup;
  vehiclesDetails: Vehicles[] = [];
  propertyDetails: PropertyDetails[] = [];
  selectedType: string = 'Vehicle';
  IsProfile: boolean = false;
  title: string = "Add New Assets";

  constructor(private fb: FormBuilder, private userservice: UserService, private vehicleService: VehicleService,
    private loginService: LoginService, private route: Router, private profileService: ProfileService, private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService) {

    this.activatedRoute.url.subscribe(urlSegments => {
      this.IsProfile = urlSegments[0].path.toLowerCase() === 'profile' ? true : false;
    });
    if (!this.IsProfile) {
      this.title = "Add New Vehicle";
    }
    else {
      this.title = "Add New Asset";
    }


  }

  async ngOnInit() {
    await this.GetVehiclesDetails();
    await this.GetPropertyDetails();
  }



  async GetVehiclesDetails() {
    this.spinner.show();
    this.vehiclesDetails = [];
    const response = await this.profileService.GetVehiclesDetails();
    if (response?.isSuccess) {
      this.vehiclesDetails = response.result?.vehicles;
    }
    this.spinner.hide();
  }

  async GetPropertyDetails() {
    this.propertyDetails = [];
    const response = await this.profileService.GetPropertyDetails();
    if (response?.isSuccess) {
      this.propertyDetails = response.result?.userProperties;
    }
  }

  selectedCard(value: string) {
    this.selectedType = value;

  }

  onClickButton(event: Event) {
    console.log('Button clicked!', event);
    // Add your logic here
  }
 
}
