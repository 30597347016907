import { Component } from '@angular/core';

@Component({
  selector: 'app-car-insurance',
  templateUrl: './car-insurance.component.html',
  styleUrls: ['./car-insurance.component.css']
})
export class CarInsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
