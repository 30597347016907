import { Component } from '@angular/core';

@Component({
  selector: 'app-insurance-banner',
  templateUrl: './insurance-banner.component.html',
  styleUrls: ['./insurance-banner.component.css']
})
export class InsuranceBannerComponent {

  isBannerShow: boolean=true;

  showBanner(){
    //alert('hi');
    this.isBannerShow = false;
  }

  imageObject: Array<object> = [{
    image: '../../assets/images/banner/01 Motor Insurance.jpg',
    thumbImage: '../../assets/images/banner/01 Motor Insurance.jpg',
    order: 1,
  }, {
    image: '../../assets/images/banner/02 Health Insurance.jpg',
    thumbImage: '../../assets/images/banner/02 Health Insurance.jpg',
    order:2,
  },{
    image: '../../assets/images/banner/03 Life Insurance.jpg',
    thumbImage: '../../assets/images/banner/03 Life Insurance.jpg',
    order: 3,
  },{
    image: '../../assets/images/banner/04 Term Insurance.jpg',
    thumbImage: '../../assets/images/banner/04 Term Insurance.jpg',
    order: 4,
  },{
    image: '../../assets/images/banner/05 Saving Plans.jpg',
    thumbImage: '../../assets/images/banner/05 Saving Plans.jpg',
    order: 5,
  },{
    image: '../../assets/images/banner/06 Family Protection.jpg',
    thumbImage: '../../assets/images/banner/06 Family Protection.jpg',
    order: 6,
  },{
    image: '../../assets/images/banner/07 Insurance Support.jpg',
    thumbImage: '../../assets/images/banner/07 Insurance Support.jpg',
    order: 7,
  },{
    image: '../../assets/images/banner/08 Best Price Insurance.jpg',
    thumbImage: '../../assets/images/banner/08 Best Price Insurance.jpg',
    order: 8,
  }
  ];

}
