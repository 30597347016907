import { Component } from '@angular/core';

@Component({
  selector: 'app-agentnavbar',
  templateUrl: './agentnavbar.component.html',
  styleUrls: ['./agentnavbar.component.css']
})
export class AgentnavbarComponent {

}
