<div class="content-box bg-grey1" [ngClass]="!isUserLogged ? '' : 'user-dashboard'">
    <div class="container-fluid" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}" [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="container "  style="background-color: #F3F3F4">
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="card mt-3 p-2">
                            <div class="row" *ngIf="kycStatus=='true'">
                                <div class="col-md-12 text-center">
                                    <img src="../../../../assets/images/success.jpg" alt="">
                                    <h4 style="color: green;">KYC Completed Successfully</h4>
                                    <span class="font-body">Please close this and continue your proposal</span>
                                </div>
                            </div>
                            <div class="row" *ngIf="kycStatus=='false'">
                                <div class="col-md-12 text-center">
                                    <img src="../../../../assets/images/wrong.png" alt="">
                                    <h4 style="color: rgb(244, 10, 10);">KYC Failed</h4>
                                    <span class="font-body">We can't process your KYC, Please try again.</span>
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
