<div class="login-container d-flex flex-root" id="kt_app_root">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
    type="ball-spin-clockwise-fade-rotating"></ngx-spinner>

  <div class="section d-flex justify-content-center">
    <div class="login">
      <div class="card-body d-flex flex-column">
        <div class="logo">
          <a href="/" class="link-primary" routerLink="/"><img src="../../assets/images/logo.svg" alt="" class="logo"></a>
        </div>
        <div class="signup-form flex-center flex-column-fluid">
          <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="signupForm">
            <div *ngIf="isSignup">
              <div class="text-center mb-11">
                <h3>Signup</h3>
              </div>
              <div class="fv-row mb-7">
                <label class="input-label required" for="mobile">Mobile Number</label>
                <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                  [searchCountryFlag]="false" [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [maxLength]="10"
                  [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode" customPlaceholder="Enter your mobile number"
                  name="phone" formControlName="phoneNumber" [inputId]="'phone_number'"
                  (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

                </ngx-intl-tel-input>
              </div>
              <div class="d-grid mb-5 mt-5">
                <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="PhoneExists()"
                  [disabled]="signupForm.invalid || ServicesDown">
                  <span class="indicator-label">Continue</span>
                </button>
              </div>
              <div class="text-center">
                <span> Already have an account?
                <a class="link-primary" routerLink="/login">Sign In Here</a></span>
              </div>
            </div>
            <div *ngIf="isOtp" style="margin-left: 3rem;">
              <div class="text-center mb-6">
                <h3>Verification</h3>
                <h6 class="font-body">Enter 6 digit code that sent to your number<br> {{phoneNumber}}. <a class="link-primary" (click)="changemobile()">Click
                    here</a> to change your<br> mobile number.</h6>
              </div>
              <div class="fv-row mb-5">
                <label class="input-label required" for="mobile">Enter OTP</label>
                <div class="fields_group">
                  <input placeholder="Enter OTP" name="number" autocomplete="off" class="form-control bg-transparent otp_filed" formControlName="otp" style="width: 29rem;" maxlength="6" minlength="6" pattern="[0-9]{6}" oninput="this.value=this.value.replace(/[^0-9]/g,'');" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />
                <span class="timerCounter" *ngIf="timerSpanValue">{{ timerValue }} Secs</span>
                </div>
              </div>
              <div class="text-center">
                <span>If you didn't receive a code! 
                <a class="link-primary" (click)="sendOtp()"> Resend</a></span>
              </div>
              <div class="d-grid mb-5 mt-5">
                <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="validateOtp()"
                  [disabled]="signupForm.invalid" style="width: 29rem;">
                  <span class="indicator-label">Submit</span>
                </button>
              </div>
              <div class="text-center">
                <span>By creating your account, you agree to<br> our
                <a routerLink="/terms-of-use" class="link-primary">Terms & Conditions</a></span>
              </div>
            </div>
            <div *ngIf="isNewUser">
              <div class="text-center mb-11">
                <h3>Basic Details</h3>
              </div>
              <div class="d-flex fv-row mb-1">
                <div class="mt-3 mx-2">
                  <label class="input-label required" for="firstName">First Name</label>
                  <input type="text" placeholder="First Name" name="firstName" autocomplete="off"
                         class="new-user form-control bg-transparent" formControlName="firstName" />
                </div>
                <div class="mt-3 mx-2">
                  <label class="input-label required" for="lastName">Last Name</label>
                  <input type="email" placeholder="Last Name " name="lastName" autocomplete="off"
                         class="new-user form-control bg-transparent" formControlName="lastName" />
                </div>
              </div>
              <div class="d-flex fv-row mb-1">
                <!-- <div class="col">
                  <label class="input-label required" for="name">Your Name</label>
                  <input type="text" placeholder="User Name" name="name" autocomplete="off"
                         class="new-user form-control bg-transparent" formControlName="userName" />
                </div> -->
                <div class="mt-3 mx-2 w-100">
                  <label class="input-label required" for="email">Email Address</label>
                  <input type="email" placeholder="Email Address" name="email" autocomplete="off"
                         class="new-user form-control bg-transparent" formControlName="userEmail" />
                </div>
              
                <div class="mt-3 mx-2 w-100">
                  <label class="input-label required" for="dob">Date of birth</label>
                  <input type="date" placeholder="DD-MM-YYYY"  name="dob" autocomplete="off" class="new-user form-control bg-transparent" min="1950-01-01" max="2030-01-01"
                         formControlName="dob" [(ngModel)]="dob" />
                </div>
                </div>
              <div class="d-flex fv-row mb-1">
                <div class="mt-3 mx-2" [hidden]="isReff">
                  <label class="input-label" for="address">Refferal Code</label>
                  <input placeholder="Enter Refferal Code"  autocomplete="off"
                         class="new-user form-control bg-transparent" formControlName="referralCode" />
                </div>
              </div>
              <div class="d-flex fv-row row mb-1">
                <div class="col mt-3">
                  <label class="input-label required" for="gender">Gender</label> &nbsp; &nbsp;
                  <input class="my-3" type="radio" id="male" name="gender" value="Male" formControlName="gender"> Male
                  &nbsp;&nbsp;
                  <input class="my-3" type="radio" id="female" name="gender" value="Female" formControlName="gender">
                  Female &nbsp;&nbsp;
                  <input class="my-3" type="radio" id="other" name="gender" value="Other" formControlName="gender">
                  Other
                </div>
              </div>
              <!-- <div class="d-flex fv-row mb-1">
                <div>
                  <label class="input-label required" for="address">Street Address</label>
                  <input placeholder="Enter Address" name="address" autocomplete="off"
                         class="new-user form-control bg-transparent" formControlName="streetAddress" />
                </div>&nbsp;&nbsp;
                <div>
                  <label class="input-label required" for="pincode">Your Pincode</label>
                  <input type="number" placeholder="Enter Pincode" name="pincode" autocomplete="off" #pinCode
                         (blur)="pincodeUserChangeEvent(pinCode)" class="new-user form-control bg-transparent"
                         formControlName="pincodeId" />
                  <label> {{pincodeMaster.district}} {{pincodeMaster.state}} {{pincodeMaster.country}}</label>
                </div>
              </div> -->
              <div class="d-flex fv-row mb-1">
                <div class="mt-3 mx-2">
                  <label class="input-label required" for="passcode">Enter new password</label>
                  <input [type]="this.passwordType" placeholder="Enter new password" name="passcode" autocomplete="off" input [type]=" showPassword ? 'text' : 'password' "
                         class="new-user form-control bg-transparent" formControlName="passcode" />
                  <img src="../../assets/images/banner/eyeslash.png" class="field-icon" (click)=" showHidePassword()" *ngIf="isView" />
                  <img src="../../assets/images/banner/eye.png" class="field-icon" (click)=" showHidePassword()" *ngIf="isNoView" />
                  
                </div>
                <div class="mt-3 mx-2">
                  <label class="input-label required" for="confirmPassword">Confirm new password</label>
                  <input [type]="this.passwordType" placeholder="Confirm new password" name="confirmPassword"
                         autocomplete="off" class="new-user form-control bg-transparent" formControlName="confirmPassword" />
                         <img src="../../assets/images/banner/eye.png" class="field-icon" (click)="ShowPassword()" *ngIf="isView" />
                         <img src="../../assets/images/banner/eyeslash.png" class="field-icon" (click)="ShowPassword()" *ngIf="isNoView" />
                  <!-- <i class="fa fa-eye-slash field-icon" (click)="ShowPassword()" aria-hidden="true"></i> -->
                </div>
              </div>
              <div class="d-grid mb-10 mt-3 justify-content-center">
                <button class="btn btn-primary" style="width: 296px; height: 40px;" type="submit" id="kt_sign_in_submit" (click)="submit()"
                        [disabled]="signupForm.invalid">
                  <span class="indicator-label">Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="footer_copy_right Copyright">
          <span >© Copyright {{currentYear}} Click4bima. All Rights Reserved</span>
        </div>
      </div>
    </div>
  </div>
  <div class="service card shadow rounded-5 bg-light">
    <app-carousel></app-carousel>
  </div>
</div>
<style>
  
  .section {
    width: 75% !important;
}
.service {
    width: 60% !important;
}
@media screen and (min-width: 992px) and (max-width: 1164px) {
    .section {
        width: 100% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .service {
        width: 70% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .footer_copy_right {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        padding: 20px 10px;
        left: 0;
        text-align: center;
        z-index: 11;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .section {
        width: 100% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .service {
        width: 70% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding: 0px !important;
    }
    .footer_copy_right {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        padding: 20px 10px;
        left: 0;
        text-align: center;
        z-index: 11;
    }
}
@media screen and (min-width: 601px) and (max-width: 767px) {
    .section {
        width: 100% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .service {
        width: 70% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .footer_copy_right {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        padding: 20px 10px;
        left: 0;
        text-align: center;
        z-index: 11;
    }
}
@media screen and (max-width: 600px) {
    .section, .service {
        width: 100% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .footer_copy_right {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #fff;
        padding: 20px 10px;
        left: 0;
        text-align: center;
        z-index: 11;
    }
}
</style>