import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.css']
})
export class HospitalComponent {
constructor(private route:Router){

}
verification(){
  this.route.navigate(['/hospital-varification']);
}
bankDetails()
{
  this.route.navigate(['/hospital-bankDetails']);
}
editPopup:boolean=false;
eidt(){
this.editPopup=true;
}
closePop()
{
  this.editPopup=false;
}
reload()
{
  location.reload();
}
tabs:string='1';
changeTab(pg:string)
{
  this.tabs=pg;
}
}

