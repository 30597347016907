<app-navbar></app-navbar>
<div class="container" >

   <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Taxi Insurance</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">Between the honks and the unending hustle-and-bustle of the city, driving a taxi becomes a cumbersome task. You need to focus on the road, the steering wheels, and the special instructions of your customers. While carrying passengers in a taxi, the vehicle is exposed to various risks and complications which can add to your financial liability.It becomes really difficult to get hold of everything without losing your cool and this leads to untoward situations and accidents. You should also keep in mind that your taxi is exposed to extreme wear and tear as it runs more than an average private car. As a result of which the statistical risk of an accident increases. Also, you must know that it is mandatory to have a valid taxi insurance policy if you drive or own one. Thus, to cover all such risks, you need a taxi insurance, which is a part of commercial vehicle insurance. The taxi insurance will ensure protection against accidents and any damage to the vehicle.</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why should I insure my Taxi with a Commercial Taxi Insurance ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If you or your organization does own any kind of commercial taxi, it is mandatory under Motor Vehicle Act to at least buy a Third Party liability only policy. It offers protection to you and the passengers if there is any loss or damage arising due to an accident or collision. A cab that comes with a valid insurance also assures your customers/passengers that you’re responsible and serious about your assets ,your life and passengers life as well.
                    </p>
                    <p class="font-body">Businesses always carry  risks and if you’re an asset-heavy business with many taxis, the risk only magnifies. In such cases, it is wise to purchase a standard or comprehensive package policy for your taxis or cabs as it will protect your commercial taxi and the owner-driver from any losses caused due to natural calamities, accidents, terrorist activities, fires, thefts and malicious acts, among other unforeseen circumstances.</p>
                    <p class="font-body">An insured taxi or cab ensure that you or your business doesn’t face any unplanned losses or downtime, which means you can instead spend your money and time towards your business growth.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of Taxi Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Based on the requirement of your cab or taxi, there are two types of policies. However, considering the risk and frequent use of such commercial vehicles, it’s always recommended to take a Standard/Comprehensive Package Policy that will financially protect the taxi and the owner-driver as well. Now let understand what these are:
                    </p>
                    <p class="font-body">1. Indemnity for third party property damage</p>
                    <p class="font-body">2. Personal accident cover for the policyholder</p>
                    <p class="font-body">Comprehensive Insurance Cover: This policy cover not mandatory by law but it choosing a Comprehensive Insurance cover  provides higher level of benefits to both the car owner and the third party involved in the accident. This policy covers the following:</p>
                    <p class="font-body">1. Damage to your own car caused due to fire, explosions, burglary, riots, strikes and accidents</p>
                    <p class="font-body">2. Personal accidental cover for the car owner is mandatory provided by the insurance provider. The policyholder might also include passengers, paid driver and cleaner within the insurance</p>
                    <p class="font-body">3. Accidental damage, deaths and injuries to the third party car</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of Risks Covered under Commercial Taxi Insurance Policy</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Add-on Covers Under Commercial Taxi Insurance</p>
                    <p class="font-body">Damage to electrical and non-electrical parts of the taxi</p>
                    <p class="font-body">Additional personal accident cover for driver</p>
                    <p class="font-body">Legal liabilities of the paid driver in connection to the insured taxi</p>
                    <p class="font-body">Additional cover for damage to headlights, bonnets and bumpers</p>
                    <p class="font-body">Legal liability regarding non-fare paying passengers</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What is not covered in Commercial Taxi Insurance ?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">It is equally important to know what’s not covered in your Commercial Taxi Insurance policy so that there are no surprises when you make a claim. Here are some cases where policy does not cover any claim arise: </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Own Damages for Third-Party Policy holder</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">In the case of a Third-Party Liability Only Policy,any damages/ losses arrises  to own vehicle won’t be covered.
                        Drunk Riding or without a Licence: The occurrence of an accident while driving with an invalid driving license or under the consumption of alcohol.
                        Contributory negligence :  Any damage caused due to contributory negligence of the driver (such as driving when there’s an existing flood)                          
                        Consequential Damages :  Any damage that is not a direct result of the accident/natural calamity i.e., consequential losses like loss of earnings, loss of market, etc.                      
                        Wear and tear of the commercial vehicle: Any loss/ damages of wear and tear of commercial vehicle does not cover in this policy.                       
                        Contractual liabilities are also not covered: Contractual liabilities are also not covered under this policy.                      
                        Inactive policy: In case, the policy is not active at the time of accident ar loss.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
