import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { GeneralService } from 'src/app/Services/general.service';
import { PolicyService } from 'src/app/Services/policy.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { CustomerPolicyListPopoverComponent } from '../customer-policy-list-popover/customer-policy-list-popover.component';
import { ExportService } from 'src/app/Services/export/export.service';
import { LookUpService } from 'src/app/Services/lookup.service';
@Component({
  selector: 'app-customer-policy-list',
  templateUrl: './customer-policy-list.component.html',
  styleUrls: ['./customer-policy-list.component.css']
})
export class CustomerPolicyListComponent {
  CustomerPolicyList:any =[];
  agentList:any;
  agentName:string = "";
  filterForm: FormGroup;
  todayDate = new Date();
  yearList : any = [];
  monthList:{text:string,value:number}[] = [];
  showPolicyList:{text:string,value:number}[] = [{text:"Both",value:2},{text:"External",value:1},{text:"Internal",value:0}];
  currentYear: number =this.todayDate.getFullYear();
  currentMonth: number = this.todayDate.getMonth() + 1;  
  monthNameList: {text:string,value:number}[] = [{text:'Jan',value:1},{text:'Feb',value:2},{text:'Mar',value:3},{text:'Apr',value:4},{text:'May',value:5},{text:'Jun',value:6},{text:'Jul',value:7},{text:'Aug',value:8},{text:'Sep',value:9},{text:'Oct',value:10},{text:'Nov',value:11},{text:'Dec',value:12}];

  defaultColDef: { resizable: boolean; sortable: boolean; };
  paginationPageSize: number;
  columnDefsPolicy: any[];
  gridOptions = {
    context: {
      componentMember: this
    }
  };
  insurancePartnersList :AutoCompleteItem[]=[];
  policyCategory = [
    { id:"1",text : "Car"},
    { id:"2",text : "Bike"},
    { id:"3",text : "Commercial"},
    { id:"4",text : "Health"},
    { id:"5",text : "Health Family"},
    { id:"6",text : "Travel"},
    { id:"7",text : "Home"},
    { id:"8",text : "Office"},
    { id:"9",text : "Shop"},
    { id:"10",text : "General"}
  ];
constructor(private agentprofileService : AgentprofileService,private spinner: NgxSpinnerService,private router: Router,private generalService :GeneralService,private fb: FormBuilder,private policyService:PolicyService,private exportService:ExportService,private _lookupService:LookUpService )
{  
  this.gridOptions = {
    context: {
      componentMember: this
    }
  };
}

ngOnInit(){
  this.filterForm = this.fb.group({
    year: [this.currentYear, Validators.required], 
    month: [this.currentMonth, Validators.required],
    agentId: [this.generalService.getUniqueuserid(), Validators.required],
    showPolicy: [2, Validators.required],
  });

  for (var i = 2020; i <= this.currentYear + 1; i++) {
    this.yearList.push(i);
  }
  this.loadCustomerList();
  this.loadAgentList();
  this.loadYearMonthList();

  this.loadGridSettings();
  this.GridFieldsLoad();
  this.loadInsurancePartners();
}

async loadGridSettings(){
  this.columnDefsPolicy = [];
  this.defaultColDef = { resizable: true, sortable: true };
  this.paginationPageSize = 10;
}

onGridReadyCustomerList(param:any) {
  this.loadCustomerList();
}

async GridFieldsLoad() {
  let Action = 'Action';
  let insuranceCompany = "Ins. Company";
  let policyCategory = "Policy Category";
  let isExternalPolicy = "Policy";
  let fullName = "Full Name";
  let planName = "Plan Name";
  let registrationNumber = "Reg. Number";
  let quoteId = "Quote Id";
  let policyNumber = "policy Number";
  let phoneNumber = "Phone No.";
  let city = "City";
  let pincode = "Pincode";
  let startDate = "Start Date";
  let endDate = "End Date";
  let premium = "Premium";
  let channel = "Channel";
  let agentCommissionPoints = "Commission Points";

  let columnDefs = [
    { headerName: Action, field: "policyIdEnc", headerTooltip: Action, cellRenderer: CustomerPolicyListPopoverComponent, "width": 80, "minWidth": 80, sortable: false, cellClass:["text-center"] },
    { headerName: insuranceCompany, field: "insuranceCompany", headerTooltip: insuranceCompany, cellRenderer: this.companyCellRender, autoHeight: true, suppressSizeToFit:true,cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly","h-48"], "width": 120, "minWidth": 120, },
    { headerName: policyCategory, field: "policyCategory", headerTooltip: policyCategory, cellRenderer: this.categoryCellRender, autoHeight: true,cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], "width": 130, "minWidth": 130, },
    { headerName: isExternalPolicy, field: "isExternalPolicy", headerTooltip: isExternalPolicy, cellRenderer: this.policyCellrender, autoHeight: true, suppressSizeToFit:true,cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], "width": 100, "minWidth": 100, },
    { headerName: fullName, field: "fullName", headerTooltip: fullName, cellRenderer: this.nameCellrender, autoHeight: true, suppressSizeToFit:true,cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], "width": 180, "minWidth": 180, },
    { headerName: planName, field: "planName", headerTooltip: planName, autoHeight: true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 180, "minWidth": 180 },
    { headerName: registrationNumber, field: "registrationNumber", headerTooltip: registrationNumber, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 130, "minWidth": 130},
    { headerName: quoteId, field: "quoteId", headerTooltip: quoteId, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 200, "minWidth": 200 },
    { headerName: policyNumber, field: "policyNumber", headerTooltip: policyNumber, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 200, "minWidth": 200},
    { headerName: phoneNumber, field: "phoneNumber", headerTooltip: phoneNumber, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 120, "minWidth": 120 },
    { headerName: city, field: "city", headerTooltip: city, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 120, "minWidth": 120},
    { headerName: pincode, field: "pincode", headerTooltip: pincode, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 90, "minWidth": 90 },
    { headerName: startDate, field: "startDate", headerTooltip: startDate, cellRenderer: this.startDateCellRender, autoHeight: true, suppressSizeToFit:true,cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], "width": 120, "minWidth": 120, },
    { headerName: endDate, field: "endDate", headerTooltip: endDate, cellRenderer: this.endDateCellRender, autoHeight: true, suppressSizeToFit:true,cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], "width": 120, "minWidth": 120, },
    { headerName: premium, field: "premium", headerTooltip: premium, cellRenderer: this.premiumCellRender, autoHeight: true, suppressSizeToFit:true,cellClass: ["ag-focus", "text-capitalize", "clippingtext", "checkbox-readonly"], "width": 120, "minWidth": 120, },
    { headerName: channel, field: "channel", headerTooltip: channel, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 90, "minWidth": 90 },
    { headerName: agentCommissionPoints, field: "agentCommissionPoints", headerTooltip: agentCommissionPoints, autoHeight: true, suppressSizeToFit:true, cellClass: ["ag-focus", "text-capitalize", "clippingtext"], "width": 150, "minWidth": 150 },
  ];

  this.columnDefsPolicy = columnDefs;
}

companyCellRender(param: any) {
  return `<img src="/assets/images/insurance_companies/${param.data.insuranceCompany}.png" class="img-fluid h-48" />`;
}

categoryCellRender(param: any) {
  return `<img img src="../../assets/svgIcon/policyvehicle/${param.data.policyCategory}.svg" class="img-fluid">`;
}

policyCellrender(param:any) {
  if (param.data.isExternalPolicy) {
    return `Offline`;
  }
  else {
    return 'Online';
  }
}

nameCellrender(param: any) {
  return `${param.data.firstName} ${param.data.lastName}`;
}

startDateCellRender(param:any){
  return `${moment(param.data.startDate).format('DD MMM, YYYY')}`;
}

endDateCellRender(param:any){
  return `${moment(param.data.endDate).format('DD MMM, YYYY')}`;
}

premiumCellRender(param:any){
  return `${param.data.premium}`;
}



async loadCustomerList(){
  let response = await this.agentprofileService.GetCustomerByAgentIdMonthYear(parseInt(this.filterFormControl['year'].value) , parseInt(this.filterFormControl['month'].value),this.filterFormControl['agentId'].value,this.filterFormControl['showPolicy'].value,);
  if (response?.isSuccess && response.result) {
    this.CustomerPolicyList = response.result;
  }
}

async loadAgentList(){
  let res = await this.agentprofileService.AgentDropDown();
  if (res != null && res.result) {
    this.agentList = res.result.map((item:any)=> {return {text:item.name,value:item.uniqueUserId,id:item.uniqueUserId}});
    let selectedAgent = this.agentList.filter((f:any)=>{return f.id == this.generalService.getUniqueuserid()}).map((m:any)=>{return m.text});        
    this.agentName = selectedAgent != null && selectedAgent != undefined && selectedAgent.length > 0 ?  selectedAgent[0] : "";
  }
}

get filterFormControl() {
  return this.filterForm.controls;
}

onAgentSelection(data:AutoCompleteItem)
{
  this.filterForm.get('agentId')?.setValue(data.id);
}

loadYearMonthList(){
  this.monthList = this.monthNameList;
  // this.monthList = [];
  // if(parseInt(this.filterFormControl['year'].value) == this.currentYear){
  //   for (var i = 0; i < this.currentMonth; i++) {
  //     this.monthList.push(this.monthNameList[i]);
  //   }
  // }
  // else
  // {
  //   this.monthList = this.monthNameList;
  // }  
}

addPolicy(){
  this.router.navigateByUrl('/ImportPolicy',{ state: { id:"0" }});
}

purchasePolicy(){
  this.router.navigate(['/']);
}

edit(policyId:any){
  this.router.navigateByUrl('/ImportPolicy',{ state: { id:policyId }});
}

async downloadPolicy(policyId:any){
  this.spinner.show();
  const response = await this.policyService.DownloadPolicy(policyId);
  if (response?.isSuccess && response.result && response.result.code ==1) 
  {
    const downloadLink = document.createElement('a');
    const fileName = response.result.data2??'policy.pdf';
    downloadLink.href = response.result.data;
    downloadLink.download = fileName;
    downloadLink.target = "_blank";
    downloadLink.click();
    this.spinner.hide();
  }
  else
    {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: "Policy document is not generated yet, please try after some time.",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      }); 
    }
  }

  onBtExport() {
    this.spinner.show();
    let exportData: { "Insurance Company": string; "Policy Category": string; Policy: any; "Full Name": string; "Plan Name": any; "Reg. Number": any; "Quote Id": any; "policy Number": any; "Phone Number": any; City: any; Pincode: any; "Start Date": any; "End Date": any; Premium: any; Channel: any; "Commission Points": any; }[] = []
    this.CustomerPolicyList.forEach((element: { insuranceCompany: any; policyCategory: any; isExternalPolicy: any; firstName: any; lastName: any; planName: any; registrationNumber: any; quoteId: any; policyNumber: any; phoneNumber: any; city: any; pincode: any; startDate: any; endDate: any; premium: any; channel: any; agentCommissionPoints: any; }) => {
      exportData.push({
        "Insurance Company":this.insurancePartnersList.filter((f:AutoCompleteItem)=>{return f.id == element.insuranceCompany})?.map((m:AutoCompleteItem)=>{return m.text})?.toString(),
        "Policy Category":this.policyCategory.filter((f:any)=>{return f.id == element.policyCategory})?.map((m:any)=>{return m.text})?.toString(),
        "Policy":element.isExternalPolicy == true ? 'Offline' : 'Online',
        "Full Name":element.firstName + " " + element.lastName,
        "Plan Name":element.planName,
        "Reg. Number":element.registrationNumber,
        "Quote Id":element.quoteId,
        "policy Number":element.policyNumber,
        "Phone Number":element.phoneNumber,
        "City":element.city,
        "Pincode":element.pincode,
        "Start Date":element.startDate,
        "End Date":element.endDate,
        "Premium":element.premium,
        "Channel":element.channel,
        "Commission Points":element.agentCommissionPoints
      });
    });
    if(exportData?.length > 0 ){
      let fileName = 'PolicyReport_' + moment(new Date()).format("YYYYMMDDhhmmss") + '.xlsx';
      this.exportService.exportToExcel(exportData, fileName);
      this.spinner.hide();
    }
    else
    {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: "No data to export!",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      }); 
    }
  }

  async loadInsurancePartners()
  {
    let response = await this._lookupService.GetInsurancePartnersList('a');
      if (response?.isSuccess) {
        this.insurancePartnersList = response.result.map((m:any)=> {return {text:m.insuranceName,value:m.insuranceId,id:m.insuranceId}});
      }
  }
}
