<app-navbar *ngIf="isReferal"></app-navbar>
<div [ngClass]="{'container' : isReferal}">
  <div [ngClass]="{'container-fluid' : isReferal}">
    <div class="insurance-tab row px-0" [ngClass]="!isUserLogged ?  (isReferal ? 'user-dashboard position-relative mt-0' : '') : 'user-dashboard position-relative mt-0'">
      <div class="col-md-12 mb-1">
          <!-- <form action="#" #vehical_details="ngForm" class="vehicle-form"> -->
            <div class="row card m-0 flex-row  py-2">
              <div class="col-auto d-flex align-items-center">
                <button type="button" class="btn btn-primary px-1 py-1"><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft" class="cursor-pointer svg_white edit-icon" (click)="goTo()"></button>
              </div>
              <div class="col">
                <div class="row justify-content-between vehicle-form">
                  <ng-container *ngIf="true">
                    <div class="col-auto text-wrap mt-lg-0 mt-2">
                      <label for="" class="mb-md-0 mb-1">Manufacturer</label>
                      <span title="{{vehicleData.make}}">{{vehicleData.make}}</span>
                    </div>
                    <div class="col-auto mt-lg-0 mt-2 text-wrap">
                      <label for="" class="mb-md-0 mb-1">Model</label>
                      <span title="{{vehicleData.model}}">{{vehicleData.model}}</span>
                    </div>
                    <div class="col-auto mt-lg-0 mt-2 text-wrap">
                      <label for="" class="mb-md-0 mb-1">Variant</label>
                      <span title="{{vehicleData.variant}}">{{vehicleData.variant}}</span>
                    </div>
                    <div class="col-auto mt-lg-0 mt-2 text-wrap" *ngIf="vehicleData.registrationNumber != undefined && vehicleData.registrationNumber != null && vehicleData.registrationNumber != ''">
                      <label for="" class="mb-md-0 mb-1">Reg. Number</label>
                      <span title="{{vehicleData.registrationNumber}}">{{vehicleData.registrationNumber}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="vehicleData.registrationNumber == undefined || vehicleData.registrationNumber == null || vehicleData.registrationNumber == ''">
                    <div class="col-auto mt-lg-0 mt-2 text-wrap">
                      <label for="" class="mb-md-0 mb-1">City</label>
                      <span title="{{vehicleData.city}}">{{vehicleData.city}}</span>
                    </div>
                    <div class="col-auto mt-lg-0 mt-2 text-wrap">
                      <label for="" class="mb-md-0 mb-1">RTO</label>
                      <span title="{{vehicleData.rto}}">{{vehicleData.rto}}</span>
                    </div> 
                  </ng-container>
                  <div class="col-auto mt-lg-0 mt-2 text-wrap">
                    <label for="" class="mb-md-0 mb-1">CC</label>
                    <span title="{{vehicleData.engineCapacityAmount}}">{{vehicleData.engineCapacityAmount}}</span>
                  </div>
                  <div class="col-auto mt-lg-0 mt-2 text-wrap">
                    <label for="" class="mb-md-0 mb-1">Fuel Type</label>
                    <span title="{{vehicleData.fuelType}}">{{vehicleData.fuelType}}</span>
                  </div>
                  <div class="col-auto mt-lg-0 mt-2 text-wrap">
                    <label for="" class="mb-md-0 mb-1">Year</label>
                    <span title="{{vehicleData.registrationYear}}">{{vehicleData.registrationYear}}</span>
                  </div>
                  <ng-container *ngIf="this.insuranceformData.vehicleType == 3">
                    <div class="col-auto"><label for="" class="mb-md-0 mb-1">Category</label> {{this.vehicleData.category}}</div>
                    <div class="col-auto"><label for="" class="mb-md-0 mb-1">Permit Type</label> {{this.vehicleData.permit}}</div>
                    <div class="col-auto" *ngIf="this.vehicleData.category == 'PCV'"><label for="" class="mb-md-0 mb-1">PCV Vehicle Type</label> {{this.vehicleData.pcVehicle}}</div>
                    <div class="col-auto" *ngIf="this.vehicleData.category == 'GCV'"><label for="" class="mb-md-0 mb-1">GCV Vehicle Type</label> {{this.vehicleData.gcVehicle}}</div>
                    <div class="col-auto" *ngIf="this.vehicleData.category == 'MISC'"><label for="" class="mb-md-0 mb-1">Misc Vehicle Type</label> {{this.vehicleData.miscVehicle}}</div>
                    <div class="col-auto" *ngIf="this.vehicleData.category == 'PCV' && this.vehicleData.pcVehicle?.toUpperCase() == 'BUS'"><label for="" class="mb-md-0 mb-1">Seating Capacity</label> {{this.vehicleData.seatingCap}} Seater</div>
                    <div class="col-auto" *ngIf="this.vehicleData.category == 'PCV' && this.vehicleData.pcVehicle?.toUpperCase() == 'BUS'"><label for="" class="mb-md-0 mb-1">Purpose</label> {{this.vehicleData.purpose}}</div>
                    <div class="col-auto" *ngIf="this.vehicleData.category == 'GCV' || this.vehicleData.category == 'MISC'"><label for="" class="mb-md-0 mb-1">GVW</label> {{this.vehicleData.gVW}} Ton</div>
                  </ng-container>
                </div>
              </div>
              <div class="col-auto d-flex align-items-center justify-content-end mt-lg-0 mt-2">
                <button type="button" class="btn btn-primary py-1 px-1" (click)="openPopupForm()"><img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="svg_white edit-icon cursor-pointer"></button>
                <!-- <button *ngIf="isPopupFormVisible" type="button" class="btn btn-primary px-1 py-1" (click)="updateVehicleDetailsForm(vehical_details.value)"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/FloppyDisk.svg" alt="Apply"></button>
                <button *ngIf="isPopupFormVisible" type="button" class="btn btn-warning px-1 py-1 ml-2 btn-active" (click)="closePopupForm()"><img class="svg_white edit-icon cursor-pointer" src="../../assets/svgIcon/close-secondary.svg" alt="Cancel"></button> -->
              </div>
            </div>
          <!-- </form>     -->
      </div>
        <div class="col-12 col-md-12 col-lg-4 col-xl-4 px-0 px-md-2">
          <div>
          <div class="card p-5 filter-section rounded-bottom-0 mx-0 mb-0">
            <form #rangeForm="ngForm">
              <ng-container *ngIf="showAccessAountSection()">
                <div class="justify-content-between d-flex align-items-center mb-3">
                    <h4 class="text-start mb-0">Claim Excess Amount <img src="/assets/images/Vector4.png" alt="" (click)="openAccessAountModal()" class="cursor-pointer ml-1 h-18px"></h4>
                    <span>₹ {{claimAccessAount}}</span>
                </div>
                <hr class=""/>
              </ng-container>
              <ng-container *ngIf="activeTab == 1">  
                <div class="justify-content-between d-flex">
                  <div>
                    <h4 class="text-start">Insured Declared value (IDV) <img src="/assets/images/Vector4.png" alt="" (click)="openIDVModal()" class="cursor-pointer ml-1 h-18px"></h4>
                  </div>
                </div>        
                <div class="row justify-content-between mt-1">
                  <div class="col-3">
                    <h6 class="text-main fw-medium">₹ {{minIdv??"0" | thousandSeparator}}</h6>
                    or enter value
                  </div>
                  <div class="col-6 range-wrap">
                    <div class="range-value" id="rangeV" [style.left]="bubble_left">
                      <!-- <span>{{currentIdv | thousandSeparator}}</span> -->
                    </div>
                    <input type="range" class="form-range text-main" min="{{minIdv}}" max="{{maxIdv}}" name="customRange" [(ngModel)]="currentIdv" value="{{currentIdv}}" id="customRange1" (input)="changeRange()">
                    <input type="number" class="form-control h-26px mt-2 py-0" value="{{currentIdv}}" (change)="changeIdv(txtIdv)" #txtIdv (keypress)="numberOnly($event)">
                  </div>
                  <div class="col-3 text-end fw-bolder">
                    <h6>₹ {{maxIdv??"0" | thousandSeparator}}</h6>
                  </div>
                </div>
                <hr class=""/>
                <ng-container *ngIf="this.insuranceformData.vehicleType == 3 && this.vehicleData.category == 'GCV' && this.minGvw > 0">
                  <div class="justify-content-between d-flex">
                    <div>
                      <h4 class="text-start">Gross Vehicle Weight</h4>
                    </div>
                  </div>        
                  <div class="row justify-content-between mt-1">
                    <div class="col-3">
                      <h6 class="text-main fw-medium">{{minGvw??"0" | thousandSeparator}} KG</h6>
                      or enter value
                    </div>
                    <div class="col-6 range-wrap">
                      <div class="range-value" id="rangeG" [style.left]="bubble_left_GVW">
                        <!-- <span>{{currentIdv | thousandSeparator}}</span> -->
                      </div>
                      <input type="range" class="form-range text-main" min="{{minGvw}}" max="{{maxGvw}}" name="customRange2" [(ngModel)]="currentGvw" value="{{currentGvw}}" id="customRange2" (input)="changeRangeGVW()">
                      <input type="number" class="form-control h-26px mt-2 py-0" value="{{currentGvw}}" (change)="changeGvw(txtGvw)" #txtGvw (keypress)="numberOnly($event)">
                    </div>
                    <div class="col-3 text-end fw-bolder">
                      <h6>{{maxGvw??"0" | thousandSeparator}} KG</h6>
                    </div>
                  </div>
                  <hr class=""/>
                </ng-container>
              </ng-container>
            </form>
          <ng-container *ngIf="activeTab == 1">  
            <ng-container *ngIf="this.vehicleData.isNew != true">
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start">Add-ons in Existing Policy <img src="/assets/images/Vector4.png" alt="" (click)="openExistingAddonModal()" class="cursor-pointer ml-1 h-18px"></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <span>
                    Zero/Nil Depreciation
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="nily" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isNilDepOptedInExistingPolicy'" [(ngModel)]="this.isNilDepOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="niln" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isNilDepOptedInExistingPolicy'" [(ngModel)]="this.isNilDepOptedInExistingPolicy"><span>No</span>
                  </span>
                </div>  
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <span>
                    Invoice Cover
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="invy" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isInvoiceCoverOptedInExistingPolicy'" [(ngModel)]="this.isInvoiceCoverOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="invn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isInvoiceCoverOptedInExistingPolicy'" [(ngModel)]="this.isInvoiceCoverOptedInExistingPolicy"><span>No</span>
                  </span>
                </div> 
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <span>
                    Consumables Cover
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="cony" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isConsumableOptedInExistingPolicy'" [(ngModel)]="this.isConsumableOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="conn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isConsumableOptedInExistingPolicy'" [(ngModel)]="this.isConsumableOptedInExistingPolicy"><span>No</span>
                  </span>
                </div>
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <span>
                    Engine and Gear Box Protect
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="cony" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isEngineCoverOptedInExistingPolicy'" [(ngModel)]="this.isEngineCoverOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="conn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isEngineCoverOptedInExistingPolicy'" [(ngModel)]="this.isEngineCoverOptedInExistingPolicy"><span>No</span>
                  </span>
                </div>
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <span>
                    Tyre Cover
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="cony" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isTyreCoverOptedInExistingPolicy'" [(ngModel)]="this.isTyreCoverOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="conn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isTyreCoverOptedInExistingPolicy'" [(ngModel)]="this.isTyreCoverOptedInExistingPolicy"><span>No</span>
                  </span>
                </div>      
              </div>  
              <hr class=""/>
            </ng-container> 
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start">Add-ons</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-6 d-flex align-items-center text-wrap mb-1" *ngFor="let item of addOnnsItems;" [title]="item.name">
                <input type="checkbox" class="scale-1_3 mt-0 mr-2" [value]="item.id" (change)="onAddOnnChange($event)" [checked]="item.checked">{{item.name}}
              </div>
            </div>  

          </ng-container>    
          <ng-container *ngIf="vehicleData.isNew == false">
            <hr class=""/>
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start">Vehicle Details</h4>
              </div>          
            </div>          
            <div class="mt-1 row" *ngIf="false">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <span>
                  Is the ownership transferred?
                </span>
                <span class="d-flex align-items-center">
                  <input id="osty" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="vehicleData.isOwnerShipTransferred??'isOwnerShipTransferred'" [(ngModel)]="this.vehicleData.isOwnerShipTransferred"><span class="mr-2">Yes</span>
                  <input id="ostn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="vehicleData.isOwnerShipTransferred??'isOwnerShipTransferred'" [(ngModel)]="this.vehicleData.isOwnerShipTransferred"><span>No</span>
                </span>  
              </div>
            </div>
            <div class="mt-1 row">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <span>
                  Is valid PUC available?
                </span>
                <span class="d-flex align-items-center">
                  <input id="osty" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="true" [name]="'isValidPUC'" [(ngModel)]="this.isValidPUC"><span class="mr-2">Yes</span>
                  <input id="ostn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="false" [name]="'isValidPUC'" [(ngModel)]="this.isValidPUC"><span>No</span>
                </span>  
              </div>
            </div>
            <div class="row mt-1" *ngIf="isValidPUC==true">
              <div class="col-6"><p class="text-start text-grey5 mb-0 mt-1 ml-24">PUC Number:</p></div>
              <div class="col">
                <input type="text" class="form-control w-100 h-26px py-0" [(ngModel)]="pucNumber" oninput="this.value = this.value.toUpperCase()" placeholder="PUC Number">
              </div>           
            </div>
            <div class="row mt-1" *ngIf="isValidPUC==true">
              <div class="col-6"><p class="text-start text-grey5 mb-0 mt-1 ml-24">PUC Valid Up To:</p></div>
              <div class="col">
                <input type="date" class="form-control w-100 h-26px py-0" [(ngModel)]="pucValidUpTo">
              </div>           
            </div>
            <hr class=""/>
          </ng-container>
          <ng-container *ngIf="this.vehicleData.isNew == false && activeTab == 1"> 
            <ng-container *ngIf="activeTab == 1"> 
             <!-- && this.vehicleData.isOwnerShipTransferred == 'No'">         -->
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start">No Claim Bonus <img src="/assets/images/Vector4.png" alt="" (click)="openNCBModal()" class="cursor-pointer ml-1 h-18px"></h4>
                </div>          
              </div>  
              <div class="row">
                <div class="col-6"><p class="text-start text-grey4 mb-3">Claim in last policy:</p></div>
                <div class="col">
                  <select class="form-select form-select-custom1 form-select_" [(ngModel)]="isPolicyClaimed" (change)="getCurrentNCB()">
                    <option *ngFor="let item of policyClaimedList; let i = index;" value="{{item.value}}">{{item.text}}</option>
                  </select>
                </div>           
              </div>       
              <div class="mt-1 row" *ngIf="isPolicyClaimed =='No' || (isPolicyClaimed == 'Yes' && isNCBProtectionOptedInExistingPolicy == 'Yes')">
                <div class="col-6"><p class="text-start text-grey4 mb-3">Current applicable NCB:</p></div>
                <div class="col">
                  <select class="form-select form-select-custom1 form-select_" [(ngModel)]="ncbCurrent" disabled="disabled">
                    <option *ngFor="let item of policyNcbClaimedList; let i = index;" value="{{item.value}}">{{item.text}}</option>
                  </select>
                </div>                    
              </div>
              <div class="row" *ngIf="isPolicyClaimed =='No' || (isPolicyClaimed == 'Yes' && isNCBProtectionOptedInExistingPolicy == 'Yes')">
                <div class="col-6"><p class="text-start text-grey4 mb-3">Previous year NCB:</p></div>
                <div class="col">
                  <select class="form-select form-select-custom1 form-select_" [(ngModel)]="ncbPrevious" (change)="getCurrentNCB()">
                    <option *ngFor="let item of policyNcbClaimedList; let i = index;" value="{{item.value}}">{{item.text}}</option>
                  </select>
                </div>           
              </div>
              <div class="row" *ngIf="isPolicyClaimed =='Yes'">
                <div class="col-6"><p class="text-start text-grey4 mb-3">Number of claim(s) made in existing policy:</p></div>
                <div class="col">
                  <select class="form-select form-select-custom1 form-select_" [(ngModel)]="claimsMadeInPreviousPolicyNo" (change)="claimCountChanged($event)">
                    <option *ngFor="let item of policyNcbClaimMadeList; let i = index;" value="{{item.value}}">{{item.text}}</option>
                  </select>
                </div>           
              </div>
              <div class="row" *ngIf="isPolicyClaimed =='Yes'">
                <div class="col-6"><p class="text-start text-grey4 mb-3">Claimed Amount:</p></div>
                <div class="col">
                  <span class="input-icon">
                    <input type="text" class="form-control w-100 h-26px py-0" [(ngModel)]="claimsMadeInPreviousPolicyAmount" (keypress)="numberOnly($event)" (keyup)="onClaimedAmountCahnge()">
                    <i>₹</i> 
                  </span>  
                </div>
              </div>
              <div class="row" *ngIf="isPolicyClaimed =='Yes'">
                <div class="col-6"><p class="text-start text-grey4 mb-3">NCB protection opted in existing policy:</p></div>
                <div class="col">
                  <select class="form-select form-select-custom1 form-select_" [(ngModel)]="isNCBProtectionOptedInExistingPolicy" (change)="onNCBProtectionOptedInExistingPolicy()">
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </div>
              </div>
              <hr class=""/> 
            </ng-container> 
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start">Has your current policy expired?</h4>
              </div>
            </div>
            <div class="mt-1">
              <select class="form-select form-select-custom1 mb-2 form-select_" [(ngModel)]="prevPolicyStatus" (change)="setPolicyStatusLabel()">
                <option *ngFor="let item of policyExpiryStatusList; let i = index;" value="{{item.value}}">{{item.text}}</option>
              </select>
            </div>
            <hr class=""/>
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start">Which cover did you have on your expiring policy?</h4>
              </div>
            </div>
            <div class="mt-1">
              <select class="form-select form-select-custom1 mb-2 form-select_" [(ngModel)]="prevPolicyType" (change)="setPolicyTypeLabel()">
                <ng-container *ngFor="let item of policyTypeList; let i = index;">
                  <option  value="{{item.value}}" *ngIf="item.isVisible">{{item.text}}</option>
                </ng-container>
              </select>
            </div>
          </ng-container>   
          <ng-container *ngIf="insuranceformData.vehicleDetails?.typeOfCover != 'Standalone'">  
            <hr class=""/>
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start">PA Cover <img src="/assets/images/Vector4.png" alt="" (click)="openPACoverModal()" class="cursor-pointer ml-1 h-18px"></h4>
              </div>
            </div>
            <div class="row mt-1 border-top">
              <div class="col-12">
                <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isPAToOwnerDriver"> <span class="ml-1">PA to Owner Driver</span>
                  </span>
                </div>
              </div>  
            </div>
            <div class="mt-1 ml-24" *ngIf="isPAToOwnerDriver == true">              
              <div class="row mb-2">
                <div class="col-12 d-flex align-items-center">
                  <input id="Individual" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Individual'" [name]="policyFor" [(ngModel)]="policyFor"><span class="mr-5">Individual</span>
                  <input id="Company" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'Company'" [name]="policyFor" [(ngModel)]="policyFor"><span>Corporate</span>
                </div>
              </div>
              <div class="row border-top" *ngIf="policyFor=='Individual'">          
                <!-- <input type="checkbox" class="scale-1_3 mr-2 mt-1" [(ngModel)]="isValidDrivingLicenseAvailable"> -->

                <div class="col-12 py-1 border-bottom">                                    
                  <span class="d-flex align-items-center justify-content-between">
                    <span class="d-flex align-items-center">Do you want to have PA Cover?</span> 
                    <span class="d-flex align-items-center">
                      <input id="yes" type="radio" class="scale-1_3 ml-3 mr-2 mt-0"  [value]="'Yes'" [name]="'isPACover'" [(ngModel)]="isPACover"><span>Yes</span>
                      <input id="no" type="radio" class="scale-1_3 mx-2 mt-0" [value]="'No'" [name]="'isPACover'" [(ngModel)]="isPACover"><span>No</span>
                    </span>                    
                </span>
                </div>
                <ng-container *ngIf="isPACover == 'No'"> 
                  <div class="col-12 py-2">
                    Select the reason(s) for not opting PA Cover: <img src="/assets/images/Vector4.png" alt="" (click)="openPACoverNotSelectingModal()" class="cursor-pointer ml-1 h-18px">
                  </div>
                  <div class="col-12 py-1">                    
                    <span class="d-flex align-items-center justify-content-between">
                       <span class="d-flex align-items-center">Already have a PA Cover?</span>
                       <span class="d-flex align-items-center">
                        <input id="yes" type="radio" class="scale-1_3 ml-3 mr-2 mt-0"  [value]="'Yes'" [name]="'alreadyHavePACover'" [(ngModel)]="alreadyHavePACover"><span>Yes</span>
                        <input id="no" type="radio" class="scale-1_3 mx-2 mt-0" [value]="'No'" [name]="alreadyHavePACover" [(ngModel)]="alreadyHavePACover"><span>No</span>
                       </span>                      
                    </span>
                  </div>                  
                </ng-container>  
                <div class="col-12 py-1">                    
                  <span class="d-flex align-items-center justify-content-between">
                    <span class="d-flex align-items-center">Have a valid driving license?</span>
                    <span class="d-flex align-items-center">
                      <input id="yes" type="radio" class="scale-1_3 ml-3 mr-2 mt-0"  [value]="'Yes'" [name]="'isValidDrivingLicenseAvailable'" [(ngModel)]="isValidDrivingLicenseAvailable"><span>Yes</span>
                      <input id="no" type="radio" class="scale-1_3 mx-2 mt-0" [value]="'No'" [name]="'isValidDrivingLicenseAvailable'" [(ngModel)]="isValidDrivingLicenseAvailable"><span>No</span>
                    </span>                      
                  </span>
                </div>              
              </div>
              <div class="row pt-1" *ngIf="isPACover == 'Yes' && policyFor == 'Individual'">
                <div class="col-5"><p class="text-start text-grey5 mb-0 mt-1">PA Cover Tenure</p></div>
                <div class="col">
                  <select name="paPolicyTerm"
                      placeholder="PA Cover Tenure" [(ngModel)]="paPolicyTerm"
                      class="form-select form-select-custom form-select_">
                      <option *ngFor="let polType of policyTermList" [value]="polType.value">
                          {{polType.text}}
                      </option>
                  </select> 
                </div>  
              </div>
              <div class="row" *ngIf="policyFor=='Company'">
                <div class="col-5"><p class="text-start text-grey5 mb-0 mt-1">Company Name:</p></div>
                <div class="col">
                  <input type="text" class="form-control w-100 h-26px py-0" [(ngModel)]="companyName">
                </div>           
              </div>
              <div class="row mt-1" *ngIf="policyFor=='Company'">
                <div class="col-5"><p class="text-start text-grey5 mb-0 mt-1">Company GSTIN:</p></div>
                <div class="col">
                  <input type="text" class="form-control w-100 h-26px py-0" [(ngModel)]="companyGSTIN" oninput="this.value = this.value.toUpperCase()" placeholder="Eg. 22AAAAA0000A1Z5" (keyup)="onCompanyGSTINChange($event)">
                  <span class="text-danger" *ngIf="!iscompanyGSTINValid">
                      Invalid GSTIN number.
                  </span>
                </div>           
              </div>
            </div>    
            </ng-container>        
            <ng-container *ngIf="this.activeTab == 2">
              <hr class=""/>
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start mb-0">TPPD</h4>
                </div>
              </div>
              <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mx-2 my-0" [(ngModel)]="showLimitedTPCovers" (change)="bindThirdPartyLimitedQuotes()"> <span class="ml-1">Show Limited TP Covers</span>
                  </span>
              </div>
            </ng-container>       
            <ng-container  *ngIf="insuranceformData.vehicleDetails?.typeOfCover != 'Standalone'">
              <hr class=""/>
              <div class="justify-content-between d-flex" *ngIf="insuranceformData.vehicleDetails?.typeOfCover != 'Standalone'">
                <div>
                  <h4 class="text-start mb-0">Unnamed PA Cover <img src="/assets/images/Vector4.png" alt="" (click)="openUNPACoverModal()" class="cursor-pointer ml-1 h-18px"></h4>
                </div>
              </div>
              <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isPACoverUnnamed"> <span class="ml-1">PA Cover for Unnamed Passenger</span>
                  </span>
                  <span *ngIf="isPACoverUnnamed==true"  class="input-icon">
                    <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="paCoverUnnamedAmount">
                      <option *ngFor="let item of unnamedPACoverAmtList;" value="{{item}}">{{item}}</option>
                    </select>
                    <i>₹</i>
                  </span>
              </div>
              <!--Start New PA Cover filters -->
                  <div class="justify-content-between d-flex align-items-center h-30" *ngIf="this.insuranceformData.vehicleType != 2 && showNewFilter && insuranceformData.vehicleDetails?.typeOfCover != 'Standalone'"> 
                    <span class="d-flex">
                      <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isPAToPaidDriver"> <span class="ml-1">PA to Paid Driver</span>
                    </span>
                    <span *ngIf="isPAToPaidDriver==true"  class="input-icon">
                      <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="paToPaidDriverAmt">
                        <option *ngFor="let item of unnamedPACoverAmtList;" value="{{item}}">{{item}}</option>
                      </select>
                      <i>₹</i>
                    </span>
                  </div>
                  <div class="justify-content-between d-flex align-items-center h-30" *ngIf="this.insuranceformData.vehicleType == 3 && showNewFilter"> 
                    <span class="d-flex">
                      <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isPAToConductor"> <span class="ml-1">PA to Conductor</span>
                    </span>
                    <span *ngIf="isPAToConductor==true" class="input-icon">
                      <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="paToConductorAmt">
                        <option *ngFor="let item of unnamedPACoverAmtList;" value="{{item}}">{{item}}</option>
                      </select>
                      <i>₹</i>
                    </span>
                  </div>
                  <div class="justify-content-between d-flex align-items-center h-30" *ngIf="this.insuranceformData.vehicleType == 3  && showNewFilter"> 
                    <span class="d-flex">
                      <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isPAToCleaner"> <span class="ml-1">PA to Cleaner</span>
                    </span>
                    <span *ngIf="isPAToCleaner==true"  class="input-icon">
                      <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="paToCleanerAmt">
                        <option *ngFor="let item of unnamedPACoverAmtList;" value="{{item}}">{{item}}</option>
                      </select>
                      <i>₹</i>
                    </span>
                  </div>          
            <!--End New Cover filters -->
            </ng-container>
            
            <ng-container *ngIf="this.insuranceformData.vehicleType != 2 && showNewFilter && insuranceformData.vehicleDetails?.typeOfCover != 'Standalone'">
              <hr class=""/>
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start mb-0">Legal Liability Covers</h4>
                </div>
              </div>
              <div class="justify-content-between d-flex align-items-center h-30" *ngIf="this.insuranceformData.vehicleType != 2 && showNewFilter"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isLLToPaidDriver"> <span class="ml-1">Number of Paid Driver</span>
                  </span>
                  <!-- <span class="d-flex">
                    Number of Paid Driver
                  </span> -->
                  <span *ngIf="isLLToPaidDriver==true">
                    <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="numberOfDrivers">
                      <option *ngFor="let item of numberOfDriversList;" value="{{item.value}}">{{item.text}}</option>
                    </select>                    
                  </span>
              </div>
              <ng-container *ngIf="(this.insuranceformData.vehicleType == 3 || this.insuranceformData.vehicleType == 1) && showNewFilter">
                <div class="justify-content-between d-flex align-items-center h-30" *ngIf="this.insuranceformData.vehicleType == 3"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isLLToCleaners"> <span class="ml-1">Number of Cleaners</span>
                  </span>
                  <!-- <span class="d-flex">
                    Number of Employee
                  </span> -->
                  <span *ngIf="isLLToCleaners==true" >
                    <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="numberOfCleaners">
                      <option *ngFor="let item of numberOfCleanersList;" value="{{item.value}}">{{item.text}}</option>
                    </select>                    
                  </span>
                </div>
                <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isLLToEmployee"> <span class="ml-1">Number of Employee</span>
                  </span>
                  <!-- <span class="d-flex">
                    Number of Employee
                  </span> -->
                  <span *ngIf="isLLToEmployee==true">
                    <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="numberOfEmployees">
                      <option *ngFor="let item of numberOfEmployeeList;" value="{{item.value}}">{{item.text}}</option>
                    </select>                    
                  </span>
                </div>
                <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isLLToNonEmployee"> <span class="ml-1">Number of Non-Employee</span>
                  </span>
                  <!-- <span class="d-flex">
                    Number of Non-Employee
                  </span> -->
                  <span *ngIf="isLLToNonEmployee==true">
                    <select  class="form-select form-select-custom1 w-100px pr-4 form-select_" [(ngModel)]="numberOfNonEmployees">
                      <option *ngFor="let item of numberOfEmployeeList;" value="{{item.value}}">{{item.text}}</option>
                    </select>                    
                  </span>
                </div>
                <div class="w-100 d-flex justify-content-between align-items-center h-30" *ngIf="this.vehicleData.category == 'PCV' && showNewFilter">
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isFPP"> <span class="ml-1">Number of FPP</span>
                  </span>
                  <!-- <span class="d-flex">
                    Number of FPP
                  </span> -->
                  <input *ngIf="isFPP==true" type="text" class="form-control w-100px h-26px py-0" placeholder="FPP" [(ngModel)]="numberOfFPP" (keypress)="numberOnly($event)">
                </div>
                <div class="w-100 d-flex justify-content-between align-items-center h-30" *ngIf="this.vehicleData.category == 'PCV' && showNewFilter">
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isNFPP"> <span class="ml-1">Number of NFPP</span>
                  </span>
                  <!-- <span class="d-flex">
                    Number of NFPP
                  </span> -->
                  <input *ngIf="isNFPP==true" type="text" class="form-control w-100px h-26px py-0" placeholder="NFPP" [(ngModel)]="numberOfNFPP" (keypress)="numberOnly($event)">
                </div>
                <div class="w-100 d-flex justify-content-between align-items-center h-30" *ngIf="this.vehicleData.category == 'GCV' && showNewFilter">
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isNumOfTrailers"> <span class="ml-1">Number of Trailers</span>
                  </span>
                  <!-- <span class="d-flex">
                    Number of Trailers
                  </span> -->
                  <input *ngIf="isNumOfTrailers==true" type="text" class="form-control w-100px h-26px py-0" placeholder="Trailers"[(ngModel)]="numberOfTrailers" (keypress)="numberOnly($event)">
                </div>
              </ng-container>              
            </ng-container>
            <ng-container *ngIf="this.insuranceformData.vehicleType == 3 && showNewFilter && activeTab == 1">
              <hr class=""/>
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start mb-0">IMT-23 <img src="/assets/images/Vector4.png" alt="" (click)="openShowIMT23Modal()" class="cursor-pointer ml-1 h-18px"></h4>
                </div>
              </div>
              <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isIMT23"> <span class="ml-1">IMT-23</span>
                  </span>
              </div>
            </ng-container>
            <ng-container *ngIf="this.insuranceformData.vehicleType == 3 && this.vehicleData.category == 'MISC' && showNewFilter && activeTab == 1">
              <hr class=""/>
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start mb-0">IMT-47 <img src="/assets/images/Vector4.png" alt="" (click)="openShowIMT47Modal()" class="cursor-pointer ml-1 h-18px"></h4>
                </div>
              </div>
              <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isIMT47"> <span class="ml-1">IMT-47</span>
                  </span>
              </div>
            </ng-container>            
            <ng-container *ngIf="showNewFilter">
              <hr class=""/>
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start mb-0">Anti Theft Device Discount <img src="/assets/images/Vector4.png" alt="" (click)="openShowAntiTheftModal()" class="cursor-pointer ml-1 h-18px"></h4>
                </div>
              </div>
              <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isAntiTheftDeviceDiscount"> <span class="ml-1">Anti Theft Device Discount</span>
                  </span>
                  <!-- <span *ngIf="isAntiTheftDeviceDiscount==true" class="input-icon">
                    <input type="text" class="form-control w-100px h-26px py-0" placeholder="Anti Theft ARAI Number" [(ngModel)]="antiTheftARAINumber">
                  </span> -->
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center h-30 mt-2" *ngIf="isAntiTheftDeviceDiscount==true">
                <span class="d-flex ml-24">
                  ARAI Number
                </span>
                <input type="text" class="form-control w-50 h-26px py-0" placeholder="ARAI Number" [(ngModel)]="antiTheftARAINumber">
              </div>
            </ng-container>
            <ng-container *ngIf="showNewFilter">
              <hr class=""/>
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start mb-0">Geographical Extension</h4>
                </div>
              </div>
              <div class="justify-content-between d-flex align-items-center h-30"> 
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isGeographicalExtension"> <span class="ml-1">Geographical Extension</span>
                  </span>
                  <span *ngIf="isGeographicalExtension==true"  class="w-50">
                    <app-multi-dropdown class="multiselect_custom" [items]="geographicalLocationsList"
                    [showSearch]="false" [showAll]="true" [showStatus]="false" [showError]="false" (itemChange)="onGeoLocationSelect($event)"
                    placeholder="Select Geographical Locations" [className]="'form-select-view-quote'">
                    </app-multi-dropdown>
                  </span>
              </div>
            </ng-container>
            <hr class=""/>
            <ng-container *ngIf="activeTab == 1 && this.vehicleData.isNew != true">
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start">Covers in Existing Policy
                  <!-- <img src="/assets/images/Vector4.png" alt="" (click)="openExistingAddonModal()" class="cursor-pointer ml-1 h-18px"> -->
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-between" *ngIf="showBiFuelKit">
                  <span>
                    Bi-Fuel Kit Cover
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="invy" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isBiFuelKitOptedInExistingPolicy'" [(ngModel)]="this.isBiFuelKitOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="invn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isBiFuelKitOptedInExistingPolicy'" [(ngModel)]="this.isBiFuelKitOptedInExistingPolicy"><span>No</span>
                  </span>
                </div>
                <div class="col-12 d-flex align-items-center justify-content-between" *ngIf="activeTab == 1">
                  <span>
                    Electrical Accessories Cover
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="invy" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isElecricalAccessoriesCoverOptedInExistingPolicy'" [(ngModel)]="this.isElecricalAccessoriesCoverOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="invn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isElecricalAccessoriesCoverOptedInExistingPolicy'" [(ngModel)]="this.isElecricalAccessoriesCoverOptedInExistingPolicy"><span>No</span>
                  </span>
                </div> 
                <div class="col-12 d-flex align-items-center justify-content-between"  *ngIf="activeTab == 1">
                  <span>
                    Non-Electrical Accessories Cover
                  </span>
                  <span class="d-flex align-items-center">
                    <input id="cony" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'Yes'" [name]="'isNonElecricalAccessoriesOptedInExistingPolicy'" [(ngModel)]="this.isNonElecricalAccessoriesOptedInExistingPolicy"><span class="mr-2">Yes</span>
                    <input id="conn" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'No'" [name]="'isNonElecricalAccessoriesOptedInExistingPolicy'" [(ngModel)]="this.isNonElecricalAccessoriesOptedInExistingPolicy"><span>No</span>
                  </span>
                </div>                        
              </div>  
              <hr class=""/>
            </ng-container>  
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start mb-0">Accessories Cover</h4>
              </div>
            </div>
            <div class="mt-1 justify-content-between d-flex align-items-center flex-column">  
              <ng-container  *ngIf="showBiFuelKit">
                <div class="w-100 d-flex justify-content-between align-items-center h-30">
                  <span class="d-flex">
                    <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isBiFuelKit" [disabled]="biFuelKitFitting == 'CompanyFitted'"> <span class="ml-1">Bi-Fuel Kit Cover</span>
                  </span>
                  <span *ngIf="isBiFuelKit == true && biFuelKitFitting != 'CompanyFitted'" class="input-icon">
                    <input type="text" class="form-control w-100px h-26px py-0" [(ngModel)]="biFuelKitAmt" (keypress)="numberOnly($event)" >
                    <i>₹</i>
                  </span>
                </div>
                <div *ngIf="isBiFuelKit==true"  class="w-100 d-flex justify-content-between align-items-center h-30">
                  <span class="d-flex align-items-center">
                    <span class="ml-2 mr-3 ml-24">Bi-Fuel Kit Type : </span>
                    <span class="d-flex align-items-center">
                      <input id="biFuelKitTypeCNG" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'CNG'" [name]="'biFuelKitType'" [(ngModel)]="biFuelKitType" [disabled]="biFuelKitFitting == 'CompanyFitted'" [readOnly]="biFuelKitFitting == 'CompanyFitted'"><span class="mr-2" >CNG</span>
                      <input id="biFuelKitTypeLPG" type="radio" class="scale-1_3 mr-2 mt-0 ml-3"  [value]="'LPG'" [name]="'biFuelKitType'" [(ngModel)]="biFuelKitType" [disabled]="biFuelKitFitting == 'CompanyFitted'" [readOnly]="biFuelKitFitting == 'CompanyFitted'"><span class="mr-2">LPG</span>
                    </span>              
                  </span>
                </div>
  
                <div *ngIf="isBiFuelKit==true"  class="w-100 d-flex justify-content-between align-items-center h-30">
                  <span class="d-flex align-items-center">
                    <span class="ml-2 mr-3 ml-24">Is Company Fitted: </span>
                    <span class="d-flex align-items-center">
                      <input id="biFuelKitFittingCF" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'CompanyFitted'" [name]="'biFuelKitFitting'" [(ngModel)]="biFuelKitFitting" [disabled]="true" [readOnly]="true"><span class="mr-2">Yes</span>
                      <input id="biFuelKitFittingAM" type="radio" class="scale-1_3 mr-2 mt-0 ml-3"  [value]="'AfterMarket'" [name]="'biFuelKitFitting'" [(ngModel)]="biFuelKitFitting" [disabled]="true" [readOnly]="true"><span class="mr-2">No</span>
                    </span>
                  </span>
                </div>
              </ng-container>
              <div class="w-100 d-flex justify-content-between align-items-center h-30" *ngIf="activeTab == 1">
                <span class="d-flex input-icon">
                  <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isElectricalAccessories"> <span class="ml-1">Electrical Accessories</span>
                </span>
                <span *ngIf="isElectricalAccessories==true" class="input-icon">
                  <input type="text" class="form-control w-100px h-26px py-0" [(ngModel)]="electricalAccessoriesAmt" (keypress)="numberOnly($event)">
                  <i>₹</i>
                </span>
              </div>
              <div class="w-100 d-flex justify-content-between align-items-center h-30" *ngIf="activeTab == 1">
                <span class="d-flex">
                  <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isNonElectricalAccessories"> <span class="ml-1">Non-Electrical Accessories</span>
                </span>
                <span *ngIf="isNonElectricalAccessories==true" class="input-icon">
                  <input type="text" class="form-control w-100px h-26px py-0" [(ngModel)]="nonElectricalAccessoriesAmt" (keypress)="numberOnly($event)">
                  <i>₹</i>
                </span>
              </div>
            </div>
            <ng-container *ngIf="this.insuranceformData.vehicleType == 2 && vehicleData.registrationYear != currentYear.toString()">
              <hr class=""/>
              <div class="justify-content-between d-flex">
                <div>
                  <h4 class="text-start mb-0">Policy Term</h4>
                </div>
              </div>
              <div class="mt-1">
                <div class="row mb-2">
                  <div class="col-12 d-flex align-items-center">
                      <input id="year1" type="radio" class="scale-1_3 mr-2 mt-0"  [value]="'1'" [name]="policyTerm" [(ngModel)]="policyTerm" (change)="policyTermChange()"><span class="mr-4">1 year</span>
                      <input id="year2" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'2'" [name]="policyTerm" [(ngModel)]="policyTerm" (change)="policyTermChange()"><span class="mr-4">2 Year</span>
                      <input id="year3" type="radio" class="scale-1_3 mr-2 mt-0" [value]="'3'" [name]="policyTerm" [(ngModel)]="policyTerm" (change)="policyTermChange()"><span>3 Year</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <hr class=""/>
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start mb-0">Vehicle on Finance  <img src="/assets/images/Vector4.png" alt="" (click)="openShowFinace() " class="cursor-pointer ml-1 h-18px"></h4>
              </div>
            </div>
            <div class="justify-content-between d-flex align-items-center h-30"> 
                <span class="d-flex">
                  <input type="checkbox" class="scale-1_3 mr-2 my-0" [(ngModel)]="isFinanced"> <span class="ml-1">Is vehicle currently on finance?</span>
                </span>            
            </div>
            <ng-container *ngIf="isFinanced==true">
              <div class="w-100 d-flex justify-content-between align-items-center h-30 mt-2">
                <span class="d-flex">
                  Financier Type
                </span>
                <select [(ngModel)]="financeType" (change)="sortQuote()" class="w-50 form-select form-select-custom form-select_">
                  <option *ngFor="let item of financeTypeList; let i = index;" value="{{item.value}}">{{item.text}}</option>
                </select>

              <!-- <div class="row pt-1" *ngIf="isPACover == 'Yes' && policyFor == 'Individual'">
                <div class="col-5"><p class="text-start text-grey5 mb-0 mt-1">PA Cover Tenure</p></div>
                <div class="col">
                  <select name="paPolicyTerm"
                      placeholder="PA Cover Tenure" [(ngModel)]="paPolicyTerm"
                      class="form-select form-select-custom form-select_">
                      <option *ngFor="let polType of policyTermList" [value]="polType.value">
                          {{polType.text}}
                      </option>
                  </select> 
                </div>  
              </div> -->

              </div>
            <div class="w-100 d-flex justify-content-between align-items-center h-30 mt-2">
              <span class="d-flex">
                Financier Name
              </span>
              <app-autocomplete class="w-50" (itemSelected)="onFinacierNameSelection($event)"
                              [items]="insurancePartnersList" placeholder="Financier Name"
                              [userInput]="financierName"
                              className="h-26"
                              (onBlurEvent)="onFinacierNameBlur($event)"></app-autocomplete>  
            </div>
            <div class="w-100 d-flex justify-content-between align-items-center h-30 mt-2">
              <span class="d-flex">
                Financed Amount
              </span>
              <span class="input-icon">
                <input type="text" class="form-control w-50 h-26px py-0 pull-right" [(ngModel)]="financedValue" (keypress)="numberOnly($event)">
                <i>₹</i> 
              </span>  
            </div>
            <div class="w-100 d-flex justify-content-between align-items-center h-30 mt-2">
              <span class="d-flex">
                Branch Name
              </span>
             <input type="text" name="" class="form-control w-50 " placeholder="Branch Name" id="" [(ngModel)]="financierBranchName"> 
            </div>
          </ng-container>
          </div>
          
            <button class="btn btn-primary w-100 rounded-0 rounded-bottom" (click)="reCalculateQuote()">Apply</button>
          </div>

          <!-- <div class="card p-5 mt-3">
            <div class="justify-content-between d-flex">
              <div>
                <h4 class="text-start">Choose Addons</h4>
              </div>
              <div class="d-flex" *ngIf="!isEditPolicyAddons">
                <img class="edit-icon" src="../../assets/svgIcon/PencilLine.svg" alt="Edit">&nbsp;&nbsp;
                <h6 class="text-edit" (click)="editPolicyAddons()">Edit</h6>
              </div>
              <div class="d-flex" *ngIf="isEditPolicyAddons">
                <img class="edit-icon" src="../../assets/svgIcon/FloppyDisk.svg" alt="Apply">&nbsp;&nbsp;
                <h6 class="text-edit" (click)="updatePolicyAddons()">Apply</h6>
              </div>
            </div>
            <div class="mt-5" *ngIf="isEditPolicyAddons">
              <app-multi-dropdown  class="w-100 multiselect_custom"
                      [items]="addOnnsItems" 
                      [showSearch]="showSearch" 
                      [showAll]="showAll" 
                      [showStatus]="showStatus"
                      [showError]="showError" 
                      placeholder="Select Foods" 
                      (itemChange)="onItemChange($event)">
                  </app-multi-dropdown>
            </div>
            <div class="mt-5" *ngIf="!isEditPolicyAddons">
              <h5 class="float-start text-main fw-medium">{{policyAddonsLabel}}</h5>
            </div>
          </div> -->

        </div>
        <div class="col-12 col-md-12 col-lg-8 col-xl-8 px-0 px-md-2">
          <div class="row mt-3 ml-1" *ngIf="isQuoteFoundTP || isQuoteFound">
            <div class="col-md-7 px-md-0">
              <div class="">
                <h2 class="text-start">Insurance plans for you</h2>
                </div>
                <div class="text-left">
                <span class="font-body">
                  {{insuranceformData.vehicleDetails?.typeOfCover == 'Standalone'  && isQuoteFound ? "Cover damages to your vehicle only and not third-party" : (activeTab == 2  && isQuoteFoundTP? "Cover damages to third-party only" : (activeTab == 1  && isQuoteFound?"Cover damages to your vehicle and third-party" : "")) }}</span>
              </div>
            </div>
            <div class="col-md-5 text-right align-self-center pl-0 d-flex justify-content-between">
              <div class="card py-2 px-3 m-0">
                <div class="row">
                  <div class="col-2">
                    <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending" width="22">
                  </div>
                  <div class="col-10 align-items-center d-flex justify-content-end  px-0">
                    <select [(ngModel)]="sort_quote" (change)="sortQuote()" class="w-100 border-0 py-0 pl-2 pr-0 sort-ddl form-select form-select_">
                      <option *ngFor="let item of sortQuoteList; let i = index;" value="{{item.value}}">{{item.text}}</option>
                    </select>
                  </div>
                </div>
              </div>  
              <button type="button" class="btn btn-primary px-2 py-2 mr-2" (click)="shareClick()">
                <img src="../../assets/svgIcon/ShareIcon.svg" alt="ArrowLeft" class="cursor-pointer edit-icon icon-20-20 mr-1">Share Quotes
              </button>          
            </div>
          </div>

            <div class="row" *ngIf="(isQuoteFoundTP || isQuoteFound)">
              <div class="col-12">
                <ul class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center">
                  <li class="list-item w-50" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                    {{insuranceformData.vehicleDetails?.typeOfCover == 'Standalone' ? 'Standalone Cover' : 'Comprehensive Cover' }}
                    <!-- <a class="font-body active font-15-12" data-toggle="tab" href="#comprehensive" (click)="changeTab(1)">Comprehensive</a> -->
                  </li>
                  <li class="list-item  w-50" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)" *ngIf="insuranceformData.vehicleDetails?.typeOfCover != 'Standalone'">
                    Third Party Only Cover
                    <!-- <a class="font-body font-15-12" data-toggle="tab" href="#thirdparty" (click)="changeTab(2)">Thirdparty</a> -->
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-content clearfix mb-5 row">
              <div id="comprehensive" class="tab-pane  col-12 c-pl c-pr position-relative" [ngClass]="{'active': activeTab == 1}">
                <!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating" name="CH"></ngx-spinner>                 -->
                <ng-container *ngFor="let quote of quoteList">
                  <div class="card p-0 mt-3 row">
                    <div class="col-12">
                      <div class="row align-value min-height78"> 
                        <div class="col">
                          <img class="image-stl" src="/assets/images/insurance_companies/{{quote.insuranceCompany}}.png" alt="">
                        </div>                  
                        <div class="col text-center">
                          <p class="text-center text-grey4 mb-2">IDV</p>
                          <h4 class="mb-0 line-height10">₹ {{ quote.idv??"0" | thousandSeparator}}</h4>
                          <span class="text-grey5 font-10">{{quote.minimumIDV??"0" | thousandSeparator}}</span> - <span class=" font-10 text-grey5">{{quote.maximumIDV??"0" | thousandSeparator}}</span>
                        </div>
                        <div class="col text-center" *ngIf="this.insuranceformData.vehicleType == 3 && this.vehicleData.category == 'GCV' && this.minGvw > 0">
                          <p class="text-center text-grey4 mb-2">GVW</p>
                          <h4 class="mb-0 line-height10">{{ quote.defaultGVW??"0" | thousandSeparator}} KG</h4>
                          <span class="text-grey5 font-10">{{quote.minimumGVW??"0" | thousandSeparator}}</span> - <span class=" font-10 text-grey5">{{quote.maximumGVW??"0" | thousandSeparator}}</span>
                        </div>
                        <div class="col text-center" *ngIf="isAgent">
                          <p class="text-center text-grey4 mb-2">Points</p>
                          <h4> {{quote.agentCommission}}</h4>
                        </div>
                        <div class="col text-center">
                          <p class="text-center text-grey4 mb-2">Claim Settled</p>
                          <h4 class="mb-0 line-height10">{{quote.claimSettlementRatio??98.3}}%</h4>
                        </div>
                        <div class="col text-center">
                          <p class="text-center text-main mb-0 font-17-13">
                            ₹ {{quote.grossPremium??"0" | thousandSeparator}}
                            <span class="gst-span text-grey4">(GST Included)</span></p>
                            <span (click)="PopUp(quote.priceBreakup,false,quote.addOnsList,quote.insuranceCompany)" class="span-link">View Breakup</span>
                        </div>
                        <div class="col" style="text-align: right;">
                          <button class="btn btn-primary" (click)="CreateQuoteConfirmation(quote,false,false)"> Buy Now </button>                            
                        </div>
                      </div>
                      <div class="row align-value justify-content-start mx-0 alert alert-warning border-0 p-2 mb-2 text-black" *ngIf="quote.isBreakIn == true">
                        <div class="col col-auto fw-bolder"> Note : vehicle inspection required</div>
                      </div>
                      <div class="row align-value justify-content-start mx-0 alert alert-success border-0 p-2 mb-2" *ngIf="getSelectedAddOns(quote.addOnsList,quote.insuranceCompany).length > 0">
                        <div class="col col-auto fw-bolder"> Applied addons:</div>
                        <div class="col col-auto" style="text-wrap: nowrap;" *ngFor="let addon of getSelectedAddOns(quote.addOnsList,quote.insuranceCompany)">{{addon.name}}
                        </div>
                      </div>
                      <div class="row align-value justify-content-start mx-0 alert alert-primary border-0 p-2 mb-2" *ngIf="getDefaultAddOns(quote.addOnsList,quote.insuranceCompany).length > 0">
                        <div class="col col-auto fw-bolder"> Default addons:</div>
                        <div class="col col-auto" style="text-wrap: nowrap;" *ngFor="let addon of getDefaultAddOns(quote.addOnsList,quote.insuranceCompany)">{{addon.name}}
                        </div>
                      </div>
                      <div class="row align-value justify-content-start mx-0 alert alert-warning border-0 p-2 mb-2" *ngIf="getNonAppliedAddOns(quote.addOnsList,quote.insuranceCompany).length > 0 && (getDefaultAddOns(quote.addOnsList,quote.insuranceCompany).length > 0 || getSelectedAddOns(quote.addOnsList,quote.insuranceCompany).length > 0)">
                        <div class="col col-auto fw-bolder"> Not applied addons:</div>
                        <div class="col col-auto" style="text-wrap: nowrap;" *ngFor="let addon of getNonAppliedAddOns(quote.addOnsList,quote.insuranceCompany)" >{{addon.name}}
                        </div>
                      </div>
                      <div class="row align-value justify-content-start mx-0 alert alert-danger border-0 p-2 mb-2" *ngIf="((getDefaultAddOns(quote.addOnsList,quote.insuranceCompany).length == 0 && getSelectedAddOns(quote.addOnsList,quote.insuranceCompany).length == 0)) && (this.insuranceformData.filter != null && this.insuranceformData.filter != undefined && this.insuranceformData.filter?.addOnsIds != null && this.insuranceformData.filter?.addOnsIds != undefined && this.insuranceformData.filter?.addOnsIds != '')">
                        <div class="col fw-bolder">No addons in this plan
                        </div>
                      </div>
                    </div>                    
                </div>
                </ng-container>
                <skeleton-loader className=" mx-2 mt-3" *ngIf="showCompLoader"></skeleton-loader>
                <ng-container *ngIf="!isQuoteFound">
                  <div class="card mt-3">
                    <div class="row ">
                      <div class="col-12 text-center py-2 d-flex justify-content-center">
                        <img class="image-stl" src="/assets/svgIcon/no_quote_found.svg" alt="No Quote Found">
                      </div>
                    </div>
                </div>
                </ng-container>
              </div>
              <div id="thirdparty" class="tab-pane col-12 c-pl c-pr position-relative" [ngClass]="{'active': activeTab == 2}">
                <!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
        type="ball-spin-clockwise-fade-rotating" name="TP"></ngx-spinner> -->
                <ng-container *ngFor="let quote of quoteListTP">
                  <div class="card p-0 mt-3 row">
                    <div class="col-12">
                      <div class="row align-value">
                        <div class="col">
                          <img class="image-stl" src="/assets/images/insurance_companies/{{quote.insuranceCompany}}.png" alt="">
                        </div>
                        <!-- <div class="col text-center">
                          <p class="text-center text-grey4">IDV</p>
                          <h4>{{ quote.idv}} </h4>
                        </div> -->
                        <div class="col text-center" *ngIf="isAgent">
                          <p class="text-center text-grey4 mb-0">Points</p>
                          <h4 class="mb-0"> {{quote.agentCommission}}</h4>
                        </div>
                        <div class="col text-center">
                          <p class="text-center text-grey4 mb-0">Regular Third Party</p>
                        </div>
                        <div class="col text-center">
                          <p class="text-center text-grey4 mb-0">Claim Settled</p>
                          <h4 class="mb-0">{{quote.claimSettlementRatio??98.3}}%</h4>
                        </div>
                        <div class="col text-center">
                          <p class="text-center text-main mb-0 font-17-13">
                            ₹ {{quote.grossPremium??"0" | thousandSeparator}}
                            <span class="gst-span text-grey4">(GST Included)</span></p>
                            <span (click)="PopUp(quote.priceBreakup,true,quote.addOnsList,quote.insuranceCompany)" class="span-link">View Breakup</span>
                        </div>
                        <div class="col" style="text-align: right;">
                          <button class="btn btn-primary" (click)="CreateQuoteConfirmation(quote,false,true)"> Buy Now </button>                            
                        </div>  
                      </div>
                    </div>  
                </div>
                </ng-container>
                <skeleton-loader className=" mx-2 mt-3" *ngIf="showTPLoader"></skeleton-loader>
                <!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
        type="ball-spin-clockwise-fade-rotating" name="TP1"></ngx-spinner> -->
                <ng-container *ngIf="showLimitedTPCovers">
                  <ng-container *ngFor="let quote of quoteListTP1" >
                    <div class="card p-0 mt-3 row">
                      <div class="col-12">
                        <div class="row align-value">
                          <div class="col">
                            <img class="image-stl" src="/assets/images/insurance_companies/{{quote.insuranceCompany}}.png" alt="">
                          </div>
                          <!-- <div class="col text-center">
                            <p class="text-center text-grey4">IDV</p>
                            <h4>{{ quote.idv}} </h4>
                          </div> -->
                          <div class="col text-center" *ngIf="isAgent">
                            <p class="text-center text-grey4 mb-2">Points</p>
                            <h4> {{quote.agentCommission}}</h4>
                          </div>
                          <div class="col text-center">
                            <p class="text-center text-grey4 mb-0">Limited Third Party <img src="/assets/images/Vector4.png" alt="" (click)="openLimitedTPModal()"></p>
                          </div>
                          <div class="col text-center">
                            <p class="text-center text-grey4 mb-0">Claim Settled</p>
                            <h4 class="mb-0">{{quote.claimSettlementRatio??98.3}}%</h4>
                          </div>
                          <div class="col text-center">
                            <p class="text-center text-main mb-0 font-17-13">
                              ₹ {{quote.grossPremium??"0" | thousandSeparator}}
                              <span class="gst-span text-grey4">(GST Included)</span></p>
                              <span (click)="PopUp(quote.priceBreakup,true,quote.addOnsList,quote.insuranceCompany)" class="span-link">View Breakup</span>
                          </div>
                          <div class="col" style="text-align: right;">
                            <button class="btn btn-primary" (click)="CreateQuoteConfirmation(quote,false,true,true)"> Buy Now</button>                            
                          </div>
                        </div>
                      </div>  
                  </div>
                  </ng-container>
                </ng-container>
                <skeleton-loader className=" mx-2 mt-3" *ngIf="showTPPDLoader"></skeleton-loader>
                <ng-container *ngIf="!isQuoteFoundTP">
                  <div class="card mt-3">
                    <div class="row ">
                      <div class="col-12 text-center py-2 d-flex justify-content-center">
                        <img class="image-stl" src="/assets/svgIcon/no_quote_found.svg" alt="No Quote Found">
                      </div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
      </div>

      <div class="overlay" *ngIf="showLimitedTPTooltip">
        <div class="popup2 position-relative col-md-4">
          <div class="modal-header tooltip_">
            <!-- <h4 class="modal-title">Sign In</h4> -->
            <h3>Limited Third Party </h3>
            <button type="button" class="close pull-right" aria-label="Close" (click)=" closeLimitedTPModal()">
              <span aria-hidden="true"></span>
          </button>
          </div>
          <div class="modal-body">
            <div class="">
              
              <!-- <img src="../../../assets/images/Vector4.png" alt=""> -->
              <p class="text-grey4">This is a limited liability third party only policy where the third party property damage is restricted to Rs. 6,000 instead of regular Rs. 1,00,000.</p>
          </div>
          </div>
        </div>
      </div>
      
      <div class="overlay" *ngIf="popup">
        <div class="popup2 col-lg-4 col-md-6 col-11">
          <div class="modal-header tooltip_ py-0">
            <img class="h-56" src="/assets/images/insurance_companies/{{priceBreakup.insuranceCompany}}.png" alt=""> 
            <button type="button" class="close pull-right " aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div class="modal-body pt-0">  
            <h3 class="text-center my-2">Premium Breakup</h3>           
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.ownDriverCover??'0')">
              <div class="col-12 fw-bolder border-top border-bottom py-1 bg-grey2">
                <span>{{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? 'A -' : ''}} Own Damage Premium</span>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.ownDriverCover??'0')">
              <div class="col-8 mt-2">
                <span>Basic Own Damage</span>
              </div>
              <div class="col-4 mt-2 text-right">
                <h5>₹ {{priceBreakup.ownDriverCover??'0' | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.geographicalExtension_OD??'0')">
              <div class="col-8  mt-1">
                <span>Geographical Extension OD </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.geographicalExtension_OD??'0' | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.biFuelKit??'0')">
              <div class="col-8  mt-1">
                <span>Bi-Fuel Kit </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.biFuelKit??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.electricalAccessories??'0')">
              <div class="col-8  mt-1">
                <span>Electrical Accessories {{this.priceBreakup.insuranceCompany==8? '(Max 20% of IDV)' : ''}}</span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.electricalAccessories??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.nonElectricalAccessories??'0')">
              <div class="col-8  mt-1">
                <span>Non Electrical Accessories {{this.priceBreakup.insuranceCompany==8? '(Max 20% of IDV)' : ''}}</span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.nonElectricalAccessories??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.fpP_Premium??'0')">
              <div class="col-8  mt-1">
                <span>FPP Cover</span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.fpP_Premium??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.nfpP_Premium??'0')">
              <div class="col-8  mt-1">
                <span>NFPP Cover </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.nfpP_Premium??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.trailers_Premium??'0')">
              <div class="col-8  mt-1">
                <span>Trailers Cover </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.trailers_Premium??"0" | thousandSeparator}}</h5>
              </div>
            </div>   
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.imT23??'0')">
              <div class="col-8  mt-1">
                <span>IMT23 </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.imT23??"0" | thousandSeparator}}</h5>
              </div>
            </div>  
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.additionalGVW??'0')">
              <div class="col-8  mt-1">
                <span>Additional GVW </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.additionalGVW??"0" | thousandSeparator}}</h5>
              </div>
            </div>          
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.ncB_Discount??'0')">
              <div class="col-8  mt-1">
                <span>NCB Discount({{insuranceformData.vehicleDetails?.ncbCurrent??"0"}}%)</span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5 class="text-success">- ₹ {{priceBreakup.ncB_Discount??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.deTariffDiscount??'0')">
              <div class="col-8  mt-1">
                <span>De-Tariff Discount</span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5 class="text-success">- ₹ {{priceBreakup.deTariffDiscount??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.antiTheftDisc??'0')">
              <div class="col-8  mt-1">
                <span>Anti Theft Device Discount </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5 class="text-success">- ₹ {{priceBreakup.antiTheftDisc??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.addOnValue??'0') && this.isThirdParty==false">
              <div class="col-8  mt-1">
                <span>Addons Amount <span class="text-main font-10 cursor-pointer" (click)="showHideAddonDetails()">{{ showDetails ? 'Hide Details' : 'Show Details'}}</span></span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.addOnValue??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div *ngIf="showDetails && selectedPriceBreakupAddons.length > 0  && this.isThirdParty==false" class="card px-4 py-2 mb-2">
              <div class="row">
                  <div class="col-6  mt-1" *ngFor="let addon of selectedPriceBreakupAddons" >
                    <span>{{addon.name}}</span>
                  </div>
                </div>
            </div> 
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0')">
              <div class="col-12 fw-bolder border-top border-bottom py-1 bg-grey2">
                <span>{{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? 'B -' : ''}} Liability Premium</span>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0')">
              <div class="col-8  mt-2">
                <span>Basic Liability</span>
              </div>
              <div class="col-4 mt-2 text-right">
                <h5>₹ {{priceBreakup.thirdPartyCoverPremium??'0' | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.biFuelKit_TP??'0')">
              <div class="col-8  mt-1">
                <span>Bi-Fuel Kit </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.biFuelKit_TP??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.geographicalExtension_TP??'0')">
              <div class="col-8  mt-1">
                <span>Geographical Extension TP </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.geographicalExtension_TP??'0' | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paCover??'0')">
              <div class="col-8  mt-1">
                <span>PA Cover To Owner</span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.paCover??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.unamedPassenger??'0')">
              <div class="col-8  mt-1">
                <span>PA Cover for Unnamed Passenger </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.unamedPassenger??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToPaidDriver??'0')">
              <div class="col-8  mt-1">
                <span>PA To Paid Driver </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.paToPaidDriver??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToEmployee??'0')">
              <div class="col-8  mt-1">
                <span>PA To Employee </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.paToEmployee??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToPaidConductor??'0')">
              <div class="col-8  mt-1">
                <span>PA To Conductor </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.paToPaidConductor??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.paToPaidCleaner??'0')">
              <div class="col-8  mt-1">
                <span>PA To Cleaner </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.paToPaidCleaner??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPaidDriver??'0')">
              <div class="col-8  mt-1">
                <span>LL To Paid Driver </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.llToPaidDriver??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPassenger??'0')">
              <div class="col-8  mt-1">
                <span>LL To Passengers </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.llToPassenger??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToEmployee??'0')">
              <div class="col-8  mt-1">
                <span>LL To Employee </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.llToEmployee??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToNonEmployee??'0')">
              <div class="col-8  mt-1">
                <span>LL To Non-Employee </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.llToNonEmployee??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPaidConductor??'0')">
              <div class="col-8  mt-1">
                <span>LL To Conductor </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.llToPaidConductor??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.llToPaidCleaner??'0')">
              <div class="col-8  mt-1">
                <span>LL To Cleaner </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.llToPaidCleaner??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            
            <div class="row" *ngIf="checkValueNotZero(priceBreakup.tppD_Discount??'0')">
              <div class="col-8  mt-1">
                <span>TPPD Discount </span>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5 class="text-success">- ₹ {{priceBreakup.tppD_Discount??"0" | thousandSeparator}}</h5>
              </div>
            </div>
            <hr class="premium_breakup my-1">
            <div class="row">
              <div class="col-8 mt-1">
                <h5>Net Premium {{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? '(A + B)' : ''}}</h5>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.netPremium??'0' | thousandSeparator}}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-8 mt-1">
                <span>GST(18%)</span>
              </div>
              <div class="col-4 mt-1 text-right" >
                <h5 class="text-danger">+ ₹ {{priceBreakup.serviceTaxValue??'0' | thousandSeparator}}</h5>
              </div>
            </div>
            <hr class="premium_breakup my-1">
            <div class="row">
              <div class="col-8 mt-1">
                <h5>Final Premium</h5>
              </div>
              <div class="col-4 mt-1 text-right">
                <h5>₹ {{priceBreakup.finalPremium ??'0' | thousandSeparator}}</h5>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12 text-end mt-5">
                <button class="btn btn-primary" (click)="closeModal()">
                  Close
                </button>
              </div>
            </div> -->
          </div>
      </div>
    </div>

    <div class="overlay" *ngIf="showIDVTooltip">
      <div class="popup2 position-relative col-md-4 col-11 ">
        <div class="modal-header tooltip_">
          <h3>Insured Declared Value (IDV) </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeIDVModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">Insured Declared Value is an estimated current market value of your vehicle as determined by different insurance provider. It represents the maximum amount you can claim in case of theft or total loss. Make sure your IDV is set accurately to ensure you get the right coverage for your vehicle.</p>
        </div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="showNCBTooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>No Claim Bonus (NCB) </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeNCBModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">No Claim Bonus is a discount offered to policyholders on their insurance premiums when they haven't made any claims during the policy term. The longer you go without a claim, the higher your NCB, saving you money on your insurance costs.</p>
        </div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="showPACoverTooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>PA Cover </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closePACoverModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">As per IRDAI guidelines, PA Cover is mandatory to ensure that vehicle owners and drivers have a basic level of financial protection upto ₹15Lakh against the risks associated with road accidents.</p>
            <div class="row">
              <div class="col py-1 border fw-bolder">Coverage</div>
              <div class="col py-1 border fw-bolder">% Age of Compensation</div>
            </div>
            <div class="row">
              <div class="col py-1 border">Death</div>
              <div class="col py-1 border">100%</div>
            </div>
            <div class="row">
              <div class="col py-1 border">Loss of 2 limbs or 2 eyes or 1 limbs and 1 eye</div>
              <div class="col py-1 border">100%</div>
            </div>
            <div class="row">
              <div class="col py-1 border">Loss of 1 limb or sight of 1 eye</div>
              <div class="col py-1 border">50%</div>
            </div>
            <div class="row">
              <div class="col py-1 border">Permanent Total Disability</div>
              <div class="col py-1 border">100%</div>
            </div>
        </div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="showUNPACoverTooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>Unnamed PA Cover</h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeUNPACoverModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">An unnamed passenger PA cover is an add-on to a motor insurance that provides personal accident coverage for passengers in a vehicle. The maximum capital sum insured for each unnamed passenger under a motor insurance is ₹2Lakhs.</p>
        </div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="showExistingAddonTooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>Add-ons in Existing Policy </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExistingAddonModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">If you haven't opted for Zero/Nil Dep, Consumable Cover, and/or Invoice Cover in your previous policy, you won't be able to purchase the policy with these add-ons to your current policy.</p>
        </div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="showShareModal">
      <div class="popup2 col-md-3 col-11">
        <div class="modal-header tooltip_">
          <h3>Quote Share Options </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeShowShareModal()">
            <span aria-hidden="true"></span>
         </button>
        </div>
        <div class="modal-body">
          <form id="kt_account_profile_details_form" class="form" [formGroup]="shareQuotesForm" novalidate="novalidate">
          <div class="row mb-3">
            <div class="col-12">Email</div>
            <div class="col-9">
              <input type="email" class="form-control" name="email" formControlName="emailAddress">
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-primary px-1 py-1 form-control" (click)="share('email')" [disabled]="!shareQuotesFormControl['emailAddress'].valid">Send</button>
            </div>
          </div>          
          <div class="row">
            <div class="col-12">Link</div>
            <div class="col-9">
              <input type="text" class="form-control" [value]="quoteShareLink" >
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-primary px-1 py-1 form-control" (click)="share('copy')">Copy</button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="ShowFinace">
        <div class="popup2 position-relative col-md-4 col-11">
          <div class="modal-header tooltip_">
            <h3>Vehicle on Finance </h3>
            <button type="button" class="close pull-right" aria-label="Close" (click)="closeShowFinace()">
              <span aria-hidden="true"></span>
          </button>
          </div>
          <div class="modal-body">
            <div class="">        
              <p class="text-grey4">If you've financed your vehicle through a loan, the insurance coverage will be directed towards the bank as it technically owns your vehicle until the loan is fully repaid.</p>
          </div>
          </div>
        </div>
      </div>
   </div>
   <div class="overlay" *ngIf="showIMT23Tooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>IMT-23 </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeShowIMT23Modal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">IMT-23 covers items like paint, tires, bonnet, fender, bumper, and front headlights.</p>
        </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="showIMT47Tooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>IMT-47 </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeShowIMT47Modal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">IMT-47 covers Mobile Cranes, Drilling Rigs, Mobile Plants, Excavators, Navvies, Shovels, Grabs and Rippers.</p>
        </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="showAntiTheftTooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>Anti-Theft Device Discount </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeShowAntiTheftModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">Your vehicle's anti-theft device must be certified by the Automotive Research Association of India (ARAI) to get the discount.</p>
        </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="showPACoverNotSelectingTooltip">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>Not opting PA Cover </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closePACoverNotSelectingModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">As per IRDAI guidelines, PA Cover is mandatory. By selecting No, you are confirming that you already have a PA cover in your previous policy or you don't have a valid driving license.</p>
        </div>
        </div>
      </div>
    </div>
    <div class="overlay" *ngIf="showAccessAountModal">
      <div class="popup2 position-relative col-md-4 col-11">
        <div class="modal-header tooltip_">
          <h3>Claim Excess Amount </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeAccessAountModal()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body">
          <div class="">        
            <p class="text-grey4">It is also known as Compulsory Deductible, refers to a fixed amount set by the insurer that the policyholder must pay out of their pocket when they make an insurance claim.</p>
        </div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="isPopupFormVisible">
      <div class="popup2 position-relative col-md-8 col-11">
        <div class="modal-header tooltip_">
          <h3>Edit Vehicle Details </h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closePopupForm()">
            <span aria-hidden="true"></span>
        </button>
        </div>
        <div class="modal-body pt-2 pop-min-340">
          <form [formGroup]="vehicleForm" class="needs-validation policy_form mb-16" novalidate="" autocomplete="off">
            <div class="row justify-content-between">             
              <ng-container *ngIf="this.insuranceformData.vehicleType == 3">
                <div class="col-md-12 border-bottom pb-2">
                  <div class="row">
                    <div class="col-md-4 mt-2">
                      <div class="w-100">
                        <p class="text-start text-grey4 mb-0 required">Vehicle Category</p>
                        <select class="form-select form-select-custom" formControlName="category" (change)="onCategoryChanged()">
                          <option *ngFor="let item of CommVehicleCategoryList; let i = index;" value="{{item.value}}">{{item.text}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2">
                      <div class="w-100">
                        <p class="text-start text-grey4 mb-0 required">Vehicle Type</p>
                        <select class="form-select form-select-custom" aria-label="Default select example" formControlName="pcVehicle" *ngIf="vehicleFormControl['category'].value == 'PCV'" (change)="vehicleTypeChange()">
                          <option selected value="">PCV Vehicle Type</option>
                          <option *ngFor="let item of CommVehiclePCVehicleList" value="{{item.value}}">{{item.text}}</option>
                        </select>
                        <select class="form-select form-select-custom" aria-label="Default select example" formControlName="gcVehicle" *ngIf="vehicleFormControl['category'].value == 'GCV'" (change)="vehicleTypeChange()">
                          <option selected value="">GCV Vehicle Type</option>
                          <option *ngFor="let item of CommVehicleGCVehicleList" value="{{item.value}}">{{item.text}}</option>
                        </select>
                        <select class="form-select form-select-custom" aria-label="Default select example" formControlName="miscVehicle" *ngIf="vehicleFormControl['category'].value == 'MISC'" (change)="vehicleTypeChange()">
                          <option selected value="">MISC Vehicle Type</option>
                          <option *ngFor="let item of CommVehicleMISCVehicleList" value="{{item.value}}">{{item.text}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4 mt-2" *ngIf="vehicleFormControl['pcVehicle'].value?.toUpperCase() == 'BUS' && vehicleFormControl['category'].value == 'PCV'" >
                      <div class="w-100">
                        <p class="text-start text-grey4 mb-0 required">Seaters</p>
                        <select class="form-select form-select-custom" aria-label="Default select example" formControlName="seatingCap" (change)="seatingCapChange">
                          <option selected value="">Select Seaters</option>
                          <option *ngFor="let item of CommVehiclePassCapList" value="{{item.value}}">{{item.text}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>   
              <div class="col-md-4 mt-2">
                <div class="w-100">
                  <p class="text-start text-grey4 mb-0 required">RTO</p>
                  <app-autocomplete class="w-100" (itemSelected)="onRTOSelection($event)" [items]="rtoList"
                    placeholder="City/RTO" (onKeyUpEvent)="onRTOBlur($event,true)"
                    [userInput]="vehicleFormControl['rto'].value"
                    [showError]="vehicleFormControl['rto'].invalid && isFormSubmitted"></app-autocomplete>
                  <span class="text-danger"
                    *ngIf="vehicleFormControl['rto'].invalid && isFormSubmitted">RTO is required.</span>
                </div>
              </div>           
              <div class="col-md-4 mt-2">
                <div class="w-100">
                  <p class="text-start text-grey4 mb-0 required">Manufacturer</p>
                  <app-autocomplete class="w-100" (itemSelected)="onMakeSelection($event)" [items]="vehicleMakeList"
                    placeholder="Make" (onBlurEvent)="onMakeBlur($event)"
                    [userInput]="vehicleFormControl['make'].value"
                    [showError]="vehicleFormControl['make'].invalid && isFormSubmitted"></app-autocomplete>
                  <span class="text-danger"
                    *ngIf="vehicleFormControl['make'].invalid && isFormSubmitted">Manufacturer is required.</span>
                </div>
              </div>
              <div class="col-md-4 mt-2">
                <div class="w-100">
                  <p class="text-start text-grey4 mb-0 required">Model</p>
                  <!-- (onBlurEvent)="onModelBlur($event)" -->
                  <app-autocomplete class="w-100" (itemSelected)="onModelSelection($event)"
                    [items]="vehicleModelList" placeholder="Model" 
                    (onBlurEvent)="onModelBlur($event)"
                    [userInput]="vehicleFormControl['model'].value"
                    [showError]="vehicleFormControl['model'].invalid && isFormSubmitted"></app-autocomplete>
                  <span class="text-danger" *ngIf="vehicleFormControl['model'].invalid && isFormSubmitted">Model is
                    required.</span>
                </div>
              </div>
              <div class="col-md-4 mt-2">
                <div class="w-100">
                  <p class="text-start text-grey4 mb-0 required">Fuel Type</p>
                  <select class="form-select form-select-custom" formControlName="fuelType" (change)="fuelChange()">
                    <option *ngIf="fuelType == null || fuelType == undefined || (fuelType && fuelType.length < 1)">Select Fuel type</option>
                    <option *ngFor="let item of fuelType;" value="{{item.name}}">{{item.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 mt-2">
                <div class="w-100">
                  <p class="text-start text-grey4 mb-0 required">Variant</p>
                  <app-autocomplete class="w-100" (itemSelected)="onVariantSelection($event)"
                    [userInput]="vehicleFormControl['variant'].value"
                    [items]="vehicleVariantList" placeholder="Variant" (onBlurEvent)="onVariantBlur($event)"
                    [showError]="vehicleFormControl['variant'].invalid && isFormSubmitted"></app-autocomplete>
                  <span class="text-danger" *ngIf="vehicleFormControl['variant'].invalid && isFormSubmitted">Variant
                    is required.</span>
                </div>
              </div>
              <fieldset [disabled]="true" class="col-md-4 mt-2" *ngIf="this.insuranceformData.vehicleType == 3 && vehicleFormControl['category'].value == 'GCV' || vehicleFormControl['category'].value == 'MISC'" >
                <div >
                  <div class="w-100">
                    <p class="text-start text-grey4 mb-0 required">GVW</p>
                    <select class="form-select form-select-custom" aria-label="Default select example" formControlName="gVW" [disabled]="true">
                      <option *ngFor="let item of CommVehicleWeightList" value="{{item.value}}">{{item.text}}</option>
                    </select>
                  </div>
                </div>
              </fieldset>
              <div class="col-md-4 mt-2" *ngIf="(this.insuranceformData?.vehicleType == 3 && vehicleFormControl['category']?.value == 'PCV') || this.insuranceformData?.vehicleType == 1 || this.insuranceformData?.vehicleType == 2" >
                <div class="w-100">
                  <p class="text-start text-grey4 mb-0 required">Cubic Capacity</p>
                  <input type="text" name="engineCapacityAmount" formControlName="engineCapacityAmount" (keypress)="numberOnly($event)" class="form-control" [readonly]="true">
                </div>
              </div>              
              <div class="col-md-4 mt-2" >
                <p class="text-start text-grey4 mb-0 required">Registration Date</p>
                <input class="form-control" type="date" formControlName="registrationDate" name="registrationDate" placeholder="Registration Date" onkeydown="return false" [readonly]="this.vehicleData.isNew == true" [max]="registrationMaxDate">
              </div>
              <div class="col-md-12 border-top mt-2" *ngIf="this.vehicleData.isNew != true">
                <div class="row">
                  <div class="col-md-4 mt-2" >
                    <p class="text-start text-grey4 mb-0 required">Policy's Expiry Status</p>
                    <select class="form-select form-select-custom" formControlName="policyExpiryStatus" (change)="policyDetail($event)">
                    <option *ngFor="let item of policyExpiryStatusList; let i = index;" value="{{item.value}}">
                      {{item.text}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 mt-2" >
                    <p class="text-start text-grey4 mb-0 required">Existing Policy Company</p>
                    <app-autocomplete class="w-100" [items]="insuranceCompaniesList"
                      placeholder="Type and search existing policy company" (itemSelected)="onPrevCompSelection($event)"
                      (onBlurEvent)="onPrevCompBlur($event)" [userInput]="vehicleFormControl['previousPolicyCompany'].value"
                      [showError]="vehicleFormControl['previousPolicyCompany'].value == ''"></app-autocomplete>
                      <span class="text-danger" *ngIf="vehicleFormControl['previousPolicyCompany'].value == ''">Existing policy company is required.</span>
                  </div>
                  <div class="col-md-4 mt-2" >
                    <p class="text-start text-grey4 mb-0 required">Existing Policy Expiry Date</p>
                    <input type="date" name="previousPolicyExpiryDate" formControlName="previousPolicyExpiryDate"
                    class="form-control" [max]="maxPolicyExpiryDate" [min]="minPolicyExpiryDate" onkeydown="return false">
                  </div>
                  <div class="col-md-4 mt-2">
                    <p class="text-start text-grey4 mb-0 required">Existing Policy Type</p>
                    <select class="form-select form-select-custom" formControlName="previousPolicyType" (change)="coverageDetail($event)">
                    <ng-container *ngFor="let item of policyTypeList; let i = index;">
                      <option  value="{{item.value}}" *ngIf="item.isVisible">{{item.text}}</option>
                    </ng-container>
                    </select>
                  </div>
                  <div class="col-md-4 mt-2">
                    <p class="text-start text-grey4 mb-0 required">Claim made in Existing Policy</p>
                    <select class="form-select form-select-custom" formControlName="claimsMadeInPreviousPolicy" (change)="preClaimDetail($event)">
                      <option *ngFor="let item of policyClaimedList; let i = index;" value="{{item.value}}">
                      {{item.text}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 mt-2" *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'No' || (vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes' && vehicleFormControl['isNCBProtectionOptedInExistingPolicy'].value == 'Yes')">
                    <p class="text-start text-grey4 mb-0 required">Existing NCB (No Claim Bonus)</p>
                    <select class="form-select form-select-custom" formControlName="ncbprevious" (change)="nsbValDetail($event)">
                      <option *ngFor="let item of policyNcbClaimedList; let i = index;" value="{{item.value}}">
                        {{item.text}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 mt-2" *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <p class="text-start text-grey4 mb-0 required">Existing policy claim(s) no.</p>
                    <select class="form-select form-select-custom" formControlName="claimsMadeInPreviousPolicyNo" (change)="claimCountChanged($event)">
                    <option *ngFor="let item of policyNcbClaimMadeList; let i = index;" value="{{item.value}}">
                    {{item.text}}</option>
                  </select>
                  </div>
                  <div class="col-md-4 mt-2" *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <p class="text-start text-grey4 mb-0 required">Claimed Amount</p>
                    <span class="input-icon">
                      <input type="text" class="form-control" formControlName="claimsMadeInPreviousPolicyAmount" (keypress)="numberOnly($event)">
                      <i>₹</i> 
                    </span>  
                  </div>
                  <div class="col-md-4 mt-2" *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <p class="text-start text-grey4 mb-0 required">NCB protection opted in existing policy</p>
                    <select class="form-select form-select-custom" formControlName="isNCBProtectionOptedInExistingPolicy">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row justify-content-center bottom-absolute">
            <div class="col-md-6">
              <button type="button" class="btn btn-primary w-100" (click)="updateVehicle()" [disabled]="isVehicleFormInvalid()"> Get Quotes </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>