import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InsuranceService } from '../Services/insurance.service';
import swal from 'sweetalert2';
import { ApiResponse } from '../Models/ApiResponse';
import { RegistrationModel, VehicleDetails } from '../Models/vehicle-component.model';
import { BusinessService } from '../Services/business.service';
import { PincodeMaster } from '../Models/signUp.component.model';
import { PasswordType } from '../Models/login.component.model';
import { LookUpService } from './../Services/lookup.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { QuotesLoginComponent } from '../Login/quotes-login/quotes-login.component';

//#region don't have bike number
import { VehicleInfoModel, LookUpViewModel, RTONamesViewModel, PinCodeMasterViewModel } from './../Models/vehicle-component.model';
import { VehicleService } from '../Services/vehicle.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../Login/login.component';
import { ReviewvehicledetailsComponent } from '../reviewvehicledetails/reviewvehicledetails.component';
import { UserService } from 'src/app/Services/user.service';
import { LanguageLableService } from '../Services/language-lable.service';
import { LoginService } from '../Services/login.service';
import { SignupService } from '../Services/signup.service';
import { GeneralService } from '../Services/general.service';
import * as moment from 'moment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  loginForm: FormGroup;
  signupForm: FormGroup;
  resetPassForm: FormGroup;
  insuranceForm: FormGroup;
  planList: any = [];
  vehicleInfoForm: FormGroup;
  vehicleDetailsForm: FormGroup;
  isForgot:boolean=true;
  tabsForm: FormGroup;
  submitted:boolean = false;
  submittedOtp:boolean = false;
  submittedPass:boolean = false;
  mobileNo: string = '';
  isReset:boolean=false;
  isPassSame:boolean=true;
  isValidLoginForm:boolean= false;
  passwordShowConfirm: boolean = false;
  public passwordTypeConfirm : string = PasswordType.pwd;
  vehicleModel = new VehicleInfoModel();
  vehicleCompanyList: LookUpViewModel[] = [];
  vehicleModelList: LookUpViewModel[] = [];
  vehicleVariantList: LookUpViewModel[] = [];
  cityList: any[];//RTONamesViewModel[] = [];
  rtoNamesList: RTONamesViewModel[] = [];
  year = new Date().getFullYear();
  registrationYear: any = [];
  cityName: string = "";
  make: string = "";
  model: string = "";
  variant: string = "";
  knowBike: boolean = true;

  isMobile: boolean = true;
  isSignup: boolean = true;
  isOtp: boolean = false;
  isNewUser: boolean = false;
  healthForm1: boolean = true;
  healthForm2: boolean = true;
  isToggle: boolean = true;
  selectedTab: number = 1;
  isViewQuotes: boolean = false;
  homeForm1: boolean = false;
  SearchCountryField = SearchCountryField;
  // separateDialCode = true;
  CountryISO = CountryISO;
  // Menu Items initialization
  isProducts:boolean = false;
  isloggedIn:boolean = true;
  isloggedOut:boolean = false;
  otpExpired: boolean = false;
  timeRemaining: Date | null = null;
  LoginPopUp:boolean=false;
  popupNo:string='login';
  component:string='1';
  registrationData = new RegistrationModel();
  userReactForm: FormGroup;
  nomineeReactForm: FormGroup;
  display1 = 'none'
  display2 = 'none';
  display3 = 'none';
  userList: any = [];
  isIllness: boolean = false;
  isViewPlanList: boolean = false;
  modalRef: BsModalRef;
  randOTP:string='';
  currentDate = new Date();
  // Signup Code
  phoneNumber: string = '';
  dob: Date | null = null;
  isReff: boolean = true;
  showPassword: boolean = false;
  ServicesDown: boolean = false;
  vehicleData: VehicleDetails = {};
  quoteRoute: boolean = false;
  pincodeMaster = new PincodeMaster();
  @Input() isUserLogged: boolean = false; 

  @Input() fullname: any="";

  @Input() currentTab:string = "";
  @Output() languageChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  selectedValue: any;
  langList: any;
  signUpFormPasswordType: string = PasswordType.pwd;
  signUpFormPasswordTypeConfirm: string = PasswordType.pwd;
  signUpFormPasswordShow: boolean = false;
  signUpFormPasswordShowConfirm: boolean = false;
  submittedLogin:boolean=false;
  submittedSignUp:boolean=false;
  resetPasswordType: string = PasswordType.pwd;
  resetPasswordTypeConfirm: string = PasswordType.pwd;
  isRememberMe: boolean = false;
  M_Number:any;
  userPass:any;
  today=new Date();
  maxDate = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');

  constructor(private languageLableService: LanguageLableService, private signupService: SignupService, private fb: FormBuilder, private insuranceservice: InsuranceService, private loginService: LoginService, private spinner: NgxSpinnerService, private vehicleService: VehicleService, private lookUpService :LookUpService, private userservice: UserService, 
    private businessService: BusinessService, private router: Router, private modalService: BsModalService, private generalService:GeneralService) {
      this.M_Number=localStorage.getItem("MobileNum");
      this.userPass=localStorage.getItem("userPass");
      this.insuranceForm = this.fb.group({
      registrationNumber: ['', Validators.required],
      VehicleDetails: this.fb.group({
        "city": [""],
        "rto": [""],
        "make": [""],
        "model": [""],
        "variant": [""],
        "registrationYear": [""],
        "registrationNumber": [""],
        "chasisNumber": [""],
        "engineNumber": [""],
        "registrationDate": [""]
      })
    });
    this.tabsForm = this.fb.group({
      "rto": ["", Validators.required],
      "make": ["", Validators.required],
      "modal": ["", Validators.required],
      "Variant": ["", Validators.required],
      "registration": ["", Validators.required],
      "city": ["", Validators.required],
    });

    this.loadBikeForm();
    this.loadQuotesModel();
    this.userForm();
    this.NomineeForm();

    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "password": ['', [Validators.required]],
      "confirm_password": [''],
      "recaptchaReactive":['', [Validators.required]]
    });

    this.signupForm = this.fb.group({
      "referralCode": [''],
      "otp": [''],
      "firstName": [''],
      "lastName": [''],
      "userName": [''],
      "phoneNumber": [''],
      "userEmail": [''],
      "streetAddress": ['T'],
      "pincodeId": ['66416'],
      "gender": ['Male'],
      "dob": [],
      "countryCode": [''],
      "passcode": [''],
      "confirmPassword": [''],
      "recaptchaReactive":['']
    });
    //this.generalService.removeAllSession();

    this.resetPassForm =  this.fb.group({
      "PhoneNumber": ['', [Validators.required]],
      "otpNumber":['']
    });
  }


  userForm() {
    this.userReactForm = this.fb.group({
      "userName": [],
      "userEmail": [],
      "phoneNumber": [],
      "panCard": [],
      "pincodeId": [],
      "streetAddress": [],
    })

  }

  NomineeForm() {
    this.nomineeReactForm = this.fb.group({
      "nomineeName": [],
      "nomineeAge": [],
      "relationshipWithNominee": [],
      "gender": [],
      'user': []
    })

  }
  languageLabelDetails(){
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    } 
  }

  ngOnInit() {
    
   this.setLoggedInUserDetails();
    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    }
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }     
    if( localStorage.getItem('routeName')){
      this.quoteRoute = true;
    }
    // this.getUserDetails();
    // alert(this.isUserLogged);
   // this.spinner.hide();
    //this.BindData();
    //this.getUserDetails();
  }

  setLoggedInUserDetails(){
    if(this.generalService.getUniqueuserid() != ''){
      this.isUserLogged = true;
      this.getUserDetails();
    }
  }

  checkUserIdLoggedIn()
  {
    
    if((sessionStorage.getItem('access_token') == null || sessionStorage.getItem('access_token') == undefined)
    && (localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != undefined && localStorage.getItem('access_token') != "") )
    {
      this.generalService.setAccessToken(localStorage.getItem('access_token') as string);
      this.generalService.setUniqueuserid(localStorage.getItem('uniqueuserid') as string);
      this.generalService.setIsAgent(localStorage.getItem('isAgent') as string);
      this.generalService.setUserDisplayName(localStorage.getItem('user_display_name') as string);
    }

    this.getLogedInUserFullName();
    if(this.generalService.getUniqueuserid() != ""){
      return true;
    }
    else
    {
      return false;
    }
    
  }

  async getLogedInUserFullName()
  {
    if(this.generalService.getUserDisplayName() != ""){
      this.fullname = this.generalService.getUserDisplayName();
    }
    else if(this.generalService.getUniqueuserid()){
        this.isUserLogged = true;
        let request = { uniqueUserId: this.generalService.getUniqueuserid() }
        const response = await this.userservice.AcquireUserInfo(request);
        if (response?.isSuccess) {
          this.fullname = `${response.result.firstName} ${response.result.lastName}`;
          this.generalService.setUserDisplayName(this.fullname);     
        }
      }
    else
    this.fullname = '';
  }
  //#region View Quotes PopUp
  async getUserDetails() {
    // const res = await this.insuranceservice.GetUserDetails();
    // if (res != null && res.result) {
    //   this.userReactForm.patchValue(res.result);
    // }
    // let request = { uniqueUserId: sessionStorage.getItem('uniqueuserid') }
    // const response = await this.userservice.AcquireUserInfo(request);
    // if (response?.isSuccess) {
    //   this.fullname = `${response.result.firstName} ${response.result.lastName}`;
  
    // }
    ;
    let request = { uniqueUserId: this.generalService.getUniqueuserid() }
    const response = await this.userservice.AcquireUserInfo(request);
    if (response?.isSuccess) {      
      this.fullname = `${response.result.firstName} ${response.result.lastName}`; 
      this.generalService.setUserDisplayName(this.fullname);          
    }
  }

  OwnerInfoDetails() {
    this.display1 = 'none';
    this.display2 = 'block';
  }

  NomineDetais() {
    this.display2 = 'none';
    this.display3 = 'block';
  }

  languageDropdown(event : Event){
    const target = event.target as HTMLLIElement;
    this.selectedValue = target.getAttribute('value');
    localStorage.setItem('selectedValue', this.selectedValue);
    this.languageLabelDetails();
    this.languageChanged.emit();
  }

  registrerDetails() {
    this.display3 = 'none';
    this.CreateQuoteConfirmation1(this.EnquiryId, this.InsuranceCompany, this.IsMockup);
  }

  async AcquireBusinessUsers() {
    const res = await this.businessService.AcquireBusinessUsers();
    if (res != null && res.result) {
      this.userList = res.result;
    }
  }

  changeUser(id: any) {
    let user = this.userList.filter((x: any) => x.uniqueUserId == id)[0];// uniqueUserId
    this.userReactForm.controls['userName'].setValue(user?.firstName);
    this.userReactForm.controls['userEmail'].setValue(user?.userEmail);
    this.userReactForm.controls['phoneNumber'].setValue(user?.phoneNumber);
    this.userReactForm.controls['pincodeId'].setValue(user?.pincode);
    this.userReactForm.controls['streetAddress'].setValue(user?.streetAddress);
  }


  //#endregion

  //  #region Don't know vehicle number 

  async cityChange(rtoName: any) {
    this.vehicleDetailsForm.value.vehicleDetails.city = rtoName;
    this.tabsForm.get('city')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.city);
    this.tabsForm.get('rto')?.setValue("");
    this.tabsForm.get('make')?.setValue("");
    this.tabsForm.get('modal')?.setValue("");
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.rtoNamesList = [];
    this.vehicleCompanyList = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.registrationYear = [];
    const res = await this.vehicleService.RTODetails(rtoName);
    if (res != null && res.result) {
      this.rtoNamesList = res.result;
    }
  }

  async rtoChange(record: any) {
    this.tabsForm.get('make')?.setValue("");
    this.tabsForm.get('modal')?.setValue("");
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.rto = record.regionCode;
    this.tabsForm.get('rto')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rto);
    await this.getVehicleCompanyDetails();
  }

  async getVehicleCompanyDetails() {
    this.vehicleCompanyList = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.registrationYear = [];
    let request = {};
    const res = await this.vehicleService.GetVehicleCompanyDetails(request);
    if (res != null && res.result) {
      this.vehicleCompanyList = res.result;
    }
  }

  async getVehicleModelDetails(record: any, vehicleCompanyId: any) {
    this.registrationYear = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.tabsForm.get('modal')?.setValue("");
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.make = record.name;
    this.tabsForm.get('make')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.make);
    let request = { Id: vehicleCompanyId };
    const res = await this.vehicleService.GetVehicleModelDetails(request);
    if (res != null && res.result) {
      this.vehicleModelList = res.result;
    }
  }

  async getVehicleVariantDetails(record: any, vehicleModelId: any) {
    this.vehicleVariantList = [];
    this.registrationYear = [];
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.model = record.name;
    this.tabsForm.get('modal')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.model);
    let request = { id: vehicleModelId };
    const res = await this.vehicleService.GetVehicleVariantDetails(request);
    if (res != null && res.result) {
      this.vehicleVariantList = res.result;
    }
  }

  async getVehicleReg(record: any) {
    this.vehicleDetailsForm.value.vehicleDetails.variant = record.name;
    this.tabsForm.get('registration')?.setValue("");
    this.tabsForm.get('Variant')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.variant);
    this.registrationYear = [];
    this.getFinancialYears();
  }

  async changeYears(record: any) {
    this.vehicleDetailsForm.value.vehicleDetails.registrationYear = record.toString();
    this.tabsForm.get('registration')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.registrationYear);
  }

  //#endregion 

  cancel() {
    this.knowBike = true;
  }



  onCloseHandled() {
    this.display = "none";
  }
  display = "none";



  Confirm() {
    this.display = "none";
    this.viewQuotes()
    this.AcquireInsurancePlans1();
  }



  async AcquireInsurancePlans1() {
    try {
      this.spinner.show();
      this.planList = [];
      let response;

      this.insuranceForm.value.VehicleDetails.registrationNumber = this.registrationData.rc_number;
      this.insuranceForm.value.VehicleDetails.chasisNumber = this.registrationData.vehicle_chasi_number;
      this.insuranceForm.value.VehicleDetails.engineNumber = this.registrationData.vehicle_engine_number;
      this.insuranceForm.value.VehicleDetails.make = this.registrationData.maker_description;
      this.insuranceForm.value.VehicleDetails.model = this.registrationData.maker_model;
      this.insuranceForm.value.registrationDate = this.registrationData.registration_date;

      this.registrationData.rc_number;
      if (this.generalService.getAccessToken() != "") {
        response = await this.insuranceservice.AcquireInsurancePlans(this.insuranceForm.value);
      }
      else {
        response = await this.insuranceservice.GetOpenQuotes(this.insuranceForm.value);
      }

      if (response?.isSuccess) {
        this.planList = response.result;
        this.spinner.hide();
      }


      if (this.planList.length == 0) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No Quotes available.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinner.hide();
    }
  }

  EnquiryId: string = "";
  InsuranceCompany: number = 0;
  IsMockup: boolean = false;
  async CreateQuoteConfirmation(EnquiryId: string, InsuranceCompany: number, IsMockup: boolean = false) {
    if (this.generalService.getAccessToken() != "") {
      this.getUserDetails();
      this.AcquireBusinessUsers();
      this.display1 = 'block';
      this.EnquiryId = EnquiryId;
      this.InsuranceCompany = InsuranceCompany;
      this.IsMockup = IsMockup;
    }
    else {
      this.modalRef = this.modalService.show(LoginComponent);
      this.modalRef.content.onClose.subscribe((result: any) => {

      });
    }

  }

  async CreateQuoteConfirmation1(EnquiryId: string, InsuranceCompany: number, IsMockup: boolean = false) {
    swal.fire({
      title: 'Are you sure?',
      text: "You want to buy this policy.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.CreateQuote(EnquiryId, InsuranceCompany, IsMockup);
      }
    })
  }

  NonineeClose() {
    this.display2 = 'none';
  }

  OwnerClose() {
    this.display1 = 'none';
  }

  vehicleClose() {
    this.display3 = 'none';
  }


  async CreateQuote(EnquiryId: string, InsuranceCompany: number, IsMockup: boolean = false) {
    this.spinner.show();
    let request = {
      EnquiryId, InsuranceCompany, IsMockPolicy: IsMockup,
      ProposerDetails: {
        FirstName: this.userReactForm.value.userName,
        LastName: this.userReactForm.value.userName,
        Email: this.userReactForm.value.userEmail,
        PhoneNumber: this.userReactForm.value.phoneNumber,

      },

      VehicleDetails: {
        ChasisNumber: this.registrationData.vehicle_chasi_number,
        EngineNumber: this.registrationData.vehicle_engine_number,
        RegistrationNumber: this.registrationData.rc_number,
        Make: this.registrationData.maker_model
      },
      NomineeDetails: {
        nomineeName: this.nomineeReactForm.value.nomineeName,
        nomineeAge: this.nomineeReactForm.value.nomineeAge,
        relationshipWithNominee: this.nomineeReactForm.value.relationshipWithNominee,
        gender: this.nomineeReactForm.value.gender
      }


    };
    const response: any = await this.insuranceservice.CreateQuote(request);
    if (response?.isSuccess) {
      if (response.result.paymentLink != '' && !IsMockup) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: response?.result?.Message,
          showConfirmButton: false,
          timer: 3000,
        });

        window.open(response.result.paymentLink, "_self")
      }
      else {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: `Policy Created Successfully!`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.router.navigate(['policyDetails']);
      }



    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: response?.result?.Message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    this.spinner.hide();
  }

  onChangeRegistration(event: any) {

  }

  //#region  Don't have bike number
  async BindData() {

    await this.getFinancialYears();
    await this.getPolicyCityList();
  }

  getFinancialYears() {
    this.registrationYear = [];
    for (var i = (this.year - 19); i < (this.year + 1); i++) {
      this.registrationYear.push(i).orderb
    }
    if (this.registrationYear)
      this.registrationYear.sort(function (a: any, b: any) { return b - a })
  }

  async GetQuoteInfo() {
    if (this.vehicleDetailsForm.value) {
      this.spinner.show();
      this.planList = [];
      const response = await this.insuranceservice.AcquireInsurancePlans(this.vehicleDetailsForm.value);
      if (response?.isSuccess) {
        this.tabsForm.reset();
        this.vehicleDetailsForm.reset();
        this.rtoNamesList = [];
        this.vehicleCompanyList = [];
        this.vehicleModelList = [];
        this.vehicleVariantList = [];
        this.registrationYear = [];
        this.viewQuotes();
        response.result.logo = 'assets/images/Royal_Sundaram.jpg';
        this.planList = response.result;
        this.knowBike = true;
        this.spinner.hide();
      }
    }
  }



  async getPolicyCityList(cityName?: string) {
    this.cityList = [];
    this.rtoNamesList = [];
    this.vehicleInfoForm.controls['rto'].disable();
    if (cityName == undefined)
      cityName = "";
    const res = await this.vehicleService.GetPolicyCities(cityName);
    if (res != null && res.data) {
      this.cityList = res.data;

    }
  }

  async onBlurCity(cityName: string) {
    if (cityName.length > 2) {
      this.cityList = [];
      this.rtoNamesList = [];
      this.vehicleInfoForm.controls['rto'].disable();
      if (cityName == undefined)
        cityName = "";
      const res = await this.vehicleService.GetPolicyCities(cityName);
      if (res != null && res.data) {
        this.cityList = res.data;
      }

    }
    else if (!cityName)
      await this.getPolicyCityList(cityName)
  }


  loadBikeForm() {
    this.vehicleInfoForm = this.fb.group({
      "model": ['', [Validators.required]],
      "make": ['', [Validators.required]],
      "registrationYear": ['', [Validators.required]],
      "variant": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "rto": ['', [Validators.required]],

    });
  }

  loadQuotesModel() {
    this.vehicleDetailsForm = this.fb.group({
      "vehicleDetails": this.fb.group({
        "model": [''],
        "make": [''],
        "registrationYear": ['', ''],
        "variant": [''],
        "city": ['', ''],
        "rto": ['', ''],
      }),
      'insuranceCompany': [3]
    });
  }

  bikeNaviagtion() {
    this.knowBike = !this.knowBike;
    this.rtoNamesList = [];
    this.planList = [];
    this.getPolicyCityList("");
  }

  changeTab(event: any, tabNumber: any) {
    var tabs = document.getElementsByClassName("nav-item");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("active");
    }
    event.target.classList.add("active");

  }


  viewQuotes() {
    this.isViewQuotes = !this.isViewQuotes;
  }
  //#endregion

  otp(value: any) {
    if (this.isOtp == false) {
      this.isMobile = !this.isMobile;
      this.isOtp = !this.isOtp;
    }
    else {
      if (value == 'home') {
        this.homeForm1 = !this.homeForm1;
      }
      else if (value == 'health') {
        this.healthForm1 = !this.healthForm1;
        this.isToggle = !this.isToggle;
      }
    }
  }

  viewIllness() {
    this.isIllness = !this.isIllness;
    this.healthForm1 = !this.healthForm1
  }

  viewPlanList() {
    this.isViewPlanList = !this.isViewPlanList;
  }

  activeMenu = 0;
  continue() {
    if (this.selectedTab < 5) {

      this.selectedTab = this.selectedTab + 1;
      console.log('enter')
      console.log(this.selectedTab)
    }

    if (this.activeMenu == 0) {
      this.isAge = true;
      this.isWhoInsureFilled = true;
    }
    else if (this.activeMenu == 1) {
      this.isLocation = true;
      this.isAgeFilled = true;
    }
    else if (this.activeMenu == 2) {
      this.isDetails = true;
      this.isLocationFilled = true;
    }
    this.activeMenu++;
  }

  back() {
    if (this.selectedTab > 1) {
      this.selectedTab = this.selectedTab - 1;
    }
  }

  isBike: boolean = this.currentTab == "" ? false : (this.currentTab == "Bike") ? true : false;
  isCar: boolean =  this.currentTab == "" ? false : (this.currentTab == "Car") ? true : false;
  isHealth: boolean =  this.currentTab == "" ? false : (this.currentTab == "Health") ? true : false;
  isTravel: boolean =  this.currentTab == "" ? false : (this.currentTab == "Travel") ? true : false;
  isProperty: boolean =  this.currentTab == "" ? false : (this.currentTab == "Property") ? true : false;
  isHome: boolean =  this.currentTab == "" ? false : (this.currentTab == "Home") ? true : false;
  isLife: boolean =  this.currentTab == "" ? false : (this.currentTab == "Life") ? true : false;
  isTerm: boolean =  this.currentTab == "" ? false : (this.currentTab == "Term") ? true : false;

  goTo(value: string,fromHomePage:boolean = false) {
    if(fromHomePage == true)
    {
      this.isProducts = true;
    }
    if (value == 'Bike') {
      this.isBike = true;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel = false;
      this.isProperty=false;
    }
    else if (value == 'Car') {
      this.isBike = false;
      this.isCar = true;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel = false;
      this.isProperty=false;
    }
    else if (value == 'Health') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = true;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel = false;
      this.isProperty=false;
    }
    else if (value == 'Home') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = true;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel = false;
      this.isProperty=false;
    }
    else if (value == 'Life') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = true;
      this.isTerm = false;
      this.isTravel = false;
      this.isProperty=false;
    }
    else if (value == 'Term') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = true;
      this.isTravel = false;
      this.isProperty=false;
    }
    else if (value == 'Travel') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel = true;
      this.isProperty=false;
    }
    else if (value == 'Property') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel = false;
      this.isProperty=true;
    }
  }

  isSelf: boolean = false;
  isSpouse: boolean = false;
  isSon: boolean = false;
  isDaughter: boolean = false;
  isFather: boolean = false;
  isMother: boolean = false;
  isOther: boolean = false;
  selectedCard(value: string) {
    if (value == 'Self') {
      this.isSelf = !this.isSelf;
    }
    else if (value == 'Spouse') {
      this.isSpouse = !this.isSpouse;
    }
    else if (value == 'Son') {
      this.isSon = !this.isSon;
    }
    else if (value == 'Daughter') {
      this.isDaughter = !this.isDaughter;
    }
    else if (value == 'Father') {
      this.isFather = !this.isFather;
    }
    else if (value == 'Mother') {
      this.isMother = !this.isMother;
    }
    else if (value == 'Other') {
      this.isOther = !this.isOther;
    }
  }

  isWhoInsure: boolean = true;
  isAge: boolean = false;
  isLocation: boolean = false;
  isDetails: boolean = false;
  isWhoInsureFilled: boolean = false;
  isAgeFilled: boolean = false;
  isLocationFilled: boolean = false;
  isDetailsFilled: boolean = false;
  selectedMenu(value: string) {
    if (value == 'Age') {
      this.isAge = true;
      this.isWhoInsureFilled = true;
    }
    else if (value == 'Location') {
      this.isLocation = true;
      this.isAgeFilled = true;
    }
    else if (value == 'Details') {
      this.isDetails = true;
      this.isLocationFilled = true;
    }
  }

  // pincodeMaster :PincodeMaster[]=[];
  async pincodeUserChangeEvent(pincode: any) {
    const res = await this.lookUpService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.pincodeMaster = res.result;
     // this.pincodeMasterId = this.pincodeMaster?.id;
    }
  }
  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  // Menu Items Click
  menuItems(value:any) {
    if(value == 'products') {
      this.isProducts = !this.isProducts;
      this.router.navigate(['/']);
    }
  }
  isHomePage(): boolean {
    return this.router.url === '/';
  }

  logout() {
    if(!this.isAgent()){
      this.generalService.removeAllSession();
      this.isUserLogged = false;
      this.router.navigate(['/']);
      if(this.router.url =='/' ){
        window.location.href = window.location.href;
      }
    }
    else
    {
      this.generalService.removeAllSession();
      this.isUserLogged = false;
      this.router.navigate(['/agent-homepage']);
      if(this.router.url =='/agent-homepage' ){
        window.location.href = window.location.href;
      }
    }    
  }

  dashboard(){
    if(!this.isAgent()){
      this.router.navigate(['/dashboard']);
    }
    else
    {
      this.router.navigate(['/agent-dashboard']);
    }

  }

  profile(){
    if(!this.isAgent()){
      this.router.navigate(['/profile']);
    }
    else
    {
      this.router.navigate(['/agent-profile']);
    }
  }

  // goToMenu(menu:string){
  //   ;
  //   this.router.navigate(['/'+menu]);
  // }
  // dashboard(){
  //   this.router.navigate(['/dashboard']);
  // }

  // profile(){
  //   this.router.navigate(['/profile']);
  // }

  // agentProfile(){
  //   this.router.navigate(['/agent-profile']);
  // }

  // dashboard(){
  //   this.router.navigate(['/agent-dashboard']);
  // }

  // Sticky Header
  isSticky = false;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    // Add logic here to determine when to make the header sticky
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= 100) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }
  Login(){
    this.LoginPopUp=true;
    this.loginFormDiv = true;
    this.forgotPasswordDiv = false;
    this.signupDiv = false;
    this.loginForm.get('confirm_password')?.clearValidators();
    this.loginForm.get('confirm_password')?.updateValueAndValidity();

    this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
    //this.loginForm.get('recaptchaReactive')?.clearValidators();
    this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
    
  }
  closeModal(){
    this.LoginPopUp=false; 
    // alert(this.router);
    this.router.navigated = false;
   }
   Popup(num:string):void{
    this.popupNo=num;
  }
  next(Component:string):void{
    this.component=Component;
  }
  loginFormDiv: boolean = true;
  forgotPasswordDiv: boolean = false;
  signupDiv: boolean = false;
  timerSpanValue: boolean = false;
  timerValue: number = 60; // Start at 30 seconds
  timer: any; // To store the interval reference
  forgotPassword() {
    ;
    this.loginFormDiv = false;
    this.forgotPasswordDiv = true;
    this.signupDiv = false;
    this.isForgot = true;
    this.isOtp = false;
    this.isReset = false; 
    this.loginForm.get('confirm_password')?.clearValidators();
    this.loginForm.get('confirm_password')?.updateValueAndValidity();   

    //this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
    this.loginForm.get('recaptchaReactive')?.clearValidators();
    this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();

    this.resetPassForm.get('otpNumber')?.clearValidators();
    this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
    this.resetPassForm.reset();
  }
  registerDiv() {
    this.loginFormDiv = false;
    this.forgotPasswordDiv = false;
    this.signupDiv = true;
    this.isNewUser = false;
    this.isOtp = false;
    this.isSignup = true;
    this.DisableDetailsRequired();
    // this.signupForm.get('otp')?.clearValidators();
    // this.signupForm.get('otp')?.updateValueAndValidity();
  }
  haveAccount() {
    this.loginFormDiv = true;
    this.forgotPasswordDiv = false;
    this.signupDiv = false;
    this.loginForm.get('confirm_password')?.clearValidators();
    this.loginForm.get('confirm_password')?.updateValueAndValidity();
    this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
    this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
  }
  startTimer() {
    this.timerValue = 60;
    //this.timerSpanValue = true;
    this.timer = setInterval(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
      } else {
        this.stopTimer();
        
      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  }
  
  stopTimer() {
    clearInterval(this.timer);
  }
  // login flow
  async submit() {
    this.submittedLogin = true;
    if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
      let phone = this.loginForm.get('PhoneNumber')?.value;
      this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
    if(this.loginForm.value.password !="" && this.loginForm.value.PhoneNumber.toString() != "")
    {
    this.spinner.show();
    
    let obj={
      password : window.btoa(this.loginForm.value.password),
      PhoneNumber:this.loginForm.value.PhoneNumber.toString(),
      OTP_FOR:"CUST"
    }
    const res = await this.loginService.AuthenticateUser(obj);
      if (res?.isSuccess) {        
        this.spinner.hide();
        let data = res.result;
        if(data.isTempPassCode == true){
          swal.fire({
            imageUrl: "../../assets/alerts/success.png",
            imageHeight: 89,
            title:'',
            text: '',
            html: data?.message,
            showDenyButton: false,
            confirmButtonText: "Ok",
            confirmButtonColor:"#0DB561",
            showCancelButton: false,     
          }).then((result) => {
            if (result.isConfirmed) {
              this.signupDiv = false;
              this.forgotPasswordDiv = true; 
              this.loginFormDiv = false;
              this.isOtp = false;
              this.isForgot=false;
              this.isOtp=false;
              this.isReset=true;
              this.loginForm.reset();
              this.resetPassForm.get('PhoneNumber')?.setValue(obj.PhoneNumber?.toString());
              this.loginForm.get('confirm_password')?.setValidators([Validators.required]);
              this.loginForm.get('confirm_password')?.updateValueAndValidity();
              this.loginForm.get('recaptchaReactive')?.clearValidators();
              this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
            } else if (result.isDenied) {
   
            }
          });
        }
        else
        {
          this.generalService.setAccessToken(data.accessToken);
          this.generalService.setUniqueuserid(data.uniqueUserId);
          this.generalService.setIsAgent(data.agentId>0?'true':'false');
          this.router.navigate(['/']);
          this.LoginPopUp = false;
        }
        
      } else {
        this.spinner.hide();
        swal.fire({
          position: 'center',
          // icon: 'error',
          // title: res?.message,
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: 'Authentication Failed',
          text: res?.message ??'Please check and re-enter your valid mobile number or password and try again.',
          showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            },
        });
      }
    }  
  }
  passwordShow: boolean = false;
  public passwordType: string = PasswordType.pwd;
  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }

  signUpFormShowPassword(){    
    ;
    if (!this.signUpFormPasswordShowConfirm) {
      this.signUpFormPasswordTypeConfirm = PasswordType.txt;
      this.signUpFormPasswordShowConfirm = true;
    }
    else {
      this.signUpFormPasswordTypeConfirm = PasswordType.pwd;
      this.signUpFormPasswordShowConfirm = false;
    }
  }
  
  Showpassword(isConfirm:boolean) {
    if(isConfirm)
    {
      if (!this.passwordShowConfirm) {
        this.passwordTypeConfirm = PasswordType.txt;
        this.passwordShowConfirm = true;
      }
      else {
        this.passwordTypeConfirm = PasswordType.pwd;
        this.passwordShowConfirm = false;
      }
    }
    else
    {
      if (!this.passwordShow) {
        this.passwordType = PasswordType.txt;
        this.passwordShow = true;
      }
      else {
        this.passwordType = PasswordType.pwd;
        this.passwordShow = false;
      }
    }      
  }

  resetShowpasswordConfirm() {    
    if (!this.passwordShowConfirm) {
      this.resetPasswordTypeConfirm = PasswordType.txt;
      this.passwordShowConfirm = true;
    }
    else {
      this.resetPasswordTypeConfirm = PasswordType.pwd;
      this.passwordShowConfirm = false;
    }      
  }

  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }
  get resetPassFormControl() {
    return this.resetPassForm.controls;
  }

  async resendOTPSignUp()
  {
    ;
    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.signupForm.get('countryCode')?.setValue(phone.dialCode);
    }
      let request = { OTP_FOR: "CUST", phoneNumber: this.signupForm?.get('phoneNumber')?.value?.toString()   }
      ////const response = await this.loginService.SendOtp(request);
  
      const response = await this.signupService.CheckPhoneExists(request);
      if (response?.code == 1) {
        this.startTimer();
        swal.fire({
          position: 'center',
          // icon: 'success',
          imageUrl: "../../assets/alerts/success.png",
          imageHeight: 89,
          title: 'OTP sent successfully!',
          showConfirmButton: true,
          confirmButtonText: 'ok',
          confirmButtonColor: "#0DB561",
        });        
      }
      this.spinner.hide();
  }

  async resendOTP()
  {
    if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
      let phone = this.resetPassForm.get('PhoneNumber')?.value;
      this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
      let request = {  OTP_FOR: "CUST", phoneNumber: this.resetPassForm?.get('PhoneNumber')?.value?.toString()   }
      const response = await this.loginService.SendOtp(request);
      if (response?.code == 1) {
        this.startTimer();
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/success.png",
          imageHeight: 89,
          // icon: 'success',
          title: 'OTP sent successfully!',
          showConfirmButton: true,
          confirmButtonText: 'ok',
          confirmButtonColor: "#0DB561",
        });        
      }
      this.spinner.hide();
  }
 
  async sendOTP() {
    if(this.resetPassForm.valid == true)
    {
      this.spinner.show();
      this.submitted=true;

      
      if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
        let phone = this.resetPassForm.get('PhoneNumber')?.value;
        this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
        this.mobileNo = phone.e164Number;
      }
      this.loginForm?.get('PhoneNumber')?.setValue(this.resetPassForm?.get('PhoneNumber')?.value)
      let request = { OTP_FOR: "CUST",  phoneNumber: this.resetPassForm?.get('PhoneNumber')?.value?.toString()  }
      
      const response = await this.loginService.SendOtp(request);
        if (response?.code == 1) {
          this.startTimer();
          this.isOtp = true;
          this.isForgot = false;
          this.spinner.hide();
          this.resetPassForm.get('otpNumber')?.addValidators([Validators.required, Validators.pattern('[0-9]{6}')]);
          this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
        }
        else{
          this.spinner.hide();
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            title: response?.message??'Phone number not exists',
            text: '',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            }
          });
        }
      }
    }

    agentValue(value:string){
      //localStorage.setItem('agentValue', value);
      this.router.navigate(['/agent-homepage']);
    }

    async validateOtp() {
      this?.loginForm?.get('password')?.setValue(""); 
      if(this.resetPassForm.valid)
      {
        this.spinner.show();          
      
        if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
          let phone = this.resetPassForm.get('PhoneNumber')?.value;
          this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
        }
       
        let request = { PhoneNumber: this.resetPassForm.get('PhoneNumber')?.value.toString(), OTP: this.resetPassForm.get('otpNumber')?.value.toString() ,GeneratedOTP:''};
        const response = await this.loginService.ValidateOTP(request);
        if (response?.code == 1) {
          this.isForgot=false;
          this.isOtp=false;
          this.isReset=true;
          this.loginForm.reset();
          this.loginForm.get('confirm_password')?.setValidators([Validators.required]);
          this.loginForm.get('confirm_password')?.updateValueAndValidity();
          this.loginForm.get('recaptchaReactive')?.clearValidators();
          this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
          this.spinner.hide(); 
        }
        else {
          this.spinner.hide(); 
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            // icon: 'error',
            title: response?.message ?? 'Invalid OTP',
            text: 'Please check and re-enter your valid OTP number and try again. Thank you for your patience.',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Try Again',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            },
            // timer: 9000,
          });
        }
      }
    }
    async validateSignUpOtp() {
      if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
        let phone = this.signupForm.get('phoneNumber')?.value;
        this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
        this.signupForm.get('countryCode')?.setValue(phone.dialCode);
      }
  
      let request = {PhoneNumber: this.signupForm.get('phoneNumber')?.value, OTP: this.signupForm.get('otp')?.value ,GeneratedOTP:''};
      const response = await this.signupService.ValidateOTP(request);
      if (response?.code == 1) {
        this.isOtp = false;
        this.isNewUser = true;
        this.signupForm.get('firstName')?.setValue("");
        this.signupForm.get('lastName')?.setValue("");
        this.signupForm.get('userEmail')?.setValue("");
        this.signupForm.get('passcode')?.setValue("");
        this.signupForm.get('confirmPassword')?.setValue("");
        this.EnableDetailsRequired();
      }
      else {
        swal.fire({
          position: 'center',
          // icon: 'error',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: response?.message,
          text: 'Please check and re-enter your valid OTP number and try again. Thank you for your patience.',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Try Again',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
          // timer: 9000,
        });
      }
    }
    async resetPassword() {
      this.loginForm?.get('PhoneNumber')?.setValue(this.resetPassForm?.get('PhoneNumber')?.value);
      if(this.loginForm.valid == true && this.isPassSame == true)
      {
        let obj={
          PhoneNumber:this.loginForm.value.PhoneNumber.toString(),
          password:window.btoa(this.loginForm.value.password.toString()),
          confirm_password:window.btoa(this.loginForm.value.confirm_password.toString())
        }
        const response = await this.loginService.ResetPassword(obj);
        if (response?.isSuccess && response?.result && response?.result.code == 1) {
          swal.fire({
            position: 'center',
            // icon: 'success',
            imageUrl: "../../assets/alerts/success.png",
            imageHeight: 89,
            title: 'Password Reset Successful!!',
            text: 'Your password has been reset successfully. You can now log in with your new password',
            showConfirmButton: true,
            confirmButtonText: 'Login',
            confirmButtonColor: "#0DB561",
            // timer: 3000,
          });
          // this.router.navigate(['/login']);
          this.loginFormDiv = true;
          this.forgotPasswordDiv = false;
          this.loginForm.get('confirm_password')?.clearValidators();
          this.loginForm.get('confirm_password')?.updateValueAndValidity();
          //this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
          this.loginForm.get('recaptchaReactive')?.clearValidators();
          this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
        }
        else
        {
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            title: response?.result?.message??"Something went wrong!",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            }
          });
        }
      }
    }
    passwordChange()
    {
      if(this?.loginForm?.get('password')?.value != "" && this?.loginForm?.get('confirm_password')?.value != "" && this?.loginForm?.get('password')?.value != this?.loginForm?.get('confirm_password')?.value)
      {
        this.isPassSame = false;
        this.isValidLoginForm = false;
      }
      else
      {
        this.isPassSame = true;
        this.isValidLoginForm = true;
      }
    }
    
    changeNumber()
    {
      this.isForgot =true;
      this.isOtp=false;
      this.isReset = false;
      this.loginForm.get('confirm_password')?.clearValidators();
      this.loginForm.get('confirm_password')?.updateValueAndValidity();
      //this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
      this.loginForm.get('recaptchaReactive')?.clearValidators();
      this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
      this.resetPassForm.get('otpNumber')?.value("");
      this.resetPassForm.get('otpNumber')?.clearValidators();
      this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
    }
    get forgotPasswordFormControl() {
      return this.loginForm.controls;
    }

    get loginFormControl() {
      return this.loginForm.controls;
    }

    get signupFormControl() {
      return this.signupForm.controls;
    }
    clickBack() {
      this.forgotPasswordDiv = false;
      this.loginFormDiv = true;
      this.loginForm.get('confirm_password')?.clearValidators();
      this.loginForm.get('confirm_password')?.updateValueAndValidity();
      this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
      //this.loginForm.get('recaptchaReactive')?.clearValidators();
      this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
    }
    async PhoneExists() {
      if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
        let phone = this.signupForm.get('phoneNumber')?.value;
        this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
        this.signupForm.get('countryCode')?.setValue(phone.dialCode);
        this.phoneNumber = phone.e164Number;
      }
     
      // OTP
      let request = {OTP_FOR: "CUST",   phoneNumber: this.signupForm.get('phoneNumber')?.value,OTP :this.randOTP }
      if(request.phoneNumber == undefined || request.phoneNumber == null || 
        (request.phoneNumber != undefined && request.phoneNumber != null && request.phoneNumber.length < 10) )
        {
          // swal.fire({
          //   position: 'center',
          //   icon: 'error',
          //   title: 'Please provide valid mobile number.',
          //   showConfirmButton: false,
          //   timer: 2000,
          // });
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            // icon: 'error',
            title: 'Please provide valid mobile number.',
            //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            },
            // timer: 9000,
          });
        }
        else
        {
          const response = await this.signupService.CheckUserForSignup(request);
          if (response?.code == 1) {
            this.startTimer();
            this.isSignup = false;
            this.isOtp = true;
            this.signupForm.get('otp')?.setValidators([Validators.required]);
            this.signupForm.get('otp')?.updateValueAndValidity();
          }
          else {
            swal.fire({
              position: 'center',
              imageUrl: "../../assets/alerts/warning.png",
              imageHeight: 89,
              // icon: 'error',
              title: response?.message,
              //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'Ok',
              cancelButtonColor: "#DF3A4D",
              customClass: {
                confirmButton: "error_btn",
              },
              // timer: 9000,
            });
          }
        }
    }
    changemobile() {
      this.isOtp = false;
      this.isSignup = true;
      this.signupForm.get('otp')?.clearValidators();
      this.signupForm.get('otp')?.updateValueAndValidity();
    }
    // sendOtp() {
    //   this.isOtp = !this.isOtp
    //   this.isSignup = !this.isSignup;
    //   // this.stopTimer();
    // }
    EnableDetailsRequired() {
      this.signupForm.get('firstName')?.setValidators([Validators.required]);
      this.signupForm.get('firstName')?.updateValueAndValidity();
      this.signupForm.get('lastName')?.setValidators([Validators.required]);
      this.signupForm.get('lastName')?.updateValueAndValidity();
      this.signupForm.get('userEmail')?.setValidators([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
      this.signupForm.get('userEmail')?.updateValueAndValidity();
      this.signupForm.get('dob')?.setValidators([Validators.required]);
      this.signupForm.get('dob')?.updateValueAndValidity();
      this.signupForm.get('gender')?.setValidators([Validators.required]);
      this.signupForm.get('gender')?.updateValueAndValidity();
      this.signupForm.get('passcode')?.setValidators([Validators.required]);
      this.signupForm.get('passcode')?.updateValueAndValidity();
      this.signupForm.get('confirmPassword')?.setValidators([Validators.required]);
      this.signupForm.get('confirmPassword')?.updateValueAndValidity();
      this.signupForm.get('recaptchaReactive')?.setValidators([Validators.required]);
      //this.loginForm.get('recaptchaReactive')?.clearValidators();
      this.signupForm.get('recaptchaReactive')?.updateValueAndValidity();
    }
  
  
  
    DisableDetailsRequired() {
      this.signupForm.get('firstName')?.clearValidators();
      this.signupForm.get('firstName')?.updateValueAndValidity();
      this.signupForm.get('lastName')?.clearValidators();
      this.signupForm.get('lastName')?.updateValueAndValidity();
      // this.signupForm.get('userName')?.clearValidators();
      // this.signupForm.get('userName')?.updateValueAndValidity();
      this.signupForm.get('userEmail')?.clearValidators();
      this.signupForm.get('userEmail')?.updateValueAndValidity();
      this.signupForm.get('dob')?.clearValidators();
      this.signupForm.get('dob')?.updateValueAndValidity();
      this.signupForm.get('gender')?.clearValidators();
      this.signupForm.get('gender')?.updateValueAndValidity();
      this.signupForm.get('streetAddress')?.clearValidators();
      this.signupForm.get('streetAddress')?.updateValueAndValidity();
      this.signupForm.get('pincode')?.clearValidators();
      this.signupForm.get('pincode')?.updateValueAndValidity();
      this.signupForm.get('passcode')?.clearValidators();
      this.signupForm.get('passcode')?.updateValueAndValidity();
      this.signupForm.get('confirmPassword')?.clearValidators();
      this.signupForm.get('confirmPassword')?.updateValueAndValidity();
      this.signupForm.get('recaptchaReactive')?.clearValidators();
      this.signupForm.get('recaptchaReactive')?.updateValueAndValidity();
    }
    isView: boolean = false;
  isNoView: boolean = true;
  showHidePassword()
  {
    this.showPassword = !this.showPassword;
    if(this.showPassword==true)
    {
      this.isView = true;
      this.isNoView = false;
    }
    else{
      this.isView = false;
      this.isNoView = true;
    }
  }
  
  async submitOTPSignup() {
    this.spinner.show();
    this.submittedSignUp = true;
    if (this.signupForm.get('passcode')?.value != this.signupForm.get('confirmPassword')?.value) {
      // swal.fire({
      //   position: 'center',
      //   //icon: 'error',
      //   title: 'Password and confirm password must be same',
      //   showConfirmButton: false,
      //   timer: 3000,
      // });
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        // icon: 'error',
        title: 'Password and confirm password must be same',
        text: 'Please check your password & confirm password and try again. Thank you for your patience.',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        },
        // timer: 9000,
      });
      
      return;
    }

    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.signupForm.get('countryCode')?.setValue(phone.dialCode);
    }

    //this.signupForm.get('otp')?.setValue(this.signupForm.value.otp.toString());

    //this.signupForm.get('passcode')?.setValue(window.btoa(this.signupForm.value.passcode));
    //this.signupForm.get('confirmPassword')?.setValue(window.btoa(this.signupForm.value.confirmPassword));
    //this.signupForm.get('userName')?.setValue(this.signupForm.value.firstName + ' ' + this.signupForm.value.lastName);
    // if (this.pincodeMaster?.id != null && this.pincodeMaster?.id != undefined && this.pincodeMaster?.id != 0) {
    //   this.signupForm.get('pincodeId')?.setValue(this.pincodeMaster?.id);
    // }

    let obj ={ 
      referralCode:"",
      firstName:this.signupForm.value.firstName,
      lastName:this.signupForm.value.lastName,
      phoneNumber:this.signupForm.get('phoneNumber')?.value,
      userName:this.signupForm.value.firstName + ' ' + this.signupForm.value.lastName,
      userEmail:this.signupForm.value.userEmail,
      streetAddress:this.signupForm.value.streetAddress,
      pincodeId : this.pincodeMaster?.id != null && this.pincodeMaster?.id != undefined && this.pincodeMaster?.id != 0 ? this.pincodeMaster?.id : this.signupForm.value.pincodeId,
      gender:this.signupForm.value.gender,
      dob:this.signupForm.value.dob,
      countryCode : this.signupForm.get('countryCode')?.value,
      otp: this.signupForm.value.otp.toString(),
      passcode:window.btoa(this.signupForm.value.passcode),
      confirmPassword:window.btoa(this.signupForm.value.confirmPassword)      
    };

    const response = await this.signupService.SignUpUser(obj);
    if (response?.isSuccess && response?.result && response?.result?.accessToken != null && response?.result?.accessToken != undefined && response?.result?.accessToken != "" ) {      
      this.generalService.setAccessToken(response.result.accessToken);
      this.generalService.setUniqueuserid(response.result.uniqueUserId);
      if(this.quoteRoute == true){
        const initialState: any = {
          list: [
            {
              "quote": localStorage.getItem('quote'),
              "vehicleData": this.vehicleData,
              "IsMockup": false
            }
          ]
        };
        this.modalRef = this.modalService.show(QuotesLoginComponent, { initialState });
      }else{
        this.LoginPopUp = false;
        this.router.navigate(['/']);

      }
      //localStorage.clear();
    }
    else
    {
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: response?.result?.message??"Something went wrong!",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        }
      });
    }
    this.spinner.hide();
  }
  forgotBackF() {
    this.loginFormDiv = true;
    this.signupDiv = false;
    this.forgotPasswordDiv = false; 
    this.isOtp = false;
    this.loginForm.get('confirm_password')?.clearValidators();
    this.loginForm.get('confirm_password')?.updateValueAndValidity();
    this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
    //this.loginForm.get('recaptchaReactive')?.clearValidators();
    this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
  }
  signupBackF() {
    this.signupDiv = false;
    this.forgotPasswordDiv = true; 
    this.loginFormDiv = false;
    this.isOtp = false;
  }
  
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
  }

  alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }

  isAgent():boolean{
    return this.generalService.getIsAgent() == 'true';
  }
  isSupport:boolean=false;
  support()
  {
this.isSupport=!this.isSupport;
  }
  onInput(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
         // Allow: Ctrl/cmd+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+C
        (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+X
        (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
         // let it happen, don't do anything
         return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
    }
  }  


  Autologin() {
    // alert(this.M_Number.number);
    if (this.isRememberMe) {
      this.isRememberMe = false
    
    }
    else if (!this.isRememberMe) {

      this.isRememberMe = true
      localStorage.setItem("MobileNum",this.M_Number.number);
      localStorage.setItem("userPass",this.userPass);
    
    }
    
  }
  strongPasswordForSignUp():boolean{
    if( /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(this.signupFormControl['passcode'].value))    
      {
        
        return false;
      }
      
    return true;
  }
  strongPassword():boolean{

    if( /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(this.forgotPasswordFormControl['password'].value))    
      {
        
        return false;
      }
      
    return true;
  }

  passKeyPress(event:any){
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }
}
