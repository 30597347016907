import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InsuranceService } from 'src/app/Services/insurance.service';
import * as moment from 'moment';
import { GeneralService } from 'src/app/Services/general.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuotesLoginComponent } from 'src/app/Login/quotes-login/quotes-login.component';
@Component({
  selector: 'app-policy-quotes',
  templateUrl: './policy-quotes.component.html',
  styleUrls: ['./policy-quotes.component.css']
})
export class PolicyQuotesComponent implements OnInit {
  ageList:any[];
  requesrData : any;
  quoteList:any;
  moment: any = moment;
  premiumBreakupPopup:boolean = false;
  priceBreakup:any;
  modalRef: BsModalRef;
  constructor(private router:Router, private insuranceSerrvice : InsuranceService,private generalService : GeneralService,private route:Router,private modalService : BsModalService){
  } 
  
  ngOnInit(): void {    
  debugger;
    let data = this.generalService.getDataToLocalStorage('quoteDataTravel');
    if(data!= ""){
      let allData = JSON.parse(data);
      if(allData.quoteList != null && allData.quoteList != undefined){
        this.quoteList = allData.quoteList;
        this.requesrData = allData.memberDetails;
      }
      else
      {
        this.getQuotes(allData.memberDetails);
      }
    }
  }
checked:boolean=false;
isDetailsPopup:boolean=false;
check()
{
 if(!this.checked)
 {
  this.checked=true;
 }
 else{
  this.checked=false;
 }
 
}

close()
{
  
this.isDetailsPopup=false;
}
tabs:string='';


async getAge()
{
  this.ageList = [];
  for (var i = 18; i <= 100; i++) {
    this.ageList.push(i)
  }
}
getDetails(quote:any){
  debugger;
  if (this.generalService.getAccessToken() != "") {
    this.proceedToPurchase(quote);
  }
  else {
    const initialState: any = {
      list: [
        {
          "quote": quote
          // ,
          // "vehicleData": this.vehicleData,
          // "IsMockup": IsMockup,
          // "proposerDetails" : this.proposerDetails
        }
      ]
    };
    let config = {
      animated: false,
      initialState : initialState
    };
    this.modalRef = this.modalService.show(QuotesLoginComponent,config);
    this.modalRef.content.action.subscribe(async (data: any) => {
      if (data) {
        this.proceedToPurchase(quote);
      }
    });
  }


}

proceedToPurchase(quote:any){
  this.generalService.setDataToLocalStorage('quoteDataTravel',JSON.stringify ({memberDetails:this.requesrData,quoteList:this.quoteList,quote:quote}));
  this.router.navigate(['/travel-detail'])
}

async getQuotes(data:any){
  this.requesrData = data;
  let response = await this.insuranceSerrvice.GetTravelQuotes(data);
  if (response?.isSuccess) {
    if (response?.isSuccess) {
      this.quoteList = response.result;
    }
  }
}

back(){
  this.route.navigate(['/'])
}

showPremiumBreakupPopup(data:any){

  this.priceBreakup = data.priceBreakUp;
  this.premiumBreakupPopup = true;
}

hidePremiumBreakupPopup(){
  this.premiumBreakupPopup = false;
}

checkValueNotZero(data:string)
{
  if(data != null && data != undefined && data != "")
  {
    return parseFloat(data) > 0 ? true : false;
  }
  else
  {
    return false;
  }
}
}
