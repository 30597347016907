<div class="agent-dashboard">
    <div class="row">
        <div class="col-12 dash-box">
            <div class="container d-flex mb-5">
                <div class="row mt-5">
                    <h3>Training</h3>
                    <div class="row">
                        <div class="col">
                            <div class="bg-white box">
                                <div class="row p-4">
                                    <div class="col-3 pt-5">
                                        <img src="../../../assets/svgIcon/agent/life-insurance.svg" class="icon" alt="">
                                    </div>
                                    <div class="col-9">
                                        <h4>LI POSP Certification Course</h4>
                                        <p class="font-body">
                                            Elevate your career in just 15 hours. Our interactive training and online test lead to IRDAI certification as a Point of Sale Person (POSP). Begin your certification journey now!
                                        </p>
                                    </div>
                                </div>
                                <a href="/li-training"><button type="button" class="btn btn-primary">Start Training</button></a>
                            </div>
                        </div>
                        <!-- <div class="col">
                            <div class="bg-white box">
                                <div class="row p-4">
                                    <div class="col-3 pt-5">
                                        <img src="../../../assets/svgIcon/agent/life-insurance.svg" class="icon" alt="">
                                    </div>
                                    <div class="col-9">
                                        <h4>GI POSP Certification Course</h4>
                                        <p class="font-body">
                                            Elevate your career in just 15 hours. Our interactive training and online test lead to IRDAI certification as a Point of Sale Person (POSP). Begin your certification journey now!
                                        </p>
                                    </div>
                                </div>
                                <a href="/agent-profile"><button type="button" class="btn btn-primary">Coming Soon ....</button></a>
                            </div>
                        </div> -->
                        <div class="col box bg-white">
                            <img src="../../../assets/svgIcon/agent/trainingsession.svg" class="icon" alt="" height="200">
                            <div class="mt-5">
                                <h5>
                                    Course Progress
                                </h5>
                                <hr>
                                <h5>Details</h5>
                                <hr>
                                <p class="font-body">
                                    Insurance : <b>Life Insurance</b>
                                </p>
                                <p class="font-body">
                                    Time HH/MM : <b>10 Hours</b>
                                </p>
                                <p class="font-body">
                                    No. of lessons : <b>5</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-20 footer">
                <app-agentfooter></app-agentfooter>  
            </div>
        </div>
    </div>
</div>
