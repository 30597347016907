import { Component } from '@angular/core';

@Component({
  selector: 'app-money-back-plan',
  templateUrl: './money-back-plan.component.html',
  styleUrls: ['./money-back-plan.component.css']
})
export class MoneyBackPlanComponent {

}
