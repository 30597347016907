import { Component, HostListener, Input } from '@angular/core';
import { LanguageLableService } from 'src/app/Services/language-lable.service';

@Component({
  selector: 'app-what-is-insurance',
  templateUrl: './what-is-insurance.component.html',
  styleUrls: ['./what-is-insurance.component.css']
})
export class WhatIsInsuranceComponent {
  // @Input() langChange:boolean = false;
  
  isSmallScreen: boolean = false;selectedValue: string | null;
  langList: any;
 constructor(private languageLableService: LanguageLableService,) { this.checkScreenSize(); }  
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); }
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 768; }

  ngOnInit() {
    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }   
  } 

  isinsCard1: boolean = true;
  isinsCard2: boolean = false;
  isinsCard3: boolean = false;
  tab:string="insCard1";
  tabToggle(value: any){
    this.tab=value;
    if(value == 'insCard1'){
      this.isinsCard1 = true;
      this.isinsCard2 = false;
      this.isinsCard3 = false;
    }
    else if(value == 'insCard2'){
      this.isinsCard1 = false;
      this.isinsCard2 = true;
      this.isinsCard3 = false;
    }
    else if(value == 'insCard3'){
      this.isinsCard1 = false;
      this.isinsCard2 = false;
      this.isinsCard3 = true;
    }
  }
}

