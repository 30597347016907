<div class="content-box bg-grey1" [ngClass]="!isUserLogged ? '' : 'user-dashboard'">
    <div class="container-fluid" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}" [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="container "  style="background-color: #F3F3F4">
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="card mt-3 p-2 pb-6">
                            <div class="row mb-3 pb-6">
                                <div class="col-md-12 text-center pb-6">
                                    <img src="../../../../assets/images/success.jpg" alt="">
                                    <h4 style="color: green;">Your proposal has been submitted Successfully </h4>
                                    <img class="img-w-162" src="/assets/images/insurance_companies/{{paymentObject?.insuranceCompany}}.png" alt="">
                                    <span class="font-body d-block pb-6">Please click on below payment link to complete your policy journey</span>
                                    <!-- <button class="btb btn-primary" *ngIf="paymentObject?.base64 != null && paymentObject?.base64 != undefined && paymentObject?.base64 != ''" (click)="showProposalModal()">View Propsal</button>  -->
                                    <a [href]="paymentObject?.base64" target="_blank" download="proposal.pdf" class="btb btn-primary p-3 rounded1 mr-4" *ngIf="paymentObject?.base64 != null && paymentObject?.base64 != undefined && paymentObject?.base64 != ''">Download Proposal</a>
                                    <a [href]="paymentObject?.paymentUrl" class="btb btn-primary p-3 rounded1">Click Here to Pay ₹ {{paymentObject?.premium??'0' | thousandSeparator}}</a><img title="Copy Link" alt="Copy Link"  class="icon_width_height_30_24 icon-main ml-3 cursor-pointer" src="../../../../assets/svgIcon/copy.svg" (click)="onCoplyClick()">
                                </div>
                            </div>                           
                        </div>                       
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>


<div class="overlay" *ngIf="showProposal">
    <div class="popup2 col-md-8 col-xs-12 col-sm-8 col-11">
      <div class="modal-header tooltip_">
        <h4>Proposal</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="showProposal = false;">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body p-2 max-height90">
        <div class="row">
          <div class="col-md-12">
            <pdf-viewer  [src]="paymentObject?.base64" [render-text]="true" [autoresize]="false" [original-size]='true' [fit-to-page]='true' [show-borders]="true" style="width: 100%; height: 80vh;"></pdf-viewer>
            <embed src="{{paymentObject?.base64}}" style="width: 100%; height: 80vh;"> 
        </div>
          <div class="col-md-12 mt-2 text-end">
            <button type="button" class="btn btn-primary mt-2"
            (click)="showProposal = false;">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
