import { Component } from '@angular/core';

@Component({
  selector: 'app-guaranteed-return-plan',
  templateUrl: './guaranteed-return-plan.component.html',
  styleUrls: ['./guaranteed-return-plan.component.css']
})
export class GuaranteedReturnPlanComponent {

}
