<div class="modalPopup">
  <div class="modal-header">
    <h4 class="modal-title">Registration Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
      <span aria-hidden="true">×</span>
   </button>
  </div>
  <div class="modal-body">
    <form id="kt_modal_add_user_form" class="form">
      <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
        <thead>
          <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
            <th class="min-w-100px">Registration Number</th>
            <!-- <th class="min-w-100px">Engine Number</th>
            <th class="min-w-100px">Chassis Number</th> -->
            <th class="min-w-100px">Registration Date</th>
            <th class="min-w-100px">Manufacturer-Model</th>
            <th class="min-w-100px"> Fuel Type</th>
            <th class="min-w-100px"> Variant</th>
          </tr>
        </thead>

        <tbody class="text-gray-600 fw-semibold">
          <tr>
            <td class="d-flex align-items-center">
              <div class="d-flex flex-column">
                 <span>{{registrationData.rc_number}}</span> 
              </div>
            </td>
            <!-- <td>
               <span>{{engineNum}}</span> 
            </td>
            <td>
               <span>{{chasisNum}}</span> 
            </td> -->
            <td>
               <span>{{registrationData.registration_date}}</span> 
            </td>
            <td>
              <span>{{registrationData.maker_description}} - {{registrationData.maker_model}}</span> 
           </td>
           <td>
            <span>{{registrationData.fuel_type}}</span> 
         </td>
           <td>
            <span>{{registrationData.variant}}</span> 
         </td>
          </tr>
        </tbody>

      </table>
      <div class="modal-header">
        <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="confirmdetails()">
          <span class="indicator-label" >Confirm</span>
        </button>
      </div>
    </form>
  </div>
</div>
