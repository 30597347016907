import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PincodeMaster } from '../Models/signUp.component.model';
import { LookUpService } from '../Services/lookup.service';
import { UserService } from '../Services/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'manageagentcustomer',
  templateUrl: './manageagentcustomer.component.html',
  styleUrls: ['./manageagentcustomer.component.css']
})
export class ManageagentcustomerComponent {
  customerForm: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  pincodeMaster = new PincodeMaster();
  submittedCustomerForm : boolean= false;
  @ViewChild('closebtn', { static: true }) closeButtonRef: ElementRef;
  @Output() dataEvent = new EventEmitter<any>();
  constructor(private fb: FormBuilder, private lookUpService: LookUpService,
    private userservice: UserService) {
    this.customerForm = this.fb.group({
      "uniqueUserId": [''],
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],
      "phoneNumber": ['', [Validators.required]],
      "countryCode": [''],
      "userEmail": ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      "dob": ['', [Validators.required]],
      "gender": ['Male', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "pincode": ['', [Validators.required,Validators.pattern('[0-9]{6}')]],
      "pincodeId": [''],

    });

    const dateObject = new Date();
    this.customerForm.get('dob')?.setValue(dateObject.toISOString().slice(0, 10));
  }

  async submit() {
    this.submittedCustomerForm = true;
    if (this.customerForm.get('phoneNumber')?.value != null) {
      let phone = this.customerForm.get('phoneNumber')?.value;
      if (phone.dialCode) {
        this.customerForm.get('countryCode')?.setValue(phone.dialCode);
        this.customerForm.get('phoneNumber')?.setValue(phone.number.replace(phone.dialCode, '').replace(/\s/g, ""));
      }

    }
    this.customerForm.get('pincodeId')?.setValue(this.pincodeMaster?.id?.toString());
    const response = await this.userservice.ManipulateUserInfo(this.customerForm.value);
    if (response?.isSuccess) {
      if (this.customerForm.get('uniqueUserId')?.value === '') {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Customer Added',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Customer Updated',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      this.dataEvent.emit(this.customerForm.value);
    }
    this.closeButtonRef.nativeElement.click();
  }

  onCountryChangeNew(e: any) {
    this.customerForm.get('phoneNumber')?.setValue("");
  }

  async pincodeUserChangeEvent(pincode: any, isLoad?: boolean) {
    this.pincodeMaster = new PincodeMaster();
    var pin = "";
    if (isLoad)
      pin = pincode;
    else
      pin = pincode.value;
    const res = await this.lookUpService.pincodeMasterDetails(pin);
    if (res != null && res.result) {
      this.pincodeMaster = res.result[0];
      this.customerForm.get('pincodeId')?.setValue(this.pincodeMaster?.id?.toString());
    }
  }

  async EditCustomer(customer: any) {
    if(customer){
      this.customerForm.patchValue(customer);
      const dateObject = new Date(customer.dob);
      this.customerForm.get('dob')?.setValue(dateObject.toISOString().slice(0, 10));
      this.pincodeUserChangeEvent(customer.pincode, true);
    }
    else
    {
      this.customerForm.reset();
    }
  }

  onInput(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }
  
  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
         // Allow: Ctrl/cmd+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+C
        (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+X
        (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
         // let it happen, don't do anything
         return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
    }
  }  

  get customerFormControl(){
    return this.customerForm.controls;
  }

  onInputChange(event: any): void {
    const initialValue = event.target.value;
   const input = event.target as HTMLInputElement;

    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (input.value.length > 6) {
        input.value = input.value.slice(0, 6);
        event.stopPropagation();
  
      }
    if (initialValue !== event.target.value) {
      event.stopPropagation();
      
    }
  }

  passKeyPress(event:any){
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }
}
