import { Component, ElementRef, EventEmitter,Renderer2, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AutoCompleteItem } from 'src/app/Models/Item';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent {
  _items: AutoCompleteItem[] = [];
  filteredItems: AutoCompleteItem[] = [];
  @Input() userInput: string = '';
  selectedIndex: number = -1;
  visibleItems: number = 5; // Number of visible items in the list
  scrollPosition: number = 0; // Scroll position of the list
  itemHeight = 30;
  @Input() placeholder: string;
  @Input() supportingText: boolean = false;
  @Input() showError = false;
  @Input() className="";
  @Input() readonly="";
  @Input('items')
  set items(items: AutoCompleteItem[]) {
      this._items = items;
      //this.filteredItems = [...this._items];
  }
  @Output() itemSelected = new EventEmitter();
  @Output() onBlurEvent = new EventEmitter();
  @Output() onKeyUpEvent = new EventEmitter();
  constructor(private renderer: Renderer2,private el: ElementRef) {}

  // Close the autocomplete when clicking outside
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.filteredItems = [];
    }
  }

  onSelect(item: AutoCompleteItem) {
    // Handle item selection event
    console.log('Selected item:', item.value);
    this.userInput = item.text;
    this.filteredItems = [];
    this.itemSelected.emit(item);
  }

  onInput(event: any) {
    //;
    // Handle input change event and update the filtered items
    const userInput = event.target.value.toLowerCase();
    this.filteredItems = this._items.filter(item =>
      this.supportingText == true && item.text1 ? (item.text1.toLowerCase().includes(userInput) || item.text.toLowerCase().includes(userInput) ):item.text.toLowerCase().includes(userInput)
    );
  }

  onBlur(event: any) {
    this.onBlurEvent.emit(event.target.value);
  }

  onKeyUp(event: any) {
    this.onKeyUpEvent.emit(event.target.value);
  }

  onClick() {
    if(this.readonly=='')
    {
    this.filteredItems = this._items;
    }
  }

  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent): void {
  //   if (event.key === 'ArrowDown') {
  //     // Handle Arrow Down key press
  //     this.selectedIndex = (this.selectedIndex + 1) % this.filteredItems.length;
  //     event.preventDefault(); // Prevent page scrolling
  //   } else if (event.key === 'ArrowUp') {
  //     // Handle Arrow Up key press
  //     if (this.selectedIndex <= 0) {
  //       this.selectedIndex = this.filteredItems.length - 1;
  //     } else {
  //       this.selectedIndex--;
  //     }
  //     event.preventDefault(); // Prevent page scrolling
  //   } else if (event.key === 'Enter') {
  //     // Handle Enter key press to select the item
  //     if (this.selectedIndex >= 0) {
  //       this.onSelect(this.filteredItems[this.selectedIndex]);
  //     }
  //   }
  // }

  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent): void {
  //   if (event.key === 'ArrowDown') {
  //     // Handle Arrow Down key press
  //     this.selectedIndex = (this.selectedIndex + 1) % this.filteredItems.length;
  //     this.scrollPosition = this.calculateScrollPosition();
  //     event.preventDefault(); // Prevent page scrolling
  //   } else if (event.key === 'ArrowUp') {
  //     // Handle Arrow Up key press
  //     if (this.selectedIndex <= 0) {
  //       this.selectedIndex = this.filteredItems.length - 1;
  //     } else {
  //       this.selectedIndex--;
  //     }
  //     this.scrollPosition = this.calculateScrollPosition();
  //     event.preventDefault(); // Prevent page scrolling
  //   } else if (event.key === 'Enter') {
  //     // Handle Enter key press to select the item
  //     if (this.selectedIndex >= 0) {
  //       this.onSelect(this.filteredItems[this.selectedIndex]);
  //     }
  //   }
  // }

  // calculateScrollPosition(): number {
  //    // Height of each list item in pixels
  //   const maxScroll = this.filteredItems.length - this.visibleItems;
  //   let position = this.selectedIndex;

  //   // Ensure position is within bounds
  //   if (position < 0) {
  //     position = 0;
  //   } else if (position > maxScroll) {
  //     position = maxScroll;
  //   }

  //   return position * this.itemHeight;
  // }

  @ViewChild('optionsList') optionsList: ElementRef;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      // Handle Arrow Down key press
      this.selectedIndex = Math.min(this.selectedIndex + 1, this.filteredItems.length - 1);
      this.scrollDown();
      event.preventDefault(); // Prevent page scrolling
    } else if (event.key === 'ArrowUp') {
      // Handle Arrow Up key press
      this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
      this.scrollUp();
      event.preventDefault(); // Prevent page scrolling
    } else if (event.key === 'Enter') {
      // Handle Enter key press to select the item
      if (this.selectedIndex >= 0) {
        this.onSelect(this.filteredItems[this.selectedIndex]);
      }
    }
  }

  scrollDown() {
    const itemHeight = 30; // Height of each list item in pixels
    const scrollTop = this.optionsList.nativeElement.scrollTop;
    const itemOffset = this.selectedIndex * itemHeight;
    if (itemOffset >= scrollTop + this.optionsList.nativeElement.clientHeight) {
      this.renderer.setProperty(this.optionsList.nativeElement, 'scrollTop', itemOffset - this.optionsList.nativeElement.clientHeight + itemHeight);
    }
  }

  scrollUp() {
    const itemHeight = 30; // Height of each list item in pixels
    const scrollTop = this.optionsList.nativeElement.scrollTop;
    const itemOffset = this.selectedIndex * itemHeight;
    if (itemOffset < scrollTop) {
      this.renderer.setProperty(this.optionsList.nativeElement, 'scrollTop', itemOffset);
    }
  }
}
