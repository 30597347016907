import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) {
  }

  async AcquireUserInfo(data: any) {
    const url = environment.APIUrl + 'user/AcquireUserInfo';
    return this.http.post<ApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async ManipulateUserInfo(data: any) {
    const url = environment.APIUrl + 'user/ManipulateUserInfo';
    return this.http.post<ApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async ManageFamilyMemberDetails(data: any) {
    const url = environment.APIUrl + 'profile/ManageFamilyMemberDetails';
    return this.http.post<ApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetAgentCustomerList() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetAgentCustomerList`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }
}
