import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,ActivatedRoute } from '@angular/router';
import { PolicyService } from 'src/app/Services/policy.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from 'src/app/Services/general.service';


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  isUserLogged: boolean = true;
  transactionNumber:string ="";
  premium:string="";
  qId:string="";
  pn:string="";
  isSelectPolicy:boolean=false;
  policyDetails:any;
  policyStatus:string =""; 
constructor(private router: Router,private route: ActivatedRoute, private policyService : PolicyService,private spinner: NgxSpinnerService,private generalService : GeneralService){

}

  ngOnInit() {
    this.transactionNumber="";
    this.premium ="";
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
    }
    console.log(this.isUserLogged,"logged in user test policy");

    this.route.queryParamMap.subscribe((params:any) => {
      this.transactionNumber = params?.params['transactionNumber'];
        this.premium = params?.params['premium'];
        this.qId = params?.params['q'];
        this.pn = params?.params['pn'];
    });
  }

  async proceed(){
    this.router.navigate(['/policyDetails']);
  }

  checkPartner():boolean{
    return this.generalService.getUserLabel() == "NL" ? true : false;
  }

  async showPolicyDetails() {
    this.spinner.show();
    this.isSelectPolicy = true;
    const response = await this.policyService.GetPolicyById1('','',this.qId);
    if (response?.isSuccess) {
      this.policyDetails = response.result;
      this.policyStatus = this.setStatus(this.policyDetails.policyValue.policyStatus, this.policyDetails.policyValue.EndDate)
    }
    this.spinner.hide();
  }

  async onClick() {  
    if(this.policyDetails.insuranceCompany == 2 || this.policyDetails.insuranceCompany == 14 || this.policyDetails.insuranceCompany == 28)  
    await this.showPdf();
  else if(this.policyDetails.insuranceCompany == 1)
   await  this.showGoDigitPdf();
  }

  async showPdf() {
    this.spinner.show();
    const response = await this.policyService.GeneratePolicyPDF(this.policyDetails.policyNumber,this.policyDetails.insuranceCompany);
    if (response?.isSuccess) {
      
      const linkSource =response.result;
      const downloadLink = document.createElement('a');
      const fileName = 'policy.pdf';
  
      downloadLink.href = 'data:application/pdf;base64,' +linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    this.spinner.hide();
  }

  async showGoDigitPdf() {

    this.spinner.show();
    let request = { ApplicationId: this.policyDetails.applicationId, InsuranceCompany: this.policyDetails.insuranceCompany.toString() }
    const response = await this.policyService.GetPolicyReceipt(request);
    if (response?.isSuccess) {
      const linkSource =response.result;
      const downloadLink = document.createElement('a');
      const fileName = 'policy.pdf';
  
      downloadLink.href = 'data:application/pdf;base64,' +linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

    }
    this.spinner.hide();
  }

  setStatus(status:number, endDate:any ){
    if(status === 1 && moment(endDate)< moment(new Date())){
      return 'Expired';
    }
    else if (status === 1 && moment(endDate)>=moment(new Date()))
    {
      return 'Active';
    }
    else
    {
      return 'In Complete';
    }
  }
}
