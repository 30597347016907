<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1>TWO WHEELER INSURANCE</h1>
            </div>
            <!-- <div class="top-line"></div> -->
            <div class="row">
                <div class="title-line"><h4>What is Two wheeler Insurance ?</h4></div>
                <div class="mt-5"><p class="font-body">Two wheeler Insurance or bike Insurance is an insurance policy which provides financial protection to
                    your two wheeler / bike against any damages that may be caused by accidents, natural disasters,
                    theft or any serious incidents. The Motor Vehicles Act, 1988, introduced the concept of two-wheeler
                    insurance in India. The Insurance Regulatory Development Authority of India (IRDAI) regulates all
                    motor insurance products in India.</p></div>
                <div class=""><p class="font-body">To run your vehicles on roads in india ,you musthave valid insurance ,without a valid insurance
                    policy are liable to attract heavy penalties. Hence, every vehicle owner must hold a mandatory
                    third-party liability cover, the minimum coverage offered on car insurance, including two-wheeler
                    insurance.</p></div>
                <div class=""><p class="font-body">The Two wheeler insurance cove provides protection to all types of two wheelers like Motorcycle
                    ,scooty ,scooter moped etc &nbsp;</p></div>
                <div class=""><p class="font-body">It is necessary to have an insurance policy. It provides financial protection against unfortunate
                    accident that may damage the insured vehicle, third party or rider etc. With a two wheeler insurance
                    policy:&nbsp;</p></div>
                <div class=""><p class="font-body">Legal protection : In india is it mandatory to have an valid insurance policy to ride on Indian
                    roads.&nbsp;</p></div>
                <div class=""><p class="font-body">Personal accident cover : The insurance company covers the insured up to the sum of Rs.15 lakh, which
                    is payable if the policyholder suffers total permanent disability or dies an accidental death.</p></div>
                <div class=""><p class="font-body">Peace of mind &nbsp;: Since you know you have financial protection against Two wheeler accidental
                    damage repair costs, you certainly have peace of mind.</p></div>
                <div class=""><p class="font-body">Roadside assistance : At the time of policy purchase ,you can opt for road side assistance that will
                    come to your ais in case you need assistance on the road. This includes services such as towing
                    ,minor repair ,flat tyre etc &nbsp;</p></div>
                <div class=""><p class="font-body">Financial Coverage: The condition of the roads in India is not very good and also with that there has
                    been an increase in rash driving cases. An accident can cause a lot of damage to the vehicle and
                    loss of life as well. Loss of money is almost sure in these cases and thus the insurance firm pays
                    for the repair of the vehicle and also covers the medical aid to the injured person. Thus, you can
                    have peace of mind as any any damage to the vehicle will not cost you and not cause any financial
                    trouble if you have a Two wheeler insurance.</p></div>
                <div class=""><p class="font-body">Third Party Cover: A third party two wheeler cover protects the person from any kind of legal
                    obligation that may arise due to the accident towards third party. It Covers damage to the third
                    party &nbsp;property or &nbsp;Death or bodily injury to a third party by the insured vehicle.
                    hird-party cover is mandated by the law which means the Two wheeler insurance policy of the insured
                    person will cover for any damage done to another vehicle and the person (Third-Party).&nbsp;</p></div>
            </div>
            <div class="row">

                <div class="title-line"><h4>Key features of two wheelers insurance policy</h4></div>
                <div class="mt-5"><p class="font-body">Two wheeler insurers nowadays have come up with a range of features to woo customers and ensure they
                    continue with them year after year. Today, buying a Two wheelers insurance online over the internet
                    is a hassle-free and quick easy process. let us take a look at the following key features of two
                    wheeler insurance plans:</p></div>
                <div class=""><p class="font-body">Financial Coverage: A two-wheeler insurance policy provides policyholders financial coverage against
                    theft or damage incurred to or by your insured two-wheeler.</p></div>
                <div class=""><p class="font-body">Compulsory Personal Accident Cover of Rs. 15 Lakh: Two wheeler owners now can avail personal accident
                    cover of Rs. 15 lakh under their two wheeler insurance policy as an inbuilt feature. IRDA has made
                    it mandatory.</p></div>
                <div class=""><p class="font-body">Easy Transfer of No Claim Bonus (NCB): The NCB discount can be easily transferred if you buy a new
                    two wheeler vehicle. The NCB is given to the rider/driver/owner and not to the vehicle. The NCB
                    rewards a person for safe driving practices and for not making any claims in the earlier year(s).
                </p></div>
                <div class=""><p class="font-body">Discounts: IRDA approved insurers provide several discounts, such as a discount for having membership
                    of a recognized automotive association, discount for vehicles that have approved anti-theft devices,
                    etc. Owners with an unblemished record also receive concessions via NCB.</p></div>
                <div class=""><p class="font-body">Optional Coverage: The additional coverage is offered at an extra cost but goes a long way to ease
                    the process of filing claims by providing extra cover. It includes personal accident cover for
                    pillion riders, enhanced cover for spare parts &amp; accessories, zero depreciation cover and so on.
                </p></div>
                <div class=""><p class="font-body">Quick Registration for Internet Purchase: Insurers offer online policy purchase or policy renewal
                    through their websites and sometimes even through mobile apps. This makes it easy for the
                    policyholder to get their needs fulfilled. Since all prior policy claim or additional details are
                    already in the database, the process is quick and highly convenient for the customer.</p></div>
                <div class="row">
                    <div class="title-line"><h4>Why to buy Two wheeler Insurance Online at www.Click4bima.com&nbsp;</h4></div>
                    <div class="mt-5"><p class="font-body"><b>1 Hassle-free Renewal</b></p></div>
                    <div class=""><p class="font-body">You can renew your Two wheeler insurance policy online without any inspection or extra charges.
                        Enter your previous policy details and renew your plan instantly. An inspection may be required
                        for a break-in renewal.</p></div>
                    <div class=""><p class="font-body">It is important for any policyholder to compare Two wheelers insurance plans before going for
                        Two-wheeler insurance renewal or buying a new policy. Compare various Two wheelers insurance
                        plans online on Click4bima and buy the one that fulfils your expectations. Following are the
                        important facts which you can consider before buying insurance online at
                        www.Click4bima.com&nbsp;</p></div>
                    <div class=""><p class="font-body"><b>2 Instant support&nbsp;</b></p></div>
                    <div class=""><p class="font-body">Our experienced team will provide all time online support to you</p></div>

                    <div class=""><p class="font-body"><b>3 Instant and easy online car insurance renewal process</b></p></div>
                    <div class=""><p class="font-body">Our simplified processes help you get two wheeler insurance policy instantly.&nbsp;</p></div>


                    <div class=""><p class="font-body"><b>4 Get the best Two wheelers insurance policy from top &nbsp;insurers</b></p></div>
                    <div class=""><p class="font-body">By taking policy from Click4bima, customer will have multiple top insurers so that he/she can
                        take policy according to his/her wishes</p></div>
                    <div class=""><p class="font-body"><b>5 A wide range of Add-on covers for improved protection</b></p></div>
                    <div class=""><p class="font-body">Customer will have options to choose Add-on covers according to his/her premium budget &nbsp;</p></div>
                    <div class=""><p class="font-body"><b>6 No inspection or Documentation</b></p></div>
                    <div class=""><p class="font-body">You can get your policy renewal without any inspection and documentation&nbsp;</p></div>



                    



                </div>
                <div class="row">
                    <div class="title-line"><h4>Types of Two wheeler insurance plans in India&nbsp;</h4></div>

                    <div class="mt-5"><p class="font-body">In India Generally Two wheeler Insurance policy is available in Two types</p></div>










                </div>
                <div class="row">
                    <div class="title-line"><h4>Comprehensive Two wheeler Insurance</h4></div>
                    <div class="mt-5"><p class="font-body">It provides complete financial protection against &lsquo;own damage&rsquo; and any third-party
                        loss or damages caused by the insured vehicle. You don&apos;t have to worry if your two-wheeler
                        gets stolen, as a comprehensive policy also covers Two wheeler theft. This also include natural
                        calamities, and even fire. However this is not mandated by law Thus, this is a more detailed
                        policy which apart from covering for the third person also covers for &nbsp;damages to your
                        vehicle &nbsp;and thus could be helpful in the long run. Know More</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>What is covered under Comprehensive Two wheeler Insurance</h4></div>
                    <div class="mt-5"><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Covers accidental damage to your Two wheeler or third party</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Coverage against any theft, loss, and damage</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Any damages caused by natural calamities&nbsp;</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>Benefits of Comprehensive Two wheeler Insurance</h4></div>
                    <div class="mt-5"><p class="font-body">A comprehensive Two wheeler insurance policy is way more effective than a third-party liability
                        policy. Following are the benefits of a comprehensive Two wheeler insurance plan:</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Personal accident cover</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Offers protection for costs incurred due to damages</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Coverage against third-party liability</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Coverage against rider&rsquo;s injury</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;All the benefits of Third-Party Insurance i.e. damage to a
                        third-party&rsquo;s property or vehicle and also any personal damages to a third-party like
                        hospitalization, death or disability</p></div>
                    <div class=""><p class="font-body">Add-on Covers with Comprehensive Two wheeler Insurance</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>Engine &amp; Gear Protection cover</h4></div>
                    <div class="mt-5"><p class="font-body">If an accident causes damage to an engine, it is covered under a standard package policy. But if
                        it is consequential damage, it is not covered. Here, this add-on comes to your rescue, covering
                        the repair charges.</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>Return to Invoice cover</h4></div>
                    <div class="mt-5"><p class="font-body">If you fall into a situation where your Two wheeler is stolen or damages beyond repair, this
                        add-on comes in handy. With return to invoice add-on, we will cover the costs of getting you the
                        same, or similar Two wheeler- including the road tax and registration fees for the same</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>Zero Depreciation cover</h4></div>
                    <div class="mt-5"><p class="font-body">With time, the value of assets like your Two wheeler reduces. That&rsquo;s why, whenever you make
                        a claim, depreciation is charged for. However, through this add-on, i.e Zero Depreciation Cover
                        you can avoid your Two wheeler&rsquo;s depreciation and instead get complete costs (without the
                        depreciation charge) during claims and repairs.</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>Consumable cover</h4></div>
                    <div class="mt-5"><p class="font-body">In this add-on, the cost of replacement of parts like screws, engine oils, nuts &amp; bolts,
                        grease is covered in the standard package policy.</p></div>

                </div>
                <div class="row">
                    <div class="title-line"><h4>Breakdown Assistance cover</h4></div>
                    <div class="mt-5"><p class="font-body">The roadside assistance add-on ensures we&rsquo;ll always be there for you and your two-wheeler
                        in case of any breakdowns.</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>What&apos;s not covered in Comprehensive Two wheeler Insurance?</h4></div>

                    <div class="mt-5"><p class="font-body">Generally what is not covered differs from one insurance company to another but to list some, we
                        give
                        you some conditions under which the damage to your Two wheeler will not be covered in your
                        comprehensive insurance policy:</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Damage is done by a person driving under the influence of drugs or alcohol
                    </p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Damage is done by a person driving without a valid license</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Ageing, wear and tear, mechanical or electrical breakdown and failure</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Damages to tyres and tubes unless damaged during an accident</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Loss or damage to accessories by burglary housebreaking or robbery unless the
                        Two
                        wheeler is stolen at the same time</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Any claim arising out of any contractual liability</p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Usage of your vehicle other than in accordance with restrictions as to use.
                    </p></div>
                    <div class=""><p class="font-body">⦁&nbsp; &nbsp;&nbsp;Damage caused if your Two wheeler is ridden outside the geographical areas of
                        India</p></div>
                    <div class=""><p class="font-body">We suggest you go for a comprehensive Two wheeler insurance policy as opposed to third-party Two
                        wheeler insurance. The former covers expenses of all damages suffered by the insured vehicle,
                        its
                        owner, and affected third-party, while the latter offers only minimal protection.&nbsp;</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>Third Party Two wheeler Insurance&nbsp;</h4></div>
                    <div class="mt-5"><p class="font-body">A third party two wheeler cover is something that as the name suggests. It protects the person
                        from
                        any kind of legal obligation that may arise due to the accident towards third party. It Covers
                        damage to the third party &nbsp;property or &nbsp;Death or bodily injury to a third party by the
                        insured vehicle. Indian Motor Vehicle Act, 1988 mandates anyone who owns a two wheeler, be it
                        motorcycle or scooter, to have valid third party Two wheeler insurance if plying on public roads
                        in
                        the country. Non compliance with rules will makes you liable to pay huge penalties.Know More</p></div>
                </div>
                <div class="row">
                    <div class="title-line"><h4>What&rsquo;s Covered in Third Party Two wheeler Insurance?</h4></div>
                    <div class="mt-5"><p class="font-body"><b>Personal Damage to Owner Driver</b></p></div>
                    <div class=""><p class="font-body">If you don&rsquo;t already have a Personal Accident Cover, this cover can be included in your Third-Party Two wheeler Insurance to protect you from losses that could arise if you face any physical injuries or death/permanent disability.</p></div>
                    <div class=""><p class="font-body">&nbsp;<b>Property Damage Compensation</b></p></div>
                    <div class=""><p class="font-body">In case damage occurs to someone&rsquo;s vehicle, house or any property, the owner is covered for the loss.</p></div>
                    <div class=""><p class="font-body"><b>Personal Damage to Third Party</b></p></div>
                    <div class=""><p class="font-body">If a person is injured in an accident,all his or her medical costs till recovery is taken care of by Third Party Two wheeler insurance. In an unfortunate case of loss of life, a compensation amount is  given too.</p></div>
                    <div class=""><p class="font-body"> <b>What&rsquo;s not Covered in Third Party Two wheeler Insurance? </b></p></div>

                    <div class=""><p class="font-body">It is equally important to know what&rsquo;s not covered in your Third-party Two wheeler
                        insurance
                        policy so that there are no surprises when you make a claim. Here are some such situations which
                        are
                        not covered under Third Party Car Insurance:</p></div>
                    <div class=""><p class="font-body"><b>Own Damages</b> : In the case of a Third party Two wheeler insurance policy, damages to own vehicle
                        won&rsquo;t be covered.&nbsp;</p></div>
                    <div class=""><p class="font-body"><b>Drunk Riding or without a Licence</b> : Your Two wheeler insurance won&rsquo;t cover for you in
                        situations where you were riding drunk or without a valid two-wheeler licence.</p></div>
                    <div class=""><p class="font-body"><b>Driving without a valid Driving Licence holder </b>: If you hold a learner&rsquo;s licence and were
                        riding your two-wheeler without a valid licence-holder on the pillion seat- then your claim in
                        those
                        situations won&rsquo;t be covered.</p></div>
                    <div class=""><p class="font-body"><b>Outside geographical locations </b>: it Does not cover accident arise outside geographical location.
                    </p></div>
                    <div class=""><p class="font-body"><b>Any contractual liabilities </b>: Claim arising out of any contractual liability does not cover under
                        Third Party Two wheeler insurance</p></div>

                    <div class=""><h4>Advantages of having third party Two wheeler Insurance</h4></div>

                    <div class=""><p class="font-body"><b>Saves Money</b></p></div>
                    <div class=""><p class="font-body">Safeguards you from traffic violation penalties as the new fine chargeable on riding without at
                        least
                        a third-party insurance is at least Rs 2,000.</p></div>
                    <div class=""><p class="font-body"><b>Protects you from Unexpected Losses</b></p></div>
                    <div class=""><p class="font-body">Protects you from losses and liabilities that could arise if you hurt a person, property or
                        vehicle
                        while riding your two-wheeler.</p></div>
                    <div class=""><p class="font-body"><b>Covers for Personal Damages</b></p></div>
                    <div class=""><p class="font-body">Protects you in the unfortunate case where you get yourself hurt while riding your two-wheeler
                    </p></div>
                </div>
                <div class="row">
                <div class="title-line"><h4>Comply with the law</h4></div>
                <div class="mt-5"><p class="font-body">Allows you to comply with the law, and ride legally on all Indian roads.</p></div>
                <div class=""><p class="font-body"><b>Peace of Mind</b></p></div>
                <div class=""><p class="font-body">Reduces your mental stress, as you&rsquo;re assured that in the case of any mishaps you may face,
                    you&rsquo;ll be covered. &nbsp;</p></div>

                <div class=""><p class="font-body">Disadvantages of a Third Party Two wheeler Insurance</p></div>
                <div class=""><p class="font-body"><b>Doesn&rsquo;t Cover Own Damages:</b></p></div>
                <div class=""><p class="font-body">It doesn&rsquo;t cover for the losses caused in case you end up accidentally damaging your own
                    two-wheeler.</p></div>

                <div class=""><p class="font-body"><b>Doesn&rsquo;t Cover for Natural Calamities</b></p></div>
                <div class=""><p class="font-body">It doesn&rsquo;t cover for you in the case of natural calamities such as floods, fires, cyclones and
                    other nature furies that may cause damages and losses to your two-wheeler. &nbsp;</p></div>
                
                </div>
<div class="row">
                <div class="title-line"><h4>No Customized Plans</h4></div>
                <div class="mt-5"><p class="font-body">You can&rsquo;t customize your third party two-wheeler insurance policy with additional features such
                    as add-ons and covers like the zero-depreciation cover, engine and gearbox protection. You can only
                    do when you opt for a comprehensive two-wheeler insurance.&nbsp;</p></div>


                <div class=""><p class="font-body">Comprehensive Two wheeler Insurance vs Third Party Two wheeler Insurance</p></div>


                <div class=""><p class="font-body">THIRD PARTY LIABILITY&nbsp; &nbsp;&nbsp;COMPREHENSIVE COVERAGE</p></div>
                <div class=""><p class="font-body">Covers only third party liability&nbsp; &nbsp;&nbsp;Covers damage to own vehicle apart from third
                    party liability</p></div>
                <div class=""><p class="font-body">Low premium&nbsp; &nbsp;&nbsp;Relatively higher premium</p></div>
                <div class=""><p class="font-body">Mandatory by law&nbsp; &nbsp;&nbsp;Not mandatory by law</p></div>
                <div class=""><p class="font-body">Covers damage only arising out of vehicular collision&nbsp; &nbsp;&nbsp;Covers damages caused due to
                    theft and natural calamities apart from vehicular collision</p></div>
</div>
<div class="row">
                <div class="title-line"><h4>Benefits of having two wheeler insurance&nbsp;</h4></div>
                <div class="mt-5"><p class="font-body">Protect your Pocket from Dents</p></div>
                <div class=""><p class="font-body">Insuring your Two wheeler with a two-wheeler insurance policy ensures your pocket doesn&rsquo;t face
                    any dents due to unfortunate losses and damages in the likelihood of an accident, natural calamity,
                    fire or theft.&nbsp;</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Be covered, legally!</h4></div>
                <div class="mt-5"><p class="font-body">According to the Motor Vehicle Act, it is mandatory to at least have a Third-Party Two wheeler
                    Insurance. Without it, you can&rsquo;t ride legally on Indian roads! Therefore, one of the benefits
                    of buying a Two wheeler insurance policy is that of being covered legally.</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Stay Clear of Traffic Penalties</h4></div>
                <div class="mt-5"><p class="font-body">Since it&rsquo;s primarily illegal to ride in India without at least a basic, Third-Party Two wheeler
                    Insurance; not having one can lead to heavy traffic penalties. Believe it or not, you save more on
                    buying insurance for your Two wheeler than getting caught even just once for not having one!</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Get Extensive Coverage with Add-ons</h4></div>
                <div class="mt-5"><p class="font-body">When you opt for a comprehensive Two wheeler insurance policy, you have the benefit of customizing it
                    with useful add-ons like a return to invoice cover, zero depreciation cover, breakdown assistance,
                    consumables cover and tyre protection amongst others that will give your Two wheeler complete
                    protection, against all the odds!</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Avoid Third-Party Problems</h4></div>
                <div class="mt-5"><p class="font-body">One of the problems people dread when they get into any kind of accident, is the countless banter
                    faced between third-parties due to damages or losses caused. Having a Two wheeler insurance policy
                    in place ensures the affected party will be covered and hence, lesser the problems faced!&nbsp;</p></div>


                <div class=""><p class="font-body">Are you worried about how to choose the right Two wheeler Insurance?</p></div>
                <div class=""><p class="font-body">Well, we are here to solve your query. Consider following points before going to buy Two wheeler
                    insurance policy that will help you to choose best insurance plan according to your wishes :</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Customize your IDV</h4></div>
                <div class="mt-5"><p class="font-body">An IDV is the market value of your Two wheeler, including the charge of its depreciation and the
                    value of the same will directly affect your Two wheeler insurance premium. Moreover, this also
                    affects the compensation value you&rsquo;ll receive during claims. So, make sure you always check if
                    your IDV is stated correctly.</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Compare Two wheeler Insurance Quotes</h4></div>
                <div class="mt-5"><p class="font-body">The biggest advantage you have of buying a Two wheeler insurance online is, that you can compare
                    different Two wheeler insurance quotes. You can either do this on online insurance aggregators or by
                    visiting different insurance provider websites. Make sure you compare important aspects such as your
                    IDV, add-ons available, service benefits, reliability and of course, claim settlement ratios and
                    processes! &nbsp;</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Service Benefits</h4></div>
                <div class="mt-5"><p class="font-body">A good Two wheeler insurance isn&rsquo;t only about coverages and claims (although yes, that&rsquo;s
                    a huge part of it!) it&rsquo;s also about the kind of service benefits you can avail through your
                    respective service provider.</p></div>
                    </div>
                    <div class="row">
                <div class="title-line"><h4>Know your Coverage</h4></div>
                <div class="mt-5"><p class="font-body">A good Two wheeler insurance should be able to cover you for the different things that you may need.
                    That&rsquo;s what the premium is for! Therefore, while choosing the right Two wheeler insurance,
                    ensure you look at the coverage you receive and then decide if what you&rsquo;re paying for is worth
                    it or not.&nbsp;</p></div>
</div>
<div class="row">
                <div class="title-line"><h4>Two wheeler Insurance Terminologies you need to know</h4></div>

                <div class="mt-5"><p class="font-body">Insured Declared Value (IDV)</p></div>
                <div class=""><p class="font-body">IDV is the maximum amount your insurance provider can give you, in case your Two wheeler is stolen or
                    totally damaged.</p></div>
                <div class=""><p class="font-body">This means, the higher your IDV is, the higher your Two wheeler insurance premium &ndash; and as your
                    vehicle ages and IDV depreciates, your premium also decreases.</p></div>
                <div class=""><p class="font-body">Also, when you decide to sell your Two wheeler, a higher IDV means you&rsquo;ll get a higher price
                    for it. Price may also be affected by other factors like usage, past Two wheeler insurance claims
                    experience etc.</p></div>
                <div class=""><p class="font-body">So, when you&rsquo;re choosing the right two wheeler insurance policy for your Two wheeler, remember
                    to make note of the IDV being offered, and not just the premium.</p></div>
                <div class=""><p class="font-body">A company offering a low premium may be tempting, but this could be because the IDV on offer is low.
                    In case of total loss of your Two wheeler, a higher IDV leads to higher compensations.</p></div>

</div>
<div class="row">
                <div class="title-line"><h4>No Claim Bonus (NCB)</h4></div>
                <div class="mt-5"><p class="font-body">NCB is a discount on premium given to the policyholder for having a claim-free policy term.</p></div>
                <div class=""><p class="font-body">A no claims bonus ranges from a discount of 20-50% and is something you earn at the end of your
                    policy period by maintaining a record of making no accident claims under your Two wheeler insurance
                    policy.</p></div>
                <div class=""><p class="font-body">This means that you can&rsquo;t get a no claims bonus when you buy your first comprehensive Two
                    wheeler insurance policy &ndash; you can only get it on your policy renewal.</p></div>
                <div class=""><p class="font-body">A No Claim Bonus is meant for the Two wheeler insurance policyholder regardless of the Two wheeler.
                    This means, even if you switch your Two wheeler, your NCB stays with you.</p></div>
                <div class=""><p class="font-body">If you decide to buy a new Two wheeler, you will be issued a new Two wheeler insurance policy, but
                    you can still avail of the NCB you accumulated on the old Two wheeler or policy.</p></div>
</div>
<div class="row">
                <div class="title-line"><h4>Zero Depreciation</h4></div>
                <div class="mt-5"><p class="font-body">There is a decrease in value of certain parts of your two wheeler So, when a damage happens, the full
                    cost of replacement is not given as depreciation is deducted from the claim money.&nbsp;</p></div>
                <div class=""><p class="font-body">But this addon makes sure there is Zero Depreciation and you get the full value of the cost of
                    repair/replacement.</p></div>
                
                <div class=""><p class="font-body" style="color: #000;"><b>Components of a Two wheeler Insurance Premium</b></p></div>
                <div class=""><p class="font-body"><b>Own Damages Cover</b> - Included in Standard/Comprehensive and standalone Own Damage policies, this part
                    of your Two wheeler insurance would cover for losses and damages caused to your own Two
                    wheeler.&nbsp;</p></div>

                <div class=""><p class="font-body"><b>Two wheeler&rsquo;s IDV </b>- Your Two wheeler&rsquo;s IDV. The current market value of your Two wheeler
                    helps directly determine your Two wheeler&rsquo;s premium.&nbsp;</p></div>

                <div class=""><p class="font-body"><b>Add-Ons</b> - Your Two wheeler insurance premium will be affected basis the type and number of add-ons
                    chosen.&nbsp;</p></div>

                <div class=""><p class="font-body"><b>Third-Party Liabilities </b>- It is mandatory by law to at least cover for third-party liabilities in a
                    Two wheeler insurance and the premium based on it is usually determines by the IRDAI guidelines.
                    Therefore, this component of your Two wheeler insurance will always remain the same.</p></div>

                <div class=""><p class="font-body"><b>Two wheeler Make &amp; Model </b>- Your Two wheeler&rsquo;s make and model directly affects the IDV, cc,
                    and the risks associated with your Two wheeler. Therefore, it has a huge impact on your Two wheeler
                    insurance premium for any plan you pick.&nbsp;</p></div>

                <div class=""><p class="font-body"><b>Age of Two wheeler </b>- The older your Two wheeler is the lesser will be its premium and vice
                    versa!&nbsp;</p></div>

                <div class=""><p class="font-body" style="color: #000;"><b>What&rsquo;s Covered in Two wheeler Insurance</b></p></div>
                <div class=""><p class="font-body">Losses &amp; Damages Suffered Due to Natural Calamities Any loss or damage caused to the insured
                    vehicle due to natural calamities, such as lightning, earthquake, flood, hurricane, cyclone,
                    typhoon, storm, tempest, inundation, hailstorm, and landslide and rockslides among others will be
                    covered.</p></div>

                <div class=""><p class="font-body"><b>Accidents </b>: Damages and losses that may arise due to an accident or collision&nbsp;</p></div>

                <div class=""><p class="font-body"><b>bTheft </b> Covers for your losses in case your two-wheeler is unfortunately stolen!</p></div>

                <div class=""><p class="font-body"><b>Fire </b>: Damages and losses to your two-wheeler in case of an accidental fire!</p></div>

                <div class=""><p class="font-body"><b>Natural Disasters</b> : Damages caused to your two-wheeler due to nature&rsquo;s many furies, such as due
                    to floods, cyclones, etc.</p></div>

                <div class=""><p class="font-body"><b>Personal Accident</b> : Covers for your expenses in cases where you&rsquo;ve hurt yourself too bad!</p></div>

                <div class=""><p class="font-body"><b>Third Party Losses</b> : When a person, a vehicle or a property is hurt or damaged due to your Two
                    wheeler&apos;s actions.</p></div>
</div>
<div class="row">
                <div class="title-line"><h4>Some Add On Covers for Two wheeler Insurance Policy</h4></div>
                <div class="mt-5"><p class="font-body">It refers to the additional covers that enhance the coverage of your two wheeler policy. However
                    insurance companies charge extra premium to avail benefit of add-on covers .Following are the
                    various add-on covers that you can opt for your Two wheeler:&nbsp;</p></div>
                <div class=""><p class="font-body">Zero Depreciation Cover. Zero depreciation cover any deduction on the account of depreciation at the
                    time of claim settlement and the full amount will be paid to you.</p></div></div>
                <div class="row"><div class="title-line"><h4>No Claim Bonus</h4></div>
                <div class="mt-5"><p class="font-body">The NCB is given to the rider/driver/owner and not to the vehicle. The NCB rewards a person for safe
                    driving practices and for not making any claims in the earlier year(s). NCB Protect allows you to
                    retain your NCB and get a discount during renewals even if you make any claim during your policy
                    tenure.</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Return to Invoice</h4></div>
                <div class="mt-5"><p class="font-body">At the time of total loss, your insurer will pay the Insured Declared Value (IDV) of your Two
                    wheeler. Return to invoice cover bridges the gap between the IDV and the invoice/ on-road price of
                    your vehicle, including registration and taxes, allowing to get the purchase value as the claim
                    amount.</p></div></div>
                    <div class="row">
                <div class="title-line"><h4>Emergency Assistance Cover&nbsp;</h4></div>
                <div class="mt-5"><p class="font-body">This cover enables you to avail emergency roadside assistance from your insurer. Most insurers offer
                    a range of services under this cover including tyre changes, minor repairs on-site, battery
                    jump-start, towing charges, lost key assistance, replacement key and fuel arrangement amongst
                    others.</p></div>
                </div>
                <div class="row">
                <div class="title-line"><h4>Engine &amp; Gear-box Protection Cover</h4></div>
                <div class="mt-5"><p class="font-body">Do you know that the cost of replacing your engine is approximately 40% of its cost? In a standard
                    two-wheeler insurance policy, only damages caused during the accident are covered. However, with
                    this add-on, you can specifically also cover for the life of your vehicle (the engine and gearbox!)
                    for any consequential damages caused post the accident. This could happen due to water regression,
                    leakage of lubricating oil, and undercarriage damages.</p></div></div>
             <div class="row">   <div class="title-line"><h4>EMI Protection</h4></div>
                <div class="mt-5"><p class="font-body">As part of the EMI protection cover, your insurer will pay the EMIs of your insured vehicle if it is
                    getting repaired at an approved garage following an accident.</p></div>
                <div class=""><p class="font-body">What Two wheeler insurance does not cover</p></div>
                <div class=""><p class="font-body">It is equally important to know what&rsquo;s not covered in your two-wheeler insurance policy, so
                    that there are no surprises when you make a claim. Here are some such situations:</p></div>
</div>
<div class="row">
                <div class="title-line"><h4>Own Damages for Third-Party Policy holder</h4></div>
                <div class="mt-5"><p class="font-body">In the case of a Third-Party or Liability Only Two wheeler Policy, damages to own vehicle won&rsquo;t
                    be covered.&nbsp;</p></div></div>
<div class="row">
                <div class="title-line"><h4>Drunk Riding or without a Licence</h4></div>
                <div class="mt-5"><p class="font-body">Your Two wheeler insurance won&rsquo;t cover for you in situations where you were riding drunk or
                    without a valid two-wheeler licence.</p></div></div>
<div class="row">
                <div class="title-line"><h4>Driving without a valid Driving Licence holder</h4></div>
                <div class="mt-5"><p class="font-body">If you hold a learner&rsquo;s licence and were riding your two-wheeler without a valid licence-holder
                    on the pillion seat- then your claim in those situations won&rsquo;t be covered.</p></div>
                </div>
                <div class="row"><div class="title-line"><h4>Consequential Damages</h4></div>
                <div class="mt-5"><p class="font-body">Any damage which is not a direct result of the accident (e.g. after an accident, if the damaged
                    two-wheeler is being used incorrectly and the engine gets damaged, it is considered as consequential
                    damage &nbsp;and it will not be covered)</p></div></div>
                <div class="row"><div class="title-line"><h4>Contributory Negligences</h4></div>
                <div class=""><p class="font-body">Any contributory negligence(e.g. damage due to driving a two-wheeler in a flood, which is not
                    recommended as per the manufacturer&rsquo;s driving manual, will not be covered)</p></div></div><div class="row">
                <div class="title-line"><h4>Add-ons not Bought</h4></div>
                <div class="mt-5"><p class="font-body">Some situations are covered in add-ons. If you haven&rsquo;t bought those add-ons, the corresponding
                    situations will not be covered.</p></div>
</div>
<div class="row">
                <div class="title-line"><h4>Factors Affecting Two wheeler Insurance Premium</h4></div>
                <div class="mt-5"><p class="font-body">There are Several factors determine the premium of your Two wheeler insurance policy. Following are
                    the some factors that affects your two wheeler insurance premium:</p></div>
                <div class=""><p class="font-body">Type of Insurance coverage : The type insurance coverage of your policy largely affects your premium
                    amount. You will pay a lesser amount for third party liability plan as compared to a comprehensive
                    plan that will provide wider coverage and hence, will attract a higher premium.</p></div>
                <div class=""><p class="font-body">Insured Declared Value: The Insured Declared Value (IDV) is estimated by finding the market value of
                    your vehicle. If the market value is lesser, so will be the IDV fixed by your insurer. As a result,
                    you will end up paying a lower amount of premium.</p></div>
                <div class=""><p class="font-body">Installed Security Device: If you have installed security devices to enhance the safety of your
                    vehicle, your insurer will offer you a lesser premium amount.</p></div>
                <div class=""><p class="font-body">Age of the Vehicle: The age of your two wheeler is inversely proportional to its market value or IDV
                    due to depreciation. Therefore, the higher the age of your vehicle, lesser will be the premium
                    amount that you will be required to pay.</p></div>
                <div class=""><p class="font-body">Age of the Insured: The age of the insured also determines the premium rate. Younger riders are
                    believed to have higher risk exposure as compared to middle-aged riders. Hence, more the age of the
                    insured, lesser will be the premium amount that you&apos;ll have to pay.</p></div>
                <div class=""><p class="font-body">No Claim Bonus: &nbsp;No Claim Bonus or NCB enables you to receive a discount on your premium at the
                    time of renewal if you have not made any claims. Thus, NCB reduces the premium that you are required
                    to pay.</p></div>
                <div class=""><p class="font-body">Geographic Location: The place where you will be riding your Two wheeler affects your premium as
                    certain places, such as metropolitan cities, have higher risk exposure. The premium amount will
                    increase as the level of risk exposure rises.</p></div>
                <div class=""><p class="font-body">Make &amp; Model of the Two wheeler: Basic models attract a lower level of coverage leading to lower
                    premiums. On the other hand, a high-end Two wheeler will require a wider range of coverage, thereby,
                    attracting a higher amount of premium.</p></div>
                <div class=""><p class="font-body">Deductible: If you opt for the voluntary deductible, your insurer will offer you a discount on your
                    premium reducing the overall amount to be paid.</p></div>
                <div class=""><p class="font-body">Engine Cubic Capacity (CC): Engine CC is directly proportional to your premium rates. This means
                    higher engine CC will make you pay a higher amount of premium.</p></div>
                <div class=""><p class="font-body">How to reduce your Two Wheeler Insurance Premium?</p></div>
                <div class=""><p class="font-body">There are several ways through which you can save on your two wheeler insurance premium without
                    compromising on your policy coverage. Following are the some of basic points which you shoul</p></div>
                <div class=""><p class="font-body">Voluntary Deductible : If you have had no claims for more than 4-5 years, you can look at increasing
                    your Voluntary Deductible and decreasing your two wheeler insurance insurance premium.</p></div>
                <div class=""><p class="font-body">No Claim Bonus: Apart from being safe on the road, cautious driving in speed-limits, will avoid
                    accidents and make sure you get a No Claim Bonus during your two wheeler insurance renewal each
                    year.</p></div>
                <div class=""><p class="font-body">Communicate with Insurance company Talk to your insurance company. If you feel you&rsquo;re still not
                    getting a cheap two wheeler insurance quote, it&rsquo;s not a bad idea to call the insurance company
                    you are interested in once before making the final decision.&nbsp;</p></div>
                <div class=""><p class="font-body">Install Safety Devices: You should install safety devices that can enhance the safety of your two
                    wheeler. This is because your insurer will take cognizance of your installation and will offer a
                    discount on your premium.</p></div>

            </div>
        </div>
    </div></div>
    <app-footer></app-footer>