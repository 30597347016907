<div class="dropdown">
    <button class="btn btn-block text-left dropdown-toggle form-select form-select-custom border-custom {{className}}" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
        data-display="static" [title]="selectedName" [id]="id"
        [ngClass]="{ 'border border-danger': showError }">
        <span class="font-14-11 overflow-handle">
            {{selected ? selected : (placeholder ? placeholder : 'Select')}}
        </span>
    </button>
    <div class="dropdown-menu w-auto">
        <!-- search -->
        <div *ngIf="showSearch" class="dropdown-item dropdown-item-search" onclick="event.stopPropagation();">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search">
                <!-- <div class="input-group-append">
                    <span class="input-group-text h-100">
                        <img src="/assets/icons/search.svg" alt="" title="Bootstrap">
                    </span>
                </div> -->
            </div>
        </div>

        <!-- checkboxes -->
        <div class="dropdown-item-checkboxes" [ngClass]="{'max-height':maxHeight}">
            <!-- all -->
            <div class="dropdown-item" onclick="event.stopPropagation();" *ngIf="showAll && all.visible"
                [ngClass]="{ 'checked': all.checked }">
                <!-- checkbox -->
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" [id]="'chkItem'+'_'+ id +'_' + all.id"
                        (change)='onChange($event, all)' [checked]="all.checked">
                    <label class="custom-control-label" [for]="'chkItem' +'_'+ id +'_' + all.id">
                        <span class="pl-2" [title]="all.name">{{all.name}}</span>
                    </label>
                </div>
            </div>

            <ng-container *ngFor="let item of filtered; let i = index; trackBy: trackByUuid">
                <div class="dropdown-item w-100" onclick="event.stopPropagation();" *ngIf="item.visible"
                    [ngClass]="{ 'checked': item.checked }">
                    <!-- checkbox -->
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [id]="'chkItem' +'_'+ id +'_'+ item.id"
                            (change)='onChange($event, item)' [checked]="item.checked">
                        <label class="custom-control-label" [for]="'chkItem' +'_'+ id +'_'+ item.id">
                            <span class="pl-2" [title]="item.name">{{item.name}}</span>
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- not found -->
        <div class="dropdown-item" *ngIf="isEmpty">
            No item found ??
        </div>

        <!-- status -->
        <div *ngIf="showStatus" class="dropdown-count text-dark">
            <span><b>Count:</b> {{_items.length}}</span>
            <span><b>Checked:</b> {{checked}}</span>
            <span *ngIf="search"><b>Search Count:</b> {{filtered.length}}</span>
        </div>
    </div>
</div>