<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid"><br><br>
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Individual Health Insurance Plan:</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p>The health plans are issued for one individual and cover him/her.</p>
                <p class="font-body">These plans are indemnity based health plans which pay the actual medical costs
                    incurred upon hospitalization. Medical costs incurred by the covered individual are paid by the
                    plan.</p>
            </div>
            <div class="title-line">
                <h4>Benefits of individual health insurance:</h4>
            </div>
            <p>There are many advantages of having individual health insurance policy. Take a look at some of the major
                benefits listed below:</p>
            <div class="row">
                <div class="title-line">
                    <h4>Coverage for medical expenses:</h4>
                </div>
                <p></p><div class="row mt-5">
                <p class="font-body">The most important and main reason why we buy health insurance is to stay protected against
                    unexpected medical expenses. It protects you to a financial crisis when you are facing a health
                    issue in life. It relieves you if the burden of the medical expense so that you can focus of
                    recovering well.</p>
                <p></p></div>
            </div><div class="row">
            <div class="title-line">
                <h4>Cashless Claim Service:</h4>
            </div>
            <p></p><div class=" mt-3">
            <p class="font-body">In a unforeseen event of medical emergency, or situation where you are supposed to be hospitalized, you
                can avail the cashless benefit provided in your policy. Cashless hospitalization facility offered by
                health insurance companies, under an individual health policy, allows an individual to avail medical
                treatment without paying for the same to the hospital. However, this facility is available only in the
                hospitals empanelled with the insurance companies. Under cashless hospitalization, the insurance company
                settles claim directly to the hospital / nursing home.</p>
            <p></p></div></div>
            <div class="row">
            <div class="title-line">
                <h4>Hospitalization Daily Allowance:</h4>
            </div>
            <div class="mt-3">
            <p class="font-body">Some insurance companies pay a specific allowance to the insured for each day he/she spends in the
                hospital. This, however, is applicable only after the insured has already spent some days in hospital.
                The number of days spent in hospital is always pre-decided in the policy and may vary from one company
                to other. If you are the primary earning member in your family, such an allowance proves to be very
                beneficial at the time of emergency.</p>
            <p></p></div></div>
            <div class="row">
            <div class="title-line">
                <h4>Tax Benefits:</h4>
            </div>
            <div class="mt-5">
            <p class="font-body">The premium paid on a health insurance policy is eligible for deduction under Section 80D of the Income
                Tax Act. The amount of deduction available is up to Rs 15,000 for individuals or Rs 20,000 for senior
                citizens (aged above 65 years). Additionally an amount of Rs 20,000 is also available for exemption
                towards premium paid for dependant parents.</p></div>
           <div class="mt-5"> <p class="font-body">The most important and main reason why we buy health insurance is to stay protected against unexpected
                medical expenses. It protects you to a financial crisis when you are facing a health issue in life. It
                relieves you if the burden of the medical expense so that you can focus of recovering well.</p></div>
            <p></p></div><div class="row">
            <div class="title-line">
                <h4>Cashless Claim Service:</h4>
            </div>
            <div class="mt-5">
            <p class="font-body">In a unforeseen event of medical emergency, or situation where you are supposed to be hospitalized, you
                can avail the cashless benefit provided in your policy. Cashless hospitalization facility offered by
                health insurance companies, under an individual health policy, allows an individual to avail medical
                treatment without paying for the same to the hospital. However, this facility is available only in the
                hospitals empanelled with the insurance companies. Under cashless hospitalization, the insurance company
                settles claim directly to the hospital / nursing home.</p>
            </div></div>
            <div class="row">
            <div class="title-line">
                <h4>Hospitalization Daily Allowance:</h4>
            </div>
            <div class="mt-5">
            <p class="font-body">Some insurance companies pay a specific allowance to the insured for each day he/she spends in the
                hospital. This, however, is applicable only after the insured has already spent some days in hospital.
                The number of days spent in hospital is always pre-decided in the policy and may vary from one company
                to other. If you are the primary earning member in your family, such an allowance proves to be very
                beneficial at the time of emergency.</p>
            </div></div>
            <div class="row">
            <div class="title-line">
                <h4>Tax Benefits:</h4>
            </div>
            <div class="mt-5">
            <p class="font-body">The premium paid on a health insurance policy is eligible for deduction under Section 80D of the Income
                Tax Act. The amount of deduction available is up to Rs 15,000 for individuals or Rs 20,000 for senior
                citizens (aged above 65 years). Additionally an amount of Rs 20,000 is also available for exemption
                towards premium paid for dependant parents.</p>
            </div></div>
            <div class="row">
            <div class="title-line">
                <h4>Documents Required to Buy Health Insurance Online</h4>
            </div>
            <p></p><div class="mt-5">
            <p>There are a few documents that a person should have to buy a health insurance policy online. These are-
            </p></div>
            <div class="mt-5">
            <p>Age proof: Any of the following , Birth Certificate, 10th or 12th mark sheet, Driving License, Passport,
                Voter&rsquo;s ID, etc.</p></div>
            <div class="mt-5"><p>Identity proof: Either of-Passport, Driving License, Voter ID, PAN Card, Aadhar Card, which proves
                one&rsquo;s citizenship.</p></div>
            <div class="mt-5"><p>Address proof: Either of-Electricity bill, Ration Card, Telephone Bill, Passport, Driving License with
                your permanent address clearly mentioned on it.</p></div>
            <div class="mt-5"><p>Medical check-up: Some plans require health check-up to ensure that the individual is not suffering from
                any chronic illness.</p></div>
            <div class="mt-5"><p>Health Insurance Renewal Online Process - Through the Insurer&rsquo;s Website</p></div>
 <div class="mt-5"><p>Health insurance renewal is an easy process, especially when done online through the insurer&rsquo;s
                website by following the steps given below:</p>
            <p>Step 1: Visit the official website of the insurance company.&nbsp;</p>
            <p>Step 2: Go to the health insurance renewal section available on the website.</p>
            <p>Step 3: Enter your previous health insurance policy number, registered mobile number and such other
                details as required at the insurance company Website.</p>
            <p>Step 4: Check the policy details and the policy renewal premium and conditions such as tenure, payment
                options etc.</p>
            <p>Step 5: Make the payment using your credit/debit card or Net banking.</p>
            <p></p></div>
        </div>
    </div>
</div></div>
<app-footer></app-footer>