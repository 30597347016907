<app-navbar></app-navbar>
<div class="container"><br><br>
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1>Pension Plan&nbsp;</h1>
            </div>
            <div class="top-line"></div>
            <div class="row">
            <div class="mt-3"><p class="font-body"><br>A pension plan is a retirement plan allow the individuals to transfer a part of their income towards
                their retirement benefits. The main motive of a pension plan is to get a regular income during
                post-retirement days. To ensure that you can enjoy your golden years with financial independence, these
                policies help you plan for your expenses and secure your future.</p></div>
            <div class="mt-3"><p class="font-body">Eligibility of a Pension Plan</p></div>
            <div class="mt-3"><p class="font-body">The main eligibility criteria to invest in the pension plan is mentioned below-</p></div>
            <div class="mt-3"><p class="font-body">Entry Age: The pension plans&rsquo; entry age varies from insurer to insurer. Some plans are available
                with an entry age of minimum 18 years, whereas some plans allow you to start investing at the age of 30
                years. Similarly, the maximum age varies depending on the insurer to insurer, but the most common one is
                70 years.</p></div>
            <div class="mt-3"><p class="font-body">Vesting Age: This is the age when the policyholder starts getting the pension. Generally, it is 40 years
                but that varies from plan to plan and company to company.</p></div></div>
                <div class="row">
            <div class="title-line">
                <h4>Benefits of Retirement Plans:</h4>
            </div>
            <div class="mt-3"><p class="font-body"><br>Guaranteed regular income for life: With Retirement plans, you and your spouse can receive regular
                pension for life.</p></div>
            <div class="mt-3"><p class="font-body">Tax benefits: Apart from enjoying a comfortable retirement, you can also enjoy tax benefits** on the
                premium paid up to a limit of 1.5 lakh. In addition, at the time of maturity, the pay-outs you will
                receive are also completely tax-free. However the tax benefits are subject to amendments Under Income
                Tax Act ,1961. It is advisable to policyholder to be update with relevant income tax benefits before opt
                for such Policy.&nbsp;</p></div>
            <div class="mt-3"><p class="font-body">Security for your children in your absence: In some retirement plans, your children will receive a
                lump-sum amount in the absence of both you and your spouse. This helps you leave behind a legacy for
                your children.</p></div>
            <div class="mt-3"><p class="font-body">Liquidity: Retirement plans are essentially a product of low liquidity. However, some plans allow
                withdrawal even during the accumulation stage. This will ensure funds to fall back on during emergencies
                without having to rely on bank loans or others for financial requirements.</p></div>
            <div class="mt-3"><p class="font-body">Accumulation Duration: An investor can either choose to pay the premium in periodic intervals or at once
                as a lump sum investment. The wealth will simultaneously accumulate over time to build up a sizable
                corpus (investment +gains). For instance, if you start investing at the age of 30 and continues
                investing until you turn 60, the accumulation period will be 30 years. Your pension for the chosen
                period primarily comes from this corpus.</p></div>
            <div class="mt-3"><p class="font-body">Payment Period: Investors often confuse this with the accumulation period. This is the period in which
                you receive the pension post-retirement. For example, if one receives a pension from the age of 60 years
                to 75 years, then the payment period will be 15 years. Most plans keep this separate from accumulation
                period, though some plans allow partial/full withdrawals during accumulation periods too.</p></div></div>
                <div class="row">
            <div class="title-line">
                <h4>Types of Pension Plans &nbsp;</h4>
            </div>
            <div class="mt-3"><p class="font-body"><br>one are those days when the retirement phase was considered a time to hang up the boots and just live
                out your remaining years in boredom. Most people now have big plans for their post-retirement phase.
                Plans that require substantial money. This makes it imperative for individuals to make retirement
                planning and building a corpus for their retirement years a key goal in their financial planning agenda.
                Due to product side innovation, individuals today can choose from a wide variety of retirement
                investment products.</p></div>
            <div class="mt-3"><p class="font-body">Pension plans or retirement plans are types of investment plans that can help individuals accumulate a
                part of their savings over a long period of time in such a way that these savings eventually become a
                steady flow of income for them in their retirement phase. Currently, there is a wide range of retirement
                plans that an individual can choose from basis his idiosyncratic needs.</p></div>
            <div class="mt-3"><p class="font-body">insurer sponsored plans that invest solely in debt</p></div>
            <div class="mt-3"><p class="font-body">Unit-linked plans that invest in a mix of debt and equity</p></div>
            <div class="mt-3"><p class="font-body">The National Pension Scheme, which can invest 1) 100 per cent in government securities, 2) 100 per cent
                in debt securities (other than government securities), or 3) invest a maximum of 75 per cent in equity.
            </p></div>
            <div class="mt-3"><p class="font-body">Further, these can be divided into 8 major categories of pension plans:</p></div>
            <div class="mt-3"><p class="font-body">National Pension Scheme &ndash; This scheme invests in a mix of debt and equity, based on your individual
                preference. The individual is allowed to withdraw 60 per cent of the funds at the time of retirement and
                the remaining 40 per cent is used for the purchase of the annuity. The maturity amount is tax-free.</p></div>
            <div class="mt-3"><p class="font-body">Deferred Annuity &ndash; This allows the individual to accumulate a corpus over a period of time either
                by paying a single premium or by paying regular premiums over the term of the policy. The pension only
                begins once the policy term gets over.</p></div>
            <div class="mt-3"><p class="font-body">Pension Funds - The government body, Pension Fund Regulatory and Development Authority (PFRDA), has
                authorised six companies to operate as fund managers for pension funds. These funds invest in a mix of
                debt and equity.</p></div>
            <div class="mt-3"><p class="font-body">Immediate Annuity - In this type of scheme, the pension begins as soon as the individual deposits a lump
                sum amount. The annuity amount depends on the amount the policyholder invests. The premiums paid for an
                immediate annuity pan are tax exempt under the Income Tax Act of 1961.</p></div>
            <div class="mt-3"><p class="font-body">Guaranteed Period Annuity &ndash; This scheme guarantees an annuity for a set period which could be five
                years, ten, fifteen, and twenty years, irrespective of whether the holder survives the duration of the
                plan.</p></div>
            <div class="mt-3"><p class="font-body">Pension Plans with and without cover - Pension plans that come with a life cover provide a lump sum
                payment to the family member/nominee at the death of the policyholder. The ones without-cover clearly do
                not offer a life cover while the nominee gets the corpus on the death of the policyholder.</p></div>
            <div class="mt-3"><p class="font-body">Annuity certain - In this scheme, the annuity is paid for a fixed period of time that can be chosen by
                the annuitant.</p></div>
            <div class="mt-3"><p class="font-body">Life Annuity &ndash; In this scheme, the annuity is paid to the annuitant until the time of death. In
                case one has chosen the &lsquo;with spouse&rsquo; option then the spouse will continue to receive the
                pension amount at the death of the annuitant dies.</p></div>
            <div class="mt-3"><p class="font-body">Tips to remember before buying a Pension Plan</p></div>
            <div class="mt-3"><p class="font-body">Estimate your future financial goal(s)</p></div>
            <div class="mt-3"><p class="font-body">Consider your current income and fix an amount to invest in the plan</p></div>
            <div class="mt-3"><p class="font-body">Research the available plans, read the benefits offered post maturity and choose accordingly</p></div>
            <div class="mt-3"><p class="font-body">Understand the product thoroughly and then decide on investing</p></div>
            <div class="mt-3"><p class="font-body">Do not choose a product only because of tax benefits</p></div>
            
            <div class="row">
            <div class="title-line">
                <h4>Retirement plan</h4>
            </div>
            <div class="mt-3"><p class="font-body"><br>These are the investment plans that are specially designed to let you save money, until you retire;
                to
                reap the fruits you had sown. From the day you buy a retirement plan, you contribute a certain amount to
                it on a regular basis. When your income stops on retirement, you start getting a steady income at
                regular intervals from your retirement plan. Very often, these plans also provide life insurance cover.
                Thus, along with wealth accumulation you also get life insurance cover.</p></div>
            <div class="mt-3"><p class="font-body">Planning for retirement is rather a life-long process. Even though, one can start their retirement at any
                age but it works best, when an individual includes this factor into their financial planning from the
                starting. Planning for retirement from an early stage of life is the best way to ensure a secured, safe
                and fun retirement.</p></div>
            <div class="mt-3"><p class="font-body">Let&lsquo;s take a look at the key take away of retirement planning.</p></div>
            <div class="mt-3"><p class="font-body">The early one starts planning for retirement the better fund they can accumulate over a long period of
                time in order to have a secured life after retirement.</p></div>
            <div class="mt-3"><p class="font-body">There are various popular investment options, which allow the individuals to accumulate fund with the
                advantage of tax benefit.</p></div>
            <div class="mt-3"><p class="font-body">Retirement planning can be described as the financial planning of investment, savings and final
                distribution of money in order to sustain one&rsquo;s self at the time of retirement.</p></div>
            <div class="mt-3"><p class="font-body">While planning for retirement it is important to consider factors like future liabilities, expenses and
                life expectancy along with asset and income.</p></div></div>
                <div class="row">
            <div class="title-line">
                <h4>Benefits of Retirement Plan</h4>
            </div>
            <div class="mt-3"><p class="font-body"><br> There are several benefits of retirement planning that range from both financial to personal and
                psychological. Let&rsquo;s look at seven common reasons why planning for your retirement can work for
                you.</p></div>
            <div class="mt-3"><p class="font-body">1. Peace of Mind: This is by far one of the most important benefits of retirement planning. Planning
                ahead not only reduces your stress during retirement but also in the years leading up to it. The lack of
                planning can leave a cloud of uncertainty around the topic that can create an unnecessary level of
                stress.</p></div>
            <div class="mt-3"><p class="font-body">2. Pre-Retirement Decisions: If you take the time to plan for your retirement early on, you will be able
                to make more efficient career-related and general financial decisions prior to retirement with
                appropriate planning. Is it better to stay at the current law firm or start your own practice? Will the
                mid to late career degree, license or other credential make sense monetarily? These decisions may be
                different for someone with fifteen years to retirement compared to someone with only five years until
                retirement.</p></div>
            <div class="mt-3"><p class="font-body">3. Getting on the Same Page: One of the benefits of early retirement planning is that you can make sure
                your plans work well with other relevant parties. It&rsquo;s never too early to make sure that you and
                your spouse are on the same page with spending and lifestyle desires in retirement, but your significant
                other may not be the only family member you may wish to have a conversation with.</p></div>
            <div class="mt-3"><p class="font-body">Some retirement plans are often affected by a saver&rsquo;s desire to meet other objectives such as
                assisting an adult child in starting or acquiring their business. To the extent that these goals may
                affect your own retirement savings, you will benefit from planning beforehand.</p></div>
            <div class="mt-3"><p class="font-body">4. Tax Benefits: There are several tax benefits of retirement planning, including reducing the amount of
                income taxes you will pay during retirement and ensuring that beneficiaries to retirement and other
                account types pay as little tax as possible.</p></div>
            <div class="mt-3"><p class="font-body">5. Cost Saving: There are many ways to reduce costs with appropriate planning. Many of the insurance
                policies you may need (long-term care, etc.) can be acquired at a lower premium when younger and in good
                health rather than waiting until retirement and risking a higher rate or denial of coverage.</p></div>
            <div class="mt-3"><p class="font-body">Those who know where they would like to reside geographically often wish to examine options other than
                buying at the time they retire. Would it make good financial sense to acquire the property in the
                desired retirement location in advance and rent it out until retirement? How much time do you need
                beforehand if you plan to build a new property? Early retirement planning can increase the likelihood
                that your goals are met with the least cost.</p></div>
            <div class="mt-3"><p class="font-body">6.Regular Income after Retirement: The pension scheme in India offers a guaranteed income that helps the
                policyholder to meet their day-to-day expenses. Your current income and future inflation should lay a
                foundation of your retirement planning as it will help you to compute the money you&apos;ll need
                post-retirement.</p></div>
            <div class="mt-3"><p class="font-body">Some of the insurance plans offer income that ensures that the policyholder does not have to worry about
                the future. Since these life-income plans offer better returns, it is a smart way to walk down the lane
                of retirement planning.</p></div>
            <div class="mt-3"><p class="font-body">7.Insurance Cover: The retirement planning solutions people invest in provides them with an insurance
                cover, to financially protect their family if the worst comes to pass. Most life insurance companies
                offer an insurance cover benefit under various retirement plans so that the spouse does not have to face
                any financial difficulty if the unfortunate happens.</p></div>
            <div class="mt-3"><p class="font-body">8.Savings for a Longer Term: Irrespective of the premium payment mode selected by you, which can be
                multiple small payouts or a lump sum payment, one thing that you assure with a retirement plan is
                savings for the long term. Pension scheme India mainly focuses on creating the annuity that can further
                invest in generating a steady flow of cash for your post-retirement years.</p></div>
            <div class="mt-3"><p class="font-body">Why Retirement planning is Important?</p></div></div>
            <div class="row">
            <div class="title-line"><h4>Life Goals&nbsp;</h4></div>
            <div class="mt-3"><p class="font-body"><br>Everybody has a goal in life and retirement is the best time to fulfil all your goals and transform your
                dream into reality. But for this you have to be financially sound, otherwise, the problem of fulfilling
                your basic needs like feeding or clothing etc will daunt you in your old days as opposed to where to go
                for a vacation with your family.&nbsp;</p></div></div>
                <div class="row">
                <div class="title-line"><h4>Plan for your kids</h4></div>
            <div class="mt-3"><p class="font-body"><br> Another very important reason why you need to save for retirement is for your kids. Most of the people
                have to undergo financial difficulties just because their parents had not properly planned for their
                retirement. Fulfilling their heavy medical expenses and other needs along with personal family need is
                really very difficult in today&rsquo;s era of high inflation. Hence, it is advisable to start with your
                retirement savings as early as you are 20 years old and single. The retirees pose a huge burden on their
                family who had not planned and saved for their retirement. Moreover, as a parent, it is your
                responsibility to protect and plan your children&rsquo;s career rather than causing them to face your
                own financial difficulties.</p></div></div>
                <div class="row">
                <div class="title-line"><h4>To take care of your health</h4></div>
            <div class="mt-3"><p class="font-body"><br>There&rsquo;s always a health issue associated with growing age. There may be a situation where you
                cannot work any longer and the savings for retirement will help to ensure that you are well cared of. So
                the big question is that can you afford the cost of long-term care since it can be very expensive and is
                included in the cost of your retirement. Hence do start with your savings.</p></div></div>
            <div class="row">    <div class="title-line"><h4>To avoid work pressure post-retirement</h4></div>
            <div class="mt-3"><p class="font-body"><br>Do you want to keep working after your retirement? If the answer is no, then you should start with your
                savings. The people who are unprepared for retirement often have to keep working to fulfill their
                family&rsquo;s need throughout life. It is very unlikely that you will generate income forever, hence,
                savings play an important role.</p></div>
            <div class="mt-3"><p class="font-body">Tips to remember before buying a Retirement Plan</p></div>
            <div class="mt-3"><p class="font-body">Estimate your future financial goal(s)</p></div>
            <div class="mt-3"><p class="font-body">Consider your current income and fix an amount to invest in the plan</p></div>
            <div class="mt-3"><p class="font-body">Research the available plans, read the benefits offered post maturity and choose accordingly</p></div>
            <div class="mt-3"><p class="font-body">Understand the product thoroughly and then decide on investing</p></div>
            <div class="mt-3"><p class="font-body">Do not choose a product only because of tax benefits</p></div>
            <div class="mt-3"><p class="font-body"><br></p></div>
        </div></div>
    </div>
</div>
<app-footer></app-footer>