<div class="">
  <app-navbar *ngIf="!showMenu" [fullname]="fullname" [isUserLogged]="isUserLogged" [currentTab]="currentTab" (languageChanged) = "languageChanged()"></app-navbar>
  <app-insurance (clickOnButton)="clicked($event)" style="background-color: #ffffff;"></app-insurance>
  <!-- <app-how-we-help-you></app-how-we-help-you> -->
  
  <app-insurance-banner *ngIf="!DisplayInsurance"></app-insurance-banner>
  <!-- <app-feature *ngIf="!DisplayInsurance"></app-feature> -->
  <app-what-is-insurance *ngIf="!DisplayInsurance"></app-what-is-insurance>
  <app-android-banner *ngIf="!DisplayInsurance"></app-android-banner>
  <app-testimonial *ngIf="!DisplayInsurance"></app-testimonial>
  <app-partner *ngIf="!DisplayInsurance"></app-partner>
  <app-need-help *ngIf="!DisplayInsurance"></app-need-help>
  <app-footer *ngIf="!showMenu"></app-footer>
</div>


<script>
  function changeTab(event, tabNumber) {
    var tabs = document.getElementsByClassName("nav-item");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("active");
    }
    event.target.classList.add("active");

  }
  function changeMenu(event, tabNumber) {
    var tabs = document.getElementsByClassName("selected-menu");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("active");
    }
    event.target.classList.add("active");
  }
  function changeCard(event, tabNumber) {
    var tabs = document.getElementsByClassName("selected-card");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("active");
    }
    event.target.classList.add("active");
  }
</script>