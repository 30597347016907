import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) {

  }
  async GetDocumentTypes() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/GetDocumentTypes`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }


  async GetUserDocuments(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/GetUserDocuments`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async SaveUserDocument(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}user/SaveUserDocument`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async DownloadDocument(docUniqueId:string = "", docIdEnc:string = "", url:string = "") {
    return this.http.get<ApiResponse>(`${environment.APIUrl}user/DownloadDocumentById?docUniqueId=${docUniqueId}&docIdEnc=${docIdEnc}&url=${url}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  
}
export interface DocumentDetails{
  uniqueUserId?:string;
  documentUniqueId?:string;
  documentTypeName?:string;
  documentName?:string;
  documentBase64?:string;
  documentNumber?:string;
  documentTypeId?:string;
  verified?:string;
  documentStatus?:string;
  userId?: string;
}
