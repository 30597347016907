import { Component } from '@angular/core';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent {
  constructor(private generalService : GeneralService){}
  openItemIndex = -1;
  isinvestmentnotcollapsed: boolean = true;
  isinvestmentcollapsed: boolean = false;
  isrenewnotcollapsed: boolean = true;
  isrenewcollapsed: boolean = false;
  isothernotcollapsed: boolean = true;
  isothertcollapsed: boolean = false;
  ismotornotcollapsed: boolean = true;
  ismotorcollapsed: boolean = false;
  islifenotcollapsed: boolean = true;
  islifecollapsed: boolean = false;
  isInsurance: boolean = false;
  islife:boolean = false;
  ismotor:boolean = false;
  isother:boolean = false;
  isrenew:boolean = false;
  isUserLogged:boolean = false;
  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  faqs(val:any) {
    if(val=='Investment') {
      this.isInsurance = !this.isInsurance;
      this.islife = false;
      this.ismotor = false;
      this.isother = false;
      this.isrenew = false;
      this.isinvestmentnotcollapsed = !this.isinvestmentnotcollapsed;
      this.isinvestmentcollapsed = !this.isinvestmentcollapsed;
      this.isrenewnotcollapsed = true;
      this.isrenewcollapsed = false;
      this.isothernotcollapsed = true;
      this.isothertcollapsed = false;
      this.ismotornotcollapsed = true;
      this.ismotorcollapsed = false;
      this.islifenotcollapsed = true;
      this.islifecollapsed = false;
    }
    if(val=='life') {
      this.isInsurance = false;
      this.islife = !this.islife;
      this.ismotor = false;
      this.isother = false;
      this.isrenew = false;
      this.islifecollapsed = !this.islifecollapsed;
      this.islifenotcollapsed = !this.islifenotcollapsed;
      this.isinvestmentnotcollapsed = true;
      this.isinvestmentcollapsed = false;
      this.isrenewnotcollapsed = true;
      this.isrenewcollapsed = false;
      this.isothernotcollapsed = true;
      this.isothertcollapsed = false;
      this.ismotornotcollapsed = true;
      this.ismotorcollapsed = false;
    }
    if(val=='motor') {
      this.isInsurance = false;
      this.islife = false;
      this.ismotor = !this.ismotor;
      this.isother = false;
      this.isrenew = false;
      this.ismotorcollapsed = !this.ismotorcollapsed;
      this.ismotornotcollapsed = !this.ismotornotcollapsed;
      this.isinvestmentnotcollapsed = true;
      this.isinvestmentcollapsed = false;
      this.isrenewnotcollapsed = true;
      this.isrenewcollapsed = false;
      this.isothernotcollapsed = true;
      this.isothertcollapsed = false;
      this.islifenotcollapsed = true;
      this.islifecollapsed = false;
    }
    if(val=='other') {
      this.isInsurance = false;
      this.islife = false;
      this.ismotor = false;
      this.isother = !this.isother;
      this.isrenew = false;
      this.isothertcollapsed = !this.isothertcollapsed;
      this.isothernotcollapsed = !this.isothernotcollapsed;
      this.isinvestmentnotcollapsed = true;
      this.isinvestmentcollapsed = false;
      this.isrenewnotcollapsed = true;
      this.isrenewcollapsed = false;
      this.ismotornotcollapsed = true;
      this.ismotorcollapsed = false;
      this.islifenotcollapsed = true;
      this.islifecollapsed = false;
    }
    if(val=='renew') {
      this.isInsurance = false;
      this.islife = false;
      this.ismotor = false;
      this.isother = false;
      this.isrenew = !this.isrenew;
      this.isothertcollapsed = false;
      this.isothernotcollapsed = true;
      this.isrenewcollapsed = !this.isrenewcollapsed;
      this.isrenewnotcollapsed = !this.isrenewnotcollapsed;
      this.isinvestmentnotcollapsed = true;
      this.isinvestmentcollapsed = false;
      this.ismotornotcollapsed = true;
      this.ismotorcollapsed = false;
      this.islifenotcollapsed = true;
      this.islifecollapsed = false;
    }
  }

  ngOnInit() {
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
    }
  }
}
