import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gatage-bank-details',
  templateUrl: './garage-bank-details.component.html',
  styleUrls: ['./garage-bank-details.component.css']
})
export class GatageBankDetailsComponent {
  BankDetails:FormGroup;
  constructor(private route:Router,private fb:FormBuilder)
  {
this.BankDetails=fb.group({
  "AccountNumber":["",Validators.required],
  "ConfirmAccount":["",Validators.required],
  "HolderName":["",Validators.required],
  "IFSC":["",Validators.required],
  "BankName":[""],
  "BranchName":[""],
  "statement":[""],
});
}
  back()
  {
    this.route.navigate(['/garage-profile']);
  }
  Success:boolean=false;
  close(){
    this.Success=false;
  }
  success()
  {
    this.Success=true;
  }
}
