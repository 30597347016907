import { Component } from '@angular/core';

@Component({
  selector: 'app-third-party-two-wheeler-insurance',
  templateUrl: './third-party-two-wheeler-insurance.component.html',
  styleUrls: ['./third-party-two-wheeler-insurance.component.css']
})
export class ThirdPartyTwoWheelerInsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
