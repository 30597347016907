<div class="container-fluid p-5 maindiv" >
<div class="container p-3" >
    <div class="android_banner_div">
      <div class="row" [ngClass]="{'card-bg': !isSmallScreen, 'bg-dark': isSmallScreen}" style="border-radius:32px">
        <!-- [ngClass]="{'col-5': !isSmallScreen, 'col-12': isSmallScreen}" [ngStyle]="{'padding-left':'5rem'}" -->
        <div class="col-lg-5 col-md-5 col-sm-5 col-12">
          <div class="android_img_banner">
            <img src="../../assets/images/android_img.png">
          </div>
            
        </div>
        <div [ngClass]="{'col-7': !isSmallScreen, 'col-12': isSmallScreen}">
            <div style="margin: auto;">
                <!-- <h1>
                  Pay, invest, save your<br> money with a <span style="color: var(--main);">
                    single app
                  </span>
                </h1> -->
                <h1>
                 {{langList.mobile_app_title}}
                </h1>
                <span class="font-body" style="color:#C2C2C9!important;">{{langList.mobile_app_content}}</span>
                <div class="d-flex mt-5">
                  <img src="../../assets/svgIcon/exchange.svg">&nbsp;&nbsp;
                  <p class="pt-5">{{langList.mobile_app_compare}}</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img src="../../assets/svgIcon/ShoppingCart.svg">&nbsp;&nbsp;
                  <p class="pt-5">{{langList.mobile_app_buy}}</p>
                </div>
                <div class="d-flex">
                  <img src="../../assets/svgIcon/Truck.svg">&nbsp;&nbsp;
                  <p class="pt-5">{{langList.mobile_app_track}}</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img src="../../assets/svgIcon/ArchiveTray.svg">&nbsp;&nbsp;
                  <p class="pt-5">{{langList.mobile_app_download}}</p>
                </div>
                <p style="font-size: 16px; color: burlywood!important;">{{langList.mobile_app_coming}}</p>
                <div class="d-flex mt-10">
                  <a href="https://play.google.com/store/apps/details?id=app.click4bima.com" target="_blank"><img class="rounded-3"
                      src="../../assets/images/Google Play.svg"></a>&nbsp;&nbsp;
                  <a href="https://play.google.com/store/apps/details?id=app.click4bima.com" target="_blank"><img class="rounded-3" src="../../assets/images/App Store.svg"></a>
                </div>&nbsp;&nbsp;
                <!-- <p class="d-flex">Coming Soon</p> -->
              </div>
        </div>
      </div>
    </div>
  </div>
</div>