import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-health-summary',
  templateUrl: './health-summary.component.html',
  styleUrls: ['./health-summary.component.css']
})
export class HealthSummaryComponent {
  @Input()  healthObj:any;
  @Output() gotoEvent = new EventEmitter<void>();
  Up: boolean = true;
  Up1: boolean = false;
  Up2: boolean = false;
  Up3: boolean = false;
  down: boolean = false;
  down1: boolean = true;
  down2: boolean = true;
  down3: boolean = true;
chUp:boolean=false;
chUp1:boolean=false;
chUp2:boolean=false;
chUp3:boolean=false;
chDw:boolean=true;
chDw1:boolean=true;
chDw2:boolean=true;
chDw3:boolean=true;



  chilVal: string = '';
  value: string = '';
  dropDown(val: string) {
    this.value = val;
    if (this.value == 'down') {
      this.down = false;
      this.Up = true;
      this.down1=true;
      this.Up1=false;
      this.down2=true;
      this.Up2=false;
      this.down3=true;
      this.Up3=false;
      
      this.child=false;

    }
    if (this.value == 'up') {
      this.down = true;
      this.Up = false;
    }
    if (this.value == 'down1') {
      this.down1 = false;
      this.Up1 = true;
      this.Up=false;
      this.down=true;
      this.Up3=false;
      this.down3=true;
      this.down2=true;
      this.Up2=false;
      
      this.child=false;
 

    }
    if (this.value == 'up1') {
      this.down1 = true;
      this.Up1 = false;
    }
    if (this.value == 'down3') {
      this.down3 = false;
      this.Up3 = true;
      this.down=true;
      this.Up=false;
      this.Up1=false;
      this.down1=true
      this.Up2=false;
      this.down2=true;
      this.child=false;
    }
    if (this.value == 'up3') {
      this.down3 = true;
      this.Up3 = false;
    }


  }
child:boolean=false;
  ChildDown() {
    this.down2 = false;
    this.Up2 = true;
    this.child=true;
    this.Up=false;
    this.Up1=false;
    this.Up3=false;
    this.down3=true;
    this.down=true;
    this.down1=true;
    
  }
  ChildUp() {
    this.down2 = true;
    this.Up2 = false;
    this.child=false;

   
  }
  tabs:string='';
next(pg:string)
{
this.tabs=pg;
if(this.tabs=='down')
{
  this.chDw=false;
  this.chUp=true;
}
if(this.tabs=='down1')
{
  this.chDw1=false;
  this.chUp1=true;
}
if(this.tabs=='down2')
{
  this.chDw2=false;
  this.chUp2=true;
}
if(this.tabs=='down3')
{
  this.chDw3=false;
  this.chUp3=true;
}
if(this.tabs=='up')
{
  this.chDw=true;
  this.chUp=false;
}
if(this.tabs=='up1')
{
  this.chDw1=true;
  this.chUp1=false;
}
if(this.tabs=='up2')
{
  this.chDw2=true;
  this.chUp2=false;
}
if(this.tabs=='up3')
{
  this.chDw3=true;
  this.chUp3=false;
}
}

paySecurly()
{

    this.gotoEvent.emit();

}


}
