import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import swal from 'sweetalert2';
import {AnswerSheetModel, ExamModel} from '../../../Models/agent.model';
import {QuestionModel} from '../../../Models/agent.model';

@Component({
  selector: 'app-liexam',
  templateUrl: './liexam.component.html',
  styleUrls: ['./liexam.component.css']
})
export class LiexamComponent implements OnInit{
istest:boolean=false;
isdone:boolean=false;
nextQuestion: boolean = false;
examinationList:ExamModel[] =[];
copyExaminationList:ExamModel[] =[];
questionSequence = 1;
questionSequenceForReview = 0;
examQuestionsData:[]=[];
intervalId:any;
remainingTime:number;
countdownText:string
showSuccessPopup:boolean = false;
  constructor(private router:Router, private fb: FormBuilder,
     private spinner: NgxSpinnerService,private agentprofileService : AgentprofileService){
  }

  isClicked: boolean = false;

  handleClick() {
    this.isClicked = !this.isClicked;
  }

ngOnInit(): void {
this.getExamQuestions();
this.startCountdown();
}

// #region Get Examinations Data
async getExamQuestions(){
  this.examinationList = [];
  this.copyExaminationList = [];
  let pageNumber = this.getRandomCardPower();
  const response = await this.agentprofileService.GetQuestionInfo(pageNumber);
if(response?.result && response?.result.length>0){
  this.copyExaminationList = response?.result;
  ;
  // const newdata = this.copyExaminationList.map((x:ExamModel) => {
  // return{
  //   examID : x.examID,
  //   examName:x.examName,
  //   questiontext:x.questiontext,
  //   QuestionModel : this.copyExaminationList.filter(a=>a.examID == x.examID).map(item =>({
  //     isCorrect:item.isCorrect,
  //     optionID:item.optionID,
  //     optionText:item.optionText,
  //   }))
  // }});
this.removeDuplicateRecords(this.copyExaminationList);
}
else{
  swal.fire({
    position: 'center',
    icon: 'error',
    title: 'No Quotes Available',
    showConfirmButton: false,
    timer: 3000,
  });
}

}

//Get Examinations Data END

//#Remove Duplicates
removeDuplicateRecords(data:any){
  const uniqueSet = new Set<number>();
  var incr = 0;
  this.examinationList = data.filter((item:any)=>{
    if(!uniqueSet.has(item.examID)){
      uniqueSet.add(item.examID);
      item.sequenceNo=incr = incr+1;
      return true;
    }
    return false;
  })
  console.log( this.examinationList,"Test Data");
}

//Remove Duplicates END


//Generate Random Numbers
 getRandomCardPower():number{    
  return Math.floor((Math.random()*5)+1);
}
//Generate Random Numbers END

test()
{
  this.istest=true;
}

async viewCertificate(){
  let answerSheetModelList:AnswerSheetModel[] =[];

  this.examinationList.forEach(question => { 
    let answerSheetModel = {} as AnswerSheetModel;   
    answerSheetModel.questionId = question.questionId;
    answerSheetModel.questiontext = question.questiontext;
    question.optionsList.forEach(option=>{
      if(option.isSelected == true && question.isAnswered == true)
      {
        answerSheetModel.answerId = option.optionID;
        answerSheetModel.answerText = option.optionText;
      }     
      if(option.isCorrect == true)
      {
        answerSheetModel.correctAnswerId = option.optionID;
        answerSheetModel.correctAnswerText = option.optionText;
      }  
      if(option.isCorrect == true && option.isSelected == true && question.isAnswered == true)  
      {
        answerSheetModel.isCorrect = true;
      } 
    });
    answerSheetModelList.push(answerSheetModel);
  });
  const response = await this.agentprofileService.SaveAgentQuestions(answerSheetModelList);
  this.showSuccessPopup = true;
  //this.router.navigate(['/agent-profile']);
}

//To Show Next Question
next(item:number, isMarked:boolean =false)
{
  this.questionSequence = item+1;
  if(isMarked)
  {
    this.examinationList[item-1].isMarked = true;
    this.examinationList[item-1].isNotAnswered = false;
    this.examinationList[item-1].isAnswered = false;
  }
  else
  {
    let optionSelected= false;
    this.examinationList[item-1].optionsList.forEach(option1=>{
      if(option1.isSelected == true)
      {
        optionSelected = true;
      }         
    });

    if(optionSelected == false)
    {
      this.examinationList[item-1].isNotAnswered = true;
      this.examinationList[item-1].isAnswered = false;
    }
    else
    {
      this.examinationList[item-1].isAnswered = true;
      this.examinationList[item-1].isNotAnswered = false;      
    }
    this.examinationList[item-1].isMarked = false;
  }
  
  if(this.questionSequence > 30){
    this.questionSequenceForReview = 0;
    this.isdone=true;
  }
}
//To Show Next Question END

submitExam(){
  this.isdone=true;
}

showQuestion(question:number, isReview:boolean = false){
  this.questionSequence = question;
  if(isReview == true)
  {
    this.isdone=false;
  }
}

getAnsweredCount(){
  if(this.examinationList){
    return this.examinationList.filter((f)=> {return f.isAnswered == true}).length;
  }
  else
  {
    return 0;
  }  
}

getMarkedCount(){
  if(this.examinationList){
    return this.examinationList.filter((f)=> {return f.isMarked == true}).length;
  }
  else
  {
    return 0;
  }  
}

getNotVisitedCount(){
  if(this.examinationList){    
    return this.examinationList.length - this.examinationList.filter((f)=> {return f.isMarked == true || f.isAnswered == true || f.isNotAnswered == true}).length;
  }
  else
  {
    return 0;
  }  
}

getNotAnsweredCount(){
  if(this.examinationList){
    return this.examinationList.filter((f)=> {return f.isNotAnswered == true}).length;
  }
  else
  {
    return 0;
  }  
}

getBgColor(item:ExamModel){
  if(item.isMarked)
  {
    return "bg-warning";
  }
  else if(item.isAnswered)
  {
    return "bg-success";
  }
  else if(item.isNotAnswered)
  {
    return "bg-danger";
  }
  else
  {
    return "bg-lightgrey";
  }
}

onOptionClick(optionId:number,questionId:number){
  this.examinationList.forEach(question => {
    if(question.questionId == questionId){
      {
        question.optionsList.forEach(option1=>{
          if(option1.optionID == optionId)
          {
            option1.isSelected = !option1.isSelected;
          }
          else
          {
            option1.isSelected = false;
          }          
        });
      }
    }
  });
}
isOptionSelected(questionId:number):boolean{
  let optionSelected = false;
  let question = this.examinationList.filter(f=> {return f.questionId == questionId});
  if(question)
  {
    question[0].optionsList.forEach(option1=>{
      if(option1.isSelected == true)
      {
        optionSelected = true;
      }         
    });
  }
  return optionSelected;
}

setQuestionSequenceForReview(sequence:number){
  this.questionSequenceForReview = sequence;
}

private startCountdown(): void { 
  this.remainingTime = 1000 * 60 * 30;   
  this.intervalId = setInterval(() => {
      // const now = new Date().getTime();
      // this.remainingTime = (new Date(this.trainingEndAt).getTime() + (24 * 1000 * 60 * 60)) - now;

      //const now = new Date().getTime();    
      this.remainingTime = this.remainingTime - 1000;    
      
      if (this.remainingTime < 0) {
        clearInterval(this.intervalId);
        this.remainingTime = 0;
        this.countdownText = 'Time Over';
      } else {
        this.countdownText = this.updateCountdownText(this.remainingTime);
      }
  }, 1000);
}

public updateCountdownText(distance : number): string {
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  return `${hours < 9 ? '0' + hours : hours}h ${minutes < 9 ? '0' + minutes : minutes}m ${seconds < 9 ? '0'+ seconds : seconds}s`;
}

}





