<app-navbar></app-navbar>
<div class="container-fluid career_banner ">
    <div class="container">
        <div class="row center">
            <div class="col-md-6 p-20 mt-5">
                <h1>
                    Let’s Make It Happen
                </h1>
                <div>
                    <span style="text-align: justify;">
                        It dose not matter where you are coming from, How you look or any other aspect about yourself.
                        If you are passionate about what you do, we can’t wait to meet you.
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <img src="../.../../../../assets/images/image 27.png" alt="" class="h-img">
            </div>
            <div class="col-md-12">
                <h1>
                    Why should you join us
                </h1>
            </div>
            <div class="col-md-4 center mt-15 ">
                <div class="col sub_card phone">
                    <div class="img_div">
                        <img src="../.../../../../assets/images/image 28.png" alt="">
                    </div>
                    <div class="row">
                        <div class="col text-center">
<h4>
    Respect for Performance
</h4>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-4 center mt-15">
                <div class="col sub_card phone">
                    <div class="img_div">
                        <img src="../.../../../../assets/images/image 29.png" alt="">
                    </div>
                    <div class="row">
                        <div class="col text-center">
<h4>
    Fast Track
    Growth & Earning
</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 center mt-15">
                <div class="col sub_card phone">
                    <div class="img_div">
                        <img src="../.../../../../assets/images/image 30.png" alt="">
                    </div>
                    <div class="row">
                        <div class="col text-center">
<h4>
    Reward For Solutions &
    Creative Work
</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 center mt-15">
                <div class="col sub_card phone">
                    <div class="img_div">
                        <img src="../.../../../../assets/images/image 31.png" alt="">
                    </div>
                    <div class="row">
                        <div class="col text-center">
<h4>
    Work As A Team
</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 center mt-15">
                <div class="col sub_card phone">
                    <div class="img_div">
                        <img src="../.../../../../assets/images/image 32.png" alt="">
                    </div>
                    <div class="row">
                        <div class="col text-center">
<h4>
    Empower Team For
    Quick Decision
</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 center mt-15">
                <div class="col sub_card phone">
                    <div class="img_div">
                        <img src="../.../../../../assets/images/image 33.png" alt="">
                    </div>
                    <div class="row">
                        <div class="col text-center">
<h4>
    Saturday Dressing 
    Throuout The Week
</h4>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>	
<div class="container-fluid career_form">
    <div class="container">
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="p-5">
                        <h4 class="career_heading">
                            Check for current opening below.
                        </h4>

                    </div>
                    <div class="m-2">
                        <span style="color:rgb(149, 21, 24); font-size: 16px;">
                            Tech.
                        </span>
                        <div class="faq-item">
                            <div class="faq-question" (click)="toggleAnswer(0)">
                              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }" aria-hidden="true"></i>
                              Front-end Developer
                              <p>
                                <span>No. of vacancy : 2 &nbsp; <i class="fa fa-map-marker"
                                    aria-hidden="true" style="float: none;"></i>Location : Mohali</span>
                            </p>
                            </div>
                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }">
                                <p>
                                    <span>
                                        We are a full-stack financial services organisation, committed to helping Indian
                                        businesses with comprehensive and innovative payment and business banking
                                        solutions built over robust technology to address the entire length and breadth
                                        of the payment and banking journey for any business.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-10">
                        <span style="color:rgb(149, 21, 24); font-size: 16px;">
                            Bussiness.
                        </span>
                        <div class="faq-item">
                            <div class="faq-question" (click)="toggleAnswer(1)">
                              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }" aria-hidden="true"></i>
                              Front-end Developer
                              <p>
                                <span>No. of vacancy : 2 &nbsp; <i class="fa fa-map-marker"
                                    aria-hidden="true" style="float: none;"></i>Location : Mohali</span>
                            </p>
                            </div>
                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">
                                <p>
                                    <span>
                                        We are a full-stack financial services organisation, committed to helping Indian
                                        businesses with comprehensive and innovative payment and business banking
                                        solutions built over robust technology to address the entire length and breadth
                                        of the payment and banking journey for any business.
                                    </span>
                                </p>
                                <p>
                                    <span>No. of vacancy : 2 &nbsp; <i class="fa fa-map-marker"
                                        aria-hidden="true" style="float: none;"></i>Location : Mohali</span>
                                </p>
                            </div>
                        </div>
                        <div class="faq-item">
                            <div class="faq-question" (click)="toggleAnswer(2)">
                              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 2, 'fa-minus': openItemIndex === 2 }" aria-hidden="true"></i>
                              Front-end Developer
                              <p>
                                <span>No. of vacancy : 2 &nbsp; <i class="fa fa-map-marker"
                                    aria-hidden="true" style="float: none;"></i>Location : Mohali</span>
                            </p>
                            </div>
                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 2 }">
                                <p>
                                    <span>
                                        We are a full-stack financial services organisation, committed to helping Indian
                                        businesses with comprehensive and innovative payment and business banking
                                        solutions built over robust technology to address the entire length and breadth
                                        of the payment and banking journey for any business.
                                    </span>
                                </p>
                                
                            </div>
                        </div>
                        <div class="faq-item">
                            <div class="faq-question" (click)="toggleAnswer(3)">
                              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }" aria-hidden="true"></i>
                              Front-end Developer
                              <p>
                                <span>No. of vacancy : 2 &nbsp; <i class="fa fa-map-marker"
                                    aria-hidden="true" style="float: none;"></i>Location : Mohali</span>
                            </p>
                            </div>
                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }">
                                <p>
                                    <span>
                                        We are a full-stack financial services organisation, committed to helping Indian
                                        businesses with comprehensive and innovative payment and business banking
                                        solutions built over robust technology to address the entire length and breadth
                                        of the payment and banking journey for any business.
                                    </span>
                                </p>
                            </div>
                        </div>
                        <!-- <div class="faq-item">
                            <div class="faq-question" (click)="toggleAnswer(4)">
                              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }" aria-hidden="true"></i>
                              Front-end Developer
                              <p>
                                <span>No. of vacancy : 2 &nbsp; <i class="fa fa-map-marker"
                                    aria-hidden="true" style="float: none;"></i>Location : Mohali</span>
                            </p>
                            </div>
                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">
                                <p>
                                    <span>
                                        We are a full-stack financial services organisation, committed to helping Indian
                                        businesses with comprehensive and innovative payment and business banking
                                        solutions built over robust technology to address the entire length and breadth
                                        of the payment and banking journey for any business.
                                    </span>
                                </p>
                                
                            </div>
                        </div>
                        <div class="faq-item">
                            <div class="faq-question" (click)="toggleAnswer(5)">
                              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }" aria-hidden="true"></i>
                              Front-end Developer
                              <p>
                                <span>No. of vacancy : 2 &nbsp; <i class="fa fa-map-marker"
                                    aria-hidden="true" style="float: none;"></i>Location : Mohali</span>
                            </p>
                            </div>
                            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">
                                <p>
                                    <span>
                                        We are a full-stack financial services organisation, committed to helping Indian
                                        businesses with comprehensive and innovative payment and business banking
                                        solutions built over robust technology to address the entire length and breadth
                                        of the payment and banking journey for any business.
                                    </span>
                                </p>
                               
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card p-5 careerFormSection">
                         <form [formGroup]="careerForm" class="needs-validation" novalidate="" autocomplete="off">
                            <div class="row">
                                <div class="col-md-12 mt-2">
                                    <h4 class="center">
                                        Want to connect with HR team?
                                    </h4>
    
                                </div>
                                <div class="col-md-12 mt-4">
                                    <label>Full Name</label>
                                    <input type="text" name="fullName" id="" placeholder="Full Name" formControlName="fullNamve" oninput="this.value=(this.value.match('[a-zA-Z]+'))" class="form-control">
                                </div>
    
                                <div class="col-md-12 mt-4">
                                    <label>Mobile Number</label>
                                    <input type="number" name="mobileNumber" id="" placeholder="Mobile Number" class="form-control" formControlName="mobileNumber" minlength="10" maxlength="10" onkeypress="if(this.value.length==10) return false;" oninput="this.value=(this.value.match('[0-9]+'))">
                                </div>
    
                                <div class="col-md-12 mt-4">
                                    <label>Location</label>
                                    <input type="text" name="location" id="" placeholder="Location" class="form-control" formControlName="location">
                                </div>
    
                                <div class="col-md-12 mt-4">
                                    <label>Total Experience</label>
                                    <input type="text" name=" totalExperience" id="" placeholder=" Experience" class="form-control" formControlName="totalExperience" >
                                </div>
                                <div class="col-md-12 mt-5">
                                    <label>Upload Resume</label>
                                </div>
                                <div class="col-md-6 mt-2 ">
                                    <div class="card">
                                        <button class="cv"> <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp;&nbsp;{{fileName}}</button>
                                        <input type="file" name="uploadResume" id="" class="form-control file-input" formControlName="uploadResume"
                                        (change)="onSelectFile($event)" accept=".png, .jpg, .jpeg, .pdf, video/*" (input)="showCross()" #fileInput>
                                        <span class="crs" *ngIf="crsButton" (click)="hideCross(fileInput)"><i class="fa fa-times" aria-hidden="true"></i></span> 

                                    </div>
                                    <!-- <label class="file-input-label"><i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp; {{fileName}}</label>
                                    <input type="file" name="uploadResume" id="" class="form-control file-input" formControlName="uploadResume"
                                    (change)="onSelectFile($event)" accept=".png, .jpg, .jpeg, .pdf, video/*" (input)="showCross()" #fileInput>
                                    <span class="crs" *ngIf="crsButton" (click)="hideCross(fileInput)"><i class="fa fa-times" aria-hidden="true"></i></span> -->
                                </div>
                                
                                <!-- <div class="col-md-12">
                                    <div class="p-5 text-center">
                                    <label >Resume or Cover Latter</label><br>
                                    <div class="center mt-5 file-input-container" >
                                        <label class="file-input-label"> <i class="fa fa-file-o" aria-hidden="true"></i>&nbsp;Upload
                                        </label>
    
                                        <input type="file" name="" id=""  class="file-input" >
                                    </div>
                                </div>
                                </div> -->
                                <div class="col-md-12 mt-10 text-center">
                                    <button class="btn btn-primary center btn-md text-center" [disabled]="careerForm.invalid" (click)="submitCareerDetails()">Submit Application</button>
                                </div>
                            </div>
                        </form>
                        
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>