import { Component } from '@angular/core';

@Component({
  selector: 'app-insurance-owner-detail',
  templateUrl: './insurance-owner-detail.component.html',
  styleUrls: ['./insurance-owner-detail.component.css']
})
export class InsuranceOwnerDetailComponent {

}
