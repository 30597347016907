import { Component } from '@angular/core';

@Component({
  selector: 'app-carInsurance-owner-detail',
  templateUrl: './carInsurance-owner-detail.component.html',
  styleUrls: ['./carInsurance-owner-detail.component.css']
})
export class carInsuranceOwnerDetailComponent {

}
