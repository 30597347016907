export class tamilLabel {
    public product: string = 'தயாரிப்பு';
    public support: string = 'ஆதரவு';
    public raise_a_query: string = 'தூக்கி கேள்வி';
    public raise_a_claim: string = 'கோரிக்கை கேட்க';
    public contact_us: string = 'எங்களை தொடர்பு கொள்ள';
    public career: string = 'தொழில்';
    public gallery: string = 'கேலரி';
    public login: string = 'உள்நுழைய';
    public two_wheeler: string = 'இரு சக்கர வாகனம்';
    public four_wheeler: string = 'நான்கு சக்கர வாகனம்';
    public health: string = 'ஆரோக்கியம்';
    public commercial_vehicle: string = 'வியாபார வாகனம்';
    public bike_ins_title: string = 'உங்கள் இரு சக்கர வாகனக் காப்பீட்டில் பிரத்தியேகமாக குறைந்த விலையைப் பெறுங்கள்';
    public bike_ins_start: string = 'இரு சக்கர வாகன காப்பீடு ₹635ல் தொடங்குகிறது*';
    public enter_bike_number: string = 'இரு சக்கர வாகன எண்ணை உள்ளிடவும். உதாரணம்: AB12CD3456';
    public verify: string = 'சரிபார்க்கவும்';
    public dont_have_policy: string = "பதிவு எண் நினைவில் இல்லையா";
    public buy_new_bike: string = 'புதிய இரு சக்கர வாகனம் வாங்கினீர்களா?';
    public what_is_ins1: string = 'காப்பீடு என்றால்';
    public what_is_ins2: string = 'என்ன?';
    public what_is_ins_title: string = 'காப்பீட்டின் மூலம் மன அமைதியைக் கண்டறியவும்';
    public what_is_ins_text: string = "எங்களின் Click4bima போர்ட்டலுக்கு வரவேற்கிறோம், எதிர்காலத்தைப் பாதுகாப்பதற்கான உங்கள் நுழைவாயில்! பரந்த அளவிலான காப்பீட்டு விருப்பங்களை ஆராய்வதற்கான உங்கள் ஒரே இடமாக எங்கள் போர்டல் உள்ளது. உடல்நலம் மற்றும் வாகனம் முதல் வீடு மற்றும் பயணம் வரை, உங்கள் தேவைகளுக்கு ஏற்ப சிறந்த கவரேஜ் திட்டங்களை உங்களுக்குக் கொண்டு வர, நாங்கள் புகழ்பெற்ற காப்பீட்டு நிறுவனங்களுடன் கூட்டு சேர்ந்துள்ளோம். வெவ்வேறு கொள்கைகளை ஒப்பிடுவதன் மூலம், நீங்கள் தகவலறிந்த முடிவுகளை எடுக்கலாம் மற்றும் உங்கள் வாழ்க்கை முறை மற்றும் பட்ஜெட்டுக்கு ஏற்ற ஒன்றைத் தேர்ந்தெடுக்கலாம்.";
    public why_insurance_imp1: string = 'ஏன் காப்பீடு';
    public why_insurance_imp2: string = 'முக்கியமானது?';
    public why_insurance_imp_title: string = 'நாளைப் பாதுகாப்பது: காப்பீட்டின் உயிர்ச்சக்தியை வெளிப்படுத்துதல்';
    public why_insurance_imp_text: string = "இன்சூரன்ஸ் என்பது வாழ்க்கையின் நிச்சயமற்ற நிலைகளுக்கு எதிரான உங்கள் கவசமாகும், உங்களுக்கு மிகவும் தேவைப்படும்போது மன அமைதியை வழங்குகிறது. இது உங்களுக்கும் நீங்கள் தேர்ந்தெடுத்த காப்பீட்டு வழங்குநருக்கும் இடையிலான ஒப்பந்தமாகும், இது எதிர்பாராத நிகழ்வுகளின் போது நிதி உதவிக்கு உத்தரவாதம் அளிக்கிறது. திடீர் மருத்துவச் செலவு, வாகன விபத்து, உங்கள் சொத்து சேதம் அல்லது எதிர்பாராத சூழ்நிலை என எதுவாக இருந்தாலும், சுமையை குறைக்க காப்பீடு நடவடிக்கை எடுக்கிறது.";
    public claim_assistance1: string = 'உதவியை';
    public claim_assistance2: string = 'கோருங்கள்';
    public claim_assistance_title: string = 'உங்கள் மன அமைதி மீட்டெடுக்கப்பட்டது';
    public claim_assistance_text: string = "Click4bima இலிருந்து 'கிளைம் உதவி' மூலம், ஒரு மென்மையான உரிமைகோரல் செயல்முறையின் முக்கியத்துவத்தைப் புரிந்துகொள்ளும் நம்பகமான கூட்டாளர் உங்களிடம் இருக்கிறார் என்பதை நீங்கள் உறுதியாக நம்பலாம். ஒரு கொள்கையை விட அதிகமாக நீங்கள் பாதுகாக்கப்படுகிறீர்கள் என்பதை அறிந்து உங்கள் மன அமைதியை மீண்டும் பெறுங்கள் - அக்கறையுள்ள குழுவால் நீங்கள் ஆதரிக்கப்படுகிறீர்கள். உங்களுக்கு மிகவும் தேவைப்படும்போது எங்கள் 'கிளைம் உதவி' உங்களின் நம்பகமான துணையாகும்.";
    public best_place_ins_title: string = 'இந்தியாவில் காப்பீடு வாங்குவதற்கான சிறந்த இடம் எது?';
    public best_place_ins_text: string = 'எங்கள் Click4bima போர்ட்டலுக்கு வரவேற்கிறோம், இந்தியாவில் சிறந்த காப்பீட்டுத் கவரேஜைக் கண்டறிவதற்கான உங்கள் ஒரே இடமாகும். நாம் ஏன் மற்றவர்களிடமிருந்து தனித்து நிற்கிறோம் என்று யோசிக்கிறீர்களா? சரியான காப்பீட்டுத் கவரேஜ் பாதுகாப்போடு கவலையற்ற எதிர்காலத்தைத் தழுவுங்கள். இன்றே எங்களுடன் உங்கள் காப்பீட்டு பயணத்தைத் தொடங்குங்கள்.';
    public everytime_support_title: string = '२४*७ सहायता';
    public everytime_support_text: string = "हमारी 24/7 बीमा सहायता से कभी भी, कहीं भी मानसिक शांति प्राप्त करें। हमारी समर्पित टीम आपको विशेषज्ञ सहायता प्रदान करने, आपके प्रश्नों का उत्तर देने और यह सुनिश्चित करने के लिए चौबीसों घंटे यहां मौजूद है कि आप हमेशा कवर रहें।."
  
  
  
    public best_price_ins_title: string = 'சிறந்த விலைக் காப்பீடு';
    public best_price_ins_text: string = "எங்கள் நோக்கம் எளிதானது: தரத்தில் சமரசம் செய்யாமல் சிறந்த காப்பீட்டு ஒப்பந்தங்களை உங்களுக்கு வழங்குவது. தனிப்பயனாக்கக்கூடிய கொள்கைகளின் வரம்பில், உங்கள் பட்ஜெட்டை அப்படியே வைத்திருக்கும் அதே வேளையில் உங்கள் எதிர்காலத்தைப் பாதுகாக்க நாங்கள் இங்கு வந்துள்ளோம்.";
    public reliable_title: string = 'நம்பகமானது';
    public reliable_text: string = 'விதிவிலக்குகள் இல்லாமல், உங்களுக்கு மிகவும் முக்கியமானவற்றைப் பாதுகாக்க எங்களை நம்பலாம். எங்கள் வாக்குறுதிகளை நிறைவேற்றுவதில் நிரூபிக்கப்பட்ட சாதனையுடன், ஒவ்வொரு அடியிலும் உங்கள் மன அமைதியை நாங்கள் உறுதிசெய்கிறோம். உங்கள் எதிர்காலத்தைப் பாதுகாப்பதில் அசைக்க முடியாத நம்பகத்தன்மைக்கு எங்களைத் தேர்ந்தெடுங்கள்.';
    public easy_claim_title: string = 'கோரிக்கை எளிதாகிவிட்டது';
    public easy_claim_text: string = 'எங்களின் நெறிப்படுத்தப்பட்ட நடைமுறைகள் மற்றும் அர்ப்பணிப்புள்ள ஆதரவுக் குழு உங்களுக்குத் தேவைப்படும்போது உங்களுக்குத் தேவையான உதவியைப் பெறுவதை உறுதி செய்கிறது. செயல்முறையை எளிதாக்குவதற்கு நாங்கள் கடமைப்பட்டுள்ளோம், எனவே உங்கள் வாழ்க்கையில் மிக முக்கியமானவற்றை விரைவாகப் பெறலாம்.';
    public mobile_app_title: string = 'ஒரே ஆப் மூலம் பணம் செலுத்துங்கள், முதலீடு செய்யுங்கள், உங்கள் பணத்தைச் சேமிக்கவும்';
    public mobile_app_content: string = 'உங்கள் காப்பீட்டுத் தேவைகளை எங்கும், எந்த நேரத்திலும் கட்டுப்படுத்துங்கள்';
  
    public mobile_app_compare: string = 'வெவ்வேறு காப்பீட்டுக் கொள்கைகளை ஒப்பிடுக';
    public mobile_app_buy: string = 'உங்கள் கொள்கைகள் அனைத்தையும் ஆன்லைனில் வாங்கவும், சேமிக்கவும் மற்றும் பகிரவும்';
    public mobile_app_track: string = 'பயணத்தின்போது உங்கள் கொள்கை நிலையைக் கண்காணிக்கவும்';
    public mobile_app_download: string = 'ஒரே தட்டலில் உங்கள் கொள்கையைப் பதிவிறக்கவும்';
    public mobile_app_coming: string = 'அற்புதமான ஒன்றுக்கு தயாராகுங்கள் - எங்கள் பயன்பாடுகள் விரைவில் தொடங்கப்படும்!';
    public testimonial_title: string = 'எங்கள் வாடிக்கையாளர் அனுபவத்திலும் நாங்கள் அக்கறை கொள்கிறோம்   ';
    public our_partner_title: string = 'எங்கள் பங்காளிகள்';
    public our_partner_text: string = 'உங்களுக்கு சிறந்த சேவைகளை வழங்க நாங்கள் இணைந்து செயல்படுகிறோம்.';
    public need_help_title: string = 'ஏதாவது உதவி வேண்டுமா?';
    public need_help_text: string = 'உங்களுக்கு சிறந்த சேவைகளை வழங்க நாங்கள் இணைந்து செயல்படுகிறோம். Click4bima போர்ட்டலில், உங்கள் காப்பீட்டு பயணத்தின் ஒவ்வொரு அடியிலும் விதிவிலக்கான வாடிக்கையாளர் சேவை மற்றும் உதவியை வழங்க நாங்கள் கடமைப்பட்டுள்ளோம். காப்பீட்டு உலகில் உங்களின் நம்பகமான பங்காளியாக இருங்கள். இன்றே எங்கள் ஆதரவுக் குழுவைத் தொடர்புகொண்டு வித்தியாசத்தை நேரில் அனுபவிக்கவும். உங்கள் மன அமைதி எங்கள் முன்னுரிமை!';
    public mail_us: string = 'எங்களுக்கு அஞ்சல்';
    public mobile_number: string = 'கைபேசி எண்';
    public whatsapp: string = 'வாட்ஸ்அப்';
    public about_us: string = 'எங்களை பற்றி';
    public sitemap: string = 'தளவரைபடம்';
    public faqs: string = 'அடிக்கடி கேட்கப்படும் கேள்விகள்';
    public motor_insurance: string = 'மோட்டார் காப்பீடு';
    public car_insurance: string = 'கார் காப்பீடு';
    public two_wheeler_insurance: string = 'இரு சக்கர வாகன காப்பீடு';
    public taxi_insurance: string = 'டாக்சி காப்பீடு';
    public commercial_veh_insurance: string = 'வணிக வாகன காப்பீடு';
    public third_party_car_ins: string = 'மூன்றாம் தரப்பு கார் காப்பீடு';
    public third_party_bike_ins: string = 'மூன்றாம் தரப்பு 2W இன்சூரன்ஸ்';
    public health_insurance: string = 'மருத்துவ காப்பீடு';
    public family_health_ins: string = 'குடும்ப நலக் பாலிசி';
    public individual_health_ins: string = 'தனிப்பட்ட சுகாதாரக் பாலிசி';
    public group_health_ins: string = 'குழு சுகாதார பாலிசி';
    public senior_citizen_health_ins: string = 'மூத்த குடிமக்கள் சுகாதாரக் பாலிசி';
    public critical_illness_ins: string = 'தீவிர நோய் பாலிசி';
    public arogya_san_ins: string = 'ஆரோக்கிய சஞ்சீவனி பாலிசி';
    public life_insurance: string = 'ஆயுள் காப்பீடு';
    public term_insurance: string = 'டேர்ம்  காப்பீடு';
    public single_pay_term_ins: string = 'ஒற்றை ஊதிய டேர்ம்  காப்பீடு ';
    public group_term_ins: string = 'குழு டேர்ம்  காப்பீடு';
    public saving_plans: string = 'சேமிப்பு திட்டங்கள்';
    public tax_saving_ins: string = 'வரி சேமிப்பு பாலிசி';
    public money_back_ins: string = 'பணம் திரும்பக் பாலிசி';
    public guaranteed_return_ins: string = 'உத்தரவாதமான வருமானக் பாலிசி';
    public ulip: string = 'யு எல் ஐ பி';
    public pension_plan: string = 'பென்ஷன் திட்டம்';
    public child_plan: string = 'குழந்தை திட்டம்';
    public others: string = 'மற்றவைகள்';
    public privacy_policy: string = 'தனியுரிமை பாலிசி';
    public cancellation_refund: string = 'ரத்துசெய்தல் & திரும்பப்பெறுதல்';
    public term_condition: string = 'விதிமுறைகளும் நிபந்தனைகளும்';
    public disclaimer: string = 'மறுப்பு';
    public grivance_redressal: string = 'பரிகாரம்';
  
    public srg_add_title: string = 'எஸ்ஆர்ஜி இன்சூரன்ஸ் ப்ரோக்கர்ஸ் பிரைவேட் லிமிடெட்';
    public srg_add_detail: string = '2 & 5, விநாயக் வளாகம், துர்கா நர்சரி சாலை, உதய்பூர், ராஜஸ்தான், 313001';
    public direct_broker_title: string = 'நேரடி தரகர், உரிமம் எண்: DB-290';
    public direct_broker_detail: string = 'செல்லுபடியாகும் வரை: 02.02.2026,CIN: U74140RJ2002PTC017656';
    public  payment_method:string='பணம் செலுத்தும் முறைகள்';
    public  copyright:string='© பதிப்புரிமை 2023 Click4bima. அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை';   
      public continue:string='தொடரவும்';
      public bought_new_bike:string='புதிய கார் வாங்கினார்';
      public bought_new_car:string='புதிய கார் வாங்கினார்';
      public car_ins_title:string='உங்கள் கார் காப்பீட்டில் பிரத்தியேகமாக குறைந்த விலையைப் பெறுங்கள்';
      public car_ins_start:string='கார் இன்சூரன்ஸ் ₹2471ல் தொடங்குகிறது* ';
      public enter_car_number:string='கார் எண்ணை உள்ளிடவும் EX: AB12CD3456';  
      public vehicle_detail_form:string='உங்கள் வாகன விவரங்கள்';
      public city:string='நகரம்';
      public rto:string="ஆர்டிஓ";
      public manufacturer:string="உற்பத்தியாளர்"; 
      public model:string="மாடல்"; 
      public fuel_type:string="எரிபொருள் வகை ";
      public variant:string="வேரியண்ட்"; 
      public reg_year:string="பதிவு ஆண்டு"; 
      public get_quotes:string="மேற்கோள்களைப் பெறவும்";  
      public search_city:string="தேடல் நகரம்";          
      public select_city:string="அல்லது நகரத்தைத் தேர்ந்தெடுக்கவும்"; 
      public select_rto:string='நகர ஆர்டிஓவைத் தேர்ந்தெடுக்கவும்';
      public search_manufacturer:string='தேடல் உற்பத்தியாளர்';
      public select_manufacturer:string='அல்லது உற்பத்தியாளரைத் தேர்ந்தெடுக்கவும்';  
      public search_model:string='தேடல் மாதிரி'; 
      public select_model:string='அல்லது மாதிரியைத் தேர்ந்தெடுக்கவும்'; 
      public search_variant:string='தேடல் மாறுபாடு';
      public select_variant:string='அல்லது மாறுபாட்டைத் தேர்ந்தெடுக்கவும்';
      public select_year:string='ஆண்டைத் தேர்ந்தெடுக்கவும்';
      public select_fuel_type:string='எரிபொருள் வகையைத் தேர்ந்தெடுக்கவும்';  
      public contact_title:string='எங்களுடன் தொடர்பில் இரு';
      public contact_text:string='கூடிய விரைவில் உங்களைத் தொடர்புகொள்வோம்.';
      public full_name:string='முழு பெயர்'; 
      public mobile:string='மொபைல் எண்';              
      public interested_in:string='ஆர்வம்'  ;           
      public email:string='மின்னஞ்சல் கணக்கு'  ;                    
      public description:string='விளக்கம்';            
      public call_me:string='என்னை மீண்டும் அழைக்கவும்';        
      public continue_fb:string='பேஸ்புக்கில் தொடரவும்';
      public continue_google:string='கூகுள் உடன் தொடரவும்';
      public password:string='கடவுச்சொல்';           
      public remember:string='என்னை நினைவில் வையுங்கள்';  
      public dont_have_acc:string="கணக்கு எதுவும் இல்லை";
      public signup_here:string="இங்கே பதிவு செய்யவும்"; 
      public click_here:string="இங்கே கிளிக் செய்யவும்";  
      public send_otp:string="ஓடிபி அனுப்பவும்";
      public back_login:string="உள்நுழைவுக்குத் திரும்பு";    
      public already_hv_acc:string="ஏற்கனவே ஒரு கணக்கு உள்ளதா";          
      public signin_here:string="இங்கே உள்நுழைக"; 
      public verification:string="சரிபார்ப்பு  ";
      public verify_text:string="+918543871911 என்ற எண்ணுக்கு அனுப்பிய 6 இலக்கக் குறியீட்டை உள்ளிடவும். உங்கள் மொபைல் எண்ணை மாற்ற இங்கே கிளிக் செய்யவும்.";
      public enter_otp:string="ஓடிபியை உள்ளிடவும்";
      public dont_rec_otp:string="நீங்கள் குறியீட்டைப் பெறவில்லை என்றால்! மீண்டும் அனுப்பு";     
      public submit:string="சமர்ப்பிக்கவும்";
      public accept_terms:string="உங்கள் கணக்கை உருவாக்குவதன் மூலம், எங்கள் விதிமுறைகள் மற்றும் நிபந்தனைகளை ஏற்கிறீர்கள்";  
      public basic_details:string ="அடிப்படை விவரங்கள்";               
      public first_name:string ="முதல் பெயர்";
      public last_name:string ="கடைசி பெயர்";        
      public dob:string ="பிறந்த தேதி";            
      public referal_code:string ="பரிந்துரை குறியீடு";                
      public gender:string ="பாலினம்";
      public new_password:string  ="புதிய கடவுச்சொல்லை உள்ளிடவும்";  
      public confirm_old_pass:string  ="புதிய கடவு சொல்லை உறுதி செய்";                      
  
      public sign_in:string='உள்நுழையவும்';     
      public dont_have_policy_popup:string='உங்கள் இரு சக்கர வாகன எண் உங்களுக்கு நினைவில் இல்லை என்றால், உங்கள் இரு சக்கர வாகனத்தின் அடிப்படை விவரங்களைப் பெற, உங்களின் RC (பதிவுச் சான்றிதழ்) தயாராக வைத்திருக்கவும்.';
  
      public year:string='ஆண்டு';
      
    public your_details:string='உங்கள் விவரங்களை உள்ளிடவும்'; 
    public name_title:string='தலைப்பு'; 
}