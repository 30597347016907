import { Component,OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsuranceService } from 'src/app/Services/insurance.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LookUpService } from 'src/app/Services/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-healthinsurance-details',
  templateUrl: './healthinsurance-details.component.html',
  styleUrls: ['./healthinsurance-details.component.css']
})
export class HealthinsuranceDetailsComponent implements OnInit {
@Input() premiumObject :any;
Tabs:string='';
proposalForm: FormGroup;
royalDetails :any;
isPayment:boolean = false;
kids=1;
Continue:boolean=false;
nextTab(tab:string):void{
  this.Tabs=tab;
  if(this.Tabs=='Nominee')
  {
    this.Continue=true;
  }
}


constructor(private fb: FormBuilder, private insuranceService : InsuranceService, private router: Router,
  private lookUpService :LookUpService,private spinner: NgxSpinnerService){
  this.proposalForm =  this.fb.group({
    "dependentProposer":[""],
    "proposerName":[""],
    "proposerGender":[""],
    "proposerPancard":[""],
    "proposerEmail":[""],
    "proposerMobile":[""],
    "insurer1DOB":[""],
    "insurer1Occupation":[""],
    "insurer1HeightFeet":[""],
    "insurer1HeightInches":[""],
    "insurer1Weight":[""],
    "insurer2Name":[""],
    "insurer2DOB":[""],
    "insurer2Occupation":[""],
    "insurer2HeightFeet":[""],
    "insurer2HeightInches":[""],
    "insurer2Weight":[""],
    "child1Name":[""],
    "child1DOB":[""],
    "child1Relation":[""],
    "child1HeightFeet":[""],
    "child1HeightInches":[""],
    "child1Weight":[""],
    "child2Name":[""],
    "child2DOB":[""],
    "child2Relation":[""],
    "child2HeightFeet":[""],
    "child2HeightInches":[""],
    "child2Weight":[""],
    "nomineeRelationWithProposer":[""],
    "nomineeFullName":[""],
    "nomineeDOB":[""],
    "nomineeContactNumber":[""],
    "proposerEmergencyMobile":[""],
    "proposerSpouse":[""],
    "proposerSelf":[""],
    "nomineeGender":[""]
    
  })




}

ngOnInit(): void {
  console.log(this.premiumObject,"PremiumObject forr Binfing")
}


async CreateQuote(){
  this.spinner.show();
var data = this.proposalForm.value;
console.log(this.proposalForm.value,"Proposal Data");
this.proposalForm.value.insurer1Weight = this.proposalForm?.value?.insurer1Weight?.toString();
this.proposalForm.value.child1Weight = this.proposalForm?.value?.child1Weight?.toString(); 
this.proposalForm.value.child2Weight = this.proposalForm?.value?.child2Weight?.toString(); 
this.proposalForm.value.proposerMobile = this.proposalForm?.value?.proposerMobile?.toString(); 
this.proposalForm.value.insurer2Weight = this.proposalForm?.value?.insurer2Weight?.toString(); 
this.proposalForm.value.nomineeMobileNumber = this.proposalForm?.value?.nomineeMobileNumber?.toString(); 
this.proposalForm.value.proposerEmergencyMobile = this.proposalForm?.value?.proposerEmergencyMobile?.toString(); 
this.proposalForm.value.proposerSelf = this.proposalForm?.value?.proposerSelf?.toString();
this.proposalForm.value.nomineeContactNumber = this.proposalForm?.value?.nomineeContactNumber?.toString();
const response: any = await this.insuranceService.HealthCreateQuote(this.proposalForm.value)
if (response?.isSuccess && response?.result?.code == 1) {
   if (response?.result?.royalSundaramResponse !=null){
    this.royalDetails = response?.result?.royalSundaramResponse;
     this.isPayment = true;
  }

  else {
    swal.fire({
      position: 'center',
      icon: 'success',
      title: `Policy Created Successfully!`,
      showConfirmButton: false,
      timer: 5000,
    });
    this.router.navigate(['policyDetails']);
  }
}
else {
  swal.fire({
    position: 'center',
    icon: 'error',
    title: response?.result?.message,
    showConfirmButton: false,
    timer: 5000,
  });
}
this.spinner.hide();


}


}