import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../Services/general.service';
@Injectable()

export class AuthGuard implements CanActivate {
  constructor(private router: Router, private generalService:GeneralService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const Islogin = window.location.pathname.indexOf('login') == -1;
    if (Islogin) {
      if ((sessionStorage.getItem('access_token') == null || sessionStorage.getItem('access_token') == undefined) && (localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != undefined && localStorage.getItem('access_token') != ""))
        {
        this.generalService.setAccessToken(localStorage.getItem('access_token') as string);
        this.generalService.setUniqueuserid(localStorage.getItem('uniqueuserid') as string);
        this.generalService.setIsAgent(localStorage.getItem('isAgent') as string);
        this.generalService.setUserLabel(localStorage.getItem('userLabel') as string);
      }
      if (this.generalService.getAccessToken() != "") {
        return true;
      }
      else
      {
        this.router.navigate(['/']);
        return false;
      }
      //if (sessionStorage.getItem("usermenulist") != undefined) {
      //  const flMenu = JSON.parse(sessionStorage.getItem("usermenulist") || '')
      //  const ftCnt = flMenu.filter((f: { menuCommand: string | null | undefined; }) => f.menuCommand != null
      //    && f.menuCommand != undefined
      //    && f.menuCommand != ''
      //    && state.url.indexOf(f.menuCommand) != -1);
      //  if (ftCnt.length == 0) {
      //    this.router.navigate(['/unauthorize']);
      //    return false;
      //  }
      //}
    }
    if (this.generalService.getAccessToken() != '') {
      return true;
    }
    this.router.navigate(['/']);
    return true;
  }
}
