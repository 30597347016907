<app-navbar></app-navbar>
<div style=" background-color: #F3F3F4 !important;">
    <div class="container">
        <div class="row" style="margin-top:100px;">
            <div class="col-4">
                <div class="card p-5">
                    <div class="justify-content-between" style="display: flex;">
                        <div>
                            <h4 class="text-start"><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer" routerLink="/">&nbsp;&nbsp; Property Details</h4>
                        </div>
                        <div class="d-flex">
                            <img class="edit-icon" src="../../assets/svgIcon/PencilLine.svg" alt="Edit">&nbsp;&nbsp;
                            <h6 class="text-edit">Edit</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div>
                    <div>
                        <h2 class="text-start">Insurance plans for you</h2>
                    </div>
                    <div class="text-left">
                        <span class="font-body">Showing 24 plans for you</span>
                    </div>
                </div>
                <div class="d-flex mt-5 row">
                    <div class="card col-8 p-5">
                        <div class="row">
                            <div class="col-1">
                                <img src="../../assets/svgIcon/funnel.svg" alt="funnel" width="17">
                            </div>
                            <div class="col d-flex justify-content-between" style="border-right: 1px solid #E7E7E9;">
                                <h6>Policy Term</h6>
                                <p style="color: #47475C;">1 Year</p>
                                <img src="../../assets/svgIcon/arrowDown.svg" alt="Arrow Down">
                            </div>
                            <div class="col d-flex justify-content-between">
                                <h6>Property Age</h6>
                                <p style="color: #47475C;">25 - 30 Year</p>
                                <img src="../../assets/svgIcon/arrowDown.svg" alt="Arrow Down">
                            </div>
                            <!-- <div class="col" style="border-right: 1px solid #E7E7E9;">
                                <h6 style="color: #848493;">Policy Period</h6>
                            </div>
                            <div class="col" style="border-right: 1px solid #E7E7E9;">
                                <h6 style="color: #848493;">Insurer</h6>
                            </div>
                            <div class="col">
                                <h6 style="color: #848493;">Premium</h6>
                            </div> -->
                        </div>
    
                    </div>
                    <div class="col"></div>
                    <div class="card col-3 p-5">
                        <div class="row">
                            <div class="col">
                                <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending" width="24">
                            </div>
                            <div class="col">
                                <h6>Sort By</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card p-5 mt-3 row">
                    <div class="row">
                        <div class="col d-flex">
                            <div>
                                <img src="../../assets/partners/unitedInsurance.png" alt="unitedInsurance" width="150">
                            </div>&nbsp;&nbsp;&nbsp;
                            <div>
                                <h4>United India Insurance </h4>
                                <span class="font-body">NCB Super Premium</span>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-end">
                            <img src="../../assets/svgIcon/Scales.svg" alt="Scales" width="28">&nbsp;&nbsp;
                            <img src="../../assets/svgIcon/Heart.svg" alt="Heart" width="28">&nbsp;&nbsp;
                            <img src="../../assets/svgIcon/ArrowsOut.svg" alt="ArrowsOut" width="28">&nbsp;&nbsp;
                            <img src="../../assets/svgIcon/Share.svg" alt="Share" width="28">
                        </div>
                    </div>
    
                    <div class="row mt-3">
                        <div class="col">
                            <p style="color:  #848493;">Cover amount</p>
                            <h4 class="text-left">₹5 Lakh</h4>
                        </div>
                        <div class="col">
                            <p style="color:  #848493;">Cashless hospitalst</p>
                            <h4 class="text-left">155</h4>
                        </div>
                        <div class="col">
                            <p style="color:  #848493;">Claims Settled</p>
                            <h4 class="text-left">99%</h4>
                        </div>
                        <div class="col">
                            <h4 style="color:#3A8247;">5% Discount</h4>
                        </div>
                    </div>
    
                    <div class="w-100">
                        <div class="card rounded-5 p-5" style="background: rgba(10, 10, 38, 0.1);">
                            <div class="d-flex row">
                                <div class="col-8">
                                    <span class="d-flex">
                                        <p class="card p-2" style="color: #848493; text-align: center;">Single private ac
                                            room</p>&nbsp;
                                        <p class="card p-2" style="color: #848493; text-align: center;">Day care treatment
                                        </p>&nbsp;
                                        <p class="card p-2" style="color: #848493; text-align: center;">₹2.5 Lakh no claim
                                            bonus</p>
                                    </span>
                                    <span class="d-flex">
                                        <p class="card p-2" style="color: #848493; text-align: center;">24 Supported Garages
                                            in (Your City)</p>&nbsp;
                                        <p class="card p-2" style="color: #848493; text-align: center;">Restoration of cover
                                            once a year</p>
                                    </span>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-dark float-right" style="color: #FFFFFF;;">₹2,500/month </button>
                                    <span class="font-body float-right me-5 mt-2">₹17464 annually</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card row mt-2 p-3">
                    <span class="font-body text-center" style="color: #4040FF;">View 3 More Policies <img src="../../assets/svgIcon/arrowDown.svg" alt="Arrow Down" style="filter: invert(34%) sepia(95%) saturate(6165%) hue-rotate(240deg) brightness(100%) contrast(104%);"></span>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row card justify-content-center" style="Padding:12px 20px 12px 20px">
            <h4><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft" class="cursor-pointer">&nbsp;&nbsp;Details about your Property property insurance</h4>
        </div>
    
        <div class="mt-5">
            <h2 class="text-left">Insurance Plan</h2>
            <div class="row">
                <div class="col card p-5">
                    <h6 class="text-left">My Health Suraksha</h6>
                    <p style="color:#848493">Covers damages to your vehicle only and not third-party</p>
                    <h4 class="text-center mt-5">₹699/ 1 Year</h4>
                </div>&nbsp;&nbsp;
                <div class="col card p-5">
                    <h6 class="text-left">Critical Illness Insurance</h6>
                    <p style="color:#848493">Covers damages to your vehicle only and not third-party</p>
                    <h4 class="text-center mt-5">₹699/ 1 Year</h4>
                </div>&nbsp;&nbsp;
                <div class="col card p-5">
                    <h6 class="text-left">InIDVidual Health Insurance</h6>
                    <p style="color:#848493">Covers damages to your vehicle only and not third-party</p>
                    <h4 class="text-center mt-5">₹699/ 1 Year</h4>
                </div>
            </div>
        </div>
    
        <div class="mt-5">
            <h2 class="text-left">Policy Duration</h2>
            <div class="row">
                <div class="col card p-5">
                    <div class="d-flex row">
                        <div class="col">
                            <h4 class="text-left" style="line-height: 2;">1 Year</h4>
                        </div>
                        <div class="col d-flex">
                            <p style="color:#848493; line-height: 2;">Premium</p>&nbsp;
                            <h2>₹699</h2>
                        </div>
                    </div>
                </div>&nbsp;&nbsp;
                <div class="col card p-5">
                    <div class="d-flex row">
                        <div class="col">
                            <h4 class="text-left" style="line-height: 2;">1 Year</h4>
                        </div>
                        <div class="col d-flex">
                            <p style="color:#848493; line-height: 2;">Premium</p>&nbsp;
                            <h2>₹699</h2>
                        </div>
                    </div>
                </div>&nbsp;&nbsp;
                <div class="col card p-5">
                    <div class="d-flex row">
                        <div class="col">
                            <h4 class="text-left" style="line-height: 2;">1 Year</h4>
                        </div>
                        <div class="col d-flex">
                            <p style="color:#848493; line-height: 2;">Premium</p>&nbsp;
                            <h2>₹699</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row mt-5">
            <div class="col-8 card">
                <h4 class="text-left mt-5">Insurance Cover</h4>
                <div class="row justify-content-between mt-5">
                    <div class="col-2">
                        <h6>₹5 lakh</h6>
                    </div>
                    <div class="col">
                        <input type="range" class="form-range" id="customRange1" style="color:#4040FF !important">
                    </div>
                    <div class="col-2">
                        <h6>₹20 Lakh</h6>
                    </div>
                </div>
                <div class="mt-5">
                    <h1 class="text-center" style="color:#4040FF">₹14 Lakh</h1>
                </div>
            </div>&nbsp;&nbsp;
            <div class="col card">
                <h4 class="text-left mt-5">Property Details</h4>
    
            </div>
        </div>
    
        <div class="row mt-5">
            <div class="card col-8">
                <h4 class="text-left mt-5">Additional Coverage</h4>
                <p class="font-body">You should get these additional benefits to enhance your current plan</p>
    
                <div class="row d-flex justify-content-between">
                    <div class="col d-flex">
                        <input type="checkbox">
                        <h5 class="m-auto">Personal Accident Cover of Rs. 10 Lakh</h5>
                    </div>
                    <div class="col d-flex">
                        <input type="checkbox">
                        <h5 class="m-auto">Engine and Gear-Box Protect Cover</h5>
                    </div>
                </div>
            </div>&nbsp;&nbsp;
            <div class="card col">
                <h4 class="mt-5">Premium Breakup</h4>
                <br>
                <div>
                    <div class="d-flex justify-content-between">
                        <p class="font-body">InIDVidual Health Insurance</p>
                        <h6 class="float-end">₹1,000</h6>
                    </div>
                    <div class="d-flex justify-content-between">
                        <p class="font-body">Net Premium</p>
                        <h6 class="float-end">₹1,000</h6>
                    </div>
                    <div class="d-flex justify-content-between">
                        <p class="font-body">Add GST(18%)</p>
                        <h6 class="float-end">₹180</h6>
                    </div>
                    <div class="d-flex justify-content-between">
                        <p class="font-body">Final Premium</p>
                        <h6 class="float-end">₹1,180</h6>
                    </div>
                </div>
    
                <div class="card p-2" style="background: rgba(58, 130, 71, 0.1);">
                    <h3 class="text-center" style="color:#3A8247">Your are eligible for 5% Discount</h3>
                </div>
            </div>
        </div>
    
    </div>
</div>
<app-footer></app-footer>