import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

   async GetIPAddress() {
    return this.http.get<any>(`${environment.APIUrl}/lookUpInfo/GetIpAddress`
    , {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }
    ).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetKYCDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}common/GetKYCDetails`, data,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async ValidateKYCDetails(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}common/ValidateKYCDetails`, data,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async UploadKYCDocument(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}common/UploadKYCDocument`, data,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
 }