<div class="container">
  <div class="bg-transparent" [ngClass]="!isUserLogged ? '' : 'user-dashboard mt-7'"
    *ngIf="!IsQuotes && !IsQuoteSelected && !IsProposer && !IsPolicyDetail && !IsDontKnowBike && !isPaymentLink">
    <div class="row bike_insurance_div" *ngIf="bikeNum">
      <div class="col-lg-5 col-md-5 col-sm-5 col-xl-5 col-12 ">
        <div class="insurance_img" *ngIf="bikeNum">
          <img src="../../assets/images/Two Wheeler.png" alt="">
        </div>
      </div>
      <div class="col-12 col-md-7 col-lg-7 col-xl-7 align-self-center ">
        <form [formGroup]="insuranceForm" class="needs-validation" novalidate="" autocomplete="off">
          <div *ngIf="bikeNum" class="px-5">
            <h2 class="heading text-left">{{langList.bike_ins_title}}</h2>
            <h5 class="text-left text-grey4 fw-medium mt-3">{{langList.bike_ins_start}}</h5>
            <br>
            <div class="d-flex">
              <!-- <input type="text" (keydown)="formatRegNo($event)" (input)="checkRegistrationNumber($event)" (paste)="checkRegistrationNumber($event)"> -->
              <input type="text" placeholder="{{langList.enter_bike_number}}" name="Registration"
                formControlName="registrationNumber" [(ngModel)]="vehicleRegNumber" 
                oninput="this.value = this.value.toUpperCase()" 
                (keyup)="formatRegNo($event)" 
                (input)="checkRegistrationNumber($event)"
                (paste)="checkRegistrationNumber($event)"                
                class="form-control bikeIcon input-control" pattern="^[A-Z]{2}-\d{1,2}-[A-Z]{1,3}-\d{1,4}$" [minlength]="8"
                [maxlength]="14">
              <button class="btn btn-primary inputButton" [disabled]="onVehicleRegNumberInput() || !vehicleRegNumber"
                (click)="bikeNumber()">
                {{langList.verify}}
              </button>
            </div>
            <div class="errorMsg" *ngIf="this.insuranceForm.get('registrationNumber')?.dirty && onVehicleRegNumberInput()">Please enter Valid Vehicle Number.</div>
          </div>
          <div class="policy_div px-5 d-flex justify-content-between align-items-center mt-2" *ngIf="isBikeR">
            <a class="font-body float-start text-main font-15-12"
              (click)="novalidpolicy()">{{langList.dont_have_policy}}
            </a>
            <a class="font-body float-end text-main font-15-12"
              (click)="dontknowbikenumber('1')">{{langList.buy_new_bike}} </a>
          </div>
        </form>
      </div>
    </div>


  </div>
  <div class="overlay" *ngIf="donthavepolicyDetails">
    <div class="popup2 col-md-4 col-xs-12 col-sm-8 col-11">
      <div class="modal-header tooltip_">
        <span>Don't Remember Vehicle Number?</span>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <span>{{langList.dont_have_policy_popup}}</span>
        <div class="text-right">

          <button type="button" class="btn btn-primary mt-3"
            (click)="dontknowbikenumber('2')">{{langList.continue}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay verifybikedetailsdiv" *ngIf="verifyBikeDetails">
    <div class="popup2 col-md-5 col-11">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">
          Your Vehicle Details
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <form [formGroup]="vehicleForm" class="needs-validation policy_form " novalidate="" autocomplete="off">
            <div class="row">
              <div class="col-lg-6 col-md-6 mt-5">
                <div class="popupdivblock">
                  <div class="d-flex">
                    <img src="../../assets/svgIcon/license-plate.svg" alt="CalendarBlank"
                      class="icon-grey4">&nbsp;&nbsp;
                    <div class="w-100">
                      <p class="text-start text-grey4 h-4px">Registration Number</p>
                      <input type="text" name="RegistrationNumber" formControlName="registrationNumber"
                        class="form-control" readonly="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mt-5">
                <div class="popupdivblock">
                  <div class="d-flex">
                    <img src="../../assets/svgIcon/calendar.svg" alt="CalendarBlank" class="icon-grey4">&nbsp;&nbsp;
                    <div class="w-100">
                      <p class="text-start text-grey4 h-4px required">Registration Date</p>
                      <input type="date" name="registrationDate" formControlName="registrationDate"
                        class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mt-5">
                <div class="popupdivblock">
                  <div class="d-flex">
                    <img src="../../assets/svgIcon/manufacture.svg" alt="city" class="icon-grey4">&nbsp;&nbsp;
                    <div class="w-100">
                      <p class="text-start text-grey4 h-4px required">Manufacturer</p>
                      <!-- <input type="text" name="make" formControlName="make" placeholder="" class="form-control"> -->
                      <app-autocomplete class="w-100" (itemSelected)="onMakeSelection($event)" [items]="vehicleMakeList"
                        placeholder="Make" (onBlurEvent)="onMakeBlur($event)"
                        [userInput]="vehicleFormControl['make'].value"
                        [showError]="vehicleFormControl['make'].invalid && isFormSubmitted"></app-autocomplete>
                      <span class="text-danger"
                        *ngIf="vehicleFormControl['make'].invalid && isFormSubmitted">Manufacturer is required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mt-5">
                <div class="popupdivblock">
                  <div class="d-flex">
                    <img src="../../assets/svgIcon/model.svg" alt="city" class="icon-grey4"
                      class="icon-grey4">&nbsp;&nbsp;
                    <div class="w-100">
                      <p class="text-start text-grey4 h-4px required">Model</p>
                      <!-- <input type="text" name="model" formControlName="model" placeholder="" class="form-control"> -->
                      <app-autocomplete class="w-100" (itemSelected)="onModelSelection($event)"
                        [items]="vehicleModelList" placeholder="Model" (onBlurEvent)="onModelBlur($event)"
                        [userInput]="vehicleFormControl['model'].value"
                        [showError]="vehicleFormControl['model'].invalid && isFormSubmitted"></app-autocomplete>
                      <span class="text-danger" *ngIf="vehicleFormControl['model'].invalid && isFormSubmitted">Model is
                        required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mt-5">
                <div class="popupdivblock">
                  <div class="d-flex">
                    <img src="../../assets/svgIcon/fuel.svg" alt="city" class="icon-grey4">&nbsp;&nbsp;
                    <div class="w-100">
                      <p class="text-start text-grey4 h-10px required">Fuel Type</p>
                      <select class="form-select form-select-custom" formControlName="fuelType">
                        <option *ngFor="let item of fuelType; let i = index;" value="{{item.name}}">{{item.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mt-5">
                <div class="popupdivblock">
                  <div class="d-flex">
                    <img src="../../assets/svgIcon/piston.svg" alt="city" class="icon-grey4">&nbsp;&nbsp;
                    <div class="w-100">
                      <p class="text-start text-grey4 h-10px required">Variant</p>
                      <!-- <select class="form-select form-select-custom" formControlName="variant" >
                        <option *ngFor="let item of vehicleVariantList; let i = index;" value="{{item}}">{{item}}</option>
                      </select> -->
                      <app-autocomplete class="w-100" (itemSelected)="onVariantSelection($event)"
                        [items]="vehicleVariantList" placeholder="Variant" (onBlurEvent)="onVariantBlur($event)"
                        [showError]="vehicleFormControl['variant'].invalid && isFormSubmitted"></app-autocomplete>
                      <span class="text-danger" *ngIf="vehicleFormControl['variant'].invalid && isFormSubmitted">Variant
                        is required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mt-5">
                <div class="popupdivblock">
                  <div class="d-flex">
                    <img src="../../assets/svgIcon/location.svg" alt="city" class="icon-grey4 w-28">&nbsp;&nbsp;
                    <div class="w-100">
                      <p class="text-start text-grey4 h-10px required">Pincode</p>
                      <app-autocomplete class="w-100" (itemSelected)="onPincodeSelection($event)"
                        [supportingText]="true" (onBlurEvent)="onPincodeBlur($event)" [items]="pincodeList"
                        placeholder="pincode" (onKeyUpEvent)="onPincodeChange($event)" (input)="onInputChange($event)"
                        [showError]="vehicleFormControl['pincode'].invalid && isFormSubmitted"></app-autocomplete>
                      <span class="text-main">Please provide pincode for more accurate quotations</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-12">
                <div class="button_divs">
                  <button class="con_btn" (click)="continuePolicyDetails()">{{langList.continue}}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 " *ngIf="verifyBikeDetailspolicy">
    <div class="popup2 col-md-8  col-xs-12 col-sm-8 col-11" style=" overflow-x: unset;">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">
          <h4 class="">Existing Policy Details
          </h4>
        </h4>
        <button type="button" class="close btn pull-right" aria-label="Close" (click)=" closeModal()"> </button>
      </div>
      <div class="modal-body heigth p-5 ">
        <div class="row">
          <form [formGroup]="insuranceForm" class="needs-validation" novalidate="" autocomplete="off">
            <div class="row">
              <ng-container *ngIf="policy">
                <div class="col-md-6 form-group text-left">
                  <label class="text-start required inline-block text-grey4">Policy's Expiry Status</label> <img
                    src="/assets/images/Vector4.png" alt="" (click)="openPolicyStatusModal()"
                    class="cursor-pointer ml-2">
                  <select class="form-select" formControlName="policyExpiryStatus" [(ngModel)]="policyExpiryStatus"
                    (change)="policyDetail($event)">
                    <option *ngFor="let item of policyExpiryStatusList; let i = index;" value="{{item.value}}">
                      {{item.text}}</option>
                  </select>
                </div>
                <ng-container *ngIf="showPreviousPolicyCompany">
                  <div class="col-md-6 form-group text-left">
                    <label class="text-start required text-grey4">Existing Policy Company</label>
                    <app-autocomplete class="w-100" [items]="insurancePartnersList"
                      placeholder="Type and search Current policy company" (itemSelected)="onPrevCompSelection($event)"
                      (onBlurEvent)="onPrevCompBlur($event)" [userInput]="previousPolicyCompany"
                      [className]="'py-6_5 shadow_'" [showError]="showPreviousPolicyCompanyError"></app-autocomplete>
                    <span class="text-danger" *ngIf="showPreviousPolicyCompanyError">Current policy company is
                      required.</span>
                  </div>
                  <div class="col-md-6 form-group text-left">
                    <label class="text-start required text-grey4">Existing Policy Expiry Date</label>
                    <input type="date" name="previousPolicyExpiryDate" formControlName="previousPolicyExpiryDate"
                      [max]="maxPolicyExpiryDate" [min]="minPolicyExpiryDate" class="form-control py-6_5 shadow_"
                      onkeydown="return false" (change)="onPreviousPolicyExpiryDateChange()">
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="Type">
                <div class="col-md-6 form-group text-left ">
                  <label class="text-start required text-grey4">Policy Type</label>
                  <select class="form-select" formControlName="policyType" [(ngModel)]="policyType"
                    (change)="coverageDetail($event)">
                    <option *ngFor="let item of policyTypeList; let i = index;" value="{{item.value}}">{{item.text}}
                    </option>
                  </select>
                </div>
              </ng-container>
              <ng-container *ngIf="preClaim">
                <div class="col-md-6 form-group text-left ">
                  <label class="text-start required text-grey4">Did you make a claim in your existing policy?</label>
                  <select class="form-select" formControlName="policyClaimed" [(ngModel)]="policyClaimed"
                    (change)="preClaimDetail($event)">
                    <option *ngFor="let item of policyClaimedList; let i = index;" value="{{item.value}}">
                      {{item.text}}</option>
                  </select>
                </div>
                <div class="col-md-6 form-group text-left" *ngIf="claimPercentage || (vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes' && vehicleFormControl['isNCBProtectionOptedInExistingPolicy'].value == 'Yes')">
                  <label class="text-start required text-grey4">Existing NCB (No Claim Bonus)<img src="/assets/images/Vector4.png" alt=""
                      (click)="openNCBModal()" class="cursor-pointer ml-1"></label>
                  <select class="form-select" formControlName="policyClaimedNCB" [(ngModel)]="policyClaimedNCB"
                    (change)="nsbValDetail($event)">
                    <option *ngFor="let item of policyNcbClaimedList; let i = index;" value="{{item.value}}">
                      {{item.text}}</option>
                  </select>
                </div>
                <div class="col-md-6 form-group text-left" *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                  <label class="text-start required text-grey4">Number of claim(s) made in existing policy</label>
                  <select class="form-select" formControlName="claimsMadeInPreviousPolicyNo" [(ngModel)]="claimsMadeInPreviousPolicyNo"
                    (change)="claimCountChanged($event)">
                    <option *ngFor="let item of policyNcbClaimMadeList; let i = index;" value="{{item.value}}">
                    {{item.text}}</option>
                  </select>
                </div>
                  <div class="col-md-6 mt-4" *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <label class="text-start required text-grey4">Claimed Amount</label>
                    <span class="input-icon">
                      <input type="text" class="form-control py-6_5 shadow_" formControlName="claimsMadeInPreviousPolicyAmount" (keypress)="numberOnly($event)" (keyup)="onClaimedAmountCahnge()">
                      <i class="ruppes-icon">₹</i> 
                    </span>  
                  </div>
                  <div class="col-md-6 mt-4" *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <label class="text-start required text-grey4">NCB protection opted in existing policy</label>
                    <select class="form-select" formControlName="isNCBProtectionOptedInExistingPolicy" (change)="onNCBProtectionOptedInExistingPolicy()">
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                  </div>
              </ng-container>
            </div>
          </form>
        </div>

      </div>
      <div class="row ">
        <div>
          <button class="con_btn mr-3 mb-3" (click)="VerifyRegistrationNumber()" [disabled]="isVehicleFormInvalid()">
            {{isDontKnowVehicleNumber ? langList.continue : 'View Quotes'}}
          </button>
        </div>

      </div>
    </div>
  </div>

  <div [ngClass]="{'d-none': !IsQuotes}">
    <vehiclequotes (dataEvent)="ShowQuoteDetials($event)" (gotoEvent)="goTo($event)"></vehiclequotes>
  </div>
  <div [ngClass]="{'d-none': !IsQuoteSelected}">
    <vehicle-quote-details (dataEvent)="ShowOwnerDetailsPage($event)"></vehicle-quote-details>
  </div>
  <div [ngClass]="{'d-none': !IsProposer}">
    <vehicle-owner-details [ownerDetails]="vehicleRegisterData" (dataEvent)="ShowPolicyDetailsPage($event)"
      (gotoEvent)="goTo($event)"></vehicle-owner-details>
  </div>
  <div [ngClass]="{'d-none': !IsPolicyDetail}">
    <vehicle-policy-details (dataEvent)="CreatePolicy()"></vehicle-policy-details>
  </div>

  <div [ngClass]="!isUserLogged ? '' : 'user-dashboard'" *ngIf="IsDontKnowBike">
    <app-dont-know-bike-number [isBike]="true" [newVehicle]="newVehicle"
      (dataEvent)="DontKnowBike($event)"></app-dont-know-bike-number>
  </div>
  <div *ngIf="isRoyalSundaram">
    <app-royal-sundaram-payment [royalDetails]="royalDetails"></app-royal-sundaram-payment>
  </div>
  <div *ngIf="isFutureGeneral">
    <app-future-general-payment [futureDetails]="futureDetails"></app-future-general-payment>
  </div>
  <div *ngIf="isShriramGeneral">
    <app-shriramgeneral-payment [ShriramDetails]="ShriramDetails"></app-shriramgeneral-payment>
  </div>

  <div *ngIf="isRelianceGeneral">
    <app-reliance-general-payment [RelianceDetails]="RelianceDetails"></app-reliance-general-payment>
  </div>

  <div *ngIf="isPaymentLink">
    <app-payment-progress [paymentObject]="paymentObject"></app-payment-progress>
  </div>


  <div class="overlay" *ngIf="showNCBTooltip">
    <div class="popup2 position-relative col-md-4 col-11">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>No Claim Bonus (NCB)</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeNCBModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-left">
          <p class="text-grey4">No Claim Bonus is a discount offered to policyholders on their insurance premiums when
            they haven't made any claims during the policy term. The longer you go without a claim, the higher your NCB,
            saving you money on your insurance costs.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showPolicyStatusTooltip">
    <div class="popup2 position-relative col-md-4 col-11">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Policy Expiry Status</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closePolicyStatusModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <span class="font-17-13 text-grey5 d-block mt-2">Not Expired</span>
        <span class="font-15-12 text-grey4">Hooray! you will get your policy instantly</span>
        <span class="font-17-13 text-grey5 d-block mt-2">Expired Within 90 Days</span>
        <span class="font-15-12 text-grey4">Pre-inspection required</span>
        <span class="font-17-13 text-grey5 d-block mt-2">Expired More than 90 Days</span>
        <span class="font-15-12 text-grey4">Pre-inspection required, NCB discound not applicable</span>
      </div>
    </div>
  </div>

  <div class="overlay" *ngIf="showKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">
        <iframe id="ifr2" [src]="kycUrl" target="_parent" frameborder='0' class="iframeclass"></iframe>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showhttpKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closehttpKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">

        <span class="font-17-13 text-grey5 d-block mt-2">Please use below link to update your KYC details</span>
        <br />
        <a id="failedKYCLink" target="_blank" (click)="closehttpKYCModal()" [href]="kycUrl">Click here to update your
          KYC details</a>
        <br />
        <span class="font-17-13 text-grey5 d-block mt-2">Once your KYC is done, return to this window and click on the
          pay button</span>

      </div>
    </div>
  </div>

  <div class="overlay" *ngIf="showIsBreakIn">
    <div class="popup2 position-relative col-md-4 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Vehicle inspection required</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative text-center">
        <span class="font-17-13 text-grey5 d-block mt-2">You will need to upload a short video of your vehicle. It takes only a few minutes. Do you want to Continue?</span>
        <div class="row mt-8 justify-content-around">
            <button type="button" class="btn btn-primary col-auto" aria-label="Close" (click)="CreatePolicy()">
                Yes Continue
              </button>
              <button type="button" class="btn btn-secondary col-auto" aria-label="Close" (click)="closeBreakInModal()">
                No
              </button>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showIsBreakInTriggered">
    <div class="popup2 position-relative col-md-8 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Inspection Request Submitted</h4>
        </div>
        <!-- <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button> -->
      </div>
      <div class="modal-body position-relative">
        <div class="row">
            <div class="col-md-4 d-flex align-item-center">
                <img src="../../../assets/svgIcon/list_clipboard.svg" class="img-fluid">
            </div>
            <div class="col-md-8 d-flex align-item-center">
                <div class="row">
                    <div class="col-md-12 text-main font-15-12">
                        Please complete the inspection process by clicking on the link  you received in your phone.
                        After completing this process, it will take approximate 48 hours to verify your vehicle’s inspection details.
                        Please use {{this.insuranceForm.get('quoteId')?.value??""}} - your reference ID for the update. However, we will notify you once inspection is verified.
                    </div>
                    <div class="col-md-12 mt-5 text-main font-15-12">
                        <span class="d-block">You can continue your policy journey with the following steps:</span>
                        <span class="d-block"><span class="text-main2">Step 1: </span>Login with your credentials.</span>
                        <span class="d-block"><span class="text-main2">Step 2: </span>Go to Dashboard.</span>
                        <span class="d-block"><span class="text-main2">Step 3: </span>Click on Incomplete tab available in Policies menu option.</span>
                        <span class="d-block"><span class="text-main2">Step 4: </span>Select your policy and click on Continue button.</span>
                        <span class="d-block"><span class="text-main2">Step 5: </span>Go to payment, and complete the transaction.</span>
                        <span class="d-block"><span class="text-main2">Step 6: </span>Congratulations! Policy is issued for your vehicle.</span>
                    </div>
                    <div class="col-12">
                        <button type="button" class="btn btn-primary col-md-6" aria-label="Close" (click)="goToPolicyDetails()">
                            Ok
                          </button>
                    </div>
                </div>    
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="banner">
  <div class="  mt-5 mb-5 ">
    <div class="bannerDIv">

      <div><img src="./assets/images/newicons/b11.png"></div>

      <div><img src="./assets/images/newicons/b22.png"></div>

      <div><img src="./assets/images/newicons/b33.png"></div>

      <div><img src="./assets/images/newicons/b44.png"></div>

    </div>
  </div>

</div>


<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 class="whatinsh1">What is Bike Insurance?
        </h2>
        <span>
          <p style="padding-top: 15px;" class="insText">A bike insurance policy offers comprehensive protection and
            coverage for your bike. It covers repair costs for accidental damages and provides financial security
            against theft, natural disasters, vandalism, and other unforeseen incidents. With optional add-ons for
            enhanced coverage, such as engine protection and roadside assistance, you can tailor your policy to your
            specific needs. Secure your ride with a bike insurance policy and enjoy peace of mind on every journey.</p>

        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;margin-bottom: 20px;">
          <a class="earnButton">Know More About Insurance</a>
        </div> -->
      </div>
      <div class="col-md-5 whatimage">
        <img src="./assets/images/newicons/bike.svg" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="whyins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5 whyimage">
        <img src="./assets/images/newicons/whybike.svg" class="imgresponsive">
      </div>
      <div class="col-md-7">
        <h2 class="whyinsh1">
          Everything about Bike Insurance</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Bike insurance provides coverage for accidental damages, theft, natural disasters, and vandalism,
            including repair costs. <br>
            2. It also offers protection against legal and financial responsibilities for injuries or property damage
            caused to others.<br>
            3. Covers personal accidents, offering compensation for injuries or death resulting from bike accidents.<br>
            4. Allows for policy customization with various plans and add-ons to match individual needs and preferences.
          </p>
        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;">
          <a class="earnButton">Know more about it</a>
        </div> -->

      </div>

    </div>
  </div>
</div>


<div class="click4bima">
  <div class="  mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12">
        <div class="">
          <h1 class="whyinsh1" style="text-align: center !important; color: #fff !important;">Types of Two-Wheeler
            Insurance</h1>
        </div>
        <div class="tableClass" [align]="'center'">
          <table>
            <thead>
              <tr>
                <th class="key-features">Key Features</th>
                <th>Comprehensive</th>
                <th>Third-Party(TP)</th>
                <th>Own Damage(OD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="leftHead">Description</td>
                <td>A comprehensive bike insurance policy covers your own motorcycle or scooter and third-party
                  liabilities. It ensures wide-ranging protection for both your vehicle and legal responsibilities.</td>
                <td>A third-party bike insurance policy covers the cost of damages or injuries you cause to someone
                  else's property or person while riding your bike. It doesn't cover any damages to your own bike.</td>
                <td>An own damage bike insurance policy covers repair or replacement costs if your bike gets damaged. It
                  ensures financial protection for your vehicle against unforeseen damages.</td>
              </tr>
              <tr>
                <td class="leftHead">Coverage</td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Third-Party Liabilities</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Third-Party Liabilities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Add-ons Availability</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
                <td>Not Available</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Premium</td>
                <td>High</td>
                <td>Low</td>
                <td>Moderate</td>
              </tr>
              <tr>
                <td class="leftHead">Pros</td>
                <td>
                  <ul>
                    <li>Wide Coverage</li>
                    <li>Additional Benefits with Add-ons</li>
                    <li>Financial Security for Own and Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Financial Security for Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>Lower Premium</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Covers Accidental Damage</li>
                    <li>Easy Claim Settlement</li>
                    <li>Financial Security for Own Vehicle Damage</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="leftHead">Cons</td>
                <td>
                  <ul>
                    <li>Higher Premiums</li>
                    <li>Depreciation Deduction</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Limited Coverage</li>
                    <li>No Financial Protection for Own Damage</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Depreciation Deduction</li>
                    <li>No PA Cover</li>
                    <li>No Financial Protection for Third-Party Damage</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div style="text-align: center;margin-top: 50px;">
    <h2 style="font-size: 40px;">Add-On We Provide for Two-Wheelers</h2>
  </div>
  <div class="desktop-view">
    <div class="addonDIvflax">
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 1 }" (click)="getValue(1)">
      <div>
        <img src="./assets/images/newicons/b1.svg">
      </div>
      <div>
        Zero/Nil<br>
        Depreciation
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 2 }" (click)="getValue(2)">
      <div>
        <img src="./assets/images/newicons/b2.svg">
      </div>
      <div>
        Consumable<br>
        Cover
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 3 }" (click)="getValue(3)">
      <div>
        <img src="./assets/images/newicons/b3.svg">
      </div>
      <div>
        Return to<br>
        Invoice
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 4 }" (click)="getValue(4)">
      <div>
        <img src="./assets/images/newicons/b4.svg">
      </div>
      <div>
        Road Side<br>
        Assistance
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 5 }" (click)="getValue(5)">
      <div>
        <img src="./assets/images/newicons/b5.svg">
      </div>
      <div>
        Engine & Gear<br>
        Box Protect
      </div>
    </div>
    <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 6 }" (click)="getValue(6)">
      <div>
        <img src="./assets/images/newicons/b6.svg">
      </div>
      <div>
        Daily<br>
        Allowance
      </div>
    </div>
  </div>
</div>
  <div class="row addonDIvs mobile-view">
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 1 }" (click)="getValue(1)">
      <div>
        <img src="./assets/images/newicons/b1.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Zero/Nil<br>
        Depreciation
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 2 }" (click)="getValue(2)">
      <div>
        <img src="./assets/images/newicons/b2.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Consumable<br>
        Cover
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 3 }" (click)="getValue(3)">
      <div>
        <img src="./assets/images/newicons/b3.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Return to<br>
        Invoice
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 4 }" (click)="getValue(4)">
      <div>
        <img src="./assets/images/newicons/b4.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Road Side<br>
        Assistance
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 5 }" (click)="getValue(5)">
      <div>
        <img src="./assets/images/newicons/b5.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Engine & Gear<br>
        Box Protect
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 6 }" (click)="getValue(6)">
      <div>
        <img src="./assets/images/newicons/b6.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Daily<br>
        Allowance
      </div>
    </div>


  </div>
  <div
    style="text-align: center;margin-top: 50px;width: 80%; margin-left: 10%; background-color: var(--grey1); padding:15px 50px 15px 50px; border-radius: 12px;     font-size: large;">
    {{text}}
  </div>
</div>

<div class="become">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12 ">
        <div class="">
          <h1 style="font-size: 40px;;    text-align: center;">How to choose best two-wheeler Insurance?</h1>
        </div>
        <div style="margin-top: 35px;">
          <img src="./assets/images/newicons/group.svg" style="width: 100%;">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 class="whatinsh1">What is Covered ?</h2>
        <span>
          <p style="padding-top: 15px;" class="insText">
            1. Coverage for damage to your two-wheeler due to accidents, including collisions, overturning, and other
            mishaps.<br>
            2. Compensation in case of theft or total loss of the vehicle.<br>
            3. Covers damages or injuries caused to third parties, including property damage and bodily injury.<br>
            4. Provides financial protection for the owner-rider in case of accidental death or disability.<br>
            5. Discounts on premium for claim-free years, which can be accumulated over time.

          </p>

        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;margin-bottom: 20px;">
          <a class="earnButton">Know More About Insurance</a>
        </div> -->
      </div>
      <div class="col-md-5 whatimage" >
        <img src="./assets/images/newicons/coverbike.svg" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="whyins1">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5 whyimage" >
        <img src="./assets/images/newicons/notcoverbike.svg" class="imgresponsive">
      </div>
      <div class="col-md-7">
        <h2 class="whyinsh1">
          What is Not Covered?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Damage due to regular use, aging, and wear and tear is not covered.<br>
            2. Issues arising from mechanical or electrical failures that are not related to accidents.<br>
            3. Damage caused intentionally or due to reckless behavior is not covered.<br>
            4. Accidents occurring while the rider is under the influence of alcohol or drugs are generally
            excluded.<br>
            5. Damage to or loss of a vehicle that is not properly registered.
          </p>
        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;">
          <a class="earnButton">Know more about it</a>
        </div> -->

      </div>

    </div>
  </div>
</div>

<div class="faq">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <h2>FAQs</h2>
    </div>
    <div class="row" style="padding-top: 15px;">
      <div class="col-md-6">

        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(0)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }"
                aria-hidden="true"></i>
              1. What does bike insurance cover?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Bike insurance covers damages to your
              bike from accidents, theft, fire, natural and man-made calamities, and third-party liabilities. It may
              also include personal accident cover and various add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(1)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"
                aria-hidden="true"></i>
              2. What is the difference between third-party and comprehensive bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">Third-party insurance covers damages and
              liabilities to third parties caused by your bike. Comprehensive insurance covers both third-party
              liabilities and damages to your own bike.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(3)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"
                aria-hidden="true"></i>
              3. What are add-ons in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> Add-ons are additional coverages you
              can purchase to enhance your policy, such as zero depreciation cover, engine protection, roadside
              assistance, and more.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(4)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"
                aria-hidden="true"></i>
              4. How can I file a claim?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">To file a claim, notify your insurer
              immediately after the incident, provide necessary documents (e.g., FIR, repair bills), and follow the
              insurer’s claim procedure, which may include an inspection of the damage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(5)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"
                aria-hidden="true"></i>
              5. Can I renew my bike insurance online?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">Yes, you can renew your policy by
              visiting
              our website, paying the premium, and updating all necessary information.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(6)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"
                aria-hidden="true"></i>
              6. How is the premium for bike insurance calculated?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }"> Premiums are calculated based on
              factors such as the bike's make and model, age, RTO, coverage type, and add-ons.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(7)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"
                aria-hidden="true"></i>
              7. What is a No Claim Bonus (NCB)?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">NCB is a discount on your premium for
              each claim-free year. It rewards safe riding and reduces your insurance cost upon renewal.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(8)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"
                aria-hidden="true"></i>
              8. What documents are required for buying bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> Common documents include the bike's
              registration certificate (RC), driving license, and identity proof. Some insurers may also require a
              recent photograph of the bike.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(9)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"
                aria-hidden="true"></i>
              9. How can I renew my bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">Bike insurance can be renewed online
              through the insurer’s website, mobile app, or by visiting an insurance office. Ensure to renew it before
              the expiry date to avoid lapses in coverage.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(10)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"
                aria-hidden="true"></i>
              10. What are the exclusions in bike insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">Common exclusions include normal wear
              and tear, mechanical breakdowns, damage caused under the influence of alcohol or drugs, and damage due to
              illegal activities.</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid background">
  <div class="container partners_section">
    <div class="card-container ">
      <div class="justify-content">
        <div class="text " style="margin-top: 3%;">
          <!-- <h1 class="heading">Our <span style="color:var(--main2)">Partners</span></h1> -->
          <h1 class="heading">Our Two-Wheeler Insurance Partners</h1>
          <span class="font-body">We work together to provide you the best services.</span>
        </div>

        <div class="container ">
          <div style="text-align: center;">
            <div class="image-row">
              <img *ngFor="let img of images" [src]="img" alt="">
            </div>
            <!-- <div style="width: 100%; text-align: center;margin-bottom: 20px;">
              <a *ngIf="!showAllImages" class="butn" (click)="showAllImages = true">Show More</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>