import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import swal from 'sweetalert2';
import { BusinessService } from '../Services/business.service';
import { UserService } from '../Services/user.service';
import { PincodeMaster } from '../Models/signUp.component.model';
import { LookUpService } from './../Services/lookup.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  userForm: FormGroup;
  businessList: any = [];
  userList: any = [];
  pincodeMaster = new PincodeMaster();
  constructor(private fb: FormBuilder, private userservice: UserService, private businessService: BusinessService,
    private lookUpService: LookUpService,) {

    this.userForm = this.fb.group({
      businessId: [''],
      uniqueUserId: [''],
      firstName: [''],
      lastName: [''],
      userEmail: ['', [Validators.pattern(/\S+@\S+\.\S+/)]],
      countryCode: [''],
      phoneNumber: ['', [Validators.required]],
      pancard:[''],
      streetAddress:[''],
      pincodeId:[''],
      pincode:['']
    });

  }
  async ngOnInit() {
    await this.getBusinessInfo();
    await this.AcquireBusinessUsers();
  }

  async getBusinessInfo() {
    let request = {};
    const res = await this.businessService.GetBusinessDetails(request);
    if (res != null && res.result) {
      this.businessList = res.result;
    }
  }

  async AcquireBusinessUsers() {
    const res = await this.businessService.AcquireBusinessUsers();
    if (res != null && res.result) {
      this.userList = res.result;
    }
  }

  clearData() {
    this.userForm.reset();
  }

  onCountryChangeNew(e: any) {
    this.userForm.get('phoneNumber')?.setValue("");
  }

  async pincodeUserChangeEvent(pincode: any) {
    this.pincodeMaster = new PincodeMaster();
    const res = await this.lookUpService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.pincodeMaster = res.result[0];
      
    }
  }

  async save() {
    if (this.userForm.get('phoneNumber')?.value != null) {
      let phone = this.userForm.get('phoneNumber')?.value;
      this.userForm.get('countryCode')?.setValue(phone.dialCode);
      this.userForm.get('phoneNumber')?.setValue(phone.number.replace(phone.dialCode, ''));
    }
    if(this.pincodeMaster !=null){
      this.userForm.get('pincodeId')?.setValue(this.pincodeMaster?.id);
    }

    const response = await this.userservice.ManipulateUserInfo(this.userForm.value);
    if (response?.isSuccess) {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User Added',
        showConfirmButton: false,
        timer: 3000,
      });
      this.clearData();
      this.ngOnInit();
    }
  }

}
