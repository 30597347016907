import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-influencer-verfication',
  templateUrl: './influencer-verfication.component.html',
  styleUrls: ['./influencer-verfication.component.css']
})
export class InfluencerVerficationComponent {
  isBasicDetails:boolean=false;
  isContanctDetail:boolean=false;
  frontImage: string | ArrayBuffer | null = null;
  backImage: string | ArrayBuffer | null = null;
  isWhatsapp:boolean=true;
  Success:boolean=false;
  BasicDetails:FormGroup;
  Contact:FormGroup;
  constructor(private route:Router,fb:FormBuilder)
    {
      this.BasicDetails=fb.group({
      "aadhaarFrontUpload":["",[Validators.required]],
      "aadhaarBackUpload":["",[Validators.required]],
      "AdharNumber":["",[Validators.required]],
      "gender":[""],
      "pinCode":["",[Validators.required]],
      "city":[""],
      "state":[""],
      "dob":[""],
      });

      this.Contact=fb.group({
        "MobileNumber":["",[Validators.required]],
        "Email":["",[Validators.required]],
        "WhatsApp":[""],
        "Website":[""],
        "BusinessApp":[""],
        "Facebook":[""],
        "Instagram":[""],
        "LinkedIn":[""],
        "twitter":[""],
      });
    }
  
  

  onFrontSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.previewfrontImage(file);
    }
  }

  previewfrontImage(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.frontImage = e.target.result;
    };

    reader.readAsDataURL(file);
  }

  onBackSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.previewbackImage(file);
    }
  }

  previewbackImage(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.backImage = e.target.result;
    };

    reader.readAsDataURL(file);
  }
  page:string='basic';
  Pages(val:string)
  {
    this.page=val;
    if(this.page=='basic'){
      this.isBasicDetails=false;
      this.isContanctDetail=false;
    }
    if(this.page=='contact'){
      this.isBasicDetails=true;
      this.isContanctDetail=false;
    }
    
  }
  num:string;
  nubmer(val:string)
  {
  this.num=val;
 
  if(this.num=='yes'){
    this.isWhatsapp=true ;
  }
  if(this.num=='no'){
    this.isWhatsapp=false;
  }
  }
  back()
  {
    this.route.navigate(['/influencer-profile']);
  }
close(){
  this.Success=false;
}
success()
{
  this.isContanctDetail=true;
  this.Success=true;
}
}
