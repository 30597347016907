  <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="../../assets/images/Service 24_7-bro 1.svg"  alt="...">
            <h4 class="text-center">24 Hours Support</h4>
            <p class="font-body text-center">Reach us on our 24X7 :</p>
            <p class="font-body text-center">+91 9829082258</p>
          </div>
      <div class="carousel-item">
        <img src="../../assets/images/Insurance-cuate-2 1.svg"  alt="...">
        <h4 class="text-center">Best Prices Insurances</h4>
            <p class="font-body text-center">We understand that finding affordable insurance without compromising on quality can be a daunting task.</p>
            <p class="font-body text-center">+91 9829082258</p>
      </div>
      <div class="carousel-item">
        <img src="../../assets/images/Secure data-cuate 1.svg"  alt="...">
        <h4 class="text-center">100% Reliable</h4>
            <p class="font-body text-center">We at Click4bima portal take pride in being 100% reliable</p>
            <p class="font-body text-center">+91 9829082258</p>
      </div>
      <div class="carousel-item">
        <img src="../../assets/images/Online consulting-cuate-2 1.svg"  alt="...">
        <h4 class="text-center">Claim Made Easy!</h4>
            <p class="font-body text-center">Experience the convenience and reliability of our claim support services</p>
            <p class="font-body text-center">+91 9829082258</p>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  