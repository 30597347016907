import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { CommercialVehicleDeatils, LookUpViewModel, RTONamesViewModel, VehicleInfoModel } from 'src/app/Models/vehicle-component.model';
import { CommercialVehicleDetailsService } from 'src/app/Services/commercial-vehicle-details.service';
import { GeneralService } from 'src/app/Services/general.service';
import { InsuranceService } from 'src/app/Services/insurance.service';
import { LanguageLableService } from 'src/app/Services/language-lable.service';
import { LookUpService } from 'src/app/Services/lookup.service';
import { VehicleService } from 'src/app/Services/vehicle.service';
import { InsuranceBannerComponent } from 'src/app/home/insurance-banner/insurance-banner.component';
import { VehiclequotesComponent } from 'src/app/vehicleinsurance/vehiclequotes/vehiclequotes.component';

@Component({
  selector: 'app-commercial-vechicles',
  templateUrl: './commercial-vechicles.component.html',
  styleUrls: ['./commercial-vechicles.component.css']
})
export class CommercialVechiclesComponent {
  @ViewChild(InsuranceBannerComponent) InsuranceBannerComponent: InsuranceBannerComponent;

  formData: any;
  langList: any;
  selCity:any = true;
  mftList:any = false;
  modelList:any = false;
  variList:any = false;
  yearList:any = false;
  rtoList:any = false;
  fuelType:any = false;

  // tabsForm: FormGroup;
  //vehicleDetailsForm: FormGroup;
  vehicleInfoForm: FormGroup;
  contactUsForm:FormGroup;
  customerInfoForm:FormGroup;

  vehicleModel = new VehicleInfoModel();
  vehicleCompanyList: LookUpViewModel[] = [];
  vehicleModelList: LookUpViewModel[] = [];
  vehicleVariantList: LookUpViewModel[] = [];
  vehicleVariantDetailsList: any = [];
  vehicleFuelDetailsList: any = [];
  fuelTypeLists: LookUpViewModel[] = [{name:'Petrol',id:1},{name:'Diesel',id:2},{name:'Electric',id:3},{name:'CNG',id:4}];
  cityList: any[];//RTONamesViewModel[] = [];
  rtoNamesList: RTONamesViewModel[] = [];
  year = new Date().getFullYear();
  month = new Date().getMonth() + 1;
  years : any = [];
  registrationYear: any = []; 
  registrationMonthName: any = ['Jan','Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep', 'Oct', 'Nov','Dec'];
  registrationMonth: any = [];
  isnew:boolean= false;
  todayDate = new Date();
  pincodeList: AutoCompleteItem[] = [];
  CommVehicleWeightList:AutoCompleteItem[]=[{text:"0 Ton - 2 Ton",value:"0-2"},{text:"2 Ton - 2.5 Ton",value:"2-2.5"},{text:"2.5 Ton - 3.5 Ton",value:"2.5-3.5"},{text:"3.5 Ton - 12 Ton",value:"3.5-12"},{text:"12 Ton - 20 Ton",value:"12-20"},{text:"20 Ton - 40 Ton",value:"20-40"},{text:"More than 40 Ton",value:"41"}];
  constructor(private languageLableService: LanguageLableService,private router:Router,private fb: FormBuilder,private dataService: CommercialVehicleDetailsService,private lookUpService: LookUpService, private vehicleService: VehicleService, private generalService : GeneralService)
  {
    // this.tabsForm = this.fb.group({
    //   "rTO": ["", Validators.required],
    //   "make": ["", Validators.required],
    //   "model": ["", Validators.required],
    //   "variant": ["", Validators.required],
    //   "registrationYear": ["", Validators.required],
    //   "city": ["", Validators.required],
    //   "ncbprevious": ["0"],
    //   "fuelType": [""],
    //   "isNew":[""]
      
    // });

    this.customerInfoForm = this.fb.group({
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],
      "email": ["",[ Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "mobileNumber": ["", [Validators.required, Validators.pattern('[0-9]{10}')]],
      "title":['Mr']
    });

    // this.vehicleDetailsForm = this.fb.group({
    //   "vehicleDetails": this.fb.group({
    //     "model": [''],
    //     "make": [''],
    //     "registrationYear": ['', ''],
    //     "variant": [''],
    //     "city": ['', ''],
    //     "rTO": ['', ''],
    //     "fuelType": ['', ''],
    //     "isNew":['']
    //   }),
    //   'insuranceCompany': [3]
    // });
    this.contactUsForm = this.fb.group({
      "name": ['', [Validators.required]],
      "mobileNumber": ['', [Validators.required]],
      "interestedIn": ['', [Validators.required]],
      "email": ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      "description": ['', [Validators.required]],
    });

    this.getQuoteForm = this.fb.group({
      RegistrationNumber:[''],
      registrationDate: [''],
      isMockUp: [''],
      VehicleType: [3],
      proposerDetails: this.fb.group({
        firstName: [''],
        lastName:[''],
        email: [''],
        mobileNumber: [''],
        title: ['']
      }),
      nomineeDetails: this.fb.group({}),  // You might want to provide default values if needed
      vehicleDetails: this.fb.group({
        registrationNumber: [''],
        chasisNumber: [''],
        engineNumber: [''],
        make: [''],
        rto: [''],
        model: [''],
        variant: [''],
        registrationYear: [''],
        city: [''],
        ncbprevious: [''],
        fuelType: [''],
        isNew: [''],
        vehiclecategory: [''],
        engineCapacityAmount: [''],
        ncbCurrent: [''],
        policyTerm: [''],
        typeOfCover: [''],
        paPolicyTerm: ['']
      }),
      isAgent: [false],
      Filter: null  // You might want to provide default values if needed
    });
  }
  
  first:boolean=true;
Popup:boolean=false;
TabName:string="Manufacturer";
add:boolean=false;
tabs:string='';
isDisable:boolean=true;
type:string='';
modals:string[]=['ALTROZ','NEXON','Punch','Harrier','Safari','NEXON EV Max','TIAGO','TIGOR','HEXA',' INDICA V2','INDICA VISTA','INDIGO eCS','NANO','XENON','ZEST','SAFARI DICOR 2.2 VTT','BOLT','INDIGO MANZA','SPACIO','OTHERS'];
// fuelType:string[]=['Petrol','Diesel','CNG','External CNG Kit'];
// variantType:string[]=['XE Petrol (1199 cc)','XE Rhytm Petrol (1199 cc)','XT Petrol (1199 cc)','XZ Petrol (1199 cc)','XE Plus Petrol (1199 cc)','XE Plus Petrol (1199 cc)','XM Petrol (1199 cc)','XM Plus (S) (1199 cc)','XM Rhytm Petrol (1199 cc)','XM Rhytm Plus Style Petrol (1199 cc)','XM Style Petrol (1199 cc)','XMA Plus (S) (1199 cc)','XT Luxe Petrol (1199 cc)','XT Petrol Dark Edition (1199 cc) ','XT i-Turbo Petrol (1199 cc)','XTA Petrol (1199 cc)'];
selectYear=[2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010,2009,2008,2007,2006,2005,2004,2003];
Tab:string='';
// menufactures:any=[
//   {imgurl:'../../../assets/svgIcon/tata.svg',name:'Tata'},
// {imgurl:'../../../assets/svgIcon/mahindra.svg',name:'Mahindra'},
// {imgurl:'../../../assets/svgIcon/kia.svg',name:'KIA'},
// {imgurl:'../../../assets/svgIcon/toyota.svg',name:'Totota'},
// {imgurl:'../../../assets/svgIcon/hyundai.svg',name:'Hyundai'},
// {imgurl:'../../../assets/svgIcon/honda.svg',name:'Honda'},
// {imgurl:'../../../assets/svgIcon/maruti.svg',name:'Maruti'},
// {imgurl:'../../../assets/svgIcon/renault.svg',name:'Ranault'},
// {imgurl:'../../../assets/svgIcon/MG.svg',name:'MG'},
// {imgurl:'../../../assets/svgIcon/nissan.svg',name:'Nissan'},
// {imgurl:'../../../assets/svgIcon/tata.svg',name:'Sakoda'},
// {imgurl:'../../../assets/svgIcon/volk.svg',name:'VolksWagen'},];
cutomerDetail: boolean = false;
//customerInfoForm:FormGroup;
getQuoteForm:FormGroup;
//vehicleDetailsForm: FormGroup;
submitted: boolean=false;
  titleList: string[] = ["Mr","Mrs","Ms"];
  selectedValue: string | null;
  tabNumber:number=0;
  // year = new Date().getFullYear();
  // years : any = [];
  @Output() dataEvent = new EventEmitter<any>();
  @Output() dataEventProposerDetails = new EventEmitter<any>();


ngOnInit(): void {
  const d = new Date();
    let month = d.getMonth();
     
    if(month==0)
    { 
      this.years.push(d.getFullYear()-1);
      this.years.push(d.getFullYear());
    }
    else
    {
      this.years.push(d.getFullYear());
    }

    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }

  // this.formData = this.fb.group({
  //   expiredStatus: [''],
  //   existingPolicyComapany: [''],
  //   previousPolicyExpiryDate: [''],
  //   previousPolicyType: [''],
  //   isOwnerShipTransferred: [''],
  //   claimsMadeInPreviousPolicy: [''],
  //   ncbCurrent: [''],
  //   category: [''],
  //   GVW: [''],
  //   isNew: [false],
  //   permit: [''],
  //   registrationNumber: [''],
  //   vehicleType: [''],
  //   manufacturer:[''],
  //   model:[],
  //   variant:[],
  //   fuelType:[],
  //   registrationYear:[]
  // });


  this.formData = this.fb.group({
    "rto": ["", Validators.required],
    "make": ["", Validators.required],
    "model": ["", Validators.required],
    "variant": ["", Validators.required],
    "registrationYear": ["", Validators.required],
    "registrationMonth": ["", Validators.required],    
    "city": ["", Validators.required],
    "registrationNumber": [""],
    "chasisNumber": [""],
    "engineNumber": [""],
    "registrationDate": [""],
    "firstName": [""],
    "lastName": [""],
    "vehiclecategory":[""],
    "isPreviousPolicyHolder":[""],
    "previousPolicyType":[""],
    "claimsMadeInPreviousPolicy":[""],
    "claimsMadeInPreviousPolicyNo":[""],
    "ncbprevious":[""],
    "typeOfCover":[""],
    "previousPolicyExpiryDate":[""],
    "vehicleIdv":[""],
    "previousPolicyCompany":[""],
    "previousPolicyNumber":[""],
    "ncbCurrent":["0"],
    "fuelType":[""],
    "engineCapacityAmount" :[""],
    "PACover":["Yes"],
    "RegisteredInTheNameOf":["Individual"],
    "isValidDrivingLicenseAvailable" :["Yes"],
    "isFinanced":["No"],
    "financedValue":[""],
    "financierName" :[""],
    "isNew":[false],
    "category":[''],
    "gcVehicle":[''],
    "pcVehicle":[''],
    "purpose":[''],
    "seatingCap":[''],
    "miscVehicle":[''],
    "gVW":['0-2'],
    "permit":[''],
    "policyExpiryStatus":[""],
    "isOwnerShipTransferred":[""],
    "policyClaimed": [''],
    "policyClaimedNCB":[''],
    "pincode":"" 
  });



  
  const savedFormData = this.dataService.getFormData();
  debugger;
  if (savedFormData) {
    this.formData.patchValue(savedFormData);
    this.isnew = savedFormData.isNew;
  }
  this.getPolicyCityList("");
}

setFormData(data:any){
  if (data) {
    this.formData.patchValue(data);
    this.isnew = data.isNew;
    // if(this.formData?.get('category')?.value == 'GCV' || this.formData?.get('category')?.value == 'MISC'){
    //   this.formData.get('gVW')?.addValidators(Validators.required);
    //   this.formData.get('gVW')?.updateValueAndValidity();
    // }
    // else
    // {
    //   this.formData.get('gVW')?.clearValidators();
    //   this.formData.get('gVW')?.updateValueAndValidity();
    // }
  }
}

numberOnly(event:any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode >= 48 && charCode <= 57) {
    return true;
  }
  return false;
}

alphabetOnly(event:any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
    return true;
  }
  return false;
}


nextTab(tabName:string):void{
 this.tabs=tabName;
}

get customerInfoFormControl() {
  return this.customerInfoForm.controls;
}

get formDataControl() {
  return this.formData.controls;
}

selectManufacturer(manufacturerName: string): void {
  // Update the selected manufacturer variable
  this.formData.get('manufacturer').setValue(manufacturerName);
  this.nextTab('Model');
}
selectModel(tabName:string):void{
  this.tabs=tabName;
  this.formData.get('model').setValue(tabName);
  this.nextTab('Variant');
 }

selectVarient(tabName:string):void{
  this.tabs=tabName;
  this.formData.get('variant').setValue(tabName);
  this.nextTab('Fuel Type');
 }

 selectFuelType(tabName:string):void{
  this.tabs=tabName;
  this.formData.get('fuelType').setValue(tabName);
  this.nextTab('Year');
 }

 selectYears(tabName:string):void{
  this.tabs=tabName;
  this.formData.get('registrationYear').setValue(tabName);
 }

Types(ty:string)
{
  this.type=ty;
this.isDisable=false;
}

close()
{
  this.Popup=false;
}

async customerInfo() {
  if(this.generalService.getUniqueuserid() != ""){
    this.submitForm();
  }
  else
  {
    this.cutomerDetail=true;
  }    
}

closeModal() {
  this.cutomerDetail=false;
}

async submitForm()
{
 ;
  // const formData = new FormData();
  // formData.append('vehicleDetails', JSON.stringify(this.formData.value));
  // formData.append('proposerDetails', JSON.stringify(this.customerInfoForm.value));
  let month = (this.registrationMonthName.indexOf(this.formData.value.registrationMonth) + 1).toString();
  month = month.length == 1 ? "0"+ month : month;
  const d = this.todayDate;
  let dayNumberInt = d.getDate();
  let dayNumber =  dayNumberInt< 10 ? "0" + dayNumberInt.toString() : dayNumberInt.toString();

  if(this.isnew == true){
    const d = this.todayDate;
    if(this.registrationMonthName.indexOf(this.formData.value.registrationMonth) == d.getMonth()){
      
    }
    else{
      dayNumber = new Date(d.getFullYear(), d.getMonth()+1, 0).getDay().toString();
    }
  }      
  
  let lastDayOfMonth = new Date(this.formData.value.registrationYear, month , 0).getDate();
  if(lastDayOfMonth < parseInt(dayNumber)){
    dayNumber = lastDayOfMonth.toString();
  }

  this.formData.get('registrationDate')?.setValue(this.formData.value.registrationYear +'-'+ month  + "-"+dayNumber);
debugger;
  if(this.formData.value.rto.indexOf("-") < 0){
    if(this.formData.value.rto.length == 4)
      {
        this.formData.get('rto')?.setValue(this.formData.value.rto.substr(0, 2) + "-" + this.formData.value.rto.substr(2, 2));
      }
      else if (this.formData.value.rto.length == 3)
      {
        this.formData.get('rto')?.setValue(this.formData.value.rto.substr(0, 2) + "-" + this.formData.value.rto.substr(2, 1));
      }          
  }
  else
  {
    this.formData.get('rto')?.setValue(this.formData.value.rto);
  }

  this.getQuoteForm = this.fb.group({
    RegistrationNumber:this.formData.value.registrationNumber,
    registrationDate: this.formData.get('registrationDate')?.value,
    isMockUp: [false],
    VehicleType: 3,
    proposerDetails: this.customerInfoForm.value,
    nomineeDetails: this.fb.group({}),  // You might want to provide default values if needed
    vehicleDetails: this.formData.value,
    isAgent: [false]
    });
  // Redirecting
  //this.VehiclequotesComponent.GetQuoteInfo(this.getQuoteForm.value);
  this.dataEvent.emit(this.getQuoteForm.value);
}

async GetQuoteInfo() {
  ;
  this.submitted=true;
  if(this.customerInfoForm.valid)
  {      
    let leadDetails ={
      name : this.customerInfoForm.value.firstName + " " + this.customerInfoForm.value.lastName,
      email: this.customerInfoForm.value.email,
      mobileNumber:this.customerInfoForm.value.mobileNumber
    };
    const res = await this.lookUpService.SaveLeadsDetail(leadDetails);    

    // this.getQuoteForm = this.fb.group({
    // RegistrationNumber:this.formData.value.registrationNumber,
    // registrationDate: this.formData.value.registrationYear,
    // isMockUp: [false],
    // VehicleType: 3,
    // proposerDetails: this.customerInfoForm.value,
    // nomineeDetails: this.fb.group({}),  // You might want to provide default values if needed
    // vehicleDetails: this.formData.value,
    // isAgent: [false]
    // });

    // ;

    // this.dataService.setFormDatas(this.getQuoteForm.value);

    // // Redirecting
    // this.router.navigate(['/CommercialPlanList']);
    this.submitForm();
  }   
}

changeTabs(event: any, tabNumber: any) {
  this.tabNumber=tabNumber;
  console.log(this.tabNumber);
  if(tabNumber == 1){
    this.selCity = true;
    this.mftList = false;
    this.modelList = false;
    this.variList = false;
    this.yearList = false;
    this.rtoList = false;
    this.fuelType = false;
  }
  else if(tabNumber == 2){
    this.selCity = false;
    this.mftList = true;
    this.modelList = false;
    this.variList = false;
    this.yearList = false;
    this.rtoList = false;
    this.fuelType = false;
  }
  else if(tabNumber == 3){
    this.selCity = false;
    this.mftList = false;
    this.modelList = true;
    this.variList = false;
    this.yearList = false;
    this.rtoList = false;
    this.fuelType = false;
  }
  else if(tabNumber == 4){
    this.selCity = false;
    this.mftList = false;
    this.modelList = false;
    this.variList = true;
    this.yearList = false;
    this.rtoList = false;
    this.fuelType = false;
  }
  else if(tabNumber == 5){
    this.selCity = false;
    this.mftList = false;
    this.modelList = false;
    this.variList = false;
    this.yearList = false;
    this.rtoList = false;
    this.fuelType = true;
  }
  else if(tabNumber == 6){
    this.selCity = false;
    this.mftList = false;
    this.modelList = false;
    this.variList = false;
    this.yearList = true;
    this.rtoList = false;
    this.fuelType = false;
  }
  else{
    this.selCity = true;
    this.mftList = false;
    this.modelList = false;
    this.variList = false;
    this.yearList = false;
    this.rtoList = false;
    this.fuelType = false;
  }
  // var tabs = document.getElementsByClassName("selected-box");
  
  // for (var i = 0; i < tabs.length; i++) {
  //   if(tabNumber > i+1)
  //   {
  //     tabs[i].classList.add("active");
  //   }
  //   else
  //   {
  //     tabs[i].classList.remove("active");
  //   }
    
  // }
  
}

async onBlurCity(cityName: string) {
  if (cityName.length > 1) {
    this.cityList = [];
    this.rtoNamesList = [];
    //this.vehicleInfoForm.controls['rTO'].disable();
    if (cityName == undefined)
      cityName = "";
    const res = await this.vehicleService.GetPolicyCityRTOs(cityName);
    if (res != null && res.data) {
      this.cityList = res.data;
    }

  }
 else if (!cityName)
    await this.getPolicyCityList(cityName)
}

async getPolicyCityList(cityName?: string) {
  this.cityList = [];
  this.rtoNamesList = [];
  //this.vehicleInfoForm.controls['rTO'].disable();
  if (cityName == undefined)
    cityName = "";
  //const res = await this.vehicleService.GetPolicyCities(cityName);
  const res = await this.vehicleService.GetPolicyCityRTOs(cityName);    
  if (res != null && res.data) {
    ;
    this.cityList = res.data;

  }
}

async changeYears(record: any) {
  this.formData.get('registrationYear')?.setValue(record.toString());
  //this.tabsForm.get('registrationYear')?.setValue(record.toString());
  this.tabNumber=7;
  this.getFinancialMonths(record.toString());
}

async changemonth(record: any) {
  this.formData.get('registrationMonth')?.setValue(record.toString());
}

async getVehicleReg(record: any) {
  this.variList = !this.variList;
  // if(this.isBike == true){
  //   this.yearList = !this.yearList;
  // }else{
  //   this.fuelType = !this.fuelType;
  // }
  this.fuelType = !this.fuelType;
  //this.vehicleDetailsForm.value.vehicleDetails.variant = record;
  //this.tabsForm.get('registrationYear')?.setValue("");
  this.formData.get('registrationYear')?.setValue("");
  // if(this.isnew){
  //   this.formData.get('registrationYear')?.setValue(this.year.toString());
  //   this.getFinancialMonths(this.year.toString());
  //   this.formData.get('registrationMonth')?.setValue(this.month.toString());
  // }
  this.formData.get('variant')?.setValue(record.toString());  
  //this.tabsForm.get('variant')?.setValue(record.toString());
  this.registrationYear = [];
  this.getFinancialYears();
  this.tabNumber=5;
  console.log(this.tabNumber);
  this.getFuelTypeList();
}

async getFuelType(record: any) {
  console.log(record);
  this.fuelType = !this.fuelType;
  this.yearList = !this.yearList;
  //this.vehicleDetailsForm.value.vehicleDetails.fuelType = record;
  this.formData.get('fuelType')?.setValue(record.toString());
  this.formData.get('registrationYear')?.setValue("");
  //this.tabsForm.get('fuelType')?.setValue(record.toString());
  this.tabNumber=6;
  console.log(this.tabNumber);

  let filteredData = this.vehicleFuelDetailsList.filter((f:any)=>{return f.fuelType == this.formData.get('fuelType')?.value});
  //this.fuelTypeLists = filteredData.map((m:any,index:number) =>{return {name :m.fuelType, id : index +1}});
  let gvWList = filteredData.map((m:any) =>{return m.grossVehicleWeight});

  let gvw = "0-2";
  if(gvWList?.length > 0)
  {
    let data = gvWList[0];
     if (data != null && data != undefined && data != ""){

      if(parseInt(data) > 2000 && parseInt(data) <= 2500){
        gvw = "2-2.5";
      }
      else if(parseInt(data) > 2500 && parseInt(data) <= 3500){
        gvw = "2.5-3.5";
      }
      else if(parseInt(data) > 3500 && parseInt(data) <= 12000){
        gvw = "3.5-12";
      }
      else if(parseInt(data) > 12000 && parseInt(data) <= 20000){
        gvw = "12-20";
      }
      else if(parseInt(data) > 12000 && parseInt(data) <= 20000){
        gvw = "12-20";
      }
      else if(parseInt(data) > 20000 && parseInt(data) <= 40000){
        gvw = "20-40";
      }
      else if(parseInt(data) > 40000){
        gvw = "41";
      }
      else
      {
        gvw = "0-2";
      }
     }
  }
  this.formData.get('gVW')?.setValue(gvw);
}

getFinancialYears() {
  this.registrationYear = [];
  for (var i = (this.year - 19); i < (this.year); i++) {
    this.registrationYear.push(i).orderb
  }
  if (this.registrationYear)
    this.registrationYear.sort(function (a: any, b: any) { return b - a })
}

getFinancialMonths(year:string) {
  const d = this.todayDate;
  let month:number = 12;
  let startMonth = 0;
  let todayDayNumber = d.getDate();
  if(d.getFullYear().toString() == year){
    
    month = d.getMonth() + 1;
    if( todayDayNumber == 1){
      startMonth = d.getMonth() - 1;
    }
    else
    {
      startMonth = d.getMonth();
    }
  }    
  this.registrationMonth = [];

  for (var i = startMonth; i < month; i++) {
    this.registrationMonth.push(this.registrationMonthName[i])
  }
}

async getVehicleVariantDetails(record: any, vehicleModelId: any) {
  this.modelList = !this.modelList;
  this.variList = !this.variList;
  this.vehicleVariantList = [];
  this.registrationYear = [];


  this.formData.get('variant')?.setValue("");
  this.formData.get('registrationYear')?.setValue("");
  this.formData.get('model')?.setValue(record);

  //this.tabsForm.get('variant')?.setValue("");
  //this.tabsForm.get('registrationYear')?.setValue("");
  //this.vehicleDetailsForm.value.vehicleDetails.model = record;
  //this.tabsForm.get('model')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.model);
  let vehicleCategory = this.formData?.get('category')?.value == 'GCV' ? 'Goods Carrying' :
  this.formData?.get('category')?.value == 'PCV' ? 'Passenger Carrying' : 'Miscellaneous';

  let bodyType = this.formData?.get('category')?.value == 'GCV' ? this.formData?.get('gcVehicle')?.value : 
  this.formData?.get('category')?.value == 'PCV' ? this.formData?.get('pcVehicle')?.value : this.formData?.get('miscVehicle')?.value;
  
  let request = {CompanyName: this.formData.get('make')?.value, ModelName: vehicleModelId.toString(),Type : 3, vehicleCategory:vehicleCategory, bodyType : bodyType, seatingCapacity : this.formData?.get('seatingCap')?.value };
  const res = await this.vehicleService.GetVehicleVariantFuelGVWDetails(request);
  if (res != null && res.result) {
    debugger;
     this.vehicleVariantDetailsList = res.result;
     let ddd = this.generalService.distinctItems(res.result.map((m:any) =>{return m.variantName}));
     this.vehicleVariantList = this.generalService.distinctItems(res.result.map((m:any) =>{return m.variantName}));
  }
  this.tabNumber=4;
  console.log(this.tabNumber);
}
async searchVehicleVariantDetails(name:string) {
  this.vehicleVariantList = [];
  this.registrationYear = [];
  // let request = { ModelName: this.formData.get('model')?.value, Name: name};
  // const res = await this.vehicleService.GetVehicleVariantDetails(request);
  // if (res != null && res.result) {
  //   this.vehicleVariantList = res.result;
  // }

  let filteredData = this.vehicleVariantDetailsList.filter((f:any)=>{return f.variantName.toLowerCase().includes(name.toLowerCase())});
  this.vehicleVariantList = this.generalService.distinctItems(filteredData.map((m:any) =>{return m.variantName}));
}
async getVehicleModelDetails(record: any, vehicleCompanyId: any) {
  this.mftList = !this.mftList;
  this.modelList = !this.modelList;
  this.registrationYear = [];
  this.vehicleModelList = [];
  this.vehicleVariantList = [];
  this.formData.get('make')?.setValue(record);
  this.formData.get('model')?.setValue("");
  this.formData.get('variant')?.setValue("");
  this.formData.get('registrationYear')?.setValue("");

  // this.tabsForm.get('model')?.setValue("");
  // this.tabsForm.get('variant')?.setValue("");
  // this.tabsForm.get('registrationYear')?.setValue("");
  //this.vehicleDetailsForm.value.vehicleDetails.make = record;
  //this.tabsForm.get('make')?.setValue(this.formData.get('make')?.value);

  let vehicleCategory = this.formData?.get('category')?.value == 'GCV' ? 'Goods Carrying' :
  this.formData?.get('category')?.value == 'PCV' ? 'Passenger Carrying' : 'Miscellaneous';

  let bodyType = this.formData?.get('category')?.value == 'GCV' ? this.formData?.get('gcVehicle')?.value : 
  this.formData?.get('category')?.value == 'PCV' ? this.formData?.get('pcVehicle')?.value : this.formData?.get('miscVehicle')?.value;

  let request = { CompanyName: vehicleCompanyId.toString() ,Name:name,Type : 3, vehicleCategory:vehicleCategory, bodyType : bodyType, seatingCapacity : this.formData?.get('seatingCap')?.value};
  const res = await this.vehicleService.GetVehicleModelDetails(request);
  if (res != null && res.result) {
    this.vehicleModelList = res.result;
  }
  this.tabNumber=3;
  console.log(this.tabNumber);
}

handleRtoClick(rto: any) {this.rtoChange(rto); this.tabNumber=1;console.log(this.tabNumber);}

async searchVehicleModelDetails(name?:string) {
  this.registrationYear = [];
  this.vehicleModelList = [];
  this.vehicleVariantList = [];

  let vehicleCategory = this.formData?.get('category')?.value == 'GCV' ? 'Goods Carrying' :
  this.formData?.get('category')?.value == 'PCV' ? 'Passenger Carrying' : 'Miscellaneous';

  let bodyType = this.formData?.get('category')?.value == 'GCV' ? this.formData?.get('gcVehicle')?.value : 
  this.formData?.get('category')?.value == 'PCV' ? this.formData?.get('pcVehicle')?.value : this.formData?.get('miscVehicle')?.value;

  let request = { CompanyName: this.formData.get('make')?.value ,Name:name,Type : 3, vehicleCategory:vehicleCategory, bodyType : bodyType, seatingCapacity : this.formData?.get('seatingCap')?.value};

  const res = await this.vehicleService.GetVehicleModelDetails(request);
  if (res != null && res.result) {
    this.vehicleModelList = res.result;
  }
}
async rtoChange(record: any) {
  this.rtoList = !this.rtoList;
  this.mftList = !this.mftList;

  this.formData.get('make')?.setValue("");
  this.formData.get('model')?.setValue("");
  this.formData.get('variant')?.setValue("");
  this.formData.get('registrationYear')?.setValue("");
  this.formData.get('rto')?.setValue(record.regionCode);
  

  // this.tabsForm.get('make')?.setValue("");
  // this.tabsForm.get('model')?.setValue("");
  // this.tabsForm.get('variant')?.setValue("");
  // this.tabsForm.get('registrationYear')?.setValue("");
  //this.vehicleDetailsForm.value.vehicleDetails.rTO = record.regionCode;
  //this.tabsForm.get('rTO')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rTO);
  await this.getVehicleCompanyDetails();
}
async getVehicleCompanyDetails(name?:string) {
  this.vehicleCompanyList = [];
  this.vehicleModelList = [];
  this.vehicleVariantList = [];
  this.registrationYear = [];

  let vehicleCategory = this.formData?.get('category')?.value == 'GCV' ? 'Goods Carrying' :
  this.formData?.get('category')?.value == 'PCV' ? 'Passenger Carrying' : 'Miscellaneous';

  let bodyType = this.formData?.get('category')?.value == 'GCV' ? this.formData?.get('gcVehicle')?.value : 
  this.formData?.get('category')?.value == 'PCV' ? this.formData?.get('pcVehicle')?.value : this.formData?.get('miscVehicle')?.value;

  let request = {Type : 3 , Name:name, vehicleCategory:vehicleCategory, bodyType : bodyType, seatingCapacity : this.formData?.get('seatingCap')?.value};

  const res = await this.vehicleService.GetVehicleCompanyDetails(request);
  if (res != null && res.result) {
    this.vehicleCompanyList = res.result;
  }
  this.tabNumber=2;
}

async cityChange(cityName: any,rtoName: any) {
  
  this.selCity = !this.selCity;
  this.rtoList = !this.rtoList;

  this.formData.get('rto')?.setValue(rtoName);
  this.formData.get('city')?.setValue(cityName);
  this.formData.get('make')?.setValue("");
  this.formData.get('model')?.setValue("");
  this.formData.get('variant')?.setValue("");
  this.formData.get('registrationYear')?.setValue("");


  // this.vehicleDetailsForm.value.vehicleDetails.city = cityName;
  // this.tabsForm.get('city')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.city);
  // this.vehicleDetailsForm.value.vehicleDetails.rTO = rtoName;
  // this.tabsForm.get('rTO')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rTO);
  // this.tabsForm.get('make')?.setValue("");
  // this.tabsForm.get('model')?.setValue("");
  // this.tabsForm.get('variant')?.setValue("");
  // this.tabsForm.get('registrationYear')?.setValue("");
  this.rtoNamesList = [];
  this.vehicleCompanyList = [];
  this.vehicleModelList = [];
  this.vehicleVariantList = [];
  this.registrationYear = [];
  this.mftList = true;
  await this.getVehicleCompanyDetails();
  // const res = await this.vehicleService.RTODetails(rtoName);
  // if (res != null && res.result) {
  //   this.rtoNamesList = res.result;
  // }
  this.tabNumber=2;
  // console.log(this.tabNumber);
}

async getFuelTypeList(){ 
  let mk = this.formData.get('make')?.value;
  let md = this.formData.get('model')?.value;
  let vr = this.formData.get('variant')?.value;
  let request = {CompanyName:this.formData.get('make')?.value, ModelName: this.formData.get('model')?.value,VariantName: this.formData.get('variant')?.value};
  const res = await this.vehicleService.GetVehicleFuelBodyTypeGVWDetails(request);
  if (res != null && res.result) {
    this.vehicleFuelDetailsList = res.result;
    let fuelList = res.result.map((m:any) =>{return m.fuelType});
    this.fuelTypeLists = this.generalService.distinctFuel(fuelList.map((item:any,index:number)=>{return {name:item,id : index +1}}));
  }


  // this.fuelTypeLists=[];
  // let filteredData = this.vehicleVariantDetailsList.filter((f:any)=>{return f.variantName == this.formData.get('variant')?.value});
  // this.fuelTypeLists = filteredData.map((m:any,index:number) =>{return {name :m.fuelType, id : index +1}});
  // let gvWList = filteredData.map((m:any) =>{return m.grossVehicleWeight});

  // let gvw = "0-2";
  // if(gvWList?.length > 0)
  // {
  //   let data = gvWList[0];
  //    if (data != null && data != undefined && data != ""){

  //     if(parseInt(data) > 2000 && parseInt(data) <= 2500){
  //       gvw = "2-2.5";
  //     }
  //     else if(parseInt(data) > 2500 && parseInt(data) <= 3500){
  //       gvw = "2.5-3.5";
  //     }
  //     else if(parseInt(data) > 3500 && parseInt(data) <= 12000){
  //       gvw = "3.5-12";
  //     }
  //     else if(parseInt(data) > 12000 && parseInt(data) <= 20000){
  //       gvw = "12-20";
  //     }
  //     else if(parseInt(data) > 12000 && parseInt(data) <= 20000){
  //       gvw = "12-20";
  //     }
  //     else if(parseInt(data) > 20000 && parseInt(data) <= 40000){
  //       gvw = "20-40";
  //     }
  //     else if(parseInt(data) > 40000){
  //       gvw = "41";
  //     }
  //     else
  //     {
  //       gvw = "0-2";
  //     }
  //    }
  // }
  // this.formData.get('gVW')?.setValue(gvw);


}

async onPincodeChange(data:any) {
  if(data.length >= 3){
    const res = await this.lookUpService.pincodeMasterDetails(data);
    if (res != null && res.result) {
      this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
    }
  }    
}

onPincodeSelection(data:AutoCompleteItem)
{
 this.formData.get('pincode')?.setValue(data.pincodeMaster?.pincode);
}

onInputChange(event: any): void {
  const initialValue = event.target.value;
 const input = event.target as HTMLInputElement;

  // Strictly replace anything not a digit.
  event.target.value = initialValue.replace(/[^0-9]/g, '');
  if (input.value.length > 6) {
      input.value = input.value.slice(0, 6);
      event.stopPropagation();

    }
  if (initialValue !== event.target.value) {
    event.stopPropagation();
    
  }
}

onPincodeBlur(data:any)
{
    this.formData.get('pincode')?.setValue(data);
}
}
