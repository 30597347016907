<div class="container" style="background-color: #f3f3f4;">
    <div class="row mt-5">
        <div class="col-md-1">
            <button class="btn btn-bd btn-sm">Back</button>
        </div>
        <div class="col-md-10 text-start">
            <h4 class="mt-2">Summary</h4>
        </div>
        <div class="col-md-1" style="    position: relative;
        right: 16px;">
            <button class="btn btn-primary btn-sm"><img src="../../../assets/svgIcon/ShareNetwork.svg"
                    alt="">&nbsp;Share</button>
        </div>

    </div>
    <div class="row mt-5">
        <div class="col-md-4 mt-5">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-6">
                        <img src="../../../assets/images/united.png" width="100%" alt="">
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4"><span>
                                    Plan:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">Comprehensive</label>
                            </div>
                            <div class="col-md-4"><span>
                                    IDV:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">₹15,186</label>
                            </div>
                            <div class="col-md-5"><span>
                                Tenure:
                            </span></div>
                        <div class="col-md-7">
                            <label for="">1 Year</label>
                        </div>

                        </div>

                    </div>
                  
                    <div class="col-md-12">
                        <hr>
                        <h5 class="h">Premium Breakup</h5>
                        <hr>
                    </div>
                    <div class="col-md-12">

                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <span>Basic Own Damage Premium</span>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <span>₹10,252</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-9">
                                <span style="color:black"><b>Net Premium</b></span>
                            </div>
                            <div class="col-md-3">
                                <span style="color:black"><b>₹12,250</b></span>
                            </div>
                            <div class="col-md-9 mt-2 hf-cl">
                                <span>GST (18%)</span>
                            </div>
                            <div class="col-md-3 mt-2 hf-cl">
                                <span>+ ₹699</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <h5 class="h" style="color:black ">Total Premium</h5>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <h5 class="h" style="color:black">₹12,250</h5>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-1">
                        <button class="btn btn-primary" id="btn">
                            Pay Securely
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-12 mt-2  back">
                        <div class="row mt-3">
                            <div class="col-md-11 col11">
                                <h4 style="color:black">Owner’s Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                    bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt=""> Edit</span>
                                </h4>
                            </div>

                            <div class="col-md-1 col1" *ngIf="down" (click)="dropDown('down')">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1 col1" *ngIf="Up" (click)="dropDown('up')">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="Up">
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <label for="">Name</label>
                                <p class="p-cl">MR JIGNESH MISTRY</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">PAN Card Number</label>
                                <p>AAAAA1111A</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">Mobile Number</label>
                                <p class="p-cl">919687337536</p>
                            </div>

                            <div class="col-md-6 mt-2">
                                <label for="">Email ID</label>
                                <p class="p-cl">mistryjignesh56@gmail.com</p>
                            </div>

                            <div class="col-md-6 mt-2">
                                <label for="">Date of Birth</label>
                                <p class="p-cl">23-12-1998</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-2  back">
                        <div class="row mt-3">
                            <div class="col-md-11 col11">
                                <h4 style="color:black">Address Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                    bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt=""> Edit</span>
                                </h4>
                            </div>

                            <div class="col-md-1 col1" *ngIf="down1" (click)="dropDown('down1')">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1 col1" *ngIf="Up1" (click)="dropDown('up1')">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="Up1">
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <label for="">Flat Number</label>
                                <p class="p-cl">A-306</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">Street Address</label>
                                <p class="p-cl">Jawahar Nagar</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">State </label>
                                <p class="p-cl">Rajasthan</p>
                            </div>

                            <div class="col-md-6 mt-2">
                                <label for="">District</label>
                                <p class="p-cl">Chittorgarh</p>
                            </div>

                            <div class="col-md-6 mt-2">
                                <label for="">Village</label>
                                <p class="p-cl">Chittorgarh</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">PIN Code</label>
                                <p class="p-cl">312001</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-2  back">
                        <div class="row mt-3">
                            <div class="col-md-11 col11">
                                <h4 style="color:black">Nominees Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                    bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt=""> Edit</span>
                                </h4>
                            </div>

                            <div class="col-md-1 col1" *ngIf="down2" (click)="dropDown('down2')">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1 col1" *ngIf="Up2" (click)="dropDown('up2')">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="Up2">
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <label for="">Nominees Name</label>
                                <p class="p-cl">Mistry Jignesh</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">Relation</label>
                                <p class="p-cl">Brother</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">Age </label>
                                <p class="p-cl">24</p>
                            </div>

                            <div class="col-md-6 mt-2">
                                <label for="">Gender</label>
                                <p class="p-cl">Male</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-2  back">
                        <div class="row mt-3">
                            <div class="col-md-11 col11">
                                <h4 style="color:black">Existing Policy Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                    bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt=""> Edit</span>
                                </h4>
                            </div>

                            <div class="col-md-1 col1" *ngIf="down3" (click)="dropDown('down3')">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1 col1" *ngIf="Up3" (click)="dropDown('up3')">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="Up3">
                        <div class="row">
                            <div class="col-md-6 mt-2">
                                <label for="">Existing Policy Company</label>
                                <p class="p-cl">Balaji</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">Existing Policy type  </label>
                                <p class="p-cl">Standard</p>
                            </div>
                            <div class="col-md-6 mt-2">
                                <label for="">Existing Policy Number </label>
                                <p class="p-cl">D08000032323</p>
                            </div>

                            <div class="col-md-6 mt-2">
                                <label for="">Existing Policy Exp. Date</label>
                                <p class="p-cl">14-01-2024</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>