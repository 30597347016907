<div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#009ef7"
               type="ball-spin-clockwise-fade-rotating"></ngx-spinner>

  <div class="card p-5 mb-5" *ngFor="let item of familyDetails">
    <div class="row">
      <div class="col">
        <p class="text-grey4 font-15-12 mb-1">Relationship</p>
        <span class="text-main-secondary font-15-12 fw-bolder">{{item.relationShipTypeName}}</span>
      </div>
      <div class="col">
        <p class="text-grey4 font-15-12 mb-1">Member Name</p>
        <span class="text-main-secondary font-15-12 fw-bolder">{{item.fullName}}</span>
      </div>
      <div class="col">
        <p class="text-grey4 font-15-12 mb-1">Date Of Birth</p>
        <span class="text-main-secondary font-15-12 fw-bolder">{{item.dob | date: 'dd/MM/yyyy'}}</span>
      </div>
      <div class="col icon-grp">
        <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_30_24 mr-md-3 mr-1" (click)="editRecords(item)">
        <img src="../../assets/svgIcon/Trash.svg" alt="Remove" class="icon_width_height_30_24">
      </div>
    </div>
  </div>
  <div>
    <form id="family" class="form" [formGroup]="memberForm"
          novalidate="novalidate">
      <div class="card p-5 mt-3 pt-1">
        <div class="row align-items-end">
          <div class="col col-xs-12 mt-4">
            <label class="input-label required" for="Married">Relationship</label>
            <select name="income" id="Relationship" class="form-select form-select-custom" formControlName="relationShipType">
              <option value="" selected>Select Relationship</option>
              <option *ngFor="let relation of RelationShipList" [value]="relation.id">
                {{relation.name}}
              </option>
            </select> 
          </div>
          <div class="col-xs-12 col mt-4">
            <label class="input-label required" for="Street">Member Full Name</label>
            <input type="text" placeholder="FullName" class="form-control" formControlName="fullName">
          </div>
          <div class="col-xs-12 col mt-4">
            <label class="input-label required" for="dob">Member Date of Birth</label>
            <input type="text" placeholder="DD-MM-YYYY" onfocus="(this.type='date')" onblur="(this.type='text')" name="dob" autocomplete="off" class="new-user form-control bg-transparent" formControlName="dob" />
          </div>
          <div class="col-xs-12 col icon-grp mt-4 text-right">
              <button class="btn btn-secondary btn-custom btn-active mt-3 mt-md-0 ml-3 ml-md-0" (click)="addMemebrInfo()">{{title}}</button>
              <img src="../../assets/svgIcon/Trash.svg" alt="Remove" class="icon_width_height_30_24 ml-md-2 ml-1 mt-3 mt-md-0" *ngIf="isEdit">
          </div>
        </div>
      </div>
      <!-- <div class="mt-3">
        <button class="btn btn-primary float-end me-5">Add a Family Member</button>
      </div> -->
    </form>
  </div>
</div>
