import { Component } from '@angular/core';

@Component({
  selector: 'app-taxi-insurance',
  templateUrl: './taxi-insurance.component.html',
  styleUrls: ['./taxi-insurance.component.css']
})
export class TaxiInsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
