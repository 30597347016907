import { Component,Input,ElementRef ,OnInit,ViewChild,AfterViewInit  } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http'; 


@Component({
  selector: 'app-lifeline-payment',
  templateUrl: './lifeline-payment.component.html',
  styleUrls: ['./lifeline-payment.component.css']
})
export class LifelinePaymentComponent implements OnInit,AfterViewInit{
  @Input() royalDetails :any;
  @ViewChild('file', { read: ElementRef }) myDiv: ElementRef<HTMLElement>;
  // @ViewChild('myDiv') myDiv: ElementRef;
  constructor(private route: ActivatedRoute,private http: HttpClient){
  console.log(this.royalDetails,"Life Details for payment");
  } 
  ngAfterViewInit() {
    this.submitForm();
}

  submitForm() {
    const form = document.getElementById('PostForm') as HTMLFormElement;
     form.submit();
  }
  
    
  ngOnInit() {
   
  } 

}
