import { Component } from '@angular/core';
import { months } from 'moment';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { GeneralService } from 'src/app/Services/general.service';
import * as XLSX from "xlsx";
@Component({
  selector: 'app-agent-commission',
  templateUrl: './agent-commission.component.html',
  styleUrls: ['./agent-commission.component.css']
})
export class AgentCommissionComponent {
dataCycle1 : any = [];
dataCycle2 : any = [];
today = new Date();
selectedMonth:number = this.today.getMonth() + 1;
selectedYear:number = this.today.getFullYear();
monthArray = ['Jan','Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep', 'Oct', 'Nov','Dec'];
dateRangeCycle1:string="";
dateRangeCycle2:string="";
agentCreatedAt:string;
agentStatus:number = 0;
monthYearArray:any=[];
totalPointsCycle1:string = "0";
totalPointsCycle2:string = "0";
totalPointsEarned:string="0";
totalPointsRedeemed:string="0";
exportDate:any=[];
  constructor(private agentprofileService : AgentprofileService,private generalService : GeneralService){
    let statusObj = this.generalService.getDataToLocalStorage("agentStatus");
    if(statusObj != "")
    {
      let obj = JSON.parse(statusObj);
      if( obj.Agent_Status == 1){
        this.getMonthYearArray(obj.ExamEndAt);
        this.getDateRangeOfCycles();
        this.totalAgentCommission();
        this.getAgentCommissionData(this.selectedYear,this.selectedMonth);
    }
    }
  }

async totalAgentCommission(){
  const response = await this.agentprofileService.TotalAgentCommission({userId : this.generalService.getUniqueuserid()

  });
    if (response?.isSuccess && response?.result) {
      this.totalPointsEarned = response?.result?.commissionPointsCr;
      this.totalPointsRedeemed = response?.result?.commissionPointsDr??"0";
    }
}

async getAgentCommissionData(year:number,month:number){
  this.selectedMonth = month;
  this.selectedYear = year;
  this.getDateRangeOfCycles();
  const response = await this.agentprofileService.AgentCommissionLedgers({userId : this.generalService.getUniqueuserid()
    ,year:year,month:month});
    if (response?.isSuccess && response?.result) {
      this.exportDate = response?.result.map((m:any)=>{return {'Policy Number': m.policyNumber, 'Issued On' : m.whenCreated, 'Credit Points' : m.commissionPointsCr, 'Debit Points' : m.commissionPointsDr, 'Cycle' : 'Cycle ' + m.cycle }});
      this.dataCycle1 =  response?.result.filter((f:any)=>f.cycle == '1');
      this.dataCycle2 =  response?.result.filter((f:any)=>f.cycle == '2');

      this.totalPointsCycle1 = this.dataCycle1.reduce((accumulator:any, current:any) => accumulator + current.commissionPointsCr, 0);
      this.totalPointsCycle2 = this.dataCycle2.reduce((accumulator:any, current:any) => accumulator + current.commissionPointsCr, 0);
    }
}

  daysInMonth (year:number,month:number):number {
    return new Date(year, month, 0).getDate();
  }

  getDateRangeOfCycles(){
    this.dateRangeCycle1 = "1 " + this.monthArray[this.selectedMonth - 1] + " " + this.selectedYear + " - " + "15 " + this.monthArray[this.selectedMonth - 1] + " " + this.selectedYear;
    this.dateRangeCycle2 = "16 " + this.monthArray[this.selectedMonth - 1] + " " + this.selectedYear + " - " + this.daysInMonth(this.selectedYear,this.selectedMonth) + this.monthArray[this.selectedMonth - 1] + " " + this.selectedYear;
  }

  getMonthYearArray(startDate:string) {
    this.monthYearArray = [];
    let current = new Date(startDate);
    let end = new Date(new Date((new Date().setMonth(new Date().getMonth() + 1))).setDate(0));
    end = new Date(new Date(new Date(end.setHours(23)).setMinutes(59)).setSeconds(59));
    while (current <= end) {
        const month = current.getMonth() + 1; // getMonth() is zero-based
        const year = current.getFullYear();        
        this.monthYearArray.push({ month : month, year:year, text : (this.monthArray[month-1] + " " + year)});        
        // Move to the next month
        current.setMonth(current.getMonth() + 1);
    }

    this.monthYearArray = this.monthYearArray?.reverse();
  }

  exportToExcel() {  
    const fileName = "CommissionPointsExport.xlsx";
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportDate);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, fileName);
  }
}
