import { Component, EventEmitter, Output, ViewChild ,AfterViewInit} from '@angular/core';
import { VehiclePremiumInfoComponent } from '../vehicle-premium-info/vehicle-premium-info.component';
import { GeneralService } from 'src/app/Services/general.service';

@Component({
  selector: 'vehicle-quote-details',
  templateUrl: './vehicle-quote-details.component.html',
  styleUrls: ['./vehicle-quote-details.component.css']
})
export class VehicleQuoteDetailsComponent implements AfterViewInit{
  @ViewChild(VehiclePremiumInfoComponent) VehiclePremiumInfoComponent: VehiclePremiumInfoComponent;
  @Output() dataEvent = new EventEmitter<string>();
  premium: any = 0;
  vehiclePremiumData: any;
  constructor(private generalService : GeneralService){}
  continue() {
    this.dataEvent.emit(this.vehiclePremiumData);
  }
  ngAfterViewInit() {
    // this.continue();

  }

  isUserLogged: boolean = false;
  ngOnInit() {
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
    }
  }
  async QuoteDetails(vehiclePremiumData: any) {
    this.premium = vehiclePremiumData.premiumData.netPremium;
    this.vehiclePremiumData = vehiclePremiumData;
    setTimeout(async () => await this.VehiclePremiumInfoComponent.QuoteDetails(vehiclePremiumData), 500);

  }
}
