<div class="container-fluid background">
    <div class="container partners_section">
      <div class="card-container ">
        <div class="justify-content-center">
          <div class="text " style="margin-top: 3%;">
            <!-- <h1 class="heading">Our <span style="color:var(--main2)">Partners</span></h1> -->
            <h1 class="heading">{{langList.our_partner_title}}</h1>
            <span class="font-body">{{langList.our_partner_text}}</span>
          </div>
          <div class="container ">
            <!-- <div id="demo" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row d-flex">
                    <img src="../../assets/partners/new/aditya.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/bajaj.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/care.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/chola.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/digit.png" class="col carImg">&nbsp;
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row d-flex">
                    <img src="../../assets/partners/new/hdfc.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/iffco.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/india.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/icici.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/liberty.png" class="col carImg">&nbsp;
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row d-flex">
                    <img src="../../assets/partners/new/reliance.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/magma.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/national.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/oriental.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/raheja.png" class="col carImg">&nbsp;
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row d-flex">
                    <img src="../../assets/partners/new/royal.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/sbi.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/shriram.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/sompo.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/star.png" class="col carImg">&nbsp;
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row d-flex">
                    <img src="../../assets/partners/new/zuno.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/tata.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/future.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/kotak.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/reliance.png" class="col carImg">&nbsp;
                    <img src="../../assets/partners/new/united.png" class="col carImg">&nbsp;
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
            </div> -->
            <!-- <ng-image-slider [animationSpeed]="0.2" [images]="imageObject" #nav [autoSlide]="0.0001" [infinite]="true"></ng-image-slider> -->
         
            <div style="text-align: center;"> 
                <div class="image-row" > 
                  <img *ngFor="let img of images" [src]="img" alt="">
                  <ng-container *ngIf="showAllImages">
                    <img *ngFor="let img of images2" [src]="img" alt="">
                  </ng-container>
                </div> 
                 
                
                 
                <div style="width: 100%; text-align: center;margin-bottom: 20px;">
                  <a *ngIf="!showAllImages" class="butn" (click)="showAllImages = true">Show More</a>
                </div>
            </div>
          </div>
          <!-- <marquee   behavior="scroll" direction="left">
            <img *ngFor="let img of images" src="{{img}}" alt="">  
          </marquee> -->
            
        </div>
      </div>
    </div>
  </div>

