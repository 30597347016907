import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class DoctorComponent {
  constructor(private route: Router) {

  }
  verification() {
    this.route.navigate(["/doctor-verfication"]);
  }
  bank() {
    this.route.navigate(["doctor-bank-detail"]);
  }

  editPopup: boolean = false;
  eidt() {
    this.editPopup = true;
  }
  closePop() {
    this.editPopup = false;
  }
  reload() {
    location.reload();
  }
  tabs: string = '1';
  changeTab(pg: string) {
    this.tabs = pg;
  }
}
