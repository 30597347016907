<div class="content-box bg-grey1" [ngClass]="!isUserLogged ? '' : 'user-dashboard'">
    <div class="container-fluid" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}" [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="container "  style="background-color: #F3F3F4">
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-8" *ngIf="!isSelectPolicy">
                        <div class="card p-2 mt-5">
                            <h4 class="text-center">
                                Congratulations! your Insurance processed successfully!
                            </h4>
                        </div>
                        <div class="card mt-3 p-2">
                            <div class="row">
                                <div class="col-md-12 center">
                                    <img src="../../../../assets/images/success.jpg" alt="">
                                    <h4 style="color: green;">Payment Successful of ₹{{premium}}</h4>
                                    <!-- <span class="font-body">Your payment is successful</span> -->
                                </div>
                            </div>
                            <div class="center mt-5">
                                <div class="card crd">
                                    <div class="row">
                                        <div class="col-md-6 mt-1 p-3" >
                                            <span>Transaction id</span>
                                        </div>
                                        <div class="col-md-6 mt-1 p-3  ">
                                            <span><b>{{transactionNumber}}</b></span>
                                        </div>
                                    </div>
                                    <div class="row border-top" *ngIf="pn">
                                        <div class="col-md-6 mt-1 p-3" >
                                            <span>Policy Number</span>
                                        </div>
                                        <div class="col-md-6 mt-1 p-3  ">
                                            <span><b>{{pn}}</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="center col-12 d-flex justify-content-center ">
                                        <div class="card p-5 down-rs mb-3">
                                            <span *ngIf="!checkPartner()">
                                                <a (click)="proceed()" attributes-list download class="text-start"><b
                                                        style="color: green;">Proceed</b></a> 
                                            </span>
                                            <span *ngIf="checkPartner()">
                                                <a (click)="showPolicyDetails()" attributes-list download class="text-start"><b
                                                        style="color: green;">View Policy Details</b></a> 
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 mt-3">
                            <div class="card ">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>
                                            Your vehicle details
                                        </h4>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <span>
                                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJJKgExd3KX8QoCOdIGMpAt6XWrVTZJEiRZQ&usqp=CAU"
                                                width="15" height="20" alt="Vehicle">&nbsp;&nbsp;Vehicle<p><b>
                                                    Suzuki Motorcycle
                                                </b></p>
                                        </span>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <img src="../../assets/svgIcon/city.svg" alt="city">&nbsp;&nbsp;
                                            <div>
                                                <p class="font">Modal
                                                    <br>
                                                    <span>ST BSVI 125</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 ">
                                        <div class="d-flex">
                                            <img src="../../assets/svgIcon/city.svg" alt="city">&nbsp;&nbsp;
                                            <div>
                                                <p class="font">RTO
                                                    <br>
                                                    <span>mp43- abc</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2">

                                        <div class="d-flex">
                                            <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;

                                            <p class="font">Year</p>
                                            &nbsp; <h5>2023</h5>

                                        </div>

                                    </div>
                                    <div class="col-md-6 mt-2">

                                        <div class="d-flex">
                                            <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;

                                            <p class="font">Last Year Claim</p>
                                            &nbsp;&nbsp;&nbsp;&nbsp; <h5>NO</h5>

                                        </div>

                                    </div>
                                    <div class="col-md-6 mt-2">

                                        <div class="d-flex">
                                            <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;

                                            <p class="font">NCB Last Year</p>
                                            &nbsp;&nbsp;&nbsp;&nbsp; <h5>0%</h5>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="card mt-5 p-5">
                                <h4>
                                    Premium Breakup
                                </h4>
                                <div class="row">
                                    <div class="col-md-8">
                                        <p>Basic Own Damage</p>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="spn">₹1,000</span>
                                    </div>
                                    <hr>
                                    <div class="col-md-8">
                                        <p>Net Premium</p>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="spn">₹1,000</span>
                                    </div>
                                    <div class="col-md-8">
                                        <p>Add GST(18%)</p>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="spn">₹180</span>
                                    </div>
                                    <hr>
                                    <div class="col-md-8">
                                        <p>Final Premium</p>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="spn">₹1,180</span>
                                    </div>
            
            
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="col-md-6">
                        <div *ngIf="isSelectPolicy">
                            <div class="card-item card row">
                              <div class="pt-3 d-block d-sm-block d-md-none row">
                                <div class="col-12">
                                  <h3 class="text-main-secondary">Policy Details</h3>
                                </div>
                              </div>
                              <div class="">
                                <div class="row border-bottom">
                                    <div class="col-md-4 text-center text-md-start">
                                        <img src="{{policyDetails.policyValue.insuranceCompanyLogo}}" alt="unitedInsurance" class="img-fluid h-72">
                                    </div>
                                    <div class="col-md-4 col-6 pt-5">
                                        <p class="text-grey4 text-grey4 font-15-12 mb-0">Plan</p>
                                        <p class="mb-0  text-main-secondary">{{policyDetails.vehicleDetails.typeOfCover}}</p>
                                    </div>
                                    <div class="col-md-4 col-6 pt-5">
                                        <p class="text-grey4 text-grey4 font-15-12 mb-0">Tenure</p>
                                        <p class="mb-0  text-main-secondary">{{policyDetails.policyValue.policyTerm}}</p>
                                    </div>
                                </div>
                              </div>  
                              <div class="row border-bottom">
                                <!-- <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Tenure</p>
                                  <p class="text-main-secondary font-15-12">{{policyDetails.policyValue.policyTerm}} Year</p>
                                </div> -->
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Policy Start Date</p>
                                  <p class="text-main-secondary font-15-12">{{policyDetails.policyValue.startDate | date:'dd-MMM-yyyy'}}</p>
                                </div>
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Policy End Date</p>
                                  <p class="text-main-secondary font-15-12">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
                                </div>
                              </div>
                              <div class="row border-bottom">
                                <div class="col-6">
                                  <p class="text-grey4 mb-1 font-13">Proposer</p>
                                  <p class="text-main-secondary font-15-12">{{policyDetails.proposerDetails.firstName}} {{policyDetails.proposerDetails.lastName}}</p>
                                </div>
                                <div class="col-6">
                                  <p class="text-grey4 mb-1 font-13">Policy No.</p>
                                  <p class="text-main-secondary font-15-12">{{policyDetails.policyNumber}} </p>
                                </div>
                              </div>
                              <div class="row border-bottom">
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">{{(policyDetails.policyCategory == 1 ? 'Four Wheeler' : policyDetails.policyCategory == 2 ? 'Two Wheeler' : policyDetails.policyCategory == 4 ? '' : policyDetails.policyCategory == 5 ? 'Life' : 'Vehicle')+ ' Number'}}</p>
                                  <p class="text-main-secondary font-15-12">{{policyDetails.vehicleDetails.registrationNumber}}</p>
                                </div>
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Manufacture/Model</p>
                                  <p class="text-main-secondary font-15-12">{{policyDetails.vehicleDetails.make}}, {{policyDetails.vehicleDetails.model}}</p>
                                </div>
                              </div>
                              <div class="row border-bottom">
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Coverage Anount</p>
                                  <p class="text-main-secondary font-15-12">NA</p>
                                </div>
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Status</p>
                                  <p class="text-main-secondary font-15-12">{{policyStatus}}</p>
                                </div>
                              </div>
                              <div class="row border-bottom">
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Your Premium</p>
                                  <p class="text-main-secondary font-15-12">₹ {{policyDetails.vehicleDetails.premium}}</p>
                                </div>
                                <div class="col">
                                  <p class="text-grey4 mb-1 font-13">Payment Method</p>
                                  <p class="text-main-secondary font-15-12">NA</p>
                                </div>
                              </div>
                            </div>
                            <div class="card card-item mt-3 row pt-0">
                              <div class="row d-flex pt-2 pb-5 pl-2 pr-2 justify-content-center">
                                <div class="col-6 col-md-4 pt-3">            
                                  <button class="btn btn-outline btn-outline-primary w-100 text-main border-main" type="button" (click)="onClick()">Download PDF</button>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
