import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidebarmenu',
  templateUrl: './sidebarmenu.component.html',
  styleUrls: ['./sidebarmenu.component.css']
})
export class SidebarmenuComponent {
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  onButtonClick(component: string) {
    this.buttonClicked.emit(component);
  }
}
