
export class SignUpModel {
  public phoneNumber: any;
  public firstName: any;
  public gender: any;
  public userEmail: any;
  public streetAddress: any;
  public pincode: any;
  public newPassword: any;
  public confirmNewPassword: any;
  public referralCode: any;
}

export class PincodeMaster{
  id:number | undefined;
  district :string | undefined;
  state:string | undefined;
  country:string | undefined;
  cityVillageArea:string | undefined;
  pincode:string | undefined;
}
