<div [ngClass]="!IsProfile ? 'user-dashboard' : ''">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#009ef7"
               type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="row mt-5 p-0" [ngClass]="!IsProfile ? 'user-route' : ''" [ngStyle]="{'top':!IsProfile ? '60px' : '' }">
    <div class="col-md-6 mt-20-10" Para-plan *ngFor="let item of vehiclesDetails">
      <div class="row m-0 insurancePlanChildDiv1">
        <div class="col-12">
        <div class="row">
          <div class="col d-flex align-items-center gap_20_8">
            <!-- <i class="iconDiv bi bi-bicycle InsTypeIcon"></i> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" class="icon_width_height_50_40" viewBox="0 0 50 50" fill="none">
              <circle opacity="0.1" cx="25" cy="25" r="25" fill="var(--main)" />
              <!-- Define the symbol for the icon -->
              <symbol id="icon" viewBox="0 0 30 30">
                <!-- Your icon paths go here -->
                <g clip-path="url(#clip0_1956_71080)">
                  <path d="M4.6875 22.5C6.75857 22.5 8.4375 20.8211 8.4375 18.75C8.4375 16.6789 6.75857 15 4.6875 15C2.61643 15 0.9375 16.6789 0.9375 18.75C0.9375 20.8211 2.61643 22.5 4.6875 22.5Z" stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M25.3125 22.5C27.3836 22.5 29.0625 20.8211 29.0625 18.75C29.0625 16.6789 27.3836 15 25.3125 15C23.2414 15 21.5625 16.6789 21.5625 18.75C21.5625 20.8211 23.2414 22.5 25.3125 22.5Z" stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.875 6.5625H20.625L25.3125 18.75" stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M1.875 9.375C1.875 9.375 7.41914 11.5242 10.3781 12.8684C10.8554 13.0878 11.3845 13.1691 11.9056 13.1033C12.4267 13.0374 12.9189 12.8269 13.3266 12.4957C14.2547 11.7293 15.75 11.25 17.8125 11.25H25.3125C23.6754 11.2495 22.0831 11.7847 20.7786 12.7739C19.4741 13.7631 18.5291 15.152 18.0879 16.7285C17.92 17.313 17.5659 17.8266 17.0794 18.1915C16.5929 18.5564 16.0007 18.7525 15.3926 18.75H4.6875" stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_1956_71080">
                    <rect width="30" height="30" fill="white" />
                  </clipPath>
                </defs>
              </symbol>

              <!-- Use the defined symbol inside the circle -->
              <use xlink:href="#icon" x="10" y="10" width="30" height="30" />
            </svg>
            <div class="row">
              <div class="col-12">
              <p class="font-body mb-0 font-15-12">{{item.make}} {{item.model}}</p></div>
            <span class="font-17-13">{{item.registrationNumber}}</span>
            </div>
          </div>
          <div class="col-3 col-md-3 col-lg-2 justify-content-between d-flex">
            <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_30_24">
            <img src="../../assets/svgIcon/Trash.svg" alt="Remove" class="icon_width_height_30_24">
    </div>
        </div>
      </div>
        <div class="row m-0 p-0 mt-3">
          <div class="col-4">
            <p class="font-body m-0 font-15-12">Reg. Year</p>
            <!-- <p class="fw-medium m-0" style="color: var(--main)">{{item.registrationYear }}</p> -->
            <span class="font-15-12 fw-bolder m-0">{{item.registrationYear }}</span>          
          </div>
          <div class="col-4">
            <p class="font-body m-0 font-15-12">Fuel Type</p>
            <!-- <p class="fw-medium m-0" style="color: var(--main)">Petrol</p> -->
            <span class="font-15-12 fw-bolder m-0">Petrol</span>
          </div>
          <div class="col-4">
            <p class="font-body m-0 font-15-12">Policy Expiry Date</p>
            <!-- <p class="fw-medium m-0" style="color: var(--main)">{{item.policyExpiryDate | date: 'dd/MM/yyyy'}}</p> -->
            <span class="font-15-12 fw-bolder m-0">{{item.policyExpiryDate | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
      </div>
    </div>
  <div class="col-12 mt-3">
    <button class="btn btn-primary float-end btn-custom" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling" (click)="onClickButton($event)">{{title}}</button>
    <div class="offcanvas offcanvas-end overflow-scroll" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">

      <div class="offcanvas-header p-4">
        <h4 class="offcanvas-title" id="offcanvasScrollingLabel">{{title}}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
      </div>
      <div class="offcanvas-body p-4">
        <div>
          <h5>Select Assets Type</h5>
          <div class="row justify-content-between p-3">
            <div class="col card p-4 card-type" [ngClass]="{'selected-card': selectedType === 'Vehicle'}">
              <li class="d-flex justify-content-between">
                <span class="text-grey5">Vehicle</span>
                <input type="radio" class="mt-0 radio-button" (change)="selectedCard('Vehicle')" [checked]="selectedType === 'Vehicle'">
              </li>
            </div>&nbsp;&nbsp;
            <div class="col card p-4 card-type" [ngClass]="{'selected-card': selectedType === 'Property'}">
              <li class="d-flex justify-content-between">
                <span class="text-grey5">Property</span>
                <input type="radio" class="mt-0 radio-button" (change)="selectedCard('Property')" [checked]="selectedType === 'Property'">
              </li>
            </div>
          </div>
        </div>
        <div *ngIf="selectedType === 'Vehicle'">
          <managevehicle></managevehicle>
        </div>
        <div *ngIf="selectedType === 'Property'">
          <manageproperty></manageproperty>
        </div>
      </div>


    </div>
  </div>
</div>
