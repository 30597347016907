<div class="container">
    <div class="row mt-10">
        <div class="col-md-12 mt-10">
            <div class="row">
                <div class="col-md-4 mt-5">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-md-1 c1 mt-5" *ngIf="!isBasicDetails">
                                <img src="../../../assets/images/Check box2.svg" alt="">
                            </div>
                            <div class="col-md-1 c1 mt-5" *ngIf="isBasicDetails">
                                <img src="../../../assets/images/Check box.svg" alt="">
                            </div>
                            <div class="col-md-11 c11 mt-6">
                                <p>Basic Details</p>
                            </div>
                            <div class="col-md-1 text-center  vl">

                            </div>


                        </div>

                        <div class="row">
                            <div class="col-md-1 c1 mt-5" *ngIf="!isContanctDetail">
                                <img src="../../../assets/images/Check box2.svg" alt="">
                            </div>
                            <div class="col-md-1 c1 mt-5" *ngIf="isContanctDetail">
                                <img src="../../../assets/images/Check box2.svg" alt="">
                            </div>
                            <div class="col-md-11 c11 mt-6">
                                <p>Contact Details</p>
                            </div>


                        </div>

                    </div>
                </div>
                <div class="col-md-8 mt-5">
                    <div class="card p-3">
                        <div *ngIf="page=='basic'">
                            <div class="row l-bm">
                                <div class="col-md-8 mt-4">
                                    <h4>Basic Details</h4>
                                </div>
                                <div class="col-md-4 mt-2">
                                    <div class="row">
                                        <div class="col-md-5  mt-2">
                                            <button class="btn btn-sm btn-back" (click)="back()">Back</button>
                                        </div>
                                        <div class="col-md-7   mt-2 ">
                                            <button class="btn btn-sm btn-primary " (click)="Pages('contact')" [disabled]="BasicDetails.invalid">Save
                                                &Next</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <form [formGroup]="BasicDetails" class="row">
                                <div class="col-md-7 mt-2">
                                    <label for="">Add Aadhaar Photos</label>
                                    <div class="row">
                                        <div class="col-md-6 mt-5 text-center">
                                            <label for="front-file-upload" class="custom-file-upload text-center">
                                                <img *ngIf="!frontImage" src="../../../assets/svgIcon/uplod.svg" alt=""
                                                    width="50">
                                                <p *ngIf="!frontImage" class="mt-3 p-2">Upload Front Image</p>
                                                <img *ngIf="frontImage" [src]="frontImage" alt="Preview" height="100%"
                                                    width="100%" /><br>

                                            </label>
                                            <input id="front-file-upload" type="file"
                                                formControlName="aadhaarFrontUpload" (change)="onFrontSelected($event)"
                                                accept="image/*">
                                            <p *ngIf="frontImage" class="mt-3 p-2 success-text">Front Image Uploaded
                                                Successfully !</p>
                                        </div>
                                        <div class="col-md-6 mt-5 text-center">
                                            <label for="back-file-upload" class="custom-file-upload text-center">
                                                <img *ngIf="!backImage" src="../../../assets/svgIcon/uplod.svg" alt=""
                                                    width="50">
                                                <p *ngIf="!backImage" class="mt-3 p-2">Upload Back Image</p>
                                                <img *ngIf="backImage" [src]="backImage" alt="Preview" height="100%"
                                                    width="100%" /><br>
                                            </label>
                                            <input id="back-file-upload" type="file" formControlName="aadhaarBackUpload"
                                                (change)="onBackSelected($event)" accept="image/*">
                                            <p *ngIf="backImage" class="mt-3 p-2 success-text">Back Image Uploaded
                                                Successfully !</p>

                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="">Aadhaar Card Number</label>
                                    <div class=" btnIn">
                                        <input type="text" name="" class="" placeholder=" Eg : 1234 - 1234 - 1234" id=""
                                            formControlName="AdharNumber">
                                        <button>Verify</button>

                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="">Gender</label>
                                    <div class="row">
                                        <div class="col-md-4 mt-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                    id="flexRadioDefault1" formControlName="gender">
                                                <span class="gen"> Male</span>
                                            </div>
                                        </div>

                                        <div class="col-md-4 mt-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                    id="flexRadioDefault1" formControlName="gender">
                                                <span class="gen"> Female</span>
                                            </div>
                                        </div>

                                        <div class="col-md-4 mt-2">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                    id="flexRadioDefault1" formControlName="gender">
                                                <span class="gen"> Other</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <label for="">Address</label>
                                    <input type="text" name="" class="form-control" placeholder="Address" id="" formControlName="adress"> 
                                </div>

                                <div class="col-md-6 mt-3">
                                    <label for="">PIN Code</label>
                                    <input type="number" name="" class="form-control" placeholder="PIN code" id="" formControlName="pinCode">
                                </div>

                                <div class="col-md-6 mt-3">
                                    <label for="">City</label>
                                    <input type="text" name="" class="form-control" placeholder="City" id=""formControlName="city">
                                </div>

                                <div class="col-md-6 mt-3">
                                    <label for="">State</label>
                                    <input type="text" name="" class="form-control" placeholder="State" id="" formControlName="state">
                                </div>

                                <div class="col-md-6 mt-3">
                                    <label for="">Date of Birth</label>
                                    <input type="date" name="" class="form-control" placeholder="Date of Birth" id="" formControlName="dob">
                                </div>
                                </form>
                            </div>
                        </div>
                        <div *ngIf="page=='contact'">
                            <form [formGroup]="Contact">
                                <div class="row l-bm">
                                    <div class="col-md-8 mt-4">
                                        <h4>
                                            Contact Details</h4>
                                    </div>
                                    <div class="col-md-4 mt-2">
                                        <div class="row">
                                            <div class="col-md-5 mt-2">
                                                <button class="btn btn-sm btn-back" (click)="Pages('basic')">Back</button>
                                            </div>
                                            <div class="col-md-7 mt-2">
                                                <button class="btn btn-sm btn-primary" [disabled]="Contact.invalid" (click)="success()">Save &Next</button>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                <div class="row l-bm">
                                    <div class="col-md-6 mt-4">
                                        <label for="">Business Mobile Number</label>
                                        <input type="number" placeholder="+91 75970696969" name="" id="  " class="form-control" formControlName="MobileNumber">
                                    </div>
                                    
                                    <div class="col-md-6 mt-4">
                                        <label for="">Business Email Address</label>
                                        <input type="email" name="" id="  " class="form-control" placeholder="useremailadd@domain.com" formControlName="Email">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <div class="row mt-2 ">
                                            <div class="col-md-12">
                                                <label for="">Do you have GSTIN Number?</label>
            
                                            </div>
                                            <div class="col-md-6 mt-2 c6">
                                                <div class="card cd ">
                                                    <div class="form-check ml-3 mt-2 ">
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                            id="flexRadioDefault1" (click)="nubmer('yes')" checked>
                                                        <p class="mt-2">
                                                            &nbsp;&nbsp; Yes
                                                        </p>
                                                    </div>
            
                                                </div>
                                            </div>
            
                                            <div class="col-md-6 mt-2 c6">
                                                <div class="card cd ">
                                                    <div class="form-check ml-3 mt-2 ">
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                            id="flexRadioDefault1" (click)="nubmer('no')">
                                                        <p class="mt-2">
                                                            &nbsp;&nbsp; No
                                                        </p>
                                                    </div>
            
                                                </div>
                                            </div> 
                                          
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-10" *ngIf="isWhatsapp">
                                            <label for="">WhatsApp Number</label>
                                            <input type="number" name=""  class="form-control" placeholder="+91 75970696969" id="" formControlName="WhatsApp">
                                        
                                    </div>
                                </div> 
                                <div class="row mt-3">
                                    <div class="col-md-6 mt-2">
                                        <label for="">Business Website</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="Website">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">Business App</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id=""  formControlName="BusinessApp">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">Facebook Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id=""  formControlName="Facebook">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">Instagram Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="Instagram">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">LinkedIn Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="LinkedIn">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">X (twitter) Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="twitter">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="Success">
    <div class="popup2">
        <div class="modal-header  bg-transparent">
            <!-- <h4 class="modal-title">Sign In</h4> -->
            <button type="button" class=" close pull-right" aria-label="Close" (click)=" close()">

            </button>
        </div>
        <div class="model-body">
            <div class=" row text-center">
                <div class="col-md-12 mt-3 p-3 text-center">
                    <img src="../../../assets/images/green_double_circle_check_mark 1.png" alt="">
                </div>
                <div class="col-md-12 mt-3">
                    <h6>""Business Verification Successfully Done""</h6>
                </div>
                <div class="col-md-12 mt-3">
                    <span>Congratulations! You've successfully Completed the Business Verification</span>
                </div>
                <div class="col-md-12 mt-2">
                    <button class="btn btn-sm btn-primary" (click)="back()">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>