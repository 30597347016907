<div class="container text-center banner_section" #insurance *ngIf="isBannerShow">
  <div class="row">
    <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-12" >
      <div class="banner_img">
        <img class="rounded-3" src="../../assets/images/banner/01 Motor Insurance.jpg">
      </div>
      
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-12" >
      <div class="banner_img">
        <img class="rounded-3" src="../../assets/images/banner/02 Health Insurance.jpg">
      </div>
      
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-12" >
      <div class="banner_img">
        <img class="rounded-3" src="../../assets/images/banner/03 Life Insurance.jpg">
      </div>
      
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-12" >
      <div class="banner_img">
        <img class="rounded-3" src="../../assets/images/banner/04 Term Insurance.jpg">
      </div>
      
    </div> -->
    <!-- <ng-image-slider imageSize="width: 10%, height: 20%, space: 15" [images]="imageObject" #nav  [infinite]="true"
    [autoSlide]="1"
    [imageSize]="{width: '23%', height: 180, space:13}"
    slideImage="1"></ng-image-slider> -->
    <div style="width: 100%; height: 20%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav
        [images]="imageObject"
        [imagePopup]="false"
        [infinite]="true"
        [autoSlide]="1"
        [imageSize]="{width: '250px', height: 150}"
        slideImage="1"
        ></ng-image-slider>
    </div>

  </div> 
  <!-- <div id="demo" class="carousel slide" data-bs-ride="carousel">

    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row d-flex">
          <img src="../../assets/images/banner/01 Motor Insurance.jpg" class="col carImg">&nbsp;
          <img src="../../assets/images/banner/02 Health Insurance.jpg" class="col carImg">&nbsp;
          <img src="../../assets/images/banner/03 Life Insurance.jpg" class="col carImg">&nbsp;
 
        </div>
      </div>
     
      
      <div class="carousel-item">
        <div class="row d-flex">
          <img src="../../assets/images/banner/04 Term Insurance.jpg" class="col carImg">&nbsp;
          <img src="../../../assets/images/banner/05 Saving Plans.jpg" class="col carImg">&nbsp;
          
          <img src="../../../assets/images/banner/08 Best Price Insurance.jpg" class="col carImg">&nbsp;
        </div>
      </div>
      
    </div>

    <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </button>
  </div> -->
</div>