<app-navbar></app-navbar>
    <div class="container">
        <div class="container-fluid">
            <div class="card">
                <div class="row">
                    <div class="testimonial-card p-5">
                        <h3>Testimonials</h3>
                        <div class="top-line"></div>
                        <form [formGroup]="testimonialsForm" class="needs-validation" novalidate="" autocomplete="off">
                            <div class="row mt-10">
                                <div class="col">
                                    <label for="name">First name <span>*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter First Name" name="firstName"
                                    formControlName="firstName">
                                </div>
                                <div class="col">
                                    <label for="name">Last name <span>*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter Last Name" name="lastName"
                                    formControlName="lastName">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label for="name">Current Career Stage <span>*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter Current Career Stage" name="currentCareerStage"
                                    formControlName="currentCareerStage">
                                </div>
                                <div class="col">
                                    <label for="name">City <span>*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter City" name="city"
                                    formControlName="city">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label for="name">Write a review about your experience with Click4bima<span>*</span></label>
                                    <textarea class="form-control" rows="4" cols="50" name="review"
                                    formControlName="review"></textarea>
                                </div>
                            </div>
                            <div class="d-grid mb-5 mt-5" style="width:20px;">
                                <button type="submit" class="btn btn-primary" id="kt_sign_in_submit" [disabled]="testimonialsForm.invalid"
                                (click)="save()">
                                <span class="indicator-label">Submit Testimonial</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div>
</div> -->
<app-footer></app-footer>