import { Component } from '@angular/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent {
nextDiv:string='';
  isAll: boolean;
  isActive: boolean;
  isExpired: boolean;
  next(name:string):void{
    this.nextDiv=name;
  }
  changeTab(value: string){
    if(value == 'all'){
     this.isAll = true;
     this.isActive = false;
     this.isExpired = false;
    }
    else if(value == 'active'){
      this.isAll = false;
     this.isActive = true;
     this.isExpired = false;
    }
    else if(value == 'expired'){
      this.isAll = false;
     this.isActive = false;
     this.isExpired = true;
    }
}
}
