
import { Component ,OnInit,Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsuranceService } from 'src/app/Services/insurance.service';
import swal from 'sweetalert2';
import { Route,Router ,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-healthinsurance-plan-list',
  templateUrl: './healthinsurance-plan-list.component.html',
  styleUrls: ['./healthinsurance-plan-list.component.css']
})
export class HealthinsurancePlanListComponent implements OnInit  {
planList:any=[];
finalPremium :string = "";
premiumObject:any;
popup:boolean=false;
isCreate:boolean = false;
@Input() quoteData: any;

  constructor(private fb: FormBuilder, private insuranceService : InsuranceService,
    private router:Router,private route:ActivatedRoute,private spinner: NgxSpinnerService){
    }

  ngOnInit(): void {
    this.getHealthQuotes();

  }
  close():void{
    this.popup=false;
  }
  getPopUp()
  {
    this.popup=true;
  }

async getHealthQuotes(){
this.planList = [];
this.spinner.show();
  const response = await this.insuranceService.GetHealtQuotes(this.quoteData);
  if (response !=null) {
    this.planList.push(response?.result?.premiumdetails?.quoteDetails);
    this.finalPremium = this.planList[0]?.finalPremium;
    this.premiumObject = {
      finalPremium:this.planList[0]?.finalPremium,
      grosspremium:this.planList[0]?.grosspremium,
      igst:this.planList[0]?.igst
    }
  }
  else{
    swal.fire({
      position: 'center',
      icon: 'error',
      title: 'No Quotes Available',
      showConfirmButton: false,
      timer: 3000,
    });
  }
  this.spinner.hide();
}

async createQuote(){
  this.isCreate = true;
  // this.router.navigate(['HealthinsuranceDetails']);
}

}