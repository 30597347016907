<!-- <div class="container mt-5 mb-5">
  <div class="row">
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-4">
          <div class="card card-item cur" [ngClass]="{'selected-card': isinsCard1}" (click)="tabToggle('insCard1')">
            <div class="text-center">
              <h2>{{langList.what_is_ins1}} <br> {{langList.what_is_ins2}}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-item cur" [ngClass]="{'selected-card': isinsCard2}" (click)="tabToggle('insCard2')">
            <div class="text-center">
              <h2>{{langList.why_insurance_imp1}}<br>{{langList.why_insurance_imp2}}</h2>

            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-item cur" [ngClass]="{'selected-card': isinsCard3}" (click)="tabToggle('insCard3')">
            <div class="text-center">
              <h2>{{langList.claim_assistance1}} <br>{{langList.claim_assistance2}}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <div class="card p-3" *ngIf="tab=='insCard1'">
            <div class="row">
              <div class="col-md-4 p-4">
                <div class="tab_img_content">
                  <img src="../../assets/images/Insurance-cuate-2 1.svg" alt="" class="img-fluid" width="100%">
                </div>
              </div>
              <div class="col-md-8 p-2">
                <div class="row">
                  <p>Insurance is like a safety plan for your money. When you get insurance, you pay a certain amount
                    regularly, called a premium. In return, if something bad happens, like your things getting damaged
                    or someone getting sick or passing away, the insurance company helps by giving you money. There are
                    different types of insurance, like auto, health, life, or things you own. It's like having a
                    financial backup when things don't go as planned. </p>

                  <ul class="mr-4">
                    <li class="mt-2">
                      Insurance serves as a financial safety net, helping individuals and businesses recover from
                      unexpected events without facing crippling financial consequences.
                    </li>
                    <li class="mt-2">
                      Insurance serves as a financial safety net, helping individuals and businesses recover from
                      unexpected events without facing crippling financial consequences.
                    </li>
                    <li class="mt-2">
                      Insurance serves as a financial safety net, helping individuals and businesses recover from
                      unexpected events without facing crippling financial consequences.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="card p-3" *ngIf="tab=='insCard2'">
            <div class="row">
              <div class="col-md-4 p-4">

                <div class="tab_img_content">
                  <img src="../../assets/images/Secure data-cuate 1.svg" alt="" class="img-fluid" width="100%">
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <ul class="mt-10">
                    <li class="mt-2">
                      It is important for financial protection in uncertain times, aiding individuals and businesses in
                      recovering from accidents, property damage etc.
                    </li>
                    <li class="mt-2">
                      It is important for financial protection in uncertain times, aiding individuals and businesses in
                      recovering from accidents, property damage etc.
                    </li>
                    <li class="mt-2">
                      It is important for financial protection in uncertain times, aiding individuals and businesses in
                      recovering from accidents, property damage etc.
                    </li>
                    <li class="mt-2">
                      It is important for financial protection in uncertain times, aiding individuals and businesses in
                      recovering from accidents, property damage etc.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <div class="card p-3" *ngIf="tab=='insCard3'">
            <div class="row">
              <div class="col-md-4">
                <div class="tab_img_content">
                  <img src="../../assets/images/Online consulting-cuate-2 1.svg" alt="" class="img-fluid" width="100%">
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <ul class="mt-10">
                    <li class="mt-2">
                      Click4Bima ensures a seamless claims experience with step-by-step guidance, aiding policyholders in navigating the process smoothly.    
                    </li>
                    <li class="mt-2">
                      We provide expert support in preparing and submitting required documents, guaranteeing accurate information presentation.   
                    </li>
                    <li class="mt-2">
                      With dedicated customer support, Click4Bima addresses queries and concerns during the claims process, enhancing the overall experience for our policyholders. 
                    </li>
                    <li class="mt-2">
                      We prioritize efficient claim resolution to minimize disruption to your life and provide the support you need when facing unexpected events. 
                    </li> 
                  </ul>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="img-width">
        <div class="insurance_definition_div mt-1">
          <img class="rounded-3" src="../../assets/images/privacy.jpg">
        </div>
        <div class="insurance_definition_div mt-3">
          <img class="rounded-3" src="../../assets/images/claim.jpg">
        </div>
        <div class="insurance_definition_div mt-3">
          <img class="rounded-3" src="../../assets/images/banner/06 Family Protection.jpg">
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-8">
        <h2 class="whatinsh1">{{langList.what_is_ins1}}
          {{langList.what_is_ins2}}</h2>
        <span>
          <p style="padding-top: 15px;" class="insText">Insurance functions as a vital financial safety net, offering
            indispensable support in the face of life's
            unforeseen challenges. By committing to a regular premium, you secure the assurance that should misfortune
            strike, be it property damage, illness, or the loss of a loved one, the insurance provider will mitigate the
            financial burden. With diverse options like auto, health, life, and property insurance, it is akin to having
            a steadfast financial guardian, ensuring your peace of mind and financial stability when the unexpected
            occurs. Investing in insurance is not just about protection; it is about securing a future where you can
            face uncertainties with confidence. Don't wait for the unexpected, be prepared and safeguard your tomorrow
            today.</p>

        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;margin-bottom: 20px;">
          <a class="earnButton">Know More About Insurance</a>
        </div> -->
      </div>
      <div class="col-md-4 whatimage">
        <img src="./assets/images/newicons/insurance.png" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="whyins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-4 whyimage">
        <img src="./assets/images/newicons/insurance1.png" class="imgresponsive">
      </div>
      <div class="col-md-8">
        <h2 class="whyinsh1">
          {{langList.why_insurance_imp1}} {{langList.why_insurance_imp2}}</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">In uncertain times, financial protection is crucial for both
            individuals and businesses. Insurance plays a
            key role in aiding recovery from accidents, property damage, and other unforeseen events. It provides the
            necessary support to help navigate through challenging situations, ensuring that financial stability and
            peace of mind are maintained. Investing in insurance means being prepared and resilient, no matter what life
            throws your way. By securing insurance, you are not just protecting your assets, you are safeguarding your
            future and enabling a quicker recovery when adversity strikes. Make the smart choice today for a more secure
            tomorrow.</p>
        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;">
          <a class="earnButton">Know more about it</a>
        </div> -->

      </div>

    </div>
  </div>
</div>

<div class="become">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-8 ">
        <div class="">
          <h1 class="mainh1">Become A POSP To Earn With Us</h1>
        </div>
        <div style="margin-top: 35px;">
          <div class="selectionDIv">
            <div style="text-align: center; cursor: pointer;">
              <div class="common">
                <img src="./assets/images/newicons/support-hand-gesture.svg" class="imageResponseive">
              </div>
              <span class="lab">24X7 Support</span>
            </div>
            <div style="text-align: center; cursor: pointer">
              <div class="common">
                <img src="./assets/images/newicons/money-saving.svg" class="imageResponseive">
              </div>
              <span class="lab">Timely Payout</span>
            </div>
            <div style="text-align: center; cursor: pointer">
              <div class="common">
                <img src="./assets/images/newicons/content-marketing.svg" class="imageResponseive">
              </div>
              <span class="lab">content-marketing</span>
            </div>
          </div>
        </div>

        <div style="margin-top: 35px;">
          <div class="selectionDIv">
            <div style="text-align: center; cursor: pointer;">
              <div class="common">
                <img src="./assets/images/newicons/online-video.svg" class="imageResponseive">
              </div>
              <span class="lab">Online Training</span>
            </div>
            <div style="text-align: center; cursor: pointer">
              <div class="common">
                <img src="./assets/images/newicons/life-insurance.svg" class="imageResponseive">
              </div>
              <span class="lab">40+ Insurance Plan</span>
            </div>
            <div style="text-align: center; cursor: pointer">
              <div class="common">
                <img src="./assets/images/newicons/gold-credit-card.svg" class="imageResponseive">
              </div>
              <span class="lab">Zero Investment</span>
            </div>
          </div>
        </div>
        <div class="earnbutn">
          <a class="earnButton" routerLink="/agent-homepage" target="_blank">Earn With Us</a>
        </div>
      </div>
      <div class="col-md-4" style="margin-top: 30px;text-align: center;">
        <img src="./assets/images/newicons/manager.png" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="click4bima">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-8 ">
        <div class="">
          <h1 class="mainh1" style="  color: #fff; font-weight: 200;">Why Click4Bima?</h1>
        </div>
        <div >
          <p class="bima" style="font-weight: bold;">Your one-stop destination <br> for <span
              class="bima2"> effortless insurance <br>search, seamless<br> comparison, quick policy<br> purchase, and
              hassle-free<br> claims </span>processing.</p>
        </div>
        <div style="margin-top: 35px;">
          <table style="width: 100%;">
            <tr>
              <td style="height: 60px;">
                <div style="display: flex; column-gap: 10px;">
                  <img src="./assets/images/newicons/check.svg">
                  <span class="textdiv">Wide Coverage Options</span>
                </div>
              </td>
              <td>
                <div style="display: flex; column-gap: 10px;">
                  <img src="./assets/images/newicons/check.svg">
                  <span class="textdiv">Instant Comparisons</span>
                </div>
              </td>
              <td>
                <div style="display: flex; column-gap: 10px;">
                  <img src="./assets/images/newicons/check.svg">
                  <span class="textdiv">Competitive Premiums</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div style="display: flex; column-gap: 10px;">
                  <img src="./assets/images/newicons/check.svg">
                  <span class="textdiv">Expert Guidance</span>
                </div>
              </td>
              <td>
                <div style="display: flex; column-gap: 10px;">
                  <img src="./assets/images/newicons/check.svg">
                  <span class="textdiv">24X7 Support</span>
                </div>
              </td>
              <td>
                <div style="display: flex; column-gap: 10px;">
                  <img src="./assets/images/newicons/check.svg">
                  <span class="textdiv">Claim Made Easy</span>
                </div>
              </td>
            </tr>
          </table>
          <!-- <div style="display: flex; width: 100%; column-gap: 65px;">
            <div style="display: flex; column-gap: 10px;">
              <img src="./assets/images/newicons/check.svg">
              <span class="textdiv">Wide Coverage Options</span>
            </div>
            <div style="display: flex; column-gap: 10px;">
              <img src="./assets/images/newicons/check.svg">
              <span class="textdiv">Instant Comparisons</span>
            </div>
            <div style="display: flex; column-gap: 10px;">
              <img src="./assets/images/newicons/check.svg">
              <span class="textdiv">Competitive Premiums</span>
            </div>
          </div>
        </div> -->
        <!-- <div style="margin-top: 35px;">
          <div style="display: flex; width: 100%; column-gap: 106px;">
            <div style="display: flex; column-gap: 10px;">
              <img src="./assets/images/newicons/check.svg">
              <span class="textdiv">Expert Guidance</span>
            </div>
            <div style="display: flex; column-gap: 10px;">
              <img src="./assets/images/newicons/check.svg">
              <span class="textdiv">24X7 Support</span>
            </div>
            <div style="display: flex; column-gap: 10px;">
              <img src="./assets/images/newicons/check.svg">
              <span class="textdiv">Claim Made Easy</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
      <div class="col-md-4" style="margin-top: 30px; margin-bottom: 30px; text-align: center;">
        <img src="./assets/images/newicons/family.png" class="imgresponsive">
      </div>
    </div>
  </div>
</div>