import { Component } from '@angular/core';

@Component({
  selector: 'app-grivanceredressal',
  templateUrl: './grivanceredressal.component.html',
  styleUrls: ['./grivanceredressal.component.css']
})
export class GrivanceredressalComponent {

}
