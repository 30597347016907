import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuData, ResponseMenu, ResponseSubMenu } from '../Models/MenuData';
import { MenuService } from '../Services/menu.service';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'app-nav-asidebar',
  templateUrl: './navasidebar.component.html',
  styleUrls: ['./navasidebar.component.css']
})
export class NavasidebarComponent implements OnInit {
  menuList: any = [];
  menuArrayData: MenuData[] = [];
  childmenulist: any = [];
  menuString: any;
  isDashboard: boolean = true;
  isPolicies: boolean = false;
  isProfile: boolean = false;
  isAgentProfile: boolean = false;
  isAgentDashboard: boolean = false;
  isHelp: boolean = false;
  isSetting: boolean = false;
  isBusiness: boolean = false;
  isVehicle: boolean = false;
  isDocument: boolean = false;
  isCustomer: boolean = false;
  isKyc: boolean = false;
  isAgent: boolean = this.generalService.getIsAgent() === 'true' ? true : false;
  currentpage: string = 'Dashboard';
  isSales: boolean = false;
  isCommisson: boolean = false;
  @Output() currentpageEvent = new EventEmitter<any>();
  agent: boolean = false;
  sideValue: any;
  constructor(private _menuservice: MenuService, public router: Router, private generalService : GeneralService) {    
    this.isAgent = this.generalService.getIsAgent() === 'true' ? true : false;
   }

   ngAfterViewInit()
  {
    this.currentpageEvent.emit(this.currentpage);
  }

  setSelectedMenu(){
    switch (this.router.url)
    {
      case '/dashboard' : this.isDashboard = true; this.deselectMenus('isDashboard'); this.currentpage = "Dashboard";
      break;
      case '/policyDetails' : this.isPolicies = true; this.deselectMenus('isPolicies');this.currentpage = "Policies";
      break;
      case '/profile' : this.isProfile = true; this.deselectMenus('isProfile');this.currentpage = "Profile";
      break;
      case '/agent-profile' : this.isAgentProfile = true; this.deselectMenus('isAgentProfile');this.currentpage = "Agent Profile";
      break;
      case '/agent-dashboard' : this.isAgentDashboard = true; this.deselectMenus('isAgentDashboard');this.currentpage = "Agent Dashboard";
      break;
      case '/PersonalInfo' : this.isKyc = true; this.deselectMenus('isKyc');this.currentpage = "Kyc";
      break;
      case '/help' : this.isHelp = true; this.deselectMenus('isHelp');this.currentpage = "Help";
      break;
      case '/settings' : this.isSetting = true; this.deselectMenus('isSetting');this.currentpage = "Setting";
      break;
      case '/businessInfo' : this.isBusiness = true; this.deselectMenus('isBusiness');this.currentpage = "Business";
      break;
      case '/vehicleInfo' : this.isVehicle = true; this.deselectMenus('isVehicle');this.currentpage = "Vehicle";
      break;
      case '/documents' : this.isDocument = true; this.deselectMenus('isDocument');this.currentpage = "Document";
      break;
      case '/customer' : this.isCustomer = true; this.deselectMenus('isCustomer');this.currentpage = "Customers";
      break;
    }
  }
  
  deselectMenus(currentmenu:string)
  {
    this.isDashboard = currentmenu == 'isDashboard';
    this.isPolicies=currentmenu == 'isPolicies';
    this.isProfile=currentmenu == 'isProfile';
    this.isHelp=currentmenu == 'isHelp';
    this.isSetting=currentmenu == 'isSetting';
    this.isBusiness=currentmenu == 'isBusiness';
    this.isVehicle=currentmenu == 'isVehicle';
    this.isDocument=currentmenu == 'isDocument';
    this.isCustomer=currentmenu == 'isCustomer';
    this.isKyc=currentmenu == 'isKyc';
    this.isAgentProfile = currentmenu == 'isAgentProfile';
    this.isAgentDashboard = currentmenu == 'isAgentDashboard';
    this.isSales = currentmenu == 'isSales';
    this.isCommisson = currentmenu == 'isCommisson';
  } 

  async ngOnInit() {
    await this.GetUserMenu();
    // this.sideValue = localStorage.getItem('agentValue');
    // if(this.sideValue == "agent"){
    //   this.agent = true;
    // }
  }

  async GetUserMenu() {
    let request = { UniqueUserId: this.generalService.getUniqueuserid()}
    const res = await this._menuservice.GetUserMenu(request);
    if (res?.isSuccess) {
      let data = res.result;
      sessionStorage.setItem("usermenulist", JSON.stringify(data));
      this.menuList = await this.frameMenu(data);
    }
  }

  async frameMenu(resData: any) {
    let resArrayMenu: ResponseMenu[] = <ResponseMenu[]>resData;
    let resArraySubMenu: ResponseSubMenu[] = [];
    const parentmenu = resArrayMenu.filter(f => f.menuAuthorityId == null || f.menuAuthorityId == 0 || f.menuAuthorityId == undefined);
    const childMenu = resArrayMenu.filter(f => f.menuAuthorityId != null && f.menuAuthorityId != undefined && f.menuAuthorityId > 0);
    parentmenu.forEach(el => {
      const menudata = new MenuData();
      menudata.Id = el.menuId;
      menudata.PrentMenuName = el.menuTitle;
      menudata.RoutingURL = '/' + el.menuCommand;
      menudata.ImageURL = el.menuIcon;
      resArraySubMenu.forEach(sm => {
        if (sm.menuId == el.menuId && el.menuAuthorityId == null) {
          menudata.subMenuId = sm.subTypeId;
        }
      })
      this.menuArrayData.push(menudata);
    });
    childMenu.forEach((el) => {
      const menudata = new MenuData();
      menudata.Id = el.menuId;
      menudata.PrentMenuName = el.menuTitle;
      menudata.RoutingURL = '/' + el.menuCommand;
      menudata.ImageURL = el.menuIcon;
      resArraySubMenu.forEach(sm => {
        if (sm.menuId == el.menuId) {
          menudata.subMenuId = sm.subTypeId;
        }
      })
      const getmenu = this.menuArrayData.filter(f => f.Id == el.menuAuthorityId);
      if (getmenu.length > 0) {
        getmenu[0].SubMenu.push(menudata);
      }
      this.childmenulist.push(menudata);
    });
    return this.menuArrayData;
  }

  changesubmenuclass(id: any) {

  }

  changemainmenuclass(id: any) {

  }


  isToggle: boolean = true;
  sideToggle() {
    this.isToggle = !this.isToggle
    if (this.isToggle) {

    }
    console.log("working")
    // document.getElementById("mySidebar").style.width = "250px";
    // document.getElementById("main").style.marginLeft = "250px";

    // document.getElementById("mySidebar").style.width = "0";
    // document.getElementById("main").style.marginLeft= "0";

  }
  isShow: boolean = false;
  showMore() {
    this.isShow = !this.isShow;
  }
  showLess() {
    this.isShow = !this.isShow
  }

  agentProfile(){
    this.router.navigate(['/agent-profile']);
  }

  agentDashboard(){
    this.router.navigate(['/agent-dashboard']);
  }

  goTo(menuString: any) {
    this.currentpage = `${menuString.charAt(0).toUpperCase()}${menuString.slice(1)}`;
    this.currentpageEvent.emit(this.currentpage);
    if (menuString == "Agent Dashboard") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = true;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/agent-dashboard']);
    } else if (menuString == "dashboard") {
      this.isDashboard = true;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/dashboard']);
    } else if (menuString == "policies") {
      this.isDashboard = false;
      this.isPolicies = true;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/policyDetails']);
    } else if (menuString == "profile") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = true;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/profile']);
    } else if (menuString == "Agent Profile") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;      
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = true;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/agent-profile']);
    }else if (menuString == "kyc") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = true;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/PersonalInfo']);
    }else if (menuString == "help") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = true;
      this.isSetting = false;
      this.isBusiness = false;
      this.isDocument = false;
      this.isVehicle = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/help']);
    } else if (menuString == "settings") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = true;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/settings']);
    }
    else if (menuString == "business") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isCustomer = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = true;
      this.isVehicle = false;
      this.isDocument = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/businessInfo']);
    }
    else if (menuString == "vehicle") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isDocument = false;
      this.isVehicle = true;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/vehicleInfo']);
    }
    else if (menuString == "document") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = true;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/documents']);
    }
    else if (menuString == "customers") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = true;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = false;
      this.router.navigate(['/customer']);
    }
    else if (menuString == "sales") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = true;
      this.isCommisson = false;
      this.router.navigate(['/sales']);
    }
    else if (menuString == "commission") {
      this.isDashboard = false;
      this.isPolicies = false;
      this.isProfile = false;
      this.isHelp = false;
      this.isSetting = false;
      this.isBusiness = false;
      this.isVehicle = false;
      this.isDocument = false;
      this.isCustomer = false;
      this.isKyc = false;
      this.isAgentProfile = false;
      this.isAgentDashboard = false;
      this.isSales = false;
      this.isCommisson = true;
      this.router.navigate(['/agent-commission']);
    }
  }

  // logout() {
  //   this.router.navigate(['/login']);
  // }
}
