<div class="agent-dashboard">
    <div class="row">
        <div class="col-12 dash-box">
            <div *ngIf="dashboard">
                <div class="mt-5">
                    <h3>Agent Dashboard</h3>
                    <span class="font-body1">Welcome to the Insurance Agent Dashboard
                    </span>
                </div>
                <div class="row mt-5">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col">
                                <h6><b>Overview</b></h6>
                            </div>
                            <!-- <div class="col">
                                <select class="form-control float-right" name="" id="">
                                    <option value="">Monthly</option>
                                </select>
                            </div> -->
                        </div>
                        <div class="row">

                            <div class="col-md-4 mt-2">
                                <div class="card  crd p-2">
                                    <div class="row">
                                        <div class="col-md-12 mt-1">
                                            <img class="img-icon" src="../../assets/svgIcon/str.svg" clialt="logo">
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <h1>1546</h1>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <p class="font-body">Commission Points </p>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <span style="color:red"> <img class="img-icon"
                                                    src="../../assets/svgIcon/agent/TrendDown.svg" clialt="logo"
                                                    width="35">
                                                -10.5%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mt-2">
                                <div class="card  crd p-2">
                                    <div class="row">
                                        <div class="col-md-12 mt-1">
                                            <img class="img-icon" src="../../../assets/svgIcon/notepad.svg"
                                                clialt="logo">
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <h1>256</h1>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <p class="font-body">Policy Sold </p>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <span style="color:green"> <img class="img-icon"
                                                    src="../../assets/svgIcon/agent/TrendUp.svg" clialt="logo">
                                                +23.5%</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mt-2">
                                <div class="card  crd p-2">
                                    <div class="row">
                                        <div class="col-md-12 mt-1">
                                            <img class="img-icon" src="../../../assets/svgIcon/percentage.svg"
                                                clialt="logo">
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <h1>8.50%</h1>
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <p class="font-body">Conversion Rate </p>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <span style="color:green"> <img class="img-icon"
                                                    src="../../assets/svgIcon/agent/TrendUp.svg" clialt="logo">
                                                +23.5%</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-10">
                                <h6><b>Sales</b></h6>
                            </div>
                            <!-- <div class="col-md-2">
                                
                            </div> -->
                        </div>
                        <!-- <div class="row">
                            <div class="col p-5 m-1 box" (click)="tabClick(0)" 
                            [ngClass]="{'bg-white' : toggle, 'btn-primary': !toggle}">
                                <img class="img-icon" src="../../assets/svgIcon/agent/Bike.svg" clialt="logo" width="27">
                                <b>Bike</b>
                            </div>
                            <div class="col p-5 m-1 box" (click)="tabClick(1)" 
                            [ngClass]="{'bg-white' : toggle1, 'btn-primary': !toggle1}">
                                <img class="img-icon" src="../../assets/svgIcon/agent/CarProfile.svg" clialt="logo" width="35">
                                <b>Car</b>
                            </div>
                            <div class="col p-5 m-1 box" (click)="tabClick(2)" 
                            [ngClass]="{'bg-white' : toggle2, 'btn-primary': !toggle2}">
                                <img class="img-icon" src="../../assets/svgIcon/agent/Lifebuoy.svg" clialt="logo" width="35">
                                <b>Life</b>
                            </div>
                            <div class="col p-5 m-1 box" (click)="tabClick(3)" 
                            [ngClass]="{'bg-white' : toggle3, 'btn-primary': !toggle3}">
                                <img class="img-icon" src="../../assets/svgIcon/agent/PlusCircle.svg" clialt="logo" width="35">
                                <b>Health</b>
                            </div>
                        </div> -->
                        <div class="row mt-2">
                            <div class="col-md-3 mt-3">
                                <div class="card p-1 crd" (click)="tabClick(0)" [ngClass]="{'bg-white' : toggle, 'btn-primary': !toggle}">
                                    <div class="row" >
                                        <div class="col-md-12 mt-2">
                                          
                                             <div class="row">
                                                <div class="col-md-4 c-4 text-center">
                                                    <img src="../../../assets/svgIcon/bike.svg" alt="">
                                                </div>
                                                <div class="col-md-8 c-8">
                                                    <p class="font-body mt-3" [ngClass]="{'colr' : !toggle}">
                                                        Two-Wheeler
                                                    </p>
                                                </div>
                                             </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 mt-3">
                                <div class="card p-1 crd"  (click)="tabClick(1)" [ngClass]="{'bg-white' : toggle1, 'btn-primary': !toggle1}">
                                    <div class="row">
                                        <div class="col-md-12 mt-2">
                                          
                                             <div class="row">
                                                <div class="col-md-4 c-4 text-center">
                                                    <img src="../../../assets/svgIcon/car.svg" class="mt-3" alt="">
                                                </div>
                                                <div class="col-md-8 c-8">
                                                    <p class="font-body mt-3" [ngClass]="{'colr' : !toggle1}">
                                                        Four-Wheeler
                                                      </p>
                                                </div>
                                             </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 mt-3">
                                <div class="card p-1 crd"  (click)="tabClick(2)" [ngClass]="{'bg-white' : toggle2, 'btn-primary': !toggle2}">
                                    <div class="row">
                                        <div class="col-md-12 mt-2">
                                          
                                             <div class="row">
                                                <div class="col-md-4 c-4 text-center">
                                                    <img src="../../../assets/svgIcon/truckkk.svg" alt="" class="mt-1">
                                                </div>
                                                <div class="col-md-8 c-8">
                                                    <p class="font-body mt-3" [ngClass]="{'colr' : !toggle2}">
                                                        Commercial 
                                                     </p>
                                                </div>
                                             </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 mt-3">
                                <div class="card p-1 crd"  (click)="tabClick(3)" [ngClass]="{'bg-white' : toggle3, 'btn-primary': !toggle3}">
                                    <div class="row">
                                        <div class="col-md-12 mt-2">
                                          
                                             <div class="row">
                                                <div class="col-md-4 c-4 text-center">
                                                    <img src="../../../assets/svgIcon/home.svg" alt="">
                                                </div>
                                                <div class="col-md-8 c-8">
                                                    <p class="font-body mt-3" [ngClass]="{'colr' : !toggle3}">
                                                        Health                                                    </p>
                                                </div>
                                             </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <!-- <div class="row mt-5">
                            <div class="col-7">
                                <h6>Earnings Review</h6>
                            </div>
                            <div class="col-5 float-right">
                                <button type="button" class="btn btn-primary">Clients</button>&nbsp;&nbsp;
                                <button type="button" class="btn btn-primary">Commission</button>
                                <select class="form-control float-right" name="" id="">
                                    <option value="">Monthly</option>
                                </select>
                            </div>
                        </div> -->
                        <div class="row bg-white p-5 m-1 mt-2 box">
                            <canvas baseChart class="chart" [data]="lineChartData" [options]="lineChartOptions"
                                [type]="lineChartType"></canvas>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                            <h6><b>Reminders</b></h6>
                        </div>
                        <div class="bg-white p-5 mt-5 box">
                            <div class="row">
                                <div class="col-2">
                                    <img class="img-icon" src="../../assets/svgIcon/agent/userImage.svg" clialt="logo"
                                        width="50">
                                </div>
                                <div class="col-8">
                                    <h6>Naresh purohit</h6>
                                    <span style="color:red;">Policy expires in 3 days</span>
                                </div>
                                <div class="col-2">
                                    <a href="/"><img class="img-icon" src="../../assets/svgIcon/agent/ArrowRight.svg"
                                            clialt="logo" width="40"></a>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-2">
                                    <img class="img-icon" src="../../assets/svgIcon/agent/userImage.svg" clialt="logo"
                                        width="50">
                                </div>
                                <div class="col-8">
                                    <h6>Naresh purohit</h6>
                                    <span style="color:red;">Policy expires in 3 days</span>
                                </div>
                                <div class="col-2">
                                    <a href="/"><img class="img-icon" src="../../assets/svgIcon/agent/ArrowRight.svg"
                                            clialt="logo" width="40"></a>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-2">
                                    <img class="img-icon" src="../../assets/svgIcon/agent/userImage.svg" clialt="logo"
                                        width="50">
                                </div>
                                <div class="col-8">
                                    <h6>Naresh purohit</h6>
                                    <span style="color:red;">Policy expires in 3 days</span>
                                </div>
                                <div class="col-2">
                                    <a href="/"><img class="img-icon" src="../../assets/svgIcon/agent/ArrowRight.svg"
                                            clialt="logo" width="40"></a>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-2">
                                    <img class="img-icon" src="../../assets/svgIcon/agent/userImage.svg" clialt="logo"
                                        width="50">
                                </div>
                                <div class="col-8">
                                    <h6>Naresh purohit</h6>
                                    <span style="color:red;">Policy expires in 3 days</span>
                                </div>
                                <div class="col-2">
                                    <a href="/"><img class="img-icon" src="../../assets/svgIcon/agent/ArrowRight.svg"
                                            clialt="logo" width="40"></a>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-2">
                                    <img class="img-icon" src="../../assets/svgIcon/agent/userImage.svg" clialt="logo"
                                        width="50">
                                </div>
                                <div class="col-8">
                                    <h6>Naresh purohit</h6>
                                    <span style="color:red;">Policy expires in 3 days</span>
                                </div>
                                <div class="col-2">
                                    <a href="/"><img class="img-icon" src="../../assets/svgIcon/agent/ArrowRight.svg"
                                            clialt="logo" width="40"></a>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-2">
                                    <img class="img-icon" src="../../assets/svgIcon/agent/userImage.svg" clialt="logo"
                                        width="50">
                                </div>
                                <div class="col-8">
                                    <h6>Naresh purohit</h6>
                                    <span style="color:red;">Policy expires in 3 days</span>
                                </div>
                                <div class="col-2">
                                    <a href="/"><img class="img-icon" src="../../assets/svgIcon/agent/ArrowRight.svg"
                                            clialt="logo" width="40"></a>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-12">
                            <img class="" src="../../assets/images/banner/01 Motor Insurance.jpg" clialt="logo" style="border-radius: 15px;"
                                width="100%">
                        </div></div>
                    </div>

                </div>
                <div class="row mt-5">
                  
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-10">
                                <h6><b>Leads</b></h6>
                            </div>
                            <div class="col-md-2 text-end">
                                <a href="">View All</a>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col bg-white p-5 m-1 box">
                                <div class="row">
                                    <div class="col-3">
                                        <img class="img-icon" src="../../assets/svgIcon/agent/attUser.svg" clialt="logo"
                                            width="35">
                                    </div>
                                    <div class="col-9">
                                        <h5 style="color:#10A0C1">102</h5>
                                        <p class="font-body">Total</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col bg-white p-5 m-1 box">
                                <div class="row">
                                    <div class="col-3">
                                        <img class="img-icon" src="../../assets/svgIcon/agent/flagUser.svg"
                                            clialt="logo" width="35">
                                    </div>
                                    <div class="col-9">
                                        <h5 style="color:#3A8247">98</h5>
                                        <p class="font-body">Successful</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col bg-white p-5 m-1 box">
                                <div class="row">
                                    <div class="col-3">
                                        <img class="img-icon" src="../../assets/svgIcon/agent/msgClock.svg"
                                            clialt="logo" width="35">
                                    </div>
                                    <div class="col-9">
                                        <h5 style="color:#E98315">2</h5>
                                        <p class="font-body">Pending</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col bg-white p-5 m-1 box">
                                <div class="row">
                                    <div class="col-3">
                                        <img class="img-icon" src="../../assets/svgIcon/agent/failLead.svg"
                                            clialt="logo" width="35">
                                    </div>
                                    <div class="col-9">
                                        <h5 style="color:#DF3A4D">2</h5>
                                        <p class="font-body">Failed</p>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-3 mt-2">
                                <div class="card p-1">
                                    <div class="row">
                                        <div class="col-md-3 c-4">
                                            <img class="img-icon" src="../../assets/svgIcon/agent/attUser.svg" clialt="logo"
                                                width="100%">
                                        </div>
                                        <div class="col-md-9 c-8">
                                            <h5 style="color:#10A0C1">102</h5>
                                            <p class="font-body">Total</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 mt-2">
                                <div class="card p-1">
                                    <div class="row">
                                        <div class="col-md-3 c-4">
                                            <img class="img-icon"src="../../assets/svgIcon/agent/flagUser.svg" clialt="logo"
                                                width="100%">
                                        </div>
                                        <div class="col-md-9 c-8">
                                            <h5 style="color:#3A8247">98</h5>
                                        <p class="font-body">Successful</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 mt-2">
                                <div class="card p-1">
                                    <div class="row">
                                        <div class="col-md-3 c-4">
                                            <img class="img-icon"src="../../assets/svgIcon/agent/flagUser.svg" clialt="logo"
                                                width="100%">
                                        </div>
                                        <div class="col-md-9 c-8">
                                            <h5 style="color:#E98315">2</h5>
                                            <p class="font-body">Pending</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="col-md-3 mt-2">
                                <div class="card p-1">
                                    <div class="row">
                                        <div class="col-md-3 c-4">
                                            <img class="img-icon" src="../../assets/svgIcon/agent/failLead.svg" clialt="logo"
                                                width="100%">
                                        </div>
                                        <div class="col-md-9 c-8">
                                            <h5 style="color:#DF3A4D">2</h5>
                                            <p class="font-body">Failed</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 p-4">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-10">
                                <h6>Insurance Sales</h6>
                            </div>
                            <div class="col-md-2 text-end">
                                <a href="">View All</a>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6 mt-2">
                                        <div class="card p-2">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/bickWithBack.svg" alt="">
                                                </div>
                                                <div class="col-md-8">
                                                    <span class="spn">Plan Type</span>
                                                    <p class="font-body"  style="font-size: 12px;">Compulsory Personal Accident (Owner-Driver)</p>
                                                </div>
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/right-arrow.svg" alt="">
                                                </div>
                                                <div class="col-md-5 mt-2">
                                                    <img src="../../../assets/images/united.png" alt="" width="100%">
                                                </div>
                                                <div class="col-md-7 mt-2">
                                                    <p class="p-c mt-2" ><b>United india insurance</b></p>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="card p-1">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <span class="spn">Expiry Date</span>
                                                                <p class="p-tx mt-2">31-Dec-2024</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Status</span>
                                                                <p class="p-tx mt-2">Policy Issued</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Premium</span>
                                                                <p class="p-tx mt-2">₹ 389 (Yearly)</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <div class="card p-2">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/bickWithBack.svg" alt="">
                                                </div>
                                                <div class="col-md-8">
                                                    <span class="spn">Plan Type</span>
                                                    <p class="font-body"  style="font-size: 12px;">Compulsory Personal Accident (Owner-Driver)</p>
                                                </div>
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/right-arrow.svg" alt="">
                                                </div>
                                                <div class="col-md-5 mt-2">
                                                    <img src="../../../assets/images/united.png" alt="" width="100%">
                                                </div>
                                                <div class="col-md-7 mt-2">
                                                    <p class="p-c mt-2" ><b>United india insurance</b></p>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="card p-1">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <span class="spn">Expiry Date</span>
                                                                <p class="p-tx mt-2">31-Dec-2024</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Status</span>
                                                                <p class="p-tx mt-2">Policy Issued</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Premium</span>
                                                                <p class="p-tx mt-2">₹ 389 (Yearly)</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <div class="card p-2">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/bickWithBack.svg" alt="">
                                                </div>
                                                <div class="col-md-8">
                                                    <span class="spn">Plan Type</span>
                                                    <p class="font-body"  style="font-size: 12px;">Compulsory Personal Accident (Owner-Driver)</p>
                                                </div>
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/right-arrow.svg" alt="">
                                                </div>
                                                <div class="col-md-5 mt-2">
                                                    <img src="../../../assets/images/united.png" alt="" width="100%">
                                                </div>
                                                <div class="col-md-7 mt-2">
                                                    <p class="p-c mt-2" ><b>United india insurance</b></p>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="card p-1">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <span class="spn">Expiry Date</span>
                                                                <p class="p-tx mt-2">31-Dec-2024</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Status</span>
                                                                <p class="p-tx mt-2">Policy Issued</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Premium</span>
                                                                <p class="p-tx mt-2">₹ 389 (Yearly)</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <div class="card p-2">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/bickWithBack.svg" alt="">
                                                </div>
                                                <div class="col-md-8">
                                                    <span class="spn">Plan Type</span>
                                                    <p class="font-body"  style="font-size: 12px;">Compulsory Personal Accident (Owner-Driver)</p>
                                                </div>
                                                <div class="col-md-2">
                                                    <img src="../../../assets/svgIcon/right-arrow.svg" alt="">
                                                </div>
                                                <div class="col-md-5 mt-2">
                                                    <img src="../../../assets/images/united.png" alt="" width="100%">
                                                </div>
                                                <div class="col-md-7 mt-2">
                                                    <p class="p-c mt-2" ><b>United india insurance</b></p>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="card p-1">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <span class="spn">Expiry Date</span>
                                                                <p class="p-tx mt-2">31-Dec-2024</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Status</span>
                                                                <p class="p-tx mt-2">Policy Issued</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <span class="spn">Premium</span>
                                                                <p class="p-tx mt-2">₹ 389 (Yearly)</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col">
                                <h6>Link Status</h6>
                            </div>
                            <div class="col text-end">
                                <a href="">View All</a>
                            </div>
                        </div>
                        <div class="row bg-white box mb-20">
                            <canvas baseChart class="chart" [data]="doughnutChartData" [type]="doughnutChartType">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="customer">
                <app-agentcustomer></app-agentcustomer>
            </div>
            <div *ngIf="profile">
                <app-agent-profile></app-agent-profile>
            </div>
            <div *ngIf="training">
                <app-agenttraining></app-agenttraining>
            </div>
            <div class="row">
                <app-agentfooter></app-agentfooter>
            </div>
        </div>

    </div>
</div>
<script>
    function changeTab(event, tabNumber) {
        var tabs = document.getElementsByClassName("list-item");
        for (var i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove("active");
        }
        event.target.classList.add("active");
    }
</script>