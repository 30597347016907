import { Component,Input,ElementRef ,OnInit,ViewChild,AfterViewInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http'; // Import HttpCl

@Component({
  selector: 'app-royal-sundaram-payment',
  templateUrl: './royal-sundaram-payment.component.html',
  styleUrls: ['./royal-sundaram-payment.component.css']
})
export class RoyalSundaramPaymentComponent implements OnInit,AfterViewInit {
  @Input() royalDetails :any;
  @ViewChild('file', { read: ElementRef }) myDiv: ElementRef<HTMLElement>;
  // @ViewChild('myDiv') myDiv: ElementRef;
  constructor(private route: ActivatedRoute,private http: HttpClient){
  
  } 
  ngAfterViewInit() {
    this.submitForm();
}

  submitForm() {
    const form = document.getElementById('PostForm') as HTMLFormElement;
     form.submit();
  }
  
    
  ngOnInit() {
   
  }
}
