<section class="banner_section">
  <div class="content-box bike_insurance_div" [ngStyle]="{'margin-top':!isAccessToken ? '' : '' }">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating">
    </ngx-spinner>
    <div class="container">
      <div class="menu_tabs" *ngIf="isProducts">
        <div class="user-menu card" *ngIf="!isViewQuotes">
          <ul class="nav nav-tabs row" role="tablist">
            <li class="nav-item menu-item col" [ngClass]="{'nav-item': isBike}" (click)="goTo('Bike')">
              <a href="#bike" class="nav-link active" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/bike.svg" alt="">&nbsp;&nbsp;<span>{{langList.two_wheeler}}</span>
              </a>
            </li>
            <li class="nav-item menu-item col" [ngClass]="{'nav-item': isCar}" (click)="goTo('Car')">
              <a href="#car" class="nav-link" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/car.svg" alt="">&nbsp;&nbsp;<span>{{langList.four_wheeler}}</span>
              </a>
            </li>
            <li class="nav-item menu-item col" [ngClass]="{'nav-item': isHealth}" (click)="goTo('Health')">
              <a href="#health" class="nav-link" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/health.svg" alt="">&nbsp;&nbsp;<span>{{langList.health}}</span>
              </a>
            </li>
            <li class="nav-item menu-item col" [ngClass]="{'nav-item': isHome}" (click)="goTo('Home')">
              <a href="#home" class="nav-link" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/home.svg" alt="">&nbsp;&nbsp;<span>{{langList.commercial_vehicle}}</span>
              </a>
            </li>
            <li class="nav-item menu-item col" [ngClass]="{'nav-item': isProperty}" (click)="goTo('property')">
              <a href="#property" class="nav-link" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/home.svg" alt="">&nbsp;&nbsp;<span>Property</span>
              </a>
            </li>
            <li class="nav-item menu-item col" [ngClass]="{'nav-item': isTravel}" (click)="goTo('Travel')">
              <a href="#travel" class="nav-link" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/home.svg" alt="">&nbsp;&nbsp;<span>Travel</span>
              </a>
            </li>
            <!-- <li class="nav-item menu-item col" [ngClass]="{'nav-item': isLife}" (click)="goTo('Life')">
              <a href="#5a" class="nav-link" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/life.svg" alt="">&nbsp;&nbsp;<span>Life</span>
              </a>
            </li>
            <li class="nav-item menu-item col" [ngClass]="{'nav-item': isTerm}" (click)="goTo('Term')">
              <a href="#6a" class="nav-link" data-bs-toggle="tab">
                <img src="../../assets/svgIcon/term.svg" alt="">&nbsp;&nbsp;<span>Term</span>
              </a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="menu_tabs11">
      <div class="user-menu card" *ngIf="!isViewQuotes">
        <ul class="nav nav-tabs row" role="tablist">
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isBike}" (click)="goTo('Bike')">
            <a href="#bike" class="nav-link active" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/bike.svg" alt="">&nbsp;&nbsp;<span>Bike</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isCar}" (click)="goTo('Car')">
            <a href="#car" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/car.svg" alt="">&nbsp;&nbsp;<span>Car</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isHealth}" (click)="goTo('Health')">
            <a href="#health" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/health.svg" alt="">&nbsp;&nbsp;<span>Health</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isHome}" (click)="goTo('Home')">
            <a href="#home" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/home.svg" alt="">&nbsp;&nbsp;<span>Home</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isLife}" (click)="goTo('Life')">
            <a href="#5a" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/life.svg" alt="">&nbsp;&nbsp;<span>Life</span>
            </a>
          </li>
          <li class="nav-item menu-item col" [ngClass]="{'nav-item': isTerm}" (click)="goTo('Term')">
            <a href="#6a" class="nav-link" data-bs-toggle="tab">
              <img src="../../assets/svgIcon/term.svg" alt="">&nbsp;&nbsp;<span>Term</span>
            </a>
          </li>
        </ul>
      </div>
    </div> -->

    <div class="container-fluid" *ngIf="knowBike">
      <div class="tab-content tab_content_div clearfix">
        <div id="homemain" class="tab-pane maindiv" [ngClass]="{'active': currentTab ==''}">
          <div class="container" style="padding-top: 20px;">
            <!-- <div class="row mt-4">
              <div class="col-12">
                <h2 class="text-center text-main" style="font-size: 32px !important;"> Buying insurance is now simple {{ isPrtner() ? '' : 'with Click4bima'}}</h2>
              </div>
              <div class="col-12 mt-4 mb-15">
                <div class="row justify-content-center">
                  <div class="col-12 text-center">
                    <span class="">Select to buy easy and fast insurance for you and your family!</span>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-2  d-grid justify-content-center" (click)="goTo('Bike')">
                <img src="/assets/svgIcon/bike_home.svg" alt="" class="img-fluid ">
                <h4 class="text-center mt-4">{{langList.two_wheeler}}</h4>
              </div>
              <div class="col-6 col-md-2  d-grid justify-content-center" (click)="goTo('Car')">
                <img src="/assets/svgIcon/car_home.svg" alt="" class="img-fluid ">
                <h4 class="text-center mt-4">{{langList.four_wheeler}}</h4>
              </div>
              <div class="col-6 col-md-2  d-grid justify-content-center" (click)="goTo('Health')">
                <img src="/assets/svgIcon/health_home.svg" alt="" class="img-fluid ">
                <h4 class="text-center mt-4">{{langList.health}}</h4>
              </div>
              <div class="col-6 col-md-2  d-grid justify-content-center" (click)="goTo('Home')">
                <img src="/assets/svgIcon/comm_home.svg" alt="" class="img-fluid ">
                <h4 class="text-center mt-4">{{langList.commercial_vehicle}}</h4>
              </div>
              <div class="col-6 col-md-2  d-grid justify-content-center" (click)="goTo('Property')">
                <img src="../../assets/svgIcon/propertys.svg" alt="" class="img-fluid ">
                <h4 class="text-center mt-4">Property</h4>
              </div>
              <div class="col-6 col-md-2  d-grid justify-content-center" (click)="goTo('Travel')" style="    position: relative;
              bottom: 2px;">
                <img  src="../../assets/svgIcon/Travel1.svg" alt="" class="img-fluid " style="height:112%">
                <h4 class="text-center " style="position: relative;
                top: 48%;">Travel</h4>
              </div>
            </div> -->
            <div class="row">
              <div class="col-md-8 ">
                <div class="">
                  <h1 class="mainh1">Buying Insurance is Now Simple with Us</h1>
                  <p class="mainp">Select to buy easy and fast
                    insurance for you and your family!</p>

                </div>
                <div style="margin-top: 35px;">
                  <div class="selectionDIv">
                    <div style="text-align: center; cursor: pointer;" (click)="goTo('Car')">
                      <div>
                        <img src="./assets/images/newicons/four.svg" class="imageResponseive">
                      </div>
                      <span class="lab">Four-Wheeler</span>
                    </div>
                    <div style="text-align: center; cursor: pointer" (click)="goTo('Bike')">
                      <div>
                        <img src="./assets/images/newicons/two.svg"  class="imageResponseive">
                      </div>
                      <span class="lab">Two-Wheeler</span>
                    </div>
                    <div style="text-align: center; cursor: pointer"  (click)="goTo('Property')">
                      <div>
                        <img src="./assets/images/newicons/prop.svg"  class="imageResponseive">
                      </div>
                      <span class="lab">Property</span>
                    </div>

                  </div>
                </div>

                <div  style="margin-top: 20px;">
                  <div class="selectionDIv">
                    <div style="text-align: center; cursor: pointer;"  (click)="goTo('Health')">
                      <div>
                        <img src="./assets/images/newicons/health.svg"  class="imageResponseive">
                      </div>
                      <span class="lab">Health</span>
                    </div>
                    <div style="text-align: center; cursor: pointer" (click)="goTo('Home')">
                      <div>
                        <img src="./assets/images/newicons/comm.svg"  class="imageResponseive">
                      </div>
                      <span class="lab">Commercial Vehicle</span>
                    </div>
                    <div style="text-align: center; cursor: pointer" (click)="goTo('Travel')">
                      <div>
                        <img src="./assets/images/newicons/travel.svg"  class="imageResponseive">
                      </div>
                      <span class="lab">Travel</span>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="col-md-4" style="text-align: center;">
                <img src="./assets/images/newicons/male.png" class="mainImg">
              </div>
            </div>

          </div>
        </div>
        <div id="bike" class="tab-pane " [ngClass]="{'active': currentTab =='Bike'}">
          <bike></bike>
        </div>
        <div id="car" class="tab-pane " [ngClass]="{'active': currentTab =='Car'}">
          <car></car>
        </div>

        <div id="health" class="tab-pane" [ngClass]="{'active': currentTab =='Health'}">
          <div > 
            <!-- class="container"  -->
            <app-healthinsurance-policy></app-healthinsurance-policy>
          </div>
        </div>
        <div id="home" class="tab-pane" [ngClass]="{'active': currentTab =='Home'}">
          <app-commercial-policy></app-commercial-policy>
        </div>
        <div id="property" class="tab-pane" [ngClass]="{'active': currentTab =='Property'}">
          <div >
            <app-property-insurance></app-property-insurance>
          </div>
        </div>
        <div id="travel" class="tab-pane" [ngClass]="{'active': currentTab =='Travel'}">
          <div >
            <app-travelinsurance-policy></app-travelinsurance-policy>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PopUp Register Details -->
  <!-- Owner Form -->
  <div class="modal modal-fade" tabindex="-1" role="dialog" [ngStyle]="{'display':display1}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Owner</h4>
          <button type="button" class="close" aria-label="Close" (click)="OwnerClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="userReactForm" class="needs-validation" novalidate="" autocomplete="off">
            <div class="row">
              <div class="form-group">
                <label class="form-label float-start" style="margin-left: 0px;"> Users:</label>
                <select class="form-select" #mycity1 (change)='changeUser(mycity1.value)'
                  aria-label="Default select example" name="userAccountCity">
                  <option value="" selected>Select User</option>
                  <option *ngFor="let user of userList" [value]="user.uniqueUserId">
                    {{user.firstName}}
                  </option>
                </select>
              </div>


              <div class="form-group">
                <label class="float-start">Your Name : </label>
                <input type="text" name="UserName" placeholder="User Name " class="form-control"
                  formControlName="userName">
              </div>
              <div class="form-group">
                <label class="float-start">Email Address : </label>
                <input type="text" name="UserEmail" placeholder="User Email Address " class="form-control"
                  formControlName="userEmail">
              </div>
              <div class="form-group">
                <label class="float-start">Mobile Number : </label>
                <input type="text" name="UserMobile" placeholder="User Mobile Number " class="form-control"
                  formControlName="phoneNumber">
              </div>
              <div class="form-group">
                <label class="float-start">Pancard : </label>
                <input type="text" name="UserPancard" placeholder="User Pancard " class="form-control"
                  formControlName="phoneNumber">
              </div>
              <div class="form-group">
                <label class="float-start">Your PinCode : </label>
                <input type="text" name="UserPincode" placeholder="User Pincode " class="form-control"
                  formControlName="pincodeId">
              </div>
              <div class="form-group">
                <label class="float-start">Street Address : </label>
                <input type="text" name="UserAddress" placeholder="User Street Address " class="form-control"
                  formControlName="streetAddress">
              </div>
            </div>
            <div class="float-end text-center mb-2">
              <button class="btn btn-primary" (click)="OwnerInfoDetails()">Confirm</button> &nbsp;
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Nominee Form -->
  <div class="modal modal-fade" tabindex="-1" role="dialog" [ngStyle]="{'display':display2}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Nominee</h4>
          <button type="button" class="close" aria-label="Close" (click)="NonineeClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form needs-validation" [formGroup]="nomineeReactForm" novalidate="" autocomplete="off">
            <div class="row">
              <div class="form-group">
                <label class="float-start">Nominee Name : </label>
                <input type="text" name="UserNomineeName" placeholder="User Nominee Name " class="form-control"
                  formControlName="nomineeName">
              </div>
              <div class="form-group">
                <label class="float-start">Relation : </label>
                <input type="text" name="Relation" placeholder="Relation " formControlName="relationshipWithNominee"
                  class="form-control">
              </div>
              <div class="form-group">
                <label class="float-start">Age : </label>
                <input type="text" name="Age" placeholder="Age " formControlName="nomineeAge" class="form-control">
              </div>
              <div class="form-group">
                <label class="input-label" for="gender">Gender</label><br>
                <input class="my-3" type="radio" id="male" formControlName="gender" name="gender" value="Male"> Male
                &nbsp;&nbsp;
                <input class="my-3" type="radio" id="female" formControlName="gender" name="gender" value="Female">
                Female &nbsp;&nbsp;
                <input class="my-3" type="radio" id="other" formControlName="gender" name="gender" value="Other">
                Other
              </div>
            </div>
            <div class="float-end text-center mb-2">
              <button class="btn btn-primary" (click)="NomineDetais()">Confirm</button> &nbsp;
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Vehicle Form -->
  <div class="modal modal-fade" tabindex="-1" role="dialog" [ngStyle]="{'display':display3}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Vehicle</h4>
          <button type="button" class="close" aria-label="Close" (click)="vehicleClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form id="kt_modal_add_user_form" class="form">
            <div class="row">

              <div class="form-group">
                <label class="float-start">Registration Number : </label>
                <input type="text" name="RegistrationNumber" [(ngModel)]="registrationData.rc_number"
                  placeholder="Registration Number " class="form-control">
              </div>
              <div class="form-group">
                <label class="float-start">Engine Numver : </label>
                <input type="text" name="EngineNumver" [(ngModel)]="registrationData.vehicle_engine_number"
                  placeholder="Engine Numver " class="form-control">
              </div>
              <div class="form-group">
                <label class="float-start">Chasis Number : </label>
                <input type="text" name="ChasisNumber" [(ngModel)]="registrationData.vehicle_chasi_number"
                  placeholder="Chasis Number " class="form-control">
              </div>
              <div class="form-group">
                <label class="float-start">Vehicle Registration Date : </label>
                <input type="text" name="VehicleRegistrationDate" [(ngModel)]="registrationData.registration_date"
                  placeholder="Vehicle Registration Date " class="form-control">
              </div>
            </div>
            <div class="float-end text-center mb-2">
              <button class="btn btn-primary" (click)="registrerDetails()">Confirm</button> &nbsp;
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="healthinsurancepolicyindividual">
    <div class="popup2 col-md-3 col-11">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">
          <h3 class="heading insurance_heading">Individual</h3>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <div>
            <div class="card p-2">
              <!-- <ul class="row p-3">
                <li class="col health-menu" [ngClass]="{'selected-menu':isWhoInsure, 'filled-menu':isWhoInsureFilled}"
                  (click)="selectedMenu('whoInsure')">
                  <a href="#1a" data-toggle="tab">
                    <h3>Who Insure</h3>
                  </a>
                </li>&nbsp;
                <li class="col health-menu" [ngClass]="{'selected-menu':isAge, 'filled-menu':isAgeFilled}"
                  (click)="selectedMenu('Age')">
                  <a href="#2a" data-toggle="tab">
                    <h3>Age</h3>
                  </a>
                </li>&nbsp;
                <li class="col health-menu" [ngClass]="{'selected-menu':isLocation, 'filled-menu':isLocationFilled}"
                  (click)="selectedMenu('Location')">
                  <a href="#3a" data-toggle="tab">
                    <h3>Location</h3>
                  </a>
                </li>&nbsp;
                <li class="col health-menu" [ngClass]="{'selected-menu':isDetails, 'filled-menu':isDetailsFilled}"
                  (click)="selectedMenu('Details')">
                  <a href="#4a" data-toggle="tab">
                    <h3>Details</h3>
                  </a>
                </li>
              </ul> -->
              <div class="tab-content clearfix">
                <div id="1a" class="tab-pane active mt-4">
                  <h4 style="padding: 0px 9px;">Who Would you like to insure?</h4>
                  <div class="row p-3">
                    <div class="col-6">
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Self</h5>
                          <input type="checkbox" (change)="selectedCard('Self')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Spouse</h5>
                          <input type="checkbox" (click)="selectedCard('Spouse')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Son</h5>
                          <input type="checkbox" (click)="selectedCard('Son')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Daughter</h5>
                          <input type="checkbox" (click)="selectedCard('Daughter')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Father</h5>
                          <input type="checkbox" (click)="selectedCard('Father')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Mother</h5>
                          <input type="checkbox" (click)="selectedCard('Mother')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Other</h5>
                          <input type="checkbox" (click)="selectedCard('Other')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Other</h5>
                          <input type="checkbox" (click)="selectedCard('Other')">
                        </li>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="counter_div conter_div1">
                        <li>
                          <span class="plus_span" (click)="counterFunction('decrement')"> - </span>
                        </li>
                        <li>
                          <span class="number_span"> {{counterValue}} </span>
                        </li>
                        <li>
                          <span class="minus_span" (click)="counterFunction('increment')"> + </span>
                        </li>
                      </div>
                      <!-- <div class="counter_div">
                        <li>
                          <span class="plus_span"> - </span>
                        </li>
                        <li>
                          <span class="number_span"> 1 </span>
                        </li>
                        <li>
                          <span class="minus_span"> + </span>
                        </li>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div id="2a" class="tab-pane mt-4">
                  <h4 style="padding: 0px 9px;">How old each member?</h4>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <label for="age" class="d-flex">Your age</label>
                      <select class="form-select" name="age" id="age">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                    <div class="col">
                      <label for="age" class="d-flex">Spouse's age</label>
                      <select class="form-select" name="spouse" id="spouse">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                  </div>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <label for="age" class="d-flex">1st Daughter</label>
                      <select class="form-select" name="age" id="age">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                    <div class="col">
                      <label for="age" class="d-flex">2nd Daughter</label>
                      <select class="form-select" name="spouse" id="spouse">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div id="3a" class="tab-pane mt-4">
                  <h4 style="padding: 0px 9px;">Where do you live?</h4>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <div class="d-flex">
                        <label for="pincode" class="d-flex">Your Pincode</label>
                        <!-- <p class="link-primary">Don't Know Pincode</p> -->
                      </div>
                      <input type="text" class="form-control" #pinCode (blur)="pincodeUserChangeEvent(pinCode)"
                        value="342312    Jaipur, Rajasthan, India">
                    </div>
                    <div class="col">
                      <div><label for="address" class="d-flex">Street Address</label></div>
                      <input type="text" class="form-control">
                    </div>

                  </div>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <label for="address" class="d-flex">Select Village</label>
                      <select class="form-select" name="age" id="age">
                        <option value="select">Select Village</option>
                        <option *ngFor="let pincode of pincodeMaster" [value]="pincode.id">
                          {{pincode.cityVillageArea}}
                        </option>

                      </select>
                    </div>
                    <div class="col">
                    </div>
                  </div>
                </div>
                <div id="4a" class="tab-pane mt-4" style="padding: 0px 9px;">
                  <h4>Does any member have an exhausting illness or medical history?</h4>
                  <div class="card shadow col p-3">
                    <div class="d-flex">
                      <div>
                        <input type="checkbox">
                      </div>&nbsp;&nbsp;
                      <div>
                        <h6 class="text-left">Existing illness</h6>
                        <span class="font-body text-left d-flex">Blood pressure, Diabetes, Heart condition, Asthma,
                          Thyroid, Cancer etc.</span>
                      </div>
                    </div>
                  </div><br>
                  <div class="card shadow col p-3">
                    <div class="d-flex">
                      <div>
                        <input type="checkbox">
                      </div>&nbsp;&nbsp;
                      <div>
                        <h6 class="text-left">Surgical procedure</h6>
                        <span class="font-body">Appendix, Gall bladder, C-section etc.</span>
                      </div>
                    </div>
                  </div><br>
                  <div class="card shadow col p-3">
                    <div class="d-flex">
                      <div>
                        <input type="checkbox">
                      </div>&nbsp;&nbsp;
                      <div>
                        <h6>None of these</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 buttons_con_div">
                <a href="#{{selectedTab}}a" data-toggle="tab">
                  <button class="btn btn-light float-start" (click)="back()">Back</button>
                </a>
                <a href="#{{selectedTab}}a" data-toggle="tab">
                  <button class="btn btn-primary float-end" *ngIf="this.selectedTab <= 4" (click)="continue()">
                    Continue
                  </button>
                  <button class="btn btn-primary float-end" *ngIf="this.selectedTab > 4" (click)="viewIllness()">View
                    plans</button>
                </a>
              </div>
            </div>
            <div class="card shadow my-5 p-2" *ngIf="isIllness">
              <div class="mt-4">
                <h4 style="padding: 0px 40px;">Tell us about your existing illnesss</h4>
                <div class="row justify-content-between p-3">
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>Diabetes</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>BP / Hypertension</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                </div>
                <div class="row justify-content-between p-3">
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>Heart Aliments</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>Other health issues</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                </div>
              </div>
              <div class="p-5">
                <button class="btn btn-light float-start" (click)="viewIllness()">Back</button>
                <button class="btn btn-primary float-end" routerLink="/health">View plans</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1 modal right" *ngIf="healthinsurancepolicyfamily">
    <div class="popup2 col-md-3 col-11">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">
          <h3 class="heading insurance_heading">Individual</h3>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <div>
            <div class="card p-2">
              <!-- <ul class="row p-3">
                  <li class="col health-menu" [ngClass]="{'selected-menu':isWhoInsure, 'filled-menu':isWhoInsureFilled}"
                    (click)="selectedMenu('whoInsure')">
                    <a href="#1a" data-toggle="tab">
                      <h3>Who Insure</h3>
                    </a>
                  </li>&nbsp;
                  <li class="col health-menu" [ngClass]="{'selected-menu':isAge, 'filled-menu':isAgeFilled}"
                    (click)="selectedMenu('Age')">
                    <a href="#2a" data-toggle="tab">
                      <h3>Age</h3>
                    </a>
                  </li>&nbsp;
                  <li class="col health-menu" [ngClass]="{'selected-menu':isLocation, 'filled-menu':isLocationFilled}"
                    (click)="selectedMenu('Location')">
                    <a href="#3a" data-toggle="tab">
                      <h3>Location</h3>
                    </a>
                  </li>&nbsp;
                  <li class="col health-menu" [ngClass]="{'selected-menu':isDetails, 'filled-menu':isDetailsFilled}"
                    (click)="selectedMenu('Details')">
                    <a href="#4a" data-toggle="tab">
                      <h3>Details</h3>
                    </a>
                  </li>
                </ul> -->
              <div class="tab-content clearfix">
                <div id="1a" class="tab-pane active mt-4">
                  <h4 style="padding: 0px 9px;">Who Would you like to insure?</h4>
                  <div class="row p-3">
                    <div class="col-6">
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Self</h5>
                          <input type="checkbox" (change)="selectedCard('Self')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Spouse</h5>
                          <input type="checkbox" (click)="selectedCard('Spouse')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Son</h5>
                          <input type="checkbox" (click)="selectedCard('Son')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Daughter</h5>
                          <input type="checkbox" (click)="selectedCard('Daughter')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Father</h5>
                          <input type="checkbox" (click)="selectedCard('Father')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Mother</h5>
                          <input type="checkbox" (click)="selectedCard('Mother')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Other</h5>
                          <input type="checkbox" (click)="selectedCard('Other')">
                        </li>
                      </div>
                      <div class="p-4 mb-5" [ngClass]="isSelf ? 'selected-card' : 'card-view'">
                        <li class="d-flex justify-content-between">
                          <h5>Other</h5>
                          <input type="checkbox" (click)="selectedCard('Other')">
                        </li>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="counter_div conter_div1">
                        <li>
                          <span class="plus_span" (click)="counterFunction('decrement')"> - </span>
                        </li>
                        <li>
                          <span class="number_span"> {{counterValue}} </span>
                        </li>
                        <li>
                          <span class="minus_span" (click)="counterFunction('increment')"> + </span>
                        </li>
                      </div>
                      <!-- <div class="counter_div">
                          <li>
                            <span class="plus_span"> - </span>
                          </li>
                          <li>
                            <span class="number_span"> 1 </span>
                          </li>
                          <li>
                            <span class="minus_span"> + </span>
                          </li>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div id="2a" class="tab-pane mt-4">
                  <h4 style="padding: 0px 9px;">How old each member?</h4>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <label for="age" class="d-flex">Your age</label>
                      <select class="form-select" name="age" id="age">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                    <div class="col">
                      <label for="age" class="d-flex">Spouse's age</label>
                      <select class="form-select" name="spouse" id="spouse">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                  </div>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <label for="age" class="d-flex">1st Daughter</label>
                      <select class="form-select" name="age" id="age">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                    <div class="col">
                      <label for="age" class="d-flex">2nd Daughter</label>
                      <select class="form-select" name="spouse" id="spouse">
                        <option value="select">Select Age</option>
                        <option value="21">1</option>
                        <option value="22">2</option>
                        <option value="23">3</option>
                        <option value="21">4</option>
                        <option value="22">5</option>
                        <option value="23">6</option>
                        <option value="21">7</option>
                        <option value="22">8</option>
                        <option value="23">9</option>
                        <option value="21">10</option>
                        <option value="22">12</option>
                        <option value="23">13</option>
                        <option value="21">14</option>
                        <option value="22">15</option>
                        <option value="23">16</option>
                        <option value="21">17</option>
                        <option value="22">18</option>
                        <option value="23">19</option>
                        <option value="21">20</option>
                        <option value="22">21</option>
                        <option value="23">22</option>
                        <option value="21">23</option>
                        <option value="22">24</option>
                        <option value="23">25</option>
                        <option value="21">26</option>
                        <option value="22">27</option>
                        <option value="23">28</option>
                        <option value="21">29</option>
                        <option value="22">30</option>
                        <option value="23">31</option>
                        <option value="21">32</option>
                        <option value="22">33</option>
                        <option value="23">34</option>
                        <option value="21">35</option>
                        <option value="22">36</option>
                        <option value="23">37</option>
                        <option value="21">38</option>
                        <option value="22">39</option>
                        <option value="23">40</option>
                        <option value="21">41</option>
                        <option value="22">42</option>
                        <option value="23">43</option>
                        <option value="21">44</option>
                        <option value="22">45</option>
                        <option value="23">46</option>
                        <option value="21">47</option>
                        <option value="22">48</option>
                        <option value="23">49</option>
                        <option value="23">50</option>
                        <option value="21">51</option>
                        <option value="22">52</option>
                        <option value="23">53</option>
                        <option value="21">54</option>
                        <option value="22">55</option>
                        <option value="23">56</option>
                        <option value="21">57</option>
                        <option value="22">58</option>
                        <option value="23">59</option>
                        <option value="21">60</option>
                        <option value="22">62</option>
                        <option value="23">63</option>
                        <option value="21">64</option>
                        <option value="22">65</option>
                        <option value="23">66</option>
                        <option value="21">67</option>
                        <option value="22">68</option>
                        <option value="23">69</option>
                        <option value="21">70</option>
                        <option value="22">71</option>
                        <option value="23">72</option>
                        <option value="21">73</option>
                        <option value="22">74</option>
                        <option value="23">75</option>
                        <option value="21">76</option>
                        <option value="22">77</option>
                        <option value="23">78</option>
                        <option value="21">79</option>
                        <option value="22">80</option>
                        <option value="23">81</option>
                        <option value="21">82</option>
                        <option value="22">83</option>
                        <option value="23">84</option>
                        <option value="21">85</option>
                        <option value="22">86</option>
                        <option value="23">87</option>
                        <option value="21">88</option>
                        <option value="22">89</option>
                        <option value="23">90</option>
                        <option value="21">91</option>
                        <option value="22">92</option>
                        <option value="23">93</option>
                        <option value="21">94</option>
                        <option value="22">95</option>
                        <option value="23">96</option>
                        <option value="21">97</option>
                        <option value="22">98</option>
                        <option value="23">99</option>
                        <option value="23">100</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div id="3a" class="tab-pane mt-4">
                  <h4 style="padding: 0px 9px;">Where do you live?</h4>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <div class="d-flex">
                        <label for="pincode" class="d-flex">Your Pincode</label>
                        <!-- <p class="link-primary">Don't Know Pincode</p> -->
                      </div>
                      <input type="text" class="form-control" #pinCode (blur)="pincodeUserChangeEvent(pinCode)"
                        value="342312    Jaipur, Rajasthan, India">
                    </div>
                    <div class="col">
                      <div><label for="address" class="d-flex">Street Address</label></div>
                      <input type="text" class="form-control">
                    </div>

                  </div>
                  <div class="row p-3 justify-content-between">
                    <div class="col">
                      <label for="address" class="d-flex">Select Village</label>
                      <select class="form-select" name="age" id="age">
                        <option value="select">Select Village</option>
                        <option *ngFor="let pincode of pincodeMaster" [value]="pincode.id">
                          {{pincode.cityVillageArea}}
                        </option>

                      </select>
                    </div>
                    <div class="col">
                    </div>
                  </div>
                </div>
                <div id="4a" class="tab-pane mt-4" style="padding: 0px 9px;">
                  <h4>Does any member have an exhausting illness or medical history?</h4>
                  <div class="card shadow col p-3">
                    <div class="d-flex">
                      <div>
                        <input type="checkbox">
                      </div>&nbsp;&nbsp;
                      <div>
                        <h6 class="text-left">Existing illness</h6>
                        <span class="font-body text-left d-flex">Blood pressure, Diabetes, Heart condition, Asthma,
                          Thyroid, Cancer etc.</span>
                      </div>
                    </div>
                  </div><br>
                  <div class="card shadow col p-3">
                    <div class="d-flex">
                      <div>
                        <input type="checkbox">
                      </div>&nbsp;&nbsp;
                      <div>
                        <h6 class="text-left">Surgical procedure</h6>
                        <span class="font-body">Appendix, Gall bladder, C-section etc.</span>
                      </div>
                    </div>
                  </div><br>
                  <div class="card shadow col p-3">
                    <div class="d-flex">
                      <div>
                        <input type="checkbox">
                      </div>&nbsp;&nbsp;
                      <div>
                        <h6>None of these</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-5 buttons_con_div">
                <a href="#{{selectedTab}}a" data-toggle="tab">
                  <button class="btn btn-light float-start" (click)="back()">Back</button>
                </a>
                <a href="#{{selectedTab}}a" data-toggle="tab">
                  <button class="btn btn-primary float-end" *ngIf="this.selectedTab <= 4" (click)="continue()">
                    Continue
                  </button>
                  <button class="btn btn-primary float-end" *ngIf="this.selectedTab > 4" (click)="viewIllness()">View
                    plans</button>
                </a>
              </div>
            </div>
            <div class="card shadow my-5 p-2" *ngIf="isIllness">
              <div class="mt-4">
                <h4 style="padding: 0px 40px;">Tell us about your existing illnesss</h4>
                <div class="row justify-content-between p-3">
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>Diabetes</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>BP / Hypertension</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                </div>
                <div class="row justify-content-between p-3">
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>Heart Aliments</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                  <div class="col-5 card shadow p-4">
                    <li class="d-flex justify-content-between">
                      <h5>Other health issues</h5>
                      <input type="checkbox">
                    </li>
                  </div>
                </div>
              </div>
              <div class="p-5">
                <button class="btn btn-light float-start" (click)="viewIllness()">Back</button>
                <button class="btn btn-primary float-end" routerLink="/health">View plans</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="overlay" *ngIf="isPopup">
  <div class="popup1">
    <div class="modal-header">
      <div class="row head ">
        <div class="col-md-10 mt-4 col10">
          <h6 class="">
            Commercial Vehicle &nbsp;&nbsp;&nbsp;<span><img src="../../assets/svgIcon/1-2.svg" alt=""
                *ngIf="firstPage"><img src="../../assets/svgIcon/2-2.svg" alt="" *ngIf="secondPage"></span>
          </h6>
        </div>
        <div class="col-md-2 mt-2 col2 ">
          <button type="button" class="btn mt-2" aria-label="Close" (click)=" close()" style="    position: relative;
    bottom: 12px; right:5px">
            <img src="../../assets/svgIcon/cancel.svg" alt="">
          </button>
        </div>
      </div>

    </div>
    <div class="model-body">
      <div class="row" *ngIf="firstPage">
        <div class="col-md-12 mt-3">
          <p class="p-tag">
            Select the category of your vehicle
          </p>
        </div>
        <div class="col-md-12 " (click)="Types('1')">
          <div class="card crd " [ngClass]="{'selected':type=='1'}">
            <div class="row rw-mg">
              <div class="col-md-3 col3 ">
                <img src="../../assets/svgIcon/riksha.svg" width="50%" height="100%" alt="">
              </div>
              <div class="col-md-6 col6  mt-2">
                <h6 class="vehicle">PCV</h6>
              </div>
              <div class="col-md-3 col3 text-end ">
                <img src="../../assets/svgIcon/qusmark.svg" width="40%" height="100%" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-2" (click)="Types('2')">
          <div class="card crd  " [ngClass]="{'selected':type=='2'}">
            <div class="row rw-mg">
              <div class="col-md-3 col3 ">
                <img src="../../assets/svgIcon/truck1.svg" width="50%" height="100%" alt="">
              </div>
              <div class="col-md-6 col6  mt-2">
                <h6 class="vehicle">GCV</h6>
              </div>
              <div class="col-md-3 col3 text-end ">
                <img src="../../assets/svgIcon/qusmark.svg" width="40%" height="100%" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-2" (click)="Types('3')">
          <div class="card crd  " [ngClass]="{'selected':type=='3'}">
            <div class="row rw-mg">
              <div class="col-md-3 col3 ">
                <img src="../../assets/svgIcon/jcb.svg" width="50%" height="100%" alt="">
              </div>
              <div class="col-md-6 col6  mt-2">
                <h6 class="vehicle">Misc...</h6>
              </div>
              <div class="col-md-3 col3 text-end ">
                <img src="../../assets/svgIcon/qusmark.svg" width="40%" height="100%" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <p class="font-body" style="font-size:12px">Vehicle Type</p>
        </div>
        <div class="col-md-12 tool  " *ngIf="type=='1'">
          <div class=" sp-font"><span>PCV: Passenger Carrying Vehicles refer to vehicles that are designed and used
              primarily for the transportation of passengers. </span></div>
        </div>

        <div class="col-md-12 tool  " *ngIf="type=='2'">
          <div class=" sp-font"><span>GCV: Goods Carrying Vehicles refer to vehicles that are designed and used
              primarily for transporting goods or cargo. </span></div>
        </div>

        <div class="col-md-12 tool  " *ngIf="type=='3'">
          <div class=" sp-font"><span>Misc:Miscellaneous vehicle are used in works such as construction or in emergency
              services such as ambulance or fire suppressing vehicles. </span></div>
        </div>
        <div class="col-md-12 mt-2">
          <div class="card">
            <div class="row">

              <div class="col-md-12" *ngIf="type=='1'">
                <select class="form-select " #Pcv aria-label="Default select example" (change)="PVCType($event)">
                  <option selected>PCV Vehicle Type</option>
                  <option *ngFor="let PCV of pcv" value="{{PCV}}">{{PCV}}</option>

                </select>
                <label for="" class="mt-3"> Seaters</label>
                <select class="form-select stl" aria-label="Default select example">
                  <option selected>Select Seaters Type</option>
                  <option *ngFor="let seat of seats" value="{{seat}}">{{seat}}</option>

                </select>
              </div>
              <div class="col-md-12 mt-3" *ngIf="selectedType=='Bus'">
                <label for=""> Purpose</label>
                <select class="form-select stl" aria-label="Default select example">
                  <option selected>Select Your Purpose</option>
                  <option value="Tourist Buss">Tourist Buss</option>
                  <option value="School Buss">School Buss</option>
                  <option value="Corporate Contract Buss">Corporate Contract Buss</option>
                </select>


              </div>
              <div class="col-md-12" *ngIf="type=='2'">
                <select class="form-select" aria-label="Default select example">
                  <option selected>GCV Vehicle Type</option>
                  <option *ngFor="let GCV of gcv" value="{{GCV}}">{{GCV}}</option>

                </select>
                <label for="" class="mt-4">GVW</label>
                <select class="form-select" aria-label="Default select example">
                  <option selected>GVW</option>
                  <option *ngFor="let gvw of GVWType" value="{{gvw}}">{{gvw}}</option>

                </select>
              </div>
              <div class="col-md-12" *ngIf="type=='3'">
                <select class="form-select" aria-label="Default select example">
                  <option selected>MISC Vehicle Type</option>
                  <option *ngFor="let MISC of misc" value="{{MISC}}">{{MISC}}</option>

                </select>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-12 ">
          <p class="p-tag">
            Select the Permit type of your vehicle
          </p>
        </div>
        <div class="col-md-6  cur" (click)="permit('public')">
          <div class="card  shad" [ngClass]="{'imgSelect':prType=='public'}">
            <div class="row">
              <div class="col-md-4">
                <img src="../../assets/svgIcon/IdentificationCard.svg" width="120%" alt="">
              </div>
              <div class="col-md-8">
                <p class="font-body mt-2">Public</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6  cur" (click)="permit('private')">
          <div class="card  shad" [ngClass]="{'imgSelect':prType=='private'}">
            <div class="row">
              <div class="col-md-4">
                <img src="../../assets/svgIcon/IdentificationCard.svg" width="120%" alt="">
              </div>
              <div class="col-md-8">
                <p class="font-body mt-2">Private</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row" *ngIf="secondPage">
        <div class="col-md-12 mt-2">
          <p class="p-tag">Select Existing policy Details</p>
        </div>
        <form [formGroup]="Polices">
          <div class="col-md-12 mt-2">
            <label for="" class="title">Policy's Expiry Status</label>
            <select class="form-select stl" aria-label="Default select example">
              <option selected>Select Policy's Expiry Status</option>
              <option *ngFor="let expiry of ExpiryPolicy " value="{{expiry}}">{{expiry}}</option>

            </select>
          </div>
          <div class="col-md-12 mt-2">
            <label for="" class="title">Existing Policy Company</label>
            <select class="form-select stl" aria-label="Default select example">
              <option selected>Type and search Current Policy Company</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div class="col-md-12 mt-2">
            <label for="" class="title">Existing Policy Expiry Date</label>
            <input type="date" name="" class="form-control" id="">
          </div>
          <div class="col-md-12 mt-2">
            <label for="" class="title">Policy Type</label>
            <select class="form-select stl" aria-label="Default select example">
              <option selected>Select Policy's Expiry Type</option>
              <option *ngFor="let type of PolicyType" value="{{type}}">{{type}}</option>

            </select>
          </div>
          <div class="col-md-12 mt-2">
            <div class="row">
              <label for="" class="title">Is the ownership transferred?</label>
              <div class="col-md-6 mt-2">
                <div class="card p-2 shad">
                  <div class="row">
                    <div class="col-md-12">
                      <input type="radio" name="ownership" id=""> &nbsp;<span>Yes</span>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-2">
                <div class="card p-2 shad">
                  <div class="row">
                    <div class="col-md-12">
                      <input type="radio" name="ownership" id=""> &nbsp;<span>No</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <label for="" class="title">Did you make a claim in your existing policy?</label>
            <select class="form-select stl" aria-label="Default select example">
              <option selected>Select Existing Policy Claim</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class="col-md-12 mt-2">
            <label for="" class="title">Existing NCB (No Claim Bonus)</label>
            <select class="form-select stl" aria-label="Default select example">
              <option selected>No Claim Bonus</option>
              <option value="0%">0%</option>
              <option value="20%">20%</option>
              <option value="25%">25%</option>
              <option value="35%">35%</option>
              <option value="45%">45%</option>
              <option value="50%">50%</option>
            </select>
          </div>
        </form>
      </div>
    </div>
    <div class="row buttom-rw">
      <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-1 r-0" [disabled]="isDisable"
        *ngIf="firstPage" (click)="changePage()" style="margin-top:4%">
        Continue
      </button>
      <button class="btn btn-primary w-100 rounded-top-0 position-absolute b-1 r-0" *ngIf="secondPage"
        (click)="gotoVehicle()" style="margin-top:4%">
        Continue
      </button>
    </div>
  </div>

</div>