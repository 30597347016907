<div class="container-fluid" style="background-color: var(--white);">
    <div class="container need_help_div">
      <div class="card-container">
        <div class="text">
          <!-- <h1 class="heading">Need some <span style="color:var(--main2)">Help </span>?</h1> -->
          <h1 class="heading">{{langList.need_help_title}}</h1>
          <!-- <span class="font-body">{{langList.need_help_text}}</span> -->
        </div>
        <h6 class="font-body mb-4" style="text-align:left">
          {{langList.need_help_text}}
        </h6>
        <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}" [ngStyle]="{'margin-top':'25px;'}">
            <div [ngClass]="{'col-4': !isSmallScreen, 'col-12 mb-5': isSmallScreen}">
              <div class="d-flex card p-5">
                <div>
                  <img src="../../assets/images/mail.png" alt=""> 
                  <strong class="ml-4"><b>{{langList.mail_us}} :-  </b><a class="mailTxt" style="color: var(--main) !important;" href="mailto:Info@srggroup.net">Info@srggroup.net</a></strong>
                </div>
              </div>
            </div>
            <div [ngClass]="{'col-4': !isSmallScreen, 'col-12 mb-5': isSmallScreen}">
              <div class="d-flex card p-5">
                <div>
                  <img src="../../assets/svgIcon/PhoneCall.svg" alt=""> 
                  <strong class="ml-4"><b>{{langList.mobile_number}} :-  </b><a class="mailTxt" style="color: var(--main) !important;" href="tel:+919829080258">+91 9829080258</a></strong>
                </div>
              </div>
            </div>
            <div [ngClass]="{'col-4': !isSmallScreen, 'col-12 mb-5': isSmallScreen}">
              <div class="card p-5">
                <div>
                  <img src="../../assets/svgIcon/WhatsappLogo.svg" alt="">  
                  <strong class="ml-4"><b>{{langList.whatsapp}} :-  </b><a class="mailTxt" style="color: var(--main) !important;" href="https://wa.me/+919829080258?">+91 9829080258</a></strong>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
