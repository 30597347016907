
<div class="container" style="background-color: #f3f3f4;">
    <div class="row">
        <div class="col-md-8 mt-5">
            <div class="card p-5">
                <div class="row p-1">
                    <div class=" col-md-2 tab" (click)="nextTab('')" [class.active]="tabs===''">
                        <p class="tabs">City/RTO</p>
                    </div>
                    <div class=" col-md-2 tab" (click)="nextTab('Manufacturer')" [class.active]="tabs==='Manufacturer'">
                        <p class="tabs">Manufacturer</p>
                    </div>
                    <div class=" col-md-2 tab" (click)="nextTab('Model')" [class.active]="tabs==='Model'">
                        <p class="tabs"> Model</p>
                    </div>
                    <div class=" col-md-2 tab" (click)="nextTab('Fuel Type')" [class.active]="tabs==='Fuel Type'">
                        <p class="tabs"> Fuel Type</p>
                    </div>
                    <div class=" col-md-2 tab" (click)="nextTab('Variant') " [class.active]="tabs==='Variant'">
                        <p class="tabs"> Variant</p>
                    </div>
                    <div class=" col-md-2 tab" (click)="nextTab('Year')" [class.active]="tabs==='Year'">
                        <p class="tabs"> Year</p>
                    </div>
                </div>
                <div class="row">
                    <div class="tabContant">
                        <div *ngIf="tabs==''">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <input type="text" class=" in-h form-control input-control cityIcon w-100">
                                </div>
                                <div class="col-md-12 mt-3">
                                    <h4>
                                        Or Select City
                                    </h4>
                                </div>
                                <div class="col-md-3 mt-4" *ngFor="let citys of city">
                                    <div class="card crd p-2">
                                        <h6 class="text-center">{{citys}}</h6>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <h4>
                                        Or Select RTO
                                    </h4>
                                </div>
                                <div class="col-md-3 mt-4" *ngFor="let RTO of rto">
                                    <div class="card crd p-2">
                                        <h6 class="text-center">{{RTO}}</h6>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                        <div *ngIf="tabs=='Manufacturer'">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <input type="text" class="in-h form-control input-control cityIcon w-100">
                                </div>
                                <div class="col-md-12 mt-3">
                                    <h4>
                                        Or Select Manufacturer
                                    </h4>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/tata.svg" alt="">&nbsp;&nbsp;Tata</h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/mahindra.svg" alt="">&nbsp;&nbsp;Mahindra
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/kia.svg" alt="">&nbsp;&nbsp;KIA</h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-3">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/toyota.svg" alt="">&nbsp;&nbsp;Toyota
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/hyundai.svg" alt="">&nbsp;&nbsp;Hyundai
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/honda.svg" alt="">&nbsp;&nbsp;Honda</h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/maruti.svg" alt="">&nbsp;&nbsp;Maruti
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/renault.svg" alt="">&nbsp;&nbsp;Renault
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/MG.svg" alt="">&nbsp;&nbsp;MG</h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/nissan.svg" alt="">&nbsp;&nbsp;Nissan
                                        </h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/tata.svg" alt="">&nbsp;&nbsp;Skoda</h6>
                                    </div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <div class="card crd p-2">
                                        <h6> <img src="../../../assets/svgIcon/volk.svg" alt="">&nbsp;&nbsp;VolksWagen
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row sticky">
                                <div class="col-md-12 mt-4 text-end">
                                    <button class="btn btn-primary btn-sm " id="btn"
                                        (click)="nextTab('Model')">Next</button>
                                </div>
                            </div> -->
                        </div>
                        <div *ngIf="tabs=='Model'">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <input type="text" class=" in-h form-control input-control cityIcon w-100">
                                </div>
                                <div class="col-md-12 mt-5">
                                    <h4>
                                        <b> Or Select Model</b>
                                    </h4>
                                </div>
                                <div class="col-md-3 mt-4" *ngFor="let modal of modals">
                                    <div class="card crd p-2">
                                        <h6 class="text-center">{{modal}}</h6>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-5">
                                    <h4>
                                        <b>Other Model</b>
                                    </h4>
                                </div>
                                <div class="col-md-3 mt-4" *ngFor="let other of otherModal">
                                    <div class="card crd p-2">
                                        <h6 class="text-center"> {{other}}</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row sticky  ">
                                <div class="col-md-6 mt-4 text-start">
                                    <button class="btn btn-light btn-sm " id="btn" (click)="nextTab('')">Back</button>
                                </div>
                                <div class="col-md-6 mt-4 text-center">
                                    <button class="btn btn-primary btn-sm " id="btn"
                                        (click)="nextTab('Fuel Type')">Next</button>
                                </div>
                            </div> -->
                        </div>
                        <div *ngIf="tabs=='Fuel Type'">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <input type="text" class="in-h form-control input-control cityIcon w-100">
                                </div>
                                <div class="col-md-12 mt-5">
                                    <h4><b>
                                            Or Select Fuel Typel
                                        </b> </h4>
                                </div>
                                <div class="col-md-3 mt-3" *ngFor="let fuel of fuelType">
                                    <div class="card crd p-2">
                                        <h6 class="text-center">{{fuel}}</h6>
                                    </div>
                                </div>

                            </div>
                            <!-- <div class="row sticky">
                                <div class="col-md-6 mt-4 text-start">
                                    <button class="btn btn-light btn-sm " id="btn"
                                        (click)="nextTab('Model')">Back</button>
                                </div>
                                <div class="col-md-6 mt-4 text-center">
                                    <button class="btn btn-primary btn-sm " id="btn"
                                        (click)="nextTab('Variant')">Next</button>
                                </div>
                            </div> -->
                        </div>
                        <div *ngIf="tabs=='Variant'">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <input type="text" class="in-h form-control input-control cityIcon w-100">
                                </div>
                                <div class="col-md-12 mt-5">
                                    <h4><b>
                                            Or Select Variant
                                        </b></h4>
                                </div>
                                <div class="col-md-3 mt-3" *ngFor="let variant of variantType">
                                    <div class="card crd p-2">
                                        <h6 class="text-center">{{variant}}</h6>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-5">
                                    <h4>
                                        <b> Other Variant
                                        </b>
                                    </h4>
                                </div>
                                <div class="col-md-3 mt-3" *ngFor="let otherV of otherVariant">
                                    <div class="card crd p-2">
                                        <h6 class="text-center">{{otherV}}</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row sticky">
                                <div class="col-md-6 mt-4 text-start">
                                    <button class="btn btn-light btn-sm " id="btn"
                                        (click)="nextTab('Fuel Type')">Back</button>
                                </div>
                                <div class="col-md-6 mt-4 text-center">
                                    <button class="btn btn-primary btn-sm " id="btn"
                                        (click)="nextTab('Year')">Next</button>
                                </div>
                            </div> -->
                        </div>
                        <div *ngIf="tabs=='Year'">
                            <div class="row">

                                <div class="col-md-12 mt-5">
                                    <h4><b>
                                            Select Year
                                        </b></h4>
                                </div>
                                <div class="col-md-3 mt-5" *ngFor="let year of selectYear">
                                    <div class="card crd p-2">
                                        <h6 class="text-center">{{year}}</h6>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row sticky">
                                <div class="col-md-6 mt-4 text-start">
                                    <button class="btn btn-light btn-sm " id="btn"
                                        (click)="nextTab('Variant')">Back</button>
                                </div>


                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card   p-5 mt-3 ">
                <div class="row">
                    <h4>
                        <a href=""><img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                class="cursor-pointer"></a>&nbsp;&nbsp;
                        Your vehicle details
                    </h4>
                    <label class="float-start mt-3">City/RTO</label>
                    <input type="text" class="form-control" name="city" placeholder="City/RTO"
                        formControlName="city"><br>
                    <label class="float-start">Make</label>
                    <input type="text" class="form-control" name="make" placeholder="Make"
                        formControlName="rto"><br>
                    <label class="float-start" for="make">Model</label>
                    <input type="text" class="form-control" placeholder="modal" formControlName="model"><br>
                    <label class="float-start" for="Variant">Variant</label>
                    <input type="text" class="form-control" placeholder="Variant" formControlName="variant"><br>


                    <label class="float-start" for="registration">Registration year</label>
                    <input type="text" class="form-control" placeholder="Registration year"
                        formControlName="registrationYear"><br>



                </div>
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <button class="btn btn-md btn-primary get" (click)="goPlan()">Get Quotes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>