import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommercialVehicleDeatils, InsuranceDetails } from 'src/app/Models/vehicle-component.model';
import { CommercialVehicleDetailsService } from 'src/app/Services/commercial-vehicle-details.service';
import { LanguageLableService } from 'src/app/Services/language-lable.service';
import { VehiclequotesComponent } from 'src/app/vehicleinsurance/vehiclequotes/vehiclequotes.component';
import { VehicleQuoteDetailsComponent } from 'src/app/vehicleinsurance/vehicle-quote-details/vehicle-quote-details.component';
import { VehicleOwnerDetailsComponent } from 'src/app/vehicleinsurance/vehicle-owner-details/vehicle-owner-details.component';
import { VehiclePolicyDetailsComponent } from 'src/app/vehicleinsurance/vehicle-policy-details/vehicle-policy-details.component';
import { InsuranceService } from 'src/app/Services/insurance.service';
import swal from 'sweetalert2';
import { AutoCompleteItem, Item } from 'src/app/Models/Item';
import { VehicleService } from 'src/app/Services/vehicle.service';
import { LookUpService } from 'src/app/Services/lookup.service';
import { CommercialVechiclesComponent } from 'src/app/commercial/commercial-vechicles/commercial-vechicles.component';
import { GeneralService } from 'src/app/Services/general.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-commercial-policy',
  templateUrl: './commercial-policy.component.html',
  styleUrls: ['./commercial-policy.component.css']
})
export class CommercialPolicyComponent {

  verifyBikeDetails: boolean = false;
  verifyBikeDetailspolicy: boolean = false;
  IsDontKnowBike: boolean = false;
  langList: any;
  selectedValue: string | null;
  isUserLogged: boolean = false;
  homeForm1: boolean = false;
  isMobile: boolean = true;
  isOtp: boolean = false;
  isPopup: boolean = false;
  DontKnow: boolean = false;
  vehicleRegisterData: any;
  vehicleForm: FormGroup;
  Bought: boolean = false;
  selectedTab: number = 1;
  isAge: boolean = false;
  isWhoInsureFilled: boolean = false;
  isLocation: boolean = false;
  isAgeFilled: boolean = false;
  isDetails: boolean = false;
  isLocationFilled: boolean = false;
  firstPage: boolean = true;
  secondPage: boolean = false;
  type: string = '';
  isDisable: boolean = true;
  selectedType: any;
  prType: string = '';




  //pcv:string[]=['Bus','Taxi/Cab','Auto Rikshaw','E-Rikshaw'];
  //seats:string[]=['4seater-6 seater','7 seater-10 seater','11seater-18 seater','18 seater-24 seater'];
  //GVWType:string[]=['0T-2T','2T-2.5T','2.5T-3.5T','3.5T','3.5T-12T','12T-20T','20T-40T and more than 20T'];
  //gcv:string[]=['Truck','Bulker','Dumper','Pick Up Van','Tanker','Tipper','Tractor','Trailor','Auto','Courier Delivery Van','Garbage Carrier','Chicken Carrier','Goods Auto','Refrigerated Van'];
  //misc:string[]=['Construction Equipment','Agricultural Equipment','Agricultural Tractor','Garbage Van','Ambulance','Fire brigade'];
  //ExpiryPolicy:string[]=['Not Expired','Expired Within 90 Days','Expired More than 90 Days ago',"I don't remember"];

  CommVehicleCategoryList: any[] = [{ text: "PCV", id: "PCV", value: "PCV", text1: "riksha.svg", tooltip: "PCV: Passenger Carrying Vehicles refer to vehicles that are designed and used primarily for the transportation of passengers." },
  { text: "GCV", id: "GCV", value: "GCV", text1: "truck1.svg", tooltip: "GCV: Goods Carrying Vehicles refer to vehicles that are designed and used primarily for transporting goods or cargo." },
  { text: "Miscellaneous", id: "MISC", value: "MISC", text1: "jcb.svg", tooltip: "Misc:Miscellaneous vehicle are used in works such as construction or in emergency services such as ambulance or fire suppressing vehicles." }
  ];
  CommVehiclePCVehicleList: AutoCompleteItem[] = [];
  CommVehicleGCVehicleList: AutoCompleteItem[] = [];
  CommVehicleMISCVehicleList: AutoCompleteItem[] = [];
  //CommVehicleCategoryList:AutoCompleteItem[]=[{text:"PCV",id:"PCV",value:"PCV", text1:"riksha.svg"}];
  // CommVehiclePCVehicleList: AutoCompleteItem[] = [
  //   { text: "Ambulance / Box Body", value: "Ambulance / Box Body" },
  //   { text: "Bus", value: "Bus" },
  //   { text: "Fully Built", value: "Fully Built" },
  //   { text: "Hard Top", value: "Hard Top" },
  //   { text: "Hatch", value: "Hatch" },
  //   { text: "Loader", value: "Loader" },
  //   { text: "Monocoque", value: "MONOCOQUE" },
  //   { text: "Motor Cycle", value: "Motor Cycle" },
  //   { text: "MPV", value: "MPV" },
  //   { text: "MUV", value: "MUV" },
  //   { text: "Omni Bus", value: "Omni Bus" },
  //   { text: "3 Wheeler", value: "PCV -3W" },
  //   { text: "Pick Up Van", value: "Pick Up Van" },
  //   { text: "PS", value: "PS" },
  //   { text: "Quadricycle", value: "QUADRICYCLE" },
  //   { text: "Saloon", value: "Saloon" },
  //   { text: "School Bus", value: "School Bus" },
  //   { text: "Scooter", value: "Scooter" },
  //   { text: "Sedan", value: "Sedan" },
  //   { text: "SUV", value: "SUV" },
  //   { text: "Van", value: "Van" },
  //   { text: "Vans / Maxi Cab", value: "Vans / Maxi Cab" }];
  // CommVehicleGCVehicleList: AutoCompleteItem[] = [
  //   { text: "Bulker", value: "BULKER" },
  //   { text: "Rock Body", value: "Rock Body" },
  //   { text: "Road Sweeper", value: "ROAD SWEEPER" },
  //   { text: "Chemical Tanker", value: "Chemical Tanker" },
  //   { text: "Box Body / Tipper", value: "Box Body / Tipper" },
  //   { text: "Crane", value: "CRANE" },
  //   { text: "Cabin", value: "Cabin" },
  //   { text: "Closed Body (Full Body)", value: "Closed Body (Full Body)" },
  //   { text: "Closed Body", value: "Closed body" },
  //   { text: "Trailer Body", value: "Trailer Body" },
  //   { text: "Truck", value: "Truck" },
  //   { text: "Hard Top", value: "Hard Top" },
  //   { text: "Refrigerated Van", value: "Refrigerated Van" },
  //   { text: "Tractors", value: "Tractors" },
  //   { text: "Open Body (Half Body)", value: "Open Body (Half Body)" },
  //   { text: "Transit Mixer", value: "Transit Mixer" },
  //   { text: "Forklift", value: "FORKLIFT" },
  //   { text: "Compressors", value: "COMPRESSORS" },
  //   { text: "Tar Sprayer", value: "TAR SPRAYER" },
  //   { text: "Truck / Tanker", value: "Truck / Tanker" },
  //   { text: "Pick Up Van", value: "Pick Up Van" },
  //   { text: "Full Body", value: "Full Body" },
  //   { text: "Plat Form", value: "Plat Form" },
  //   { text: "Cusomised", value: "Cusomised" },
  //   { text: "Open Body", value: "Open Body" },
  //   { text: "Light Goods Vehicle", value: "Light Goods Vehicle" },
  //   { text: "Mobile Plant", value: "MOBILE PLANT" },
  //   { text: "Fully Built", value: "Fully Built" },
  //   { text: "Tractor / Trailer", value: "TRACTOR / TRAILER" },
  //   { text: "Bus", value: "Bus" },
  //   { text: "Heavy Truck / Semi Trailer", value: "Heavy Truck / Semi Trailer" },
  //   { text: "Muv", value: "MUV" },
  //   { text: "Tractor Loader", value: "TRACTOR LOADER" },
  //   { text: "Recovery Vehicle", value: "RECOVERY VEHICLE" },
  //   { text: "Truck Open Body", value: "Truck Open Body" },
  //   { text: "Garbage Van", value: "GARBAGE VAN" },
  //   { text: "Customizable", value: "Customizable" },
  //   { text: "Box Body", value: "Box Body" },
  //   { text: "Pick Up", value: "Pick Up" },
  //   { text: "Deck Body", value: "Deck Body" },
  //   { text: "Road Surfacer", value: "ROAD SURFACER" },
  //   { text: "3 Wheeler", value: "GCW - 3W" },
  //   { text: "Van", value: "Van" },
  //   { text: "Rock / Scoop Body", value: "Rock / Scoop Body" },
  //   { text: "Trailer", value: "Trailer" },
  //   { text: "Dumper", value: "Dumper" },
  //   { text: "Tipper", value: "Tipper" },
  //   { text: "Tanker", value: "Tanker" },
  //   { text: "Loader", value: "Loader" },
  //   { text: "Mixer", value: "Mixer" },
  //   { text: "Half Body", value: "Half Body" },
  //   { text: "Tipper / Dumper", value: "Tipper / Dumper" },
  //   { text: "Delivery Van", value: "Delivery Van" },
  //   { text: "Milk Tanker", value: "Milk Tanker" },
  //   { text: "Tipper", value: "Tipper" },
  //   { text: "Tanker", value: "Tanker" },
  //   { text: "Loader", value: "Loader" },
  //   { text: "Mixer", value: "Mixer" },
  //   { text: "Half Body", value: "Half Body" },
  //   { text: "Tipper / Dumper", value: "Tipper / Dumper" },
  //   { text: "Delivery Van", value: "Delivery Van" },
  //   { text: "Milk Tanker", value: "Milk Tanker" }
  // ];
  // CommVehicleMISCVehicleList: AutoCompleteItem[] = [
  //   { text: "Tractor", value: "Tractors" },
  //   { text: "Transit Mixer", value: "Transit Mixer" },
  //   { text: "Boom Lift", value: "BOOM LIFT" },
  //   { text: "Agriculture Equipment", value: "AGRICULTURE EQUIPMENT" },
  //   { text: "Compressors", value: "COMPRESSORS" },
  //   { text: "Fire Fighter", value: "FIRE FIGHTER" },
  //   { text: "Tower Wagons", value: "Tower Wagons" },
  //   { text: "Publicity Van", value: "PUBLICITY VAN" },
  //   { text: "Harvester", value: "HARVESTER" },
  //   { text: "Library Van", value: "LIBRARY VAN" },
  //   { text: "Vibratory Compactor", value: "Vibratory Compactor" },
  //   { text: "Backhoe Loader", value: "BACKHOE LOADER" },
  //   { text: "Refrigerated Van", value: "Refrigerated Van" },
  //   { text: "Crane", value: "CRANE" },
  //   { text: "Road Sweeper", value: "ROAD SWEEPER" },
  //   { text: "Vanity Van", value: "VANITY VAN" },
  //   { text: "Forklift", value: "FORKLIFT" },
  //   { text: "Earth Moving Machine", value: "EARTH MOVING MACHINE" },
  //   { text: "Tar Sprayer", value: "TAR SPRAYER" },
  //   { text: "Ambulance", value: "AMBULANCE" },
  //   { text: "Cattle Carrier", value: "CATTLE CARRIER" },
  //   { text: "Excavator", value: "EXCAVATOR" },
  //   { text: "Cash Van", value: "Cash Van" },
  //   { text: "Motor Grader", value: "MOTOR GRADER" },
  //   { text: "Suction Cum Jetting Machine", value: "SUCTION CUM JETTING MACHINE" },
  //   { text: "Drilling Rigs", value: "DRILLING RIGS" },
  //   { text: "Mobile Plant", value: "MOBILE PLANT" },
  //   { text: "Tractor Dozer", value: "TRACTOR DOZER" },
  //   { text: "Soil Compactor", value: "Soil Compactor" },
  //   { text: "Bulldozer", value: "BULLDOZER" },
  //   { text: "Generator Van", value: "GENERATOR VAN" },
  //   { text: "Tractor Loader", value: "TRACTOR LOADER" },
  //   { text: "Recovery Vehicle", value: "RECOVERY VEHICLE" },
  //   { text: "Garbage Van", value: "GARBAGE VAN" },
  //   { text: "Road Roller", value: "ROAD ROLLER" },
  //   { text: "Surgeries/Dispensaries", value: "SURGERIES/DISPENSARIES" },
  //   { text: "Mobile Canteen", value: "Mobile Canteen" },
  //   { text: "Road Surfacer", value: "ROAD SURFACER" },
  //   { text: "Hearse Van", value: "HEARSE VAN" },
  //   { text: "Trailer", value: "Trailer" },
  //   { text: "Paver Finisher", value: "PAVER FINISHER" },
  //   { text: "Loader", value: "Loader" },
  //   { text: "Dog Van", value: "DOG VAN" },
  //   { text: "Construction Equipment", value: "CONSTRUCTION EQUIPMENT" },
  //   { text: "Golf Cart", value: "GOLF CART" }
  // ];
  CommVehiclePassCapList: AutoCompleteItem[] = [{ text: "4 seater- 6 seater", value: "4,5,6" }, { text: "7 seater - 10 seater", value: "7,8,9,10" }, { text: "11 seater - 18 seater", value: "11,12,13,14,15,16,17,18" }, { text: "19 seater-24 seater", value: "19,20,21,22,23,24" }, { text: "More than 24 seater", value: "25" }];
  CommVehicleWeightList: AutoCompleteItem[] = [{ text: "0 Ton - 2 Ton", value: "0-2" }, { text: "2 Ton - 2.5 Ton", value: "2-2.5" }, { text: "2.5 Ton - 3.5 Ton", value: "2.5-3.5" }, { text: "3.5 Ton - 12 Ton", value: "3.5-12" }, { text: "12 Ton - 20 Ton", value: "12-20" }, { text: "20 Ton - 40 Ton", value: "20-40" }, { text: "More than 40 Ton", value: "41" }];
  CommVehiclePurposeList: AutoCompleteItem[] = [{ text: "Tourist Bus", value: "Tourist Bus" }, { text: "School Bus", value: "School Bus" }, { text: "Corporate Contract Bus", value: "Corporate Contract Bus" }];

  //PolicyType:string[]=['Standard/Comprehensive Coverage','Third-Party Liability Coverage',"I don't remember"];
  Polices: FormGroup;
  insuranceForm: FormGroup;
  FirstPolices: FormGroup;
  vehicleNumber: string;
  vehicleDetails = new CommercialVehicleDeatils();
  registrationData: any;
  isQuotes: boolean = false;
  isProposer: boolean = false;
  isVerifyVehicleDetails: boolean = true;
  isDontKnow: boolean = false;
  formData: CommercialVehicleDeatils = {
    category: '',
    claimsMadeInPreviousPolicy: '',
    existingPolicyComapany: '',
    expiredStatus: '',
    GVW: '',
    passCap: '',
    isNew: false,
    isOwnerShipTransferred: '',
    ncbCurrent: '',
    permit: '',
    previousPolicyExpiryDate: '',
    previousPolicyType: '',
    registrationNumber: '',
    vehicleType: ''
  };
  checked: boolean = false;
  vehicleMakeList: AutoCompleteItem[] = [];
  vehicleModelList: AutoCompleteItem[] = [];
  vehicleVariantList: AutoCompleteItem[] = [];
  insurancePartnersList: AutoCompleteItem[] = [];
  isInvalidInput: boolean = false;
  insuranceformData = {} as InsuranceDetails;
  showKYCPopup: boolean = false;
  showhttpKYCPopup: boolean = false;
  kycUrl: any = '';
  royalDetails: any;
  isRoyalSundaram: boolean = false;
  futureDetails: any;
  isFutureGeneral: boolean = false;
  RelianceDetails: any;
  isRelianceGeneral: boolean = false;
  minPolicyExpiryDate = moment(new Date()).add(-1, 'years').format('YYYY-MM-DD');
  maxPolicyExpiryDate = moment(new Date()).add(1, 'years').format('YYYY-MM-DD');
  ShriramDetails: any;
  isShriramGeneral: boolean = false;
  isPaymentLink:boolean = false;
  paymentObject:any;
  showIsBreakIn:boolean = false;
  showIsBreakInTriggered:boolean = false;
  @ViewChild(VehiclequotesComponent) vehiclequotesComponent: VehiclequotesComponent;
  @ViewChild(CommercialVechiclesComponent) commercialVechiclesComponent: CommercialVechiclesComponent;
  @ViewChild(VehicleOwnerDetailsComponent) vehicleOwnerDetailsComponent: VehicleOwnerDetailsComponent;

  constructor(private router: Router, private fb: FormBuilder, private dataService: CommercialVehicleDetailsService, private spinner: NgxSpinnerService,
    private languageLableService: LanguageLableService, private insuranceservice: InsuranceService, private vehicleService: VehicleService,
    private _lookupService: LookUpService, private generalService: GeneralService, private sanitizer: DomSanitizer) {
  }
  images: any[] = [
    './assets/partners/new/digit.png',
    './assets/partners/new/sompo.png',
    './assets/partners/new/shriram.png',
    './assets/partners/new/reliance.png'
  ]
  images1: any[] = [
    './assets/partners/new/icici.png',
    './assets/partners/new/bajaj.png',
    './assets/partners/new/royal.png',
    './assets/partners/new/future.png',
    './assets/partners/new/iffco.png'
  ]

  //   Digit
  // Universal Sompo
  // Reliance
  // Shriram

  // ICICI Lombard
  // Bajaj
  // Royal
  // Future Generali
  // iffco


  showAllImages = false;
  openItemIndex = -1;
  toggleAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  text = '';
  types = 0;
  getValue(types: number) {
    this.types = types;
    if (types == 1) {
      this.text = 'Zero Depreciation Cover ensures that the policyholder receives the full claim amount without any deduction for the depreciation of vehicle parts in the event of an accident or damage.';
    }
    else if (types == 2) {
      this.text = 'A Consumable Cover provides coverage for the cost of consumable items that need to be replaced due to damage from an accident such as Engine Oil. Brake Fluid, Air Conditioner Refrigerant, Coolant, Nuts and Bolts, Grease, Lubricants, Washers, Bearings, Filters (oil, fuel, air).';
    }
    else if (types == 3) {
      this.text = 'This ensures you receive the full invoice value of your vehicle in the event of total loss due to theft or irreparable damage. This coverage bridges the gap between the IDV and the actual purchase price, including registration and road tax, providing comprehensive financial protection.';
    }
    else if (types == 4) {
      this.text = 'Roadside Assistance cover provides emergency support services to drivers when their vehicle experiences a breakdown or other issues while on the road.';
    }
    else if (types == 5) {
      this.text = 'This cover provides coverage for repair or replacement costs of the engine and gearbox  such as  Water Ingress, Lubricant Leakage etc. due to accidental damage or unforeseen incidents.';
    }
    else if (types == 6) {
      this.text = 'IMT23 coverage is an endorsement that provides the policyholder with at least 50% coverage for damages to specific vehicle parts, such as paint, bonnet, fender, bumper, and similar components.';
    }

  }

  ngOnInit() {
    this.getValue(1);
    this.insuranceForm = this.fb.group({
      registrationNumber: ['', Validators.required],
      policyType: ['', Validators.required],
      policyExpiryStatus: ['', Validators.required],
      policyClaimed: ['', Validators.required],
      policyClaimedNCB: ['', Validators.required],
      VehicleType: 1,
      quoteId: [''],
      isMockUp: [false],
      proposerDetails: [],
      nomineeDetails: [],
      vehicleDetails: [],
      previousPolicyCompany: [''],
      previousPolicyExpiryDate: [],
    });

    this.vehicleForm = this.fb.group({
      "city": [""],
      "rto": [""],
      "make": ["", Validators.required],
      "model": ["", Validators.required],
      "variant": ["", Validators.required],
      "registrationYear": [""],
      "registrationNumber": [""],
      "chasisNumber": [""],
      "engineNumber": [""],
      "registrationDate": [""],
      "firstName": [""],
      "lastName": [""],
      "vehiclecategory": [""],
      "isPreviousPolicyHolder": [""],
      "previousPolicyType": [""],
      "policyType": ["0"],
      "claimsMadeInPreviousPolicy": [""],
      "claimsMadeInPreviousPolicyNo": ['1'],
      "claimsMadeInPreviousPolicyAmount": ['1000'],
      "ncbprevious": ["0"],
      "typeOfCover": [""],
      "previousPolicyExpiryDate": [""],
      "vehicleIdv": ["0"],
      "bodyIDV": ["0"],//shivraj
      "chassisIDV": ["0"],//shivraj
      "previousPolicyCompany": [""],
      "previousPolicyNumber": [""],
      "ncbCurrent": ["0"],
      "fuelType": [""],
      "engineCapacityAmount": [""],
      "PACover": ["Yes"],
      "RegisteredInTheNameOf": ["Individual"],
      "isValidDrivingLicenseAvailable": ["Yes"],
      "isFinanced": ["No"],
      "financedValue": [""],
      "financierName": [""],
      "isNew": [false],
      "category": [''],
      "gcVehicle": [''],
      "pcVehicle": [''],
      "purpose": [''],
      "seatingCap": [''],
      "miscVehicle": [''],
      "gVW": [''],
      "permit": [''],
      "policyExpiryStatus": ["1"],
      "isOwnerShipTransferred": ["No"],
      "policyClaimed": [''],
      "policyClaimedNCB": [''],
      "pincode": [""],
      "isNCBProtectionOptedInExistingPolicy":["No"]
    });

    this.Polices = this.fb.group({
      expiredStatus: [''],
      existingPolicyComapany: [''],
      previousPolicyExpiryDate: [''],
      previousPolicyType: [''],
      isOwnerShipTransferred: [''],
      claimsMadeInPreviousPolicy: [''],
      ncbCurrent: ['']
    });

    this.FirstPolices = this.fb.group({
      category: [''],
      claimsMadeInPreviousPolicy: [''],
      GVW: [''],
      isNew: [false],
      permit: [''],
      registrationNumber: [''],
      vehicleType: ['']
    });
    if (localStorage.getItem("selectedValue") != '') {
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else {
      this.selectedValue = "english";
    }
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
    if (sessionStorage.getItem('quotes_data') != undefined && sessionStorage.getItem('quotes_data') != '') {
    }
    if (this.generalService.getUniqueuserid() != "") {
      this.isUserLogged = true;
    }

    this.loadInsurancePartners();
    this.fetchVehicleBodyType();
  }

  onVehicleRegNumberInput(): boolean {
    // Check if the input matches the desired format
    //const regexPattern = /^[A-Z]{2}\d{1,2}[A-Z]{1,2}\d{3,4}$/;
    const regexPattern = /^[A-Z]{2}-\d{1,2}-[A-Z]{1,3}-\d{1,4}$/;
    return this.isInvalidInput = !regexPattern.test(this.vehicleNumber);
  }

  get vehicleFormControl() {
    return this.vehicleForm.controls;
  }

  otp(value: any) {
    // if (this.isOtp == false) {

    //   this.SendOtp();
    // }
    // else {
    if (value == 'home') {
      this.homeForm1 = !this.homeForm1;
    }
    else if (value == 'health') {
      //  this.router.navigate(['HealthinsurancePolicy']);
      //this.ValidateOTP();
    }
    //}
  }

  // PopupOpen(){
  //   if(this.vehicleNumber && this.vehicleNumber.trim() !== ''){
  //     this.formData.isNew=false;
  //     this.formData.registrationNumber=this.vehicleNumber;
  //     this.isPopup=true;
  //   }
  // }

  PopupOpen(value: any) {
    // ;
    console.log(this.formData);
    if (this.vehicleNumber && this.vehicleNumber.trim() !== '' && value == 1) {
      if (value == 1) {
        this.validateRegistrationNumber(false);
        this.formData.isNew = false;
        this.formData.registrationNumber = this.vehicleNumber;
      }
      // else{
      //   this.formData.isNew=true;
      // }
      this.isPopup = true;

    } else if (value > 1) {
      this.firstPage = true;
      this.secondPage = false;
      this.isPopup = true;
      this.formData.isNew = true;
    }
    this.vehicleForm.get("isNew")?.setValue(value == 3);

  }

  dontKnowPopUp() {
    this.DontKnow = true;
    this.isPopup = true;
  }
  BoughtNewPopup() {
    this.Bought = true;
    this.isPopup = true;
  }


  back() {
    if (this.selectedTab > 1) {
      this.selectedTab = this.selectedTab - 1;
    }
  }
  activeMenu = 0;
  continue() {
    if (this.selectedTab < 5) {

      this.selectedTab = this.selectedTab + 1;
      console.log('enter')
      console.log(this.selectedTab)
    }

    if (this.activeMenu == 0) {
      this.isAge = true;
      this.isWhoInsureFilled = true;
    }
    else if (this.activeMenu == 1) {
      this.isLocation = true;
      this.isAgeFilled = true;
    }
    else if (this.activeMenu == 2) {
      this.isDetails = true;
      this.isLocationFilled = true;
    }
    this.activeMenu++;
  }

  changePage() {

    if (this.vehicleForm.get("isNew")?.value == true) {
      this.commercialVechiclesComponent?.setFormData(this.vehicleForm.value);
      this.isDontKnow = true;
      this.isVerifyVehicleDetails = false;
      this.isPopup = false;
    }
    else {
      this.firstPage = false;
      this.secondPage = true;
    }

    // if(this.vehicleFormControl['permit'].value.length > 0 && this.vehicleFormControl['category'].value ==  'PCV' && this.vehicleFormControl['pcVehicle'].value.length > 0 && this.vehicleFormControl['pcVehicle'].value.indexOf('Bus') <0)
    //   {
    //     this.firstPage=false;
    //     this.secondPage=true;
    //     this.isDisable=false;
    //   }
    //   else if(this.vehicleFormControl['permit'].value.length > 0 && this.vehicleFormControl['category'].value ==  'PCV' && this.vehicleFormControl['pcVehicle'].value.length > 0 && this.vehicleFormControl['pcVehicle'].value.indexOf('Bus') != -1  && this.vehicleFormControl['seatingCap'].value.length > 0 && this.vehicleFormControl['purpose'].value.length > 0)
    //   {
    //     this.firstPage=false;
    //     this.secondPage=true;
    //     this.isDisable=false;
    //   }
    //   else if(((this.vehicleFormControl['permit'].value.length > 0 && this.vehicleFormControl['category'].value ==  'GCV' && this.vehicleFormControl['gcVehicle'].value.length > 0) || (this.vehicleFormControl['category'].value ==  'MISC' && this.vehicleFormControl['miscVehicle'].value.length > 0 )) && this.vehicleFormControl['gVW'].value.length > 0)
    //   {
    //     this.firstPage=false;
    //     this.secondPage=true;
    //     this.isDisable=false;
    //   }
    //   else
    //   {
    //     this.isDisable=true;
    //   }
  }

  validateVehicleCategory(): boolean {
    if (this.vehicleFormControl['permit'].value.length > 0 && this.vehicleFormControl['category'].value == 'PCV' && this.vehicleFormControl['pcVehicle'].value.length > 0 && this.vehicleFormControl['pcVehicle'].value.indexOf('Bus') < 0) {

      this.isDisable = false;
    }
    else if (this.vehicleFormControl['permit'].value.length > 0 && this.vehicleFormControl['category'].value == 'PCV' && this.vehicleFormControl['pcVehicle'].value.length > 0 && this.vehicleFormControl['pcVehicle'].value.indexOf('Bus') != -1 && this.vehicleFormControl['seatingCap'].value.length > 0)// && this.vehicleFormControl['purpose'].value.length > 0)
    {

      this.isDisable = false;
    }
    else if (((this.vehicleFormControl['permit'].value.length > 0 && this.vehicleFormControl['category'].value == 'GCV' && this.vehicleFormControl['gcVehicle'].value.length > 0) || (this.vehicleFormControl['category'].value == 'MISC' && this.vehicleFormControl['miscVehicle'].value.length > 0)))// && this.vehicleFormControl['gVW'].value.length > 0)
    {

      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
    return this.isDisable;
  }

  validateExistingPolicyDetails(): boolean {
    let disable = true;

    let policyExpiryStatus = parseInt(this.vehicleForm.get('policyExpiryStatus')?.value??"0")
    let policyType = this.vehicleForm.get('previousPolicyType')?.value??""; 

    if(policyType == "" || policyExpiryStatus == 0 || (this.vehicleFormControl['previousPolicyCompany'].value??"") == ""
    || (this.vehicleFormControl['previousPolicyExpiryDate'].value??"") == "")
    {
      return true;
    }

    if ((parseInt(this.policyExpiryStatus) > 0 && this.policyType != null && this.policyType != undefined && this.policyType != "" && this.policyClaimed == 2 && parseInt(this.policyClaimedNCB) < 100)
      || (parseInt(this.policyExpiryStatus) > 0 && this.policyType != null && this.policyType != undefined && this.policyType != "" && this.policyClaimed == 1)
      //|| (parseInt(this.policyExpiryStatus) > 0 && this.policyType == 3)
    ) {
      disable = false;
    }
    else {
      disable = true;
    }

    return disable;
  }

  close() {
    this.isPopup = false;
  }
  Types(ty: string) {
    this.vehicleForm.get("category")?.setValue(ty);
    // this.type=ty;
    // //this.isDisable=false;
    // if (ty=="1"){
    //   this.formData.category="PCV";

    // }else if (ty=="2"){
    //   this.formData.category="GCV";
    // }else{
    //   this.formData.category="Misc";
    // }
    //this.formData.category=ty;
  }

  PVCType(event: any) {
    //this.selectedType=event.target.value;
    this.vehicleForm.get("pcVehicle")?.setValue(event.target.value);
  }

  SeaterType(event: any) {
    //this.formData.passCap = event.target.value;
    this.vehicleForm.get("seatingCap")?.setValue(event.target.value);
  }

  permit(per: string) {
    // this.prType=per;
    // this.formData.permit=per;
    this.vehicleForm.get("permit")?.setValue(per);
  }

  gotoVehicle() {
    // ;
    // var data= this.Polices;
    // this.formData.expiredStatus=this.Polices.value.expiredStatus;  
    // this.formData.existingPolicyComapany=this.Polices.value.existingPolicyComapany;  
    // this.formData.previousPolicyExpiryDate=this.Polices.value.previousPolicyExpiryDate;  
    // this.formData.previousPolicyType=this.Polices.value.previousPolicyType;  
    // this.formData.isOwnerShipTransferred=this.Polices.value.isOwnerShipTransferred;  
    // this.formData.claimsMadeInPreviousPolicy=this.Polices.value.claimsMadeInPreviousPolicy;  
    // this.formData.ncbCurrent=this.Polices.value.ncbCurrent;
    // this.formData.vehicleType=this.FirstPolices.value.vehicleType;
    // this.formData.GVW=this.FirstPolices.value.GVW;

    //this.dataService.setFormData(this.vehicleForm.value);
    this.commercialVechiclesComponent?.setFormData(this.vehicleForm.value);
    this.isDontKnow = true;
    this.isVerifyVehicleDetails = false;
    this.isPopup = false;

    //this.insuranceservice.QuotesValue(this.insuranceForm.value);
    //this.router.navigate(['/CommercialVechicles']);
  }
  ownership(val: string) {
    this.checked = val == 'Yes';
    this.vehicleForm.get("isOwnerShipTransferred")?.setValue(val);
  }

  async validateRegistrationNumber(showDetails: boolean) {
    // this.onClick.emit();
    this.spinner.show();
    ;
    const response = await this.insuranceservice.GetKycDetails(this.vehicleNumber).then((response) => {
      if (response?.isSuccess) {

        if (showDetails) {
          // if(this.previousPolicyCompany == "" && (parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyExpiryStatus) < 3))
          // {
          //   this.showPreviousPolicyCompanyError = true;
          // }
          // else
          {
            this.verifyBikeDetails = false;
            this.verifyBikeDetailspolicy = false;
            // this.IsQuotes = true;
            // setTimeout(async () => await this.getvehiclequotes1(), 500)
            // this.showMenu = this.menuService.showMenu();
            // this.appComponent.setShowMenuFlag(this.showMenu);
            // this.homeComponent.showMenu = this.showMenu;
          }
        }
        else {
          if (response.result.vehicle_category == "2WN") {
            // swal.fire({
            //   position: 'center',
            //   icon: 'error',
            //   title: 'Please provide valid four-wheeler number.',
            //   showConfirmButton: false,
            //   timer: 3000,
            // });

            swal.fire({
              position: 'center',
              imageUrl: "../../assets/alerts/warning.png",
              imageHeight: 89,
              // icon: 'error',
              title: 'Please provide valid four-wheeler number.',
              //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'Ok',
              cancelButtonColor: "#DF3A4D",
              customClass: {
                confirmButton: "error_btn",
              },
              // timer: 9000,
            });
          }
          else {
            this.verifyBikeDetails = true;
            this.registrationData = response.result;
            console.log(this.registrationData);
            //this.insuranceForm.value.VehicleType = this.registrationData.vehicle_category == "2WN" ? 2 : 1;
            //this.carNum = !this.carNum;
            // this.policy = true;
            // this.Type = true;
            // this.preClaim = true;
            this.fillVehicleForm();
            //this.loadVariantListByModel(this.registrationData.maker_model)
            this.vehicleForm.get('make')?.setValue('');
            this.vehicleForm.get('model')?.setValue('');
            this.loadMakeList(false, '', '');
            setTimeout(() => {
              this.loadMakeList(true, this.registrationData.maker_description, this.registrationData.maker_model);
            }, 1000);
          }

        }
      }
      else {
        // this.novalidpolicy();
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Invalid vehicle number.',
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      }
      this.spinner.hide();
    });
  }
  async fillVehicleForm() {
    this.vehicleForm.get('registrationNumber')?.setValue(this.registrationData.rc_number);
    this.vehicleForm.get('chasisNumber')?.setValue(this.registrationData.vehicle_chasi_number);
    this.vehicleForm.get('engineNumber')?.setValue(this.registrationData.vehicle_engine_number);
    this.vehicleForm.get('model')?.setValue(this.registrationData.maker_model);
    this.vehicleForm.get('make')?.setValue(this.registrationData.maker_description);
    this.vehicleForm.get('registrationDate')?.setValue(this.registrationData.registration_date);
    //moment(this.registrationData.registration_date,'YYYY-MM-DD').format('DD/MM/YYYY'));
    this.vehicleForm.get('registrationYear')?.setValue(this.registrationData.registration_date.split('-')[0]);
    this.vehicleForm.get('rto')?.setValue(`${this.registrationData.rc_number.slice(0, 4)}`);
    this.vehicleForm.get('city')?.setValue(`${this.registrationData.registered_at.split(' ')[0]}`);
    this.vehicleForm.get('firstName')?.setValue(`${this.registrationData.owner_name.split(' ').slice(0, 1).join(' ')}`);
    this.vehicleForm.get('lastName')?.setValue(`${this.registrationData.owner_name.split(' ').slice(1).join(' ')}`);
    this.vehicleForm.get('vehiclecategory')?.setValue(this.registrationData.vehicle_category);
    this.vehicleForm.get('vehicleIdv')?.setValue("0");
    this.vehicleForm.get('previousPolicyCompany')?.setValue(this.registrationData.insurance_company);
    this.vehicleForm.get('previousPolicyNumber')?.setValue(this.registrationData.insurance_policy_number);
    this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(this.registrationData.insurance_upto);
    this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.registrationData.insurance_upto);
    let fuel_type = this.registrationData.fuel_type;
    if (fuel_type != null && fuel_type != undefined && fuel_type != "") {
      fuel_type = fuel_type.toLowerCase();
      fuel_type = fuel_type.charAt(0).toUpperCase() + fuel_type.slice(1);
    }
    this.vehicleForm.get('fuelType')?.setValue(fuel_type);
    this.vehicleForm.get('variant')?.setValue(this.registrationData.variant);
    this.vehicleForm.get('engineCapacityAmount')?.setValue(this.registrationData.cubic_capacity);
    this.vehicleForm.get('PACover')?.setValue("Yes");
    this.vehicleForm.get('RegisteredInTheNameOf')?.setValue("Individual");
    this.vehicleForm.get('isValidDrivingLicenseAvailable')?.setValue("Yes");
    //moment(this.registrationData.insurance_upto,'YYYY-MM-DD').format('DD/MM/YYYY'));
    //this.vehicleForm.get('yearOfManufacture')?.setValue(this.registrationData.manufacturing_date.split('/')[1]);
    this.insuranceForm.get('vehicleDetails')?.setValue(this.vehicleForm.value);
    //this.VehiclequotesComponent.GetQuoteInfo(this.insuranceForm.value);
  }

  async loadMakeList(searchByVahanData: boolean = false, name: string, modelName: string) {
    name = name ?? "";
    let request = { Type: 1, Name: (searchByVahanData == true ? name : ''), searchByVahanData: searchByVahanData };
    const res = await this.vehicleService.GetVehicleCompanyDetails(request);
    if (res != null && res.result) {
      if (searchByVahanData == false) {
        this.vehicleMakeList = res.result.map((item: any) => { return { text: item, value: item, id: item } });
      }
      else {
        ;
        if (res.result[0] != null && res.result[0] != undefined && res.result[0] != "") {
          this.vehicleForm.get('make')?.setValue(res.result[0]);
          this.loadModelListByMake('');
          setTimeout(() => {
            this.loadModelListByMake(modelName, true)
          }, 1000);
        }
      }
    }
  }

  async loadModelListByMake(model: string, searchByVahanData: boolean = false) {
    model = model ?? "";
    let request = { Type: 3, searchByVahanData: searchByVahanData };
    const res = await this.vehicleService.GetVehicleModelDetails(request);
    if (res != null && res.result) {
      if (searchByVahanData == false) {
        this.vehicleModelList = res.result.map((item: any) => { return { text: item, value: item, id: item } });
      }
      else {
        if (res.result[0] != null && res.result[0] != undefined && res.result[0] != "") {
          this.vehicleForm.get('model')?.setValue(res.result[0]);
          this.loadVariantListByModel(this.vehicleForm.get('model')?.value);
        }
      }
    }
  }

  async loadVariantListByModel(model: string) {
    // if(model)
    // {
    //   model = model.indexOf(' ') > 0 ? model.split(" ")[0] : model;
    // }
    let request = { ModelName: model };
    const res = await this.vehicleService.GetVehicleVariantDetails(request);
    if (res != null && res.result) {
      this.vehicleVariantList = res.result.map((item: any) => { return { text: item, value: item, id: item } });
    }
  }

  async loadInsurancePartners() {
    let response = await this._lookupService.GetInsurancePartnersList('m');
    ;
    if (response?.isSuccess) {
      this.insurancePartnersList = response.result.map((m: any) => { return { text: m.insuranceName, value: m.insuranceName, id: m.insuranceName } });
      if (this.insurancePartnersList.filter((f: any) => { return f.text == this.vehicleForm.get('previousPolicyCompany')?.value })?.length > 0) {
        //this.previousPolicyCompany = this.vehicleForm.get('previousPolicyCompany')?.value;
      }
    }
  }

  policyExpiryStatusList = [
    { value: 0, text: 'Select Existing Policy Status' },
    { value: 1, text: 'Not Expired' },
    { value: 2, text: 'Expired Within 90 Days' },
    // {value:4,text:'Expired More than 90 Days ago'},
    // {value:3,text:"I don't remember"}   

  ];

  policyTypeList =
    [
      { value: '', text: 'Select Existing Policy Type' },
      { value: 'Comprehensive',text:'Standard/Comprehensive Coverage'},    
      { value: 'Thirdparty',text:'Third-Party Liability Coverage'}
    ];

  policyClaimedList =
    [
      { value: 0, text: 'Select Existing Policy Claim' },
      { value: 1, text: 'Yes' },
      { value: 2, text: 'No' }
    ];

  policyNcbClaimedList = [
    { value: 100, text: 'Select existing NCB (No Claim Bonus) ' },
    { value: 0, text: '0%' },
    { value: 20, text: '20%' },
    { value: 25, text: '25%' },
    { value: 35, text: '35%' },
    { value: 45, text: '45%' },
    { value: 50, text: '50%' }
  ];

  policyNcbClaimMadeList = [
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    // { value: '4', text: '4' },
    // { value: '5', text: '5' }
  ];

  showNCBTooltip: boolean = false;
  showPolicyStatusTooltip: boolean = false;
  policyExpiryStatus: any = 0;
  showPreviousPolicyCompany: boolean = false;
  showPreviousPolicyCompanyError: boolean = false;
  policyClaimed: any = 0;
  policyClaimedNCB: any = 100;
  claimsMadeInPreviousPolicyNo: any = "1";
  policyType: any = "";
  Type: any = false;
  preClaim: any = false;
  isClaimed: any = false;
  claimPercentage: any = false;
  previousPolicyCompany: string = "";
  showViewQuotesButton: boolean = false;
  openNCBModal() {
    this.showNCBTooltip = true;
  }

  closeNCBModal() {
    this.showNCBTooltip = false;
  }

  openPolicyStatusModal() {
    this.showPolicyStatusTooltip = true;
  }

  closePolicyStatusModal() {
    this.showPolicyStatusTooltip = false;
  }

  preClaimDetail(claimVal: any): void {
    let index = parseInt(claimVal.target.value);
    if (claimVal == 'Yes') {
      this.isClaimed = true;
      this.claimPercentage = false;
    } else {
      this.isClaimed = false;
      this.claimPercentage = true;
    }
    if (index == 1) {
      this.isClaimed = true;
      this.claimPercentage = false;
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("Yes");
      this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue("1");
      this.claimsMadeInPreviousPolicyNo = "1";
    }
    else {
      this.isClaimed = false;
      this.claimPercentage = true;
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("No");
      this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue("1");
      this.claimsMadeInPreviousPolicyNo = "1";
      if (this.vehicleForm.get('previousPolicyType')?.value == "Comprehensive") {
        this.policyClaimedNCB = 0;
      }
      else if (this.vehicleForm.get('previousPolicyType')?.value == "Thirdparty") {
        this.policyClaimedNCB = 0;
      }
    }
    console.log('Clicked value:', claimVal);
    this.toggleViewQuotesButton();
    this.toggleNCBPercentage();
  }

  policyDetail(polVal: any): void {
    let index = parseInt(polVal.target.value);
    this.toggleViewQuotesButton();
    this.vehicleForm.get('policyExpiryStatus')?.setValue(index.toString());
    if (index == 1) {
      this.vehicleForm.get('isPreviousPolicyHolder')?.setValue("True");
      this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(moment(new Date()).format('YYYY-MM-DD'));
      this.minPolicyExpiryDate = moment(new Date()).format('YYYY-MM-DD');
      this.maxPolicyExpiryDate = moment(new Date()).add(1, 'years').format('YYYY-MM-DD');
    }
    else if (index == 2) {
      this.vehicleForm.get('isPreviousPolicyHolder')?.setValue("false");
      this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(moment(new Date()).add(-1, 'months').format('YYYY-MM-DD'));
      this.minPolicyExpiryDate = moment(new Date()).add(-90, 'days').format('YYYY-MM-DD');
      this.maxPolicyExpiryDate = moment(new Date()).add(-1, 'days').format('YYYY-MM-DD');
      // this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.vehicleForm.get('previousPolicyExpiryDate')?.value);
    }
    else {
      this.vehicleForm.get('isPreviousPolicyHolder')?.setValue("False");
      //this.vehicleForm.get('previousPolicyExpiryDate')?.setValue(moment(new Date()).add(-4, 'months').format('YYYY-MM-DD'));
      this.minPolicyExpiryDate = moment(new Date()).add(-5, 'years').format('YYYY-MM-DD');
      this.maxPolicyExpiryDate = moment(new Date()).add(5, 'years').format('YYYY-MM-DD');
      // this.insuranceForm.get('previousPolicyExpiryDate')?.setValue(this.vehicleForm.get('previousPolicyExpiryDate')?.value);
    }

    // if(this.chosenOption.length == 0 || ( this.chosenOption.length > 0 && this.chosenOption.filter(f=> f.order ==1 ).length == 0)){
    //   this.chosenOption.push({label:"Policy's Expiry Status", value:polVal, value1:"", order :1});
    // }
    // else
    // {
    //   this.chosenOption = this.chosenOption.map(p =>
    //     p.order === 1
    //       ? { ...p, value: polVal }
    //       : p
    //   );
    // }
    this.toggleNCBPercentage();
    console.log('Clicked value:', polVal);
  }
  coverageDetail(covVal: any): void {
    //this.Type = !this.Type;
    this.preClaim = true;
    //this.setCoverageValue(covVal);
    this.policyClaimedNCB = 100;
    this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue("1");
    this.claimsMadeInPreviousPolicyNo = "1";
    let value = covVal.target.value;
    this.policyType = value;
    if (value != "Thirdparty") {
      this.vehicleForm.get('previousPolicyType')?.setValue("Comprehensive");
      this.vehicleForm.get('typeOfCover')?.setValue("Comprehensive");
      this.vehicleForm.get('ncbprevious')?.setValue("0");
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("No");
      this.isClaimed = false;
      this.claimPercentage = true;
      this.preClaim = true;
      //this.setnsbValue("20%");
      this.policyClaimed = 2;
      this.policyClaimedNCB = 0;
      //this.showViewQuotesButton = true;
      //this.setPreviousClaimed('No');
      //this.value2=['20%','25%','35%','45%','50%'];
    }
    else {
      this.vehicleForm.get('previousPolicyType')?.setValue("Thirdparty");
      this.vehicleForm.get('typeOfCover')?.setValue("LiabilityOnly");
      this.vehicleForm.get('ncbprevious')?.setValue("0");
      this.vehicleForm.get('claimsMadeInPreviousPolicy')?.setValue("No");
      this.preClaim = true;
      this.claimPercentage = false;
      this.isClaimed = false;
      this.policyClaimed = 2;
      this.policyClaimedNCB = 0;
      this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue("1");
      this.claimsMadeInPreviousPolicyNo = "1";
      //this.setnsbValue("0%");
      //this.showViewQuotesButton = true;
      //this.setPreviousClaimed('No');
      //this.value2=['0%'];
    }
    // else {
    //   this.vehicleForm.get('previousPolicyType')?.setValue("");
    //   this.vehicleForm.get('typeOfCover')?.setValue("");
    //   //this.showViewQuotesButton = true;
    // }
    this.toggleViewQuotesButton();
    this.toggleNCBPercentage();
    console.log('Clicked value:', covVal);
  }
  // nsbValDetail(nsbVal: string, index: number): void {
  //   this.vehicleForm.get('ncbprevious')?.setValue(nsbVal.replace(/[^a-zA-Z0-9]/g, ''));
  //   console.log('Clicked value:', nsbVal);
  //   if(this.chosenOption.length == 0 || (this.chosenOption.length > 0 && this.chosenOption.filter(f=> f.order ==3 ).length == 0)){
  //     this.chosenOption.push({label:"Policy Claimed",value: (this.isClaimed ? "Yes" : "No"), value1:(this.isClaimed ? "" : nsbVal), order :3});
  //   }
  //   else
  //   {
  //     this.chosenOption = this.chosenOption.map(p =>
  //       p.order === 3
  //         ? { ...p, value1: (this.isClaimed ? "" : nsbVal)  }
  //         : p
  //     );
  //   }

  // }

  nsbValDetail(nsbVal: any): void {
    let nbsvalue = nsbVal.target.value.replace(/[^a-zA-Z0-9]/g, '');
    this.vehicleForm.get('ncbprevious')?.setValue(nbsvalue);
    this.policyClaimedNCB = nbsvalue;
    console.log('Clicked value:', nsbVal);
    this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue("1");
    this.claimsMadeInPreviousPolicyNo = "1";
    this.toggleViewQuotesButton();
    //this.setnsbValue(nsbVal);
  }

  claimCountChanged(nsbVal: any): void {
    let claimCount = nsbVal.target.value;
    this.vehicleForm.get('claimsMadeInPreviousPolicyNo')?.setValue(claimCount);
    this.claimsMadeInPreviousPolicyNo = claimCount;
  }

  toggleViewQuotesButton() {
    if ((parseInt(this.policyExpiryStatus) > 0 && this.policyType != null && this.policyType != undefined && this.policyType != "" && this.policyClaimed == 2 && parseInt(this.policyClaimedNCB) < 100)
      || (parseInt(this.policyExpiryStatus) > 0 && this.policyType != null && this.policyType != undefined && this.policyType != "" && this.policyClaimed == 1)
      //|| (parseInt(this.policyExpiryStatus) > 0 && this.policyType == 3)
    ) {
      this.showViewQuotesButton = true;
    }
    else {
      this.showViewQuotesButton = false;
    }
  }

  toggleNCBPercentage() {
    if ((parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyExpiryStatus) < 3) && this.policyType != null && this.policyType != undefined && this.policyType != "Thirdparty" && parseInt(this.policyClaimed) == 2) {
      this.claimPercentage = true;
    }
    else {
      this.claimPercentage = false;
    }

    if (parseInt(this.policyExpiryStatus) > 0 && parseInt(this.policyExpiryStatus) < 3) {
      this.showPreviousPolicyCompany = true;
    }
    else {
      this.showPreviousPolicyCompany = false;
    }
  }




  previousPolicyCompanyChange(data: any) {
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data.target.value);
  }

  onPrevCompSelection(data: AutoCompleteItem) {
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data.text);
    this.previousPolicyCompany = data.text;
  }

  onPrevCompBlur(data: any) {
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data);
    this.previousPolicyCompany = data;
  }

  ShowQuotes(forData: any) {
    this.isDontKnow = false;
    this.isQuotes = true;
    this.vehiclequotesComponent.GetQuoteInfo(forData);
  }

  ShowQuoteDetials(vehiclePremiumData: any) {
    this.insuranceForm.addControl('insuranceCompany', new FormControl('', Validators.required));
    console.log('Data received from vehicles quotes component:', vehiclePremiumData);
    this.insuranceForm.get('quoteId')?.setValue(vehiclePremiumData.quoteDetails.quoteId);
    this.insuranceForm.get('isMockUp')?.setValue(vehiclePremiumData.isMockUp);
    this.insuranceForm.get('insuranceCompany')?.setValue(vehiclePremiumData?.quoteDetails?.insuranceCompany);
    this.vehicleForm.get('bodyIDV')?.setValue(vehiclePremiumData?.quoteDetails?.bodyIDV);
    this.vehicleForm.get('chassisIDV')?.setValue(vehiclePremiumData?.quoteDetails?.chassisIDV);
    this.isQuotes = false;
    //this.IsQuoteSelected = false;
    this.isProposer = true;
    this.registrationData = vehiclePremiumData.vehicleData;
    //this.vehicleOwnerDetailsComponent.showMenu = this.showMenu;
    setTimeout(async () => await this.vehicleOwnerDetailsComponent?.QuoteDetails(vehiclePremiumData, vehiclePremiumData.vehicleData), 100);
  }

  ShowPolicyDetailsPage(data: any) {
    console.log('Data received insurance details component:', data);
    this.isQuotes = false;
    //this.IsQuoteSelected = false;
    this.isProposer = true;
    //this.IsPolicyDetail = false;
    this.insuranceForm.get('proposerDetails')?.setValue(data.UserDetails);
    this.insuranceForm.get('nomineeDetails')?.setValue(data.NomineeDetails);
    this.insuranceForm.get('vehicleDetails')?.setValue(data.vehicleData);
    ;
    this.insuranceformData = data.insuranceformData;
    this.insuranceformData.proposerDetails = data.UserDetails;
    this.insuranceformData.nomineeDetails = data.NomineeDetails;
    this.insuranceformData.vehicleDetails = data.vehicleData;
    this.insuranceformData.existingTPPolicyDetails = data.existingTPPolicyDetails;
    this.insuranceformData.proposerDetails.city = this.insuranceformData.proposerDetails.district;
    this.CreatePolicy();
  }
  aadhaarNumber: string = "";

  goTo(data: any) {
    if (data == "partner_home") {
      this.IsDontKnowBike = false;
      this.isQuotes = false;
      this.isProposer = false;
    } else if (data == "vehicle_owner") {
      this.IsDontKnowBike = false;
      this.isQuotes = true;
      this.isProposer = false;
      //this.insuranceservice.QuotesValue(this.insuranceForm.value);
    }
    else if (data == "vehicle_quote") {
      if (this.router.url != "/") {
        this.router.navigate(['']);
      }
      else {
        this.IsDontKnowBike = false;
        this.isQuotes = false;
        this.isProposer = false;
        this.isVerifyVehicleDetails = true;
        ;
        let dd = this.insuranceForm.value;
        //this.insuranceservice.QuotesValue(this.insuranceForm.value);
      }
    }
  }

  async CreatePolicy() {
    this.spinner.show();
    this.showIsBreakIn=false;
    // if(this.insuranceForm.value.vehicleDetails == null){
    //   this.loadVehicleForm();
    // }
    const response: any = await this.insuranceservice.CreateQuote(this.insuranceformData);//this.insuranceForm.value);
    if (response?.isSuccess && response?.result?.code == 2) {
      //pre inspection case
        this.showIsBreakIn=true;
        this.insuranceformData.isBreakIn=true;
      }
      else if (response?.isSuccess && response?.result?.code == 3) {
        //after pre inspection api trigger
         this.showIsBreakInTriggered=true;
        //   swal.fire({
        //     imageUrl: "../../assets/alerts/success.png",
        //     imageHeight: 89,
        //     title:'',
        //     text: '',
        //     html: response?.result?.message,
        //     showDenyButton: false,
        //     confirmButtonText: "OK",
        //     confirmButtonColor:"#0DB561",
        //     showCancelButton: false,  
        //     cancelButtonText:"Don't want",    
        //     denyButtonText: "Don't want",
        //     denyButtonColor:"#0DB561",
        //   }).then((result) => {
        //     //this.router.navigate(['policyDetails']);
        //   });
    }
    else if (response?.isSuccess && response?.result?.code == 1) {
      if (response.result.isKycVerified == false && response.result.kycLink != null && response.result.kycLink != undefined && response.result.kycLink != "") {

        this.kycUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink);
        if (response.result.kycLink.toLowerCase().indexOf('https') == -1 || this.insuranceForm.value.insuranceCompany == 14 || this.insuranceForm.value.insuranceCompany == 9 || this.insuranceForm.value.insuranceCompany == 7 || this.insuranceForm.value.insuranceCompany == 13) {
          this.showhttpKYCPopup = true;
          this.vehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
        else {
          this.showKYCPopup = true;
          this.vehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
        this.insuranceformData.ApplicationId = response.result.applicationId;
        this.insuranceformData.PolicyNumber = response.result.policyNumber;
        this.insuranceformData.url = response.result.kycLink;
      }
      else if (response?.result?.paymentObject != null && response?.result?.paymentObject?.paymentUrl != null && response?.result?.paymentObject?.paymentUrl != undefined && response?.result?.paymentObject?.paymentUrl != '') {
        this.paymentObject = response?.result?.paymentObject;
        this.isPaymentLink = true;
        this.isProposer = false;
        this.preClaim = false;
      }
      else
        if (response.result.paymentLink && !this.insuranceForm.get('isMockUp')?.value) {
          swal.fire({
            position: 'center',
            icon: 'success',
            title: response?.result?.Message,
            showConfirmButton: false,
            timer: 3000,
          });

          window.open(response.result.paymentLink, "_self")
        }
        else if (response?.result?.royalSundaramResponse != null) {
          this.royalDetails = response?.result?.royalSundaramResponse;
          this.isRoyalSundaram = true;
          //  this.isBikeR = false;
          this.preClaim = false;
          // this.router.navigate(['royal',JSON.stringify(response?.result?.royalSundaramResponse)]);
        }
        else if (response?.result?.futureGeneralQuoteResponse != null) {
          this.futureDetails = response?.result?.futureGeneralQuoteResponse;
          this.isFutureGeneral = true;
          //this.isBikeR = false;
          this.preClaim = false;
        }
        else if (response?.result?.createUniversalSompoResponse != null) {
          const downloadLink = document.createElement('a');
          downloadLink.href = response?.result?.createUniversalSompoResponse.url;
          downloadLink.click();
        }
        else if (response?.result?.reliance_GeneralResponse != null) {
          this.RelianceDetails = response?.result?.reliance_GeneralResponse;
          this.isRelianceGeneral = true;
          this.preClaim = false;
        }

        else if (response?.result?.shriram_GeneralResponse != null) {
          this.ShriramDetails = response?.result?.shriram_GeneralResponse;
          this.isShriramGeneral = true;
          this.preClaim = false;
        }
        else {
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            // icon: 'error',
            title: response?.result?.message,
            //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            }
          });
        }
    }
    else {
      if (response.result.isKycVerified == false && response.result.kycLink != null && response.result.kycLink != undefined && response.result.kycLink != "") {
        this.kycUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink);
        if (response.result.kycLink.toLowerCase().indexOf('https') == -1 || this.insuranceForm.value.insuranceCompany == 14 || this.insuranceForm.value.insuranceCompany == 9 || this.insuranceForm.value.insuranceCompany == 7 || this.insuranceForm.value.insuranceCompany == 13) {
          this.showhttpKYCPopup = true;
          this.vehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
        else {
          this.showKYCPopup = true;
          this.vehicleOwnerDetailsComponent.setShowNegativeKycInfo(true);
        }
        this.insuranceformData.ApplicationId = response.result.applicationId;
        this.insuranceformData.PolicyNumber = response.result.policyNumber;
        this.insuranceformData.url = response.result.kycLink;
      }
      else {
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          // icon: 'error',
          title: response?.result?.message ?? "Something went wrong.",
          //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          }
        });
      }
    }
    this.spinner.hide();
  }
  closeKYCModal() {
    this.showKYCPopup = false;
  }
  closehttpKYCModal() {
    this.showhttpKYCPopup = false;
  }
  goToPolicyDetails(){
    this.router.navigate(['policyDetails']);
  }

  closeBreakInModal() {
    this.showIsBreakIn = false;
  }

  isTyping = !0;
  //focus
onFocusInput(e: any) {
  //document.getElementById(id).placeholder = "";
}
//key down
formatRegNo(e:any) {
if ((e && e.keyCode && e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode >= 65 && e.keyCode <= 90) &&
navigator.userAgent.match(/UCBrowser/))
    return !1;
var t = e.keyCode;
if (-1 != navigator.userAgent.indexOf("MSIE"))// || 1 == !!document.documentMode) 
  {
    if (32 == t)// || 8 == t)
        return !1;
    var o = e.target.value;
    o.length >= 3 && (o = this.formatReg(o)),
    o.length >= 5 && (o = this.formatReg(o)),
    o.length >= 7 && (o = this.formatReg(o)),
    e.target.value = o;
    this.vehicleNumber = e.target.value;
  } else
    //setTimeout(()=>{
        if (32 == t)// || 8 == t)
            return !1;
        var ev = e.target.value;
        ev.length >= 3 && (ev = this.formatReg(ev)),
        ev.length >= 5 && (ev = this.formatReg(ev)),
        ev.length >= 7 && (ev = this.formatReg(ev)),
        e.target.value = ev;
        this.vehicleNumber = e.target.value;
        return 1;
    //}, 100)
  }
//paste and type
checkRegistrationNumber(e:any) {
  this.isTyping && (this.isTyping = !1),
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9\- ]+/g, '');
  e.target.value = e.target.value.toUpperCase()
  if(e.target.value && e.target.value.includes('-')){
    var temp_regno = e.target.value.split('-');
  setTimeout(()=>{
  var temp_regno = e.target.value.split('-');
  if(temp_regno && temp_regno.length > 3 && temp_regno[3] && temp_regno[3].length > 4){
    e.target.value = (e.target.value.substring(0, e.target.value.length - 1));
    this.vehicleNumber = e.target.value;
    }
  },100)
  }
  //this.onVehicleRegNumberInput();
}

formatReg(value:any) {
  if(value != null && value != undefined && value != "" ){
    var t = value;//value != null && value != undefined && value != "" ? value : e.target.value;
    t = t.replace(/-/g, "");
    var o = new Array(4);
    o[0] = t.slice(0, 2),
    o[1] = "",
    o[2] = "",
    o[3] = "";
    try {
        isNaN(t.slice(2, 4)) ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
        isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4))) : o[1] = t.slice(2, 4),
        isNaN(t.slice(4, 8)) ? (o[2] = o[2] + t.slice(4, 5),
        isNaN(t.slice(5, 6)) && isNaN(t.slice(6, 7)) ? o[2] = o[2] + t.slice(5, 7) : (isNaN(t.slice(5, 6)) ? o[2] = o[2] + t.slice(5, 6) : o[3] = o[3] + t.slice(5, 6),
        isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
        isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11))) : o[3] = o[3] + t.slice(4, 8)
    } catch (e) {}
    return o.join("-").replace(/ /g, "").replace(/--/g, "-").replace(/-\s*$/, "").replace(/[^- 0-9 a-z A-z]/g, "")
  }
    return value;
}

async fetchVehicleBodyType(){
  this.CommVehicleGCVehicleList = await this.getVehicleBodyType('GCV');
  this.CommVehiclePCVehicleList = await this.getVehicleBodyType('PCV');
  this.CommVehicleMISCVehicleList = await this.getVehicleBodyType('MISC');
}

async getVehicleBodyType(category:string){
  const res = await this._lookupService.GetVehicleBodyType(category);
  debugger;
  if (res != null && res.result) {
      return res.result;
  }
}
}


