import { Component } from '@angular/core';

@Component({
  selector: 'app-document-popover',
  styleUrls: ['./document-popover.component.css'],
  template: `<div>
  <!-- <button (click)="onEditClicked()" class="btn btn-sm btn-primary">Edit</button> -->
  <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_24_18" title="Edit Document" (click)="onEditClicked()" data-bs-toggle="offcanvas"
  data-bs-target="#adddocument" aria-controls="offcanvasScrolling">
  <img src="../../assets/svgIcon/file-download.svg" alt="Downlaod" class="icon_width_height_24_18" title="Download Document" (click)="onDownloadClicked()">
</div>`
})
export class DocumentPopoverComponent {
  ngOnInit() {

  }
  params: any;
  agInit(params: any): void {
    this.params = params;
  }
  constructor() { }
  async onEditClicked() {
    this.params.context.componentMember.editDocument(this.params.data);
  }

  async onDownloadClicked() {
    this.params.context.componentMember.downloadDocument(this.params.data);
  }
}
