<app-navbar></app-navbar>
<!-- <div class="content-box">
    <div class="first_box">
        <div class="container">
            <div class="row content-body text-center">
                <h3 style="color: var(--main);">Career</h3>
                <h1 class="mt-2">Welcome to the Insurance Company Career Page!</h1>
                <h3 class="mt-2" style="color: #848493;">SRG is looking for people who have passion to perform. Here,
                    great performers never go unnoticed and rightly rewarded. We offer suitable opportunity to grow and
                    develop in a very calm & friendly space. We put extra significance to equate professional and
                    personal growth. To expand existing skill, we provide tools and support along with plenty of
                    opportunity to learn, grow and accomplish. Each member of our team gets direct impact on company's
                    success. If someone wants to succeed by putting honesty & hard work, this is the place.</h3>
            </div>
            <div class="row backgroud_banner">
            </div>
        </div>
    </div>
    <div class="second_box">
        <div class="container">
            <div class="row content-body text-center">
                <h3 style="color: var(--main);">Our Culture</h3>
                <h1 class="mt-2">Become a Part of Our Culture</h1>
            </div>
            <div class="row">
                <div class="culture_subCard col">
                    <h3>Teamwork</h3>
                    <p class="font-body"></p>
                </div>
                <div class="culture_subCard col">
                    <h3>Learning</h3>
                    <p class="font-body"></p>
                </div>
                <div class="culture_subCard col">
                    <h3>Inclusion and Diversity</h3>
                    <p class="font-body"></p>
                </div>
                <div class="culture_subCard col">
                    <h3>Innovation and Creativity</h3>
                    <p class="font-body"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="third_box">
        <h3>Title</h3>
        <div class="container">
            <div class="card-container d-flex card bg-dark" style="flex-direction: row; border-radius:32px">
                <div style="margin-left: 5rem;">
                    <img class="phone1 rounded-5" src="../../assets/images/android_img.png"
                        style="height: 400px;width:400px;">
                </div>
                <div style="margin: auto;">
                    <h1 style="color: #FFFFFF;">
                        Pay, invest, save your<br> money with a <span style="color: var(--main);">
                            single
                            app
                        </span>
                    </h1>
                    <span class="font-body" style="color:#C2C2C9!important;">Get control of all your insurance needs
                        anywhere,
                        anytime</span>
                    <div class="d-flex mt-5">
                        <i class="fa fa-compress" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                        <p>Compare different insurance policies</p>&nbsp;&nbsp;&nbsp;&nbsp;
                        <i class="fa fa-shopping-cart" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                        <p>Buy, store and share all your policies online</p>
                    </div>
                    <div class="d-flex">
                        <i class="fa fa-truck" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                        <p>Track your policy status on the go</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <i class="fa fa-download" style="color:white;" aria-hidden="true"></i>&nbsp;&nbsp;
                        <p>Download your policy with a single tap</p>
                    </div>
                    <p style="color: #E7E7E9 !important;">Download our app from</p>
                    <div class="d-flex">
                        <a href="https://play.google.com/store/apps/details?id=com.bimag.app"><img class="rounded-3"
                                src="../../assets/images/googlePlay.jpg" width="180"></a>&nbsp;&nbsp;
                        <a href=""><img class="rounded-3" src="../../assets/images/appStore.jpg" width="180"></a>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <div class="footer-tab" style="background-color: #F3F3F4;">
        <div class="row">
            <div class="col-8">
                <ul class="d-flex mt-5" style="margin-left: 52px;">
                    <h6 class="footer-item" routerLink="/aboutus">ABOUT US</h6>
                    <h6 class="footer-item" routerLink="/career">CAREERS</h6>
                    <h6 class="footer-item">CONTACT</h6>
                    <h6 class="footer-item">FAQS </h6>
                    <h6 class="footer-item">BLOGS </h6>
                    <h6 class="footer-item" routerLink="/sitemap">SITEMAP</h6>
                </ul>
            </div>
            <div class="col-4">
                <div class="row d-flex mr-5 mt-3">
                    <div class="col dimension">
                        <a href="#"><img class="mt-2" src="../../assets/svgIcon/Whatsapp.svg" width="20"></a>
                    </div>&nbsp;
                    <div class="col dimension">
                        <a href="https://www.instagram.com/Click4bima/"><img class="mt-2"
                                src="../../assets/svgIcon/Insta.svg" width="20"></a>
                    </div>&nbsp;
                    <div class="col dimension">
                        <a href="https://www.facebook.com/Click4rbima"><img class="mt-2"
                                src="../../assets/svgIcon/fb2.svg" width="11"></a>
                    </div>&nbsp;
                    <div class="col dimension">
                        <a href=""><img class="mt-2" src="../../assets/svgIcon/Linkedin.svg" width="20"></a>
                    </div>&nbsp;
                    <div class="col dimension">
                        <a href="#"><img class="mt-3" src="../../assets/svgIcon/Youtube2.svg" width="20"></a>
                    </div>&nbsp;
                    <div class="col dimension">
                        <a href="https://twitter.com/Click4bima"><img class="mt-2"
                                src="../../assets/svgIcon/Twitter.svg" width="20"></a>
                    </div>
                    <div class="col dimension">
                        <a href="mailto: Click4bima@gmail.com"><img class="mt-2" src="../../assets/svgIcon/mail.svg"
                                width="20"></a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="content-box">
    <div class="container">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Career</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">SRG is looking for people who have passion to perform. Here, great performers
                    never go
                    unnoticed and rightly rewarded. We offer suitable opportunity to grow and develop in a very calm &
                    friendly space. We put extra significance to equate professional and personal growth. To expand
                    existing
                    skill, we provide tools and support along with plenty of opportunity to learn, grow and accomplish.
                    Each
                    member of our team gets direct impact on company's success. If someone wants to succeed by putting
                    honesty & hard work, this is the place.</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Corporate Governance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        The corporate governance of our organization is based on an effective independent board
                        and separation of the board's supervisory role from the executive management team. Presently The
                        Board
                        members are having excellent records in their respective field of business operation supported
                        by a team
                        of seasoned managerial experts.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Code of conduct</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        Our aim is to become people's choice in all areas of operation to adopt a fair business
                        practice exceeding customer expectations by strictly implementing high standards of Corporate
                        Governance
                        in letter and spirit. We believe that every conduct of the company and its employee are the
                        focus of
                        public attention and we need to reinforce it with more values that will help us to comply with
                        legal
                        requirements, improve performance of the company and to deal with all stakeholders in a fair and
                        transparent manner. Also we are committed to review and update our Policies and Procedures to
                        begin
                        modern policies and actions which are customer friendly and promote financial prudence.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Philosophy of the Code</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">he company expects all Directors and members of the Management to exercise good
                        judgment, ensure the interest, safety and welfare of customers, employees and other stakeholders
                        and to
                        maintain a cooperative, efficient and productive work environment in the organization.</p>
                    <p class="font-body">Directors and members of the Management should discharge their duties honestly
                        and
                        diligently. The Code of the company needs to be followed while working in the premises of the
                        Company,
                        offsite locations inside or outside India, at company sponsored business and social events or
                        where they
                        act as representatives of the company.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Conflict of Interest</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Conflict of interest occurs when personal interest of any member of the Board
                        and
                        Management interferes with the interest of the company which should be avoided. Conflict can
                        arise in
                        many situations. It is not possible to cover every possible conflict at times, also, it will not
                        be easy
                        to distinguish between proper and improper activity.</p>
                    <p class="font-body">Some of the common circumstances that may lead to a conflict of interest as :-
                        Employment/Outside Assignment :-</p>
                </div>
            </div>
            <div class="mt-3">
                <ul>
                    <li>Director and Members of Management are prohibited from engaging any activity/ employmentthat
                        interferes with their performance or responsibilities to the company or otherwise is in conflict
                        with or prejudicial to the company.</li>
                    <li>Business Interest - If member(s) of the Board of Directors or Management consider investing in
                        securities issued by the company's customer, supplier or competitor, they should ensure that
                        these
                        investments do not compromise their responsibilities or interest of the company.
                        Directors and members of the Management should avoid conducting company’s business with a
                        relative
                        or a firm/Company or Association in which the relative has significant role. Relatives shall
                        include
                        kith and kin and blood relations. If such a related party transaction is unavoidable, they must
                        disclose its nature to the Board of Directors.
                    </li>
                </ul>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Compliance of Law</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Directors and Members of Executive Management must comply with applicable laws,
                        rules
                        and regulations, regulatory orders, statutory or otherwise. All financial transactions of the
                        Company
                        shall be conducted in a fair and transparent manner. No one should manipulate, falsify or alter
                        the
                        transactions in the books of accounts. Internal accounting and audit procedures shall fairly and
                        accurately reflect all the Company’s transactions.</p>
                    <p class="font-body">Some of the common circumstances that may lead to a conflict of interest as :-
                        Employment/Outside Assignment :-</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Use of Company's Assets and Resources</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The assets of the Company should not be misused. These include tangible assets
                        such as
                        equipment, systems, materials, etc. as well as intangible assets such as proprietary
                        information,
                        relationship with customers and suppliers, etc.</p>
                </div>
                <div class="text-center apply_now_div mt-3">
                    <a href="/Vacancy" class="btn apply_now_btn">Apply Now</a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>