<div class="user-dashboard">
    <div class="row user-route">

        <form id="PostForm" name="PostForm" autocomplete="off" [action]="ShriramDetails.paymentURL" method="post">
            <input name="createdBy" [value]="ShriramDetails.createdBy">
            <input type="hidden" name="paymentFrom"  [value]="ShriramDetails.paymentFrom">
            <input type="hidden" name="prodCode" [value]="ShriramDetails.prodCode">
            <input type="hidden" name="QuoteId" [value]="ShriramDetails.quoteId">
            <input type="hidden" name="amount" [value]="ShriramDetails.amount">
            <input type="hidden" name="sourceUrl" [value]="ShriramDetails.sourceUrl">
            <input type="hidden" name="DbFrom" [value]="ShriramDetails.dbFrom">
            <input type="hidden" name="application" [value]="ShriramDetails.application">
            <input type="hidden" name="prodName" [value]="ShriramDetails.prodName">
            <input type="hidden" name="description" [value]="ShriramDetails.description">
            <input type="hidden" name="isForWeb" [value]="ShriramDetails.isForWeb">
             <input type="submit" value="Submit" (click)="submitForm()"> 
            </form>

            <!-- <form id="PostForm" name="PostForm" autocomplete="off" action="https://novaapiuat.shriramgi.com/uatnovapymt/mydefaultcc.aspx" method="post">
                <input name="createdBy" [value]="ShriramDetails.createdBy"/>
                <input type="hidden" name="createdBy" [value]="ShriramDetails.sourceUrl"/>
                <input type="hidden" name="paymentFrom"  [value]="ShriramDetails.paymentFrom">
                <input type="hidden" name="prodCode" [value]="ShriramDetails.prodCode">
                <input type="hidden" name="QuoteId" id="quoteId" [value]="ShriramDetails.QuoteId">
                <input type="hidden" name="amount" [value]="ShriramDetails.amount">
                <input type="hidden" name="sourceUrl" [value]="ShriramDetails.sourceUrl">
                <input type="hidden" name="DbFrom" [value]="ShriramDetails.DbFrom">
                <input type="hidden" name="application" [value]="ShriramDetails.application">
                <input type="hidden" name="prodName" [value]="ShriramDetails.prodName">
                 <input type="submit" value="Submit" (click)="submitForm()"> 
                </form> -->
    </div>
</div>