<app-navbar></app-navbar>
<div class="container ">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
    <ng-container *ngIf="!isRoyalSundaram">
        <div class="row">
            <div class="col-md-12 mt-3" [ngClass]="{'mt-15':tabs=='Nominee'}">
                <div class="card px-3 py-1">
                    <div class="row align-items-center">
                        <div class="col-auto align ">
                            <button class="btn-primary btn btn-sm  px-3 "><img class="text-white"
                                    src="../../../assets/svgIcon/whiteArrow.svg" alt="" (click)="back()"></button>
                        </div>
                    
                        <div class="col align">
                            <label for="" class="p-h">Destination</label>
                            <p class="p-align mb-0">{{this.allData?.memberDetails?.destination}}
                            </p>
                        </div>
                        <div class="col align">
                            <label for="" class="p-h">Date</label>
                            <p class="p-align mb-0">{{ moment(this.allData?.memberDetails?.travelStartDate).format("DD MMM, YYYY")}} To {{ moment(this.allData?.memberDetails?.travelEndDate).format("DD MMM, YYYY")}}

                            </p>
                        </div>
                        <div class="col  align">
                            <label for="" class="p-h">Members</label>
                            <p class="p-align mb-0">{{this.allData?.memberDetails?.members.length}}
                            </p>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mt-3">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-auto border-right ">
                          <img class="image-stl" src="/assets/images/insurance_companies/{{priceBreakup.insuranceCompany}}.png" alt="">
                        </div>
                        <div class="col d-flex flex-column align-self-center">
                          <span class="d-block mb-1"><span class="spn">Plan Name : </span> {{priceBreakup.planName}}</span>
                          <span class="d-block"><span class="spn">Product Name : </span> {{priceBreakup.productName}}</span>
                        </div>
                      </div>            
                      <hr class="mt-0 mb-2">
                      <div class="row">
                        <div class="col-8 mt-1">
                          <h5>Net Premium {{checkValueNotZero(priceBreakup.ownDriverCover??'0') && checkValueNotZero(priceBreakup.thirdPartyCoverPremium??'0') ? '(A + B)' : ''}}</h5>
                        </div>
                        <div class="col-4 mt-1 text-right">
                          <h5>₹ {{priceBreakup.netPremium??'0' | thousandSeparator}}</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8 mt-1">
                          <span>GST(18%)</span>
                        </div>
                        <div class="col-4 mt-1 text-right" >
                          <h5 class="text-danger">+ ₹ {{priceBreakup.totalTax??'0' | thousandSeparator}}</h5>
                        </div>
                      </div>
                      <hr class="my-1">
                      <div class="row">
                        <div class="col-8 mt-1">
                          <h5>Final Premium</h5>
                        </div>
                        <div class="col-4 mt-1 text-right">
                          <h5>₹ {{priceBreakup.grossPremium ??'0' | thousandSeparator}}</h5>
                        </div>
                      </div>
                </div>
            </div>
            <div class="col-md-8 mt-3">
                <div class="card p-3 ">
                    <div class="row">
                        <div class="col-md-4 tab" (click)="nextTab('Members')">
                            <div class=" card p-3" [ngClass]="{'selected':tabs=='Members'}">
                                <p class="p-tex" [ngClass]="{'tb-cl':tabs=='Members'}">Members Details</p>
                            </div>
                        </div>
                        <div class="col-md-4 tab" (click)="nextTab('Proposer')">
                            <div class="card p-3" [ngClass]="{'selected':tabs=='Proposer'}">
                                <p class="p-tex" [ngClass]="{'tb-cl':tabs=='Proposer'}"> Proposer Details</p>
                            </div>
                        </div>
                        <div class="col-md-4 tab" (click)="nextTab('Address')">
                            <div class="card p-3" [ngClass]="{'selected':tabs=='Address'}">
                                <p class="p-tex" [ngClass]="{'tb-cl':tabs=='Address'}">Address Details</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 border-top">
                        <div class="scroll-container" *ngIf="tabs=='Members'">
                            <div class="row border-bottom" *ngFor="let input_fields of required_input_fields">
                                <app-dynamic-member [containerClass]="'col-md-6'" (dataEvent)="getSavedData($event, travelFormControl['planFor'].value)"
                                [required_input_fields]="input_fields">
                            </app-dynamic-member>
                            </div>
                        </div>
                        <div *ngIf="tabs=='Proposer'">
                            <div class="card row br-top-0">
                                <div class="details_card">
                                    <form id="kt_account_profile_details_form" class="form" [formGroup]="userForm"
                                        novalidate="novalidate">
                                        <div>
                                            <div id="ownerdetails1">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="justify-content-between row">
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <div class="mt-3 col-md-6">
                                                                        <label for="name" class="required">PAN Card</label>                                                            
                                                                        <div class="d-flex">
                                                                            <input type="text" class="form-control rounded-right1-0" name="pancard"
                                                                            formControlName="pancard" placeholder="Pan card" minlength="10"
                                                                            maxlength="10" (keypress)="alphaNumericOnly($event)" oninput="this.value = this.value.toUpperCase()"
                                                                            [ngClass]="{'border border-danger': ((userFormControl['pancard'].touched  || submittedProposer) && !userFormControl['pancard'].valid)}">
                                                                            <button  disabled="" class="btn btn-primary py-0 px-2 rounded-left1-0" [disabled]="!userFormControl['pancard'].valid" (click)="getPanCardDetails()">
                                                                                <img  src="../../assets/svgIcon/fetch.svg" alt="Fetch" class="icon-white" title="Fetch Details">
                                                                            </button>
                                                                        </div>
                                                                        <Span class="text-danger"
                                                                            *ngIf="((userFormControl['pancard'].touched  || submittedProposer) && userFormControl['pancard'].errors?.['required'])">
                                                                            PAN number is required.
                                                                        </Span>
                                                                        <Span class="text-danger"
                                                                            *ngIf="((userFormControl['pancard'].touched  || submittedProposer) && userFormControl['pancard'].errors?.['pattern'])">
                                                                            Provide valid PAN number.
                                                                        </Span>
                                                                    </div>
                            
                                                                <div class="mt-3 col-md-6">
                                                                    <label class="required" for="dob">Date Of Birth </label>
                                                                    <input type="date" placeholder="DD-MM-YYYY" name="dob"
                                                                        formControlName="dob" autocomplete="off" [max]="maxDOB" onkeydown="return false" 
                                                                        class="new-user form-control bg-transparent"
                                                                        [ngClass]="{'border border-danger': ((userFormControl['dob'].touched  || submittedProposer) && !userFormControl['dob'].valid)}" />
                                                                    <Span class="text-danger"
                                                                        *ngIf="((userFormControl['dob'].touched  || submittedProposer) && !userFormControl['dob'].valid)">
                                                                        Date of birth is required.
                                                                    </Span>
                                                                </div>
                                                                <div class="col-md-3 mt-3">
                                                                    <label for="title" class="required">Title</label>
                                                                    <select name="title" id="title" class="form-select form-select-custom"
                                                                        formControlName="title">
                                                                        <option *ngFor="let title of titleList" [value]="title">
                                                                            {{title}}
                                                                        </option>
                                                                    </select>                
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <div class="row">                                                                                
                                                                        <div class="mt-3 col-md-6">
                                                                            <label for="name" class="required">First Name</label>
                                                                            <input type="text" class="form-control" name="firstname"
                                                                                (keypress)="alphabetOnly($event)" formControlName="firstname"
                                                                                placeholder="First Name"
                                                                                [ngClass]="{'border border-danger': ((userFormControl['firstname'].touched  || submittedProposer) && !userFormControl['firstname'].valid)}">
                                                                            <Span class="text-danger"
                                                                                *ngIf="((userFormControl['firstname'].touched  || submittedProposer) && !userFormControl['firstname'].valid)">
                                                                                First name is required.
                                                                            </Span>
                                                                        </div>
                                                                        <div class="mt-3 col-md-6">
                                                                            <label for="name" class="required">Last Name</label>
                                                                            <input type="text" class="form-control" name="lastname"
                                                                                (keypress)="alphabetOnly($event)" formControlName="lastname"
                                                                                placeholder="Last name"
                                                                                [ngClass]="{'border border-danger': ((userFormControl['lastname'].touched  || submittedProposer) && userFormControl['lastname'].errors?.['required'])}">
                                                                            <Span class="text-danger"
                                                                                *ngIf="((userFormControl['lastname'].touched  || submittedProposer) && !userFormControl['lastname'].valid)">
                                                                                Last name is required.
                                                                            </Span>
                                                                        </div>
                                                                    </div>                
                                                                </div>                                                            
                                                            </div>
                                                            </div>
                                                        </div> 
                                                    </div>                                                               
                                                </div>
                                            </div>
                                        </div>                        
                                        <div class="justify-content-between row">    
                                            <div class="mt-3 col-md-4">
                                                <label for="name" class="required">Gender</label>
                                                <select name="gender" id="gender" class="form-select form-select-custom"
                                                formControlName="gender">
                                                    <option *ngFor="let gender of genderList" [value]="gender">
                                                        {{gender}}
                                                    </option>
                                                </select>    
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['gender'].touched  || submittedProposer) && userFormControl['gender'].errors?.['required'])">
                                                    Gender is required.
                                                </Span>
                                            </div> 
                                            <div class="mt-3 col-md-4">
                                                <label for="name" class="required">Email Address</label>
                                                <input type="text" class="form-control" name="email" formControlName="email"
                                                    placeholder="User email address"
                                                    [ngClass]="{'border border-danger': ((userFormControl['email'].touched  || submittedProposer) && !userFormControl['email'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['email'].touched  || submittedProposer) && userFormControl['email'].errors?.['required'])">
                                                    Email address is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['email'].touched  || submittedProposer) && userFormControl['email'].errors?.['pattern'])">
                                                    Please provide valid email address.
                                                </Span>
                                            </div>
                                            <div class="mt-3 col-md-4">
                                                <label for="name" class="required">Mobile Number</label>
                                                <input type="text" class="form-control" name="mobile"
                                                    formControlName="mobileNumber" placeholder="Proposer mobile number"
                                                    minlength="10" maxlength="10" (keypress)="numberOnly($event)"
                                                    [ngClass]="{'border border-danger': ((userFormControl['mobileNumber'].touched  || submittedProposer) && !userFormControl['mobileNumber'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['mobileNumber'].touched  || submittedProposer) && userFormControl['mobileNumber'].errors?.['required'])">
                                                    Mobile number is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['mobileNumber'].touched  || submittedProposer) && userFormControl['mobileNumber'].errors?.['pattern'])">
                                                    Provide valid Mobile number.
                                                </Span>
                                            </div>                                                                
                                        </div>
                                    </form>
                                </div>                                   
                            </div>							
                        </div>
                        <div *ngIf="tabs=='Address'">
                            <div class="card row br-top-0">
                                <div class="details_card">
                                    <form id="kt_account_profile_details_form" class="form" [formGroup]="userForm"
                                        novalidate="novalidate">
                                        <div class="justify-content-between row">
                                            <div class="mt-3 col-md-6">
                                                <label class="required" for="addressLine1">Flat Number/Street Number</label>
                                                <input type="text" class="form-control" name="addressLine1" formControlName="addressLine1"
                                                    placeholder="Flat Number/Street Number"
                                                    [ngClass]="{'border border-danger': ((userFormControl['addressLine1'].touched  || submittedAddress) && userFormControl['addressLine1'].errors?.['required'])}">
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['addressLine1'].touched  || submittedAddress) && userFormControl['addressLine1'].errors?.['required'])">
                                                    Flat number/Street number is required.
                                                </Span>
                                            </div>
                                            <div class="mt-3 col-md-6">
                                                <label class="required" for="addressLine2">Street Address</label>
                                                <input type="text" class="form-control" name="addressLine2"
                                                    formControlName="addressLine2" placeholder="Street Address"
                                                    [ngClass]="{'border border-danger': ((userFormControl['addressLine2'].touched  || submittedAddress) && !userFormControl['addressLine2'].valid)}">
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['addressLine2'].touched  || submittedAddress) && userFormControl['addressLine2'].errors?.['required'])">
                                                    Street address is required.
                                                </Span>
                                            </div>
                                            <div class="mt-3 col-md-6">
                                                <label class="required" for="name">Pincode</label>
                                                <app-autocomplete class="w-100" (itemSelected)="onPincodeSelection($event)"
                                                    [items]="pincodeList" placeholder="Pincode" [supportingText]="true"
                                                    [userInput]="userFormControl['pincode'].value"
                                                    [showError]="((userFormControl['pincode'].touched  || submittedAddress) && !userFormControl['pincode'].valid)"
                                                    (onKeyUpEvent)="onPincodeChange($event)"  (input)="onInputChange($event)"></app-autocomplete>
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['required'])">
                                                    Pincode is required.
                                                </Span>
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['pincode'].touched  || submittedAddress) && userFormControl['pincode'].errors?.['pattern'])">
                                                    Provide valid pincode.
                                                </Span>
                                            </div>
                                            <div class="mt-3 col-md-6">
                                                <label class="required" for="name">City</label>
                                                <app-autocomplete class="w-100" (itemSelected)="onCitySelection($event)"
                                                    [items]="cityList" placeholder="City"
                                                    [supportingText]="true"
                                                    [userInput]="userFormControl['city'].value"
                                                    [showError]="((userFormControl['city'].touched  || submittedAddress) && !userFormControl['city'].valid)"
                                                    (onBlurEvent)="onCityBlur($event)" [readonly]="'readonly'"></app-autocomplete>
                                                <Span class="text-danger"
                                                    *ngIf="((userFormControl['city'].touched  || submittedAddress) && userFormControl['city'].errors?.['required'])">
                                                    City is required.
                                                </Span>
                                            </div>                                        
                                        </div>
                                    </form>
                                </div>         
                    </div>
                        </div>                    
                    </div>
                </div>
                <div class="row mt-5" *ngIf="tabs=='Members'">
                    <div class="col-md-12 text-end">
                        <button class="btn py-3 btn-primary" [disabled]="!isDynamicFormIsValid()" (click)="nextTab('Proposer')">Next</button>
                    </div>
                </div>
                <div class="row mt-5" *ngIf="tabs=='Proposer'">
                    <div class="col-md-12 d-flex justify-content-between">
                        <button class="btn py-3 bt-back" (click)="nextTab('Members')">Back</button>
                        <button class="btn py-3 btn-primary" [disabled]="!isProposerValid()" (click)="nextTab('Address')">Next</button>
                    </div>
                </div>
                <div class="row mt-5" *ngIf="tabs=='Address'">
                    <div class="col-md-12 d-flex justify-content-between">
                        <button class="btn py-3 bt-back " (click)="nextTab('Proposer')">Back</button>
                        <button class="btn py-3 btn-primary" [disabled]="!isAddressValid()" (click)="getSummary()">Purchase Policy</button>
                    </div>

                </div>

            </div>
        </div>
    </ng-container>
    <div *ngIf="isRoyalSundaram">
        <app-royal-sundaram-payment [royalDetails]="royalDetails"></app-royal-sundaram-payment>
      </div>
</div>