import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { UserService } from '../Services/user.service';
import { AppConfig } from '../Shared/AppConfig';
import swal from 'sweetalert2';
import { LoginService } from './../Services/login.service';
import { Router } from '@angular/router';
import { ProfileService } from '../Services/profile.service';
import{FamilyMembers,PropertyDetails,RelationShips,Vehicles} from '../Models/Profile.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  protected _appSet = AppConfig.settings;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  passwordForm: FormGroup;
  userForm: FormGroup;
  memberForm:FormGroup;

  public CurrentpasswordType: string = PasswordType.pwd;
  CurrentpasswordShow: boolean = false;
  public NewpasswordType: string = PasswordType.pwd;
  NewpasswordShow: boolean = false;
  public ConfirmNewpasswordType: string = PasswordType.pwd;
  ConfirmNewpasswordShow: boolean = false;
  IsResetPassword: boolean = true;
  IsChangePassword: boolean | undefined;
  TimezoneList: any = [];
  rolelist: any = [];
  mobile: string = '';


  isPersonalDetails: boolean = true;
  isFamilyDetails: boolean = false;
  isAssets: boolean = false;
  isAddresses: boolean = false;
  isPassword: boolean = false;

  fullname: string = '';
  email: string = '';
  uniqueuserid: any;

  constructor(private fb: FormBuilder, private userservice: UserService,
    private loginService: LoginService, private route: Router, private profileService: ProfileService,
    private spinner: NgxSpinnerService, private generalService : GeneralService) {
    this.passwordForm = this.fb.group({
      "user_email": sessionStorage.getItem('user_email'),
      "current_password": ['', [Validators.required]],
      "password": ['', [Validators.required]],
      "confirm_password": ['', [Validators.required]],
      "phoneNumber": [''],
    });

  }
  ngOnInit() {
    this.uniqueuserid = this.generalService.getUniqueuserid();
    this.getUserDetails();

  }


  async getUserDetails() {
    this.spinner.show();
    let request = { uniqueUserId: this.generalService.getUniqueuserid() }
    const response = await this.userservice.AcquireUserInfo(request);
    if (response?.isSuccess) {
      this.email = response.result.userEmail;
      this.fullname = `${response.result.firstName} ${response.result.lastName}`;

    }
    this.spinner.hide();
  }



 async changepassword():Promise<boolean> {
  if(this.passwordForm.value.password && this.passwordForm.value.confirm_password){
    if(this.passwordForm.value.password !==this.passwordForm.value.confirm_password ){
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'New and confirm password does not match',
        showConfirmButton: false,
        timer: 3000,
      });
      return false;
    }
    if(this.passwordForm.value.current_password !== this.userForm.value.userHash){
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'current password does not match the with system password',
        showConfirmButton: false,
        timer: 3000,
      });
      return false;
    }
    if (this.userForm.get('phoneNumber')?.value != null && typeof (this.userForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.userForm.get('phoneNumber')?.value;
      this.passwordForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
    const response = await this.loginService.ResetPassword(this.passwordForm.value);
    if (response?.isSuccess) {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Reset password link successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      this.route.navigate(['/login']);
    }
  }
  return true;

  }

  changetab(tabname: any) {
    if (tabname == 'settings') {
      document.getElementById('settings-tab')?.click();
    }
  }


  showchangepassword(event: any) {
    if (event == 'true') {
      this.IsChangePassword = true;
      this.IsResetPassword = false;
    }
    else {
      this.passwordForm.reset();
      this.IsChangePassword = false;
      this.IsResetPassword = true;
    }
  }

  ShowCurrentPassword() {
    if (!this.CurrentpasswordShow) {
      this.CurrentpasswordType = PasswordType.txt;
      this.CurrentpasswordShow = true;
    }
    else {
      this.CurrentpasswordType = PasswordType.pwd;
      this.CurrentpasswordShow = false;
    }
  }
  ShowNewPassword() {
    if (!this.NewpasswordShow) {
      this.NewpasswordType = PasswordType.txt;
      this.NewpasswordShow = true;
    }
    else {
      this.NewpasswordType = PasswordType.pwd;
      this.NewpasswordShow = false;
    }
  }
  ShowConfirmNewPassword() {
    if (!this.ConfirmNewpasswordShow) {
      this.ConfirmNewpasswordType = PasswordType.txt;
      this.ConfirmNewpasswordShow = true;
    }
    else {
      this.ConfirmNewpasswordType = PasswordType.pwd;
      this.ConfirmNewpasswordShow = false;
    }
  }




  async copyReferralLink() {
    window.navigator.clipboard.writeText((document.getElementById('kt_referral_link_input') as HTMLInputElement).value);
    swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Link copied',
      showConfirmButton: false,
      timer: 3000,
    });
  }

  changeTab(value: string){
    if( value == 'personalDetails'){
      this.isPersonalDetails = true;
      this.isPassword=false;
    }
    // else if( value == 'familyDetails'){
    //   this.isPersonalDetails = false;
    //   this.isFamilyDetails = true;
    //   this.isAssets = false;
    //   this.isAddresses = false;
    //   this.isPassword=false;
    // }
    // else if( value == 'Assets'){
    //   this.isPersonalDetails = false;
    //   this.isFamilyDetails = false;
    //   this.isAssets = true;
    //   this.isAddresses = false;
    //   this.isPassword=false;
    // }
    // else if( value == 'Addresses'){
    //   this.isPersonalDetails = false;
    //   this.isFamilyDetails = false;
    //   this.isAssets = false;
    //   this.isAddresses = true;
    //   this.isPassword=false;
    // }
    else if(value == 'changePaasword'){
      this.isPersonalDetails = false;
      this.isPassword=true;
    }
  }

  isVehicle: boolean = false;
  isProperty: boolean= false;
  isTwoWheeler: boolean = false;
  isFourWheeler: boolean = false;

  selectedCard(value: string){
    if(value == 'Vehicle'){
      this.isVehicle =! this.isVehicle;
    }
    else if(value == 'Property'){
      this.isProperty =! this.isProperty;
    }
    else if(value == 'Two Wheeler'){
      this.isTwoWheeler =! this.isTwoWheeler;
    }
    else if(value == 'Four Wheeler'){
      this.isFourWheeler =! this.isFourWheeler;
    }
  }
}
