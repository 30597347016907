import { Component, EventEmitter, OnInit, Output, ViewChild ,Input, HostListener} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PincodeMaster } from '../../Models/signUp.component.model';
import { InsuranceService } from '../../Services/insurance.service';
import { LookUpService } from '../../Services/lookup.service';
import { UserService } from '../../Services/user.service';
import { VehiclePremiumInfoComponent } from '../vehicle-premium-info/vehicle-premium-info.component';
import { InsuranceDetails, VehicleDetails,PremiumDetails, PriceBreakup   } from '../../Models/vehicle-component.model';
import swal from 'sweetalert2';
import { CommonService } from 'src/app/Services/common.service';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from 'src/app/Services/general.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { InsuranceCompany } from 'src/app/GeneralCommon/Enums/common-enums';
import { PolicyService } from 'src/app/Services/policy.service';
import { flush } from '@angular/core/testing';
@Component({
  selector: 'vehicle-owner-details',
  templateUrl: './vehicle-owner-details.component.html',
  styleUrls: ['./vehicle-owner-details.component.css']
})
export class VehicleOwnerDetailsComponent implements OnInit {
  isSmallScreen: boolean = false; 
  priceBreakup: PriceBreakup = {};
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); } 
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }
  @ViewChild(VehiclePremiumInfoComponent) VehiclePremiumInfoComponent: VehiclePremiumInfoComponent;
  @Output() dataEvent = new EventEmitter<any>();
  @Output() gotoEvent = new EventEmitter<any>();
  @Input()  ownerDetails:any;
  showNegativeKycInfo:boolean = false;
  userForm: FormGroup;
  nomineeForm: FormGroup;
  vehicleForm: FormGroup;
  existingTPPolicyDetailsForm: FormGroup;
  validatePolicyUserForm: FormGroup;
  premium: any = 0;
  mobile: any;
  pincodeMaster = new PincodeMaster();
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  vehiclePremiumData: any;
  RelationShipList: { id: number; name: string; }[];
  isUserLogged: boolean = false;
  vehicleData: VehicleDetails = {};
  submitted:boolean = false;
  submittedAddress:boolean = false;
  submittedNominee:boolean = false;
  submittedVehicle:boolean = false;
  submittedPolicy:boolean = false;
  submittedBank:boolean = false;
  insurancePartnersList:AutoCompleteItem[] =[];
  stateList :AutoCompleteItem[] =[];
  districtList:AutoCompleteItem[]=[];
  ensuringBranchStateList :AutoCompleteItem[] =[];
  ensuringBranchDistrictList:AutoCompleteItem[]=[];
  pincodeList: AutoCompleteItem[] = [];
  cityList: AutoCompleteItem[] = [];
  state:string="";
  district:string="";
  pincodeId:string="";
  showMenu:boolean=false;
  titleList: string[] = ["Mr","Mrs","Ms"];
  genderList: string[] = ["Male","Female","Other"];
  maritalStatusList: string[] = ["Single","Married","Divorced","Separated"];
  showExistingPolicyControls:boolean =true;
  activeTab:number=1;
  ageList:any[]= [];
  showDetails:boolean=true;
  selectedAddons:any=[];
  idv:string="";
  companyLogo:string="";
  companyLogoId:string="";
  plan:string="";
  tenure:string="";
  tenurePeriod:string="";
  isThirdPArty:boolean=true;
  panel1:boolean=true;
  panel2:boolean=false;
  panel3:boolean=false;
  panel4:boolean=false;
  panel5:boolean=false;
  panel6:boolean=false;
  panel7:boolean=false;
  isSummry:boolean=false;
  isOwnerValid:boolean = false;
  isAddressValid:boolean = false;
  isNomineeValid:boolean = false;
  isVehicleValid:boolean = false;
  isPolicyValid:boolean = false;
  insuranceformData= {} as InsuranceDetails;
  showTPDetailsForm:boolean=false;
  today = new Date();
  minDate = "";
  maxDate = "";
  maxDOB = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
  isTesting:boolean = false;
  panImage: string | ArrayBuffer | null = null;
  form16Image: string | ArrayBuffer | null = null;
  aadharBackImage: string | ArrayBuffer | null = null;
  aadharFrontImage: string | ArrayBuffer | null = null;
  insuredImage: string | ArrayBuffer | null = null;
  aadharNumber :string="";
  showPANForm16 :boolean =false;
  showForm16 :boolean =false;
  showAadhar :boolean =false;
  showAadharImage :boolean =false;
  showInsuredImage:boolean=false;
  panImageRequiredError: false;
  form16ImageRequiredError: false;
  aadharBackImageRequiredError: false;
  aadharFrontImageRequiredError: false;
  aadharMsg:string = "";
  aadharCode:number = 0;
  isReferal : boolean = false;
  royalDetails :any;
  isRoyalSundaram :boolean = false;

  futureDetails :any;
  isFutureGeneral :boolean = false;

  ShriramDetails :any;
  isShriramGeneral :boolean = false;

  RelianceDetails :any;
  isRelianceGeneral :boolean = false;

  HDFCDetails :any;
  isHDFC :boolean = false;

  showKYCPopup:boolean=false;
  showhttpKYCPopup:boolean=false;

  kycUrl:any='';

  rId:string = "";
  uId:string = "";
  pId:string = "";
  status:number = 0;
  showIsBreakIn:boolean = false;
  showIsBreakInTriggered:boolean = false;
  showNomineeForm:boolean=true;
  isNomineeFormRequired:boolean=true;
  panDocType:string="Image";
  form16DocType:string="Image";
  insurerDocType:string="Image";
  aadhar1DocType:string="Image";
  aadhar2DocType:string="Image";
  isAgent:boolean = false;
  showBankDetailsTab:boolean = false;
  docUploadForm: FormGroup;
  uploadDocList=["AADHAAR","CIN"];
  showKYCNextEditButton:boolean = false;
  iskycVerified:boolean = false;
  ovdDoc: string | ArrayBuffer | null = null;
  ovdDocType:string="Image";
  ovdDocUploadPanel:boolean = false;
  showNegativeKYCCorfirmButton:boolean = false;
  kycFailedMsg:string="";
  negativeKYCFailedMsg:string="";
  isKYCValidateImplementation:boolean = false;
  constructor(private fb: FormBuilder, private lookUpService: LookUpService, private userservice: UserService, private _lookupService:LookUpService,private agentprofileService : AgentprofileService,private spinner: NgxSpinnerService,private insuranceservice: InsuranceService, private generalService : GeneralService,private route : ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private policyService : PolicyService, private commonService:CommonService) {
    this.isAgent = this.generalService.getIsAgent() == 'true'
    this.rId = this.route.snapshot.paramMap.get('q')??"";
    this.uId = this.route.snapshot.paramMap.get('u')??"";
    this.pId = this.route.snapshot.paramMap.get('p')??"";
    let status = this.route.snapshot.paramMap.get('s')??"";
    if(status != null && status != undefined && status != ''){
      this.status = parseInt(status);
    }
    if(this.rId != "" || (this.pId != null && this.pId != undefined && this.pId != ''))
    { 
      this.isReferal =true;
    }

    this.docUploadForm= this.fb.group({
      "documentName": ['', [Validators.required]],
      "documentType": ['', [Validators.required]],
      "base64DocumentString": ['', [Validators.required]]
    });
  }
detail:string='declaration';
selectedOptionTab:string='pan';
showValidatePolicyUserPopup:boolean = false;
showSendOTPButton:boolean = true;
timerValue: number = 60; // Start at 30 seconds
timer: any; // To store the interval reference
submittedOtp:boolean = false;
minPolicyExpiryDate =  moment(new Date()).add(-3, 'years').format('YYYY-MM-DD');
maxPolicyExpiryDate =  moment(new Date()).add(3, 'years').format('YYYY-MM-DD');
minTPPolicyExpiryDate =  moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
maxTPPolicyExpiryDate =  moment(new Date()).add(5,'years').format('YYYY-MM-DD');

minStartTPPolicyExpiryDate =  moment(new Date()).add(-5, 'years').format('YYYY-MM-DD');
maxStartTPPolicyExpiryDate =  moment(new Date()).add(5,'years').format('YYYY-MM-DD');

quote:any;
isDeclared:boolean = false;
isPaymentLink:boolean = false;
paymentObject:any;
Details(name:string, isBackClick:boolean = false):void{
  if(isBackClick){
    this.detail=name;
  }
  else
  {
    if(this.detail =='declaration')
    {
      this.detail=name;
    }
    else if(name == 'address' && this.detail =='owner')
    {
      this.submitted = true;
      if(this.userFormControl['firstName'].valid && this.userFormControl['lastName'].valid &&
      this.userFormControl['phoneNumber'].valid && this.userFormControl['email'].valid &&
      this.userFormControl['panNumber'].valid && this.userFormControl['dateOfBirth'].valid 
      && this.isPANAadhaDocValid())
      {
        this.detail=name;
        this.isOwnerValid = true;
      }
      else
      {
        this.isOwnerValid = false;
      }
    }
    else if(name == 'nominee' && this.detail =='address')
    {
      this.submittedAddress = true;
      if(this.userFormControl['flatNo'].valid && this.userFormControl['streetAddress'].valid &&
      this.userFormControl['state'].valid && this.userFormControl['city'].valid &&
      this.userFormControl['pincode'].valid)
      {
        this.detail=name;
        this.isAddressValid = true;
      }
      else{
        this.isAddressValid = false;
      }
    }
    else if(name == 'vehicle' && this.detail =='nominee')
    {
      this.submittedNominee = true;
      if(this.nomineeForm.valid)
      {
        this.detail=name;
        this.isNomineeValid = true;
      }
      else
      {
        this.isNomineeValid = false;
      }
    }
    else if((name == 'policy' || name == 'bank') && this.detail =='vehicle')
    {
      this.submittedVehicle = true;
      if(this.vehicleFormControl['registrationNumber'].valid && this.vehicleFormControl['chasisNumber'].valid &&
      this.vehicleFormControl['engineNumber'].valid && this.vehicleFormControl['registrationDate'].valid)
      {
        this.detail=name;
        this.isVehicleValid = true;
      }
      else
      {
        this.isVehicleValid = false;
      }
    }
    else if(name == 'vehicle'){
      this.removePolicyValidation();
      this.detail=name;
    }
    if(name == 'bank' && (this.detail =='policy' || this.detail =='vehicle')){
      this.submittedPolicy = true;
      if(this.isPASumInsuredGT15Lacs() && this.vehicleForm.valid && this.existingTPPolicyDetailsForm.valid)
        {
          this.detail=name;
          this.isPolicyValid = true;
        }
        else
        {
          this.isPolicyValid = false;
        }
    }
  }
  // else
  // {
  //   this.detail=name;
  // }
  
}
  get userFormControl() {
    return this.userForm.controls;
  }

  get nomineeFormControl() {
    return this.nomineeForm.controls;
  }

  get vehicleFormControl() {
    return this.vehicleForm.controls;
  }

  get existingTPPolicyDetailsFormControl(){
    return this.existingTPPolicyDetailsForm.controls;
  }

  get validatePolicyUserFormControl() {
    return this.validatePolicyUserForm.controls;
  }

  get docUploadFormControl() {
    return this.docUploadForm.controls;
  }
  ngOnInit() {
    this.ageList.push({value:'',text: 'Select nominee age'});
    for(let i=18;i<=100;i++)
    {
      this.ageList.push({value:i.toString(),text: i.toString()});
    }
    this.userForm = this.fb.group({
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],
      "phoneNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "countryCode": [''],
      "email": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') ]],
      "dob": [''],
      "gender": ['Male', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "pincode": ['',[Validators.required,Validators.pattern('[0-9]{6}')]],
      "pincodeId": [''],
      "dateOfBirth": ['', [Validators.required]],
      "panNumber": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "flatNo": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "district": ['', [Validators.required]],
      "state": ['', [Validators.required]],
      "title":['Mr'],
      "aadharNumber":[''],
      "aadharFrontBase64":[''],
      "aadharBackbase64":[''],
      "panBase64":[''],
      "form16Base64":[''],
      "isPANNumber":[true],
      "insuredBase64":[''],
      "fatherName":[''],
      "aadharFrontExt":[''],
      "aadharBackExt":[''],
      "panExt":[''],
      "form16Ext":[''],
      "insuredPhotoExt":[''],
      "fatherFirstName":[''],
      "fatherLastName":[''],
      "maritalStatus":['Single'],  //Married  
      "companyGSTIN":[],
      "companyCIN":[''],
      "pep":["No"],
      "disabilityDeclaration":["Yes"],
      "dataPrivacy":["Yes"],
      "accountNo":[""],
      "confirmAccountNo":[""],
      "ifscCode":[""],
      "bankName":[""]
    });
    this.nomineeForm = this.fb.group({
      "nomineeName": ['', [Validators.required]],
      "nomineeAge": ['', [Validators.required]],
      "relationshipWithNominee": ['', [Validators.required]],
      "gender": ['', [Validators.required]]
    });

    this.vehicleForm = this.fb.group({
      "city": [""],
      "rto": [""],
      "make": [""],
      "model": [""],
      "variant": [""],
      "registrationYear": [""],
      "registrationNumber": ["", [Validators.required]],
      "registrationNumber1": ["", [Validators.required]],
      "chasisNumber": ["", [Validators.required]],
      "engineNumber": ["", [Validators.required]],
      "registrationDate": ["", [Validators.required]],
      "claimsMadeInPreviousPolicy": ["No"],
      "previousPolicyExpiryDate":["", [Validators.required]],
      "ncbprevious": [""],      
      "typeOfCover": [""],
      "vehicleIDV":[""],
      "bodyIDV":["0"],//shivraj
      "chassisIDV":["0"],//shivraj
      "previousPolicyCompany":["", [Validators.required]],
      "previousPolicyNumber":["", [Validators.required]],      
      "ncbCurrent":[""],
      "firstName": [""],
      "lastName": [""],
      "vehiclecategory":[""],
      "isPreviousPolicyHolder":[""],
      "previousPolicyType":["", [Validators.required]],
      "fuelType":[""],
      "engineCapacityAmount" :[""],
      "paCover":[""],
      "alreadyHavePACover":[""],
      "paPolicyTerm":[""],
      "registeredInTheNameOf": [""],
      "isValidDrivingLicenseAvailable": [""],
      "premium":[""],
      "isFinanced":[""],
      "financedValue": [""],
      "financierName":[""],
      "financeType": [""],
      "financierBranchName":[""],
      "policyTerm": ["1"],
      "isTPPD":[""],
      "companyNameForCar":[""],
      "companyGSTINForCar":[""],
      "isNew":[""],
      "category":[''],
      "gcVehicle":[''],
      "pcVehicle":[''],
      "purpose":[''],
      "seatingCap":[''],
      "miscVehicle":[''],
      "gVW":[''],
      "permit":[''],
      "policyExpiryStatus":["1"],
      "isOwnerShipTransferred":["No"],
      "policyClaimed": [''],
      "policyClaimedNCB":[''],
      "vehicleIDVObj":[],
      "pincode":[],
      "usesType":['4']
    })


    this.existingTPPolicyDetailsForm = this.fb.group({
      "tpInsurer": ['', [Validators.required]],
      "tpPolicyNumber": ['', [Validators.required]],
      "tpPolicyTerm": ['1', [Validators.required]],
      "tpInsurerAddress": ['', [Validators.required]],
      "tpAddress1": ['', [Validators.required]],
      "tpAddress2": ['', [Validators.required]],
      "tpInceptionDate": ['', [Validators.required]],
      "tpExpiryDate": ['', [Validators.required]],
      "tpSumInsured": ['1500000', [Validators.required]],
      "tpIsStandalone":['No']
    });


    this.loadRelationships();
    this.userForm.get("firstName")?.setValue(this.ownerDetails?.firstName??"");
      this.userForm.get("lastName")?.setValue(this.ownerDetails?.lastName??"");
      if(this.generalService.getUniqueuserid() != ""){
        this.isUserLogged = true;
      }
      this.loadInsurancePartners();
     this.getStatesByCountry('India');
     //this.setPANAadharVisbility();

     let ownerDataStr = this.generalService.getDataToLocalStorage('ownerDetails');
     if(ownerDataStr != ""){
      this.insuranceformData = JSON.parse(ownerDataStr);
      this.userForm.patchValue(this.insuranceformData.proposerDetails);
      this.nomineeForm.patchValue(this.insuranceformData.nomineeDetails);
      //this.existingTPPolicyDetailsForm.patchValue(this.insuranceformData.existingTPPolicyDetails);
     }
     
      let vehiclePremiumDataStr = this.generalService.getDataToLocalStorage('quoteDetails');
      if(vehiclePremiumDataStr != ""){
        let vehiclePremiumData = JSON.parse(vehiclePremiumDataStr);
        this.QuoteDetails(vehiclePremiumData,vehiclePremiumData?.vehicleData) ;    
      }  
      
      this.validatePolicyUserForm = this.fb.group({
        "firstName": [''],
        "lastName": [''],
        "phoneNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
        "emailAddress": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') ]],
        "otpNumber": ["",[Validators.required,Validators.pattern('[0-9]{6}')]],
        "quoteId": [''],
        "insuranceId": [''],
        "productId": [''],
        "title": [''],
        "isOtpVerified" : [false]
      });

      if(this.pId != null && this.pId != undefined && this.pId != ''){
        this.getPolicyDetails();
      }
  }

  loadRelationships() {
    this.RelationShipList = [ 
    { id: 1, name: "Father" }, 
    { id: 2, name: "Mother" }, 
    { id: 3, name: "Brother" },
    { id: 4, name: "Sister" },
    { id: 5, name: "Son" }, 
    { id: 6, name: "Daughter" }, 
    { id: 7, name: "Uncle" },
    { id: 8, name: "Aunt" },
    { id: 9, name: "Spouse"},
    { id: 11, name: "Grand Son"},
    { id: 12, name: "Grand Daughter"}
    ]
    // [{ id: 1, name: "Spouse" }, { id: 2, name: "Parent" }, { id: 3, name: "Child" }, { id: 4, name: "Sibling" },
    // { id: 5, name: "Grandparent" }, { id: 6, name: "Grandchild" }, { id: 7, name: "Cousin" }, { id: 8, name: "AuntUncle" },
    // { id: 9, name: "NieceNephew" }, { id: 10, name: "Friend" }, { id: 11, name: "Colleague" }, { id: 12, name: "Acquaintance" }];
  }



  async pincodeUserChangeEvent(pincode: any, isLoad?: boolean) {
    this.pincodeMaster = new PincodeMaster();
    var pin = "";
    if (isLoad)
      pin = pincode;
    else
      pin = pincode.value;
    const res = await this.lookUpService.pincodeMasterDetails(pin);
    if (res != null && res.result) {
      this.pincodeMaster = res.result[0];
      this.userForm.get('pincodeId')?.setValue(this.pincodeMaster?.id?.toString());
    }
  }

  onCountryChangeNew(e: any) {
    this.userForm.get('phoneNumber')?.setValue("");
  }

  continue() {
    this.submitted=true;
    this.submittedAddress=true;
    this.submittedNominee=true;
    this.submittedVehicle = true;
    this.submittedPolicy = true;
    this.submittedBank = true;
    let phone = this.userForm.get('phoneNumber')?.value;
    if (phone.dialCode) {
      this.userForm.get('countryCode')?.setValue(phone.dialCode);
      this.userForm.get('phoneNumber')?.setValue(phone.number.replace(phone.dialCode, '').replace(/\s/g, ""));
    }
    
    if (this.isPASumInsuredGT15Lacs() && this.isPANAadhaDocValid()==true && this.userForm.valid && this.nomineeForm.valid && this.vehicleForm.valid && this.existingTPPolicyDetailsForm.valid) {
      this.vehicleForm.get('companyGSTINForCar')?.setValue(this.userForm.get('companyGSTIN')?.value??"");
      this.isSummry = true;
    }
  }

  showMainDetails(){
    this.isSummry = false;
  }

  proceedToBuy(){
    let decMsg = this.declaration();
    if(decMsg != "" && this.isDeclared == false){
      swal.fire({
        title:'',
        text: '',
        html: decMsg,
        showDenyButton: false,
        showCancelButton: true,   
        cancelButtonText:"Don't confirm",     
        denyButtonText: "Don't confirm",
        denyButtonColor:"#0DB561",
        confirmButtonText: "Yes, I Confirm",
        confirmButtonColor:"#0DB561",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //swal.fire("Saved!", "", "success");
          this.isDeclared = true;
          this.checkBreakin();
        } else if (result.isDenied) {
          //swal.fire("Changes are not saved", "", "info");
        }
        //canProcced = result.isConfirmed;
      });
    }
    else
    {      
      this.checkBreakin();
    }
  }

  async validateUserByOTP(){
    this.showIsBreakIn=false;
    if(this.validatePolicyUserForm.value.isOtpVerified == false  || 
      (this.validatePolicyUserForm.value.isOtpVerified == true && 
      (this.validatePolicyUserForm.value.phoneNumber != this.userForm.value.phoneNumber || this.validatePolicyUserForm.value.emailAddress != this.userForm.value.email))
    ){
      this.validatePolicyUserForm.get("title")?.setValue(this.userForm.value.title);
      this.validatePolicyUserForm.get("firstName")?.setValue(this.userForm.value.firstName);
      this.validatePolicyUserForm.get("lastName")?.setValue(this.userForm.value.lastName);
      this.validatePolicyUserForm.get("phoneNumber")?.setValue(this.userForm.value.phoneNumber);
      this.validatePolicyUserForm.get("emailAddress")?.setValue(this.userForm.value.email);
      this.validatePolicyUserForm.get("quoteId")?.setValue(this.insuranceformData.quoteId);
      this.validatePolicyUserForm.get("insuranceId")?.setValue(this.insuranceformData.insuranceCompany);
      this.validatePolicyUserForm.get("productId")?.setValue(this.insuranceformData.vehicleType??this.insuranceformData.VehicleType);
      //this.sendOTP();
      this.showValidatePolicyUserPopup = true;
      this.showSendOTPButton = true;
      this.timerValue = 0;
    }
    else
    {
      this.paySecurely();
    }
  }

  checkBreakin(){
    if(this.insuranceformData.isBreakIn == true)
      {
        this.showIsBreakIn=true;
      }
      else
      {
        this.validateUserByOTP();
      }
  }

  closeBreakInModal() {
    this.showIsBreakIn = false;
  }
  
  paySecurely(){
    this.vehicleForm.value.isTPPD = this.vehicleForm.value.isTPPD == undefined || this.vehicleForm.value.isTPPD == null || this.vehicleForm.value.isTPPD =="" ? false : this.vehicleForm.value.isTPPD;
    this.insuranceformData.priceBreakup = this.priceBreakup;
    this.insuranceformData.isBreakInProposal = this.status == 5;
    let obj = {
      UserDetails: this.userForm.value,
      NomineeDetails: this.nomineeForm.value,
      vehiclePremiumData: this.vehiclePremiumData,
      vehicleData:this.vehicleForm.value,
      insuranceformData:this.insuranceformData,
      existingTPPolicyDetails: this.existingTPPolicyDetailsForm.value
    }

    if(this.isReferal)
    {
      this.insuranceformData.proposerDetails = this.userForm.value;
      this.insuranceformData.nomineeDetails = this.nomineeForm.value;
      this.insuranceformData.vehicleDetails = this.vehicleForm.value;
      this.insuranceformData.existingTPPolicyDetails = this.existingTPPolicyDetailsForm.value;
      this.insuranceformData.proposerDetails.city = this.insuranceformData.proposerDetails.district;
      this.insuranceformData.priceBreakup = this.priceBreakup;
      this.CreatePolicy();
    }
    else
    {
      this.dataEvent.emit(obj);
    } 
  }

  async CreatePolicy() {
    this.showIsBreakIn=false;
    this.spinner.show();
    const response: any = await this.insuranceservice.CreateQuote(this.insuranceformData);//this.insuranceForm.value);
    this.readResponse(response);
    this.spinner.hide();
  }

  readResponse(response:any){
    if (response?.isSuccess && response?.result?.code == 2) {
      //pre inspection case
        this.showIsBreakIn=true;
        this.insuranceformData.isBreakIn=true;
      }
      else if (response?.isSuccess && response?.result?.code == 3) {
         this.showIsBreakInTriggered=true;
    }
    else if (response?.isSuccess && response?.result?.code == 1) {
      if(response.result.isKycVerified == false && response.result.kycLink!=null &&  response.result.kycLink!=undefined && response.result.kycLink!="")
      {      
        this.kycUrl= this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink);  
        if(response.result.kycLink.toLowerCase().indexOf('https')==-1 || this.insuranceformData.insuranceCompany==14 || this.insuranceformData.insuranceCompany==9 || this.insuranceformData.insuranceCompany==7)
        {
            this.showhttpKYCPopup=true;
            this.setShowNegativeKycInfo(true);
        }
        else
        {
            this.setShowNegativeKycInfo(true);
            this.showKYCPopup=true;           
        }          
      }
      else if (response?.result?.paymentObject != null && response?.result?.paymentObject?.paymentUrl != null && response?.result?.paymentObject?.paymentUrl != undefined && response?.result?.paymentObject?.paymentUrl != '') {
        this.paymentObject = response?.result?.paymentObject;
        this.isPaymentLink = true;
      }
      else
      if (response?.result?.paymentLink) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: response?.result?.Message,
          showConfirmButton: false,
          timer: 3000,
        });

        window.open(response?.result?.paymentLink, "_self")
        this.generalService.removeDataFromLocalStorage("quoteDetails");
        this.generalService.removeDataFromLocalStorage("ownerDetails");
        
      }
      else if (response?.result?.royalSundaramResponse !=null){
        this.royalDetails = response?.result?.royalSundaramResponse;
        this.isRoyalSundaram = true;
        this.generalService.removeDataFromLocalStorage("quoteDetails");
        this.generalService.removeDataFromLocalStorage("ownerDetails");
      }
      else if (response?.result?.futureGeneralQuoteResponse !=null){
        this.futureDetails = response?.result?.futureGeneralQuoteResponse;
        this.isFutureGeneral = true;
        this.generalService.removeDataFromLocalStorage("quoteDetails");
        this.generalService.removeDataFromLocalStorage("ownerDetails");
      }
      else if (response?.result?.shriram_GeneralResponse !=null){
        this.ShriramDetails = response?.result?.shriram_GeneralResponse;
        this.isShriramGeneral = true;
        this.generalService.removeDataFromLocalStorage("quoteDetails");
        this.generalService.removeDataFromLocalStorage("ownerDetails");
      }

      else if (response?.result?.reliance_GeneralResponse !=null){
        this.RelianceDetails = response?.result?.reliance_GeneralResponse;
        this.isRelianceGeneral = true;
        this.generalService.removeDataFromLocalStorage("quoteDetails");
        this.generalService.removeDataFromLocalStorage("ownerDetails");
      }
      else if(response?.result?.createUniversalSompoResponse !=null){
        const downloadLink = document.createElement('a');
        downloadLink.href = response?.result?.createUniversalSompoResponse.url;
        downloadLink.click();
        this.generalService.removeDataFromLocalStorage("quoteDetails");
        this.generalService.removeDataFromLocalStorage("ownerDetails");
      }    
      else {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: `Policy Created Successfully!`,
          showConfirmButton: false,
          timer: 5000,
        });
        this.router.navigate(['policyDetails']);
      }
    }
    else {
      if(response?.result?.isKycVerified == false && response?.result?.kycLink!=null &&  response?.result?.kycLink!=undefined && response?.result?.kycLink!="")
      {        
        this.kycUrl= this.sanitizer.bypassSecurityTrustResourceUrl(response.result.kycLink); 
        if(response.result.kycLink.toLowerCase().indexOf('https')==-1|| this.insuranceformData.insuranceCompany==14 || this.insuranceformData.insuranceCompany==9 || this.insuranceformData.insuranceCompany==7)
        {
            this.showhttpKYCPopup=true;
            this.setShowNegativeKycInfo(true);
        }
        else
        {
          this.showKYCPopup=true;       
          this.setShowNegativeKycInfo(true);
        }
        this.insuranceformData.ApplicationId=response.result.applicationId;
        this.insuranceformData.PolicyNumber=response.result.policyNumber;
        this.insuranceformData.url=response.result.kycLink;
      }
      else
      {
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          // icon: 'error',
          title: '',
          text: response?.result?.message ?? "Something went wrong",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
          // timer: 9000,
        });
      }
    }
  }

  async getPolicyDetails() {
    this.spinner.show();
    const response = await this.policyService.policyDetailsByFlag(0,"AcutalQuoteRequest","","",this.pId);
    if (response?.isSuccess && response.result) {
      this.insuranceformData = response.result;
      this.nomineeForm.patchValue(this.insuranceformData?.nomineeDetails);

      if(this.insuranceformData.existingTPPolicyDetails){
        this.existingTPPolicyDetailsForm.patchValue(this.insuranceformData.existingTPPolicyDetails);
      }

      let vehiclePremiumData={
        quoteRequest: this.insuranceformData,
        vehicleData:this.insuranceformData.vehicleDetails,
        premiumData:this.insuranceformData?.priceBreakup,
        proposerDetails:this.insuranceformData.proposerDetails,
        quoteDetails:{
          idv : this.insuranceformData?.vehicleDetails?.vehicleIDV,
          bodyIDV : this.insuranceformData?.vehicleDetails?.bodyIDV,
          chassisIDV:this.insuranceformData?.vehicleDetails?.chassisIDV,
          insuranceCompany: this.insuranceformData?.insuranceCompany,
          logo:"",
          addOnsList:[]
        }
      }
      this.QuoteDetails(vehiclePremiumData,this.insuranceformData.vehicleDetails);
      this.isSummry = true;

      if(this.status == 5){
        this.spinner.show();
        // const response = await this.insuranceservice.ContinuePolicyJourney({quoteId:this.insuranceformData.quoteId,insuranceCompany:this.insuranceformData.insuranceCompany,status:this.status});
        // this.readResponse(response);
        this.paySecurely()
        //this.spinner.hide();
      }
      //this.showIsBreakIn = true;
    }
    if(this.status != 5){
      this.spinner.hide();
    }
  }

  async QuoteDetails(vehiclePremiumData: any,_registrationDetails:any) { 
    window.scroll({top: 0, left: 0, behavior: 'smooth' });
    this.isDeclared = this.status == 5;
    this.validatePolicyUserForm?.get("isOtpVerified")?.setValue(this.status == 5);
    vehiclePremiumData.vehicleData.registeredInTheNameOf = vehiclePremiumData.vehicleData.registeredInTheNameOf ?? vehiclePremiumData.vehicleData.RegisteredInTheNameOf;
    this.insuranceformData= vehiclePremiumData.quoteRequest;
    this.premium = vehiclePremiumData.premiumData.netPremium;
    this.priceBreakup = vehiclePremiumData.premiumData;
    this.vehiclePremiumData = vehiclePremiumData;
    this.vehicleData = _registrationDetails;   
    this.vehicleForm.patchValue(vehiclePremiumData.vehicleData);
    this.userForm.get('companyGSTIN')?.setValue(this.vehicleForm.value.companyGSTINForCar??"");
    this.policyTypeList=
    [
      { value: '', text:'Select Previous Policy Type'},
      { value: 'Bundled', text: (this.insuranceformData.vehicleType == 2 ? 'Bundled (1OD + 5TP)' : 'Bundled (1OD + 3TP)')},
      { value: 'Comprehensive',text:'Standard/Comprehensive Coverage'},
      { value: 'Standalone', text: 'Standalone Coverage'},
      { value: 'Thirdparty',text:'Third-Party Liability Coverage'}
    ];
    this.policyTypeList= this.policyTypeList.filter((f:any)=>{return f.value == this.vehicleForm.value.previousPolicyType});
    this.quote = vehiclePremiumData.quoteDetails;
    this.insuranceformData.isBreakIn = vehiclePremiumData.quoteDetails?.isBreakIn;
    this.vehicleForm.get('vehicleIDV')?.setValue(vehiclePremiumData.quoteDetails.idv.toString());
    this.vehicleForm.get('bodyIDV')?.setValue(vehiclePremiumData?.quoteDetails?.bodyIDV?.toString());
    this.vehicleForm.get('chassisIDV')?.setValue(vehiclePremiumData?.quoteDetails?.chassisIDV?.toString());
    let regNo = this.vehicleFormControl['registrationNumber'].value??"";
    let rto = this.vehicleFormControl['rto'].value;
    if(rto == ""){
      rto = this.vehicleData.rto;
      this.vehicleForm?.get("rto")?.setValue(rto);
    }
    this.vehicleForm.get('registrationNumber1')?.setValue(regNo.replace(rto,""));
    this.selectedAddons = vehiclePremiumData.quoteDetails.addOnsList.filter((f:any)=> {return f.isAvailable == "Yes"});
    this.idv =vehiclePremiumData.quoteDetails.idv;
    this.companyLogo =vehiclePremiumData.quoteDetails.logo;
    this.companyLogoId =vehiclePremiumData.quoteDetails.insuranceCompany;
    this.setPANAadharVisbility();
    this.isThirdPArty =vehiclePremiumData.vehicleData.typeOfCover == "LiabilityOnly"; 
    this.plan =vehiclePremiumData.vehicleData.typeOfCover == "LiabilityOnly" && vehiclePremiumData.vehicleData.isTPPD == true ? "Limited Third Party" : vehiclePremiumData.vehicleData.typeOfCover == "LiabilityOnly" ? "Regular Third Party" : (vehiclePremiumData.vehicleData.typeOfCover =="Comprehensive" && vehiclePremiumData.vehicleData.isNew  && this.insuranceformData.vehicleType != 3) ? "Bundled " + (this.insuranceformData.vehicleType == 2 ? "1OD_5TP" : "1OD_3TP" ) : vehiclePremiumData.vehicleData.typeOfCover;
    this.tenure = vehiclePremiumData?.vehicleData?.policyTerm??"1";
    let expDate = moment(this.vehicleForm.get('previousPolicyExpiryDate')?.value,'YYYY-MM-DD');
    let ispastExpiryDate = expDate < moment(this.today);
    let startDate = vehiclePremiumData.vehicleData.isNew || ispastExpiryDate ? moment(this.today).format('DD MMM, YYYY') : expDate.add(1,'days').format('DD MMM, YYYY');
    let endDate = vehiclePremiumData.vehicleData.isNew ? (vehiclePremiumData.quoteRequest.vehicleType == 1 ? moment(this.today).add(3, 'years').add(-1,'days').format('DD MMM, YYYY') : (vehiclePremiumData.quoteRequest.vehicleType == 2 ? moment(this.today).add(5, 'years').add(-1,'days').format('DD MMM, YYYY') :  moment(this.today).add(1, 'years').add(-1,'days').format('DD MMM, YYYY'))) : ispastExpiryDate == false ? expDate.add(parseInt(this.tenure),'years').add(-1,'days').format('DD MMM, YYYY') : moment(this.today).add(parseInt(this.tenure),'years').add(-1,'days').format('DD MMM, YYYY');//expDate.add(parseInt(this.tenure),'Years').format('DD MMM, YYYY');

    this.tenurePeriod= startDate + ' To ' + endDate;
    if(vehiclePremiumData.proposerDetails){
      this.userForm.patchValue(vehiclePremiumData.proposerDetails);
      if(vehiclePremiumData.proposerDetails.mobileNumber !=  null && vehiclePremiumData.proposerDetails.mobileNumber != undefined && vehiclePremiumData.proposerDetails.mobileNumber != "" && this.userForm.get("phoneNumber")?.value =="" )
      {
        this.userForm.get("phoneNumber")?.setValue(vehiclePremiumData.proposerDetails.mobileNumber);
      }            
      if(vehiclePremiumData.quoteDetails.insuranceCompany == 8){
        this.aadharNumber = this.userForm?.value?.aadharNumber;
        if(this.userForm?.value?.aadharNumber != null && this.userForm?.value?.aadharNumber != undefined && this.userForm?.value?.aadharNumber != ""){
          this.aadharMsg ="Aadhar number is verified";
          this.aadharCode = 200;
        }
        this.aadharFrontImage = this.userFormControl['aadharFrontBase64']?.value;
        //this.aadharBackImage = this.userFormControl['aadharBackbase64']?.value;
        this.panImage = this.userFormControl['panBase64']?.value;
        this.form16Image = this.userFormControl['form16Base64']?.value;
        this.insuredImage = this.userFormControl['insuredBase64']?.value;

        this.aadhar1DocType = this.userFormControl['aadharFrontExt']?.value?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
        //this.aadhar2DocType = this.userFormControl['aadharBackExt']?.value?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
        this.insurerDocType = this.userFormControl['insuredPhotoExt']?.value?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
        this.form16DocType = this.userFormControl['form16Ext']?.value?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
        this.panDocType = this.userFormControl['panExt']?.value?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
      }    
    }

    if(vehiclePremiumData.vehicleData.registeredInTheNameOf == "Individual"){
      this.uploadDocList=["AADHAAR"];
      this.docUploadForm.get("documentName")?.setValue("AADHAAR");
    }
    else{
      this.uploadDocList=["CIN"];
      this.docUploadForm.get("documentName")?.setValue("CIN");
    }  

    this.removePolicyValidation();
    if((vehiclePremiumData.vehicleData.paCover == "No" && vehiclePremiumData.vehicleData.registeredInTheNameOf == "Individual" && vehiclePremiumData.vehicleData.isValidDrivingLicenseAvailable == "Yes" && vehiclePremiumData?.vehicleData?.alreadyHavePACover == "Yes") || this.plan=="Standalone"){
      if(this.vehiclePremiumData.vehicleData.isNew == true && vehiclePremiumData.quoteDetails.insuranceCompany == 3)
      {
        this.removeExistingTPPolicyValidation(); 
      }
      else {
        this.addExistingTPPolicyValidation();//add by shivraj
      }
      this.showTPDetailsForm = true;
    }
    else
    {
      this.showTPDetailsForm = false;    
      this.removeExistingTPPolicyValidation();  
    }

    if(parseInt(this.vehicleData.registrationYear??"0") == this.today.getFullYear())
    {
      this.maxDate = moment(this.today).format('YYYY-MM-DD');
    }
    else
    {
      this.maxDate = this.vehicleData.registrationYear + '-12-31';
    }
    this.minDate = this.vehicleData.registrationYear + '-01-01';
    

    if(this.vehiclePremiumData.vehicleData.isNew == true){
      this.maxDate = moment(this.today).format('YYYY-MM-DD');
      this.minDate = moment(this.today).format('YYYY-MM-DD');
    }

    // if(vehiclePremiumData.vehicleData.registeredInTheNameOf == "Company"){
    //   this.titleList = ["M/s"];
    //   this.userForm.get('title')?.setValue("M/s"); 
    //   this.userForm.get('gender')?.setValue("Others"); 
    //   this.userForm.get('gender')?.setValue("");
    //   this.userForm.get('gender')?.clearValidators();
    // }
    if(vehiclePremiumData.vehicleData.registeredInTheNameOf == "Company" ){
      //&& (vehiclePremiumData.quoteDetails.insuranceCompany == 1 || vehiclePremiumData.quoteDetails.insuranceCompany == 2 || vehiclePremiumData.quoteDetails.insuranceCompany == 9 || vehiclePremiumData.quoteDetails.insuranceCompany == 3 || vehiclePremiumData.quoteDetails.insuranceCompany == 8 || vehiclePremiumData.quoteDetails.insuranceCompany == 7)){
      this.titleList = ["M/s"];
      this.userForm.get('title')?.setValue("M/s"); 
      this.userForm.get('gender')?.setValue("");
      this.userForm.get('gender')?.clearValidators();
      this.userForm.get('gender')?.updateValueAndValidity();
      this.userForm.get('lastName')?.clearValidators();
      this.userForm.get('lastName')?.updateValueAndValidity();
      this.userForm.get('companyGSTIN')?.addValidators(Validators.required);
      this.userForm.get('companyGSTIN')?.updateValueAndValidity();
      this.userForm.get('companyGSTIN')?.addValidators(Validators.pattern(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/));
      this.userForm.get('companyGSTIN')?.updateValueAndValidity();
      if(this.companyLogoId == '8'|| this.companyLogoId == '18'){
        this.userForm.get('companyCIN')?.addValidators(Validators.required);
        this.userForm.get('companyCIN')?.updateValueAndValidity();
        this.userForm.get('companyCIN')?.addValidators(Validators.pattern(/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/));
        this.userForm.get('companyCIN')?.updateValueAndValidity();
      }

      this.showNomineeForm = false;
      this.removeNomineeFormValidation();
      this.maxDOB = moment(this.today).format('YYYY-MM-DD');
      this.showForm16 = false;
    }
    else
    {
      this.showForm16 = this.companyLogoId != '18';
      this.userForm.get('companyGSTIN')?.clearValidators();
      this.userForm.get('companyGSTIN')?.updateValueAndValidity();
      this.userForm.get('companyCIN')?.clearValidators();
      this.userForm.get('companyCIN')?.updateValueAndValidity();
      this.showNomineeForm = true;
      this.maxDOB = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
      this.titleList = ["Mr","Mrs","Ms"];
    }
    if(vehiclePremiumData.vehicleData.paCover == "Yes" && vehiclePremiumData.vehicleData.registeredInTheNameOf == "Individual" && vehiclePremiumData.vehicleData.isValidDrivingLicenseAvailable == "Yes" ){
      this.addNomineeFormValidation();
    }  
    else
    {
      this.removeNomineeFormValidation();
    }  

    if(this.companyLogoId == '9'){
      this.showBankDetailsTab= true;
      this.addBankValidators();
    }
    else{
      this.showBankDetailsTab= false;
      this.removeBankValidators();
    }
    if(this.companyLogoId == '18'){
      this.showKYCNextEditButton = false;
      this.isKYCValidateImplementation = true;
    }
    else
    {
      this.showKYCNextEditButton = true;
      this.isKYCValidateImplementation = false;
    }
  }

  removePolicyValidation(){
    if(this.vehiclePremiumData.vehicleData.isNew)
    {
      this.showExistingPolicyControls = false;
      this.vehicleForm.get('registrationNumber')?.clearValidators();
      this.vehicleForm.get('registrationNumber')?.updateValueAndValidity();
      this.vehicleForm.get('registrationNumber')?.addValidators(Validators.required);
      this.vehicleForm.get('registrationNumber')?.updateValueAndValidity();
      this.vehicleForm.get('registrationNumber')?.setValue(this.vehicleFormControl['rto'].value + "-NEW");
      this.vehicleForm.get('registrationNumber1')?.setValue("-NEW");
      //this.vehicleForm.controls['registrationNumber'].disable();

      this.vehicleForm.get('previousPolicyCompany')?.clearValidators();
      this.vehicleForm.get('previousPolicyCompany')?.updateValueAndValidity();
      this.vehicleForm.get('previousPolicyType')?.clearValidators();
      this.vehicleForm.get('previousPolicyType')?.updateValueAndValidity();
      this.vehicleForm.get('previousPolicyNumber')?.clearValidators();
      this.vehicleForm.get('previousPolicyNumber')?.updateValueAndValidity();
      this.vehicleForm.get('previousPolicyExpiryDate')?.clearValidators();
      this.vehicleForm.get('previousPolicyExpiryDate')?.updateValueAndValidity();  

      //Existing TP form validation remove
      //this.removeExistingTPPolicyValidation();
    }  

    if(this.insuranceformData.insuranceCompany==7 && this.insuranceformData.vehicleType == 3 && this.vehiclePremiumData.vehicleData.category == 'PCV' && this.vehiclePremiumData?.vehicleData?.pcVehicle?.toLowerCase() != 'bus' && this.vehiclePremiumData?.vehicleData?.pcVehicle?.toLowerCase() != 'school bus'){
      this.vehicleForm.get('usesType')?.addValidators(Validators.required);
      this.vehicleForm.get('usesType')?.updateValueAndValidity();  
    }
    else
    {
      this.vehicleForm.get('usesType')?.clearValidators();
      this.vehicleForm.get('usesType')?.updateValueAndValidity(); 
    }
  }

  removeExistingTPPolicyValidation(){          
          this.existingTPPolicyDetailsForm.get('tpInsurer')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpInsurer')?.updateValueAndValidity();
          this.existingTPPolicyDetailsForm.get('tpPolicyNumber')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpPolicyNumber')?.updateValueAndValidity();
          this.existingTPPolicyDetailsForm.get('tpPolicyTerm')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpPolicyTerm')?.updateValueAndValidity();
          this.existingTPPolicyDetailsForm.get('tpInceptionDate')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpInceptionDate')?.updateValueAndValidity();
          this.existingTPPolicyDetailsForm.get('tpExpiryDate')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpExpiryDate')?.updateValueAndValidity();
          this.existingTPPolicyDetailsForm.get('tpSumInsured')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpSumInsured')?.updateValueAndValidity();          
          this.existingTPPolicyDetailsForm.get('tpAddress1')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpAddress1')?.updateValueAndValidity();
          this.existingTPPolicyDetailsForm.get('tpAddress2')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpAddress2')?.updateValueAndValidity();
          this.existingTPPolicyDetailsForm.get('tpInsurerAddress')?.clearValidators();
          this.existingTPPolicyDetailsForm.get('tpInsurerAddress')?.updateValueAndValidity();  
  }

  addExistingTPPolicyValidation(){          
    this.existingTPPolicyDetailsForm.get('tpInsurer')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpInsurer')?.updateValueAndValidity();
    this.existingTPPolicyDetailsForm.get('tpPolicyNumber')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpPolicyNumber')?.updateValueAndValidity();
    this.existingTPPolicyDetailsForm.get('tpPolicyTerm')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpPolicyTerm')?.updateValueAndValidity();
    this.existingTPPolicyDetailsForm.get('tpInceptionDate')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpInceptionDate')?.updateValueAndValidity();
    this.existingTPPolicyDetailsForm.get('tpExpiryDate')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpExpiryDate')?.updateValueAndValidity();
    this.existingTPPolicyDetailsForm.get('tpSumInsured')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpSumInsured')?.updateValueAndValidity();          
    this.existingTPPolicyDetailsForm.get('tpAddress1')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpAddress1')?.updateValueAndValidity();
    this.existingTPPolicyDetailsForm.get('tpAddress2')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpAddress2')?.updateValueAndValidity();
    this.existingTPPolicyDetailsForm.get('tpInsurerAddress')?.addValidators(Validators.required);
    this.existingTPPolicyDetailsForm.get('tpInsurerAddress')?.updateValueAndValidity();  
}

  removeNomineeFormValidation(){          
    this.nomineeForm.get('nomineeName')?.clearValidators();
    this.nomineeForm.get('nomineeName')?.updateValueAndValidity();
    this.nomineeForm.get('nomineeAge')?.clearValidators();
    this.nomineeForm.get('nomineeAge')?.updateValueAndValidity();
    this.nomineeForm.get('relationshipWithNominee')?.clearValidators();
    this.nomineeForm.get('relationshipWithNominee')?.updateValueAndValidity();
    this.nomineeForm.get('gender')?.clearValidators();
    this.nomineeForm.get('gender')?.updateValueAndValidity();
    this.isNomineeFormRequired = false;
  }

  addNomineeFormValidation(){          
    this.nomineeForm.get('nomineeName')?.addValidators(Validators.required);
    this.nomineeForm.get('nomineeName')?.updateValueAndValidity();
    this.nomineeForm.get('nomineeAge')?.addValidators(Validators.required);
    this.nomineeForm.get('nomineeAge')?.updateValueAndValidity();
    this.nomineeForm.get('relationshipWithNominee')?.addValidators(Validators.required);
    this.nomineeForm.get('relationshipWithNominee')?.updateValueAndValidity();
    this.nomineeForm.get('gender')?.addValidators(Validators.required);
    this.nomineeForm.get('gender')?.updateValueAndValidity();
    this.isNomineeFormRequired = true;
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
  }

  alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }

  
  goTo(){
    if(this.isReferal){
      this.generalService.setDataToLocalStorage('ownerDetails', JSON.stringify(this.insuranceformData));
      this.router.navigate(['/vehicle-quotes',{q:this.rId,u:this.uId}]);
    } 
    else
    {
      this.gotoEvent.emit('vehicle_owner');
    } 
  }

  policyTypeList=
  [
    { value: '', text:'Select Previous Policy Type'},
    { value: 'Bundled', text: (this.insuranceformData.vehicleType == 2 ? 'Bundled (1OD + 5TP)' : 'Bundled (1OD + 3TP)')},
    { value: 'Comprehensive',text:'Standard/Comprehensive Coverage'},
    { value: 'Standalone', text: 'Standalone Coverage'},
    { value: 'Thirdparty',text:'Third-Party Liability Coverage'}
  ];

  policyTermList=
  [
    {value:'1',text:'1 Year'},
    {value:'2',text:'2 Year'},
    {value:'3',text:'3 Year'},
    {value:'4',text:'4 Year'},
    {value:'5',text:'5 Year'}
  ];

  async loadInsurancePartners()
  {
    let response = await this._lookupService.GetInsurancePartnersList('m');
      if (response?.isSuccess) {
        this.insurancePartnersList = response.result.map((m:any)=> {return {text:m.insuranceName,value:m.insuranceName,id:m.insuranceName}});

      }
  }

  async getStatesByCountry(country:string)
  {
    let response = await this._lookupService.getStatesListByCountry(country); 
    if (response?.isSuccess) {
      this.ensuringBranchStateList = this.stateList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
    }
  }

  async getDistrictsByState(state:string)
  {
    let response = await this._lookupService.getDistrictsListByState(state); 
    if (response?.isSuccess) {
      this.districtList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
    }
  }

  async getPincodesByDistrict(district:string)
  {
    let response = await this._lookupService.getPincodeByDistrict(district); 
    if (response?.isSuccess) {
      //this.pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      this.cityList = response.result.map((item:any)=> {return {text:item.cityVillageArea,value:item.cityVillageArea, text1:item.pincode, id:item.id}});
    }
  }

  onStateSelection(data:AutoCompleteItem){
   this.getDistrictsByState(data.text);
   this.userForm.get('state')?.setValue(data.text);
   console.log(this.userForm.value);
  }
  

  onDistrictSelection(data:AutoCompleteItem){
    this.userForm.get('city')?.setValue(data.text);
    this.userForm.get('district')?.setValue(data.text);
    this.getPincodesByDistrict(data.text);
    console.log(this.userForm.value); 
  }

   onPincodeSelection(data:AutoCompleteItem)
   {
    this.getDistrictsByState(data.pincodeMaster?.state?.toUpperCase()??"");
    this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase()??"");
    this.userForm.get('pincode')?.setValue(data.pincodeMaster?.pincode);
    this.userForm.get('pincodeId')?.setValue(data.pincodeMaster?.pincode?.toUpperCase());
    this.userForm.get('city')?.setValue(data.pincodeMaster?.cityVillageArea?.toUpperCase());
    this.userForm.get('district')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    this.userForm.get('state')?.setValue(data.pincodeMaster?.state?.toUpperCase());
  }

   onCitySelection(data:AutoCompleteItem)
   {

     this.userForm.get('city')?.setValue(data.text);
    // console.log(this.userForm.value);
   }

   onStateBlur(data:any)
   {
    this.userForm.get('state')?.setValue(data);
   }

  //  onPincodeBlur(data:any)
  //  {
  //     this.userForm.get('pincode')?.setValue(data);
  //     this.userForm.get('pincodeId')?.setValue(data);
  //  }

   async onPincodeChange(data:any) {
    if(data.length >= 3){
      const res = await this.lookUpService.pincodeMasterDetails(data);
      if (res != null && res.result) {
        this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      }
    }
    
  }

   onCityBlur(data:any)
   {
    // if(data == ""){
       this.userForm.get('city')?.setValue(data);
    // }
   }

   onDistrictBlur(data:any)
   {
      this.userForm.get('city')?.setValue(data);
      this.userForm.get('district')?.setValue(data);
   }

   onPrevCompSelection(data:AutoCompleteItem){
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data.text);
  }

  onPrevCompBlur(data:any)
  {
    this.vehicleForm.get('previousPolicyCompany')?.setValue(data);
  }

  changeTab(tab:string)
  {
    switch(tab){
      case 'declaration' : this.detail =tab; break;
      case 'owner' : if(this.isOwnerValid) this.detail =tab; break;
      case 'address' : if(this.isAddressValid) this.detail =tab; break;
      case 'nominee' : if(this.isNomineeValid) this.detail =tab; break;
      case 'vehicle' : if(this.isVehicleValid) this.detail =tab; break;
      case 'policy' : if(this.isPolicyValid) this.detail =tab; break;
      case 'bank' : this.detail =tab; break;
    }    
  }

  changeOptionTab(tab:string)
  {
    this.selectedOptionTab =tab;
    this.userForm.get('isPANNumber')?.setValue(tab == "pan");
    this.togglePANAadharValidation();   
  }

  checkValueNotZero(data:string)
  {
    if(data != null && data != undefined && data != "")
    {
      return parseFloat(data) > 0 ? true : false;
    }
    else
    {
      return false;
    }
  }

  showHideAddonDetails(){
    this.showDetails = !this.showDetails;
  }
   //(blur)="onStateBlur($event.target)"
   //(blur)="onPincodeBlur($event.target)"
   //(blur)="onCityBlur($event.target)"

  toggleCollapse(panelNumber:number){
    this.hideAllPanel();
    switch(panelNumber){
      case 1 : this.panel1 = !this.panel1; break;
      case 2 : this.panel2 = !this.panel2; break;
      case 3 : this.panel3 = !this.panel3; break;
      case 4 : this.panel4 = !this.panel4; break;
      case 5 : this.panel5 = !this.panel5; break;
      case 6 : this.panel6 = !this.panel6; break;
      case 7 : this.panel7 = !this.panel7; break;
    }
  }

  editClick(tab:string){
    debugger;
    this.isSummry = false;
    this.changeTab(tab);
  }

  hideAllPanel(){
    this.panel1 = false;
    this.panel2 = false;
    this.panel3 = false;
    this.panel4 = false;
    this.panel5 = false;
    this.panel6 = false;
    this.panel7 = false;
  }

  sameAsPolicyDetails(e:any){
    if(e.target.checked)
    {
      this.existingTPPolicyDetailsForm.get('tpInsurer')?.setValue(this.vehicleForm.get('previousPolicyCompany')?.value);
      this.existingTPPolicyDetailsForm.get('tpPolicyNumber')?.setValue(this.vehicleForm.get('previousPolicyNumber')?.value);      
      this.existingTPPolicyDetailsForm.get('tpExpiryDate')?.setValue(this.vehicleForm.get('previousPolicyExpiryDate')?.value);
      if(this.vehicleForm.get('previousPolicyExpiryDate')?.value != null && this.vehicleForm.get('previousPolicyExpiryDate')?.value != undefined && this.vehicleForm.get('previousPolicyExpiryDate')?.value != ""){
      let tpInceptionDate = moment(this.vehicleForm.get('previousPolicyExpiryDate')?.value,'YYYY-MM-DD').add(-1, 'years').add(1,'days').format('YYYY-MM-DD');
      this.existingTPPolicyDetailsForm.get('tpInceptionDate')?.setValue(tpInceptionDate);  
      }  
    }
    else
    {
      this.existingTPPolicyDetailsForm.get('tpInsurer')?.setValue('');
      this.existingTPPolicyDetailsForm.get('tpPolicyNumber')?.setValue('');
    }
  }

  onPrevTPCompSelection(data:AutoCompleteItem){
    this.existingTPPolicyDetailsForm.get('tpInsurer')?.setValue(data.text);
  }

  onPrevTPCompBlur(data:any)
  {
    this.existingTPPolicyDetailsForm.get('tpInsurer')?.setValue(data);
  }

  async getPanCardDetails(){
    this.showNegativeKYCCorfirmButton = false;
    this.userForm.get("flatNo")?.setValue("");
    this.userForm.get("streetAddress")?.setValue("");
    this.userForm.get("pincode")?.setValue("");
    this.userForm.get("pincodeId")?.setValue("");
    this.userForm.get("city")?.setValue("");
    this.userForm.get("district")?.setValue("");
    this.userForm.get("state")?.setValue("");
    this.userForm.get('firstName')?.setValue("");
    this.userForm.get('lastName')?.setValue("");
    this.spinner.show();
    let obj = {
      docNum:1,
      source:'',
      id_number:this.userForm.get('panNumber')?.value,
      insuranceCompany:this.insuranceformData.insuranceCompany,
      dob:this.userForm.get('dateOfBirth')?.value,
      registeredInTheNameOf:this.vehicleForm.value.registeredInTheNameOf,
      gstin:this.vehicleForm.value.companyGSTINForCar
    }
    const response = await this.commonService.GetKYCDetails(obj);    
    console.log(response); 
    if (response?.isSuccess) {
      this.spinner.hide();
      if(this.insuranceformData.insuranceCompany==3 && response?.result?.iskycVerified==0)    
      {
        this.kycUrl=response.result.redirect_link;
          this.showhttpKYCPopup=true;  
          this.setShowNegativeKycInfo(true);
        //result.redirect_link
      }
      else{
        this.setShowNegativeKycInfo(false);

      }
      let panDetails = response.result; 
      this.userForm.get('dateOfBirth')?.setValue(panDetails?.dob??'');
      this.setKycDetails(panDetails);
    }
    else
    {
      this.spinner.hide();
    }
  }

  async getVehicleDetails(){
    this.spinner.show();
    await this.insuranceservice.GetKycDetails(this.vehicleFormControl['registrationNumber'].value).then((response)=> {    
      if (response?.isSuccess) {               
          // if(response.result.vehicle_category != this.vehicleFormControl['vehiclecategory'].value)
          // {
          //   swal.fire({
          //     position: 'center',
          //     imageUrl: "../../assets/alerts/warning.png",
          //     imageHeight: 89,
          //     // icon: 'error',
          //     title: 'Please provide valid ' + (this.vehicleFormControl['vehiclecategory'].value =='2WN' ?  'two-wheeler' : 'four wheeler')  +' number.',
          //     //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
          //     showCancelButton: true,
          //     showConfirmButton: false,
          //     cancelButtonText: 'Ok',
          //     cancelButtonColor: "#DF3A4D",
          //     customClass: {
          //       confirmButton: "error_btn",
          //     },
          //     // timer: 9000,
          //   });
          // }
          // else
          {
            let registrationData = response.result;
            this.vehicleForm.get('engineNumber')?.setValue(registrationData.vehicle_engine_number);
            this.vehicleForm.get('chasisNumber')?.setValue(registrationData.vehicle_chasi_number);
            //this.vehicleForm.get('registrationDate')?.setValue(registrationData.registration_date);            
          }        
      }
      else
      {
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: 'Please provide valid vehicle number.',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
        });
      }
      this.spinner.hide();
    });
  }

  onEnsuringBranchStateSelection(data:AutoCompleteItem){
    this.getEnsuringBranchDistrictsByState(data.text);
    this.existingTPPolicyDetailsForm.get('tpAddress2')?.setValue(data.text);
   }

   onEnsuringBranchStateBlur(data:any)
   {
    this.existingTPPolicyDetailsForm.get('tpAddress2')?.setValue(data);
   }   
 
   onEnsuringBranchDistrictSelection(data:AutoCompleteItem){
     this.existingTPPolicyDetailsForm.get('tpAddress1')?.setValue(data.text);
   }

   onEnsuringBranchDistrictBlur(data:any)
   {
      this.existingTPPolicyDetailsForm.get('tpAddress1')?.setValue(data);
   }

   async getEnsuringBranchDistrictsByState(state:string)
   {
     let response = await this._lookupService.getDistrictsListByState(state); 
     if (response?.isSuccess) {
       this.ensuringBranchDistrictList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
     }
   }

   onRegistrationNoChange(event: any) {
    //this.vehicleForm.get('registrationNumber')?.setValue(this.vehicleFormControl['rto'].value + event.target.value.toUpperCase());
  }

  onPanSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && this.checkFileSize(file)) {
      this.panCardImage(file);
    }
  }

  panCardImage(file: File): void {
    let ext = this.generalService.getFileExtension(file.name);
    this.userForm.get('panExt')?.setValue(ext);
    this.panDocType = ext?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.panImage = e.target.result;
      this.userForm.get('panBase64')?.setValue(this.panImage);
    };

    reader.readAsDataURL(file);
  }

  onForm16Selected(event: any): void {
    const file: File = event.target.files[0];
    if (file && this.checkFileSize(file)) {
      this.form16ImageFile(file);
    }
  }

  form16ImageFile(file: File): void {
    const reader = new FileReader();    
    let ext = this.generalService.getFileExtension(file.name);
    this.userForm.get('form16Ext')?.setValue(ext);
    this.form16DocType = ext?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
    reader.onload = (e: any) => {
      this.form16Image = e.target.result;
      this.userForm.get('form16Base64')?.setValue(this.form16Image);
    };

    reader.readAsDataURL(file);
  }

  onFrontSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && this.checkFileSize(file)) {
      this.previewfrontImage(file);
    }
  }

  previewfrontImage(file: File): void {
    const reader = new FileReader();
    let ext = this.generalService.getFileExtension(file.name);
    this.userForm.get('aadharFrontExt')?.setValue(ext);
    this.aadhar1DocType = ext?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
    reader.onload = (e: any) => {
      this.aadharFrontImage = e.target.result;
      this.userForm.get('aadharFrontBase64')?.setValue(this.aadharFrontImage);
    };
    reader.readAsDataURL(file);
  }

  onBackSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && this.checkFileSize(file)) {
      this.previewbackImage(file);
    }
  }

  previewbackImage(file: File): void {
    const reader = new FileReader();
    let ext = this.generalService.getFileExtension(file.name);
    this.userForm.get('aadharBackExt')?.setValue(ext);
    this.aadhar2DocType = ext?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
    reader.onload = (e: any) => {
      this.aadharBackImage = e.target.result;
      this.userForm.get('aadharBackbase64')?.setValue(this.aadharBackImage);
    };

    reader.readAsDataURL(file);
  }

  onInsuredImageSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file && this.checkFileSize(file)) {
      this.insuredImageFile(file);
    }
  }

  insuredImageFile(file: File): void {
    let ext = this.generalService.getFileExtension(file.name);
    this.userForm.get('insuredPhotoExt')?.setValue(ext);
    this.insurerDocType = ext?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.insuredImage = e.target.result;
      this.userForm.get('insuredBase64')?.setValue(this.insuredImage);
    };
    reader.readAsDataURL(file);
  }

  formatInput(event: any) {
    let input = event.target as HTMLInputElement;
    let value = input.value.replace(/\s+/g, ''); // Remove existing spaces
    let newValue = '';  
    for (let i = 0; i < value.length; i += 4) {
      newValue += value.substr(i, 4) + ' ';
    } 
    this.userForm.get('aadharNumber')?.setValue( newValue.trim());
    this.aadharNumber = newValue.trim();

    this.aadharMsg="";
    this.aadharCode =0;
  }

  async adhaarValidate(aadharNumber:string){
    this.spinner.show();
    let obj ={
      id_number :aadharNumber.replace(/\s+/g, ''),
      source:"",
      docNum : 2
    }
    const response = await this.commonService.GetKYCDetails(obj);
    if (response?.isSuccess && response?.result?.status_code == 200) {
      this.aadharMsg ="Aadhar number is verified";
      this.aadharCode = 200;
    }
    else
    {
      this.aadharCode = response?.result?.status_code??201;
      this.aadharMsg = response?.result?.status_code + " : " + response?.result?.message;
    }
    this.spinner.hide();
  }

  setPANAadharVisbility(){
    this.showAadhar =false;
    this.showAadharImage = false;
    this.showInsuredImage=false;
    this.showPANForm16 =false;
    if(this.companyLogoId == '8'|| this.isTesting == true){ //|| this.companyLogoId == '18'
      this.showPANForm16 =true;
      if(this.vehicleForm.value.registeredInTheNameOf == "Individual"){
        this.showAadhar =true;
        this.showAadharImage = true;
        this.showInsuredImage = this.companyLogoId == '8';
      }
      else
      {
        this.showAadhar =false;
        this.showAadharImage = false;
        this.showInsuredImage=false;
      }

      if(this.vehicleForm.value.registeredInTheNameOf == "Company"){
        this.showAadhar = this.companyLogoId == '8';
      }
    }    
    this.togglePANAadharValidation();
  }

  togglePANAadharValidation(){
    if(this.companyLogoId == '8' || this.isTesting == true)// || this.companyLogoId == '18'
      {
      if(this.selectedOptionTab == 'pan'){
        this.userForm.get('form16Base64')?.clearValidators();
        this.userForm.get('form16Base64')?.updateValueAndValidity();

        this.userForm.get('panBase64')?.clearValidators();
        this.userForm.get('panBase64')?.updateValueAndValidity();
        this.userForm.get('panBase64')?.addValidators(Validators.required);
        this.userForm.get('panBase64')?.updateValueAndValidity();

        this.userForm.get('panNumber')?.clearValidators();
        this.userForm.get('panNumber')?.updateValueAndValidity();
        this.userForm.get('panNumber')?.addValidators(Validators.required);
        this.userForm.get('panNumber')?.updateValueAndValidity();

        this.userForm.get('panNumber')?.addValidators(Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}'));
        this.userForm.get('panNumber')?.updateValueAndValidity();
       // "panNumber": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      }
      else if(this.selectedOptionTab == 'form16'){
        this.userForm.get('panBase64')?.clearValidators();
        this.userForm.get('panBase64')?.updateValueAndValidity();

        this.userForm.get('form16Base64')?.clearValidators();
        this.userForm.get('form16Base64')?.updateValueAndValidity();
        this.userForm.get('form16Base64')?.addValidators(Validators.required);
        this.userForm.get('form16Base64')?.updateValueAndValidity();
      }

      this.userForm.get('aadharFrontBase64')?.clearValidators();
      this.userForm.get('aadharFrontBase64')?.updateValueAndValidity();

      this.userForm.get('aadharBackbase64')?.clearValidators();
      this.userForm.get('aadharBackbase64')?.updateValueAndValidity();

      this.userForm.get('insuredBase64')?.clearValidators();
      this.userForm.get('insuredBase64')?.updateValueAndValidity();

      this.userForm.get('fatherFirstName')?.clearValidators();
      this.userForm.get('fatherFirstName')?.updateValueAndValidity();

      this.userForm.get('fatherLastName')?.clearValidators();
      this.userForm.get('fatherLastName')?.updateValueAndValidity();

      if(this.vehicleForm.value.registeredInTheNameOf == "Individual"){
        
        this.userForm.get('aadharFrontBase64')?.addValidators(Validators.required);
        this.userForm.get('aadharFrontBase64')?.updateValueAndValidity();

        // this.userForm.get('aadharBackbase64')?.addValidators(Validators.required);
        // this.userForm.get('aadharBackbase64')?.updateValueAndValidity();      

        if(this.companyLogoId == '8'){
          this.userForm.get('insuredBase64')?.addValidators(Validators.required);
          this.userForm.get('insuredBase64')?.updateValueAndValidity();
        }

        this.userForm.get('fatherFirstName')?.addValidators(Validators.required);
        this.userForm.get('fatherFirstName')?.updateValueAndValidity();


      // this.userForm.get('fatherLastName')?.addValidators(Validators.required);
      // this.userForm.get('fatherLastName')?.updateValueAndValidity(); 
      }

      if(this.companyLogoId == '8' && this.vehicleForm.value.registeredInTheNameOf != "Individual"){
        this.userForm.get('aadharNumber')?.addValidators(Validators.required);
        this.userForm.get('aadharNumber')?.updateValueAndValidity();
      }
    }
    else{
      this.userForm.get('panBase64')?.clearValidators();
      this.userForm.get('panBase64')?.updateValueAndValidity();

      this.userForm.get('form16Base64')?.clearValidators();
      this.userForm.get('form16Base64')?.updateValueAndValidity();

      this.userForm.get('aadharFrontBase64')?.clearValidators();
      this.userForm.get('aadharFrontBase64')?.updateValueAndValidity();

      this.userForm.get('aadharBackbase64')?.clearValidators();
      this.userForm.get('aadharBackbase64')?.updateValueAndValidity();      

      this.userForm.get('aadharNumber')?.clearValidators();
      this.userForm.get('aadharNumber')?.updateValueAndValidity();

      this.userForm.get('aadharNumber')?.clearValidators();
      this.userForm.get('aadharNumber')?.updateValueAndValidity();

      this.userForm.get('insuredBase64')?.clearValidators();
      this.userForm.get('insuredBase64')?.updateValueAndValidity();

      this.userForm.get('fatherFirstName')?.clearValidators();
      this.userForm.get('fatherFirstName')?.updateValueAndValidity();

      this.userForm.get('fatherLastName')?.clearValidators();
      this.userForm.get('fatherLastName')?.updateValueAndValidity();
    }
  }

  isPANAadhaDocValid():boolean{    
    let errorCount =0; 
    if(this.companyLogoId == '8'|| this.isTesting == true){// || this.companyLogoId == '18'
      if(this.selectedOptionTab == 'pan' && this.userFormControl['panBase64'].errors?.['required']){
        errorCount++;
      }
      else if(this.selectedOptionTab == 'form16' && this.userFormControl['form16Base64'].errors?.['required']){
        errorCount++;
      }
      if(this.userFormControl['aadharFrontBase64'].errors?.['required'])
      {
        errorCount++;
      }
      if(this.userFormControl['aadharBackbase64'].errors?.['required']){
        errorCount++;
      }
      if(this.userFormControl['aadharNumber'].errors?.['required']){
        errorCount++;
      }
      if(this.userFormControl['insuredBase64'].errors?.['required']){
        errorCount++;
      }
      if(this.aadharCode != 200 && (this.vehicleForm.value.registeredInTheNameOf == "Individual")){
        errorCount++;
      }
    }
    return errorCount == 0;
  }

  closeKYCModal()
  {
    this.showKYCPopup= false;
  }
  closehttpKYCModal()
  {
    this.showhttpKYCPopup= false;
  }

  openValidatePolicyUserPopup()
  {
    this.showValidatePolicyUserPopup= true;
  }

  closeValidatePolicyUserPopup()
  {
    this.showValidatePolicyUserPopup= false;
  }

  isAadharVerified():boolean{
    return this.aadharCode == 200;
  }
  // onInputChange(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode >= 48 && charCode <= 57) {
  //     if (input.value.length > 6) {
  //       input.value = input.value.slice(0, 6);
  //       event.stopPropagation();
  
  //     }
  //     return true;
  //   }
  //   return false;
  // }
  onInputChange(event: any): void {
    const initialValue = event.target.value;
   const input = event.target as HTMLInputElement;

    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (input.value.length > 6) {
        input.value = input.value.slice(0, 6);
        event.stopPropagation();
  
      }
    if (initialValue !== event.target.value) {
      event.stopPropagation();
      
    }
  }

  startTimer() {
    this.timerValue = 60;
    //this.timerSpanValue = true;
    this.timer = setInterval(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
      } else {
        this.stopTimer();
        
      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  }
  
  stopTimer() {
    clearInterval(this.timer);
  }

  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
         // Allow: Ctrl/cmd+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+C
        (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+X
        (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
         // let it happen, don't do anything
         return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
    }
  }  

  onInput(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }
  
  async sendOTP(isResend:boolean = false )
  {
    this.spinner.show();
    this.validatePolicyUserForm.get("otpNumber")?.setValue("");   
    this.validatePolicyUserForm.get("phoneNumber")?.setValue(this.validatePolicyUserForm.value.phoneNumber.toString()); 
    const response: any = await this.insuranceservice.SendPolicyUserOtp(this.validatePolicyUserForm.value);//this.insuranceForm.value);
    if (response?.isSuccess && response?.result?.code == 1) {
      if(isResend == false){
        this.showSendOTPButton = false;
      }
      this.startTimer();
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/success.png",
        imageHeight: 89,
        // icon: 'success',
        title: 'OTP sent successfully!',
        showConfirmButton: true,
        confirmButtonText: 'ok',
        confirmButtonColor: "#0DB561",
      });  
    }
    else
    {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: response?.result?.message ?? "Something went wrong, unable to send OTP",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      });  
    }
  }

  async validateOtp()
  {
    this.submittedOtp = true;
    this.spinner.show();
    this.validatePolicyUserForm.get("otpNumber")?.setValue(this.validatePolicyUserForm.value.otpNumber.toString());  
    this.validatePolicyUserForm.get("phoneNumber")?.setValue(this.validatePolicyUserForm.value.phoneNumber.toString());
    const response: any = await this.insuranceservice.ValidatePolicyUserOtp(this.validatePolicyUserForm.value);//this.insuranceForm.value);
    if (response?.isSuccess && response?.result?.code == 1) {
      this.spinner.hide();
      this.validatePolicyUserForm.get("isOtpVerified")?.setValue(true);
      this.showValidatePolicyUserPopup = false;
      setTimeout(() => {
          this.paySecurely()  
      }, 100);
    }
    else
    {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: response?.result?.message ?? "Something went wrong, unable to send OTP",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      });  
    }
  }

  checkFileSize(file:File):boolean{
    let isValid = false;
    if(file.size > 512000){
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: "File size must be less than 500kb",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      });  
    }
    else if(!(file?.type?.toLocaleLowerCase().includes('png') || file?.type?.toLocaleLowerCase().includes('pdf'))){
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: 'File format accepted only PDF & PNG',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
      });
    }
    else{
      isValid = true;
    }
    return isValid;
  }

  declaration():string{
    let decorationMessage = "";
    let canProcced = true;
    if(this.vehicleData.isNew==false && this.vehicleData?.typeOfCover != "LiabilityOnly"){   
      // 11	FW	Zero/Nil Depreciation
      // 12	FW	Consumables Cover
      // 13	FW	Engine and Gear Box Protect
      // 15	FW	Tyre Cover
      // 17	FW	Invoice Cover   

      if(this.quote?.addOnsList.length > 0){
        let addon = this.quote.addOnsList.filter((f:any)=>{return (f.label =="Zero Nil Dep" || f.label =="Invoice Cover" || f.label == "Tyre Cover" || f.label == "Engine and Gear Box Protect") && f.isAvailable == 'Yes'}).map((m:any)=>{return m.id});
        if(addon.includes(11) == true && this.insuranceformData?.vehicleDetails?.isNilDepOptedInExistingPolicy == "Yes"){
          decorationMessage = "Please confirm that following cover(s) <b>Zero/Nil Dep ";
          canProcced = false;
        }
        if(addon.includes(17) == true && (this.insuranceformData.insuranceCompany != 2 && this.insuranceformData.vehicleType != 3 && this.insuranceformData?.vehicleDetails?.isInvoiceCoverOptedInExistingPolicy == "Yes")){// && this.insuranceformData?.vehicleDetails?.isInvoiceCoverOptedInExistingPolicy == "Yes"
          if(decorationMessage == ""){
            decorationMessage = "Please confirm that following cover(s) <b>Invoice Cover ";
          }
          else
          {
            decorationMessage += ", Invoice Cover"
          }
          canProcced = false;
        }
        if(addon.includes(13) == true && (this.insuranceformData.insuranceCompany != 2 && this.insuranceformData.vehicleType != 3 && this.insuranceformData?.vehicleDetails?.isEngineCoverOptedInExistingPolicy == "Yes")){// && this.insuranceformData?.vehicleDetails?.isEngineCoverOptedInExistingPolicy == "Yes"
          if(decorationMessage == ""){
            decorationMessage = "Please confirm that following cover(s) <b>Engine and Gear Box Protect Cover ";
          }
          else
          {
            decorationMessage += ", Engine and Gear Box Protect Cover"
          }
          canProcced = false;
        }

        if(this.insuranceformData.vehicleType != 2 && (this.insuranceformData.insuranceCompany != 2 && this.insuranceformData.vehicleType != 3 && this.insuranceformData?.vehicleDetails?.isTyreCoverOptedInExistingPolicy == "Yes")){
          if(addon.includes(15) == true){// && this.insuranceformData?.vehicleDetails?.isTyreCoverOptedInExistingPolicy == "Yes"
            if(decorationMessage == ""){
              decorationMessage = "Please confirm that following cover(s) <b>Tyre Cover ";
            }
            else
            {
              decorationMessage += ", Tyre cover"
            }
            canProcced = false;
          }  
        }        
      }
      
      if(this.insuranceformData?.isBiFuelKit == true && (this.insuranceformData.insuranceCompany != 2 && this.insuranceformData.vehicleType != 3 && this.insuranceformData?.vehicleDetails?.isBiFuelKitOptedInExistingPolicy == "Yes")){// && this.insuranceformData?.vehicleDetails?.isBiFuelKitOptedInExistingPolicy == "Yes"
        if(decorationMessage == ""){
          decorationMessage = "Please confirm that following cover(s) <b>Bi-Fuel Kit Cover ";
        }
        else
        {
          decorationMessage += ", Bi-Fuel Kit cover"
        }
        canProcced = false;
      }

      if(this.insuranceformData?.isElectricalAccessories == true && (this.insuranceformData.insuranceCompany != 2 && this.insuranceformData.vehicleType != 3 && this.insuranceformData?.vehicleDetails?.isElecricalAccessoriesCoverOptedInExistingPolicy == "Yes")){ // && this.insuranceformData?.vehicleDetails?.isElecricalAccessoriesCoverOptedInExistingPolicy == "Yes"
        if(decorationMessage == ""){
          decorationMessage = "Please confirm that following cover(s) <b>Electrical Accessories Cover ";
        }
        else
        {
          decorationMessage += ", Electrical Accessories cover"
        }
        canProcced = false;
      }  

      if(this.insuranceformData?.isNonElectricalAccessories == true && (this.insuranceformData.insuranceCompany != 2 && this.insuranceformData.vehicleType != 3 && this.insuranceformData?.vehicleDetails?.isNonElecricalAccessoriesOptedInExistingPolicy == "Yes")){ // && this.insuranceformData?.vehicleDetails?.isNonElecricalAccessoriesOptedInExistingPolicy == "Yes"
        if(decorationMessage == ""){
          decorationMessage = "Please confirm that following cover <b>Non-Electrical Accessories Cover ";
        }
        else
        {
          decorationMessage += ", Non-Electrical Accessories cover"
        }
        canProcced = false;
      }  

      if(decorationMessage != ""){
        decorationMessage += " </b> already having in your existing policy, if any of the cover are not found in your existing policy at the time of claim, you may face issue in claim settlement."
      }
    }
    return canProcced ? "" : decorationMessage;
  }


  isPASumInsuredGT15Lacs(){
    let isValid:boolean = false;
    if(this.existingTPPolicyDetailsForm != null && this.existingTPPolicyDetailsForm != undefined &&
      this.existingTPPolicyDetailsForm.value.tpSumInsured != null && this.existingTPPolicyDetailsForm.value.tpSumInsured != undefined && this.existingTPPolicyDetailsForm.value.tpSumInsured != "" &&
      parseInt(this.existingTPPolicyDetailsForm.value.tpSumInsured) >= 1500000)
      {
        isValid = true
      }
      return isValid;
  }
  
  setShowNegativeKycInfo(val:boolean){
    this.showNegativeKycInfo = val;
  }

  goToPolicyDetails(){
    this.router.navigate(['policyDetails']);
  }

  isTyping = !0;
  formatRegNo(e:any) {
    if ((e && e.keyCode && e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode >= 65 && e.keyCode <= 90) &&
    navigator.userAgent.match(/UCBrowser/))
        return !1;
    var t = e.keyCode;
    if (-1 != navigator.userAgent.indexOf("MSIE"))// || 1 == !!document.documentMode) 
      {
        if (32 == t)// || 8 == t)
            return !1;
        var o = this.vehicleForm?.get("rto")?.value +"-"+ e.target.value;
        o.length >= 3 && (o = this.formatReg(o)),
        o.length >= 5 && (o = this.formatReg(o)),
        o.length >= 7 && (o = this.formatReg(o)),
        this.vehicleForm.get('registrationNumber')?.setValue(0);
        e.target.value = o?.replace(this.vehicleForm?.get("rto")?.value,"");
    } else{
        //setTimeout(function(){
            if (32 == t)// || 8 == t)
                return !1;
            var ev = this.vehicleForm?.get("rto")?.value +"-"+  e.target.value;
            ev.length >= 3 && (ev = this.formatReg(ev)),
            ev.length >= 5 && (ev = this.formatReg(ev)),
            ev.length >= 7 && (ev = this.formatReg(ev)),
            this.vehicleForm.get('registrationNumber')?.setValue(ev);
            e.target.value = ev?.replace(this.vehicleForm?.get("rto")?.value,"");
            return 1;
        //}, 100)
    }
    return !1;
  }
  //paste and type
    checkRegistrationNumber(e:any) {
      this.isTyping && (this.isTyping = !1),
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9\- ]+/g, '');
      e.target.value = e.target.value.toUpperCase();
      let rto = this.vehicleForm?.get("rto")?.value;
      let value1 = rto +"-"+  e.target.value;       
      if(value1 && value1.includes('-')){
        var temp_regno = value1.split('-');
      //setTimeout(function(){
      var temp_regno = value1.split('-');
      if(temp_regno && temp_regno.length > 3 && temp_regno[3] && temp_regno[3].length > 4){
        let finalNo = (e.target.value.substring(0, e.target.value.length - 1));
        this.vehicleForm.get('registrationNumber')?.setValue(finalNo);
        e.target.value = finalNo.replace(rto,"");
        }
      //},100)
      }
      //this.onVehicleRegNumberInput();
    }
  
    formatReg(value:any) {
      if(value != null && value != undefined && value != "" ){
        var t = value;//value != null && value != undefined && value != "" ? value : e.target.value;
        t = t.replace(/-/g, "");
        var o = new Array(4);
        o[0] = t.slice(0, 2),
        o[1] = "",
        o[2] = "",
        o[3] = "";
        try {
            isNaN(t.slice(2, 4)) ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
            isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4))) : o[1] = t.slice(2, 4),
            isNaN(t.slice(4, 8)) ? (o[2] = o[2] + t.slice(4, 5),
            isNaN(t.slice(5, 6)) && isNaN(t.slice(6, 7)) ? o[2] = o[2] + t.slice(5, 7) : (isNaN(t.slice(5, 6)) ? o[2] = o[2] + t.slice(5, 6) : o[3] = o[3] + t.slice(5, 6),
            isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
            isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11))) : o[3] = o[3] + t.slice(4, 8)
        } catch (e) {}
        if(o[3] && o[3]?.length > 4){
          o[3] = o[3].substr(0,4);
        }
        let finalNo = o.join("-").replace(/ /g, "").replace(/--/g, "-").replace(/-\s*$/, "").replace(/[^- 0-9 a-z A-z]/g, "");
        return finalNo;
      }
        return value;
    }

    tpStartDateChange(){
      let tpInceptionDate = this.existingTPPolicyDetailsForm.get('tpInceptionDate')?.value;
      let tpPolicyTerm = this.existingTPPolicyDetailsForm.get('tpPolicyTerm')?.value;
      if(tpInceptionDate != null && tpInceptionDate != undefined && tpInceptionDate != "" && tpPolicyTerm != null && tpPolicyTerm != undefined && tpPolicyTerm != ""){
        this.existingTPPolicyDetailsForm.get('tpExpiryDate')?.setValue(moment(tpInceptionDate,'YYYY-MM-DD').add(-1, 'days').add(parseInt(tpPolicyTerm),'years').format('YYYY-MM-DD'));
      }
    }

    chasisNumberMaxLength(e:any):boolean{      
      let maxLength = this.companyLogoId == '18' ? 25 :17;
      let isMaxLimitNotReached =  false;

      const charCode = (e.which) ? e.which : e.keyCode;
      if(e?.target?.value?.length == maxLength){
        isMaxLimitNotReached = false;
      }
      else if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
        //return true;
        isMaxLimitNotReached =  true
      }       
      return isMaxLimitNotReached;
      //if(e?.target?.value?.length == (this.companyLogoId == '18' ? 25 : 17)) 
    }

    async getIfsc() {
      this.spinner.show();
      const response = await this.agentprofileService.GetIFSCInfo(this.userForm.get('ifscCode')?.value);
      debugger;
      if (response?.isSuccess) {
        let ifscDetails = response.result;
  
        if(ifscDetails?.bankName!=null && ifscDetails?.bankName!=undefined && ifscDetails?.bankName!= "" )
        {
          this.userForm.get('bankName')?.setValue(ifscDetails.bankName)
        }
      }
      this.spinner.hide();
    }

    addBankValidators(){
      this.userForm.get('ifscCode')?.addValidators(Validators.required);
      this.userForm.get('ifscCode')?.updateValueAndValidity();
      this.userForm.get('ifscCode')?.addValidators(Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/));
      this.userForm.get('ifscCode')?.updateValueAndValidity();

      this.userForm.get('bankName')?.addValidators(Validators.required);
      this.userForm.get('bankName')?.updateValueAndValidity();

      this.userForm.get('accountNo')?.addValidators(Validators.required);
      this.userForm.get('accountNo')?.updateValueAndValidity();
    }

    removeBankValidators(){
      this.userForm.get('ifscCode')?.clearValidators();
      this.userForm.get('ifscCode')?.updateValueAndValidity();

      this.userForm.get('bankName')?.clearValidators();
      this.userForm.get('bankName')?.updateValueAndValidity();

      this.userForm.get('accountNo')?.clearValidators();
      this.userForm.get('accountNo')?.updateValueAndValidity();
    }

    isAccountConfirmAccountSame(){
      return this.userFormControl['accountNo'].value === this.userFormControl['confirmAccountNo'].value;
    }

    async validateKYC(){
      this.kycFailedMsg = "";
      this.spinner.show();
      let obj = {
        docNum:1,
        source:'',
        id_number:this.userForm.get('panNumber')?.value,
        insuranceCompany:this.insuranceformData.insuranceCompany,
        dob:this.userForm.get('dateOfBirth')?.value,
        registeredInTheNameOf:this.vehicleForm.value.registeredInTheNameOf,
        gstin:this.vehicleForm.value.companyGSTINForCar,
        fullName: this.userFormControl['firstName'].value +' '+ this.userFormControl['lastName'].value,
        gender:this.userFormControl['gender'].value,
        vehicleType:this.insuranceformData.vehicleType,
        quoteId:this.insuranceformData.quoteId,
      }

      const response = await this.commonService.ValidateKYCDetails(obj);   
      this.spinner.hide(); 
        if(response?.result?.iskycVerified)
        {
          this.showKYCNextEditButton = true;
          this.iskycVerified = true;
          this.setKycDetails(response.result);
        }
        else{
          this.iskycVerified = false
          this.showNegativeKYCCorfirmButton = true;
          this.kycFailedMsg = response?.result?.message??"";
        }
  }

  async UploadKYCDocument(){
    this.iskycVerified = false;
    this.negativeKYCFailedMsg = "";
    this.spinner.show();
    let obj = {
      insuranceCompany:this.insuranceformData.insuranceCompany,
      vehicleType:this.insuranceformData.vehicleType,
      quoteId:this.insuranceformData.quoteId,
      documentName:this.docUploadForm.get('documentName')?.value, //AADHAAR / CIN       
      documentType:this.docUploadForm.get('documentType')?.value, //"pdf" : "Image"
      base64DocumentString:this.docUploadForm.get('base64DocumentString')?.value, //base64
      registeredInTheNameOf:this.vehicleForm.value.registeredInTheNameOf
    };
    const response = await this.commonService.UploadKYCDocument(obj);
    console.log(response);     
    if(response?.result?.iskycVerified)
      {
        this.spinner.hide();
        this.showKYCNextEditButton = true;
        this.iskycVerified = true;
        this.setKycDetails(response.result);
        this.ovdDocUploadPanel = true;
      }
      else{
        this.iskycVerified = false
        this.spinner.hide();
        this.ovdDocUploadPanel = true;
        this.negativeKYCFailedMsg = (response?.result?.message??"") == "" ? "KYC details not foud" : response?.result?.message??"";
      }
  }

  hideKYCNextButtonClick(flag:boolean){
    this.showKYCNextEditButton = flag;
  }

  setKycDetails(panDetails:any){
    if(panDetails?.full_name != null && panDetails?.full_name != undefined && panDetails?.full_name.length > 5 )
      {
        if(this.vehicleForm.value.registeredInTheNameOf == "Company"){
          this.userForm.get('firstName')?.setValue(panDetails?.full_name);
        }
        else
        {
          let nameArray = panDetails?.full_name.split(' ');
          switch(nameArray.length)
          {
            case 1:
              this.userForm.get('firstName')?.setValue(nameArray[0]);
              this.userForm.get('lastName')?.setValue(" ");
              break;
            case 2:
              this.userForm.get('firstName')?.setValue(nameArray[0]);
              this.userForm.get('lastName')?.setValue(nameArray[1]);
              break;
            case 3:
              this.userForm.get('firstName')?.setValue(nameArray[0] + ' ' + nameArray[1]);
              this.userForm.get('lastName')?.setValue(nameArray[2]);
              break;
            case 4:
              this.userForm.get('firstName')?.setValue(nameArray[0] + ' ' + nameArray[1] + ' ' + nameArray[2]);
              this.userForm.get('lastName')?.setValue(nameArray[3]);
              break;  
          }
        }

        //........................For HDFC
       debugger;
            this.userForm.get("flatNo")?.setValue(panDetails?.permanentAddress1??"");
            let address = (panDetails?.permanentAddress2??"")  + (((panDetails?.permanentAddress3??"") != "") ? ( ", " +panDetails?.permanentAddress3) : "")
            this.userForm.get("streetAddress")?.setValue(address);
            this.userForm.get("pincode")?.setValue(panDetails?.permanentPincode??"");
            this.userForm.get("pincodeId")?.setValue(panDetails?.permanentPincode??"");
            this.userForm.get("city")?.setValue(panDetails?.permanentCity??"");
            this.userForm.get("district")?.setValue(panDetails?.permanentCity??"");
            this.userForm.get("state")?.setValue(panDetails?.state??"");
            if(panDetails?.email != undefined && panDetails?.email != null && panDetails?.email != "")
            this.userForm.get("email")?.setValue(panDetails?.email??"");
            if(panDetails?.mobile != undefined && panDetails?.mobile != null && panDetails?.mobile != "")
            this.userForm.get("phoneNumber")?.setValue(panDetails?.mobile??"");
          
        //........................
      }  
    }

    onOvdDocSelected(event: any): void {
      const file: File = event.target.files[0];
      if (file && this.checkFileSize(file)) {
        this.previewOvdDoc(file);
      }
    }
  
    previewOvdDoc(file: File): void {
      const reader = new FileReader();
      let ext = this.generalService.getFileExtension(file.name);
      this.ovdDocType = ext?.toLocaleLowerCase()?.indexOf("pdf") != -1 ? "PDF" : "Image";
      reader.onload = (e: any) => {
        this.ovdDoc = e.target.result;
        this.docUploadForm.get('base64DocumentString')?.setValue(this.ovdDoc);
        this.docUploadForm.get('documentType')?.setValue(this.ovdDocType);

        if(this.vehicleForm.value.registeredInTheNameOf == "Individual"){
          this.userForm.get('aadharFrontBase64')?.setValue(this.ovdDoc);
          this.userForm.get('aadharFrontExt')?.setValue(this.ovdDocType);
        }
        else{
          this.userForm.get('panBase64')?.setValue(this.ovdDoc);
          this.userForm.get('panExt')?.setValue(this.ovdDocType);
        }        
      };
      reader.readAsDataURL(file);
    }
}
