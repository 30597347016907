export class marathiLabel {
    public product: string = 'उत्पादन';
    public support: string = 'समर्थन';
    public raise_a_query: string = 'प्रश्न उचला';
    public raise_a_claim: string = 'दावा वाढवा';
    public contact_us: string = 'आमच्याशी संपर्क साधा';
    public career: string = 'करिअर';
    public gallery: string = 'गॅलरी';
    public login: string = 'लॉगिन';
    public two_wheeler: string = 'दुचाकी';
    public four_wheeler: string = 'चारचाकी वाहन';
    public health: string = 'आरोग्य';
    public commercial_vehicle: string = 'व्यावसायिक वाहन';
    public bike_ins_title: string = 'तुमच्या टू-व्हीलर इन्शुरन्सवर अगदी कमी किमतीत मिळवा';
    public bike_ins_start: string = 'दुचाकी विमा ₹635* पासून सुरू';
    public enter_bike_number: string = 'दुचाकी क्रमांक प्रविष्ट करा उदाहरण: AB12CD3456';
    public verify: string = 'सत्यापित करा';
    public dont_have_policy: string = "नोंदणी क्रमांक आठवत नाही";
    public buy_new_bike: string = 'नवीन दुचाकी खरेदी केली?';
    public what_is_ins1: string = 'विमा म्हणजे';
    public what_is_ins2: string = 'काय?';
    public what_is_ins_title: string = 'विम्यासह मनःशांती शोधा'; 
    public what_is_ins_text: string = "आमच्या Click4bima पोर्टलवर तुमचे स्वागत आहे, तुमचे भविष्य सुरक्षित करण्याचे प्रवेशद्वार! विमा पर्यायांची विस्तृत श्रेणी एक्सप्लोर करण्यासाठी आमचे पोर्टल हे तुमचे वन-स्टॉप गंतव्य आहे. आरोग्य आणि वाहनापासून ते घर आणि प्रवासापर्यंत, आम्ही तुमच्या गरजेनुसार सर्वोत्तम कव्हरेज योजना आणण्यासाठी प्रतिष्ठित विमा कंपन्यांशी भागीदारी केली आहे. वेगवेगळ्या धोरणांची तुलना करून, तुम्ही माहितीपूर्ण निर्णय घेऊ शकता आणि तुमच्या जीवनशैली आणि बजेटमध्ये बसणारे एक निवडू शकता.";
    public why_insurance_imp1: string = 'विमा महत्त्वाचा';
    public why_insurance_imp2: string = 'का आहे?';
    public why_insurance_imp_title: string = 'उद्या सुरक्षित करणे: विम्याच्या जीवनशक्तीचे अनावरण';
    public why_insurance_imp_text: string = "विमा हे जीवनातील अनिश्चिततेविरूद्ध तुमचे कवच आहे, जे तुम्हाला सर्वात जास्त गरज असताना मनःशांती देते. हा तुमचा आणि तुमचा निवडलेला विमा प्रदाता यांच्यातील करार आहे जो अनपेक्षित घटनांच्या वेळी आर्थिक सहाय्याची हमी देतो. अचानक झालेला वैद्यकीय खर्च असो, वाहन अपघात असो, तुमच्या मालमत्तेचे नुकसान असो किंवा कोणतीही अनपेक्षित परिस्थिती असो, ओझे कमी करण्यासाठी विमा पावले उचलतात.";
    public claim_assistance1: string = 'सहाय्याचा दावा';
    public claim_assistance2: string = 'करा';
    public claim_assistance_title: string = 'तुमची मनःशांती पुनर्संचयित केली';
    public claim_assistance_text: string = "Click4bima कडून 'क्लेम असिस्टन्स' सह, तुम्ही खात्री बाळगू शकता की तुमच्याकडे एक विश्वासार्ह भागीदार आहे जो सुरळीत दावा प्रक्रियेचे महत्त्व समजतो. तुमची मनःशांती परत मिळवा हे जाणून घ्या की तुम्ही फक्त पॉलिसीद्वारे संरक्षित आहात - तुम्हाला काळजी घेणार्‍या टीमचा पाठिंबा आहे. आमची 'क्लेम असिस्टन्स' ही तुमची सर्वात जास्त गरज असताना तुमचा विश्वासू साथीदार आहे.";
    public best_place_ins_title: string = 'भारतात विमा खरेदी करण्यासाठी आम्हाला सर्वोत्तम ठिकाण कोणते बनवते?';
    public best_place_ins_text: string = 'आमच्या Click4bima पोर्टलवर आपले स्वागत आहे, भारतातील सर्वोत्कृष्ट विमा संरक्षण शोधण्यासाठी तुमचे वन-स्टॉप गंतव्य. आपण बाकीच्यांपेक्षा वेगळे का आहोत याचे आश्चर्य वाटते? योग्य विमा संरक्षणासह चिंतामुक्त भविष्याचा स्वीकार करा. तुमचा विमा प्रवास आजच आमच्यासोबत सुरू करा.'; 

    public everytime_support_title: string = '२४*७ समर्थन';
    public everytime_support_text: string = "आमच्या 24/7 विमा सहाय्याने कधीही, कुठेही मनःशांती मिळवा. आमची समर्पित टीम तुम्हाला तज्ञांचे समर्थन प्रदान करण्यासाठी, तुमच्या प्रश्नांची उत्तरे देण्यासाठी आणि तुम्ही नेहमी कव्हर करत आहात याची खात्री करण्यासाठी येथे चोवीस तास आहे."


    public best_price_ins_title: string = 'सर्वोत्तम किंमत विमा';
    public best_price_ins_text: string = "आमचे ध्येय सोपे आहे: गुणवत्तेशी तडजोड न करता तुम्हाला सर्वोत्तम विमा सौदे प्रदान करणे. सानुकूल करण्यायोग्य धोरणांच्या श्रेणीसह, तुमचे बजेट अबाधित ठेवून तुमचे भविष्य सुरक्षित ठेवण्यासाठी आम्ही येथे आहोत.";
    public reliable_title: string = 'विश्वसनीय';
    public reliable_text: string = 'कोणत्याही अपवादाशिवाय, तुमच्यासाठी सर्वात महत्त्वाचे असलेल्या गोष्टींचे संरक्षण करण्यासाठी तुम्ही आमच्यावर विश्वास ठेवू शकता. आमच्या वचनांची पूर्तता करण्याच्या सिद्ध ट्रॅक रेकॉर्डसह, आम्ही प्रत्येक टप्प्यावर तुमची मनःशांती सुनिश्चित करतो. तुमचे भविष्य सुरक्षित ठेवण्यासाठी अतुलनीय विश्वासार्हतेसाठी आम्हाला निवडा.';
    public easy_claim_title: string = 'दावा सोपा केला';
    public easy_claim_text: string = "आमची सुव्यवस्थित कार्यपद्धती आणि समर्पित समर्थन कार्यसंघ हे सुनिश्चित करतात की तुम्हाला आवश्यक असलेली मदत तुम्हाला सर्वात जास्त आवश्यक असेल तेव्हा मिळेल. आम्ही प्रक्रिया सुलभ करण्यासाठी वचनबद्ध आहोत जेणेकरुन तुम्ही तुमच्या जीवनातील सर्वात महत्त्वाच्या गोष्टींकडे त्वरीत परत येऊ शकता.";
    public mobile_app_title: string = 'एका अपद्वारे पैसे द्या, गुंतवणूक करा, तुमचे पैसे वाचवा';
    public mobile_app_content: string = 'तुमच्या सर्व विमा गरजांवर कुठेही, कधीही नियंत्रण मिळवा';

    public mobile_app_compare: string = 'वेगवेगळ्या विमा पॉलिसींची तुलना करा';
    public mobile_app_buy:string='तुमची सर्व पॉलिसी ऑनलाइन खरेदी करा, स्टोअर करा आणि शेअर करा';
    public mobile_app_track: string = 'जाता जाता तुमच्या पॉलिसी स्थितीचा मागोवा घ्या';
    public mobile_app_download: string = 'तुमची पॉलिसी एका टॅपने डाउनलोड करा';
    public mobile_app_coming: string = 'काहीतरी आश्चर्यकारक करण्यासाठी सज्ज व्हा - आमची अॅप्स लवकरच लाँच होईल!';
    public testimonial_title: string = 'आम्ही आमच्या ग्राहक अनुभवाची देखील काळजी घेतो';
    public our_partner_title: string = 'आमचे पार्टनर्स';
    public our_partner_text: string = 'आम्ही तुम्हाला सर्वोत्तम सेवा देण्यासाठी एकत्र काम करतो.';
    public need_help_title: string = 'काही मदत हवी आहे?';
    public need_help_text: string = 'तुम्हाला सर्वोत्तम सेवा देण्यासाठी आम्ही एकत्र काम करतो. Click4bima पोर्टलवर, आम्ही तुमच्या विमा प्रवासाच्या प्रत्येक टप्प्यावर अपवादात्मक ग्राहक सेवा आणि सहाय्य प्रदान करण्यासाठी वचनबद्ध आहोत. विम्याच्या जगात नेव्हिगेट करण्यासाठी आम्हाला तुमचा विश्वासू भागीदार होऊ द्या. आजच आमच्या सपोर्ट टीमशी संपर्क साधा आणि प्रत्यक्ष फरक अनुभवा. तुमची मनःशांती ही आमची प्राथमिकता आहे!';
    public mail_us:string='आम्हाला मेल करा';
    public mobile_number:string='मोबाईल नंबर';
    public whatsapp:string='व्हॉट्सअॅप';
    public about_us:string='आमच्याबद्दल';
    public sitemap:string='साइटमॅप';    
    public faqs:string='वारंवार विचारले जाणारे प्रश्न';
    public motor_insurance:string='मोटर विमा';
    public car_insurance :string='कार विमा';   
    public two_wheeler_insurance :string='दुचाकी विमा';
    public taxi_insurance :string='टॅक्सी विमा';
    public commercial_veh_insurance:string='व्यावसायिक वाहन विमा';
    public third_party_car_ins:string='थर्ड पार्टी कार विमा'; 
    public third_party_bike_ins:string='थर्ड पार्टी 2W विमा';
    public health_insurance:string='आरोग्य विमा';
    public family_health_ins:string='कौटुंबिक आरोग्य पॉलिसी ';
    public individual_health_ins:string='व्यक्तिगत आरोग्य पॉलिसी ';
    public group_health_ins:string='ग्रुप आरोग्य पॉलिसी ';
    public senior_citizen_health_ins:string='वरिष्ठ नागरिक आरोग्य पॉलिसी '; 
    public critical_illness_ins:string='गंभीर आजार पॉलिसी';
    public arogya_san_ins:string='आरोग्य संजीवनी पॉलिसी';
    public life_insurance:string='जीवन विमा';
    public term_insurance:string='टर्म विमा';
    public single_pay_term_ins:string='सिंगल पे टर्म विमा'; 
    public group_term_ins:string='ग्रुप टर्म विमा';  
    public saving_plans:string='बचत योजना';  
    public tax_saving_ins:string='टॅक्स बचत पॉलिसी';  
    public money_back_ins:string='मनी बॅक पॉलिसी';  
    public guaranteed_return_ins:string='गॅरंटीड रिटर्न पॉलिसी';   
    public ulip:string='यू एल आय पी';
    public pension_plan:string='पेन्शन योजना';  
    public child_plan:string='बाल योजना';    
    public others:string='इतर';    
    public privacy_policy:string='प्रायव्हसी पॉलिसी';  
    public cancellation_refund:string='रद्द करणे आणि परतावा';  
    public term_condition:string='अटी व शर्ती';
    public disclaimer: string = 'अस्वीकरण';
    public grivance_redressal: string = 'निवारण';
    public srg_add_title: string = 'एसआरजी इन्शुरन्स ब्रोकर्स प्रायव्हेट लिमिटेड';
    public srg_add_detail: string = '2 & 5, विनायक कॉम्प्लेक्स, दुर्गा नर्सरी रोड, उदयपूर, राजस्थान, 313001';
    
    public direct_broker_title: string = 'डायरेक्ट ब्रोकर, परवाना क्रमांक: DB-290';
    public direct_broker_detail: string = 'वैधता: 02.02.2026, CIN: U74140RJ2002PTC017656';
    public  payment_method:string='पेमेंट पद्धती';
    public  copyright:string='© कॉपीराइट 2023 Click4bima. सर्व हक्क राखीव';   
    public continue:string='चालू ठेवा';
    public bought_new_bike:string='नवीन टू-व्हीलर घेतली';
    public bought_new_car:string='नवीन कार घेतली';
    public car_ins_title:string='तुमच्या कार इन्शुरन्सवर अगदी कमी किमतीत मिळवा';
    public car_ins_start:string='कार विमा ₹2471* पासून सुरू';
    public enter_car_number:string='कार क्रमांक प्रविष्ट करा उदाहरण: AB12CD3456'; 
    public vehicle_detail_form:string='तुमच्या वाहनाचे तपशील';
    public city:string='शहर'; 
    public rto:string="आरटीओ";
    public manufacturer:string="उत्पादक"; 
    public model:string="मॉडेल"; 
    public fuel_type:string="इंधन प्रकार";
    public variant:string="व्हेरिएंट"; 
    public reg_year:string="नोंदणी वर्ष"; 
    public get_quotes:string="कोट्स मिळवा";  
    public search_city:string="शहर शोधा"; 
    public select_city:string="किंवा शहर निवडा"; 
    public select_rto:string='शहर आरटीओ निवडा';
    public search_manufacturer:string='उत्पादक शोधा';
    public select_manufacturer:string='किंवा उत्पादक निवडा';  
    public search_model:string='मॉडेल शोधा';
    public select_model:string='किंवा मॉडेल निवडा';  
    public search_variant:string='व्हेरिएंट शोधा';   
    public select_variant:string='किंवा व्हेरिएंट निवडा';   
    public select_year:string='वर्ष निवडा';   
    public select_fuel_type:string='इंधनाचा प्रकार निवडा'; 
    public contact_title:string='आमच्याशी संपर्क साधा';   
    public contact_text:string='आम्ही तुमच्याशी शक्य तितक्या लवकर परत येऊ. ';  
    public full_name:string='पूर्ण नाव';     
    public mobile:string='मोबाईल नंबर';            
    public interested_in:string='स्वारस्य आहे'  ;  
    public email:string='ईमेल पत्ता';
    public description:string='वर्णन';            
    public call_me:string='मला परत कॉल करा';
    public continue_fb:string='फेसबुक सह सुरू ठेवा';
    public continue_google:string='गुगल सह सुरू ठेवा';  
    public sign_in:string='साइन इन करा';  
    public password:string='पासवर्ड';    
    public remember:string='माझी आठवण ठेवा';   
    public dont_have_acc:string="कोणतेही खाते नाही"; 
    public signup_here:string="येथे साइन अप करा";     
    public click_here:string="इथे क्लिक करा";       
    public send_otp:string="ओटीपी पाठवा";         
    public back_login:string="लॉगिन करण्यासाठी परत";       
    public already_hv_acc:string="आधीपासूनच खाते आहे का?";     
    public signin_here:string="येथे साइन इन करा";     
    public verification:string="पडताळणी";   
    public verify_text:string="तुमच्या+K142";    
    public enter_otp:string="ओटीपी एंटर करा";   
    public dont_rec_otp:string="जर तुम्हाला कोड मिळाला नसेल तर! पुन्हा पाठवा";    
    public submit:string="सबमिट करा  "; 
    public accept_terms:string="तुमचे खाते तयार करून, तुम्ही आमच्या अटी आणि नियमांशी सहमत आहात";    
    public basic_details:string ="बेसिक तपशील";      
    public first_name:string ="प्रथम नाव";     
    public last_name:string ="आडनाव";     
    public dob:string ="जन्मतारीख";                        
    public referal_code:string ="रेफरल कोड";              
    public gender:string ="लिंग";                
    public new_password:string  ="नवीन पासवर्ड टाका";   
    public confirm_old_pass:string="नवीन पासवर्ड कन्फर्म करा";      
    public dont_have_policy_popup:string='तुम्हाला तुमचा दुचाकी क्रमांक आठवत नसेल, तर तुमच्या दुचाकीच्या मूलभूत तपशीलांमध्ये प्रवेश करण्यासाठी तुमची आरसी (नोंदणी प्रमाणपत्र) तुमच्याकडे तयार ठेवा.';       
    public year:string='वर्ष';
    public your_details:string='आपले तपशील प्रविष्ट करा';
   public name_title:string=' शीर्षक';



}