<div class="user-dashboard">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
    type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="card-user user-route">
    <div class="row">
      <h1>Enter Business Details</h1>
    </div>
    <div class="card card-body">
      <form [formGroup]="businessInfoForm" class="needs-validation" novalidate="" autocomplete="off">
        <div class="row">
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start required">Name </label>
              <input type="text" name="Registration" placeholder="Name " class="form-control"
                formControlName="businessName">
            </div>
          </div>
          <div class="col-md-4 pr-1">
            <label class="float-start required">Mobile </label>
            <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
              [searchCountryFlag]="false" [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode"
              name="phone" formControlName="businessPhoneNumber" [inputId]="'phone_number'"
              (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

            </ngx-intl-tel-input>
          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start required">Email </label>
              <input type="text" name="Registration" placeholder="Email" class="form-control"
                formControlName="businessEmail">
            </div>
          </div>
        </div><br>

        <div class="row">
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start">Description </label>
              <input type="text" name="Registration" placeholder="Description" class="form-control"
                formControlName="businessDescription">
            </div>
          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start required">Address 1 </label>
              <input type="text" name="Registration" placeholder="Address 1" class="form-control"
                formControlName="businessAddress1">
            </div>
          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start">Address 2 </label>
              <input type="text" name="Registration" placeholder="Address 2" class="form-control"
                formControlName="businessAddress2">
            </div>
          </div>
        </div><br>

        <div class="row">
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start required">Pincode </label>
              <input type="text" name="Registration" #pinCode (blur)="pincodeChangeEvent(pinCode)" placeholder="Pincode"
                class="form-control" formControlName="businessPinCode">
            </div>
          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start required"> City </label><br />
              <select class="form-select" #mycity (change)='changeCity(mycity.value)'
                aria-label="Default select example" name="businessCity" formControlName="businessCity">
                <option value="" selected>Select City</option>
                <option *ngFor="let city of cityList" [value]="city.id">
                  {{city.cityVillageArea}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start">Taluka </label>
              <input type="text" name="taluka" placeholder="Enter Taluka" class="form-control" formControlName="taluka">
            </div>
          </div>
        </div><br>

        <div class="row">
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start">District </label>
              <input type="text" name="district" placeholder="Enter District" class="form-control"
                formControlName="district">
            </div>
          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start">State </label>
              <input type="text" name="state" placeholder="Enter State" class="form-control" formControlName="stateId">
            </div>
          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start">Country </label>
              <input type="text" name="countryId" placeholder="Enter Country" class="form-control"
                formControlName="countryId">
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start required">Category </label>
              <select class="form-select" #category formControlName="category" aria-label="Default select example"
                name="category">
                <option value="" selected>Select Category</option>
                <option *ngFor="let category of categoryData" [value]="category.id">
                  {{category.name}}
                </option>
              </select>
            </div>

          </div>
          <div class="col-md-4 pr-1">
            <div class="form-group">
              <label class="float-start required">Bussiness logo</label>
              <input class="form-control form-control-sm" formControlName="documentBase64" type="file"
                (change)="onSelectFile($event)" accept=".png, .jpg, .jpeg, .pdf, video/*" />
            </div>
          </div>
        </div>
        <br>

        <div class="float-end text-center mb-2">
          <button class="btn btn-light" (click)="clearData()">Clear</button>&nbsp;
          <button class="btn btn-primary" [disabled]="businessInfoForm.invalid" (click)="businessInfo()">Save</button>
        </div>
      </form>

    </div>
  </div>
  <div class="text-center">
    <div class="card-user">
      <div class="row">
        <h1 class="text-start mt-4">Business Information</h1>
      </div>
      <div class="row p-3">
        <div class="col-md-6 ps-0">
          <div class="row mt-2 m-0 p-3 insurancePlanChildDiv card">
            <div class="row m-0 p-0 text-start">
              <div class="col d-flex">
                <div>
                  <p class="font-body m-0">Name</p>
                  <h4>Krishnpal Singh</h4>
                </div>
              </div>
              <div class="col d-flex">
                <div>
                  <p class="font-body m-0">Phone Number</p>
                  <h4>7877976163</h4>
                </div>
              </div>
              <div class="col d-flex">
                <div>
                  <p class="font-body m-0">Email</p>
                  <h4>krishnpal@bimag.com</h4>
                </div>
              </div>
            </div>
            <div class="row m-0 p-0 mt-5 text-start">
              <div class="col-4">
                <p class="font-body m-0">Address1</p>
                <h5>Magartalav</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">Address2</p>
                <h5>Desuri</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">Pincode</p>
                <h5>306502</h5>
              </div>
            </div>
            <div class="row m-0 p-0 mt-5 text-start">
              <div class="col-4">
                <p class="font-body m-0">City</p>
                <h5>Pali</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">State</p>
                <h5>Rajasthan</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">Country</p>
                <h5>India</h5>
              </div>
            </div>
            <div class="row m-0 p-0 mt-5 text-start">
              <div class="col-12">
                <p class="font-body m-0">Description</p>
                <h5>Write your Description here !</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 ps-0">
          <div class="row mt-2 m-0 p-3 insurancePlanChildDiv card">
            <div class="row m-0 p-0 text-start">
              <div class="col d-flex">
                <div>
                  <p class="font-body m-0">Name</p>
                  <h4>Krishnpal Singh</h4>
                </div>
              </div>
              <div class="col d-flex">
                <div>
                  <p class="font-body m-0">Phone Number</p>
                  <h4>7877976163</h4>
                </div>
              </div>
              <div class="col d-flex">
                <div>
                  <p class="font-body m-0">Email</p>
                  <h4>krishnpal@bimag.com</h4>
                </div>
              </div>
            </div>
            <div class="row m-0 p-0 mt-5 text-start">
              <div class="col-4">
                <p class="font-body m-0">Address1</p>
                <h5>Magartalav</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">Address2</p>
                <h5>Desuri</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">Pincode</p>
                <h5>306502</h5>
              </div>
            </div>
            <div class="row m-0 p-0 mt-5 text-start">
              <div class="col-4">
                <p class="font-body m-0">City</p>
                <h5>Pali</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">State</p>
                <h5>Rajasthan</h5>
              </div>
              <div class="col-4">
                <p class="font-body m-0">Country</p>
                <h5>India</h5>
              </div>
            </div>
            <div class="row m-0 p-0 mt-5 text-start">
              <div class="col-12">
                <p class="font-body m-0">Description</p>
                <h5>Write your Description here !</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <button type="button" class="btn btn-primary float-end me-5" data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Add Bank</button>
      <div class="offcanvas offcanvas-end overflow-scroll" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
        id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
        <form class="needs-validation" novalidate="" autocomplete="off">
          <div class="offcanvas-header">
            <h4 class="offcanvas-title" id="offcanvasScrollingLabel">Add Bank Details</h4>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Account Number</label>
              <input type="text" class="form-control" placeholder="Account Number" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Confirm Account Number</label>
              <input type="text" class="form-control" placeholder="Confirm Account Number"
                formControlName="registrationNumber" name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">IFSC Code</label>
              <input type="text" class="form-control" placeholder="IFSC Code" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Bank Name</label>
              <input type="text" class="form-control" placeholder="Bank Name" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Branch Name</label>
              <input type="text" class="form-control" placeholder="Branch Name" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Name</label>
              <input type="text" class="form-control" placeholder="Name" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Address</label>
              <input type="text" class="form-control" placeholder="Address" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Pin Code</label>
              <input type="text" class="form-control" placeholder="Pincode" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="make" class="input-label required">City</label>
              <select class="form-select" aria-label="Default select example" name="Make" formControlName="make">
                <option value="" selected>Select City</option>
                <option>Jaipur</option>
                <option>Jodhpur</option>
                <option>Udaipur</option>
              </select>
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Taluka</label>
              <input type="text" class="form-control" placeholder="Taluka" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">District</label>
              <input type="text" class="form-control" placeholder="District" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">State</label>
              <input type="text" class="form-control" placeholder="State" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="mt-3 text-start">
              <label for="number" class="input-label required">Country</label>
              <input type="text" class="form-control" placeholder="Country" formControlName="registrationNumber"
                name="registrationNumber">
            </div>
            <div class="row" style="top: 60px; position: relative;">
              <button class="btn btn-primary" data-bs-dismiss="offcanvas">Add Bank</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>