import { Component } from '@angular/core';

@Component({
  selector: 'app-singlepayinsurance',
  templateUrl: './singlepayinsurance.component.html',
  styleUrls: ['./singlepayinsurance.component.css']
})
export class SinglepayinsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
