<app-navbar></app-navbar> 
<div class="container" style="background-color: rgba(64, 64, 255, 0.05)">
    <div class="container-fluid">
        <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
            <div class="card mt-10 p-3">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h4><img src="../../assets/svgIcon/ArrowLeft.svg" alt="Arrow left"> Select Insurance Plan and
                            Policy
                            Duration</h4>
                    </div>
                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h6 class="text-end"><img class="scales" src="../../assets/svgIcon/Scales.svg" alt="Scales">
                            <span class="primary"> Compare</span>
                        </h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-10" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
            <h2 class="text-start">Insurance Plan</h2>
        </div>
        <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
            <div class="ins_box p-3" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen, 'ins_box_active': isInsBox1}" (click)="selectInsBox('box1')">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h6 class="text-start">Own Damage Only</h6>
                    </div>
                    <div class="text-end" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <img [ngClass]="{'active_info': isInsBox1}" src="../../assets/svgIcon/Info.svg" alt="">
                    </div>
                </div>
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <p class="font-body">Covers damages to your vehicle only and not third-party</p>
                </div>
                <div class="text-center" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <p><span class="amount" [ngClass]="{'amount_active': isInsBox1}">₹699/</span>
                        <span class="year text-center" [ngClass]="{'year_active': isInsBox1}">1 Year</span>
                    </p>
                </div>
            </div>&nbsp;&nbsp;
            <div class="ins_box p-3" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen, 'ins_box_active': isInsBox2}" (click)="selectInsBox('box2')">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h6 class="text-start">Own Damage Only</h6>
                    </div>
                    <div class="text-end" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <img [ngClass]="{'active_info': isInsBox2}" src="../../assets/svgIcon/Info.svg" alt="">
                    </div>
                </div>
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <p class="font-body">Covers damages to your vehicle only and not third-party</p>
                </div>
                <div class="text-center" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <p><span class="amount" [ngClass]="{'amount_active': isInsBox2}">₹699/</span>
                        <span class="year text-center" [ngClass]="{'year_active': isInsBox2}">1 Year</span>
                    </p>
                </div>
            </div>&nbsp;&nbsp;
            <div class="ins_box p-3" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen, 'ins_box_active': isInsBox3}" (click)="selectInsBox('box3')">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h6 class="text-start">Own Damage Only</h6>
                    </div>
                    <div class="text-end" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <img [ngClass]="{'active_info': isInsBox3}" src="../../assets/svgIcon/Info.svg" alt="">
                    </div>
                </div>
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <p class="font-body">Covers damages to your vehicle only and not third-party</p>
                </div>
                <div class="text-center" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <p><span class="amount" [ngClass]="{'amount_active': isInsBox3}">₹699/</span>
                        <span class="year text-center" [ngClass]="{'year_active': isInsBox3}">1 Year</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="mt-10" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
            <h2 class="text-start">Policy Duration <img src="../../assets/svgIcon/Info.svg" alt=""></h2>
        </div>

        <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
            <div class="policy_card" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen, 'policy_active': isPolicyBox1}" (click)="selectPolicy('box1')">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div class="align-items-center d-flex" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h4>1 Year</h4>
                    </div>
                    <div class="text-end" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <p><span class="control_label">Premium </span><span class="amount"
                                [ngClass]="{'amount_active': isPolicyBox1}"> ₹699</span></p>
                    </div>
                </div>
            </div>&nbsp;&nbsp;
            <div class="policy_card" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen, 'policy_active': isPolicyBox2}" (click)="selectPolicy('box2')">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div class="align-items-center d-flex" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h4>2 Year</h4>
                    </div>
                    <div class="text-end" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <p><span class="control_label">Premium </span><span class="amount"
                                [ngClass]="{'amount_active': isPolicyBox2}"> ₹699</span></p>
                    </div>
                </div>
            </div>&nbsp;&nbsp;
            <div class="policy_card" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen, 'policy_active': isPolicyBox3}" (click)="selectPolicy('box3')">
                <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div class="align-items-center d-flex" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <h4>3 Year</h4>
                    </div>
                    <div class="text-end" [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                        <p><span class="control_label">Premium </span><span class="amount"
                                [ngClass]="{'amount_active': isPolicyBox3}"> ₹699</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-10" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
            <div [ngClass]="{'col-8': !isSmallScreen, 'col-12': isSmallScreen}">
                <div class="insured_value" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h4>Insured Value (IDV)</h4>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6>₹30,500</h6>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <!-- <input type="range" class="form-range" id="customRange1" style="color:#951518 !important"> -->
                            <input type="range" min="1" max="100" value="50">
                        </div>
                        <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6>₹42,670</h6>
                        </div>
                    </div>
                    <div class="text-center" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h1 class="primary">₹30,500</h1>
                    </div>
                </div>

                <div class="mt-3 extra_coverage" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h4>Add Extra Coverage</h4>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <input type="checkbox">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h5>Personal Accident Cover of Rs. 10 Lakh</h5>
                                </div>
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Info.svg" alt="">
                                </div>
                            </div>
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <span style="color: #DF3A4D;">PA cover is mandatory</span>
                                </div>
                            </div>
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p><span class="amount">₹180/</span>
                                        <span class="year text-center">1 Year</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <input type="checkbox">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h5>Engine and Gear-Box Protect Cover</h5>
                                </div>
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Info.svg" alt="">
                                </div>
                            </div>
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p><span class="amount">₹100</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <input type="checkbox">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h5>Consumable Cover</h5>
                                </div>
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Info.svg" alt="">
                                </div>
                            </div>
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p><span class="amount">₹320</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <input type="checkbox">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h5>Return to Invoice (RTI) Cover</h5>
                                </div>
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Info.svg" alt="">
                                </div>
                            </div>
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p><span class="amount">₹50</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <input type="checkbox">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h5>Passenger Cover</h5>
                                </div>
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Info.svg" alt="">
                                </div>
                            </div>
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p><span class="amount">₹57</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <input type="checkbox">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <h5>PA Cover for paid Driver - IMT 17</h5>
                                </div>
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Info.svg" alt="">
                                </div>
                            </div>
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}"></div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p><span class="amount">₹150</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>&nbsp;&nbsp;&nbsp;
            <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                <div class="vehicle_details" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h4>Your vehicle details</h4>
                    </div>
                    <div class="mt-3" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/svgIcon/suzuki.svg" alt="">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Vehicle</p>
                            <h5>Suzuki Motorcycle</h5>
                        </div>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img class="svg_img" src="../../assets/svgIcon/bike_icon.svg" alt="Bike" width="20">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Model</p>
                                    <h5>STD BSVI 125</h5>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img class="svg_img" src="../../assets/svgIcon/city.svg" alt="city" width="27">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">RTO</p>
                                    <h5>RJ14 - Jaipur</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/CalendarBlank.svg" alt="Calendar">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Year</p>
                                    <h5>2014</h5>
                                </div>
                            </div>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                                <div [ngClass]="{'col-2': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <img src="../../assets/svgIcon/Coins.svg" alt="">
                                </div>
                                <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                                    <p class="font-body">Last Year Claim</p>
                                    <h5>NO</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col-1': !isSmallScreen, 'col-12': isSmallScreen}">
                            <img src="../../assets/svgIcon/Percent.svg" alt="">
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">NCB Last Year</p>
                            <h5>0%</h5>
                        </div>
                    </div>
                </div>
                <div class="mt-3 premium_breakup" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                    <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <h4>Premium Breakup</h4>
                    </div>
                    <div class="mt-2 border-btm" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Basic Own Damage</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹1,000</h6>
                        </div>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Net Premium</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹1,000</h6>
                        </div>
                    </div>
                    <div class="mt-2 border-btm" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Add GST(18%)</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹180</h6>
                        </div>
                    </div>
                    <div class="mt-2" [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <p class="font-body">Final Premium</p>
                        </div>
                        <div [ngClass]="{'col': !isSmallScreen, 'col-12': isSmallScreen}">
                            <h6 class="text-end">₹1,180</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="bottm_bar">
        <div class="container-fluid">
            <div [ngClass]="{'row': !isSmallScreen, '': isSmallScreen}">
                <div [ngClass]="{'col-6': !isSmallScreen, 'col-12': isSmallScreen}">
                    <p class="font-body">Net premium</p>
                    <h1 class="text-start">₹1,180</h1>
                </div>
                <div [ngClass]="{'col-6 text-right': !isSmallScreen, 'col-12': isSmallScreen}">
                        <button class="btn">Help?</button>
                        <a href="/bike-insurance-owner-details"><button class="btn btn-primary">Continue</button></a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>