<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1>Child Plan&nbsp;</h1>
            </div>
            <div class="top-line"> </div>
            <div class="row">
                <div class="mt-3">
                    <p class="font-body">A child insurance plan is a combination of insurance and investment that ensure
                        a secure future for your child. Life cover is available as a lumpsum payment at the end of
                        policy term. IT take care of your Protection and Savings needs for securing the future of your
                        children. You can protect and secure the future of your child with child insurance plans
                        encompassing child education plans. In our child education insurance plan, you pay premiums for
                        a specified period (monthly, half-yearly, yearly or single pay). Once the policy term ends, you
                        receive a lump sum amount called the Maturity Benefit. In case of an unfortunate event during
                        the policy term, the company offers your nominee the Life Cover amount.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Child Education Plan?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A child education plan is a dual purpose financial instrument offering both
                        protection and investment return. For the protection component on offer, such plans are also
                        commonly known as child insurance plans.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Why do you need a Child Education Plan?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A child education plan is an insurance policy that offers protection as well as
                        an
                        opportunity for saving money to ensure a secure future for your child.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">It acts as a safety net to make sure that your child&rsquo;s education does not
                        get
                        affected even if you are not around. In case of an unfortunate event, your child receives the
                        life
                        cover.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">It makes sure that your child receives the education he/she desires with a
                        lump-sum
                        payout at maturity or when any unfortunate event occurs to you.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types of Child Plans</h4>
                </div>
                <div class="mt-3"></div>
                <p class="font-body">The types of Child Insurance plans are very from one Insurance provider to another.
                    Some plans are listed below for your reference:-&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">1 Single-Premium Child Plan</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Single premium child plan requires a one time investment for the entire policy term
                    and stays worry-free from remembering the due dates of premium payment.&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">2 Regular Premium Child Plan</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Regular premium child education plan offers you flexibility on payment of premium.
                    You can pay the premium at prefixed intervals.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">3 Child ULIP</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Child ULIP is the combination of investment plan and life coverage. It gives you a
                    three-prolonged benefit, along with higher insurance coverage, contribution in the equity market,
                    and disciplined investments. Although the plan carries some amount of risk. Under this policy the
                    policyholder has the option to switch between funds after certain waiting period.&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">4 Child Endowment Plan&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">In this plan you authorize your insurance provider to invest in debt instrument.
                    This plan also provides Capital appreciation on investment.</p>
            </div>
            <div class="title-line">
                <h4>Benefits of Child Insurance Plan</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Flexibility of disbursing of funds on maturity or death</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Premium can be waive of in case of demise of Insurance</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Availability of secured loans against child insurance plan</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Tax benefits under Income Tax Act</p>
            </div>
            <div class="title-line">
                <h4>Features of Child Plan</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Child plans are one of the best investment options, as they are loaded with
                    multiple features like the generation of additional wealth, tax- Savings, and more.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Listed below are some of the significant features of child plans:</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Waiver of Premium :- The child plan has an in-built premium waiver, which is
                    applicable when the parent dies. This feature may vary from insurer to insurer.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Sum Assured :- The sum assured for a child plan is usually 10x the gross earnings
                    of the policyholder, which is paid out in the event of the demise of the parent or at maturity.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Partial Withdrawal :- Plans offered by various insurance providers come with the
                    option of partial withdrawals (as and when the child turns 18 years).</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Choice of Funds :- Child plans allow you to choose between different fund options
                    such as equity, debt, money-market and hybrid. &nbsp;You also have the option of switching between
                    funds after a certain period.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">High Returns :- The return of child plans goes as high as 12%, which is well above
                    the rate of inflation in the long term. Therefore, it not only protects your investments from
                    getting eroded (as a result of inflation) but also contributes towards the steady growth of the
                    fund.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Tax Benefits :- Child plans are characterised by the triple exempt benefit (EEE
                    exemption), which implies that the investment (premium) is eligible for tax dedication, the interest
                    earned is exempted from tax and income generated is also exempted from tax.</p>
            </div>
        <div class="row">
            <div class="title-line">
                <h4>Benefits of Child Education Plan</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">The Child Education Plan come up with lot of benefits listed below:&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">1. Support for child&rsquo;s future plans: All of us want the best education
                    possible for our kids, whether in India or abroad. However, the cost of both school and higher
                    education is increasing with every passing day. Child plans help you in meeting the education
                    expenses of your children. Besides education, we also plan for their marriage and to support them
                    beyond marriage, if required. You may want to go for a child plan that ensures cash flow to meet all
                    such needs. &nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">2. Medical treatment: These plans offers the convenience of withdrawals during
                    policy period in case of medical emergency.&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">3. Financial Support : It provide financial security during the growing years of
                    your child. Covering the earning parent&rsquo;s life is a crucial part of savings for children as it
                    ensures there is sufficient fund and cash flow for the education and other future expenses even if
                    something untoward happens to the parent.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">&nbsp;4.other benefits : such as income benefit , some of the plans provides
                    regular income to child.</p>
            </div>
            
        <div class="row">
            <div class="title-line">
                <h4>Things to be consider before buying Child Plan</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">1. Essential Features : consider essential features that are required before buying
                    plan&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">2. Plan Tenure: choose plan according to your need based on your requirement
                    ,financial strength and age of your child.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">3. Claim settlement Ratio: it is most important factor to consider before buying
                    plan . Check the Claim Settlement Ratio of Insurance provider. With a high settlement ratio there is
                    a significant possibility that insurance provider will pass your future claims.&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Need of Child Education Plan</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Education insurance plan appears like insurance plan coverage in many ways but is
                    designed specifically at ensuring the future educational needs of your kids are met. Usually, this
                    means that a single payment will be paid out to your kids on a decided time frame in exchange for
                    your costs.</p>
            </div>
            
            <div class="mt-3">
                <p class="font-body">A good education insurance plan will usually take into account factors such as
                    rising prices and tax to ensure that your kids get the right pay-out to complete the amount. Some
                    guidelines even include extended benefits, such as paying towards your child&rsquo;s marriage or
                    providing a single payment for the kid to use outside to train needs.</p>
            </div>
            
            <div class="mt-3">
                <p class="font-body">Some education guidelines will pay out in a decided adulthood time frame,
                    regardless of your health status. This is often when your kids reach college age. As such knowledge
                    plan can be viewed as an alternative to creating a &lsquo;college fund&rsquo;.</p>
            </div>
            
        </div>
        <div class="row">
            <div class="title-line">
                <h4>What Documents are Required for Child Insurance Plan?</h4>
            </div>
            <div class="mt-3">
                <p class="font-body"> The documents required for starting an insurance plan depending on the
                    requirements as per the company policies as well as the coverage of the plan itself. Nevertheless,
                    there are a few standard documents that are invariably required, no matter what.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Policy form- This is where all your proposal details and information is entered.
                </p>
            </div>
            <div class="mt-3">
                <p class="font-body">Proof of address- Anything from your passport, driving license, aadhar card,
                    electricity bill can be used.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Proof of income- The person buying the policy for the child needs to show that he
                    has a source of income to pay the premiums.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Proof of identity- PAN card, aadhar card, driving license, voter ID, are common
                    identity proofs you can use.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Proof of age- A copy of the passport, birth certificate, or 10th and 12th mark
                    sheets can be used for this.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Figuring out the best child health insurance plan is a subjective decision since
                    requirements vary from family to family. Understand where you currently are and the future you are
                    planning for your child, can help you pick the right plan for him.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Best Child Insurance Plan Riders</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Riders act as additional benefits on the base insurance plan that you take, and
                    these are highly recommended. There are a few great riders that should be opted for, but it is
                    necessary to know that your choice of insurance plan may govern the riders that are available to
                    you.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">1. Income Benefit Rider</p>
            </div>
            <div class="mt-3">
                <p class="font-body">This is one of the highly recommended riders for your child&rsquo;s insurance plan,
                    especially if you have a fast-paced life. By opting for an additional premium amount, your child can
                    get a specific percentage of the policy amount every month as income. This usually triggers if the
                    parent passes away, meets with an accident, or is diagnosed with a critical illness.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">2. Critical Illness Rider</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Similar to the one mentioned above, if a parent is diagnosed with a high-risk
                    illness or undergoes a specific medical procedure, the child can receive a specified amount from the
                    rider. The premium for this depends on the age of the parent, amongst other factors.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">3. Disability Benefit Rider</p>
            </div>
            <div class="mt-3">
                <p class="font-body">This rider usually comes in variants of partial to total disability of the parent.
                    If the parent gets disabled either by an accident or a disease, the child receives a benefit of a
                    large amount from the rider.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">4. Accidental Death Rider</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Death caused by accident may not always be covered by the insurance policy terms.
                    Therefore, this rider helps your child remain safe and secure, as well as provide him with financial
                    support in the event such a tragedy does occur.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">5. Premium Waiver Rider</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Even if your child receives a monetary benefit after your death, he still needs to
                    pay the remaining premiums of his policy to prevent it from lapsing. This rider takes care of that,
                    where any future premiums are waived if the parent were to pass away.</p>
            </div>
            
        <div class="row">
            <div class="title-line">
                <h4>What child Insurance plan does not cover?</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Suicide: if policyholder commits suicide within one year of buying child insurance
                    policy then beneficiary nominated by policyholder does not receive any claim amount. &nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Indulged in Criminal activities: Any harm arise due to illegal or criminal activity
                    amounts to make eneligible to receive claim amount.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Alcohol abuse : In case policyholder dies due to overdose of drugs or abuse of
                    alcohol
                    ,then his/her nominee dose not receive any compensation amount.</p>
            </div>
        </div>

        <div class="row">
            <div class="title-line">
                <h4>How to make claim ?</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Get the child insurance plan from an insurance company that boasts of a high claim
                    settlement ratio. This ensures of a smooth and quick claim processing and settlement in times of
                    crisis.
                    Let&rsquo;s get acquainted with the claim process.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">In case of an event for which a claim needs to be filed, inform the insurance
                    company
                    about the incident as soon as possible. This can be done by visiting the nearest branch office or
                    calling at their toll-free number or sending an email</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Also submit the claim form and give other details like particulars of the policy,
                    the
                    date and cause of the incident, name of nominee, etc.</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Once the claim is registered, provide other supporting documents and &nbsp;reports
                </p>
            </div>
            <div class="mt-3">
                <p class="font-body">The company will appoint an assessor to verify the documents and the case</p>
            </div>
            <div class="mt-3">
                <p class="font-body">If approved, and no further investigation is needed, the claim benefit is
                    transferred
                    with 30 days of furnishing the documents</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Document required for making a claim ?</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Duly filled in claim form</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Policy document</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Death certificate</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Medical certificate</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Prescriptions</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Diagnostic reports</p>
            </div>
            <div class="mt-3">
                <p class="font-body">FIR copy (unnatural death)</p>
            </div>
            <div class="mt-3">
                <p class="font-body">Postmortem report(unnatural death)</p>
            </div>
            <div class="mt-3">
                <p class="font-body">KYC of insured and nominee</p>
            </div>
            <div class="mt-3">
                <p class="font-body">NEFT details</p>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>