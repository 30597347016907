export class MenuData {
  Id: number = 0;
  PrentMenuName: string | undefined;
  RoutingURL: string = '';
  SubMenu: MenuData[] = [];
  ImageURL: string = '';
  IsExpand: boolean = false;
  subMenuId: number = 0;
}

export interface ResponseMenu {
  menuId: number;
  UserId: number;
  menuTitle: string;
  menuAuthorityId: number;
  menuCommand: string;
  menuAction: string;
  menuIcon: string;
  menuPermissionId: number;
  OpenInNewTab: boolean;
}

export class ResponseSubMenu {
  public subMenuId: number = 0;
  public menuId: number = 0;
  public menuAuthorityId: number = 0;
  public subTypeId: number = 0;
  public menuTitle: string = '';
  public menuCommand: string = '';
  public menuAction: string = '';
}
