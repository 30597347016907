import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupService } from './../Services/signup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from 'src/app/Models/login.component.model';
import swal from 'sweetalert2';
import { LookUpService } from './../Services/lookup.service';
import { PincodeMaster } from '../Models/signUp.component.model'
import { LoginService } from '../Services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuotesLoginComponent } from '../Login/quotes-login/quotes-login.component';
import { VehicleDetails } from '../Models/vehicle-component.model';
import { GeneralService } from '../Services/general.service';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['signup.component.css']
})
export class SignupComponent implements OnInit {
  currentYear : number = new Date().getFullYear();
  [x: string]: any;
  phoneNumber: string = '';
  referralCode: string = '';
  signupForm: FormGroup;
  mobileForm: FormGroup;
  public passwordType: string = PasswordType.pwd;
  passwordShow: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  isSignup: boolean = true;
  isOtp: boolean = false;
  isNewUser: boolean = false;
  pincodeMaster = new PincodeMaster();
  ServicesDown: boolean = false;
  pincodeMasterId: any;
  isReff: boolean = true;
  modalRef: BsModalRef;
  vehicleData: VehicleDetails = {};
  showPassword: boolean = false;
  quoteRoute: boolean = false;

  // Timer Initialization
  isResendEnabled: boolean = true;
  timerSpanValue: boolean = false;
  timerValue: number = 40; // Start at 30 seconds
  timer: any; // To store the interval reference
  dob: Date | null = null;
  isForgot: boolean = true;

  constructor(private signupService: SignupService, private _avRoute: ActivatedRoute,
    private fb: FormBuilder, private router: Router, private generalService : GeneralService,
    private lookUpService: LookUpService, private loginService: LoginService,
    private spinner: NgxSpinnerService, private modalService: BsModalService) {

    this.signupForm = this.fb.group({
      "referralCode": [''],
      "otp": [''],
      "firstName": [''],
      "lastName": [''],
      "userName": [''],
      "phoneNumber": [''],
      "userEmail": [''],
      "streetAddress": ['T'],
      "pincodeId": ['66416'],
      "gender": ['Male'],
      "dob": [],
      "countryCode": [''],
      "passcode": [''],
      "confirmPassword": [''],
    });

    this.mobileForm = this.fb.group({
      "otp": [''],
      "phoneNumber": [''],
    });
    if (this._avRoute.snapshot.params["ReferralCode"]) {
      this.referralCode = this._avRoute.snapshot.params["ReferralCode"];
    }
    if (this.referralCode != null && this.referralCode != undefined && this.referralCode != '') {
      this.signupForm.get('referralCode')?.setValue(this.referralCode);
    } else {
      this.isReff = false;
    }
    this.signupForm.get('phoneNumber')?.setValidators([Validators.required]);
    this.signupForm.get('phoneNumber')?.updateValueAndValidity;
    this.StatusCheck();

    const dateObject = new Date();
    this.signupForm.get('dob')?.setValue(dateObject.toISOString().slice(0, 10));
  }
  ngOnInit() {
    this.startTimer();
    if( localStorage.getItem('routeName')){
      this.quoteRoute = true;
    }
  }
  startTimer() {
    this.timerSpanValue = true;
    this.timer = setInterval(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
      } else {
        this.stopTimer();
        
      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  }
  
  stopTimer() {
    clearInterval(this.timer);
  }
  sendOtp() {
    this.isOtp = !this.isOtp
    this.isSignup = !this.isSignup;
    this.stopTimer();
  }
  async StatusCheck() {
    this.spinner.show();
    try {
      const response = await this.loginService.StatusCheck();
      if (response === ``) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: `Website currently down, apologize for the inconvenience.`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.ServicesDown = true;
      }

    }
    catch (error) {
      console.log(error);
    }
    this.spinner.hide();
  }
  randOTP:string ="";
  //Generate RandomNo
 GenerateRandomNo()
{
    this.randOTP = "";
    let range = {min: 100000, max: 999999}
    let delta = range.max - range.min
    this.randOTP = Math.round(range.min + Math.random() * delta).toString();
}




  async PhoneExists() {
    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.phoneNumber = phone.e164Number;
    }
    this.GenerateRandomNo();
    // OTP
    let request = { phoneNumber: this.signupForm.get('phoneNumber')?.value,OTP :this.randOTP }

    const response = await this.signupService.CheckUserForSignup(request);
    if (response?.code == 1) {
      this.isSignup = false;
      this.isOtp = true;
      this.signupForm.get('otp')?.setValidators([Validators.required]);
      this.signupForm.get('otp')?.updateValueAndValidity;
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: response?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }



  changemobile() {
    this.isOtp = false;
    this.isSignup = true;
    this.signupForm.get('otp')?.clearValidators();
    this.signupForm.get('otp')?.updateValueAndValidity;
  }

  async validateOtp() {
    this.spinner.show();
    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.signupForm.get('countryCode')?.setValue(phone.dialCode);
    }

    let request = { PhoneNumber: this.signupForm.get('phoneNumber')?.value, OTP: this.signupForm.get('otp')?.value ,GeneratedOTP:this.randOTP};

    const response = await this.signupService.ValidateOTP(request);
    if (response?.code == 1) {
      this.isOtp = false;
      this.isNewUser = true;
      this.EnableDetailsRequired();
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: response?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    this.spinner.hide();

  }

  async pincodeUserChangeEvent(pincode: any) {
    this.pincodeMaster = new PincodeMaster();
    const res = await this.lookUpService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.pincodeMaster = res.result[0];
      this.pincodeMasterId = this.pincodeMaster?.id;
    }
  }

  async submit() {
    this.spinner.show();
    if (this.signupForm.get('passcode')?.value != this.signupForm.get('confirmPassword')?.value) {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Password and confirm password should be same',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.signupForm.get('countryCode')?.setValue(phone.dialCode);
    }

    this.signupForm.get('otp')?.setValue(this.signupForm.value.otp.toString());

    this.signupForm.get('passcode')?.setValue(window.btoa(this.signupForm.value.passcode));
    this.signupForm.get('confirmPassword')?.setValue(window.btoa(this.signupForm.value.confirmPassword));
    this.signupForm.get('userName')?.setValue(this.signupForm.value.firstName + ' ' + this.signupForm.value.lastName);
    if (this.pincodeMaster?.id != null && this.pincodeMaster?.id != undefined && this.pincodeMaster?.id != 0) {
      this.signupForm.get('pincodeId')?.setValue(this.pincodeMaster?.id);
    }

    const response = await this.signupService.SignUpUser(this.signupForm.value);
    if (response?.isSuccess) {
      this.generalService.setAccessToken(response.result.accessToken);
      this.generalService.setUniqueuserid(response.result.uniqueUserId);
      if(this.quoteRoute == true){
        const initialState: any = {
          list: [
            {
              "quote": localStorage.getItem('quote'),
              "vehicleData": this.vehicleData,
              "IsMockup": false
            }
          ]
        };
        this.modalRef = this.modalService.show(QuotesLoginComponent, { initialState });
      }else{
        this.router.navigate(['/dashboard']);
      }
      //localStorage.clear();
    }
    this.spinner.hide();
  }


  onCountryChangeNew(e: any) {
    this.signupForm.get('phoneNumber')?.setValue("");
  }

  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }

  EnableDetailsRequired() {
    this.signupForm.get('firstName')?.clearValidators();
    this.signupForm.get('firstName')?.updateValueAndValidity;
    this.signupForm.get('lastName')?.clearValidators();
    this.signupForm.get('lastName')?.updateValueAndValidity;
    // this.signupForm.get('userName')?.setValidators([Validators.required]);
    // this.signupForm.get('userName')?.updateValueAndValidity;
    this.signupForm.get('userEmail')?.setValidators([Validators.required]);
    this.signupForm.get('userEmail')?.updateValueAndValidity;
    this.signupForm.get('dob')?.setValidators([Validators.required]);
    this.signupForm.get('dob')?.updateValueAndValidity;
    this.signupForm.get('gender')?.setValidators([Validators.required]);
    this.signupForm.get('gender')?.updateValueAndValidity;
    // this.signupForm.get('streetAddress')?.setValidators([Validators.required]);
    // this.signupForm.get('streetAddress')?.updateValueAndValidity;
    // this.signupForm.get('pincode')?.setValidators([Validators.required]);
    // this.signupForm.get('pincode')?.updateValueAndValidity;
    this.signupForm.get('passcode')?.setValidators([Validators.required]);
    this.signupForm.get('passcode')?.updateValueAndValidity;
    this.signupForm.get('confirmPassword')?.setValidators([Validators.required]);
    this.signupForm.get('confirmPassword')?.updateValueAndValidity;
  }



  DisableDetailsRequired() {
    this.signupForm.get('firstName')?.clearValidators();
    this.signupForm.get('firstName')?.updateValueAndValidity;
    this.signupForm.get('lastName')?.clearValidators();
    this.signupForm.get('lastName')?.updateValueAndValidity;
    // this.signupForm.get('userName')?.clearValidators();
    // this.signupForm.get('userName')?.updateValueAndValidity;
    this.signupForm.get('userEmail')?.clearValidators();
    this.signupForm.get('userEmail')?.updateValueAndValidity;
    this.signupForm.get('dob')?.clearValidators();
    this.signupForm.get('dob')?.updateValueAndValidity;
    this.signupForm.get('gender')?.clearValidators();
    this.signupForm.get('gender')?.updateValueAndValidity;
    this.signupForm.get('streetAddress')?.clearValidators();
    this.signupForm.get('streetAddress')?.updateValueAndValidity;
    this.signupForm.get('pincode')?.clearValidators();
    this.signupForm.get('pincode')?.updateValueAndValidity;
    this.signupForm.get('passcode')?.clearValidators();
    this.signupForm.get('passcode')?.updateValueAndValidity;
    this.signupForm.get('confirmPassword')?.clearValidators();
    this.signupForm.get('confirmPassword')?.updateValueAndValidity;
  }
  isView: boolean = false;
  isNoView: boolean = true;
  showHidePassword()
  {
    this.showPassword = !this.showPassword;
    if(this.showPassword==true)
    {
      this.isView = true;
      this.isNoView = false;
    }
    else{
      this.isView = false;
      this.isNoView = true;
    }
  }
}
