import { Component, EventEmitter, OnInit, Output ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InsuranceService } from '../Services/insurance.service';
import swal from 'sweetalert2';
import { ApiResponse } from '../Models/ApiResponse';
import { RegistrationModel } from '../Models/vehicle-component.model';
import { BusinessService } from '../Services/business.service';
import { PincodeMaster } from '../Models/signUp.component.model';
import { LookUpService } from './../Services/lookup.service';
import {SignupService} from '../Services/signup.service';

//#region don't have bike number
import { VehicleInfoModel, LookUpViewModel, RTONamesViewModel, PinCodeMasterViewModel } from './../Models/vehicle-component.model';
import { VehicleService } from '../Services/vehicle.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../Login/login.component';
import { ReviewvehicledetailsComponent } from '../reviewvehicledetails/reviewvehicledetails.component';
import { LanguageLableService } from '../Services/language-lable.service';
import { GeneralService } from '../Services/general.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  insuranceForm: FormGroup;
  planList: any = [];
  vehicleInfoForm: FormGroup;
  vehicleDetailsForm: FormGroup;
  @ViewChild('mobileNumber') mobileNumber: any;
  @ViewChild('otp') oneTimePassCode: any;
  tabsForm: FormGroup;

  vehicleModel = new VehicleInfoModel();
  vehicleCompanyList: LookUpViewModel[] = [];
  vehicleModelList: LookUpViewModel[] = [];
  vehicleVariantList: LookUpViewModel[] = [];
  cityList: any[];//RTONamesViewModel[] = [];
  rtoNamesList: RTONamesViewModel[] = [];
  year = new Date().getFullYear();
  registrationYear: any = [];
  cityName: string = "";
  make: string = "";
  model: string = "";
  variant: string = "";
  knowBike: boolean = true;

  
 
  healthForm1: boolean = true;
  healthForm2: boolean = true;
  isToggle: boolean = true;
  selectedTab: number = 1;
  isViewQuotes: boolean = false;
  

  registrationData = new RegistrationModel();
  userReactForm: FormGroup;
  nomineeReactForm: FormGroup;
  display1 = 'none'
  display2 = 'none';
  display3 = 'none';
  userList: any = [];
  isIllness: boolean = false;
  isViewPlanList: boolean = false;
  modalRef: BsModalRef;
  isAccessToken: boolean = this.generalService.getAccessToken() != "" ? true : false;
  isProducts:boolean = false;
  currentTab:string = "";
  @Output() clickOnButton = new EventEmitter<any>();
  selectedValue: string | null;
  langList: any;

  // Health Insurance 
  healthinsurancepolicyindividual: boolean = false;
  healthinsurancepolicyfamily: boolean = false;
  
  isPopup:boolean=false;
  isPolicy:boolean=false;
  tabs:string='';
  isDisable:boolean=true;
  isDontKnow:boolean=true;
  isBought:boolean=true;
  type:string='';
  tab:string='';
  vehicle:string='';
  DontKnow:boolean=false;
  Bought:boolean=false;
  isVechicles:boolean=true;
    isDetail: boolean=false;
ListValue:string;
  VehicleTypte:string="";
  pcv:string[]=['Bus','Taxi/Cab','Auto Rikshaw','E-Rikshaw'];
gcv:string[]=['Truck','Bulker','Dumper','Pick Up Van','Tanker','Tipper','Tractor','Trailor','Auto','Courier Delivery Van','Garbage Carrier','Chicken Carrier','Goods Auto','Refrigerated Van'];
misc:string[]=['Construction Equipment','Agricultural Equipment','Agricultural Tractor','Garbage Van','Ambulance','Fire brigade'];
seats:string[]=['4seater-6 seater','7 seater-10 seater','11seater-18 seater','18 seater-24 seater'];
ExpiryPolicy:string[]=['Not Expired','Expired Within 90 Days',"I don't remember"];
PolicyType:string[]=['Standard/Comprehensive Coverage','Third-Party Liability Coverage',"I don't remember"];
GVWType:string[]=['0T-2T','2T-2.5T','2.5T-3.5T','3.5T','3.5T-12T','12T-20T','20T-40T and more than 20T'];
firstPage:boolean=true;
secondPage:boolean=false;
Polices:FormGroup;

  constructor(private languageLableService: LanguageLableService,private fb: FormBuilder, private insuranceservice: InsuranceService,
    private spinner: NgxSpinnerService, private vehicleService: VehicleService, private lookUpService :LookUpService,
    private businessService: BusinessService, private router: Router, private modalService: BsModalService, 
    private signupService :SignupService, private generalService:GeneralService) {
    this.insuranceForm = this.fb.group({
      registrationNumber: ['', Validators.required],
      VehicleDetails: this.fb.group({
        "city": [""],
        "rto": [""],
        "make": [""],
        "model": [""],
        "variant": [""],
        "registrationYear": [""],
        "registrationNumber": [""],
        "chasisNumber": [""],
        "engineNumber": [""],
        "registrationDate": [""]
      })
    });
    this.tabsForm = this.fb.group({
      "rto": ["", Validators.required],
      "make": ["", Validators.required],
      "modal": ["", Validators.required],
      "Variant": ["", Validators.required],
      "registration": ["", Validators.required],
      "city": ["", Validators.required],
    });

    

    this.loadBikeForm();
    this.loadQuotesModel();
    this.userForm();
    this.NomineeForm();
  }

  // buttonClicked(){
  //   this.clickOnButton.emit();
  // }
  userForm() {
    this.userReactForm = this.fb.group({
      "userName": [],
      "userEmail": [],
      "phoneNumber": [],
      "panCard": [],
      "pincodeId": [],
      "streetAddress": [],
    })

  }

  NomineeForm() {
    this.nomineeReactForm = this.fb.group({
      "nomineeName": [],
      "nomineeAge": [],
      "relationshipWithNominee": [],
      "gender": [],
      'user': []
    })

  }

  ngOnInit() {
    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    } 
   // this.spinner.hide();
    //this.BindData();
    //this.getUserDetails();
  }


  //#region View Quotes PopUp
  async getUserDetails() {

    const res = await this.insuranceservice.GetUserDetails();
    if (res != null && res.result) {
      this.userReactForm.patchValue(res.result);
    }

  }

  OwnerInfoDetails() {
    this.display1 = 'none';
    this.display2 = 'block';
  }

  NomineDetais() {
    this.display2 = 'none';
    this.display3 = 'block';
  }

  registrerDetails() {
    this.display3 = 'none';
    this.CreateQuoteConfirmation1(this.EnquiryId, this.InsuranceCompany, this.IsMockup);
  }

  async AcquireBusinessUsers() {
    const res = await this.businessService.AcquireBusinessUsers();
    if (res != null && res.result) {
      this.userList = res.result;
    }
  }

  changeUser(id: any) {
    let user = this.userList.filter((x: any) => x.uniqueUserId == id)[0];// uniqueUserId
    this.userReactForm.controls['userName'].setValue(user?.firstName);
    this.userReactForm.controls['userEmail'].setValue(user?.userEmail);
    this.userReactForm.controls['phoneNumber'].setValue(user?.phoneNumber);
    this.userReactForm.controls['pincodeId'].setValue(user?.pincode);
    this.userReactForm.controls['streetAddress'].setValue(user?.streetAddress);
  }


  //#endregion

  //  #region Don't know vehicle number 

  async cityChange(rtoName: any) {
    this.vehicleDetailsForm.value.vehicleDetails.city = rtoName;
    this.tabsForm.get('city')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.city);
    this.tabsForm.get('rto')?.setValue("");
    this.tabsForm.get('make')?.setValue("");
    this.tabsForm.get('modal')?.setValue("");
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.rtoNamesList = [];
    this.vehicleCompanyList = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.registrationYear = [];
    const res = await this.vehicleService.RTODetails(rtoName);
    if (res != null && res.result) {
      this.rtoNamesList = res.result;
    }
  }

  async rtoChange(record: any) {
    this.tabsForm.get('make')?.setValue("");
    this.tabsForm.get('modal')?.setValue("");
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.rto = record.regionCode;
    this.tabsForm.get('rto')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rto);
    await this.getVehicleCompanyDetails();
  }

  async getVehicleCompanyDetails() {
    this.vehicleCompanyList = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.registrationYear = [];
    let request = {};
    const res = await this.vehicleService.GetVehicleCompanyDetails(request);
    if (res != null && res.result) {
      this.vehicleCompanyList = res.result;
    }
  }

  async getVehicleModelDetails(record: any, vehicleCompanyId: any) {
    this.registrationYear = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.tabsForm.get('modal')?.setValue("");
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.make = record.name;
    this.tabsForm.get('make')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.make);
    let request = { Id: vehicleCompanyId };
    const res = await this.vehicleService.GetVehicleModelDetails(request);
    if (res != null && res.result) {
      this.vehicleModelList = res.result;
    }
  }

  async getVehicleVariantDetails(record: any, vehicleModelId: any) {
    this.vehicleVariantList = [];
    this.registrationYear = [];
    this.tabsForm.get('Variant')?.setValue("");
    this.tabsForm.get('registration')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.model = record.name;
    this.tabsForm.get('modal')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.model);
    let request = { id: vehicleModelId };
    const res = await this.vehicleService.GetVehicleVariantDetails(request);
    if (res != null && res.result) {
      this.vehicleVariantList = res.result;
    }
  }

  async getVehicleReg(record: any) {
    this.vehicleDetailsForm.value.vehicleDetails.variant = record.name;
    this.tabsForm.get('registration')?.setValue("");
    this.tabsForm.get('Variant')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.variant);
    this.registrationYear = [];
    this.getFinancialYears();
  }

  async changeYears(record: any) {
    this.vehicleDetailsForm.value.vehicleDetails.registrationYear = record.toString();
    this.tabsForm.get('registration')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.registrationYear);
  }

  //#endregion 

  cancel() {
    this.knowBike = true;
  }



  onCloseHandled() {
    this.display = "none";
  }
  display = "none";



  Confirm() {
    this.display = "none";
    this.viewQuotes()
    this.AcquireInsurancePlans1();
  }



  async AcquireInsurancePlans1() {
    try {
      this.spinner.show();
      this.planList = [];
      let response;

      this.insuranceForm.value.VehicleDetails.registrationNumber = this.registrationData.rc_number;
      this.insuranceForm.value.VehicleDetails.chasisNumber = this.registrationData.vehicle_chasi_number;
      this.insuranceForm.value.VehicleDetails.engineNumber = this.registrationData.vehicle_engine_number;
      this.insuranceForm.value.VehicleDetails.make = this.registrationData.maker_model;
      this.insuranceForm.value.registrationDate = this.registrationData.registration_date;

      this.registrationData.rc_number;
      if (this.generalService.getAccessToken() != "") {
        response = await this.insuranceservice.AcquireInsurancePlans(this.insuranceForm.value);
      }
      else {
        response = await this.insuranceservice.GetOpenQuotes(this.insuranceForm.value);
      }

      if (response?.isSuccess) {
        this.planList = response.result;
        this.spinner.hide();
      }


      if (this.planList.length == 0) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'No Quotes available.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinner.hide();
    }
  }

  EnquiryId: string = "";
  InsuranceCompany: number = 0;
  IsMockup: boolean = false;
  async CreateQuoteConfirmation(EnquiryId: string, InsuranceCompany: number, IsMockup: boolean = false) {
    if (this.generalService.getAccessToken() != "") {
      this.getUserDetails();
      this.AcquireBusinessUsers();
      this.display1 = 'block';
      this.EnquiryId = EnquiryId;
      this.InsuranceCompany = InsuranceCompany;
      this.IsMockup = IsMockup;
    }
    else {
      this.modalRef = this.modalService.show(LoginComponent);
      this.modalRef.content.onClose.subscribe((result: any) => {

      });
    }

  }

  async CreateQuoteConfirmation1(EnquiryId: string, InsuranceCompany: number, IsMockup: boolean = false) {
    swal.fire({
      title: 'Are you sure?',
      text: "You want to buy this policy.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.CreateQuote(EnquiryId, InsuranceCompany, IsMockup);
      }
    })
  }

  NonineeClose() {
    this.display2 = 'none';
  }

  OwnerClose() {
    this.display1 = 'none';
  }

  vehicleClose() {
    this.display3 = 'none';
  }


  async CreateQuote(EnquiryId: string, InsuranceCompany: number, IsMockup: boolean = false) {
    this.spinner.show();
    let request = {
      EnquiryId, InsuranceCompany, IsMockPolicy: IsMockup,
      ProposerDetails: {
        FirstName: this.userReactForm.value.userName,
        LastName: this.userReactForm.value.userName,
        Email: this.userReactForm.value.userEmail,
        PhoneNumber: this.userReactForm.value.phoneNumber,

      },

      VehicleDetails: {
        ChasisNumber: this.registrationData.vehicle_chasi_number,
        EngineNumber: this.registrationData.vehicle_engine_number,
        RegistrationNumber: this.registrationData.rc_number,
        Make: this.registrationData.maker_model
      },
      NomineeDetails: {
        nomineeName: this.nomineeReactForm.value.nomineeName,
        nomineeAge: this.nomineeReactForm.value.nomineeAge,
        relationshipWithNominee: this.nomineeReactForm.value.relationshipWithNominee,
        gender: this.nomineeReactForm.value.gender
      }


    };
    const response: any = await this.insuranceservice.CreateQuote(request);
    if (response?.isSuccess) {
      if (response.result.paymentLink != '' && !IsMockup) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: response?.result?.Message,
          showConfirmButton: false,
          timer: 3000,
        });

        window.open(response.result.paymentLink, "_self")
      }
      else {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: `Policy Created Successfully!`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.router.navigate(['policyDetails']);
      }



    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: response?.result?.Message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    this.spinner.hide();
  }

  onChangeRegistration(event: any) {

  }

  //#region  Don't have bike number
  async BindData() {

    await this.getFinancialYears();
    await this.getPolicyCityList();
  }

  getFinancialYears() {
    this.registrationYear = [];
    for (var i = (this.year - 19); i < (this.year + 1); i++) {
      this.registrationYear.push(i).orderb
    }
    if (this.registrationYear)
      this.registrationYear.sort(function (a: any, b: any) { return b - a })
  }

  async GetQuoteInfo() {
    if (this.vehicleDetailsForm.value) {
      this.spinner.show();
      this.planList = [];
      const response = await this.insuranceservice.AcquireInsurancePlans(this.vehicleDetailsForm.value);
      if (response?.isSuccess) {
        this.tabsForm.reset();
        this.vehicleDetailsForm.reset();
        this.rtoNamesList = [];
        this.vehicleCompanyList = [];
        this.vehicleModelList = [];
        this.vehicleVariantList = [];
        this.registrationYear = [];
        this.viewQuotes();
        response.result.logo = 'assets/images/Royal_Sundaram.jpg';
        this.planList = response.result;
        this.knowBike = true;
        this.spinner.hide();
      }
    }
  }



  async getPolicyCityList(cityName?: string) {
    this.cityList = [];
    this.rtoNamesList = [];
    this.vehicleInfoForm.controls['rto'].disable();
    if (cityName == undefined)
      cityName = "";
    const res = await this.vehicleService.GetPolicyCities(cityName);
    if (res != null && res.data) {
      this.cityList = res.data;

    }
  }

  async onBlurCity(cityName: string) {
    if (cityName.length > 2) {
      this.cityList = [];
      this.rtoNamesList = [];
      this.vehicleInfoForm.controls['rto'].disable();
      if (cityName == undefined)
        cityName = "";
      const res = await this.vehicleService.GetPolicyCities(cityName);
      if (res != null && res.data) {
        this.cityList = res.data;
      }

    }
    else if (!cityName)
      await this.getPolicyCityList(cityName)
  }


  loadBikeForm() {
    this.vehicleInfoForm = this.fb.group({
      "model": ['', [Validators.required]],
      "make": ['', [Validators.required]],
      "registrationYear": ['', [Validators.required]],
      "variant": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "rto": ['', [Validators.required]],

    });
  }

  loadQuotesModel() {
    this.vehicleDetailsForm = this.fb.group({
      "vehicleDetails": this.fb.group({
        "model": [''],
        "make": [''],
        "registrationYear": ['', ''],
        "variant": [''],
        "city": ['', ''],
        "rto": ['', ''],
      }),
      'insuranceCompany': [3]
    });
  }

  bikeNaviagtion() {
    this.knowBike = !this.knowBike;
    this.rtoNamesList = [];
    this.planList = [];
    this.getPolicyCityList("");
  }

  changeTab(event: any, tabNumber: any) {
    var tabs = document.getElementsByClassName("nav-item");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("active");
    }
    event.target.classList.add("active");

  }


  viewQuotes() {
    this.isViewQuotes = !this.isViewQuotes;
  }
  //#endregion

  otp(value: any) {
    // if (this.isOtp == false) {
     
    //   this.SendOtp();
    // }
    // else {
      if (value == 'home') {
        // this.homeForm1 = !this.homeForm1;
      }
      else if (value == 'health') {
       this.router.navigate(['HealthinsurancePolicy']);
       //this.ValidateOTP();
      }
    //}
  }
  randOTP :String = "";
  GenerateRandomNo()
  {   
    this.randOTP = "";
    let range = {min: 100000, max: 999999}
    let delta = range.max - range.min
    this.randOTP = Math.round(range.min + Math.random() * delta).toString();
  }

  async ValidateOTP() {
    let request = { OTP:  this.oneTimePassCode.nativeElement.value ,GeneratedOTP:this.randOTP}
    const response = await this.signupService.ValidateOTP(request);
    if (response?.code == 1) {
      this.router.navigate(['HealthinsurancePolicy']);
      // this.healthForm1 = !this.healthForm1;
      // this.isToggle = !this.isToggle;
    }
    else{
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Invalid OTP',
        showConfirmButton: false,
        timer: 3000,
      });
    }

  }

 async SendOtp(){
  this.GenerateRandomNo();
  let request = { phoneNumber: this.mobileNumber.nativeElement.value ,OTP :this.randOTP  }
    const response = await this.signupService.SendOtp(request);
    if (response?.code == 1) {
      // this.isMobile = !this.isMobile;
      // this.isOtp = !this.isOtp;
    }
    else{
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Phone number not exists',
        showConfirmButton: false,
        timer: 3000,
      });
  }
}

  viewIllness() {
    this.isIllness = !this.isIllness;
    this.healthForm1 = !this.healthForm1
  }

  viewPlanList() {
    this.isViewPlanList = !this.isViewPlanList;
  }

  activeMenu = 0;
  continue() {
    if (this.selectedTab < 5) {

      this.selectedTab = this.selectedTab + 1;
      console.log('enter')
      console.log(this.selectedTab)
    }

    if (this.activeMenu == 0) {
      this.isAge = true;
      this.isWhoInsureFilled = true;
    }
    else if (this.activeMenu == 1) {
      this.isLocation = true;
      this.isAgeFilled = true;
    }
    else if (this.activeMenu == 2) {
      this.isDetails = true;
      this.isLocationFilled = true;
    }
    this.activeMenu++;
  }

  back() {
    if (this.selectedTab > 1) {
      this.selectedTab = this.selectedTab - 1;
    }
  }

  isBike: boolean = true;
  isCar: boolean = false;
  isHealth: boolean = false;
  isHome: boolean = false;
  isLife: boolean = false;
  isTerm: boolean = false;
  isProperty:boolean=false;
  isTravel:boolean=false;
  goTo(value: string) {
    // this.isProducts = true;
    if (value == 'Bike') {
      this.isBike = true;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel=false;
    }
    else if (value == 'Car') {
      this.isBike = false;
      this.isCar = true;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel=false;
    }
    else if (value == 'Health') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = true;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel=false;
    }
    else if (value == 'Home') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = true;
      this.isLife = false;
      this.isTerm = false;
      this.isTravel=false;
    }
    else if (value == 'Life') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = true;
      this.isTerm = false;
      this.isTravel=false;
    }
    else if (value == 'Term') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = true;
      this.isTravel=false;
    }
    else if (value == 'Property') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isProperty=true;
      this.isTravel=false;
    }
    else if (value == 'Travel') {
      this.isBike = false;
      this.isCar = false;
      this.isHealth = false;
      this.isHome = false;
      this.isLife = false;
      this.isTerm = false;
      this.isProperty=false;
      this.isTravel=true;
    }
    this.currentTab = value;
    this.clickOnButton.emit(value);
  }

  isSelf: boolean = false;
  isSpouse: boolean = false;
  isSon: boolean = false;
  isDaughter: boolean = false;
  isFather: boolean = false;
  isMother: boolean = false;
  isOther: boolean = false;
  selectedCard(value: string) {
    if (value == 'Self') {
      this.isSelf = !this.isSelf;
    }
    else if (value == 'Spouse') {
      this.isSpouse = !this.isSpouse;
    }
    else if (value == 'Son') {
      this.isSon = !this.isSon;
    }
    else if (value == 'Daughter') {
      this.isDaughter = !this.isDaughter;
    }
    else if (value == 'Father') {
      this.isFather = !this.isFather;
    }
    else if (value == 'Mother') {
      this.isMother = !this.isMother;
    }
    else if (value == 'Other') {
      this.isOther = !this.isOther;
    }
  }

  isWhoInsure: boolean = true;
  isAge: boolean = false;
  isLocation: boolean = false;
  isDetails: boolean = false;
  isWhoInsureFilled: boolean = false;
  isAgeFilled: boolean = false;
  isLocationFilled: boolean = false;
  isDetailsFilled: boolean = false;
  selectedMenu(value: string) {
    if (value == 'Age') {
      this.isAge = true;
      this.isWhoInsureFilled = true;
    }
    else if (value == 'Location') {
      this.isLocation = true;
      this.isAgeFilled = true;
    }
    else if (value == 'Details') {
      this.isDetails = true;
      this.isLocationFilled = true;
    }
  }
  activeTab: number = 1;
  activeTabs:number = 5;
  activeTabs1:number = 1;
  
  insuranceTab(val:any) {
    if (val == 'individual') {
      this.healthinsurancepolicyindividual = true;
      this.activeTabs = 1;
    }
    if (val == 'family') {
      this.activeTabs = 2;
      this.healthinsurancepolicyfamily = true;
    }
  }
  
  pincodeMaster :PincodeMaster[]=[];
  async pincodeUserChangeEvent(pincode: any) {
    const res = await this.lookUpService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.pincodeMaster = res.result;
     // this.pincodeMasterId = this.pincodeMaster?.id;
    }
  }
  closeModal() {
    this.healthinsurancepolicyindividual = false;
    this.healthinsurancepolicyfamily = false;
  }
  counterValue : number = 0
  counterFunction(val:any) {
    if(val == 'decrement') {
      this.counterValue--;
    }
    if(val == 'increment') {
      this.counterValue++;
    }
  }
  nextPopUp()
  {
    this.isPolicy=true;
    this.isPopup=false
  }
  Policyclose()
  {
    this.isPolicy=false;
;
  }
  close()
  {
    this.isPopup=false;
  }
  DontKnowclose()
  {
    this.DontKnow=false;
  }
  Boughtclose()
  {
    this.Bought=false;
  }
  Types(ty:string)
{
  this.type=ty;
this.isDisable=false;
}
NewVehicle(Vname:string){
this.vehicle=Vname;
this.isBought=false;
}
DontHaveType(name:string)
{
  this.tabs=name;
  this.isDontKnow=false;
}
GotoCommercial()
{
 this.router.navigate(['/CommercialVechicles']);
}
GotoDontHave()
{
  this.router.navigate(['/DontKnowCommercialVechicle']);
}
GotoBought()
{
  this.router.navigate(['/BoughtNewCommercialVechicles']);
}
PCVtype(event: any) {
  this.ListValue = event.target.value;

}
changePage()
{
  this.firstPage=false;
  this.secondPage=true;
}
gotoVehicle(){
  this.router.navigate(['/CommercialVechicles']);
}
prType:string='';
permit(per:string)
{
this.prType=per;
}
selectedType:any;
PCVvalue:string='';
PVCType(event:any)
{
this.selectedType=event.target.value;

}

isPrtner():boolean{
  return this.generalService.getUserLabel() == "NL";
}

}