<div class="user-dashboard">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#009ef7"
               type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  <div class="row user-route bg-grey9">
    <div class="col-12 text-left">
      <h1>Your Policies</h1></div>
    <div class="col-12">
      <p class="text-grey4 font-15-12">Manage your insurance policies & renewals</p>
    </div>
    <div class="col-12 d-flex flex-column" *ngIf="(policyDetailsActiveList && policyDetailsActiveList.length == 0) && (policyDetailsInCompleteList && policyDetailsInCompleteList.length == 0) && (policyDetailsExpiredList && policyDetailsExpiredList.length == 0) && isResult == true">
      <img src="/assets/images/no_active_policy_found.png" class="align-self-center w-30">
    </div>
    <div class="col-7 pb-3 pt-3" *ngIf="(policyDetailsActiveList && policyDetailsActiveList.length > 0) || (policyDetailsInCompleteList && policyDetailsInCompleteList.length > 0) || (policyDetailsExpiredList && policyDetailsExpiredList.length > 0)">
      <div class="row">
        <div class="col-12">
          <ul class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center">
            <!-- <li class="list-item col-3" [ngClass]="{'activeList': isAll}" (click)="changeTab('all')">
              <a class="font-body active font-15-12" data-toggle="tab" href="#all">All</a>
            </li> -->
            <li class="list-item  col-4" [ngClass]="{'activeList': isActive}"   (click)="changeTab('active')">
              <a class="font-body active font-15-12" data-toggle="tab" href="#active">Active</a>
            </li>
            <li class="list-item  col-4" [ngClass]="{'activeList': isinactive}"   (click)="changeTab('Inactive')">
              <a class="font-body font-15-12" data-toggle="tab" href="#inactive">Inactive</a>
            </li>
            <li class="list-item  col-4" [ngClass]="{'activeList': isExpired}" (click)="changeTab('expired')">
              <a class="font-body font-15-12" data-toggle="tab" href="#expired">Expired</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content clearfix mb-5">
        <!-- <div id="all" class="tab-pane active col-12 c-pl c-pr">
          <div class="card-item card mt-4 row" *ngFor="let policyDetails of policyDetailsList" (click)="showPolicyDetails(policyDetails.policyValue.policyNumber)" [ngClass]="{'border-main' : selectedPolicy == policyDetails.policyValue.policyNumber}">
            <div class="row">
              <div class="col">
                <img src="{{policyDetails.policyValue.insuranceCompanyLogo}}" alt="unitedInsurance" class="partImg">
              </div>
              <div class="col">
                <p class="font-body font-15-12 mb-0">Plan</p>
                <p class="mb-0 text-main-secondary" *ngIf="policyDetails.insuranceCompany != 28">Vehicle Insurance</p>
                <p class="mb-0 text-main-secondary" *ngIf="policyDetails.insuranceCompany == 28">Health Insurance</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Expiry Date</p>
                <p class="mb-0 text-main-secondary">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Status</p>
                <p class="mb-0 text_green text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus === 1">Active</p>
                <p class="mb-0 text_red text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus != 1">Inactive</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Premium</p>
                <p class="mb-0 text-main-secondary">₹ {{policyDetails.policyValue.premium}} (Yearly)</p>
              </div>
              <div class="col-1 d-flex justify-content-center align-items-center">
                <i class="bi bi-chevron-right rightArrIcon"></i>
              </div>
            </div>
            <div class="row justify-content-start mt-3">
              
              <div class="col-8 d-flex align-items-center">
                <h4>{{policyDetails.policyValue.insuranceCompany}}</h4>
              </div>
            </div>
            <div class="sub-card mt-3">
              <div class="row">
                
              </div>
            </div>
          </div>
        </div> -->
        <div id="active" class="tab-pane active col-12 c-pl c-pr d-flex flex-column" *ngIf="isActive">
          <img src="/assets/images/no_active_policy_found.png" class="align-self-center w-50" *ngIf="policyDetailsActiveList && policyDetailsActiveList.length == 0">
          <div class="card-item card mt-4 row" *ngFor="let policyDetails of policyDetailsActiveList" (click)="showPolicyDetails(policyDetails.policyId,true)" [ngClass]="{'border-main' : selectedPolicy == policyDetails.policyId}">
            <!-- <div class="row">
              <div class="col">
                <img src="{{policyDetails.policyValue.insuranceCompanyLogo}}" alt="unitedInsurance" class="partImg">
              </div>
              <div class="col">
                <p class="font-body font-15-12 mb-0">Plan</p>
                <p class="mb-0 text-main-secondary">Vehicle Insurance</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Expiry Date</p>
                <p class="mb-0 text-main-secondary">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Status</p>
                <p class="mb-0 text_green text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus === 1">Active</p>
                <p class="mb-0 text_red text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus != 1">Inactive</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Premium</p>
                <p class="mb-0 text-main-secondary">₹ {{policyDetails.policyValue.premium}} (Yearly)</p>
              </div>
              <div class="col-1 d-flex justify-content-center align-items-center">
                <i class="bi bi-chevron-right rightArrIcon"></i>
              </div>
            </div> -->
            <div class="row">
              <div class="col-1">
                <img src="../../assets/svgIcon/policyvehicle/{{policyDetails.policyCategory}}.svg" class="img-fluid"/>
              </div>
              <div class="col-8">
                <p class="font-body font-15-12 mb-0">Plan Type <span class="text-grey5" *ngIf="policyDetails.policyCategory < 4">- {{policyDetails?.vehicleDetails?.registrationNumber}}</span></p>
                <p class="mb-0  text-main-secondary">{{policyDetails?.vehicleDetails?.typeOfCover}} ({{(policyDetails.policyCategory == 1 ? 'Four Wheeler' : policyDetails.policyCategory == 2 ? 'Two Wheeler' : policyDetails.policyCategory == 3 ? 'Comm Vehicle' : policyDetails.policyCategory == 4 ? 'Health Individual' : policyDetails.policyCategory == 5 ? 'Health Family' : policyDetails.policyCategory == 6 ? 'Travel' : policyDetails.policyCategory == 7 ? 'Home' :  policyDetails.policyCategory == 8 ? 'Office' : policyDetails.policyCategory == 9 ? 'Shop' : '')+ ' Insurance'}})</p>
              </div>
              <div class="col-3 d-flex flex justify-content-end align-items-center align-contnt-center flex-wrap text-main cursor-pointer font-15-12"> View Details
                <i class="bi bi-chevron-right rightArrIcon"></i>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/assets/images/insurance_companies/{{policyDetails.insuranceCompany}}.png" alt="unitedInsurance" class="partImg">
              </div>
              <div class="col-8 d-flex align-items-center h-left">
                <h4>{{policyDetails.policyValue.insuranceCompany}}</h4>
              </div>
            </div>
            <div class="row box list-box m-2" [align]="'left'">
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Expiry Date</p>
                <p class="mb-0 text-main-secondary">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Status</p>
                <p class="mb-0 text_green text-main-secondary">Active</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Premium</p>
                <p class="mb-0 text-main-secondary">₹ {{policyDetails.policyValue.premium > 0 ? policyDetails.policyValue.premium : policyDetails.vehicleDetails.premium }} (Yearly)</p>
              </div>
            </div>
          </div>
        </div>
        <div id="inactive" class="tab-pane col-12 c-pl c-pr d-flex flex-column" *ngIf="isinactive">
          <img src="/assets/images/no_active_policy_found.png" class="align-self-center w-50" *ngIf="policyDetailsInCompleteList && policyDetailsInCompleteList.length == 0">
          <div class="card-item card mt-4 row" *ngFor="let policyDetails of policyDetailsInCompleteList" (click)="showPolicyDetails(policyDetails.policyId,false)" [ngClass]="{'border-main' : selectedPolicy == policyDetails.policyId}">
            <div class="row">
              <div class="col-1">
                <img src="../../assets/svgIcon/policyvehicle/{{policyDetails.policyCategory}}.svg" class="img-fluid"/>
              </div>
              <div class="col-8">
                <p class="font-body font-15-12 mb-0">Plan Type <span class="text-grey5" *ngIf="policyDetails.policyCategory < 4">- {{policyDetails?.vehicleDetails?.registrationNumber}}</span></p>
                <p class="mb-0  text-main-secondary">{{policyDetails?.vehicleDetails?.typeOfCover}} ({{(policyDetails.policyCategory == 1 ? 'Four Wheeler' : policyDetails.policyCategory == 2 ? 'Two Wheeler' : policyDetails.policyCategory == 3 ? 'Comm Vehicle' : policyDetails.policyCategory == 4 ? 'Health Individual' : policyDetails.policyCategory == 5 ? 'Health Family' : policyDetails.policyCategory == 6 ? 'Travel' : policyDetails.policyCategory == 7 ? 'Home' :  policyDetails.policyCategory == 8 ? 'Office' : policyDetails.policyCategory == 9 ? 'Shop' : '')+ ' Insurance'}})</p>
              </div>
              <div class="col-3 d-flex flex justify-content-end align-items-center align-contnt-center flex-wrap text-main cursor-pointer font-15-12"> View Details
                <i class="bi bi-chevron-right rightArrIcon"></i>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img src="/assets/images/insurance_companies/{{policyDetails.insuranceCompany}}.png" alt="unitedInsurance" class="partImg">
              </div>
              <div class="col-8 d-flex align-items-center h-left">
                <h4>{{policyDetails.policyValue.insuranceCompany}}</h4>
              </div>
            </div>
            <div class="row box list-box m-2" [align]="'left'">
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Expiry Date</p>
                <p class="mb-0  text-main-secondary">NA</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Status</p>
                <p class="mb-0 text-main-secondary">{{policyDetails.policyValue.policyStatus === 7 ? 'Inspection Pending' : (policyDetails.policyValue.policyStatus === 6 ? 'Inspection Rejected' : (policyDetails.policyValue.policyStatus === 5 ? 'Inspection Approved' : (policyDetails.policyValue.policyStatus === 4 ? 'Payment Pending' : (policyDetails.policyValue.policyStatus === 3 ? 'KYC Completed' : (policyDetails.policyValue.policyStatus === 2 ? 'Payment Done' : 'In Complete')))))}}</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Premium</p>
                <p class="mb-0  text-main-secondary">₹ {{policyDetails.policyValue.premium > 0 ? policyDetails.policyValue.premium : policyDetails.vehicleDetails.premium}} (Yearly)</p>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col">
                <img src="{{policyDetails.policyValue.insuranceCompanyLogo}}" alt="unitedInsurance" class="partImg">
              </div>
              <div class="col">
                <p class="font-body font-15-12 mb-0">Plan</p>
                <p class="mb-0  text-main-secondary">Vehicle Insurance</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Expiry Date</p>
                <p class="mb-0  text-main-secondary">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Status</p>
                <p class="mb-0 text_green text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus === 1">Active</p>
                <p class="mb-0 text_red text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus != 1">Inactive</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Premium</p>
                <p class="mb-0  text-main-secondary">₹ {{policyDetails.policyValue.premium}} (Yearly)</p>
              </div>
              <div class="col-1 d-flex justify-content-center align-items-center">
                <i class="bi bi-chevron-right rightArrIcon"></i>
              </div>
            </div> -->
           
            
          </div>
        </div>
        <div id="expired" class="tab-pane col-12 c-pl c-pr d-flex flex-column" *ngIf="isExpired">
          <img src="/assets/images/no_active_policy_found.png" class="align-self-center w-50" *ngIf="policyDetailsExpiredList && policyDetailsExpiredList.length == 0">
          <div class="card-item card mt-4 row" *ngFor="let policyDetails of policyDetailsExpiredList" (click)="showPolicyDetails(policyDetails.policyId,true)" [ngClass]="{'border-main' : selectedPolicy == policyDetails.policyId}">
            <!-- <div class="row">
              <div class="col">
                <img src="{{policyDetails.policyValue.insuranceCompanyLogo}}" alt="unitedInsurance" class="partImg">
              </div>
              <div class="col">
                <p class="font-body font-15-12 mb-0">Plan</p>
                <p class="mb-0  text-main-secondary">Vehicle Insurance</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Expiry Date</p>
                <p class="mb-0  text-main-secondary">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Status</p>
                <p class="mb-0 text_green text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus === 1">Active</p>
                <p class="mb-0 text_red text-main-secondary" *ngIf="policyDetails.policyValue.policyStatus != 1">Inactive</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Premium</p>
                <p class="mb-0  text-main-secondary">₹ {{policyDetails.policyValue.premium}} (Yearly)</p>
              </div>
              <div class="col-1 d-flex justify-content-center align-items-center">
                <i class="bi bi-chevron-right rightArrIcon"></i>
              </div>
            </div> -->
            <div class="row mb-1">
              <div class="col-1">
                <img src="../../assets/svgIcon/policyvehicle/{{policyDetails.policyCategory}}.svg" class="img-fluid"/>
              </div>
              <div class="col-8">
                <p class="font-body font-15-12 mb-0">Plan Type <span class="text-grey5" *ngIf="policyDetails.policyCategory < 4">- {{policyDetails?.vehicleDetails?.registrationNumber}}</span> </p>
                <p class="mb-0  text-main-secondary">{{policyDetails?.vehicleDetails?.typeOfCover}} ({{(policyDetails.policyCategory == 1 ? 'Four Wheeler' : policyDetails.policyCategory == 2 ? 'Two Wheeler' : policyDetails.policyCategory == 3 ? 'Comm Vehicle' : policyDetails.policyCategory == 4 ? 'Health Individual' : policyDetails.policyCategory == 5 ? 'Health Family' : policyDetails.policyCategory == 6 ? 'Travel' : policyDetails.policyCategory == 7 ? 'Home' :  policyDetails.policyCategory == 8 ? 'Office' : policyDetails.policyCategory == 9 ? 'Shop' : '')+ ' Insurance'}})</p>
              </div>
              <div class="col-3 d-flex flex justify-content-end align-items-center align-contnt-center flex-wrap text-main cursor-pointer font-15-12"> View Details
                <i class="bi bi-chevron-right rightArrIcon"></i>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-2">
                <img src="/assets/images/insurance_companies/{{policyDetails.insuranceCompany}}.png" alt="unitedInsurance" class="partImg">
              </div>
              <div class="col-8 d-flex align-items-center">
                <h4>{{policyDetails.policyValue.insuranceCompany}}</h4>
              </div>
            </div>
            <div class="row box list-box m-2" [align]="'left'">
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Expiry Date</p>
                <p class="mb-0 text-main-secondary">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Status</p>
                <p class="mb-0 text_red text-main-secondary">Expired</p>
              </div>
              <div class="col">
                <p class="font-body text-grey4 font-15-12 mb-0">Premium</p>
                <p class="mb-0 text-main-secondary">₹ {{policyDetails.policyValue.premium > 0 ? policyDetails.policyValue.premium : policyDetails.vehicleDetails.premium}} (Yearly)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-5 pb-3 pt-3" *ngIf="(policyDetailsActiveList && policyDetailsActiveList.length > 0) || (policyDetailsInCompleteList && policyDetailsInCompleteList.length > 0) || (policyDetailsExpiredList && policyDetailsExpiredList.length > 0)">
      <div *ngIf="!isSelectPolicy" class="text-center">
        <img class="selectPolicy" src="../../assets/svgIcon/PersonalFiles.svg" alt="">
        <h3 class="text-grey4 ">Select a Policy & view details</h3>
      </div>
      <div *ngIf="isSelectPolicy">
        <div class="card-item card row">
          <div class="pt-3 d-block d-sm-block d-md-none row">
            <div class="col-12">
              <h3 class="text-main-secondary">Policy Details</h3>
            </div>
          </div>
          <div class="d-none d-sm-none d-md-block">
            <div class="row">
              <div class="col-2">
                <img img src="../../assets/svgIcon/policyvehicle/{{policyDetails.policyCategory}}.svg" class="img-fluid" class="partImg">
              </div>
              <div class="col-5">
                <img src="/assets/images/insurance_companies/{{policyDetails.insuranceCompany}}.png" alt="unitedInsurance" class="insImg">
              </div>
              <div class="col-5 align-self-center">
                <p class="text-grey4 text-grey4 font-15-12 mb-0">Plan</p>
                <p class="mb-0  text-main-secondary">{{policyDetails?.vehicleDetails?.typeOfCover}}</p>
                <p class="mb-0  text-main-secondary">{{(policyDetails.policyCategory == 1 ? 'Four Wheeler' : policyDetails.policyCategory == 2 ? 'Two Wheeler' : policyDetails.policyCategory == 3 ? 'Comm. Vehicle' : policyDetails.policyCategory == 4 ? 'Health' : policyDetails.policyCategory == 5 ? 'Life' : '')+ ' Insurance'}}</p>
                <p class="mb-0  text-main-secondary fw-bolder">₹ {{policyDetails.policyValue.premium > 0 ? policyDetails.policyValue.premium : policyDetails.vehicleDetails.premium }} (Yearly)</p>
              </div>
            </div>
          </div>  
          <hr>  
          <div class="row">
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Tenure</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.policyValue.policyTerm}} Year</p>
            </div>
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Policy Start Date</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.policyValue.startDate | date:'dd-MMM-yyyy'}}</p>
            </div>
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Policy End Date</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-4">
              <p class="text-grey4 mb-1 font-13">Status</p>
              <p class="text-main-secondary font-15-12">{{ policyDetails.policyValue.policyStatus === 1 && moment(policyDetails.policyValue.EndDate) < moment(today) ? 'Expired' : (policyDetails.policyValue.policyStatus === 1 && moment(policyDetails.policyValue.EndDate) > moment(today)) ? 'Active' : (policyDetails.policyValue.policyStatus === 7 ? 'Inspection Pending' : (policyDetails.policyValue.policyStatus === 6 ? 'Inspection Rejected' : (policyDetails.policyValue.policyStatus === 5 ? 'Inspection Approved' : (policyDetails.policyValue.policyStatus === 4 ? 'Payment Pending' : (policyDetails.policyValue.policyStatus === 3 ? 'KYC Completed' : (policyDetails.policyValue.policyStatus === 2 ? 'Payment Done' : 'In Complete'))))) ) }}</p>
            </div>
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Quote Reference Number</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.quoteId | uppercase}}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <p class="text-grey4 mb-1 font-13">Proposer</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.proposerDetails.firstName}} {{policyDetails.proposerDetails.lastName}}</p>
            </div>
            <div class="col-md-6">
              <p class="text-grey4 mb-1 font-13">Policy No.</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.policyNumber}} </p>
            </div>
          </div>
          <hr>
          <div class="row" *ngIf="policyDetails.policyCategory == 1 || policyDetails.policyCategory == 2 || policyDetails.policyCategory == 3">
            <div class="col">
              <!-- <p class="text-grey4 mb-1 font-13">{{(policyDetails.policyCategory == 1 ? 'Four Wheeler' : policyDetails.policyCategory == 2 ? 'Two Wheeler' : policyDetails.policyCategory == 3 ? 'Commercial' : '')+ ' Vehicle Number'}}</p> -->
              <p class="text-grey4 mb-1 font-13">Registration Number</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.vehicleDetails.registrationNumber}}</p>
            </div>
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Manufacture/Model</p>
              <p class="text-main-secondary font-15-12">{{policyDetails.vehicleDetails.make}}, {{policyDetails.vehicleDetails.model}}</p>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Coverage Amount</p>
              <p class="text-main-secondary font-15-12">NA</p>
            </div>
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Status</p>
              <p class="text-main-secondary font-15-12">{{ policyDetails.policyValue.policyStatus === 1 && moment(policyDetails.policyValue.EndDate) < moment(today) ? 'Expired' : (policyDetails.policyValue.policyStatus === 1 && moment(policyDetails.policyValue.EndDate) > moment(today)) ? 'Active' : 'In Complete' }}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Your Premium</p>
              <p class="text-main-secondary font-15-12">₹ {{policyDetails.policyValue.premium > 0 ? policyDetails.policyValue.premium : policyDetails.vehicleDetails.premium }} (Yearly)</p>
            </div>
            <div class="col">
              <p class="text-grey4 mb-1 font-13">Payment Method</p>
              <p class="text-main-secondary font-15-12">NA</p>
            </div>
          </div> -->
          <!-- <div class="d-flex justify-content-start mt-4 mb-4 d-none d-sm-none d-md-block">
            <div class="col-4">
              <img src="{{policyDetails.policyValue.insuranceCompanyLogo}}" alt="unitedInsurance" class="partImg">
            </div>
            <div class="col-8 d-flex align-items-center">
              <h4>{{policyDetails.policyValue.insuranceCompany}}</h4>
            </div>
          </div> -->
          <!-- <div class="row">
            <div class="col-md-6">
              <p class="text-grey4 mb-1 font-15-12">Plan Term</p>
              <p class="text-main-secondary font-15-12 fw-bolder">1 {{policyDetails.policyValue.planTerm}} Year</p>
            </div>
            <div class="col-md-6">
              <p class="text-grey4 mb-1 font-15-12">Expiry Date</p>
              <p class="text-main-secondary font-15-12 fw-bolder"> {{policyDetails.policyValue.policyStatus == 0 ? 'NA' :  policyDetails.policyValue.endDate | date:'dd-MMM-yyyy'}}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <p class="text-grey4 mb-1 font-15-12">Your Premium</p>
              <p class="text-main-secondary font-15-12 fw-bolder">₹ {{policyDetails.policyValue.premium}} (Yearly)</p>
            </div>
            <div class="col-md-6">
              <p class="text-grey4 mb-1 font-15-12">Status</p>
              <p class="text-main-secondary font-15-12 fw-bolder">
                {{setStatus( policyDetails.policyValue.policyStatus, policyDetails.policyValue.EndDate)  }}
              </p>
            </div>
          </div>
          <hr> -->
          
        </div>
        <div class="card-item mt-3 row pt-0" *ngIf="showDownloadButton == true || policyDetails.policyValue.policyStatus == 5 || policyDetails.policyValue.policyStatus == 7 || policyDetails.policyValue.policyStatus == 4">
          <div class="row d-flex pt-2 pb-5 pl-2 pr-2 justify-content-center">
            <div class="col-6 col-md-4 pt-3">            
              <button class="btn btn-outline btn-outline-primary text-main border-main" type="button"  *ngIf="showDownloadButton == true" (click)="onClick()">Download PDF</button>
              <button class="btn btn-outline btn-outline-primary text-main border-main" type="button"  *ngIf="policyDetails.policyValue.policyStatus == 4" (click)="makePaymen()">Make Payment</button>
              <button class="btn btn-outline btn-outline-primary text-main border-main" type="button"  *ngIf="policyDetails.policyValue.policyStatus == 5" (click)="continueJourney()">Continue Buy Policy</button>
              <button class="btn btn-outline btn-outline-primary text-main border-main" type="button"  *ngIf="policyDetails.policyValue.policyStatus == 7" (click)="checkStatus()">Check Inspection Status</button>
            </div>
            <!-- <div class="col-6 col-md-4 pt-3">
              <a href="#" class="btn btn-outline btn-outline-dark w-100 text-main-secondary">Get Help</a>
            </div>
            <div class="col-6 col-md-4 pt-3">
              <a href="#" class="btn btn-outline btn-outline-dark w-100 text-main-secondary">Share</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>&nbsp;&nbsp;&nbsp;
  </div>
  <!-- <div class="bottom-section d-flex row">
    <div class="col-8">
      <ul class="d-flex">
        <a><h6>Home|</h6></a>&nbsp;
        <a><h6>About US |</h6></a>&nbsp;
        <a><h6>Career |</h6></a>&nbsp;
        <a><h6>Legal & Admin Policies |</h6></a>&nbsp;
        <a><h6>Insurance Companies |</h6></a>&nbsp;
        <a><h6>Security  |</h6></a>&nbsp;
        <a><h6>Contact Us |</h6></a>
      </ul>
    </div>
    <div class="col-4">
      <div class="row">
        <div class="col-7">
          <a href="#"><p>Handcrafted & Proudly Made In</p></a>
        </div>
        <div class="col-5">
          <img src="../../assets/images/india-flag.svg">
        </div>
      </div>
    </div>
    <hr>
  </div>
  <div>
    <p class="text-center text-white">© COPYRIGHT@2023 Click4bima. ALL RIGHT RESERVED</p>
  </div> -->
</div>
<!-- <app-footer></app-footer> -->
<script>
  function changeTab(event, tabNumber) {
    var tabs = document.getElementsByClassName("list-item");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].classList.remove("active");
    }
    event.target.classList.add("active");
  }
</script>

