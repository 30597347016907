import { Component } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {

  settingData : any=[
  {
   label1:"Policy Updates",
   label2:"Policy Updates: Important updates for your purchased policies including payment, issuance, claims and renewal reminders",
   idPrefix:"pol_updt",
  },
  {
    label1:"Pre Purchase Updates",
    label2:"Information on products you have shown an interest in including Quotes, Brochures, Illustrations",
    idPrefix:"pre_pur_uptd",
   },
   {
    label1:"News & Offers",
    label2:"News, promos, and events for you",
    idPrefix:"new_offr",
   }
  ];
}
