<!DOCTYPE html>
<html lang="en">
<!--begin::Head-->
<head>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <!--begin::Fonts(mandatory for all pages)-->
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700" />
  <!--end::Fonts-->
</head>
<!--end::Head-->
<!--begin::Body-->
<body id="kt_body" class="app-blank bgi-size-cover bgi-position-center bgi-no-repeat">
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root" id="kt_app_root">
    <!--begin::Authentication - Password reset -->
    <div class="d-flex flex-column flex-column-fluid flex-lg-row">
      <!--begin::Aside-->
      <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
      </div>
      <!--begin::Aside-->
      <!--begin::Body-->
      <div class="d-flex flex-center w-lg-50 p-10">
        <!--begin::Card-->
        <div class="card rounded-3 w-md-550px">
          <!--begin::Card body-->
          <div class="card-body d-flex flex-column p-10 p-lg-20 pb-lg-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-center flex-column-fluid pb-15 pb-lg-20">
              <!--begin::Form-->
              <form class="form w-100" [formGroup]="resetpasswordForm" novalidate="novalidate" id="kt_password_reset_form">
                <!--begin::Heading-->
                <div class="text-center mb-10">
                  <!--begin::Title-->
                  <h1 class="text-dark fw-bolder mb-3">Forgot Password ?</h1>
                  <!--end::Title-->
                  <!--begin::Link-->
                  <div class="text-gray-500 fw-semibold fs-6">Enter your phonenumber to reset your password.</div>
                  <!--end::Link-->
                </div>
                <!--begin::Heading-->
                <!--begin::Input group=-->
                <div class="fv-row mb-8">
                  <!--begin::Email-->
                  <ngx-intl-tel-input class="bg-transparent"
                                      [enableAutoCountrySelect]="true"
                                      [enablePlaceholder]="true"
                                      [searchCountryFlag]="false"
                                      [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                      [selectFirstCountry]="false"
                                      [selectedCountryISO]="CountryISO.India"
                                      [phoneValidation]="true"
                                      [separateDialCode]="separateDialCode"
                                      name="phone"
                                      formControlName="PhoneNumber"
                                      [inputId]="'phone_number'"
                                      (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

                  </ngx-intl-tel-input>
                  <!--end::Email-->
                </div>
                <!--begin::Actions-->
                <div class="d-flex flex-wrap justify-content-center pb-lg-0">
                  <button type="button" id="kt_password_reset_submit" class="btn btn-primary me-4" (click)="resetPassword()" [disabled]="resetpasswordForm.invalid">
                    <!--begin::Indicator label-->
                    <span class="indicator-label">Submit</span>
                    <!--end::Indicator label-->
                  </button>
                  <a href="/login" class="btn btn-light">Cancel</a>
                </div>
                <!--end::Actions-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </div>
      <!--end::Body-->
    </div>
    <!--end::Authentication - Password reset-->
  </div>
  <!--end::Root-->
</body>
<!--end::Body-->
</html>
