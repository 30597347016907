<div class="container">
  <div class="bg-transparent" [ngClass]="!isUserLogged ? '' : 'mt-7 user-dashboard'"
    *ngIf="!IsQuotes && !IsQuoteSelected && !IsProposer && !IsPolicyDetail && !IsDontKnowBike && !isPaymentLink">

    <div class="row bike_insurance_div">
      <div class="col-lg-5 col-md-5 col-sm-5 col-xl-5 col-12">
        <div class="insurance_img" *ngIf="carNum">
          <img src="../../assets/images/Car.png" alt="">
        </div>

      </div>
      <div class="col-12 col-md-7 col-lg-7 col-xl-7 align-self-center">
        <form [formGroup]="insuranceForm" class="needs-validation" novalidate="" autocomplete="off">
          <div *ngIf="carNum" class="px-5">
            <h2 class="heading text-left">{{langList.car_ins_title}}</h2>
            <h5 class="text-left text-grey4 fw-medium mt-3">{{langList.car_ins_start}}</h5>
            <br>
            <div class="d-flex">
              <input type="text" placeholder="{{langList.enter_car_number}}" name="Registration"
                formControlName="registrationNumber" [(ngModel)]="vehicleRegNumber"
                oninput="this.value = this.value.toUpperCase()" 
                (keyup)="formatRegNo($event)" 
                (input)="checkRegistrationNumber($event)"
                (paste)="checkRegistrationNumber($event)"
                class="form-control carIcon input-control" pattern="^(\d{2}BH\d{4}[A-Z]{0,2})|([A-Z]{2}\d{1,2}[A-Z]{1,2}\d{3,4})$">
              <button class="btn btn-primary inputButton" [disabled]="onVehicleRegNumberInput() || !vehicleRegNumber"
                (click)="carNumber()">
                {{langList.verify}}
              </button>
            </div>
            <div class="errorMsg" *ngIf="this.insuranceForm.get('registrationNumber')?.dirty && onVehicleRegNumberInput()">Please enter Valid Vehicle Number.</div>
          </div>
          <!-- <div *ngIf="chosenOption.length">
          <div class="row mb-5">
              <div class="color-change" [ngClass
              ]="'card col-12'" *ngFor="let obj of chosenOption; let i = index;" 
              class="color-change hover text-left">
              <div class="row d-flex align-items-center">
                <div class="col-10 pt-2 pb-2">
                  <span class="text-grey4 font-12-10">{{ obj.label }}</span>
                  <h5 class="mb-0 font-14-11">{{ (obj.order < 3 ? obj.value : (obj.value == "Yes" ? obj.value :( obj.value + ": " + obj.value1)))}}</h5>
                </div>
                <div class="col-2 text-right">
                  <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="Edit" class="icon_width_height_24_18" (click)="editSelectedOption(obj.order)">
                </div>
              </div> 
              </div>                
          </div>    
        </div> -->

          <div class="policy_div px-5 d-flex justify-content-between align-items-center mt-2">
            <a class="font-body float-start text-main font-15-12"
              (click)="novalidpolicy()">{{langList.dont_have_policy}}
              <!-- <span class="tooltip">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <span class="tooltiptext">Keep your RC copy ready with you for the basic information.</span>
              
             </span> -->
            </a>
            <a class="font-body float-end text-main font-15-12"
              (click)="dontknowbikenumber('1')">{{langList.bought_new_car}} </a>
          </div>
        </form>
      </div>
    </div>


  </div>
  <div class="overlay" *ngIf="donthavepolicyDetails">
    <div class="popup2 col-md-4 col-xs-12 col-sm-8 col-11">
      <div class="modal-header tooltip_">
        <span>Don't Remember Vehicle Number?</span>
        <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 mt-2">
            <span>If you don't remember your four wheeler number, keep your RC (Registration Certificate) ready with you
              to access the basic details of your four-wheeler.</span>
          </div>
          <div class="col-md-12 mt-2 text-end">
            <button type="button" class="btn btn-primary mt-2 "
              (click)="dontknowbikenumber('2')">{{langList.continue}}</button>

          </div>

          <!-- <button type="button" class="btn btn-secondary cancel_btn mt-2" (click)="closeModal()" style="margin-right: 10px;">Cancel</button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="overlay verifybikedetailsdiv" *ngIf="verifyBikeDetails">
    <div class="popup2 col-md-5 col-11">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">
          Your Vehicle Details
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <form [formGroup]="vehicleForm" class="needs-validation policy_form" novalidate="" autocomplete="off">
            <div class="" *ngIf="policy">
              <div class="row">
                <div class="col-lg-6 col-md-6 mt-5">
                  <div class="popupdivblock">
                    <div class="d-flex">
                      <img src="../../assets/svgIcon/license-plate.svg" alt="CalendarBlank"
                        class="icon-grey4">&nbsp;&nbsp;
                      <div class="w-100">
                        <p class="text-start text-grey4 h-4px">Registration Number</p>
                        <input type="text" name="RegistrationNumber" formControlName="registrationNumber"
                          class="form-control" readonly="true">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 mt-5">
                  <div class="popupdivblock">
                    <div class="d-flex">
                      <img src="../../assets/svgIcon/calendar.svg" alt="CalendarBlank" class="icon-grey4">&nbsp;&nbsp;
                      <div class="w-100">
                        <p class="text-start text-grey4 h-4px required">Registration Date</p>
                        <input type="date" name="registrationDate" formControlName="registrationDate"
                          class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 mt-5">
                  <div class="popupdivblock">
                    <div class="d-flex">
                      <img src="../../assets/svgIcon/manufacture.svg" alt="city" class="icon-grey4">&nbsp;&nbsp;
                      <div class="w-100">
                        <p class="text-start text-grey4 h-4px required">Manufacturer</p>
                        <!-- <input type="text" name="make" formControlName="make" placeholder="" class="form-control"> -->
                        <app-autocomplete class="w-100" (itemSelected)="onMakeSelection($event)"
                          [items]="vehicleMakeList" placeholder="Make" (onBlurEvent)="onMakeBlur($event)"
                          [userInput]="vehicleFormControl['make'].value"
                          [showError]="vehicleFormControl['make'].invalid && isFormSubmitted"></app-autocomplete>
                        <span class="text-danger"
                          *ngIf="vehicleFormControl['make'].invalid && isFormSubmitted">Manufacturer is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 mt-5">
                  <div class="popupdivblock">
                    <div class="d-flex">
                      <img src="../../assets/svgIcon/model.svg" alt="city" class="icon-grey4">&nbsp;&nbsp;
                      <div class="w-100">
                        <p class="text-start text-grey4 h-4px required">Model</p>
                        <!-- <input type="text" name="model" formControlName="model" placeholder="" class="form-control"> -->
                        <app-autocomplete class="w-100" (itemSelected)="onModelSelection($event)"
                          [items]="vehicleModelList" placeholder="Model" (onBlurEvent)="onModelBlur($event)"
                          [userInput]="vehicleFormControl['model'].value"
                          [showError]="vehicleFormControl['model'].invalid && isFormSubmitted"></app-autocomplete>
                        <span class="text-danger" *ngIf="vehicleFormControl['model'].invalid && isFormSubmitted">Model
                          is required.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 mt-5">
                  <div class="popupdivblock">
                    <div class="d-flex">
                      <img src="../../assets/svgIcon/fuel.svg" alt="city" class="icon-grey4">&nbsp;&nbsp;
                      <div class="w-100">
                        <p class="text-start text-grey4 h-10px required">Fuel Type</p>
                        <select class="form-select form-select-custom" formControlName="fuelType">
                          <option *ngFor="let item of fuelType; let i = index;" value="{{item.name}}">{{item.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 mt-5">
                  <div class="popupdivblock">
                    <div class="d-flex">
                      <img src="../../assets/svgIcon/piston.svg" alt="city" class="icon-grey4">&nbsp;&nbsp;
                      <div class="w-100">
                        <p class="text-start text-grey4 h-10px required">Variant</p>
                        <!-- <select class="form-select form-select-custom" formControlName="variant" >
                        <option *ngFor="let item of vehicleVariantList; let i = index;" value="{{item}}">{{item}}</option>
                      </select> -->
                        <app-autocomplete class="w-100" (itemSelected)="onVariantSelection($event)"
                          [items]="vehicleVariantList" placeholder="Variant"
                          (onBlurEvent)="onVariantBlur($event)"></app-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 mt-5">
                  <div class="popupdivblock">
                    <div class="d-flex">
                      <img src="../../assets/svgIcon/location.svg" alt="city" class="icon-grey4 w-28">&nbsp;&nbsp;
                      <div class="w-100">
                        <p class="text-start text-grey4 h-10px required">Pincode</p>
                        <app-autocomplete class="w-100" (itemSelected)="onPincodeSelection($event)"
                          [supportingText]="true" (onBlurEvent)="onPincodeBlur($event)" [items]="pincodeList"
                          placeholder="pincode" (onKeyUpEvent)="onPincodeChange($event)" (input)="onInputChange($event)"
                          [showError]="vehicleFormControl['pincode'].invalid && isFormSubmitted"></app-autocomplete>
                        <span class="text-main">Note: Please provide pincode for more accurate quotations</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 mt-5">
                  <div class="button_divs">
                    <button class="con_btn" (click)="continuePolicyDetails()">{{langList.continue}}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="overlay verifybikedetailsdiv verifybikedetailsdiv1" *ngIf="verifyBikeDetailspolicy">
    <div class="popup2 col-md-8  col-xs-12 col-sm-8 col-11" style=" overflow-x: unset;">
      <div class="modal-header tooltip_">
        <h4 class="modal-title">
          <h4 class="">Existing Policy Details
          </h4>
        </h4>
        <button type="button" aria-label="Close" class="close pull-right" (click)="closeModal()"><span aria-hidden="true" ></span></button>
      </div>
      <div class="modal-body heigth p-5 ">
        <div class="row ">
          <form [formGroup]="insuranceForm" class="needs-validation" novalidate="" autocomplete="off">
            <div class="row">
              <ng-container *ngIf="policy">
                <div class="col-md-6 mt-4">
                  <label class="required text-start text-grey4">Policy's Expiry Status</label><img
                    src="/assets/images/Vector4.png" alt="" (click)="openPolicyStatusModal()"
                    class="cursor-pointer ml-2">
                  <select class="form-select" formControlName="policyExpiryStatus" [(ngModel)]="policyExpiryStatus"
                    (change)="policyDetail($event)">
                    <option *ngFor="let item of policyExpiryStatusList; let i = index;" value="{{item.value}}">
                      {{item.text}}</option>
                  </select>
                </div>
                <ng-container *ngIf="this.insuranceForm.get('policyExpiryStatus')?.value=='1' || this.insuranceForm.get('policyExpiryStatus')?.value=='2'">
                  <div class="col-md-6 mt-4" *ngIf="showPreviousPolicyCompany">
                    <label class="required text-start text-grey4">Existing Policy Company</label>
                    <app-autocomplete class="w-100" [items]="insurancePartnersList"
                      placeholder="Type and search existing policy company" (itemSelected)="onPrevCompSelection($event)"
                      (onBlurEvent)="onPrevCompBlur($event)" [userInput]="previousPolicyCompany"
                      [className]="'py-6_5 shadow_'" [showError]="showPreviousPolicyCompanyError"></app-autocomplete>
                    <span class="text-danger" *ngIf="showPreviousPolicyCompanyError">Existing policy company is
                      required.</span>
                  </div>
                  <div class="col-md-6 mt-4">
                    <label class="required text-start text-grey4">Existing Policy Expiry Date</label>
                    <input type="date" name="previousPolicyExpiryDate" formControlName="previousPolicyExpiryDate"
                      class="form-control py-6_5 shadow_" [max]="maxPolicyExpiryDate" [min]="minPolicyExpiryDate"
                      onkeydown="return false" (change)="onPreviousPolicyExpiryDateChange()">
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="Type" >
                <div class="col-md-6 mt-4">
                  <label class="required text-start text-grey4">Policy Type</label>
                  <select class="form-select" formControlName="policyType" [(ngModel)]="policyType"
                    (change)="coverageDetail($event)">
                    <option *ngFor="let item of policyTypeList; let i = index;" value="{{item.value}}">{{item.text}}
                    </option>
                  </select>
                </div>
                <ng-container *ngIf="this.insuranceForm.get('policyType')?.value != '' && this.insuranceForm.get('policyType')?.value != 'Thirdparty'">
                  <div class="col-md-6 mt-4">
                    <label class="text-start required text-grey4">Did you make a claim in your existing policy?</label>
                    <select class="form-select" formControlName="policyClaimed" [(ngModel)]="policyClaimed"
                      (change)="preClaimDetail($event)">
                      <option *ngFor="let item of policyClaimedList; let i = index;" value="{{item.value}}">
                        {{item.text}}</option>
                    </select>
                  </div>
                  <ng-container *ngIf="claimPercentage || (vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes' && vehicleFormControl['isNCBProtectionOptedInExistingPolicy'].value == 'Yes')">
                    <div class="col-md-6 mt-4">
                      <label class="text-start required text-grey4">Existing NCB (No Claim Bonus) <img
                          src="/assets/images/Vector4.png" alt="" (click)="openNCBModal()"
                          class="cursor-pointer"></label>
                      <select class="form-select" formControlName="policyClaimedNCB" [(ngModel)]="policyClaimedNCB"
                        (change)="nsbValDetail($event)">
                        <option *ngFor="let item of policyNcbClaimedList; let i = index;" value="{{item.value}}">
                          {{item.text}}</option>
                      </select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <div class="col-md-6 mt-4">
                    <label class="text-start required text-grey4">Number of claim(s) made in existing policy</label>
                    <select class="form-select" formControlName="claimsMadeInPreviousPolicyNo" [(ngModel)]="claimsMadeInPreviousPolicyNo"
                      (change)="claimCountChanged($event)">
                      <option *ngFor="let item of policyNcbClaimMadeList; let i = index;" value="{{item.value}}">
                      {{item.text}}</option>
                    </select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <div class="col-md-6 mt-4">
                      <label class="text-start required text-grey4">Claimed Amount</label>
                      <span class="input-icon">
                        <input type="text" class="form-control py-6_5 shadow_" formControlName="claimsMadeInPreviousPolicyAmount" (keypress)="numberOnly($event)" (keyup)="onClaimedAmountCahnge()">
                        <i>₹</i> 
                      </span>  
                    </div>
                  </ng-container>
                  <ng-container *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                    <div class="col-md-6 mt-4">
                      <label class="text-start required text-grey4">NCB protection opted in existing policy</label>
                      <select class="form-select" formControlName="isNCBProtectionOptedInExistingPolicy" (change)="onNCBProtectionOptedInExistingPolicy()">
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

            </div>
          </form>
        </div>

      </div>
      <div class="row btn-mrg">
        <div>
          <button class="con_btn mr-3 mb-3" (click)="VerifyRegistrationNumber()" [disabled]="isVehicleFormInvalid()">
            {{isDontKnowVehicleNumber ? langList.continue : 'View Quotes'}}
          </button>
        </div>

      </div>
    </div>
  </div>


  <div [ngClass]="{'d-none': !IsQuotes}">
    <vehiclequotes (dataEvent)="ShowQuoteDetials($event)" (gotoEvent)="goTo($event)"></vehiclequotes>
  </div>
  <div [ngClass]="{'d-none': !IsQuoteSelected}">
    <vehicle-quote-details (dataEvent)="ShowOwnerDetailsPage($event)"></vehicle-quote-details>
  </div>
  <div [ngClass]="{'d-none': !IsProposer}">
    <vehicle-owner-details [ownerDetails]="this.vehicleForm.value" (dataEvent)="ShowPolicyDetailsPage($event)"
      (gotoEvent)="goTo($event)"></vehicle-owner-details>
  </div>
  <div [ngClass]="{'d-none': !IsPolicyDetail}">
    <vehicle-policy-details (dataEvent)="CreatePolicy()"></vehicle-policy-details>
  </div>

  <div [ngClass]="!isUserLogged ? '' : 'user-dashboard'" *ngIf="IsDontKnowBike">
    <app-dont-know-bike-number [isBike]="false" [newVehicle]="newVehicle"
      (dataEvent)="DontKnowBike($event)"></app-dont-know-bike-number>
  </div>

  <div *ngIf="isRoyalSundaram">
    <app-royal-sundaram-payment [royalDetails]="royalDetails"></app-royal-sundaram-payment>
  </div>
  <div *ngIf="isFutureGeneral">
    <app-future-general-payment [futureDetails]="futureDetails"></app-future-general-payment>
  </div>

  <div *ngIf="isShriramGeneral">
    <app-shriramgeneral-payment [ShriramDetails]="ShriramDetails"></app-shriramgeneral-payment>
  </div>

  <div *ngIf="isRelianceGeneral">
    <app-reliance-general-payment [RelianceDetails]="RelianceDetails"></app-reliance-general-payment>
  </div>

  <div *ngIf="isHDFC">
    <app-hdfcergo-payment [HDFCDetails]="HDFCDetails"></app-hdfcergo-payment>
  </div>
  <div *ngIf="isPaymentLink">
    <app-payment-progress [paymentObject]="paymentObject"></app-payment-progress>
  </div>
  <div class="overlay" *ngIf="showNCBTooltip">
    <div class="popup2 position-relative col-md-4 col-11">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>No Claim Bonus (NCB) </h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeNCBModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <p class="text-grey4">No Claim Bonus is a discount offered to policyholders on their insurance premiums when
            they haven't made any claims during the policy term. The longer you go without a claim, the higher your NCB,
            saving you money on your insurance costs.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showPolicyStatusTooltip">
    <div class="popup2 position-relative col-md-4 col-11">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Policy Expiry Status</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closePolicyStatusModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <span class="font-17-13 text-grey5 d-block mt-2">Not Expired</span>
          <span class="font-15-12 text-grey4">Hooray! you will get your policy instantly</span>
          <span class="font-17-13 text-grey5 d-block mt-2">Expired Within 90 Days</span>
          <span class="font-15-12 text-grey4">Pre-inspection required</span>
          <span class="font-17-13 text-grey5 d-block mt-2">Expired More than 91 Days</span>
          <span class="font-15-12 text-grey4">Pre-inspection required, NCB discound not applicable</span>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">
        <iframe id="ifr2" [src]="kycUrl" target="_parent" frameborder='0' class="iframeclass"></iframe>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showhttpKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closehttpKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">

        <span class="font-17-13 text-grey5 d-block mt-2">Please use below link to update your KYC details</span>
        <br />
        <a id="failedKYCLink" target="_blank" (click)="closehttpKYCModal()" [href]="kycUrl">Click here to update your
          KYC details</a>
        <br />
        <span class="font-17-13 text-grey5 d-block mt-2">Once your KYC is done, return to this window and click on the
          pay button</span>

      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showIsBreakIn">
    <div class="popup2 position-relative col-md-4 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Vehicle inspection required</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative text-center">
        <span class="font-17-13 text-grey5 d-block mt-2">You will need to upload a short video of your vehicle. It takes only a few minutes. Do you want to Continue?</span>
        <div class="row mt-8 justify-content-around">
            <button type="button" class="btn btn-primary col-auto" aria-label="Close" (click)="CreatePolicy()">
                Yes Continue
              </button>
              <button type="button" class="btn btn-secondary col-auto" aria-label="Close" (click)="closeBreakInModal()">
                No
              </button>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showIsBreakInTriggered">
    <div class="popup2 position-relative col-md-8 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Inspection Request Submitted</h4>
        </div>
        <!-- <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button> -->
      </div>
      <div class="modal-body position-relative">
        <div class="row">
            <div class="col-md-4 d-flex align-item-center">
                <img src="../../../assets/svgIcon/list_clipboard.svg" class="img-fluid">
            </div>
            <div class="col-md-8 d-flex align-item-center">
                <div class="row">
                    <div class="col-md-12 text-main font-15-12">
                        Please complete the inspection process by clicking on the link  you received in your phone.
                        After completing this process, it will take approximate 48 hours to verify your vehicle’s inspection details.
                        Please use {{this.insuranceForm.get('quoteId')?.value??""}} - your reference ID for the update. However, we will notify you once inspection is verified.
                    </div>
                    <div class="col-md-12 mt-5 text-main font-15-12">
                        <span class="d-block">You can continue your policy journey with the following steps:</span>
                        <span class="d-block"><span class="text-main2">Step 1: </span>Login with your credentials.</span>
                        <span class="d-block"><span class="text-main2">Step 2: </span>Go to Dashboard.</span>
                        <span class="d-block"><span class="text-main2">Step 3: </span>Click on Incomplete tab available in Policies menu option.</span>
                        <span class="d-block"><span class="text-main2">Step 4: </span>Select your policy and click on Continue button.</span>
                        <span class="d-block"><span class="text-main2">Step 5: </span>Go to payment, and complete the transaction.</span>
                        <span class="d-block"><span class="text-main2">Step 6: </span>Congratulations! Policy is issued for your vehicle.</span>
                    </div>
                    <div class="col-12">
                        <button type="button" class="btn btn-primary col-md-6" aria-label="Close" (click)="goToPolicyDetails()">
                            Ok
                          </button>
                    </div>
                </div>    
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- new Things -->


<div class="banner dasktopDIv">
  <div class="  mt-5 mb-5 ">

    <div style="width: 100%; height: 20%; text-align:center;margin:0; padding-bottom:10px;" class="carcompo">
      <ng-image-slider #nav [images]="bannerImg" [imagePopup]="false" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: '80px'}" slideImage="1" [showArrow]="false"></ng-image-slider>
    </div>
  </div>
</div>

<div class="banner mobileDiv" >
  <div class="  mt-5 mb-5 ">

    <div class="bannerDIv">
      <!-- <ng-image-slider #nav [images]="bannerImg" [imagePopup]="false" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: '80px'}" slideImage="1" [showArrow]="false"></ng-image-slider> -->
        <ng-container *ngFor="let item of bannerImg">
          <div><img [src]="item.image"></div>

        </ng-container>
    </div>
  </div>
</div>


<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 class="whatinsh1">What is Car Insurance?
        </h2>
        <span>
          <p style="padding-top: 15px;" class="insText">Car insurance is a crucial financial product designed to
            protect
            you, your vehicle, and others on the road. It offers coverage for various potential incidents, including
            accidents, theft, and damage caused by natural disasters. Depending on the policy, car insurance can cover
            medical expenses, repair costs, and legal liabilities arising from an accident. With a wide range of
            coverage options available, you can tailor your car insurance policy to meet your specific needs and
            budget,
            ensuring comprehensive protection for your vehicle and yourself.</p>

        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;margin-bottom: 20px;">
          <a class="earnButton">Know More About Insurance</a>
        </div> -->
      </div>
      <div class="col-md-5 whatimage">
        <img src="./assets/images/newicons/car.svg" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="whyins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5 whyimage">
        <img src="./assets/images/newicons/whycar.svg" class="imgresponsive">
      </div>
      <div class="col-md-7">
        <h2 class="whyinsh1">
          Why it is important to insure Car?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Provides financial protection, covering repair and replacement costs in case of accidents, theft, or
            damage.<br>
            2. Offers peace of mind by knowing you are financially protected against unforeseen events.<br>
            3. Compensates for the loss if your vehicle is stolen, ensuring you are not left without transportation.
          </p>
        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;">
          <a class="earnButton">Know more about it</a>
        </div> -->

      </div>

    </div>
  </div>
</div>


<div class="click4bima">
  <div class="  mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12">
        <div class="">
          <h1 class="whyinsh1" style="text-align: center !important; color: #fff !important;">Types of Four-Wheeler
            Insurance</h1>
        </div>
        <div class="tableClass" [align]="'center'">
          <table>
            <thead>
              <tr>
                <th class="key-features">Key Features</th>
                <th>Comprehensive</th>
                <th>Third-Party(TP)</th>
                <th>Own Damage(OD)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Description</td>
                <td>Comprehensive car insurance covers both your own vehicle's damages and liability for third-party
                  injuries or property damage, offering full protection on the road.</td>
                <td>Third-Party car insurance provides coverage for damages and injuries you cause to others while
                  driving, ensuring you're protected against liability claims.</td>
                <td>Own Damage car insurance covers repair or replacement costs for your vehicle if it’s damaged in an
                  accident, theft, or natural disaster.</td>
              </tr>
              <tr>
                <td>Coverage</td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Third-Party Liabilities</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Third-Party Liabilities</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Add-ons Availability</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>Engine & Gearbox Protect</li>
                    <li>Key Protect</li>
                    <li>Return To Invoice</li>
                    <li>Roadside Assistance</li>
                    <li>Rim Damage Cover</li>
                    <li>Loss of Personal Belonging</li>
                    <li>Passenger Assistance</li>
                    <li>NCB Protection</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
                <td>Not Available</td>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>Engine & Gearbox Protect</li>
                    <li>Key Protect</li>
                    <li>Return To Invoice</li>
                    <li>Roadside Assistance</li>
                    <li>Rim Damage Cover</li>
                    <li>Loss of Personal Belonging</li>
                    <li>Passenger Assistance</li>
                    <li>NCB Protection</li>
                    <li>Engine & Gear Box</li>
                    <li>Daily Allowance</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Premium</td>
                <td>High</td>
                <td>Low</td>
                <td>Moderate</td>
              </tr>
              <tr>
                <td>Pros</td>
                <td>
                  <ul>
                    <li>Wide Coverage</li>
                    <li>Additional Benefits with Add-ons</li>
                    <li>Financial Security</li>
                    <li>Legal Compliance</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Financial Security</li>
                    <li>Legal Compliance</li>
                    <li>Lower Premium</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Covers Accidental Damage</li>
                    <li>Easy Claim Settlement</li>
                    <li>Financial Security for Own Vehicle Damage</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Cons</td>
                <td>
                  <ul>
                    <li>Higher Premiums</li>
                    <li>Depreciation Deduction</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Limited Coverage</li>
                    <li>No Financial Protection for Own Damage</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Depreciation Deduction</li>
                    <li>No PA Cover</li>
                    <li>No Financial Protection for Third-Party Damage</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <div style="text-align: center;margin-top: 50px;padding-bottom: 30px;">
    <h2 style="font-size: 40px;  ">Add-Ons We Provide for Four-Wheelers</h2>
  </div>

  <!-- <div style="width: 100%; height: 20%; text-align:center;margin:0; ">
    <ng-image-slider #nav [images]="addonImg" [imagePopup]="false" [infinite]="false" style="cursor: pointer;" [autoSlide]="5"
      [imageSize]="{width: '23%', height: '200px'}" slideImage="2" (imageClick)="getValue($event)"></ng-image-slider>
  </div> -->
  <div class="desktop-view">
    <div class="addonDIvflax ">
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 1 }" (click)="getValue(1)">
        <div>
          <img src="./assets/images/newicons/b1.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Zero/Nil<br>
          Depreciation
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 2 }" (click)="getValue(2)">
        <div>
          <img src="./assets/images/newicons/b2.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Consumable<br>
          Cover
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 3 }" (click)="getValue(3)">
        <div>
          <img src="./assets/images/newicons/b3.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Return to<br>
          Invoice
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 4 }" (click)="getValue(4)">
        <div>
          <img src="./assets/images/newicons/b4.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Road Side<br>
          Assistance
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 5 }" (click)="getValue(5)">
        <div>
          <img src="./assets/images/newicons/b5.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Engine & Gear<br>
          Box Protect
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 6 }" (click)="getValue(6)">
        <div>
          <img src="./assets/images/newicons/b6.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Daily<br>
          Allowance
        </div>
      </div>

    </div>
    <div class="addonDIvflax ">
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 7 }" (click)="getValue(7)">
        <div>
          <img src="./assets/images/newicons/b7.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Key Protect
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 8 }" (click)="getValue(8)">
        <div style=" height: 61%; padding-top: 12px; ">
          <img src="./assets/images/newicons/b8.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Tyre Cover
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 9 }" (click)="getValue(9)">
        <div style=" height: 61%; ">
          <img src="./assets/images/newicons/b9.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Rim Damage Cover
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 10 }" (click)="getValue(10)">
        <div>
          <img src="./assets/images/newicons/b10.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Loss of Personal <br> Belonging
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 11 }" (click)="getValue(11)">
        <div>
          <img src="./assets/images/newicons/b11.svg" class="imgresponsiveaddon">
        </div>
        <div>
          Passenger <br>Assistance
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': type === 12 }" (click)="getValue(12)">
        <div style=" height: 61%; padding-top: 12px; ">
          <img src="./assets/images/newicons/b12.svg" class="imgresponsiveaddon">
        </div>
        <div>
          NCB Protection
        </div>
      </div>

    </div>
  </div>
  <div class="row addonDIvs mobile-view"  >
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 1 }" (click)="getValue(1)">
      <div>
        <img src="./assets/images/newicons/b1.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Zero/Nil<br>
        Depreciation
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 2 }" (click)="getValue(2)">
      <div>
        <img src="./assets/images/newicons/b2.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Consumable<br>
        Cover
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 3 }" (click)="getValue(3)">
      <div>
        <img src="./assets/images/newicons/b3.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Return to<br>
        Invoice
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 4 }" (click)="getValue(4)">
      <div>
        <img src="./assets/images/newicons/b4.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Road Side<br>
        Assistance
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 5 }" (click)="getValue(5)">
      <div>
        <img src="./assets/images/newicons/b5.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Engine & Gear<br>
        Box Protect
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 6 }" (click)="getValue(6)">
      <div>
        <img src="./assets/images/newicons/b6.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Daily<br>
        Allowance
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 7 }" (click)="getValue(7)">
      <div>
        <img src="./assets/images/newicons/b7.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Key Protect
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 8 }" (click)="getValue(8)">
      <div style=" height: 61%; padding-top: 12px; ">
        <img src="./assets/images/newicons/b8.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Tyre Cover
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 9 }" (click)="getValue(9)">
      <div style=" height: 61%; ">
        <img src="./assets/images/newicons/b9.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Rim Damage Cover
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 10 }" (click)="getValue(10)">
      <div>
        <img src="./assets/images/newicons/b10.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Loss of Personal <br> Belonging
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 11 }" (click)="getValue(11)">
      <div>
        <img src="./assets/images/newicons/b11.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Passenger <br>Assistance
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': type === 12 }" (click)="getValue(12)">
      <div style=" height: 61%; padding-top: 12px; ">
        <img src="./assets/images/newicons/b12.svg" class="imgresponsiveaddon">
      </div>
      <div>
        NCB Protection
      </div>
    </div>

  </div>
  <div
    style="text-align: center;margin-top: 50px;width: 80%; margin-left: 10%; background-color: var(--grey1); padding:15px 50px 15px 50px; border-radius: 12px;     font-size: large;">
    {{text}}
  </div>
</div>

<div class="become">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12 ">
        <div class="">
          <h1 style="font-size: 40px;;text-align: center;">How to choose best Four-Wheeler Insurance?</h1>
        </div>
        <div style="margin-top: 35px; text-align: center;">
          <img style="width: 100%;" src="./assets/images/newicons/cargroup.svg">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 class="whatinsh1">What is Covered in Car
          Insurance?</h2>
        <span>
          <p style="padding-top: 15px;" class="insText">
            1. Covers repair or replacement costs for your vehicle in case of accidents.<br>
            2. Provides compensation if your car is stolen or declared a total loss.<br>
            3. Covers damages or injuries caused to others, including property damage and bodily injury.<br>
            4. Offers discounts on premiums for claim-free years, which can accumulate over time.<br>
            5. Covers damages due to man-made events like riots, vandalism, or terrorism.
          </p>

        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;margin-bottom: 20px;">
          <a class="earnButton">Know More About Insurance</a>
        </div> -->
      </div>
      <div class="col-md-5 whatimage">
        <img src="./assets/images/newicons/covercar.svg" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="whyins1">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5 whyimage">
        <img src="./assets/images/newicons/notcovercar.svg" class="imgresponsive">
      </div>
      <div class="col-md-7">
        <h2 class="whyinsh1">
          What is Not Covered in Car Insurance?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Damage due to regular use, aging, and wear and tear is not covered.<br>
            2. Issues arising from mechanical or electrical failures that are not related to accidents.<br>
            3. Damage caused intentionally or due to reckless behavior is not covered.<br>
            4. Accidents occurring while the rider is under the influence of alcohol or drugs are generally
            excluded.<br>
            5. Damage to or loss of a vehicle that is not properly registered.
          </p>
        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;">
          <a class="earnButton">Know more about it</a>
        </div> -->

      </div>

    </div>
  </div>
</div>

<div class="faq">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <h2>FAQs</h2>
    </div>
    <div class="row" style="padding-top: 15px;">
      <div class="col-md-6">

        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(0)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }"
                aria-hidden="true"></i>
              1. What is car insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Car insurance provides financial
              protection against losses and damages resulting from accidents, theft, or other incidents involving your
              vehicle. It also covers liability for damages caused to other people or property.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(1)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"
                aria-hidden="true"></i>
              2. How many types of car insurance are there?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">In India, car insurance primarily falls
              into three categories:<br>
              Third-Party Insurance: This mandatory type covers damages or injuries caused to others in an accident
              where you are at fault. It does not cover damages to your own vehicle.<br>
              Comprehensive Insurance: This type offers broader protection. It covers damages to your own vehicle as
              well as third-party damages. It includes coverage for accidents, theft, fire, natural disasters, and
              vandalism.
              Own Damage (OD) Insurance: This is a component of comprehensive insurance that specifically covers damages
              to your own vehicle. It includes repairs or replacement costs due to accidents, theft, fire, and natural
              calamities.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(3)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"
                aria-hidden="true"></i>
              3. How can I buy car insurance through Click4bima?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> Visit our website, enter your vehicle
              details, and compare quotes from various insurance providers. Choose the policy that best suits your needs
              and complete the purchase online.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(4)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"
                aria-hidden="true"></i>
              4. What information do I need to provide to get a car insurance quote?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">You'll need to provide details such as
              RTO, your vehicle's make and model, registration number, year of manufacture, and your personal
              information.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(5)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"
                aria-hidden="true"></i>
              5. Can I renew my car insurance through Click4bima?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">Yes, you can renew your existing car
              insurance policy directly through Click4bima. </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(6)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"
                aria-hidden="true"></i>
              6. What should I do if I need to file a claim?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }"> Report the incident to your insurance
              provider as soon as possible. Provide all necessary documentation, such as a police report, photographs of
              the damage, and any other required information.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(7)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"
                aria-hidden="true"></i>
              7. Are there any discounts available for car insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">Many insurers offer discounts for
              factors such as no-claim bonuses, safe driving records, and installing anti-theft devices. Check the
              available discounts when comparing quotes on Click4bima.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(8)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"
                aria-hidden="true"></i>
              8. Will I require Car insurance for an electric/CNG/LPG car?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> Yes, owners or electric cars or cars
              fitted with CNG/LPG are also required to carry a valid insurance policy. However premium plans may
              differ..</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(9)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"
                aria-hidden="true"></i>
              9.What is Depreciation in Car Insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">Depreciation refers to the decrease in a
              car's value over time due to usage, wear and tear, and aging. As your car gets older, its value decreases,
              and this affects the cost of repairs or replacements. When you claim insurance for repairs, the insurance
              company covers a portion of the expenses based on the car’s current value after depreciation. You will
              need to cover the difference between the cost of new parts and the depreciated value of your car.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(10)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"
                aria-hidden="true"></i>
              10. How is my car insurance premium determined?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">Your premium is influenced by various
              factors, including:<br>
              Vehicle make and model<br>
              Your location<br>
              Vehicle’s Age<br>
              IDV<br>
              Opted Add-on Covers<br>
              Additional benefits like PA Cover, Accessories Cover, Legal Liability Covers etc.<br>
              Coverage limits and deductibles</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid background">
  <div class="container partners_section">
    <div class="card-container ">
      <div class="justify-content">
        <div class="text " style="margin-top: 3%;">
          <!-- <h1 class="heading">Our <span style="color:var(--main2)">Partners</span></h1> -->
          <h1 class="heading">Our Four-Wheeler Insurance Partners</h1>
          <span class="font-body">We work together to provide you the best services.</span>
        </div>

        <div class="container ">
          <div style="text-align: center;">
            <div class="image-row">
              <img *ngFor="let img of images" [src]="img" alt="">
              <ng-container *ngIf="showAllImages">
                <img *ngFor="let img of images2" [src]="img" alt="">
              </ng-container>
            </div>
            <div style="width: 100%; text-align: center;margin-bottom: 20px;">
              <a *ngIf="!showAllImages" class="butn" (click)="showAllImages = true">Show More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>