import { Component } from '@angular/core';

@Component({
  selector: 'app-gi-posptraining',
  templateUrl: './gi-posptraining.component.html',
  styleUrls: ['./gi-posptraining.component.css']
})
export class GiPosptrainingComponent {
  
  showImage: boolean = false;

  startTraining(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.showImage = true;
  }
}
