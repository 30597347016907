<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Senior Citizen Health Insurance Plan :</h1>
            </div>
            <div class="top-line"></div>
            <div class="row">
            <div class="mt-5"><p class="font-body">This plan is for Senior Citizens As the name suggests, senior citizen Health Insurance
                plans are meant to cover individuals aged 60 years and above. Since senior citizens are more prone to
                ailments, these plans are designed keeping in mind their healthcare requirements. The sum insured levels
                are limited and premiums are manageable and affordable. Retirement years are often called the Golden
                Years of one&rsquo;s life, however this couldn&rsquo;t be further away from the truth. Loss of income
                due to retirement, susceptibility to various age related health issues, and of course spiralling health
                costs make health insurance plans for Senior Citizens a must-have.</p></div></div>
            <div class="row"><div class="title-line">
                <h4>Things to look at when buying a Senior Citizen Health Policy</h4>
            </div>
           <div class="mt-5"> <p class="font-body"></p></div>
           <div class="mt-5"><p class="font-body">What is the maximum age of entry.</p></div>
           <div class="mt-5"> <p class="font-body">Is lifetime renewal offered.</p></div>
           <div class="mt-5"><p class="font-body">Are pre-existing conditions covered.</p></div>
           <div class="mt-5"><p class="font-body">What is the maximum sum insured.</p></div>
           <div class="mt-5"><p class="font-body">What is the network hospital coverage like.</p></div>
           <div class="mt-5"><p class="font-body">What are the premiums and is the claim benefit offered.</p></div>
           <div class="mt-5"><p class="font-body">Is co-payment offered.</p></div>
           <div class="mt-5"><p class="font-body">Benefits of Senior Citizen Health Insurance Plan:</p></div></div>
            <div class="row"><div class="title-line">
                <h4>Lifestyle Diseases</h4>
            </div>
            <p class="font-body mt-3">With an appropriate health insurance policy, lifestyle diseases can be dealt properly
                with senior citizens, without causing further damage to your lifestyle and dent to your pocket.</p>
            <div class="row"><div class="title-line">
                <h4>Inadequate Insurance</h4>
            </div>
           <div class="mt-5"> <p class="font-body">Old age is highly unpredictable and one does not even have the strength to fight a
                stressful situation. You might already be having an insurance policy but is it adequate?</p></div></div>
                <div class="row">
            <div class="title-line">
                <h4>Beat Medical Inflation</h4>
            </div>
            <div class="mt-5"><p class="font-body"> The cost of medical treatment in India is always on the rise. With adequate medical
                insurance, you can ensure that an unfortunate ailment at old age does not erode your hard earned
                savings.</p></div></div>
           <div class="row"> <div class="title-line">
                <h4>Big Savings</h4>
            </div>
            <div class="mt-5"><p class="font-body">Plan early for your retirement or for your retirement. Securing a health insurance
                policy early in life guarantees maximum comfort, protection as well as the lowest premiums.</p></div>
           <div class="row"> <div class="title-line">
                <h4>Not just Health Insurance</h4>
            </div>
            <div class="mt-5"><p class="font-body">Our health insurance plans for senior citizens come with wellness benefits such as the
                right kind of diet, fitness regime, nutrition, health-related tips.</p>
            </div></div>
            <div class="row">
            <div class="title-line">
                <h4>Tax Savings</h4>
            </div>
            <div class="mt-5"><p class="font-body">Buying health insurance is a wise financial decision and a kind of saving as you get
                tax benefit of upto Rs60,000 annually under section 80D on the premium paid for your health insurance
                policy.</p></div>
                <div class="mt-5"><p class="font-body">Health Insurance Renewal Online Process - Through the Insurer&rsquo;s Website</p></div>
                <div class="mt-5"><p class="font-body">Health insurance renewal is an easy process, especially when done online through the
                insurer&rsquo;s website by following the steps given below:</p></div>
                <div class="mt-5"><p class="font-body">Step 1: Visit the official website of the insurance company.&nbsp;</p></div>
                <div class="mt-5"><p class="font-body">Step 2: Go to the health insurance renewal section available on the website.</p></div>
                <div class="mt-5"><p class="font-body">Step 3: Enter your previous health insurance policy number, registered mobile number
                and such other details as required at the insurance company Website.</p></div>
                <div class="mt-5"><p class="font-body">Step 4: Check the policy details and the policy renewal premium and conditions such as
                tenure, payment options etc.</p></div>
                <div class="mt-5"><p class="font-body">Step 5: Make the payment using your credit/debit card or Net banking.</p></div>
        </div>
    </div>
</div></div>
<app-footer></app-footer>