<div class="container-fluid " [ngClass]="!isUserLogged ? '' : 'user-dashboard'" style="background-color: #F3F3F4;">
    <div class="container" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}"
        [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="row">
            <div class="">
                <h3>
                    Register Business
                </h3>
                <span>
                    Manage your insurance policies & renewals 
                </span>
            </div>
            <div class="card-box">
                <div class="row">
                    <div class="col tab" [ngClass]="{'activeTab': TabFirst==''}"  (click)="profile('')">
                        <h4><b>
                                Personal Info
                            </b></h4>
                    </div>
                    <div class="col tab" [ngClass]="{'activeTab': TabSecond=='Aadhar-otp' || TabSecond=='Identity' || TabSecond=='adhar-doc' || TabSecond=='adhar-doc-success' || TabSecond=='AadharSuccess'} " (click)="Aadhar('Aadhar-otp')">
                        <h4><b>
                                Business Type </b></h4>
                    </div>
                    <div class="col tab" [ngClass]="{'activeTab': TabThird}" (click)="PanCard('Pan-card')">
                        <h4><b>
                                Business Info
                            </b></h4>
                    </div>
                    <div class="col tab" [ngClass]="{'activeTab': TabThird}" (click)="PanCard('bank-details')">
                        <h4><b>
                                Bank Details
                            </b></h4>
                    </div>
                    <div class="col tab" [ngClass]="{'activeTab': TabThird}" (click)="PanCard('upload-documents')">
                        <h4><b>
                                Documents
                            </b></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="tabContant">
                        <div *ngIf="TabName==''">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 mt-2">
                                        <label class="required">Full Name</label>
                                        <input type="text" name="name" class="form-control" id=""
                                            placeholder="Your Name">
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <label class="required">Date Of birth </label>
                                        <input type="Date" name="Dob" class="form-control" id="" placeholder="DOB">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label for="Date" class="required">Gender </label><br>
                                        <input class="my-3 radio-button" type="radio" id="male" name="gender"
                                            value="Male" formControlName="gender">
                                        Male
                                        &nbsp;&nbsp;
                                        <input class="my-3 radio-button" type="radio" id="female" name="gender"
                                            value="Female" formControlName="gender">
                                        Female &nbsp;&nbsp;
                                        <input class="my-3 radio-button" type="radio" id="other" name="gender"
                                            value="Other" formControlName="gender">
                                        Other
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Yout Mobile </label>
                                        <input type="number" name="mobile" class="form-control" id=""
                                            placeholder="+91 4565467895" 
                                            onkeypress="if(this.value.length=='10') return false">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Your Email Address </label>
                                        <input type="email" name="email" class="form-control" id=""
                                            placeholder="useremailadd@domain.com">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Street Address </label>
                                        <input type="text" name="Address" class="form-control" id=""
                                            placeholder="B273, Manglam Balaji City, Usercity">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label class="required">Your Pincode </label>
                                        <input type="number" name="Pincode" class="form-control" id=""
                                            placeholder="Your Pincode"
                                            onkeypress="if(this.value.length=='6') return false">
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-12 text-end">
                                        <button class="btn btn-primary " (click)="Aadhar('Aadhar-otp')">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='Aadhar-otp'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div class="details_img">
                                                        <img src="../../assets/images/register_images/businessTypes.png" class="img-fluid" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                    <div class="details_content">
                                                        <h6>Hospital</h6>
                                                        <p>Delivering quality healthcare services for optimal wellness.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div class="details_img">
                                                        <img src="../../assets/images/register_images/medical.png" class="img-fluid" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                    <div class="details_content">
                                                        <h6>Medical</h6>
                                                        <p>Empowering healthcare solutions with advanced medical expertise and personalized care.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div class="details_img">
                                                        <img src="../../assets/images/register_images/doctor.png" class="img-fluid" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                    <div class="details_content">
                                                        <h6>Doctor/Nurse</h6>
                                                        <p>Expert healthcare professionals dedicated to your well-being, providing personalized care and medical expertise.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div class="details_img">
                                                        <img src="../../assets/images/register_images/garage.png" class="img-fluid" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                    <div class="details_content">
                                                        <h6>Garage/Automobile Store</h6>
                                                        <p>Your go-to destination for automotive solutions and products.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div class="details_img">
                                                        <img src="../../assets/images/register_images/influencer.png" class="img-fluid" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                    <div class="details_content">
                                                        <h6>Influencer/Content Creator</h6>
                                                        <p>Empowering healthcare solutions with advanced medical expertise and personalized care.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div class="details_img">
                                                        <img src="../../assets/images/register_images/agent.png" class="img-fluid" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                    <div class="details_content">
                                                        <h6>Agent</h6>
                                                        <p>Protecting your future with comprehensive insurance coverage.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <div class="details_img">
                                                        <img src="../../assets/images/register_images/other.png" class="img-fluid" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                                                    <div class="details_content">
                                                        <h6>Other</h6>
                                                        <p>Register your unique business and connect with potential customers.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='AadharSuccess'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <p>Do you have GSTIN ?</p>
                                                <input type="radio" name="gstin" value="yes" /> yes 
                                                <input type="radio" name="gstin" value="no" /> No
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mt-10">

                                            <div class="mt-8">
                                                <label>Enter OTP</label>
                                                <input type="number" name="OTP" id="" class="form-control"
                                                    placeholder="OTP"
                                                    onkeypress="if(this.value.length=='6') return false">
                                            </div>
                                            <button class="btn btn-primary mt-10" id="btn"
                                                (click)="AadharSuccess('AadharSuccess')">
                                                Verify Now</button>
                                            <div class="text-center mt-10"> <span>
                                                    Not linked with Mobile Number?
                                                </span>
                                                <span (click)="adharDoc('adhar-doc')" class="point"><b>Verify aadhaar with
                                                        other
                                                        methods
                                                    </b></span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="TabName=='Identity'">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <div>
                                            <img src="../../../assets/images/success.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h1>**** - **** - 2342</h1>
                                        <span>Your aadhaar card verified successfully</span>

                                    </div>
                                </div>
                                <div class=" mt-10 row">
                                    <div class="col-md-12 text-end">
                                        <button class="btn btn-primary" (click)="PanCard('Pan-card')">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName== 'Pan-card'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <p>Do you have GSTIN ?</p>
                                                <input type="radio" name="gstin" value="yes" /> yes  &nbsp; &nbsp;
                                                <input type="radio" name="gstin" value="no" /> No
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>GSTIN Number</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="GSTIN Number" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Garage Name</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="Garage Name" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Business SubCategory</label>
                                                <select class="form-control" name="businesscategory">
                                                    <option>Business Category</option>
                                                    <option>General Hospital</option>
                                                    <option>Business Category</option>
                                                    <option>Business Category</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Company PAN</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="Company PAN Number" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Business Type</label>
                                                <select class="form-control" name="businesscategory">
                                                    <option>Business Type</option>
                                                    <option>Private Limited</option>
                                                    <option>Individual</option>
                                                    <option>Business Category</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Established Year</label>
                                                <select class="form-control" name="businesscategory">
                                                    <option>Established Year</option>
                                                    <option>2019</option>
                                                    <option>2020</option>
                                                    <option>2022</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Select Business Category</label>
                                                <select class="form-control" name="businesscategory">
                                                    <option>Business Type</option>
                                                    <option>Private Limited</option>
                                                    <option>Individual</option>
                                                    <option>Business Category</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Business Mobile Number</label>
                                                <input type="number" class="form-control" name="gstinnumber" placeholder="Business Mobile Number" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <p>Business Mobile Number Same for What’s App?</p>
                                                <input type="radio" name="gstin" value="yes" /> yes  &nbsp; &nbsp;
                                                <input type="radio" name="gstin" value="no" /> No
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>What’s App Number</label>
                                                <input type="number" class="form-control" name="gstinnumber" placeholder="What’s App Number" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Business Email Address</label>
                                                <input type="email" class="form-control" name="gstinnumber" placeholder="Business Email Address" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Enter Pincode</label>
                                                <input type="number" class="form-control" name="gstinnumber" placeholder="Enter Pincode" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Select Village</label>
                                                <select class="form-control" name="businesscategory">
                                                    <option>Select Village</option>
                                                    <option>Private Limited</option>
                                                    <option>Individual</option>
                                                    <option>Business Category</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Street Address</label>
                                                <input type="number" class="form-control" name="gstinnumber" placeholder="Street Address" /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='panSuccess'">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <div>
                                            <img src="../../../assets/images/success.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h1>Verify Identity Success</h1>
                                        <span>Select a mobile number/email address to set communication
                                            preferences</span>
                                        <div class=" center">
                                            <span style="color: #E98315;"> Verify Identity process takes up-to 2 to 3
                                                days to
                                                verify</span>
                                        </div>
                                    </div>
                                </div>
                                <div class=" mt-10 row">
                                    <div class="col-md-12 text-end">
                                        <button class="btn btn-primary">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='adhar-doc'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="text-center">
                                            <img src="../../../assets/images/adhar-card.png" class="AP-img" alt="">
                                            <div class="mt-5">
                                                <h4>Verify Your Aadhaar Card</h4>
                                                <span>Select a mobile number/email address to set communication
                                                    preferences</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="text-center"><b>&nbsp;&nbsp;&nbsp;Upload Aadhar Front</b></span>
                                        <img src="../../../assets/images/adhar-font.png" class="AP-img" alt="">
                                        <div class="mt-10 ml-3">
                                            <label for="file-upload" class="file-upload-label"><i class="fa fa-id-card"
                                                    aria-hidden="true"></i>&nbsp;&nbsp;Aadhar Front </label>
                                            <input type="file" id="file-upload" class="file-upload-input">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="text-center"><b>&nbsp;&nbsp;&nbsp;Upload Aadhar Back</b></span>
                                        <img src="../../../assets/images/adhar-font.png" class="AP-img" alt="">
                                        <div class="mt-10 ml-3">
                                            <label for="file-upload" class="file-upload-label"><i class="fa fa-id-card"
                                                    aria-hidden="true"></i>&nbsp;&nbsp;Aadhar Back </label>
                                            <input type="file" id="file-upload" class="file-upload-input">
                                        </div>

                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp;
                                        &nbsp; &nbsp; <button class="btn btn-primary"
                                            (click)="adharDocSuccess('adhar-doc-success')">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='adhar-doc-success'">
                            <div class="container">
                                <div class="row text-center">
                                    <div class="col-md-12">
                                        <div>
                                            <img src="../../../assets/images/success.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <h1>Your aadhaar card uploaded successfully</h1>

                                        <div class=" center">
                                            <span style="color: #E98315;"> Verify Identity process takes up-to 2 to 3
                                                days to
                                                verify</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- <div *ngIf="TabName=='Aadhaar&Pan'">
                            <div class="container  center">
                                <div class="row ">
                                    <div class="col-md-12 ">
                                        <h3>Please select option to complete your kyc</h3>
                                    </div>
                                    <div class="col-md-6 mt-10">
                                        <button class="btn btn-primary  text-start" (click)="Tab('Aadhar-otp')">Aadhaar Card</button>
                                    </div>
                                    <div class="col-md-6 mt-10 text-end">
                                        <button class="btn btn-primary  text-start" (click)="Tab('Pan-card')">Pan Card</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div *ngIf="TabName=='bank-details'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>GSTIN Number</label>
                                                <input type="number" class="form-control" name="gstinnumber" placeholder="Account Number" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>IFSC Code</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="IFSC Code" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Bank Name</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="Bank Name" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Branch Name</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="Branch Name" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="Name" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Street Address</label>
                                                <input type="text" class="form-control" name="gstinnumber" placeholder="Street Address" /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Pincode</label>
                                                <input type="number" class="form-control" name="gstinnumber" placeholder="Pincode" /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="TabName=='upload-documents'">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Document Type</label>
                                                <select class="form-control" name="businesscategory">
                                                    <option>Aadhaar Card</option>
                                                    <option>PAN Card</option>
                                                    
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <label>Aadhaar Number</label>
                                                <input type="text" class="form-control" name="" placeholder="Aadhaar Number" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-6">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <div class="file-drop" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                                    <img src="/assets/svgIcon/cloud.svg" class="" />
                                                    <label class="custom-file-dnd">Drop file here or </label> 
                                                    <input type="file" id="{{id}}" class="file_uploader" (change)="onFileSelected($event)" accept="{{acceptType}}" #fileInput multiple>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="details_block1">
                                            <div class="form-group">
                                                <div class="file_preview" *ngIf="(docObj != null && docObj != undefined && docObj.documentBase64 != '')">
                                                    <div>
                                                        <img class="img_preview mr-3" src="{{docObj.documentBase64}}">
                                                        {{ docObj.documentName }}
                                                    </div>
                                                    <span class="material-symbols-outlined mr-2 icon cursor-pointer text-danger" title="Remove file" (click)="removeDocument(docObj.documentTypeId??'0')">
                                                        delete
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
