import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.css']
})
export class PaymentCardComponent implements OnInit  {

  ngOnInit(): void {

  }
  paymentType:string='';
  
 Payment(type:string):void{
this.paymentType=type;
 }
}
