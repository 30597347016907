import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../Models/login.component.model';
import { UserService } from '../Services/user.service';
import { AppConfig } from '../Shared/AppConfig';
import swal from 'sweetalert2';
import { LoginService } from './../Services/login.service';
import { Router } from '@angular/router';
import { ProfileService } from '../Services/profile.service';
import{FamilyMembers,PropertyDetails,RelationShips,Vehicles} from '../Models/Profile.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  public CurrentpasswordType: string = PasswordType.pwd;
  CurrentpasswordShow: boolean = false;
  public NewpasswordType: string = PasswordType.pwd;
  NewpasswordShow: boolean = false;
  public ConfirmNewpasswordType: string = PasswordType.pwd;
  ConfirmNewpasswordShow: boolean = false;
  passwordForm:FormGroup;
isSubmit:boolean=false;
  IsResetPassword: boolean = true;
  IsChangePassword: boolean | undefined;

  constructor(private fb: FormBuilder, private userservice: UserService,
    private loginService: LoginService,private route: Router,private profileService:ProfileService){
      this.passwordForm = this.fb.group({
        "user_email": sessionStorage.getItem('user_email'),
        "CurrentPassword": ['', [Validators.required]],
        "NewPassword": ['', [Validators.required]],
        "confirm_password": ['', [Validators.required]],
        "phoneNumber": [''],
        "OTP_FOR": ['CUST'],
      });
    }



  showchangepassword(event: any) {
    if (event == 'true') {
      this.IsChangePassword = true;
      this.IsResetPassword = false;

      // ChangePassword
    }
    else {
      this.passwordForm.reset();
      this.IsChangePassword = false;
      this.IsResetPassword = true;
    }
  }

  ShowCurrentPassword() {
    if (!this.CurrentpasswordShow) {
      this.CurrentpasswordType = PasswordType.txt;
      this.CurrentpasswordShow = true;
    }
    else {
      this.CurrentpasswordType = PasswordType.pwd;
      this.CurrentpasswordShow = false;
    }
  }
  ShowNewPassword() {
    if (!this.NewpasswordShow) {
      this.NewpasswordType = PasswordType.txt;
      this.NewpasswordShow = true;
    }
    else {
      this.NewpasswordType = PasswordType.pwd;
      this.NewpasswordShow = false;
    }
  }
  ShowConfirmNewPassword() {
    if (!this.ConfirmNewpasswordShow) {
      this.ConfirmNewpasswordType = PasswordType.txt;
      this.ConfirmNewpasswordShow = true;
    }
    else {
      this.ConfirmNewpasswordType = PasswordType.pwd;
      this.ConfirmNewpasswordShow = false;
    }
  }

 async changePassWord(){

  if(this.passwordForm.value.NewPassword == this.passwordForm.value.confirm_password){
  if(this.passwordForm.value.CurrentPassword == this.passwordForm.value.NewPassword){
    swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'Current Password and New Password should not be same',
      showConfirmButton: false,
      timer: 3000,
    });
  }
  else{
    this.passwordForm.value.CurrentPassword  =window.btoa(this.passwordForm.get('CurrentPassword')?.value)
    this.passwordForm.value.NewPassword  =window.btoa(this.passwordForm.get('NewPassword')?.value)
    const res = await this.profileService.ChangePassword(this.passwordForm.value);
    if (res?.isSuccess && res?.result != 'Current password not matched') {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Password changed successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      this.passwordForm.reset();
      location.reload();
    }
    else if (res?.result == 'Current password not matched'){
      swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please enter correct current password',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }


  }
else{
  swal.fire({
    position: 'center',
    icon: 'warning',
    title: 'New Password and Confirm New Password are not matching',
    showConfirmButton: false,
    timer: 3000,
  });
}


}
get changePassword(){
 return  this.passwordForm.controls;
}

paswordValidation():boolean
{
if(this.changePassword['CurrentPassword'].valid && this.changePassword['NewPassword'].valid && this.changePassword['confirm_password'].valid && this.changePassword['NewPassword'].value?.length >=8 && /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(this.changePassword['NewPassword'].value))
  {
   
    return false;
  
  }
  return true;
}
strongPassword():boolean{
  if( /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(this.changePassword['NewPassword'].value))
    {
      return false;
    }
  return true;
}
}
