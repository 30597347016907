<div class="container" style="background-color: #f3f3f4;">
    <div class="row">
        <div class="col-md-6 mt-5 p-5">
            <div class="row">
                <div class="col-md-12">
                    <h4>Verify Your Identity</h4>
                    <span class="mt-2">Select a mobile number/email address to set communication preferences</span>
                </div>
            </div>
            <div class="row">
                <div class="card mt-3 p-2 crd-brd">
                    <div class="row">
                        <div class="col-md-9">
                            <p>Email</p>
                            <h4>
                                contacttopsr@gmail.com
                            </h4>
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="../../../assets/images/SealCheck.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="card mt-3 p-2 crd-brd" (click)="cards('aadhaar')">
                    <div class="row">
                        <div class="col-md-9">
                            <p>Aadhar Card</p>
                            <h4>
                                xxxx - xxxx - 3424
                            </h4>
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="../../../assets/images/ClockCountdown.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="card mt-3 p-2 crd-brd" (click)="cards('pan')">
                    <div class="row">
                        <div class="col-md-9">
                            <p>Pancard</p>
                            <h4>
                                xxxxxxxxFT
                            </h4>
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="../../../assets/images/ClockCountdown.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="card mt-3 p-2 ">
                    <div class="row">
                        <div class="col-md-12">
                            <h4>Verify Identity submit on 2 OCT 2023 2:30PM</h4>
                            <span>Select a mobile number/email address to set communication preferences</span>
                        </div>
                        <div class="col-md-12">
                            <div class="card mt-2 p-2 text-start" style="background-color: rgba(255, 164, 66, 0.1);">
                                <p style="color: rgba(233, 131, 21, 1);">
                                    Verify Identity process takes up-to 2 to 3 days to verify
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-5 p-5">
            <div class="card p-5 mt-17">
                <div *ngIf="card==='aadhaar'">
                    <div class="row">
                        <div class="col-md-12">
                            <span>Aadhaar Card</span>
                            <h4>
                                XXXX - XXXX - 3424
                            </h4>
                        </div>
                        <div class="col-md-6 text-center mt-10 ">
                            <p><b>Upload Aadhar Front</b></p>
                            <img src="../../../assets/images/image 50.png" width="100%" height="70%" alt="">
                        </div>
                        <div class="col-md-6 text-center mt-10 ">
                            <p><b>Upload Aadhar Back</b></p>
                            <img src="../../../assets/images/image 51.png" width="100%" height="70%" alt="">
                        </div>

                    </div>
                </div>
                <div *ngIf="card==='pan'">
                    <div class="row">
                        <div class="col-md-12">
                            <span>Pan Card</span>
                            <h4>
                                xxxxxxxxFT
                            </h4>
                        </div>
                        <div class="col-md-12 text-start mt-10 ">
                            <p><b>Upload PAN-CARD</b></p>
                            <img src="../../../assets/images/pan.png" width="50%" height="50%" alt="">
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>