import { Component } from '@angular/core';

@Component({
  selector: 'app-critical-illness-policy',
  templateUrl: './critical-illness-policy.component.html',
  styleUrls: ['./critical-illness-policy.component.css']
})
export class CriticalIllnessPolicyComponent {

}
