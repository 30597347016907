import { Component } from '@angular/core';
import { GeneralService } from 'src/app/Services/general.service';
import { SitemapComponent } from 'src/app/sitemap/sitemap.component';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent {
  constructor(private generalService : GeneralService){}
  isUserLogged: boolean = false;
  ngOnInit() {
    if (this.generalService.getUniqueuserid() != "") {
      this.isUserLogged = true;
    }
  }
  TabFirst: string = '';
  TabSecond: string = '';
  TabThird: string = '';
  TabName: string = '';
  profile(pName: string): void {
    this.TabName = pName;
    
    this.TabFirst = '';
    this.TabSecond = '';
    this.TabThird = '';
    
  }
  Aadhar(aName:string):void{
    this.TabName = aName;
    
    this.TabFirst = '';
    this.TabSecond = 'Aadhar-otp';
    this.TabThird = '';
    
  }
  
  IdentityAadhar(iName:string):void{
    this.TabName = iName;
    this.TabFirst = '';
    this.TabSecond = 'Identity';
    this.TabThird = '';
  }
  
  adharDoc(docName:string):void{
    this.TabName = docName;
    this.TabFirst = '';
    this.TabSecond = 'adhar-doc';
    this.TabThird = '';
  }
  adharDocSuccess(succesName:string):void{
    this.TabName = succesName;
    this.TabFirst = '';
    this.TabSecond = 'adhar-doc-success';
    this.TabThird = '';
  }
  AadharSuccess(sName:string):void{
    this.TabName = sName;
    this.TabFirst = '';
    this.TabSecond = 'AadharSuccess';
    this.TabThird = '';
  }
  PanCard(pName:string):void{
    this.TabName = pName;
    this.TabFirst = '';
    this.TabSecond = 'Aadhar-otp';
    this.TabThird = 'Pan-card';
  }
  panSuccess(panSuccessName:string):void{
    this.TabName = panSuccessName;
    this.TabFirst = '';
    this.TabSecond = 'Aadhar-otp';
    this.TabThird = 'panSuccess';
  }

  // if(this.TabName=='')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=false;
  //   this.TabThird=false;
  // }
  // if(this.TabName=='Aadhar-otp')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=true;
  //   this.TabThird=false;
  //   alert('click')
  //   console.log(this.TabSecond)

  // }
  // if(this.TabName=='adhar-doc')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=true;
  //   this.TabThird=false;
  // }
  // if(this.TabName=='adhar-doc-success')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=true;
  //   this.TabThird=false;
  // }
  // if(this.TabName=='Identity')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=true;
  //   this.TabThird=false;
  // }
  // if(this.TabName=='AadharSuccess')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=true;
  //   this.TabThird=false;
  // }
  // if(this.TabName=='Pan-card')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=true;
  //   this.TabThird=true;
  // }
  // if(this.TabName=='panSuccess')
  // {
  //   this.TabFirst=true;
  //   this.TabSecond=true;
  //   this.TabThird=true;
  // }

}

