import { Component } from '@angular/core';

@Component({
  selector: 'app-third-party-car-insurance',
  templateUrl: './third-party-car-insurance.component.html',
  styleUrls: ['./third-party-car-insurance.component.css']
})
export class ThirdPartyCarInsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
