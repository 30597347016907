import { Component } from '@angular/core';

@Component({
  selector: 'app-tax-saving-plan',
  templateUrl: './tax-saving-plan.component.html',
  styleUrls: ['./tax-saving-plan.component.css']
})
export class TaxSavingPlanComponent {

}
