<form [formGroup]="propertyInfoForm" class="needs-validation" novalidate="" autocomplete="off">
  <div class="offcanvas-header" *ngIf="!IsProfile">
    <h4 class="offcanvas-title" id="offcanvasScrollingLabel">{{title}}</h4>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #closebtn></button>
  </div>
  <div class="">

    <div class="mt-3">
      <h5>Select Property Type</h5>
      <div class="row justify-content-between p-3">
        <div class="col card p-4 card-type" [ngClass]="{'selected-card': propertyInfoForm.get('propertyType')?.value === 1}">
          <li class="d-flex justify-content-between">
            <span class="text-grey5">Owned</span>
            <input type="radio" class="mt-0 radio-button" id="propertyType1" value="1" formControlName="propertyType" [checked]="propertyInfoForm.get('propertyType')?.value == 1" (change)="setPropertyType(1)">
          </li>
        </div>&nbsp;&nbsp;
        <div class="col card p-4 card-type" [ngClass]="{'selected-card': propertyInfoForm.get('propertyType')?.value === 2}">
          <li class="d-flex justify-content-between">
            <span class="text-grey5">Rental</span>
            <input type="radio" class="mt-0 radio-button" id="propertyType2" value="2" formControlName="propertyType" [checked]="propertyInfoForm.get('propertyType')?.value == 2" (change)="setPropertyType(2)">
          </li>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <label for="number" class="input-label required">City</label>
      <input type="text" class="form-control" placeholder="City" formControlName="propertyCity" name="city" oninput="this.value = this.value.toUpperCase()">
    </div>
    <div class="mt-3">
      <label for="make" class="input-label required">Age of Property</label>
      <select class="form-select form-select-custom"
              aria-label="Default select example" name="Make" formControlName="propertyAge">
        <option value="" selected>Select Property Age</option>
        <option *ngFor="let proprt of proprtyAge" [value]="proprt.id">
          {{proprt.name}}
        </option>
      </select>
    </div>
    <div class="mt-3">
      <label for="Model" class="input-label required">Structure Value</label>
      <select class="form-select form-select-custom" name="model"
              formControlName="propertyValue" aria-label="Default select example">
        <option value="" selected>Select Structure Value</option>
        <option *ngFor="let model of structureValueList" [value]="model.id">
          {{model.name}}
        </option>
      </select>
    </div>

    <div class="mt-3">
      <label class="input-label">Structure Value</label>
      <div class="row justify-content-between p-3 pt-0 pb-0">
        <div class="col card p-4 card-type" [ngClass]="{'selected-card': propertyInfoForm.get('isInsured')?.value == 'true'}">
          <li class="d-flex justify-content-between">
            <span class="text-grey5">Yes</span>
            <input type="radio" class="mt-0 radio-button" id="isInsured1" value="true" [checked]="propertyInfoForm.get('isInsured')?.value == 'true'" formControlName="isInsured">
          </li>
        </div>&nbsp;&nbsp;
      
      <div class="col card p-4 card-type" [ngClass]="{'selected-card': propertyInfoForm.get('isInsured')?.value == 'false'}">
        <li class="d-flex justify-content-between">
          <span class="text-grey5">No</span>
          <input type="radio" class="mt-0 radio-button" id="isInsured2" value="false" [checked]="propertyInfoForm.get('isInsured')?.value == 'false'" formControlName="isInsured">
        </li>
      </div>
    </div>
  </div>
  </div>

  <div class="mt-3">
    <label for="number" class="input-label required">Policy Expiry Date</label>
    <input type="date" name="policyExpiryDate" class="form-control" formControlName="policyExpiryDate">
  </div>
  <div class="row position-absolute b-0">
    <div class="col">
      <button class="btn btn-primary btn-custom w-100" data-bs-dismiss="offcanvas"
            (click)="insertPropertynfo()">
      {{title}}
    </button>
    </div>
    
  </div>
</form>
