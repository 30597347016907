import { Component } from '@angular/core';
import { LanguageLableService } from '../Services/language-lable.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent {
  selectedValue: string | null;
  langList: any;

  constructor(private languageLableService: LanguageLableService) {
  }
  ngOnInit(){
    window.scroll(0,0);
    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
  }

  languageChanged(){
    location.reload();
   }
}
