import { Component } from '@angular/core';

@Component({
  selector: 'app-commercial-vehicle-insurance',
  templateUrl: './commercial-vehicle-insurance.component.html',
  styleUrls: ['./commercial-vehicle-insurance.component.css']
})
export class CommercialVehicleInsuranceComponent {
  ngOnInit(){
    window.scroll(0,0);
  }
}
