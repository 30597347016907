<div class="overlay">
  <div class="modalPopup popup2 col-lg-4 col-md-6 col-11">
    <div class="modal-header bg-transparent tooltip_ border-bottom-0">
      <button type="button" class="close pull-right" aria-label="Close" (click)=" closeModal()">
        <span aria-hidden="true"></span>
     </button>
    </div>
    <div class="modal-body pt-0">
        <div class="mt-1">
            <div class=" d-flex flex-column" *ngIf="isSignin">              
              <div class="flex-center flex-column-fluid">
                <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm">
                  <div class="text-center mb-3">
                    <h2>Login to your Account</h2>
                  </div>
                  <div class="fv-row mb-7">
                    <label class="input-label required" for="mobile">Mobile Number</label>
                    <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                      [searchCountryFlag]="false" [onlyCountries]="[CountryISO.India]"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" customPlaceholder="Enter your mobile number"
                      name="phone" formControlName="PhoneNumber" [inputId]="'phone_number'"
                      (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>
      
                    </ngx-intl-tel-input>
                  </div>
                  <div class="fv-row mb-3">
                    <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold">
                      <div><label class="input-label required" for="password">Password</label></div>                      
                    </div>
                    <input [type]="this.passwordType" placeholder="Enter Password" name="password" autocomplete="off"
                      class="form-control bg-transparent" formControlName="Password" />
                    <i [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'" (click)="ShowPassword()" aria-hidden="true"></i>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="d-flex">
                      <input type="checkbox" class="scale-1_3 mt-0 mx-1">&nbsp;
                    <span class="remember mt-1">Remember me</span>
                    </span>                    
                    <a class="link-primary" (click)="forgotPassword()">Forgot Password?</a>
                  </div>
                  <div class="row mt-5">
                    <div class="col-12">
                      <re-captcha required  formControlName="recaptchaReactive"></re-captcha>
                    </div>
                  </div>
                  <div class="d-grid mb-5 mt-2">
                    <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" [disabled]="loginForm.invalid || ServicesDown"
                      (click)="submit()">
                      <span class="indicator-label">Sign In</span>
                    </button>
                  </div>
                  <div class="text-center">
                    Don't have an account?
                    <a class="link-primary link-color" (click)="quoteSignup()">Sign Up Here</a>
                  </div>
                </form>
              </div>
              <!-- <div class="Copyright">
                <span>© Copyright 2023 BimaG General Insurance Company Limited</span>
              </div> -->
            </div>
            <div class=" d-flex flex-column" *ngIf="isforgotpassword">
              <div class="flex-center flex-column-fluid">
                <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="resetPassForm">
                  <!-- <div class="back_div">
                    <button class="btn-primary back_btn" (click)="forgotBackF()"><img src="../../assets/svgIcon/ArrowLeft.svg" class="svg_white" /></button>
                  </div> -->
                  <div *ngIf="isForgot">
                    <div class="text-center mb-3">
                      <h2>Forgot Password</h2>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="input-label" for="mobile">Mobile Number</label>
                      <ngx-intl-tel-input class="bg-transparent"
                        [enableAutoCountrySelect]="true" 
                        placeholder="Enter Mobile Number/Email"
                        [searchCountryFlag]="false" 
                        [onlyCountries]="[CountryISO.India]"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.India" 
                        [phoneValidation]="true" 
                        [enablePlaceholder]="true"
                        customPlaceholder="Enter your mobile number"
                        name="phone" 
                        [maxLength]="10"
                        formControlName="PhoneNumber" 
                        [inputId]="'phone_number'"
                        (countryChange)="onCountryChangeNew($event)" 
                        aria-hidden="true" 
                        #phoneNumber>
    
                      </ngx-intl-tel-input>
                    </div>
                    <div class="d-grid mb-5 mt-5">
                      <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" [disabled]="resetPassForm.invalid" (click)="sendOtp()">
                        <span class="indicator-label">Send OTP</span>
                      </button>
                    </div>
                    <div class="text-center">
                      <span>Back to login?
                      <a class="link-primary" (click)="quotesSignInF()">Click Here</a></span>
                    </div>
                  </div>
                  <div *ngIf="isOtp">
                    <div class="text-center mb-3">
                      <h2>OTP Verification</h2>
                      <p class="text-center font-body">Enter 6 digit code that sent to your mobile number +91{{mobileNo}}. <a class="text-primary cursor-pointer link-color" (click)="changemobile()">Click here</a> to change your mobile number.</p>
                    </div>
                    <div class="fv-row mb-5">
                      <label class="input-label" for="mobile">Enter OTP</label><br>
                      <div class="fields_group">
                        <input type="text"  #otp class="bg-transparent form-control otp_filed" name="otp" formControlName="otpNumber" aria-hidden="true" [formControl]="enterOTP" placeholder="Enter OTP" required [minlength]="6" [maxlength]="6" (keypress)="numberOnly($event)" >
                      </div>
                      
                    </div>
                    <div class="text-right">
                      If you didn't receive a code! 
                      <!-- <a  class="link-primary" (click)="sendOtp()" > Resend</a> <span *ngIf="timerValue > 0">{{ timerValue }} Secs</span></span> -->
                      <button type="button" [disabled]="timerValue > 0" class="link-primary link-primary forgot_password_link cursor-pointer btn-none px-0 link-color" (click)="resendOTP()"> Resend OTP <ng-container *ngIf="timerValue > 0"> in {{ timerValue }} Secs</ng-container></button>
                    </div>
                    <div class="d-grid mb-5 mt-5">
                      <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" [disabled]="enterOTP.invalid" (click)="resetPassword()">
                        <span class="indicator-label">Submit</span>
                      </button>
                    </div>
                    <div class="text-center">
                      <span> Back to login?
                      <a class="link-primary" (click)="quotesSignInF()">Click Here</a></span>
                    </div>
                  </div>
    
    
                  <div *ngIf="isReset">
                    <div class="text-center mb-3">
                      <h2>Reset Password</h2>
                    </div>
                    <!-- <form class="form"  [formGroup]="resetPassForm"> -->
                    <div class="fv-row mb-7">
                      <label class="input-label" for="mobile">Enter New Password</label>
                      <input type="password" placeholder="New Password" name="password" autocomplete="off" #password
                      class="form-control bg-transparent" formControlName="password1" required="required"/>
                      <span (click)="password.type=password.type=='text'?'password':'text'" 
                      class="fa fa-fw field-icon toggle-password"
                          [ngClass]="(password.type=='password')?' fa-eye-slash':' fa-eye'"></span>
                          <Span class="text-danger"
                          *ngIf="((ResetPassword['password1'].touched  || restPass) && ResetPassword['password1'].errors?.['required'])">
                         Password is  required.
                      </Span>
                    </div>
                    <div class="fv-row mb-3">
                      <!-- <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold">
                        <div><label for="password">Password</label></div>
                        <a href="/forgotpassword" class="link-primary" routerLink="/forgotPassword">Forgot Password ?</a>
                      </div> -->
                      <label class="input-label" for="mobile">Confirm New Password</label>
                      <input type="password" placeholder="Confirm New Password" name="password" autocomplete="off" #password1
                        class="form-control bg-transparent" formControlName="confirm_password" required="required" />
                        <span (click)="password1.type=password1.type=='password'?'text':'password'" 
                      class="fa fa-fw field-icon toggle-password"
                          [ngClass]="(password1.type=='password')?' fa-eye-slash':' fa-eye'"></span>
                          <Span class="text-danger"
                          *ngIf="((ResetPassword['confirm_password'].touched  || restPass) && ResetPassword['confirm_password'].errors?.['required'])">             
                          Confirm Password is  required.
                      </Span>
                    </div>
                    <div class="d-grid mb-5 mt-5">
                      <button class="btn btn-primary" type="submit" id="kt_sign_in_submit"
                      (click)="passwordReset()">
                        <span class="indicator-label">Reset Password</span>
                      </button>
                    </div>
                    <div class="text-center">
                      <span>Back to login?
                        <a class="link-primary" (click)="quotesSignInF()" >Click Here</a></span>
                    </div>
                    <!-- </form> -->
                  </div>
                </form>
              </div>
            </div>
            <div class=" d-flex flex-column" *ngIf="isSignUpDiv">
              <div class="flex-center flex-column-fluid">
                <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="signupForm">
                  <!-- <div class="back_div">
                    <button class="btn-primary back_btn" (click)="signupBackF()"><img src="../../assets/svgIcon/ArrowLeft.svg" class="svg_white" /></button>
                  </div> -->
                  <div *ngIf="isSignup">
                    <div class="text-center mb-3">
                      <h2>Sign Up</h2>
                    </div>
                    <div class="fv-row mb-7">
                      <label class="input-label required" for="mobile">Mobile Number</label>
                      <ngx-intl-tel-input class="bg-transparent" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                        [searchCountryFlag]="false" [onlyCountries]="[CountryISO.India]"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [maxLength]="10"
                        [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" customPlaceholder="Enter your mobile number"
                        name="phone" formControlName="phoneNumber" [inputId]="'phone_number'"
                        (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>
      
                      </ngx-intl-tel-input>
                      <Span class="text-danger"
                      *ngIf="((signupFormControl['phoneNumber'].touched  || restPass) && signupFormControl['phoneNumber'].errors?.['required'])">
                      Phone number is required.
                                </Span>
                    </div>
                    <div class="d-grid mb-5 mt-5">
                      <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="PhoneExists()"
                        [disabled]="!signupFormControl['phoneNumber'].valid">
                        <span class="indicator-label">Send OTP</span>
                      </button>
                    </div>
                    <div class="text-center">
                      <span> Already have an account?
                      <a class="link-primary link-color" (click)="quotesSignInF()">Sign In Here</a></span>
                    </div>
                  </div>
                  <div *ngIf="isOtp">
                    <div class="text-center mb-3">
                      <h2>OTP Verification</h2>
                      <p class="text-center font-body">Enter 6 digit code that sent to your mobile number +91{{mobileNo}}. <a class="text-primary cursor-pointer link-color"  (click)="changemobileSignUp()">Click here</a> to change your mobile number.</p>
                    </div>
                    <div class="fv-row mb-5">
                      <label class="input-label required" for="mobile">Enter OTP</label>
                      <div class="fields_group">
                        <input placeholder="Enter OTP" name="number" autocomplete="off" class="form-control bg-transparent otp_filed" formControlName="otp"  maxlength="6" minlength="6" pattern="[0-9]{6}" oninput="this.value=this.value.replace(/[^0-9]/g,'');" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" />
                      <!-- <span class="timerCounter" *ngIf="timerSpanValue">{{ timerValue }} Secs</span> -->
                      </div>
                    </div>
                    <div class="text-right">
                      If you didn't receive a code! 
                      <!-- <a class="link-primary" (click)="sendOtp()"> Resend</a></span> -->
                      <button type="button" [disabled]="timerValue > 0" class="link-primary link-primary forgot_password_link cursor-pointer btn-none px-0 link-color" (click)="resendOTPSignUp()"> Resend OTP <ng-container *ngIf="timerValue > 0"> in {{ timerValue }} Secs</ng-container></button>
                    </div>
                    <div class="d-grid mb-5 mt-5">
                      <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" (click)="validateOtp1()"
                        [disabled]="signupForm.invalid">
                        <span class="indicator-label">Submit</span>
                      </button>
                    </div>
                    <div class="text-center">
                      <span>By creating your account, you agree to<br> our
                      <a href="/terms-of-use" target="_blank" class="link-primary">Terms & Conditions</a></span>
                    </div>
                  </div>
                  <div *ngIf="isNewUser">
                    <div class="text-center mb-3">
                      <h2>Basic Details</h2>
                    </div>
                    <div class="d-flex fv-row mb-1">
                      <div class="mt-3 mx-2 w-100">
                        <label class="input-label required" for="firstName">First Name</label>
                        <input type="text" placeholder="First Name" name="firstName" autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="firstName" />
                      </div>
                      <div class="mt-3 mx-2 w-100">
                        <label class="input-label required" for="lastName">Last Name</label>
                        <input type="email" placeholder="Last Name " name="lastName" autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="lastName" />
                      </div>
                    </div>
                    <div class="d-flex fv-row mb-1">
                      <div class="mt-3 mx-2 w-100">
                        <label class="input-label required" for="email">Email Address</label>
                        <input type="email" placeholder="Email Address" name="email" autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="userEmail" />
                      </div>
                    
                      <div class="mt-3 mx-2 w-100">
                        <label class="input-label required" for="dob">Date of birth</label>
                        <input type="date" placeholder="DD-MM-YYYY"  name="dob" autocomplete="off" class="new-user form-control bg-transparent date_control" min="1950-01-01" max="2030-01-01"
                               formControlName="dob" [(ngModel)]="dob" [max]="maxDate" onkeydown="return false"/>
                      </div>
                      </div>
                    <div class="d-flex fv-row mb-1">
                      <div class="mt-3 mx-2 w-100" [hidden]="isReff">
                        <label class="input-label" for="address">Refferal Code</label>
                        <input placeholder="Enter Refferal Code"  autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="referralCode" />
                      </div>
                    </div>
                    <div class="d-flex fv-row row mb-1">
                      <div class="col mt-3 ml-2">
                        <label class="input-label required" for="gender">Gender</label> &nbsp; &nbsp;
                        <input class="my-3" type="radio" id="male" name="gender" value="Male" formControlName="gender"> Male
                        &nbsp;&nbsp;
                        <input class="my-3" type="radio" id="female" name="gender" value="Female" formControlName="gender">
                        Female &nbsp;&nbsp;
                        <input class="my-3" type="radio" id="other" name="gender" value="Other" formControlName="gender">
                        Other
                      </div>
                    </div>
                    <!-- <div class="d-flex fv-row mb-1">
                      <div>
                        <label class="input-label required" for="address">Street Address</label>
                        <input placeholder="Enter Address" name="address" autocomplete="off"
                               class="new-user form-control bg-transparent" formControlName="streetAddress" />
                      </div>&nbsp;&nbsp;
                      <div>
                        <label class="input-label required" for="pincode">Your Pincode</label>
                        <input type="number" placeholder="Enter Pincode" name="pincode" autocomplete="off" #pinCode
                               (blur)="pincodeUserChangeEvent(pinCode)" class="new-user form-control bg-transparent"
                               formControlName="pincodeId" />
                        <label> {{pincodeMaster.district}} {{pincodeMaster.state}} {{pincodeMaster.country}}</label>
                      </div>
                    </div> -->
                    <div class="d-flex fv-row mb-1">
                      <div class="mt-3 mx-2 w-100">
                        <label class="input-label required" for="passcode">Enter new password</label>
                        <input [type]="this.passwordType1" placeholder="Enter new password" name="passcode" autocomplete="off" input [type]=" showPassword1 ? 'text' : 'password' "
                               class="new-user form-control bg-transparent" formControlName="passcode" />
                               <i [class]="passwordShow1 === false ? 'fa fa-eye-slash field_icon field-icon2 fs-4' : 'fa fa-eye field-icon2 fs-4'" (click)="Showpassword1()"></i>
                        
                      </div>
                      <div class="mt-3 mx-2 w-100">
                        <label class="input-label required" for="confirmPassword">Confirm new password</label>
                        <input [type]="this.passwordType2" placeholder="Confirm new password" name="confirmPassword" input [type]=" showPassword2 ? 'text' : 'password' "
                               autocomplete="off" class="new-user form-control bg-transparent" formControlName="confirmPassword" />
                               <i [class]="passwordShow2 === false ? 'fa fa-eye-slash field_icon field-icon2 fs-4' : 'fa fa-eye field-icon2 fs-4'" (click)="Showpassword2()"></i>
                        <!-- <i class="fa fa-eye-slash field-icon" (click)="ShowPassword()" aria-hidden="true"></i> -->
                      </div>
                    </div>

                    <div class="row mt-5">
                      <div class="col-12 mx-2">
                        <re-captcha required  formControlName="recaptchaReactive"></re-captcha>
                      </div>
                    </div>
                    <div class="d-grid mb-10 mt-2 justify-content-center">
                      <button class="btn btn-primary submit-button" Stype="submit" id="kt_sign_in_submit" (click)="signupSubmit()"
                              [disabled]="signupForm.invalid">
                        <span class="indicator-label">Submit</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
    </div>
  </div>
</div>
  