import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-bike-insurance-policy-detail',
  templateUrl: './bike-insurance-policy-detail.component.html',
  styleUrls: ['./bike-insurance-policy-detail.component.css']
})
export class BikeInsurancePolicyDetailComponent {
  isSmallScreen: boolean = false; constructor() { this.checkScreenSize(); } 
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); } 
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }
}
