
<div class="container" >
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card mt-5 p-5">
                    <div class="row">
                        <div class="col-md-9">
                            <h6 class="text-start">
                                <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                                    class="cursor-pointer">&nbsp;&nbsp; Your
                                vehicle details
                            </h6>
                        </div>
                        <div class="col-md-3">
                            <div class="d-flex ">
                                <img class="edit-icon" src="../../assets/svgIcon/PencilLine.svg" alt="Edit" width="20%"
                                    height="20%">&nbsp;&nbsp;
                                <!-- <span class="fa fa-pencil-square"></span>&nbsp; -->
                                <h6 class="text-edit">Edit</h6>
                            </div>
                        </div>
                        <div class="col-md-2 mt-5">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJJKgExd3KX8QoCOdIGMpAt6XWrVTZJEiRZQ&usqp=CAU"
                                width="15" height="20" alt="Vehicle">&nbsp;&nbsp;

                        </div>
                        <div class="col-md-10 mt-5">
                            <div>
                                <p class="text-start font">Vehicle
                                    &nbsp;&nbsp;<span>Suzuki Motorcycle</span></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-5">
                                <div class="d-flex">
                                    <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;
                                    <div>
                                        <p class="font">Year</p>
                                        <h5>2023</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-5">
                                <div class="d-flex">
                                    <img src="../../assets/svgIcon/city.svg" alt="city">&nbsp;&nbsp;
                                    <div>
                                        <p class="font">RTO
                                            <br>
                                            <span>mp43- abc</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


                <div class="card mt-5 p-5">
                    <div class="text-start">
                        <h4>ADD-ONS Covers</h4>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input checkbox" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>PA Owner Cover</span>
                            <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Death or disability of owner</p>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input checkbox" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Zero Depreciation</span>
                            <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bumper to Bumper Cover</p>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input checkbox" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Invoice Cover</span>
                            <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Cover for total damage</p>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input checkbox" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>24*7 Roadside Assisatance</span>
                            <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Breakdown Coverage</p>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input checkbox" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Engine Protector</span>
                            <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Covers expenses for repairs replacement</p>
                        </label>
                    </div>




                </div>
                <div class="card mt-5 p-5">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="text-start">
                                <h6>
                                    Insured value (IDV)
                                </h6>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex">
                                <span class="fa fa-save"></span>&nbsp;
                                <h6 class="text-edit">Save</h6>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <p>
                                Clikc to know more about <a href="#">IDV</a>
                            </p>
                        </div>
                        <div class="col-md-3">
                            <p>₹32,500</p>
                        </div>
                        <div class="col-md-6">
                            <input type="range" class="form-range" id="customRange1">
                        </div>
                        <div class="col-md-3">
                            <p>₹47,670</p>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="text-start mt-5">
                    <h4>
                        Insurance Plan for you
                    </h4>
                </div>
                <div class="text-start">
                    <p>Covers damages to your vehicle for 1 year and third-party for 5 year </p>
                </div>

                <div class="mt-5 row ">
                    <div class="col-md-4 ">
                        <div class="card mb-5 ">
                            <div class="row mt-2 p-2">
                                <div class="col-2">
                                    <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending" width="24">
                                </div>
                                <div class="col-9">
                                    <h6>Sort By</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card col-md-12 p-5">
                        <div class="row">
                            <div class="col-md-1 ">
                                <img src="../../assets/svgIcon/funnel.svg" alt="funnel" width="17">
                            </div>
                            <div class=" col-md-3    d-flex justify-content-between tb-item">
                                <h6>Cover</h6>
                                <img src="../../assets/svgIcon/arrowDown.svg" alt="Arrow Down" width="17">
                            </div>
                            <div class=" col-md-3  tb-item">
                                <h6>Policy Period</h6>
                            </div>
                            <div class=" col-md-2  tb-item">
                                <h6>Insurer</h6>
                            </div>
                            <div class=" col-md-2  col-xl">
                                <h6>Premium</h6>
                            </div>
                        </div>

                    </div>
                    

                </div>
                <div class="card p-5 mt-3 row">
                    <div class="row align-value">
                        <div class="col-md-3">
                            <img class="image-stl" src="../../assets/svgIcon/PencilLine.svg " alt="">
                        </div>
                        <div class="col-md-3">
                            <h4  >IDV</h4    >
                            <h4> ₹21,500</h4>
                        </div>
                        
                        <div class="col-md-3">
                            <h4  >Claim Settled</h4  >
                            <h4>99%</h4>
                        </div>
                        <div class="col-md-3">
                            <img src="../../assets/svgIcon/Scales.svg" alt="Scales" width="28">&nbsp;&nbsp;
                            <img src="../../assets/svgIcon/Heart.svg" alt="Heart" width="28">&nbsp;&nbsp;
                            <img src="../../assets/svgIcon/ArrowsOut.svg" alt="ArrowsOut" width="28">&nbsp;&nbsp;
                            <img src="../../assets/svgIcon/Share.svg" alt="Share" width="28">
                        </div>
                                        </div>
                </div>
            </div>
        </div>
    </div>
</div>