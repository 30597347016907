export class hindiLabel {
  public product: string = 'उत्पाद';
  public support: string = 'समर्थन';
  public raise_a_query: string = 'प्रश्न उठाएं';
  public raise_a_claim: string = 'दावा उठाएं';
  public contact_us: string = 'संपर्क करें';
  public career: string = 'व्यवसाय';
  public gallery: string = 'गैलरी';
  public login: string = 'लॉग इन करें';
  public two_wheeler: string = 'दोपहिया';
  public four_wheeler: string = 'चौपहिया';
  public health: string = 'स्वास्थ्य';
  public commercial_vehicle: string = 'व्यावसायिक वाहन';
  public bike_ins_title: string = 'अपने दोपहिया बीमा पर विशेष रूप से कम कीमत प्राप्त करें';
  public bike_ins_start: string = 'दोपहिया वाहन बीमा ₹635* से शुरू';
  public enter_bike_number: string = 'दोपहिया नंबर दर्ज करें उदाहरण: AB12CD3456 ';
  public verify: string = 'सत्यापित करें';
  public dont_have_policy: string = 'पंजीकरण संख्या याद नहीं है?';
  public buy_new_bike: string = 'नया दोपहिया वाहन खरीदा?';
  public what_is_ins1: string = 'बीमा';
  public what_is_ins2: string = 'क्या है?';
  public what_is_ins_title: string = 'बीमा के साथ मन की शांति पाएं';
  public what_is_ins_text: string = "भविष्य की सुरक्षा के लिए आपके प्रवेश द्वार, हमारे Click4bima पोर्टल में आपका स्वागत है! हमारा पोर्टल बीमा विकल्पों की एक विस्तृत श्रृंखला का पता लगाने के लिए आपका वन-स्टॉप गंतव्य है। स्वास्थ्य और ऑटो से लेकर घर और यात्रा तक, हमने आपकी आवश्यकताओं के अनुरूप सर्वोत्तम कवरेज योजनाएं लाने के लिए प्रतिष्ठित बीमा कंपनियों के साथ साझेदारी की है। विभिन्न पॉलिसियों की तुलना करके, आप सूचित निर्णय ले सकते हैं और वह चुन सकते हैं जो आपकी जीवनशैली और बजट के अनुकूल हो।";
  public why_insurance_imp1: string = 'बीमा क्यों';
  public why_insurance_imp2: string = 'महत्वपूर्ण है?';
  public why_insurance_imp_title: string = 'कल को सुरक्षित करना: बीमा की जीवन शक्ति का अनावरण';
  public why_insurance_imp_text: string = 'बीमा जीवन की अनिश्चितताओं के खिलाफ आपकी ढाल है, जो आपको तब मानसिक शांति प्रदान करता है जब आपको इसकी सबसे अधिक आवश्यकता होती है। यह आपके और आपके चुने हुए बीमा प्रदाता के बीच एक अनुबंध है जो अप्रत्याशित घटनाओं के समय वित्तीय सहायता की गारंटी देता है। चाहे वह अचानक चिकित्सा व्यय हो, कोई ऑटोमोबाइल दुर्घटना हो, आपकी संपत्ति को नुकसान हो, या कोई अप्रत्याशित स्थिति हो, बीमा बोझ को कम करने के लिए कदम उठाता है।';
  public claim_assistance1: string = 'सहायता का';
  public claim_assistance2: string = 'दावा करें';
  public claim_assistance_title: string = 'आपके मन की शांति पुनः स्थापित हो गई';
  public claim_assistance_text: string = "Click4bima से 'दावा सहायता' के साथ, आप निश्चिंत हो सकते हैं कि आपके पास एक विश्वसनीय भागीदार है जो एक सहज दावा प्रक्रिया के महत्व को समझता है। यह जानकर अपने मन की शांति पुनः प्राप्त करें कि आप केवल एक पॉलिसी से अधिक द्वारा संरक्षित हैं - आप एक ऐसी टीम द्वारा समर्थित हैं जो परवाह करती है। जब आपको इसकी सबसे अधिक आवश्यकता होती है तो हमारी 'दावा सहायता' आपका विश्वसनीय साथी है।";
  public best_place_ins_title: string = 'भारत में बीमा खरीदने के लिए कौन सी चीज़ हमें सर्वश्रेष्ठ बनाती है?';
  public best_place_ins_text: string = 'हमारे Click4bima पोर्टल में आपका स्वागत है, जो भारत में सर्वोत्तम बीमा कवरेज खोजने के लिए आपका वन-स्टॉप गंतव्य है। आश्चर्य है कि हम बाकी लोगों से अलग क्यों खड़े हैं? सही बीमा कवरेज की सुरक्षा के साथ चिंता मुक्त भविष्य को अपनाएँ। आज ही हमारे साथ अपनी बीमा यात्रा शुरू करें।';




  public everytime_support_title: string = '२४*७ सहायता';
  public everytime_support_text: string = "हमारी 24/7 बीमा सहायता से कभी भी, कहीं भी मानसिक शांति प्राप्त करें। हमारी समर्पित टीम आपको विशेषज्ञ सहायता प्रदान करने, आपके प्रश्नों का उत्तर देने और यह सुनिश्चित करने के लिए चौबीसों घंटे यहां मौजूद है कि आप हमेशा कवर रहें।."



  public best_price_ins_title: string = 'सर्वोत्तम मूल्य बीमा';
  public best_price_ins_text: string = 'हमारा मिशन सरल है: गुणवत्ता से समझौता किए बिना आपको सर्वोत्तम बीमा सौदे प्रदान करना। अनुकूलन योग्य पॉलिसियाँ की एक श्रृंखला के साथ, हम आपके बजट को बरकरार रखते हुए आपके भविष्य की सुरक्षा के लिए यहां हैं।';
  public reliable_title: string = 'भरोसेमंद';
  public reliable_text: string = 'बिना किसी अपवाद के, जो आपके लिए सबसे महत्वपूर्ण है उसकी रक्षा के लिए आप हम पर भरोसा कर सकते हैं। अपने वादों को पूरा करने के सिद्ध ट्रैक रिकॉर्ड के साथ, हम हर कदम पर आपकी मानसिक शांति सुनिश्चित करते हैं। अपने भविष्य की सुरक्षा में अटूट विश्वसनीयता के लिए हमें चुनें।';
  public easy_claim_title: string = 'दावा आसान हो गया';
  public easy_claim_text: string = 'हमारी सुव्यवस्थित प्रक्रियाएं और समर्पित सहायता टीम यह सुनिश्चित करती है कि जब आपको इसकी सबसे अधिक आवश्यकता हो तो आपको वह सहायता मिले जिसकी आपको आवश्यकता है। हम इस प्रक्रिया को सरल बनाने के लिए प्रतिबद्ध हैं ताकि आप तुरंत उस चीज़ पर वापस आ सकें जो आपके जीवन में सबसे महत्वपूर्ण है।';
  public mobile_app_title: string = 'एक ही ऐप से भुगतान करें, निवेश करें, अपना पैसा बचाएं';
  public mobile_app_content: string = 'कहीं भी, कभी भी अपनी सभी बीमा आवश्यकताओं पर नियंत्रण प्राप्त करें';

  public mobile_app_compare: string = 'विभिन्न बीमा पॉलिसियों की तुलना करें';
  public mobile_app_buy: string = 'अपनी सभी पॉलिसियाँ ऑनलाइन खरीदें, संग्रहीत करें और साझा करें';
  public mobile_app_track: string = 'चलते-फिरते अपनी पॉलिसी की स्थिति को ट्रैक करें';
  public mobile_app_download: string = 'एक टैप से अपनी पॉलिसी डाउनलोड करें';
  public mobile_app_coming: string = 'कुछ अद्भुत चीज़ के लिए तैयार हो जाइए - हमारे ऐप्स जल्द ही लॉन्च होंगे!';
  public testimonial_title: string = 'हम अपने ग्राहक अनुभव का भी ख़्याल रखते हैं';
  public our_partner_title: string = 'हमारे सहयोगी';
  public our_partner_text: string = 'हम आपको सर्वोत्तम सेवाएं प्रदान करने के लिए मिलकर काम करते हैं।';
  public need_help_title: string = 'सहायता चाहिए ?';
  public need_help_text: string = 'आपको सर्वोत्तम सेवाएं प्रदान करने के लिए हम मिलकर काम करते हैं। Click4bima पोर्टल पर, हम आपकी बीमा यात्रा के हर चरण पर असाधारण ग्राहक सेवा और सहायता प्रदान करने के लिए प्रतिबद्ध हैं। आइए बीमा की दुनिया में आगे बढ़ने में हम आपके विश्वसनीय भागीदार बनें। आज ही हमारी सहायता टीम से संपर्क करें और अंतर का प्रत्यक्ष अनुभव करें। आपके मन की शांति हमारी प्राथमिकता है!';
  public mail_us: string = 'हमें ईमेल करें';
  public mobile_number: string = 'मोबाइल नंबर';
  public whatsapp: string = 'व्हाट्सएप';
  public about_us: string = 'हमारे बारे में';
  public sitemap: string = 'साइटमैप';
  public faqs: string = 'अक्सर पूछे जाने वाले प्रश्न';
  public motor_insurance: string = 'मोटर बीमा';
  public car_insurance: string = 'कार बीमा';
  public two_wheeler_insurance: string = 'दोपहिया वाहन का बीमा';
  public taxi_insurance: string = 'टैक्स बीमा';
  public commercial_veh_insurance: string = 'व्यावसायिक वाहन बीमा';
  public third_party_car_ins: string = 'थर्ड पार्टी कार बीमा';
  public third_party_bike_ins: string = 'थर्ड पार्टी 2W बीमा';
  public health_insurance: string = 'स्वास्थ्य बीमा';
  public family_health_ins: string = 'परिवार स्वास्थ्य पॉलिसी';
  public individual_health_ins: string = 'व्यक्तिगत स्वास्थ्य पॉलिसी';
  public group_health_ins: string = 'ग्रुप स्वास्थ्य पॉलिसी';
  public senior_citizen_health_ins: string = 'वरिष्ठ नागरिक स्वास्थ्य पॉलिसी';
  public critical_illness_ins: string = 'गंभीर बीमारी पॉलिसी';
  public arogya_san_ins: string = 'आरोग्य संजीवनी पॉलिसी';
  public life_insurance: string = 'जीवन बीमा';
  public term_insurance: string = 'सावधि बीमा';
  public single_pay_term_ins: string = 'एकल भुगतान अवधि बीमा ';
  public group_term_ins: string = 'ग्रुप सावधि बीमा';
  public saving_plans: string = 'बचत योजनाएँ';
  public tax_saving_ins: string = 'टैक्स बचत पॉलिसी';
  public money_back_ins: string = 'मनी बैक पॉलिसी';
  public guaranteed_return_ins: string = 'गारंटीकृत वापसी पॉलिसी';
  public ulip: string = 'यू एल आई पी';
  public pension_plan: string = 'पेंशन योजना';
  public child_plan: string = 'बाल योजना';
  public others: string = 'अन्य';
  public privacy_policy: string = 'निजी पॉलिसी';
  public cancellation_refund: string = 'रद्दीकरण एवं धनवापसी';
  public term_condition: string = 'नियम एवं शर्तें';
  public disclaimer: string = 'अस्वीकरण';
  public grivance_redressal: string = 'निवारण';

  public srg_add_title: string = 'एसआरजी इंश्योरेंस ब्रोकर्स प्राइवेट लिमिटेड';
  public srg_add_detail: string = '2 & 5, विनायक कॉम्प्लेक्स, दुर्गा नर्सरी रोड, उदयपुर, राजस्थान, 313001';
  public direct_broker_title: string = 'डायरेक्ट ब्रोकर, लाइसेंस संख्या: DB-290';
  public direct_broker_detail: string = 'वैधता अवधि: 02.02.2026 तक,CIN: U74140RJ2002PTC017656';
  public  payment_method:string='भुगतान की विधि';
  public  copyright:string='© कॉपीराइट 2023 Click4bima। सर्वाधिकार सुरक्षित';   
    public continue:string='जारी रखें';
    public bought_new_bike:string='नया दोपहिया वाहन खरीदा';
    public bought_new_car:string='नई कार खरीदी';
    public car_ins_title:string='अपने कार बीमा पर अत्यंत कम मूल्य प्राप्त करें';
    public car_ins_start:string='कार बीमा ₹2471* से शुरू';
    public enter_car_number:string='कार नंबर दर्ज करें उदाहरण: AB12CD3456 ';  
    public vehicle_detail_form:string='आपके वाहन का विवरण';
    public city:string='शहर';
    public rto:string="आरटीओ";
    public manufacturer:string="उत्पादक"; 
    public model:string="मॉडल"; 
    public fuel_type:string="ईंधन प्रकार";
    public variant:string="वेरिएंट"; 
    public reg_year:string="पंजीकरण वर्ष"; 
    public get_quotes:string="कोट्स प्राप्त करें";  
    public search_city:string="शहर खोजें";          
    public select_city:string="या शहर का चयन करें"; 
    public select_rto:string='शहर आरटीओ का चयन करें';
    public search_manufacturer:string='उत्पादक खोजें';
    public select_manufacturer:string='या उत्पादक का चयन करें';  
    public search_model:string='मॉडल खोजें'; 
    public select_model:string='या मॉडल चुनें'; 
    public search_variant:string='वेरिएंट खोजें';
    public select_variant:string='या वेरिएंट चुनें';
    public select_year:string='वर्ष चुनें';
    public select_fuel_type:string='ईंधन प्रकार चुनें';  
    public contact_title:string='हमारे साथ संपर्क में रहें';
    public contact_text:string='हम यथाशीघ्र आपसे संपर्क करेंगे।';
    public full_name:string='पूरा नाम'; 
    public mobile:string='मोबाइल नंबर';              
    public interested_in:string='में दिलचस्पी है'  ;           
    public email:string='ई-मेल पता'  ;                    
    public description:string='विवरण';            
    public call_me:string='मुझे वापस कॉल करें';        
    public continue_fb:string='फेसबुक के साथ जारी रखें';
    public continue_google:string='गूगल के साथ जारी रखें';
    public password:string='पासवर्ड';           
    public remember:string='मुझे याद रखें';  
    public dont_have_acc:string="कोई खाता नहीं है";
    public signup_here:string="यहां साइनअप करें"; 
    public click_here:string="यहाँ क्लिक करें";  
    public send_otp:string="ओटीपी भेजें";
    public back_login:string="लॉगिन पर वापस जाएं";    
    public already_hv_acc:string="क्या आपके पास पहले से खाता मौजूद है";          
    public signin_here:string="यहाँ साइन इन करें"; 
    public verification:string="सत्यापन  ";
    public verify_text:string="आपके नंबर +918543871911 पर भेजा गया 6 अंकों का कोड दर्ज करें। अपना मोबाइल नंबर बदलने के लिए यहां क्लिक करें।";
    public enter_otp:string="ओटीपी दर्ज करें";
    public dont_rec_otp:string="यदि आपको कोई कोड प्राप्त नहीं हुआ! पुन: भेजें";     
    public submit:string="सबमिट करें";
    public accept_terms:string="अपना खाता बनाकर, आप हमारे नियमों और शर्तों से सहमत होते हैं";  
    public basic_details:string ="मूल विवरण";               
    public first_name:string ="पहला नाम";
    public last_name:string ="उपनाम";        
    public dob:string ="जन्मतिथि";            
    public referal_code:string ="रेफ़रल कोड";                
    public gender:string ="लिंग";
    public new_password:string  ="नया पासवर्ड दर्ज करें";  
    public confirm_old_pass:string  ="नये पासवर्ड की पुष्टि करें";                      

    public sign_in:string='साइन इन करें';     
    public dont_have_policy_popup:string='यदि आपको अपना दोपहिया वाहन नंबर याद नहीं है, तो अपने दोपहिया वाहन की बुनियादी जानकारी प्राप्त करने के लिए अपना आरसी (पंजीकरण प्रमाणपत्र) अपने पास तैयार रखें।';
    public year:string='वर्ष';
    
   public your_details:string='अपना विवरण दर्ज करें';
   public name_title:string='शीर्षक';

  }