<div class="content-box">
  <div class="container-fluid">
    <div class="card shadow" style="margin-top: 2rem;">
    <div class="card-user mb-5">
        <h2>
          <b class="float-start text-primary mt-4 ms-4">Roles List</b>
        </h2><br><br>
      <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 ms-4 pt-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a href="../../demo1/dist/index.html" class="text-muted text-hover-primary">Home</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">User Management</li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">Roles</li>
        <!--end::Item-->
      </ul>
      <div class="float-end me-5">
        <a href="#" class="btn btn-primary" data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_app">Create</a>
      </div>
      </div>
     <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container container-xxl">
        <!--begin::Row-->
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title">
                  <h2>Administrator</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">Total users with this role: 5</div>
                <!--end::Users-->
                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>All Admin Controls
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View and Edit Financial Summaries
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>Enabled Bulk Reports
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View and Edit Payouts
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View and Edit Disputes
                  </div>
                  <div class='d-flex align-items-center py-2'>
                    <span class='bullet bg-primary me-3'></span>
                    <em>and 7 more...</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <a href="../../demo1/dist/apps/user-management/roles/view.html"
                 class="btn btn-light my-1 me-2">View Role</a>
                <button type="button" class="btn btn-primary my-1" data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role">Edit Role</button>
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title">
                  <h2>Developer</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">Total users with this role: 14</div>
                <!--end::Users-->
                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>Some Admin Controls
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Financial Summaries only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View and Edit API Controls
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Payouts only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View and Edit Disputes
                  </div>
                  <div class='d-flex align-items-center py-2'>
                    <span class='bullet bg-primary me-3'></span>
                    <em>and 3 more...</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <a href="../../demo1/dist/apps/user-management/roles/view.html"
                class="btn btn-light my-1 me-2">View Role</a>
                <button type="button" class="btn btn-primary my-1" data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role">Edit Role</button>
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title">
                  <h2>Analyst</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">Total users with this role: 4</div>
                <!--end::Users-->
                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>No Admin Controls
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View and Edit Financial Summaries
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>Enabled Bulk Reports
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Payouts only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Disputes only
                  </div>
                  <div class='d-flex align-items-center py-2'>
                    <span class='bullet bg-primary me-3'></span>
                    <em>and 2 more...</em>
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <a href="../../demo1/dist/apps/user-management/roles/view.html"
                class="btn btn-light my-1 me-2">View Role</a>
                <button type="button" class="btn btn-primary my-1" data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role">Edit Role</button>
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title">
                  <h2>Support</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">Total users with this role: 23</div>
                <!--end::Users-->
                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>No Admin Controls
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Financial Summaries only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Payouts only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View and Edit Disputes
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>Response to Customer Feedback
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <a href="../../demo1/dist/apps/user-management/roles/view.html"
                class="btn btn-light my-1 me-2">View Role</a>
                <button type="button" class="btn btn-primary my-1" data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role">Edit Role</button>
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-md-4">
            <!--begin::Card-->
            <div class="card card-flush h-md-100">
              <!--begin::Card header-->
              <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title">
                  <h2>Trial</h2>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-1">
                <!--begin::Users-->
                <div class="fw-bold text-gray-600 mb-5">Total users with this role: 546</div>
                <!--end::Users-->
                <!--begin::Permissions-->
                <div class="d-flex flex-column text-gray-600">
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>No Admin Controls
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Financial Summaries only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Bulk Reports only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Payouts only
                  </div>
                  <div class="d-flex align-items-center py-2">
                    <span class="bullet bg-primary me-3"></span>View Disputes only
                  </div>
                </div>
                <!--end::Permissions-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer flex-wrap pt-0">
                <a href="../../demo1/dist/apps/user-management/roles/view.html"
                class="btn btn-light my-1 me-2">View Role</a>
                <button type="button" class="btn btn-primary my-1" data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_role">Edit Role</button>
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Col-->
          <!--begin::Add new card-->
          <div class="ol-md-4">
            <!--begin::Card-->
            <div class="card h-md-100">
              <!--begin::Card body-->
              <div class="card-body d-flex flex-center">
                <!--begin::Button-->
                <button type="button" class="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal"
                  data-bs-target="#kt_modal_add_role">
                  <!--begin::Illustration-->
                  <img src="assets/media/illustrations/sketchy-1/4.png" alt="" class="mw-100 mh-150px mb-7" />
                  <!--end::Illustration-->
                  <!--begin::Label-->
                  <div class="fw-bold fs-3 text-gray-600 text-hover-primary">Add New Role</div>
                  <!--end::Label-->
                </button>
                <!--begin::Button-->
              </div>
              <!--begin::Card body-->
            </div>
            <!--begin::Card-->
          </div>
          <!--begin::Add new card-->
        </div>
        <!--end::Row-->

      </div>
      <!--end::Content container-->
    </div>
    </div>
    <!-- </div> -->
  </div>
