<app-navbar (languageChanged)="languageChanged()"></app-navbar>
<div class="container mb-3">
    <div class="container-fluid">
        <div class="first_card">
            <div class="row">
                <div class="col-md-8 contactUs">
                    <img src="../../assets/images/contactus_banner.svg" class="imgresponsive">
                </div>
                <div class="col-md-4 contactUs1">
                    <div class="contact-card p-5  ">
                        <h3 class="card_title">{{langList.contact_title}}</h3>
                        <p class="font-body ">{{langList.contact_text}}</p>
                        <form [formGroup]="contactUsForm" class="needs-validation" novalidate="" autocomplete="off">
                            <div class="row mt-10">
                                <div class="col">
                                    <label for="name">{{langList.full_name}}</label>
                                    <input type="text" class="form-control" name="name" formControlName="name">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label for="name">{{langList.mobile}}</label>
                                    <input type="text" class="form-control" name="mobileNumber"
                                        formControlName="mobileNumber" oninput="this.value=(this.value.match('[0-9]+'))"
                                        onkeypress="if(this.value.length>=10) return false;">
                                </div>
                                <div class="col">
                                    <label for="name">{{langList.interested_in}}</label>
                                    <select class="form-select" aria-label="Default select example"
                                        formControlName="interestedIn">
                                        <option selected>Your Interest</option>
                                        <option value="Buy Bike Policy">Buy Bike Policy</option>
                                        <option value="Renew Bike Policy">Renew Bike Policy</option>
                                        <option value="Buy Private Car  Policy">Buy Private Car Policy</option>
                                        <option value="Renew Private Car Policy ">Renew Private Car Policy </option>
                                        <option value="Buy Commercial Vehicle Policy">Buy Commercial Vehicle Policy
                                        </option>
                                        <option value="Renew Commercial Vehicle Policy">Renew Commercial Vehicle Policy
                                        </option>
                                        <option value="Buy Health Policy">Buy Health Policy</option>
                                        <option value="Renew Health Policy">Renew Health Policy</option>
                                        <option value="Buy Travel Policy">Buy Travel Policy</option>
                                        <option value="Renew Travel Policy">Renew Travel Policy</option>

                                    </select>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label for="name">{{langList.email}}</label>
                                    <input type="text" class="form-control" name="email" formControlName="email">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label for="name"> Your Query</label>
                                    <input type="text" class="form-control" name="description"
                                        formControlName="description">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3 m-auto">
                                    <button class="btn btn-primary" [disabled]="contactUsForm.invalid"
                                        (click)="contactus()">{{langList.call_me}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="second_card">
            <div class="rowcard">
                <div class="col sub_card phone">
                    <div style="width: 100%;text-align: center;" [align]="'center'">
                        <div class="img_divs">
                            <img src="../../assets/images/contactus_phone.svg" alt="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <p><span class="text1">{{langList.whatsapp}}:</span> <span class="text2"> (+91) 98290 -
                                    82258</span></p>
                            <p><span class="text1">{{langList.mobile}}:</span> <span class="text2"> (+91) 98290 -
                                    82258</span></p>
                        </div>
                    </div>
                </div>
                <div class="col sub_card email">
                    <div style="width: 100%;text-align: center;" [align]="'center'">
                        <div class="img_divs">
                            <img src="../../assets/images/contactus_email.svg" alt="">
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col text-center">
                            <p><span class="text1">Contact Mail:</span> <span class="text2"> info@srggroup.net</span>
                            </p>
                            <p><span class="text1">Claim Mail:</span> <span class="text2"> claim@srggroup.net</span></p>
                        </div>
                    </div>
                </div>
                <div class="col sub_card location">
                    <div style="width: 100%;text-align: center;" [align]="'center'">
                        <div class="img_divs">
                            <img src="../../assets/images/contactus_location.svg" alt="">
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col text-center">
                            <p><span class="text1">{{langList.srg_add_detail}}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>