import { Component, OnInit } from '@angular/core';
import { SettingsComponent } from 'src/app/settings/settings.component';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.css']
})
export class ComparisonComponent  implements OnInit{
  ngOnInit(): void {

  }
  imgCheck:Boolean=false;
  heart:String='../../assets/svgIcon/Heart.svg'
  redHeart:String='../../../../assets/images/redHeart.png'
path:String='../../assets/svgIcon/Heart.svg'
  like():void{

    if(!this.imgCheck)
    {
      this.imgCheck=true;
this.path=this.redHeart;
    }
    else{
      this.imgCheck=false;
      this.path=this.heart;
    }
  }


}
