<app-navbar></app-navbar>
<div class="content-box">
    <div class="container">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">CANCELLATION & REFUND</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">The cancellation of the policy and/or the refund of the premium is processed
                    as
                    follow:</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Cancellation of the policy by you</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If you dont agree to certain terms and conditions in vour policy, you can cancel it any time
                        before
                        the renewal date. To initiate a cancellation, you need to write an application to the insurance
                        company stating the reasc for cancellind the bolicy. The insurance companv or e is the companv
                        whose
                        policy vol have purchased. Depending on when you have applied forIf you dont agree to certain
                        terms
                        and conditions in vour policy, you can cancel it any time before the renewal date. To initiate a
                        cancellation, you need to write an application to the insurance company stating the reasc for
                        cancellind the bolicy. The insurance companv or e is the companv whose policy vol have
                        purchased.
                        Depending on when you have applied for
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Cancellation of the policy by you</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If you dont agree to certain terms and conditions in vour policy, you can cancel it any time
                        before
                        the renewal date. To initiate a cancellation, you need to write an application to the insurance
                        company stating the reasc for cancellind the bolicy. The insurance companv or e is the companv
                        whose
                        policy vol have purchased. Depending on when you have applied forIf you dont agree to certain
                        terms
                        and conditions in vour policy, you can cancel it any time before the renewal date. To initiate a
                        cancellation, you need to write an application to the insurance company stating the reasc for
                        cancellind the bolicy. The insurance companv or e is the companv whose policy vol have
                        purchased.
                        Depending on when you have applied for
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Cancellation of the policy by you</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If you dont agree to certain terms and conditions in vour policy, you can cancel it any time
                        before
                        the renewal date. To initiate a cancellation, you need to write an application to the insurance
                        company stating the reasc for cancellind the bolicy. The insurance companv or e is the companv
                        whose
                        policy vol have purchased. Depending on when you have applied forIf you dont agree to certain
                        terms
                        and conditions in vour policy, you can cancel it any time before the renewal date. To initiate a
                        cancellation, you need to write an application to the insurance company stating the reasc for
                        cancellind the bolicy. The insurance companv or e is the companv whose policy vol have
                        purchased.
                        Depending on when you have applied for
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>