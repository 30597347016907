import { Component, OnInit ,Output, EventEmitter, Input, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InsuranceService } from '../Services/insurance.service';
import swal from 'sweetalert2';
import { ApiResponse } from '../Models/ApiResponse';
import { RegistrationModel } from '../Models/vehicle-component.model';
import { BusinessService } from '../Services/business.service';
import { LookUpService } from '../Services/lookup.service';

//#region don't have bike number
import { VehicleInfoModel, LookUpViewModel, RTONamesViewModel, PinCodeMasterViewModel } from './../Models/vehicle-component.model';
import { VehicleService } from '../Services/vehicle.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../Login/login.component';
import { ReviewvehicledetailsComponent } from '../reviewvehicledetails/reviewvehicledetails.component';
import { InsuranceBannerComponent } from '../home/insurance-banner/insurance-banner.component';
import { min } from 'moment';
import { LanguageLableService } from '../Services/language-lable.service';
import { GeneralService } from '../Services/general.service';
import { AutoCompleteItem } from '../Models/Item';

@Component({
  selector: 'app-dont-know-bike-number',
  templateUrl: './dont-know-bike-number.component.html',
  styleUrls: ['./dont-know-bike-number.component.css']
})
export class DontKnowBikeNumberComponent implements OnInit{
  @ViewChild('insurance') InsuranceBannerComponent: InsuranceBannerComponent;

  selCity:any = true;
  mftList:any = false;
  modelList:any = false;
  variList:any = false;
  yearList:any = false;
  rtoList:any = false;
  fuelType:any = false;

  tabsForm: FormGroup;
  vehicleDetailsForm: FormGroup;
  vehicleInfoForm: FormGroup;
  contactUsForm:FormGroup;
  customerInfoForm:FormGroup;

  vehicleModel = new VehicleInfoModel();
  vehicleCompanyList: LookUpViewModel[] = [];
  vehicleModelList: LookUpViewModel[] = [];
  vehicleVariantList: LookUpViewModel[] = [];
  fuelTypeLists: LookUpViewModel[] = [{name:'Petrol',id:1},{name:'Diesel',id:2},{name:'Electric',id:3},{name:'CNG',id:4}];
  cityList: any[];//RTONamesViewModel[] = [];
  rtoNamesList: RTONamesViewModel[] = [];
  year = new Date().getFullYear();
  years : any = [];
  registrationYear: any = [];    
  registrationMonthName: any = ['Jan','Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep', 'Oct', 'Nov','Dec'];
  registrationMonth: any = [];
  cityName: string = "";
  make: string = "";
  model: string = "";
  variant: string = "";
  fuel: string = "";
  knowBike: boolean = true;
  isnew:boolean= false;
  submitted: boolean=false;
  titleList: string[] = ["Mr","Mrs","Ms","M/s"];
  tabNumber:number=0;
  @Output() dataEvent = new EventEmitter<any>();
  @Output() dataEventProposerDetails = new EventEmitter<any>();
  @Input() isBike:boolean;
  @Input() newVehicle:any;

  tabs = [ { label: 'City/RTO', type: 'city', id: '1a' }, { label: 'Manufacturer', type: 'manufacturer', id: '2a' }, { label: 'Model', type: 'model', id: '3a' }, { label: 'Variant', type: 'variant', id: '4a' }, { label: 'Fuel Type', type: 'fuelType', id: '5a' }, { label: 'Year', type: 'year', id: '6a' } ];
  cutomerDetail: boolean = false;
  showFuelTab: boolean;
  selectedValue: string | null;
  langList: any;
  todayDate = new Date();
  pincodeList: AutoCompleteItem[] = [];

  constructor(private languageLableService: LanguageLableService,private fb: FormBuilder, private insuranceservice: InsuranceService,
    private spinner: NgxSpinnerService, private vehicleService: VehicleService,public modalRef: BsModalRef, private generalService : GeneralService,
    private businessService: BusinessService, private router: Router, private modalService: BsModalService, private lookUpService: LookUpService) {
      this.tabsForm = this.fb.group({
        "rto": ["", Validators.required],
        "make": ["", Validators.required],
        "model": ["", Validators.required],
        "variant": ["", Validators.required],
        "registrationYear": ["", Validators.required],
        "registrationMonth": ["", Validators.required],        
        "city": ["", Validators.required],
        "ncbprevious": ["0"],
        "fuelType": [""],
        "isNew":[""],
        "registrationDate":[""],
        "pincode":[""]
      });

      this.customerInfoForm = this.fb.group({
        "firstName": ['', [Validators.required]],
        "lastName": ['', [Validators.required]],
        "email": ["",[ Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        "mobileNumber": ["", [Validators.required, Validators.pattern('[0-9]{10}')]],
        "title":['Mr']
      });

      this.vehicleDetailsForm = this.fb.group({
        "vehicleDetails": this.fb.group({
          "model": [''],
          "make": [''],
          "registrationYear": ['', ''],
          "registrationMonth": [""],
          "variant": [''],
          "city": ['', ''],
          "rto": ['', ''],
          "fuelType": ['', ''],
          "isNew":['']
        }),
        'insuranceCompany': [3]
      });
      this.contactUsForm = this.fb.group({
        "name": ['', [Validators.required]],
        "mobileNumber": ['', [Validators.required]],
        "interestedIn": ['', [Validators.required]],
        "email": ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        "description": ['', [Validators.required]],
      });
  this.loadBikeForm();
  }

  loadBikeForm() {
    this.vehicleInfoForm = this.fb.group({
      "model": ['', [Validators.required]],
      "make": ['', [Validators.required]],
      "registrationYear": ['', [Validators.required]],
      "variant": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "rto": ['', [Validators.required]],

    });
  }

   isUserLogged: boolean = false;
   ngOnInit() {
     
    const d = this.todayDate;
    let month = d.getMonth();
     
    if(month==0)
    { 
      this.years.push(d.getFullYear()-1);
      this.years.push(d.getFullYear());
    }
    else
    {
      this.years.push(d.getFullYear());
    }

    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
    
    console.log(this.isBike,"Bike/Car");
    if(this.isBike == true){
      // this.showFuelTab = false;
      // this.tabsForm.get('fuelType')?.setValue('Petrol');
      this.fuelTypeLists =  [{name:'Petrol',id:1},{name:'Electric',id:3},{name:'CNG',id:4}];
    }else{
      // this.showFuelTab = true;
      this.fuelTypeLists =  [{name:'Petrol',id:1},{name:'Diesel',id:2},{name:'Electric',id:3},{name:'CNG',id:4}];
    }

    if(this.newVehicle == 1){
      this.isnew = true;      
    }else{
      this.isnew = false;
    }
    this.tabsForm.get("isNew")?.setValue(this.isnew);
    this.getPolicyCityList("");
     if(this.generalService.getUniqueuserid() != ""){
       this.isUserLogged = true;
     }
     this.InsuranceBannerComponent?.showBanner();
   }

  changeTabs(event: any, tabNumber: any) {
    this.tabNumber=tabNumber;
    console.log(this.tabNumber);
    if(tabNumber == 1){
      this.selCity = true;
      this.mftList = false;
      this.modelList = false;
      this.variList = false;
      this.yearList = false;
      this.rtoList = false;
      this.fuelType = false;
    }
    else if(tabNumber == 2){
      this.selCity = false;
      this.mftList = true;
      this.modelList = false;
      this.variList = false;
      this.yearList = false;
      this.rtoList = false;
      this.fuelType = false;
    }
    else if(tabNumber == 3){
      this.selCity = false;
      this.mftList = false;
      this.modelList = true;
      this.variList = false;
      this.yearList = false;
      this.rtoList = false;
      this.fuelType = false;
    }
    else if(tabNumber == 4){
      this.selCity = false;
      this.mftList = false;
      this.modelList = false;
      this.variList = true;
      this.yearList = false;
      this.rtoList = false;
      this.fuelType = false;
    }
    else if(tabNumber == 5){
      this.selCity = false;
      this.mftList = false;
      this.modelList = false;
      this.variList = false;
      this.yearList = false;
      this.rtoList = false;
      this.fuelType = true;
    }
    else if(tabNumber == 6){
      this.selCity = false;
      this.mftList = false;
      this.modelList = false;
      this.variList = false;
      this.yearList = true;
      this.rtoList = false;
      this.fuelType = false;
    }
    else{
      this.selCity = true;
      this.mftList = false;
      this.modelList = false;
      this.variList = false;
      this.yearList = false;
      this.rtoList = false;
      this.fuelType = false;
    }
    // var tabs = document.getElementsByClassName("selected-box");
    
    // for (var i = 0; i < tabs.length; i++) {
    //   if(tabNumber > i+1)
    //   {
    //     tabs[i].classList.add("active");
    //   }
    //   else
    //   {
    //     tabs[i].classList.remove("active");
    //   }
      
    // }
    
  }

  
  async onBlurCity(cityName: string) {
    if (cityName.length > 1) {
      this.cityList = [];
      this.rtoNamesList = [];
      //this.vehicleInfoForm.controls['rTO'].disable();
      if (cityName == undefined)
        cityName = "";
      const res = await this.vehicleService.GetPolicyCityRTOs(cityName);
      if (res != null && res.data) {
        this.cityList = res.data;
      }

    }
   else if (!cityName)
      await this.getPolicyCityList(cityName)
  }

  async getPolicyCityList(cityName?: string) {
    this.cityList = [];
    this.rtoNamesList = [];
    this.vehicleInfoForm.controls['rto'].disable();
    if (cityName == undefined)
      cityName = "";
    //const res = await this.vehicleService.GetPolicyCities(cityName);
    const res = await this.vehicleService.GetPolicyCityRTOs(cityName);    
    if (res != null && res.data) {
      ;
      this.cityList = res.data;

    }
  }

  async changeYears(record: any) {
    this.vehicleDetailsForm.value.vehicleDetails.registrationYear = record.toString();
    this.tabsForm.get('registrationYear')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.registrationYear);
    this.tabNumber=7;
    this.getFinancialMonths(record.toString());
  }

  async changemonth(record: any) {
    this.vehicleDetailsForm.value.vehicleDetails.registrationMonth = record.toString();
    this.tabsForm.get('registrationMonth')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.registrationMonth);
  }
  

  async getVehicleReg(record: any) {
    this.variList = !this.variList;
    // if(this.isBike == true){
    //   this.yearList = !this.yearList;
    // }else{
    //   this.fuelType = !this.fuelType;
    // }
    this.fuelType = !this.fuelType;
    this.vehicleDetailsForm.value.vehicleDetails.variant = record;
    this.tabsForm.get('registrationYear')?.setValue("");
    this.tabsForm.get('variant')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.variant);
    this.registrationYear = [];
    this.registrationMonth = [];
    this.getFinancialYears();
    this.tabNumber=5;
    console.log(this.tabNumber);
    this.getFuelTypeList();
  }

  async getFuelType(record: any) {
    console.log(record);
    this.fuelType = !this.fuelType;
    this.yearList = !this.yearList;
    this.vehicleDetailsForm.value.vehicleDetails.fuelType = record;
    this.tabsForm.get('registrationYear')?.setValue("");
    this.tabsForm.get('fuelType')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.fuelType);
    this.tabNumber=6;
    console.log(this.tabNumber);
  }

  getFinancialYears() {
    this.registrationYear = [];
    this.registrationMonth = [];
    for (var i = (this.year - 24); i < (this.year); i++) {
      this.registrationYear.push(i).orderb
    }
    if (this.registrationYear)
      this.registrationYear.sort(function (a: any, b: any) { return b - a })
  }

  getFinancialMonths(year:string) {
    const d = this.todayDate;
    let month:number = 12;
    let startMonth = 0;
    let todayDayNumber = d.getDate();
    if(d.getFullYear().toString() == year){
      
      month = d.getMonth() + 1;
      if( todayDayNumber == 1){
        startMonth = d.getMonth() - 1;
      }
      else
      {
        startMonth = d.getMonth();
      }
    }    
    this.registrationMonth = [];
  
    for (var i = startMonth; i < month; i++) {
      this.registrationMonth.push(this.registrationMonthName[i])
    }
  }

  async getVehicleVariantDetails(record: any, vehicleModelId: any) {
    this.modelList = !this.modelList;
    this.variList = !this.variList;
    this.vehicleVariantList = [];
    this.registrationYear = [];
    this.registrationMonth = [];
    this.tabsForm.get('variant')?.setValue("");
    this.tabsForm.get('registrationYear')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.model = record;
    this.tabsForm.get('model')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.model);
    let request = { ModelName: vehicleModelId.toString(),CompanyName: this.vehicleDetailsForm.value.vehicleDetails.make , Type : (this.isBike == true ? 2 : 1) };
    const res = await this.vehicleService.GetVehicleVariantDetails(request);
    if (res != null && res.result) {
      this.vehicleVariantList = res.result;
    }
    this.tabNumber=4;
    console.log(this.tabNumber);
  }
  async searchVehicleVariantDetails(name:string) {
    this.vehicleVariantList = [];
    this.registrationYear = [];
    this.registrationMonth = [];    
    let request = { ModelName: this.vehicleDetailsForm.value.vehicleDetails.model,Name: name,CompanyName: this.vehicleDetailsForm.value.vehicleDetails.make , Type : (this.isBike == true ? 2 : 1)};
    const res = await this.vehicleService.GetVehicleVariantDetails(request);
    if (res != null && res.result) {
      this.vehicleVariantList = res.result;
    }
  }
  async getVehicleModelDetails(record: any, vehicleCompanyId: any) {
    this.mftList = !this.mftList;
    this.modelList = !this.modelList;
    this.registrationYear = [];
    this.registrationMonth = []; 
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.tabsForm.get('model')?.setValue("");
    this.tabsForm.get('variant')?.setValue("");
    this.tabsForm.get('registrationYear')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.make = record;
    this.tabsForm.get('make')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.make);
    //let request = { CompanyName: vehicleCompanyId.toString() ,Name:name};
    let request = { CompanyName: this.vehicleDetailsForm.value.vehicleDetails.make ,Name:name, Type : (this.isBike == true ? 2 : 1)};

    const res = await this.vehicleService.GetVehicleModelDetails(request);
    if (res != null && res.result) {
      this.vehicleModelList = res.result;
    }
    this.tabNumber=3;
    console.log(this.tabNumber);
  }

  handleRtoClick(rto: any) {this.rtoChange(rto); this.tabNumber=1;console.log(this.tabNumber);}

  async searchVehicleModelDetails(name?:string) {
    this.registrationYear = [];
    this.registrationMonth = []; 
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    let request = { CompanyName: this.vehicleDetailsForm.value.vehicleDetails.make ,Name:name, Type : (this.isBike == true ? 2 : 1)};
    const res = await this.vehicleService.GetVehicleModelDetails(request);
    if (res != null && res.result) {
      this.vehicleModelList = res.result;
    }
  }
  async rtoChange(record: any) {
    this.rtoList = !this.rtoList;
    this.mftList = !this.mftList;
    this.tabsForm.get('make')?.setValue("");
    this.tabsForm.get('model')?.setValue("");
    this.tabsForm.get('variant')?.setValue("");
    this.tabsForm.get('registrationYear')?.setValue("");
    this.vehicleDetailsForm.value.vehicleDetails.rto = record.regionCode;
    this.tabsForm.get('rto')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rto);
    await this.getVehicleCompanyDetails();
  }
  async getVehicleCompanyDetails(name?:string) {
    this.vehicleCompanyList = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.registrationYear = [];
    this.registrationMonth = []; 
    let request = {Type : (this.isBike == true ? 2 : 1) , Name:name};
    const res = await this.vehicleService.GetVehicleCompanyDetails(request);
    if (res != null && res.result) {
      this.vehicleCompanyList = res.result;
    }
    this.tabNumber=2;
  }

  async cityChange(cityName: any,rtoName: any) {
    
    this.selCity = !this.selCity;
    this.rtoList = !this.rtoList;
    this.vehicleDetailsForm.value.vehicleDetails.city = cityName;
    this.tabsForm.get('city')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.city);
    this.vehicleDetailsForm.value.vehicleDetails.rto = rtoName;
    this.tabsForm.get('rto')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rto);
    this.tabsForm.get('make')?.setValue("");
    this.tabsForm.get('model')?.setValue("");
    this.tabsForm.get('variant')?.setValue("");
    this.tabsForm.get('registrationYear')?.setValue("");
    this.rtoNamesList = [];
    this.vehicleCompanyList = [];
    this.vehicleModelList = [];
    this.vehicleVariantList = [];
    this.registrationYear = [];
    this.registrationMonth = []; 
    this.mftList = true;
    await this.getVehicleCompanyDetails();
    // const res = await this.vehicleService.RTODetails(rtoName);
    // if (res != null && res.result) {
    //   this.rtoNamesList = res.result;
    // }
    this.tabNumber=2;
    // console.log(this.tabNumber);
  }

  async getFuelTypeList(){
    this.fuelTypeLists=[];
    let request = {CompanyName:this.vehicleDetailsForm.value.vehicleDetails.make, ModelName: this.vehicleDetailsForm.value.vehicleDetails.model,VariantName: this.vehicleDetailsForm.value.vehicleDetails.variant};
    const res = await this.vehicleService.GetVehicleFuelDetails(request);
    if (res != null && res.result) {
      this.fuelTypeLists = res.result.map((item:any,index:number)=>{return {name:item,id : index +1}});
      this.fuelTypeLists = this.generalService.distinctFuel(this.fuelTypeLists);

    }
  }

  async GetQuoteInfo() {
    this.submitted=true;
    debugger;
    if(this.customerInfoForm.valid)
    {      
      let leadDetails ={
        name : this.customerInfoForm.value.firstName + " " + this.customerInfoForm.value.lastName,
        email: this.customerInfoForm.value.email,
        mobileNumber:this.customerInfoForm.value.mobileNumber
      };
      const res = await this.lookUpService.SaveLeadsDetail(leadDetails);      
      this.tabsForm.get("isNew")?.setValue(this.isnew);
      this.submitForm();
    }   
  }

  async submitForm()
  {
    let month = (this.registrationMonthName.indexOf(this.tabsForm.value.registrationMonth) + 1).toString();
      month = month.length == 1 ? "0"+ month : month;
      const d = this.todayDate;
      let dayNumberInt = d.getDate();
      let dayNumber =  dayNumberInt< 10 ? "0" + dayNumberInt.toString() : dayNumberInt.toString();
      if(this.isnew == true){        
        if(this.registrationMonthName.indexOf(this.tabsForm.value.registrationMonth) == d.getMonth()){        
         
        }
        else{
          dayNumber = new Date(d.getFullYear(), d.getMonth()+1, 0).getDay().toString();
        }
      }  
      
      let lastDayOfMonth = new Date(this.tabsForm.value.registrationYear, month , 0).getDate();
      if(lastDayOfMonth < parseInt(dayNumber)){
        dayNumber = lastDayOfMonth.toString();
      }

      this.tabsForm.get('registrationDate')?.setValue(this.tabsForm.value.registrationYear +'-'+ month  + "-"+dayNumber);

      if(this.vehicleDetailsForm.value.vehicleDetails.rto.indexOf("-") < 0){
        if(this.vehicleDetailsForm.value.vehicleDetails.rto.length == 4)
          {
            this.tabsForm.get('rto')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rto.substr(0, 2) + "-" + this.vehicleDetailsForm.value.vehicleDetails.rto.substr(2, 2));
          }
          else if (this.vehicleDetailsForm.value.vehicleDetails.rto.length == 3)
          {
            this.tabsForm.get('rto')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rto.substr(0, 2) + "-" + this.vehicleDetailsForm.value.vehicleDetails.rto.substr(2, 1));
          }          
      }
      else
      {
        this.tabsForm.get('rto')?.setValue(this.vehicleDetailsForm.value.vehicleDetails.rto);
      }
      
      let obj ={
        vehicleDetails : this.tabsForm.value,
        proposerDetails : this.generalService.getUniqueuserid() != "" ? null :this.customerInfoForm.value
      }
      this.dataEvent.emit(obj);
  }

  async customerInfo() {
    if(this.generalService.getUniqueuserid() != ""){
      this.submitForm();
    }
    else
    {
      this.cutomerDetail=true;
    }    
  }

  async cancel(){

  }

  async cityRtoValue(){
    alert('hi');
  }
  closeModal() {
    this.cutomerDetail=false;
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
  }

  get customerInfoFormControl() {
    return this.customerInfoForm.controls;
  }

  getActiveClass(tab:number)
  {
    let className='';
    switch(tab)
    {
      case 1: className = this.tabNumber == 1? 'onemore' : ( this.tabNumber >= 2 ? 'active': '');break;
      case 2: className =  this.tabNumber == 2? 'onemore' : ( this.tabNumber >= 3 ? 'active': '');break;
      case 3: className =  this.tabNumber == 3? 'onemore' : ( this.tabNumber >= 4 ? 'active': '');break;
      case 4: className =  this.tabNumber == 4? 'onemore' : ( this.tabNumber >= 5 ? 'active': '');break;
      case 5: className =  this.tabNumber == 5? 'onemore' : ( this.tabNumber >= 6 ? 'active': '');break;
      case 6: className =  this.tabNumber == 6? 'onemore' : ( this.tabNumber >= 7 ? 'active': '');break;
    }
    console.log(tab + '' + className);
    return className;
    
  }

  titleOnChange(){
    if(this.customerInfoFormControl['title'].value == "M/s"){
      this.customerInfoForm.get('lastName')?.clearValidators();
      this.customerInfoForm.get('lastName')?.updateValueAndValidity();
    }
  }

  async onPincodeChange(data:any) {
    if(data.length >= 3){
      const res = await this.lookUpService.pincodeMasterDetails(data);
      if (res != null && res.result) {
        this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      }
    }    
  }

  onPincodeSelection(data:AutoCompleteItem)
  {
    debugger;
   this.tabsForm.get('pincode')?.setValue(data.pincodeMaster?.pincode);
  }

  onInputChange(event: any): void {
    const initialValue = event.target.value;
   const input = event.target as HTMLInputElement;

    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (input.value.length > 6) {
        input.value = input.value.slice(0, 6);
        event.stopPropagation();
  
      }
    if (initialValue !== event.target.value) {
      event.stopPropagation();
      
    }
  }

  onPincodeBlur(data:any)
  {
      this.tabsForm.get('pincode')?.setValue(data);
  }
}
