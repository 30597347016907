<div class="container" style="background-color: #f3f3f4;" *ngIf="!isCreate">
    <div class="row">
        <div class="col-md-4">
            <div class="card mt-5 p-4">
                <div class="row">
                    <div class="col-md-8">
                        <p><img src="../../../assets/svgIcon/ArrowLeft.svg" alt=""> &nbsp; Insured members</p>
                    </div>
                    <div class="col-md-4">
                        <a href=""><img src="../../../assets/images/PencilLineblue.png" alt="">&nbsp;&nbsp;Edit</a>
                    </div>
                    <div class="col-md-6 mt-3">
                        <span class="mb-2">
                            Self
                        </span> <br>
                        <p>
                            32 Year
                        </p>

                    </div>
                    <div class="col-md-6 mt-3">
                        <span class="mb-2">
                            Spouse’s
                        </span>
                        <p>
                            28 Year
                        </p>

                    </div>
                    <div class="col-md-6 mt-3">
                        <span class="mb-2">
                            1st Daughter
                        </span>
                        <p>
                            07 Year
                        </p>

                    </div>
                    <div class="col-md-6 mt-3">
                        <span class="mb-2">
                            2nd Daughter
                        </span>
                        <p>
                            03 Year
                        </p>

                    </div>
                </div>
            </div>
            <div class="card mt-4 p-3">
                <div class="row">
                    <div class="col-md-9">
                        <p> &nbsp; What is your existing illness ?</p>
                    </div>
                    <div class="col-md-3">
                        <a href=""><img src="../../../assets/images/PencilLineblue.png" alt="">&nbsp;&nbsp;Edit</a>
                    </div>
                    <div class="col-md-12 mt-3 ">
                        <div class="card p-1 crd ">
                            <p class="p-size">
                                Diabetes
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3 ">
                        <div class="card p-1 crd ">
                            <p class="p-size">
                                Heart Ailments
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3 ">
                        <div class="card p-1 crd ">
                            <p class="p-size">
                                BP / Hypertension
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3 ">
                        <div class="card p-1 crd ">
                            <p class="p-size">
                                Other health issues
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3 ">
                        <div class="card p-1  ">
                            <p class="p-size">
                                Surgical procedure
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3 ">
                        <div class="card p-1  ">
                            <p class="p-size">
                                None of the Above
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="text-start mt-5">
                <h4>
                    Insurance Plan for you
                </h4>
            </div>
            <div class="text-start">
                <span>Showing 24 plans for you</span>
            </div>

            <div class="mt-5 row ">

                <!-- <div class="card col-md-8 p-3">
                    <div class="row">
                        <div class="col-md-1 ">
                            <img src="../../assets/svgIcon/funnel.svg" alt="funnel" width="17">
                        </div>
                        <div class=" col-md-3    d-flex justify-content-between tb-item">
                            <h6>Cover</h6>
                            <img src="../../assets/svgIcon/arrowDown.svg" alt="Arrow Down" width="17">
                        </div>
                        <div class=" col-md-3  tb-item">
                            <h6>Policy Period</h6>
                        </div>
                        <div class=" col-md-2  tb-item">
                            <h6>Insurer</h6>
                        </div>
                        <div class=" col-md-2  col-xl">
                            <h6>Premium</h6>
                        </div>
                    </div>

                </div> -->
                <div class="col-md-8">
                    <div class="card  p-3    mt-2">
                        <div class="row">
                            <div class="col-md-1">

                                <img src="../../assets/svgIcon/funnel.svg" alt="funnel" width="17">

                            </div>
                            <div class="col-md-3" >
                                <h6 (click)="getPopUp()">Cover &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img
                                        src="../../assets/svgIcon/arrowDown.svg" alt="Arrow Down" width="15"></h6>

                            </div>
                            <div class="col-md-3">
                                <h6>Policy Period</h6>
                            </div>
                            <div class="col-md-3">
                                <h6>Insurer</h6>
                            </div>
                            <div class="col-md-2">
                                <h6>Premium</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="card p-3 mt-2">
                        <div class="row ">
                            <div class="col-2">
                                <img src="../../assets/svgIcon/SortAscending.svg" alt="SortAscending" width="24">
                            </div>
                            <div class="col-9">
                                <h6>Sort By</h6>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="card mt-3 p-4">
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <img src="../../../assets/images/united.png" width="95%" height="95%" alt="">
                    </div>
                    <div class="col-md-6 mt-3">
                        <p>Royal Sundaram Lifeline </p>
                        <span>NCB Super Premium</span>
                    </div>
                    <div class="col-md-3 mt-3">
                        <img src="../../assets/svgIcon/Scales.svg" alt="Scales" width="28">&nbsp;&nbsp;
                        <img src="../../assets/svgIcon/Heart.svg" alt="Heart" width="28">&nbsp;&nbsp;
                        <img src="../../assets/svgIcon/ArrowsOut.svg" alt="ArrowsOut" width="28">&nbsp;&nbsp;
                        <img src="../../assets/svgIcon/Share.svg" alt="Share" width="28">
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3"><span>
                            Cover amount
                        </span>
                        <p>
                            ₹5 Lakh
                        </p>
                    </div>
                    <div class="col-md-3"><span>
                            Cashless hospitals
                        </span>
                        <p>
                            155
                        </p>
                    </div>
                    <div class="col-md-3">
                        <span>
                            Claims Settled
                        </span>
                        <p>
                            99%
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p style="color: #3A8247;">
                            <img src="../../../assets/images/percent.png" alt="">&nbsp;&nbsp;&nbsp;5% Discount
                        </p>
                    </div>

                </div>
                <div class="card mt-4 p-4" style="background-color: #0A0A261A;">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row text-center ">
                                <div class="col-md-4 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            Single private ac room
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            Day care treatment </span>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            ₹2.5 Lakh no claim bonus </span>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            24 Supported Garages in (Your City) </span>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            Restoration of cover once a year </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button class="btn btn-sm text-start" (click)="createQuote()"><span
                                            class="sp">₹{{finalPremium}}</span></button>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="card text-center p-2 mt-2">
                <span class="text-center" style="color: #10A0C1;">
                    View 3 More Policies&nbsp;&nbsp;&nbsp;&nbsp;<img src="../../assets/svgIcon/arrowDown.svg" alt="">
                </span>
            </div>
            <!-- <div class="card mt-3 p-4">
                <div class="row">
                    <div class="col-md-3 mt-3">
                        <img src="../../../assets/images/united.png" width="95%" height="95%" alt="">
                    </div>
                    <div class="col-md-6 mt-3">
                        <p>United India Insurance </p>
                        <span>NCB Super Premium</span>
                    </div>
                    <div class="col-md-3 mt-3">
                        <img src="../../assets/svgIcon/Scales.svg" alt="Scales" width="28">&nbsp;&nbsp;
                        <img src="../../assets/svgIcon/Heart.svg" alt="Heart" width="28">&nbsp;&nbsp;
                        <img src="../../assets/svgIcon/ArrowsOut.svg" alt="ArrowsOut" width="28">&nbsp;&nbsp;
                        <img src="../../assets/svgIcon/Share.svg" alt="Share" width="28">
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-3"><span>
                            Cover amount
                        </span>
                        <p>
                            ₹5 Lakh
                        </p>
                    </div>
                    <div class="col-md-3"><span>
                            Cashless hospitals
                        </span>
                        <p>
                            155
                        </p>
                    </div>
                    <div class="col-md-3">
                        <span>
                            Claims Settled
                        </span>
                        <p>
                            99%
                        </p>
                    </div>
                    <div class="col-md-3">
                        <p style="color: #3A8247;">
                            <img src="../../../assets/images/percent.png" alt="">&nbsp;&nbsp;&nbsp;5% Discount
                        </p>
                    </div>

                </div>
                <div class="card mt-4 p-4" style="background-color: #0A0A261A;">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row text-center ">
                                <div class="col-md-4 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            Single private ac room
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            Day care treatment </span>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            ₹2.5 Lakh no claim bonus </span>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            24 Supported Garages in (Your City) </span>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3 ">
                                    <div class="card p-1 ">
                                        <span class="f-size">
                                            Restoration of cover once a year </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 ">
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button class="btn btn-sm"><span>₹2,500/month</span></button>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <span class="mt-3">₹17464 annually</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="card text-center p-2 mt-2">
                <span class="text-center" style="color: #10A0C1;">
                    View 3 More Policies&nbsp;&nbsp;&nbsp;&nbsp;<img src="../../assets/svgIcon/arrowDown.svg" alt="">
                </span>
            </div> -->
        </div>
    </div>
</div>
<div class="overlay" *ngIf="popup && !isCreate">
    <div class="popup2">
        <div class="modal-header">
            <!-- <h4 class="modal-title">Sign In</h4> -->
            <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="model-ve">
            <div class="p-5">
                <h4>Your Insurance Plan</h4>
            </div>
            <div class="row">
                <div class="col-md-12 mt-2">
                    <div class="card crd mt-3 p-3">
                        <div class="row">
                            <div class="col-md-9 mt-1">
                                <p>
                                    3-5 lakh
                                </p>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1">
    
                                </div>
                            </div>
                        </div>
                    </div>

                </div> <div class="col-md-12 mt-2">
                    <div class="card crd mt-3 p-3">
                        <div class="row">
                            <div class="col-md-9 mt-1">
                                <p>
                                    5-7 lakh
                                </p>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1">
    
                                </div>
                            </div>
                        </div>
                    </div>

                </div> <div class="col-md-12 mt-2">
                    <div class="card crd mt-3 p-3">
                        <div class="row">
                            <div class="col-md-9 mt-1">
                                <p>
                                    7-9 lakh
                                </p>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1">
    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="isCreate"> 
    <app-healthinsurance-details [premiumObject]="premiumObject"></app-healthinsurance-details>
</div>