import { Component } from '@angular/core';

@Component({
  selector: 'app-carInsurance-policy-detail',
  templateUrl: './carInsurance-policy-detail.component.html',
  styleUrls: ['./carInsurance-policy-detail.component.css']
})
export class carInsurancePolicyDetailComponent {

}
