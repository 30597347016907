import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { LookUpService } from 'src/app/Services/lookup.service';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { GeneralService } from 'src/app/Services/general.service';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { alphaNumericOnly } from 'src/app/functions/validation-functions';
@Component({
  selector: 'app-agent-kyc-verification',
  templateUrl: './agent-kyc-verification.component.html',
  styleUrls: ['./agent-kyc-verification.component.css']
})
export class AgentKycVerificationComponent {
  saveAdhaarForm: FormGroup;
  savePanForm: FormGroup;
  saveImageForm: FormGroup;
  saveEducationForm: FormGroup;
  //aadhaarNumber: any;
  pincode: any;
  pincodeId: string = "";
  panNumber: any;
  idNumber: string;
  source: string;
  validationForm: FormGroup;
  docNum: number;
  adhaarDetails: any = {};
  panDetails: any;
  idNumber2: string;
  button1: boolean = false;
  button2: boolean = false;
  button3: boolean = false;
  userId: string | null;
  pincodeList: AutoCompleteItem[] = [];
  cityList: AutoCompleteItem[] = [];
  districtList: AutoCompleteItem[] = [];
  stateList: AutoCompleteItem[] = [];
  aadharMsg: string = "";
  aadharCode: number = 0;
  PanMsg: string = "";
  PanCode: number = 0;
  submitted: boolean = false;
  Pansubmitted: boolean = false;
  maxDOB = moment(new Date()).add(-18, 'years').format('YYYY-MM-DD');
  aadharStatus:number = 0;
  panStatus:number = 0;
  selfieStatus:number = 0;
  educationStatus:number = 0;
  constructor(private router: Router, private fb: FormBuilder, private spinner: NgxSpinnerService, private agentprofileService: AgentprofileService, private lookUpService: LookUpService,private generalService: GeneralService) {
    this.saveAdhaarForm = this.fb.group({
      "aadhaarNumber": [''],
      "gender": ['', [Validators.required]],
      "address": ['', [Validators.required]],
      "pincode": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "state": ['', [Validators.required]],
      "pincodeId": [''],
      "aadhaarFrontUpload": ['', [Validators.required]],
      "aadhaarBackUpload": ['', [Validators.required]],
      "firstName" : ['', [Validators.required]],
      "lastName" : ['', [Validators.required]],
      "userDocuments":[],
      "status":[2],
      "userId":[0]
    });

    this.validationForm = this.fb.group({
      "id_number": [''],
      "source": [''],
      "docNum": [''],
    });

    this.savePanForm = this.fb.group({
      "uploadPAN": ['', [Validators.required]],
      "panNumber": ['', [Validators.required]],
      "panName": ['',[Validators.required]],
      "dob":['',[Validators.required]],
      "userDocument":[],
      "status":[2],
      "userId":[0]
    });

    this.saveImageForm = this.fb.group({
      "agentSelfie": ['', [Validators.required]],
      "status":[2],
      "userId":[0]
    });

    this.saveEducationForm = this.fb.group({
      "qualificationID": ['', [Validators.required]],
      "uploadMarksheet": ['', [Validators.required]],
      "userDocument":[],
      "status":[2],
      "userId":[0]
    });
  }

  frontImage: string | ArrayBuffer | null = null;
  backImage: string | ArrayBuffer | null = null;
  panImage: string | ArrayBuffer | null = null;
  verifySelfie: string | ArrayBuffer | null = null;
  marksheet: string | ArrayBuffer | null = null;

  onAgentSelfieSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.verifySelfie = null;      
      this.saveImageForm.get('agentSelfie')?.setValue("");
      if (file!.size > 5242880) {        
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File size should not be greater than 5MB',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else if(!(file?.type?.toLocaleLowerCase().includes('jpeg') || file?.type?.toLocaleLowerCase().includes('jpg') || file?.type?.toLocaleLowerCase().includes('png'))){
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File format accepted only JPEG & PNG',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else{
        this.agentSelfie(file);
      }
    }
  }

  agentSelfie(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.verifySelfie = e.target.result;
      this.saveImageForm.get('agentSelfie')?.setValue(e.target.result);
    };

    reader.readAsDataURL(file);
  }

  onAgentMarksheetSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.marksheet = null;      
      this.saveEducationForm.get('uploadMarksheet')?.setValue("");
      if (file!.size > 5242880) {        
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File size should not be greater than 5MB',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else if(!(file?.type?.toLocaleLowerCase().includes('jpeg') || file?.type?.toLocaleLowerCase().includes('jpg') || file?.type?.toLocaleLowerCase().includes('png'))){
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File format accepted only JPEG & PNG',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else{
        this.agentMarksheet(file);
      }
    }
  }

  agentMarksheet(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.marksheet = e.target.result;
      this.saveEducationForm.get('uploadMarksheet')?.setValue(e.target.result);      
    };

    reader.readAsDataURL(file);
  }

  onFrontSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
        this.frontImage = null;      
        this.saveAdhaarForm.get('aadhaarFrontUpload')?.setValue("");
      if (file!.size > 5242880) {        
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File size should not be greater than 5MB',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else if(!(file?.type?.toLocaleLowerCase().includes('jpeg') || file?.type?.toLocaleLowerCase().includes('jpg') || file?.type?.toLocaleLowerCase().includes('png'))){
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File format accepted only JPEG & PNG',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else{
        this.previewfrontImage(file);
      }
    }
  }
 
  previewfrontImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.frontImage = e.target.result;      
      this.saveAdhaarForm.get('aadhaarFrontUpload')?.setValue(e.target.result);
    };
 
    reader.readAsDataURL(file);
  }
 
  onBackSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.backImage = null;      
      this.saveAdhaarForm.get('aadhaarBackUpload')?.setValue("");
      if (file!.size > 5242880) {        
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File size should not be greater than 5MB',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else if(!(file?.type?.toLocaleLowerCase().includes('jpeg') || file?.type?.toLocaleLowerCase().includes('jpg') || file?.type?.toLocaleLowerCase().includes('png'))){
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File format accepted only JPEG & PNG',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else{
        this.previewbackImage(file);
      }
    }
  }
 
  previewbackImage(file: File): void {
    const reader = new FileReader();
 
    reader.onload = (e: any) => {
      this.backImage = e.target.result;
      this.saveAdhaarForm.get('aadhaarBackUpload')?.setValue(e.target.result);
    };
 
    reader.readAsDataURL(file);
  }

  onPanSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.panImage = null;      
      this.savePanForm.get('uploadPAN')?.setValue("");
      if (file!.size > 5242880) {        
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File size should not be greater than 5MB',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else if(!(file?.type?.toLocaleLowerCase().includes('jpeg') || file?.type?.toLocaleLowerCase().includes('jpg') || file?.type?.toLocaleLowerCase().includes('png'))){
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'File format accepted only JPEG & PNG',
          showConfirmButton: false,
          timer: 3000,
        });
      }
      else{
        this.panCardImage(file);
      }
    }
  }

  panCardImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.panImage = e.target.result;
      this.savePanForm.get("uploadPAN")?.setValue(e.target.result);
    };

    reader.readAsDataURL(file);
  }
  Success: boolean = false;
  steps: string = 'aadhar';
  selfy: string = '1';
  adharSuccess: boolean = false;
  panSuccess: boolean = false;
  selfieSuccess: boolean = false;
  educationSuccess: boolean = false;
  first: boolean = true;
  second: boolean = true;
  third: boolean = true;
  four: boolean = true;
  isAadharUnderReview:boolean = false;
  isPanUnderReview:boolean = false;
  isSelfieUnderReview:boolean = false;
  isEducationUnderReview:boolean = false;
  async nextStep(name: string) {
    if (name == 'aadhar') {
      this.updateStatus();
      this.steps = name;
      this.first = true; 
    }
    else if (name == 'pan') {
      if(this.isAadharUnderReview == false)
      {
        this.submitted = true;
        this.spinner.show();
        const response1 = await this.agentprofileService.SaveAadhaar(this.saveAdhaarForm.value);
        if(response1?.isSuccess && response1.result && response1.result.code > 0){
          this.adharSuccess = true;
          this.aadharCode = 200;
          this.aadharMsg;
          this.first = false;
          this.aadharStatus = 2;
          this.isAadharUnderReview = true;
          this.updateStatus();
          this.spinner.hide();
          this.steps = name;
        }
        else{
          this.aadharMsg = response1?.result?.message;
          this.aadharCode = response1?.result?.subcode;
          this.spinner.hide();
        }
      }
      else
      {
        this.steps = name;
        this.adharSuccess = true;
        this.first = false; 
      }   
    }
    else if (name == 'selfie') {
      if(this.isPanUnderReview == false)
      {
        this.spinner.show();
        const response2 = await this.agentprofileService.SavePan(this.savePanForm.value);
        if(response2?.isSuccess && response2.result && response2.result.code > 0){
          this.panSuccess = true;
          this.second = false; 
          this.panStatus = 2;
          this.isPanUnderReview = true;
          this.updateStatus();
          this.spinner.hide();
          this.steps = name;
        }
        else
        {
          this.spinner.hide();
        }        
      }
      else{
        this.panSuccess = true;
        this.steps = name;
        this.second = false;        
      }
    }
    else if (name == 'Educational') {
        if(this.isSelfieUnderReview == false)
        {
            this.spinner.show();
            const response3 = await this.agentprofileService.SaveAgentPicture(this.saveImageForm.value);
            if(response3?.isSuccess){
              this.selfieSuccess = true;
              this.third = false;
              this.selfieStatus = 2;
              this.isSelfieUnderReview = true;
              this.updateStatus();
              this.spinner.hide();
              this.steps = name;
            }
            else
            {
              this.spinner.hide();
            }
        }
        else
        {
          this.steps = name;
          this.selfieSuccess = true;
          this.third = false;
        }
    }
  }
  nextSelfy(page: string) {
    this.selfy = page;
  }
  GoBack() {
    this.router.navigate(['/agent-profile']);
  }
  close() {
    this.Success = false;
  }
  async success() {
    if(this.isEducationUnderReview == false)
    {
      this.spinner.show();
      const response4 = await this.agentprofileService.SaveAgentQualification(this.saveEducationForm.value);
      if(response4?.isSuccess){
        this.four = false;
        this.isEducationUnderReview = true;
        this.educationStatus = 2;
        this.updateStatus();
        this.educationSuccess = true;
        this.Success = true;
        this.spinner.hide();
      }
      else
      {
        this.spinner.hide();
      }
    }
    else{
      this.four = false;
      this.educationSuccess = true;
      window.location.href = window.location.href;
    }    
  }
  exit() {
    location.reload();
  }
  back(value: string) {
    location.reload();
    localStorage.setItem("true", value);
  }
  async adhaarValidate() {
    this.spinner.show();
    this.idNumber = this.saveAdhaarForm.get('aadhaarNumber')?.value?.toString();
    this.docNum = 2;
    this.source = '';
    this.validationForm.get('id_number')?.setValue(this.idNumber);
    this.validationForm.get('source')?.setValue(this.source);
    this.validationForm.get('docNum')?.setValue(this.docNum);
    const response = await this.agentprofileService.GetAgentKYCDetails(this.validationForm.value);
    if (response?.isSuccess && response?.result?.status_code == 200) {
      this.adhaarDetails = response.result;
      this.aadharMsg = "Aadhar number is verified";
      this.aadharCode = 200;
      if (this.adhaarDetails != '') {
        this.button1 = true;
      }
      else {
        this.button1 = false;
      }
    }
    else {
      this.aadharCode = response?.result?.status_code ?? 201;
      this.aadharMsg = "Please enter valid Aadhar number";
    }
    this.spinner.hide();
  }
holder:string|null;
invalid:boolean=false;
  async panValidate() {
    this.spinner.show();
    this.idNumber = this.PanForm['panNumber'].value.toString();
    this.docNum = 1;
    this.source = '';
    // this.validationForm.get('id_number')?.setValue(this.idNumber);
    // this.validationForm.get('source')?.setValue(this.source);
    // this.validationForm.get('docNum')?.setValue(this.docNum); 
    let obj = {
      docNum: 1,
      source: '',
      id_number: this.savePanForm.get('panNumber')?.value
    }
    const response = await this.agentprofileService.GetAgentKYCDetails(obj);
    if (response?.isSuccess) {
      this.panDetails = response.result;
      this.holder = this.panDetails.full_name;
      this.savePanForm.get('panName')?.setValue(this.holder);
      this.savePanForm.get('dob')?.setValue(this.panDetails.dob);      
      if (this.holder != null) {
        this.PanMsg = "Pan card number is verified";
        this.PanCode = 200;
        this.button2 = true;
        this.button3 = false;
        this.invalid=false;
      }
      else {
        this.button2 = false;
        this.button3 = false;
        this.PanCode = response?.result?.status_code ?? 201;
        this.PanMsg = "Enter your valid Pan card number";
        this.invalid=true;
      }

    }
    else {
      this.PanCode = response?.result?.status_code ?? 201;
      this.PanMsg = response?.result?.message;
    }

    this.spinner.hide();
  }
  async pincodeChange(data: any) {
    if (data.length >= 3) {
      const res = await this.lookUpService.pincodeMasterDetails(data)

      if (res != null && res.result) {
        this.pincodeList = res.result.map((item: any) => { return { text: item.pincode, value: item.pincode, text1: item.cityVillageArea, id: item.id, pincodeMaster: item } });
      }
    }
  }
  onPincodeSelection(data: AutoCompleteItem) {
    this.getDistrictsByState(data.pincodeMaster?.state?.toUpperCase() ?? "");
    this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase() ?? "");
    this.saveAdhaarForm.get('pincode')?.setValue(data.pincodeMaster?.pincode);
    this.saveAdhaarForm.get('pincodeId')?.setValue(data.pincodeMaster?.pincode?.toUpperCase());
    this.saveAdhaarForm.get('city')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    //this.saveAdhaarForm.get('district')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    this.saveAdhaarForm.get('state')?.setValue(data.pincodeMaster?.state?.toUpperCase());
  }
  onDistrictSelection(data: AutoCompleteItem) {
    // this.saveAdhaarForm.get('city')?.setValue(data.text);
    this.saveAdhaarForm.get('district')?.setValue(data.text);
    this.getPincodesByDistrict(data.text);
    console.log(this.saveAdhaarForm.value);
  }

  onCitySelection(data: AutoCompleteItem) {
    this.saveAdhaarForm.get('city')?.setValue(data.text);
  }

  onStateSelection(data: AutoCompleteItem) {
    this.getDistrictsByState(data.text);
    this.saveAdhaarForm.get('state')?.setValue(data.text);
  }


  async getPincodesByDistrict(district: string) {
    let response = await this.lookUpService.getPincodeByDistrict(district);
    if (response?.isSuccess) {
      //this.pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
      this.cityList = response.result.map((item: any) => { return { text: item.cityVillageArea, value: item.cityVillageArea, text1: item.pincode, id: item.id } });
    }
  }

  async getDistrictsByState(state: string) {
    let response = await this.lookUpService.getDistrictsListByState(state);
    if (response?.isSuccess) {
      this.districtList = response.result.map((item: any) => { return { text: item.name, value: item.name, id: item.name } });
    }
  }

  get userFormControl() {
    return this.saveAdhaarForm.controls;
  }

  // async getVillageAreaByPincode(pincode: string) {
  //   let response = await this.lookUpService.getVillageAreaByPincode(pincode);
  //   if (response?.isSuccess) {
  //     this.pincodeList = response.result.map((item: any) => {
  //       return { text: item.cityVillageArea, value: item.cityVillageArea, id: item.id };
  //     });
  //   }
  // }
  id: any;
  mychange(val: any) {
    const self = this;
    let chIbn = val.split(' ').join('');
    if (chIbn.length > 0) {
      chIbn = chIbn.match(new RegExp('.{1,4}', 'g')).join(' ');
    }
    console.log(chIbn);
    this.id = chIbn;
  }
  ValueAdhar: any;

  formatInput(event: any) {

    let input = event.target as HTMLInputElement;
    let value = input.value.replace(/\s+/g, ''); // Remove existing spaces
    let newValue = '';

    for (let i = 0; i < value.length; i += 4) {
      newValue += value.substr(i, 4) + ' ';
    }

    this.saveAdhaarForm.get('aadhaarNumber')?.setValue(newValue.trim());
    this.aadharMsg = "";
    this.aadharCode = 0;
    if (this.saveAdhaarForm.get('aadhaarNumber')?.value?.length < 14) {
      { this.button1 = false; }
    }
  }

  aadharNumberSpaceOnly(event:any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    debugger;
    if ((charCode >= 48 && charCode <= 57 || charCode == 32)) {
      if(event.target.value.length=='14'){
        return false
      }
      return true;
    }
    return false;
  }

  get AdharForm() {
    return this.saveAdhaarForm.controls;
  }

  get PanForm() {
    return this.savePanForm.controls;
  }
  isAadharValid(): boolean {
    let d= this.saveAdhaarForm;
    let dd = this.aadharCode;
    let ddd = this.isAadharUnderReview;
    let valid = !(this.saveAdhaarForm.valid && (this.aadharCode == 200 || this.isAadharUnderReview == true));
    return valid;
  }
  PanCheck() {
    if (this.PanForm['panNumber'].value?.toString().length < 10) {
    this.holder=null;
      this.PanMsg = "Enter Valid Pan Number";
      this.invalid=false;
    }
  }

  ngOnInit(): void {
    this.getAadharDetails();
    this.getPanDetails();
    this.getProfileImageDetails();
    this.getQualificationDetails();

    let statusObj = this.generalService.getDataToLocalStorage("agentStatus");
    if(statusObj != "")
    {
      let obj = JSON.parse(statusObj);
      this.aadharStatus = obj.ADR_Status;
      this.panStatus = obj.PAN_Status;
      this.selfieStatus = obj.IMG_Status;
      this.educationStatus = obj.QUL_Status;
    }
  }

  async getAadharDetails(){
    this.spinner.show();
    const response = await this.agentprofileService.GetAadharDetailsByUserId(this.generalService.getUniqueuserid());
    if (response?.isSuccess && response?.result) {
      this.saveAdhaarForm.patchValue(response.result);
      this.button1 = true;
      this.aadharCode = 200;
      this.adharSuccess = true;
      this.first = false;
      this.isAadharUnderReview = this.saveAdhaarForm.value.status == 2 || this.saveAdhaarForm.value.status == 1;
      this.backImage = this.saveAdhaarForm.value.aadhaarBackUpload;
      this.frontImage = this.saveAdhaarForm.value.aadhaarFrontUpload;

      if(this.saveAdhaarForm != null){
        let value = this.saveAdhaarForm.get('aadhaarNumber')?.value;
        let newValue = "";
        for (let i = 0; i < value.length; i += 4) {
          newValue += value.substr(i, 4) + ' ';
        }
        this.saveAdhaarForm.get('aadhaarNumber')?.setValue(newValue);
      }
      this.spinner.hide();
    }
    else{
      this.spinner.hide();
    }
  }

  async getPanDetails(){
    const response = await this.agentprofileService.GetPanDetailsByUserId(this.generalService.getUniqueuserid());
    if (response?.isSuccess && response?.result) {
      this.savePanForm.patchValue(response.result);
      this.panSuccess = true;
      this.second = false;
      this.isPanUnderReview = this.savePanForm.value.status == 2 || this.savePanForm.value.status == 1;
      this.holder = this.savePanForm.get('panName')?.value;
      this.panImage = this.savePanForm.value.uploadPAN;
    }
  }

  async getProfileImageDetails(){
    const response = await this.agentprofileService.GetProfileImgDetailsByUserId(this.generalService.getUniqueuserid());
    if (response?.isSuccess && response?.result) {
      this.saveImageForm.patchValue(response.result);
      //this.saveImageForm.get('agentSelfie')?.setValue(e.target.result);
      let fdfd = this.saveImageForm;
      this.selfieSuccess = true;
      this.third = false;
      this.isSelfieUnderReview = this.saveImageForm.value.status == 2 || this.saveImageForm.value.status == 1;
      this.verifySelfie = this.saveImageForm.value.agentSelfie;
    }
  }

  async getQualificationDetails(){
    const response = await this.agentprofileService.GetQualficationDetailsByUserId(this.generalService.getUniqueuserid());
    if (response?.isSuccess && response?.result) {
      this.saveEducationForm.patchValue(response.result);
      this.educationSuccess = true;
      this.four = false;
      this.isEducationUnderReview = this.saveEducationForm.value.status == 2 || this.saveEducationForm.value.status == 1;
      this.marksheet = this.saveEducationForm.value.uploadMarksheet;
    }
  }

  updateStatus(){
    if(this.isAadharUnderReview == false){
      this.adharSuccess = false;
      this.first = true;
    }  
    if(this.isPanUnderReview == false){
      this.second = true;
      this.panSuccess = false;
    }
    if(this.isSelfieUnderReview == false){
      this.third = true;
      this.selfieSuccess = false;
    }
    if(this.isEducationUnderReview == false){
      this.four = true;      
      this.educationSuccess = false;
    }
  }

  alphaNumericOnly(event:any): boolean {
    return alphaNumericOnly(event);
  }
}