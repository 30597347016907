<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">

            <div class="row">
                <h1>Arogya Sanjeevani Policy:</h1>
            </div>
            <div class="top-line"></div>
            <div class="row">
                <div class="mt-3">
                    <p class="font-body">Arogya Sanjeevani Policy is a Standard Health Insurance Product (SHIP) set by
                        the Insurance Regulatory and Development Authority of India (IRDAI) from April 01, 2020. This
                        new plan simplify health insurance by providing insurer the basic and standard plan that will be
                        offered by various health insurance companies with exactly the same benefits.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">There are different health Insurance Company who provide this policy , there is
                        difference in the plan&rsquo;s premium quoted by them and the services offered during the
                        purchase process, claim settlement process and other related processes as given in the Policy
                        Documents.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Types :</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">1. Individual Plan</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">This type of plan allows only 1 policyholder as the beneficiary.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">2. Family Plan</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">The proposer can include their spouse, dependent children, parents, and
                        parents-in-law
                        under the plan for a fixed sum insured against a single premium.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">A specified discount is available when more than one member is covered under a
                        single
                        proposal with an individual sum insured.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Eligibility&nbsp;</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The minimum entry age is 18 years and the maximum entry age allowed under the
                        plan is
                        65 years.&nbsp;</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Under the Family Plan of Arogya Sanjeevani Policy, the minimum age allowed for
                        children
                        is 3 months, whereas the maximum age for children is 25 years.&nbsp;</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">However, if the child above 18 years of age is financially independent, he or
                        she shall
                        be ineligible for the coverage in the subsequent renewals.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Features&nbsp;</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">The Arogya Sanjeevani Health Insurance Policy comes with the following
                        features:</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Affordable Premium</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Low Co-payment</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">15-Day Free Look Period</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Life-long Renewability</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Tax Benefit</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">No Medical Check-up Up To The Age of 45 Years</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Sum Insured Options</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">The sum insured ranges from a minimum of Rs. 1,00,000 to a maximum of Rs.
                        5,00,000 in
                        multiples of Rs. 50,000.</p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Annual Policy it comes with a tenure of 1 year.It can be renewed also as per
                        the norms.
                    </p>
                </div>
                <div class="mt-3">
                    <p class="font-body">Cumulative Bonus For every claim-free year, the policyholder(s) gets rewarded
                        with a 5%
                        bonus of the sum Insured. No Claim Bonus (NCB) is calculated on a cumulative basis and can go up
                        to 50%.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Waiting Period</h4>
                </div>
                <p class="font-body mt-3">Arogya Sanjeevani Policy comes with a waiting period of 30 days for all illnesses
                    except accidents. This exclusion is however if the insured person has continuous coverage for more
                    than
                    12 months. Pre-existing diseases declared at the time of application come with a waiting period of
                    48
                    months from the date of policy inception.&nbsp;</p>
            </div>
            <div class="mt-3">
                <p class="font-body">What All Is Covered?</p>
            </div>
        <div class="row">
            <div class="title-line">
                <h4>Hospitalization Expenses</h4>
            </div>
            <div class="mt-3">
                <p class="font-body"> The policyholder can claim the cost of treatment incurred during hospitalization
                    (for
                    more than 24 hours) from their insurance provider. Hospitalization expenses include doctor&rsquo;s
                    fees,
                    nursing expenses, room rent, bed charges, hospital stay, etc. Hospitalization expenses are covered
                    up to
                    the percentage as stated in the policy of the sum insured subject to a maximum of Rs. 5,000 per day
                    of
                    hospitalisation.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Pre-Hospitalization Expenses</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">The plan covers medical expenses incurred pre-hospitalization for a fixed period of
                    30
                    days prior to the date of admission to the hospital.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Post-Hospitalization Expenses</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">The plan covers medical expenses incurred for a fixed period of 60 days from the
                    date
                    of discharge from the hospital.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Daycare Treatment</h4>
            </div>
            <p class="font-body">This refers to medical treatment, and/or surgical procedure undertaken under general or
                local anesthesia in a hospital/daycare center in less than 24 hours because of technological
                advancement.&nbsp;</p>
        </div>
        <div class="title-line">
            <h4>Road Ambulance Cover</h4>
        </div>
        <div class="mt-3">
            <p class="font-body">The policy covers road ambulance up to Rs. 2,000 per hospitalization or such as may be
                amended by insurance company</p>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>ICU/ICCU Charges</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Expenses incurred for ICU/ICCU are covered up to 5% of the sum insured subject to a
                    maximum of Rs. 10,000 per day.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>Plastic Surgery and Dental Treatments</h4>
            </div>
            <div class="mt-3">
                <p class="font-body">Expenses incurred on Plastic surgery and dental treatments necessitated due to a
                    disease or an injury are covered under the policy.</p>
            </div>
        </div>
        <div class="row">
            <div class="title-line">
                <h4>AYUSH Treatment</h4>
            </div>
            <p class="font-body">The plan covers the expenses incurred under Ayurveda, Yoga and Naturopathy, Unani,
                Siddha and Homeopathy systems of medicines during up to a specified limit of sum insured.</p>
            <div class="row">
                <div class="title-line">
                    <h4>Other Technological Treatments</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        New-age/Modern treatment including Robotic surgeries, Bronchial Thermoplasty, Intra vitreal
                        injections,
                        etc.(listed below), will be covered under the policy up to a limit of 50% of the sum insured.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>