import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentDetails } from '../Services/document.service';
import { GeneralService } from '../Services/general.service';
@Component({
  selector: 'app-registerbusiness',
  templateUrl: './registerbusiness.component.html',
  styleUrls: ['./registerbusiness.component.css']
})
export class RegisterbusinessComponent {
  constructor(private generalService : GeneralService){}
  isUserLogged: boolean = false;
  uploadedFile: File | undefined;
  @Input() docObj: DocumentDetails = {};
  @Input() id = '';
  @Input() className!:string;
  @Input() showErrorRequired = false;
  @Input() showErrorValid = false;
  @Input() acceptType!:string;
  @Output() selectedFile = new EventEmitter();
  @Output() deletedFile = new EventEmitter();

  onDragOver(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.addFileDropStyles();
  }

  onDragLeave(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.removeFileDropStyles();
  }

  onDrop(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.removeFileDropStyles();
    const files = (event as DragEvent).dataTransfer?.files;
    if (files && files.length > 0) {
      this.uploadedFile = files[0]; // Handle only the first file
      this.sendSelectedFile();
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.uploadedFile = input.files[0]; // Handle only the first file
      this.sendSelectedFile();
    }
  }
  sendSelectedFile()
  {
    ;
    let reader = new FileReader();
      reader.onload = async (event: any) => {
        let baseUpload64FileData = reader.result!.toString();
        //this.documentForm.get('documentBase64')?.setValue(baseUpload64FileData);
        let fileType = await this.SetFileType(baseUpload64FileData);
        this.docObj.documentBase64=baseUpload64FileData;
        this.docObj.documentTypeName=fileType;
        this.docObj.documentName=this.uploadedFile?.name;
        this.selectedFile.emit(this.docObj);
      }
      if(this.uploadedFile != undefined && this.uploadedFile != null)
      {
        reader.readAsDataURL(this.uploadedFile);
      }
      
  }

  private addFileDropStyles(): void {
    const fileDrop = document.getElementById(this.id);
    fileDrop?.classList.add('drag-over');
  }
  
  private removeFileDropStyles(): void {
    const fileDrop = document.getElementById(this.id);
    fileDrop?.classList.remove('drag-over');
  }

  async SetFileType(baseUpload64FileData:string) {
    let format=''; 
    if (baseUpload64FileData.includes(`application/pdf`)) {
      format = 'pdf';
    }
    else if (baseUpload64FileData.includes(`image`)) {
      format = 'image';
    }
    else if (baseUpload64FileData.includes(`video`)) {
      format = 'video';
    }
    return format;
  }

  removeDocument(typeId:string)
  {
    this.uploadedFile = undefined;
    this.docObj.documentBase64='';
    this.docObj.documentTypeName='';
    this.docObj.documentName='';
    this.deletedFile.emit(typeId);
  }
  ngOnInit() {
    if (this.generalService.getUniqueuserid() != "") {
      this.isUserLogged = true;
    }
  }
  TabFirst: string = '';
  TabSecond: string = '';
  TabThird: string = '';
  TabName: string = '';
  profile(pName: string): void {
    this.TabName = pName;
    
    this.TabFirst = '';
    this.TabSecond = '';
    this.TabThird = '';
    
  }
  Aadhar(aName:string):void{
    this.TabName = aName;
    
    this.TabFirst = '';
    this.TabSecond = 'Aadhar-otp';
    this.TabThird = '';
    
  }
  
  IdentityAadhar(iName:string):void{
    this.TabName = iName;
    this.TabFirst = '';
    this.TabSecond = 'Identity';
    this.TabThird = '';
  }
  
  adharDoc(docName:string):void{
    this.TabName = docName;
    this.TabFirst = '';
    this.TabSecond = 'adhar-doc';
    this.TabThird = '';
  }
  adharDocSuccess(succesName:string):void{
    this.TabName = succesName;
    this.TabFirst = '';
    this.TabSecond = 'adhar-doc-success';
    this.TabThird = '';
  }
  AadharSuccess(sName:string):void{
    this.TabName = sName;
    this.TabFirst = '';
    this.TabSecond = 'AadharSuccess';
    this.TabThird = '';
  }
  PanCard(pName:string):void{
    this.TabName = pName;
    this.TabFirst = '';
    this.TabSecond = 'Aadhar-otp';
    this.TabThird = 'Pan-card';
  }
  panSuccess(panSuccessName:string):void{
    this.TabName = panSuccessName;
    this.TabFirst = '';
    this.TabSecond = 'Aadhar-otp';
    this.TabThird = 'panSuccess';
  }
}
