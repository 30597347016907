import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, Subject, empty } from 'rxjs';

@Component({
  selector: 'app-reviewvehicledetails',
  templateUrl: './reviewvehicledetails.component.html',
  styleUrls: ['./reviewvehicledetails.component.css']
})
export class ReviewvehicledetailsComponent implements OnInit {
  list: any = [];
  registrationData: any;
  isBike :boolean = false;
  dataSubject: Subject<any> = new Subject<any>();
  engineNum:string="";
  chasisNum:string="";
   regex = /(?<=...).(?=..)/gm;
   subst = `*`;
  constructor(public modalRef: BsModalRef) { }

  
  ngOnInit(): void {
    this.engineNum="";
    this.chasisNum="";
    this.isBike = this.list[0].Bike;//to check it is bike or car if it's bike it should true 
    this.registrationData = this.list[0].registrationData;
    this.engineNum = this.maskData(this.registrationData.vehicle_engine_number)
    this.chasisNum  = this.maskData(this.registrationData.vehicle_chasi_number)
  
}

  maskData(inputData:string):string{
    return inputData.replace(this.regex, this.subst);
  }

  closeModal() {
    this.modalRef.hide();
  }

  confirmdetails() {
    this.dataSubject.next(this.registrationData);
    this.modalRef.hide();
  }

}
