<div class="container" style="background-color: antiquewhite;">
    <div class="caonteiner-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card mt-5 p-3 ">
                    <h6 class="text-start">
                        <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                            class="cursor-pointer">&nbsp;&nbsp; Your
                        Compare Plane
                    </h6>
                </div>
                <div class="card mt-3 p-4">
                    <div class="text-start">
                        <span>
                            Insurance Companies
                        </span>
                    </div>
                    <div class="mt-20 p-5 bg-crd"> <span>
                            IDV
                        </span></div>
                    <div class="p-5">
                        <span>
                            Claims settled
                        </span>
                    </div>
                    <div class=" p-5 bg-crd"> <span>
                            Features
                            <br><br><br>
                            <br><br><br>
                        </span></div>
                    <div class="p-5 mt-20 bg-crd ">
                        <span>
                            Premium
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card mt-5 p-3">
                    <div class="row center">
                        <div class="col-md-4 ">
                            <h4> Own Damage Only</h4>
                        </div>
                        <div class="col-md-4 ">
                            <h4>Comprehensive Cover</h4>
                        </div>
                        <div class="col-md-4 ">
                            <h4>Third-Party Cover</h4>
                        </div>
                    </div>
                </div>
                <div class="card mt-5 p-5">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-10">
                                    <img src="../../../../assets/images/united.png" alt="" class="img">
                                    <div>
                                        <span>
                                            <b>United india insurance</b>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <a href=""> <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-12 bg-crd p-5 mt-3">
                                <h4>
                                    ₹21,500
                                </h4>
                            </div>

                            <div class="col-md-12  p-5 mt-3">
                                <h4>
                                    96%
                                </h4>
                            </div>
                            <div class="col-md-12 bg-crd p-5 text-start">
                                <div class="row">
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>6 Months Repair Warranty</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>50% Advance Payment</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>Own Damage Only</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>Reimbursement in 48 hour</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>24 Supported Garages in your city</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 p-5 center">
                                <div class="row">
                                    <div class="col-md-4" (click)="like()">
                                        <img src="{{path}}" alt="Heart" width="28">&nbsp;&nbsp;
                          <div><span><b>like</b></span></div>
                                    </div>
                                    <div class="col-md-4">
                                        <img src="../../assets/svgIcon/ArrowsOut.svg" alt="ArrowsOut" width="28">&nbsp;&nbsp;
                                        <div><span><b>Policy Details</b></span></div>
                                    </div>
                                    <div class="col-md-4">
                                        <img src="../../assets/svgIcon/Share.svg" alt="Share" width="28">
                                        <div><span><b>Share</b></span></div>
                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 bg-crd center p-5">
                                <button class="btn btn-primary  ">
                                    ₹2500
                                </button><span>&nbsp;/Year</span>
                            </div>
                        </div>
                        <div class="col-md-6">

                            <div class="row">
                                <div class="col-md-10">
                                    <img src="../../../../assets/images/united.png" alt="" class="img">
                                    <div>
                                        <span>
                                            <b>United india insurance</b>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <a href=""> <i class="fa fa-times" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-12 bg-crd p-5 mt-3">
                                <h4>
                                    ₹21,500
                                </h4>
                            </div>

                            <div class="col-md-12  p-5 mt-3">
                                <h4>
                                    96%
                                </h4>
                            </div>
                            <div class="col-md-12 bg-crd p-5 text-start">
                                <div class="row">
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>6 Months Repair Warranty</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>50% Advance Payment</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>Own Damage Only</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>Reimbursement in 48 hour</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="card crd-width p-1">
                                            <span>24 Supported Garages in your city</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 p-5 center">
                                <div class="row">
                                    <div class="col-md-4">
                                        <img src="../../assets/svgIcon/Heart.svg" alt="Heart" width="28">&nbsp;&nbsp;
                          <div><span><b>like</b></span></div>
                                    </div>
                                    <div class="col-md-4">
                                        <img src="../../assets/svgIcon/ArrowsOut.svg" alt="ArrowsOut" width="28">&nbsp;&nbsp;
                                        <div><span><b>Policy Details</b></span></div>
                                    </div>
                                    <div class="col-md-4">
                                        <img src="../../assets/svgIcon/Share.svg" alt="Share" width="28">
                                        <div><span><b>Share</b></span></div>
                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 bg-crd center p-5">
                                <button class="btn btn-primary  ">
                                    ₹2500
                                </button><span>&nbsp;/Year</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>