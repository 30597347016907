<div class="row mt-5 p-0">
  <div class="col-md-6 mt-2" *ngFor="let item of AddressDetailsList">
    <div class="row m-0 insurancePlanChildDiv1">
		  <div class="col-12">
          <div class="row">
            <div class="col d-flex align-items-center gap_20_8">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" class="icon_width_height_50_40" viewBox="0 0 50 50" fill="none">
            <circle opacity="0.1" cx="25" cy="25" r="25" fill="var(--main)"/>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none" x="15" y="15">
              <path d="M17.8125 24.3754V18.7504C17.8125 18.5018 17.7137 18.2633 17.5379 18.0875C17.3621 17.9117 17.1236 17.8129 16.875 17.8129H13.125C12.8764 17.8129 12.6379 17.9117 12.4621 18.0875C12.2863 18.2633 12.1875 18.5018 12.1875 18.7504V24.3754C12.1875 24.6241 12.0887 24.8625 11.9129 25.0384C11.7371 25.2142 11.4986 25.3129 11.25 25.3129H5.625C5.37636 25.3129 5.1379 25.2142 4.96209 25.0384C4.78627 24.8625 4.6875 24.6241 4.6875 24.3754V13.5403C4.68751 13.4096 4.71483 13.2804 4.76771 13.161C4.82058 13.0415 4.89784 12.9344 4.99453 12.8465L14.3695 3.99419C14.5421 3.83707 14.7672 3.75 15.0006 3.75C15.234 3.75 15.459 3.83707 15.6316 3.99419L25.0066 12.8465C25.1033 12.9344 25.1806 13.0415 25.2335 13.161C25.2863 13.2804 25.3137 13.4096 25.3137 13.5403V24.3754C25.3137 24.6241 25.2149 24.8625 25.0391 25.0384C24.8633 25.2142 24.6248 25.3129 24.3762 25.3129H18.75C18.5014 25.3129 18.2629 25.2142 18.0871 25.0384C17.9113 24.8625 17.8125 24.6241 17.8125 24.3754Z" stroke="var(--main)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </svg>          
          <div class="row">
            <div class="col-12">
                 <p class="font-body m-0 font-15-12">Home</p>
                 </div>
              <span class="font-17-13 fw-bolder m-0">{{item.streetAddress}}</span>
            </div>
            </div>
            <div class="col-3 col-md-3 col-lg-2 justify-content-between d-flex ">
              <img src="../../assets/svgIcon/PencilLineBlack.svg" alt="" class="icon_width_height_30_24">
              <img src="../../assets/svgIcon/Trash.svg" alt="" class="icon_width_height_30_24">
            </div>
          </div>
          </div>
          <div class="row m-0 p-0 mt-3">
            <div class="col-12 col-md-10">
              <p class="font-body m-0 font-15-12">Flat, House no.</p>
              <span class="font-17-13 fw-bolder m-0">{{item.streetAddress}} {{item.city}} {{item.district}}
                {{item.state}} {{item.pincode}}</span>
            </div>
          </div>
        </div>
  </div>
</div>

  <div class="row mt-3">
    <div class="col-12 text-right">
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#rightModal">
        Add New Address
      </button>
    </div>    
    <div class="modal fade right-modal" id="rightModal" tabindex="-1" role="dialog" aria-labelledby="rightModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-right" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="rightModalLabel"> <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
              class="cursor-pointer">&nbsp;&nbsp;Add new Address</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
<div class="container">
  <div class="row">
    <div class="col-md-12 mt-3">
      <label >Address Type</label>
      <input type="text" name="type" id="" class="form-control" placeholder="Address Type"> 
    </div>
    
    <div class="col-md-12 mt-3">
      <label >Street Address</label>
      <input type="text" name="Street Address" id="" class="form-control" placeholder="Street Address"> 
    </div>
    
    <div class="col-md-12 mt-3">
      <label >Your Pincode <a href="#">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Don't Know Pincode</a>
      </label>
      <input type="number" name="Your Pincode" id="" class="form-control" placeholder="Your Pincode"> 
    </div>
    <div class="col-md-12 mt-3">
      <label >Select Village</label>
      <select class="form-select" aria-label="Default select example">
        <option selected>Select Village</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>
    </div>
    <div class="col-md-12 mt-3">
      <label >Floor No.</label>
      <select class="form-select" aria-label="Default select example">
        <option selected>Floor No.</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>
    </div>
    <div class="col-md-12 mt-10">
<button class="btn btn-primary" id="btn">Add New Address</button>
    </div>
  </div>
</div>
          </div>
        </div>
      </div>
    </div>

  </div>
