<div class="container agent-dashboard" *ngIf="!istest">
    <div class="row mt-12">
        <div class="row dash-box mt-5">
        <div class="card p-4">
            <div class="row">
                <div class="col-md-9 d-flex align-items-center">
                    <h3 class="mb-0">POSP Certification Exam</h3>
                </div>
                <div class="col-md-3 text-end">
                    <a href="/agent-profile"><button type="button" class="btn "><img src="../../../../assets/svgIcon/Vectorlefttt.svg" alt="">
                        Back</button></a>
                        <button type="button" class="btn btn-primary ml-3" (click)="test()">Start Test</button>
                </div>
                <div class="col-md-12">
                    <hr class="my-3">
                    <h3>Instructions for Trainee undergoing the Training.</h3>
                    <ol class="mt-3">
                        <li class="font-body">
                            The contents of this course is as per the syllabus prescribed by IRDAI.
                        </li>
                        <li class="font-body">
                            It covers 5 Insurance Modules covering Life and Non Life insurance conceptual knowledge.
                        </li>
                        <li class="font-body">
                            Go through the contents of each topic/Module. You will get Knowledge of Insurance ,
                            Insurance market and various sectors which get benefitted with insurance.
                        </li>
                        <li class="font-body">
                            At the end of course, there will be an objective type test consisting of 30 questions
                            covering the whole syllabus of the course. You have to secure at least 35% marks in the
                            test.
                        </li>
                        <li class="font-body">
                            All the best. Go Ahead and grow.
                        </li>
                    </ol>
                </div>
            </div>
        </div>

            <div class="row bg-white">
                <div class="col"></div>
                <div class="col"></div>
            </div>
            <div class="row mt-10">
                <app-agentfooter></app-agentfooter>
            </div>
        </div>
    </div>
</div>
<div class="container agent-dashboard" *ngIf="istest">
    <div class="row mt-12">
    </div>
    <div class="row mt-5" *ngIf="!isdone">
        <div class="col-md-8">
            <div class="card crd p-3">
                <div class="row">
                    <div class="col-md-6 mt-2">
                        <h3>LI POSP Certification Exam</h3>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <ng-container  *ngFor="let item of examinationList; let i = index;">
                    <div class="contaier" *ngIf="item.sequenceNo == questionSequence">
                        <div class="col-md-12 mt-5">
                            <p class="text-main">Question {{item.sequenceNo}}/{{examinationList.length}}</p>
                        </div>
                        <div class="col-md-12 mt-5">
                            <h3>
                                {{item.questiontext}}
                            </h3>
                        </div>
                        <div class="col-md-12 mt-5 " *ngFor="let option of item.optionsList; let j = index;" >
                            <div class="option-tab cursor-pointer" [ngClass]="{'selected-option':option.isSelected}" (click)="onOptionClick(option.optionID,item.questionId)">                                
                                <label >{{option.optionText}}</label>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button class="btn btn-agentlog btn-mark-review" [disabled]="!isOptionSelected(item.questionId)" (click)="next(item.sequenceNo,true)">Mark for Review & Next</button>
                        </div>
                        <div class="col-md-6 mt-4 text-end">
                            <button class="btn btn-warning " (click)="next(item.sequenceNo,false)"> Save & Next &nbsp;<img
                                    src="../../../../assets/svgIcon/CaretRight.svg" alt=""></button>
                        </div>
                    </div>
                    </ng-container>

                    <!-- <div class="contaier" *ngIf="!nextQuestion">
                        <div class="col-md-12 mt-5">
                            <p style="color: rgba(16, 160, 193, 1);">Question 1/30</p>
                        </div>
                        <div class="col-md-12 mt-5">
                            <h3>
                                Which among the following is the regulator for the insurance industry in India?
                            </h3>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> Insurance Authority of India</p>

                            </div>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> Insurance regulatory and development authority of India</p>

                            </div>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> Life insurances corporation of India</p>

                            </div>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> General Insurances corporation of India</p>

                            </div>
                        </div>
                        <div class="col-md-9 mt-4">
                            <button class="btn btn-agentlog ">Mark for Review & Next</button>
                        </div>
                        <div class="col-md-3 mt-4">
                            <button class="btn btn-warning " (click)="next()"> Save & Next &nbsp;<img
                                    src="../../../../assets/svgIcon/CaretRight.svg" alt=""></button>
                        </div>
                    </div>
                    <div class="contaier" *ngIf="nextQuestion">
                        <div class="col-md-12 mt-5">
                            <p style="color: rgba(16, 160, 193, 1);">Question 30/30</p>
                        </div>
                        <div class="col-md-12 mt-5">
                            <h3>
                                What is the life & General Insurance Plans available with Click4bima and why can be my expected earnings?
                            </h3>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> Insurance Authority of India</p>

                            </div>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> Insurance regulatory and development authority of India</p>

                            </div>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> Life insurances corporation of India</p>

                            </div>
                        </div>
                        <div class="col-md-12 mt-5 ">
                            <div class="card cd ">
                                <p class="mt-3 ml-3"> General Insurances corporation of India</p>

                            </div>
                        </div>
                        <div class="col-md-9 mt-4">
                            <button class="btn btn-agentlog ">Mark for Review & Next</button>
                        </div>
                        <div class="col-md-3 mt-4">
                            <button class="btn btn-warning " (click)="submitExam()"> Save & Next &nbsp;<img
                                    src="../../../../assets/svgIcon/CaretRight.svg" alt=""></button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card p-3">
                <div class="row">
                    <div class="col-6">
                        <p class="mt-3">Time Left</p>
                    </div>
                    <div class="col-6 text-end">
                        <div class="card mt-3 text-end">
                            {{countdownText}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-3 p-3">
                <div class="row">
                    <div class="col-md-12 mt-2">
                        <p>Questions</p>
                    </div>
                    <div class="col-md-6 mt-5">
                        <p class="d-flex align-items-center">
                            <span class="questions-box bg-success">{{getAnsweredCount()}}</span>&nbsp;&nbsp;Answered
                            <!-- <img src="../../../../assets/svgIcon/G0.svg" alt="">&nbsp;&nbsp;Answered -->
                        </p>
                    </div>
                    <div class="col-md-6 mt-5">
                        <p class="d-flex align-items-center">                          
                            <!-- <img src="../../../../assets/svgIcon/G1.svg" alt=""> -->
                            <span class="questions-box bg-danger">{{getNotAnsweredCount()}}</span>&nbsp;&nbsp;Not Answered
                        </p>
                    </div>
                    <div class="col-md-6 mt-5">
                        <p class="d-flex align-items-center">
                            <!-- <img src="../../../../assets/svgIcon/Y0.svg" alt=""> -->
                            <span class="questions-box bg-warning">{{getMarkedCount()}}</span>&nbsp;&nbsp;Marked
                        </p>
                    </div>
                    <div class="col-md-6 mt-5">
                        <p class="d-flex align-items-center">
                            <!-- <img src="../../../../assets/svgIcon/G29.svg" alt=""> -->
                            <span class="questions-box bg-lightgrey">{{getNotVisitedCount()}}</span>&nbsp;&nbsp;Not Visited
                        </p>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12 mt-4">
                        <p>Questions Palette</p>
                    </div>
                    <div class="col-md-2 c2 mt-4 d-flex justify-content-center" *ngFor="let item of examinationList; let i = index;">
                        <span class="text-dark questions-box {{getBgColor(item)}} cursor-pointer" (click)="showQuestion(item.sequenceNo)">{{ item.sequenceNo }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row text-center mt-5" *ngIf="isdone">
        <div class="col-md-12">
            <div class="row justify-content-center">
                <div class="col-md-5">
                    <div class="card p-4 ">
                        <div class="row">
                            <div class="col-md-3">
                                <p class="mt-3">Time Left</p>
                            </div>
                            <div class="col-md-9 text-end">
                                <div class="card mt-3  text-end">
                                    {{countdownText}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-5">
                    <div class="card mt-3 p-3">
                        <div class="row">
                            <div class="col-md-12 mt-2">
                                <p>Questions</p>
                            </div>
                            <div class="col-md-6 mt-5">
                                <p class="d-flex align-items-center">
                                    <span class="questions-box bg-success">{{getAnsweredCount()}}</span>&nbsp;&nbsp;Answered
                                    <!-- <img src="../../../../assets/svgIcon/G0.svg" alt="">&nbsp;&nbsp;Answered -->
                                </p>
                            </div>
                            <div class="col-md-6 mt-5">
                                <p class="d-flex align-items-center">                          
                                    <!-- <img src="../../../../assets/svgIcon/G1.svg" alt=""> -->
                                    <span class="questions-box bg-danger">{{getNotAnsweredCount()}}</span>&nbsp;&nbsp;Not Answered
                                </p>
                            </div>
                            <div class="col-md-6 mt-5">
                                <p class="d-flex align-items-center">
                                    <!-- <img src="../../../../assets/svgIcon/Y0.svg" alt=""> -->
                                    <span class="questions-box bg-warning">{{getMarkedCount()}}</span>&nbsp;&nbsp;Marked
                                </p>
                            </div>
                            <div class="col-md-6 mt-5">
                                <p class="d-flex align-items-center">
                                    <!-- <img src="../../../../assets/svgIcon/G29.svg" alt=""> -->
                                    <span class="questions-box bg-lightgrey">{{getNotVisitedCount()}}</span>&nbsp;&nbsp;Not Visited
                                </p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 mt-4">
                                <p>Questions Palette</p>
                            </div>
                            <div class="col-md-2 c2 mt-4 d-flex justify-content-center" *ngFor="let item of examinationList; let i = index;">
                                <span class="text-dark questions-box {{getBgColor(item)}} cursor-pointer" (click)="setQuestionSequenceForReview(item.sequenceNo)">{{ item.sequenceNo }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col mt-6 text-start" *ngIf="remainingTime > 0">
                                <button class="btn btn-agentlog btn-review" [disabled]="questionSequenceForReview == 0" (click)="showQuestion(questionSequenceForReview,true)">Review</button>
                            </div>
                            <div class="col mt-6 text-end">
                                <button class="btn btn-warning" (click)="viewCertificate()">Submit Test</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="showSuccessPopup">
    <div class="popup2 col-md-3 col-11">
        <div class="model-body p-4">
            <div class="row text-center">
                <div class="col-md-12">
                    <img src="../../../../assets/images/7117898_3255338 1.svg" />
                </div>
                <div class="col-md-12 mt-3">
                    <h5>"Please come after 24-hours to check your result"</h5>
                    <p class="mt-3 text-center">Congratulations! You've successfully submitted the Exam and the result will be declared after 24-hours.</p>
                </div>
                <div class="col-md-12 mt-3">
                    <a href="/agent-profile">
                        <button class="btn btn-primary btn-sm w-75" >Ok</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>