<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">Third Party Two Wheeler Insurance</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">Third-party insurance also referred to as liability insurance essentially provides financial coverage to an insured person against any liability incurred in case of any loss/damage caused to third-party property or the person. This type of policy compensates the damages to a third party and their property. Under motor laws of India, third party insurance is compulsory. Third-party insurance covers accidental damage to the third party property or Death or bodily injury to a third party by the insured vehicle So, this means that the insurance will also cover the third person who may have underwent an accident or have incurred some loss due to the accident. The policy is different in a way that it does not cover any loss or damages to the insured two wheeler due to theft, accident, etc.</p>
                <p class="font-body">The law mandates that for every vehicle purchase it is compulsory to have third-party insurance. As per the rules of the Motor Vehicles Act 1988, the Insurance Regulatory and Development Authority (IRDA) of India computes the third party damages.</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How Does Third-Party Two Wheeler Insurance Work?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        In order to better understand the concept of third-party Two wheeler insurance, let us look at some terminologies. For example, in the event of a Two wheeler accident, the parties involved are as follows: 
                    </p>
                    <p class="font-body">First party: The insured person or policy holder</p>
                    <p class="font-body">Second party: The insurance company </p>
                    <p class="font-body">Third party: The person who claims for the damages caused by the first party</p>
                    <p class="font-body">In an event where an insured person with a third party insurance policy is held legally liable for injuries or damage done to a third party, then his/her insurance company indemnifies the insured person. Any losses arising due to damages or injury caused by the insured to a third party or third party’s property, are covered under the third-party insurance policy. In the event of a Two wheeler accident, an insured person with a third party insurance policy is required to immediately inform the insurance company of the incident. If you were responsible for the accident (or the other driver believes you were responsible), it's almost certain that a claim will be made against you, which your insurance company will be expected to pay. So, for the speedy resolution of the claim it is of utmost importance that  the insurance  company is intimated about the accidental claim at the earliest.</p>
                    <p class="font-body">Further Insurance companies cover two kinds of motor Third Party Insurance claims — bodily injury liability and property damage liability.</p>
                    <p class="font-body">Third party bodily injury liability: claim arises out of the insured causing physical injuries to another person with his vehicle. Such claims provide coverage for hospitalization expenses, pain & suffering, loss of income as well as death or permanent disability as a result of the accident.</p>
                    <p class="font-body">Third party property damage liability claim covers the property damage or complete loss of property caused by the insured vehicle. It includes claims related to damaged landscapes, such as a ruined fence, front lawn, etc. damaged property such as mailboxes, the fence gates, etc. as well as replacement of structures , such as house etc. This is how a third-party motor insurance works.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What Does a Third-Party Two Wheeler Insurance Policy Cover? </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        A third-party insurance of Two wheeler covers for the expenses incurred due to the following reasons: 
                    </p>
                    <p class="font-body">Third Party Liabilities : Third party liabilities arise out of any loss or damages caused by the insured Two wheeler to a third party during an accident. Since the accident is caused by the insured Two wheeler, paying for the damages is the responsibility of the insured Two wheeler’s owner. Third party liability insurance covers legal liabilities arising out of causing</p>
                    <p class="font-body">Damage to third-party property : In case you, unfortunately, ram your Two wheeler in a third party person’s property, such as the home,boundary wall or a shop, your third party insurance policy will pay for the loss or damages sustained by that person.</p>
                    <p class="font-body">Damage to a Third-Party Vehicle : because of the insured vehicle If you accidentally hit a third party person‘s Two wheeler with your own while driving, your insurance policy will come to your rescue and pay for the damages caused to that person's Two wheeler on your behalf.</p>
                    <p class="font-body">Death or bodily injury to a third party : In case you accidentally hit a third party person with your Two wheeler, your third party insurer will pay for the treatment of his bodily injuries.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Personal Accident Cover</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">A few motor insurance companies also offer personal accident cover to the owner-driver of the insured Two wheeler. As part of this cover, the owner-driver of the Two wheeler is granted with compensation in case he ends up suffering from a disability or dies as a result of a Two wheeler accident. In case of death of the policyholder, the compensation is paid to the nominee appointed by the Two wheeler owner.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>What Does a Third-Party Two wheeler Insurance Policy Not Cover?</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">When opting for a third party only Two wheeler insurance policy, you must be aware of what benefits you’re missing out on. Let’s take a look at the following most common exclusions that third party Two wheeler insurance does not cover: </p>
                    <p class="font-body">Cost of damages to a vehicle or the belongings in the vehicle in case of an accident.</p>
                    <p class="font-body">Accidental damage/liability/loss outside any particular geographical boundary.</p>
                    <p class="font-body">Any Claims arising out of contractual liability.</p>
                    <p class="font-body">Cover for losses incurred if your Two wheeler is stolen or destroyed.</p>
                    <p class="font-body">In case vehicle was driving by any individual, other than the owner or the designated driver.</p>
                    <p class="font-body">If the third party sustains accidental loss or damage, which has accrued from any substantial loss.</p>
                    <p class="font-body">Liability, caused by a nuclear weapon or radioactive contamination, directly or indirectly.</p>
                    <p class="font-body">Claim arising when the policy is inactive.</p>
                    <p class="font-body">Claim arising when the driver is not carrying a valid driving license at the time of the accident.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Advantages of a Third-Party Two wheeler Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">There are several advantages of third-party Two wheeler insurance. Let’s look at the advantages that you will be eligible for in case you buy third-party Two wheeler insurance: </p>
                    <p class="font-body">Fullfil Mandate Requirements : Under the Motor Vehicles Act it is necessary to insure your Two wheeler if you want to drive on Indian roads. Getting basic third-party Two wheeler insurance will protect you from hefty fines.</p>
                    <p class="font-body">It Will Save You Time :Third-party Two wheeler insurance is very easy to purchase online with us in just a few clicks. This will save you time and money as you can buy this plan from the comfort of your home. </p>
                    <p class="font-body">Personal Damages coverages : In case of an unfortunate event like an accident where you hurt someone physically or in severe cases, cause the death of the third person. Your third-party Two wheeler insurance will provide coverage for the losses up to unlimited liability.</p>
                    <p class="font-body">Provides Third-Party Property or Vehicle Damages Coverage: In case of an accident where it causes damage to one’s vehicle or property, your third-party Two wheeler insurance will provide coverage.</p>
                    <p class="font-body">Protects you in case of any physical injuries : In case you don’t have a personal accident cover, you can opt for it with your third-party Two wheeler insurance. This way you can protect yourself and your family financially in the case of permanent disability or death due to an accident.</p>
                    <p class="font-body">Protectection  from Unexpected Losses: Indian roads are congested, and you can see several two wheelers on the roads. So, an unfortunate event like an accident that is bound to happen. If you opt for a third-party Two wheeler insurance policy, you don’t have to worry about the expenses that might incur in case of an unexpected accident loss. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Disadvantages of Third-Party Two wheeler Insurance</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Nothing is perfect, not even your third-party Two wheeler insurance as it comes with several disadvantages as well. Here is the look at the disadvantages that you might face if you buy third-party Two wheeler insurance:</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Doesn't cover your own damages </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Your third-party Two wheeler insurance will not provide coverage against the damages and losses to your own Two wheeler. To protect your Two wheeler financially, you need to opt for a separate comprehensive Two wheeler insurance policy from reputable insurers. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Doesn't cover damages due to natural calamities </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">No one can predict the future. Natural calamities such as floods, earthquakes, tsunami, etc. are some of the unfortunate events that no one has control on. In case your Two wheeler is damaged due to a natural calamity, your third-party Two wheeler insurance will not cover it. However, if you are opting comprehensive Two wheeler insurance will surely protect you financially against the damage caused to your Two wheeler due to both natural and man-made calamities. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>No Protection from Theft/Fire </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Besides accidents, a Two wheeler is constantly under the risk of getting stolen or catching fire. In case your Two wheeler catches fire or gets stolen, your third party insurance policy will be of no use to you as it will not pay for the loss of your Two wheeler.</p>
                    <p class="font-body">You can’t opt for add-ons </p>
                    <p class="font-body">There are several add-ons that enhance the coverage of your plan, but you can only opt for them in case you buy a comprehensive Two wheeler insurance plan and not with third-party Two wheeler insurance. </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>How to File a Claim for Third Party Two wheeler Insurance? </h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Given below are the step wise points that you need to consider in case of a third-party insurance claim:</p>
                    <p class="font-body">1. Application: In an unfortunate event like an accident, the third-party must file an FIR immediately after the accident takes place. You must retain a copy of the said FIR and the Charge sheet filed by the Police. On happening of an accident causing injuries to the third party, you need to inform the police and your insurance provider, without any delay and share the valid documents of the vehicle including the driving licence of the driver.</p>
                    <p class="font-body">2. File an FIR : In case of an accident, where you are not at fault or you have been impleaded in an accident, though not involved, file an FIR with the police against the person/s who have caused the accident or have impleaded you as a party to the case and defend the case in the court along with your insurer. </p>
                    <p class="font-body">3.Register the case with Motor accidents Claims Tribunal: In India, Motor Accident Claims Tribunal decides the claim for compensation amount to a third party. In case of personal injuries to a third-party, there is no upper limit, whereas, there is limited liability of insurance up to Rs 7.5 lakhs in the event of losses and damages caused to third-party property or vehicle depending on the involvement of the vehicle insured. A claims petition seeking compensation is filed by the third party before the concerned Motor Accident Claims Tribunal and the judicial proceedings start wherein the vehicle owner, driver and insurer is a party to the case.</p>
                    <p class="font-body">If the damages and losses caused due to an accident are minor, then it is advisable to settle it outside the court as dealing with the court proceedings is a time-consuming process. </p>
                    <p class="font-body">4. Get the Compensation amount: When the matter is decided by the Tribunal and there no violation of the insurance policy and in case everything is in order, the compensation amount awarded by the Tribunal will be paid by your insurance provider to the third party.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
