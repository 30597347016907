export class odiyaLabel {
    public product: string = 'ଉତ୍ପାଦ';
    public support: string = 'ସମର୍ଥନ';
    public raise_a_query: string = 'ପ୍ରଶ୍ନ ପଚାର |';
    public raise_a_claim: string = 'ଦାବି ପାଇଁ ପଚାର |';
    public contact_us: string = 'ଆମ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ |';
    public career: string = 'କ୍ୟାରିଅର୍';
    public gallery: string = 'ଗ୍ୟାଲେରୀ';
    public login: string = 'ଭିତରକୁ ଯାଉ';
    public two_wheeler: string = 'ଦୁଇ ଚକିଆ ଯାନ |';
    public four_wheeler: string = 'ଚାରି ଚକିଆ ଯାନ |';
    public health: string = 'ସ୍ୱାସ୍ଥ୍ୟ';
    public commercial_vehicle: string = 'ବାଣିଜ୍ୟିକ ଯାନ';
    public bike_ins_title: string = 'ତୁମର ଦୁଇ ଚକିଆ ବୀମା ଉପରେ ଏକ ସ୍ୱତନ୍ତ୍ର ମୂଲ୍ୟ ପ୍ରାପ୍ତ କର |';
    public bike_ins_start: string = 'ଦୁଇ ଚକିଆ ବୀମା ₹ 635 * ରୁ ଆରମ୍ଭ ହେଉଛି |';
    public enter_bike_number: string = 'ଦୁଇ ଚକିଆ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ | EX: AB12CD3456 |';
    public verify: string = 'ଯାଞ୍ଚ କରନ୍ତୁ |';
    public dont_have_policy: string = "ପଞ୍ଜୀକରଣ ସଂଖ୍ୟା ମନେରଖ ନାହିଁ|";
    public buy_new_bike: string = 'ଏକ ନୂତନ ଦୁଇ ଚକିଆ କିଣିଛନ୍ତି କି?';
    public what_is_ins1: string = 'ବୀମା';
    public what_is_ins2: string = 'କ’ଣ?';
    public what_is_ins_title: string = 'ବୀମା ସହିତ ମନର ଶାନ୍ତି ଆବିଷ୍କାର କରନ୍ତୁ|';
    public what_is_ins_text: string = "ଆମର Click4bima ପୋର୍ଟାଲକୁ ସ୍ୱାଗତ, ଭବିଷ୍ୟତକୁ ସୁରକ୍ଷିତ ରଖିବା ପାଇଁ ଆପଣଙ୍କ ଗେଟୱେ! ଆମର ପୋର୍ଟାଲ୍ ହେଉଛି ଆପଣଙ୍କର ଏକ-ଷ୍ଟପ୍ ଗନ୍ତବ୍ୟସ୍ଥଳ ଯାହାକି ବିଭିନ୍ନ ପ୍ରକାରର ବୀମା ବିକଳ୍ପଗୁଡିକ ଅନୁସନ୍ଧାନ କରିବାକୁ | ସ୍ and ାସ୍ଥ୍ୟ ଏବଂ ଅଟୋ ଠାରୁ ଆରମ୍ଭ କରି ଘର ଏବଂ ଭ୍ରମଣ ପର୍ଯ୍ୟନ୍ତ, ଆମେ ଆପଣଙ୍କର ଆବଶ୍ୟକତା ଅନୁଯାୟୀ ସର୍ବୋତ୍ତମ କଭରେଜ୍ ଯୋଜନା ଆଣିବା ପାଇଁ ପ୍ରତିଷ୍ଠିତ ବୀମା କମ୍ପାନୀଗୁଡିକ ସହିତ ସହଭାଗୀ ହୋଇଛୁ | ବିଭିନ୍ନ ନୀତି ତୁଳନା କରି, ଆପଣ ସୂଚନାଯୋଗ୍ୟ ନିଷ୍ପତ୍ତି ନେଇପାରିବେ ଏବଂ ଆପଣଙ୍କ ଜୀବନଶ lifestyle ଳୀ ଏବଂ ବଜେଟ୍ ସହିତ ମେଳ ଖାଉଥିବା ଚୟନ କରିପାରିବେ |";
    public why_insurance_imp1: string = 'ବୀମା କାହିଁକି';
    public why_insurance_imp2: string = 'ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ?';
    public why_insurance_imp_title: string = 'ଆସନ୍ତାକାଲି ସୁରକ୍ଷିତ: ବୀମାର ମହତ୍ତ୍ୱକୁ ଉନ୍ମୋଚନ |';
    public why_insurance_imp_text: string = "ଜୀବନର ଅନିଶ୍ଚିତତା ବିରୁଦ୍ଧରେ ବୀମା ହେଉଛି ଆପଣଙ୍କର ield ାଲ, ଯେତେବେଳେ ଆପଣ ଏହାକୁ ଅଧିକ ଆବଶ୍ୟକ କରନ୍ତି ସେତେବେଳେ ଆପଣଙ୍କୁ ମାନସିକ ଶାନ୍ତି ପ୍ରଦାନ କରିଥାଏ | ଏହା ତୁମ ଏବଂ ତୁମର ମନୋନୀତ ବୀମା ପ୍ରଦାନକାରୀଙ୍କ ମଧ୍ୟରେ ଏକ ଚୁକ୍ତି ଯାହା ଅପ୍ରତ୍ୟାଶିତ ଘଟଣା ସମୟରେ ଆର୍ଥିକ ସହାୟତା ନିଶ୍ଚିତ କରେ | ଏହା ହଠାତ୍ ଚିକିତ୍ସା ଖର୍ଚ୍ଚ, ଏକ ଅଟୋମୋବାଇଲ୍ ଦୁର୍ଘଟଣା, ଆପଣଙ୍କ ସମ୍ପତ୍ତିର କ୍ଷତି, କିମ୍ବା କ any ଣସି ଅପ୍ରତ୍ୟାଶିତ ପରିସ୍ଥିତି, ଭାରକୁ ହ୍ରାସ କରିବାକୁ ବୀମା ପଦକ୍ଷେପ |";
    public claim_assistance1: string = 'ସହାୟତା';
    public claim_assistance2: string = 'ଦାବି';
    public claim_assistance_title: string = 'ତୁମର ମନର ଶାନ୍ତି ପୁନ ored ସ୍ଥାପିତ |';
    public claim_assistance_text: string = "Click4bima ରୁ 'ଦାବି ସହାୟତା' ସହିତ, ଆପଣ ନିଶ୍ଚିତ ହୋଇପାରିବେ ଯେ ଆପଣଙ୍କର ଏକ ନିର୍ଭରଯୋଗ୍ୟ ସାଥୀ ଅଛନ୍ତି ଯିଏ ଏକ ସୁଗମ ଦାବି ପ୍ରକ୍ରିୟାର ମହତ୍ତ୍ୱ ବୁ understand ନ୍ତି | ତୁମର ମାନସିକ ଶାନ୍ତି ଫେରି ପାଇବ ଯେ ତୁମେ କେବଳ ଏକ ନୀତି ଅପେକ୍ଷା ଅଧିକ ସୁରକ୍ଷିତ - ତୁମେ ଯତ୍ନ ନେଉଥିବା ଏକ ଦଳ ଦ୍ୱାରା ସମର୍ଥିତ | ଯେତେବେଳେ ଆପଣ ଏହାକୁ ଅଧିକ ଆବଶ୍ୟକ କରନ୍ତି ଆମର 'ଦାବି ସହାୟତା' ହେଉଛି ଆପଣଙ୍କର ବିଶ୍ୱସ୍ତ ସାଥୀ |";
    public best_place_ins_title: string = 'ଭାରତରେ ବୀମା କିଣିବା ପାଇଁ ଆମକୁ ସର୍ବୋତ୍ତମ ସ୍ଥାନ କଣ?';
    public best_place_ins_text: string = 'ଆମର Click4bima ପୋର୍ଟାଲକୁ ସ୍ Welcome ାଗତ, ଭାରତରେ ସର୍ବୋତ୍ତମ ବୀମା କଭରେଜ୍ ଖୋଜିବା ପାଇଁ ଆପଣଙ୍କର ଏକ ଷ୍ଟପ୍ ଗନ୍ତବ୍ୟସ୍ଥଳ | ଭାବୁଛୁ ଆମେ କାହିଁକି ଅନ୍ୟମାନଙ୍କଠାରୁ ଅଲଗା? ସଠିକ୍ ବୀମା କଭରେଜର ସୁରକ୍ଷା ସହିତ ଏକ ଚିନ୍ତାମୁକ୍ତ ଭବିଷ୍ୟତକୁ ଗ୍ରହଣ କରନ୍ତୁ | ଆଜି ଆମ ସହିତ ଆପଣଙ୍କର ବୀମା ଯାତ୍ରା ଆରମ୍ଭ କରନ୍ତୁ |';

    public everytime_support_title: string = '24 * 7 ସମର୍ଥନ';
    public everytime_support_text: string = "ଆମର 24/7 ବୀମା ସହାୟତା ସହିତ ଯେକ time ଣସି ସମୟରେ, ମାନସିକ ଶାନ୍ତି ପ୍ରାପ୍ତ କରନ୍ତୁ | ଆମର ଉତ୍ସର୍ଗୀକୃତ ଦଳ ଆପଣଙ୍କୁ ବିଶେଷଜ୍ଞ ସମର୍ଥନ ଯୋଗାଇବା, ଆପଣଙ୍କ ପ୍ରଶ୍ନର ଉତ୍ତର ଦେବା ଏବଂ ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଆପଣ ସର୍ବଦା ଆବୃତ ଅଟନ୍ତି |"


    public best_price_ins_title: string = 'ସର୍ବୋତ୍ତମ ମୂଲ୍ୟ ବୀମା |';
    public best_price_ins_text: string = "ଆମର ଲକ୍ଷ୍ୟ ସରଳ: ଗୁଣବତ୍ତାରେ ସାଲିସ୍ ନକରି ଆପଣଙ୍କୁ ସର୍ବୋତ୍ତମ ବୀମା କାରବାର ଯୋଗାଇବା | କଷ୍ଟୋମାଇଜେବଲ୍ ନୀତିଗୁଡିକର ଏକ ସୀମା ସହିତ, ଆମେ ଆପଣଙ୍କର ବଜେଟ୍ ଅକ୍ଷୁର୍ଣ୍ଣ ରଖିବାବେଳେ ଆପଣଙ୍କ ଭବିଷ୍ୟତକୁ ସୁରକ୍ଷିତ ରଖିବା ପାଇଁ ଏଠାରେ ଅଛୁ |";
    public reliable_title: string = 'ନିର୍ଭରଯୋଗ୍ୟ';
    public reliable_text: string = 'କ us ଣସି ବ୍ୟତିକ୍ରମ ବିନା, ତୁମ ପାଇଁ ସବୁଠାରୁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ବିଷୟକୁ ସୁରକ୍ଷା ଦେବା ପାଇଁ ତୁମେ ଆମକୁ ବିଶ୍ୱାସ କରିପାରିବ | ଆମର ପ୍ରତିଜ୍ଞା ଉପରେ ବିତରଣର ଏକ ପ୍ରମାଣିତ ଟ୍ରାକ୍ ରେକର୍ଡ ସହିତ, ଆମେ ତୁମର ମାନସିକ ଶାନ୍ତି, ପ୍ରତ୍ୟେକ ପଦକ୍ଷେପରେ ନିଶ୍ଚିତ କରୁ | ତୁମର ଭବିଷ୍ୟତକୁ ସୁରକ୍ଷିତ ରଖିବାରେ ଅଦମ୍ୟ ବିଶ୍ୱସନୀୟତା ପାଇଁ ଆମକୁ ବାଛ |';
    public easy_claim_title: string = 'ଦାବି ସହଜ ହେଲା |';
    public easy_claim_text: string = "ଆମର ଶୃଙ୍ଖଳିତ ପ୍ରଣାଳୀ ଏବଂ ଉତ୍ସର୍ଗୀକୃତ ସମର୍ଥନ ଦଳ ନିଶ୍ଚିତ କରନ୍ତି ଯେ ଯେତେବେଳେ ଆପଣ ଆବଶ୍ୟକ କରନ୍ତି ସେତେବେଳେ ଆପଣ ଆବଶ୍ୟକ କରୁଥିବା ସହାୟତା ପାଇବେ | ଆମେ ପ୍ରକ୍ରିୟାକୁ ସରଳ କରିବାକୁ ପ୍ରତିଶ୍ରୁତିବଦ୍ଧ ଅଟୁ ଯାହା ଦ୍ you ାରା ତୁମେ ତୁମର ଜୀବନରେ ସବୁଠାରୁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ ବିଷୟକୁ ଶୀଘ୍ର ଫେରିପାରିବ |";
    public mobile_app_title: string = 'ଗୋଟିଏ ଆପ୍ ସହିତ ଦେୟ ଦିଅ, ବିନିଯୋଗ କର, ତୁମର ଟଙ୍କା ସଞ୍ଚୟ କର |';
    public mobile_app_content: string = 'ଯେକ anywhere ଣସି ଠାରେ, ଯେକ time ଣସି ସମୟରେ ଆପଣଙ୍କର ସମସ୍ତ ବୀମା ଆବଶ୍ୟକତାକୁ ନିୟନ୍ତ୍ରଣ କରନ୍ତୁ |';

    public mobile_app_compare: string = 'ବିଭିନ୍ନ ବୀମା ପଲିସି ତୁଳନା କରନ୍ତୁ |';
    public mobile_app_track: string = 'ଅନ୍ଲାଇନ୍ରେ ଆପଣଙ୍କର ସମସ୍ତ ନୀତି କ୍ରୟ, ସଂରକ୍ଷଣ ଏବଂ ଅଂଶୀଦାର କରନ୍ତୁ |';
    public mobile_app_download: string = 'ଯିବା ସମୟରେ ଆପଣଙ୍କର ନୀତି ସ୍ଥିତିକୁ ଟ୍ରାକ୍ କରନ୍ତୁ |';
    public mobile_app_coming: string = 'ଗୋଟିଏ ଟ୍ୟାପ୍ ସହିତ ଆପଣଙ୍କର ପଲିସି ଡାଉନଲୋଡ୍ କରନ୍ତୁ |';
    public testimonial_title: string = 'ଆମେ ମଧ୍ୟ ଆମର ଗ୍ରାହକଙ୍କ ଅଭିଜ୍ଞତା ବିଷୟରେ ଚିନ୍ତା କରୁ |';
    public our_partner_title: string = 'ଆମର ସହଭାଗୀଗଣ |';
    public our_partner_text: string = 'ଆପଣଙ୍କୁ ସର୍ବୋତ୍ତମ ସେବା ଯୋଗାଇବା ପାଇଁ ଆମେ ମିଳିତ ଭାବରେ କାର୍ଯ୍ୟ କରୁ |';
    public need_help_title: string = 'କିଛି ସାହାଯ୍ୟ ଦରକାର କି?';
    public need_help_text: string = 'ଆପଣଙ୍କୁ ସର୍ବୋତ୍ତମ ସେବା ଯୋଗାଇବା ପାଇଁ ଆମେ ମିଳିତ ଭାବରେ କାର୍ଯ୍ୟ କରୁ | Click4bima ପୋର୍ଟାଲରେ, ଆମେ ଆପଣଙ୍କର ବୀମା ଯାତ୍ରାର ପ୍ରତ୍ୟେକ ପଦକ୍ଷେପରେ ଅସାଧାରଣ ଗ୍ରାହକ ସେବା ଏବଂ ସହାୟତା ପ୍ରଦାନ କରିବାକୁ ପ୍ରତିଶ୍ରୁତିବଦ୍ଧ | ଆସନ୍ତୁ ବୀମା ଜଗତକୁ ନେଭିଗେଟ୍ କରିବାରେ ଆପଣଙ୍କର ବିଶ୍ୱସ୍ତ ଅଂଶୀଦାର ହେବା | ଆଜି ଆମର ସମର୍ଥନ ଦଳ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ ଏବଂ ପାର୍ଥକ୍ୟକୁ ପ୍ରଥମେ ଅନୁଭବ କରନ୍ତୁ | ତୁମର ମାନସିକ ଶାନ୍ତି ଆମର ପ୍ରାଥମିକତା!';
    public mail_us: string = 'ଆମକୁ ମେଲ୍ କରନ୍ତୁ |';
    public mobile_number: string = 'ମୋବାଇଲ୍ ନମ୍ବର';
    public whatsapp: string = 'ହ୍ବାଟ୍ସଆପ୍';
    public about_us: string = 'ଆମ ବିଷୟରେ';
    public sitemap: string = 'ସାଇଟମ୍ୟାପ୍';
    public faqs: string = 'ପ୍ରାୟତଃ ପଚରାଯାଇଥିବା ପ୍ରଶ୍ନ';
    public motor_insurance: string = 'ମୋଟର ବୀମା |';
    public car_insurance: string = 'କାର ବୀମା |';
    public two_wheeler_insurance: string = 'ଦୁଇଟି ଚକିଆ ବୀମା |';
    public taxi_insurance: string = 'ଟ୍ୟାକ୍ସି ବୀମା |';
    public commercial_veh_insurance: string = 'ବାଣିଜ୍ୟିକ ଯାନ ବୀମା |';
    public third_party_car_ins: string = 'ତୃତୀୟ ପକ୍ଷ କାର ବୀମା |';
    public third_party_bike_ins: string = 'ତୃତୀୟ ପକ୍ଷ 2W ବୀମା |';
    public health_insurance: string = 'ସ୍ଵାସ୍ଥ୍ୟ ବିମା';
    public family_health_ins: string = 'ପାରିବାରିକ ସ୍ୱାସ୍ଥ୍ୟ ପଲିସି';
    public individual_health_ins: string = 'ବ୍ୟକ୍ତିଗତ ସ୍ୱାସ୍ଥ୍ୟ ପଲିସି|';
    public group_health_ins: string = 'ଗୋଷ୍ଠୀ ସ୍ୱାସ୍ଥ୍ୟ ପଲିସି';
    public senior_citizen_health_ins: string = 'ବରିଷ୍ଠ ନାଗରିକ ସ୍ୱାସ୍ଥ୍ୟ ପଲିସି';
    public critical_illness_ins: string = 'ଗୁରୁତର ଅସୁସ୍ଥତା ପଲିସି';
    public arogya_san_ins: string = 'ଆରୋଗିଆ ସଞ୍ଜୀବନୀ ପଲିସି';
    public life_insurance: string = 'ଜୀବନ ବୀମା';
    public term_insurance: string = 'ଟର୍ମ ଇନସୁରାନ୍ସ |';
    public single_pay_term_ins: string = 'ଏକକ ଦେୟ ଟର୍ମ ବୀମା | ';
    public group_term_ins: string = 'ଗୋଷ୍ଠୀ ଟର୍ମ ବୀମା |';
    public saving_plans: string = 'ଯୋଜନା ସଞ୍ଚୟ କରୁଛି |';
    public tax_saving_ins: string = 'ଟିକସ ସଞ୍ଚୟ ପଲିସି';
    public money_back_ins: string = 'ଟଙ୍କା ଫେରସ୍ତ ପଲିସି ';
    public guaranteed_return_ins: string = 'ସୁନିଶ୍ଚିତ ଫେରସ୍ତ ପଲିସି';
    public ulip: string = 'ୟୁ ଲ ମୁଁ  ପି';
    public pension_plan: string = 'ପେନ୍ସନ୍ ଯୋଜନା';
    public child_plan: string = 'ଶିଶୁ ଯୋଜନା';
    public others: string = 'ଅନ୍ୟମାନେ |';
    public privacy_policy: string = 'ଗୋପନୀୟତା ପଲିସି';
    public cancellation_refund: string = 'ବାତିଲ୍ ଏବଂ ଫେରସ୍ତ';
    public term_condition: string = 'ସର୍ତ୍ତାବଳୀ ଏବଂ ସର୍ତ୍ତଗୁଡିକ';
    public disclaimer: string = 'ପ୍ରତ୍ୟାଖ୍ୟାନ |';
    public grivance_redressal: string = 'ପ୍ରତିକାର';
    public srg_add_title: string = 'ଏସ୍ଇ ଆର ଜି  ନସୁରାନ୍ସ ବ୍ରୋକର୍ସ ବ୍ୟକ୍ତିଗତ ସୀମିତ |';
    public srg_add_detail: string = '2 & 5, ବିନୟକ କମ୍ପ୍ଲେକ୍ସ, ଦୁର୍ଗା ନର୍ସରୀ ରୋଡ୍, ଉଦୟପୁର, ରାଜସ୍ଥାନ, 313001';

    public direct_broker_title: string = 'ସିଧାସଳଖ ଦଲାଲ, ଲାଇସେନ୍ସ ନଂ: DB-290 |';
    public direct_broker_detail: string = 'ବ id ଧତା ପର୍ଯ୍ୟନ୍ତ: 02.02.2026, CIN: U74140RJ2002PTC017656';
    public payment_method: string = 'ଦେୟ ପଦ୍ଧତି';
    public copyright: string = '© କପିରାଇଟ୍ 2023 କ୍ଲିକ୍ 4 ବିମା | ସମସ୍ତ ଅଧିକାର ସଂରକ୍ଷିତ';
    public continue: string = 'ଜାରି ରଖ | ';
    public bought_new_bike: string = ' ଏକ ନୂତନ ଦୁଇ ଚକିଆ କିଣିଛି |';
    public bought_new_car: string = 'ଏକ ନୂତନ କାର୍ କିଣିଛି |';
    public car_ins_title: string = 'ତୁମର କାର ବୀମା ଉପରେ ଏକ ସ୍ୱତନ୍ତ୍ର କମ୍ ମୂଲ୍ୟ ପାଅ |';
    public car_ins_start: string = 'କାର ବୀମା ₹ 2471 * ରୁ ଆରମ୍ଭ ହେଉଛି |';
    public enter_car_number: string = 'କାର୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ | EX: AB12CD3456 |';
    public vehicle_detail_form: string = 'ତୁମର ଯାନର ବିବରଣୀ |';
    public city: string = 'ସହର';
    public rto: string = "ଆରଟିଓ";
    public manufacturer: string = "ଉତ୍ପାଦକ";
    public model: string = "ମଡେଲ୍ |";
    public fuel_type: string = "ଇନ୍ଧନ ପ୍ରକାର |";
    public variant: string = "ଭାରିଏଣ୍ଟ୍ |";
    public reg_year: string = "ପଞ୍ଜୀକରଣ ବର୍ଷ |";
    public get_quotes: string = "କୋଟ୍ସ ପାଆନ୍ତୁ |";
    public search_city: string = "ସହର ଖୋଜ |";
    public select_city: string = " କିମ୍ବା ସହର ଚୟନ କରନ୍ତୁ |";
    public select_rto: string = 'ସିଟି ଆରଟିଓ ଚୟନ କରନ୍ତୁ |';
    public search_manufacturer: string = 'ସନ୍ଧାନକାରୀ ଉତ୍ପାଦକ |';
    public select_manufacturer: string = 'କିମ୍ବା ଉତ୍ପାଦକ ଚୟନ କରନ୍ତୁ |';
    public search_model: string = 'ସନ୍ଧାନ ମଡେଲ୍ |';
    public select_model: string = 'କିମ୍ବା ମଡେଲ୍ ଚୟନ କରନ୍ତୁ |';
    public search_variant: string = 'ବିଭିନ୍ନ ପ୍ରକାର ଖୋଜ |';
    public select_variant: string = 'କିମ୍ବା ଭାରିଆଣ୍ଟ ଚୟନ କରନ୍ତୁ |';
    public select_year: string = 'ବର୍ଷ ଚୟନ କରନ୍ତୁ |';
    public select_fuel_type: string = 'ଇନ୍ଧନ ପ୍ରକାର ଚୟନ କରନ୍ତୁ |';
    public contact_title: string = 'ଆମ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ |';
    public contact_text: string = 'ଆମେ ଯଥା ଶୀଘ୍ର ଆପଣଙ୍କ ନିକଟକୁ ଫେରିଯିବା |';
    public full_name: string = 'ପୁରା ନାମ';
    public mobile: string = 'ମୋବାଇଲ୍ ନମ୍ବର';
    public interested_in: string = 'ପାଇଁ ଆଗ୍ରହୀ';
    public email: string = 'ଈମୈଲ ଠିକଣା';
    public description: string = 'ବର୍ଣ୍ଣନା';
    public call_me: string = 'ମୋତେ ଫେରିଯାଅ |';
    public continue_fb: string = 'ଫେସବୁକ୍ ସହିତ ଜାରି ରଖନ୍ତୁ |';
    public continue_google: string = 'ଗୁଗୁଲ୍ ସହିତ ଜାରି ରଖ |';
    public sign_in: string = 'ସାଇନ୍ ଇନ୍ କରନ୍ତୁ |';
    public password: string = 'ପାସୱାର୍ଡ';
    public remember: string = 'ମୋତେ ମନେ ରଖିବେ';
    public dont_have_acc: string = "କ any ଣସି ଖାତା ନାହିଁ |";
    public signup_here: string = "ଏଠାରେ ସାଇନ୍ ଅପ୍ କରନ୍ତୁ |";
    public click_here: string = "ଏଠି କ୍ଲିକ କରନ୍ତୁ";
    public send_otp: string = " ଓଟିପି ପ୍ରେରଣ କରନ୍ତୁ |";
    public back_login: string = "ଲଗଇନ୍ କୁ ଫେରନ୍ତୁ |";
    public already_hv_acc: string = "ପୂର୍ବରୁ ଏକ ଖାତା ଅଛି |";
    public signin_here: string = "ଏଠାରେ ସାଇନ୍ ଇନ୍ କରନ୍ତୁ |";
    public verification: string = "ଯା ification ୍ଚ";
    public verify_text: string = "ଆପଣଙ୍କ ସଂଖ୍ୟା +918543871911 କୁ ପଠାଯାଇଥିବା 6 ଅଙ୍କ ବିଶିଷ୍ଟ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ | ଆପଣଙ୍କର ମୋବାଇଲ୍ ନମ୍ବର ପରିବର୍ତ୍ତନ କରିବାକୁ ଏଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ |";
    public enter_otp: string = "ଓଟିପି ପ୍ରବେଶ କରନ୍ତୁ |";
    public dont_rec_otp: string = "ଯଦି ଆପଣ ଏକ କୋଡ୍ ଗ୍ରହଣ କରିନାହାଁନ୍ତି! ପଠାନ୍ତୁ";
    public submit: string = "ଦାଖଲ କରନ୍ତୁ |";
    public accept_terms: string = "ଆପଣଙ୍କର ଖାତା ସୃଷ୍ଟି କରି, ଆପଣ ଆମର ସର୍ତ୍ତାବଳୀ ଏବଂ ଚୁକ୍ତିନାମାକୁ ରାଜି ହୁଅନ୍ତି |";
    public basic_details: string = "ମ .ଳିକ  ଳିକ ବିବରଣୀ";
    public first_name: string = "ପ୍ରଥମ ନାମ";
    public last_name: string = "ଶେଷ ନାମ";
    public dob: string = "ଜନ୍ମ ତାରିଖ";
    public referal_code: string = "ରେଫରାଲ୍ କୋଡ୍ |";
    public gender: string = "ଲିଙ୍ଗ";
    public new_password: string = "ନୂତନ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |";
    public confirm_old_pass: string = "ନୂତନ ପାସୱାର୍ଡ ନିଶ୍ଚିତ କରନ୍ତୁ |";
    public dont_have_policy_popup: string = "ଯଦି ତୁମର ଦୁଇଟି ଚକିଆ ନମ୍ବର ମନେ ନାହିଁ, ତୁମର ଦୁଇ ଚକିଆ ଯାନର ମ details ଳିକ ବିବରଣୀ ପାଇବାକୁ ତୁମର RC (ପଞ୍ଜୀକରଣ ସାର୍ଟିଫିକେଟ୍) ତୁମ ସହିତ ପ୍ରସ୍ତୁତ ରଖ |"; public mobile_app_buy: string = 'ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಎಲ್ಲಾ ನೀತಿಗಳನ್ನು ಖರೀದಿಸಿ, ಸಂಗ್ರಹಿಸಿ ಮತ್ತು ಹಂಚಿಕೊಳ್ಳಿ';
    public year:string='ବର୍ଷ';  
    public your_details:string='ଆପଣଙ୍କର ବିବରଣୀ ପ୍ରବେଶ କରନ୍ତୁ |';
    public name_title:string=' ଆଖ୍ୟା |';
}