<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            
            <div class="row">
                <h1 class="mt-5">Health insurance</h1>
            </div>
            <div class="top-line"></div>
            <p>Health Insurance is one of the type of insurance that provides medical expenses coverage in case of a health emergency. A health insurance plan provides coverage to the insured for different expenses including day-care expenses, surgical and crtical illness expenses etc .</p>
            <p><br></p>
            <div class="title-line"><h4>What is Health insurance Policy?</h4></div>
            <p class="font-body">&nbsp;<br>It is defined generally as a contract between the insurer and the policyholder in which the insurance company provides financial coverage for medical expenses incurred by the insured.the health insurance policy provides benefit of reimbursement of medical expenses or cashless treatment mentioned in the health policy.&nbsp;</p>
            <p><br></p>
            <div class="title-line"><h4>Family Health Insurance Plans:</h4></div>
            <br><p><br>In a Family plan the plan covers the entire family, as many as 6 to 8 members can be covered under one policy taken by the person. There is one sum insured which is shared jointly and independently by each member covered and the total premium is cheaper than separate individual plans when compared by the Insurer. Members who can be covered under this plans include self, spouse, dependent children till 25 years and dependent parents.</p><br>
            <div class="title-line"><h4>Benefits of Family Insurance Plan.</h4></div>
            <p><br>Entire family Coverage: Health insurance plans for family cover all the family members under one plan. You can cover yourself, your spouse, dependent children, dependent parents and even dependent parents-in-law. In fact, there are a some plans which allow you to cover your extended family too. So, family medical insurance plans are a one-stop health insurance solution for all your family members. They also allow health insurance for parents who need affordable health cover.</p>
            <br>
            <div class="title-line"><h4>Every covered member gets high coverage</h4></div>
            <p><br>The sum insured under the family floater policy can be used entirely by any member who falls ill. As such, each family member has complete access to the sum insured and can avail a high coverage if you choose an optimal sum insured level.</p><br>
            <div class="title-line"><h4>3. comprehensive cover option:</h4></div>
            <p><br>Family health plans come with different coverage benefits and you can compare and choose a plan which has the most inclusive coverage features. When you do so, your family can enjoy complete coverage and you would ensure peace of mind knowing that every major medical expense would be covered by the plan.</p><br>
            <div class="title-line"><h4>4. &nbsp; Addition of New Members</h4></div>
            <p><br>Family floater plans allow you the convenience of adding new family members. If there is a new member in your family (childbirth), you can easily avail coverage for the child from the middle of the policy term, depending upon the terms and conditions of the policy. You would just have to make a request to the insurance company, pay an additional premium and the child would be covered under the policy without any hassles.</p><br>
            <div class="title-line"><h4>5. &nbsp; Tax benefits</h4></div>
            <p><br> The last and also the most important benefit in family floater health plans is that they allow you tax benefits. You can enjoy a tax deduction of up to INR 25,000 when you buy a family floater policy. This deduction increases to INR 50,000 if you are senior citizen. Moreover, if you buy a separate family floater health insurance for parents, you can avail an additional deduction of INR 25,000 which also increases to INR 50,000 if your parents are senior citizens.</p> <br>
            <p>Family health insurance plans are, therefore, an ideal solution for the health insurance needs for your family members. The plan is easily available, covers all members and is affordable. So, invest in a family health plan for your family and secure their health.</p><br><br>
            <div class="title-line"><h4>Maternity coverage in some plans:</h4></div>
            <p><br>For a young couple who would be starting a family in the near future, family health plans having maternity coverage is a good choice. These plans allow coverage for maternity related expenses.</p>
            <p>Documents Required to Buy Health Insurance Online</p>
            <p>There are a few documents that a person should have to buy a health insurance policy online. These are-</p>
            <p>Age proof: Any of the following , Birth Certificate, 10th or 12th mark sheet, Driving License, Passport, Voter&rsquo;s ID, etc.</p>
            <p>Identity proof: Either of-Passport, Driving License, Voter ID, PAN Card, Aadhar Card, which proves one&rsquo;s citizenship.</p>
            <p>Address proof: Either of-Electricity bill, Ration Card, Telephone Bill, Passport, Driving License with your permanent address clearly mentioned on it.</p>
            <p>Medical check-up: Some plans require health check-up to ensure that the individual is not suffering from any chronic illness.</p>
            <p>Health Insurance Renewal Online Process - Through the Insurer&rsquo;s Website</p>
            <p>Health insurance renewal is an easy process, especially when done online through the insurer&rsquo;s website by following the steps given below:</p>
            <p >Step 1: Visit the official website of the insurance company.&nbsp;</p>
            <p >Step 2: Go to the health insurance renewal section available on the website.</p>
            <p >Step 3: Enter your previous health insurance policy number, registered mobile number and such other details as required at the insurance company Website.</p>
            <p >Step 4: Check the policy details and the policy renewal premium and conditions such as tenure, payment options etc.</p>
            <p >Step 5: Make the payment using your credit/debit card or Net banking.</p>
            
        </div>
    </div>
</div>
<app-footer></app-footer>
