import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormField, HealthQuestionForm } from 'src/app/Models/HeathQuestionForm';

@Component({
  selector: 'app-dynamic-components',
  templateUrl: './dynamic-components.component.html',
  styleUrls: ['./dynamic-components.component.css']
})
export class DynamicComponentsComponent {
  @Input() required_input_fields: HealthQuestionForm;
  @Input() questionFor: string;
  @Output() dataEvent  = new EventEmitter<any>(); 

  onChange(id:string,event:any, type:any="", parentId:any =""){
    ;
    let selectedValue = type !="checkbox" ? event.target.value : (event.target.checked == true ? "Yes" : "No");
    console.log(id + '-' + selectedValue);
    this.required_input_fields.formFields = this.required_input_fields.formFields?.map((obj:FormField) => {
      if (obj.id === id) {
          return { ...obj, selectedValue: selectedValue };
      }
      return obj;
    });
    this.dataEvent.emit({data: this.required_input_fields,parentId:parentId,for:this.questionFor});
  }
}
