import { Injectable } from '@angular/core';

import { hindiLabel } from '../Models/hindiLanguageLabel';
import { englishLabel } from '../Models/englishLanguageLabel';
import { gujratiLabel } from '../Models/gujratiLanguageLabel';
import { punjabiLabel } from '../Models/punjabiLanguageLabel';
import { marathiLabel } from '../Models/marathiLanguageLabel';
import { kannadaLabel } from '../Models/kannadaLanguageLabel';
import { teluguLabel } from '../Models/teluguLanguageLabel';
import { malayalamLabel } from '../Models/malyalamLanguageLabel';
import { tamilLabel } from '../Models/tamilLanguageLabel';
import { bengaliLabel } from '../Models/bengaliLanguageLabel';
import { odiyaLabel } from '../Models/odiyaLanguageLabel';

@Injectable({
  providedIn: 'root'
})
export class LanguageLableService { 
  hindiLabelData = new hindiLabel()
  englishLabelData = new englishLabel()
  gujratiLabelData = new gujratiLabel()
  punjabiLabelData = new punjabiLabel()
  marathiLabelData = new marathiLabel()
  kannadaLabelData = new kannadaLabel()
  teluguLabelData = new teluguLabel()
  malayalamLabelData = new malayalamLabel()
  tamilLabelData = new tamilLabel()
  bengaliLabelData = new bengaliLabel()
  odiyaLabelData = new odiyaLabel()
  constructor() { }

  languageDropdown() {
    let Model:any = [];
    const storedValue = localStorage.getItem('selectedValue'); 
    if (storedValue == "hindi") {
      Model = this.hindiLabelData; 
    }
    else if (storedValue == "gujrati") {
      Model = this.gujratiLabelData; 
    }
    else if (storedValue == "punjabi") {
      Model = this.punjabiLabelData; 
    }
    else if (storedValue == "kannada") {
      Model = this.kannadaLabelData; 
    }
    else if (storedValue == "marathi") {
      Model = this.marathiLabelData; 
    }
    else if (storedValue == "telugu") {
      Model = this.teluguLabelData; 
    }
    else if (storedValue == "malayalam") {
      Model = this.malayalamLabelData; 
    }
    else if (storedValue == "tamil") {
      Model = this.tamilLabelData; 
    }
    else if (storedValue == "bengali") {
      Model = this.bengaliLabelData; 
    }
    else if (storedValue == "odiya") {
      Model = this.odiyaLabelData; 
    }
    else if (storedValue == "english") {
      Model = this.englishLabelData; 
    } else {
      Model = this.englishLabelData; 
    }
    return Model;
  }
}
