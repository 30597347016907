import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsuranceService } from 'src/app/Services/insurance.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-add-testimonial',
  templateUrl: './add-testimonial.component.html',
  styleUrls: ['./add-testimonial.component.css']
})
export class AddTestimonialComponent {
  testimonialsForm: FormGroup;
  constructor(private fb: FormBuilder,private insuranceService:InsuranceService) {
    this.testimonialsForm = this.fb.group({
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],  
      "currentCareerStage": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "review": ['', [Validators.required]]
    });
  
    
  }

 async save(){
  const response = await this.insuranceService.PostTestimonials(this.testimonialsForm.value);
  if (response?.isSuccess) {
    swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Testimonial added successfully',
      showConfirmButton: false,
      timer: 3000,
    });
    this.testimonialsForm.reset();
  }
  }

}

