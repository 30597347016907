<div class="container">
    <div class="row mt-10">
        <div class="col-md-12 mt-10">

            <div class="row">
                <div class="col-md-4 mt-4">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-md-1 c1 mt-5" *ngIf="!isBasicDetails">
                                <img src="../../../assets/images/Check box2.svg" alt="">
                            </div>
                            <div class="col-md-1 c1 mt-5" *ngIf="isBasicDetails">
                                <img src="../../../assets/images/Check box.svg" alt="">
                            </div>
                            <div class="col-md-11 c11 mt-6">
                                <p>Basic Details</p>
                            </div>
                            <div class="col-md-1 text-center  vl">

                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-1 c1 mt-5" *ngIf="!isCategory">
                                <img src="../../../assets/images/Check box2.svg" alt="">
                            </div>
                            <div class="col-md-1 c1 mt-5" *ngIf="isCategory">
                                <img src="../../../assets/images/Check box.svg" alt="">
                            </div>
                            <div class="col-md-11 c11 mt-6">
                                <p>Business Category Details</p>
                            </div>
                            <div class="col-md-1 text-center  vl">

                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-1 c1 mt-5" *ngIf="!isContanctDetail">
                                <img src="../../../assets/images/Check box2.svg" alt="">
                            </div>
                            <div class="col-md-1 c1 mt-5" *ngIf="isContanctDetail">
                                <img src="../../../assets/images/Check box2.svg" alt="">
                            </div>
                            <div class="col-md-11 c11 mt-6">
                                <p>Contact Details</p>
                            </div>


                        </div>

                    </div>
                </div>
                <div class="col-md-8 mt-4">
                    <div class="card p-3">
                        <div  *ngIf="page=='basic'">
                            <form [formGroup]="BasicDetails">
                            <div class="row l-bm">
                                <div class="col-md-8 mt-4">
                                    <h4>Basic Details</h4>
                                </div>
                                <div class="col-md-4 mt-2">
                                    <div class="row">
                                        <div class="col-md-5  mt-2">
                                            <button class="btn btn-sm btn-back" (click)="back()">Back</button>
                                        </div>
                                        <div class="col-md-7   mt-2 " >
                                            <button class="btn btn-sm btn-primary "[disabled]="BasicDetails.invalid" (click)="Pages('category')">Save &Next</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mt-2 ">
                                <div class="col-md-12">
                                    <label for="">Do you have GSTIN Number?</label>

                                </div>
                                <div class="col-md-2 c6">
                                    <div class="card cd ">
                                        <div class="form-check ml-3 mt-2 ">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" (click)="nubmer('gst')" checked>
                                            <p class="mt-2">
                                                &nbsp;&nbsp; Yes
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-md-2 c6">
                                    <div class="card cd ">
                                        <div class="form-check ml-3 mt-2 ">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" (click)="nubmer('pan')">
                                            <p class="mt-2">
                                                &nbsp;&nbsp; No
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 l-bm">

                                <div class="col-md-6" *ngIf="GSTno">
                                    <label for="">GSTIN Number</label>
                                    <div class=" btnIn">
                                        <input type="text" name="" class="" placeholder=" Eg : 22AAAAA0000A1Z5" id="" formControlName="number">
                                        <button>Verify</button>

                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="PANno">
                                    <label for="">PAN Number</label>
                                    <div class=" btnIn">
                                        <input type="text" name="" class="" placeholder=" Eg : 22AAAAA0000A1Z5" id=""  formControlName="number">
                                        <button>Verify</button>

                                    </div>
                                </div>

                            </div>
                            <div class="row mt-2 l-bm">
                                <div class="col-md-12 mt-2">
                                    <label for="">Clinic Name</label>
                                    <p class="fn">SIMS Hospital</p>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <label for="">Business PAN Card Number</label>
                                    <p class="fn">AAAAA1111A</p>

                                </div>
                                <div class="col-md-6 mt-2">
                                    <label for="">Established Year</label>
                                    <p class="fn">2015</p>

                                </div>
                                <div class="col-md-6 mt-2">
                                    <label for="">Business Address</label>
                                    <p class="fn">Address Here</p>

                                </div>
                                <div class="col-md-6 mt-2">
                                    <label for="">PIN Code</label>
                                    <p class="fn">395004</p>

                                </div>
                                <div class="col-md-6 mt-2">
                                    <label for="">State</label>
                                    <p class="fn">Gujarat</p>

                                </div>
                                <div class="col-md-6 mt-2">
                                    <label for="">City</label>
                                    <p class="fn">Surat</p>

                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6 mt-5 text-start">
                                    <label for="front-file-upload" class="custom-file-upload text-center">
                                        <img src="../../../assets/svgIcon/uplod.svg" alt="" width="100%">
                                        <p class="mt-3 p-2">Upload </p>

                                    </label>
                                    <input id="front-file-upload" type="file" accept="image/*">
                                </div>
                            </div>
                            </form>
                        </div>
                        <div  *ngIf="page=='category'">
                            <form [formGroup]="Category">
                            <div class="row l-bm">
                                <div class="col-md-8 mt-4">
                                    <h4>
                                        Business Category Details</h4>
                                </div>
                                <div class="col-md-4 mt-2">
                                    <div class="row">
                                        <div class="col-md-5 mt-2">
                                            <button class="btn btn-sm btn-back" (click)="Pages('basic')">Back</button>
                                        </div>
                                        <div class="col-md-7 mt-2">
                                            <button class="btn btn-sm btn-primary" (click)="Pages('contact')">Save &Next</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-6 mt-3">
                                    <label for="">Business Subcategory</label>
                                    <select class="form-select" aria-label="Default select example" formControlName="Subcategory">
                                      <option selected>Select Business Catgory</option>
                                
                                    </select>  
                                  </div> 
                                <div class="col-md-6 mt-3">
                                  <label for="">Select Business Catgory</label>
                                  <select class="form-select" aria-label="Default select example" formControlName="BusinessCatgory">
                                    <option selected>Select Business Catgory</option>
                             
                                  </select>  
                                </div>
                                
                          
                            </div>
                        </form>
                        </div>
                        <div *ngIf="page=='contact'">
                            <form [formGroup]="Contact">
                                <div class="row l-bm">
                                    <div class="col-md-8 mt-4">
                                        <h4>
                                            Contact Details</h4>
                                    </div>
                                    <div class="col-md-4 mt-2">
                                        <div class="row">
                                            <div class="col-md-5 mt-2">
                                                <button class="btn btn-sm btn-back" (click)="Pages('category')">Back</button>
                                            </div>
                                            <div class="col-md-7 mt-2">
                                                <button class="btn btn-sm btn-primary" [disabled]="Contact.invalid" (click)="success()">Save &Next</button>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                <div class="row l-bm">
                                    <div class="col-md-6 mt-4">
                                        <label for="">Business Mobile Number</label>
                                        <input type="number" placeholder="+91 75970696969" name="" id="  " class="form-control" formControlName="MobileNumber">
                                    </div>
                                    
                                    <div class="col-md-6 mt-4">
                                        <label for="">Business Email Address</label>
                                        <input type="email" name="" id="  " class="form-control" placeholder="useremailadd@domain.com" formControlName="Email">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <div class="row mt-2 ">
                                            <div class="col-md-12">
                                                <label for="">Do you have GSTIN Number?</label>
            
                                            </div>
                                            <div class="col-md-6 mt-2 c6">
                                                <div class="card cd ">
                                                    <div class="form-check ml-3 mt-2 ">
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                            id="flexRadioDefault1" (click)="nubmer('yes')" checked>
                                                        <p class="mt-2">
                                                            &nbsp;&nbsp; Yes
                                                        </p>
                                                    </div>
            
                                                </div>
                                            </div>
            
                                            <div class="col-md-6 mt-2 c6">
                                                <div class="card cd ">
                                                    <div class="form-check ml-3 mt-2 ">
                                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                            id="flexRadioDefault1" (click)="nubmer('no')">
                                                        <p class="mt-2">
                                                            &nbsp;&nbsp; No
                                                        </p>
                                                    </div>
            
                                                </div>
                                            </div> 
                                          
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-10" *ngIf="isWhatsapp">
                                            <label for="">WhatsApp Number</label>
                                            <input type="number" name=""  class="form-control" placeholder="+91 75970696969" id="" formControlName="WhatsApp">
                                        
                                    </div>
                                </div> 
                                <div class="row mt-3">
                                    <div class="col-md-6 mt-2">
                                        <label for="">Business Website</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="Website">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">Business App</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id=""  formControlName="BusinessApp">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">Facebook Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id=""  formControlName="Facebook">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">Instagram Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="Instagram">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">LinkedIn Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="LinkedIn">
                                    </div>
                                    
                                    <div class="col-md-6 mt-2">
                                        <label for="">X (twitter) Link</label>
                                        <input type="text" name="" class="form-control" placeholder="Enter URl Here" id="" formControlName="twitter">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="Success">
    <div class="popup2">
        <div class="modal-header  bg-transparent">
            <!-- <h4 class="modal-title">Sign In</h4> -->
            <button type="button" class=" close pull-right" aria-label="Close" (click)=" close()">

            </button>
        </div>
        <div class="model-body">
            <div class=" row text-center">
                <div class="col-md-12 mt-3 p-3 text-center">
                    <img src="../../../assets/images/green_double_circle_check_mark 1.png" alt="">
                </div>
                <div class="col-md-12 mt-3">
                    <h6>""Business Verification Successfully Done""</h6>
                </div>
                <div class="col-md-12 mt-3">
                    <span>Congratulations! You've successfully Completed the Business Verification</span>
                </div>
                <div class="col-md-12 mt-2">
                    <button class="btn btn-sm btn-primary" (click)="back()">
                        Continue
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>