import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AutoCompleteItem } from 'src/app/Models/Item';
import { AgentprofileService } from 'src/app/Services/agentprofile.service';
import { GeneralService } from 'src/app/Services/general.service';
import { InsuranceService } from 'src/app/Services/insurance.service';
import { LookUpService } from 'src/app/Services/lookup.service';
import { PolicyService } from 'src/app/Services/policy.service';
import { UserService } from 'src/app/Services/user.service';
import { VehicleService } from 'src/app/Services/vehicle.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-add-policy',
  templateUrl: './add-policy.component.html',
  styleUrls: ['./add-policy.component.css']
})
export class AddPolicyComponent {
  userForm: FormGroup;
  nomineeForm: FormGroup;
  vehicleForm: FormGroup;
  existingTPPolicyDetailsForm: FormGroup;
  validatePolicyUserForm: FormGroup;
  insuranceForm: FormGroup;
  priceBreakupForm: FormGroup;
  activeTab:number=1;
  ageList:any[]= [];
  detail:string='owner';
  submitted:boolean = false;
  submittedAddress:boolean = false;
  submittedNominee:boolean = false;
  submittedVehicle:boolean = false;
  submittedPolicy:boolean = false;
  isOwnerValid:boolean = false;
  isAddressValid:boolean = false;
  isNomineeValid:boolean = false;
  isVehicleValid:boolean = false;
  isPolicyValid:boolean = false;
  insuranceCompany:number = 0;
  insuranceCompanyStr:string = '';
  agentName:string = "";
  policyTypeList=
  [
    {value:'',text:'Select Previous Policy Type'},
    {value:'Comprehensive',text:'Standard/Comprehensive Coverage'},
    {value:'Thirdparty',text:'Third-Party Liability Coverage'},
    // {value:'NA',text:'I am Not Sure'}
  ];
  newPolicyTypeList=
  [
    {value:'',text:'Select Policy Type'},
    {value:'Comprehensive',text:'Package'},
    {value:'Bundled',text:'Bundled (1 + 3)'}, 
    {value:'Thirdparty',text:'TP'},   
    {value:'Standalone',text:'SAOD'}    
  ];
  isExistingPolicyValidationRemoved:boolean = true;
  RelationShipList: { id: number; name: string; }[];
  insurancePartnersList:AutoCompleteItem[] =[];
  stateList :AutoCompleteItem[] =[];
  districtList:AutoCompleteItem[]=[];
  ensuringBranchStateList :AutoCompleteItem[] =[];
  ensuringBranchDistrictList:AutoCompleteItem[]=[];
  pincodeList: AutoCompleteItem[] = [];
  cityList: AutoCompleteItem[] = [];
  state:string="";
  district:string="";
  pincodeId:string="";
  panel1:boolean=true;
  panel2:boolean=false;
  panel3:boolean=false;
  panel4:boolean=false;
  panel5:boolean=false;
  isSummary:boolean=false;
  titleList: string[] = ["Mr","Mrs","Ms","M/s"];
  genderList: string[] = ["Male","Female","Other"];
  policyCategory :{ id: number; name: string; }[];
  isMotor:string = "Yes";
  policyImage: string | ArrayBuffer | null = null;
  policyImageRequiredError: false;
  vehicleMakeList:AutoCompleteItem[] =[];
  vehicleModelList:AutoCompleteItem[] =[];
  vehicleVariantList:AutoCompleteItem[] =[];
  agentList:any;
  fuelType:any[] = ['Petrol','Diesel','CNG','LPG','Electric'];
  vehicleCategory:any[] = ['PCV','GCV','MISC'];
  id:any = null;
  constructor(private fb: FormBuilder, private lookUpService: LookUpService, private _lookupService:LookUpService,private agentprofileService : AgentprofileService,private spinner: NgxSpinnerService,
    private generalService : GeneralService, private router: Router, private policyService:PolicyService, private vehicleService:VehicleService)
  {
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {      
      this.id = obj['id'];
    }   
    this.loadAgentList(); 
  }
  UpdateUniqueUserId(uniqueUserId: string) {
    this.insuranceForm.get('uniqueUserId')?.setValue(uniqueUserId);
  }

  ngOnInit() {
    this.ageList.push({value:'',text: 'Select your age'});
    for(let i=18;i<=100;i++)
    {
      this.ageList.push({value:i.toString(),text: i.toString()});
    }

    this.insuranceForm = this.fb.group({
      vehicleType: [1, Validators.required],
      quoteId: [''],
      userId:[0],
      agentId: [this.generalService.getUniqueuserid(), Validators.required],
      uniqueUserId:[""],
      isMockUp: [false],
      insuranceCompany:[''],
      proposerDetails: [],
      nomineeDetails: [],
      vehicleDetails: [],
      existingTPPolicyDetails:[],
      priceBreakup:[],
      planName:['', Validators.required],
      isMotor:['Yes'],
      channel:['',Validators.required]
    });

    this.userForm = this.fb.group({
      "firstName": ['', [Validators.required]],
      "lastName": [''],
      "phoneNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "countryCode": [''],
      "email": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$') ]],
      "dob": [''],
      "gender": ['Male', [Validators.required]],
      "streetAddress": ['', [Validators.required]],
      "pincode": ['',[Validators.required,Validators.pattern('[0-9]{6}')]],
      "pincodeId": [''],
      // "dateOfBirth": ['', [Validators.required]],
      // "panNumber": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "dateOfBirth": [''],
      "panNumber": [''],
      "flatNo": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "district": [''],
      "state": ['', [Validators.required]],
      "title":['Mr'],
      "aadharNumber":[''],
      "aadharFrontBase64":[''],
      "aadharBackbase64":[''],
      "panBase64":[''],
      "form16Base64":[''],
      "isPANNumber":[true],
      "insuredBase64":[''],
      "fatherName":[''],
      "aadharFrontExt":[''],
      "aadharBackExt":[''],
      "panExt":[''],
      "form16Ext":[''],
      "insuredPhotoExt":[''],
      "fatherFirstName":[''],
      "fatherLastName":['']      
    });
    this.nomineeForm = this.fb.group({
      "nomineeName": [''],
      "nomineeAge": [''],
      "relationshipWithNominee": [''],
      "gender": ['Male']
    });

    this.vehicleForm = this.fb.group({
      "city": [""],
      "rto": [""],
      "make": [""],
      "model": [""],
      "variant": [""],
      "registrationYear": [""],
      "registrationNumber": ["", [Validators.required]],
      "chasisNumber": ["", [Validators.required]],
      "engineNumber": ["", [Validators.required]],
      "registrationDate": ["", [Validators.required]],         
      "ncbprevious": [""],      
      "typeOfCover": [""],
      "vehicleIDV":[""],
      "bodyIDV":["0"],//shivraj
      "chassisIDV":["0"],//shivraj
      "previousPolicyCompany":[""],
      "previousPolicyNumber":[""],    
      "previousPolicyType":[""],  
      "previousPolicyExpiryDate":[""],
      "claimsMadeInPreviousPolicy": ["No"],   
      "ncbCurrent":[""],
      "firstName": [""],
      "lastName": [""],
      "vehiclecategory":[""],
      "isPreviousPolicyHolder":[""],      
      "fuelType":["Petrol"],
      "engineCapacityAmount" :[""],
      "paCover":[""],
      "alreadyHavePACover":[""],
      "paPolicyTerm":[""],
      "registeredInTheNameOf": [""],
      "isValidDrivingLicenseAvailable": [""],
      "premium":[""],
      "isFinanced":[""],
      "financedValue": [""],
      "financierName":[""],
      "policyTerm": ["1"],
      "isTPPD":[false],
      "companyNameForCar":[""],
      "companyGSTINForCar":[""],
      "isNew":[false],
      "category":['PCV'],
      "gcVehicle":[''],
      "pcVehicle":[''],
      "purpose":[''],
      "seatingCap":[''],
      "miscVehicle":[''],
      "gVW":[''],
      "permit":[''],
      "policyExpiryStatus":[""],
      "isOwnerShipTransferred":["No"],
      "policyClaimed": [''],
      "policyClaimedNCB":[''],
    })

    // this.existingTPPolicyDetailsForm = this.fb.group({
    //   "tpInsurer": ['', [Validators.required]],
    //   "tpPolicyNumber": ['', [Validators.required]],
    //   "tpPolicyTerm": ['1', [Validators.required]],
    //   "tpInsurerAddress": ['', [Validators.required]],
    //   "tpAddress1": ['', [Validators.required]],
    //   "tpAddress2": ['', [Validators.required]],
    //   "tpInceptionDate": ['', [Validators.required]],
    //   "tpExpiryDate": ['', [Validators.required]],
    //   "tpSumInsured": ['1500000', [Validators.required]]
    // });

    this.priceBreakupForm = this.fb.group({
      "policyNumber": ['', [Validators.required]],
      "net_TP": ['', [Validators.required]],
      "net_OD": ['', [Validators.required]],
      "basicOwnDamagePremium": [''],
      "thirdPartyCoverPremium": [''],
      "addOnValue": [''],
      "paCover":[""],
      "serviceTaxValue": ['', [Validators.required]],
      "netPremium": ['', [Validators.required]],
      "finalPremium": ['', [Validators.required]],
      "planName": ['', [Validators.required]],
      "insuranceCompany": ['', [Validators.required]],
      "policyStartDate": ['', [Validators.required]],
      "policyEndDate": ['', [Validators.required]],  
      "policyBase64" : ['', [Validators.required]],
      "isPACover":["No"],  
    });

    this.loadPolicyCategory();
    this.loadRelationships();

    this.loadInsurancePartners();
    this.getStatesByCountry('India');
     //this.setPANAadharVisbility(); 
      
      this.validatePolicyUserForm = this.fb.group({
        "firstName": [''],
        "lastName": [''],
        "phoneNumber": [''],
        "emailAddress": [''],
        "otpNumber": ["",[Validators.required,Validators.pattern('[0-9]{6}')]],
        "quoteId": [''],
        "insuranceId": [''],
        "productId": [''],
        "title": [''],
        "isOtpVerified" : [false]
      });      
      this.categoryChange();

      if(this.id != "0")
      {      
        this.loadPolicyData(this.id);
      }   
  }

  Details(name:string):void{
    this.detail=name;
    if(name == 'address' && this.detail =='owner')
    {
      this.submitted = true;
      if(this.userFormControl['firstName'].valid && (this.userFormControl['lastName'].valid || this.userFormControl['title'].value == 'M/s') &&
      this.userFormControl['phoneNumber'].valid && this.userFormControl['email'].valid &&
      this.userFormControl['panNumber'].valid && this.userFormControl['dateOfBirth'].valid )
      {
        this.detail=name;
        this.isOwnerValid = true;
      }
      else
      {
        this.isOwnerValid = false;
      }
    }
    else if(name == 'nominee' && this.detail =='address')
    {
      this.submittedAddress = true;
      if(this.userFormControl['flatNo'].valid && this.userFormControl['streetAddress'].valid &&
      this.userFormControl['state'].valid && this.userFormControl['city'].valid &&
      this.userFormControl['pincode'].valid)
      {
        this.detail=name;
        this.isAddressValid = true;
      }
      else{
        this.isAddressValid = false;
      }
    }
    else if(name == 'vehicle' && this.detail =='nominee')
    {
      this.submittedNominee = true;
      if(this.nomineeForm.valid)
      {
        this.detail=name;
        this.isNomineeValid = true;
      }
      else
      {
        this.isNomineeValid = false;
      }
    }
    else if(name == 'policy' && this.detail =='vehicle')
    {
      this.submittedVehicle = true;
      if(this.vehicleFormControl['registrationNumber'].valid && this.vehicleFormControl['chasisNumber'].valid &&
      this.vehicleFormControl['engineNumber'].valid && this.vehicleFormControl['registrationDate'].valid)
      {
        this.detail=name;
        this.isVehicleValid = true;
      }
      else
      {
        this.isVehicleValid = false;
      }
    }
    else if(name == 'vehicle'){
      this.detail=name;
    }
    else
    {
      this.detail=name;
    }
    
  }

  get insuranceFormControl() {
    return this.insuranceForm.controls;
  }
  
    get userFormControl() {
      return this.userForm.controls;
    }
  
    get nomineeFormControl() {
      return this.nomineeForm.controls;
    }
  
    get vehicleFormControl() {
      return this.vehicleForm.controls;
    }
  
    // get existingTPPolicyDetailsFormControl(){
    //   return this.existingTPPolicyDetailsForm.controls;
    // }

    get priceBreakupFormControl(){
      return this.priceBreakupForm.controls;
    }
    

    loadRelationships() {
      this.RelationShipList = [ 
      { id: 1, name: "Father" }, 
      { id: 2, name: "Mother" }, 
      { id: 3, name: "Brother" },
      { id: 4, name: "Sister" }, 
      { id: 5, name: "Son" }, 
      { id: 6, name: "Daughter" }, 
      { id: 7, name: "Uncle" },
      { id: 8, name: "Aunt" },
      { id: 9, name: "Spouse"},
      { id: 11, name: "Grand Son"},
      { id: 12, name: "Grand Daughter"}
      ]
      // [{ id: 1, name: "Spouse" }, { id: 2, name: "Parent" }, { id: 3, name: "Child" }, { id: 4, name: "Sibling" },
      // { id: 5, name: "Grandparent" }, { id: 6, name: "Grandchild" }, { id: 7, name: "Cousin" }, { id: 8, name: "AuntUncle" },
      // { id: 9, name: "NieceNephew" }, { id: 10, name: "Friend" }, { id: 11, name: "Colleague" }, { id: 12, name: "Acquaintance" }];
    }

    loadPolicyCategory(){
      if(this.insuranceFormControl['isMotor'].value == "Yes"){
        this.policyCategory =  [{ id:1,name : "Car"},
        { id:2,name : "Bike"},
        { id:3,name : "Commercial"}];

        this.priceBreakupForm.get('net_OD')?.addValidators([Validators.required]);
        this.priceBreakupForm.get('net_OD')?.updateValueAndValidity();
        this.priceBreakupForm.get('net_TP')?.addValidators([Validators.required]);
        this.priceBreakupForm.get('net_TP')?.updateValueAndValidity();
      }
      else
      {
        this.policyCategory =  [
          { id:4,name : "Health"},
          // { id:5,name : "Health Family"},
          { id:6,name : "Travel"},
          { id:7,name : "Home"},
          { id:8,name : "Office"},
          { id:9,name : "Shop"},
          { id:10,name : "General"}];

          this.priceBreakupForm.get('net_OD')?.clearValidators();
          this.priceBreakupForm.get('net_OD')?.updateValueAndValidity();
          this.priceBreakupForm.get('net_TP')?.clearValidators();
          this.priceBreakupForm.get('net_TP')?.updateValueAndValidity();
      }
    }
    
    async loadInsurancePartners()
    {
      let response = await this._lookupService.GetInsurancePartnersList('a');
        if (response?.isSuccess) {
          this.insurancePartnersList = response.result.map((m:any)=> {return {text:m.insuranceName,value:m.insuranceId,id:m.insuranceId,text1:m.cpaCoverAmount}});
          if(this.id != "0"){            
            this.insuranceCompanyStr =  this.insurancePartnersList.filter((f:AutoCompleteItem)=>{return f.value == this.insuranceCompany?.toString()})[0]?.text??"";
          }
        }
    }
  
    async getStatesByCountry(country:string)
    {
      let response = await this._lookupService.getStatesListByCountry(country); 
      if (response?.isSuccess) {
        this.ensuringBranchStateList = this.stateList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
      }
    }
  
    async getDistrictsByState(state:string)
    {
      let response = await this._lookupService.getDistrictsListByState(state); 
      if (response?.isSuccess) {
        this.districtList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
      }
    }
  
    async getPincodesByDistrict(district:string)
    {
      let response = await this._lookupService.getPincodeByDistrict(district); 
      if (response?.isSuccess) {
        let pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode, id:item.id}});
        this.pincodeList = this.uniqueItems(pincodeList);
        //this.cityList = response.result.map((item:any)=> {return {text:item.cityVillageArea,value:item.cityVillageArea, text1:item.pincode, id:item.id}});
      }
    }
  
    onStateSelection(data:AutoCompleteItem){
     this.getDistrictsByState(data.text);
     this.userForm.get('state')?.setValue(data.text);
     console.log(this.userForm.value);
    }
    
  
    onDistrictSelection(data:AutoCompleteItem){
      this.userForm.get('city')?.setValue(data.text);
      this.userForm.get('district')?.setValue(data.text);
      this.userForm.get('state')?.setValue(data.pincodeMaster?.state?.toUpperCase());
      this.getPincodesByDistrict(data.text);
      // this.userForm.get('pincode')?.setValue(data.pincodeMaster?.pincode);
      // this.userForm.get('pincodeId')?.setValue(data.pincodeMaster?.pincode?.toUpperCase());
      // this.userForm.get('city')?.setValue(data.pincodeMaster?.cityVillageArea?.toUpperCase());
      // this.userForm.get('district')?.setValue(data.pincodeMaster?.district?.toUpperCase());
      // this.userForm.get('city')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    }
  
     onPincodeSelection(data:AutoCompleteItem)
     {
      // this.getDistrictsByState(data.pincodeMaster?.state?.toUpperCase()??"");
      // this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase()??"");
      this.userForm.get('pincode')?.setValue(data.text);
      this.userForm.get('pincodeId')?.setValue(data.text);
      // this.userForm.get('city')?.setValue(data.pincodeMaster?.cityVillageArea?.toUpperCase());
      // this.userForm.get('district')?.setValue(data.pincodeMaster?.district?.toUpperCase());
      // this.userForm.get('city')?.setValue(data.pincodeMaster?.district?.toUpperCase());
      // this.userForm.get('state')?.setValue(data.pincodeMaster?.state?.toUpperCase());
    }
  
     onCitySelection(data:AutoCompleteItem)
     {
  
       this.userForm.get('city')?.setValue(data.text);
      // console.log(this.userForm.value);
     }
  
     onStateBlur(data:any)
     {
      this.userForm.get('state')?.setValue(data);
     }
  
     onPincodeBlur(data:any)
     {
        this.userForm.get('pincode')?.setValue(data);
        this.userForm.get('pincodeId')?.setValue(data);
     }
  
     async onPincodeChange(data:any) {
      // if(data.length >= 3){
      //   const res = await this.lookUpService.pincodeMasterDetails(data);
      //   if (res != null && res.result) {
      //     this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode, id:item.id}});
      //   }
      // }      
    }

    async onDistrictChange(data:any) {
      if(data.length >= 4){
        const res = await this.lookUpService.getPincodeByDistrict(data);
        if (res != null && res.result) {
          //this.districtList = res.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
          //this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
          let districtList = res.result.map((item:any)=> {return {text:item.district,value:item.district, id:item.id,pincodeMaster:item}});
          this.districtList = this.uniqueItems(districtList);
        }
      }      
    }
  
     onCityBlur(data:any)
     {
      // if(data == ""){
         this.userForm.get('city')?.setValue(data);
      // }
     }
  
     onDistrictBlur(data:any)
     {
        this.userForm.get('city')?.setValue(data);
        this.userForm.get('district')?.setValue(data);
     }
  
    onPrevCompSelection(data:AutoCompleteItem){
      this.vehicleForm.get('previousPolicyCompany')?.setValue(data.text);
    }
  
    onPrevCompBlur(data:any)
    {
      this.vehicleForm.get('previousPolicyCompany')?.setValue(data);
    }

    onInsCompSelection(data:AutoCompleteItem){
      this.insuranceForm.get('insuranceCompany')?.setValue(parseInt(data.value));
      this.priceBreakupForm.get('insuranceCompany')?.setValue(parseInt(data.value));
      this.priceBreakupForm.get('paCover')?.setValue(data.text1);
      this.insuranceCompany = parseInt(data.value);
      this.insuranceCompanyStr = data.text;
    }

    // onInsCompBlur(data:any)
    // {
    //   this.vehicleForm.get('previousPolicyCompany')?.setValue(data);
    // }
  
    changeTab(tab:string)
    {
      switch(tab){
        case 'owner' : if(this.isOwnerValid) this.detail =tab; break;
        case 'address' : if(this.isAddressValid) this.detail =tab; break;
        case 'nominee' : if(this.isNomineeValid) this.detail =tab; break;
        case 'vehicle' : if(this.isVehicleValid) this.detail =tab; break;
        case 'policy' : if(this.isPolicyValid) this.detail =tab; break;
      }    
    }
    
     toggleCollapse(panelNumber:number){
      this.hideAllPanel();
      switch(panelNumber){
        case 1 : this.panel1 = !this.panel1; break;
        case 2 : this.panel2 = !this.panel2; break;
        case 3 : this.panel3 = !this.panel3; break;
        case 4 : this.panel4 = !this.panel4; break;
        case 5 : this.panel5 = !this.panel5; break;
      }
    }
  
    editClick(tab:string){
      this.isSummary = false;
      this.changeTab(tab);
    }
  
    hideAllPanel(){
      this.panel1 = false;
      this.panel2 = false;
      this.panel3 = false;
      this.panel4 = false;
      this.panel5 = false;
    }

    // onPrevTPCompSelection(data:AutoCompleteItem){
    //   this.existingTPPolicyDetailsForm.get('tpInsurer')?.setValue(data.text);
    // }
  
    // onPrevTPCompBlur(data:any)
    // {
    //   this.existingTPPolicyDetailsForm.get('tpInsurer')?.setValue(data);
    // }

    onInputChange(event: any): void {
      const initialValue = event.target.value;
     const input = event.target as HTMLInputElement;
  
      // Strictly replace anything not a digit.
      event.target.value = initialValue.replace(/[^0-9]/g, '');
      if (input.value.length > 6) {
          input.value = input.value.slice(0, 6);
          event.stopPropagation();
    
        }
      if (initialValue !== event.target.value) {
        event.stopPropagation();
        
      }
    }
    
    numberOnly(event:any): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      }
      return false;
    }
  
    alphabetOnly(event:any): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
        return true;
      }
      return false;
    }
  
    alphaNumericOnly(event:any): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
        return true;
      }
      return false;
    }
    
      policyTermList=
    [
      {value:'1',text:'1 Year'},
      {value:'2',text:'2 Year'},
      {value:'3',text:'3 Year'},
      {value:'4',text:'4 Year'},
      {value:'5',text:'5 Year'}
    ];
    
    // onEnsuringBranchStateSelection(data:AutoCompleteItem){
    //   this.getEnsuringBranchDistrictsByState(data.text);
    //   this.existingTPPolicyDetailsForm.get('tpAddress2')?.setValue(data.text);
    //  }
  
    //  onEnsuringBranchStateBlur(data:any)
    //  {
    //   this.existingTPPolicyDetailsForm.get('tpAddress2')?.setValue(data);
    //  }   
   
    //  onEnsuringBranchDistrictSelection(data:AutoCompleteItem){
    //    this.existingTPPolicyDetailsForm.get('tpAddress1')?.setValue(data.text);
    //  }
  
    //  onEnsuringBranchDistrictBlur(data:any)
    //  {
    //     this.existingTPPolicyDetailsForm.get('tpAddress1')?.setValue(data);
    //  }

   async getEnsuringBranchDistrictsByState(state:string)
   {
     let response = await this._lookupService.getDistrictsListByState(state); 
     if (response?.isSuccess) {
       this.ensuringBranchDistrictList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
     }
   }

     async continue() {
      this.submitted=true;
      this.submittedAddress=true;
      this.submittedNominee=true;
      this.submittedVehicle = true;
      this.submittedPolicy = true;
      let phone = this.userForm.get('phoneNumber')?.value;
      if (phone.dialCode) {
        this.userForm.get('countryCode')?.setValue(phone.dialCode);
        this.userForm.get('phoneNumber')?.setValue(phone.number.replace(phone.dialCode, '').replace(/\s/g, ""));
      } 
      let kkk = this.insuranceForm.get("planName")?.value;
      this.priceBreakupForm.get('planName')?.setValue(this.insuranceForm.get("planName")?.value);
      this.vehicleForm.get('typeOfCover')?.setValue(this.insuranceForm.get("planName")?.value);

      let userForm=this.userForm;
      let nomineeForm = this.nomineeForm;
      let vehicleForm = this.vehicleForm;
      let priceBreakupForm = this.priceBreakupForm;
      if (this.userForm.valid && this.nomineeForm.valid && (this.vehicleForm.valid || this.insuranceFormControl['isMotor'].value == "No") && this.priceBreakupForm.valid) 
      {
        this.insuranceForm.get("vehicleType")?.setValue(parseInt(this.insuranceFormControl['vehicleType'].value));
        //this.insuranceForm.get("quoteId")?.setValue(parseInt(this.priceBreakupFormControl['policyNumber'].value));
        this.insuranceForm.get("insuranceCompany")?.setValue(this.insuranceCompany);
        this.insuranceForm.get("proposerDetails")?.setValue(this.userForm.value);
        this.insuranceForm.get("nomineeDetails")?.setValue(this.nomineeForm.value);
        this.insuranceForm.get("vehicleDetails")?.setValue(this.vehicleForm.value);
        this.insuranceForm.get("priceBreakup")?.setValue(this.priceBreakupForm.value);
        this.spinner.show();
        const res = await this.policyService.saveOldPolicy(this.insuranceForm.value);
      if (res != null && res.result) {
        this.spinner.hide();
        if(res.result.code ==0){
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            title: res.result.message,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            },
          });
          this.spinner.hide();
        }
        else
        {
          swal.fire({
            position: 'center',
            icon: 'success',
            title: this.id != "0" ? "Policy updated successfully." : " Policy saved successfully.",
            showConfirmButton: false,
            timer: 3000,
          });
          this.spinner.hide();
          this.router.navigate(['/sales'])
        }    
      }
      else
      {
        this.spinner.hide();
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: "Something went wrong",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
        });
      }
      }
    }

    typeofCoverChange(event: any) {
      this.vehicleForm.get('typeOfCover')?.setValue(event.target.value);
      if(this.vehicleForm.get('typeOfCover')?.value == "bundled"){
        this.removePreviousPolicyValidation();
      }
      else
      {
        this.addPreviousPolicyValidation();
      }
      this.keyUp('net_od');
    }

    removePreviousPolicyValidation(){
      this.isExistingPolicyValidationRemoved = true;
      this.vehicleForm.get('previousPolicyCompany')?.clearValidators();
      this.vehicleForm.get('previousPolicyCompany')?.updateValueAndValidity();
      this.vehicleForm.get('previousPolicyType')?.clearValidators();
      this.vehicleForm.get('previousPolicyType')?.updateValueAndValidity();
      this.vehicleForm.get('previousPolicyNumber')?.clearValidators();
      this.vehicleForm.get('previousPolicyNumber')?.updateValueAndValidity();
      this.vehicleForm.get('previousPolicyExpiryDate')?.clearValidators();
      this.vehicleForm.get('previousPolicyExpiryDate')?.updateValueAndValidity(); 
    }

    addPreviousPolicyValidation(){
      // this.isExistingPolicyValidationRemoved = false;
      // this.vehicleForm.get('previousPolicyCompany')?.addValidators(Validators.required);
      // this.vehicleForm.get('previousPolicyCompany')?.updateValueAndValidity();
      // this.vehicleForm.get('previousPolicyType')?.addValidators(Validators.required);
      // this.vehicleForm.get('previousPolicyType')?.updateValueAndValidity();
      // this.vehicleForm.get('previousPolicyNumber')?.addValidators(Validators.required);
      // this.vehicleForm.get('previousPolicyNumber')?.updateValueAndValidity();
      // this.vehicleForm.get('previousPolicyExpiryDate')?.addValidators(Validators.required);
      // this.vehicleForm.get('previousPolicyExpiryDate')?.updateValueAndValidity(); 
    }

    categoryChange(){
      //this.isMotor = this.insuranceFormControl['vehicleType'].value == "1" || this.insuranceFormControl['vehicleType'].value == "2" || this.insuranceFormControl['vehicleType'].value == "3" ? "Yes" : "No";
      if(this.insuranceFormControl['isMotor'].value == "Yes"){
        if(this.insuranceFormControl['vehicleType'].value == "3"){
          this.newPolicyTypeList=
          [
            {value:'',text:'Select Policy Type'},
            {value:'Comprehensive',text:'Package'},
            {value:'Thirdparty',text:'TP'}
          ];
        }
        else
        {
          let textLabel = this.insuranceFormControl['vehicleType'].value == "1" ? 'Bundled (1 + 3)' : this.insuranceFormControl['vehicleType'].value == "2" ? 'Bundled (1 + 5)' : "Bundled";
          this.newPolicyTypeList=
          [
            {value:'',text:'Select Policy Type'},
            {value:'Comprehensive',text:'Package'},
            {value:'Bundled',text: textLabel }, 
            {value:'Thirdparty',text:'TP'},   
            {value:'Standalone',text:'SAOD'}    
          ];
        }

        this.loadMakeList(false,'',''); 
      }
    }

    onPolicySelected(event: any): void {
      const file: File = event.target.files[0];
      if (file && this.checkFileSize(file)) {
        this.policyCardImage(file);
      }
    }
  
    policyCardImage(file: File): void {
      let ext = this.generalService.getFileExtension(file.name);
      this.userForm.get('policyExt')?.setValue(ext);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.policyImage = e.target.result;
        this.priceBreakupForm.get('policyBase64')?.setValue(this.policyImage);
      };
  
      reader.readAsDataURL(file);
    }

    checkFileSize(file:File):boolean{
      let isValid = false;
      if(file.size > 26214400){
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: "File size must be less than 25mb",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
        });  
      }
      else{
        isValid = true;
      }
      return isValid;
    }

    onMakeSelection(data:AutoCompleteItem)
    {
      this.vehicleForm.get('make')?.setValue(data.text);
      this.loadModelListByMake('');
    }
    onModelSelection(data:AutoCompleteItem)
    {
      this.vehicleForm.get('model')?.setValue(data.text);
      this.loadVariantListByModel(this.vehicleForm.get('model')?.value)
    }
    onVariantSelection(data:AutoCompleteItem)
    {
      this.vehicleForm.get('variant')?.setValue(data.text);
    }
    onMakeBlur(data:any)
    {
      this.vehicleForm.get('make')?.setValue(data);
      this.loadModelListByMake('');
    }
    onModelBlur(data:any)
    {
      this.vehicleForm.get('model')?.setValue(data);
      this.loadVariantListByModel(this.vehicleForm.get('model')?.value)
    }
    onVariantBlur(data:any)
    {
      this.vehicleForm.get('variant')?.setValue(data);
    }  

    async loadMakeList(searchByVahanData:boolean = false, name:string,modelName:string)
    {
      name = name??"";
      let request = {Type : parseInt(this.insuranceFormControl['vehicleType'].value) , Name: (searchByVahanData == true ? name :''),searchByVahanData : searchByVahanData};
      const res = await this.vehicleService.GetVehicleCompanyDetails(request);
      if (res != null && res.result) {
        if(searchByVahanData == false)
        {
          this.vehicleMakeList = res.result.map((item:any)=> {return {text:item,value:item,id:item}});
        }
        else
        {
          ;
          if(res.result[0] != null && res.result[0] != undefined && res.result[0] != "")
          {
            this.vehicleForm.get('make')?.setValue(res.result[0]);
            this.loadModelListByMake('');
            setTimeout(() => {
              this.loadModelListByMake(modelName, true)
            }, 200);
          }
        }
      }
    }
  
    async loadModelListByMake(model:string, searchByVahanData:boolean = false)
    {
      model = model??"";
      let request = {Type : parseInt(this.insuranceFormControl['vehicleType'].value), CompanyName: this.vehicleFormControl['make'].value ,Name:(searchByVahanData == true ? model :''), searchByVahanData: searchByVahanData};
      const res = await this.vehicleService.GetVehicleModelDetails(request);
      if (res != null && res.result) {
        if(searchByVahanData == false)
        {
          this.vehicleModelList = res.result.map((item:any)=> {return {text:item,value:item,id:item}});
        }
        else
        {
          if(res.result[0] != null && res.result[0] != undefined && res.result[0] != ""){
            this.vehicleForm.get('model')?.setValue(res.result[0]);
            this.loadVariantListByModel(this.vehicleForm.get('model')?.value);
          }
          
        }
      }
    }
  
    async loadVariantListByModel(model:string)
    {
      let request = { ModelName: model };
      const res = await this.vehicleService.GetVehicleVariantDetails(request);
      if (res != null && res.result) {
        this.vehicleVariantList = res.result.map((item:any)=> {return {text:item,value:item,id:item}});
      }
    }

    async loadAgentList(){
      let res = await this.agentprofileService.AgentDropDown();
      if (res != null && res.result) {
        this.agentList = res.result.map((item:any)=> {return {text:item.name,value:item.uniqueUserId,id:item.uniqueUserId}});
        let selectedAgent = this.agentList.filter((f:any)=>{return f.id == this.insuranceForm.get('agentId')?.value}).map((m:any)=>{return m.text});        
        this.agentName = selectedAgent != null && selectedAgent != undefined && selectedAgent.length > 0 ?  selectedAgent[0] : "";
      }
    }

    onAgentSelection(data:AutoCompleteItem)
    {
      this.insuranceForm.get('agentId')?.setValue(data.id);
    }

    uniqueItems = (data:any) => {
        let unique_values = data.filter((a:any, i:number) => data.findIndex((s:any) => a.text === s.text) === i) 
      return unique_values;
    }

    segmentChange(){
      this.insuranceForm.get('planName')?.setValue("");
      this.insuranceForm.get("vehicleType")?.setValue("");
      this.loadPolicyCategory();
    }

    parseToInt(val:any):number{
      if(val != null && val != undefined && val != ""){
        return parseInt(val);
      }
      else
      {
        return 0;
      }
    }

    keyUp(control:string){
      switch(control){
        case "net_od":          
          this.priceBreakupForm.get('netPremium')?.setValue((this.parseToInt(this.priceBreakupFormControl['net_TP'].value) + this.parseToInt(this.priceBreakupFormControl['net_OD'].value)).toString());
          this.priceBreakupForm.get('serviceTaxValue')?.setValue((this.parseToInt(this.priceBreakupFormControl['netPremium'].value) * ( this.insuranceFormControl['isMotor'].value == 'Yes' && this.insuranceFormControl['planName'].value == 'Thirdparty' ? .12 :.18 )).toString());
          this.priceBreakupForm.get('finalPremium')?.setValue((this.parseToInt(this.priceBreakupFormControl['serviceTaxValue'].value) + this.parseToInt(this.priceBreakupFormControl['netPremium'].value)).toString());
          break;
        case "net_tp":          
          this.priceBreakupForm.get('netPremium')?.setValue((this.parseToInt(this.priceBreakupFormControl['net_TP'].value) + this.parseToInt(this.priceBreakupFormControl['net_OD'].value)).toString());
          this.priceBreakupForm.get('serviceTaxValue')?.setValue((this.parseToInt(this.priceBreakupFormControl['netPremium'].value) * ( this.insuranceFormControl['isMotor'].value == 'Yes' && this.insuranceFormControl['planName'].value == 'Thirdparty' ? .12 :.18 )).toString());
          this.priceBreakupForm.get('finalPremium')?.setValue((this.parseToInt(this.priceBreakupFormControl['serviceTaxValue'].value) + this.parseToInt(this.priceBreakupFormControl['netPremium'].value)).toString());
          break;
        case "net_premium":
          this.priceBreakupForm.get('serviceTaxValue')?.setValue((this.parseToInt(this.priceBreakupFormControl['netPremium'].value) * ( this.insuranceFormControl['isMotor'].value == 'Yes' && this.insuranceFormControl['planName'].value == 'Thirdparty' ? .12 :.18 )).toString());
          this.priceBreakupForm.get('finalPremium')?.setValue((this.parseToInt(this.priceBreakupFormControl['serviceTaxValue'].value) + this.parseToInt(this.priceBreakupFormControl['netPremium'].value)).toString());
          break;
        case "gst":
          this.priceBreakupForm.get('finalPremium')?.setValue((this.parseToInt(this.priceBreakupFormControl['serviceTaxValue'].value) + this.parseToInt(this.priceBreakupFormControl['netPremium'].value)).toString());
          break;
      }
    }

    paCoverChange(){
      if(this.priceBreakupFormControl['isPACover'].value == "Yes"){
         this.priceBreakupForm.get('paCover')?.addValidators(Validators.required);
         this.priceBreakupForm.get('paCover')?.updateValueAndValidity();
      }
      else
      {
        this.priceBreakupForm.get('paCover')?.clearValidators();
         this.priceBreakupForm.get('paCover')?.updateValueAndValidity();
      }
    }

    async loadPolicyData(id:any){
      this.spinner.show();
      let response = await this.policyService.GetPolicyById1("0","","",id)
      if (response?.isSuccess) {
        let obj =JSON.parse(response.result.acutalQuoteRequest);
        let insuranceFormObj = {
          vehicleType: obj.VehicleType,
          quoteId: obj.QuoteId,
          userId:obj.UserId,
          agentId: obj.AgentId,
          uniqueUserId:obj.UniqueUserId,
          isMockUp: false,
          insuranceCompany:obj.InsuranceCompany,
          isMotor:obj.VehicleType < 4 ? "Yes" : "No",
          channel:obj.Channel
        }
        this.insuranceForm.patchValue(insuranceFormObj);
        this.nomineeForm.patchValue(response.result.nomineeDetails);
        this.userForm.patchValue(response.result.proposerDetails);
        this.vehicleForm.patchValue(response.result.vehicleDetails);
        this.insuranceForm.get('channel')?.setValue(response.result.channel);

        let priceBrakup ={
        "policyNumber": obj.PriceBreakup.PolicyNumber,
        "net_TP": obj.PriceBreakup.Net_TP,
        "net_OD": obj.PriceBreakup.Net_OD,
        "basicOwnDamagePremium": obj.PriceBreakup.BasicOwnDamagePremium,
        "thirdPartyCoverPremium": obj.PriceBreakup.ThirdPartyCoverPremium,
        "addOnValue": obj.PriceBreakup.AddOnValue,
        "paCover":obj.PriceBreakup.PACover,
        "serviceTaxValue": obj.PriceBreakup.ServiceTaxValue,
        "netPremium": obj.PriceBreakup.NetPremium,
        "finalPremium": obj.PriceBreakup.FinalPremium,
        "planName": obj.PriceBreakup.PlanName,
        "insuranceCompany": response.result.insuranceCompany,
        "policyStartDate": obj.PriceBreakup.PolicyStartDate,
        "policyEndDate": obj.PriceBreakup.PolicyEndDate,  
        "policyBase64" : obj.PriceBreakup.PolicyBase64,
        "isPACover":obj.PriceBreakup.IsPACover,
        };
        priceBrakup.isPACover = priceBrakup.isPACover == undefined || priceBrakup.isPACover == null ? (parseInt(( priceBrakup.paCover == null ||  priceBrakup.paCover == undefined || priceBrakup.paCover == "")? "0" : priceBrakup.paCover )  > 0 ? "Yes" : "No") : priceBrakup.isPACover;
        this.insuranceForm.get("planName")?.setValue(priceBrakup.planName);
        this.priceBreakupForm.patchValue(priceBrakup);

        this.insuranceForm.get('insuranceCompany')?.setValue(parseInt(this.priceBreakupForm.get('insuranceCompany')?.value));
        this.insuranceCompany = this.priceBreakupForm.get('insuranceCompany')?.value;
        let base64 = response.result.proposerDetails.panBase64;
        if(base64 != null && base64 != null && base64 != "" ){          
          this.priceBreakupForm.get('policyBase64')?.setValue('data:application/pdf;base64,' + base64);
        }
        if(this.id != "0" && this.insurancePartnersList){            
          this.insuranceCompanyStr =  this.insurancePartnersList.filter((f:AutoCompleteItem)=>{return f.value == this.insuranceCompany?.toString()})[0]?.text??"";
        }
        this.insuranceForm.get('agentId')?.setValue(obj.AgentId);
        if(this.id != "0" && this.agentList){
          let selectedAgent = this.agentList.filter((f:any)=>{return f.id == this.insuranceForm.get('agentId')?.value}).map((m:any)=>{return m.text});        
          this.agentName = selectedAgent != null && selectedAgent != undefined && selectedAgent.length > 0 ?  selectedAgent[0] : "";
        }
        this.spinner.hide();
        this.insuranceForm.get("vehicleType")?.setValue(parseInt(this.insuranceFormControl['vehicleType'].value));
        this.loadPolicyCategory()
      }
      else
      {
        this.spinner.hide();
      }
    }

    backTolist(){
      this.router.navigate(['/sales']);
    }
}
