import { Component, HostListener } from '@angular/core';
import { GeneralService } from 'src/app/Services/general.service';
import { LanguageLableService } from 'src/app/Services/language-lable.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent {
  isSmallScreen: boolean = false;selectedValue: string | null;
  langList: any;
  isUserLogged: boolean = false;
 constructor(private languageLableService: LanguageLableService, private generalService : GeneralService) { this.checkScreenSize(); 
  
} 
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); } 
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }

  ngOnInit() {
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
    }
    if(localStorage.getItem("selectedValue") != ''){
      this.selectedValue = localStorage.getItem("selectedValue");
    }
    else{
      this.selectedValue = "english";
    } 
    const res = this.languageLableService.languageDropdown();
    if (res != null) {
      this.langList = res;
    }
  }
  isLoggedIn():boolean
  {
    return this.generalService.getUniqueuserid() != "";
    
  }
}
