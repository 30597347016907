import { Component } from '@angular/core';
import { FormGroup,Validator,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hospital-bussiness-varification',
  templateUrl: './hospital-bussiness-varification.component.html',
  styleUrls: ['./hospital-bussiness-varification.component.css']
})
export class HospitalBussinessVarificationComponent {
GSTno:boolean=true;
PANno:boolean=false;
num:string='gst';
isBasicDetails:boolean=false;
isCategory:boolean=false;
isContanctDetail:boolean=false;
page:string='basic';
isWhatsapp:boolean=true;
Success:boolean=false;
BasicDetails:FormGroup;
Category:FormGroup;
Contact:FormGroup;
constructor(private route:Router,fb:FormBuilder)
  {
    this.BasicDetails=fb.group({
      "number":["",[Validators.required]]
    });
    this.Category=fb.group({
      "BusinessCatgory":[""],
      "Subcategory":[""],
    });
    this.Contact=fb.group({
      "MobileNumber":["",[Validators.required]],
      "Email":["",[Validators.required]],
      "WhatsApp":[""],
      "Website":[""],
      "BusinessApp":[""],
      "Facebook":[""],
      "Instagram":[""],
      "LinkedIn":[""],
      "twitter":[""],
    });
  }
  back()
  {
    this.route.navigate(['/hospital']);
  }
close(){
  this.Success=false;
}
success()
{
  this.isContanctDetail=true;
  this.Success=true;
}
Pages(val:string)
{
  this.page=val;
  if(this.page=='basic'){
    this.isBasicDetails=false;
    this.isCategory=false;
    this.isContanctDetail=false;
  }
  if(this.page=='category'){
    this.isBasicDetails=true;
    this.isCategory=false;
    this.isContanctDetail=false;
  }
  if(this.page=='contact'){
    this.isBasicDetails=true;
    this.isCategory=true;
    this.isContanctDetail=false;
  }
  
}
nubmer(val:string)
{
this.num=val;
if(this.num=="gst")
{
  this.GSTno=true;
  this.PANno=false;
}
if(this.num=="pan")
{
  this.GSTno=false;
  this.PANno=true;
}
if(this.num=='yes'){
  this.isWhatsapp=true ;
}
if(this.num=='no'){
  this.isWhatsapp=false;
}
}
}
