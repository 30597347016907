<!-- <div class="row">
  <div class="col-12"> -->
    <!-- <div class="sideNav"> -->
      <a href="/" class="d-none d-sm-block"><img class="logo" src="../../assets/images/logo.svg" alt="logo" width="150" /></a>
      <a href="/" class="d-block d-sm-none"><img class="logo" src="../../assets/svgIcon/Click4bima_mobile.svg" clialt="logo" width="60" /></a>
      <img class="w-100" src="../../assets/svgIcon/logo_separator.svg" />
      {{setSelectedMenu()}}
      <div class="sideNav-menu" *ngIf="!isAgent">
        <div class="menu" [ngClass]="{'activeLi': isDashboard,'active-menu': isDashboard}" (click)="goTo('dashboard')">
          <div class="d-flex">
            <i class="bi bi-house-door icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isDashboard}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isDashboard}">Dashboard</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isPolicies, 'active-menu': isPolicies}" (click)="goTo('policies')">
          <div class="d-flex">
            <i class="bi bi-shield-check icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isPolicies}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isPolicies}">Policies</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isProfile, 'active-menu': isProfile}" (click)="goTo('profile')">
          <div class="d-flex">
            <i class="bi bi-person-circle icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isProfile}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isProfile}">Profile</h4>
            </div>
          </div>
        </div>
        <!-- <div class="menu" [ngClass]="{'activeLi': isKyc, 'active-menu': isKyc}" (click)="goTo('kyc')">
          <div class="d-flex">
            <i class="bi bi-check-circle-fill icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isKyc}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 [ngClass]="{'activeMenu': isKyc}">KYC</h4>
            </div>
          </div>
        </div> -->
        
        <!-- <div class="menu" [ngClass]="{'activeLi': isHelp, 'active-menu': isHelp}" (click)="goTo('help')">
          <div class="d-flex">
            <i class="bi bi-question-circle icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isHelp}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 [ngClass]="{'activeMenu': isHelp}">Help</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isSetting, 'active-menu': isSetting}" (click)="goTo('settings')">
          <div class="d-flex">
            <i class="bi bi-gear icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isSetting}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 [ngClass]="{'activeMenu': isSetting}">Settings</h4>
            </div>
          </div>
        </div> -->
      </div>
      <div class="sideNav-menu" *ngIf="isAgent">
        <div class="menu" [ngClass]="{'activeLi': isAgentDashboard, 'active-menu': isAgentDashboard}" (click)="goTo('Agent Dashboard')">
          <div class="d-flex">
            <i class="bi bi-person-circle icon-box d-flex justify-content-center align-items-center"
            [ngClass]="{'activeIcon': isAgentDashboard}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isAgentDashboard}">Dashboard</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isCustomer, 'active-menu': isCustomer}" (click)="goTo('customers')">
          <div class="d-flex">
            <i class="bi bi-people-fill icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isCustomer}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isCustomer}">Customers</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isAgentProfile, 'active-menu': isAgentProfile}" (click)="goTo('Agent Profile')">
          <div class="d-flex">
            <i class="bi bi-person-circle icon-box d-flex justify-content-center align-items-center" [ngClass]="{'activeIcon': isAgentProfile}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isAgentProfile}">Profile</h4>
            </div>
          </div>
        </div>
        <!-- <div class="menu" [ngClass]="{'activeLi': isVehicle, 'active-menu': isVehicle}" (click)="goTo('vehicle')">
          <div class="d-flex">
            <i class="bi bi-car-front-fill icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isVehicle}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isVehicle}">Vehicle</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isDocument, 'active-menu': isDocument}" (click)="goTo('document')">
          <div class="d-flex">
            <i class="bi bi-file-earmark-text icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isDocument}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isDocument}">Documents</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isBusiness, 'active-menu': isBusiness}" (click)="goTo('business')">
          <div class="d-flex">
            <i class="bi bi-briefcase icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isBusiness}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isBusiness}">Business</h4>
            </div>
          </div>
        </div> -->
        <div class="menu" [ngClass]="{'activeLi': isPolicies, 'active-menu': isPolicies}" (click)="goTo('policies')">
          <div class="d-flex">
            <i class="bi bi-shield-check icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isPolicies}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isPolicies}">Policies</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isSales, 'active-menu': isSales}" (click)="goTo('sales')">
          <div class="d-flex">
            <i class="bi bi-briefcase icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isSales}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isBusiness}">Sales</h4>
            </div>
          </div>
        </div>
        <div class="menu" [ngClass]="{'activeLi': isCommisson, 'active-menu': isCommisson}" (click)="goTo('commission')">
          <div class="d-flex">
            <i class="bi bi-currency-rupee icon-box d-flex justify-content-center align-items-center"
              [ngClass]="{'activeIcon': isCommisson}"></i>
            <div class="menu-item d-flex align-items-center">
              <h4 class="menu-text" [ngClass]="{'activeMenu': isCommisson}">Commission</h4>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
  <!-- </div>
</div> -->