<div class="container" style="background-color: #f3f3f4;" *ngIf="!isPayment">
    <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="proposalForm">

        <div class="row mt-10">
            <div class="col-md-4">
                <div class="card  p-4">
                    <div class="row">
                        <div class="col-md-12">
                            <p> &nbsp; Insured members</p>
                        </div>

                        <div class="col-md-6 mt-3">
                            <span>
                                Self
                            </span>
                            <p>
                                32 Year
                            </p>

                        </div>
                        <div class="col-md-6 mt-3">
                            <span>
                                Spouse’s
                            </span>
                            <p>
                                28 Year
                            </p>

                        </div>
                        <div class="col-md-6 mt-3">
                            <span>
                                1st Daughter
                            </span>
                            <p>
                                07 Year
                            </p>

                        </div>
                        <div class="col-md-6 mt-3">
                            <span>
                                2nd Daughter
                            </span>
                            <p>
                                03 Year
                            </p>

                        </div>
                    </div>
                </div>
                <div class="card mt-5 p-4">
                    <div class="row">
                        <div class="col-md-12">
                            <p>Add Extra Coverage</p>
                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <p class="form-check-label" for="flexCheckDefault">
                                    Personal Accident Cover of Rs. 10 Lakh &nbsp;&nbsp;&nbsp;&nbsp;<img
                                        src="../../../assets/images/Vector4.png" alt="">
                                </p>
                                <span style="color: red;"> PA cover is mandatory </span>
                                <h3 class="mt-3">₹180/ 1 Year</h3>
                            </div>
                        </div>
                        <div class="col-md-12 mt-4">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <p class="form-check-label" for="flexCheckDefault">
                                    Consumable Cover
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img
                                        src="../../../assets/images/Vector4.png" alt="">
                                </p>
                                <h3 class="mt-3">₹320</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card p-5 mt-5">
                    <div class="row">
                        <div class="col-md-12 mt-2">
                            <h4>
                                Premium Breakup
                            </h4>
                        </div>
                        <div class="mt-3 col-md-8">
                            <span>InIDVidual Health Insurance</span>
                        </div>
                        <div class="mt-3 col-md-4">
                            <p>₹{{premiumObject.grosspremium}}</p>
                        </div>
                        <hr>
                        <div class="mt-1 col-md-8">
                            <span>Net Premium</span>
                        </div>
                        <div class="mt-1 col-md-4">
                            <p>₹{{premiumObject.grosspremium}}</p>
                        </div>
                        <div class="mt-1 col-md-8">
                            <span>Add GST(18%)</span>
                        </div>
                        <div class="mt-1 col-md-4">
                            <p>₹{{premiumObject.igst}}</p>
                        </div>
                        <hr>
                        <div class="mt-1 col-md-8">
                            <span>Final Premium</span>
                        </div>
                        <div class="mt-1 col-md-4">
                            <p>₹{{premiumObject.finalPremium}}</p>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="card p-2" style="background-color: rgba(58, 130, 71, 0.1);">
                                <h4 style="color: rgba(58, 130, 71, 1);"><img src="../../../assets/images/percent.png"
                                        alt="">&nbsp;Your are eligible for 5% Discount</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card p-4">
                    <div class="row p-1">
                        <div class=" col-md-3 tab" (click)="nextTab('')" [class.active]="Tabs===''">
                            <h4 class="tabs"> Proposer</h4>
                        </div>
                        <div class=" col-md-3 tab" (click)="nextTab('Members')" [class.active]="Tabs==='Members'">
                            <h4 class="tabs"> Members</h4>
                        </div>
                        <div class=" col-md-3 tab" (click)="nextTab('Medical')" [class.active]="Tabs==='Medical'">
                            <h4 class="tabs"> Medical</h4>
                        </div>
                        <div class=" col-md-3 tab" (click)="nextTab('Nominee')" [class.active]="Tabs==='Nominee'">
                            <h4 class="tabs"> Nominee</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="tabContent">
                            <div *ngIf="Tabs==''">
                                <div class="row">
                                    <div class="col-md-12 mt-3">
                                        <h4>
                                            Great! Let’s start with proposer details
                                        </h4>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Self
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <!-- <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault" name="proposerSelf" formControlName="proposerSelf">

                                            </div> -->
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                
                                              </div>                                  </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Spouse
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <!-- <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault" name="proposerSpouse" formControlName="proposerSpouse">
                                                   
                                            </div> -->k
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                              
                                              </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-12 mt-10">
                                        <h4>Proposer's details</h4>
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label for=""> Full Name as per your ID Card</label>
                                        <input type="text" name="name" id="" placeholder="Pushpendra Singh Rathor"
                                            class="form-control" name="proposerName" formControlName="proposerName">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label for="">Gender</label>
                                        <select class="form-select" aria-label="Default select example"
                                            name="proposerGender" formControlName="proposerGender">
                                            <option selected>Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="">Pan Card</label>
                                        <input type="text" name="pan" id="" placeholder="Pan Card No."
                                            class="form-control" onkeypress="if(this.value.length==10) return false"
                                            name="proposerPancard" formControlName="proposerPancard">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="">DOB (DD-MM-YYYY)</label>
                                        <input type="date" name="dob" id="" placeholder="DOB (DD-MM-YYYY)"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <h4>
                                            Contact Details
                                        </h4>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="">Email Address</label>
                                        <input type="email" name="email" id="" placeholder="abc@gmail.com"
                                            class="form-control" name="proposerEmail" formControlName="proposerEmail">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="">Mobile</label>
                                        <input type="number" name="Mobile" id="" placeholder="+91 " class="form-control"
                                            onkeypress="if(this.value.length==10) return false" name="proposerMobile"
                                            formControlName="proposerMobile">
                                    </div>

                                    <div class="col-md-6 mt-3">
                                        <label for="">Emergency Mobile No.</label>
                                        <input type="number" name="Emergency" id="" placeholder="+91 "
                                            class="form-control" onkeypress="if(this.value.length==10) return false"
                                            name="proposerEmergencyMobile" formControlName="proposerEmergencyMobile">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-end mt-4">
                                        <button class="btn btn-primary" (click)="nextTab('Members')">Next</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="Tabs=='Members'">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>
                                            Tell us the details about the member to be insured
                                        </h4>
                                    </div>
                                    <div class="col-md-12 mt-4">
                                        <p>Pushpendra Singh Rathor</p>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label for="">DOB (DD-MM-YYYY)</label>
                                        <input type="date" name="DOB" id="" class="form-control"
                                            placeholder="DOB (DD-MM-YYYY)" name="insurer1DOB"
                                            formControlName="insurer1DOB">
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label for="">Occupation</label>
                                        <input type="text" name="Occupation" id="" class="form-control"
                                            placeholder="Occupation" name="insurer1Occupation"
                                            formControlName="insurer1Occupation">
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label for="">Height (Feet)</label>
                                        <select class="form-select" aria-label="Default select example"
                                            name="insurer1HeightFeet" formControlName="insurer1HeightFeet">
                                            <option selected>Height (Feet)</option>
                                            <option value="1"> 4 Feet</option>
                                            <option value="2">5 Feet</option>
                                            <option value="3">6 Feet</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <label for="">Hight (Inch)</label>
                                        <select class="form-select" aria-label="Default select example"
                                            name="insurer1HeightInches" formControlName="insurer1HeightInches">
                                            <option selected>Hight (Inch)</option>
                                            <option value="1"> 0 </option>
                                            <option value="2"> 1 </option>
                                            <option value="3"> 2 </option>
                                            <option value="4"> 3 </option>
                                            <option value="5"> 4 </option>
                                            <option value="6"> 5 </option>
                                            <option value="7"> 6 </option>
                                            <option value="8"> 7 </option>
                                            <option value="9"> 8 </option>
                                            <option value="10"> 9 </option>

                                        </select>
                                    </div>
                                    <div class="col-md-4 mt-4">
                                        <label for="">Weight (KG)</label>
                                        <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                            onkeypress="if(this.value.length==3) return false" name="insurer1Weight"
                                            formControlName="insurer1Weight">
                                    </div>
                                    <div class="col-md-12 mt-6">
                                        <h4>Spouse</h4>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="">Enter Spouse's Full Name</label>
                                        <input type="text" name="name" id="" placeholder="Full Name"
                                            class="form-control" name="insurer2Name" formControlName="insurer2Name">
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label for="">DOB (DD-MM-YYYY)</label>
                                        <input type="date" name="DOB" id="" class="form-control"
                                            placeholder="DOB (DD-MM-YYYY)" name="insurer2DOB"
                                            formControlName="insurer2DOB">
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label for="">Occupation</label>
                                        <input type="text" name="Occupation" id="" class="form-control"
                                            placeholder="Occupation" name="insurer2Occupation"
                                            formControlName="insurer2Occupation">
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="">Height (Feet)</label>
                                        <select class="form-select" aria-label="Default select example"
                                            name="insurer2HeightFeet" formControlName="insurer2HeightFeet">
                                            <option selected>Height (Feet)</option>
                                            <option value="1"> 4 Feet</option>
                                            <option value="2">5 Feet</option>
                                            <option value="3">6 Feet</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <label for="">Hight (Inch)</label>
                                        <select class="form-select" aria-label="Default select example"
                                            name="insurer2HeightInches" formControlName="insurer2HeightInches">
                                            <option selected>Hight (Inch)</option>
                                            <option value="1"> 4 </option>
                                            <option value="2">5 </option>
                                            <option value="3">6 </option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label for="">Weight (KG)</label>
                                        <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                            onkeypress="if(this.value.length==3) return false " name="insurer2Weight"
                                            formControlName="insurer2Weight">
                                    </div>
                                   <!-- <div class="col-md-12 mt-5">
                                        <h4>
                                            How many Child
                                        </h4>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <div class="card p-2 cr">
                                            <div class="row">
                                                <div class="col-md-7 p-1">
                                                    <h3 class="ml-2">
                                                        Child
                                                    </h3>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="card mt-1">
                                                        <div class="row">
                                                            <div class="col-md-4">
                                                                <i class="fa fa-minus" aria-hidden="true"
                                                                   (click)="removeChild()" ></i>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <p>{{kids}}</p>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <i class="fa fa-plus" aria-hidden="true"
                                                                   (click)="addChild()" ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                        
                                    </div> -->
                                    <div class="col-md-12 mt-3" *ngIf="kids==1">
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 1</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 mt-3"*ngIf="kids==2" >
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 1</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 2</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3"*ngIf="kids==3" >
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 1</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 2</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>   <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 3</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3"*ngIf="kids==4" >
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 1</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 2</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>  
                                         <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 3</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 mt-6">
                                                <h4>Child 4</h4>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <label for="">Enter Child's Full Name</label>
                                                <input type="text" name="name" id="" placeholder="Full Name"
                                                    class="form-control" name="child1Name" formControlName="child1Name">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">DOB (DD-MM-YYYY)</label>
                                                <input type="date" name="DOB" id="" class="form-control"
                                                    placeholder="DOB (DD-MM-YYYY)" name="child1DOB" formControlName="child1DOB">
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Feet)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightFeet" formControlName="child1HeightFeet">
                                                    <option selected>Height (Feet)</option>
                                                    <option value="1"> 4 Feet</option>
                                                    <option value="2">5 Feet</option>
                                                    <option value="3">6 Feet</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="">Height (Inch)</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1HeightInches" formControlName="child1HeightInches">
                                                    <option selected>Hight (Inch)</option>
                                                    <option value="1"> 4 </option>
                                                    <option value="2">5 </option>
                                                    <option value="3">6 </option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-4">
                                                <label for="">Weight (KG)</label>
                                                <input type="number" name="Weight" class="form-control" placeholder="-- KG"
                                                    onkeypress="if(this.value.length==3) return false " name="child1Weight"
                                                    formControlName="child1Weight">
                                            </div>
                                            <div class="col-md-6 mt-4">
                                                <label for="">Child Relation</label>
                                                <select class="form-select" aria-label="Default select example"
                                                    name="child1Relation" formControlName="child1Relation">
                                                    <option selected>Child Relation</option>
                                                    <option value="Daughter"> Daughter </option>
                                                    <option value="Son">Son </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                               
                                  
                                </div>
                                
                                <div class="row">
                                        <div class="col-md-10  mt-4">
                                            <button class="btn btn-lignt" (click)="nextTab('')">Back</button>
                                        </div>
                                    <div class="col-md-2   mt-4">
                                        <button class="btn btn-primary" (click)="nextTab('Medical')">Next</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="Tabs=='Medical'">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>Help us know the medical condition, if any
                                        </h4>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <p>Have you or any member of your family proposed to be insured, suffered or are
                                            suffering from any disease/ailment/adverse medical condition of any kind
                                            especially Heart/Stroke/Cancer/Renal disorder/Alzheimer's
                                            disease/Parkinsons's disease?</p>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Pushpendra Singh
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Puran Kawnar
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Harshvardhan Singh
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Bhanupriya Kanwar
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Not Applicable
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-md-12 mt-5">
                                        <p>Have you or any member of your family proposed to be insured, suffered or are
                                            suffering from any disease/ailment/adverse medical condition of any kind
                                            especially Heart/Stroke/Cancer/Renal disorder/Alzheimer's
                                            disease/Parkinsons's disease?</p>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Pushpendra Singh
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Puran Kawnar
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Harshvardhan Singh
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Bhanupriya Kanwar
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value=""
                                                            id="flexCheckDefault">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Not Applicable
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <!-- <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="flexCheckDefault">

                                        </div> -->
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="flexRadioDefault" id="flexRadioDefault1">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                        <div class="col-md-10  mt-4">
                                            <button class="btn btn-light" (click)="nextTab('Members')">Back</button>
                                        </div>
                                    <div class="col-md-2  mt-4">
                                        <button class="btn btn-primary" (click)="nextTab('Nominee')">Next</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="Tabs=='Nominee'">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Puran Kanwar
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <!-- <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault">
    
                                            </div> -->
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="flexRadioDefault" id="flexRadioDefault1">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <div class="card crd mt-3 p-3">
                                            <div class="row">
                                                <div class="col-md-8 mt-1">
                                                    <p>
                                                        Someone Else
                                                    </p>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="flexRadioDefault" id="flexRadioDefault1">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <h4>
                                            Give us the details of nominee to be
                                        </h4>

                                    </div>
                                    <div class="col-md-6 mt-5">
                                <label for="">Relationship with proposer</label>
                                <select class="form-select" aria-label="Default select example" name="nomineeRelationWithProposer" formControlName="nomineeRelationWithProposer">
                                    <option selected>Relationship with proposer</option>
                                    <option value="Father"> Father </option>
                                    <option value="Son">Son  </option>
                                    <option value="Daughter">Daughter  </option>
                                  </select>

                            </div>
                         
                                    <div class="col-md-6 mt-5">
                                        <label for="">Gender</label>
                                        <select class="form-select" aria-label="Default select example"
                                            name="nomineeGender" formControlName="nomineeGender">
                                            <option selected>Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label for="">Nominee Full Name</label>
                                        <input type="text" name="name" id="" class="form-control"
                                            placeholder="Nominee Full Name" name="nomineeFullName"
                                            formControlName="nomineeFullName">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label for="">DOB (DD-MM-YYYY)</label>
                                        <input type="date" name="DOB" id="" class="form-control"
                                            placeholder="DOB (DD-MM-YYYY)" name="nomineeDOB"
                                            formControlName="nomineeDOB">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <label for="">Contact Number</label>
                                        <input type="number" name="Contact_Number" id="" class="form-control"
                                            placeholder="Contact Number"
                                            onkeypress="if(this.value.length==10) return false;"
                                            name="nomineeContactNumber" formControlName="nomineeContactNumber">
                                    </div>
                                </div>
                                <div class="row">
                                        <div class="col-md-9  mt-4">
                                            <button class="btn btn-light" (click)="nextTab('Medical')">Back</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="row " *ngIf="Continue">
            <div class="container">
                <div class="card mt-5 p-3">
                    <div class="row">
                        <div class="col-md-8">
                            <span>Net premium</span>
                            <h3>₹{{premiumObject.finalPremium}}</h3>
                        </div>
                        <div class="col md-4">
                            <span>Help?</span>&nbsp;&nbsp;
                            <button class="btn btn-primary" id="btn" (click)="CreateQuote()">Continue</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="isPayment">
    <app-lifeline-payment [royalDetails]="royalDetails"></app-lifeline-payment>
</div>