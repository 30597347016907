export class kannadaLabel {
    public product: string = 'ಉತ್ಪನ್ನ';
    public support: string = 'ಸಹಾಯ';
    public raise_a_query: string = 'ಪ್ರಶ್ನೆ ಕೇಳಿ';
    public raise_a_claim: string = 'ಹಕ್ಕು ಕೇಳಿ';
    public contact_us: string = 'ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ';
    public career: string = 'ವೃತ್ತಿ';
    public gallery: string = 'ಗ್ಯಾಲರಿ';
    public login: string = 'ಲಾಗಿನ್ ಮಾಡಿ';
    public two_wheeler: string = 'ದ್ವಿಚಕ್ರ ವಾಹನ';
    public four_wheeler: string = 'ಚತುರ್ಚಕ್ರ ವಾಹನ';
    public health: string = 'ಆರೋಗ್ಯ';
    public commercial_vehicle: string = 'ವ್ಯಾಪಾರ ವಾಹನ';
    public bike_ins_title: string = 'ನಿಮ್ಮ ದ್ವಿಚಕ್ರ ವಾಹನ ವಿಮೆಯ ಮೇಲೆ ವಿಶೇಷವಾಗಿ ಕಡಿಮೆ ಬೆಲೆಯನ್ನು ಪಡೆಯಿರಿ';
    public bike_ins_start: string = 'ದ್ವಿಚಕ್ರ ವಾಹನ ವಿಮೆ ₹635 ರಿಂದ ಪ್ರಾರಂಭ*';
    public enter_bike_number: string = 'ದ್ವಿಚಕ್ರ ವಾಹನ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ EX: AB12CD3456 ';
    public verify: string = 'ಪರಿಶೀಲಿಸಿ';
    public dont_have_policy: string = "ನೋಂದಣಿ ಸಂಖ್ಯೆ ನೆನಪಿಲ್ಲವೇ";
    public buy_new_bike: string = 'ಹೊಸ ದ್ವಿಚಕ್ರ ವಾಹನವನ್ನು ಖರೀದಿಸಿರುವಿರಾ?';
    public what_is_ins1: string = 'ವಿಮೆ';
    public what_is_ins2: string = 'ಎಂದರೇನು?';
    public what_is_ins_title: string = 'ವಿಮೆಯೊಂದಿಗೆ ಮನಸ್ಸಿನ ಶಾಂತಿಯನ್ನು ಅನ್ವೇಷಿಸಿ';
    public what_is_ins_text: string = "ನಮ್ಮ Click4bima ಪೋರ್ಟಲ್‌ಗೆ ಸುಸ್ವಾಗತ, ಭವಿಷ್ಯವನ್ನು ಕಾಪಾಡುವ ನಿಮ್ಮ ಗೇಟ್‌ವೇ! ವ್ಯಾಪಕ ಶ್ರೇಣಿಯ ವಿಮಾ ಆಯ್ಕೆಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ನಮ್ಮ ಪೋರ್ಟಲ್ ನಿಮ್ಮ ಏಕ-ನಿಲುಗಡೆ ತಾಣವಾಗಿದೆ. ಆರೋಗ್ಯ ಮತ್ತು ಆಟೋದಿಂದ ಮನೆ ಮತ್ತು ಪ್ರಯಾಣದವರೆಗೆ, ನಿಮ್ಮ ಅಗತ್ಯಗಳಿಗೆ ಅನುಗುಣವಾಗಿ ಉತ್ತಮ ಕವರೇಜ್ ಯೋಜನೆಗಳನ್ನು ನಿಮಗೆ ತರಲು ನಾವು ಪ್ರತಿಷ್ಠಿತ ವಿಮಾ ಕಂಪನಿಗಳೊಂದಿಗೆ ಪಾಲುದಾರಿಕೆ ಹೊಂದಿದ್ದೇವೆ. ವಿಭಿನ್ನ ಪಾಲಿಸಿಗಳನ್ನು ಹೋಲಿಸುವ ಮೂಲಕ, ನೀವು ತಿಳುವಳಿಕೆಯುಳ್ಳ ನಿರ್ಧಾರಗಳನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಜೀವನಶೈಲಿ ಮತ್ತು ಬಜೆಟ್‌ಗೆ ಸರಿಹೊಂದುವಂತಹದನ್ನು ಆಯ್ಕೆ ಮಾಡಬಹುದು.";
    public why_insurance_imp1: string = 'ವಿಮೆ ಏಕೆ';
    public why_insurance_imp2: string = 'ಮುಖ್ಯವಾಗಿದೆ?';
    public why_insurance_imp_title: string = 'ನಾಳೆಯನ್ನು ಭದ್ರಪಡಿಸುವುದು: ವಿಮೆಯ ಜೀವಂತಿಕೆಯನ್ನು ಅನಾವರಣಗೊಳಿಸುವುದು';
    public why_insurance_imp_text: string = "ವಿಮೆಯು ಜೀವನದ ಅನಿಶ್ಚಿತತೆಗಳ ವಿರುದ್ಧ ನಿಮ್ಮ ರಕ್ಷಾಕವಚವಾಗಿದೆ, ನಿಮಗೆ ಹೆಚ್ಚು ಅಗತ್ಯವಿರುವಾಗ ನಿಮಗೆ ಮನಸ್ಸಿನ ಶಾಂತಿಯನ್ನು ನೀಡುತ್ತದೆ. ಇದು ನಿಮ್ಮ ಮತ್ತು ನೀವು ಆಯ್ಕೆ ಮಾಡಿದ ವಿಮಾ ಪೂರೈಕೆದಾರರ ನಡುವಿನ ಒಪ್ಪಂದವಾಗಿದ್ದು, ಅನಿರೀಕ್ಷಿತ ಘಟನೆಗಳ ಸಮಯದಲ್ಲಿ ಹಣಕಾಸಿನ ಬೆಂಬಲವನ್ನು ಖಾತರಿಪಡಿಸುತ್ತದೆ. ಇದು ಹಠಾತ್ ವೈದ್ಯಕೀಯ ವೆಚ್ಚವಾಗಲಿ, ವಾಹನ ಅಪಘಾತವಾಗಲಿ, ನಿಮ್ಮ ಆಸ್ತಿಗೆ ಹಾನಿಯಾಗಲಿ ಅಥವಾ ಯಾವುದೇ ಅನಿರೀಕ್ಷಿತ ಸಂದರ್ಭವಾಗಲಿ, ವಿಮೆಯು ಹೊರೆಯನ್ನು ತಗ್ಗಿಸಲು ಹೆಜ್ಜೆ ಹಾಕುತ್ತದೆ.";
    public claim_assistance1: string = 'ಕ್ಲೈಮ್';
    public claim_assistance2: string = 'ನೆರವು';
    public claim_assistance_title: string = 'ನಿಮ್ಮ ಮನಸ್ಸಿನ ಶಾಂತಿಯನ್ನು ಪುನಃಸ್ಥಾಪಿಸಲಾಗಿದೆ';
    public claim_assistance_text: string = "Click4bima ನಿಂದ 'ಕ್ಲೈಮ್ ಅಸಿಸ್ಟೆನ್ಸ್' ನೊಂದಿಗೆ, ಸುಗಮವಾದ ಕ್ಲೈಮ್ ಪ್ರಕ್ರಿಯೆಯ ಪ್ರಾಮುಖ್ಯತೆಯನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ವಿಶ್ವಾಸಾರ್ಹ ಪಾಲುದಾರರನ್ನು ನೀವು ಹೊಂದಿದ್ದೀರಿ ಎಂದು ನೀವು ಖಚಿತವಾಗಿ ಹೇಳಬಹುದು. ಕೇವಲ ಒಂದು ನೀತಿಗಿಂತ ಹೆಚ್ಚಿನದರಿಂದ ನಿಮ್ಮನ್ನು ರಕ್ಷಿಸಲಾಗಿದೆ ಎಂದು ತಿಳಿದುಕೊಂಡು ನಿಮ್ಮ ಮನಸ್ಸಿನ ಶಾಂತಿಯನ್ನು ಮರಳಿ ಪಡೆಯಿರಿ - ನೀವು ಕಾಳಜಿವಹಿಸುವ ತಂಡದಿಂದ ಬೆಂಬಲಿತರಾಗಿದ್ದೀರಿ. ನಿಮಗೆ ಹೆಚ್ಚು ಅಗತ್ಯವಿರುವಾಗ ನಮ್ಮ 'ಕ್ಲೈಮ್ ಅಸಿಸ್ಟೆನ್ಸ್' ನಿಮ್ಮ ವಿಶ್ವಾಸಾರ್ಹ ಒಡನಾಡಿಯಾಗಿದೆ.";
    public best_place_ins_title: string = 'ಭಾರತದಲ್ಲಿ ವಿಮೆಯನ್ನು ಖರೀದಿಸಲು ನಮ್ಮನ್ನು ಉತ್ತಮ ಸ್ಥಳವನ್ನಾಗಿ ಮಾಡುವುದು ಯಾವುದು?';
    public best_place_ins_text: string = 'ನಮ್ಮ Click4bima ಪೋರ್ಟಲ್‌ಗೆ ಸುಸ್ವಾಗತ, ಭಾರತದಲ್ಲಿ ಅತ್ಯುತ್ತಮ ವಿಮಾ ರಕ್ಷಣೆಯನ್ನು ಹುಡುಕುವ ನಿಮ್ಮ ಒಂದು-ನಿಲುಗಡೆ ತಾಣವಾಗಿದೆ. ನಾವು ಉಳಿದವರಿಗಿಂತ ಏಕೆ ಎದ್ದು ಕಾಣುತ್ತೇವೆ ಎಂದು ಆಶ್ಚರ್ಯಪಡುತ್ತೀರಾ? ಸರಿಯಾದ ವಿಮಾ ರಕ್ಷಣೆಯ ರಕ್ಷಣೆಯೊಂದಿಗೆ ಚಿಂತೆ-ಮುಕ್ತ ಭವಿಷ್ಯವನ್ನು ಸ್ವೀಕರಿಸಿ. ಇಂದು ನಮ್ಮೊಂದಿಗೆ ನಿಮ್ಮ ವಿಮಾ ಪ್ರಯಾಣವನ್ನು ಪ್ರಾರಂಭಿಸಿ.';
    
  public everytime_support_title: string = '24*7 ಬೆಂಬಲ';
  public everytime_support_text: string = "ನಮ್ಮ 24/7 ವಿಮಾ ನೆರವಿನೊಂದಿಗೆ ಯಾವುದೇ ಸಮಯದಲ್ಲಿ, ಎಲ್ಲಿಯಾದರೂ ಮನಸ್ಸಿನ ಶಾಂತಿಯನ್ನು ಪಡೆಯಿರಿ. ನಮ್ಮ ಮೀಸಲಾದ ತಂಡವು ನಿಮಗೆ ಪರಿಣಿತ ಬೆಂಬಲವನ್ನು ಒದಗಿಸಲು, ನಿಮ್ಮ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲು ಮತ್ತು ನೀವು ಯಾವಾಗಲೂ ರಕ್ಷಣೆಯಲ್ಲಿರುತ್ತೀರಿ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಗಡಿಯಾರದ ಸುತ್ತ ಇಲ್ಲಿಯೇ ಇದೆ."



  public best_price_ins_title: string = 'ಉತ್ತಮ ಬೆಲೆಯ ವಿಮೆ';
  public best_price_ins_text: string = 'ನಮ್ಮ ಧ್ಯೇಯವು ಸರಳವಾಗಿದೆ: ಗುಣಮಟ್ಟದಲ್ಲಿ ರಾಜಿ ಮಾಡಿಕೊಳ್ಳದೆಯೇ ನಿಮಗೆ ಉತ್ತಮ ವಿಮಾ ವ್ಯವಹಾರಗಳನ್ನು ಒದಗಿಸುವುದು. ಗ್ರಾಹಕೀಯಗೊಳಿಸಬಹುದಾದ ನೀತಿಗಳ ಶ್ರೇಣಿಯೊಂದಿಗೆ, ನಿಮ್ಮ ಬಜೆಟ್ ಅನ್ನು ಹಾಗೆಯೇ ಇರಿಸಿಕೊಂಡು ನಿಮ್ಮ ಭವಿಷ್ಯವನ್ನು ರಕ್ಷಿಸಲು ನಾವು ಇಲ್ಲಿದ್ದೇವೆ.';
  public reliable_title: string = 'ವಿಶ್ವಾಸಾರ್ಹ';
  public reliable_text: string = 'ಯಾವುದೇ ವಿನಾಯಿತಿಗಳಿಲ್ಲದೆ ನಿಮಗೆ ಹೆಚ್ಚು ಮುಖ್ಯವಾದುದನ್ನು ರಕ್ಷಿಸಲು ನೀವು ನಮ್ಮನ್ನು ನಂಬಬಹುದು. ನಮ್ಮ ಭರವಸೆಗಳನ್ನು ಪೂರೈಸುವ ಸಾಬೀತಾದ ದಾಖಲೆಯೊಂದಿಗೆ, ನಿಮ್ಮ ಮನಸ್ಸಿನ ಶಾಂತಿಯನ್ನು ನಾವು ಪ್ರತಿ ಹಂತದಲ್ಲೂ ಖಚಿತಪಡಿಸುತ್ತೇವೆ. ನಿಮ್ಮ ಭವಿಷ್ಯವನ್ನು ಕಾಪಾಡುವಲ್ಲಿ ಅಚಲವಾದ ವಿಶ್ವಾಸಾರ್ಹತೆಗಾಗಿ ನಮ್ಮನ್ನು ಆರಿಸಿ.';
  public easy_claim_title: string = 'ಹಕ್ಕು ಸುಲಭವಾಯಿತು';
  public easy_claim_text: string = 'ನಮ್ಮ ಸುವ್ಯವಸ್ಥಿತ ಕಾರ್ಯವಿಧಾನಗಳು ಮತ್ತು ಮೀಸಲಾದ ಬೆಂಬಲ ತಂಡವು ನಿಮಗೆ ಹೆಚ್ಚು ಅಗತ್ಯವಿರುವಾಗ ನಿಮಗೆ ಅಗತ್ಯವಿರುವ ಸಹಾಯವನ್ನು ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ಪ್ರಕ್ರಿಯೆಯನ್ನು ಸರಳಗೊಳಿಸಲು ನಾವು ಬದ್ಧರಾಗಿದ್ದೇವೆ ಆದ್ದರಿಂದ ನಿಮ್ಮ ಜೀವನದಲ್ಲಿ ಹೆಚ್ಚು ಮುಖ್ಯವಾದುದನ್ನು ನೀವು ತ್ವರಿತವಾಗಿ ಮರಳಿ ಪಡೆಯಬಹುದು.';
  public mobile_app_title: string = 'ಒಂದೇ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಪಾವತಿಸಿ, ಹೂಡಿಕೆ ಮಾಡಿ, ನಿಮ್ಮ ಹಣವನ್ನು ಉಳಿಸಿ';
  public mobile_app_content: string = 'ಎಲ್ಲಿಯಾದರೂ, ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ಎಲ್ಲಾ ವಿಮೆ ಅಗತ್ಯಗಳ ನಿಯಂತ್ರಣವನ್ನು ಪಡೆಯಿರಿ';

  public mobile_app_compare: string = 'ವಿಭಿನ್ನ ವಿಮಾ ಪಾಲಿಸಿಗಳನ್ನು ಹೋಲಿಕೆ ಮಾಡಿ';
  public mobile_app_buy: string = 'ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಎಲ್ಲಾ ನೀತಿಗಳನ್ನು ಖರೀದಿಸಿ, ಸಂಗ್ರಹಿಸಿ ಮತ್ತು ಹಂಚಿಕೊಳ್ಳಿ';
  public mobile_app_track: string = 'ನಿಮ್ಮ ಎಲ್ಲಾ ನೀತಿಗಳನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಖರೀದಿಸಿ, ಸಂಗ್ರಹಿಸಿ ಮತ್ತು ಹಂಚಿಕೊಳ್ಳಿ ಮತ್ತು ಪ್ರಯಾಣದಲ್ಲಿರುವಾಗ ನಿಮ್ಮ ನೀತಿ ಸ್ಥಿತಿಯನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಿ';
  public mobile_app_download: string = 'ನಿಮ್ಮ ನೀತಿಯನ್ನು ಒಂದೇ ಟ್ಯಾಪ್‌ನಲ್ಲಿ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ';
  public mobile_app_coming: string = 'ಅದ್ಭುತವಾದ ಸಂಗತಿಗೆ ಸಿದ್ಧರಾಗಿ - ನಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು ಶೀಘ್ರದಲ್ಲೇ ಲಾಂಚ್ ಆಗಲಿವೆ!';
  public testimonial_title: string = 'ನಾವು ನಮ್ಮ ಗ್ರಾಹಕರ ಅನುಭವದ ಬಗ್ಗೆಯೂ ಕಾಳಜಿ ವಹಿಸುತ್ತೇವೆ';
  public our_partner_title: string = 'ನಮ್ಮ ಸಂಗಾತಿಗಳು';
  public our_partner_text: string = 'ನಿಮಗೆ ಉತ್ತಮ ಸೇವೆಗಳನ್ನು ಒದಗಿಸಲು ನಾವು ಒಟ್ಟಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತೇವೆ.';
  public need_help_title: string = 'ಸಹಾಯ ಬೇಕೇ?';
  public need_help_text: string = 'ನಿಮಗೆ ಉತ್ತಮ ಸೇವೆಗಳನ್ನು ಒದಗಿಸಲು ನಾವು ಒಟ್ಟಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತೇವೆ. Click4bima ಪೋರ್ಟಲ್‌ನಲ್ಲಿ, ನಿಮ್ಮ ವಿಮಾ ಪ್ರಯಾಣದ ಪ್ರತಿ ಹಂತದಲ್ಲೂ ಅಸಾಧಾರಣ ಗ್ರಾಹಕ ಸೇವೆ ಮತ್ತು ಸಹಾಯವನ್ನು ಒದಗಿಸಲು ನಾವು ಬದ್ಧರಾಗಿದ್ದೇವೆ. ವಿಮೆಯ ಪ್ರಪಂಚವನ್ನು ನ್ಯಾವಿಗೇಟ್ ಮಾಡುವಲ್ಲಿ ನಾವು ನಿಮ್ಮ ವಿಶ್ವಾಸಾರ್ಹ ಪಾಲುದಾರರಾಗೋಣ. ಇಂದು ನಮ್ಮ ಬೆಂಬಲ ತಂಡವನ್ನು ಸಂಪರ್ಕಿಸಿ ಮತ್ತು ವ್ಯತ್ಯಾಸವನ್ನು ನೇರವಾಗಿ ಅನುಭವಿಸಿ. ನಿಮ್ಮ ಮನಸ್ಸಿನ ಶಾಂತಿ ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ!';
  public mail_us: string = 'ನಮಗೆ ಮೇಲ್ ಮಾಡಿ';
  public mobile_number: string = 'ಮೊಬೈಲ್ ನಂಬರ್';
  public whatsapp: string = 'ವಾಟ್ಸಾಪ್';
  public about_us: string = 'ನಮ್ಮ ಬಗ್ಗೆ';
  public sitemap: string = 'ಸೈಟ್ಮ್ಯಾಪ್';
  public faqs: string = 'ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು';
  public motor_insurance: string = 'ಮೋಟಾರ್ ವಿಮೆ';
  public car_insurance: string = 'ಕಾರು ವಿಮೆ';
  public two_wheeler_insurance: string = 'ದ್ವಿಚಕ್ರ ವಾಹನ ವಿಮೆ';
  public taxi_insurance: string = 'ಟ್ಯಾಕ್ಸಿ ವಿಮೆ';
  public commercial_veh_insurance: string = 'ವಾಣಿಜ್ಯ ವಾಹನ ವಿಮೆ';
  public third_party_car_ins: string = 'ಥರ್ಡ್ ಪಾರ್ಟಿ ಕಾರ್ ವಿಮೆ';
  public third_party_bike_ins: string = 'ಥರ್ಡ್ ಪಾರ್ಟಿ 2 W ವಿಮೆ';
  public health_insurance: string = 'ಆರೋಗ್ಯ ವಿಮೆ';
  public family_health_ins: string = 'ಕೌಟುಂಬಿಕ ಆರೋಗ್ಯ ಪಾಲಿಸಿ';
  public individual_health_ins: string = 'ವೈಯಕ್ತಿಕ ಆರೋಗ್ಯ ಪಾಲಿಸಿ';
  public group_health_ins: string = 'ಗುಂಪು ಆರೋಗ್ಯ ಪಾಲಿಸಿ';
  public senior_citizen_health_ins: string = 'ಹಿರಿಯ ನಾಗರಿಕರ ಆರೋಗ್ಯ ಪಾಲಿಸಿ';
  public critical_illness_ins: string = 'ಗಂಭೀರ ಅನಾರೋಗ್ಯದ ಪಾಲಿಸಿ';
  public arogya_san_ins: string = 'ಆರೋಗ್ಯ ಸಂಜೀವನಿ ಪಾಲಿಸಿ';
  public life_insurance: string = 'ಜೀವ ವಿಮೆ';
  public term_insurance: string = 'ಟರ್ಮ್ ವಿಮೆ';
  public single_pay_term_ins: string = 'ಏಕ ಪಾವತಿ ಟರ್ಮ್ ವಿಮೆ ';
  public group_term_ins: string = 'ಗುಂಪು ಟರ್ಮ್ ವಿಮೆ';
  public saving_plans: string = 'ಉಳಿತಾಯ ಯೋಜನೆಗಳು';
  public tax_saving_ins: string = 'ತೆರಿಗೆ ಉಳಿತಾಯ ಪಾಲಿಸಿ';
  public money_back_ins: string = 'ಹಣ ಹಿಂತಿರುಗಿಸುವ ಪಾಲಿಸಿ';
  public guaranteed_return_ins: string = 'ಗ್ಯಾರಂಟಿಡ್ ರಿಟರ್ನ್ ಪಾಲಿಸಿ';
  public ulip: string = 'ಯುಎಲ್‌ಐಪಿ';
  public pension_plan: string = 'ಪೆನ್ಷನ್ ಯೋಜನೆ';
  public child_plan: string = 'ಮಕ್ಕಳ ಯೋಜನೆ';
  public others: string = 'ಇತರರು';
  public privacy_policy: string = 'ಗೌಪ್ಯತೆ ಪಾಲಿಸಿ';
  public cancellation_refund: string = 'ರದ್ದುಗೊಳಿಸುವಿಕೆ ಮತ್ತು ಮರುಪಾವತಿ';
  public term_condition: string = 'ನಿಯಮಗಳು ಮತ್ತು ಶರತ್ತುಗಳು';
  public disclaimer: string = 'ಹಕ್ಕು ನಿರಾಕರಣೆ';
  public grivance_redressal: string = 'ಪರಿಹಾರ';

  public srg_add_title: string = 'ಎಸ್ ಆರ್ ಜಿ ಇನ್ಶುರೆನ್ಸ್ ಬ್ರೋಕರ್ಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್';
  public srg_add_detail: string = '2 & 5, ವಿನಾಯಕ್ ಕಾಂಪ್ಲೆಕ್ಸ್, ದುರ್ಗಾ ನರ್ಸರಿ ರಸ್ತೆ, ಉದಯಪುರ, ರಾಜಸ್ಥಾನ, 313001';
  public direct_broker_title: string = 'ನೇರ ಬ್ರೋಕರ್, ಪರವಾನಗಿ ಸಂಖ್ಯೆ: DB-290';
  public direct_broker_detail: string = 'ಮಾನ್ಯತೆ : 02.02.2026ವರೆಗೆ,CIN: U74140RJ2002PTC017656';
  public  payment_method:string='ಪಾವತಿ ವಿಧಾನಗಳು';
  public  copyright:string='© ಕಾಪಿರೈಟ್ 2023 Click4bima. ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ';   
    public continue:string='ಮುಂದುವರಿಸಿ';
    public bought_new_bike:string='ಹೊಸ ದ್ವಿಚಕ್ರ ವಾಹನವನ್ನು ಖರೀದಿಸಿದೆ';
    public bought_new_car:string='ಹೊಸ ಕಾರನ್ನು ಖರೀದಿಸಿದೆ';
    public car_ins_title:string='ನಿಮ್ಮ ಕಾರ್ ಇನ್ಶೂರೆನ್ಸ್‌ನಲ್ಲಿ ಕಡಿಮೆ ಬೆಲೆಯನ್ನು ಪಡೆಯಿರಿ';
    public car_ins_start:string='ಕಾರು ವಿಮೆ ₹2471*ರಿಂದ ಆರಂಭ';
    public enter_car_number:string='ಕಾರ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ ಉದಾಹರಣೆ: AB12CD3456 ';  
    public vehicle_detail_form:string='ನಿಮ್ಮ ವಾಹನದ ವಿವರ';
    public city:string='ನಗರ';
    public rto:string="ಆರ್.ಟಿ.ಓ";
    public manufacturer:string="ಉತ್ಪಾದಕ"; 
    public model:string="ಮಾಡೆಲ್"; 
    public fuel_type:string="ಇಂಧನ ಪ್ರಕಾರ";
    public variant:string=" ವೇರಿಯೆಂಟ್"; 
    public reg_year:string="ನೋಂದಣಿಯ ವರ್ಷ"; 
    public get_quotes:string="ಕೋಟ್‌ಗಳನ್ನು ಪಡೆಯಿರಿ";  
    public search_city:string="ನಗರವನ್ನು ಹುಡುಕಿ";          
    public select_city:string="ಅಥವಾ ನಗರವನ್ನು ಆರಿಸಿ"; 
    public select_rto:string='ನಗರ ಆರ್‌ಟಿಒ ಆರಿಸಿ';
    public search_manufacturer:string='ತಯಾರಕರನ್ನು ಹುಡುಕಿ';
    public select_manufacturer:string='ಅಥವಾ ತಯಾರಕರನ್ನು ಆರಿಸಿ';  
    public search_model:string='ಮಾಡೆಲ್ಯನ್ನು ಹುಡುಕಿ'; 
    public select_model:string='ಅಥವಾ ಮಾಡೆಲ್ ಆರಿಸಿ'; 
    public search_variant:string='ಅಥವಾ ಮಾಡೆಲ್ ಆರಿಸಿ';
    public select_variant:string='ಅಥವಾ ವೇರಿಯಂಟ್ ಆರಿಸಿ';
    public select_year:string='ವರ್ಷವನ್ನು ಆರಿಸಿ';
    public select_fuel_type:string='ಇಂಧನ ಪ್ರಕಾರವನ್ನು ಆರಿಸಿ';  
    public contact_title:string='ನಮ್ಮೊಂದಿಗೆ ಸಂಪರ್ಕದಲ್ಲಿರಿ';
    public contact_text:string='ನಾವು ಆದಷ್ಟು ಬೇಗ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸುತ್ತೇವೆ.';
    public full_name:string='ಪೂರ್ಣ ಹೆಸರು'; 
    public mobile:string='ಮೊಬೈಲ್ ನಂಬರ್';              
    public interested_in:string='ಆಸಕ್ತಿ ಇದೆ'  ;           
    public email:string='ಇಮೇಲ್ ಪತ್ತೆ '  ;                    
    public description:string='ವಿವರಣೆ';            
    public call_me:string='ನಮ್ಮನ್ನು ಹಿಂದಕ್ಕೆ ಕರೆ ಮಾಡಿ';        
    public continue_fb:string='ಫೇಸ್‌ಬುಕ್‌ನೊಂದಿಗೆ ಮುಂದುವರಿಯಿರಿ';
    public continue_google:string='ಗೂಗಲ್ನೊಂದಿಗೆ ಮುಂದುವರಿಯಿರಿ';
    public password:string='ಪಾಸ್ವರ್ಡ್';           
    public remember:string='ನನ್ನನ್ನು ನೆನಪಿನಲ್ಲಿಡು';  
    public dont_have_acc:string="ಖಾತೆಯನ್ನು ಹೊಂದಿಲ್ಲ";
    public signup_here:string="ಇಲ್ಲಿ ಸೈನ್ ಅಪ್ ಮಾಡಿ"; 
    public click_here:string="ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ";  
    public send_otp:string="ಒಟಿಪಿ ಕಳುಹಿಸಿ";
    public back_login:string="ಲಾಗಿನ್ ಗೆ ಹಿಂತಿರುಗಿ";    
    public already_hv_acc:string="ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರ";          
    public signin_here:string="ಇಲ್ಲಿ ಸೈನ್ ಇನ್ ಮಾಡಿ"; 
    public verification:string="ಪರಿಶೀಲನೆ  ";
    public verify_text:string="ನಿಮ್ಮ ಸಂಖ್ಯೆ +918543871911 ಗೆ ಕಳುಹಿಸಿದ 6 ಅಂಕಿಯ ಕೋಡನ್ನು ನಮೂದಿಸಿ. ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ಬದಲಾಯಿಸಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ.";
    public enter_otp:string="ಒ ಟಿ ಪಿ ನಮೂದಿಸಿ";
    public dont_rec_otp:string="ನೀವು ಕೋಡ್ ಸ್ವೀಕರಿಸದಿದ್ದರೆ! ಮರುಕಳುಹಿಸಿ";     
    public submit:string="ಸಲ್ಲಿಸಿ ";
    public accept_terms:string="ನಿಮ್ಮ ಖಾತೆಯನ್ನು ರಚಿಸುವ ಮೂಲಕ, ನೀವು ನಮ್ಮ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು ಒಪ್ಪುತ್ತೀರಿ";  
    public basic_details:string ="ಮೂಲ ವಿವರಗಳು";               
    public first_name:string ="ಮೊದಲ ಹೆಸರು";
    public last_name:string ="ಉಪನಾಮ";        
    public dob:string ="ಹುಟ್ತಿದ ದಿನ";            
    public referal_code:string ="ರೆಫರಲ್ ಕೋಡ್";                
    public gender:string ="ಲಿಂಗ";
    public new_password:string  ="ಹೊಸ ಪಾಸ್ವರ್ಡವನ್ನು ನಮೂದಿಸಿ";  
    public confirm_old_pass:string  ="ಹೊಸ ಪಾಸ್ವರ್ಡವನ್ನು ದೃಢೀಕರಿಸಿ";  
    
    public sign_in:string='ಸೈನ್ ಇನ್ ಮಾಡಿ';     
    public dont_have_policy_popup:string='ನಿಮ್ಮ ದ್ವಿಚಕ್ರ ವಾಹನ ಸಂಖ್ಯೆ ನಿಮಗೆ ನೆನಪಿಲ್ಲದಿದ್ದರೆ, ನಿಮ್ಮ ದ್ವಿಚಕ್ರ ವಾಹನದ ಮೂಲ ವಿವರಗಳನ್ನು ಪ್ರವೇಶಿಸಲು ನಿಮ್ಮ RC (ನೋಂದಣಿ ಪ್ರಮಾಣಪತ್ರ) ಅನ್ನು ನಿಮ್ಮೊಂದಿಗೆ ಸಿದ್ಧವಾಗಿಡಿ.';            
    public year:string='ವರ್ಷ';
    
   public your_details:string='ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ';
   public name_title:string=' ಶೀರ್ಷಿಕೆ';

    
}