<div class="bg-white">
    <a href="/" class="d-none d-sm-block"><img class="logo" src="../../assets/images/logo.svg" alt="logo" width="150" /></a>
    <a href="/" class="d-block d-sm-none"><img class="logo" src="../../assets/svgIcon/Click4bima_mobile.svg" clialt="logo" width="60" /></a>
    <div class="sideNav-menu pl-4">
        <!-- <div class="menu" (click)="onButtonClick('dashboard')">
            <div class="d-flex">
                <img class="user-icon" src="../../assets/svgIcon/agent/dashboard.svg" clialt="logo" width="20" />
                <div class="menu-item d-flex align-items-center">
                <h4 >Dashboard</h4>
                </div>
            </div>
        </div>
        <div class="menu" (click)="onButtonClick('customer')">
        <div class="d-flex">
            <img class="user-icon" src="../../assets/svgIcon/agent/UsersThree.svg" clialt="logo" width="20" />
            <div class="menu-item d-flex align-items-center">
            <h4 >Customers</h4>
            </div>
        </div>
        </div> -->
        <div class="menu" >
        <div class="d-flex">
           
            <img class="user-icon" src="../../assets/svgIcon/agent/Link.svg" clialt="logo" width="20" />
            <a href="/agent-profile"><div class="menu-item d-flex align-items-center">
            <h4 >Profile</h4>
            </div></a>
        </div>
        </div>
        <!-- <div class="menu" (click)="onButtonClick('training')">
        <div class="d-flex">
            <img class="user-icon" src="../../assets/svgIcon/agent/HardDrives.svg" clialt="logo" width="20" />
            <div class="menu-item d-flex align-items-center">
            <h4>Training</h4>
            </div>
        </div>
        </div> -->
        <!-- <div class="menu" (click)="onButtonClick('commission')">
        <div class="d-flex">
            <img class="user-icon" src="../../assets/svgIcon/agent/Coins.svg" clialt="logo" width="20" />
            <div class="menu-item d-flex align-items-center">
            <h4>Commission</h4>
            </div>
        </div>
        </div>
        <div class="menu" (click)="onButtonClick('chat')">
        <div class="d-flex">
            <img class="user-icon" src="../../assets/svgIcon/agent/ChatCircleDots.svg" clialt="logo" width="20" />
            <div class="menu-item d-flex align-items-center">
            <h4>Chat</h4>
            </div>
        </div>
        </div> -->
    </div>
</div>
