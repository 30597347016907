<div class="user-dashboard">
    <div class="user-route" style="margin-top:130px;">
        <div class="row">
            <div class="col-12">
            <h2 class="mb-0 text-main-secondary">
                Communication Preferences
            </h2>
            <span class="text-grey4 font-15-12">Select a mobile number/email address to set communication preferences</span>
        </div>
         </div>
         <div class="row mt-3">   
            <div class="col-md-6">
                <div class="card p-5">
                    <label class="text-grey5 input-label">Select Mobile Number</label>
                    <select class="form-select form-select-custom" aria-label="Default select example">
                        <option selected>1235456778</option>
                        <option value="1">4546546546</option>
                        <option value="2">454544875</option>
                        <option value="3">84555648748</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card p-5">
                    <label class="text-grey5 input-label">Select Email Address</label>
                    <select class="form-select form-select-custom" aria-label="Default select example">
                        <option selected>abc@gmail.com</option>
                        <option value="1">abc@gmail.com</option>
                        <option value="2">abc@gmail.com</option>
                        <option value="3">abc@gmail.com</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mt-3"> 
            <div class="col-md-12 mt-5">
                <h2 class="mb-0 text-main-secondary">
                    Notification
                </h2>
                <!-- <span class="text-grey5">Select a mobile number/email address to set communication preferences</span> -->
            </div>
        </div>
        <div class="row mt-3"> 
            <div class="col-12">
                <div class="card p-5">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table1">
                                <thead>
                                    <tr class="bg-grey8">
                                        <th scope="col">Label</th>
                                        <th scope="col">Call</th>
                                        <th scope="col">SMS</th>
                                        <th scope="col">WhatsApp</th>
                                        <th scope="col">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of settingData" id="{{item.idPrefix}}">
                                        <td><span class="fw-bolder">{{item.label1}}</span><br>
                                            <span>{{item.label2}}</span>
                                        </td>
                                        <td>
                                            <input class="radio-button scale-1_8" type="checkbox"
                                                id="{{item.idPrefix}}_call"> 
                                        </td>
                                        <td>
                                            <input class="radio-button scale-1_8" type="checkbox"
                                            id="{{item.idPrefix}}_sms">    
                                        </td>
                                        <td>
                                            <input class="radio-button scale-1_8" type="checkbox"
                                            id="{{item.idPrefix}}_whatsapp"> 
                                        </td>
                                        <td>
                                            <input class="radio-button scale-1_8" type="checkbox"
                                            id="{{item.idPrefix}}_email">    
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td><span><b>Policy Updates</b></span><br>
                                            <span>Policy Updates: Important updates for your purchased policies including payment,
                                                issuance, claims and renewal reminders</span>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">    
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span><b>Pre Purchase Updates</b></span><br>
                                            <span>Information on products you have shown an interest in including Quotes, Brochures,
                                                Illustrations</span>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span><b>News & Offers </b></span><br>
                                            <span>News, promos, and events for you</span>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-check">
                                                <input class="form-check-input radio-button" type="checkbox"
                                                    id="flexCheckIndeterminate">
            
                                            </div>
                                        </td>
                                    </tr>     -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    <hr>
                    <table class="table table2 mb-0">
                        <tbody>
                            <tr>
                                <td style="width: 4%;">
                                    <input class="radio-button scale-1_8" type="checkbox"
                                        id="flexCheckIndeterminate"> 
                                </td>
                                <td>
                                    <span>
                                        <b>Unsubscribe for all</b>
                                    </span>
                                    <br>
                                    <span>Get the information about the amazing offers and important information</span>
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-primary btn-custom">save</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-footer></app-footer> -->