<p>car-quote-detail works!</p>
<!-- <app-insurance></app-insurance> -->
<div class="container" id="mt"><br><br>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card p-5 bg-body-tertiary">
                    <h4>
                        <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft">&nbsp;&nbsp;Your vehicle details
                    </h4>

                    <!-- (click)="bikeNaviagtion()"  -->
                    <form novalidate="" autocomplete="off">
                        <label>City</label>
                        <input type="text" class="form-control" name="rto" placeholder="City" formControlName="city"
                            value="{{city}}"><br>
                        <label>RTO</label>
                        <input type="text" class="form-control" name="rto" placeholder="RTO" formControlName="rto"><br>
                        <label>Manufacturer</label>
                        <input type="text" class="form-control" placeholder="Make" formControlName="make"
                            name="make"><br>
                        <label>Model</label>
                        <input type="text" class="form-control" placeholder="Model" formControlName="model"
                            name="Modal"><br>
                        <label>Fuel Type</label>
                        <input type="text" class="form-control" placeholder="Fuel Type" formControlName="Fuel Type"
                            name="Fuel Type"><br>
                        <label>Variant</label>
                        <input type="text" class="form-control" placeholder="Variant" formControlName="Variant"
                            name="Variant"><br>
                        <label>Registration Year</label>
                        <input type="text" class="form-control" placeholder="Registration Year"
                            formControlName="Variant" name="Year"><br>

                        <div class="float-end text-center">
                            <button class="btn btn-secondary px-6 me-2">Cancel</button>
                            <button class="btn btn-primary">
                                Get Quotes
                            </button> &nbsp;
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-8">
                <!-- <form  [formGroup]="vehicleDetailsForm" class="needs-validation" novalidate="" autocomplete="off"> -->
                <div class="card p-5 bg-body-tertiary">
                    <ul class="row">
                        <li class="col-md-3 selected-box active">
                            <a href="#1a" data-toggle="tab">
                                <h3>City/RTO</h3>
                            </a>
                        </li>&nbsp;&nbsp;
                        <li class="col-md-3 selected-box">
                            <a href="#2a" data-toggle="tab">
                                <h3>Manufacturer</h3>
                            </a>
                        </li>&nbsp;&nbsp;
                        <li class="col-md-3 selected-box ">
                            <a href="#3a" data-toggle="tab">
                                <h3>Model</h3>
                            </a>
                        </li>&nbsp;&nbsp;
                        <li class="col-md-3 selected-box">
                            <a href="#6a" data-toggle="tab">
                                <h3>Fuel Year</h3>
                            </a>
                        </li>&nbsp;&nbsp;
                        <li class="col-md-3 selected-box">
                            <a href="#4a" data-toggle="tab">
                                <h3>Variant</h3>
                            </a>
                        </li>&nbsp;&nbsp;

                        <li class="col-md-3 selected-box">
                            <a href="#5a" data-toggle="tab">
                                <h3>Year</h3>
                            </a>
                        </li>&nbsp;&nbsp;
                    </ul><br>
                    <div class="tab-content view ">
                        <div id="1a" class="tab-pane active a1">
                            <div>
                                <input type="text" name="cityName" class="form-control input-control cityIcon wt"
                                    placeholder="Search City">
                            </div>
                            <div class="mt-5">
                                <div>
                                    <h3 class="text-start">Or Select City</h3>
                                </div>
                                <div class="row view">
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Delhi()">Delhi</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Mumbai()">Mumbai</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Channai()">Channai</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Kolkata()">Kolkata</h4>
                                    </div>
                                </div><br>
                                <div class="row view">
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Bengaluru()">Bengaluru</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Noida()">Noida,Ghaziabad</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Gurgoan()">Gurgoan</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Pune()">Pune</h4>
                                    </div>
                                </div><br>
                                <div class="row view">
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Hydrabad()">Hydrabad</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5 " (click)="Ahemdabad()">Ahemdabad</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Jaipur()">Jaipur</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5" (click)="Udaipur()">Udaipur</h4>
                                    </div>
                                </div><br>
                            </div><br>
                            <div>
                                <h3 class="text-start">Select City RTO</h3>
                            </div>
                            <div class="row view">
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">1</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">2</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">3</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">4</h4>
                                </div>
                            </div><br>
                            <div class="view">
                                <h6>Can't find your city? <a href="#">Click Here</a></h6>
                            </div>
                        </div>
                        <div id="2a" class="tab-pane a1">
                            <div>
                                <input type="text" class="form-control input-control manufacturerIcon wt"
                                    placeholder="Search Manufacturer">
                            </div>
                            <div class="mt-5">
                                <div>
                                    <h3 class="text-start">Or Select Manufacturer</h3>
                                </div>
                                <div class="row view">
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Delhi</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Mumbai</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Channai</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Kolkata</h4>
                                    </div>
                                </div><br>
                                <br>
                                <br>
                            </div><br>
                            <!-- <div class="view">
                                <h5>Can't find your two wheeler manufacturer? <a href="#">Click Here</a></h5>
                            </div> -->
                        </div>
                        <div id="3a" class="tab-pane">
                            <div>
                                <input type="text" class="form-control input-control bikeIcon wt"
                                    placeholder="Search Model">
                            </div>
                            <div class="mt-5">
                                <div>
                                    <h3 class="text-start">Or Select Model</h3>
                                </div>
                                <div class="row view">
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Access</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Gixxer</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Swish</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">GS150R</h4>
                                    </div>
                                </div><br>
                                <div class="row view">
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Hayate</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Lets</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Burgman</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4">
                                        <h4 class="m-auto pt-5 pb-5">Inturder</h4>
                                    </div>
                                </div><br>
                            </div><br>

                            <div>
                                <h3 class="text-start">Other Model</h3>
                            </div>
                            <div class="row">
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">UZ</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">Slingshot</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">Zeus</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">Heat</h4>
                                </div>
                            </div><br>

                            <div class="view">
                                <h5>Can't find your two wheeler Model? <a href="#">Click Here</a></h5>
                            </div>
                        </div>
                        <div class="tab-pane" id="6a">

                            <div>
                                 <input type="text" class="form-control input-control bikeIcon wt" placeholder="Fuel Type">
                            </div>
                            <div class="mt-5">
                                <h3 class="text-start">Select your Fuel Type</h3>
                            </div>
                            <div class="row view mt-5">
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">Petrol</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">Diesel</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">CNG</h4>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div class="card col-4">
                                    <h4 class="m-auto pt-5 pb-5">External CNG Kit</h4>
                                </div>
                            </div><br>
                        </div>
                        <div id="4a" class="tab-pane ">
                            <div>
                                <input type="text" class="form-control input-control bikeIcon wt"
                                    placeholder="Search Variant">
                            </div>
                            <div class="mt-5">
                                <div>
                                    <h3 class="text-start">Or Select Variant</h3>
                                </div>
                                <div class="row view">
                                    <div class="card col-4 mt-5 ">
                                        <h4 class="m-auto pt-5 pb-5">ABS(155 cc)</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5 ">Dual Tone Rear Disc (155 cc)</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5 ">SF(155 cc)</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5 ">SF-Moto GP(250)</h4>
                                    </div>
                                </div><br>
                                <div class="row view">
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5 ">STD(155 cc)</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5 ">STD(250 cc)</h4>

                                    </div><br>
                                </div><br>
                                <div>
                                    <h3 class="text-start">Other Variant</h3>
                                </div>
                                <div class="row view">
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5 ">ABS(155 cc)</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5">Dual Tone Rear Disc (155 cc)</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5">SF(155 cc)</h4>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="card col-4 mt-5">
                                        <h4 class="m-auto pt-5 pb-5">SF-Moto GP(250)</h4>
                                    </div>
                                </div><br>
                                <div class="view">
                                    <h5>Can't find your two wheeler variant? <a href="#">Click Here</a></h5>
                                </div>
                                <div class="view">
                                    <button class="btn btn-primary btn-lg ">
                                        View Plans
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="5a">
                            <div>
                                <input type="text" class="form-control input-control bikeIcon wt" placeholder="year">
                            </div>
                        </div>

                    </div>
                    <!-- </form> -->
                </div>

            </div>
        </div>
    </div>
</div>