<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1>Guaranteed Return Plan</h1>
            </div>
            <div class="top-line"></div>
            <div class="row">
                <div class="mt-5">
                    <p class="font-body">Guaranteed Returns Insurance plans or Traditional insurance plans are insurance
                        plans which not only provides the policy holder with protection, but also returns a certain
                        amount of sum, post the term period. This plan helps you fulfill your medium to long term goals
                        such as Child&apos;s Education/ Marriage or Retirement planning etc.</p>
                </div>          
                <div class="title-line">
                    <h4>Guaranteed Return Insurance Plan Key Features</h4>
                </div>
                <div class="mt-5">
                    <p class="font-body">Guaranteed Returns &ndash; Total sum assured is returned to the customer upon
                        maturity or to the nominee in case of death during the term period</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Tax Saving &ndash; Tax exemptions under Income Tax Act, 1961</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Risk free plan &ndash; Guaranteed Returns Insurance plans are risk free as they
                        invest in fixed income securities</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Flexibility to choose Plan Option: Endowment, Regular Income or Whole Life
                        Income</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Life cover for the financial protection of your loved ones</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Benefits of Having Guaranteed Returns Insurance Plan:</h4>
                </div>
                <div class="mt-5">
                    <p class="font-body"> Maturity Benefit: Guaranteed benefits are paid out on maturity plan and this
                        amount is informed to the policy holder before the plan is purchased. So, there is no disclaimer
                        applicable regarding &ldquo;Past performance&rdquo;</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Death Benefit: In case of death of the person insured, the death benefit is
                        paid out to the nominee. This is a big benefit available only with life insurance</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Income Tax Benefit: The premium paid by the policy holder and maturity/death
                        benefits received are eligible for tax benefits as per prevailing Income Tax law</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">Flexibility in Premium Payment: The policyholder gets the option to pay premium
                        for a limited period and get cover for a longer policy term.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">No Medicals: These plans are available online and can be bought in a matter of
                        minutes. There is no medical test required. Customers can buy the plan and submit the document
                        online for quick issuance of the policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Applying For Traditional Insurance through Click4bima</h4>
                </div>
                <div class="mt-5">
                    <p class="font-body">1. On the Click4bima homepage. click on Guaranteed Return plan under the
                        Personal tab.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">2. Click policy Quotes to compare and choose from top insurance providers.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">3. Fill necessary details as required</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">4. Fill in your name, email address, city, country code. and mobile number.
                        Click Continue.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">S. You will be taken to the Life Insurance quotes page where you will see life
                        insurance quotes of more than 10 insurers. Next, choose the plan as per payment schedule - One
                        Time Payout and Monthly Payout Plans.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">6. After reviewing and comparing each life insurance quote, click the premium
                        amount to buy the desired plan.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">7. You will see a pop-up on the screen which will give you an overview of the
                        chosen plan like premium, plan features, exclusions, additional fiders, etc. Click Proceed.</p>
                </div>
                <div class="mt-5">
                    <p class="font-body">8. This will take you to the insurer&apos;s website. Fill in the necessary
                        details to buy the plan.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>