<div class="col-md-4">
  <div class="row propertyCard">
    <div class="row mb-4">
      <div class="col-7">
        <span class="webkitDisplay">
          <h5>Your vehicle details</h5>
        </span>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <img class="mr-4" style="vertical-align: text-top;" src="../../assets/svgIcon/suzuki.svg" alt="">
        <h5 class="webkitDisplay"><span class="greyTxt">Vehicle</span><br>{{vehicleData.make}}</h5>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        <i class="fa fa-calendar-o mr-4 yearIcon" aria-hidden="true"></i>
        <h5 class="webkitDisplay"><span class="greyTxt">Model</span><br>{{vehicleData.make}}</h5>
      </div>
      <div class="col-6">
        <i class="fa fa-building-o mr-4 yearIcon" aria-hidden="true"></i>
        <h5 class="webkitDisplay"><span class="greyTxt">Registration Number - City</span><br>{{vehicleData.registrationNumber}} - {{vehicleData.city}}</h5>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        <i class="fa fa-calendar-o mr-4 yearIcon" aria-hidden="true"></i>
        <h5 class="webkitDisplay"><span class="greyTxt">Year</span><br>{{vehicleData.registrationYear}}</h5>
      </div>
      <!--<div class="col-6">
          <i class="fa fa-building-o mr-4 yearIcon" aria-hidden="true"></i>
          <h5 class="webkitDisplay"><span class="greyTxt">Last Year Claim</span><br>No</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <i class="fa fa-calendar-o mr-4 yearIcon" aria-hidden="true"></i>
          <h5 class="webkitDisplay"><span class="greyTxt">NCB Last Year</span><br>0%</h5>
        </div>
      </div>-->
    </div>
    <div class="row propertyCard">
      <div class="row mb-4">
        <div class="col-9">
          <span class="webkitDisplay">
            <h5>
              Premium Breakup
            </h5>
          </span>
        </div>

      </div>
      <div class="row">
        <div class="col-8">
          <p class="greyTxt">Net Premium</p>
          <p class="greyTxt">Add GST(18%)</p>
          <p class="greyTxt">Final Premium</p>
        </div>
        <div class="col-4" style="text-align: end;">
          <p style="color: #000">₹{{premiumData.premium}}</p>
          <p style="color: #000">₹{{premiumData.gst}}</p>
          <p style="color: #000">₹{{premiumData.netPremium}}</p>
        </div>
      </div>

    </div>
  </div>
</div>
