
<div class="content-box ">
    <div class="container-fluid">
      <ngx-spinner bdColor="rgba(51,51,51,0.8)"
                   size="medium"
                   color="#009ef7"
                   type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
      <div class="card card-user shadow">
          <h2>
            <b class="float-start text-primary mt-4 ms-4">Quote Details</b>
          </h2>
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 ms-4 pt-1">
              <li class="breadcrumb-item text-muted">
                <a href="../../demo1/dist/index.html" class="text-muted text-hover-primary">Home</a>
              </li>
             
              <li class="breadcrumb-item">
                <span class="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
             
              <li class="breadcrumb-item text-muted">Quote Details</li>
            
              
            </ul>

        <div id="kt_app_content" class="app-content flex-column-fluid">
          <div id="kt_app_content_container" class="app-container container-xxl">
            <div class="card">
              <div class="card-header border-0 pt-6">
                <div class="card-title">
                  <div class="d-flex align-items-center position-relative my-1">
                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                      </svg>
                    </span>
                    <input type="text" data-kt-user-table-filter="search" class="form-control form-control-solid w-250px ps-14" placeholder="Search quote" />
                  </div>
                </div>
              </div>
              <div class="card-body py-4">
                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                  <thead>
                    <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                      <th class="min-w-125px">Enquiry Id</th>
                      <th class="min-w-125px">Insurance Company</th>
                      <th class="min-w-105px">RegistrationNumber</th>
                      <!-- <th class="min-w-105px">RequestQuoteJson</th>
                      <th class="min-w-125px">ResponseQuoteJson</th> -->
                    </tr>
                  </thead>
                 
                  <tbody class="text-gray-600 fw-semibold" *ngFor="let quoteDetails of quoteDetailsList">
                    <tr>
                      <td class="d-flex align-items-center">
                        <div class="d-flex flex-column">
                          <span>{{quoteDetails.enquiryId}}</span>
                        </div>
                      </td>
                      <td>{{ quoteDetails.insuranceCompany }} </td>
                      <td>{{ quoteDetails.registrationNumber }} </td>
                      <!-- <td>{{ quoteDetails.requestQuoteJson }} </td>
                      <td>{{ quoteDetails.responseQuoteJson }} </td> -->
                      
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>
    </div>
  