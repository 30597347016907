import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../Shared/AppConfig';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) {

  }

  async GetFamilyMemberDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}profile/GetFamilyMemberDetails`,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  

  async GetPropertyDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}profile/GetPropertyDetails`,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetVehiclesDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}profile/GetVehiclesDetails`,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }


  async GetAddressDetails() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}profile/GetAddressDetails`,  {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
  

  async ManageFamilyMemberDetails(data:any){
    return this.http.post<ApiResponse>(`${environment.APIUrl}profile/ManageFamilyMemberDetails`, data, {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
          }).toPromise().catch(async (errorRes: ApiResponse) => {
            let res = new ApiResponse();
            res.isSuccess = false;
            res.message = errorRes.message;
            return res;
          });
  }

  async ManagePropertyDetails(data:any){
    return this.http.post<ApiResponse>(`${environment.APIUrl}profile/ManagePropertyDetails`, data, {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
          }).toPromise().catch(async (errorRes: ApiResponse) => {
            let res = new ApiResponse();
            res.isSuccess = false;
            res.message = errorRes.message;
            return res;
          });
  }

  async ChangePassword(data:any){
    return this.http.post<ApiResponse>(`${environment.APIUrl}profile/ChangePasswordDetails`, data, {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
          }).toPromise().catch(async (errorRes: ApiResponse) => {
            let res = new ApiResponse();
            res.isSuccess = false;
            res.message = errorRes.message;
            return res;
          });
  }


//   async SavePaymentDetail(data: any) {
//     return this.http.post<ApiResponse>(`${environment.APIUrl}policy/SavePaymentDetail`, data, {
//       headers: new HttpHeaders()
//         .set('Content-Type', 'application/json')
//     }).toPromise().catch(async (errorRes: ApiResponse) => {
//       let res = new ApiResponse();
//       res.isSuccess = false;
//       res.message = errorRes.message;
//       return res;
//     });
//   }

//   async UpdatePolicyReceipt(data: any) {
//     return this.http.post<ApiResponse>(`${environment.APIUrl}policy/UpdatePolicyReceipt`, data, {
//       headers: new HttpHeaders()
//         .set('Content-Type', 'application/json')
//     }).toPromise().catch(async (errorRes: ApiResponse) => {
//       let res = new ApiResponse();
//       res.isSuccess = false;
//       res.message = errorRes.message;
//       return res;
//     });
//   }
 
//   async GetPolicyById(policyNo?:string) {
//     return this.http.get<ApiResponse>(`${environment.APIUrl}policy/PolicyDetailsById?policyNo=${policyNo}`, {
//       headers: new HttpHeaders()
//         .set('Content-Type', 'application/json')
//     }).toPromise().catch(async (errorRes: any) => {
//       return errorRes;
//     });
//   }

}
