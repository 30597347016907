<app-navbar></app-navbar>
<div class="container"> 
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row"><h1>Tax Saving Investments</h1></div>
            <div class="top-line"></div>
            <div class="row">
            <div class="mt-3"><p class="font-body">This scheme is for both salaried and non-salaried individual taxpayers. You pay taxes on your expenses and incomes. Taxes which apply to your expenses are &lsquo;indirect taxes&rsquo;, and the taxes applicable to your income are the &lsquo;direct taxes&rsquo;. To reduce the income tax burden, Govt comes up with tax saving investment plans where you can avail these tax saving schems and claim deductions for the same as per Income Tax Act, 1961.</p></div>
            <div class="mt-3"><p class="font-body">This not only provides you tax exemptions but also helps to earn tax free Income. While choosing the right tax-saving investments plans it is important to consider the factors like safety, returns and liquidity. Also, it is important to keep a proper understanding of how the returns will be taxed. If the returns on investment are taxable, then the scope to create wealth over a long-term gets constrained.</p></div>
            <div class="mt-3"><p class="font-body">For your better understanding it is important to know about the key section of the income Tax Act under which benefits are available :&nbsp;</p></div>
            <div class="mt-3"><p class="font-body">Income Tax Act i.e. section 80C. Most forms of tax-saving investments plan work under the parameters of section 80C of the Income Tax Act. As per this section, the investments made by the investor are eligible for tax exemption up to a maximum limit of Rs. 1, 50,000. Such investments include ELSS (Equity Linked Saving Scheme), Fixed Deposits, Life Insurance, Public Provident Fund, National Savings Scheme and Bonds. There are a very few investment avenues that provide a further tax deduction, over and above this limit.</p></div>
            </div>
            <div class="row">
            <div class="title-line"><h4>Tax Saving Investment Under 80C</h4></div>
            <div class="mt-3"><p class="font-body">Following are the best tax saving investment under 80C where you can avail tax benefits in maximum way:</p></div>
        </div>
        <div class="row">
            <div class="title-line"><h4>1.ELSS Mutual Fund</h4></div>
            <div class="mt-3"><p class="font-body">As the name suggests, Equity Linked Saving Scheme or ELSS is a type of mutual fund scheme that primarily invests in the stock market or Equity. Investments of up to 1.5 Lac done in ELSS Mutual Funds are eligible for tax deduction under section 80C of the Income Tax Act. The advantage ELSS has over other tax Saving instruments is the shortest lock-in period of 3 years. This means you can sell your investment only after 3 years, from the date of purchase.</p></div>
            <div class="mt-3"><p class="font-body">Who Should Invest in ELSS Mutual Funds</p></div>
            <div class="mt-3"><p class="font-body">Salaried Individuals:</p></div>
            <div class="mt-3"><p class="font-body">When you are a salaried employee, there is a certain amount that goes towards Employee Provident Fund (EPF) which is a fixed income product. If one wants to balance out risk &amp; return on their investment portfolio then ELSS is the best option. In addition to the upside of extraordinary returns, investments in ELSS are also eligible for tax deduction under section 80C. While Unit Linked Insurance Plans (ULIPs) and the National Pension Scheme (NPS) also do the same, they have a higher lock-in period &amp; lesser potential of returns. For instance, ULIPs have a lock-in period of five years. NPS is more of a retirement solution with partial exposure to equity and the invested amount is locked till the age of 60 years. With an ELSS fund, you have the shortest lock-in period of only three years.</p></div>
            <div class="mt-3"><p class="font-body">First time investors:</p></div>
            <div class="mt-3"><p class="font-body">If you are a new investor, ELSS is an ideal choice, since in addition to tax benefits you get a flavour of equity investing and mutual funds. Yes, equity investments do carry a higher risk, but that is generally over the short term. If you invest for more than five years, the risk is much lower. Like all equity investments, the best way is to start investing in monthly SIPs through the year. SIP in a ELSS fund helps you to accumulate more units when the market is in red and generate exceptional returns when the markets are favourable.&nbsp;</p></div>
            
            <div class="mt-3"><p class="font-body">Things Investor Should Consider Before Investing in ELSS Funds</p></div>
            <div class="mt-3"><p class="font-body">Fund returns:</p></div>
            <div class="mt-3"><p class="font-body">Before you go for a fund, compare the fund performance with its competitors &amp; benchmark to know if it has shown consistent performance in the past. If a fund outperforms its benchmark or competitors, then the fund delivers high returns.</p></div>
            <div class="mt-3"><p class="font-body">History of fund house:</p></div>
            <div class="mt-3"><p class="font-body">It is recommended to choose fund houses that have performed consistently over a long period, say about five to 10 years.</p></div>
            <div class="mt-3"><p class="font-body">Expense ratio:</p></div>
            <div class="mt-3"><p class="font-body">The expense ratio depicts how much of your investment goes towards managing the fund. If a fund has a lower expense ratio, it means you can have higher take-home returns - so it&apos;s always better to go for such funds.</p></div>
            <div class="mt-3"><p class="font-body">Financial parameters:</p></div>
            <div class="mt-3"><p class="font-body">You can also consider several parameters such as Standard Deviation, Sharpe Ratio, Alpha and Beta to analyse the performance of a fund. A fund with a higher standard deviation and beta is more risky than one with a lower deviation and beta. Choose funds with a higher Sharpe ratio.</p></div>
            <div class="mt-3"><p class="font-body">Fund manager:</p></div>
            <div class="mt-3"><p class="font-body">It is important to be careful when going for a fund manager, because he / she is the person who plays a key role in management of your funds. The fund manager must be competent and must have great experience in picking the right stocks and creating a strong portfolio.</p></div>
            <div class="mt-3"><p class="font-body">Advantages of ELSS Mutual Funds</p></div>
            <div class="mt-3"><p class="font-body">Here&apos;s a look at the advantages of ELSS Mutual Funds:</p></div>
            <div class="mt-3"><p class="font-body">Shortest lock-in:</p></div>
            <div class="mt-3"><p class="font-body">ELSS has the shortest lock-in period of three years. Tax-saving fixed deposits have a five-year lock-in, while PPF has a 15-year maturity. All in all, ELSS offers more liquidity in the medium term.</p></div>
            <div class="mt-3"><p class="font-body">Potentially higher returns:</p></div>
            <div class="mt-3"><p class="font-body">Unlike ELSS where return is market linked, other 80C investments like PPF or FDs are fixed income products. ELSS has the potential to generate significantly higher wealth in a medium to long-term investment horizon.</p></div>
            <div class="mt-3"><p class="font-body">Better post-tax returns:</p></div>
            <div class="mt-3"><p class="font-body">Long Term Capital Gains from ELSS are tax free up to limit of 1 lac. Gains over 1 lac attracts a tax rate of just 10%. Lower tax rates, coupled with higher returns ensure the best post tax returns.</p></div>
            <div class="mt-3"><p class="font-body">Regular investing is hassle-free and convenient: It is easy to invest in ELSS funds through a monthly SIP.</p></div>
            
            <div class="title-line"><h4>2. National Pension Scheme</h4></div>
            <div class="mt-3"><p class="font-body">National Pension System (NPS) is a pension cum investment scheme launched by Government of India to provide old age security to Citizens of India. It brings an attractive long term saving avenue to effectively plan your retirement through safe and regulated market-based return. Upon maturity, individual gets a lump sum amount along with regular income for a stress-free retirement.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>3.Unit Linked Insurance Plan&nbsp;</h4></div>
            <div class="mt-3"><p class="font-body">Unit Linked Insurance Plan (ULIP) is a mix of insurance along with investment. From a ULIP, the goal is to provide wealth creation along with life cover where the insurance company puts a portion of your investment towards life insurance and rest into a fund that is based on equity or debt or both and matches with your long-term goals. These goals could be retirement planning, children&rsquo;s education or another important event you may wish to save for.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>4. Public Provident Fund(PPF)</h4></div>
            <div class="mt-3"><p class="font-body">Public Provident Fund is a popular investment scheme among investors courtesy its multiple investor-friendly features and associated benefits. It is a long-term investment scheme popular among individuals who want to earn high but stable returns. Proper safekeeping of the principal amount is the prime target of individuals opening a PPF account.</p></div>
            <div class="mt-3"><p class="font-body">A Public provident fund scheme is ideal for individuals with a low risk appetite. Since this plan is mandated by the government, it is backed up with guaranteed returns to protect the financial needs of the masses in India. Further, invested funds in the PPF account are not market-linked either. Investors can also undertake the public provident fund regime to diversify their financial and investment portfolio. At times of downswing of the business cycle, PPF accounts can provide stable returns on investment annually.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>5. Sukanya Samriddhi Yojana&nbsp;</h4></div>
            <div class="mt-3"><p class="font-body">Sukanya Samriddhi Yojana (SSY) is a small deposit scheme launched back in 2015 as part of the Government initiative Beti Bachao, Beti Padhao campaign for the girl child. One of the reasons why this scheme has become popular is due to its tax benefit. It comes with a maximum tax benefit of Rs 1.5 lakh under section 80C of the Income-tax Act. Further, the interest accrued and maturity amount are exempt from tax.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>6.National Savings Certificate ( NSC )</h4></div>
            <div class="mt-3"><p class="font-body">The National Savings Certificate is a fixed income investment scheme that you can open with any post office. A Government of India initiative, it is a savings bond that encourages subscribers &ndash; mainly small to mid-income investors &ndash; to invest while saving on income tax. A fixed-income instrument like Public Provident Fund and Post Office FDs, this scheme too is a secure and low-risk product. You can buy it from the nearest post office in your name, for a minor or with another adult as a joint account. They come with a fixed maturity period of five years. There is no maximum limit on the purchase of NSCs, but only investments of up to Rs.1.5 lakh can earn you a tax break under Section 80C of the Income Tax Act. The certificates earn a fixed interest, which is currently at a rate of 6.8% per annum.</p></div></div>
            <div class="row">
            <div class="title-line"><h4>7. Senior Citizen Saving Scheme</h4></div>
            <div class="mt-3"><p class="font-body">Senior Citizen Savings Scheme (SCSS) is a government-sponsored savings instrument for individuals above the age of 60. The Government of India introduced this scheme in 2004 intending to provide senior citizens with a steady and secure source of income for their post-retirement phase. It is one of the most lucrative savings schemes in India and offers comparatively substantial returns to its subscribers. The SCSS interest rate for April to June 2020 will be provided at 7.4% Furthermore, it is a government-backed scheme, and hence, the risk of the capital loss is negligible. Individuals can apply for SCSS through post offices as well as public &amp; private banks.</p></div>
            
            <div class="mt-2"><p class="font-body"><b>Eligibility Criteria:</b></p></div>
            <div class="mt-2"><p class="font-body">An individual must be a citizen of India. Non-residential Indians (NRIs) or a person of Indian origin (PIOs) are not eligible to avail this feature. Also, Hindu Undivided Family (HUFs) do not qualify for this scheme.</p></div>
            
            <div class="mt-2"><p class="font-body">As this is a senior citizen savings scheme, so any resident of India aged 60 years or above is eligible for this scheme. However, there are few exceptions to the age bar:</p></div>
            
            <div class="mt-2"><p class="font-body">Retirees in the age group of 55-60 years who have opted for Voluntary Retirement Scheme (VRS) or Superannuation are eligible to avail the scheme if they apply for the same within one month of gaining their retirement perks.</p></div>
            
            <div class="mt-3"><p class="font-body">Retired defence personnel can avail this scheme irrespective of their age, provided they fulfil all other conditions.</p></div>
            <div class="mt-3"><p class="font-body">This scheme can be availed with a minimum deposit amount of Rs. 1000.</p></div>
            
        </div>
        <div class="row">
            <div class="title-line"><h4>8. Bank Fixed Deposit Scheme&nbsp;</h4></div>
            <div class="mt-3"><p class="font-body"> A fixed deposit refers to an investment scheme that banks and non-banking financing companies provide. FDs offer greater returns on the principal invested when compared to the returns generated from a regular savings account. Fixed deposits have a fixed tenure, hence the name. Depending on a consumer&rsquo;s investment portfolio, the FD investment period can either be short-term or long-term. The interest rates on fixed deposits vary from one company or bank to another. Fixed deposit investors need to remember, however, that they cannot withdraw money before maturity without financial repercussions. In emergencies, early withdrawal is possible after the payment of penalties.</p></div>
            <div class="mt-3"><p class="font-body">Fixed deposit investments are offered by banks, post-offices, and other non-banking financial companies. In India, investors have countless options to open fixed deposit accounts. However, they must compare the interest rates, the reputation of the company, and other factors before depositing their funds.&nbsp;</p></div>
            <div class="mt-3"><p class="font-body">You can approach any of the banks or NBFCs in the country to open a fixed deposit account. Individuals lacking bank accounts can also avail of fixed deposit investments through post office accounts.</p></div>
        </div>
        <div class="row">
            <div class="title-line"><h4>9. Insurance</h4></div>
            <div class="mt-3"><p class="font-body">Life insurance is considered one of the most usefull way to save tax and avail other tax benefits. If you have paid an insurance premium to insure your own life or the life of your spouse or child, such premium payments are eligible for deduction under section 80C of the Income Tax Act. Irrespective of your child being dependent or independent, minor or major, married or unmarried, the deduction under section 80C shall be allowed. An individual and a HUF, both, can claim this deduction under Section 80C.</p></div>
            <div class="mt-3"><p class="font-body">Many taxpayers have a doubt about whether this deduction is available only in respect of life insurance cover taken with LIC. This is not true. Premium paid towards a life cover taken with any insurer that is approved by the Insurance Regulatory and Development Authority of India (IRDAI), is eligible for a Section 80C deduction. However, to claim deduction under section 80C the premium paid should not exceed 10% of the sum assured where the policy has been issued after 1st April 2012. For policies issued prior to 1 April 2012, in order to claim this deduction, the premium paid should not exceed 20% of the sum assured. Further, here it is important to note that a policy issued after 1 April 2013, covering the life of an individual with a disability referred to under Section 80U or a disease referred to under Section 80DDB, the requirement to claim the deduction under Section 80C is that the premium should not exceed 15% of the sum assured.</p></div>
            <div class="mt-3"><p class="font-body">Tax saving Investment other than 80C</p></div>
            <div class="mt-3"><p class="font-body">Section 80TTA: Interest from Saving Bank Account</p></div>
            <div class="mt-3"><p class="font-body">Section 80D: Payment of health insurance premium</p></div>
            <div class="mt-3"><p class="font-body">Section 80E: Repayment of an education loan</p></div>
            <div class="mt-3"><p class="font-body">Section 24: &nbsp;Interest payment of a home loan</p></div>
        </div>
    </div>
</div></div>
<app-footer></app-footer>
