import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-influencer-bank',
  templateUrl: './influencer-bank.component.html',
  styleUrls: ['./influencer-bank.component.css']
})
export class InfluencerBankComponent {
  BankDetails:FormGroup;
  constructor(private route:Router,private fb:FormBuilder)
  {
this.BankDetails=fb.group({
  "AccountNumber":["",Validators.required],
  "ConfirmAccount":["",Validators.required],
  "HolderName":["",Validators.required],
  "IFSC":["",Validators.required],
  "BankName":[""],
  "BranchName":[""],
  "statement":[""],
});
}
  back()
  {
    this.route.navigate(['/influencer-profile']);
  }
  Success:boolean=false;
  close(){
    this.Success=false;
  }
  success()
  {
    this.Success=true;
  }

}
