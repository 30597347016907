//import { Router,Component, OnInit,ActivatedRoute } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/Services/general.service';


@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.css']
})
export class FailureComponent implements OnInit {
  isUserLogged: boolean = false;
  transactionNumber:string ="";
  premium:string="";
  constructor(private router: Router,private route: ActivatedRoute, private generalService : GeneralService){
  }
  ngOnInit() {
    this.transactionNumber="";
    this.premium ="";
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
    }

    this.route.queryParamMap.subscribe((params:any) => {
      ;
      this.transactionNumber = params?.params['transactionNumber'];
        this.premium = params?.params['premium'];
    })
  }
}
