import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dont-know-commercial-vechicle',
  templateUrl: './dont-know-commercial-vechicle.component.html',
  styleUrls: ['./dont-know-commercial-vechicle.component.css']
})
export class DontKnowCommercialVechicleComponent {
  constructor(private router:Router){

  }
Popup:boolean=true;
isDisable:boolean=true;
type:string='';
TabName:string="Manufacturer";
add:boolean=false;
tabs:string='';
rto:string[]=['DL1S','DL2S','DL3S','DL4S','DL5S','DL6S','DL7S','DL8S','DL9S','DL10S','DL11S','DL12S',]
city:string[]=['Delhi','Mumbai','Chennai','Kolkata','Bengaluru','Noida, Ghaziabad','Gurgaon','Pune','Hyderabad','Ahmedabad','Jaipur','Udaipur'];
modals:string[]=['ALTROZ','NEXON','Punch','Harrier','Safari','NEXON EV Max','TIAGO','TIGOR'];
otherModal:string[]=['HEXA',' INDICA V2','INDICA VISTA','INDIGO eCS','NANO','XENON','ZEST','SAFARI DICOR 2.2 VTT','BOLT','INDIGO MANZA','SPACIO','OTHERS'];
fuelType:string[]=['Petrol','Diesel','CNG','External CNG Kit'];
variantType:string[]=['XE Petrol (1199 cc)','XE Rhytm Petrol (1199 cc)','XT Petrol (1199 cc)','XZ Petrol (1199 cc)'];
otherVariant:string[]=['XE Plus Petrol (1199 cc)','XE Plus Petrol (1199 cc)','XM Petrol (1199 cc)','XM Plus (S) (1199 cc)','XM Rhytm Petrol (1199 cc)','XM Rhytm Plus Style Petrol (1199 cc)','XM Style Petrol (1199 cc)','XMA Plus (S) (1199 cc)','XT Luxe Petrol (1199 cc)','XT Petrol Dark Edition (1199 cc) ','XT i-Turbo Petrol (1199 cc)','XTA Petrol (1199 cc)'];
selectYear=[2023,2022,2021,2020,2019,2018,2017,2016,2015,2014,2013,2012,2011,2010,2009,2008,2007,2006,2005,2004,2003];
Tab:string='';
nextTab(tabName:string):void{
 this.tabs=tabName;
 

}

close()
{
  this.Popup=false;
}
Types(ty:string)
{
  this.type=ty;
this.isDisable=false;
}goPlan()
{
  this.router.navigate(['/CommercialPlanList']);
}
} 
