<div class="content-box bg-grey1" [ngClass]="!isUserLogged ? '' : 'user-dashboard'">
    <div class="container-fluid" [ngStyle]="{'margin-bottom':!isUserLogged ? '' : '90px'}" [ngClass]="!isUserLogged ? '' : 'user-route'">
        <div class="container " style="background-color: #F3F3F4">
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"  type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="card p-2 mt-5">
                            <h4 class="text-center">
                                Unable to process your payment!
                            </h4>
                        </div>
                        <div class="card mt-3 p-2">
                            <div class="row">
                                <div class="col-md-12 center">
                                    <img src="../../../../assets/images/wrong.png" alt="">
                                    <h4 style="color: rgb(244, 10, 10);">Payment Failed of ₹{{premium}}</h4>
                                    <span class="font-body">We can't process your payment, check your internet connection and try again.</span>
                                </div>
                            </div>
                            <div class="center mt-5">
                                <div class="card crd">
                                    <div class="row">
                                        <div class="col-md-6 mt-1 p-3 ">
                                            <span>Transaction id</span>
                                        </div>
                                        <div class="col-md-6 mt-1 p-3  ">
                                            <span><b>{{transactionNumber}}</b></span>
                                        </div>
                                        <!-- <div class="col-md-6 mt-1 p-3 ">
                                            <span>Payment type</span>
                                        </div>
                                        <div class="col-md-6 mt-1 p-3 ">
                                            <span><b>Net banking

                                                </b></span>
                                        </div>
                                        <div class="col-md-6 mt-1 p-3  ">
                                            <span>Bank</span>
                                        </div>
                                        <div class="col-md-6 mt-1 p-3 ">
                                            <span><b>HDFC</b></span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="center mt-5 mb-4">
                                    <button class="btn btn-lg btn-primary">
                                        Try Again ₹{{premium}}
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4 mt-3">
                        <div class="card ">
                            <div class="row">
                                <div class="col-md-12">
                                    <h4>
                                        Your vehicle details
                                    </h4>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <span>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJJKgExd3KX8QoCOdIGMpAt6XWrVTZJEiRZQ&usqp=CAU"
                                            width="15" height="20" alt="Vehicle">&nbsp;&nbsp;Vehicle<p><b>
                                                Suzuki Motorcycle
                                            </b></p>
                                    </span>
                                </div>
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <img src="../../assets/svgIcon/city.svg" alt="city">&nbsp;&nbsp;
                                        <div>
                                            <p class="font">Modal
                                                <br>
                                                <span>ST BSVI 125</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 ">
                                    <div class="d-flex">
                                        <img src="../../assets/svgIcon/city.svg" alt="city">&nbsp;&nbsp;
                                        <div>
                                            <p class="font">RTO
                                                <br>
                                                <span>mp43- abc</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-2">

                                    <div class="d-flex">
                                        <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;

                                        <p class="font">Year</p>
                                        &nbsp; <h5>2023</h5>

                                    </div>

                                </div>
                                <div class="col-md-6 mt-2">

                                    <div class="d-flex">
                                        <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;

                                        <p class="font">Last Year Claim</p>
                                        &nbsp;&nbsp;&nbsp;&nbsp; <h5>NO</h5>

                                    </div>

                                </div>
                                <div class="col-md-6 mt-2">

                                    <div class="d-flex">
                                        <img src="../../assets/svgIcon/CalendarBlank.svg" alt="CalendarBlank">&nbsp;&nbsp;

                                        <p class="font">NCB Last Year</p>
                                        &nbsp;&nbsp;&nbsp;&nbsp; <h5>0%</h5>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="card mt-5 p-5">
                            <h4>
                                Premium Breakup
                            </h4>
                            <div class="row">
                                <div class="col-md-8">
                                    <p>Basic Own Damage</p>
                                </div>
                                <div class="col-md-4">
                                    <span class="spn">₹1,000</span>
                                </div>
                                <hr>
                                <div class="col-md-8">
                                    <p>Net Premium</p>
                                </div>
                                <div class="col-md-4">
                                    <span class="spn">₹1,000</span>
                                </div>
                                <div class="col-md-8">
                                    <p>Add GST(18%)</p>
                                </div>
                                <div class="col-md-4">
                                    <span class="spn">₹180</span>
                                </div>
                                <hr>
                                <div class="col-md-8">
                                    <p>Final Premium</p>
                                </div>
                                <div class="col-md-4">
                                    <span class="spn">₹1,180</span>
                                </div>


                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        </div>
    </div>