import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from './../Models/login.component.model';
import { LoginService } from './../Services/login.service';
import swal from 'sweetalert2';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  loginmodel = new LoginModel();
  newPassword: string | undefined;
  confirmNewPassword: string | undefined;
  isPasswordFlag = false;
  resetpasswordForm: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  constructor(private loginService: LoginService, private fb: FormBuilder) {
    this.resetpasswordForm = this.fb.group({
      phoneNumber: ['', Validators.required]
    });

  }

  onCountryChangeNew(e: any) {
    this.resetpasswordForm.get('phoneNumber')?.setValue("");
  }

  async resetPassword() {
    if (this.resetpasswordForm.get('phoneNumber')?.value != null && typeof (this.resetpasswordForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.resetpasswordForm.get('phoneNumber')?.value;
      this.resetpasswordForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
    const response = await this.loginService.ResetPassword(this.resetpasswordForm.value);
    if (response?.isSuccess) {
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Reset password link successfully',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }
}
