<div class="login-container d-flex flex-root" id="kt_app_root">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
      type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  
    <div class="section d-flex justify-content-center">
      <div class="login">
        <div class="card-body d-flex flex-column">
          <div class="logo">
            <a href="/" class="link-primary" routerLink="/"><img src="../../assets/images/logo.svg" alt="" class="logo"></a>
          </div>
          <div class="login-form flex-center flex-column-fluid"  [ngStyle]="{'margin-top':isForgot ? 'margin-top: calc(100% - 371px)' : '' }" [ngStyle]="{'margin-top':isOtp ? 'calc(100% - 350px)' : '' }">
            <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="resetPassForm">
              <div *ngIf="isForgot">
                <div class="text-center mb-11">
                  <h1>Forgot Password</h1>
                </div>
                <div class="fv-row mb-7">
                  <label class="input-label" for="mobile">Mobile Number</label>
                  <ngx-intl-tel-input class="bg-transparent"
                    [enableAutoCountrySelect]="true" 
                    placeholder="Enter Mobile Number/Email"
                    [searchCountryFlag]="false" 
                    [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.India" 
                    [phoneValidation]="true" 
                    [separateDialCode]="separateDialCode"
                    customPlaceholder="Enter your mobile number"
                    name="phone" 
                    [maxLength]="10"
                    formControlName="PhoneNumber" 
                    [inputId]="'phone_number'"
                    (countryChange)="onCountryChangeNew($event)" 
                    aria-hidden="true" 
                    #phoneNumber>

                  </ngx-intl-tel-input>
                </div>
                <div class="d-grid mb-5 mt-5">
                  <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" [disabled]="resetPassForm.invalid" (click)="sendOtp()">
                    <span class="indicator-label">Send OTP</span>
                  </button>
                </div>
                <div class="text-center">
                  <span>Back to login?
                  <a class="link-primary" routerLink="/login">Click Here</a></span>
                </div>
              </div>
              <div *ngIf="isOtp">
                <div class="text-center mb-11">
                  <h1>Forgot Password</h1>
                </div>
                <div class="text-center">
                  <h6 class="font-body">Enter 6 digit code that sent to your number<br> {{mobileNo}}<a class="link-primary"  (click)="sendOtp()">. Click here</a> to change your<br> mobile number.</h6>
                </div>
                <div class="fv-row mb-5">
                  <label class="input-label" for="mobile">Enter OTP</label><br>
                  <div class="fields_group">
                    <input type="text"  #otp class="bg-transparent form-control otp_filed" name="otp" formControlName="otpNumber" aria-hidden="true" [formControl]="enterOTP" placeholder="Enter OTP" style="width: 29rem;" required [minlength]="6" [maxlength]="6" (keypress)="numberOnly($event)" >
                  <span class="timerCounter" *ngIf="timerSpanValue">{{ timerValue }} Secs</span>
                  </div>
                  
                </div>
                <div class="text-center">
                  <span>If you didn't receive a code! 
                  <a  class="link-primary" (click)="sendOtp()" > Resend</a></span>
                </div>
                <div class="d-grid mb-5 mt-5">
                  <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" [disabled]="enterOTP.invalid" (click)="resetPassword()">
                    <span class="indicator-label">Submit</span>
                  </button>
                </div>
                <div class="text-center">
                  <span> Back to login?
                  <a routerLink="/login" class="link-primary">Click Here</a></span>
                </div>
              </div>


              <div *ngIf="isReset">
                <div class="text-center mb-11">
                  <h1>Reset Password</h1>
                </div>
                <!-- <form class="form"  [formGroup]="resetPassForm"> -->
                <div class="fv-row mb-7">
                  <label class="input-label" for="mobile">Enter new password</label>
                  <input type="password" placeholder="New Password" name="password" autocomplete="off" #password
                  class="form-control bg-transparent" formControlName="password1" style="width: 29rem;"/>
                  <span (click)="password.type=password.type=='text'?'password':'text'" 
                  class="fa fa-fw field-icon toggle-password"
                      [ngClass]="(password.type=='password')?' fa-eye-slash':' fa-eye'"></span>
                </div>
                <div class="fv-row mb-3">
                  <!-- <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold">
                    <div><label for="password">Password</label></div>
                    <a href="/forgotpassword" class="link-primary" routerLink="/forgotPassword">Forgot Password ?</a>
                  </div> -->
                  <label class="input-label" for="mobile">Confirm new password</label>
                  <input type="password" placeholder="Confirm New Password" name="password" autocomplete="off" #password1
                    class="form-control bg-transparent" formControlName="confirm_password" style="width: 29rem;"/>
                    <span (click)="password1.type=password1.type=='password'?'text':'password'" 
                  class="fa fa-fw field-icon toggle-password"
                      [ngClass]="(password1.type=='password')?' fa-eye-slash':' fa-eye'"></span>
                </div>
                <div class="d-grid mb-5 mt-5">
                  <button class="btn btn-primary" type="submit" id="kt_sign_in_submit" style="width: 29rem;"
                  (click)="passwordReset()">
                    <span class="indicator-label">Reset Password</span>
                  </button>
                </div>
                <div class="text-center">
                  <span>Back to login?
                    <a class="link-primary" routerLink="/login">Click Here</a></span>
                </div>
                <!-- </form> -->
              </div>
            </form>
          </div>
          <div class="footer_copy_right Copyright text-center">
            <span>© Copyright {{currentYear}} Click4bima. All Rights Reserved</span>
          </div>
        </div>
      </div>
    </div>
    <div class="service card shadow rounded-5 bg-light">
      <app-carousel></app-carousel>
    </div>
  </div>

  


