<app-navbar></app-navbar>
<div class="container">
    <div class="container-fluid">
        <div class="row content-body">
            <div class="row">
                <h1 class="mt-5">CANCELLATION & REFUND</h1>
            </div>
            <div class="top-line"></div>
            <div class="row mt-5">
                <p class="font-body mt-3">The cancellation of the policy and/or the refund of the premium is processed
                    as
                    follow:</p>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Cancellation of the policy by you</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If you don’t agree to certain terms and conditions in your policy, you can cancel it any time before the renewal date. To initiate a cancellation, you need to write an application to the insurance company stating the reason for cancelling the policy. The insurance company or insurer referred to here is the company whose policy you have purchased. Depending on when you have applied for cancellation your request will be processed. The period of cancellation will be considered as the time when the insurance company receives the written request.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Cancellation within the free-look period</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">Free-look period is a 15-day period post purchase, that most insurance companies give their customers to review the policy and cancel it if required. The Insurance Regulatory and Development Authority of India (IRDAI) has mandated free-look period for life insurance policies and health insurance policies with policy periods of 3 years or more. However, most insurance companies have a free-look period for health insurance policies with a policy period of less than 3 years too.</p>
                    <P class="font-body">If you request a cancellation of your policy within the free-look period, you get a refund of your premium excluding:</P>
                    <ul>
                        <li>Health check-up expenses</li>
                        <li>Stamp duty</li>
                        <li>The proportionate risk premium of the period for which you were covered</li>
                    </ul>
                    <p class="font-body">Also, please note that the free-look provision is not available for renewals.</p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Cancellation Beyond Free-Look Period</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        If you apply for cancellation of the policy beyond the free-look period then the policy cancellation will be processed as per the underwriting of the policy. Also, the amount of premium will be refunded will be equal to what has been defined in the cancellation clause of your policy wording.
                    </p>
                    <p class="font-body">
                        If you wish to cancel a policy you have purchased from our portal, you can contact us over the phone or on email and we will assist you through the process.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Cancellation by the Insurance Company</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        The insurance company has the right to cancel your policy in the event of fraud, misrepresentation, non-disclosure of important facts or non-cooperation by the insured members. In such cases, the insurance company will give notice of 30-days and the premium will not be refunded.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="title-line">
                    <h4>Refund of the Premium</h4>
                </div>
                <div class="mt-3">
                    <p class="font-body">
                        The premium that you pay is collected directly by the insurance company. If you claim for a refund because of an incorrect transaction or policy cancellation, you will get an amount as per the insurance company’s policy. The refunded amount will be given to you in the form of a cheque or direct transfer, as per the insurance company’s policy
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- <div class="container mt-15">
        <div class="content-box">
            <div class="card-container container-fluid d-flex">
                <div class="card card-item shadow">
                <h2 style="text-decoration: underline;">Cancellation & Refund</h2>
                <p>The cancellation of the policy and/or the refund of the premium is processed as follows:</p>
                <h3>Cancellation of the policy by you</h3>
                <p>If you don’t agree to certain terms and conditions in your policy, you can cancel it any time before the renewal date. To initiate a cancellation, you need to write an application to the insurance company stating the reason for cancelling the policy. The insurance company or insurer referred to here is the company whose policy you have purchased. Depending on when you have applied for cancellation your request will be processed. The period of cancellation will be considered as the time when the insurance company receives the written request.</p>
                <h3>Cancellation within the free-look period</h3>
                <p>Free-look period is a 15-day period post purchase, that most insurance companies give their customers to review the policy and cancel it if required. The Insurance Regulatory and Development Authority of India (IRDAI) has mandated free-look period for life insurance policies and health insurance policies with policy periods of 3 years or more. However, most insurance companies have a free-look period for health insurance policies with a policy period of less than 3 years too.</p>
                <P>If you request a cancellation of your policy within the free-look period, you get a refund of your premium excluding:</P>
                <ul>
                    <ol>Health check-up expenses</ol>
                    <ol>Stamp duty</ol>
                    <ol>The proportionate risk premium of the period for which you were covered</ol>
                </ul>
                <p>Also, please note that the free-look provision is not available for renewals.</p>
                <h3>Cancellation Beyond Free-Look Period</h3>
                <p>If you apply for cancellation of the policy beyond the free-look period then the policy cancellation will be processed as per the underwriting of the policy. Also, the amount of premium will be refunded will be equal to what has been defined in the cancellation clause of your policy wording.</p>
                <p>If you wish to cancel a policy you have purchased from our portal, you can contact us over the phone or on email and we will assist you through the process.</p>
                <h3>Cancellation by the Insurance Company</h3>
                <p>The insurance company has the right to cancel your policy in the event of fraud, misrepresentation, non-disclosure of important facts or non-cooperation by the insured members. In such cases, the insurance company will give notice of 30-days and the premium will not be refunded.</p>
                <h3>Refund of the Premium</h3>
                <p>The premium that you pay is collected directly by the insurance company. If you claim for a refund because of an incorrect transaction or policy cancellation, you will get an amount as per the insurance company’s policy. The refunded amount will be given to you in the form of a cheque or direct transfer, as per the insurance company’s policy</p>
                </div>
            </div>
        </div>
    </div> -->
<app-footer></app-footer>

