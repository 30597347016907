import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpService } from './../Services/lookup.service';
import { LookUpViewModel, PinCodeMasterViewModel } from './../Models/vehicle-component.model';
import { BusinessService } from './../Services/business.service';
import { CommonService } from './../Services/common.service';
import { BusinessInfoModel } from './../Models/business.component.model';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';




@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.css']
})
export class BusinessInfoComponent implements OnInit {
  businessInfoForm: FormGroup;
  userAccountInfoForm: FormGroup;
  userCityList: PinCodeMasterViewModel[] = [];
  cityList: PinCodeMasterViewModel[] = [];
  fileToUpload: File | undefined;
  baseUpload64FileData: string = "";
  format: string = '';
  deaultData = new PinCodeMasterViewModel();
  deaultUserData = new PinCodeMasterViewModel();


  businessId!: number;
  modalReference: any;

  iPAddress: any;
  GeoLocation: any;
  latitude!: string
  longitude!: string
  disableProp: boolean = true;
  businessList = [new BusinessInfoModel()];

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  categoryData:any[]=[];

  constructor(private fb: FormBuilder, private lookUpService: LookUpService,
    private businessService: BusinessService, //private modalService: NgbModal,
    private commonService: CommonService,
    private spinner: NgxSpinnerService) {
    this.businessInfoForm = this.fb.group({
      "businessName": ['', [Validators.required]],
      "businessPhoneNumber": ['', [Validators.required]],
      "businessCountryCode": [],
      "businessEmail": ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      "businessDescription": ['', ''],
      "businessAddress1": ['', [Validators.required]],
      "businessAddress2": ['', ''],
      "businessPinCode": ['', [Validators.required]],
      "businessCity": ['', [Validators.required]],
      "stateId": [{ value: '', disabled: true }],
      "countryId": [{ value: '', disabled: true }],
      "taluka": [{ value: '', disabled: true }],
      "district": [{ value: '', disabled: true }],
      "userId": ['', ''],
      "businessId": ['', ''],
      "businessStatus": ['', ''],
      "businessAddressType": ['', ''],
      "pinCodeMasterId": ['', ''],
      "category": ['', [Validators.required]],
      "documentBase64": ['', Validators.required]

    });

    this.userAccountInfoForm = this.fb.group({
      "accountBankId": ['', [Validators.required]],
      "accountIFSCCode": ['', [Validators.required]],
      "accountBankName": ['', []],
      "userAccountAddress1": ['', ''],
      "userAccountPinCode": ['', [Validators.required]],
      "userAccountCity": ['', [Validators.required]],
      "countryId": [{ value: '', disabled: true }],
      "stateId": [{ value: '', disabled: true }],
      "userId": ['', []],
      "userAccountAddressType": ['', []],
      "businessId": ['', ''],
      "accountStatus": [0, ''],
      "taluka": [{ value: '', disabled: true }],
      "district": [{ value: '', disabled: true }],
      "pinCodeMasterId": ['', ''],
      "confirmAccountBankId": ['', [Validators.required]],
     
    });
  }


  ngOnInit() {
    this.userCityList = [];
    this.cityList = [];
    this.deaultData = new PinCodeMasterViewModel();
    this.deaultUserData = new PinCodeMasterViewModel();
    this.getBusinessInfo();
    this.getIpAdress();
    // this.getUserLocation();
    this.loadCategory();
  }

  loadCategory(){
    this.categoryData = [{id:1,name:"Garage"},{id:2,name:"Medical Store"},{id:3,name:"Hospital"}];
  }

  async getIpAdress(){
  const res = await  this.commonService.GetIPAddress();
  if (res !=null ) {
    this.iPAddress = res.result?.ip;
  }
  }

  getUserLocation() {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.GeoLocation = "latitude:" + position.coords.latitude + ",longitude:" + position.coords.longitude;
    //   this.latitude = (position.coords.latitude).toLocaleString();
    //   this.longitude = (position.coords.longitude).toLocaleString();

    // },
    //   (error) => {
    //     let errorMessage = '';
    //     if (error.PERMISSION_DENIED) {
    //       errorMessage = `Couldn't get your location Permission denied. \n Please allow the location for access.`;
    //       console.log("Error", errorMessage.toString(), "error");
    //     }
    //     else if (error.POSITION_UNAVAILABLE) {
    //       errorMessage = `Couldn't get your location Position unavailable. \n Please allow the location for access.`;
    //       console.log("Error", errorMessage.toString(), "error");
    //     }
    //     else if (error.TIMEOUT) {
    //       errorMessage = `Couldn't get your location'+'Timed out.`;
    //       console.log("Error", errorMessage.toString(), "Time Out");
    //     }
    //     else {
    //       console.log(error.message + `Error: ${error.code}`);
    //     }
    //   }, { enableHighAccuracy: true }
    // );
  }



  //#region dropdowns loading


  async changeCity(cityId: any) {
    this.businessInfoForm.controls['countryId'].setValue(this.deaultData.country);
    this.businessInfoForm.controls['taluka'].setValue(this.deaultData.taluka);
    this.businessInfoForm.controls['district'].setValue(this.deaultData.district);
    this.businessInfoForm.controls['stateId'].setValue(this.deaultData.state);
  }

  async changeUserCity(cityId: any) {
    this.userAccountInfoForm.controls['countryId'].setValue(this.deaultUserData.country);
    this.userAccountInfoForm.controls['taluka'].setValue(this.deaultUserData.taluka);
    this.userAccountInfoForm.controls['district'].setValue(this.deaultUserData.district);
    this.userAccountInfoForm.controls['stateId'].setValue(this.deaultUserData.state);
  }

  async countryDetails() {
  }

  //#endregion 

  //#region Save Business Infomation
  async businessInfo() {
    
    if (this.businessInfoForm.get('businessPhoneNumber')?.value != null && typeof (this.businessInfoForm.get('businessPhoneNumber')?.value) == 'object') {
      let phone = this.businessInfoForm.get('businessPhoneNumber')?.value;
      this.businessInfoForm.get('businessPhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.businessInfoForm.get('businessCountryCode')?.setValue(phone.dialCode);
    }
    this.businessInfoForm.value.userId = 1002;
    this.businessInfoForm.value.businessId = 0;
    this.businessInfoForm.value.businessStatus = true;
    this.businessInfoForm.value.businessAddressType = 1;
    this.businessInfoForm.value.pinCodeMasterId = this.businessInfoForm.value.businessCity;
    const res = await this.businessService.BusinessInfo(this.businessInfoForm.value);
    if (res.isSuccess) {
      this.businessId = res.result;
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'business data added successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      this.cityList = [];
      this.getBusinessInfo();
      this.clearData();
    }
  }

  //#endregion 

  //#region Clear Data
  clearData() {
    this.businessInfoForm.reset();
  }
  //#endregion
  async getBusinessInfo() {
    let request = {};
    const res = await this.businessService.GetBusinessDetails(request);
    if (res != null && res.result) {
      this.businessList = res.result;
    }
  }


  async addUserInfo() {
    if(this.userAccountInfoForm.value.confirmAccountBankId === this.userAccountInfoForm.value.accountBankId){
    this.userAccountInfoForm.value.userId = 1002;
    this.userAccountInfoForm.value.businessId = this.businessId;
    this.userAccountInfoForm.value.userAccountAddressType = 3;
    this.userAccountInfoForm.value.pinCodeMasterId = this.userAccountInfoForm.value.userAccountCity;
    const res = await this.businessService.UserAccountInfo(this.userAccountInfoForm.value);
    if (res.isSuccess) {
      this.display = "none";
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'User Account added successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      this.userCityList = [];
      this.clearPopupData();

    }
  }
  else{
    swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Account number not matched with the confirm account number',
      showConfirmButton: false,
      timer: 3000,
    });
  }
  }

  clearPopupData() {
    this.userAccountInfoForm.reset();
  }

  async pincodeChangeEvent(pincode: any) {
    this.cityList = [];
    this.deaultData = new PinCodeMasterViewModel();

    this.businessInfoForm.controls['businessCity'].setValue([]);
    this.businessInfoForm.controls['countryId'].setValue("");
    this.businessInfoForm.controls['taluka'].setValue("");
    this.businessInfoForm.controls['district'].setValue("");
    this.businessInfoForm.controls['stateId'].setValue("");

    const res = await this.lookUpService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.deaultData = res.result[0];
      this.cityList = res.result;
    }
  }
  async pincodeUserChangeEvent(pincode: any) {
    this.userCityList = [];
    this.deaultUserData = new PinCodeMasterViewModel();
    this.userAccountInfoForm.controls['userAccountCity'].setValue([]);
    this.userAccountInfoForm.controls['countryId'].setValue("");
    this.userAccountInfoForm.controls['taluka'].setValue("");
    this.userAccountInfoForm.controls['district'].setValue("");
    this.userAccountInfoForm.controls['stateId'].setValue("");
    const res = await this.lookUpService.pincodeMasterDetails(pincode.value);
    if (res != null && res.result) {
      this.deaultUserData = res.result[0];
      this.userCityList = res.result;
    }
  }

  onCountryChangeNew(e: any) {
    this.businessInfoForm.get('businessPhoneNumber')?.setValue("");
  }
  async onSelectFile(file: any) {
    this.fileToUpload = file.target.files.item(0);
    if (this.fileToUpload?.type.includes('image') && this.fileToUpload!.size > 500000) {
      this.baseUpload64FileData = '';
      this.businessInfoForm.get('documentBase64')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 500kb',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else if (this.fileToUpload?.type.includes('pdf') && this.fileToUpload!.size > 5000000) {
      this.baseUpload64FileData = '';
      this.businessInfoForm.get('documentBase64')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 5MB',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else if (this.fileToUpload?.type.includes('video') && this.fileToUpload!.size > 50000000) {
      this.baseUpload64FileData = '';
      this.businessInfoForm.get('documentBase64')?.setValue('');
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'File size should not be greather than 50MB',
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else {
      let reader = new FileReader();
      reader.onload = async (event: any) => {
        this.baseUpload64FileData = reader.result!.toString();
        await this.SetFileType();
      }
      reader.readAsDataURL(this.fileToUpload!);
    }
  }

  async SetFileType() {
    if (this.baseUpload64FileData.includes(`application/pdf`)) {
      this.format = 'pdf';
    }
    else if (this.baseUpload64FileData.includes(`image`)) {
      this.format = 'image';
    }
    else if (this.baseUpload64FileData.includes(`video`)) {
      this.format = 'video';
    }
  }

  display = "none";
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }
}
