import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VehiclePremiumInfoComponent } from '../vehicle-premium-info/vehicle-premium-info.component';
import { InsuranceDetails, VehicleDetails,PremiumDetails   } from '../../Models/vehicle-component.model';
import { GeneralService } from 'src/app/Services/general.service';



@Component({
  selector: 'vehicle-policy-details',
  templateUrl: './vehicle-policy-details.component.html',
  styleUrls: ['./vehicle-policy-details.component.css']
})
export class VehiclePolicyDetailsComponent implements OnInit {
  isSmallScreen: boolean = false; 
  @HostListener('window:resize', ['$event']) onResize(event: any) { this.checkScreenSize(); } 
  checkScreenSize() { this.isSmallScreen = window.innerWidth <= 900; }
  @ViewChild(VehiclePremiumInfoComponent) VehiclePremiumInfoComponent: VehiclePremiumInfoComponent;
  @Output() dataEvent = new EventEmitter<any>();
  vehicleForm: FormGroup;
  premium: any;
  vehicleData: VehicleDetails = {};
  premiumData: PremiumDetails = {};
  isUserLogged: boolean = false;
  constructor(private fb: FormBuilder, private generalService: GeneralService) {
    this.vehicleForm = this.fb.group({
      "city": [""],
      "rto": [""],
      "make": [""],
      "model": [""],
      "variant": [""],
      "registrationYear": [""],
      "registrationNumber": [""],
      "chasisNumber": [""],
      "engineNumber": [""],
      "registrationDate": [""]
    })
  }

  ngOnInit() {
    if(this.generalService.getUniqueuserid() != ""){
      this.isUserLogged = true;
    }
  }


  async QuoteDetails(vehiclePremiumData: any) {
    this.premium = vehiclePremiumData.premiumData.netPremium;
    this.vehicleData = vehiclePremiumData.vehicleData;
    this.premiumData = vehiclePremiumData.premiumData;
   // setTimeout(async () => await this.VehiclePremiumInfoComponent.QuoteDetails(vehiclePremiumData), 500);
    this.vehicleForm.patchValue(vehiclePremiumData.vehicleData);

  }

  CreatePolicy() {
    this.dataEvent.emit();
  }

} 
