import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MemberFormField, MembersForm } from 'src/app/Models/MembersForm';

@Component({
  selector: 'app-dynamic-member',
  templateUrl: './dynamic-member.component.html',
  styleUrls: ['./dynamic-member.component.css']
})
export class DynamicMemberComponent {
  @Input() required_input_fields: MembersForm;
  @Input() questionFor: string;
  @Input() containerClass: string ="";
  @Output() dataEvent  = new EventEmitter<any>(); 


  onChange(id:string,event:any, type:any="", parentId:any =""){
    ;
    let selectedValue = type !="checkbox" ? event.target.value : (event.target.checked == true ? "Yes" : "No");
    console.log(id + '-' + selectedValue);
    this.required_input_fields.memberFormFields = this.required_input_fields.memberFormFields?.map((obj:MemberFormField) => {
      if (obj.id === id) {
          return { ...obj, selectedValue: selectedValue };
      }
      return obj;
    });
    this.dataEvent.emit({data: this.required_input_fields,parentId:parentId,for:this.required_input_fields.for});
  }
}
